import { IScreenplay } from '@lexialearning/lobo-common';

export enum ActUiActionType {
  AddPlayedFunFact = 'ACT_UI.ADD_PLAYED_FUN_FACT'
}

export interface IActUiState {
  playedFunFactIds: string[];
}

export interface IFunFactData {
  funFact: IScreenplay | undefined; // once Contentful is populated, undefined should not be necessary
  playbacksRemaining: number;
}

export const AllottedFunFactPlaybacks = 3;
