import { ofType, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { filter, mergeMap, startWith, takeWhile } from 'rxjs/operators';
import {
  IProgramPosition,
  LevelAction,
  LevelActionLoad,
  LevelActionLoadSuccess,
  LevelActionType,
  PlacementAction,
  PlacementActionLoadForDeepLink,
  ProgramContextActionLoadedForDeepLinking,
  ProgramContextActionType,
  ProgramContextSelector,
  UnitAction,
  UnitActionLoad,
  UnitActionLoadSuccess,
  UnitActionType,
  UnitSelector
} from 'curriculum-services';
import {
  EncounterSceneAction,
  EncounterScenePrepareAction
} from 'feature-areas/encounters/encounter-scene';
import { PlacementScenePrepareAction } from 'feature-areas/placement/placement-scene/redux';
import { RouteBuilder, RouterService, RoutePath } from 'router-service';
import { PredicateUtils } from 'utils';
import { AppState } from 'services';

type OutputActions =
  | LevelActionLoad
  | PlacementActionLoadForDeepLink
  | PlacementScenePrepareAction
  | EncounterScenePrepareAction
  | UnitActionLoad;

export function loadProgramContextForDeepLinking(
  action$: Observable<ProgramContextActionLoadedForDeepLinking>,
  state$: StateObservable<AppState>
): Observable<OutputActions> {
  return action$.pipe(
    ofType(ProgramContextActionType.LoadedForDeepLinking),
    mergeMap(action => {
      const position = ProgramContextSelector.getPosition(state$.value);

      const loadSuccess$ = action$ as unknown as Observable<LoadSuccessActions>;

      return loadSuccess$.pipe(
        startWith(action),
        takeWhile(a => a?.type !== UnitActionType.LoadSuccess, true),
        mergeMap(loadSuccessAction => {
          const isOnboardingOrPlacement =
            ProgramContextSelector.isOnboardingOrPlacement(state$.value);

          switch (loadSuccessAction.type) {
            case ProgramContextActionType.LoadedForDeepLinking:
              return of(getDeepLinkAction(isOnboardingOrPlacement, position));
            case LevelActionType.LoadSuccess:
              return from([
                UnitAction.load.request(),
                getEncounterScenePrepareActionMaybe(isOnboardingOrPlacement)
              ]);
            case UnitActionType.LoadSuccess:
              maybeRouteToFirstRound(state$.value);

              return of(undefined);
            default:
              return of(undefined);
          }
        }),
        filter(PredicateUtils.isDefined)
      );
    })
  );
}
loadProgramContextForDeepLinking.displayName =
  'loadProgramContextForDeepLinking';

function getDeepLinkAction(
  isOnboardingOrPlacement: boolean,
  position: IProgramPosition
) {
  if (isOnboardingOrPlacement) {
    return PlacementAction.loadForDeepLink();
  }

  return LevelAction.load.request({ sysId: position.levelId });
}

function getEncounterScenePrepareActionMaybe(isOnboardingOrPlacement: boolean) {
  if (isOnboardingOrPlacement) {
    return;
  }

  return EncounterSceneAction.prepare();
}

/**
 * If the requested URL lacked a round id, nav to the first round of the
 * requested unit.
 */
function maybeRouteToFirstRound(state: unknown): void {
  if (
    RouterService.isOnRoute([
      RoutePath.OnboardingRounds,
      RoutePath.PlacementRounds,
      RoutePath.Rounds
    ])
  ) {
    return;
  }

  const unit = UnitSelector.getUnit(state);
  RouterService.history.replace(
    RouteBuilder.rounds(unit.sysId, unit.rounds[0].sysId)
  );
}

type LoadSuccessActions =
  | ProgramContextActionLoadedForDeepLinking
  | LevelActionLoadSuccess
  | UnitActionLoadSuccess;
