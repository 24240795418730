import { Position } from '@lexialearning/common-ui';
import { Types } from 'common-ui';

export interface ISkipButtonStyle {
  bottomRight: Types.ViewStyle;
}

export class SkipButtonStyles {
  private static readonly styles = {
    bottomRight: {
      bottom: 35,
      position: Position.Absolute,
      right: 55
    }
  };

  public static get() {
    return this.styles;
  }
}
