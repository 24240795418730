import { Svg, Path, G, Rect } from '@lexialearning/common-ui';
import { useSelector } from 'react-redux';
import { ProfileSelector } from 'services/profile';
import { FaceStyle } from 'services/profile/avatar.model';
import { getFaceColors } from '../face-styles.model';

export function FaceStyleTwoSvg(): React.ReactElement {
  const { hairColor, skinColor } = useSelector(ProfileSelector.getAvatarMaybe)!;
  const {
    eyeLines,
    eyebrowLines,
    eyebrows,
    eyelashes,
    eyes,
    lips,
    skin,
    skinLines
  } = getFaceColors(FaceStyle.Two, hairColor, skinColor);

  return (
    <Svg
      data-test-id={FaceStyleTwoSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <G>
        <Rect fill={skin} strokeWidth="0" width="1000" height="1000" />
      </G>
      <G>
        <G>
          <Path
            fill={lips}
            strokeWidth="0"
            d="M646.92,872.51c-34.24-4.19-68.26-8.25-102.26-12.53-59.83-7.52-119.33-5.65-178.53,5.83-10.67,2.07-21.24,4.85-32,6.24-9.04,1.17-18.3,1.22-27.42.8-2.3-.11-6.27-4.04-6.16-6.04.13-2.47,3.49-5.02,5.9-6.99,1.07-.88,3.37-.91,4.87-.49,12.88,3.59,22.55-3.43,32.46-9.64,14.54-9.1,29.04-18.28,43.57-27.4,20.72-13.01,42.78-17.83,67.18-12.84,8.79,1.8,18.6,2.14,27.31.25,28.79-6.27,55.88-.23,82.23,10.09,18.27,7.16,35.72,16.38,53.55,24.65,5.69,2.64,11.45,5.16,17.13,7.82,13.32,6.25,27.13,8.06,41.58,4.95,3.24-.7,6.8-1.79,9.81-1.06,2.75.67,5.87,3.14,7.02,5.65.59,1.29-2.29,5.6-4.42,6.43-5.36,2.07-11.12,3.66-16.83,4.12-8.3.67-16.69.17-24.99.17Z"
          />
          <G>
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M480.47,700.43c7.15,1.21,15,2.37,22.77,3.91,4.91.98,9.64,3.03,14.58,3.73,23.36,3.33,35.73-5.32,33.72-28.63-.74-8.53-4.73-16.77-7.09-25.18-.51-1.8-.4-3.77-.57-5.66,1.87.49,4.11.49,5.55,1.56,16.08,11.97,20.51,46.44,7.37,61.68-7.36,8.53-18.04,10.29-28.75,8.87-9.09-1.21-18.03-3.64-27.02-5.61-10.53-2.3-20.72-2.61-29.97,4.27-4.98,3.7-9.29,3.4-12.31-2.58-3.14-6.22-.64-11.11,4.96-13.37,4.89-1.97,10.58-1.98,16.77-2.99Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M414.74,544.86c.24,1.39,1.1,3.05.62,4.11-2.42,5.32-5.39,10.4-7.8,15.72-7.43,16.36-15.12,32.62-21.93,49.25-8.5,20.77-4.22,41.24,3.63,61.12,5.73,14.51,13.74,27.71,25.33,38.53,1.89,1.77,2.52,4.9,3.73,7.41-2.99-.21-6.54.56-8.87-.79-14.85-8.58-23.31-22.76-29.85-37.76-11.55-26.49-13.33-53.76-.37-80.27,8.77-17.96,19.33-35.05,29.11-52.53,1.21-2.16,2.52-4.25,3.78-6.38.87.53,1.75,1.06,2.62,1.59Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M409.51,402.82c-.69,2.64-1.1,4.21-1.51,5.77-1.23-1.34-2.9-2.48-3.63-4.05-9.3-20-18.48-40.04-27.62-60.11-1.03-2.26-1.88-4.69-2.21-7.13-.19-1.43.6-4.18,1.31-4.31,1.73-.31,3.86.21,5.48,1.06,1.38.72,2.43,2.26,3.38,3.61,14.09,19.84,20.05,42.66,24.78,65.16Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M139.18,453.15c8.76,57.28,70.08,76.99,121.41,74.03,46.87-2.71,95.91-36.57,95.91-58.4,0-49.88-56.77-113.9-125.94-112.53-78.59,1.56-98.93,47.59-91.38,96.9Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M875.47,440.47c-8.76,57.28-89.21,73.26-148.72,66.42-46.64-5.36-105.8-36.31-105.8-58.14,0-49.88,66.66-114.16,135.83-112.79,78.59,1.56,126.23,55.2,118.69,104.5Z"
            />
            <G>
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M336.93,456.15c-2.38,17.9-9.79,31.32-22.23,44.75-2.1,2.24-8.81,8.67-11.19,10.49-13.98,11.05-36.78,15.52-53.84,14.82-11.61-.42-29.79-5.87-39.57-12.03-3.64-2.24-10.63-8.25-13.7-11.19-31.04-29.37-35.24-86.84-.56-120.54,15.52-15.1,41.25-20.42,55.8-18.46,35.8,4.89,57.61,12.17,81.8,56.49,2.38,4.19,4.06,30.9,3.5,35.66Z"
              />
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M841.6,464.68c-4.06,7.97-9.51,15.94-16.22,21.95-12.72,11.47-38.04,22.51-55.8,21.95-13.42-.42-28.53-.56-40.27-4.47-26.15-8.95-49.92-51.32-49.92-82.5,0-21.4,8.67-47.13,19.16-58.59,13.84-14.82,35.66-26.85,66.56-25.73,55.1,2.1,78.17,34.96,83.06,56.91,4.89,22.37,5.03,48.1-6.57,70.48Z"
              />
            </G>
            <G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M253.99,396.64c25.24-.56,44.9,19.33,45.88,46.42.86,23.79-21.36,46.86-45.8,47.56-24.57.7-46.37-20.21-46.48-44.59-.13-26.64,20.72-48.82,46.4-49.39Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M195.9,382.41c-34.8,33.79-30.49,91.28.51,120.66,3.04,2.88,10.09,8.93,13.65,11.14,9.88,6.12,27.99,11.59,39.6,12.07,17.03.71,39.82-3.9,53.81-14.87,2.42-1.9,9.07-8.22,11.17-10.47,12.52-13.43,19.97-26.95,22.3-44.83.61-4.72-1.16-31.39-3.44-35.56-24.29-44.41-46.01-51.71-81.86-56.57-14.56-1.97-40.22,3.38-55.73,18.43ZM313.21,483.15c-14.85,21.76-34.55,31.42-53.61,32.74-38.31-.66-67.8-23.43-75.22-58.36-7.09-33.38,9.61-62.94,39.33-77.29,14.32-6.91,31.08-4.76,43.8-1.45,8.46,2.2,21.42,9.41,28.97,13.8,30.15,17.55,40.58,55.62,16.74,90.57Z"
                />
              </G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M813.94,418.78c1.56,26.2-20.61,50.18-47.04,52.32-25.15,2.03-48.02-19.65-49.91-44.51-2.07-27.39,19.77-52.91,45.82-54.55,27.78-1.75,49.43,18.04,51.14,46.74Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M729.34,504.07c11.73,3.96,26.86,4.08,40.26,4.49,17.77.55,43.1-10.48,55.76-21.85,6.8-6.11,12.17-14.01,16.3-22.01,11.57-22.41,11.45-48.07,6.51-70.48-4.83-21.91-27.89-54.77-82.99-56.89-30.94-1.19-52.83,10.86-66.61,25.76-10.49,11.34-19.13,37.09-19.13,58.49,0,31.26,23.68,73.65,49.89,82.49ZM766.31,498.09c-45.36-1.38-76.18-35.51-76.29-79.77-.11-40.84,31.45-74.01,68.97-73.12,53.84,1.28,80.91,30.32,81.35,77.41.4,43.03-30.78,74.7-74.02,75.48Z"
                />
              </G>
            </G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M230.45,459.94c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M735.35,446.51c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
          </G>
          <G>
            <G>
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M695.15,358.32c47.8-29.05,97.93-17.19,143.33,12.43,4.89,3.19,26.98,30.05,30.32,34.85,8.23,11.85,5.21,24.86-.29,38.49-2.35,5.82-4.74,11.64-6.64,17.61-.92,2.89-3.45,7.23-1.06,9.2,2.48,2.04,6.29-1.57,9.17-2.92,2.21-1.03,4.15-2.81,5.97-4.52,18.25-17.11,23.64-40.42,10.98-62.87-25.86-45.87-65.04-72.96-107.1-77.42-53.51-2.1-89.88,12.88-121.97,39.38-23.21,19.17-37.36,43.69-41.5,73.72-.69,5.04-.72,16.24,4.59,12.48,2.78-1.97,7.61-12.7,9.14-16.17,11.35-25.66,32.59-54.53,65.06-74.26Z"
              />
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M729.34,504.07c-7.06-.89-18.59-1.95-25.66-2.7-1.6-.17-4.76-.06-5.02,1.71-.23,1.58,2.46,2.46,3.87,2.74,16.38,3.19,32.69,7.58,49.24,8.89,21.32,1.68,42.72-.3,62.79-8.88,4.84-2.06,18.49-16.91,6.89-12.46-20.76,7.97-52.84,15.62-92.11,10.69Z"
              />
            </G>
            <G>
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M315.49,511.26c-6.1,2.58-31.79,15.08-65.84,15.01-11.87-.02-34.18-5.56-46.22-10.94-2.04-.91-5.72-1.13-6.64.91-.96,2.16,2.34,4.27,4.06,5.77,1.83,1.6,4.22,2.78,6.56,3.55,27.43,8.97,39.28,13.04,82.52.55,6.26-1.81,31.56-17.4,25.56-14.86Z"
              />
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M354.78,474.94c.78,1.97.85,6.74,3.11,6.48,4.78-.55,4.26-17.62,3.04-23.39-4.8-22.82-16.9-46.42-33.68-64.99-25.54-28.26-55.64-41.61-95.12-42.71-9.13-.25-20.86-.41-30.48,2.22-28.05,7.68-51.05,23.07-66.98,47.71-12.5,19.33-17.67,39.89-5.64,61.58,4.65,8.4,9.5,16.82,15.34,24.39,4.26,5.51,10.22,9.76,15.66,14.27.8.66,3.04-.08,4.4-.66.34-.14.21-2.45-.35-3.37-1.29-2.14-3.01-4.01-4.5-6.03-10.73-14.53-16.69-30.69-17.36-48.88-1.33-36.25,30.43-58.57,54.66-66.69,12.3-4.12,38.46-10.23,63.05-4.65,60.63,13.74,74.14,48.85,88.43,81.04,2.54,5.73,4.11,17.86,6.42,23.69Z"
              />
            </G>
          </G>
        </G>
        <G>
          <G>
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M900.44,294.31c-3.37-1.69-7.62-4.38-11.07-6.57-4.21-2.66-8.45-5.34-12.11-7.14-49.48-23.91-104.04-36.41-162.17-37.13l-4.21-.03c-31.37,0-59.75,3.59-84.34,10.65-4.54,1.31-11.56,4.64-18.34,7.85-3.87,1.84-8.13,3.86-10.8,4.92.23-1.61.81-3.89,1.63-6.09,13.88-37.25,53.04-64.89,95.22-67.23,3.46-.19,6.9-.29,10.31-.29,38.29,0,75.37,11.44,113.37,35,30.23,18.64,51.92,33.77,73.3,55.95,2.46,2.34,6.47,6.68,9.2,10.11Z"
            />
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M112.7,308.47c1.18-2.96,2.72-6.34,3.45-7.5,20.6-33.23,65.65-68.98,115.89-68.98,11.5,0,22.83,1.91,33.68,5.68,25.26,8.9,41.77,23.86,50.48,45.68,2.77,6.53,2.05,8.23,1.61,8.67-.33.31-1,.51-1.92.51-1.25,0-3.5-.36-7.14-2.03-24.85-11.47-45.28-17.68-82.07-17.68h-2.49c-1.68-.1-3.66-.16-5.68-.16-28.06,0-65.61,11.77-98,30.73-.85.49-2.03,1.3-3.36,2.18-1.04.71-2.78,1.9-4.45,2.91Z"
            />
          </G>
          <G>
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M595.84,271.03c-3.8.68-1.3-8.13-.04-11.51,14.49-38.99,55.46-67.14,98.3-69.48,46.21-2.52,87.74,11.71,125.73,35.21,28.84,17.84,51.62,33.34,74.01,56.47,2.55,2.63,15.93,16.37,12.44,17.48-5.39,1.73-20.32-10.45-30.6-15.44-50.6-24.57-104.72-36.17-160.56-36.76-29.17-.31-59.3,2.31-87.57,10.42-8.29,2.38-27.05,12.77-31.7,13.61ZM617.01,246.3c81.31-23.26,158.05-11.13,234.48,11.93-10.08-7.71-20.17-14.91-31.1-20.52-46.31-23.73-93.31-44.51-147.91-32.14-23.71,5.37-41.5,17.82-55.47,40.72Z"
            />
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M224.23,276.29c-28.91-1.84-69,10.83-102.02,30.11-3.25,1.9-11.62,8.65-14.3,7.24-1.25-.66,3.94-12.31,5.28-14.48,26.74-43.15,90.46-86.79,153.63-64.73,24.24,8.47,42.93,23.47,52.63,47.58,1.49,3.69,3.73,9.56.76,12.52s-9.29.84-12.93-.84c-25.45-11.75-45.69-17.7-83.06-17.4ZM309.37,283.16c-6.69-15.93-24.58-32-40.17-37-48.4-15.52-87.77,2.37-123.7,32.67-.16.14-9.84,7.31-9.49,8.08,84.64-29.61,118.5-25.62,173.36-3.75Z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
FaceStyleTwoSvg.displayName = 'FaceStyleTwoSvg';
