import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  SreConfigAction,
  SreConfigActionType,
  SreConfigureSuccessAction,
  SreConfigureValidatedAction
} from '../redux';
import { ISreDependencies } from './sre-epics.model';

export function configureSreValidatedEpic(
  action$: Observable<SreConfigureValidatedAction>,
  _state$: StateObservable<unknown>,
  deps: ISreDependencies
): Observable<SreConfigureSuccessAction> {
  return action$.pipe(
    ofType(SreConfigActionType.ConfigureValidated),
    mergeMap(async () => {
      const config = await deps.sre.configure();

      return SreConfigAction.configure.success(config);
    })
  );
}
configureSreValidatedEpic.displayName = 'configureSreValidatedEpic';
