import {
  LanguageFrameSession as LF,
  SoundLogCollection,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import { IToken } from '@lexialearning/sre';

export class LanguageFrameOptionsFactory {
  public static readonly displayName = 'LanguageFrameOptionsFactory';

  public static create(
    tokens: Partial<IToken>[],
    soundLogCollectionMode: SoundLogCollection,
    parameters?: Partial<LF.IScoringParameters>
  ): LF.IConfig {
    return {
      scoring: {
        strategy: LF.ScoringStrategy.Rubric,
        threshold: LF.SCORING_SRE_THRESHOLD_DEFAULT,
        ...parameters
      },
      sessionType: SreSessionType.LanguageFrame,
      soundLogCollectionMode,
      tokens: tokens
        .filter(t => t.text !== '?') // see spec
        .map(t => ({
          text: t.text!,
          weight: t.weight!,
          wordType: t.wordType!,
          ...t
        }))
    };
  }
}
