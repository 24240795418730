import { ActionType, createAction } from 'typesafe-actions';
import { IMeterState } from '../../progress-meters.model';
import { RoundMeterActionType } from './RoundMeterActionType';

export const RoundMeterAction = {
  updated: createAction(RoundMeterActionType.Updated)<IMeterState>()
};

export type RoundMeterActions = ActionType<typeof RoundMeterAction>;
export type RoundMeterUpdatedAction = ActionType<
  typeof RoundMeterAction.updated
>;
