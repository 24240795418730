import {
  IModeling,
  ModelingActionType,
  ModelSection
} from '@lexialearning/lobo-common/main-model/tasks';
import { ActionType, createAction } from 'typesafe-actions';

export const ModelingAction = {
  highlight: createAction(
    ModelingActionType.Highlight
  )<IModelingActionPayload>(),
  setModeling: createAction(
    ModelingActionType.SetModeling
  )<IModelingActionSetModelingPayload>(),
  unhighlight: createAction(
    ModelingActionType.Unhighlight
  )<IModelingActionPayload>()
};

export interface IModelingActionPayload {
  modelItemId: string;
  modelSection: ModelSection;
}

export type IModelingActionSetModelingPayload = IModeling;

export type ModelingActions = ActionType<typeof ModelingAction>;
