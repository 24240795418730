import { useRef, useState } from 'react';

/**
 * Fixes issues in usePrevious hook whereby it can use updated previous value
 * before current value has changed
 * Modified from
 * https://www.developerway.com/posts/implementing-advanced-use-previous-hook
 * @param value
 * @returns previous value
 */
export const usePreviousPersistent = <T>(value: T) => {
  const [previous, setPrevious] = useState<T | undefined>(undefined);
  const ref = useRef(value);

  // if the value passed into hook doesn't match our ref,
  // set "previous" to the ref.current value
  // and update the ref.current value to the new value
  if (value !== ref.current) {
    setPrevious(ref.current);

    ref.current = value;
  }

  return previous;
};
