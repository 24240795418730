import { Circle, Defs, RadialGradient, Stop, Svg } from 'common-ui';
import { Color } from 'common-styles';
import { uniqueId } from 'lodash';

export function PipCompletedRingSvg() {
  const gradientId = uniqueId('pip_completed_ring');

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
      <Defs>
        <RadialGradient
          id={gradientId}
          cx=".5"
          cy=".5"
          r=".5"
          fx=".5"
          fy=".5"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor={Color.White} stopOpacity="0" />
          <Stop offset="0.764" stopColor={Color.White} stopOpacity=".004" />
          <Stop offset="0.835" stopColor={Color.White} stopOpacity=".42" />
          <Stop offset="0.866" stopColor={Color.White} />
          <Stop offset="0.9" stopColor={Color.White} />
          <Stop offset="0.932" stopColor={Color.White} stopOpacity=".42" />
          <Stop offset="1" stopColor={Color.White} stopOpacity="0" />
        </RadialGradient>
      </Defs>
      <Circle cx="9" cy="9" r="9" fill={`url(#${gradientId})`} />
    </Svg>
  );
}

PipCompletedRingSvg.displayName = 'PipCompletedRingSvg';
