import { createSelector } from '@reduxjs/toolkit';
import { IBootstrappingState } from './bootstrapping-redux.model';

export class BootstrappingSelector {
  public static readonly displayName = 'BootstrappingSelector';

  public static isBootstrapped: (state: unknown) => boolean;

  public static isConfigurationComplete: (state: unknown) => boolean;

  public static createSelectors(
    selector: (state: any) => IBootstrappingState
  ): void {
    this.isBootstrapped = createSelector(
      selector,
      (state: IBootstrappingState) =>
        state.isAutoLoginComplete &&
        state.isConfigurationComplete &&
        state.isDeviceSetupComplete &&
        state.isFeatureAreaBootstrapContentLoadingComplete &&
        state.isDeepLinkPositionSetupComplete
    );
    this.isConfigurationComplete = createSelector(
      selector,
      (state: IBootstrappingState) => state.isConfigurationComplete
    );
  }
}
