import { Color } from 'common-styles/Color';
import { G, Path, Svg } from 'common-ui/components/svg';

export interface ICheckMarkSvgProps {
  accessibilityLabel?: string;
}

export function CheckMarkSvg(props: ICheckMarkSvgProps) {
  const { accessibilityLabel } = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={40}
      viewBox="0 0 60 40"
      accessibilityLabel={accessibilityLabel}
      data-test-id={CheckMarkSvg.displayName}
    >
      <G id="checkMark" transform="translate(-172 -132)">
        <Path
          id="checkmark"
          d="M502.864,571.13a3.039,3.039,0,0,1-2.25.98,3.111,3.111,0,0,1-2.211-.981l-8.886-8.885a3.264,3.264,0,1,1,4.616-4.615l6.5,6.5,13.539-13.539a3.112,3.112,0,0,1,2.212-.981,3.64,3.64,0,0,1,3.385,3.385,3.109,3.109,0,0,1-.981,2.211Z"
          transform="translate(-300 -409)"
          fill={Color.Blue}
        />
      </G>
    </Svg>
  );
}
CheckMarkSvg.displayName = 'CheckMarkSvg';
