import { G, Path, Svg } from 'common-ui';

export function Pick4Svg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={75.501}
      height={35.896}
      accessibilityLabel="Pick four."
    >
      <G fill="#455b5b">
        <Path d="M7.31 1.68a7.152 7.152 0 015.2 1.929 6.061 6.061 0 012.1 4.781 6.51 6.51 0 01-2.013 4.948 7.166 7.166 0 01-5.116 1.929H4.207v4.781a2.026 2.026 0 01-2.1 2.1A2.03 2.03 0 01.016 19.88V3.858A2.05 2.05 0 012.278 1.68zm-.168 9.81a2.888 2.888 0 002.935-3.019 2.822 2.822 0 00-2.851-3.019H4.207v6.038z" />
        <Path d="M16.369 4.446a2.112 2.112 0 01-.755-1.677 2.348 2.348 0 112.348 2.351 2.639 2.639 0 01-1.594-.671zm3.69 15.516a2.105 2.105 0 01-2.013 2.264 2.055 2.055 0 01-2.013-2.264V8.891a2.16 2.16 0 012.013-2.264 2.055 2.055 0 012.013 2.264z" />
        <Path d="M32.052 10.903l-.755-.252a6.725 6.725 0 00-1.761-.419c-2.1 0-3.439 1.761-3.439 4.193a5.084 5.084 0 00.923 2.935 3.1 3.1 0 002.516 1.258 6.407 6.407 0 001.761-.419 8.981 8.981 0 011.342-.419 1.83 1.83 0 011.761 1.762c0 .839-.5 1.51-1.594 2.013a8.3 8.3 0 01-3.439.839 6.978 6.978 0 01-5.279-2.266 7.727 7.727 0 01-2.1-5.535 8.276 8.276 0 012.1-5.7 6.771 6.771 0 015.28-2.351 7.782 7.782 0 013.523.755c1.006.5 1.51 1.09 1.51 1.845a1.793 1.793 0 01-1.594 1.929c-.252-.084-.504-.084-.755-.168z" />
        <Path d="M40.187 11.909l4.36-4.193a2.788 2.788 0 012.013-1.09 1.845 1.845 0 011.761 1.845c0 .5-.084 1.006-1.006 1.761l-3.439 3.1 4.781 5.619a2.264 2.264 0 01.59 1.429 1.9 1.9 0 01-1.929 1.929 2.845 2.845 0 01-2.013-1.258l-4.948-6.122h-.084v5.032a2.105 2.105 0 01-2.013 2.264 2.055 2.055 0 01-2.013-2.264v-17.7A2.105 2.105 0 0138.258 0a2.055 2.055 0 012.013 2.264v9.645z" />
        <Path d="M59.904 3.526a2.077 2.077 0 012.177-2.348 2.118 2.118 0 012.181 2.348v10.567h4.277V3.526a2.077 2.077 0 012.181-2.348 2.077 2.077 0 012.184 2.348v10.567h.5a1.959 1.959 0 012.1 2.013 2.007 2.007 0 01-2.1 2.013h-.5v2.6c0 1.594-.671 2.348-2.181 2.348s-2.181-.755-2.181-2.348v-2.6h-6.377a2.181 2.181 0 01-2.261-2.348z" />
        <Path d="M12.161 33.129a2.768 2.768 0 112.768 2.768 2.738 2.738 0 01-2.768-2.768z" />
        <Path d="M27.929 33.129a2.768 2.768 0 112.768 2.768 2.738 2.738 0 01-2.768-2.768z" />
        <Path d="M43.696 33.129a2.768 2.768 0 112.768 2.768 2.768 2.768 0 01-2.768-2.768z" />
        <Path d="M59.463 33.129a2.768 2.768 0 112.768 2.768 2.768 2.768 0 01-2.768-2.768z" />
      </G>
    </Svg>
  );
}
Pick4Svg.displayName = 'Pick4Svg';
