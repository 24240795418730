import { IEncounterEducator } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { Image, Text, View } from 'common-ui';
import { TabButton } from 'common-ui/components/button/tab-button/TabButton';
import { ActivityPositionAction } from 'services/curriculum-services';
import { EncounterTabStyles } from './EncounterTab.styles';

export interface IEncounterTabProps {
  autoFocus?: boolean;
  disabled: boolean;
  encounter: IEncounterEducator;
  encounterNumber: number;
  selected: boolean;
  selectEncounter(encounterId: string): void;
  tabPanelId: string;
}

export class EncounterTabComponent extends React.PureComponent<IEncounterTabProps> {
  public static readonly displayName = 'EncounterTab';

  constructor(props: IEncounterTabProps) {
    super(props);
    this.handleEncounterSelection = this.handleEncounterSelection.bind(this);
  }

  private handleEncounterSelection() {
    this.props.selectEncounter(this.props.encounter.sysId);
  }

  public render() {
    const {
      autoFocus,
      disabled,
      encounter,
      encounterNumber,
      selected,
      tabPanelId
    } = this.props;
    const styles = EncounterTabStyles.get();

    return (
      <TabButton
        autoFocus={autoFocus}
        disabled={disabled}
        selected={selected}
        onTabPress={this.handleEncounterSelection}
        tabId={encounter.sysId}
        tabPanelId={tabPanelId}
      >
        <View style={styles.badge}>
          <Image
            style={styles.badgeImage}
            source={encounter.badge.imageSource}
            accessibilityLabel={encounter.badge.altText}
            importantForAccessibility={
              ImportantForAccessibility.NoHideDescendants
            }
          />
        </View>
        <Text style={styles.encounterLabel}>Encounter {encounterNumber}</Text>
      </TabButton>
    );
  }
}

const mapDispatchToProps = {
  selectEncounter: (encounterId: string) =>
    ActivityPositionAction.encounterSelected({ encounterId })
};

export const EncounterTab = connect(
  undefined,
  mapDispatchToProps
)(EncounterTabComponent);

export const EncounterTabPrivates = { mapDispatchToProps };
