import * as React from 'react';
import { AnimatedView, Image, Types } from 'common-ui';
import { BackgroundGradientSvg } from 'feature-areas/shell';
import SplashMeiPng from './assets/SplashMei.png';
import { SplashScreenAnimatedStyles } from './SplashScreen.animated-styles';

export interface ISplashScreenProps {
  isVisible?: boolean;
  styleOverride?: Types.ViewStyle;
}

export interface ISplashScreenState {
  isHidden: boolean;
}

export class SplashScreen extends React.PureComponent<
  ISplashScreenProps,
  ISplashScreenState
> {
  public static readonly displayName = 'SplashScreen';

  private readonly animatedStyles: SplashScreenAnimatedStyles;

  constructor(props: ISplashScreenProps) {
    super(props);

    this.state = {
      isHidden: false
    };
    this.animatedStyles = new SplashScreenAnimatedStyles();
  }

  public componentDidUpdate(prevProps: ISplashScreenProps) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this.animatedStyles.getAnimations().hide.start(() => {
        this.setState({ isHidden: true });
      });
    }
  }

  public render() {
    const styles = this.animatedStyles.get();

    if (this.state.isHidden) {
      return null;
    }

    return (
      <AnimatedView
        style={{
          ...styles.container,
          ...this.props.styleOverride
        }}
        animatedStyle={styles.containerAnimated}
        testId={SplashScreen.displayName}
      >
        <BackgroundGradientSvg id={SplashScreen.displayName} />
        <Image
          style={styles.image}
          source={SplashMeiPng}
          accessibilityLabel="Welcome to Lexia English."
        />
      </AnimatedView>
    );
  }
}
