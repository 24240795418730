import { IScreenplay } from '@lexialearning/lobo-common';
import { ScreenplayBuilder } from 'screenplay';
import { TransitionScreenplayId } from '../transition.model';
import { AvatarEditorIntroScreenplayBuilder } from '../builders/avatar-editor/AvatarEditorIntroScreenplayBuilder';

export class IntroductionToAvatarEditorTransitionScreenplayFactory {
  public static readonly displayName =
    'IntroductionToAvatarEditorTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static create(): IntroductionToAvatarEditorTransitionScreenplayFactory {
    return new IntroductionToAvatarEditorTransitionScreenplayFactory();
  }

  private constructor() {
    this.screenplay = this.buildAvatarEditorScreenplay();
  }

  private buildAvatarEditorScreenplay(): IScreenplay {
    return ScreenplayBuilder.create(
      TransitionScreenplayId.IntroductionToAvatarEditor
    ).addScreenplay(
      AvatarEditorIntroScreenplayBuilder.create()
        .setTimerDuration()
        .navToAndShowAvatarEditor()
        .playAvatarIntroduction()
        .addDelay(300)
        .showInstructionalModal()
        .addInstructionalGifUserEvent().screenplay
    ).screenplay;
  }
}
