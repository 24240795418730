import { ImageStyle as CuiImageStyle } from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { loboImageStyle } from 'common-styles';

export class ImageStyles {
  public static readonly build = memoizeOne((style?: CuiImageStyle) =>
    loboImageStyle({
      height: 220,
      width: 220,
      ...style
    })
  );
}
