import { CommonUiSelector, MicSelector } from 'common-ui';
import { IPreparedTaskSelector } from 'curriculum-services/program-context/context-factories/RoundContextFactory';
import { DndSelector } from 'dnd/redux';
import { ActUiSelector } from 'feature-areas/acts';
import { CalibrationUiSelector } from 'feature-areas/calibration';
import { DevToolsSelector } from 'feature-areas/dev-tools/redux/DevTools.selector';
import { EducatorSelector } from 'feature-areas/educator';
import { AppShellSelector } from 'feature-areas/shell';
import { taskRegistry } from 'feature-areas/tasks/lobo-task-registry';
import { LexiaServiceSelector } from 'lexia-service';
import { ScreenplayReduxProvider } from 'screenplay';
import { SreSelector } from 'sre';
import { StormSelector } from 'storm';
import { TaskSelector } from 'task-components';
import { SeeSpeakSelector } from 'task-components/see-speak';
import { ThemeSelector } from 'theme';
import { AuthSelector } from '../auth';
import {
  LevelSelector,
  PlacementSelector,
  ProgramContextSelector,
  ProgressSelector,
  RoundContextFactory,
  UnitSelector
} from '../curriculum-services';
import { CustomerSelector } from '../customer';
import { ProfileSelector } from '../profile';
import { BootstrapContentSelector } from './bootstrap-content';
import { BootstrappingSelector } from './redux';
import { AppState } from './redux/root.reducer';
import { AvatarEditorSelector } from 'feature-areas/avatar-editor/redux';

export class SelectorsFactory {
  public static readonly displayName = 'SelectorsFactory';

  /**
   * Invoke selector creators on each slice of the state, passing them their corresponding
   * top level selector, so they need not know where in the state tree they reside.
   */
  public static create(): void {
    AppShellSelector.createSelectors((state: AppState) => state.shell);
    AuthSelector.createSelectors((state: AppState) => state.auth);
    AvatarEditorSelector.createSelectors(
      (state: AppState) => state.avatarEditor
    );
    BootstrappingSelector.createSelectors(
      (state: AppState) => state.bootstrapping
    );
    BootstrapContentSelector.createSelectors(
      (state: AppState) => state.bootstrapContent
    );
    CustomerSelector.createSelectors((state: AppState) => state.customer);
    CommonUiSelector.createSelectors((state: AppState) => state.commonUi);
    DevToolsSelector.createSelectors((state: AppState) => state.devTools);
    DndSelector.createSelectors((state: AppState) => state.dnd);
    EducatorSelector.createSelectors((state: AppState) => state.educator);
    LexiaServiceSelector.createSelectors(
      (state: AppState) => state.lexiaService
    );
    LevelSelector.createSelectors((state: AppState) => state.level);
    MicSelector.createSelectors((state: AppState) => state.mic);
    PlacementSelector.createSelectors((state: AppState) => state.placement);
    ProfileSelector.createSelectors((state: AppState) => state.profile);
    ProgressSelector.createSelectors();
    ScreenplayReduxProvider.selector.createSelectors(
      (state: AppState) => state.screenplay
    );
    SreSelector.createSelectors((state: AppState) => state.sre);
    StormSelector.createSelectors((state: AppState) => state.storm);
    TaskSelector.createSelectors((state: AppState) => state.task);
    UnitSelector.createSelectors((state: AppState) => state.unit);

    // needs to be last since it depends on others
    CalibrationUiSelector.createSelectors(
      (state: AppState) => state.calibrationUi
    );

    const taskSelector: IPreparedTaskSelector = {
      getTask: TaskSelector.getTaskContent,
      getTaskMaybe: TaskSelector.getTaskContentMaybe
    };
    const programContextFactory = new RoundContextFactory(
      taskSelector,
      taskRegistry
    );
    ProgramContextSelector.createSelectors(
      (state: AppState) => state.programContext,
      programContextFactory
    );
    ActUiSelector.createSelectors((state: AppState) => state.actUi);

    SeeSpeakSelector.createSelectors((state: AppState) => state.seeSpeak);
    ThemeSelector.createSelectors(
      (state: AppState) => state.theme,
      ProgramContextSelector.isOnboardingOrPlacement,
      UnitSelector.getInstructionalStep,
      UnitSelector.getType
    );
  }
}
