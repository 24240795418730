export class SsoButtonStyles {
  private static readonly styles = {
    button: {
      marginTop: 100
    }
  };

  public static get() {
    return this.styles;
  }
}
