import { IUnitEducator } from '@lexialearning/lobo-common';
import { BackgroundImage, PressScaleButton, Row, Text, View } from 'common-ui';
import unit_shadowPng from '../../../images/shadows/unit_shadow.png';
import { UnitButtonStyles } from './UnitButton.styles';
import { useCallback } from 'react';
import { useActiveComponentState } from '@lexialearning/common-ui';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';

export interface IUnitButtonProps extends Omit<IButtonBaseProps, 'onPress'> {
  unitNumber: number;
  unit: IUnitEducator;
  onPress(unit: IUnitEducator): void;
  disabled: boolean;
}

export function UnitButton(props: IUnitButtonProps) {
  const { disabled, onPress, unit, unitNumber, ...restProps } = props;

  const handleUnitPress = useCallback(() => {
    onPress(unit);
  }, [onPress, unit]);

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = UnitButtonStyles.build(activeComponentState);

  return (
    <PressScaleButton
      {...restProps}
      {...interactionHandlers}
      hoverScale={1.02}
      style={styles.unitButton}
      onPress={handleUnitPress}
      disabled={disabled}
      testId={UnitButton.displayName}
    >
      <BackgroundImage style={styles.shadow} source={unit_shadowPng} />
      <Row style={styles.container} testId={`${UnitButton.displayName}_inner`}>
        <View style={styles.badgeCircle}>
          <Text style={styles.badgeText}>{unitNumber}</Text>
        </View>
        <Text style={styles.unitLabel}>{unit.academicType}</Text>
      </Row>
    </PressScaleButton>
  );
}
UnitButton.displayName = 'UnitButton';
