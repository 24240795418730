declare const __VERSION_MAJOR__: string;
declare const __VERSION_MINOR__: string;
declare const __VERSION_PATCH__: string;
declare const __VERSION_BUILD__: string;
declare const __VERSION_BRANCH__: string;

export class Version {
  public static readonly Major = __VERSION_MAJOR__;

  public static readonly Minor = __VERSION_MINOR__;

  public static readonly Patch = __VERSION_PATCH__;

  public static readonly Branch = __VERSION_BRANCH__;

  public static readonly Build = __VERSION_BUILD__;

  public static readonly Main = `${Version.Major}.${Version.Minor}.${Version.Patch}`;

  public static readonly All = `${Version.Main} - ${Version.Branch}`;

  public static readonly Number = `${Version.Main}.${Version.Build}`;
}
