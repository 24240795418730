import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { IMicrophone } from '@lexialearning/lobo-common/main-model/sre';
import * as React from 'react';
import { Column } from 'common-ui';
import {
  CalibrationModal,
  CalibrationStep,
  CalibrationUiAction
} from 'feature-areas/calibration';
import { AppShellAction } from 'feature-areas/shell';
import { IPlayActionPayload, ScreenplayAction } from 'screenplay/redux';
import { RouterService } from 'services/router-service';
import { MicSelection } from '../mic-selection/MicSelection';
import { MicSelectionModalStyles } from './MicSelectionModal.styles';

export interface IMicSelectionModalProps {
  isStudent: boolean;
  openingVO: IScreenplay;
  selectedMic?: IMicrophone;
  close(): void;
  hideModal(): void;
  prepareCalibrationModal(): void;
  openCalibrationModal(): void;
  playScreenplay(payload: IPlayActionPayload): void;
}

/**
 * Component used by both MicChangedModal & ChangeMicModal
 * to connect to redux for props necessary for selecting
 * a new microphone in a modal.
 * This allows the modal to have different headers, different opening Voiceovers
 * and different modal types (error, dismissible)
 */
export class MicSelectionModal extends React.PureComponent<IMicSelectionModalProps> {
  public static displayName = 'MicSelectionModal';

  private readonly initialMic?: IMicrophone;

  constructor(props: IMicSelectionModalProps) {
    super(props);

    this.initialMic = props.selectedMic;
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  public componentDidMount() {
    const { openingVO, playScreenplay } = this.props;
    playScreenplay({ screenplay: openingVO });
  }

  private handleRequestClose() {
    const { isStudent, selectedMic } = this.props;
    const isLogin = RouterService.isOnLoginRoute();

    this.props.close();
    if (isStudent && !isLogin && selectedMic !== this.initialMic) {
      this.props.hideModal();
      // TODO: Remove setTimeout here once Numfum resolves: LOBO-18444
      setTimeout(() => {
        this.props.prepareCalibrationModal();
        this.props.openCalibrationModal();
      }, 0);
    }
  }

  public render() {
    const styles = MicSelectionModalStyles.get();

    return (
      <Column style={styles.container}>
        <MicSelection onOkPress={this.handleRequestClose} />
      </Column>
    );
  }

  // istanbul ignore next - trivial code, not worth testing
  public static mapDispatchToProps = {
    close: () => AppShellAction.hideModal(),
    hideModal: () => AppShellAction.hideModal(),
    openCalibrationModal: () =>
      AppShellAction.showModal({ id: CalibrationModal.ModalId }),
    playScreenplay: (payload: IPlayActionPayload) =>
      ScreenplayAction.play(payload),
    prepareCalibrationModal: () =>
      CalibrationUiAction.setStep({ step: CalibrationStep.Interactive })
  };
}
