import {
  ModelingActionType,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import {
  UserGlobalActionLogout,
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  LevelActionType,
  LevelActionUnload,
  PositionActionActivityDeselected,
  PositionActionType,
  UnitActionLoad,
  UnitActionType
} from 'curriculum-services';
import { ModelingActions } from '../modeling/Modeling.action';
import { modelingReducer } from '../modeling/modeling.reducer';
import { selectedChoicesReducer } from './selectedChoices.reducer';
import { ITaskState, TaskActionType } from './task-redux.model';
import { TaskActions } from './Task.action';
import {
  AppShellActionNavigateBack,
  AppShellActionType
} from 'feature-areas/shell';

const taskInitialState: ITaskState = {
  canSubmit: false,
  modeling: undefined,
  phase: TaskPhase.None,
  roundId: undefined,
  selectedChoices: undefined,
  showSolution: false,
  taskContent: undefined
};

type InputActions =
  | TaskActions
  | ModelingActions
  | UnitActionLoad
  | LevelActionUnload
  | PositionActionActivityDeselected
  | AppShellActionNavigateBack
  | UserGlobalActionLogout
  | UserGlobalActionLogoutSuccess;

export function taskReducer(
  state = taskInitialState,
  action: InputActions
): ITaskState {
  switch (action.type) {
    case TaskActionType.Entry:
      return { ...state, phase: TaskPhase.Entry };

    case TaskActionType.Evaluated:
      return {
        ...state,
        phase: TaskPhase.Interactive
      };

    case AppShellActionType.NavigateBack:
    case UserGlobalActionType.Logout:
    case TaskActionType.Exit:
      return {
        ...state,
        phase: TaskPhase.Exit
      };

    case TaskActionType.Feedback:
      return {
        ...state,
        phase: TaskPhase.Feedback
      };

    case ModelingActionType.Highlight:
    case ModelingActionType.Unhighlight:
    case ModelingActionType.SetModeling:
      return { ...state, modeling: modelingReducer(state.modeling, action) };

    case TaskActionType.Interactive:
      return {
        ...state,
        phase: TaskPhase.Interactive
      };

    case TaskActionType.Loaded:
      return {
        ...state,
        ...action.payload,
        canSubmit: false,
        phase: TaskPhase.None,
        selectedChoices: []
      };

    case TaskActionType.Preamble:
      return {
        ...state,
        phase: TaskPhase.Preamble
      };

    case TaskActionType.Solution:
      return {
        ...state,
        phase: TaskPhase.Solution,
        selectedChoices: []
      };

    case TaskActionType.ShowSolution:
      return {
        ...state,
        showSolution: true
      };

    case TaskActionType.SolutionComplete:
      return {
        ...state,
        showSolution: false
      };

    case TaskActionType.UpdateCanSubmit:
      return {
        ...state,
        canSubmit: action.payload.canSubmit
      };

    case TaskActionType.ClearSelectedChoices:
    case TaskActionType.SetSelectedChoice:
    case TaskActionType.ToggleSelectedChoice:
      return {
        ...state,
        selectedChoices: selectedChoicesReducer(state.selectedChoices, action)
      };

    case UnitActionType.Load:
    case LevelActionType.Unload:
    case PositionActionType.ActivityDeselected:
    case UserGlobalActionType.LogoutSuccess:
      return taskInitialState;

    default:
      return state;
  }
}
taskReducer.displayName = 'taskReducer';

export const TaskReducerPrivates = { initialState: taskInitialState };
