import {
  ActiveComponentState,
  Border,
  Position
} from '@lexialearning/common-ui';
import { Align, Color, Font, Justify } from 'common-styles';

export class UnitButtonStyles {
  private static readonly styles = {
    badgeCircle: {
      backgroundColor: Color.Clementine,
      borderRadius: 15,
      height: 30,
      marginLeft: 10,
      marginRight: 15,
      width: 30
    },
    badgeText: {
      fontFamily: Font.Family.semiBold,
      fontSize: 16
    },
    shadow: {
      height: 64,
      position: Position.Absolute,
      top: -10,
      width: 384
    },
    unitButton: {
      alignItems: Align.Center,
      height: 40,
      justifyContent: Justify.Center,
      marginBottom: 5,
      width: 360
    },
    unitLabel: {
      fontSize: 16,
      marginHorizontal: 5
    }
  };

  public static build(activeComponentState: ActiveComponentState) {
    return {
      ...this.styles,
      container: {
        backgroundColor: Color.NearWhite,
        borderColor: Color.getSetFor(activeComponentState).border,
        borderRadius: 10,
        borderStyle: Border.Solid,
        borderWidth: 5,
        height: 40,
        justifyContent: Justify.Start,
        width: 360
      }
    };
  }
}
