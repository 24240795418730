// Api Documentation here: https://jira.lexialearning.com/wiki/display/ELKMK/myLexia+Integration

export interface ILexiaError {
  code: number;
  message: string | null;
  severity: string | null;
  __type__: 'Error';
}

export enum LexiaProduct {
  Lobo = 'ell'
}

export interface IStudentApiRequestBase {
  authToken: string;
  studentId: number;
  version: string;
}
