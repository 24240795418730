import { AppShellSelector } from 'feature-areas/shell';
import { AppState } from 'services';
import { IGetInteractionStateArgs, SeeSpeakHelper } from '../SeeSpeak.helper';
import { InteractionState } from 'common-styles';
import {
  AnimationEndState,
  LanguageFrameMeter,
  useButtonMeterTransitions
} from 'shared-components/vu-meter';
import { SeeSpeakAction } from '../redux/SeeSpeak.action';
import {
  ISreButtonMeterAnimations,
  SreButtonMeterAnimatedStyles
} from './SreButtonMeter.animated-styles';
import { SreMicButton } from '../sre-mic-button';
import { SreSelector, SreSessionType } from 'sre';
import { SubmitButton } from 'task-components/shared';
import { TaskAction, TaskSelector } from 'task-components';
import { View, AnimatedView, Types } from 'common-ui';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskPhase } from '@lexialearning/lobo-common';
import { ButtonMeter } from 'shared-components/vu-meter/button-meter/ButtonMeter';

export function SreButtonMeter() {
  const { isListeningToLf, interactionState } = useSelectors();

  const { animateTransition, isAnimating, showMeter, showMic } =
    useButtonMeterTransitions();

  // DISPATCHES
  const dispatch = useDispatch();
  // istanbul ignore next - LOBO-19009 to resolve in separate PR
  const stopListening = () => dispatch(SeeSpeakAction.toggleListen());

  // STYLES AND ANIMATIONS
  const animatedStyles = useRef(new SreButtonMeterAnimatedStyles());
  const styles = animatedStyles.current.get();
  const animations = animatedStyles.current.getAnimations();
  const { showVuMeter } = animations;

  useUpdateCanSubmitEffect(isAnimating);

  useAnimationEffects(animations, animateTransition, interactionState, showMic);

  // istanbul ignore next - LOBO-19009 to resolve in separate PR
  const playShowVuMeterAnimation = () => {
    animateTransition({
      animation: showVuMeter,
      endState: AnimationEndState.ShowMeter
    });
  };

  return (
    <ButtonMeter
      showMeter={showMeter}
      showMic={showMic}
      styles={styles}
      renderBeforeMeter={() => <View style={styles.spacer1} testId="spacer1" />}
      renderMeter={
        // istanbul ignore next - LOBO-19009 to resolve in separate PR
        () => (
          <LanguageFrameMeter
            interactionState={interactionState}
            styleOverride={styles.vuMeterOverrides}
            withCaret
          />
        )
      }
      renderAfterMeter={() => (
        <>
          <View style={styles.spacer2} testId="spacer2" />
          <AnimatedView
            style={styles.submitContainer}
            animatedStyle={styles.submitContainerAnimated}
          >
            <AnimatedView
              style={styles.submitOpacity}
              animatedStyle={styles.submitOpacityAnimated}
            >
              <SubmitButton
                disabled={isAnimating}
                interactionState={interactionState}
                onPress={stopListening}
                styleOverride={styles.submitButtonOverride}
                withCallout={false}
              />
            </AnimatedView>
          </AnimatedView>
        </>
      )}
      renderMic={() => (
        <SreMicButton
          disabled={isAnimating || isListeningToLf}
          onPress={playShowVuMeterAnimation}
        />
      )}
    />
  );
}
SreButtonMeter.displayName = 'SreButtonMeter';

function useSelectors() {
  const isListeningToLf = useSelector((state: AppState) =>
    SreSelector.getIsListeningTo(SreSessionType.LanguageFrame, state)
  );
  const interactionState = useSelector((state: AppState) =>
    SeeSpeakHelper.getInteractionState(state, getInteractionState)
  );

  return {
    interactionState,
    isListeningToLf
  };
}

/**
 * Gets InteractionState used for both SubmitButton and VuMeter
 * (default return value is 'disabled', which state only applies to the button, but it is treated as the 'default' state in the VuMeter switch statement)
 */
function getInteractionState({
  feedbackInteractionState,
  isFeedbackPhase,
  isListeningToLf,
  isNeutralFeedback
}: IGetInteractionStateArgs): InteractionState {
  if (isListeningToLf) {
    return InteractionState.Default;
  }

  if (isFeedbackPhase && !isNeutralFeedback) {
    return feedbackInteractionState;
  }

  return InteractionState.Disabled;
}

function useUpdateCanSubmitEffect(isAnimating: boolean) {
  const isListeningToLf = useSelector((state: AppState) =>
    SreSelector.getIsListeningTo(SreSessionType.LanguageFrame, state)
  );

  // DISPATCHES
  const dispatch = useDispatch();
  const updateCanSubmit = useCallback(
    (canSubmit: boolean) => {
      dispatch(TaskAction.updateCanSubmit({ canSubmit }));
    },
    [dispatch]
  );

  useEffect(() => {
    updateCanSubmit(isListeningToLf && !isAnimating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListeningToLf, isAnimating]);
}

function useAnimationEffects(
  animations: ISreButtonMeterAnimations,
  animateTransition: (args: {
    animation: Types.Animated.CompositeAnimation;
    endState: AnimationEndState;
  }) => Promise<void>,
  interactionState: InteractionState,
  showMic: boolean
) {
  const isModalOpen = useSelector(AppShellSelector.isModalOpen);
  const taskPhase = useSelector(TaskSelector.getPhase);

  const { fastReset, immediateReset, reset } = animations;

  useEffect(() => {
    // istanbul ignore if - LOBO-19009 to resolve in separate PR
    if (
      [TaskPhase.Entry, TaskPhase.Interactive].includes(taskPhase) &&
      !showMic
    ) {
      animateTransition({
        animation: reset,
        endState: AnimationEndState.ShowMic
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskPhase]);

  useEffect(() => {
    if (interactionState === InteractionState.Inconclusive) {
      animateTransition({
        animation: fastReset,
        endState: AnimationEndState.ShowMic
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionState]);

  useEffect(() => {
    if (isModalOpen && taskPhase !== TaskPhase.Feedback) {
      animateTransition({
        animation: immediateReset,
        endState: AnimationEndState.ShowMic
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);
}

export enum SreButtonMeterError {
  sreInitializedTimeout = 'sreInitializedTimeout'
}
