import { ActionType, createAction } from 'typesafe-actions';

export enum ScreenplayTesterActionType {
  Play = 'SCREENPLAY_TESTER.PLAY'
}

export const ScreenplayTesterAction = {
  play: createAction(ScreenplayTesterActionType.Play)()
};

export type ScreenplayTesterActions = ActionType<typeof ScreenplayTesterAction>;
export type ScreenplayTesterActionPlay = ActionType<
  typeof ScreenplayTesterAction.play
>;
