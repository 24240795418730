import { ChoicesLayout, ICard } from '@lexialearning/lobo-common';

export interface IChoiceAnswer {
  choicesLayout: ChoicesLayout;
  selectedChoices: ICard[];
}

export class ChoiceHelper {
  public static serializeAnswer(answer: IChoiceAnswer) {
    if (!answer.selectedChoices) {
      return 'no answer provided';
    }
    const imageChoice = answer.choicesLayout === ChoicesLayout.Image;

    return answer.selectedChoices
      .map(c => `${c.sysId}:${imageChoice ? c.image?.imageSource : c.label}`)
      .join('|');
  }
}
