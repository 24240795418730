import { handleLogoutCurriculumEpic } from './epics/handleLogoutCurriculum.epic';
import { levelEpics } from './level/epics/level.epic';
import { levelsCompletedEpic } from './levels-completed/epics/levelsCompleted.epic';
import { placementEpics } from './placement/epics/placement.epic';
import { programContextEpics } from './program-context/epics/programContext.epic';
import { unitEpics } from './unit/epics/unit.epic';

export const curriculumEpics = [
  handleLogoutCurriculumEpic,
  ...levelEpics,
  ...levelsCompletedEpic,
  ...placementEpics,
  ...programContextEpics,
  ...unitEpics
];
