import { ITextButtonIconProps, Path, Svg, Types } from 'common-ui';

export interface IExternalLinkIconSvgProps extends ITextButtonIconProps {
  style?: Types.ViewStyle;
}

export function ExternalLinkIconSvg(props: IExternalLinkIconSvgProps) {
  const { color, style } = props;

  return (
    <Svg
      accessibilityLabel="External Link"
      data-test-id={ExternalLinkIconSvg.displayName}
      width="33"
      height="33"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M20.084.5H32.5v12.416h-3.584V6.601L11.508 24.01l-2.517-2.517L26.399 4.084h-6.315V.5zm8.832 28.416V16.5H32.5v12.416c0 .967-.356 1.806-1.067 2.517-.71.711-1.55 1.067-2.517 1.067H4.084a3.422 3.422 0 0 1-2.539-1.067C.848 30.723.5 29.883.5 28.916V4.084c0-.967.348-1.806 1.045-2.517A3.422 3.422 0 0 1 4.084.5H16.5v3.584H4.084v24.832h24.832z"
        fill={color}
      />
    </Svg>
  );
}
ExternalLinkIconSvg.displayName = 'ExternalLinkIconSvg';
