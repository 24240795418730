import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import out_of_licensesPng from './out_of_licenses.png';

export class OutOfLicensesModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    getTeacherVO: true,
    headline: 'Your school is out of licenses.',
    id: 'OutOfLicensesModal',
    image: out_of_licensesPng,
    text: 'Please get your teacher.',
    title: 'Sorry!',
    type: ModalType.Error
  });
}
