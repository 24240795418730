import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CalibrationScene } from 'feature-areas/calibration/calibration-scene/CalibrationScene';
import { EncounterScene } from 'feature-areas/encounters';
import { EncounterCompleteScene } from 'feature-areas/encounters/encounter-complete-scene/EncounterCompleteScene';
import { HomeScene } from 'feature-areas/home/home-scene/HomeScene';
import { IntroductionScene } from 'feature-areas/introduction/introduction-scene/IntroductionScene';
import { LevelCompleteScene } from 'feature-areas/levels/level-complete-scene/LevelCompleteScene';
import { LevelIntroScene } from 'feature-areas/level-intro/level-intro-scene/LevelIntroScene';
import { LevelScene } from 'feature-areas/levels/level-scene/LevelScene';
import { PlacementScene } from 'feature-areas/placement/placement-scene/PlacementScene';
import { ProgramCompleteScene } from 'feature-areas/program-complete';
import { TaskDemoScene } from 'feature-areas/tasks/task-demo-scene/TaskDemoScene';
import { UnitCompleteScene } from 'feature-areas/units/unit-complete-scene/UnitCompleteScene';

export class PreparedScenes {
  // #region Calibration
  public get calibration(): CalibrationScene | undefined {
    return this.calibrationSubject.value;
  }

  public set calibration(value: CalibrationScene | undefined) {
    this.calibrationSubject.next(value);
  }

  public get calibration$(): Observable<CalibrationScene | undefined> {
    return this.calibrationSubject.asObservable();
  }

  public get calibrationReady(): Promise<CalibrationScene> {
    return lastValueFrom(
      this.calibration$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.calibration!);
  }

  private readonly calibrationSubject = new BehaviorSubject<
    CalibrationScene | undefined
  >(undefined);
  // #endregion

  // #region Encounter
  public get encounter(): EncounterScene | undefined {
    return this.encounterSubject.value;
  }

  public set encounter(value: EncounterScene | undefined) {
    this.encounterSubject.next(value);
    this.encounterOrPlacementSubject.next(value);
  }

  public get encounter$(): Observable<EncounterScene | undefined> {
    return this.encounterSubject.asObservable();
  }

  public get encounterReady(): Promise<EncounterScene> {
    return lastValueFrom(
      this.encounter$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.encounter!);
  }

  private readonly encounterSubject = new BehaviorSubject<
    EncounterScene | undefined
  >(undefined);
  // #endregion

  // #region Task Demo
  public get taskDemo(): TaskDemoScene | undefined {
    return this.taskDemoSubject.value;
  }

  public set taskDemo(value: TaskDemoScene | undefined) {
    this.taskDemoSubject.next(value);
  }

  public get taskDemo$(): Observable<TaskDemoScene | undefined> {
    return this.taskDemoSubject.asObservable();
  }

  public get taskDemoReady(): Promise<TaskDemoScene> {
    return lastValueFrom(
      this.taskDemo$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.taskDemo!);
  }

  private readonly taskDemoSubject = new BehaviorSubject<
    TaskDemoScene | undefined
  >(undefined);
  // #endregion

  // #region EncounterComplete
  public get encounterComplete(): EncounterCompleteScene | undefined {
    return this.encounterCompleteSubject.value;
  }

  public set encounterComplete(value: EncounterCompleteScene | undefined) {
    this.encounterCompleteSubject.next(value);
  }

  public get encounterComplete$(): Observable<
    EncounterCompleteScene | undefined
  > {
    return this.encounterCompleteSubject.asObservable();
  }

  public get encounterCompleteReady(): Promise<EncounterCompleteScene> {
    return lastValueFrom(
      this.encounterComplete$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.encounterComplete!);
  }

  private readonly encounterCompleteSubject = new BehaviorSubject<
    EncounterCompleteScene | undefined
  >(undefined);
  // #endregion

  // #region EncounterOrPlacement

  // NOTE: Should there be a third scene potentially returned here at some point,
  // eg, EncounterOrPlacementOrNewScene, then we will likely want to change our
  // naming strategy, potentially to programModeSpecific, in that case
  // (assuming the third option is also based on ProgramMode)

  /**
   * Will only return either the Encounter or Placement scene, depending upon
   * which is the scene being used for rounds in the current ProgramMode
   * (Placement scene for Placement and Onboarding modes, Preview mode will depend upon
   *  which round the user deep-linked into, Encounter for all others)
   */
  public get encounterOrPlacement():
    | EncounterScene
    | PlacementScene
    | undefined {
    return (
      this.encounterOrPlacementSubject.value || this.placementSubject.value
    );
  }

  public get encounterOrPlacement$(): Observable<
    EncounterScene | PlacementScene | undefined
  > {
    return this.encounterOrPlacementSubject.asObservable();
  }

  /**
   * Will only return either the EncounterReady or PlacementReady, depending upon
   * which is the scene being used for rounds in the current ProgramMode
   * (Placement scene for Placement and Onboarding modes, Preview mode will depend upon
   *  which round the user deep-linked into, Encounter for all others)
   */
  public get encounterOrPlacementReady(): Promise<
    EncounterScene | PlacementScene
  > {
    return lastValueFrom(
      this.encounterOrPlacement$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.encounterOrPlacement!);
  }

  private readonly encounterOrPlacementSubject = new BehaviorSubject<
    EncounterScene | PlacementScene | undefined
  >(undefined);
  // #endregion

  // #region Home
  public get home(): HomeScene | undefined {
    return this.homeSubject.value;
  }

  public set home(value: HomeScene | undefined) {
    this.homeSubject.next(value);
  }

  public get home$(): Observable<HomeScene | undefined> {
    return this.homeSubject.asObservable();
  }

  public get homeReady(): Promise<HomeScene> {
    return lastValueFrom(
      this.home$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.home!);
  }

  private readonly homeSubject = new BehaviorSubject<HomeScene | undefined>(
    undefined
  );
  // #endregion

  // #region Introduction
  public get introduction(): IntroductionScene | undefined {
    return this.introductionSubject.value;
  }

  public set introduction(value: IntroductionScene | undefined) {
    this.introductionSubject.next(value);
  }

  public get introduction$(): Observable<IntroductionScene | undefined> {
    return this.introductionSubject.asObservable();
  }

  public get introductionReady(): Promise<IntroductionScene> {
    return lastValueFrom(
      this.introduction$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.introduction!);
  }

  private readonly introductionSubject = new BehaviorSubject<
    IntroductionScene | undefined
  >(undefined);
  // #endregion

  // #region Level
  public get level(): LevelScene | undefined {
    return this.levelSubject.value;
  }

  public set level(value: LevelScene | undefined) {
    this.levelSubject.next(value);
  }

  public get level$(): Observable<LevelScene | undefined> {
    return this.levelSubject.asObservable();
  }

  public get levelReady(): Promise<LevelScene> {
    return lastValueFrom(
      this.level$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.level!);
  }

  private readonly levelSubject = new BehaviorSubject<LevelScene | undefined>(
    undefined
  );
  // #endregion

  // #region LevelComplete
  public get levelComplete(): LevelCompleteScene | undefined {
    return this.levelCompleteSubject.value;
  }

  public set levelComplete(value: LevelCompleteScene | undefined) {
    this.levelCompleteSubject.next(value);
  }

  public get levelComplete$(): Observable<LevelCompleteScene | undefined> {
    return this.levelCompleteSubject.asObservable();
  }

  public get levelCompleteReady(): Promise<LevelCompleteScene> {
    return lastValueFrom(
      this.levelComplete$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.levelComplete!);
  }

  private readonly levelCompleteSubject = new BehaviorSubject<
    LevelCompleteScene | undefined
  >(undefined);
  // #endregion

  // #region LevelIntro
  public get levelIntro(): LevelIntroScene | undefined {
    return this.levelIntroSubject.value;
  }

  public set levelIntro(value: LevelIntroScene | undefined) {
    this.levelIntroSubject.next(value);
  }

  public get levelIntro$(): Observable<LevelIntroScene | undefined> {
    return this.levelIntroSubject.asObservable();
  }

  public get levelIntroReady(): Promise<LevelIntroScene> {
    return lastValueFrom(
      this.levelIntro$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.levelIntro!);
  }

  private readonly levelIntroSubject = new BehaviorSubject<
    LevelIntroScene | undefined
  >(undefined);
  // #endregion

  // #region Placement
  public get placement(): PlacementScene | undefined {
    return this.placementSubject.value;
  }

  public set placement(value: PlacementScene | undefined) {
    this.placementSubject.next(value);
    this.encounterOrPlacementSubject.next(value);
  }

  public get placement$(): Observable<PlacementScene | undefined> {
    return this.placementSubject.asObservable();
  }

  public get placementReady(): Promise<PlacementScene> {
    return lastValueFrom(
      this.placement$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.placement!);
  }

  private readonly placementSubject = new BehaviorSubject<
    PlacementScene | undefined
  >(undefined);
  // #endregion

  // #region ProgramComplete
  public get programComplete(): ProgramCompleteScene | undefined {
    return this.programCompleteSubject.value;
  }

  public set programComplete(value: ProgramCompleteScene | undefined) {
    this.programCompleteSubject.next(value);
  }

  public get programComplete$(): Observable<ProgramCompleteScene | undefined> {
    return this.programCompleteSubject.asObservable();
  }

  public get programCompleteReady(): Promise<ProgramCompleteScene> {
    return lastValueFrom(
      this.programComplete$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.programComplete!);
  }

  private readonly programCompleteSubject = new BehaviorSubject<
    ProgramCompleteScene | undefined
  >(undefined);
  // #endregion

  // #region UnitComplete
  public get unitComplete(): UnitCompleteScene | undefined {
    return this.unitCompleteSubject.value;
  }

  public set unitComplete(value: UnitCompleteScene | undefined) {
    this.unitCompleteSubject.next(value);
  }

  public get unitComplete$(): Observable<UnitCompleteScene | undefined> {
    return this.unitCompleteSubject.asObservable();
  }

  public get unitCompleteReady(): Promise<UnitCompleteScene> {
    return lastValueFrom(
      this.unitComplete$.pipe(
        filter(s => !!s),
        take(1)
      )
    ).then(() => this.unitComplete!);
  }

  private readonly unitCompleteSubject = new BehaviorSubject<
    UnitCompleteScene | undefined
  >(undefined);
  // #endregion
}
