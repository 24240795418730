import { Color, ShadowStyle } from 'common-styles';

export class TopicButtonStyles {
  private static readonly styles = {
    topicButton: {
      backgroundColor: Color.NearWhite,
      borderRadius: 48,
      height: 96,
      width: 96,
      ...ShadowStyle.button
    }
  };

  public static get() {
    return this.styles;
  }
}
