import {
  IActivityPosition,
  InstructionalStep,
  IRoundNode,
  IRoundReference,
  ISubunitPosition,
  ITaskAttempt,
  IUnit,
  IUnitPosition,
  IUnitSnipped,
  SubunitType,
  TaskEvaluationResult
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, last } from 'lodash';

export class ActivityPositionBuilder {
  public static readonly displayName = 'ActivityPositionBuilder';

  public static create(
    partial?: Partial<IActivityPosition>
  ): ActivityPositionBuilder {
    return new ActivityPositionBuilder({
      activityId: '',
      encounterId: '',
      isComplete: false,
      lastProgressDate: '',
      subunitPositions: [],
      unitPosition: {
        attempts: [],
        roundExposureCount: 1,
        roundId: '',
        unitId: ''
      },
      ...cloneDeep(partial)
    });
  }

  /**
   * Get the active unit or subunit position as an IUnitPosition.
   * (subunit positions will retain the xtra properties)
   */
  public get activeUnitPosition(): IUnitPosition {
    return last(this.raw.subunitPositions) ?? this.raw.unitPosition;
  }

  /**
   * Get the active unit or subunit position as an ISubunitPosition.
   * Default values will be added for properties lacking from IUnitPosition.
   */
  public get activeUnitPositionAsSubunitPosition(): ISubunitPosition {
    return (
      last(this.raw.subunitPositions) ?? {
        ...this.raw.unitPosition,
        parentRoundId: '',
        parentUnitId: '',
        subunitType: SubunitType.NotApplicable
      }
    );
  }

  public get activityPosition(): IActivityPosition {
    return this.validate()._activityPosition;
  }

  /**
   * Get activity position without any validation.
   * Use with care!
   */
  public get raw(): IActivityPosition {
    return this._activityPosition;
  }

  /**
   * Get unit position + subunit positions as IUnitPosition objects
   */
  public get unitPositions(): IUnitPosition[] {
    return [this.raw.unitPosition, ...this.raw.subunitPositions];
  }

  private constructor(private _activityPosition: IActivityPosition) {}

  public addAttempt(attempt: ITaskAttempt): ActivityPositionBuilder {
    const attempts = [...this.activeUnitPosition.attempts, cloneDeep(attempt)];

    return this.updateActiveUnitPosition({ attempts });
  }

  /**
   * Add a subunit position and reset parent unit/subunit positions (except
   * round exposure count).
   * Sets parent and round and unit ID based on existing unit position,
   * unless otherwise specified.
   */
  public addSubunitPosition(
    partial: Partial<ISubunitPosition>
  ): ActivityPositionBuilder {
    this.resetAllUnitPositions(UnitPositionResetType.RetainRoundExposureCount);
    const { roundId: parentRoundId, unitId: parentUnitId } =
      this.activeUnitPosition;

    this._activityPosition.subunitPositions = [
      ...this._activityPosition.subunitPositions,
      {
        attempts: [],
        parentRoundId,
        parentUnitId,
        roundExposureCount: 1,
        roundId: '',
        subunitType: SubunitType.NotApplicable,
        unitId: '',
        ...partial
      }
    ];

    return this;
  }

  public addSubunitPositionFrom(
    subunit: IUnit,
    parentRoundNode: IRoundNode
  ): ActivityPositionBuilder {
    const subunitType =
      subunit.instructionalStep === InstructionalStep.Instruction
        ? SubunitType.Instruction
        : parentRoundNode.content.task?.taskType;

    return this.addSubunitPosition({
      parentRoundId: parentRoundNode.content.sysId,
      parentUnitId: parentRoundNode.parent?.content.sysId,
      roundId: subunit.rounds[0]?.sysId,
      subunitType,
      unitId: subunit.sysId
    });
  }

  public appendRoundIdsToRecycle(roundIds: string[]): ActivityPositionBuilder {
    if (!roundIds.length) {
      return this;
    }

    const unitPosition = this.activeUnitPosition;

    unitPosition.recycling = {
      pass: 0,
      ...unitPosition.recycling,
      roundIdsToRecycle: [
        ...(unitPosition.recycling?.roundIdsToRecycle ?? []),
        ...roundIds
      ]
    };

    return this;
  }

  public popSubunit(): ActivityPositionBuilder {
    const subunit = this._activityPosition.subunitPositions.pop();

    if (subunit?.subunitType === SubunitType.Instruction) {
      this.activeUnitPosition.roundExposureCount += 1;
    }

    return this;
  }

  public recycle(): ActivityPositionBuilder {
    const { recycling } = this.activeUnitPosition;

    if (!recycling?.roundIdsToRecycle.length) {
      throw new LexiaError(
        'Invalid attempt to recycle unit position lacking recycling info',
        ActivityPositionBuilder.displayName,
        ActivityPositionBuilderError.RecycleOperation
      ).withContext({ activityPosition: this.raw });
    }

    return this.resetAllUnitPositions().updateActiveUnitPosition({
      recycling: { ...recycling, pass: recycling.pass + 1 },
      roundId: recycling.roundIdsToRecycle[0]
    });
  }

  public removeSubunitPositions(): ActivityPositionBuilder {
    this._activityPosition.subunitPositions = [];

    return this;
  }

  /**
   * Reset usage info for unit and subunit positions. Removes attempts,
   * attemptStartDate, placementProgress, recycling, and roundExposureCount
   * (unless otherwise specified by the optional reset type argument).
   */
  public resetAllUnitPositions(
    resetType = UnitPositionResetType.Full
  ): ActivityPositionBuilder {
    const { unitPosition, subunitPositions } = this._activityPosition;
    this._activityPosition.unitPosition = this.resetUnitPosition(
      unitPosition,
      resetType
    );
    this._activityPosition.subunitPositions = subunitPositions.map(u =>
      this.resetUnitPosition(u, resetType)
    );

    return this;
  }

  public update(partial: Partial<IActivityPosition>): ActivityPositionBuilder {
    this._activityPosition = {
      ...this._activityPosition,
      ...partial
    };

    return this;
  }

  public updateActiveUnitPosition(
    partial: Partial<IUnitPosition>
  ): ActivityPositionBuilder {
    const { subunitPositions, unitPosition } = this._activityPosition;

    if (subunitPositions.length) {
      const subunitPosition = { ...subunitPositions.pop()!, ...partial };
      subunitPositions.push(subunitPosition);
    } else {
      this._activityPosition.unitPosition = { ...unitPosition, ...partial };
    }

    return this;
  }

  /**
   * Defines the unitPosition placementProgress prop, updating the accuracy
   * and incorrectRoundIds based on the given lastAttempt.
   * (If the last attempt result is incorrect, appends the current round ID
   * to the incorrectRoundIds array)
   * Only scored rounds are counted when calculating the accuracy.
   */
  public updatePlacementProgress(
    lastAttempt: ITaskAttempt | undefined,
    unit: IUnit
  ): ActivityPositionBuilder {
    const scoredRoundCount = unit.rounds.filter(r => r.task?.isScored).length;
    if (scoredRoundCount <= 0) {
      throw new LexiaError(
        `Invalid roundCount value of ${scoredRoundCount}`,
        ActivityPositionBuilder.displayName,
        ActivityPositionBuilderError.ZeroRoundCount
      ).withContext({
        activityPosition: this._activityPosition,
        scoredRoundCount
      });
    }

    const { unitPosition } = this._activityPosition;
    const incorrectRoundIds = [
      ...(unitPosition.placementProgress?.incorrectRoundIds ?? [])
    ];

    if (incorrectRoundIds.includes(unitPosition.roundId)) {
      throw new LexiaError(
        'Invalid call: current round has already been processed',
        ActivityPositionBuilder.displayName,
        ActivityPositionBuilderError.ProgressInvalidOperation
      ).withContext({ activityPosition: this._activityPosition });
    }

    if (lastAttempt?.result === TaskEvaluationResult.Incorrect) {
      incorrectRoundIds.push(unitPosition.roundId);
    }

    const accuracy =
      ((scoredRoundCount - incorrectRoundIds.length) / scoredRoundCount) * 100;

    unitPosition.placementProgress = {
      accuracy,
      incorrectRoundIds
    };

    return this;
  }

  public validate(): ActivityPositionBuilder {
    const issues: string[] = [];
    const { activityId, encounterId, subunitPositions, unitPosition } =
      this.raw;
    const { unitPositions } = this;

    if (!activityId) {
      issues.push('ActivityId missing.');
    }

    if (!encounterId) {
      issues.push('EncounterId missing.');
    }

    if (
      unitPositions.filter(u => u.attempts.length || u.attemptStartDate)
        .length > 1
    ) {
      issues.push('More than one active unit/subunit.');
    }

    if (unitPositions.some(u => !u.unitId || !u.roundId)) {
      issues.push('Unit and/or subunit position(s) missing unit or round id.');
    }

    if (unitPositions.some(u => u.roundExposureCount <= 0)) {
      issues.push(
        'Some unit and/or subunit position(s) have an invalid roundExposureCount.'
      );
    }

    if (subunitPositions.some(u => !u.parentUnitId || !u.parentRoundId)) {
      issues.push('Some subunit(s) are missing a parent unit or round id.');
    }

    if (
      subunitPositions.some(u => u.subunitType === SubunitType.NotApplicable)
    ) {
      issues.push('Some subunit(s) have an invalid subunit type.');
    }

    if (subunitPositions.length) {
      const subunit = subunitPositions[0];
      const roundIdsMatch = unitPosition.roundId === subunit.parentRoundId;

      if (!roundIdsMatch || subunit.parentUnitId !== unitPosition.unitId) {
        issues.push('First subunit does not seem to derive from unit.');
      }

      if (subunitPositions.length > 1) {
        const parent = subunitPositions[0];
        const child = subunitPositions[1];

        if (
          parent.roundId !== child.parentRoundId ||
          child.parentUnitId !== parent.unitId
        ) {
          issues.push(
            'Second subunit does not seem to derive from first subunit.'
          );
        }
      }
    }

    if (issues.length) {
      throw new LexiaError(
        `Activity position has ${
          issues.length
        } validation issues: ${issues.join(' ')}`,
        ActivityPositionBuilder.displayName,
        ActivityPositionBuilderError.Invalid
      ).withContext({ activityPosition: this.raw, issues });
    }

    return this;
  }

  /**
   * Set active unit position as specified.
   * Subunit positions are removed and existing unit position is reset.
   */
  public withActiveUnitPosition(
    unit: IUnitSnipped,
    round?: IRoundReference,
    partial?: Partial<IUnitPosition>
  ): ActivityPositionBuilder {
    const old = this.resetUnitPosition(this._activityPosition.unitPosition);

    return this.withUnitPosition({
      ...old,
      ...partial,
      roundId: round?.sysId ?? unit.rounds?.[0]?.sysId,
      unitId: unit.sysId
    }).removeSubunitPositions();
  }

  private resetUnitPosition<T extends IUnitPosition>(
    p: T,
    resetType = UnitPositionResetType.Full
  ): T {
    const roundExposureCount =
      resetType === UnitPositionResetType.RetainRoundExposureCount
        ? p.roundExposureCount
        : 1;

    return {
      ...p,
      attemptStartDate: undefined,
      attempts: [],
      placementProgress: undefined,
      recycling: undefined,
      roundExposureCount
    };
  }

  public withUnitPosition(
    partial: Partial<IUnitPosition>
  ): ActivityPositionBuilder {
    this._activityPosition.unitPosition = {
      ...this._activityPosition.unitPosition,
      ...partial
    };

    return this;
  }
}

export enum UnitPositionResetType {
  Full,
  RetainRoundExposureCount
}

export enum ActivityPositionBuilderError {
  Invalid = 'Invalid',
  ProgressInvalidOperation = 'ProgressInvalidOperation',
  RecycleOperation = 'RecycleOperation',
  ZeroRoundCount = 'ZeroRoundCount'
}
