import { EncounterContext } from 'curriculum-services';
import { NumberUtils } from 'utils';

export class EncounterCompleteSceneHelper {
  public static getEncounterPosition(context: EncounterContext): string {
    const { act, level, levelNumber } = context;
    const actIdx = level.acts.findIndex(a => a.sysId === act.sysId);
    const encIdx = context.findEncounterIndex();

    const levelNum = NumberUtils.toTwoDigitString(levelNumber);
    const actNum = NumberUtils.toTwoDigitString(actIdx + 1);
    const encNum = NumberUtils.toTwoDigitString(encIdx + 1);

    return `l${levelNum}_a${actNum}_e${encNum}`;
  }
}
