import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  TransitionAction,
  TransitionActionAvatarToNext,
  TransitionActionIntroductionToNext
} from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { RoutePath, RouterService } from 'router-service';
import {
  AvatarEditorAction,
  AvatarEditorActionHideEditor
} from 'feature-areas/avatar-editor/redux';

export function avatarToNextTransitionEpic(
  action$: Observable<TransitionActionAvatarToNext>
): Observable<
  AvatarEditorActionHideEditor | TransitionActionIntroductionToNext
> {
  return action$.pipe(
    ofType(TransitionActionType.AvatarToNext),
    mergeMap(() => {
      const actions: (
        | AvatarEditorActionHideEditor
        | TransitionActionIntroductionToNext
      )[] = [AvatarEditorAction.hideEditor()];

      if (RouterService.isOnRoute(RoutePath.AvatarEditor)) {
        actions.push(TransitionAction.introductionToNext());
      }

      return from(actions);
    })
  );
}
avatarToNextTransitionEpic.displayName = 'avatarToNextTransitionEpic';
