import { AnimatedView, IAnimatedViewProps } from '../../view/AnimatedView';
import { RowStyles } from './Row.styles';

export function AnimatedRow(props: IAnimatedViewProps) {
  const { children, style, testId, ...viewProps } = props;

  return (
    <AnimatedView
      {...viewProps}
      style={RowStyles.build(style)}
      testId={testId || AnimatedRow.displayName}
    >
      {children}
    </AnimatedView>
  );
}
AnimatedRow.displayName = 'AnimatedRow';
