import { Overflow } from '@lexialearning/common-ui';
import { ShadowStyle } from 'common-styles';
import { UtilityButtonSharedStyles } from './utility-button-model';
import { Types } from 'common-ui';
import { SystemInfo } from '@lexialearning/utils-react';

export class UtilityButtonStyles {
  public static build(withShadow: boolean, styleOverride?: Types.ButtonStyle) {
    return {
      button: {
        ...(!SystemInfo.isNative && withShadow && ShadowStyle.button),
        borderRadius: UtilityButtonSharedStyles.button.borderRadius,
        overflow: Overflow.Visible,
        ...styleOverride
      }
    };
  }
}
