import { IScreenplay } from '@lexialearning/lobo-common';
import { find, map, switchMap } from 'rxjs/operators';
import {
  LevelAction,
  LevelActionLoad,
  LevelSelector
} from 'curriculum-services';
import { LevelIntroScreenplayBuilder } from 'feature-areas/transitions/builders/levels';
import { ScreenplayAction } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import {
  DeepLinkObservableFactoryBase,
  DeepLinkOutputAction,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';

export interface ILevelDeepLinkDeps extends IDeepLinkDeps {
  levelId: string;
}

export type LevelDeepLinkOutputAction = DeepLinkOutputAction | LevelActionLoad;

export class LevelDeepLinkObservableFactory extends DeepLinkObservableFactoryBase<ILevelDeepLinkDeps> {
  public static displayName = 'LevelDeepLinkObservableFactory';

  public readonly dispatches: LevelDeepLinkOutputAction[] = [];

  public static createFor(
    deps: ILevelDeepLinkDeps
  ): LevelDeepLinkObservableFactory {
    return new LevelDeepLinkObservableFactory(deps)
      .loadLevel()
      .thenPlayLevelIntroScreenplay();
  }

  constructor(public readonly deps: ILevelDeepLinkDeps) {
    super(deps);
  }

  private loadLevel(): LevelDeepLinkObservableFactory {
    this.dispatches.push(
      LevelAction.load.request({ sysId: this.deps.levelId })
    );

    return this;
  }

  /**
   * Monitor state changes until level is loaded.
   * Then (maybe) route to level, and play the level intro screenplay.
   */
  private thenPlayLevelIntroScreenplay(): LevelDeepLinkObservableFactory {
    const { state$ } = this.deps;
    const playLevelIntroScreenplay$ = state$.pipe(
      find(() => this.isLevelReady()),
      switchMap(async () => this.buildLevelIntroScreenplay()),
      map(screenplay => ScreenplayAction.play({ screenplay }))
    );
    this.deferredDispatches$.push(playLevelIntroScreenplay$);

    return this;
  }

  private isLevelReady(): boolean {
    const { state$, levelId } = this.deps;

    return LevelSelector.getLevelMaybe(state$.value)?.sysId === levelId;
  }

  private async buildLevelIntroScreenplay(): Promise<IScreenplay> {
    const { state$, preparedScenes } = this.deps;

    const levelScene = await preparedScenes.levelReady;
    levelScene.show();

    const levelContent = BootstrapContentSelector.getLevelScreenContent(
      state$.value
    );
    const entryPromptVo = levelContent?.entryPrompt;

    return LevelIntroScreenplayBuilder.createFor({
      entryPromptVo,
      preparedScenes
    }).screenplay;
  }
}
