import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LevelActionReady, LevelActionType } from 'curriculum-services';
import { LevelSceneAnimationName } from 'feature-areas/levels';
import { RouterService, RoutePath } from 'router-service';
import {
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import { PreparedScenes, SceneName } from 'services/storm-lobo';

interface IShowUpdatedLevelSceneEpicDeps {
  preparedScenes: PreparedScenes;
}

export function showUpdatedLevelSceneEpic(
  action$: Observable<LevelActionReady>,
  _state$: StateObservable<unknown>,
  deps: IShowUpdatedLevelSceneEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(LevelActionType.Ready),
    filter(() => RouterService.isOnRoute(RoutePath.Levels)),
    map(
      () =>
        ScreenplayBuilder.create('showUpdatedLevelScene')
          .addCallback(async () => {
            const levelScene = await deps.preparedScenes.levelReady;
            levelScene.show();
          })
          .addStormAnimation({
            name: LevelSceneAnimationName.Root.Idle,
            targetScene: SceneName.Level
          }).screenplay
    ),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
showUpdatedLevelSceneEpic.displayName = 'showUpdatedLevelSceneEpic';
