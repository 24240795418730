import memoizeOne from 'memoize-one';
import { Color, Font, InteractionState } from 'common-styles';
import { ThemeType } from 'theme';

export class ImageChoiceStyles {
  public static readonly build = memoizeOne(
    (
      interactionState: InteractionState = InteractionState.Default,
      themeType: ThemeType
    ) => {
      const isDefaultState = interactionState === InteractionState.Default;
      const isInstruction = themeType === ThemeType.Instruction;

      return {
        cardStyleOverride: {
          ...(isInstruction && {
            image: {
              height: 145,
              width: 145
            }
          }),
          label: {
            text: {
              ...(isDefaultState && {
                fontFamily: Font.Family.regular
              })
            }
          }
        },
        choiceContainerStyleOverride: {
          button: {
            borderRadius: 25,
            margin: 0
          },
          interactiveContainerWrapper: {
            borderColor: Color.Transparent,
            borderRadius: 20,
            borderWidth: 3
          },
          interactiveContainerStyleOverride: {
            backgroundColor: Color.Transparent,
            borderRadius: 19,
            borderWidth: isDefaultState ? 3 : 6,
            padding: 4,
            ...(isDefaultState && { borderColor: Color.Gray20 })
          }
        }
      };
    }
  );
}
