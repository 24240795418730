import l01Png from './l01.png';
import l02Png from './l02.png';
import l03Png from './l03.png';
import l04Png from './l04.png';
import l05Png from './l05.png';
import l06Png from './l06.png';
import l07Png from './l07.png';
import l08Png from './l08.png';
import l09Png from './l09.png';
import l10Png from './l10.png';
import l11Png from './l11.png';
import l12Png from './l12.png';
import l13Png from './l13.png';
import l14Png from './l14.png';
import l15Png from './l15.png';
import l16Png from './l16.png';
import l17Png from './l17.png';
import l18Png from './l18.png';
import l19Png from './l19.png';

export {
  l01Png,
  l02Png,
  l03Png,
  l04Png,
  l05Png,
  l06Png,
  l07Png,
  l08Png,
  l09Png,
  l10Png,
  l11Png,
  l12Png,
  l13Png,
  l14Png,
  l15Png,
  l16Png,
  l17Png,
  l18Png,
  l19Png
};
