import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Row, Text, TextButton, TextButtonType } from 'common-ui';
import { AppState } from 'services';
import { AuthSelector } from 'services/auth';
import { CustomerAction, CustomerSelector } from 'services/customer/redux';
import { AppShellAction } from 'shell/redux/AppShell.action';
import { AccountInfoStyles } from './AccountInfo.styles';
import { LogoutWithConfirmationButton } from 'feature-areas/logout-confirmation';

export interface IAccountInfoProps {
  customerName: string | undefined;
  isAuthenticated: boolean;
  changeAccount(): void;
  close(): void;
}

export class AccountInfoComponent extends React.PureComponent<IAccountInfoProps> {
  public static readonly displayName = 'AccountInfo';

  constructor(props: IAccountInfoProps) {
    super(props);
    this.handleChangeAccount = this.handleChangeAccount.bind(this);
  }

  private handleChangeAccount() {
    this.props.changeAccount();
    this.props.close();
  }

  public render() {
    const { customerName, isAuthenticated } = this.props;
    const styles = AccountInfoStyles.get();
    const buttonStyles = AccountInfoStyles.buildButton(isAuthenticated);

    return (
      <Column
        testId={AccountInfoComponent.displayName}
        style={styles.mainColumn}
      >
        {isAuthenticated && (
          <Row style={styles.mainRow}>
            <Text style={styles.text}>
              You must log out to change account settings
            </Text>
            <LogoutWithConfirmationButton
              autoFocus
              buttonType={TextButtonType.Secondary}
            />
          </Row>
        )}
        <Row style={styles.mainRow}>
          <Text style={styles.text}>Account</Text>
          <Text style={styles.text}>{customerName}</Text>
          <TextButton
            autoFocus={!isAuthenticated}
            testId="changeAccountButton"
            styleOverride={buttonStyles}
            disabled={isAuthenticated}
            onPress={this.handleChangeAccount}
            text="Change Account"
          />
        </Row>
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    customerName: CustomerSelector.getCustomerName(state),
    isAuthenticated: AuthSelector.isAuthenticated(state)
  };
}

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  changeAccount: () => CustomerAction.changeAccount(),
  close: () => AppShellAction.hideModal()
};

export const AccountInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfoComponent);

export const AccountInfoPrivates = { mapStateToProps };
