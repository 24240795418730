import { addSavePointRoundEpic } from './save-point/addSavePointRound.epic';
import { changePositionEpic } from './progress/changePosition.epic';
import { createSavePointEpic } from './save-point/createSavePoint.epic';
import { handleSessionTimeoutEpic } from './session-timeout/handleSessionTimeout.epic';
import { handleSessionTimeoutWarningsEpic } from './session-timeout/handleSessionTimeoutWarnings.epic';
import { keepSessionAliveEpic } from './session-timeout/keepSessionAlive.epic';
import { levelUpEpic } from './level-management/levelUp.epic';
import { maybeRequestSaveEpic } from './save-point/maybeRequestSave.epic';
import { onboardingCompletionEpic } from './placement/onboardingCompletion.epic';
import { prepareLevelUpEpic } from './level-management/prepareLevelUp.epic';
import { prepareNextPositionEpic } from './progress/prepareNextPosition.epic';
import { removeLevelPositionEpic } from './level-management/removeLevelPosition.epic';
import { requestLevelLoadEpic } from './level-management/requestLevelLoad.epic';
import { savePositionEpic } from './save-point/savePosition.epic';
import { sessionHistoryEpic } from './session-history/sessionHistory.epic';
import { setUpLevelEpic } from './level-management/setUpLevel.epic';
import { skipToNextPositionEpic } from './progress/skipToNextPosition.epic';

export const programContextEpics = [
  addSavePointRoundEpic,
  changePositionEpic,
  createSavePointEpic,
  handleSessionTimeoutEpic,
  handleSessionTimeoutWarningsEpic,
  keepSessionAliveEpic,
  levelUpEpic,
  maybeRequestSaveEpic,
  onboardingCompletionEpic,
  prepareLevelUpEpic,
  prepareNextPositionEpic,
  removeLevelPositionEpic,
  requestLevelLoadEpic,
  savePositionEpic,
  sessionHistoryEpic,
  setUpLevelEpic,
  skipToNextPositionEpic
];
