import { FaceStyle, SkinColor, HairColor } from 'services/profile/avatar.model';
import {
  HairColorHexMap,
  defaultHairColor
} from '../hair-style-options/hair.model';

interface ISkinAttributes {
  skinLines: string;
  skin: string;
  lips: string;
}

interface IEyeAttributes {
  eyeLines: string;
  eyes: string;
}

export const SkinColorToSkinAttributesMap: Map<
  SkinColor | undefined,
  ISkinAttributes
> = new Map<SkinColor, ISkinAttributes>([
  [
    SkinColor.Light,
    {
      lips: '#B86A58',
      skin: '#FFC7A2',
      skinLines: '#B06243'
    }
  ],
  [
    SkinColor.MediumLight,
    {
      lips: '#A34029',
      skin: '#D78E67',
      skinLines: '#944526'
    }
  ],
  [
    SkinColor.MediumDark,
    {
      lips: '#6F1705',
      skin: '#A85A41',
      skinLines: '#691200'
    }
  ],
  [
    SkinColor.Dark,
    {
      lips: '#4D0000',
      skin: '#79351E',
      skinLines: '#470303'
    }
  ]
]);

const SkinColorToEyeAttributesMap = new Map<
  FaceStyle,
  Map<SkinColor, IEyeAttributes>
>([
  [
    FaceStyle.One,
    new Map<SkinColor, IEyeAttributes>([
      [SkinColor.Light, { eyeLines: '#1C2C30', eyes: '#6A8586' }],
      [SkinColor.MediumLight, { eyeLines: '#302910', eyes: '#836B2B' }],
      [SkinColor.MediumDark, { eyeLines: '#2C0801', eyes: '#491605' }],
      [SkinColor.Dark, { eyeLines: '#070000', eyes: '#340000' }]
    ])
  ],
  [
    FaceStyle.Two,
    new Map<SkinColor, IEyeAttributes>([
      [SkinColor.Light, { eyeLines: '#420E04', eyes: '#874724' }],
      [SkinColor.MediumLight, { eyeLines: '#3C0C05', eyes: '#6E2606' }],
      [SkinColor.MediumDark, { eyeLines: '#210000', eyes: '#5D1800' }],
      [SkinColor.Dark, { eyeLines: '#170000', eyes: '#440000' }]
    ])
  ],
  [
    FaceStyle.Three,
    new Map<SkinColor, IEyeAttributes>([
      [SkinColor.Light, { eyeLines: '#3E3508', eyes: '#736520' }],
      [SkinColor.MediumLight, { eyeLines: '#3E2703', eyes: '#664D1D' }],
      [SkinColor.MediumDark, { eyeLines: '#411F07', eyes: '#673B16' }],
      [SkinColor.Dark, { eyeLines: '#1D0000', eyes: '#431800' }]
    ])
  ],
  [
    FaceStyle.Four,
    new Map<SkinColor, IEyeAttributes>([
      [SkinColor.Light, { eyeLines: '#602A01', eyes: '#A0601E' }],
      [SkinColor.MediumLight, { eyeLines: '#5D2000', eyes: '#934006' }],
      [SkinColor.MediumDark, { eyeLines: '#380C00', eyes: '#6E2800' }],
      [SkinColor.Dark, { eyeLines: '#2B0200', eyes: '#410000' }]
    ])
  ]
]);

/** Gets all of the colors necessary to render a face style svg  */
export function getFaceColors(
  faceStyle: FaceStyle,
  hairColor: HairColor,
  skinColor: SkinColor
) {
  const hairAttributes = HairColorHexMap.get(hairColor) ?? defaultHairColor;

  return {
    ...SkinColorToSkinAttributesMap.get(skinColor),
    ...SkinColorToEyeAttributesMap.get(faceStyle)?.get(skinColor),
    eyebrowLines: hairAttributes.hairLines,
    eyebrows: hairAttributes.hair,
    eyelashes: '#340000'
  };
}
