import {
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { ITextOrdering } from '@lexialearning/lobo-common/tasks/text-ordering';
import * as React from 'react';
import { Column, SpotlightArea } from 'common-ui';
import { ITaskProps } from 'task-components/core';
import { ISegment, OrderingContainer } from 'task-components/shared';
import { VerticalOrderingContent } from './vertical-ordering-content/VerticalOrderingContent';
import { VerticalOrderingStyles } from './VerticalOrdering.styles';

export type IVerticalOrderingProps = ITaskProps<ITextOrdering>;

export class VerticalOrdering extends React.PureComponent<IVerticalOrderingProps> {
  public static readonly displayName = 'VerticalOrdering';

  constructor(props: IVerticalOrderingProps) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
  }

  private shouldShowCorrectFeedback() {
    const { evaluationResult, phase } = this.props;

    return (
      evaluationResult === TaskEvaluationResult.Correct &&
      phase === TaskPhase.Feedback
    );
  }

  private renderContent(
    segments: ISegment[],
    moveSegment: (targetIndex: number, sourceIndex: number) => void
  ) {
    const {
      taskContent: { textPillWidth }
    } = this.props;

    return (
      <VerticalOrderingContent
        segments={segments}
        moveSegment={moveSegment}
        textPillWidth={textPillWidth}
        shouldShowCorrectFeedback={this.shouldShowCorrectFeedback()}
      />
    );
  }

  public render() {
    const { taskContent } = this.props;
    const { spotlights, instructionalImage, segments } = taskContent;
    const hasSpotlight = !!(spotlights.length || instructionalImage);
    const styles = VerticalOrderingStyles.build(hasSpotlight);

    return (
      <Column style={styles.taskContainer}>
        <SpotlightArea
          spotlights={spotlights}
          instructionalImage={instructionalImage}
        />
        <Column style={styles.orderingContainer}>
          <OrderingContainer
            orderedSegments={segments}
            renderContent={this.renderContent}
            extraData={this.shouldShowCorrectFeedback()}
          />
        </Column>
      </Column>
    );
  }
}
