import {
  ILanguageFrameToken,
  LanguageFrameTokenDecoration
} from '@lexialearning/lobo-common/tasks/see-speak';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { SeeSpeakActionType } from './see-speak-redux.model';
import { LanguageFrameSession as LF } from 'sre';

export const SeeSpeakAction = {
  clearSreOptions: createAction(SeeSpeakActionType.ClearSreOptions)(),
  decoratedTokens: createAction(
    SeeSpeakActionType.DecoratedTokens
  )<IDecoratedTokensPayload>(),
  removeTokenDecoration: createAction(
    SeeSpeakActionType.RemoveTokenDecoration
  )<ISeeSpeakRemoveTokenDecorationPayload>(),
  setSreOptions: createAsyncAction(
    SeeSpeakActionType.SetSreOptions,
    SeeSpeakActionType.SetSreOptionsSuccess,
    SeeSpeakActionType.SetSreOptionsFailure
  )<ISeeSpeakSreOptionsPayload, LF.IConfig | undefined, void>(),
  toggleListen: createAction(SeeSpeakActionType.ToggleListen)()
};

export interface IDecoratedTokensPayload {
  languageFrameTokens: ILanguageFrameToken[];
}

export interface ISeeSpeakSreOptionsPayload {
  languageFrameTokens: ILanguageFrameToken[];
}

export interface ISeeSpeakRemoveTokenDecorationPayload {
  decoration: LanguageFrameTokenDecoration;
}

export type SeeSpeakActions = ActionType<typeof SeeSpeakAction>;
export type SeeSpeakActionDecoratedTokens = ActionType<
  typeof SeeSpeakAction.decoratedTokens
>;
export type SeeSpeakActionSetSreOptions = ActionType<
  typeof SeeSpeakAction.setSreOptions.request
>;
export type SeeSpeakActionSetSreOptionsSuccess = ActionType<
  typeof SeeSpeakAction.setSreOptions.success
>;
export type SeeSpeakActionToggleListen = ActionType<
  typeof SeeSpeakAction.toggleListen
>;
