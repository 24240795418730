import * as React from 'react';
import { Inspector } from 'react-inspector';
import { connect } from 'react-redux';
import { ScrollView } from 'common-ui';
import { ILoboAppConfig } from 'services/app-config';
import { ConfigEditorStyles } from './ConfigEditor.styles';
import { ConfigNode } from './ConfigNode';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppState } from 'services';

export interface IConfigEditorProps {
  config: ILoboAppConfig;
  onNodePress(key: string, value: boolean | string | number): void;
}

export class ConfigEditorComponent extends React.PureComponent<IConfigEditorProps> {
  public static readonly displayName = 'ConfigEditor';

  public constructor(props: IConfigEditorProps) {
    super(props);

    this.renderCustomNode = this.renderCustomNode.bind(this);
  }

  private renderCustomNode(props: any) {
    return <ConfigNode {...props} onPress={this.props.onNodePress} />;
  }

  public render() {
    const { config } = this.props;
    const styles = ConfigEditorStyles.get();

    return (
      <ScrollView style={styles.inspectorContainer}>
        <Inspector
          data={config}
          expandLevel={1}
          nodeRenderer={this.renderCustomNode}
          table={false}
        />
      </ScrollView>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    config: UniConfigSelector.getConfig<ILoboAppConfig>(state)
  };
}

export const ConfigEditor = connect(mapStateToProps)(ConfigEditorComponent);
