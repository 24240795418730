import { useTheme } from 'theme';
import { Column } from '../layout/column/Column';
import { Row } from '../layout/row/Row';
import { View } from '../view/View';
import { DotRowPosition, GripDotsStyles } from './GripDots.styles';

export interface IGripDotsProps {
  dotRowPosition: DotRowPosition;
}

export function GripDots(props: IGripDotsProps) {
  const { themeSize } = useTheme();
  const styles = GripDotsStyles.build(props.dotRowPosition, themeSize);

  return (
    <Row style={styles.dotRow}>
      <Column style={styles.dotColumn}>
        <View style={styles.singleDot} />
        <View style={styles.singleDot} />
        <View style={styles.singleDot} />
      </Column>
      <Column style={styles.dotColumn}>
        <View style={styles.singleDot} />
        <View style={styles.singleDot} />
        <View style={styles.singleDot} />
      </Column>
    </Row>
  );
}
GripDots.displayName = 'GripDots';
