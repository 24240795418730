import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { ScreenplayAction } from 'screenplay';

export interface IWithTapAndHearProps {
  voiceover?: IScreenplay;
}

interface IWithTapAndHearInnerProps extends IWithTapAndHearProps {
  playVoiceover(voiceover: IScreenplay): void;
}

export function withTapAndHear(Component: any) {
  class TapAndHearComponent extends React.PureComponent<IWithTapAndHearInnerProps> {
    public static readonly displayName = `withTapAndHear(${Component.displayName})`;

    constructor(props: IWithTapAndHearInnerProps) {
      super(props);

      this.handlePress = this.handlePress.bind(this);
    }

    private handlePress() {
      const { playVoiceover, voiceover } = this.props;
      if (voiceover) {
        playVoiceover(voiceover);
      }
    }

    public render() {
      return <Component {...this.props} onPress={this.handlePress} />;
    }
  }

  const mapDispatchToProps = {
    playVoiceover: (vo: IScreenplay) =>
      ScreenplayAction.play({ screenplay: vo })
  };

  const TapAndHear = connect(null, mapDispatchToProps)(TapAndHearComponent);

  return TapAndHear as typeof Component;
}
