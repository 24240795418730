/**
 * Intended to hold info about high-level student route through the app
 * Currently only holding the sysIds of the Levels/Acts/Encounters that
 * the student enters in a session - but may be extended to include other
 * pages visited and/or whether a given level/act/encounter was completed
 * during the session etc
 * TODO: Rename this to something more specific/descriptive (session history
 * sounds a little more all-encompassing than this is), probably not
 * until we have a better idea of what all this will be/hold
 */
export interface ISessionHistoryEvent {
  pageType: SessionHistoryPageType;
  levelId: string;
  actId: string;
  encounterId: string;
}

export enum SessionHistoryPageType {
  Home = 'Home',
  Level = 'Level',
  Act = 'Act',
  Educator = 'Educator'
}

/**
 * Flags indicating whether the redux state content matches the program
 * position.
 *
 * @see ProgramContextService.determineContentReadiness
 */
export interface IContentReadiness {
  all: boolean;
  level: boolean;
  unit: boolean;
  task: boolean;
}

/**
 * Mode or flow in which the curriculum program is presented to the user
 */
export enum ProgramMode {
  /**
   * "Normal" mode for students (who have already been placed in the program)
   */
  ActiveStudent = 'ActiveStudent',
  /**
   * Student has completed the program
   */
  Completed = 'Completed',
  /**
   * Educator mode
   */
  Educator = 'Educator',
  /**
   * Student is onboarding (pre-placement)
   */
  Onboarding = 'Onboarding',
  /**
   * Student is being placed (placement test)
   */
  Placement = 'Placement',

  Unknown = 'Unknown'
}
