import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { merge } from 'lodash';
import { SystemInfo } from 'utils';
import { LexiaProduct } from '../lexia-service-private.model';
import { ILexiaServiceState } from './lexia-service-redux.model';
import { LexiaServiceActions } from './lexiaService.action';
import { LexiaServiceActionType } from './LexiaServiceActionType';

const studentApi = {
  authToken: '',
  studentId: 0,
  version: ''
};

const lexiaServiceInitialState: ILexiaServiceState = {
  options: {
    apiTimeoutArray: [5, 10, 20],
    isMakingRequest: false,
    maxRetries: 2
  },
  requestsBases: {
    authenticate: {
      customer_code: '',
      product: LexiaProduct.Lobo
    },
    login: {
      attemptNum: 1,
      requestNum: 0,
      version: ''
    },
    logout: {
      ...studentApi,
      attemptNum: 1,
      requestNum: 0
    },
    register: {
      platform: getPlatform(),
      product: LexiaProduct.Lobo
    },
    studentApi
  }
};

export function lexiaServiceReducer(
  state: ILexiaServiceState = lexiaServiceInitialState,
  action: LexiaServiceActions | UserGlobalActionLogoutSuccess
): ILexiaServiceState {
  switch (action.type) {
    case LexiaServiceActionType.UpdateRequestBase:
      return {
        ...state,
        requestsBases: merge({}, state.requestsBases, action.payload)
      };

    case LexiaServiceActionType.UpdateServiceOptions:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload.options
        }
      };

    case UserGlobalActionType.LogoutSuccess:
      return {
        ...lexiaServiceInitialState,
        requestsBases: {
          ...lexiaServiceInitialState.requestsBases,
          authenticate: {
            ...lexiaServiceInitialState.requestsBases.authenticate,
            customer_code: state.requestsBases.authenticate.customer_code
          }
        }
      };

    default:
      return state;
  }
}

function getPlatform() {
  /**
   * Used for "platform" value when communicating with myLexia.
   * @see https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=MYL&title=Authentication+API
   *
   * Both web and Android platforms send "web", iOS is set explicitly to "ios".
   *
   * We set iOS explicitly because it allows for Clever to fall back to using the
   * old custom URL scheme in the case where universal links aren’t working for
   * some reason. And “mobile” is for the “myLexia mobile” app which has its own
   * special considerations. "liwcb" generally isn’t necessary to make any
   * distinction so myLexia prefers that we avoid using it.
   */
  return SystemInfo.isNative ? 'ios' : 'web';
}

export const LexiaServiceReducerPrivates = {
  getPlatform,
  initialState: lexiaServiceInitialState
};
