import {
  IMicrophone,
  ISreConfig,
  RSpeechPlatform,
  SreConfigStatus
} from '@lexialearning/lobo-common/main-model/sre';
import { VoiceType } from '@lexialearning/sre';
import { SreConfigActionType } from '../sre-redux.model';
import { SreConfigActions } from './SreConfig.action';

export const configInitialState: ISreConfig = {
  language: '',
  mic: undefined,
  micBlocked: false,
  micsAvailable: [],
  rSpeechPlatform: RSpeechPlatform.Unknown,
  sreVersion: '',
  status: SreConfigStatus.Uninitialized,
  voiceType: VoiceType.Child
};

export function sreConfigReducer(
  state = configInitialState,
  action: SreConfigActions
): ISreConfig {
  switch (action.type) {
    case SreConfigActionType.ConfigureValidated:
      return {
        ...state,
        status: SreConfigStatus.Initializing
      };

    case SreConfigActionType.ConfigureSuccess:
      return {
        ...state,
        ...action.payload
      };

    case SreConfigActionType.ConfigureFailure:
      return {
        ...configInitialState,
        status: SreConfigStatus.Error
      };

    case SreConfigActionType.SelectMic:
      return {
        ...state,
        mic: action.payload.mic
      };

    case SreConfigActionType.SetMicsAvailable:
      const { micsAvailable } = action.payload;
      const hadMicBefore = !!state.micsAvailable.length && !!state.mic;
      const micStillAvailable =
        state.mic &&
        !!micsAvailable.find((m: IMicrophone) => m.id === state.mic!.id);

      // to cover the case when user doesn't allow mic access on time so the
      // first time micsAvailable and mic are set they will be empty
      return {
        ...state,
        mic: !hadMicBefore
          ? micsAvailable[0]
          : micStillAvailable
          ? state.mic
          : undefined,
        micsAvailable
      };

    case SreConfigActionType.SetMicBlocked:
      return {
        ...state,
        micBlocked: action.payload
      };

    default:
      return state;
  }
}
