import memoizeOne from 'memoize-one';
import { Color } from 'common-styles';

export class ConfigNodeStyles {
  public static readonly build = memoizeOne((isMouseOver: boolean) => ({
    container: {
      backgroundColor: isMouseOver
        ? Color.LexiaPurpleTransparent10
        : Color.Transparent,
      display: 'inline'
    }
  }));
}
