import {
  ITextboard,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Text } from 'common-ui';
import { SubmitButton } from 'task-components/shared';
import { ITaskProps, TaskSelector } from '../core';
import { TextboardStyles } from './Textboard.styles';

const PUNCTUATION_REGEX =
  /^\s*[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/;

export interface ITextboardProps extends ITaskProps<ITextboard> {
  canSubmit: boolean;
}

export class TextboardComponent extends React.PureComponent<ITextboardProps> {
  public static readonly displayName = 'Textboard';

  constructor(props: ITextboardProps) {
    super(props);
    this.handlePressSubmit = this.handlePressSubmit.bind(this);
  }

  public componentDidUpdate(prevProps: ITextboardProps) {
    if (!prevProps.isInteractive && this.props.isInteractive) {
      this.props.updateCanSubmit({ canSubmit: true });
    }
  }

  private handlePressSubmit(): void {
    this.props.onEvaluated(TaskEvaluationResult.Correct, {});
  }

  private renderTextPart(text: string, i: number) {
    if (!text) {
      // this happens if each word is demarcated separately, it's
      // important to render null instead of filter them out to keep the
      // correct indexes since highlighting is based on them
      return null;
    }

    const styles = TextboardStyles.get();
    // render each word separately for better text overflow alignment
    const words = text.split(' ').filter(Boolean);

    return words.map((w: string, j: number) => {
      const shouldRemoveSpace = i !== 0 && j === 0 && PUNCTUATION_REGEX.test(w);
      const style = shouldRemoveSpace
        ? i % 2
          ? styles.highlightedTextWithoutSpace
          : styles.textWithoutSpace
        : i % 2
        ? styles.highlightedText
        : styles.text;

      return (
        <Text key={`${i}-${j}`} style={style}>
          {w}
        </Text>
      );
    });
  }

  public render() {
    const { taskContent, canSubmit } = this.props;
    const textParts = taskContent.text.split(/_+/);
    const styles = TextboardStyles.get();

    return (
      <>
        <Row style={styles.content}>{textParts.map(this.renderTextPart)}</Row>
        {canSubmit && (
          <Row style={styles.buttonContainer}>
            <SubmitButton onPress={this.handlePressSubmit} />
          </Row>
        )}
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    canSubmit: TaskSelector.getCanSubmit(state)
  };
}

export const Textboard = connect(mapStateToProps)(TextboardComponent);
