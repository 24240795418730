import { Align, Color, Justify } from 'common-styles';

export class StudentInfoSectionStyles {
  private static readonly styles = {
    container: {
      alignItems: Align.Start,
      alignSelf: Align.Stretch,
      backgroundColor: Color.BlackTransparent04,
      borderRadius: 10,
      justifyContent: Justify.Start,
      marginBottom: 15,
      paddingHorizontal: 15,
      paddingVertical: 15
    },
    title: {
      color: Color.Gray70,
      fontSize: 14
    }
  };

  public static get() {
    return this.styles;
  }
}
