import {
  ILevelsCompletedState,
  ILevelsCompletedStateWrapper
} from './levels-completed-redux.model';
import {
  ILevelStatus,
  LevelStatus,
  LoadStatus
} from '../levels-completed.model';
import { createSelector } from '@reduxjs/toolkit';

export class LevelsCompletedSelector {
  public static readonly displayName = 'LevelsCompletedSelector';

  public static readonly getLevelsStatus: (
    state: ILevelsCompletedStateWrapper
  ) => ILevelStatus[] = createSelector(
    state => state.levelsCompleted,
    l => (l as ILevelsCompletedState).levels
  );

  public static readonly getLoadStatus: (
    state: ILevelsCompletedStateWrapper
  ) => LoadStatus = createSelector(
    state => state.levelsCompleted,
    l => (l as ILevelsCompletedState).loadStatus
  );

  public static readonly getHasUnseenLevelCertificates: (
    state: ILevelsCompletedStateWrapper
  ) => boolean = createSelector(
    LevelsCompletedSelector.getLevelsStatus,
    (statuses: ILevelStatus[]) =>
      statuses.some(
        s => s.status === LevelStatus.Completed && !s.certificateViewed
      )
  );
}
