import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function SkipIconSvg({
  iconColor = Color.NearWhite
}: IGlossyButtonIconSvgProps) {
  return (
    <G
      data-test-id={SkipIconSvg.displayName}
      fill={iconColor}
      transform="translate(32, 21)"
    >
      <Path d="M17.18 12.171a2.258 2.258 0 0 0 0-3.91l-6.884-3.982L3.387.299A2.258 2.258 0 0 0 0 2.254v15.925a2.258 2.258 0 0 0 3.387 1.95l6.884-3.982Z" />
      <Path d="M31.18 12.171a2.258 2.258 0 0 0 0-3.91l-6.884-3.982-6.909-3.98A2.258 2.258 0 0 0 14 2.254v15.925a2.258 2.258 0 0 0 3.387 1.95l6.884-3.982Z" />
    </G>
  );
}
SkipIconSvg.displayName = 'SkipIconSvg';
