import { StormEngine } from '@lexialearning/storm-react';
import { LexiaError } from '@lexialearning/utils';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  StormAction,
  StormActionInitialize,
  StormActionInitializeSuccess,
  StormActionType
} from '../redux';
import { StormService } from '../service';

export interface IInitializeStormDeps {
  stormService: StormService;
}

/**
 * Marks the start and end of Storm initialization. The actual command to
 * initialize StormService is from StormContainer as the StormPlayerControl is
 * too big an object to pass around through redux. (It kills redux dev tools)
 *
 * @see StormContainer
 */
export function initializeStormEpic(
  action$: Observable<StormActionInitialize>
): Observable<StormActionInitializeSuccess> {
  return action$.pipe(
    ofType(StormActionType.Initialize),
    mergeMap(async () => {
      try {
        // StormEngine has been initialized by Cui, so we can just grab a handle to it
        const ctrl = await StormEngine.getInstance();
        await StormService.initialize(ctrl);
      } catch (e) {
        throw new LexiaError(
          'Storm initialization failed',
          initializeStormEpic.displayName,
          StormInitError.ServiceInitFailed
        ).withCause(e);
      }

      return StormAction.initialize.success();
    })
  );
}
initializeStormEpic.displayName = 'initializeStormEpic';

export enum StormInitError {
  ServiceInitFailed = 'ServiceInitFailed'
}
