import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { ProgramCompleteScene } from '../ProgramCompleteScene';
import { ProgramCompleteSceneDefinitionBuilder } from '../ProgramCompleteSceneDefinitionBuilder';
import {
  ProgramCompleteSceneActionType,
  ProgramCompleteScenePrepareAction
} from '../redux';

export interface IPrepareProgramCompleteSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareProgramCompleteSceneEpic(
  action$: Observable<ProgramCompleteScenePrepareAction>,
  _state$: StateObservable<unknown>,
  deps: IPrepareProgramCompleteSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(ProgramCompleteSceneActionType.Prepare),
    switchMap(async ({ payload }) => {
      const { preparedScenes, stormService } = deps;
      // this scene is final, so no need to ever recreate it
      if (preparedScenes.programComplete) {
        return;
      }

      const { definition } = ProgramCompleteSceneDefinitionBuilder.create(
        payload.levelNum
      )
        .withBackground()
        .withBackgroundOverlay();

      await stormService.initialization;
      const scene = await stormService.prepareScene(definition);

      preparedScenes.programComplete = new ProgramCompleteScene(
        scene,
        stormService,
        payload.levelNum
      );
    }),
    ignoreElements()
  );
}
prepareProgramCompleteSceneEpic.displayName = 'prepareProgramCompleteSceneEpic';
