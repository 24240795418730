import merge from 'lodash/merge';
import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  loboAnimated,
  ZIndex
} from 'common-styles';
import { CONSTANTS } from '../../helpers';
import { Types } from '../../types';
import { Position } from '@lexialearning/common-ui';

export interface IModalStyleOverride {
  modal?: {
    top?: number;
    right?: number;
  };
}

const durations = {
  hide: 200,
  show: 300
};
const yTranslate = -150;

export interface IModalAnimations {
  showModal: Types.Animated.CompositeAnimation;
  hideModal: Types.Animated.CompositeAnimation;
}

export class ModalAnimatedStyles {
  private readonly animations: IModalAnimations;

  private readonly styles: any;

  constructor() {
    const animatedValues = {
      opacity: loboAnimated.createValue(0),
      translateYValue: loboAnimated.createValue(yTranslate)
    };

    const showModal = loboAnimated.parallel([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          duration: durations.show,
          easing: loboAnimated.Easing.Out(),
          toValue: 1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        animatedValues.translateYValue,
        {
          duration: durations.show,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      )
    ]);

    const hideModal = loboAnimated.parallel([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          duration: durations.hide,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        animatedValues.translateYValue,
        {
          duration: durations.hide,
          easing: loboAnimated.Easing.Out(),
          toValue: yTranslate
        }
      )
    ]);

    this.animations = {
      hideModal,
      showModal
    };

    this.styles = {
      container: {
        alignItems: Align.Stretch,
        bottom: 0,
        left: 0,
        position: Position.Absolute,
        right: 0,
        top: 0,
        zIndex: ZIndex.Modal
      },
      modal: {
        alignSelf: Align.Center,
        borderRadius: 8,
        maxHeight: CONSTANTS.BaseDimensions.Height,
        maxWidth: CONSTANTS.BaseDimensions.Width,
        position: Position.Absolute
      },
      modalAnimated: {
        transform: [{ translateY: animatedValues.translateYValue }]
      },
      overlay: {
        backgroundColor: Color.BlackTransparent70,
        flex: 1
      },
      overlayAnimated: {
        opacity: animatedValues.opacity
      }
    };
  }

  public readonly build = memoizeOne((styleOverride?: IModalStyleOverride) =>
    merge({}, this.styles, styleOverride)
  );

  public getAnimations() {
    return this.animations;
  }
}
