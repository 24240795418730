import { SreSessionType } from 'sre';
import { IMicEnergyMeterProps, MicEnergyMeter } from './MicEnergyMeter';

export const CalibrationMeter = (props: IMicEnergyMeterProps) => (
  <MicEnergyMeter
    {...props}
    subscribeToSessionType={SreSessionType.Calibration}
    testId={CalibrationMeter.displayName}
  />
);
CalibrationMeter.displayName = 'CalibrationMeter';
