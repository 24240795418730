import { UnitType } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UnitActionLoadSuccess,
  UnitActionType,
  UnitSelector
} from 'services/curriculum-services/unit/redux';
import { ControlPanelLayout } from '../app-shell.model';
import {
  AppShellAction,
  AppShellActionSetControlPanelLayout
} from '../AppShell.action';

export function updateControlPanelLayoutEpic(
  action$: Observable<UnitActionLoadSuccess>,
  state$: StateObservable<unknown>
): Observable<AppShellActionSetControlPanelLayout> {
  return action$.pipe(
    ofType(UnitActionType.LoadSuccess),
    map(() => {
      const unit = UnitSelector.getUnitMaybe(state$.value);

      const layout =
        unit?.type === UnitType.Media
          ? ControlPanelLayout.Minimized
          : ControlPanelLayout.Standard;

      return AppShellAction.setControlPanelLayout(layout);
    })
  );
}
updateControlPanelLayoutEpic.displayName = 'updateControlPanelLayoutEpic';
