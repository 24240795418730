import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  TaskAction,
  TaskActionExit,
  TaskActionFeedback,
  TaskActions,
  TaskActionType,
  TaskSelector
} from '../redux';
import { TaskRegistry } from '../registry';
import {
  FeedbackCompletionHandler,
  FeedbackCompletionOutputActions
} from './feedback-completion';

const matchingTypes = [
  TaskActionType.Evaluated,
  TaskActionType.FeedbackComplete,
  TaskActionType.SolutionComplete
] as const;

export interface ITaskPhaseProgressionEpicDeps {
  taskRegistry: TaskRegistry;
}

export type TaskPhaseProgressionOutputActions =
  | TaskActionFeedback
  | FeedbackCompletionOutputActions
  | TaskActionExit;

export function taskPhaseProgressionEpic(
  action$: Observable<TaskActions>,
  state$: StateObservable<unknown>,
  deps: ITaskPhaseProgressionEpicDeps
): Observable<TaskPhaseProgressionOutputActions> {
  return action$.pipe(
    ofType(...matchingTypes),
    filter(() => !!TaskSelector.getTaskContentMaybe(state$.value)),
    map(action => {
      const handler = phaseProgressionMap.get(action.type)!;

      return handler(state$, deps);
    })
  );
}
taskPhaseProgressionEpic.displayName = 'taskPhaseProgressionEpic';

const phaseProgressionMap = createPhaseProgressionMap();

type PhaseCompletionHandler = (
  state$: StateObservable<unknown>,
  deps: ITaskPhaseProgressionEpicDeps
) => TaskPhaseProgressionOutputActions;
type PhaseProgressionMap = Map<TaskActionType, PhaseCompletionHandler>;
function createPhaseProgressionMap(): PhaseProgressionMap {
  const m: PhaseProgressionMap = new Map();
  m.set(TaskActionType.Evaluated, () => TaskAction.feedback());
  m.set(TaskActionType.FeedbackComplete, handleFeedbackCompletion);
  m.set(TaskActionType.SolutionComplete, () => TaskAction.exit());

  validatePhaseProgressionMap(m);

  return m;
}

function handleFeedbackCompletion(
  state$: StateObservable<unknown>
): FeedbackCompletionOutputActions {
  return FeedbackCompletionHandler.handle(state$.value);
}

// istanbul ignore next - not worth testing essentially build time validation
function validatePhaseProgressionMap(m: PhaseProgressionMap): void {
  const missingTypes = matchingTypes.filter(t => !m.has(t));
  if (missingTypes.length > 0) {
    throw new LexiaError(
      `The following types are missing from the map: ${missingTypes}`,
      taskPhaseProgressionEpic.displayName,
      TaskPhaseProgressionEpicError.InvalidActionType
    ).withContext({ missingTypes });
  }
}

export const TaskPhaseProgressionEpicPrivates = { matchingTypes };

export enum TaskPhaseProgressionEpicError {
  InvalidActionType = 'InvalidActionType'
}
