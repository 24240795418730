import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NetworkStateProvider } from 'services/network-state';
import {
  BootstrappingAction,
  BootstrappingActionBootstrap,
  BootstrappingActionHalted
} from '../redux/Bootstrapping.action';
import {
  ConfigActionInitSuccess as UniConfigActionInitSuccess,
  ConfigAction as UniConfigAction
} from '@lexialearning/utils-react';

export type CheckNetworkStateEpicOutputTypes =
  | BootstrappingActionBootstrap
  | BootstrappingActionHalted;

/**
 * Checks if the network is online and dispatches the bootstrap action if so or
 * the halted action if not.
 * This is intended to run after the Uni config has succeeded and gates the app
 * when offline. It really only works for react native as a web app cannot
 * really start in offline mode. The native app should display the "NotConnected"
 * modal until it gets back online.
 * @see monitorNetworkStateEpic
 */
export const checkNetworkStateEpic = (
  action$: Observable<UniConfigActionInitSuccess>
): Observable<CheckNetworkStateEpicOutputTypes> =>
  action$.pipe(
    filter(UniConfigAction.init.success.match),
    mergeMap(async () => NetworkStateProvider.getOnlineStatus()),
    map(isOnline =>
      isOnline ? BootstrappingAction.bootstrap() : BootstrappingAction.halted()
    )
  );
checkNetworkStateEpic.displayName = 'checkNetworkStateEpic';
