import { AudioService } from '@lexialearning/common-ui';
import {
  IListener,
  LanguageFrameSession as LF
} from '@lexialearning/lobo-common/main-model/sre';
import {
  ILanguageFrameEndResult,
  Sre,
  IToken,
  IPrimedSessionRunner
} from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { LoboSfx } from 'audio';
import { SoundLogFormatHelper } from 'sre/helpers/SoundLogFormat.helper';
import { ILanguageFrameSessionConfig } from '@lexialearning/sre/dist/sre-core/Sre';

export class LanguageFrameListener implements IListener<LF.IResult> {
  public static readonly displayName = 'LanguageFrameListener';

  public get isPrimed(): boolean {
    return !!this.primedSession;
  }

  private primedSession:
    | IPrimedSessionRunner<ILanguageFrameEndResult>
    | undefined;

  public constructor(private readonly sre: Sre) {}

  public async prime(options: LF.IConfig): Promise<ILanguageFrameEndResult> {
    try {
      return await this.primeForLanguageFrame(options);
    } catch (sreErr) {
      const sreState = {
        isCalibrated: this.sre.isCalibrated(),
        isConfigured: this.sre.isConfigured(),
        isSessionRunning: this.sre.isSessionRunning()
      };
      throw new LexiaError(
        `Error priming: ${sreErr}`,
        LanguageFrameListener.displayName,
        LanguageFrameListenerError.PrimeError
      ).withContext({ sreErr, sreState });
    }
  }

  public async listen(options: LF.IConfig): Promise<LF.IResult> {
    try {
      return await this.listenForLanguageFrame(options);
    } catch (sreErr) {
      const sreState = {
        isCalibrated: this.sre.isCalibrated(),
        isConfigured: this.sre.isConfigured(),
        isSessionRunning: this.sre.isSessionRunning()
      };
      throw new LexiaError(
        `Error listening: ${sreErr}`,
        LanguageFrameListener.displayName,
        LanguageFrameListenerError.ListenError
      ).withContext({ sreErr, sreState });
    }
  }

  private primeForLanguageFrame(
    options: LF.IConfig
  ): IPrimedSessionRunner<ILanguageFrameEndResult> {
    this.primedSession = this.sre.listenForPrimedLanguageFrame(
      this.getSessionConfig(options)
    );

    return this.primedSession;
  }

  private async listenForLanguageFrame(
    options: LF.IConfig
  ): Promise<LF.IResult> {
    const result = await this.getListeningResult(options);

    const threshold =
      options.scoring.threshold || LF.SCORING_SRE_THRESHOLD_DEFAULT;

    const passed =
      options.scoring.strategy === LF.ScoringStrategy.Rubric
        ? result.passed
        : result.sreRawScore >= threshold;

    return LanguageFrameListener.languageFrameEndResultAsIResult(
      result,
      passed
    );
  }

  private async getListeningResult(
    options: LF.IConfig
  ): Promise<ILanguageFrameEndResult> {
    const session = this.primedSession || this.primeForLanguageFrame(options);
    const activated = await session?.activate();
    // Session is now activated, and so no longer a primed session
    // (or activation failed, in which case it is nonetheless no longer valid)
    this.primedSession = undefined;

    if (!activated) {
      throw new LexiaError(
        'Primed session could not be activated',
        LanguageFrameListener.displayName,
        LanguageFrameListenerError.PrimeActivationError
      );
    }

    AudioService.playSfx(LoboSfx.MicListening);

    return session;
  }

  private getSessionConfig(options: LF.IConfig): ILanguageFrameSessionConfig {
    const soundLogFormat = SoundLogFormatHelper.getSoundLogFormat(
      options.soundLogCollectionMode
    );

    return {
      maxDurationMS: 30000,
      soundLogFormat,
      tokens: options.tokens as IToken[]
    };
  }

  public static languageFrameEndResultAsIResult(
    result: ILanguageFrameEndResult,
    didPass: boolean
  ): LF.IResult {
    const { wordFinalResults, ...restResult } = result;

    // TODO this structure would benefit a lot from cleanup
    return {
      ...restResult,
      passed: didPass,
      sreRawScore: result.sreRawScore as LF.Score,
      wordFinalResult: wordFinalResults.map(r => ({
        ...r,
        pronunciationScore: r.pronunciationScore as LF.Score
      }))
    };
  }
}

export enum LanguageFrameListenerError {
  ListenError = 'ListenError',
  PrimeActivationError = 'PrimeActivationError',
  PrimeError = 'PrimeError'
}
