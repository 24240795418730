import { Position } from '@lexialearning/common-ui';

export class LoginSsoInteractionPageStyles {
  private static readonly styles = {
    absolutePosition: {
      position: Position.Absolute
    },
    button: {
      top: 35,
      left: 36,
      height: 134,
      width: 170.6
    },
    container: {
      flex: 1
    },
    arms: {
      pointerEvents: 'none',
      position: Position.Absolute,
      left: -64,
      bottom: -9,
      width: 223
    }
  };

  public static get() {
    return this.styles;
  }
}
