import {
  AudioService,
  IVoiceoverData,
  IVoiceoverOptions,
  PlaybackResult
} from '@lexialearning/common-ui';
import { StormAudio, StormNode } from '@lexialearning/storm-react';
import { LexiaError } from '@lexialearning/utils';
import { ISpeakerInfo, Sfx } from 'audio';
import { IMusicRequest } from 'audio/music';
import { Scene } from 'storm/scenes';
import { StormService } from '../service';

export class LxStormAudio {
  public static readonly displayName = 'LxStormAudio';

  public get musicPathPrefix() {
    return this.stormService.getAssetPaths().Music.path;
  }

  public get sfxPathPrefix() {
    return this.stormService.getAssetPaths().Sfx.path;
  }

  public get voiceoverPathPrefix() {
    return this.stormService.getAssetPaths().Voiceovers.path;
  }

  constructor(private readonly stormService: StormService) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  private async initialize(): Promise<void> {
    await this.stormService.initialization;
  }

  public cancelMusic(): void {
    AudioService.cancelBackgroundMusic();
    AudioService.cancelSoundtrack();
  }

  public cancelVoiceover(filePath?: string, speaker?: ISpeakerInfo): void {
    if (speaker && filePath) {
      const targetScene = this.getTargetScene(filePath, speaker);
      targetScene.cancelTalk(speaker);
    }
    AudioService.cancelVoiceover();
  }

  public pauseMusic(): void {
    AudioService.pauseBackgroundMusic();
    AudioService.pauseSoundtrack();
  }

  public pauseVoiceover(): void {
    AudioService.pauseVoiceover();
  }

  public async playMusic(
    data: IMusicRequest
  ): Promise<PlaybackResult | undefined> {
    const path = LxStormAudio.asAbsolute(data.path, this.musicPathPrefix);
    if (data.loop) {
      // Note: this returns the wrong PlaybackResult for possible current playback -
      // it returns 'Canceled' instead of 'Replaced', but return value isn't
      // being used here, so its a moot issue
      AudioService.cancelSoundtrack();

      return AudioService.playBackgroundMusic(path);
    }
    // Note: this returns the wrong PlaybackResult for possible current playback -
    // it returns 'Canceled' instead of 'Replaced', but return value isn't
    // being used here, so its a moot issue
    AudioService.cancelBackgroundMusic();

    return AudioService.playSoundtrack(path);
  }

  /* istanbul ignore next */
  public async playSfx(sfx: Sfx): Promise<PlaybackResult | undefined> {
    return AudioService.playSfx(sfx);
  }

  public async playVoiceover(
    filePath: string,
    transcript: string,
    speaker?: ISpeakerInfo
  ): Promise<PlaybackResult | undefined> {
    const url = LxStormAudio.asAbsolute(filePath, this.voiceoverPathPrefix);

    let speechDriver: StormNode | undefined;
    if (speaker) {
      const targetScene = this.getTargetScene(filePath, speaker);
      speechDriver = targetScene.talk(speaker);
    }
    const voData: IVoiceoverData = { transcript, url };
    const voOptions: IVoiceoverOptions = { speechDriver };

    return AudioService.playVoiceover(voData, voOptions);
  }

  public resumeMusic(): void {
    AudioService.resumeBackgroundMusic();
    AudioService.resumeSoundtrack();
  }

  public resumeVoiceover(): void {
    AudioService.resumeVoiceover();
  }

  public async reset(): Promise<void> {
    StormAudio.kill();
    await this.initialize();
  }

  private static asAbsolute(path: string, prefix: string) {
    const root = path.startsWith('/') ? '' : prefix;
    const ext = path.toLowerCase().endsWith('.opus') ? '' : '.opus';

    return `${root}${path}${ext}`.toLowerCase();
  }

  private getTargetScene(filePath: string, speaker: ISpeakerInfo): Scene {
    const targetScene = this.stormService.findSceneById(speaker.sceneId);
    if (!targetScene || !targetScene.active) {
      throw new LexiaError(
        `Target scene '${speaker.sceneId}' not found in active scene list`,
        LxStormAudio.displayName,
        LxStormAudioError.NoActiveScene
      ).withContext({
        filePath,
        scenes: this.stormService.loadedScenes.map(
          s => `${s.id} (${s.active ? '' : 'not '}active)`
        ),
        speaker
      });
    }

    return targetScene;
  }
}

export enum LxStormAudioError {
  UnregisteredSfx = 'UnregisteredSfx',
  NoActiveScene = 'NoActiveScene'
}
