import { G, Path, Rect, Svg } from 'common-ui/components/svg';
import { Color } from 'common-styles';
import { KeyNavHelper } from 'common-ui';
import { ActiveComponentState } from '@lexialearning/common-ui';

export const CLEVER_BLUE = '#2F66A8';

export function CleverButtonSvg({
  activeComponentState
}: {
  activeComponentState: ActiveComponentState;
}) {
  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
  const buttonFillColor = getFillColor(activeComponentState);
  const textColor = isKeyNav ? Color.Black : Color.White;
  const strokeColor = isKeyNav ? Color.Black : Color.Transparent;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      accessibilityLabel="Log in with Clever."
      height="61"
      width="282"
      style={{
        filter: 'drop-shadow(2px 3px 5px rgb(0 0 0 / 0.3))'
      }}
      data-test-id="cleverButtonSvg"
    >
      <Rect
        width="271"
        height="50"
        fill={buttonFillColor}
        x="3"
        y="3"
        rx="3"
        ry="3"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <G transform="translate(9 9)">
        <Rect width="37" height="38" fill={textColor} rx="3" />
        <Path
          fill={buttonFillColor}
          d="M19.672 32.802c-8.145 0-13.447-7-13.474-13.794v-.068a13.871 13.871 0 0 1 13.364-13.766c8.261-.075 11.261 5.086 12.235 6.313-1.975 1.438-4.059 2.928-4.059 2.928-3.26-3.95-5.269-4.179-8.025-4.179a8.844 8.844 0 0 0-8.5 8.8v.085a8.944 8.944 0 0 0 8.751 8.732 9.461 9.461 0 0 0 7.774-4.3l3.877 2.949a13.442 13.442 0 0 1-11.943 6.3"
        />
        <G fill={textColor}>
          <Path
            d="M70.928 24.265h7.29v1.589h-9.232V12.58h1.942v11.683Z"
            data-name="L"
          />
          <Path
            d="M84.168 15.907c3.344 0 4.866 2.607 4.866 5.1s-1.522 5.1-4.866 5.1-4.866-2.607-4.866-5.1 1.522-5.1 4.866-5.1Zm0 8.8c2.523 0 3.044-2.4 3.044-3.7s-.52-3.7-3.044-3.7-3.044 2.4-3.044 3.7.518 3.701 3.044 3.701Z"
            data-name="O"
          />
          <Path
            d="M99.729 25.059c0 1.387-.02 4.862-4.866 4.862-1.241 0-3.664-.314-4.006-2.884h1.763c.32 1.535 1.942 1.535 2.364 1.535 3.044 0 2.984-2.256 2.984-3.4v-.407a3.177 3.177 0 0 1-2.824 1.367c-3.564 0-4.606-3.088-4.606-5.4 0-2.717 1.583-4.825 4.365-4.825a3.634 3.634 0 0 1 3.163 1.7v-1.421h1.663v8.875Zm-4.546-.333c1.121 0 2.863-.685 2.863-3.254 0-1.571-.281-4.1-2.824-4.1-2.723 0-2.863 2.385-2.863 3.623.001 2.122.902 3.731 2.824 3.731Z"
            data-name="G"
          />
          <Path
            d="M107.781 14.428v-1.848h1.76v1.848Zm1.76 11.426h-1.76v-9.669h1.76Z"
            data-name="I"
          />
          <Path
            d="M738.717 694.854h-1.763v-5.953c0-1.683-.52-2.533-2.242-2.533-1 0-2.764.591-2.764 3.216v5.27h-1.764v-9.67h1.663v1.363h.039a3.857 3.857 0 0 1 3.163-1.645c1.622 0 3.666.611 3.666 3.365v6.581Z"
            data-name="Path 994"
          />
          <Path
            d="M120.717 25.854h-1.763v-5.953c0-1.683-.52-2.533-2.242-2.533-1 0-2.764.591-2.764 3.216v5.27h-1.764v-9.67h1.663v1.363h.039a3.857 3.857 0 0 1 3.163-1.645c1.4 0 3.666.611 3.666 3.365v6.581Z"
            data-name="N"
          />
          <Path
            d="M132.714 25.854h-1.822l-3.024-9.669h1.922l2 7.6h.039l2.022-7.6h1.963l2.042 7.6h.039l2.123-7.6h1.763l-3.044 9.669h-1.822l-2.123-7.487h-.039l-2.042 7.487Z"
            data-name="W"
          />
          <Path
            d="M143.389 14.428v-1.848h1.763v1.848Zm1.763 11.426h-1.763v-9.669h1.763Z"
            data-name="I"
          />
          <Path
            d="M149.957 17.534v6.286c0 .758.7.758 1.062.758h.62v1.276c-.64.056-1.141.13-1.321.13-1.742 0-2.123-.906-2.123-2.07v-6.379h-1.421v-1.349h1.422v-2.7h1.763v2.7h1.683v1.349h-1.683Z"
            data-name="T"
          />
          <Path
            d="M161.893 25.854h-1.763v-6.322c0-1.313-.4-2.164-2.083-2.164-1.441 0-2.924.776-2.924 3.216v5.27h-1.763V12.58h1.763v4.9h.039a3.763 3.763 0 0 1 3.065-1.571c1.65 0 3.666.611 3.666 3.365v6.581Z"
            data-name="H"
          />
          <Path
            d="M180.38 16.57c-.54-2.329-2.643-2.755-4.025-2.755-2.6 0-4.707 1.775-4.707 5.27 0 3.124 1.2 5.527 4.766 5.527 1.262 0 3.444-.555 4.106-3.641h1.883a5.98 5.98 0 0 1-6.289 5.232c-3.264 0-6.468-1.96-6.468-7.062 0-4.086 2.523-6.915 6.709-6.915 3.705 0 5.627 2.126 5.908 4.344Z"
            data-name="C"
          />
          <Path d="M186.327 25.854h-1.763V12.58h1.763Z" data-name="L" />
          <Path
            d="M197.863 22.822a4.142 4.142 0 0 1-1.862 2.643 4.784 4.784 0 0 1-2.843.665 4.494 4.494 0 0 1-4.687-4.844c0-2.994 1.563-5.38 5.027-5.38 3.024 0 4.506 2.218 4.506 5.639h-7.65c0 2.016 1.021 3.18 3.044 3.18a2.619 2.619 0 0 0 2.7-1.9h1.763Zm-1.683-2.566c-.1-1.5-.781-2.884-2.924-2.884a2.945 2.945 0 0 0-2.9 2.884Z"
            data-name="E"
          />
          <Path
            d="M206.134 16.185h1.922l-3.986 9.669h-1.883l-3.823-9.669h2.042l2.723 7.875h.039l2.966-7.875Z"
            data-name="V"
          />
          <Path
            d="M217.911 22.822a4.142 4.142 0 0 1-1.862 2.643 4.784 4.784 0 0 1-2.843.665 4.494 4.494 0 0 1-4.687-4.844c0-2.994 1.563-5.38 5.027-5.38 3.024 0 4.506 2.218 4.506 5.639h-7.65c0 2.016 1.021 3.18 3.044 3.18a2.619 2.619 0 0 0 2.7-1.9h1.763Zm-1.682-2.566c-.1-1.5-.781-2.884-2.924-2.884a2.945 2.945 0 0 0-2.9 2.884Z"
            data-name="E"
          />
          <Path
            d="M222.157 25.854h-1.763v-9.67h1.663v1.609h.04a3.412 3.412 0 0 1 2.943-1.886 1.522 1.522 0 0 1 .461.056v1.684h-.662a2.556 2.556 0 0 0-2.684 2.589v5.621Z"
            data-name="R"
          />
        </G>
      </G>
    </Svg>
  );
}
CleverButtonSvg.displayName = 'CleverButtonSvg';

function getFillColor(activeComponentState: ActiveComponentState) {
  return KeyNavHelper.isKeyNav(activeComponentState)
    ? Color.getSetFor(activeComponentState).background
    : CLEVER_BLUE;
}
