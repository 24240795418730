// istanbul ignore file - TODO: finish Addressing specs - LOBO-19187 -says missing coverage when tests are hitting the area supposedly missed
import * as React from 'react';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppShellAction, AppShellMode, AppShellSelector } from './redux';
import { AppShellStyles } from './AppShell.styles';
import { ControlPanel } from './control-panel';
import { Curtain } from './curtain/Curtain';
import { ILoboAppConfig } from 'services/app-config';
import { SpinnerToast } from './spinner-toast';
import { AutoFocusView, Text, View } from 'common-ui';
import { UtilityBar } from './utility-bar';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SkipButton } from './skip-button';
import { AvatarEditorScreen } from 'feature-areas/avatar-editor/AvatarEditorScreen';
import { AvatarEditorSelector } from 'feature-areas/avatar-editor/redux';

export interface IAppShellProps {
  children: React.ReactNode;
}

export function AppShell(props: IAppShellProps) {
  const config = useSelector(UniConfigSelector.getConfig<ILoboAppConfig>);
  const authUrl = config.lexiaService.customerUrl;
  const showAuthUrl = config.showAuthUrl;
  const mode = useSelector(AppShellSelector.getMode);
  const showSkipButton = useSelector(AppShellSelector.getShouldShowSkipButton);
  const showSpinner = useSelector(AppShellSelector.getShowSpinner);

  const location = useLocation();

  const dispatch = useDispatch();

  const notifyNavigationHistoryUpdate = useCallback(() => {
    dispatch(AppShellAction.notifyNavigationHistoryUpdate());
  }, [dispatch]);

  useEffect(() => {
    notifyNavigationHistoryUpdate();
  }, [notifyNavigationHistoryUpdate, location]);

  const { children } = props;

  const styles = AppShellStyles.get();

  const withUtilityBar = mode !== AppShellMode.None;
  const withControlPanel = mode === AppShellMode.Full;

  const shouldShowAvatarEditor = useSelector(
    AvatarEditorSelector.getShouldShowEditor
  );

  return (
    <>
      {withUtilityBar && <UtilityBar />}
      <AutoFocusView triggerFocus={window?.location?.pathname} />
      <View style={styles.content}>{children}</View>
      {withControlPanel && <ControlPanel />}
      {showSkipButton && <SkipButton />}
      {shouldShowAvatarEditor && <AvatarEditorScreen />}
      <SpinnerToast
        withBottomOffset={withControlPanel}
        shouldShow={showSpinner}
      />
      <Curtain />
      {showAuthUrl && (
        <Text testId="showAuthUrlView" style={styles.authUrlBar}>
          Auth Server: {authUrl}
        </Text>
      )}
    </>
  );
}
AppShell.displayName = 'AppShell';
