import { ThemeSize } from '@lexialearning/lobo-common';

export { ThemeSize };

export interface IThemeState {
  readonly theme?: {
    size?: ThemeSize;
    type?: ThemeType;
  };
}

export enum ThemeType {
  Instruction = 'Instruction',
  Placement = 'Placement',
  PoK = 'PoK',
  Standard = 'Standard'
}
