import { XYCoord } from 'react-dnd';
import { createSelector } from '@reduxjs/toolkit';
import { IDndForcedDragLayer, IDndReduxState } from './dnd-redux.model';

export class DndSelector {
  public static readonly displayName = 'DndSelector';

  public static getState: (state: unknown) => IDndReduxState;

  public static getDropTargetPosition: (state: unknown) => XYCoord | undefined;

  public static getIsDraggingOrDropping: (state: unknown) => boolean;

  public static getForcedDragLayer: (
    state: unknown
  ) => IDndForcedDragLayer | undefined;

  public static createSelectors(selector: (state: any) => IDndReduxState) {
    DndSelector.getState = createSelector(
      selector,
      (state: IDndReduxState) => state
    );
    DndSelector.getDropTargetPosition = createSelector(
      DndSelector.getState,
      (state: IDndReduxState) => state.dropTargetPosition
    );
    DndSelector.getIsDraggingOrDropping = createSelector(
      DndSelector.getState,
      (state: IDndReduxState) => state.isDraggingOrDropping
    );
    DndSelector.getForcedDragLayer = createSelector(
      DndSelector.getState,
      (state: IDndReduxState) => state.forcedDragLayer
    );
  }
}
