import { ITask, TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { cloneDeep } from 'lodash';
import { ITaskRegistration, TaskRegistrationBuilder } from '../core';
import { PlaceholderTask } from './PlaceholderTask';

export function createPlaceholderTaskRegistration(): ITaskRegistration<TaskTypeName.Placeholder> {
  return TaskRegistrationBuilder.create<TaskTypeName.Placeholder, ITask>(
    TaskTypeName.Placeholder,
    PlaceholderTask
  ).withPrepareContent(task =>
    cloneDeep({
      ...task,
      taskType: task.taskType || TaskTypeName.Placeholder
    })
  ).registration;
}
