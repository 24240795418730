import { Position } from '@lexialearning/common-ui';
import { DashboardAvatarStyles } from '../../DashboardAvatar.styles';

export class ToolTipButtonStyles {
  private static readonly svgSize = 12;
  private static readonly offset = 2;

  private static readonly styles = {
    button: {
      position: Position.Absolute,
      bottom:
        DashboardAvatarStyles.nameHeight -
        DashboardAvatarStyles.editButtonBottomOffset -
        this.offset,
      right: -(DashboardAvatarStyles.avatarSize / 2) - this.offset,
      width: DashboardAvatarStyles.avatarSize + this.offset,
      height: DashboardAvatarStyles.avatarSize + this.offset,
      padding: 0,
      paddingLeft:
        DashboardAvatarStyles.avatarSize - this.svgSize + this.offset,
      paddingTop: DashboardAvatarStyles.avatarSize - this.svgSize + this.offset
    },
    svg: {
      size: this.svgSize
    }
  };

  public static get() {
    return this.styles;
  }
}
