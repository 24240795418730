import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import {
  Defs,
  G,
  Line,
  Path,
  RadialGradient,
  Rect,
  Stop,
  Svg
} from 'common-ui';

export function InstructionFrameSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={900}
      height={750}
      viewBox="0 0 900 750"
      style={{ position: Position.Absolute, top: -210 }}
    >
      <Defs>
        <RadialGradient
          id="instruction-bg-radial-gradient"
          cx="450"
          cy="300"
          fx="450"
          fy="300"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopColor="#505050" />
          <Stop offset="1" stopColor="#262626" />
        </RadialGradient>
      </Defs>
      <G transform="translate(-63 107.5)">
        <Path
          d="M28,0H867.455a28,28,0,0,1,28,28V539.384a28,28,0,0,1-28,28H28a28,28,0,0,1-28-28V28A28,28,0,0,1,28,0Z"
          transform="translate(64 74.616)"
          stroke="#ffed03"
          strokeWidth="2"
          fill="url(#instruction-bg-radial-gradient)"
        />
        <Path
          d="M0,110V-40"
          transform="translate(515.5 -67.5)"
          fill="none"
          stroke="rgba(0,0,0,0.14)"
          strokeWidth="3"
        />
        <Path
          d="M0,110V-40"
          transform="translate(513.5 -67.5)"
          fill="none"
          stroke={Color.Yellow}
          strokeWidth="3"
        />
        <Path
          d="M817.894,3870.868c20.981.052,24.575,9.89,32.59,21.6,3.5,5.1,9.038,12.021,20.216,13.022-3.984-.007-6.921,1.328-11.964-.831-18.1-7.748-40.841-29.422-40.841-29.422Z"
          transform="translate(-305.233 -3830.77)"
          fill="#ffed03"
        />
        <Path
          d="M870.7,3870.868c-20.981.052-24.575,9.89-32.589,21.6-3.5,5.1-9.038,12.021-20.216,13.022,3.984-.007,6.921,1.328,11.964-.831,18.1-7.748,40.841-29.422,40.841-29.422Z"
          transform="translate(-356.233 -3830.77)"
          fill="#ffed03"
        />
        <Path
          d="M47.751,34.315h0l-.875,0-.876,0v-.007c-4.65-.017-28.729-.107-46-.107,10.667-3.145,15.191-10.345,19.566-17.308,2.755-4.384,5.357-8.526,9.373-11.673A22.594,22.594,0,0,1,36.16,1.43,33.748,33.748,0,0,1,46.484,0l.392,0,.392,0A33.759,33.759,0,0,1,57.593,1.43a22.594,22.594,0,0,1,7.222,3.79c4.016,3.148,6.617,7.289,9.372,11.674,4.374,6.963,8.9,14.162,19.564,17.307l-.617,0c-15.738,0-36.981.074-45.383.105v.006ZM46.758,5.6a6,6,0,1,0,6,6A6.007,6.007,0,0,0,46.758,5.6Z"
          transform="translate(466.616 41.857)"
          fill="#323232"
        />
        <Line
          x1="4"
          y1="9"
          transform="translate(508.5 40.5)"
          fill="none"
          stroke={Color.Yellow}
          strokeWidth="3"
        />
        <Line
          x1="1"
          y2="8"
          transform="translate(516.5 41.5)"
          fill="none"
          stroke={Color.Yellow}
          strokeWidth="3"
        />
        <Line
          x1="4"
          y1="9"
          transform="translate(513.16 40.087) rotate(20)"
          fill="none"
          stroke={Color.Yellow}
          strokeidth="3"
        />
        <G
          transform="translate(74 85.616)"
          fill="none"
          stroke="#ffed03"
          strokeWidth="4"
          strokeDasharray="10"
        >
          <Rect width={875.455} height={545.658} rx="25" stroke="none" />
          <Rect
            x="2"
            y="2"
            width={871.455}
            height={541.658}
            rx="23"
            fill="none"
          />
        </G>
      </G>
    </Svg>
  );
}
InstructionFrameSvg.displayName = 'InstructionFrameSvg';
