import { ActiveComponentState } from '@lexialearning/common-ui';
import { Color, InteractionState } from 'common-styles';
import { KeyNavHelper } from 'common-ui';

export class PillSvgStyles {
  public static build(
    activeComponentState: ActiveComponentState,
    interactionState: InteractionState,
    isChecked: boolean
  ) {
    const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
    const isKeyNavAndChecked = isKeyNav && isChecked;
    const isHighlighted = interactionState === InteractionState.Highlighted;
    const isPressed = [
      ActiveComponentState.Pressed,
      ActiveComponentState.KeyboardPressed
    ].includes(activeComponentState);

    return {
      background: {
        fill: isHighlighted
          ? Color.Yellow
          : isKeyNavAndChecked
          ? Color.Blue
          : isPressed
          ? Color.Gray7
          : Color.NearWhite
      },
      checkmark: {
        display: isChecked ? 'inline' : 'none',
        fill:
          interactionState !== InteractionState.Default
            ? Color.getSetFor(interactionState).border
            : isKeyNav
            ? Color.NearWhite
            : Color.Blue
      },
      shadow: {
        display: isKeyNavAndChecked ? 'none' : 'inline',
        fill: isHighlighted ? Color.Yellow1 : Color.Gray10
      }
    };
  }
}
