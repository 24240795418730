import { ILogger } from '@lexialearning/main-model';
import { StormSfxScreenplayActionFactory, StormSfxScreenplayer } from 'audio';
import { MusicScreenplayer, MusicScreenplayerActionFactory } from 'audio/music';
import {
  CallbackPlayer,
  DelayActionPlayer,
  ReactAnimationActionPlayer,
  ScreenplayBuilder,
  Screenplayer,
  ScreenplayerType
} from 'screenplay';
import { Services } from 'services/Services';
import {
  LxStormAudio,
  StormAnimationActionFactory,
  StormAnimationScreenplayer,
  StormService
} from 'storm';
import { VoiceoverScreenplayer } from 'voiceovers';

export class ScreenplayerFactory {
  public static create(
    stormService: StormService,
    stormAudioPlayer: LxStormAudio,
    logger: ILogger
  ): Screenplayer {
    ScreenplayBuilder.defaultActionFactories.set(
      ScreenplayerType.Music,
      new MusicScreenplayerActionFactory()
    );
    ScreenplayBuilder.defaultActionFactories.set(
      ScreenplayerType.Sfx,
      new StormSfxScreenplayActionFactory()
    );
    ScreenplayBuilder.defaultActionFactories.set(
      ScreenplayerType.StormAnimation,
      new StormAnimationActionFactory()
    );

    const reactAnimationScreenplayer = new ReactAnimationActionPlayer(logger);
    Services.reactAnimationScreenplayer = reactAnimationScreenplayer;

    return new Screenplayer(
      [
        reactAnimationScreenplayer,
        new CallbackPlayer(),
        new DelayActionPlayer(),
        new MusicScreenplayer(stormAudioPlayer),
        new StormAnimationScreenplayer(stormService),
        new StormSfxScreenplayer(stormAudioPlayer),
        new VoiceoverScreenplayer(stormAudioPlayer, logger)
      ],
      logger
    );
  }
}
