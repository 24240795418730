import { TextPillWidth } from '@lexialearning/lobo-common/main-model';
import { Row, Text } from 'common-ui';
import { ChoiceContainer } from '../../../shared';
import { IChoiceProps } from '../../../shared/choice.model';
import { TextChoiceStyles } from './TextChoice.styles';
import { useChoiceState } from 'task-components/shared/useChoiceState';

export interface ITextChoiceProps extends IChoiceProps {
  textPillWidth: TextPillWidth;
}

export function TextChoice(props: ITextChoiceProps) {
  const { sysId, isCorrectChoice, text = '', textPillWidth } = props;

  const { interactionState } = useChoiceState(props);

  const styles = TextChoiceStyles.build(interactionState, textPillWidth);
  const textSplit = text.split('|');

  return (
    <ChoiceContainer
      sysId={sysId}
      isCorrectChoice={isCorrectChoice}
      styleOverride={styles.choiceContainerStyleOverride}
    >
      {textSplit.length === 1 ? (
        <Text>{text}</Text>
      ) : (
        <Row>
          <Text style={styles.leftPipe}>{textSplit[0].trim()}</Text>
          <Text style={styles.pipe}>|</Text>
          <Text style={styles.rightPipe}>{textSplit[1].trim()}</Text>
        </Row>
      )}
    </ChoiceContainer>
  );
}
TextChoice.displayName = 'TextChoice';
