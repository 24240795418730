import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { StormNode, StormTexture } from '@lexialearning/storm-react';
import { LexiaError } from '@lexialearning/utils';
import { shuffle } from 'lodash';
import { ISpeakerInfo } from 'audio';
import { IApplyTextureOptions, MaterialChannelName } from '../materials';
import { LxStormNode } from '../service';
import { IStormNodeAnimationRequest } from '../service/LxStormNode';
import { SceneElement } from './SceneElement';
import {
  IMaterialDefinition,
  ITalkInfo,
  TalkEventHandler
} from './storm-scenes.model';

export abstract class SceneBase<TChild extends SceneElement> {
  public static readonly displayName: string = 'SceneBase';

  public readonly elementMap: Map<string, TChild>;

  public get isAttached(): boolean {
    return this.node.isAttached;
  }

  public get name(): string {
    return this.node.stormNode.name || '(n/a)';
  }

  /**
   * Meant to be replaced with a custom handler that can initiate animations
   * on the speaker of the scene.
   */
  public onTalk: TalkEventHandler = () => void 0;

  /**
   * Meant to be replaced with a custom handler that can respond to talking
   * being canceled
   */
  public onCancelTalking: TalkEventHandler = () => void 0;

  private readonly animationLayers: ISceneAnimationLayer[] = [];

  protected constructor(
    public readonly placeholderName: string,
    public readonly node: LxStormNode,
    elements: TChild[],
    private readonly animationDefinitions: IAnimationDefinition[],
    private readonly materialDefinitions: IMaterialDefinition[]
  ) {
    this.elementMap = elements.reduce((elementMap, e) => {
      e.parent = this;

      return elementMap.set(e.placeholderName, e);
    }, new Map<string, TChild>());

    this.maybeAutoplayAnimation();
  }

  protected maybeAutoplayAnimation(): void {
    const autoplay = this.node.isAttached
      ? this.pickOneAutoplayAnimation()
      : undefined;
    if (autoplay) {
      this.startAnimation(autoplay);
    }
  }

  private pickOneAutoplayAnimation(): IAnimationDefinition | undefined {
    const autoplayAnimations = this.animationDefinitions.filter(
      a => a.autoplay
    );
    if (!autoplayAnimations.length) {
      return;
    }

    return shuffle(autoplayAnimations)[0];
  }

  public applyTexture(
    texture: StormTexture,
    options?: Partial<IApplyTextureOptions>
  ): void {
    if (!this.materialDefinitions.length) {
      throw new LexiaError(
        'Cannot apply a texture to a node lacking defined materials',
        SceneBase.displayName,
        SceneBaseError.InvalidTextureOp
      ).withContext({ scene: this.name });
    }

    const opt: IApplyTextureOptions = {
      channel: MaterialChannelName.Diffuse,
      index: 0,
      materialName: this.materialDefinitions[0].name,
      ...options
    };
    if (!this.materialDefinitions.map(d => d.name).includes(opt.materialName)) {
      throw new LexiaError(
        `Material ${opt.materialName} does not exist in element ${this.name}`,
        SceneBase.displayName,
        SceneBaseError.InvalidMaterialName
      ).withContext({
        material: opt.materialName,
        materials: this.materialDefinitions.map(d => d.name),
        scene: this.name
      });
    }

    this.node.applyTexture(texture, opt);
  }

  /**
   * Randomly select one animation definition matching the specified category.
   */
  public pickOneAnimation(category: string): IAnimationDefinition {
    const matches = this.animationDefinitions.filter(
      a => a.category === category
    );

    if (!matches.length) {
      throw new LexiaError(
        `No animation definitions for category ${category}`,
        SceneBase.displayName,
        SceneBaseError.MissingAnimations
      ).withContext({
        animations: this.animationDefinitions,
        scene: this.name
      });
    }

    return shuffle(matches)[0];
  }

  /**
   * Play the requested animation, returning a promise that resolves when it
   * ends. If the request specifies a targetElement not matching this placeholderName,
   * play it on the target element, which is expected to be a direct child.
   * (Playing animations on less direct descendants is not currently supported)
   */
  public async playAnimation(request: ISceneAnimationRequest): Promise<void> {
    const target = this.getAndMaybeAttachTarget(request);
    const layer = target.getAnimationLayer(request.animationLayer ?? 0);

    if (layer.resolve !== undefined) {
      layer.resolve();
      layer.resolve = undefined;
      layer.request = undefined;
    }

    layer.request = request;
    target.updateAnimationLayers();

    return new Promise<void>(resolve => {
      layer!.resolve = resolve;
    });
  }

  /**
   * Pause the animation playing on the specified layer
   */
  public pauseAnimation(request: ISceneAnimationRequest) {
    const target = this.getAndMaybeAttachTarget(request);
    target.node.pause(request.name);
  }

  /**
   * Resume the animation - reset speed to 1 or that of original request
   * - Will do nothing if speed prop of request is 0
   */
  public resumeAnimation(request: ISceneAnimationRequest): void {
    if (request.speed !== 0) {
      const target = this.getAndMaybeAttachTarget(request);

      target.node.resume(request.name, request.speed);
    }
  }

  /**
   * Kick off the requested animation, but do not wait for it to complete.
   * @see playAnimation
   */
  public startAnimation(request: ISceneAnimationRequest): void {
    void this.playAnimation(request);
  }

  /**
   * Stop the animation playing on the specified layer
   */
  public stopAnimation(request: ISceneAnimationRequest) {
    const target = this.getTargetMaybe(request);

    // If the scene on which the given animation was playing is already torn down,
    // target element may not be found, but this is ok
    // as the animation will have been torn down already with the scene
    if (target) {
      const layer = target.animationLayers.find(
        l => l.id === (request.animationLayer ?? 0)
      );

      if (layer) {
        target.node.cancel(request.name);
        target.finishAnimationLayer(layer);
      }
    }
  }

  private getTargetMaybe(
    request: ISceneAnimationRequest
  ): SceneBase<SceneElement> | undefined {
    if (
      request.targetElement &&
      request.targetElement !== this.placeholderName
    ) {
      const element = this.getElementMaybe(request.targetElement);

      return element;
    }

    return this;
  }

  /**
   * Internal helper to get or create a layer
   */
  private getAnimationLayer(layerId: number): ISceneAnimationLayer {
    let layer = this.animationLayers.find(l => l.id === layerId);
    if (!layer) {
      layer = {
        id: layerId,
        request: undefined,
        resolve: undefined
      };
      this.animationLayers.push(layer);
      this.animationLayers.sort((a, b) => a.id - b.id);
    }

    return layer;
  }

  /**
   * Get target element for the given animation request.
   * If target element is undefined or equals the current element's placeholder,
   * then target is current SceneBase element, eg, scene's root.
   * (Also attaches target child element if is not attached)
   */
  private getAndMaybeAttachTarget(
    request: ISceneAnimationRequest
  ): SceneBase<SceneElement> {
    if (
      request.targetElement &&
      request.targetElement !== this.placeholderName
    ) {
      const element = this.getElement(request.targetElement);

      if (!element.node.isAttached) {
        this.node.attachChild(element.placeholderName, element.node);
      }

      return element;
    }

    return this;
  }

  /**
   * Internal helper that triggers when an animation in the specified layer finishes
   */
  private finishAnimationLayer(layer: ISceneAnimationLayer) {
    if (layer.resolve) {
      layer.resolve();
      layer.resolve = undefined;
    }

    layer.request = undefined;

    this.updateAnimationLayers();
  }

  /**
   * Internal helper that ensures the top-most animation layer is playing
   */
  private updateAnimationLayers() {
    // Walk through the layers top to bottom
    let lastLayerIdx = this.animationLayers.length - 1;
    while (lastLayerIdx >= 0) {
      const layer = this.animationLayers[lastLayerIdx];

      // Find the top-most layer that has an animation request and play it
      if (layer?.request) {
        this.node.animate(layer.request).then(
          layer.request.loop
            ? () => {
                // do nothing for looping animations
              }
            : () => {
                this.finishAnimationLayer(layer);
              },
          // istanbul ignore next: will never hit because animate throws if animation is invalid
          () => {
            // Always ignore reject
          }
        );

        return;
      }

      lastLayerIdx -= 1;
    }
  }

  /**
   * Calls onTalk on the speaker node
   */
  public talk(speaker: ISpeakerInfo): StormNode {
    if (speaker.speakerId) {
      return this.getElement(speaker.speakerId).talk({
        ...speaker,
        speakerId: ''
      });
    }

    const info: ITalkInfo = {
      audioLength: -1,
      directions: speaker.directions // TODO need the length?
    };
    this.onTalk(info);

    return this.node.stormNode;
  }

  /**
   * Calls onCancelTalking on the speaker node
   */
  public cancelTalk(speaker: ISpeakerInfo): StormNode {
    if (speaker.speakerId) {
      return this.getElement(speaker.speakerId).cancelTalk({
        ...speaker,
        speakerId: ''
      });
    }

    const info: ITalkInfo = {
      audioLength: -1,
      directions: speaker.directions // TODO need the length?
    };
    this.onCancelTalking(info);

    return this.node.stormNode;
  }

  private getElement(placeholderName: string): TChild {
    const element = this.getElementMaybe(placeholderName);
    if (!element) {
      throw new LexiaError(
        `Scene lacks target element ${placeholderName}`,
        SceneBase.displayName,
        SceneBaseError.InvalidTargetElement
      ).withContext({ placeholderName, scene: this.name });
    }

    return element;
  }

  private getElementMaybe(placeholderName: string): TChild | undefined {
    return this.elementMap.get(placeholderName);
  }
}

export interface ISceneAnimationRequest extends IStormNodeAnimationRequest {
  targetElement?: string;
  animationLayer?: number;
}

export interface ISceneAnimationLayer {
  id: number;
  request: IStormNodeAnimationRequest | undefined;
  resolve: (() => void) | undefined;
}

export enum SceneBaseError {
  InvalidTargetElement = 'InvalidTargetElement',
  InvalidMaterialName = 'InvalidMaterialName',
  InvalidTextureOp = 'InvalidTextureOp',
  MissingAnimations = 'MissingAnimations',
  UnknownAnimation = 'UnknownAnimation'
}
