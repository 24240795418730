import * as React from 'react';
import { connect } from 'react-redux';
import { InteractionState } from 'common-styles';
import {
  IUserControlButtonStyleOverride,
  MicButton,
  MicButtonType,
  MicSelector,
  Types
} from 'common-ui';

export interface IFauxMicButtonProps {
  disabled?: boolean;
  interactionState: InteractionState;
  onPress(): void;
  registerAnimation?(animation: Types.Animated.CompositeAnimation): () => void;
  styleOverride?: IUserControlButtonStyleOverride;
}

export class FauxMicButtonComponent extends React.PureComponent<IFauxMicButtonProps> {
  public static readonly displayName = 'FauxMicButton';

  private get isDisabled() {
    const { disabled } = this.props;

    return this.interactionState !== InteractionState.Default || disabled;
  }

  private get interactionState() {
    const { interactionState } = this.props;

    return [
      InteractionState.Disabled,
      InteractionState.Highlighted,
      InteractionState.Correct
    ].includes(interactionState)
      ? interactionState
      : InteractionState.Default;
  }

  public render() {
    const { onPress, registerAnimation, styleOverride } = this.props;

    return (
      <MicButton
        type={MicButtonType.UserControl}
        styleOverride={styleOverride}
        interactionState={this.interactionState}
        onPress={onPress}
        disabled={this.isDisabled}
        immediateCallout={false}
        registerAnimation={registerAnimation}
      />
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    interactionState: MicSelector.getInteractionState(state)
  };
}

/**
 * Mic button that visually responds to mic interaction states Highlighted and Correct from redux,
 * but does not put the app into listening mode
 * - intended to be used with a screenplay that includes the MockMicListenScreenplayFactory,
 */
export const FauxMicButton = connect(mapStateToProps)(FauxMicButtonComponent);
