import { useSelector } from 'react-redux';
import { ThemeSize, ThemeType } from './theme.model';
import { ThemeSelector } from './Theme.selector';

export interface IThemeProps {
  themeSize: ThemeSize;
  themeType: ThemeType;
}

export function useTheme() {
  const themeSize = useSelector(ThemeSelector.getThemeSize);
  const themeType = useSelector(ThemeSelector.getThemeType);

  return {
    themeSize,
    themeType
  };
}
