import { IScreenplay } from '@lexialearning/lobo-common';
import { Music } from 'audio/music';
import { ProgramContextAction } from 'curriculum-services';
import {
  PlacementSceneAnimationName,
  PlacementSceneElementName
} from 'feature-areas/placement/placement-scene/placement-scene.model';
import { RouteBuilder, RouterService } from 'router-service';
import { SceneName } from 'services/storm-lobo';
import { PlacementScreenplayId } from './placement-screenplay-builders.model';
import { PlacementScreenplayBuilderBase } from './PlacementScreenplayBuilderBase';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=ELKMK&title=Student+Placement
 */
export class PlacementOutroScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'PlacementOutroScreenplayBuilder';

  public static create(): PlacementOutroScreenplayBuilder {
    return new PlacementOutroScreenplayBuilder();
  }

  private constructor() {
    super(PlacementScreenplayId.Outro);
  }

  public routeToPlacementComplete() {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.placementComplete());
    });

    return this;
  }

  public dispatchStartProgram() {
    this.builder.addReduxAction(ProgramContextAction.startProgram());

    return this;
  }

  public animateConfettiWithMusic(): PlacementOutroScreenplayBuilder {
    this.builder
      .addMusic({ path: Music.PlacementComplete }, { concurrent: true })
      .addStormAnimation(
        {
          name: PlacementSceneAnimationName.Overlay.Intro,
          targetElement: PlacementSceneElementName.Overlay,
          targetScene: SceneName.Placement
        },
        { concurrent: true }
      );

    return this;
  }

  public playCongratulationsVo(
    placementCompletionVo: IScreenplay
  ): PlacementOutroScreenplayBuilder {
    this.builder.addActionList(this.addSpeaker(placementCompletionVo));

    return this;
  }
}
