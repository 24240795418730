import { IProfile } from '../profile.model';

export enum ProfileActionType {
  LoadSuccess = 'PROFILE.LOAD_SUCCESS',
  UpdateAvatar = 'PROFILE.UPDATE_AVATAR',
  UpdateProfile = 'PROFILE.UPDATE_PROFILE'
}

export interface IProfileState {
  profile: IProfile | undefined;
}
