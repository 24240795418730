import { cancelListenScreenplayOnShowModalEpic } from './cancelListenScreenplayOnShowModal.epic';
import { evaluateSreSessionEpic } from './evaluateSreSession.epic';
import { maybeRecalibrateEpic } from './maybeRecalibrate.epic';
import { setSreOptionsEpic } from './setSreOptions.epic';
import { toggleListenEpic } from './toggleListen.epic';

export const seeSpeakEpics = [
  cancelListenScreenplayOnShowModalEpic,
  evaluateSreSessionEpic,
  maybeRecalibrateEpic,
  setSreOptionsEpic,
  toggleListenEpic
];
