import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  TransitionAction,
  TransitionActionEducatorToEncounter
} from 'feature-areas/transitions';
import {
  DevToolsAction,
  DevToolsActionHide,
  DevToolsActionShortcutToRound
} from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

type OutputActions = DevToolsActionHide | TransitionActionEducatorToEncounter;

export function shortcutToRoundEpic(
  action$: Observable<DevToolsActionShortcutToRound>
): Observable<OutputActions> {
  return action$.pipe(
    ofType(DevToolsActionType.ShortcutToRound),
    mergeMap(action =>
      from([
        DevToolsAction.hide(),
        TransitionAction.educatorToEncounter(action.payload)
      ])
    )
  );
}
shortcutToRoundEpic.displayName = 'shortcutToRoundEpic';
