import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import program_downPng from './program_down.png';

export class ProgramDownModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    headline: 'We are temporarily down.',
    id: 'ProgramDownModal',
    image: program_downPng,
    text: 'We are working to fix the problem and will be back soon.',
    title: 'Oops!',
    type: ModalType.Error
  });
}
