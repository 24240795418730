import {
  ColumnStyle as CuiColumnStyle,
  Cursor,
  Overflow,
  Position,
  RowStyle as CuiRowStyle
} from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  Justify,
  loboAnimated,
  loboViewStyle
} from 'common-styles';
import { Types } from 'common-ui';

const DURATION_MILLIS = 150;
const MAX_OFFSET = 50;

export class SpinnerToastAnimatedStyles {
  private readonly animations: {
    show: Types.Animated.CompositeAnimation;
    hide: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
    outerContainer: CuiColumnStyle;
    innerContainer: CuiRowStyle;
    label: Types.TextStyle;
  };

  constructor() {
    const opacity = loboAnimated.createValue(0);
    const translateY = loboAnimated.createValue(MAX_OFFSET);

    const show = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
        duration: DURATION_MILLIS,
        easing: loboAnimated.Easing.Out(),
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: DURATION_MILLIS,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      })
    ]);

    const hide = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
        duration: DURATION_MILLIS,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: DURATION_MILLIS,
        easing: loboAnimated.Easing.Out(),
        toValue: MAX_OFFSET
      })
    ]);

    this.styles = {
      containerAnimated: {
        opacity,
        transform: [{ translateY }]
      },
      innerContainer: {
        alignItems: Align.Center,
        backgroundColor: Color.Gray60,
        borderRadius: 10,
        cursor: Cursor.Default,
        height: 60,
        justifyContent: Justify.Center,
        overflow: Overflow.Visible,
        padding: 10,
        width: 230
      },
      label: {
        color: Color.NearWhite,
        fontSize: 20,
        marginHorizontal: 10
      },
      outerContainer: {
        alignItems: Align.Center,
        cursor: Cursor.Default,
        justifyContent: Justify.Center,
        overflow: Overflow.Visible
      }
    };

    this.animations = {
      hide,
      show
    };
  }

  public build = memoizeOne((withBottomOffset: boolean) => ({
    ...this.styles,
    container: {
      alignSelf: Align.Center,
      bottom: withBottomOffset ? 55 : 5,
      position: Position.Absolute
    },
    innerContainer: loboViewStyle(this.styles.innerContainer) as CuiRowStyle,
    outerContainer: loboViewStyle(this.styles.outerContainer) as CuiColumnStyle
  }));

  public getAnimations() {
    return this.animations;
  }
}
