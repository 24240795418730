import { LexiaError } from '@lexialearning/utils';
import {
  IErrorModal,
  IModalDefinition,
  ModalActionShow,
  ModalType
} from '@lexialearning/utils-react';
import { loboErrorModalMap } from './loboErrorModalMap';

export enum SuppressorErrorModalError {
  InvalidShowCall = 'InvalidShowCall'
}

/**
 * Used to suppress Uni Error Modals that should still be handled by Lobo.
 */
export class SuppressorErrorModal implements IErrorModal {
  public static readonly displayName = 'SuppressorErrorModal';

  public static readonly Id = SuppressorErrorModal.displayName;

  public readonly id = SuppressorErrorModal.Id;

  public createDefinition(): IModalDefinition {
    return { id: this.id, type: ModalType.Error };
  }

  /**
   * Return true for errors that should be handled by Lobo, not Uni
   */
  public match(error: LexiaError): boolean {
    return loboErrorModalMap.has(error.standardCode);
  }

  public shouldSuppress(): boolean {
    return true;
  }

  public show(error: LexiaError): ModalActionShow {
    throw new LexiaError(
      'This method should never be called!',
      SuppressorErrorModal.displayName,
      SuppressorErrorModalError.InvalidShowCall
    ).withContext({ error });
  }
}
