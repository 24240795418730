import { LexiaError } from '@lexialearning/utils';
import * as React from 'react';
import { SpinnerHandler } from './SpinnerHandler';

export interface ISpinnerProps {
  isPageReady: boolean;
}

/**
 * @deprecated use useSpinner, this should be removed once all uses of it are migrated to hooks
 */
export function withSpinner<T>(WrappedComponent: any) {
  return class extends React.PureComponent<T & ISpinnerProps> {
    public static readonly displayName = 'SpinnerHOC';

    public componentDidMount() {
      const { isPageReady } = this.props;
      if (isPageReady === undefined) {
        throw new LexiaError(
          `isPageReady is not defined for component ${WrappedComponent.displayName}`,
          withSpinner.displayName,
          WithSpinnerError.IsPageReadyUndefined
        );
      }
      if (!isPageReady) {
        SpinnerHandler.requestSpinner();
      }
    }

    public componentDidUpdate(prevProps: ISpinnerProps) {
      const { isPageReady } = this.props;
      if (prevProps.isPageReady && !isPageReady) {
        SpinnerHandler.requestSpinner();
      }
      if (!prevProps.isPageReady && isPageReady) {
        SpinnerHandler.dismissSpinner();
      }
    }

    public componentWillUnmount() {
      const { isPageReady } = this.props;
      if (!isPageReady) {
        SpinnerHandler.dismissSpinner();
      }
    }

    public render() {
      const { isPageReady, ...restProps } = this.props;

      if (!isPageReady) {
        return null;
      }

      return <WrappedComponent {...restProps} />;
    }
  };
}
withSpinner.displayName = 'withSpinner';

export enum WithSpinnerError {
  IsPageReadyUndefined = 'IsPageReadyUndefined'
}
