import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  AnimationDefinitionCmsTransformer,
  CmsService,
  ContentProviderFactory,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import {
  FileFilter,
  Mount,
  StormAudio,
  StormEngineControl as StormPlayerControl,
  StormTexture
} from '@lexialearning/storm-react';
import { ObservableHelper } from 'utils';
import { AnimationDefinitions } from '../animations';
import {
  IMountPoint,
  IStormAssetFolders,
  IStormConfig,
  STORM_CONFIG_KEY
} from '../config';
import { ISceneDefinition, Scene, SceneFactory } from '../scenes';
import { LxStormEngineControl } from './LxStormEngineControl';

export class StormService {
  public static readonly displayName = 'StormService';

  public static async initialize(ctrl: StormPlayerControl): Promise<void> {
    await this.instance.initialize(ctrl);
  }

  private static instance: StormService;

  private _loadedScenes: Scene[] = [];

  public get loadedScenes(): Scene[] {
    return this._loadedScenes;
  }

  public animationDefinitions = new AnimationDefinitions([]);

  private readonly initializationObservableHelper =
    new ObservableHelper<boolean>(false);

  public get initialization(): Promise<void> {
    return this.initializationObservableHelper.valuePromise.then(() => void 0);
  }

  public get isInitialized(): boolean {
    return this.initializationObservableHelper.value;
  }

  private readonly ctrl: LxStormEngineControl;

  private sceneFactory!: SceneFactory;

  constructor(
    private readonly configProvider: IConfigProvider,
    private readonly contentProviderFactory: ContentProviderFactory,
    cmsService: CmsService
  ) {
    this.ctrl = new LxStormEngineControl();
    cmsService.registerTransformer(new AnimationDefinitionCmsTransformer());
    StormService.instance = this;
  }

  private async initialize(ctrl: StormPlayerControl): Promise<void> {
    this.ctrl.initialize(ctrl);
    this.sceneFactory = new SceneFactory(this, this.ctrl);

    await this.loadAnimationDefinitions();
    this.initializationObservableHelper.value = true;
  }

  private async loadAnimationDefinitions(): Promise<void> {
    const provider = this.contentProviderFactory.create();
    const defs = await provider.loadByContentType<IAnimationDefinition>(
      LoboContentType.AnimationDefinition
    );
    this.animationDefinitions = new AnimationDefinitions(defs);
  }

  public getAssetPaths<T extends IStormAssetFolders>(): T {
    return this.getConfig().assetPaths as T;
  }

  /**
   * Creates a new StormAudio instance.
   *
   * @param shouldProcessMarkers - Should the playback consider markers existing in the loaded file(s).
   *    For example, for lipsync
   * @param decodeOnLoad - Should the audio be decoded in full on load, trading higher memory usage for decreased cpu usage and latency?
   *    For example, sfx.
   */
  public async createAudioPlayer(
    shouldProcessMarkers: boolean,
    decodeOnLoad: boolean
  ): Promise<StormAudio> {
    await this.initialization;

    return this.ctrl.createAudio(shouldProcessMarkers, decodeOnLoad);
  }

  public getConfig(): IStormConfig {
    return this.configProvider.getConfig<IStormConfig>(STORM_CONFIG_KEY);
  }

  public async prepareScene(definition: ISceneDefinition): Promise<Scene> {
    await this.initialization;

    // Unload any existing scene with the same ID
    const prevScene = this.findSceneById(definition.id);
    if (prevScene) {
      this.unloadScene(prevScene);
    }

    // Now load the incoming scene
    const scene = await this.sceneFactory.create(definition);
    this._loadedScenes.push(scene);

    return scene;
  }

  public unloadScene(scene: Scene): void {
    this._loadedScenes = this._loadedScenes.filter(s => s !== scene);

    [scene, ...Array.from(scene.elementMap.values())]
      .map(e => e.node)
      .forEach(lxNode => {
        this.ctrl.unloadScene(lxNode.stormNode);
      });
  }

  public unloadAllScenes(): void {
    this.ctrl.unloadAllScenes();
    this._loadedScenes = [];
  }

  public loadTexture(path: string): StormTexture {
    return this.ctrl.loadTexture(path);
  }

  public async mount(mountPoint: IMountPoint): Promise<Mount> {
    await this.initialization;

    return this.ctrl.mount(mountPoint);
  }

  public unmount(mount: Mount): void {
    this.ctrl.unmount(mount);
  }

  /**
   * Preload all files (with implicit or explicit extensions) in the given folder path.
   * If the given path is to a specific file (i.e. the last path element includes a .),
   * consider the parent folder as the path to use.
   *
   * @param path path to file or folder starting with the mount point name
   * @param files optional array of file extensions to include (otherwise use default list)
   */
  public async preload(path: string, files?: string[]) {
    await this.initialization;

    const fileList = ['.tx', '.opus', '.sga'].some(ext => path.endsWith(ext))
      ? [path.slice(path.lastIndexOf('/') + 1)]
      : files;
    const fileFilter = fileList && new FileFilter(...fileList);

    await this.ctrl.preload(this.getFolder(path), fileFilter);
  }

  private getFolder(path: string): string {
    if (!path) {
      return '';
    }

    const index = path.lastIndexOf('/');
    const endOfPath = index >= 0 ? path.substring(index) : '';
    const filename = endOfPath.includes('.');

    return filename ? path.slice(0, index) : path;
  }

  public findSceneById(id: string): Scene | undefined {
    return this._loadedScenes.find(s => s.id === id);
  }
}
