import {
  ChoicesLayout,
  IYesNo,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { Column } from 'common-ui/components/layout';
import { SpotlightArea } from 'common-ui/components/spotlight-area';
import { ITaskProps, TaskAction } from 'task-components';
import { ThemeType, withTheme } from 'theme';
import { Choices } from '../choices';
import { YesNoStyles } from './YesNo.styles';

export interface IYesNoProps extends ITaskProps<IYesNo> {
  themeType: ThemeType;
  clearSelectedChoices(): void;
}

export class YesNoComponent extends React.PureComponent<IYesNoProps> {
  public static readonly displayName = 'YesNo';

  public componentDidUpdate(prevProps: IYesNoProps) {
    if (
      prevProps.phase === TaskPhase.Feedback &&
      this.props.phase === TaskPhase.Interactive
    ) {
      this.props.clearSelectedChoices();
    }
  }

  public render() {
    const { choices, instructionalImage, spotlights } = this.props.taskContent;
    const hasSpotlightArea = !!(instructionalImage || spotlights.length);
    const styles = YesNoStyles.build(
      this.props.themeType === ThemeType.Instruction,
      hasSpotlightArea
    );

    return (
      <Column style={styles.container}>
        {hasSpotlightArea && (
          <SpotlightArea {...{ instructionalImage, spotlights }} />
        )}
        <Choices
          choices={choices}
          choicesLayout={ChoicesLayout.YesNo}
          onEvaluated={this.props.onEvaluated}
        />
      </Column>
    );
  }
}

// istanbul ignore next --trivial
const mapDispatchToProps = {
  clearSelectedChoices: () => TaskAction.clearSelectedChoices()
};

export const YesNo = withTheme(
  connect(undefined, mapDispatchToProps)(YesNoComponent)
);
