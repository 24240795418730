import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { SpinnerHandler } from 'spinner-handler';
import {
  IScreenplayActionPlayer,
  ScreenplayerType
} from '../screenplayer.model';
import { IScreenplayCallback } from './callback-player.model';

export class CallbackPlayer
  implements IScreenplayActionPlayer<IScreenplayCallback>
{
  public readonly type = ScreenplayerType.Callback;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public cancel(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public pause(): void {}

  public async play(
    action: IScreenplayAction<IScreenplayCallback>
  ): Promise<void> {
    if (action.data.shouldUseLoadingSpinner) {
      SpinnerHandler.requestSpinner();
    }
    const promise = action.data.fn();

    return (
      promise?.finally &&
      promise.finally(() => {
        if (action.data.shouldUseLoadingSpinner) {
          SpinnerHandler.dismissSpinner();
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public resume(): void {}
}
