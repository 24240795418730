import memoizeOne from 'memoize-one';
import { Align, Color, ShadowStyle } from 'common-styles';
import { KeyNavHelper } from 'common-ui';
import { IInteractiveContainerStylesOverride } from 'common-ui/components/interactive/InteractiveContainer.styles';
import { ActiveComponentState, Justify } from '@lexialearning/common-ui';
import { merge } from 'lodash';

export interface IChoiceContainerStylesOverride {
  button?: {
    borderRadius?: number;
    margin?: number;
    marginBottom?: number;
    marginHorizontal?: number;
    marginTop?: number;
    padding?: number;
  };
  interactiveContainerWrapper?: {
    alignItems?: Align;
    backgroundColor?: string;
    borderRadius?: number;
    height?: number;
    justifyContent?: Justify;
    width?: number;
  };
  interactiveContainerStyleOverride?: IInteractiveContainerStylesOverride;
}

export class ChoiceContainerStyles {
  private static readonly styles = {
    button: {
      alignItems: Align.Center,
      borderColor: Color.Transparent,
      borderWidth: 4,
      margin: 1,
      padding: 0
    },
    interactiveContainerWrapper: {
      backgroundColor: Color.NearWhite,
      borderRadius: 29,
      ...ShadowStyle.activity
    }
  };
  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      styleOverride?: IChoiceContainerStylesOverride
    ) => {
      const keyNavStyles = KeyNavHelper.isKeyNav(activeComponentState) && {
        button: {
          borderColor: Color.getSetFor(activeComponentState).border
        },
        interactiveContainerWrapper: {
          backgroundColor: Color.getSetFor(activeComponentState).background
        }
      };

      return merge({}, this.styles, styleOverride, keyNavStyles);
    }
  );
}
