import * as React from 'react';
import { Column, Text } from 'common-ui';
import { ModalRegistry } from 'shared-components';
import { SystemInfo } from 'utils';
import { ConfigAdminModalStyles } from './ConfigAdminModal.styles';
import { ConfigEditor } from './ConfigEditor';
import { EditConfigValue } from './EditConfigValue';

interface IConfigAdminModalState {
  key: string;
  value: boolean | string | number;
}

export class ConfigAdminModal extends React.PureComponent<
  {},
  IConfigAdminModalState
> {
  public static readonly displayName = 'ConfigAdminModal';

  public static readonly ModalId = ModalRegistry.register({
    Component: ConfigAdminModal,
    title: 'Config Admin'
  });

  constructor(props: {}) {
    super(props);
    this.state = { key: '', value: '' };
    this.setEditorInputs = this.setEditorInputs.bind(this);
  }

  private setEditorInputs(key: string, value: boolean | string | number) {
    this.setState({ key, value });
  }

  public render() {
    const { key, value } = this.state;
    const styles = ConfigAdminModalStyles.get();

    return (
      <Column style={styles.container}>
        <EditConfigValue
          initialKey={key}
          initialValue={value}
          onChange={this.setEditorInputs}
        />

        {!SystemInfo.isNative && (
          <>
            <Text style={styles.instruction}>
              Type directly in the inputs above or click on an item in the
              config below to pre-populate and then edit
            </Text>
            <ConfigEditor onNodePress={this.setEditorInputs} />
          </>
        )}
      </Column>
    );
  }
}
