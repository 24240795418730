import { RoundRecyclingStrategy, UnitType } from '@lexialearning/lobo-common';
import { PlacementPositionDeterminer } from '../../../../placement/position-determiners/PlacementPositionDeterminer';
import { OnboardingPositionDeterminer } from '../../../../placement/position-determiners/OnboardingPositionDeterminer';
import { RoundContext } from '../../../RoundContext';
import { PokPositionDeterminer } from './PokPositionDeterminer';
import { PositionDeterminer } from './PositionDeterminer';

export class PositionDeterminerFactory {
  public static readonly displayName = 'PositionDeterminerFactory';

  /**
   * Create the PositionDeterminer strategy based on round context (e.g.
   * unit type or recycling strategy).
   * Note that the task registration can also override this, since it is the
   * one that typically calls this method.
   *
   * @see ITaskRegistration.createPositionDeterminer
   * @see prepareNextPositionEpic
   * @see skipToNextPositionEpic
   */
  public static create(context: RoundContext): PositionDeterminer {
    const { mainUnit } = context;

    return mainUnit.roundRecyclingStrategy === RoundRecyclingStrategy.Assessment
      ? new PokPositionDeterminer(context)
      : mainUnit.type === UnitType.Onboarding
      ? new OnboardingPositionDeterminer(context)
      : mainUnit.type === UnitType.Placement
      ? new PlacementPositionDeterminer(context)
      : new PositionDeterminer(context);
  }
}
