import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IActivityPosition,
  IMainUnitNode,
  IRound,
  ISubunitPosition,
  IUnit,
  IUnitNode,
  SubunitType
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { ProgramNodeHelper } from './ProgramNode.helper';

export class UnitSubtreeFactory {
  public static readonly displayName = 'UnitSubtreeFactory';

  public static create(
    activityPosition: IActivityPosition,
    units: IUnit[]
  ): IUnitSubtree {
    const unitMap = units.reduce((m, u) => m.set(u.sysId, u), new Map());
    const factory = new UnitSubtreeFactory(activityPosition, unitMap);
    const mainUnitNode = factory.nodifyMainUnit();

    return {
      activeUnitNode: factory.nodifySubunits(mainUnitNode),
      mainUnitNode
    };
  }

  public constructor(
    private readonly activityPosition: IActivityPosition,
    private readonly unitMap: Map<string, IUnit>
  ) {}

  private nodifyMainUnit(): IMainUnitNode {
    const mainUnit = this.unitMap.get(
      this.activityPosition.unitPosition.unitId
    );

    if (!mainUnit) {
      throw new LexiaError(
        'Unable to find main unit for specified unit position',
        UnitSubtreeFactory.displayName,
        UnitSubtreeFactoryError.MainUnitMissing
      ).withContext({
        activityPosition: this.activityPosition,
        units: [...this.unitMap.values()]
      });
    }

    const mainUnitNode: IMainUnitNode = {
      ...ProgramNodeHelper.createUnwired<IMainUnitNode>(
        mainUnit,
        LoboContentType.Unit
      ),
      childUnit: undefined,
      children: mainUnit.rounds.map(round => ({
        ...ProgramNodeHelper.createUnwired(round, LoboContentType.Round)
      })),
      // linked below
      parentUnit: undefined,

      subunitType: SubunitType.NotApplicable
    };

    return mainUnitNode;
  }

  private nodifySubunits(parentUnitNode: IUnitNode): IUnitNode {
    const { subunitPositions } = this.activityPosition;
    if (!subunitPositions.length) {
      return parentUnitNode;
    }

    return subunitPositions.reduce(
      (parent, subunitPosition) => this.nodifySubunit(parent, subunitPosition),
      parentUnitNode
    );
  }

  private nodifySubunit(
    parentUnitNode: IUnitNode,
    subunitPosition: ISubunitPosition
  ): IUnitNode {
    const subunit = this.unitMap.get(subunitPosition.unitId);

    if (!subunit) {
      throw new LexiaError(
        'Unable to find subunit for specified subunit position',
        UnitSubtreeFactory.displayName,
        UnitSubtreeFactoryError.SubunitMissing
      ).withContext({ subunitPosition, units: [...this.unitMap.values()] });
    }
    const parentUnit = parentUnitNode.content;
    const parentRound = parentUnit.rounds.find(
      r => r.sysId === subunitPosition.parentRoundId
    );

    if (!parentRound) {
      throw new LexiaError(
        'Unable to find subunit parent round',
        UnitSubtreeFactory.displayName,
        UnitSubtreeFactoryError.SubunitParentRoundMissing
      ).withContext({ subunit, subunitPosition });
    }
    const subunitNode: IUnitNode<IRound> = {
      ...ProgramNodeHelper.createUnwired<IUnitNode<IRound>>(
        subunit,
        LoboContentType.Unit
      ),
      childUnit: undefined,
      children: subunit.rounds.map(r => ({
        ...ProgramNodeHelper.createUnwired(r, LoboContentType.Round)
      })),
      parentUnit: parentUnitNode,
      subunitType: subunitPosition.subunitType
    };

    parentUnitNode.childUnit = subunitNode;
    const parentRoundNode = parentUnitNode.children.find(
      r => r.content.sysId === parentRound.sysId
    )!;
    parentRoundNode.children.push(subunitNode);

    return subunitNode;
  }
}

export enum UnitSubtreeFactoryError {
  MainUnitMissing = 'MainUnitMissing',
  SubunitMissing = 'SubunitMissing',
  SubunitParentRoundMissing = 'SubunitParentRoundMissing'
}

export interface IUnitSubtree {
  /**
   * NB: This node is not fully wired up
   */
  mainUnitNode: IMainUnitNode;

  /**
   * NB: This node is not fully wired up
   */
  activeUnitNode: IUnitNode;
}
