import {
  IProgressStatus,
  IRound,
  ProgressStatus
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { TaskRegistry } from 'task-components';
import { RoundContext } from '../../RoundContext';

export class UnitProgressStatusFactory {
  public static readonly displayName = 'UnitProgressStatusFactory';

  public static create(
    context: RoundContext,
    taskRegistry: TaskRegistry
  ): IProgressStatus[] {
    const factory = new UnitProgressStatusFactory(context, taskRegistry);

    return factory.create();
  }

  private readonly activeRoundId: string;

  private isPastActiveRound = false;

  private constructor(
    private readonly context: RoundContext,
    private readonly taskRegistry: TaskRegistry
  ) {
    this.activeRoundId = context.isInstructionRound
      ? context.standardRound.sysId
      : context.round.sysId;
  }

  private create(): IProgressStatus[] {
    const { rounds, sysId } = this.context.mainUnit;
    const isRecyclePass = !!this.context.parentUnitRecyclePass;

    return isRecyclePass
      ? this.createRecyclePassRoundsProgressStatus(sysId, rounds)
      : this.createRoundsProgressStatus(rounds);
  }

  private createRoundsProgressStatus(rounds: IRound[]): IProgressStatus[] {
    return rounds.reduce(
      (items, round) => [...items, ...this.createRoundStatusItems(round)],
      [] as IProgressStatus[]
    );
  }

  /**
   * For a recycling pass, non-recycled rounds get a Skip status
   */
  private createRecyclePassRoundsProgressStatus(
    unitId: string,
    rounds: IRound[]
  ): IProgressStatus[] {
    const originalUnit = this.context.encounter.units.find(
      u => u.sysId === unitId
    );

    if (!originalUnit) {
      throw new LexiaError(
        'Unit with given id not found among the current encounter units',
        UnitProgressStatusFactory.displayName,
        UnitProgressStatusFactoryError.GivenUnitNotFound
      ).withContext({
        encounterUnitIds: this.context.encounter.units.map(u => u.sysId),
        unitId
      });
    }

    const originalRoundsIds = originalUnit.rounds.map(r => r.sysId);

    const recycledRoundMap = rounds.reduce(
      (m, r) => m.set(r.sysId, r),
      new Map<string, IRound>()
    );

    return originalRoundsIds.reduce(
      (items, roundId) =>
        recycledRoundMap.has(roundId)
          ? [
              ...items,
              ...this.createRoundStatusItems(recycledRoundMap.get(roundId)!)
            ]
          : [...items, { status: ProgressStatus.Skip, sysId: roundId }],
      [] as IProgressStatus[]
    );
  }

  private createRoundStatusItems(round: IRound): IProgressStatus[] {
    const roundItem = this.createRoundStatus(round.sysId);
    const subunitItems = this.createForkSubunitStatusItems(round);

    return [roundItem, ...subunitItems];
  }

  private createForkSubunitStatusItems(round: IRound): IProgressStatus[] {
    const { standardUnit } = this.context;
    if (!round.task) {
      return [];
    }

    const registration = this.taskRegistry.get(round.task.taskType);
    const paths = registration.getForkSubunits(round.task);
    const path = paths.find(p => p.sysId === standardUnit.sysId) ?? paths[0];

    return paths.length
      ? path.rounds.map(r => this.createRoundStatus(r.sysId))
      : [];
  }

  private createRoundStatus(roundId: string): IProgressStatus {
    const activeRoundStatus =
      this.context.isRoundComplete && !this.context.isInstructionRound
        ? ProgressStatus.Done
        : ProgressStatus.Active;

    const item = {
      status:
        roundId === this.activeRoundId
          ? activeRoundStatus
          : this.isPastActiveRound
          ? ProgressStatus.Pending
          : ProgressStatus.Done,
      sysId: roundId
    };

    this.isPastActiveRound =
      this.isPastActiveRound || roundId === this.activeRoundId;

    return item;
  }
}

export enum UnitProgressStatusFactoryError {
  GivenUnitNotFound = 'GivenUnitNotFound'
}
