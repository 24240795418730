import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { calibrationReducer as calibration } from './calibration/calibration.reducer';
import { sreSessionReducer as session } from './session/sreSession.reducer';
import { sreConfigReducer as config } from './sre-config/sreConfig.reducer';

export const sreReducer = combineReducers({
  calibration,
  config,
  session
});

export type SreState = StateType<typeof sreReducer>;
