import {
  IScreenplay,
  IScreenplayAction
} from '@lexialearning/lobo-common/main-model';

export interface IScreenplayActionPlayer<D = object> {
  /**
   * Can be a core ScreenplayActionType or any custom type
   */
  readonly type: ScreenplayerType | string;

  cancel(action?: IScreenplayAction<D>): void;
  pause(action?: IScreenplayAction<D>): void;
  play(action: IScreenplayAction<D>): Promise<void>;
  resume(action?: IScreenplayAction<D>): void;
}

export interface IScreenplayActionFactory<T extends object = object> {
  readonly type: ScreenplayerType;
  create(data?: T, options?: Partial<IActionOptions>): IScreenplayAction<T>;
}

export interface IActionOptions {
  concurrent: boolean;
}

/**
 * This is intended as a subset of possible screenplay action types.
 * It should only include the "core" ones that are part of the screenplay
 * package. But the Screenplayer can play any IScreenplayActionPlayer
 *
 */
export enum ScreenplayerType {
  Callback = 'Callback',
  Delay = 'Delay',
  Music = 'Music',
  ReactAnimation = 'ReactAnimation',
  ReduxDispatcher = 'ReduxDispatcher',
  Sfx = 'Sfx',
  StormAnimation = 'StormAnimation'
}

export interface IScreenplayActive extends IScreenplayResult {
  actionIndex: number;
}
export interface IScreenplayResult extends IScreenplay {
  canceled: boolean;
  skipped: boolean;
}

export interface IScreenplayEvent<T extends object = object> {
  action?: IScreenplayAction<T>;
  actionIndex?: number;
  type: ScreenplayEvent;
  totalActions: number;
}

export enum ScreenplayEvent {
  BeforeAction = 'before',
  AfterAction = 'after',
  Canceled = 'canceled',
  Completed = 'completed',
  Skipped = 'skipped'
}

export interface ILoopedActionRequest {
  loop?: boolean;
}

export interface IAnimationRequest<
  TName extends string = string,
  TElem extends string = string,
  TScene extends string = string
> extends ILoopedActionRequest {
  name: TName;
  targetScene: TScene;
  targetElement?: TElem;
  /**
   * The point in the timeline at which the animation should start or be set to.
   */
  time?: number;
  /**
   * The point in the timeline at which the animation should start as a percent
   * of the full timeline length. For example, setting it to 50 will start the
   * animation half-way through the full timeline.
   */
  timeAsPercent?: number;
  /**
   * The speed at which the animation playback should run. Set to 0 for just
   * positioning at a point in the timeline and leaving there. 0.5 for
   * half-rate, 2.0 for double-speed, etc. Negative values are ignored.
   */
  speed?: number;
  /**
   * The time (in seconds) it takes for the animation to fully blend in/out
   */
  blendTimeSeconds?: number;
  /**
   * The animation layer id the animation should run on. Higher IDs take precedence and override lower layers.
   */
  animationLayer?: number;
}
