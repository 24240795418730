import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  SreSelector,
  SreSessionAction,
  SreSessionActionCancel,
  SreSessionType
} from 'sre';
import { AppShellActionShowModal } from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

export function cancelListeningOnShowModalEpic(
  action$: Observable<AppShellActionShowModal>,
  state$: StateObservable<unknown>
): Observable<SreSessionActionCancel> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal),
    filter(() =>
      SreSelector.getIsListeningTo(SreSessionType.LanguageFrame, state$.value)
    ),
    map(() => SreSessionAction.cancel.request())
  );
}
cancelListeningOnShowModalEpic.displayName = 'cancelListeningOnShowModalEpic';
