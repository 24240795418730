import { IScreenplay } from '@lexialearning/lobo-common';
import { find, map } from 'rxjs/operators';
import { LevelSelector } from 'curriculum-services';
import { HomeIntroScreenplayBuilder } from 'feature-areas/transitions/builders/home';
import { ScreenplayAction } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { ProfileSelector } from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import {
  DeepLinkHelperOutputAction,
  DeepLinkObservableFactoryBaseWithLevelsLoading,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';

export interface IHomeDeepLinkDeps extends IDeepLinkDeps {
  stormService: StormService;
}
export type HomeDeepLinkOutputAction = DeepLinkHelperOutputAction;

export class HomeDeepLinkObservableFactory extends DeepLinkObservableFactoryBaseWithLevelsLoading<IHomeDeepLinkDeps> {
  public static readonly displayName = 'HomeDeepLinkObservableFactory';

  public static createFor(
    deps: IHomeDeepLinkDeps
  ): HomeDeepLinkObservableFactory {
    return new HomeDeepLinkObservableFactory(deps)
      .awaitLevelsThenLoadLevel()
      .thenPlayHomeIntroScreenplay();
  }

  constructor(public readonly deps: IHomeDeepLinkDeps) {
    super(deps);
  }

  private thenPlayHomeIntroScreenplay(): HomeDeepLinkObservableFactory {
    const { state$ } = this.deps;
    const playHomeIntroScreenplay$ = state$.pipe(
      find(() => this.isLevelReady()),
      map(() =>
        this.buildHomeIntroScreenplay(
          this.deps.preparedScenes,
          this.deps.stormService
        )
      ),
      map(screenplay => ScreenplayAction.play({ screenplay }))
    );
    this.deferredDispatches$.push(playHomeIntroScreenplay$);

    return this;
  }

  private isLevelReady(): boolean {
    const { state$ } = this.deps;

    return !!LevelSelector.getLevelMaybe(state$.value);
  }

  private buildHomeIntroScreenplay(
    preparedScenes: PreparedScenes,
    stormService: StormService
  ): IScreenplay {
    const { state$ } = this.deps;

    const homeContent = BootstrapContentSelector.getHomeScreenContent(
      state$.value
    );
    const micPromptVo = homeContent?.microphonePrompt.voiceover;
    const levelNum = LevelSelector.getLevelNumber(state$.value);
    const grade = ProfileSelector.getGrade(state$.value);

    return HomeIntroScreenplayBuilder.createFor({
      grade,
      levelNum,
      micPromptVo,
      preparedScenes,
      stormService
    }).screenplay;
  }
}
