import { LexiaError } from '@lexialearning/utils';
import { truncate } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { GradeName, UserRole } from 'lexia-service';
import { IProfile } from '../profile.model';
import { IProfileState } from './profile-redux.model';
import { IAvatar } from 'services/profile/avatar.model';

export class ProfileSelector {
  public static readonly displayName = 'ProfileSelector';

  public static getAvatarMaybe: (state: unknown) => IAvatar | undefined;

  public static getDisplayName: (state: unknown) => string;

  public static getFirstName: (state: unknown) => string;

  public static getLastName: (state: unknown) => string;

  public static getMinutesSinceLastLogin: (
    state: unknown
  ) => number | undefined;

  public static getProfileMaybe: (state: unknown) => IProfile | undefined;

  public static getProfile: (state: unknown) => IProfile;

  public static getUserRole: (state: unknown) => UserRole;

  public static hasCreatedAvatar: (state: unknown) => boolean;

  public static isDeepLink: (state: unknown) => boolean;

  /**
   * Returns if user role is Student or DeepLinkStudent
   */
  public static isStudent: (state: unknown) => boolean;

  /**
   * Returns true only if user role is Student (not DeepLinkStudent)
   */
  public static isStudentLoggedIn: (state: unknown) => boolean;

  public static getGradeMaybe: (state: unknown) => GradeName | undefined;

  public static getGrade: (state: unknown) => GradeName;

  public static createSelectors(selector: (state: any) => IProfileState) {
    this.getProfileMaybe = createSelector(
      selector,
      (state: IProfileState) => state.profile
    );

    this.getProfile = createSelector(selector, (state: IProfileState) => {
      if (!state.profile) {
        throw new LexiaError(
          'Profile undefined',
          ProfileSelector.displayName,
          ProfileSelectorError.ProfileUndefined
        );
      }

      return state.profile;
    });

    this.getFirstName = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.firstName ?? ''
    );

    this.getLastName = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.lastName ?? ''
    );

    this.getMinutesSinceLastLogin = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.minutesSinceLastLogin
    );

    this.getDisplayName = createSelector(
      this.getProfileMaybe,
      this.buildDisplayName
    );

    this.getUserRole = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.role ?? UserRole.Unknown
    );

    this.getAvatarMaybe = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.avatar
    );

    this.hasCreatedAvatar = createSelector(
      this.getAvatarMaybe,
      (avatar?: IAvatar) => !!avatar?.faceStyle
    );

    this.isDeepLink = createSelector(this.getUserRole, (role: UserRole) =>
      [UserRole.DeepLinkStudent, UserRole.DeepLinkEducator].includes(role)
    );

    this.isStudent = createSelector(this.getUserRole, (role: UserRole) =>
      [UserRole.Student, UserRole.DeepLinkStudent].includes(role)
    );

    this.isStudentLoggedIn = createSelector(
      this.getUserRole,
      (role: UserRole) => role === UserRole.Student
    );

    this.getGradeMaybe = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => profile?.grade
    );

    this.getGrade = createSelector(
      this.getProfileMaybe,
      (profile?: IProfile) => {
        if (!profile?.grade) {
          throw new LexiaError(
            'Grade undefined',
            ProfileSelector.displayName,
            ProfileSelectorError.GradeUndefined
          );
        }

        return profile.grade;
      }
    );
  }

  private static buildDisplayName(profile?: IProfile): string {
    if (!profile) {
      return '';
    }

    return `${truncate(profile.firstName, { length: 12 })} ${
      profile.lastName[0]
    }.`;
  }
}

export enum ProfileSelectorError {
  ProfileUndefined = 'ProfileUndefined',
  GradeUndefined = 'GradeUndefined'
}
