import {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import videoJs, { VideoJsPlayer } from 'video.js';
import { IVideoPlayerModifiedProps } from './video.model';
import { VideoHelper } from './Video.helper';
import { VideoStyles } from './Video.styles';
import { App } from '@lexialearning/reactxp';
import { AppActivationState } from '@lexialearning/reactxp/dist/common/Types';
import { useDidMount } from '../../hooks';

/**
 *  Video component to render HLS streaming videos. Notes: The HLS video must return a wrapped component or else React throws an unknown child error when a new video loads
 */
export function Video(props: IVideoPlayerModifiedProps): React.ReactElement {
  const {
    autoPlay,
    captionsBlocked,
    checkBrowser,
    checkForWifi,
    deferConfig,
    id,
    loadingSpinner,
    onCanPlayThrough,
    onClick,
    onEnded,
    onError,
    onTimeUpdate,
    pathname,
    poster,
    resetPauseFlag,
    seekable,
    setHlsCanPlay,
    testId,
    videoRole,
    videoConfig,
    videoRef
  } = props;

  const player: MutableRefObject<VideoJsPlayer | null> = useRef(null);
  const videoNodeRef = useRef<HTMLVideoElement | null>(null);
  const styles = VideoStyles.build(videoRole);

  useImperativeHandle(videoRef, () => ({ player: player.current }));

  useEffect(() => {
    // instantiate Video.js
    /* istanbul ignore else */
    if (videoNodeRef.current) {
      /* istanbul ignore next */
      player.current = videoJs(videoNodeRef.current, videoConfig, () => {
        VideoHelper.setupPlayer(
          {
            autoPlay,
            captionsBlocked,
            checkBrowser,
            checkForWifi,
            deferConfig,
            loadingSpinner,
            onCanPlayThrough,
            onClick,
            onEnded,
            onError,
            onTimeUpdate,
            pathname,
            poster,
            resetPauseFlag,
            seekable,
            setHlsCanPlay,
            testId
          },
          player
        );
      });
    }

    return () => {
      VideoHelper.clearErrorTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    if (
      player.current &&
      autoPlay &&
      // ⬇ this allows the autoPlay to be disabled if the user hasn't interacted with the page yet
      'userActivation' in navigator &&
      (navigator as any).userActivation.hasBeenActive
    ) {
      player.current.userActive(false);
      player.current.play();
    }
  }, [autoPlay]);

  const activationStateRef = useRef<AppActivationState | undefined>(undefined);
  /** True if app was paused programmatically when AppActivationState became other than Active */
  const programmaticallyPausedRef = useRef<boolean>(false);
  useDidMount(() => {
    const subscription = App.activationStateChangedEvent.subscribe(
      /* istanbul ignore next */
      (s: AppActivationState) => {
        if (activationStateRef.current !== s) {
          activationStateRef.current = s;

          /* istanbul ignore next */
          if (!!player.current) {
            // Became active and had been programmatically paused when had become non-active
            if (
              s === AppActivationState.Active &&
              programmaticallyPausedRef.current
            ) {
              player.current.play();
            }

            // Became non-active and is currently playing
            /* istanbul ignore next */
            else if (
              s !== AppActivationState.Active &&
              !player.current.paused()
            ) {
              programmaticallyPausedRef.current = true;
              player.current.pause();
            }
          }
        }
      }
    );

    return () => subscription.unsubscribe();
  });

  return (
    <div style={styles}>
      <link rel="stylesheet" href="css/video-js.css" />
      {/* NOTE: video.js removes the <video> node and replaces it with a new one */}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        className={`video-js vjs-fluid ${
          !seekable && 'progress-control-disabled'
        }`}
        style={styles}
        id={id}
        data-test-id={`${Video.displayName}-video-element`}
        playsInline={autoPlay}
        ref={videoNodeRef}
      />
    </div>
  );
}

Video.displayName = 'Video';
