import { SceneZIndex } from 'services/storm-lobo';
import { Scene } from 'storm';

export class SceneControllerBase {
  public static displayName = 'SceneShellBase';

  constructor(
    public readonly scene: Scene,
    private readonly zIndex: SceneZIndex
  ) {}

  public show() {
    this.scene.zIndex = this.zIndex;
    this.scene.active = true;
  }

  public hide() {
    this.scene.active = false;
  }
}
