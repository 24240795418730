import { Align } from '@lexialearning/common-ui';
import { AnimatableCSSProperty, Justify, loboAnimated } from 'common-styles';
import { Types } from '../../types';

const defaultDuration = 200;
interface IFadeAnimationStyleProps {
  duration?: number;
}

export class FadeAnimationAnimatedStyles {
  private readonly animatedValues: {
    opacity: Types.AnimatedValue;
  };

  private readonly animations: {
    show: Types.Animated.CompositeAnimation;
    hide: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
    containerStatic: Types.ViewStyle;
  };

  constructor(
    { duration = defaultDuration }: IFadeAnimationStyleProps,
    styleOverride?: Types.ViewStyle
  ) {
    const opacity = loboAnimated.createValue(0);

    const show = loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
      duration,
      easing: loboAnimated.Easing.In(),
      toValue: 1
    });

    const hide = loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
      duration,
      easing: loboAnimated.Easing.Out(),
      toValue: 0
    });

    this.animatedValues = {
      opacity
    };

    this.animations = {
      hide,
      show
    };

    this.styles = {
      containerAnimated: {
        opacity
      },
      containerStatic: {
        alignItems: Align.Center,
        justifyContent: Justify.Center,
        ...styleOverride
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
