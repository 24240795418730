export enum RoutePath {
  Acts = '/acts/:actId',
  AvatarEditor = '/avatar-editor',
  Calibration = '/calibration',
  DeviceSetup = '/device-setup',
  Educator = '/educator',
  EducatorTab = '/educator/:selectedTab',
  EncounterComplete = '/encounter-complete',
  GettingStarted = '/getting-started',
  Home = '/home',
  Introduction = '/introduction',
  LevelComplete = '/level-complete',
  LevelIntroduction = '/level-introduction',
  Levels = '/levels/:levelId',
  Login = '/login',
  LoginSsoInteraction = '/login-sso-interaction',
  LoginTab = '/login/:selectedTab',
  Onboarding = '/onboarding',
  OnboardingOrientation = '/onboarding/orientation',
  OnboardingRounds = '/onboarding/units/:unitId/rounds/:roundId',
  PlacementComplete = '/placement-complete',
  PlacementRounds = '/placement/units/:unitId/rounds/:roundId',
  ProgramComplete = '/program-complete',
  Root = '/',
  Rounds = '/units/:unitId/rounds/:roundId',
  UnitComplete = '/unit-complete'
}

export interface IActRouteParams {
  actId: string;
}

export interface ILevelRouteParams {
  levelId: string;
}

export interface IRoundsRouteParams {
  roundId: string;
  unitId: string;
}

export enum QueryStringParam {
  /**
   * Query param provided in Clever logins, used to override the default server
   */
  ApiUrl = 'apiURL',
  /**
   * Query param to override the default myLexia server
   */
  AuthServer = 'authServer',
  /**
   * Allows deep linking
   */
  AutoLogin = 'trainingCode',
  /**
   * Config override for the student api url
   */
  ConfigStudentApiUrl = 'config.studentApi.apiDomain', // Preferred, but not currently allowed: `config.${STUDENT_API_URL_CONFIG_STRING}`,
  /**
   * Config override for the default myLexia server
   */
  ConfigCustomerUrl = 'config.lexiaService.customerUrl', // Preferred, but not currently allowed: `config.${AUTH_SERVER_CONFIG_STRING}`,
  /**
   * Used in SSO, auth token used to login
   */
  LexiaAuthToken = 'lexiaAuthToken',
  /**
   * Used in Clever SSO, the url the app should direct to on logout
   */
  LogoutUrl = 'logoutURL',
  /**
   * prefix for all config overrides
   */
  OverridePrefix = 'config.',
  /**
   * Used in SSO, personId used to login
   */
  PersonId = 'personId',
  /**
   * Used in SSO, role used to login
   */
  Role = 'role',
  /**
   * Overwrite customer code
   */
  SiteId = 'siteId',
  /**
   * Query param to override student api endpoint
   * (seems worth having the shorter shorthand, leaving the below, however,
   *  as it is already a known value, so best not to force people to change)
   */
  StudentApi = 'studentApi',
  /**
   * Query param to override student api endpoint
   * @deprecated It can still be used, but will only set the student api Domain, proper approach is to use studentApiDomain override
   */
  StudentApiEndpoint = 'studentApiEndpoint',
  /**
   * Query param to override student api domain
   */
  StudentApiDomain = 'studentApiDomain'
}

export enum EducatorTabName {
  Level = 'level',
  Overview = 'overview'
}

export enum LoginTabName {
  Student = 'student',
  Educator = 'educator'
}

/** Optional second arg passed to history.replace() */
export interface ILocationState {
  /**
   * If used, should be set to the path which is being replaced
   * eg, when user is on '/home', going to '/avatar-editor':
   *   history.replace('/avatar-editor', { prevPath: '/home' })
   * (Currently only utilized for routing to the AvatarEditor
   *  when not coming from login)
   */
  prevPath?: string;
}
