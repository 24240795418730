import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { last } from 'lodash';
import { DeepLinkSubjectFactory } from '../DeepLinkSubjectFactory';
import { SubjectType } from '../position-builder-deep-link.model';

export class PlacementDeepLinkSubjectFactory extends DeepLinkSubjectFactory {
  public static readonly displayName = 'PlacementDeepLinkSubjectFactory';

  protected determineSubjectType(): SubjectType {
    const isRound = last(this.ancestors)!.contentType === LoboContentType.Unit;
    const isPlacement = this.ancestors.some(
      a => a.contentType === LoboContentType.PlacementStep
    );

    if (isPlacement) {
      return isRound ? SubjectType.PlacementRound : SubjectType.PlacementUnit;
    }

    // Onboarding
    return isRound ? SubjectType.OnboardingRound : SubjectType.OnboardingUnit;
  }

  protected determineContentType(subjectType: SubjectType): LoboContentType {
    switch (subjectType) {
      case SubjectType.OnboardingRound:
        return LoboContentType.Round;
      case SubjectType.OnboardingUnit:
        return LoboContentType.Unit;
      case SubjectType.PlacementRound:
        return LoboContentType.Round;
      case SubjectType.PlacementUnit:
        return LoboContentType.Unit;
      default:
        throw new Error('TODO');
    }
  }

  protected buildExpectedAncestry(subjectType: SubjectType): LoboContentType[] {
    const ancestry = [LoboContentType.Placement];
    const isRound = [
      SubjectType.PlacementRound,
      SubjectType.OnboardingRound
    ].includes(subjectType);
    const isPlacement = [
      SubjectType.PlacementRound,
      SubjectType.PlacementUnit
    ].includes(subjectType);

    if (isPlacement) {
      ancestry.push(LoboContentType.PlacementStep);
    }

    ancestry.push(LoboContentType.Unit);

    if (isRound) {
      ancestry.push(LoboContentType.Round);
    }

    return ancestry;
  }
}
