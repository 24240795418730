import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { ExcellingLevelDeterminer } from 'curriculum-services/program-context/service-helpers';
import { GradeName } from 'lexia-service';
import { StormAssets } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';
import { AnimationDefinitionFactory } from 'storm/animations';
import {
  homeCharacterPositionsMap,
  HomeSceneElementName
} from './home-scene.model';

export class HomeSceneDefinitionBuilder {
  public static readonly ScenePath = '/scenes/home/home_root.sg';

  public static create(): HomeSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Home,
      this.ScenePath
    );

    return new HomeSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}

  public withCharacters() {
    homeCharacterPositionsMap.forEach((char, placeholder) => {
      const path = `/scenes/characters/${char}/01/lod-1/${char}.sg`;
      const animName = `idle_stance_${char}`;
      const animations = this.buildCharacterAnimations(placeholder, animName);
      const character = SceneDefinitionBuilder.create(placeholder, path, {
        animations
      }).definition;

      this.definition.elements.push(character);
    });

    return this;
  }

  private buildCharacterAnimations(
    placeholder: string,
    animName: string
  ): IAnimationDefinition[] {
    return [
      AnimationDefinitionFactory.create(animName, {
        autoplay: true,
        loop: true,
        path: `/scenes/character-animations/${animName}.sga`,
        targetElements: [placeholder]
      })
    ];
  }

  public withLevelMeter(grade: GradeName, levelNum: number) {
    const isExcellingLevel = ExcellingLevelDeterminer.isExcellingLevel(
      grade,
      levelNum
    );
    const isUpperGrade = isExcellingLevel === undefined;

    const meterAssetSuffix = isUpperGrade
      ? '_full'
      : isExcellingLevel
      ? '_secondary'
      : '_primary';

    const assetPath = `${StormAssets.Folders.Home.path}/level-meter/rainbow_meter${meterAssetSuffix}.sg`;
    this.definition.elements.push(
      SceneDefinitionBuilder.create(HomeSceneElementName.LevelMeter, assetPath)
        .definition
    );

    if (isExcellingLevel) {
      this.withLevelMeterSparkles();
    }

    return this;
  }

  private withLevelMeterSparkles() {
    const sparklesAssetPath = `${StormAssets.Folders.Home.path}/level-meter/rainbow_meter_sparkles.sg`;

    this.definition.elements.push(
      SceneDefinitionBuilder.create(
        HomeSceneElementName.LevelMeterSparkles,
        sparklesAssetPath
      ).definition
    );
  }
}
