import { Position } from '@lexialearning/common-ui';
import {
  AnimatableCSSProperty,
  Color,
  loboAnimated,
  LoboAnimatedValue
} from 'common-styles';
import { Types } from 'common-ui';

interface ITransitionAnimationSet {
  fadeIn: Types.Animated.CompositeAnimation;
  fadeOut: Types.Animated.CompositeAnimation;
}

const ANIMATION_DURATION = 300;
const LEVEL_COLORS = [
  { colorBottom: Color.Transparent, colorTop: Color.Transparent },
  { colorBottom: 'rgb(201,27,252)', colorTop: 'rgb(249,179,252)' },
  { colorBottom: 'rgb(208,1,1)', colorTop: 'rgb(255,193,193)' },
  { colorBottom: 'rgb(245,123,0)', colorTop: 'rgb(255,206,139)' },
  { colorBottom: 'rgb(232,170,0)', colorTop: 'rgb(255,215,103)' },
  { colorBottom: 'rgb(126,163,1)', colorTop: 'rgb(215,248,76)' },
  { colorBottom: 'rgb(1,173,81)', colorTop: 'rgb(123,255,153)' },
  { colorBottom: 'rgb(2,173,173)', colorTop: 'rgb(80,255,228)' },
  { colorBottom: 'rgb(2,139,163)', colorTop: 'rgb(157,232,250)' },
  { colorBottom: 'rgb(93,124,255)', colorTop: 'rgb(175,211,253)' },
  { colorBottom: 'rgb(118,59,250)', colorTop: 'rgb(209,197,253)' },
  { colorBottom: 'rgb(203,3,103)', colorTop: 'rgb(255,183,227)' },
  { colorBottom: 'rgb(252,27,27)', colorTop: 'rgb(252,179,179)' },
  { colorBottom: 'rgb(224,96,3)', colorTop: 'rgb(253,208,123)' },
  { colorBottom: 'rgb(221,144,0)', colorTop: 'rgb(255,237,139)' },
  { colorBottom: 'rgb(226,157,1)', colorTop: 'rgb(242,247,120)' },
  { colorBottom: 'rgb(59,152,1)', colorTop: 'rgb(165,252,119)' },
  { colorBottom: 'rgb(2,162,131)', colorTop: 'rgb(123,255,199)' },
  { colorBottom: 'rgb(3,45,159)', colorTop: 'rgb(162,234,255)' },
  { colorBottom: 'rgb(77,37,248)', colorTop: 'rgb(193,216,247)' }
];

export class CurtainAnimatedStyles {
  private readonly backToHomeOpacityValue: LoboAnimatedValue;

  private readonly backToLevelOpacityValue: LoboAnimatedValue;

  private readonly styles: {
    container: Types.ViewStyle;
    backToHome: Types.AnimatedViewStyle;
    backToLevel: Types.AnimatedViewStyle;
  };

  private readonly animations: {
    backToHome: ITransitionAnimationSet;
    backToLevel: ITransitionAnimationSet;
  };

  constructor() {
    this.backToHomeOpacityValue = loboAnimated.createValue(0);
    this.backToLevelOpacityValue = loboAnimated.createValue(0);

    this.animations = {
      backToHome: this.createAnimations(this.backToHomeOpacityValue),
      backToLevel: this.createAnimations(this.backToLevelOpacityValue)
    };

    this.styles = {
      backToHome: {
        opacity: this.backToHomeOpacityValue
      },
      backToLevel: {
        opacity: this.backToLevelOpacityValue
      },
      container: {
        position: Position.Absolute,
        top: 0
      }
    };
  }

  private createAnimations(opacityValue: LoboAnimatedValue) {
    return {
      fadeIn: loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
        duration: (ANIMATION_DURATION * 1) / 3,
        easing: loboAnimated.Easing.Out(),
        toValue: 1
      }),
      fadeOut: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        opacityValue,
        {
          duration: (ANIMATION_DURATION * 2) / 3,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      )
    };
  }

  public getAnimations() {
    return this.animations;
  }

  public getLevelColors(levelNumber = 0) {
    return LEVEL_COLORS[levelNumber] || LEVEL_COLORS[0];
  }

  public get() {
    return this.styles;
  }
}
