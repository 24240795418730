import { FontFamily } from './enums';

export const Font = {
  Family: {
    regular: FontFamily.FigmentSansRegular,
    semiBold: FontFamily.FigmentSansSemiBold
  },
  Size: {
    large: 24,
    small: 18,
    xLarge: 45,
    xSmall: 14,
    xxSmall: 12
  }
};
