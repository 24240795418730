import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';

export class BouncingGabberAnimatedStyles {
  private static readonly PopDurationMs = 100;

  private static readonly AnimIntervalMs = 800;

  private static readonly AnimOffset = 7;

  private readonly animations: {
    pop: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
    gabberImage: Types.ImageStyle;
  };

  constructor(delayMs: number) {
    const translateY = loboAnimated.createValue(0);

    const pop = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: delayMs,
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: BouncingGabberAnimatedStyles.PopDurationMs,
        easing: loboAnimated.Easing.Out(),
        toValue: -BouncingGabberAnimatedStyles.AnimOffset
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: BouncingGabberAnimatedStyles.PopDurationMs,
        easing: loboAnimated.Easing.In(),
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: BouncingGabberAnimatedStyles.AnimIntervalMs - delayMs,
        toValue: 0
      })
    ]);

    this.animations = {
      pop
    };

    this.styles = {
      containerAnimated: {
        transform: [{ translateY }]
      },
      gabberImage: {
        height: 36,
        margin: 3,
        width: 36
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
