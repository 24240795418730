import { StormEngineView } from '@lexialearning/storm-react';
import * as React from 'react';
import { Column } from 'common-ui';
import { StormContainerStyles } from './StormContainer.styles';

export class StormContainerComponent extends React.PureComponent {
  public static readonly displayName = 'StormContainer';

  public render() {
    const styles = StormContainerStyles.get();

    return (
      <Column style={styles.default}>
        <StormEngineView />
      </Column>
    );
  }
}

export const StormContainer = StormContainerComponent;
