import { educatorLoadLevelsEpic } from './educatorLoadLevels.epic';
import { maybeLoadLevelEpic } from './maybeLoadLevel.epic';
import { selectLevelActivityEpic } from './selectLevelActivity.epic';
import { showActivityCarouselModalEpic } from './showActivityCarouselModal.epic';

export const educatorEpics = [
  educatorLoadLevelsEpic,
  maybeLoadLevelEpic,
  selectLevelActivityEpic,
  showActivityCarouselModalEpic
];
