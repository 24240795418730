import { appConfigEpic } from 'app-config/epics/appConfig.epic';
import { sfxEpics } from 'audio/sfx/sfx.epic';
import { calibrationSceneEpics } from 'feature-areas/calibration/calibration-scene';
import { calibrationUiEpics } from 'feature-areas/calibration/redux/epics/calibration-ui.epic';
import { devToolsEpic } from 'feature-areas/dev-tools/epics/devTools.epic';
import { educatorEpics } from 'feature-areas/educator/epics/educator.epic';
import { encounterSceneEpics } from 'feature-areas/encounters/encounter-scene/epics/encounterScene.epic';
import { homeSceneEpics } from 'feature-areas/home/home-scene/epics/home-scene.epic';
import { introductionSceneEpics } from 'feature-areas/introduction/introduction-scene/epics/introduction-scene.epic';
import { levelCompleteSceneEpics } from 'feature-areas/levels/level-complete-scene/epics/level-complete-scene.epic';
import { levelIntroSceneEpics } from 'feature-areas/level-intro/level-intro-scene/epics/level-intro-scene.epic';
import { levelSceneEpics } from 'feature-areas/levels/level-scene/epics/level-scene.epic';
import { placementSceneEpics } from 'feature-areas/placement/placement-scene/epics/placementScene.epic';
import { programCompleteSceneEpics } from 'feature-areas/program-complete/epics/program-complete-scene.epic';
import { taskDemoSceneEpics } from 'feature-areas/tasks/task-demo-scene/epics/task-demo-scene.epic';
import { roundEpics } from 'feature-areas/transitions/builders/rounds/epics/round.epic';
import { transitionEpics } from 'feature-areas/transitions/transition.epic';
import { unitCompleteSceneEpics } from 'feature-areas/units/unit-complete-scene/epics/unitCompleteScene.epic';
import { ScreenplayReduxProvider } from 'screenplay/redux/ScreenplayReduxProvider';
import { authEpics } from 'services/auth/epics/auth.epics';
import { curriculumEpics } from 'services/curriculum-services/curriculum.epic';
import { customerSettingsEpics } from 'services/customer-settings/customerSettingsEpics';
import { customerEpics } from 'services/customer/epics/customer.epic';
import { errorHandlerEpics } from 'services/error-handler/epics/errorHandler.epic';
import { loggingEpics } from 'services/logging/epics/logging.epic';
import { networkStateEpics } from 'services/network-state/networkState.epic';
import { stormLoboEpics } from 'services/storm-lobo/epics/stormLobo.epic';
import { progressMetersEpic } from 'shell/progress-meters/redux/progressMeters.epic';
import { appShellEpics } from 'shell/redux/epics/appShell.epic';
import { spinnerHandlerEpics } from 'spinner-handler';
import { sreEpics } from 'sre/epics/sre.epic';
import { stormEpics } from 'storm/epics/storm.epic';
import { taskEpics } from 'task-components/core/epics/task.epic';
import { seeSpeakEpics } from 'task-components/see-speak/epics/seeSpeak.epic';
import { bootstrappingEpics } from '../epics/bootstrapping.epic';
import { commonUiEpic } from 'common-ui/epics/commonUi.epic';
import { avatarEditorEpics } from 'feature-areas/avatar-editor/redux/epics/avatarEditor.epic';

export const allEpics = [
  ...appConfigEpic,
  ...appShellEpics,
  ...authEpics,
  ...avatarEditorEpics,
  ...bootstrappingEpics,
  ...calibrationSceneEpics,
  ...calibrationUiEpics,
  ...commonUiEpic,
  ...curriculumEpics,
  ...customerEpics,
  ...customerSettingsEpics,
  ...devToolsEpic,
  ...educatorEpics,
  ...encounterSceneEpics,
  ...taskDemoSceneEpics,
  ...errorHandlerEpics,
  ...homeSceneEpics,
  ...introductionSceneEpics,
  ...levelCompleteSceneEpics,
  ...levelIntroSceneEpics,
  ...levelSceneEpics,
  ...loggingEpics,
  ...networkStateEpics,
  ...placementSceneEpics,
  ...programCompleteSceneEpics,
  ...progressMetersEpic,
  ...roundEpics,
  ...ScreenplayReduxProvider.epics,
  ...seeSpeakEpics,
  ...sfxEpics,
  ...spinnerHandlerEpics,
  ...sreEpics,
  ...stormEpics,
  ...stormLoboEpics,
  ...taskEpics,
  ...transitionEpics,
  ...unitCompleteSceneEpics
];
