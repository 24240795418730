import { AppState } from 'services';
import { AvatarEditorDeepLinkObservableFactory } from '../AvatarEditorDeepLinkObservableFactory';
import { CalibrationDeepLinkObservableFactory } from '../CalibrationDeepLinkObservableFactory';
import { DefaultDeepLinkObservableFactory } from '../DefaultDeepLinkObservableFactory';
import {
  HomeDeepLinkObservableFactory,
  HomeDeepLinkOutputAction
} from '../HomeDeepLinkObservableFactory';
import {
  IUnitDeepLinkDeps,
  UnitDeepLinkObservableFactory
} from '../UnitDeepLinkObservableFactory';
import {
  LevelDeepLinkOutputAction,
  LevelDeepLinkObservableFactory,
  ILevelDeepLinkDeps
} from '../LevelDeepLinkObservableFactory';
import { Observable } from 'rxjs';
import { OnboardingDeepLinkObservableFactory } from '../OnboardingDeepLinkObservableFactory';
import { PlacementDeepLinkObservableFactory } from '../PlacementDeepLinkObservableFactory';
import { PreparedScenes } from 'services/storm-lobo';
import { ProfileActionLoadSuccess, ProfileActionType } from 'services/profile';
import { RouterService, RoutePath } from 'router-service';
import { StormService } from 'storm';
import { filter, mergeMap } from 'rxjs/operators';
import { matchRoutes } from 'react-router-dom';
import { ofType, StateObservable } from 'redux-observable';
import {
  ActDeepLinkObservableFactory,
  ActDeepLinkOutputAction,
  IActDeepLinkDeps
} from '../ActDeepLinkObservableFactory';

interface IHandleDeepLinkEpicDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export type DeepLinkOutputAction =
  | HomeDeepLinkOutputAction
  | LevelDeepLinkOutputAction
  | ActDeepLinkOutputAction;

export function handleDeepLinkEpic(
  action$: Observable<ProfileActionLoadSuccess>,
  state$: StateObservable<AppState>,
  deps: IHandleDeepLinkEpicDeps
): Observable<DeepLinkOutputAction> {
  return action$.pipe(
    ofType(ProfileActionType.LoadSuccess),
    filter(() => !RouterService.isOnLoginRoute()),
    mergeMap(() => getDeepLinkObservable(state$, deps))
  );
}
handleDeepLinkEpic.displayName = 'handleDeepLinkEpic';

function getDeepLinkObservable(
  state$: StateObservable<AppState>,
  deps: IHandleDeepLinkEpicDeps
): Observable<DeepLinkOutputAction> {
  const pathMatch = matchRoutes(
    [
      RoutePath.Acts,
      RoutePath.AvatarEditor,
      RoutePath.Calibration,
      RoutePath.Educator,
      RoutePath.EducatorTab,
      RoutePath.Home,
      RoutePath.Levels,
      RoutePath.Onboarding,
      RoutePath.OnboardingOrientation,
      RoutePath.OnboardingRounds,
      RoutePath.PlacementRounds,
      RoutePath.Rounds
    ].map(path => ({ path })),
    RouterService.activeRoute
  )?.[0];

  const { stormService, ...commonDeps } = deps;
  const dependencies = { ...commonDeps, state$ };
  const dependenciesWithParams = {
    ...dependencies,
    ...pathMatch?.params
  };

  switch (pathMatch?.route.path) {
    case RoutePath.AvatarEditor:
      return AvatarEditorDeepLinkObservableFactory.createFor(dependencies)
        .observable;

    case RoutePath.Calibration:
      return CalibrationDeepLinkObservableFactory.createFor({
        ...dependencies,
        stormService
      }).observable;

    case RoutePath.Home:
      return HomeDeepLinkObservableFactory.createFor({
        ...dependencies,
        stormService
      }).observable;

    case RoutePath.Levels:
      return LevelDeepLinkObservableFactory.createFor(
        dependenciesWithParams as ILevelDeepLinkDeps
      ).observable;

    case RoutePath.Acts:
      return ActDeepLinkObservableFactory.createFor(
        dependenciesWithParams as IActDeepLinkDeps
      ).observable;

    case RoutePath.Rounds:
      return UnitDeepLinkObservableFactory.createFor(
        dependenciesWithParams as IUnitDeepLinkDeps
      ).observable;

    case RoutePath.OnboardingRounds:
      return OnboardingDeepLinkObservableFactory.createFor({
        ...(dependenciesWithParams as IUnitDeepLinkDeps),
        stormService
      }).observable;

    case RoutePath.PlacementRounds:
      return PlacementDeepLinkObservableFactory.createFor({
        ...(dependenciesWithParams as IUnitDeepLinkDeps),
        stormService
      }).observable;

    default:
      return DefaultDeepLinkObservableFactory.createFor({
        ...dependencies
      }).observable;
  }
}
