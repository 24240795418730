import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export enum PlacementAnimationCategory {
  PlacementComplete = 'Placement complete rewards'
}

export enum PlacementSceneElementName {
  BackgroundOverlay = 'background_overlay',
  Character = 'character1',
  Overlay = 'overlay'
}

export class PlacementSceneAnimationName {
  public static readonly Root = {
    Center: 'center',
    Intro: StandardSceneAnimationName.Intro,
    ShiftFarToLeft: 'shift_far_to_left',
    ShiftNearToLeft: 'shift_near_to_left'
  };

  public static readonly BackgroundOverlay = {
    Idle: StandardSceneAnimationName.Idle
  };

  public static readonly Character = {
    GestureBottomLeft: 'gesture_towards_screen_bottom_left',
    GestureBottomRight: 'gesture_towards_screen_bottom_right',
    IntroWave: 'intro_wave_1'
  };

  public static readonly Overlay = {
    Intro: StandardSceneAnimationName.Intro
  };
}
