import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { IMountPoint } from 'storm';

export class LevelVoMountPointFactory {
  public static readonly displayName = 'LevelVoMountPointInfoFactory';

  public static readonly MountPointConfigKey = 'levelVoiceoverMountPoint';

  public static create(
    configProvider: IConfigProvider,
    levelTitle: string
  ): IMountPoint {
    const baseMp = configProvider.getConfig<IMountPoint>(
      this.MountPointConfigKey
    );

    return {
      ...baseMp,
      dir: `${baseMp.dir}l_${levelTitle}`,
      name: `${baseMp.name}l_${levelTitle}`
    };
  }
}
