import { Position } from '@lexialearning/common-ui';
import { Color, Font } from 'common-styles';

export class ToolTipDialogStyles {
  private static arrowStyles = {
    borderColor: Color.Transparent,
    borderBottomColor: Color.NearWhite,
    borderWidth: 10,
    position: Position.Absolute,
    right: 8,
    bottom: 25,
    borderBottomWidth: 16
  };

  private static readonly styles = {
    toolTipArrow: {
      ...this.arrowStyles
    },
    toolTipArrowBorder: {
      ...this.arrowStyles,
      borderBottomColor: Color.Gold,
      bottom: this.arrowStyles.bottom + 3
    },
    toolTipBox: {
      backgroundColor: Color.NearWhite,
      borderColor: Color.Gold,
      borderRadius: 8,
      borderWidth: 2,
      position: Position.Absolute,
      shadowColor: Color.BlackTransparent30,
      shadowOffset: { height: 2, width: 2 },
      shadowRadius: 2,
      top: 155,
      width: 173
    },
    toolTipText: {
      borderRadius: 6,
      paddingHorizontal: 6,
      paddingVertical: 4,
      backgroundColor: Color.NearWhite,
      fontSize: Font.Size.xxSmall
    }
  };

  public static get() {
    return this.styles;
  }
}
