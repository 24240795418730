import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { ISfx, Sfx } from 'audio/sfx';
import { IScreenplayActionFactory, ScreenplayerType } from 'screenplay';

export class StormSfxScreenplayActionFactory
  implements IScreenplayActionFactory<ISfx>
{
  public readonly type = ScreenplayerType.Sfx;

  public static create(nameOrPath: Sfx): IScreenplayAction<ISfx> {
    return {
      data: {
        path: nameOrPath
      },
      type: ScreenplayerType.Sfx
    };
  }

  public create(data: ISfx): IScreenplayAction<ISfx> {
    return StormSfxScreenplayActionFactory.create(data.path);
  }
}
