import memoizeOne from 'memoize-one';
import { Justify } from 'common-styles';
import { Position } from '@lexialearning/common-ui';

const INDICATOR_WIDTH = 30;

export const VerticalIndicatorDimension = {
  TextPillOffset: -INDICATOR_WIDTH / 2,
  Width: INDICATOR_WIDTH
};

export enum VerticalIndicatorPosition {
  Left = 'left',
  Right = 'right'
}

export class VerticalIndicatorStyles {
  public static readonly build = memoizeOne(
    (position: VerticalIndicatorPosition) => ({
      container: {
        justifyContent: Justify.Center,
        paddingVertical: 12,
        position: Position.Absolute,
        width: VerticalIndicatorDimension.Width,
        ...(position === VerticalIndicatorPosition.Left && {
          left: VerticalIndicatorDimension.TextPillOffset
        }),
        ...(position === VerticalIndicatorPosition.Right && {
          right: VerticalIndicatorDimension.TextPillOffset
        })
      }
    })
  );
}
