import {
  AccessibilityOrientation,
  AccessibilityRole
} from '@lexialearning/common-ui';
import { IScreenplay, IUnitEducator } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { BackgroundImage, Column, Image, Row, Text, View } from 'common-ui';
import { EducatorSelector } from 'feature-areas/educator/redux';
import { TransitionAction } from 'feature-areas/transitions';
import * as arcImages from '../../images/arc';
import { ActDetailStyles } from './ActDetail.styles';
import { EncounterTab } from './encounter-tab/EncounterTab';
import { FunFactsBubble } from './fun-facts-bubble';
import { SkillExampleBox } from './skill-example-box/SkillExampleBox';
import { UnitButton } from './unit-button/UnitButton';
import { EducatorActivityDetail } from 'feature-areas/educator/redux/EducatorActivityDetail';

export interface IActDetailProps {
  carouselIndex: number;
  activeScreenplay?: IScreenplay;
  activityDetail: EducatorActivityDetail;
  isCurrentSlide: boolean;
  levelNumber: number;
  goToUnit(unit: IUnitEducator): void;
}

interface IActDetailState {
  isLoadingUnit: boolean;
}

export class ActDetailComponent extends React.PureComponent<
  IActDetailProps,
  IActDetailState
> {
  public static readonly displayName = 'ActDetail';

  public static readonly ScreenplayId = 'ActDetail';

  constructor(props: IActDetailProps) {
    super(props);

    this.state = {
      isLoadingUnit: false
    };
    this.handleUnitPress = this.handleUnitPress.bind(this);
  }

  private handleUnitPress(unit: IUnitEducator) {
    this.setState({ isLoadingUnit: true });
    this.props.goToUnit(unit);
  }

  private get funFacts(): IScreenplay[] {
    const { act } = this.props.activityDetail;
    const { characterFunFacts } = act;

    return characterFunFacts;
  }

  public render() {
    const { activityDetail, carouselIndex, isCurrentSlide, levelNumber } =
      this.props;
    const { isLoadingUnit } = this.state;
    const { act, encounter } = activityDetail;

    // eslint-disable-next-line import/namespace
    const arcImage = arcImages[`arc_${carouselIndex + 1}Png`];
    const skillExampleCount = encounter.hasListeningSkills ? 3 : 2;
    const styles = ActDetailStyles.get();
    const tabPanelId = 'encounter-panel';

    return (
      <Row style={styles.actContainer}>
        <BackgroundImage
          style={styles.characterContainer}
          source={act!.educatorBackground?.imageSource}
        >
          <BackgroundImage style={styles.arc} source={arcImage}>
            <View style={styles.levelCharInfo}>
              <Text style={styles.levelNumber}>Level {levelNumber}</Text>
              <View style={styles.characterNameContainer}>
                <Text style={styles.characterName}>
                  {act.character.displayName}
                </Text>
              </View>
            </View>
            <Image
              style={styles.characterImage}
              source={act!.character.fullBodyImage.imageSource}
              accessibilityLabel={act!.character.fullBodyImage.altText}
            />
            <FunFactsBubble funFacts={this.funFacts} />
          </BackgroundImage>
          <Column
            accessibilityLabel="Encounter Tabs."
            accessibilityRole={AccessibilityRole.TabList}
            ariaOrientation={AccessibilityOrientation.Vertical}
            style={styles.encounterTabs}
          >
            {act.encounters.map((e, idx) => (
              <EncounterTab
                disabled={isLoadingUnit}
                encounter={e}
                encounterNumber={idx + 1}
                selected={e.sysId === encounter.sysId}
                key={`encountertab-${e.sysId}`}
                tabPanelId={tabPanelId}
                autoFocus={isCurrentSlide && idx === 0}
              />
            ))}
          </Column>
        </BackgroundImage>
        <Column
          accessibilityRole={AccessibilityRole.TabPanel}
          ariaLabelledBy={encounter.sysId}
          id={tabPanelId}
          style={styles.encounterContent}
        >
          <Row style={styles.encounterDescContainer}>
            <SkillExampleBox
              boxCount={skillExampleCount}
              headline="Sub Skill"
              content={encounter.grammarSubskills
                .map(gs => gs.displayName)
                .join(', ')}
            />
            <SkillExampleBox
              boxCount={skillExampleCount}
              headline="Skill Example"
              content={encounter.skillExamples.join(', ')}
            />
            {encounter.hasListeningSkills && (
              <SkillExampleBox
                boxCount={skillExampleCount}
                headline="Listening Skills"
                content={act!.listeningSkill.displayName}
              />
            )}
          </Row>
          <Column>
            {encounter.units.map((u, idx) => (
              <UnitButton
                key={u.sysId}
                unit={u}
                unitNumber={idx + 1}
                onPress={this.handleUnitPress}
                disabled={isLoadingUnit}
              />
            ))}
          </Column>
        </Column>
      </Row>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    educatorContext: EducatorSelector.getContext(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  goToUnit: (unit: IUnitEducator) =>
    TransitionAction.educatorToEncounter({
      roundId: unit.rounds[0].sysId,
      unitId: unit.sysId
    })
};

export const ActDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActDetailComponent);

export const ActDetailPrivates = { mapDispatchToProps, mapStateToProps };
