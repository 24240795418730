import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import {
  CantConnectModal,
  InvalidCustomerCodeModal,
  LoginConflictModal,
  NotEnrolledModal,
  OldVersionModal,
  OutOfLicensesModal,
  ProgramCompleteModal,
  ProgramDownModal,
  WebGLDisabledModal
} from 'feature-areas/errors';

/**
 * Map of lexia standard error codes to Lobo error modals. The number of items
 * here should reduce as they get converted to Uni equivalents.
 */
export const loboErrorModalMap = new Map<LexiaStandardErrorCode, string>([
  [LexiaStandardErrorCode.LicenseViolation, OutOfLicensesModal.ModalId],
  [LexiaStandardErrorCode.ClientVersionTooOld, OldVersionModal.ModalId],
  [LexiaStandardErrorCode.NotInProgram, NotEnrolledModal.ModalId],
  [LexiaStandardErrorCode.InvalidAuthToken, LoginConflictModal.ModalId],
  [LexiaStandardErrorCode.CantConnectToServer, CantConnectModal.ModalId],
  [
    LexiaStandardErrorCode.StudentCompletedProgram,
    ProgramCompleteModal.ModalId
  ],
  [LexiaStandardErrorCode.ProgramDown, ProgramDownModal.ModalId],
  [LexiaStandardErrorCode.InvalidSiteId, InvalidCustomerCodeModal.ModalId],
  [LexiaStandardErrorCode.WebGLDisabled, WebGLDisabledModal.ModalId]
]);
