import { SoundLogCollection } from '@lexialearning/lobo-common/main-model/sre';
import { LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { AppConfigAction, AppConfigActionApplyOverrides } from 'app-config';
import { ILoboAppConfig } from 'services/app-config';
import {
  CustomerActionSettingsLoaded,
  CustomerActionType
} from 'services/customer/redux';
import { SreService } from 'sre';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { IConfigOverride } from '@lexialearning/utils';
import { AppState } from 'services';

export interface IProcessCustomerSettingsDeps {
  sre: SreService;
}

export function processCustomerSettingsEpic(
  action$: Observable<CustomerActionSettingsLoaded>,
  state$: StateObservable<AppState>,
  deps: IProcessCustomerSettingsDeps
): Observable<AppConfigActionApplyOverrides> {
  return action$.pipe(
    ofType(CustomerActionType.SettingsLoaded),
    map(({ payload: settings }) => {
      const overrides: IConfigOverride[] = [];

      if (CustomerSettingKey.SoundLogCollection in settings) {
        const value = mapSettingToSoundLogCollectionMode(
          settings[CustomerSettingKey.SoundLogCollection]
        );
        overrides.push({
          key: 'sre.soundLogCollectionMode',
          value
        });
        reconfigureSoundLogCollectionAfterConfigApplied(
          value,
          state$,
          deps.sre
        );
      }

      if (CustomerSettingKey.LogRocket in settings) {
        overrides.push({
          key: 'logger.logRocket.enabled',
          value: !!settings[CustomerSettingKey.LogRocket]
        });
      }

      if (CustomerSettingKey.LogLevel in settings) {
        overrides.push({
          key: 'logger.loggingLevel',
          value: mapLogLevelSetting(settings[CustomerSettingKey.LogLevel])
        });
      }

      return AppConfigAction.applyOverrides({ overrides });
    }),
    filter(d => d.payload.overrides!.length > 0)
  );
}
processCustomerSettingsEpic.displayName = 'processCustomerSettingsEpic';

/**
 * Map FEATURE_RSE_DISABLE_SOUND_LOG_COLLECTION to SoundLogCollection enum
 */
function mapSettingToSoundLogCollectionMode(
  setting: number
): SoundLogCollection {
  switch (setting) {
    case 0: // not disabled = enabled
      return SoundLogCollection.Full;
    case 1: // disabled
      return SoundLogCollection.None;
    case 2: // metadata only
      return SoundLogCollection.Metadata;
    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Unrecognized setting "${setting}" for soundLog collection. Defaulting to full mode`
      );

      return SoundLogCollection.Full;
  }
}

/**
 * Monitor state changes until we verify the config override was applied and
 * then config soundLog collection
 */
function reconfigureSoundLogCollectionAfterConfigApplied(
  mode: SoundLogCollection,
  state$: StateObservable<AppState>,
  sre: SreService
): void {
  state$
    .pipe(
      first(
        state =>
          UniConfigSelector.getConfig<ILoboAppConfig>(state).sre
            .soundLogCollectionMode === mode
      ),
      tap(() => {
        sre.soundLogs.configure();
      })
    )
    .subscribe();
}

function mapLogLevelSetting(setting: number): LoggingLevel {
  const settingMap = new Map<LogLevelSetting, LoggingLevel>([
    [LogLevelSetting.Disabled, LoggingLevel.Disabled],
    [LogLevelSetting.Error, LoggingLevel.Error],
    [LogLevelSetting.Info, LoggingLevel.Info],
    [LogLevelSetting.Verbose, LoggingLevel.Verbose]
  ]);

  return settingMap.get(setting) ?? LoggingLevel.Verbose;
}

export enum LogLevelSetting {
  Disabled = 0,
  Error = 10,
  Info = 20,
  Verbose = 30
}

export enum CustomerSettingKey {
  LogLevel = 'feat_19',
  LogRocket = 'feat_12', // FEATURE_RSE_ENABLE_LOGROCKET
  SoundLogCollection = 'feat_11' // FEATURE_RSE_DISABLE_SOUND_LOG_COLLECTION
}
