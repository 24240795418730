import { LexiaError } from '@lexialearning/utils';
import { loboAnimated, LoboAnimatedValue } from 'common-styles';
import { Types } from 'common-ui';
import { isNumber } from 'lodash';
import { ThemeType } from 'theme';
import {
  ColorSet,
  MARKERS_COLOR_MAP,
  MeterMarkerProgressStatus,
  MeterMarkerType
} from './meterMarker.model';

export interface IMarkerAnimations {
  setProgress(progress: MeterMarkerProgressStatus): void;
  animateCompleted: Types.Animated.CompositeAnimation;
}

export class MeterMarkerHelper {
  public static readonly displayName = 'MeterMarkerHelper';

  public static readonly PopDurationMs = 250;

  private static getColor(
    status: MeterMarkerProgressStatus,
    markerType: MeterMarkerType,
    themeType: ThemeType,
    isRecyclePass?: boolean
  ): string {
    const defaultValue = MARKERS_COLOR_MAP.get(ColorSet.Default)!.get(status)!;
    const recycleValueMaybe =
      isRecyclePass && MARKERS_COLOR_MAP.get(ColorSet.Recycle)?.get(status);
    const themeValueMaybe = MARKERS_COLOR_MAP.get(themeType)?.get(status);

    const mappedValue = recycleValueMaybe || themeValueMaybe || defaultValue;

    // Some statuses are mapped directly to a color (ie, type string) and can
    // be returned directly, some are mapped to different values for pip and bar
    // and so we need to get and return that markerType mapped value
    return typeof mappedValue === 'string'
      ? mappedValue
      : mappedValue.get(markerType)!;
  }

  /**
   * Get the color values for the given arguments and flatten into a single-level
   * mapping of status to color
   */
  private static getMarkerColorMap(
    markerType: MeterMarkerType,
    themeType: ThemeType,
    isRecyclePass?: boolean
  ): Map<MeterMarkerProgressStatus, string> {
    const flatMap = new Map<number, string>();
    Object.values(MeterMarkerProgressStatus).forEach(status => {
      if (typeof status === 'number') {
        flatMap.set(
          status,
          this.getColor(status, markerType, themeType, isRecyclePass)
        );
      }
    });

    return flatMap;
  }

  public static getMarkerBgColorInterpolation(
    markerType: MeterMarkerType,
    bgColorValue: LoboAnimatedValue,
    themeType: ThemeType,
    isRecyclePass?: boolean
  ): LoboAnimatedValue {
    if (markerType !== MeterMarkerType.Pip && isRecyclePass) {
      throw new LexiaError(
        'Invalid options: Only pip markers have a treatment for recycle pass',
        MeterMarkerHelper.displayName,
        MeterMarkerHelperError.RecyclePassInvalidForMarkerType
      ).withContext({ themeType });
    }
    if (themeType !== ThemeType.PoK && isRecyclePass) {
      throw new LexiaError(
        'Invalid options: Only Pok units can have recycle pass',
        MeterMarkerHelper.displayName,
        MeterMarkerHelperError.RecyclePassInvalidForThemeType
      ).withContext({ themeType });
    }

    const colorMap = this.getMarkerColorMap(
      markerType,
      themeType,
      isRecyclePass
    );

    // Create an array of all the values of the MeterMarkerProgressStatus enum
    const statusesArray = Object.values(MeterMarkerProgressStatus).filter(v =>
      isNumber(v)
    ) as number[];
    // Create an array of all the colors which are mapped to the given status
    // values in the above array
    const mappedColorsArray = statusesArray.map(s =>
      colorMap.get(s)
    ) as string[];

    // Create and return a color interpolation of the numeric values to their
    // mapped color
    return loboAnimated.interpolate(
      bgColorValue,
      statusesArray,
      mappedColorsArray
    );
  }

  /**
   * If progress has changed, run animations to update UI to reflect that change
   */
  public static maybeUpdateMarker(
    prevProgress: MeterMarkerProgressStatus,
    progress: MeterMarkerProgressStatus,
    animations: IMarkerAnimations
  ) {
    if (
      [
        MeterMarkerProgressStatus.Active,
        MeterMarkerProgressStatus.Upcoming
      ].includes(prevProgress) &&
      progress === MeterMarkerProgressStatus.Completed
    ) {
      animations.animateCompleted.start();
    } else if (prevProgress !== progress) {
      animations.setProgress(progress);
    }
  }
}

export enum MeterMarkerHelperError {
  RecyclePassInvalidForMarkerType = 'RecyclePassInvalidForMarkerType',
  RecyclePassInvalidForThemeType = 'RecyclePassInvalidForThemeType'
}
