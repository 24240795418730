import { UserInterface as RxUserInterface } from '@lexialearning/reactxp';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { SizingUtils } from '../helpers';
import { View } from './view';

export interface IPositionProps {
  index: number;
  onPosition(position: XYCoord, idx?: number): void;
}

export function withPositionHandler(WrappedComponent: any) {
  return class PositionHoc extends React.PureComponent<IPositionProps> {
    public static readonly displayName = 'PositionHoc';

    constructor(props: any) {
      super(props);
      this.handlePosition = this.handlePosition.bind(this);
    }

    public componentDidMount() {
      if (window?.addEventListener) {
        window.addEventListener('resize', this.handlePosition);
      }
    }

    public componentWillUnmount() {
      if (window?.removeEventListener) {
        window.removeEventListener('resize', this.handlePosition);
      }
    }

    private async handlePosition() {
      const { index, onPosition } = this.props;
      const position = await this.getPositionRelativeToApp();
      onPosition(position, index);
    }

    private async getPositionRelativeToApp(): Promise<XYCoord> {
      const offset = await RxUserInterface.measureLayoutRelativeToWindow(this);

      return SizingUtils.getOffsetRelativeToApp(offset);
    }

    public render() {
      const { onPosition, ...restProps } = this.props;

      return (
        <View onLayout={this.handlePosition}>
          <WrappedComponent {...restProps} />
        </View>
      );
    }
  } as typeof WrappedComponent;
}
withPositionHandler.displayName = 'withPositionHandler';
