import { ofType, StateObservable } from 'redux-observable';
import { ignoreElements, Observable, switchMap } from 'rxjs';
import { ProfileActionLoadSuccess, ProfileActionType } from '../../../profile';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services/bootstrapping';
import { LogRocketInitializer } from 'services/logging/log-rocket';
import { ILoboAppConfig } from 'services/app-config';
import { ConfigSelector } from '@lexialearning/utils-react';

/**
 * Add logging info about the user session once the user logs in.
 *
 * Note: this should be replaced with the Uni implementation of LogRocket
 * integration
 */
export function identifyLogsEpic(
  action$: Observable<ProfileActionLoadSuccess>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(ProfileActionType.LoadSuccess),
    switchMap(async () => {
      const { logger } = deps.appLoggerDependencies;
      const logRocketConfig = ConfigSelector.getConfig<ILoboAppConfig>(
        state$.value
      ).logger.logRocket;

      await LogRocketInitializer.identify(logRocketConfig, logger);
    }),
    ignoreElements()
  );
}
identifyLogsEpic.displayName = 'identifyLogsEpic';
