// istanbul ignore file - code generated
import { ITextInputIconProps, Path, Svg } from 'common-ui';

export function LockIconSvg(props: ITextInputIconProps) {
  const { color } = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="28"
      viewBox="0 0 102.673 140.5"
    >
      <Path
        d="M86.462,48.635V35.125a35.125,35.125,0,1,0-70.25,0v13.51H0v81.057A10.7,10.7,0,0,0,10.586,140.5H91.865a10.886,10.886,0,0,0,10.808-10.808V48.635Zm-24.725,70.4h-20.8L44.611,86.9a10.4,10.4,0,1,1,14.015-.512Zm19.321-70.4H21.615V35.125A29.346,29.346,0,0,1,51.337,5.4,29.346,29.346,0,0,1,81.058,35.125Z"
        fill={color}
      />
    </Svg>
  );
}
LockIconSvg.displayName = 'LockIconSvg';
