import { ActiveComponentState, Overflow } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { KeyNavHelper } from 'common-ui';
import memoizeOne from 'memoize-one';

const SIZE = 90;
export const MARGIN = 7;

export class SquareButtonStyles {
  public static readonly build = memoizeOne(
    (activeComponentState: ActiveComponentState, selected: boolean) => {
      const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
      const keyNavColors = Color.getSetFor(activeComponentState);

      const borderWidth = isKeyNav ? 5 : selected ? 4 : 0;

      return {
        squareButton: {
          backgroundColor: '#FAD88D',
          borderRadius: 8,
          borderWidth,
          margin: MARGIN - borderWidth,
          height: SIZE + borderWidth * 2,
          width: SIZE + borderWidth * 2,
          overflow: Overflow.Hidden,
          ...(isKeyNav
            ? {
                backgroundColor: keyNavColors.background,
                borderColor: keyNavColors.border
              }
            : selected && {
                borderColor: Color.Blue
              })
        }
      };
    }
  );
}
