import {
  ISceneDefinition,
  ISceneDefinitionBase,
  ISceneElementDefinition
} from './storm-scenes.model';

export class SceneDefinitionBuilder<
  TScene extends ISceneDefinitionBase = ISceneDefinitionBase
> {
  public static readonly displayName = 'SceneDefinitionBuilder';

  public static create(
    placeholderName: string,
    path: string,
    definition?: Partial<ISceneElementDefinition>
  ): SceneDefinitionBuilder<ISceneElementDefinition> {
    return new SceneDefinitionBuilder<ISceneElementDefinition>({
      animations: [],
      elements: [],
      lazyLoad: false,
      materials: [],

      ...definition,

      path,
      placeholderName
    });
  }

  public static createRoot(
    id: string,
    path: string,
    definition?: Partial<ISceneDefinition>
  ): SceneDefinitionBuilder<ISceneDefinition> {
    return new SceneDefinitionBuilder<ISceneDefinition>({
      animations: [],
      elements: [],
      materials: [],

      ...definition,

      id,
      path
    });
  }

  private constructor(public readonly definition: TScene) {}
}
