import { AnimatedRow, Row, useSizing } from 'common-ui';
import { AvatarEditorAction, AvatarEditorSelector } from '../redux';
import { AvatarTimerStatus } from '../redux/avatar-editor-redux.model';
import { Sfx } from 'audio';
import { TimerAnimatedStyles } from './Timer.animated-styles';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSfx } from 'audio/sfx/useSfx';

const NOT_STARTED = 0;

export function Timer() {
  const dispatch = useDispatch();
  const playSfx = useSfx();

  const appWidth = useSizing().appSize.w;

  const timerStatus = useSelector(AvatarEditorSelector.getTimerStatus);
  const duration = useSelector(AvatarEditorSelector.getTimerDuration);
  const durationRef = useRef(duration);

  const animatedStyles = useRef(
    new TimerAnimatedStyles(appWidth, durationRef.current)
  );
  const startTimeRef = useRef(NOT_STARTED);

  const start = useCallback(() => {
    // Store the start time to calculate the elapsed time
    startTimeRef.current = Date.now();
    animatedStyles.current
      .buildTimerAnimation(durationRef.current)
      .start(({ finished }) => {
        if (finished) {
          playSfx(Sfx.AvatarTimesUp);
          dispatch(AvatarEditorAction.timerTimeout());
        }
      });
  }, [dispatch, playSfx]);

  const pause = useCallback(() => {
    // Update duration to reflect the remaining time
    const elapsed = Date.now() - startTimeRef.current;
    durationRef.current = durationRef.current - elapsed;
    animatedStyles.current.getAnimations().timer.stop();
  }, []);

  /** Reset and start again, to be run after timer has run out and student elects to continue editing */
  const restart = useCallback(() => {
    durationRef.current = duration;
    animatedStyles.current.resetTimer();
    dispatch(AvatarEditorAction.timerStart());
  }, [dispatch, duration]);

  useEffect(() => {
    switch (timerStatus) {
      case AvatarTimerStatus.Paused:
        return pause();
      case AvatarTimerStatus.Running:
        return start();
      case AvatarTimerStatus.Restart:
        return restart();
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerStatus]);

  const styles = animatedStyles.current.build(appWidth);

  return (
    <Row testId={Timer.displayName} style={styles.container}>
      <AnimatedRow style={styles.timer} animatedStyle={styles.timerAnimated} />
    </Row>
  );
}
Timer.displayName = 'Timer';
