export enum DevToolsActionType {
  AdjustCompletedActivity = 'DEV_TOOLS.ADJUST_COMPLETED_ACTIVITY',
  ChangePosition = 'DEV_TOOLS.CHANGE_POSITION',
  Hide = 'DEV_TOOLS.HIDE',
  LogSreSessionInfo = 'DEV_TOOLS.LOG_SRE_SESSION_INFO',
  OpenContentfulEntry = 'DEV_TOOLS.OPEN_CONTENTFUL_ENTRY',
  OverrideSreModel = 'DEV_TOOLS.OVERRIDE_SRE_MODEL',
  PlayLevelCompleteLevelIntro = 'DEV_TOOLS.PLAY_LEVEL_COMPLETE_LEVEL_INTRO',
  SetShouldShowIosShortcuts = 'DEV_TOOLS.SET_SHOULD_SHOW_IOS_SHORTCUTS',
  SetShouldShowMicErrorShortcuts = 'DEV_TOOLS.SET_SHOULD_SHOW_MIC_ERROR_SHORTCUTS',
  ShortcutToRound = 'DEV_TOOLS.SHORTCUT_TO_ROUND',
  Show = 'DEV_TOOLS.SHOW',
  SkipToLevelPage = 'DEV_TOOLS.SKIP_TO_LEVEL_PAGE',
  UpdateHasOnscreenCharacter = 'DEV_TOOLS.UPDATE_HAS_CHARACTER'
}
