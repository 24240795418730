import { Column } from 'common-ui';
import { ProgressMetersStyles } from './ProgressMeters.styles';
import { RoundMeter } from './round-meter';
import { UnitMeter } from './unit-meter';

export function ProgressMeters() {
  const styles = ProgressMetersStyles.get();

  return (
    <Column style={styles.container}>
      <RoundMeter />
      <UnitMeter />
    </Column>
  );
}
