import {
  ICard,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import { IUserChoice } from '@lexialearning/lobo-common/tasks/user-choice';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Row } from 'common-ui';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { ThemeSize } from 'theme';
import { ITaskProps, TaskSelector } from '../core';
import { UserChoiceCard, UserChoiceCardType } from './user-choice-card';
import { IUserChoiceAnswer } from './user-choice-component.model';
import { UserChoiceStyles } from './UserChoice.styles';

export interface IUserChoiceProps extends ISfxProps, ITaskProps<IUserChoice> {
  selectedChoices?: string[];
}

export interface IUserChoiceState {
  cardType: UserChoiceCardType;
}

export class UserChoiceComponent extends React.PureComponent<
  IUserChoiceProps,
  IUserChoiceState
> {
  public static readonly displayName = 'UserChoice';

  constructor(props: IUserChoiceProps) {
    super(props);

    const { taskContent, themeSize } = props;
    const cardType = this.getCardType(taskContent.choices, themeSize);
    this.state = { cardType };
  }

  private getCardType(choices: ICard[], themeSize: ThemeSize) {
    return choices.some(
      (c: ICard) =>
        (themeSize === ThemeSize.Large && c.label.length > 22) ||
        (themeSize === ThemeSize.Small && c.label.length > 28)
    )
      ? UserChoiceCardType.LongText
      : choices.some((c: ICard) => c.label !== '')
      ? UserChoiceCardType.ShortText
      : UserChoiceCardType.Image;
  }

  public componentDidUpdate(prevProps: IUserChoiceProps) {
    if (
      this.props.selectedChoices &&
      !isEqual(prevProps.selectedChoices, this.props.selectedChoices) &&
      this.props.selectedChoices.length ===
        this.props.taskContent.selectionCount
    ) {
      const answer: IUserChoiceAnswer = {
        selectedChoices: this.props.taskContent.choices!.filter(
          c => this.props.selectedChoices?.includes(c.sysId)
        ),
        selections: this.props.taskContent.choices
          .map((c, idx) =>
            this.props.selectedChoices!.includes(c.sysId) ? idx : undefined
          )
          .filter(s => s !== undefined) as number[]
      };
      this.props.onEvaluated(TaskEvaluationResult.Correct, answer);
    } else if (
      !isEqual(prevProps.selectedChoices, this.props.selectedChoices)
    ) {
      this.props.playSfx(Sfx.Neutral);
    }
  }

  public render() {
    const {
      taskContent: { choices }
    } = this.props;
    const { cardType } = this.state;
    const styles = UserChoiceStyles.get();

    return (
      <Row style={styles.taskRow}>
        <Row style={styles.choiceRow}>
          {choices.map((choice, idx) => (
            <UserChoiceCard
              key={idx}
              sysId={choice.sysId}
              index={idx}
              delayIndex={idx === 1 && choices.length === 2 ? 2 : idx}
              cardType={cardType}
              choice={choice}
            />
          ))}
        </Row>
      </Row>
    );
  }
}
export function mapStateToProps(state: unknown) {
  return {
    selectedChoices: TaskSelector.getSelectedChoices(state)
  };
}

export const UserChoice = withSfx(
  connect(mapStateToProps)(UserChoiceComponent)
);

export const UserChoicePrivates = {
  mapStateToProps
};
