import { InteractionState } from 'common-styles';
import { IPositionProps, View, withPositionHandler } from 'common-ui';
import { IconSlotStyles } from './IconSlot.styles';
import { PillSvg } from '../svg/Pill.lx-svg';
import { ActiveComponentState } from '@lexialearning/common-ui';

export interface IIconSlotProps extends IPositionProps {
  activeComponentState: ActiveComponentState;
  interactionState: InteractionState;
  pillOriginIdx: number;
}

export function IconSlotComponent(props: IIconSlotProps) {
  const { activeComponentState, pillOriginIdx, interactionState } = props;
  const isChecked = pillOriginIdx > -1;
  const styles = IconSlotStyles.build(activeComponentState, isChecked);

  return (
    <View style={styles.pillContainer} testId={IconSlotComponent.displayName}>
      <PillSvg
        activeComponentState={activeComponentState}
        interactionState={interactionState}
        isChecked={isChecked}
      />
    </View>
  );
}
IconSlotComponent.displayName = 'IconSlotComponent';

export const IconSlot = withPositionHandler(IconSlotComponent);
