import { setEncountersUntilEditableEpic } from './setEncountersUntilEditable.epic';
import { handleTimeoutUserConfirmationEpic } from './handleTimeoutUserConfirmation.epic';
import { handleTimerTimeoutEpic } from './handleTimerTimeout.epic';
import { logAvatarEditorEntryEpic } from './logAvatarEditorEntry.epic';
import { saveAvatarEpic } from './saveAvatar.epic';
import { timerPauseEpic } from './timerPause.epic';
import { timerStartEpic } from './timerStart.epic';
import { toggleAvatarMusicEpic } from './toggleAvatarMusic.epic';
import { handleKeepAliveInAvatarEditorEpic } from './handleKeepAliveInAvatarEditor.epic';

export const avatarEditorEpics = [
  setEncountersUntilEditableEpic,
  handleKeepAliveInAvatarEditorEpic,
  handleTimeoutUserConfirmationEpic,
  handleTimerTimeoutEpic,
  logAvatarEditorEntryEpic,
  saveAvatarEpic,
  timerPauseEpic,
  timerStartEpic,
  toggleAvatarMusicEpic
];
