import { G, Path, Rect, Svg } from 'common-ui';

export interface IIndicatorHorizontalSvgProps {
  width?: number;
}

export function HorizontalIndicatorSvg(props: IIndicatorHorizontalSvgProps) {
  const fillColor = '#0367B3';
  const { width = 66 } = props;

  return (
    <Svg width={width} height="37" viewBox={`0 0 ${width} 37`}>
      <G transform="translate(-104.511 -681.606)">
        <Path
          fill={fillColor}
          d="M104.5,718.6v-37c0,0,2.6,7.8,7.5,11.8s12,3.9,12,3.9v4.9c0,0-7.1-0.1-12,3.9S104.5,718.6,104.5,718.6z"
        />
        <Rect
          x="123.7"
          y="697.3"
          fill={fillColor}
          width={width - 28}
          height="4.9"
        />
        <Path
          transform={`translate(${width - 66} 0)`}
          fill={fillColor}
          d="M170.5,718.6v-37c0,0-2.6,7.8-7.5,11.8s-12,3.9-12,3.9v4.9c0,0,7.1-0.1,12,3.9S170.5,718.6,170.5,718.6z"
        />
      </G>
    </Svg>
  );
}
HorizontalIndicatorSvg.displayName = 'HorizontalIndicatorSvg';
