import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ScreenplayActionCancelMusic, ScreenplayActionType } from '../redux';
import { IScreenplayDeps } from './screenplay-epic.model';

/**
 * Cancel any active music screenplay playback.
 * No action is dispatched post-process. The playEpic is expected
 * to dispatch Canceled instead.
 */
export function cancelMusicScreenplayEpic(
  action$: Observable<ScreenplayActionCancelMusic>,
  _: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<void> {
  return action$.pipe(
    ofType(ScreenplayActionType.CancelMusic),
    tap(() => {
      deps.screenplayer.cancelMusic();
    }),
    ignoreElements()
  );
}
cancelMusicScreenplayEpic.displayName = 'cancelMusicScreenplayEpic';
