import {
  Align,
  AnimatableCSSProperty,
  Color,
  Justify,
  LoboAnimatedValue,
  loboAnimated
} from 'common-styles';
import { IGlossyButtonStyleOverride } from 'common-ui/components/button/glossy-button/GlossyButton.animated-styles';
import { Types } from 'common-ui';
import { merge } from 'lodash';
import {
  ButtonMeterAnimatedStylesDefaults,
  ButtonMeterAnimationHelper,
  IButtonMeterAnimations,
  IButtonMeterStyles,
  IButtonMeterValues
} from 'shared-components';

interface ISreButtonMeterStyles extends IButtonMeterStyles {
  spacer1: Types.ViewStyle;
  spacer2: Types.ViewStyle;
  submitContainer: Types.ViewStyle;
  submitContainerAnimated: Types.AnimatedViewStyle;
  submitOpacity: Types.ViewStyle;
  submitOpacityAnimated: Types.AnimatedViewStyle;
  submitButtonOverride: IGlossyButtonStyleOverride;
}

export interface ISreButtonMeterAnimations extends IButtonMeterAnimations {
  fastReset: Types.Animated.CompositeAnimation;
  immediateReset: Types.Animated.CompositeAnimation;
}

interface ISreButtonMeterValues<T = number> extends IButtonMeterValues<T> {
  submitBackgroundColor: T;
  submitOpacity: T;
}

export interface ISreButtonMeterAnimatedValues
  extends ISreButtonMeterValues<LoboAnimatedValue> {}

export class SreButtonMeterAnimatedStyles {
  private readonly barWidth = 9;
  private readonly borderContainerSize = 53;
  private readonly submitButtonSize = 38;

  private readonly initialValues: ISreButtonMeterValues = {
    ...ButtonMeterAnimatedStylesDefaults.initialValues,
    borderContainerWidth: this.borderContainerSize,
    submitBackgroundColor: 0,
    submitOpacity: 0
  };

  private readonly meterOpenValues = {
    ...ButtonMeterAnimatedStylesDefaults.meterOpenValues,
    borderContainerWidth: 209,
    submitBackgroundColor: 0,
    submitOpacity: 1
  };

  private readonly styles: ISreButtonMeterStyles = merge(
    {},
    ButtonMeterAnimatedStylesDefaults.styles,
    {
      outerContainer: {
        marginTop: 26,
        marginBottom: 6
      },
      borderContainer: {
        borderRadius: 38,
        height: this.borderContainerSize
      },
      spacer1: { width: 30, flexGrow: 0, flexShrink: 5 }, //
      vuMeterContainer: {
        flexShrink: 5,
        width: 110
      },
      vuMeterOverrides: {
        container: {
          paddingLeft: this.barWidth
        },
        bar: {
          height: 33,
          marginHorizontal: 0,
          marginLeft: -this.barWidth,
          marginVertical: 0,
          width: this.barWidth
        },
        caret: {
          bottom: -13,
          right: -10,
          transform: [{ scale: 0.5 }]
        }
      },
      spacer2: { width: 15, flexGrow: 0, flexShrink: 5 },
      submitContainer: {
        borderRadius: this.submitButtonSize / 2,
        height: this.submitButtonSize,
        width: this.submitButtonSize,
        marginRight: (this.borderContainerSize - this.submitButtonSize) / 2,
        justifyContent: Justify.Center,
        alignItems: Align.Center
      },
      submitContainerAnimated: {
        backgroundColor: undefined,
        opacity: undefined
      },
      submitOpacity: {
        borderRadius: 24
      },
      submitOpacityAnimated: {
        opacity: undefined
      },
      submitButtonOverride: {
        height: 47,
        width: 47
      }
    }
  );

  private readonly animationHelper: ButtonMeterAnimationHelper;
  private readonly animations: ISreButtonMeterAnimations;
  private get animatedValues(): ISreButtonMeterAnimatedValues {
    return this.animationHelper.animatedValues as ISreButtonMeterAnimatedValues;
  }

  constructor() {
    this.animationHelper = this.createHelper();
    this.setSubmitBtnAnimatedStyles();

    const speed = 1;
    this.animations = this.createAnimations(speed);
  }

  private createHelper(): ButtonMeterAnimationHelper {
    const submitBtnAnimatedValues = {
      submitBackgroundColor: loboAnimated.createValue(
        this.initialValues.submitBackgroundColor
      ),
      submitOpacity: loboAnimated.createValue(this.initialValues.submitOpacity)
    };

    return new ButtonMeterAnimationHelper(
      this.initialValues,
      this.meterOpenValues,
      this.styles,
      submitBtnAnimatedValues
    );
  }

  private setSubmitBtnAnimatedStyles(): void {
    this.styles.submitContainerAnimated.backgroundColor =
      loboAnimated.interpolate(
        this.animatedValues.submitBackgroundColor,
        [0, 1],
        [Color.Transparent, Color.Gray10]
      );
    this.styles.submitOpacityAnimated.opacity =
      this.animatedValues.submitOpacity;
  }

  private createAnimations(speed: number): ISreButtonMeterAnimations {
    return {
      showVuMeter: this.createShowVuMeterAnimation(speed),
      reset: this.createResetAnimation(speed),
      fastReset: this.createResetAnimation(speed / 2),
      immediateReset: {
        start:
          // istanbul ignore next - LOBO-18576 to resolve in separate PR
          onEnd => {
            this.resetValues();
            onEnd?.({ finished: true });
          },
        stop:
          // istanbul ignore next
          // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
          () => {}
      }
    };
  }

  private createShowVuMeterAnimation(speed: number) {
    const innerElementsHalfFadeInValues = {
      delay: 100,
      duration: 150
    };

    const submitFadeInMs = 600;

    const submitCircleBgSetToGray = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      this.animatedValues.submitBackgroundColor,
      {
        duration: 0,
        toValue: 1 // temp before fading back out to transparent
      }
    );

    const submitCircleBgGrayFadeOut = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      this.animatedValues.submitBackgroundColor,
      {
        duration: submitFadeInMs * speed,
        toValue: this.meterOpenValues.submitBackgroundColor
      }
    );

    const submitFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.submitOpacity,
      {
        duration: submitFadeInMs * speed,
        toValue: this.meterOpenValues.submitOpacity
      }
    );

    return this.animationHelper.createShowVuMeterAnimation(
      innerElementsHalfFadeInValues,
      speed,
      [submitCircleBgSetToGray],
      [submitCircleBgGrayFadeOut, submitFadeIn]
    );
  }

  private createResetAnimation(speed: number) {
    const submitOpacityReset = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.submitOpacity,
        {
          duration: 0,
          toValue: this.initialValues.submitOpacity
        }
      )
    ]);

    return this.animationHelper.createResetAnimation(speed, [
      submitOpacityReset
    ]);
  }

  // istanbul ignore next - LOBO-18576 to resolve in separate PR
  private resetValues() {
    this.animatedValues.borderContainerWidth.setValue(
      this.initialValues.borderContainerWidth
    );
    this.animatedValues.borderContainerOpacity.setValue(
      this.initialValues.borderContainerOpacity
    );
    this.animatedValues.innerOpacity.setValue(this.initialValues.innerOpacity);
    this.animatedValues.micOpacity.setValue(this.initialValues.micOpacity);
    this.animatedValues.submitBackgroundColor.setValue(
      this.initialValues.submitBackgroundColor
    );
    this.animatedValues.submitOpacity.setValue(
      this.initialValues.submitOpacity
    );
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
