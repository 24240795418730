import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  SreSessionAction,
  SreSessionActionCancel,
  SreSessionActionCancelSuccess,
  SreSessionActionType
} from '../redux';
import { ISreDependencies } from './sre-epics.model';

export function cancelSreSessionEpic(
  action$: Observable<SreSessionActionCancel | UserGlobalActionLogout>,
  _state$: StateObservable<unknown>,
  deps: ISreDependencies
): Observable<SreSessionActionCancelSuccess> {
  return action$.pipe(
    ofType(SreSessionActionType.Cancel, UserGlobalActionType.Logout),
    switchMap(async () => {
      await deps.sre.cancelCurrentSession();

      return SreSessionAction.cancel.success();
    })
  );
}
cancelSreSessionEpic.displayName = 'cancelSreSessionEpic';
