import { StateObservable } from 'redux-observable';
import { ProgramContextService } from 'curriculum-services';
import { ActReactAnimationName } from 'feature-areas/acts';
import {
  LevelSceneAnimationName,
  LevelSceneElementName
} from 'feature-areas/levels';
import { SceneName } from 'services/storm-lobo';
import { MockMicListenScreenplayFactory } from 'shared-components/faux-mic-button';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { ActScreenplayId } from './act-screenplay-builder.model';

export interface IActOutroScreenplayBuilderDeps {
  programContextService: ProgramContextService;
  state$: StateObservable<unknown>;
}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Showcase+to+Encounter+transition
 */
export class ActOutroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'ActOutroScreenplayBuilder';

  public static createFor(
    deps: IActOutroScreenplayBuilderDeps
  ): ActOutroScreenplayBuilder {
    return new ActOutroScreenplayBuilder(deps);
  }

  private constructor(deps: IActOutroScreenplayBuilderDeps) {
    super(ActScreenplayId.Outro);

    const { programContextService, state$ } = deps;

    this.disableUtilityBar()
      .turnOffFunFacts()
      .playMockMicListen()
      .fadeOutActPageElements()
      .playLevelSceneOutro()
      .awaitContentLoaded(programContextService, state$);
  }

  private turnOffFunFacts(): ActOutroScreenplayBuilder {
    this.builder.addStormAnimation(
      {
        name: LevelSceneAnimationName.FunFactsEffects.Outro,
        targetElement: LevelSceneElementName.Effects,
        targetScene: SceneName.Level
      },
      { concurrent: true }
    );

    return this;
  }

  private playMockMicListen(): ActOutroScreenplayBuilder {
    this.builder.addScreenplay(
      MockMicListenScreenplayFactory.create().screenplay
    );

    return this;
  }

  private fadeOutActPageElements(): ActOutroScreenplayBuilder {
    this.builder.addReactAnimation(ActReactAnimationName.FadeOut);

    return this;
  }

  private playLevelSceneOutro(): ActOutroScreenplayBuilder {
    this.builder.addStormAnimation({
      name: LevelSceneAnimationName.Root.Outro,
      targetScene: SceneName.Level
    });

    return this;
  }
}
