import {
  InstructionalStep,
  IRound,
  IScreenplayAction,
  TaskTypeName,
  UnitType
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { sample } from 'lodash';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import { ProgramMode, RoundContext } from 'curriculum-services';
import {
  CharacterSceneCharacterAnimationLayer,
  CharacterSceneElementName
} from 'feature-areas/character-scene/character-scene.model';
import {
  EncounterScene,
  EncounterSceneAnimationName,
  EncounterSceneElementName
} from 'feature-areas/encounters';
import { LevelScene } from 'feature-areas/levels';
import { PlacementScene } from 'feature-areas/placement';
import { PlacementSceneAnimationName } from 'feature-areas/placement/placement-scene/placement-scene.model';
import {
  ControlPanelComponent,
  FadeAnimationType
} from 'feature-areas/shell/control-panel/ControlPanel';
import { MeterMarkerHelper } from 'feature-areas/shell/progress-meters/MeterMarker.helper';
import { RoundMeterComponent } from 'feature-areas/shell/progress-meters/round-meter/RoundMeter';
import { RoundMeterAnimatedStyles } from 'feature-areas/shell/progress-meters/round-meter/RoundMeter.animated-styles';
import { BarAnimatedStyles } from 'feature-areas/shell/progress-meters/unit-meter/bar/Bar.animated-styles';
import {
  CharacterAnimationCategory,
  PreparedScenes,
  SceneName
} from 'services/storm-lobo';
import { TaskReactAnimationName } from 'task-components/core/task-component.model';
import { TaskWorkAreaAnimatedStyles } from 'task-components/core/task-work-area/TaskWorkArea.animated-styles';
import { RoundIntroType } from '../rounds/redux';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { UnitScreenplayId } from './unit-screenplay-builders.model';
import { MeterMarkerAnimationName } from 'feature-areas/shell/progress-meters/meterMarker.model';

export interface IUnitIntroScreenplayDeps {
  preparedScenes: PreparedScenes;
  roundContext: RoundContext;
  roundIntroType: RoundIntroType;
}

export class UnitIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'UnitIntroScreenplayBuilder';

  public static createFor(
    deps: IUnitIntroScreenplayDeps
  ): UnitIntroScreenplayBuilder {
    return new UnitIntroScreenplayBuilder(deps);
  }

  private constructor(private readonly deps: IUnitIntroScreenplayDeps) {
    super(UnitScreenplayId.Intro);

    const { preparedScenes, roundContext, roundIntroType } = deps;
    const {
      parentUnit: activeUnit,
      encounter: { units }
    } = roundContext;
    const unitCount = units.length;
    const activeUnitIndex = units.findIndex(u => u.sysId === activeUnit.sysId);
    const isPok = activeUnit.type === UnitType.PresentationOfKnowledge;
    const isInstruction = roundContext.isInstructionRound;
    const isSubUnit = roundContext.isSubunit;
    const isRecycling = !!roundContext.parentUnitRecyclePass;
    const isOnboarding = roundContext.programMode === ProgramMode.Onboarding;
    const isPlacement = roundContext.programMode === ProgramMode.Placement;
    const isSeeSpeak =
      roundContext.round.task?.taskType === TaskTypeName.SeeSpeak;

    const isOnboardingIntro = roundIntroType === RoundIntroType.OnboardingIntro;
    const isPlacementIntro = roundIntroType === RoundIntroType.PlacementIntro;
    const isEncounterIntro = roundIntroType === RoundIntroType.EncounterIntro;
    const isSubUnitOutro = roundIntroType === RoundIntroType.SubUnitOutro;
    const encounterOrPlacementScene = preparedScenes.encounterOrPlacement!;

    this.enableUtilityBar()
      .showScene(encounterOrPlacementScene)
      .backgroundAnimations(isEncounterIntro);

    if (isOnboarding || isPlacement) {
      if (roundContext.hasOnscreenCharacter) {
        this.shiftCharacterLeft(isOnboarding);
      } else {
        this.detachCharacter();
      }
    } else if (roundContext.hasOnscreenCharacter) {
      this.characterIdle(encounterOrPlacementScene);
    }

    this.hideLevel(preparedScenes.level);

    if (
      (isOnboardingIntro && isSeeSpeak) ||
      isPlacementIntro ||
      isEncounterIntro
    ) {
      this.fadeInControlPanel();
    }

    if (isPlacementIntro || isEncounterIntro) {
      this.unitMeterIntroAnimation(unitCount).roundMeterIntroAnimation();
    }

    if (!(isOnboardingIntro || isSubUnitOutro || isSubUnit || isRecycling)) {
      this.activeRoundPipAnimation();
    }

    if (isRecycling) {
      this.recyclePipAnimation(activeUnit.rounds);
    }

    if (isPok) {
      this.pokActions();
    }

    if (isInstruction) {
      this.instructionActions();
    }

    // eslint-disable-next-line complexity
    if (
      (isPlacementIntro || isEncounterIntro) &&
      // POK has additional actions which would complicate the calculation
      // of the delay, but also likely provide enough of an additional time
      // buffer that the delay is not necessary for entry into POK
      !isPok
    ) {
      this.addDelayMaybe(activeUnitIndex);
    }
  }

  private showScene(
    encounterOrPlacementScene: EncounterScene | PlacementScene
  ): UnitIntroScreenplayBuilder {
    this.builder.addCallback(async () => {
      encounterOrPlacementScene.show();
    });

    return this;
  }

  private backgroundAnimations(
    isEncounterIntro: boolean
  ): UnitIntroScreenplayBuilder {
    const { parentUnit: activeUnit } = this.deps.roundContext;
    const isMediaUnit = activeUnit.type === UnitType.Media;
    const isInstructionStep =
      activeUnit.instructionalStep === InstructionalStep.Instruction;

    if (isEncounterIntro) {
      this.backgroundIntroAnimation();
    }
    if (isMediaUnit || isInstructionStep) {
      this.backgroundDimAnimation();
    }

    return this;
  }

  private backgroundIntroAnimation(): UnitIntroScreenplayBuilder {
    this.builder
      .addMusic({ path: Music.BackgroundIntro }, { concurrent: true })
      .addStormAnimation({
        name: EncounterSceneAnimationName.Background.Intro,
        targetElement: EncounterSceneElementName.Background,
        targetScene: SceneName.Encounter
      });

    return this;
  }

  private backgroundDimAnimation(): UnitIntroScreenplayBuilder {
    this.builder.addStormAnimation({
      name: EncounterSceneAnimationName.Background.Dim,
      targetElement: EncounterSceneElementName.Background,
      targetScene: SceneName.Encounter
    });

    return this;
  }

  private shiftCharacterLeft(
    isOnboarding: boolean
  ): UnitIntroScreenplayBuilder {
    const animationName = isOnboarding
      ? PlacementSceneAnimationName.Root.ShiftFarToLeft
      : PlacementSceneAnimationName.Root.ShiftNearToLeft;
    this.builder.addStormAnimation({
      name: animationName,
      targetScene: SceneName.Placement
    });

    return this;
  }

  private detachCharacter(): UnitIntroScreenplayBuilder {
    const { preparedScenes } = this.deps;

    this.builder.addCallback(() => {
      preparedScenes.encounterOrPlacement?.character?.detach();
    });

    return this;
  }

  private characterIdle(
    preparedScene: EncounterScene | PlacementScene
  ): UnitIntroScreenplayBuilder {
    const sceneName = preparedScene.scene.id;
    const idleAnimation = preparedScene.character.pickOneAnimation(
      CharacterAnimationCategory.Idle
    );
    this.builder.addStormAnimation({
      animationLayer: CharacterSceneCharacterAnimationLayer.Base,
      blendTimeSeconds: 0.25,
      loop: true,
      name: idleAnimation.name,
      targetElement: CharacterSceneElementName.Character,
      targetScene: sceneName
    });

    return this;
  }

  private fadeInControlPanel(): UnitIntroScreenplayBuilder {
    this.builder.addReactAnimation(
      ControlPanelComponent.getAnimationName(FadeAnimationType.FadeIn),
      { concurrent: true }
    );

    return this;
  }

  private unitMeterIntroAnimation(
    unitCount: number
  ): UnitIntroScreenplayBuilder {
    [...Array(unitCount)].map((_, idx) => {
      this.builder.addReactAnimation(
        `${MeterMarkerAnimationName.BarIntro}-${idx}`,
        { concurrent: true },
        { awaitRegistrationTimeoutMs: 1500 }
      );
    });

    return this;
  }

  private roundMeterIntroAnimation(): UnitIntroScreenplayBuilder {
    this.builder.addReactAnimation(RoundMeterComponent.entryAnimationName, {
      concurrent: true
    });

    return this;
  }

  private activeRoundPipAnimation(): UnitIntroScreenplayBuilder {
    this.builder.addReactAnimation(MeterMarkerAnimationName.PipIntro, {
      concurrent: true
    });

    return this;
  }

  private recyclePipAnimation(rounds: IRound[]): UnitIntroScreenplayBuilder {
    rounds.map(round => {
      this.builder.addReactAnimation(
        `${MeterMarkerAnimationName.RecycleIntro}-${round.sysId}`,
        { concurrent: true }
      );
    });

    return this;
  }

  private pokActions(): UnitIntroScreenplayBuilder {
    this.builder
      .addStormAnimation(
        {
          name: EncounterSceneAnimationName.Background.PokIntro,
          targetElement: EncounterSceneElementName.Background,
          targetScene: SceneName.Encounter
        },
        { concurrent: true }
      )
      .addSfx(Sfx.PokIntro)
      .addAction(this.getPokVo())
      .addStormAnimation({
        loop: true,
        name: EncounterSceneAnimationName.Background.PokIdle,
        targetElement: EncounterSceneElementName.Background,
        targetScene: SceneName.Encounter
      });

    return this;
  }

  private getPokVo(): IScreenplayAction {
    const { roundContext } = this.deps;
    const { act, parentUnit: activeUnit, parentUnitRecyclePass } = roundContext;

    const character = activeUnit.character
      ? activeUnit.character
      : act.character;
    const pokVoPool = parentUnitRecyclePass
      ? character.presentationOfKnowledgeRecycling
      : character.presentationOfKnowledgeOpening;
    const pokVo = sample(pokVoPool)?.actions[0];

    if (!pokVo) {
      throw new LexiaError(
        'Missing pok voiceover',
        UnitIntroScreenplayBuilder.displayName,
        UnitIntroScreenplayBuilderError.NoPokVo
      );
    }

    return pokVo;
  }

  private hideLevel(
    levelScene: LevelScene | undefined
  ): UnitIntroScreenplayBuilder {
    this.builder.addCallback(() => {
      levelScene?.hide();
    });

    return this;
  }

  private instructionActions(): UnitIntroScreenplayBuilder {
    this.builder
      .addAction(this.getInstructionVo())
      .addReactAnimation(
        TaskReactAnimationName.InstructionUnitEntry,
        { concurrent: true },
        { awaitRegistrationTimeoutMs: 1500 }
      )
      .addSfx(Sfx.InstructionOn);

    return this;
  }

  private getInstructionVo(): IScreenplayAction {
    const { roundContext } = this.deps;
    const preBranchingPool = roundContext.parentUnit.character
      ? roundContext.parentUnit.character.preBranching
      : roundContext.act.character.preBranching;
    const instructionVo = sample(preBranchingPool)?.actions[0];

    if (!instructionVo) {
      throw new LexiaError(
        'Missing instruction voiceover',
        UnitIntroScreenplayBuilder.displayName,
        UnitIntroScreenplayBuilderError.NoInstructionVo
      ).withContext({ unit: roundContext.parentUnit });
    }

    return instructionVo;
  }

  /**
   * Delay to allow round/unit meter animations to finish before Entry phase ends
   * (Needed to gate the enabling of the skip button for Educator Mode,
   *  but seems worth having for students as well, to make sure everything
   *  properly completes before moving on)
   */
  private addDelayMaybe(activeUnitIndex: number): this {
    const delayMs =
      MeterMarkerHelper.PopDurationMs +
      RoundMeterAnimatedStyles.EntryAnimationTiming +
      activeUnitIndex * BarAnimatedStyles.AnimationTiming -
      TaskWorkAreaAnimatedStyles.StandardEntryAnimationTiming;

    this.builder.addDelay(delayMs);

    return this;
  }
}

export enum UnitIntroScreenplayBuilderError {
  NoPokVo = 'NoPokVo',
  NoInstructionVo = 'NoInstructionVo'
}
