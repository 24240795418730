import { DragSourceConnector, DragSourceMonitor } from 'react-dnd';
import { Sfx } from 'audio';
import {
  IDndDragSourceCollectedProps,
  IDndDragSourceProps,
  IDndDragSourcePropsWithSfx,
  IDragSourceSpec
} from './drag-source.model';

/**
 * Provides default implementations for the dnd DragSource HOC collect function
 * and spec.
 */
export class DragSourceHelper {
  public static collect(
    connect: DragSourceConnector,
    monitor: DragSourceMonitor
  ): IDndDragSourceCollectedProps {
    return {
      canDrag: monitor.canDrag(),
      connectDragPreview: connect.dragPreview(),
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  }

  public static createSpec(spec?: Partial<IDragSourceSpec>): IDragSourceSpec {
    const useSfx =
      spec === undefined || spec.useSfx === undefined ? true : spec.useSfx;

    return {
      beginDrag: createDragBeginHandler(useSfx),
      endDrag: createDragEndHandler(useSfx)
    };
  }
}

function createDragBeginHandler(useSfx: boolean) {
  return (props: IDndDragSourcePropsWithSfx): IDndDragSourceProps => {
    const { playSfx, ...rest } = props;

    if (useSfx) {
      playSfx(Sfx.DragBegin);
    }

    if (props.onBeginDrag) {
      props.onBeginDrag(props.index, props);
    }

    return rest;
  };
}

function createDragEndHandler(useSfx: boolean) {
  return (
    props: IDndDragSourcePropsWithSfx,
    monitor: DragSourceMonitor
  ): void => {
    const droppedToTarget = props.activeTargetIdx > -1;

    if (useSfx) {
      const sfx = droppedToTarget ? Sfx.DragEnd : Sfx.CantDo;
      props.playSfx(sfx);
    }

    if (props.onEndDrag) {
      const item = monitor.getItem<any>();
      if (item) {
        props.onEndDrag(item.index);
      }
    }
  };
}
