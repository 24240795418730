export class MicSelectionModalStyles {
  private static readonly styles = {
    container: {
      minHeight: 370,
      minWidth: 500
    }
  };

  public static get() {
    return this.styles;
  }
}
