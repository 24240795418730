import { Color, Font } from 'common-styles';

export class SkipToNextPositionButtonStyles {
  private static readonly styles = {
    buttonOverrides: {
      button: {
        backgroundColor: Color.DarkYellow,
        height: 35,
        marginHorizontal: 0,
        marginVertical: 0,
        padding: 5,
        width: 130
      },
      buttonText: {
        color: Color.Black,
        fontSize: Font.Size.small,
        lineHeight: Font.Size.small,
        marginLeft: 0
      }
    },
    container: {
      top: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
