import {
  IScreenplay,
  TaskAudioSupport,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { ICarousel } from '@lexialearning/lobo-common/tasks/carousel';
import * as React from 'react';
import { connect } from 'react-redux';
import { Carousel, Image, View } from 'common-ui';
import { AppShellAction } from 'feature-areas/shell';
import {
  IPlayActionPayload,
  ScreenplayAction,
  ScreenplayBuilder,
  ScreenplaySelector
} from 'screenplay';
import { ITaskProps, ModelingAction } from 'task-components/core';
import { SubmitButton } from 'task-components/shared';
import { SystemInfo } from 'utils';
import { CarouselTaskStyles } from './CarouselTask.styles';
import { SlideNumber } from './slide-number';

export interface ICarouselTaskProps extends ITaskProps<ICarousel> {
  isSlideVoiceoverPlaying: boolean;
  isRehearPlaying: boolean;
  setPresentationVoiceover(voiceover: IScreenplay): void;
  playSlide(slidePayload: IPlayActionPayload): void;
  cancelVoiceover(): void;
  cancelMusic(): void;
}

interface ICarouselTaskState {
  currentSlideIdx: number;
  isInteractive: boolean;
  maxPlayedSlideIndex: number;
}

export class CarouselTaskComponent extends React.PureComponent<
  ICarouselTaskProps,
  ICarouselTaskState
> {
  public static readonly displayName = 'CarouselTask';

  constructor(props: ICarouselTaskProps) {
    super(props);

    this.state = {
      currentSlideIdx: 0,
      isInteractive: false,
      maxPlayedSlideIndex: -1
    };

    this.setPresentationVoiceover = this.setPresentationVoiceover.bind(this);
    this.playCurrentVoiceover = this.playCurrentVoiceover.bind(this);
    this.maybeCancelVoiceover = this.maybeCancelVoiceover.bind(this);
    this.handleSlideAudio = this.handleSlideAudio.bind(this);
    this.handlePressPrevious = this.handlePressPrevious.bind(this);
    this.handlePressNext = this.handlePressNext.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateSlide = this.updateSlide.bind(this);
    this.renderBottomControl = this.renderBottomControl.bind(this);
    this.renderRightControl = this.renderRightControl.bind(this);
  }

  public componentDidMount() {
    this.props.updateCanSubmit({ canSubmit: true });
  }

  public componentWillUnmount() {
    this.props.cancelMusic();
  }

  public componentDidUpdate(prevProps: ICarouselTaskProps) {
    const { currentSlideIdx } = this.state;
    const { phase } = this.props;

    if (
      currentSlideIdx === 0 &&
      prevProps.phase !== phase &&
      phase === TaskPhase.Interactive
    ) {
      this.handleSlideAudio();
    }
  }

  private getCurrentSlideVoiceover() {
    const {
      taskContent: { slides }
    } = this.props;
    const { currentSlideIdx } = this.state;

    return slides[currentSlideIdx].voiceover!;
  }

  private setPresentationVoiceover() {
    const { setPresentationVoiceover } = this.props;

    setPresentationVoiceover(this.getCurrentSlideVoiceover());
  }

  private createSlidePayload(): IPlayActionPayload {
    const voiceover = this.getCurrentSlideVoiceover();
    const slideScreenplay = ScreenplayBuilder.create(SLIDE_SCREENPLAY_ID)
      .addReduxAction(AppShellAction.disableAudioSupport())
      .addScreenplay(voiceover)
      .addCallback(() => {
        this.setState({
          isInteractive: true,
          maxPlayedSlideIndex: this.state.currentSlideIdx
        });
      }).screenplay;

    return {
      nextAction: AppShellAction.enableAudioSupport(),
      screenplay: slideScreenplay
    };
  }

  private playCurrentVoiceover() {
    const { playSlide } = this.props;
    const slidePayload = this.createSlidePayload();

    this.setState({ isInteractive: false });
    playSlide(slidePayload);
  }

  private maybeCancelVoiceover() {
    const { isSlideVoiceoverPlaying, isRehearPlaying, cancelVoiceover } =
      this.props;

    if (isSlideVoiceoverPlaying || isRehearPlaying) {
      cancelVoiceover();
    }
  }

  private handleSlideAudio() {
    const { currentSlideIdx, maxPlayedSlideIndex } = this.state;
    this.setPresentationVoiceover();

    if (currentSlideIdx > maxPlayedSlideIndex) {
      this.playCurrentVoiceover();
    }
  }

  private handlePressPrevious() {
    this.updateSlide(this.state.currentSlideIdx - 1);
  }

  private handlePressNext() {
    this.updateSlide(this.state.currentSlideIdx + 1);
  }

  private handleSubmit() {
    this.setState({ isInteractive: false });
    const { onEvaluated, cancelMusic } = this.props;

    cancelMusic();
    onEvaluated(TaskEvaluationResult.Correct, {});
  }

  private updateSlide(slideIdx: number) {
    this.maybeCancelVoiceover();

    if (this.state.currentSlideIdx !== slideIdx) {
      this.setState(
        {
          currentSlideIdx: slideIdx
        },
        this.handleSlideAudio
      );
    }
  }

  private renderSlides(): React.ReactElement[] {
    const {
      taskContent: { slides }
    } = this.props;
    const styles = CarouselTaskStyles.get().image;

    return slides.map((s, idx) => (
      <Image
        key={`slideImg${idx}`}
        style={styles}
        source={s.image?.imageSource}
        accessibilityLabel={s.image?.altText}
      />
    ));
  }

  private renderBottomControl(): React.ReactElement {
    return <SlideNumber number={this.state.currentSlideIdx + 1} />;
  }

  private renderRightControl(): React.ReactElement {
    return <SubmitButton onPress={this.handleSubmit} />;
  }

  public render() {
    const {
      taskContent: { slides }
    } = this.props;

    const { currentSlideIdx, isInteractive, maxPlayedSlideIndex } = this.state;
    const isLastSlide = currentSlideIdx === slides.length - 1;
    const isSlidePlayed = currentSlideIdx <= maxPlayedSlideIndex;

    const shouldShowSubmitButton = isLastSlide && isSlidePlayed;

    const styles = CarouselTaskStyles.get();

    return (
      <View style={styles.container}>
        <Carousel
          accessibilityLabel="Task Media Carousel."
          currentSlideIdx={currentSlideIdx}
          onPressNext={this.handlePressNext}
          onPressPrevious={this.handlePressPrevious}
          renderBottomControl={this.renderBottomControl}
          renderRightControl={
            shouldShowSubmitButton ? this.renderRightControl : undefined
          }
          // TODO: remove SystemInfo.isNative check so swiping will work on Chromebooks LOBO-12521
          scrollEnabled={SystemInfo.isNative && isInteractive}
          disabledLeftCtrl={!isInteractive}
          disabledRightCtrl={!isInteractive || !isSlidePlayed}
          slideWidth={CarouselTaskStyles.SlideWidth}
          slides={this.renderSlides()}
          styleOverrides={styles.carouselOverrides}
          useSnapNavigation
          updateCurrentSlide={this.updateSlide}
        />
      </View>
    );
  }
}

const SLIDE_SCREENPLAY_ID = 'slideScreenplay';

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: unknown) => {
  const activeScreenplay = ScreenplaySelector.getActiveScreenplayId(state);

  return {
    isRehearPlaying: activeScreenplay === TaskAudioSupport.Presentation,
    isSlideVoiceoverPlaying: activeScreenplay === SLIDE_SCREENPLAY_ID
  };
};

const mapDispatchToProps = {
  cancelMusic: () => ScreenplayAction.cancelMusic(),
  cancelVoiceover: () => ScreenplayAction.cancel(),
  playSlide: (slidePayload: IPlayActionPayload) =>
    ScreenplayAction.play(slidePayload),
  setPresentationVoiceover: (voiceover: IScreenplay) =>
    ModelingAction.setModeling({
      direction: { autoplay: false, items: [] },
      presentation: {
        autoplay: true,
        items: [{ data: {}, highlight: false, id: 'carousel-slide', voiceover }]
      }
    })
};

export const CarouselTask = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarouselTaskComponent);

export const CarouselTaskPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
