import memoizeOne from 'memoize-one';
import { Color } from 'common-styles';
import { Position } from '@lexialearning/common-ui';

export interface IUserControlButtonStyleOverride {
  margin?: number;
  position?: Position;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  backgroundColor?: string;
  borderColor?: string;
}

export enum UserControlButtonSize {
  Large,
  Small
}

export class UserControlButtonStyles {
  private static readonly container = {
    backgroundColor: Color.BlackTransparent30,
    borderColor: Color.Black
  };

  public static readonly containerSmall = {
    ...this.container,
    borderRadius: 28,
    borderWidth: 4,
    height: 55,
    width: 55
  };

  public static readonly containerLarge = {
    ...this.container,
    borderRadius: 47,
    borderWidth: 6,
    height: 94,
    width: 94
  };

  public static readonly build = memoizeOne(
    (
      size: UserControlButtonSize,
      styleOverride?: IUserControlButtonStyleOverride
    ) => {
      const isSmall = size === UserControlButtonSize.Small;

      return {
        container: {
          ...(isSmall ? this.containerSmall : this.containerLarge),
          ...styleOverride
        },
        ...(isSmall && {
          buttonStyleOverride: {
            height: 38,
            width: 38
          }
        })
      };
    }
  );
}
