import { IActivityPosition, IPositionChange } from '@lexialearning/lobo-common';
import { ActionType, createAction } from 'typesafe-actions';
import { ActivityPositionActionType } from './activity-position-redux.model';

export const ActivityPositionAction = {
  /**
   * Update several activity positions. For example, to set unset round IDs, or
   * to manipulate via dev tools.
   */
  adjusted: createAction(ActivityPositionActionType.Adjusted)<
    IActivityPosition[]
  >(),
  /**
   * Request to make the imminent activity position as the active position.
   * This is typically dispatched from a screenplay at the point it wishes to
   * officially transition from the current program context to the next one.
   * This leads to a dispatch of activityPositionChanged.
   */
  change: createAction(
    ActivityPositionActionType.Change
  )<void | IActivityPositionChangeActionPayload>(),
  /**
   * The student's position has changed within the active activity.
   * This could be due to a completion, stepping, or forking event.
   * Program context content loads may follow from this.
   */
  changed: createAction(
    ActivityPositionActionType.Changed
  )<IActivityPosition>(),
  /**
   * Educator selected an encounter on the active activity
   * (only in educator mode)
   */
  encounterSelected: createAction(
    ActivityPositionActionType.EncounterSelected
  )<IActivityPositionSelectEncounterActionPayload>(),
  /**
   * The next position on the active activity has been prepared.
   * Should be stored in the imminentPosition property.
   */
  prepared: createAction(
    ActivityPositionActionType.Prepared
  )<IPositionChange>(),
  /**
   * Active activity position artificially set. Primarily for educator mode
   * or deep linking.
   */
  selected: createAction(
    ActivityPositionActionType.Selected
  )<IActivityPosition>(),
  /**
   * Kicks off epic to dispatch necessary actions to skip to the next
   * position when requested
   */
  skip: createAction(ActivityPositionActionType.Skip)(),
  /**
   * Alias to prepared but used specifically for skipping rounds in educator
   * mode
   */
  skipPrepared: createAction(
    ActivityPositionActionType.SkipPrepared
  )<IPositionChange>()
};

export interface IActivityPositionChangeActionPayload {
  suppressUnitLoad?: boolean;
}

export interface IActivityPositionSelectEncounterActionPayload {
  encounterId: string;
}

export type ActivityPositionActionAdjusted = ActionType<
  typeof ActivityPositionAction.adjusted
>;
export type ActivityPositionActionChange = ActionType<
  typeof ActivityPositionAction.change
>;
export type ActivityPositionActionChanged = ActionType<
  typeof ActivityPositionAction.changed
>;
export type ActivityPositionActionPrepared = ActionType<
  typeof ActivityPositionAction.prepared
>;
export type ActivityPositionActionSkip = ActionType<
  typeof ActivityPositionAction.skip
>;
export type ActivityPositionActionSelected = ActionType<
  typeof ActivityPositionAction.selected
>;
export type ActivityPositionActionSkipPrepared = ActionType<
  typeof ActivityPositionAction.skipPrepared
>;

export type ActivityPositionActions = ActionType<typeof ActivityPositionAction>;
