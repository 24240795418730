import { TaskPhase } from '@lexialearning/lobo-common/main-model';
import { useSelector } from 'react-redux';
import { InteractionState } from 'common-styles';
import { GlossyButton } from 'common-ui';
import { IGlossyButtonProps } from 'common-ui/components/button/glossy-button/GlossyButton';
import { ProgramContextSelector } from 'curriculum-services';
import { TaskSelector } from 'task-components/core';
import { ButtonType } from '@lexialearning/reactxp/dist/common/Types';

export function SubmitButton(props: IGlossyButtonProps) {
  const defaultInteractionState = useSelector(state =>
    getInteractionState(state)
  );
  const interactionState = props.interactionState || defaultInteractionState;
  const disabled =
    interactionState !== InteractionState.Default || props.disabled;

  return (
    <GlossyButton
      {...props}
      {...{ disabled, interactionState }}
      testId={SubmitButton.displayName}
      type={ButtonType.Submit}
    />
  );
}
SubmitButton.displayName = 'SubmitButton';

function getInteractionState(state: unknown) {
  const phase = TaskSelector.getPhase(state);
  const canSubmit = TaskSelector.getCanSubmit(state);

  switch (phase) {
    case TaskPhase.Feedback:
      const fbIntState = TaskSelector.getFeedbackInteractionState(
        ProgramContextSelector.getLastEvaluationResultMaybe(state)
      );

      return fbIntState === InteractionState.Default
        ? InteractionState.Disabled
        : fbIntState;
    case TaskPhase.Interactive:
      return canSubmit ? InteractionState.Default : InteractionState.Disabled;
    case TaskPhase.Solution:
      const showSolution = TaskSelector.getShowSolution(state);

      return showSolution
        ? InteractionState.Highlighted
        : InteractionState.Disabled;
    case TaskPhase.Entry:
    default:
      return InteractionState.Disabled;
  }
}
