import memoizeOne from 'memoize-one';
import { Align, Color } from 'common-styles';
import { Cursor, Position } from '@lexialearning/common-ui';

export class DeveloperAccessMenuStyles {
  private static readonly menuItemStyles = {
    backgroundColor: Color.NearWhiteTransparent40,
    borderRadius: 20,
    cursor: Cursor.Pointer,
    height: 25,
    marginHorizontal: 5,
    minWidth: 125,
    paddingHorizontal: 20,
    paddingVertical: 2
  };

  private static readonly micErrorButtonStyles = {
    ...DeveloperAccessMenuStyles.menuItemStyles,
    backgroundColor: Color.Blue
  };

  private static readonly styles = {
    devToolsButton: {
      ...DeveloperAccessMenuStyles.menuItemStyles,
      left: 250,
      position: Position.Absolute,
      top: 15
    },
    micErrorText: {
      color: Color.NearWhite,
      fontSize: 16
    },
    shortcutRow: {
      alignSelf: Align.Center,
      position: Position.Absolute,
      top: 50
    },
    text: {
      fontSize: 16
    }
  };

  public static readonly build = memoizeOne(
    (isPreamble: boolean, isInteractive: boolean) => {
      const maybeInteractiveOpacity = isInteractive ? 1 : 0.2;

      return {
        ...DeveloperAccessMenuStyles.styles,
        simulateCorrect: {
          ...DeveloperAccessMenuStyles.menuItemStyles,
          backgroundColor: Color.Green,
          opacity: maybeInteractiveOpacity
        },
        simulateIncorrect: {
          ...DeveloperAccessMenuStyles.menuItemStyles,
          backgroundColor: Color.Red,
          opacity: maybeInteractiveOpacity
        },
        simulateNoSignal: {
          ...DeveloperAccessMenuStyles.micErrorButtonStyles,
          opacity: maybeInteractiveOpacity
        },
        simulateTooLoud: {
          ...DeveloperAccessMenuStyles.micErrorButtonStyles,
          opacity: maybeInteractiveOpacity
        },
        simulateTooSoft: {
          ...DeveloperAccessMenuStyles.micErrorButtonStyles,
          opacity: maybeInteractiveOpacity
        },
        skipPreamble: {
          ...DeveloperAccessMenuStyles.menuItemStyles,
          opacity: isPreamble ? 1 : 0.3
        }
      };
    }
  );
}
