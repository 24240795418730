import {
  IImageProps as CuiIImageProps,
  Image as CuiImage,
  ImageStyle as CuiImageStyle,
  ImportantForAccessibility,
  ResizeMode as CuiResizeMode
} from '@lexialearning/common-ui';
import * as React from 'react';
import { ImageStyles } from './Image.styles';
import { LexiaError } from '@lexialearning/utils';
import { Logger } from '@lexialearning/utils-react';

interface IImageProps extends Omit<CuiIImageProps, 'style'> {
  style?: CuiImageStyle;
  accessibilityLabel?: string;
  importantForAccessibility?: ImportantForAccessibility;
}
export class Image extends React.PureComponent<IImageProps> {
  public static readonly displayName = 'Image';

  constructor(props: IImageProps) {
    super(props);

    this.handleError = this.handleError.bind(this);
  }

  private async handleError(err: Error) {
    const lexiaError = new LexiaError(
      'Image component errored',
      Image.displayName,
      ImageError.RenderError
    ).withCause(err);
    void Logger.logError(lexiaError);
  }

  public render() {
    const {
      accessibilityLabel,
      children,
      importantForAccessibility,
      source,
      resizeMode,
      style,
      testId
    } = this.props;

    return (
      source !== '' && (
        <CuiImage
          accessibilityLabel={accessibilityLabel}
          importantForAccessibility={importantForAccessibility}
          onError={this.handleError}
          resizeMode={resizeMode || CuiResizeMode.Stretch}
          source={source}
          style={ImageStyles.build(style)}
          testId={testId}
        >
          {children}
        </CuiImage>
      )
    );
  }
}

export enum ImageError {
  RenderError = 'RenderError'
}
