import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LxStormAudio } from 'storm';
import { SfxAction, SfxActionPlay, SfxActionPlayComplete } from './Sfx.action';
import { SfxActionType } from './sfx.model';
import { ILogger } from '@lexialearning/main-model';

export interface ISfxDeps {
  logger: ILogger;
  stormAudioPlayer: LxStormAudio;
}

export function playSfxEpic(
  action$: Observable<SfxActionPlay>,
  _state$: StateObservable<unknown>,
  deps: ISfxDeps
): Observable<SfxActionPlayComplete> {
  return action$.pipe(
    ofType(SfxActionType.Play),
    mergeMap(async action => {
      try {
        await deps.stormAudioPlayer.playSfx(action.payload.path);
      } catch (err) {
        // TODO: (LOBO-12969) Temporarily suppressing invasive sxf playback error until addressed
        // Need to remove the error log to the console as well.
        /* istanbul ignore if  */
        /* istanbul ignore else */
        if (err?.message?.indexOf('Error getting ready to play audio') < 0) {
          await deps.logger.logError(
            new LexiaError(
              `Error attempting to play SFX: ${err?.message}`,
              playSfxEpic.displayName,
              PlaySfxEpicError.PlaybackError
            )
              .withCause(err)
              .withContext({ action })
          );
        } else {
          // eslint-disable-next-line no-console
          console.error('Suppressing sfx playback error:', err?.message);
        }
      }
    }),
    map(() => SfxAction.playComplete())
  );
}

playSfxEpic.displayName = 'playSfxEpic';

export enum PlaySfxEpicError {
  PlaybackError = 'PlaybackError'
}
