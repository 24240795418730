import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { ICompletionChoiceStyleOverride } from '../completion-component.model';

export interface ICompletionChoiceStyleProps {
  shouldBeHidden?: boolean;
  styleOverride?: ICompletionChoiceStyleOverride;
  pillWidth: number;
}

export class CompletionChoiceStyles {
  public static readonly Height = 40;

  public static readonly build = memoizeOne(
    (props: ICompletionChoiceStyleProps) => {
      const { shouldBeHidden, styleOverride, pillWidth } = props;

      return merge(
        {},
        {
          textPill: {
            container: {
              height: CompletionChoiceStyles.Height,
              opacity: shouldBeHidden ? 0 : 1,
              width: pillWidth
            }
          }
        },
        styleOverride
      );
    }
  );
}
