import { Cursor, Direction, Overflow } from '@lexialearning/common-ui';
import { Align, Color, Font, ShadowStyle } from 'common-styles';

export class BrowserCardStyles {
  private static readonly Radius = 10;

  private static readonly styles = {
    browserCard: {
      alignItems: Align.Stretch,
      alignSelf: Align.Center,
      backgroundColor: Color.NearWhite,
      borderRadius: BrowserCardStyles.Radius,
      flexDirection: Direction.Row,
      marginBottom: 30,
      minWidth: 230,
      ...ShadowStyle.button,
      cursor: Cursor.Pointer
    },
    browserName: {
      fontSize: Font.Size.small
    },
    browserNameWrapper: {
      borderBottomRightRadius: BrowserCardStyles.Radius,
      borderTopRightRadius: BrowserCardStyles.Radius,
      flex: 1,
      overflow: Overflow.Hidden
    },
    logo: {
      height: 80,
      width: 80
    },
    logoBox: {
      backgroundColor: Color.Gray5,
      borderBottomLeftRadius: BrowserCardStyles.Radius,
      borderTopLeftRadius: BrowserCardStyles.Radius,
      overflow: Overflow.Hidden,
      padding: 10
    }
  };

  public static get() {
    return BrowserCardStyles.styles;
  }
}
