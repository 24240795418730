import { Overflow } from '@lexialearning/common-ui';
import { Align, Color } from 'common-styles';

export interface IColorSelectorStyleOverride {
  marginLeft?: number;
  marginTop?: number;
}

export class ColorSelectorStyles {
  private static readonly styles = {
    container: {
      alignSelf: Align.Start,
      borderColor: Color.NearWhite,
      borderRadius: 20,
      borderWidth: 2,
      marginLeft: 23,
      marginRight: 7,
      marginTop: 20,
      width: 41,
      height: 236,
      overflow: Overflow.Hidden
    }
  };

  public static get() {
    return this.styles;
  }
}
