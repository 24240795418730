import { TextButton } from 'common-ui';
import { AppCrasher } from 'services/error-handler/app-crasher/AppCrasher';

interface ITypes {
  style: object;
}

export function ReloadAppButton(props: ITypes) {
  return (
    <TextButton
      onPress={() => AppCrasher.reload()}
      styleOverride={props.style}
      text="Reload App"
    />
  );
}
