import { IScreenplay } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import { IntroductionSceneAnimationName } from 'feature-areas/introduction/introduction-scene/introduction-scene.model';
import { IntroductionSceneAction } from 'feature-areas/introduction/introduction-scene/redux';
import { AppShellAction } from 'feature-areas/shell';
import {
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { StormService } from 'storm';
import { ScreenplayTesterReactAnimationName } from './ScreenplayTester';
import {
  ScreenplayTesterActionPlay,
  ScreenplayTesterActionType
} from './ScreenplayTester.action';

export interface IPlayTestScreenplayDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function playTestScreenplayEpic(
  action$: Observable<ScreenplayTesterActionPlay>,
  _state$: StateObservable<unknown>,
  deps: IPlayTestScreenplayDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(ScreenplayTesterActionType.Play),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      await stormService?.initialization;

      return createTestScreenplay(preparedScenes);
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}

playTestScreenplayEpic.displayName = 'playTestScreenplayEpic';

function createTestScreenplay(preparedScenes: PreparedScenes): IScreenplay {
  return ScreenplayBuilder.create('tester screenplay')
    .addReduxAction(IntroductionSceneAction.prepare())
    .addCallback(async () => {
      const introductionScene = await preparedScenes.introductionReady;
      introductionScene.show();
    })
    .addReactAnimation(ScreenplayTesterReactAnimationName.Item1, {
      concurrent: true
    })
    .addReactAnimation(ScreenplayTesterReactAnimationName.Item2, {
      concurrent: true
    })
    .addStormAnimation(
      {
        loop: true,
        name: IntroductionSceneAnimationName.Root.Intro,
        targetScene: SceneName.Introduction
      },
      { concurrent: true }
    )
    .addMusic({ path: Music.Branding }, { concurrent: true })
    .addSfx(Sfx.Flare)
    .addReduxAction(AppShellAction.disableUtilityBar()).screenplay;
}
