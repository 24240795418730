import {
  Button as CuiButton,
  ButtonStyle as CuiButtonStyle,
  IButtonProps as ICuiButtonProps,
  ISyntheticEvent
} from '@lexialearning/common-ui';
import { Types as RxTypes, UserInterface } from '@lexialearning/reactxp';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CommonUiSelector } from '../../redux/CommonUi.selector';
import {
  IGlobalDisabledProps,
  withGlobalDisabled
} from '../withGlobalDisabled.hoc';
import { ButtonBaseStyles } from './ButtonBase.styles';
import { SystemInfo } from '@lexialearning/utils-react';

export interface IButtonBaseProps
  extends IGlobalDisabledProps,
    Omit<ICuiButtonProps, 'style'> {
  autoFocus?: boolean;
  style?: CuiButtonStyle;
  isKeydownHandlingDisabled?: boolean;
  ignoreKeydownHandlingDisabled?: boolean;
  forwardedRef?: any;
}

export class ButtonBaseComponent extends React.PureComponent<IButtonBaseProps> {
  public static readonly displayName = 'ButtonBase';

  private readonly buttonRef: any;

  constructor(props: IButtonBaseProps) {
    super(props);
    this.buttonRef = React.createRef();
    this.handleHoverStart = this.handleHoverStart.bind(this);
    this.handleHoverEnd = this.handleHoverEnd.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handlePress = this.handlePress.bind(this);
    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
  }

  public componentDidMount(): void {
    const { autoFocus, forwardedRef } = this.props;
    // TODO: remove when this is done in the underlying ReactXp library
    // Currently we need to do this here and in a setTimeout because the use of
    // restrictFocusWithin steals focus
    // https://jira.lexialearning.com/browse/UNI-4724
    // But we want to be able to set autoFocus prop which ReactXp should already
    // use to set initial focus, and remove all use of focusOnMount
    if (autoFocus && !SystemInfo.isNative) {
      setTimeout(() => {
        (forwardedRef ?? this.buttonRef)?.current?.focus();
      }, 10);
    }
  }

  private handleHoverStart(e: ISyntheticEvent) {
    // TODO: remove when this is done in the underlying ReactXp library
    if (!UserInterface.isNavigatingWithKeyboard() && !this.props.disabled) {
      this.props.onHoverStart?.(e);
    }
  }

  private handleHoverEnd(e: ISyntheticEvent) {
    // TODO: remove when this is done in the underlying ReactXp library
    if (!UserInterface.isNavigatingWithKeyboard() && !this.props.disabled) {
      this.props.onHoverEnd?.(e);
    }
  }

  private handlePress(e: ISyntheticEvent) {
    this.maybeCallInteractionFunction(e, this.props.onPress);
  }

  private handlePressIn(e: ISyntheticEvent) {
    this.maybeCallInteractionFunction(e, this.props.onPressIn);
  }

  private handlePressOut(e: ISyntheticEvent) {
    this.maybeCallInteractionFunction(e, this.props.onPressOut);
  }

  private handleKeyDown(e: RxTypes.KeyboardEvent) {
    this.maybeCallInteractionFunction(e, this.props.onKeyDown);
  }

  private handleKeyUp(e: RxTypes.KeyboardEvent) {
    this.maybeCallInteractionFunction(e, this.props.onKeyUp);
  }

  private maybeCallInteractionFunction(
    e: ISyntheticEvent,
    interactionFunction?: (e: any) => void
  ) {
    // native always stops propagation for buttons, even if no press handler is defined,
    // so this is added for web to keep them aligned
    e?.stopPropagation();

    if (!this.props.disabled && (!e || this.isNonMouseOrLeftClick(e))) {
      interactionFunction?.(e);
    }
  }

  /**
   * Checks whether either:
   *   1) is not a mouse event, i.e. is a tap or key event (e.button === undefined),
   *   2) is a left click (e.button === 0)
   */
  private isNonMouseOrLeftClick(e: ISyntheticEvent) {
    return !(e as RxTypes.MouseEvent).button;
  }

  public render() {
    const {
      disabledOpacity,
      forwardedRef,
      ignoreKeydownHandlingDisabled,
      isKeydownHandlingDisabled,
      style,
      onHoverStart,
      onHoverEnd,
      onKeyDown,
      onKeyUp,
      onPressIn,
      onPressOut,
      onPress,
      ...restProps
    } = this.props;
    const styles = ButtonBaseStyles.build(style);

    return (
      <CuiButton
        {...restProps}
        disabledOpacity={disabledOpacity ?? styles.opacity}
        forwardedRef={forwardedRef ?? this.buttonRef}
        onHoverEnd={this.handleHoverEnd}
        onHoverStart={this.handleHoverStart}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
        onPress={this.handlePress}
        onPressIn={this.handlePressIn}
        onPressOut={this.handlePressOut}
        style={styles}
      />
    );
  }
}

// istanbul ignore next - trivial
const mapStateToProps = (state: unknown) => ({
  isKeydownHandlingDisabled: CommonUiSelector.isKeydownHandlingDisabled(state)
});

export const ButtonBase = compose(
  withGlobalDisabled,
  connect(mapStateToProps)
)(ButtonBaseComponent) as typeof ButtonBaseComponent;
