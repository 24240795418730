import {
  IAct,
  ILevel,
  ITextWithVoiceover,
  ITopic
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { sample } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { Mount } from 'storm';
import { NumberUtils } from 'utils';
import { ILevelState, LevelVoiceoverMountStatus } from './level-redux.model';

export class LevelSelector {
  public static readonly displayName = 'LevelSelector';

  public static areVoiceoversMounted: (state: unknown) => boolean;

  public static getState: (state: unknown) => ILevelState;

  public static getActs: (state: unknown) => IAct[];

  public static getLevel: (state: unknown) => ILevel;

  public static getLevelMaybe: (state: unknown) => ILevel | undefined;

  public static getLevelId: (state: unknown) => string;

  public static getLevelIdMaybe: (state: unknown) => string | undefined;

  public static getMount: (state: unknown) => Mount | undefined;

  public static getLevelNumber: (state: unknown) => number;

  public static getLevelNumberMaybe: (state: unknown) => number | undefined;

  public static getTopics: (state: unknown) => ITopic[];

  /**
   * Get level content only when isReady is true (meaning the level is ready for
   * display in the app, as determined by the application)
   */
  public static getLevelDisplayReady: (state: unknown) => ILevel | undefined;

  /**
   * Get a random welcome message from the current level
   */
  public static getWelcomeMessage: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static createSelectors(selector: (state: any) => ILevelState) {
    this.areVoiceoversMounted = createSelector(
      selector,
      (state: ILevelState) =>
        state.voiceovers.status === LevelVoiceoverMountStatus.Mounted
    );

    this.getState = createSelector(selector, (state: ILevelState) => state);
    this.getLevel = createSelector(selector, (state: ILevelState) => {
      if (!state.levelContent) {
        throw new LexiaError(
          'No active level in redux',
          LevelSelector.displayName,
          LevelSelectorError.NoLevelContent
        );
      }

      return state.levelContent;
    });
    this.getLevelMaybe = createSelector(
      this.getState,
      (state: ILevelState) => state.levelContent
    );

    this.getActs = createSelector(
      this.getLevelMaybe,
      (level: ILevel | undefined) => level?.acts || []
    );

    this.getLevelDisplayReady = createSelector(
      this.getState,
      (state: ILevelState) => (state.isReady ? state.levelContent : undefined)
    );
    this.getLevelId = createSelector(this.getLevel, (l: ILevel) => l.sysId);
    this.getLevelIdMaybe = createSelector(
      this.getLevelMaybe,
      (l: ILevel | undefined) => l?.sysId
    );
    this.getLevelNumber = createSelector(this.getLevel, (l: ILevel) =>
      NumberUtils.parse(l.title)
    );
    this.getLevelNumberMaybe = createSelector(
      this.getLevelMaybe,
      (l: ILevel | undefined) => l && NumberUtils.parse(l.title)
    );
    this.getMount = createSelector(
      selector,
      (state: ILevelState) => state.voiceovers.mount
    );
    this.getTopics = createSelector(this.getActs, (acts: IAct[]) =>
      acts.map(a => a.topic)
    );
    this.getWelcomeMessage = createSelector(
      this.getLevelMaybe,
      (level: ILevel | undefined) => sample(level?.welcomeMessage || [])
    );
  }
}

export enum LevelSelectorError {
  NoLevelContent = 'NoLevelContent',
  NoMountPoint = 'NoMountPoint'
}
