import { ActionType, createAction } from 'typesafe-actions';
import { AppConfigActionType } from './app-config-redux.model';
import { IConfigOverride } from '@lexialearning/utils';

export const AppConfigAction = {
  applyOverrides: createAction(
    AppConfigActionType.ApplyOverrides
  )<IAppConfigOverridesPayload>(),
  updateEntries: createAction(AppConfigActionType.UpdateEntries)<
    IAppConfigUpdateEntryPayload[]
  >()
};

export interface IAppConfigOverridesPayload {
  overrides?: IConfigOverride[];
  overrideSetName?: string;
}

export interface IAppConfigUpdatedPayload {
  config: object;
}

export interface IAppConfigUpdateEntryPayload {
  key: string;
  value: string | boolean | number | object | undefined;
}

export type AppConfigActions = ActionType<typeof AppConfigAction>;
export type AppConfigActionApplyOverrides = ActionType<
  typeof AppConfigAction.applyOverrides
>;
export type AppConfigActionUpdateEntries = ActionType<
  typeof AppConfigAction.updateEntries
>;
