import { FlexWrap, Position } from '@lexialearning/common-ui';
import { Color, FontFamily } from 'common-styles';

export class TextboardStyles {
  private static readonly WordSpacing = 30;

  private static readonly textStyles = {
    color: Color.NearWhite,
    fontFamily: FontFamily.ArchitectsDaughterRegular,
    fontSize: 55,
    marginRight: TextboardStyles.WordSpacing
  };

  private static readonly styles = {
    buttonContainer: {
      bottom: 15,
      position: Position.Absolute
    },
    content: {
      flexWrap: FlexWrap.Wrap,
      padding: 35,
      paddingBottom: 70,
      paddingRight: 5
    },
    highlightedText: {
      ...TextboardStyles.textStyles,
      color: Color.DarkYellow
    },
    highlightedTextWithoutSpace: {
      ...TextboardStyles.textStyles,
      color: Color.DarkYellow,
      marginLeft: -TextboardStyles.WordSpacing
    },
    text: TextboardStyles.textStyles,
    textWithoutSpace: {
      ...TextboardStyles.textStyles,
      marginLeft: -TextboardStyles.WordSpacing
    }
  };

  public static get() {
    return this.styles;
  }
}
