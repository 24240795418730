import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProgramContextActionLoaded } from '../../program-context/redux/ProgramContext.action';
import { ProgramMode } from '../../program-context/program-context.model';
import { ProgramContextActionType } from '../../program-context/redux/program-context-redux.model';
import {
  PlacementAction,
  PlacementActionLoad
} from '../redux/Placement.action';

export function requestPlacementContentLoadEpic(
  action$: Observable<ProgramContextActionLoaded>
): Observable<PlacementActionLoad> {
  return action$.pipe(
    ofType(ProgramContextActionType.Loaded),
    filter(a =>
      [ProgramMode.Placement, ProgramMode.Onboarding].includes(a.payload.mode)
    ),
    map(() => PlacementAction.load.request())
  );
}

requestPlacementContentLoadEpic.displayName = 'requestPlacementContentLoadEpic';
