import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommonUiAction,
  CommonUiActionSetGlobalDisabled
} from 'common-ui/redux/CommonUi.action';
import {
  SpinnerActionHide,
  SpinnerActionShow,
  SpinnerActionType
} from 'spinner-handler';

export function toggleGlobalDisabledEpic(
  action$: Observable<SpinnerActionHide | SpinnerActionShow>
): Observable<CommonUiActionSetGlobalDisabled> {
  return action$.pipe(
    ofType(SpinnerActionType.Hide, SpinnerActionType.Show),
    map(action =>
      CommonUiAction.setGlobalDisabled({
        isGlobalDisabled: action.type === SpinnerActionType.Show
      })
    )
  );
}
toggleGlobalDisabledEpic.displayName = 'toggleGlobalDisabledEpic';
