import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { SpinnerHandler } from '../SpinnerHandler';
import { SpinnerAction, SpinnerActions } from './Spinner.action';
import {
  ConfigActionInitSuccess as UniConfigActionInitSuccess,
  ConfigAction as UniConfigAction
} from '@lexialearning/utils-react';

export interface IConfigureSpinnerHandlerEpicDeps {
  configProvider: IConfigProvider;
}

export function configureSpinnerHandlerEpic(
  action$: Observable<UniConfigActionInitSuccess>,
  _: StateObservable<unknown>,
  deps: IConfigureSpinnerHandlerEpicDeps
): Observable<SpinnerActions> {
  return action$.pipe(
    filter(UniConfigAction.init.success.match),
    take(1),
    tap(() => {
      SpinnerHandler.configure(deps.configProvider);
    }),
    mergeMap(() => SpinnerHandler.visibility$),
    map((spinnerVisible: boolean) => {
      if (spinnerVisible) {
        return SpinnerAction.show();
      }

      return SpinnerAction.hide();
    })
  );
}
configureSpinnerHandlerEpic.displayName = 'configureSpinnerHandlerEpic';
