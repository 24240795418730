import * as React from 'react';
import { BackgroundImage, Column, Text } from 'common-ui';
import { ModalContentStyles } from './ModalContent.styles';

export interface IModalContentProps {
  children?: React.ReactNode;
  headline?: string;
  image?: string;
  text?: string;
}

export class ModalContent extends React.PureComponent<IModalContentProps> {
  public static readonly displayName = 'ModalContent';

  public render() {
    const { children, headline, image, text } = this.props;
    const styles = ModalContentStyles.get();

    return (
      <Column style={styles.contentContainer}>
        {!!headline && (
          <Text testId="modalHeadline" style={styles.headline}>
            {headline}
          </Text>
        )}
        {!!text && (
          <Text testId="modalTextContent" style={styles.text}>
            {text}
          </Text>
        )}
        {children}
        {!!image && <BackgroundImage source={image} style={styles.img} />}
      </Column>
    );
  }
}
