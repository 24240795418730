/**
 * Should be used in conjunction with CancellationController
 *
 * @see CancellationController
 */
export class CancellationSignal {
  public static readonly displayName = 'CancellationSignal';

  public get isCanceled(): boolean {
    return this._isCanceled;
  }

  private _isCanceled = false;

  public cancel!: () => void;

  public readonly promise = new Promise<void>(resolve => {
    this.cancel = () => {
      this._isCanceled = true;
      resolve();
    };
  });
}
