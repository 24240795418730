import { Color, Font, Justify } from 'common-styles';

export class MicInfoStyles {
  private static readonly styles = {
    VUMeterBar: {
      bar: {
        height: 30,
        marginHorizontal: 3,
        width: 15
      }
    },
    VUMeterRow: {
      justifyContent: Justify.SpaceBetween,
      width: 290
    },
    changeButton: {
      button: {
        height: 40,
        marginRight: 0,
        minWidth: 103
      },
      buttonText: {
        fontFamily: Font.Family.semiBold,
        fontSize: 16
      }
    },
    selectedMic: {
      color: Color.Gray70,
      fontSize: Font.Size.xSmall,
      marginBottom: 5
    }
  };

  public static get() {
    return this.styles;
  }
}
