import { TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { ITextOrdering } from '@lexialearning/lobo-common/tasks/text-ordering';
import {
  IOrderingAnswer,
  serializeOrderingAnswer
} from 'task-components/shared';
import { ITaskRegistration, TaskRegistrationBuilder } from '../core';
import { TextOrdering } from './TextOrdering';

export function createTextOrderingTaskRegistration(): ITaskRegistration<
  TaskTypeName.TextOrdering,
  ITextOrdering,
  IOrderingAnswer
> {
  return {
    ...TaskRegistrationBuilder.create<TaskTypeName.TextOrdering, ITextOrdering>(
      TaskTypeName.TextOrdering,
      TextOrdering
    ).registration,
    serializeAnswer: serializeOrderingAnswer
  };
}
