import { ActionType, createAction } from 'typesafe-actions';
import { PlacementSceneActionType } from './placement-scene-redux.model';

export const PlacementSceneAction = {
  prepare: createAction(PlacementSceneActionType.Prepare)()
};

export type PlacementScenePrepareAction = ActionType<
  typeof PlacementSceneAction.prepare
>;

export type PlacementSceneActions = ActionType<typeof PlacementSceneAction>;
