import { Row } from '../layout';
import { Text } from '../text';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalHeaderStyles } from './ModalHeader.styles';

export interface IModalHeaderProps {
  title: string;
  id: string;
  onClose?(): void;
}

export function ModalHeader({ id, title, onClose }: IModalHeaderProps) {
  const styles = ModalHeaderStyles.build({ hasCloseButton: !!onClose });

  return (
    <Row style={styles.modalHeader}>
      <Text selectable style={styles.modalTitle} id={id} testId="modalTitle">
        {title}
      </Text>
      {!!onClose && <ModalCloseButton onClose={onClose} />}
    </Row>
  );
}
ModalHeader.displayName = 'ModalHeader';
