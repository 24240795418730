import memoizeOne from 'memoize-one';
import { loboButtonStyle } from 'common-styles';
import { Overflow } from '@lexialearning/common-ui';

export class ButtonBaseStyles {
  public static readonly build = memoizeOne(style =>
    loboButtonStyle({
      margin: 0,
      opacity: 1,
      overflow: Overflow.Visible,
      ...style
    })
  );
}
