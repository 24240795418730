import { IModeling, ModelingActionType } from '@lexialearning/lobo-common';
import { IModelingActionPayload, ModelingActions } from './Modeling.action';

/**
 * Used within taskReducer
 */

export function modelingReducer(
  state: IModeling | undefined,
  action: ModelingActions
): IModeling | undefined {
  switch (action.type) {
    case ModelingActionType.Highlight:
      return state && setHighlight(state, action.payload, true);

    case ModelingActionType.Unhighlight:
      return state && setHighlight(state, action.payload, false);

    case ModelingActionType.SetModeling:
      return { ...action.payload };

    default:
      return state;
  }
}

function setHighlight(
  state: IModeling,
  payload: IModelingActionPayload,
  highlight: boolean
): IModeling {
  const section = state[payload.modelSection];
  section.items = section.items.map(item =>
    item.id === payload.modelItemId ? { ...item, highlight } : item
  );

  return {
    ...state,
    [payload.modelSection]: section
  };
}
