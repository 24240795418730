import { Mount } from '@lexialearning/storm-react';
import {
  ILevelVoiceoversState,
  LevelActionType,
  LevelVoiceoverMountStatus
} from './level-redux.model';
import { LevelActions } from './Level.action';

export const levelVoiceoversInitialState: ILevelVoiceoversState = {
  mount: undefined,
  status: LevelVoiceoverMountStatus.Unmounted
};

export function levelVoiceoversStateReducer(
  state: ILevelVoiceoversState,
  action: LevelActions
): ILevelVoiceoversState {
  switch (action.type) {
    case LevelActionType.MountVoiceovers:
      return {
        mount: undefined,
        status: LevelVoiceoverMountStatus.Mounting
      };

    case LevelActionType.MountVoiceoversSuccess:
      return {
        ...state,
        // make serializable for redux serializableCheck
        mount: { ...action.payload } as Mount,
        status: LevelVoiceoverMountStatus.Mounted
      };

    case LevelActionType.Unload:
      return {
        ...state,
        status: LevelVoiceoverMountStatus.Unmounting
      };

    default:
      return state;
  }
}
levelVoiceoversStateReducer.displayName = 'levelVoiceoversStateReducer';
