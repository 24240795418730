import {
  AvatarEditorAction,
  AvatarEditorActionTimerTimeout
} from '../AvatarEditor.action';
import { AppShellSelector } from 'feature-areas/shell';
import { AppState } from 'services';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import {
  Observable,
  filter,
  first,
  firstValueFrom,
  startWith,
  switchMap
} from 'rxjs';
import {
  ProgramContextActionKeepSessionAlive,
  ProgramContextActionType
} from 'curriculum-services';
import { StateObservable, ofType } from 'redux-observable';

/**
 * Keep alive is dispatched in the editor when the App's SessionTimeoutConfirmationModal
 * has come up and the user clicks 'Yes', to confirm they are still there. They should be
 * re-shown the Avatar TimeoutModal at this point to select from the options there
 */
export function handleKeepAliveInAvatarEditorEpic(
  action$: Observable<ProgramContextActionKeepSessionAlive>,
  state$: StateObservable<AppState>
): Observable<AvatarEditorActionTimerTimeout> {
  return action$.pipe(
    ofType(ProgramContextActionType.KeepSessionAlive),
    filter(() => AvatarEditorSelector.getShouldShowEditor(state$.value)),
    switchMap(async () => {
      const modalClosedPromise = firstValueFrom(
        state$.pipe(
          startWith(state$.value),
          first(s => !AppShellSelector.isModalOpen(s))
        )
      );

      // Wait for SessionTimeoutConfirmationModal to be closed before
      // opening the AvatarEditorTimeoutModal
      await modalClosedPromise;

      return AvatarEditorAction.timerTimeout();
    })
  );
}
handleKeepAliveInAvatarEditorEpic.displayName =
  'handleKeepAliveInAvatarEditorEpic';
