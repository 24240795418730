import {
  CardType,
  TaskEvaluationResult,
  TaskPhase,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { Store } from 'redux';
import { from } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';
import { Sfx, SfxAction } from 'audio/sfx';
import { AppShellSelector } from 'feature-areas/shell';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayAction } from 'screenplay';
import { AppShellAction } from 'shell/redux/AppShell.action';
import { SpinnerAction } from 'spinner-handler';
import { LanguageFrameSession, SreSessionAction, SreSessionType } from 'sre';
import { TaskAction, TaskSelector } from 'task-components';
import { IUserChoiceAnswer } from 'task-components/user-choice/user-choice-component.model';
import { DevToolsAction } from '../redux/DevTools.action';
import { DevToolsSelector } from '../redux/DevTools.selector';
import { CalibrationResult } from '@lexialearning/sre';

export class DevShortcuts {
  constructor(private readonly store: Store) {}

  public toggleDevTools(event: KeyboardEvent): void {
    if (!event.shiftKey) {
      return;
    }

    const isVisible = DevToolsSelector.getIsVisible(this.store.getState());
    const action = isVisible ? DevToolsAction.hide() : DevToolsAction.show();
    this.store.dispatch(action);
  }

  public toggleSpinner(): void {
    const isVisible = AppShellSelector.getShowSpinner(this.store.getState());
    const action = isVisible ? SpinnerAction.hide() : SpinnerAction.show();
    this.store.dispatch(action);
  }

  public goToSolution(): void {
    this.store.dispatch(TaskAction.solution());
  }

  /** If true, a simulation request is currently in-process */
  private hasActiveSimulationRequest = false;

  /**
   * Dispatch a correct/incorrect eval.
   * When not in interactive mode, wait to be in interactive mode.
   * Ignored if no task is active or already processing a request.
   * Known issue: User choice requires content in the attempt answer,
   * so will not work
   */
  public simulateAnswer(result: TaskEvaluationResult): void {
    if (
      // is not in a task or is already beyond interactive phase
      // (ie, cannot skip a task/round until already in it)
      this.isPhase(
        TaskPhase.None,
        TaskPhase.Feedback,
        TaskPhase.Solution,
        TaskPhase.Exit
      ) ||
      // or there is already an active simulation request
      this.hasActiveSimulationRequest
    ) {
      return;
    }

    this.hasActiveSimulationRequest = true;

    // Await and then skip preamble
    // (Need to subscribe in order to skip this, otherwise simulating before in
    //  preamble phase means it doesn't get skipped)
    if (!this.isPhase(TaskPhase.Interactive)) {
      from(this.store as any)
        .pipe(first(() => this.isPhase(TaskPhase.Preamble)))
        .subscribe(() => {
          this.skip();
        });
    }

    // Await interactive phase and then dispatch evaluated
    from(this.store as any)
      .pipe(first(() => this.isPhase(TaskPhase.Interactive)))
      .subscribe(() => {
        // Dispatch evaluated
        this.store.dispatch(
          TaskAction.evaluated({
            answer: this.buildAnswer(this.store.getState()),
            result
          })
        );

        // Reset hasActiveSimulationRequest
        this.hasActiveSimulationRequest = false;
      });
  }

  private isPhase(...phases: TaskPhase[]) {
    const currentPhase = TaskSelector.getPhase(this.store.getState());

    return phases.includes(currentPhase);
  }

  private buildAnswer(state: unknown): object {
    if (
      TaskSelector.getTaskContent(state).taskType === TaskTypeName.UserChoice
    ) {
      const answer: IUserChoiceAnswer = {
        selectedChoices: [
          {
            label: 'simulated answer',
            longText: '',
            sysId: 'NO ID',
            type: CardType.Other
          }
        ],
        selections: [0]
      };

      return answer;
    }

    return {};
  }

  public simulateMicError(
    calibrationCode: Exclude<CalibrationResult, CalibrationResult.Ok>
  ) {
    if (!TaskSelector.getTaskContentMaybe(this.store.getState())) {
      return;
    }

    const state = this.store.getState();

    from(this.store as any)
      .pipe(
        skipWhile(
          () =>
            TaskSelector.getPhase(state) !== TaskPhase.Interactive &&
            TaskSelector.getTaskContentMaybe(state)?.taskType !==
              TaskTypeName.SeeSpeak
        ),
        first()
      )
      .subscribe(() => {
        const result: LanguageFrameSession.IResult = {
          audioQuality: { calibrationResult: calibrationCode } as any
        } as any;
        this.store.dispatch(
          SreSessionAction.listenResult({
            result,
            sessionOptions: { sessionType: SreSessionType.LanguageFrame }
          })
        );
      });
  }

  public playSfx(sfx: Sfx) {
    this.store.dispatch(SfxAction.play({ path: sfx }));
  }

  public navigateBack() {
    this.store.dispatch(AppShellAction.navigateBack());
  }

  public skip(ctrlKey?: boolean): void {
    if (RouterService.activeRoute === RouteBuilder.calibration()) {
      if (ctrlKey) {
        this.store.dispatch(DevToolsAction.skipToLevelPage());
      }

      // TODO skip to home if its only altKey that's pressed

      return;
    }

    this.store.dispatch(ScreenplayAction.skip());
  }

  public logSreSessionInfo() {
    this.store.dispatch(DevToolsAction.logSreSessionInfo());
  }

  public playLevelCertificatesBadgeAnimation() {
    this.store.dispatch(DevToolsAction.playLevelCompleteLevelIntro());
  }
}
