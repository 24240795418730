import { ActionType, createAction } from 'typesafe-actions';
import { InteractionState } from 'common-styles';
import { MicActionType } from './mic.model';

export const MicAction = {
  setForceDisabled: createAction(MicActionType.SetForceDisabled)<boolean>(),
  setInteractionState: createAction(
    MicActionType.SetInteractionState
  )<InteractionState>()
};

export type MicActions = ActionType<typeof MicAction>;

export type MicActionSetInteractionState = ActionType<
  typeof MicAction.setInteractionState
>;
export type MicActionSetForceDisabled = ActionType<
  typeof MicAction.setForceDisabled
>;
