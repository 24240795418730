import {
  ActiveComponentState,
  useActiveComponentState
} from '@lexialearning/common-ui';
import {
  Avatar,
  AvatarType,
  KeyNavHelper,
  PressScaleButton,
  Row,
  Text,
  View
} from 'common-ui';
import { AppShellSelector, UserInfoButtonType } from '../../redux';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';
import { LevelCertificateBadge } from '../level-certificate-badge';
import { LevelCertificateEncouragement } from '../level-certificate-encouragement';
import { LevelsCompletedSelector, LevelSelector } from 'curriculum-services';
import { PlacementIconSvg } from './PlacementIcon.lx-svg';
import { PokIconSvg } from './PokIcon.lx-svg';
import { ProfileSelector } from 'services/profile';
import { UserInfoButtonStyles } from './UserInfoButton.styles';
import { UtilityButtonHelper } from 'common-ui/components/button/utility-button/UtilityButtonHelper';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export interface IUserInfoButtonProps extends IButtonBaseProps {
  isHighlighted?: boolean;
}

function renderIcon(
  buttonType: UserInfoButtonType,
  activeComponentState: ActiveComponentState,
  userDisplayName: string
) {
  const colorSetName =
    UtilityButtonHelper.getColorSetName(activeComponentState);
  const colorSet = UtilityButtonHelper.getColorSet(colorSetName)!;
  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
  const iconProps = { colorSet, isKeyNav };

  if (buttonType === UserInfoButtonType.Placement) {
    return <PlacementIconSvg {...iconProps} />;
  }

  if (buttonType === UserInfoButtonType.PresentationOfKnowledge) {
    return <PokIconSvg {...iconProps} />;
  }

  return (
    <Avatar
      type={AvatarType.Icon}
      accessibilityLabel={`${userDisplayName} user information.`}
    />
  );
}

export function UserInfoButton(props: IUserInfoButtonProps) {
  const buttonType = useSelector(AppShellSelector.getUserInfoButtonType);
  const hasUnseenLevelCertificates = useSelector(
    LevelsCompletedSelector.getHasUnseenLevelCertificates
  );
  const levelNumber = useSelector(LevelSelector.getLevelNumberMaybe);
  const userDisplayName = useSelector(ProfileSelector.getDisplayName);

  const { isHighlighted = false, onPress, testId, ...restProps } = props;

  const { activeComponentState, interactionHandlers, setActiveComponentState } =
    useActiveComponentState(props);

  const [hideLevelCertBadge, setHideLevelCertBadge] = useState(false);
  useEffect(() => {
    setHideLevelCertBadge(false);
  }, [levelNumber]);

  const handlePress = useCallback(() => {
    setHideLevelCertBadge(true);
    setActiveComponentState(ActiveComponentState.Default);
    onPress?.();
  }, [onPress, setActiveComponentState]);

  const styles = UserInfoButtonStyles.build(
    buttonType,
    isHighlighted,
    activeComponentState
  );
  const isNameVisible = buttonType !== UserInfoButtonType.Minimal;

  return (
    <Row
      testId={testId || UserInfoButton.displayName}
      style={styles.buttonContainer}
    >
      {hasUnseenLevelCertificates && <LevelCertificateEncouragement />}
      <PressScaleButton
        {...restProps}
        {...interactionHandlers}
        {...UtilityButtonHelper.getSharedProps()}
        onPress={handlePress}
      >
        {/*
        iosShadow is also present for web, but specifically added to mimic
        the UtilityButtonSvg shadow, which is present bc the regular drop shadow
        does not appear for iOS
        */}
        <View style={styles.iosShadow} />
        <View style={styles.container}>
          <Row style={styles.innerContainer}>
            {isNameVisible && (
              <View style={styles.labelContainer}>
                <Text style={styles.usernameLabel}>{userDisplayName}</Text>
              </View>
            )}
            <View style={styles.iconContainer}>
              {renderIcon(buttonType, activeComponentState, userDisplayName)}
            </View>
          </Row>
        </View>
        {hasUnseenLevelCertificates && !hideLevelCertBadge && (
          <LevelCertificateBadge />
        )}
      </PressScaleButton>
    </Row>
  );
}
UserInfoButton.displayName = 'UserInfoButton';
