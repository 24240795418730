import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements, switchMap } from 'rxjs/operators';
import {
  ProfileActionLoadSuccess,
  ProfileActionType,
  ProfileSelector
} from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { CalibrationScene } from '../CalibrationScene';
import { CalibrationSceneDefinitionBuilder } from '../CalibrationSceneDefinitionBuilder';
import { AppState } from 'services';

export interface IPrepareCalibrationSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareCalibrationSceneEpic(
  action$: Observable<ProfileActionLoadSuccess>,
  state$: StateObservable<AppState>,
  deps: IPrepareCalibrationSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(ProfileActionType.LoadSuccess),
    filter(() => ProfileSelector.isStudent(state$.value)),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      preparedScenes.calibration = undefined;

      const { definition } =
        CalibrationSceneDefinitionBuilder.create().withGabber();

      await stormService.initialization;
      const scene = await stormService.prepareScene(definition);
      const calibrationScene = new CalibrationScene(scene);
      preparedScenes.calibration = calibrationScene;
    }),
    ignoreElements()
  );
}
prepareCalibrationSceneEpic.displayName = 'prepareCalibrationSceneEpic';
