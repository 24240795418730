import { Text, View } from 'common-ui';
import { ToolTipDialogStyles } from './ToolTipDialog.styles';
import { useSelector } from 'react-redux';
import { AvatarEditorSelector } from 'feature-areas/avatar-editor/redux';

export function ToolTipDialog() {
  const styles = ToolTipDialogStyles.get();

  const encountersUntilEditable = useSelector(
    AvatarEditorSelector.getEncountersUntilEditable
  );

  return (
    <View testId={ToolTipDialog.displayName} style={styles.toolTipBox}>
      <View style={styles.toolTipArrowBorder} />
      <View style={styles.toolTipArrow} />
      <Text id={ToolTipDialog.displayName} style={styles.toolTipText}>
        {`Editing will be available after completing ${encountersUntilEditable} more encounter${
          encountersUntilEditable > 1 ? 's' : ''
        }.`}
      </Text>
    </View>
  );
}

ToolTipDialog.displayName = 'ToolTipDialog';
