import { Color } from 'common-styles';
import { G, Path, Svg } from 'common-ui';

export function SpeechBubbleTailSvg(props: {
  borderFill?: string;
  backgroundFill?: string;
  style?: any;
}) {
  const width = 45;
  const height = 53.27645;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      overflow="visible"
      style={props.style}
      data-test-id={SpeechBubbleTailSvg.displayName}
    >
      <G transform="translate(51.477 -34.535) rotate(90)">
        <Path
          d="M-477.465,1872.935c8.842-19.469,12.141-36.409,12.278-45h41c-3.374,4.243-33.539,41.54-53.274,45Z"
          transform="translate(512 -1821)"
          fill={props.borderFill || Color.Black}
        />
        <Path
          d="M490.44,359h0c20.58,9.93,37,11.91,44.92,12.33v32.72a188.65,188.65,0,0,1-21.4-17,132.56,132.56,0,0,1-23.52-28Z"
          transform="translate(-327, 536) rotate(-89.5)"
          fill={props.backgroundFill || Color.NearWhite}
        />
      </G>
    </Svg>
  );
}
SpeechBubbleTailSvg.displayName = 'SpeechBubbleTailSvg';
