import { ITaskAttempt } from '@lexialearning/lobo-common';
import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { ICalibrationEventPayload, LoboLogItemCategory } from 'logging';
import {
  ActivityPositionBuilder,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import {
  SreCalibrationActionComplete,
  SreCalibrationActionType,
  SreSelector
} from 'sre/redux';
import { ISreSessionAnswer } from 'task-components/see-speak';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { RouterService, RoutePath } from 'router-service';
import { AppState } from 'services';
import { DeviceInfoReporter } from '@lexialearning/utils-react';

export function logCalibrationEpic(
  action$: Observable<SreCalibrationActionComplete>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SreCalibrationActionType.Complete),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.Calibration),
    ignoreElements()
  );
}
logCalibrationEpic.displayName = 'logCalibrationEpic';

async function createEvent(
  action: SreCalibrationActionComplete,
  state: AppState,
  helper: AppLoggerDependencies
): Promise<ILogItemMinimal<ICalibrationEventPayload, LoboLogItemCategory>> {
  const roundContext = ProgramContextSelector.getRoundContextMaybe(state);
  const category = RouterService.isOnRoute(RoutePath.Calibration)
    ? LoboLogItemCategory.Calibration
    : LoboLogItemCategory.Recalibration;
  const deviceInfo = await DeviceInfoReporter.create();
  const { result, soundLogId } = action.payload;
  const payload: ICalibrationEventPayload = {
    ...SreSelector.getConfig(state),
    context: helper.getContext(state),
    deviceInfo,
    result,
    soundLogId
  };

  maybeSetActivityPosition(roundContext, payload);

  return {
    category,
    loggingLevel: LoggingLevel.Info,
    payload,
    summary: buildSummary(payload, roundContext)
  };
}

function maybeSetActivityPosition(
  context: RoundContext | undefined,
  payload: ICalibrationEventPayload
): void {
  if (!context) {
    return;
  }

  const builder = ActivityPositionBuilder.create(context.activityPosition);
  const { attempts } = builder.activeUnitPosition;

  payload.activityPosition = builder.updateActiveUnitPosition({
    attempts: attempts.map(transformAnswer as any)
  }).activityPosition;
}

function transformAnswer(
  attempt: ITaskAttempt<ISreSessionAnswer>
): ITaskAttempt {
  const { audioQuality, stopReason } = attempt.answer.result ?? {};

  return {
    ...attempt,
    answer: {
      audioQuality,
      calibrationResult: attempt.answer.calibrationResult,
      stopReason
    }
  };
}

function buildSummary(
  { context, result, mic }: ICalibrationEventPayload,
  roundContext: RoundContext | undefined
): string {
  const attemptCount = roundContext?.attempts.length;

  return roundContext
    ? `Recalibration ${result} from ${context} (${attemptCount} attempts) on mic "${mic?.name}"`
    : `Calibration ${result} on mic "${mic?.name}"`;
}
