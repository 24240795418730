import { ActionType, createAction } from 'typesafe-actions';
import { IMeterState } from '../../progress-meters.model';
import { UnitMeterActionType } from './UnitMeterActionType';

export const UnitMeterAction = {
  updated: createAction(UnitMeterActionType.Updated)<IMeterState>()
};

export type UnitMeterActions = ActionType<typeof UnitMeterAction>;
export type UnitMeterUpdatedAction = ActionType<typeof UnitMeterAction.updated>;
