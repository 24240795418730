import { IStudentApiConfig as IStudentApiConfigUni } from '@lexialearning/student-api';
import { IAvatar } from 'services/profile/avatar.model';

/** Student API StudentProperty keys used by Lobo */
export enum StudentProperty {
  Avatar = 'avatar',
  LevelCertificatesViewed = 'level-certificates-viewed',
  HasViewedOnboardingVideo = 'has-viewed-onboarding-video'
}

export enum StudentApiConfigValues {
  FetchRetryThrottleSeconds = 'studentApi.fetchRetryThrottleSeconds'
}

export interface IStudentApiConfigLobo extends IStudentApiConfigUni {
  /**
   * When set, progress is not submitted to the API (only keep-alive requests).
   * This is only meant for non-production use.
   */
  disableWrites?: boolean;

  /**
   * Seconds to wait before honoring an (unforced) keep alive request
   * @see StudentProgressApi.keepAlive()
   */
  keepAliveSeconds: number;
}

export interface IAvatarStudentProperties {
  selection: IAvatar;
  encountersUntilEditable: number;
}

export const STUDENT_API_CONFIG_KEY = 'studentApi';
export const STUDENT_API_URL_CONFIG_STRING = `${STUDENT_API_CONFIG_KEY}.apiDomain`;
