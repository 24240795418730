import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Color, Direction, Font, TextAlign } from 'common-styles';
import { Types } from '../../types';
import { ROW_HEIGHT } from './Dropdown.styles';
import { UserInterface } from '@lexialearning/reactxp';

export interface IDropdownItemStyleOverride {
  row?: Types.ViewStyle;
  item?: Types.TextStyle;
}
export class DropdownItemStyles {
  public static readonly build = memoizeOne(
    (
      isHighlighted: boolean,
      isHovered: boolean,
      styleOverride?: IDropdownItemStyleOverride
    ) => {
      const isKeyNav = UserInterface.isNavigatingWithKeyboard();

      return merge(
        {
          item: {
            color: isKeyNav && isHighlighted ? Color.White : Color.Black,
            flex: 1,
            fontSize: Font.Size.small,
            letterSpacing: 0,
            lineHeight: 22,
            paddingHorizontal: 8,
            textAlign: TextAlign.Center
          },
          row: {
            height: ROW_HEIGHT,
            backgroundColor:
              isKeyNav && isHighlighted
                ? Color.Black
                : isHighlighted || isHovered
                ? Color.GoldTransparent30
                : Color.NearWhite,
            flexDirection: Direction.Row
          }
        },
        styleOverride
      );
    }
  );
}
