import { UnitType } from '@lexialearning/lobo-common/main-model';
import { RoundContext } from 'curriculum-services';
import { PlacementPostFeedbackPhaseDeterminer } from './PlacementPostFeedbackPhaseDeterminer';
import { PoKPostFeedbackPhaseDeterminer } from './PoKPostFeedbackPhaseDeterminer';
import { PostFeedbackPhaseDeterminer } from './PostFeedbackPhaseDeterminer';

export class PostFeedbackPhaseDeterminerFactory {
  public static readonly displayName = 'PostFeedbackPhaseDeterminerFactory';

  public static create(context: RoundContext): PostFeedbackPhaseDeterminer {
    const unitType = context.mainUnit.type;

    switch (unitType) {
      case UnitType.Placement:
        return new PlacementPostFeedbackPhaseDeterminer(context);

      case UnitType.PresentationOfKnowledge:
        return new PoKPostFeedbackPhaseDeterminer(context);

      default:
        return new PostFeedbackPhaseDeterminer(context);
    }
  }
}
