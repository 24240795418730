import { UnitType } from '@lexialearning/lobo-common/main-model';
import { merge } from 'lodash';
import { Color, Direction, Justify } from 'common-styles';
import { ThemeType } from 'theme';
import { Position } from '@lexialearning/common-ui';

export class ImageTaskStyles {
  public static MediaStyle = {
    buttonContainer: {
      backgroundColor: Color.BlackTransparent40,
      borderBottomLeftRadius: 100,
      borderTopLeftRadius: 100,
      height: 84,
      justifyContent: Justify.Start,
      padding: 11,
      position: Position.Absolute,
      right: 17,
      width: 97
    },
    container: {
      backgroundColor: Color.Black,
      borderRadius: 30,
      flexDirection: Direction.Row,
      height: 624,
      width: 1024
    },
    imageContainer: {
      borderRadius: 25,
      height: 590,
      width: 990
    },
    taskContainer: {
      flex: 1,
      justifyContent: Justify.Start,
      marginTop: 10
    }
  };

  public static FullStyle = {
    buttonContainer: {
      bottom: 26,
      position: Position.Absolute
    },
    container: {
      backgroundColor: Color.NearWhite,
      borderRadius: 30,
      height: 552,
      marginTop: 7,
      width: 906
    },
    imageContainer: {
      backgroundColor: Color.NearWhite,
      borderRadius: 25,
      height: 522,
      width: 876
    },
    taskContainer: {
      flex: 1,
      justifyContent: Justify.Start
    }
  };

  public static HalfStyle = merge({}, ImageTaskStyles.FullStyle, {
    buttonContainer: {
      bottom: 42
    },
    container: {
      height: 522,
      width: 592
    },
    imageContainer: {
      height: 488,
      width: 558
    },
    taskContainer: {
      marginRight: 55,
      marginTop: 20
    }
  });

  public static InstructionStyle = merge({}, ImageTaskStyles.FullStyle, {
    container: {
      backgroundColor: Color.Transparent,
      height: 510,
      width: 875
    },
    imageContainer: {
      borderRadius: 25,
      height: 472,
      width: 792
    }
  });

  // Intentionally not memoized as this contains minimal logic and
  // is not called with great frequency
  public static build(
    unitType: UnitType,
    hasOnscreenCharacter: boolean,
    themeType: ThemeType
  ) {
    return unitType === UnitType.Media
      ? ImageTaskStyles.MediaStyle
      : themeType === ThemeType.Instruction
      ? ImageTaskStyles.InstructionStyle
      : hasOnscreenCharacter
      ? ImageTaskStyles.HalfStyle
      : ImageTaskStyles.FullStyle;
  }
}
