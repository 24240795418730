import { Svg, Path, G } from '@lexialearning/common-ui';
import {
  HairColorHexMap,
  IHairSvgProps,
  defaultHairColor
} from '../hair.model';
import { HairColor } from 'services/profile/avatar.model';

export function VeryShortHairSvg({
  colorName: hairColor
}: IHairSvgProps): React.ReactElement {
  const { hair, hairLines } =
    HairColorHexMap.get(hairColor as HairColor) ?? defaultHairColor;

  return (
    <Svg
      data-test-id={VeryShortHairSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 471.93 721.39"
    >
      <G>
        <Path
          fill="#B1A8A6"
          strokeWidth="0"
          d="M426.97,371.4c-2.13,2.35-4.22,4.64-6.24,6.93-2.84,3.17-6.41,4.64-11.25,4.64-4.25,0-8.83-1.11-13.27-2.19l-2.65-.65-.98.43c-5.1,2.19-6.9,7.39-8.5,12-.72,2.09-1.41,4.09-2.32,5.56l-.13.23-.1.26c-6.05,14.58-14.12,31.03-28.35,42.31-18.01,15.92-38.71,27.72-58.95,38.94-1.21.69-2.42,1.37-3.63,2.03l-.36.2-.29.29c-7.32,6.8-7.94,16.8-8.53,25.63-.2,3.07-.39,5.98-.82,8.66l-.03.26v.26c0,3.11-.16,6.31-.33,9.71-.23,4.9-.46,9.91-.29,14.84.29,6.8,1.41,13.4,4.51,19.52l.13.26.2.26c8.24,10.79,21.32,17.72,33.97,24.39,16.58,8.76,32.2,17.07,35.21,32.86.56,2.98-4.54,6.77-9.02,10.14-2.78,2.06-5.66,4.18-7.72,6.47-10.46,9.02-20.04,19.16-29.33,28.97-14.12,14.94-28.74,30.37-46.23,41.49-14.42,7.94-28.25,12.03-40.97,12.03-28.15,0-49.6-20.5-60.35-57.74-7.91-27.33,1.11-33.35,16.05-43.29,5.56-3.69,11.87-7.91,18.11-13.76,7.13-5.92,8.66-23.54,8.53-43.03-.07-6.38-.29-12.91-.56-19.36l-.16-4.41c-.23-6.18-.72-20.86-11.74-26.29-2.22-1.08-4.87-1.8-8.04-1.99-37.73-6.57-77.42-21.87-107.89-47.64-21.55-18.18-36.88-42.5-48.36-65.65v-.1l-.16-.33c-2.91-5.88-5.39-11.84-7.45-17.62-1.8-5.1-3.2-10.69-4.18-16.58C-6.59,235.42,4.46,146.2,48.27,87.61,82.76,41.51,139.06,13.49,215.57,4.34c6.93-.69,14.09-1.08,21.19-1.08,46.56,0,92.79,15.43,130.16,43.42,38.58,28.87,64.9,69.48,74.18,114.37,6.87,25.99,8.44,55.02,4.71,86.44-.13,4.25,1.83,7.94,5.46,10.69,8.08,6.15,13.27,13.83,15.4,22.79,9.38,36.42-16.67,65.13-39.69,90.43Z"
        />
        <Path
          fill="#928A88"
          strokeWidth="0"
          d="M294.23,479.85c-1.21.69-2.42,1.37-3.63,2.03l-.36.2-.29.29c-7.32,6.8-7.85,6.69-8.44,15.52-.2,3.07-.49,16.09-.91,18.77l.06-9.85-.09,10.37c0,3.11-.07-3.8-.23-.4-.23,4.9-.46,9.91-.29,14.84-19.62,11.54-37.63,26.3-64.89,29.11-4,.41-8.11.03-12.1-.43-.07-6.38-.29-12.91-.56-19.36l-.16-4.41c-.23-6.18-.72-20.86-11.74-26.29,6.28-2.48,13.05-3.6,19.68-4.87,23.18-4.48,45.94-11.18,67.84-19.98,5.26-2.13,10.59-4.38,16.12-5.56Z"
        />
        <Path
          fill="#736B69"
          strokeWidth="0"
          d="M215.19,1.09C3.36,26.46-19.89,181.97,11.21,360.69c.97,5.55,6.27,25.09,8.24,30.36,25.38,67.85,92.46,108.32,162.69,120.43,14.31.8,16.53,13.69,16.92,25.18.5,14.65,3.26,55.96-6.8,64.3-22.7,21.37-46.75,19.97-35.07,60.35,16.35,56.52,56.41,75.04,106.23,47.53,29.38-18.64,49.68-48.12,76.09-70.85,5.59-6.31,19.08-12.09,17.66-19.59-5.68-29.99-51.29-34.38-69.8-58.6-6.44-12.69-3.58-28.76-3.54-42.61,1.72-10.78-.28-24.37,8.35-32.43,21.93-12.16,44-24.44,63.03-41.28,14.11-11.2,21.22-27.87,29.32-43.61,2.22-4.32,3.16-10.72,6.53-14.3,1.29-1.37,3.26-1.88,5.08-1.43,9.27,2.25,19.73,4.56,27-3.6,25.01-27.95,57.38-58.85,46.71-100.33-2.39-10.04-8.28-18.28-16.59-24.59-2.47-1.92-4.27-4.58-4.21-7.74,3.45-29.19,2.82-58.86-4.73-87.5C422.87,56.76,318.03-9.41,215.19,1.09ZM295.62,577.25c17.35,12.54,43.36,16.18,53.08,35.82,1.75,3.53.77,8.79-2,11.6-16.81,17.06-31.52,27.34-46.79,45.57-31.97,39.59-101.98,75.26-127.95,12.8-27.41-65.9,1.43-44.74,27.38-82.58,3.02-4.4,5.67-12.44,6.08-17.76,1.52-19.71.29-39.69-1.25-59.36-.41-5.33-4.78-12.11,3.81-12.42,24.7-2.07,47.73-12.49,71.22-19.64,2.57,1.81.86,6.37.91,9.15-.19,25.12-9.44,60.94,15.5,76.81ZM467.41,303.07c-1.36,21.61-15.94,38.17-29.57,53.36-10.04,14.52-25.47,32.21-44.33,20.92-6.35-2.41-7.37,7.2-9.33,11.27-11.12,32-32.31,58.46-62.97,73.45-39.9,22.22-82.99,42.29-129.44,43.75-62.42-1.58-132.18-43.62-161.65-105.06-11.43-23.83-18.54-67.47-21.31-110.23-3.07-47.39.92-95.18,8.31-125.85C31.26,96.81,80,46.28,143.12,21.95,226.67-7.14,333.11,2.1,393.25,73.01c25.17,26.18,41.18,59.33,48.82,94.8,4.95,25.33,8.53,52.23,2.7,77.64-.86,2.91-3.18,3.86-5.94,3.83-20.05.48-35.34,18.33-42.76,35.32-1.27,2.22-4.66,11.75.31,5.48,9.76-18.07,31.31-45.65,53.82-30.53,14.52,9.94,19.55,26.74,17.2,43.52Z"
        />
      </G>
      <G>
        <Path
          fill={hair}
          strokeWidth="0"
          d="M379.8,246.05c-.54-4.55-.98-8.8-1.4-12.8-.87-8.25-1.62-15.38-2.88-22.47-.94-5.28-1.82-10.58-2.69-15.88-2.98-18.05-6.07-36.72-11.78-54.27-2.89-8.9-18.25-15.14-26.86-17.42-33.38-8.9-99.62-21.53-142.37-21.73h-4.27c-49.06,0-106.51,3.6-160.58,30.35-.17.07-.49.2-.93.3-1.66.45-4.28.86-6.2,1.09C49.13,57.59,113.09,14.96,215.37,2.73c7.03-.72,14.23-1.09,21.37-1.09,46.93,0,93.51,15.53,131.16,43.71,38.88,29.11,65.44,70.08,74.8,115.36,6.65,25.25,8.35,53.38,5.07,83.72-.1,1.12-.23,2.18-.35,3.24-.02.91.07,1.78.26,2.61-3.14-.59-6.2-.89-9.13-.89-18.42,0-32.58,11.53-42.09,34.28-1.07,2.53-2.18,5.07-3.32,7.61l-3.05,6.91-1.82.06-8.47-52.2Z"
        />
        <Path
          fill={hairLines}
          strokeWidth="0"
          d="M236.75,0v4.9c46.22,0,92.11,15.29,129.19,43.06,38.23,28.63,64.36,68.91,73.55,113.41l.03.13.03.13c6.53,24.77,8.2,52.5,4.97,82.44l-.03.3v.04c-.06.68-.14,1.36-.22,2.05-1.95-.22-3.85-.34-5.72-.34-19.86,0-35.04,12.21-45.1,36.27-1.04,2.46-2.12,4.93-3.24,7.43-.94-5.82-1.88-11.59-2.79-17.21-1.56-9.6-3.05-18.77-4.37-26.98-.54-4.58-.98-8.79-1.4-12.76-.87-8.3-1.63-15.48-2.91-22.67-.94-5.27-1.82-10.57-2.69-15.87-3-18.16-6.1-36.94-11.89-54.71-4.09-12.61-26.59-18.9-29.13-19.57-33.55-8.95-100.16-21.64-143.2-21.84-1.43,0-2.88,0-4.31,0-49.39,0-107.25,3.64-161.9,30.64-.1.04-.22.08-.34.11l-.08.02-.08.02s-.02,0-.04,0C54.7,58.04,117.17,17.77,215.67,5.98c6.94-.71,14.03-1.07,21.06-1.07l.02-4.9M236.74,0c-7.17,0-14.38.36-21.56,1.1C103.13,14.51,43.85,64.37,17.5,134.79c-.07.1-.1.16-.13.26.03,0,.09,0,.16,0,1.05,0,6.18-.62,8.86-1.35.56-.13.98-.29,1.28-.43,50.65-25.05,104.49-30.19,159.84-30.19,1.42,0,2.86,0,4.29,0,41.91.2,107.27,12.42,141.96,21.68,9.87,2.62,23.28,8.79,25.73,16.35,7.32,22.49,10.23,46.46,14.42,69.93,1.86,10.46,2.62,21.15,4.28,35.24,2.52,15.6,5.62,34.62,8.7,53.62,1.44-.03,2.84-.1,4.28-.13,1.14-2.62,2.32-5.23,3.47-7.85,1.14-2.55,2.26-5.1,3.33-7.65,7.14-17.06,19.29-33.27,40.58-33.27,3.67,0,7.62.48,11.86,1.53-.88-1.37-1.41-2.94-1.37-4.68.13-1.08.26-2.16.36-3.24v-.03c3.04-28.15,2.16-56.69-5.1-84.22C424.38,63.98,332.28,0,236.74,0h0Z"
        />
      </G>
    </Svg>
  );
}
VeryShortHairSvg.displayName = 'VeryShortHairSvg';
