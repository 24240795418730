import { IScreenplay } from '@lexialearning/lobo-common';
import { find, map, switchMap } from 'rxjs/operators';
import {
  ActSelector,
  ActivityPositionBuilder,
  LevelSelector,
  PositionAction,
  ProgramContextSelector,
  ProgramPositionFactory
} from 'curriculum-services';
import { EducatorSelector } from 'feature-areas/educator';
import {
  LevelScene,
  LevelSceneAnimationName,
  LevelScenePrepareAction
} from 'feature-areas/levels';
import { ActIntroScreenplayBuilder } from 'feature-areas/transitions/builders/acts';
import { ScreenplayAction, ScreenplayBuilder } from 'screenplay';
import { SceneName } from 'services/storm-lobo/StormAssets';
import {
  DeepLinkHelperOutputAction,
  DeepLinkObservableFactoryBaseWithLevelsLoading,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';

export interface IActDeepLinkDeps extends IDeepLinkDeps {
  actId: string;
}

export type ActDeepLinkOutputAction =
  | DeepLinkHelperOutputAction
  | LevelScenePrepareAction;

export class ActDeepLinkObservableFactory extends DeepLinkObservableFactoryBaseWithLevelsLoading<IActDeepLinkDeps> {
  public static readonly displayName = 'ActDeepLinkObservableFactory';

  private parentLevelId = '';

  private actIndex = -1;

  public static createFor(
    deps: IActDeepLinkDeps
  ): ActDeepLinkObservableFactory {
    return (
      new ActDeepLinkObservableFactory(deps)
        .awaitLevelsThenLoadParentLevel()
        // Can be commented out when not desired, but sets initial deep link
        // position to second encounter in order to see Fun Facts (not available for
        // first encounters)
        .thenSetPositionToSecondEncounters()
        .thenSelectAct()
        .thenPlayActIntroScreenplay()
    );
  }

  constructor(public readonly deps: IActDeepLinkDeps) {
    super(deps);
    this.getParentLevelId = this.getParentLevelId.bind(this);
  }

  private awaitLevelsThenLoadParentLevel(): ActDeepLinkObservableFactory {
    return this.awaitLevelsThenLoadLevel(this.getParentLevelId);
  }

  private getParentLevelId(state: unknown) {
    const levels = EducatorSelector.getLevels(state);
    const level = levels.find(l =>
      l.acts.map(a => a.sysId).some(a => a === this.deps.actId)
    );
    this.parentLevelId = level?.sysId || '';
    this.actIndex =
      level?.acts.findIndex(a => a.sysId === this.deps.actId) || 0;

    return this.parentLevelId;
  }

  private thenSetPositionToSecondEncounters(): ActDeepLinkObservableFactory {
    const { state$, actId } = this.deps;
    const loadPosition$ = state$.pipe(
      // Await initial position setup, so as to avoid race conditions
      find(() => this.isLevelReady() && this.areActivityPositionsReady()),
      // Then update to desired position
      map(() => {
        const levels = EducatorSelector.getLevels(state$.value);
        const level = levels.find(l => l.sysId === this.parentLevelId)!;

        const levelPosition = {
          activeActivityId: actId,
          activityPositions: ProgramPositionFactory.toSnippedLevel(
            level
          ).activities.map(a => {
            const encounter = a.encounters[1];

            return ActivityPositionBuilder.create({
              activityId: a.sysId,
              encounterId: encounter?.sysId ?? ''
            }).withUnitPosition({
              roundId: encounter?.units[0]?.rounds[0]?.sysId ?? '',
              unitId: encounter?.units[0]?.sysId ?? ''
            }).raw;
          }),
          levelId: level.sysId
        };

        return PositionAction.levelStartPosition(levelPosition);
      })
    );
    this.deferredDispatches$.push(loadPosition$);

    return this;
  }

  private thenSelectAct(): ActDeepLinkObservableFactory {
    const { state$, actId } = this.deps;
    const loadAct$ = state$.pipe(
      find(() => this.isLevelReady() && this.areActivityPositionsReady()),
      map(() => PositionAction.activitySelected({ activityId: actId }))
    );
    this.deferredDispatches$.push(loadAct$);

    return this;
  }

  /**
   * Monitor state changes until act is loaded.
   * Then (maybe) route to act, and play the act intro screenplay.
   */
  private thenPlayActIntroScreenplay(): ActDeepLinkObservableFactory {
    const { state$, preparedScenes } = this.deps;
    const playActIntroScreenplay$ = state$.pipe(
      find(() => this.isLevelReady() && this.isActReady()),
      switchMap(async () => preparedScenes.levelReady),
      map(levelScene => this.buildActIntroScreenplay(levelScene)),
      map(screenplay => ScreenplayAction.play({ screenplay }))
    );
    this.deferredDispatches$.push(playActIntroScreenplay$);

    return this;
  }

  private buildActIntroScreenplay(levelScene: LevelScene): IScreenplay {
    const { state$ } = this.deps;
    const context = ProgramContextSelector.getEncounterContext(state$.value);
    const { act, activityPositionMap, encounter, level } = context;
    levelScene.show();
    levelScene.prepareShowcase(act);

    const incompleteActs = level.acts.filter(
      a => !activityPositionMap.get(a.sysId)?.isComplete
    );
    const selectedActNumber =
      incompleteActs.findIndex(a => a.sysId === act.sysId) + 1;
    const currentEncounter = context.findEncounterIndex() + 1;

    return ScreenplayBuilder.create('Act_DeepLink_Screenplay')
      .addStormAnimation({
        name: LevelSceneAnimationName.Root.buildCharacterSelection(
          this.actIndex + 1
        ),
        targetScene: SceneName.Level
      })
      .addScreenplay(
        ActIntroScreenplayBuilder.createFor({
          act,
          actsEntered: [],
          currentEncounter,
          encounterId: encounter.sysId,
          encounterPercentCompleted: 0,
          hasFunFact: currentEncounter !== 1,
          selectedActNumber
        }).screenplay
      ).screenplay;
  }

  private isLevelReady(): boolean {
    const { state$ } = this.deps;

    return (
      !!this.parentLevelId &&
      this.parentLevelId === LevelSelector.getLevelMaybe(state$.value)?.sysId
    );
  }

  private areActivityPositionsReady(): boolean {
    const { state$ } = this.deps;

    return !!ProgramContextSelector.getActivityPositions(state$.value).length;
  }

  private isActReady(): boolean {
    const { state$ } = this.deps;
    const act = ActSelector.getActMaybe(state$.value);

    return act?.sysId === this.deps.actId;
  }
}
