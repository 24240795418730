import * as React from 'react';
import { connect } from 'react-redux';
import { AnimatedView } from 'common-ui';
import { Services } from 'services';
import { ProgramContextAction } from 'services/curriculum-services';
import { ActReactAnimationName } from './act-page.model';
import { ActPageAnimatedStyles } from './ActPage.animated-styles';
import { ActFooter } from './footer/ActFooter';
import { FunFact } from './fun-fact/FunFact';

export interface IActPageProps {
  keepSessionAlive(): void;
}

export class ActPageComponent extends React.PureComponent<IActPageProps> {
  public static readonly displayName = 'ActPage';

  private readonly animatedStyles: ActPageAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: IActPageProps) {
    super(props);

    this.animatedStyles = new ActPageAnimatedStyles();
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentDidMount() {
    this.props.keepSessionAlive();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { fadeIn, fadeOut } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      { name: ActReactAnimationName.FadeIn, animation: fadeIn },
      { name: ActReactAnimationName.FadeOut, animation: fadeOut }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const styles = this.animatedStyles.get();

    return (
      <AnimatedView animatedStyle={styles.container}>
        <FunFact />
        <ActFooter />
      </AnimatedView>
    );
  }
}

const mapDispatchToProps = {
  keepSessionAlive: () => ProgramContextAction.keepSessionAlive()
};

export const ActPage = connect(undefined, mapDispatchToProps)(ActPageComponent);

export const ActPagePrivates = { mapDispatchToProps };
