import { IPlacement } from '@lexialearning/lobo-common';

export enum PlacementActionType {
  Load = 'PLACEMENT.LOAD',
  LoadSuccess = 'PLACEMENT.LOAD_SUCCESS',
  LoadFailure = 'PLACEMENT.LOAD_FAILURE',
  LoadForDeepLink = 'PLACEMENT.LOAD_FOR_DEEP_LINK',
  UpdateHasPlayedLookbackIntro = 'PLACEMENT.UPDATE_HAS_PLAYED_LOOKBACK_INTRO',
  Unloaded = 'PLACEMENT.UNLOADED'
}

export interface IPlacementState {
  isLoading: boolean;
  hasPlayedLookbackIntro: boolean;
  placementContent: IPlacement | undefined;
}
