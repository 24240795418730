import { ILevelEducator } from '@lexialearning/lobo-common';
import {
  ContentProviderFactory,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RouterService } from 'router-service';
import {
  ProfileActionLoadSuccess,
  ProfileActionType,
  ProfileSelector
} from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { EducatorSelector } from '../redux';
import {
  EducatorAction,
  EducatorActionLoadLevelsSuccess
} from '../redux/Educator.action';

export interface IEducatorDeps {
  contentProviderFactory: ContentProviderFactory;
  preparedScenes: PreparedScenes;
}

export function educatorLoadLevelsEpic(
  action$: Observable<ProfileActionLoadSuccess>,
  state$: StateObservable<unknown>,
  deps: IEducatorDeps
): Observable<EducatorActionLoadLevelsSuccess> {
  return action$.pipe(
    ofType(ProfileActionType.LoadSuccess),
    filter(() => !ProfileSelector.isStudentLoggedIn(state$.value)),
    tap(async () => {
      // Await intro scene to be prepared before loading levels in order to avoid
      // bottlenecks. Levels can and should load after this and so should
      // not be allowed to hold it up. (Only applies on login RoutePath,
      // not when loading levels at any other point)
      if (RouterService.isOnLoginRoute()) {
        await deps.preparedScenes.introductionReady;
      }
    }),
    switchMap(async () => {
      const { contentProviderFactory } = deps;
      let levels = EducatorSelector.getLevels(state$.value);
      if (levels.length > 0) {
        return levels;
      }

      const provider = contentProviderFactory.create();
      levels = await provider.loadByContentType<ILevelEducator>(
        LoboContentType.Level,
        { nestingDepth: 1 }
      );

      return levels.filter(l => l.showInEducatorMode);
    }),
    map(EducatorAction.loadLevelsSuccess)
  );
}
educatorLoadLevelsEpic.displayName = 'educatorLoadLevelsEpic';
