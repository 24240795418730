import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function MicIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={MicIconSvg.displayName}>
      {shadowColor && (
        <G transform="translate(0.8 1)">
          <Path
            fill={shadowColor}
            d="M53.28,17.75a6.5,6.5,0,1,0-13,0v6.5h13Z"
          />
          <Path
            fill={shadowColor}
            d="M46.78,38.69a6.5,6.5,0,0,0,6.5-6.5v-6.5h-13v6.5a6.5,6.5,0,0,0,6.5,6.5Z"
          />
          <Path
            fill={shadowColor}
            d="M57.76,32.3V23.05a1.5,1.5,0,1,0-3,0V32.3a8,8,0,0,1-7.9,8H46.71a8,8,0,0,1-7.9-8V23a1.5,1.5,0,0,0-3,0v9.3a11,11,0,0,0,9.47,10.87v4.38H40a1.5,1.5,0,0,0,0,3H53.3a1.5,1.5,0,1,0,0-3h-5V43.17A11,11,0,0,0,57.76,32.3Z"
          />
        </G>
      )}
      {/* Mic */}
      <G>
        <Path fill={iconColor} d="M53.28,17.75a6.5,6.5,0,1,0-13,0v6.5h13Z" />
        <Path
          fill={iconColor}
          d="M46.78,38.69a6.5,6.5,0,0,0,6.5-6.5v-6.5h-13v6.5a6.5,6.5,0,0,0,6.5,6.5Z"
        />
        <Path
          fill={iconColor}
          d="M57.76,32.3V23.05a1.5,1.5,0,1,0-3,0V32.3a8,8,0,0,1-7.9,8H46.71a8,8,0,0,1-7.9-8V23a1.5,1.5,0,0,0-3,0v9.3a11,11,0,0,0,9.47,10.87v4.38H40a1.5,1.5,0,0,0,0,3H53.3a1.5,1.5,0,1,0,0-3h-5V43.17A11,11,0,0,0,57.76,32.3Z"
        />
      </G>
    </G>
  );
}
MicIconSvg.displayName = 'MicIconSvg';
