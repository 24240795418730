import { PlacementScreenplayBuilderBase } from '../placement/PlacementScreenplayBuilderBase';
import { OnboardingScreenplayId } from './onboarding-screenplay-builders.model';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=ELKMK&title=Student+Placement
 */
export class OnboardingOutroScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'OnboardingOutroScreenplayBuilder';

  public static create(): OnboardingOutroScreenplayBuilder {
    return new OnboardingOutroScreenplayBuilder();
  }

  private constructor() {
    super(OnboardingScreenplayId.Outro);
  }
}
