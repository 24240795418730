import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  PositionActionActivityDeselected,
  PositionActionType
} from 'curriculum-services';
import { IMeterState } from '../../progress-meters.model';
import { RoundMeterActions } from './RoundMeter.action';
import { RoundMeterActionType } from './RoundMeterActionType';

export const roundMeterInitialState: IMeterState = {
  markers: []
};

export function roundMeterReducer(
  state: IMeterState = roundMeterInitialState,
  action:
    | RoundMeterActions
    | PositionActionActivityDeselected
    | UserGlobalActionLogoutSuccess
): IMeterState {
  switch (action.type) {
    case RoundMeterActionType.Updated:
      return { ...action.payload };

    case PositionActionType.ActivityDeselected:
    case UserGlobalActionType.LogoutSuccess:
      return roundMeterInitialState;

    default:
      return state;
  }
}
roundMeterReducer.displayName = 'roundMeterReducer';
