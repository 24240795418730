/**
 * Taken from node_modules/react-native/Libraries/Animated/src/NativeAnimatedHelper.js
 * If the package definitions change, this needs to be updated
 */
export enum NativeAnimationStyle {
  BorderBottomEndRadius = 'borderBottomEndRadius',
  BorderBottomLeftRadius = 'borderBottomLeftRadius',
  BorderBottomRightRadius = 'borderBottomRightRadius',
  BorderBottomStartRadius = 'borderBottomStartRadius',
  BorderColor = 'borderColor',
  BorderRadius = 'borderRadius',
  BorderTopEndRadius = 'borderTopEndRadius',
  BorderTopLeftRadius = 'borderTopLeftRadius',
  BorderTopRightRadius = 'borderTopRightRadius',
  BorderTopStartRadius = 'borderTopStartRadius',
  Elevation = 'elevation',
  Opacity = 'opacity',
  ShadowOpacity = 'shadowOpacity',
  ShadowRadius = 'shadowRadius',
  ZIndex = 'zIndex'
}

export enum NativeAnimationTransform {
  Perspective = 'perspective',
  Rotate = 'rotate',
  RotateX = 'rotateX',
  RotateY = 'rotateY',
  RotateZ = 'rotateZ',
  Scale = 'scale',
  ScaleX = 'scaleX',
  ScaleY = 'scaleY',
  TranslateX = 'translateX',
  TranslateY = 'translateY'
}

/**
 * List of properties that can be animated within ReactXP,
 * but not using the native driver
 */
export enum NonNativeAnimationStyle {
  BackgroundColor = 'backgroundColor',
  Bottom = 'bottom',
  Color = 'color',
  Height = 'height',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Width = 'width'
}

export const AnimatableCSSProperty = {
  ...NativeAnimationStyle,
  ...NativeAnimationTransform,
  ...NonNativeAnimationStyle
};
export type AnimatableCSSProperty =
  | NativeAnimationStyle
  | NativeAnimationTransform
  | NonNativeAnimationStyle;
