import { DeployEnvironment } from '@lexialearning/main-model';
import { SystemInfo } from 'utils';
import { Version } from './Version';

export class ConfigOverridesFactory {
  public static readonly AppName = 'Lobo';

  public static getSetName(env: DeployEnvironment): string {
    switch (env) {
      case DeployEnvironment.Local:
        const releaseRegEx = /release\/(\d+\.\d+\.\d+)$/;
        const isRelease = releaseRegEx.test(Version.Branch);
        const branchName = isRelease ? 'release' : Version.Branch;

        return SystemInfo.isNative
          ? `${this.AppName} local ${branchName} native`
          : `${this.AppName} local ${branchName}`;

      case DeployEnvironment.Develop:
      case DeployEnvironment.DevelopDebug:
      case DeployEnvironment.DevelopLatest:
      case DeployEnvironment.Main:
      case DeployEnvironment.MainDebug:
      case DeployEnvironment.MainLatest:
      case DeployEnvironment.QA:
      case DeployEnvironment.QADebug:
      case DeployEnvironment.PerformanceProfiling:
        return SystemInfo.isNative
          ? `${this.AppName} ${env
              .replace('-debug', '')
              .replace('-latest', '')} native`
          : `${this.AppName} ${env
              .replace('-debug', '')
              .replace('-latest', '')}`;

      default:
        return '';
    }
  }
}
