import {
  IModeling,
  ITask,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';

export interface ITaskState {
  canSubmit: boolean;
  modeling: IModeling | undefined;
  phase: TaskPhase;
  roundId: string | undefined;
  showSolution: boolean;
  taskContent: ITask | undefined;
  selectedChoices: string[] | undefined;
}

export enum TaskActionType {
  ClearSelectedChoices = 'TASK.CLEAR_SELECTED_CHOICES',
  Entry = 'TASK.ENTRY',
  EntryComplete = 'TASK.ENTRY_COMPLETE',
  Evaluated = 'TASK.EVALUATED',
  Exit = 'TASK.EXIT',
  ExitComplete = 'TASK.EXIT_COMPLETE',
  Feedback = 'TASK.FEEDBACK',
  FeedbackComplete = 'TASK.FEEDBACK_COMPLETE',
  Interactive = 'TASK.INTERACTIVE',
  Loaded = 'TASK.LOADED',
  PlayAudioSupport = 'TASK.PLAY_AUDIO_SUPPORT',
  Preamble = 'TASK.PREAMBLE',
  PreambleComplete = 'TASK.PREAMBLE_COMPLETE',
  SetSelectedChoice = 'TASK.SET_SELECTED_CHOICE',
  ShowSolution = 'TASK.SHOW_SOLUTION',
  Solution = 'TASK.SOLUTION',
  SolutionComplete = 'TASK.SOLUTION_COMPLETE',
  ToggleSelectedChoice = 'TASK.TOGGLE_SELECTED_CHOICE',
  UpdateCanSubmit = 'TASK.UPDATE_CAN_SUBMIT'
}
