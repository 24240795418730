import { TaskPhase } from '@lexialearning/lobo-common';
import { InteractionState } from 'common-styles';
import { FeedbackStrategy, UnitSelector } from 'curriculum-services';
import { useSelector } from 'react-redux';
import { ModelingSelector, TaskSelector } from 'task-components';

export interface IChoiceStateProps {
  sysId: string;
  isCorrectChoice?: boolean;
}

export function useChoiceState(props: IChoiceStateProps) {
  const { isCorrectChoice, sysId } = props;
  const highlightedItemId = useSelector(ModelingSelector.getHighlightedItemId);
  const showSolution = useSelector(TaskSelector.getShowSolution);
  const selectedChoices = useSelector(TaskSelector.getSelectedChoices);
  const phase = useSelector(TaskSelector.getPhase);
  const feedbackStrategy = useSelector(UnitSelector.getFeedbackStrategy);

  const isSelected = selectedChoices.includes(sysId);
  const isHighlighted =
    (isCorrectChoice && showSolution) ||
    sysId === highlightedItemId ||
    (isSelected && feedbackStrategy === FeedbackStrategy.Neutral);

  let shouldShowSelected = false;
  let shouldShowCorrect = false;
  let shouldShowIncorrect = false;
  if (!showSolution && isSelected) {
    if (isCorrectChoice === undefined) {
      shouldShowSelected = true;
    } else if (phase === TaskPhase.Feedback) {
      shouldShowCorrect = isCorrectChoice;
      shouldShowIncorrect = !isCorrectChoice;
    }
  }

  const interactionState = isHighlighted
    ? InteractionState.Highlighted
    : shouldShowSelected
    ? InteractionState.Selected
    : shouldShowCorrect
    ? InteractionState.Correct
    : shouldShowIncorrect
    ? InteractionState.Incorrect
    : InteractionState.Default;

  return {
    interactionState,
    isSelected
  };
}
useChoiceState.displayName = 'useChoiceState';
