import { useEffect, useRef } from 'react';
import { SparkleSvg } from './Sparkle.lx-svg';
import {
  ISparkleAnimationProps,
  SparkleAnimatedStyles
} from './Sparkle.animated-styles';
import { AnimatedView } from 'common-ui';

export interface ISparkleProps extends ISparkleAnimationProps {
  triggerAnimation: number;
}

export function Sparkle(props: ISparkleProps) {
  const { triggerAnimation, ...restProps } = props;

  const animatedStylesRef = useRef(new SparkleAnimatedStyles(restProps));
  const { sparkle } = animatedStylesRef.current.getAnimations();
  const styles = animatedStylesRef.current.get();

  useEffect(() => {
    if (triggerAnimation > 0) {
      sparkle.start();
    }
  }, [triggerAnimation, sparkle]);

  return (
    <AnimatedView
      testId={Sparkle.displayName}
      animatedStyle={styles.positionAnimated}
      style={styles.positionStatic}
    >
      <AnimatedView animatedStyle={styles.opacityAnimated}>
        <SparkleSvg />
      </AnimatedView>
    </AnimatedView>
  );
}
Sparkle.displayName = 'Sparkle';
