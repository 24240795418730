export enum DeviceInfoHelperError {
  FailedToGetInfo = 'FailedToGetInfo'
}

export interface IGetDataOptions {
  /**
   * When true, an error getting device info is NOT logged.
   * Useful to avoid infinite loops when getting device info to log another
   * error.
   */
  suppressErrorLogging: boolean;
}
