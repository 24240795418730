import {
  ILevel,
  IProgramPosition,
  SubunitType
} from '@lexialearning/lobo-common';
import {
  IContentBase,
  IContentProvider,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { ActivityPositionBuilder } from 'curriculum-services';
import {
  AncestorIndex,
  IActiveBranch,
  IDeepLinkContentIds,
  IDeepLinkSubject
} from '../position-builder-deep-link.model';
import { DeepLinkContentIdsFactory } from './DeepLinkContentIdsFactory';

export class DefaultDeepLinkPositionBuilder {
  public static readonly displayName = 'DefaultDeepLinkPositionBuilder';

  private activityPositionBuilder!: ActivityPositionBuilder;

  private contentId!: IDeepLinkContentIds;

  /**
   * Subject to which we are deep linking (e.g. round, unit, subunit)
   */

  public constructor(
    /**
     * sysId and contentType of subject's ancestors starting with the Level and
     * all the way to the subject itself.
     */
    private readonly ancestors: IContentBase[],
    private readonly provider: IContentProvider
  ) {}

  public async build(subject: IDeepLinkSubject): Promise<IProgramPosition> {
    this.contentId = DeepLinkContentIdsFactory.create(subject, this.ancestors);

    const level = await this.provider.loadById<ILevel>(
      LoboContentType.Level,
      this.contentId.level,
      { nestingDepth: 4 }
    );

    const activeBranch = this.buildActiveBranch(level);
    const position = this.createProgramPosition(activeBranch);
    this.activityPositionBuilder = this.createActivityPositionBuilder(
      activeBranch,
      position
    );

    this.maybeAddInstructionSubunit()
      .maybeAddForkSubunit()
      .maybeAddForkedInstructionSubunit();

    position.activityPositions = position.activityPositions.map(ap =>
      ap.activityId === position.activeActivityId
        ? this.activityPositionBuilder.raw
        : ap
    );

    return position;
  }

  private buildActiveBranch(level: ILevel): IActiveBranch {
    const activity = level.acts.find(a => a.sysId === this.contentId.activity)!;
    const encounter = activity.encounters.find(
      e => e.sysId === this.contentId.encounter
    )!;
    const mainUnit = encounter.units.find(
      u => u.sysId === this.contentId.mainUnit
    )!;
    const mainRoundId = this.contentId.mainRound
      ? mainUnit.rounds.find(r => r.sysId === this.contentId.mainRound)?.sysId
      : mainUnit.rounds[0].sysId;

    return {
      activity,
      encounter,
      level,
      mainRoundId,
      mainUnit
    };
  }

  private createProgramPosition(activeBranch: IActiveBranch): IProgramPosition {
    const activityPositions = activeBranch.level.acts.map(a => {
      const encounter = a.encounters[0];
      const unit = encounter.units[0];
      const roundId = unit.rounds?.[0].sysId ?? '';

      return ActivityPositionBuilder.create({
        activityId: a.sysId,
        encounterId: encounter.sysId
      }).withUnitPosition({ roundId, unitId: unit.sysId }).raw;
    });

    return {
      activeActivityId: activeBranch.activity.sysId,
      activityPositions,
      isComplete: false,
      levelId: activeBranch.level.sysId
    };
  }

  private createActivityPositionBuilder(
    activeBranch: IActiveBranch,
    position: IProgramPosition
  ): ActivityPositionBuilder {
    const activityPosition = position.activityPositions.find(
      a => a.activityId === position.activeActivityId
    )!;

    return ActivityPositionBuilder.create(activityPosition)
      .update({ encounterId: activeBranch.encounter.sysId })
      .withUnitPosition({
        roundId: activeBranch.mainRoundId,
        unitId: activeBranch.mainUnit.sysId
      });
  }

  private maybeAddInstructionSubunit(): DefaultDeepLinkPositionBuilder {
    const instructionSubunitId = this.contentId.instructionSubunit;
    if (!instructionSubunitId) {
      return this;
    }

    this.activityPositionBuilder.addSubunitPosition({
      roundId: this.contentId.instructionRound ?? '',
      subunitType: SubunitType.Instruction,
      unitId: instructionSubunitId
    });

    return this;
  }

  private maybeAddForkSubunit(): DefaultDeepLinkPositionBuilder {
    const forkSubunitId = this.contentId.forkSubunit;
    if (!forkSubunitId) {
      return this;
    }

    this.activityPositionBuilder.addSubunitPosition({
      roundId: this.contentId.forkRound ?? '',
      subunitType: this.ancestors[AncestorIndex.ForkTask].contentType,
      unitId: forkSubunitId
    });

    return this;
  }

  private maybeAddForkedInstructionSubunit(): DefaultDeepLinkPositionBuilder {
    const forkedInstructionSubunitId = this.contentId.forkedInstructionSubunit;
    if (!forkedInstructionSubunitId) {
      return this;
    }

    this.activityPositionBuilder.addSubunitPosition({
      roundId: this.contentId.forkedInstructionRound ?? '',
      subunitType: SubunitType.Instruction,
      unitId: forkedInstructionSubunitId
    });

    return this;
  }
}
