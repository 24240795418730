import { SystemInfo } from '@lexialearning/utils-react';
import { View } from '../view';
import * as React from 'react';

export interface IAutoFocusViewProps {
  triggerFocus?: string;
}

export class AutoFocusView extends React.PureComponent<IAutoFocusViewProps> {
  public static readonly displayName = 'AutoFocusView';

  public render() {
    if (SystemInfo.isNative) {
      return null;
    }

    return <View tabIndex={-1} autoFocus key={this.props.triggerFocus} />;
  }
}
