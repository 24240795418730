import {
  IUnitSavePoint,
  UnitSavePointStatus
} from '@lexialearning/lobo-common/main-model/curriculum';
import {
  PositionActionActivityDeselected,
  PositionActionType
} from '../position';
import { SavePointActionType } from './save-point-redux.model';
import {
  SavePointActionRoundAdded,
  SavePointActions,
  SavePointActionSavePosition,
  SavePointActionSavePositionSuccess
} from './SavePoint.action';

/**
 * NOTE: This reducer is called by programContextReducer not the root reducer.
 * So all actions here must first be recognized by programContextReducer.
 */
export function savePointReducer(
  state: IUnitSavePoint[] = [],
  action: SavePointActions | PositionActionActivityDeselected
): IUnitSavePoint[] {
  switch (action.type) {
    case PositionActionType.ActivityDeselected:
      // Remove saved/saving save points, but keep deferred in case of reentry
      return state.filter(sp => sp.status === UnitSavePointStatus.Deferred);

    case SavePointActionType.Created:
      return [...state, action.payload];

    case SavePointActionType.RoundAdded:
      return state.map(sp => updateTo(sp, action.payload, action));

    case SavePointActionType.SavePosition:
      return state.map(sp =>
        updateTo(sp, { ...sp, status: UnitSavePointStatus.Saving }, action)
      );

    case SavePointActionType.SavePositionSuccess:
      return state.map(sp =>
        updateTo(
          sp,
          { ...sp, status: UnitSavePointStatus.Saved },
          action,
          UnitSavePointStatus.Saving
        )
      );

    default:
      return state;
  }
}
savePointReducer.displayName = 'savePointReducer';

function updateTo(
  sp: IUnitSavePoint,
  revised: IUnitSavePoint,
  {
    payload: { unitId }
  }:
    | SavePointActionRoundAdded
    | SavePointActionSavePosition
    | SavePointActionSavePositionSuccess,
  status = UnitSavePointStatus.Deferred
): IUnitSavePoint {
  return sp.unitId === unitId && sp.status === status ? revised : sp;
}
