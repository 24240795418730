import { Align, Color, Justify } from 'common-styles';

export class AccountSettingsModalStyles {
  public static readonly webViewHeight = 400;

  public static readonly styles = {
    contentArea: {
      alignItems: Align.Stretch,
      borderBottomWidth: 1,
      borderColor: Color.Gray10,
      flex: 1,
      justifyContent: Justify.Start,
      paddingHorizontal: 25,
      paddingVertical: 15,
      width: 590
    },
    footerInfo: {
      height: 60
    },
    mainColumn: {
      alignItems: Align.Stretch,
      backgroundColor: Color.NearWhite,
      height: 530,
      justifyContent: Justify.Start,
      width: 850
    },
    mainRow: {
      alignItems: Align.Stretch,
      flex: 1,
      justifyContent: Justify.Start
    }
  };

  public static get() {
    return this.styles;
  }
}
