import { SceneName } from 'services/storm-lobo/StormAssets';
import {
  IMaterialDefinition,
  ISceneDefinition,
  SceneDefinitionBuilder
} from 'storm';
import { EncounterCompleteSceneMaterialName } from './encounter-complete-scene.model';

export class EncounterCompleteSceneDefinitionBuilder {
  public static readonly ScenePath =
    '/scenes/encounter-complete/encounter_complete.sg';

  public static create(
    position: string
  ): EncounterCompleteSceneDefinitionBuilder {
    const materials: IMaterialDefinition[] = [
      {
        name: EncounterCompleteSceneMaterialName.PaperBack,
        texturePaths: [
          `/scenes/images/encounter-complete/${position}_complete.tx`
        ]
      }
    ];

    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.EncounterComplete,
      this.ScenePath,
      { materials }
    );

    return new EncounterCompleteSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}
}
