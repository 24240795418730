import * as React from 'react';
import { ResponseSpeechBubble } from '../shared/speech-bubble/ResponseSpeechBubble';
import { ISpeechBubbleCommonProps } from '../speech-bubbles.model';

export function ChoralSpeechBubble(
  props: ISpeechBubbleCommonProps
): React.ReactElement {
  const { animatedStyles, ...restProps } = props;

  return (
    <ResponseSpeechBubble
      {...restProps}
      animatedStyles={animatedStyles.response}
      hasTailLeft={true}
      testId={ChoralSpeechBubble.displayName}
    />
  );
}
ChoralSpeechBubble.displayName = 'ChoralSpeechBubble';
