import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogoutConfirmationModal } from 'feature-areas/logout-confirmation';
import {
  AppShellAction,
  AppShellActionRequestLogout,
  AppShellActionShowModal
} from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

export function handleRequestLogoutEpic(
  action$: Observable<AppShellActionRequestLogout>
): Observable<AppShellActionShowModal> {
  return action$.pipe(
    ofType(AppShellActionType.RequestLogout),
    map(() => AppShellAction.showModal({ id: LogoutConfirmationModal.ModalId }))
  );
}
handleRequestLogoutEpic.displayName = 'handleRequestLogoutEpic';
