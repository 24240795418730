import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  TaskDemoSceneAction,
  TaskDemoScenePrepareAction
} from 'feature-areas/tasks/task-demo-scene/redux';
import { CommonUiSelector } from 'common-ui';
import { TaskDemoType } from 'services/storm-lobo/StormAssets';
import { TaskActionLoaded, TaskActionType, TaskSelector } from '../redux';

export function showTaskDemoEpic(
  action$: Observable<TaskActionLoaded>,
  state$: StateObservable<unknown>
): Observable<TaskDemoScenePrepareAction | void> {
  return action$.pipe(
    ofType(TaskActionType.Loaded),
    filter(() => {
      const state = state$.value;

      const demosShown = CommonUiSelector.getTaskDemosShown(state);
      const { taskType } = TaskSelector.getTaskContent(state);

      if (!demosShown.includes(taskType) && TaskDemoType.includes(taskType)) {
        return true;
      }

      return false;
    }),
    map(() => TaskDemoSceneAction.prepare())
  );
}
showTaskDemoEpic.displayName = 'loadTaskEpic';
