import { Align, Direction } from 'common-styles';
import { CONSTANTS } from 'common-ui';
import {
  IPageAnimatedValues,
  IPageAnimations,
  PageAnimatedStyles
} from 'shared-components/Page.animated-styles';

export class LevelPageAnimatedStyles {
  private readonly styles: any = {
    acts: {
      alignItems: Align.Stretch,
      flex: 1,
      flexDirection: Direction.Row,
      width: CONSTANTS.BaseDimensions.Width
    },
    container: {
      alignItems: Align.Center,
      flex: 1
    }
  };

  private readonly animatedValues: IPageAnimatedValues;

  private readonly animations: IPageAnimations;

  constructor() {
    this.animatedValues = PageAnimatedStyles.createEntryExitAnimatedValues();
    this.animations = PageAnimatedStyles.createEntryExitAnimations(
      this.animatedValues
    );
    this.styles.container.opacity = this.animatedValues.opacity;
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
