import merge from 'lodash/merge';
import memoizeOne from 'memoize-one';
import { Color, InteractionState } from 'common-styles';

export interface ISpotlightStyleOverride {
  container: {
    marginHorizontal: number;
  };
}

export enum SpotlightAreaLayout {
  Default,
  Small
}

export class SpotlightStyles {
  public static readonly styles = {
    container: {
      default: {
        borderColor: Color.NearWhite,
        borderWidth: 8,
        marginHorizontal: 5
      },
      highlighted: {
        borderColor: Color.getSetFor(InteractionState.Highlighted).border
      },
      small: {
        marginHorizontal: 3
      }
    },
    image: {
      default: {
        borderColor: Color.Gray50,
        borderRadius: 0,
        borderWidth: 1,
        height: 220,
        width: 220
      },
      small: {
        height: 176,
        width: 176
      }
    }
  };

  public static readonly build = memoizeOne(
    (
      spotlightsCount: number,
      isHighlighted: boolean,
      layout: SpotlightAreaLayout,
      styleOverride?: ISpotlightStyleOverride
    ) => {
      const styles = {
        card: {
          image: {
            ...SpotlightStyles.styles.image.default,
            ...(layout === SpotlightAreaLayout.Small &&
              SpotlightStyles.styles.image.small)
          }
        },
        container: {
          ...SpotlightStyles.styles.container.default,
          ...(isHighlighted && SpotlightStyles.styles.container.highlighted),
          ...(layout === SpotlightAreaLayout.Small &&
            SpotlightStyles.styles.container.small)
        }
      };
      if (spotlightsCount === 2) {
        styles.container.marginHorizontal =
          styles.container.marginHorizontal + 5;
      }

      return merge({}, styles, styleOverride);
    }
  );
}
