import { delay, random } from 'lodash';
import * as React from 'react';
import { AnimatedView } from 'common-ui';
import {
  AmbienceAnimationStyles,
  IAmbienceAnimations
} from './AmbienceAnimation.animated-styles';

export interface IAmbienceAnimationProps {
  index: number;
  playAnimation: boolean;
}
export class AmbienceAnimation extends React.PureComponent<IAmbienceAnimationProps> {
  public static readonly displayName = 'AmbienceAnimation';

  private readonly ambienceAnimationStyles: AmbienceAnimationStyles;

  private readonly animations: IAmbienceAnimations;

  constructor(props: IAmbienceAnimationProps) {
    super(props);
    this.ambienceAnimationStyles = new AmbienceAnimationStyles();
    this.animations = this.ambienceAnimationStyles.getAnimations();
    this.startAnimation = this.startAnimation.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
  }

  private startAnimation() {
    const { playAnimation } = this.props;
    this.animations.sizeAnimation.start(() => {
      playAnimation
        ? this.startAnimation()
        : this.animations.returnToStart.start();
    });
  }

  private stopAnimation() {
    this.animations.sizeAnimation.stop();
  }

  public componentDidUpdate(prevProps: IAmbienceAnimationProps) {
    const { index } = this.props;

    if (this.props.playAnimation && !prevProps.playAnimation) {
      delay(this.startAnimation, random(1, 500) * ((index % 2) + 1));
    }

    if (!this.props.playAnimation && prevProps.playAnimation) {
      this.stopAnimation();
    }
  }

  public render() {
    const styles = this.ambienceAnimationStyles.get();

    return (
      <AnimatedView
        style={styles.container}
        animatedStyle={styles.containerAnimated}
      />
    );
  }
}
