import memoizeOne from 'memoize-one';
import { Align } from 'common-styles';
import { CONSTANTS } from 'common-ui';
import { Position } from '@lexialearning/common-ui';

export class LoginShellStyles {
  private static readonly styles = {
    container: {
      flex: 1
    },
    loginImg: {
      height: 768,
      left: -227,
      position: Position.Absolute,
      width: 937
    },
    versionContainer: {
      alignSelf: Align.Center,
      bottom: 24,
      position: Position.Absolute
    }
  };

  public static readonly build = memoizeOne((keyboardHeight: number) => ({
    ...LoginShellStyles.styles,
    formContainer: {
      alignItems: Align.End,
      height: CONSTANTS.BaseDimensions.Height,
      marginBottom: keyboardHeight,
      paddingRight: 55,
      width: CONSTANTS.BaseDimensions.Width
    }
  }));
}
