import { Rect, Svg } from '@lexialearning/common-ui';
import { Color } from 'common-styles';

export function YellowStrokeSvg(props: { style: any }) {
  return (
    <Svg
      data-test-id={YellowStrokeSvg.displayName}
      width="124"
      height="99"
      style={props.style}
    >
      <Rect
        x="2.5"
        y="2.5"
        rx="12"
        ry="12"
        width="117"
        height="92"
        fill={Color.Transparent}
        stroke={Color.Yellow}
        strokeWidth="5"
      />
    </Svg>
  );
}
YellowStrokeSvg.displayName = 'YellowStrokeSvg';
