import {
  IImageProps as CuiIImageProps,
  ImageStyle as CuiImageStyle,
  ImportantForAccessibility
} from '@lexialearning/common-ui';
import * as React from 'react';
import { Image } from './Image';

interface IBackgroundImageProps
  extends Omit<
    CuiIImageProps,
    'style' | 'accessibilityLabel' | 'importantForAccessibility'
  > {
  style?: CuiImageStyle;
}
export class BackgroundImage extends React.PureComponent<IBackgroundImageProps> {
  public static readonly displayName = 'BackgroundImage';

  public render() {
    const { children } = this.props;

    return (
      <Image
        {...this.props}
        importantForAccessibility={ImportantForAccessibility.No}
      >
        {children}
      </Image>
    );
  }
}
