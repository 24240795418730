import {
  ChoicesLayout,
  IChoice,
  TaskEvaluationResult,
  TextPillWidth
} from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { TaskSelector } from 'task-components';
import { ThemeType, withTheme } from 'theme';
import { ImageChoicesContainer } from './image-choice/ImageChoicesContainer';
import { TextChoicesContainer } from './text-choice/TextChoicesContainer';
import { YesNoChoicesContainer } from './yes-no-choice/YesNoChoicesContainer';

export interface IChoicesProps {
  choices: IChoice[];
  choicesLayout: ChoicesLayout;
  textPillWidth: TextPillWidth;
  themeType: ThemeType;
  selectedChoiceSysId?: string;
  onEvaluated(result: TaskEvaluationResult, answer?: object): void;
}

export class ChoicesComponent extends React.PureComponent<IChoicesProps> {
  public static readonly displayName = 'Choices';

  public componentDidUpdate(prevProps: IChoicesProps) {
    if (prevProps.selectedChoiceSysId !== this.props.selectedChoiceSysId) {
      const selectedChoice = this.props.choices.find(
        c => c.card.sysId === this.props.selectedChoiceSysId
      );
      if (selectedChoice) {
        this.props.onEvaluated(
          selectedChoice.isCorrectChoice
            ? TaskEvaluationResult.Correct
            : TaskEvaluationResult.Incorrect,
          {
            choicesLayout: this.props.choicesLayout,
            selectedChoices: [selectedChoice.card]
          }
        );
      }
    }
  }

  public render() {
    const { choices, choicesLayout, textPillWidth, themeType } = this.props;

    return choicesLayout === ChoicesLayout.Image ? (
      <ImageChoicesContainer
        choices={choices}
        isInstruction={themeType === ThemeType.Instruction}
      />
    ) : choicesLayout === ChoicesLayout.Text ? (
      <TextChoicesContainer choices={choices} textPillWidth={textPillWidth} />
    ) : (
      <YesNoChoicesContainer choices={choices} />
    );
  }
}

function mapStateToProps(state: unknown) {
  return {
    selectedChoiceSysId: TaskSelector.getSelectedChoice(state)
  };
}

export const Choices = withTheme(connect(mapStateToProps)(ChoicesComponent));

export const ChoicesPrivates = {
  mapStateToProps
};
