import { IEncounterProgress } from '@lexialearning/lobo-common/main-model/student';
import { Row, View } from 'common-ui';
import { EncounterProgressBarStyles } from './EncounterProgressBar.styles';

export interface IEncounterProgressBarProps {
  encounterPositions: IEncounterProgress[];
}

export function EncounterProgressBar(props: IEncounterProgressBarProps) {
  const styles = EncounterProgressBarStyles.build(props.encounterPositions);

  return (
    <Row testId={EncounterProgressBar.displayName}>
      <Row style={styles.encounter1}>
        <View style={styles.encounter1progress} />
      </Row>
      <Row style={styles.encounter2}>
        <View style={styles.encounter2progress} />
      </Row>
      <Row style={styles.encounter3}>
        <View style={styles.encounter3progress} />
      </Row>
    </Row>
  );
}
EncounterProgressBar.displayName = 'EncounterProgressBar';
