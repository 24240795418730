import { IScreenplay } from '@lexialearning/lobo-common';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { CalibrationOutroScreenplayBuilder } from '../builders/calibration';
import { OnboardingIntroScreenplayBuilder } from '../builders/onboarding/OnboardingIntroScreenplayBuilder';
import { TransitionScreenplayId } from '../transition.model';

export interface ICalibrationToOnboardingTransitionScreenplayDeps {
  introductionVo: IScreenplay;
  preparedScenes: PreparedScenes;
  stormService: StormService;
  targetRoundId: string;
  targetUnitId: string;
}

export class CalibrationToOnboardingTransitionScreenplayFactory {
  public static readonly displayName =
    'CalibrationToOnboardingTransitionScreenplayFactory';

  public static createFor(
    deps: ICalibrationToOnboardingTransitionScreenplayDeps
  ): CalibrationToOnboardingTransitionScreenplayFactory {
    return new CalibrationToOnboardingTransitionScreenplayFactory(deps);
  }

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  protected readonly builder: ScreenplayBuilder;

  private constructor(deps: ICalibrationToOnboardingTransitionScreenplayDeps) {
    const {
      introductionVo,
      preparedScenes,
      stormService,
      targetRoundId,
      targetUnitId
    } = deps;

    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.CalibrationToOnboarding
    )
      .addScreenplay(
        CalibrationOutroScreenplayBuilder.create()
          .disableUtilityBar()
          .fadeOutReactElements()
          .playSceneOutro().screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.onboarding());
      })
      .addScreenplay(
        OnboardingIntroScreenplayBuilder.create()
          .enableUtilityBar()
          .showPlacementScene(preparedScenes, stormService)
          .playOnboardingIntro(introductionVo)
          .navigateToOnboardingUnit(targetUnitId, targetRoundId)
          .dispatchOnboardingRoundIntro().screenplay
      );
  }
}
