import { handleDeepLinkEpic } from './handleDeepLink.epic';
import { loadProgramContextForDeepLinking } from './loadProgramContextForDeepLinking.epic';
import { buildDeepLinkPositionEpic } from './position-builder/buildDeepLinkPosition.epic';
import { loadPlacementContentForDeepLinkEpic } from './position-builder/placement/loadPlacementContentForDeepLink.epic';

export const deepLinkEpics = [
  buildDeepLinkPositionEpic,
  handleDeepLinkEpic,
  loadPlacementContentForDeepLinkEpic,
  loadProgramContextForDeepLinking
];
