import { AppShellAction } from 'feature-areas/shell/redux';
import { useDispatch } from 'react-redux';
import { UserInfoButton } from './UserInfoButton';

export function HideUserInfoButton() {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(AppShellAction.hideModal());
  };

  return (
    <UserInfoButton
      isHighlighted
      onPress={onPress}
      testId={HideUserInfoButton.displayName}
    />
  );
}
HideUserInfoButton.displayName = 'HideUserInfoButton';
