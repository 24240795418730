import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { IScreenplayActionPlayer, ScreenplayerType } from 'screenplay';
import { LxStormAudio } from 'storm/audio';
import { ISfx } from '.';

export class StormSfxScreenplayer implements IScreenplayActionPlayer {
  public readonly type = ScreenplayerType.Sfx;

  constructor(private readonly stormAudioPlayer: LxStormAudio) {}

  /**
   * Stubbed out only
   * SFX players are not canceled because the audio lengths are so short
   * and the generic cancel strategy for players is to free them up.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public cancel(): void {}

  /**
   * Stubbed out only
   * SFX players are not paused because the audio lengths are so short
   * and there would be no point in resuming a partial sfx after pause.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public pause(): void {}

  public async play(action: IScreenplayAction<ISfx>): Promise<void> {
    await this.stormAudioPlayer.playSfx(action.data.path);
  }

  /**
   * Stubbed out only
   * As SFX players are not paused, there is no need for resume.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public resume(): void {}
}
