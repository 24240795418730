import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  TaskActionEvaluated,
  TaskActionInteractive,
  TaskActionType
} from 'task-components';
import { ProgramMode } from '../program-context.model';
import { PositionActions, PositionActionType } from './position';
import {
  ActivityPositionActions,
  ActivityPositionActionType
} from './position/activity-position';
import { activityPositionsReducer } from './position/activity-position/activityPositions.reducer';
import {
  positionInitialState,
  positionReducer
} from './position/position.reducer';
import {
  IProgramContextState,
  ProgramContextActionType
} from './program-context-redux.model';
import { ProgramContextActions } from './ProgramContext.action';
import { SavePointActions, SavePointActionType } from './save-points';
import { savePointReducer } from './save-points/savePoint.reducer';

type InputActions =
  | ActivityPositionActions
  | PositionActions
  | ProgramContextActions
  | SavePointActions
  | TaskActionEvaluated
  | TaskActionInteractive
  | UserGlobalActionLogoutSuccess;

export const programContextInitialState: IProgramContextState = {
  mode: ProgramMode.Unknown,
  position: positionInitialState,
  savePoints: [],
  sessionHistory: []
};

export function programContextReducer(
  state = programContextInitialState,
  action: InputActions
): IProgramContextState {
  switch (action.type) {
    case PositionActionType.ActivityDeselected:
      return {
        ...state,
        position: positionReducer(state.position, action),
        savePoints: savePointReducer(state.savePoints, action)
      };

    case ActivityPositionActionType.Adjusted:
    case ActivityPositionActionType.Changed:
    case ActivityPositionActionType.EncounterSelected:
    case ActivityPositionActionType.Prepared:
    case ActivityPositionActionType.Selected:
    case ActivityPositionActionType.SkipPrepared:
    case TaskActionType.Evaluated:
    case TaskActionType.Interactive:
      return {
        ...state,
        position: {
          ...state.position,
          activityPositions: activityPositionsReducer(state, action)
        }
      };

    case PositionActionType.LevelActivitySelected:
    case PositionActionType.LevelPositionUnloaded:
    case PositionActionType.LevelStartPosition:
    case PositionActionType.LevelUpPositionPrepared:
    case PositionActionType.ActivitySelected:
      return {
        ...state,
        position: positionReducer(state.position, action)
      };

    case ProgramContextActionType.Loaded:
    case ProgramContextActionType.LoadedForDeepLinking:
      return {
        ...state,
        mode: action.payload.mode,
        position: action.payload.position ?? state.position
      };

    case PositionActionType.LevelUp:
    case ProgramContextActionType.StartProgram:
      return {
        ...state,
        mode: state.position.levelUpPosition?.isProgramComplete
          ? ProgramMode.Completed
          : ProgramMode.ActiveStudent,
        position: positionReducer(state.position, action)
      };

    case ProgramContextActionType.ProgramPositionAdjustedForPlacement:
      return {
        ...state,
        position: action.payload
      };

    case ProgramContextActionType.OnboardingCompleted:
      return {
        ...state,
        mode: ProgramMode.Placement,
        position: {
          ...state.position,
          activeActivityId: action.payload.activityId,
          activityPositions: activityPositionsReducer(state, action)
        }
      };

    case ProgramContextActionType.UpdateSessionHistory:
      return {
        ...state,
        sessionHistory: [
          ...state.sessionHistory,
          action.payload.sessionHistoryEvent
        ]
      };

    case SavePointActionType.Created:
    case SavePointActionType.RoundAdded:
    case SavePointActionType.SavePosition:
    case SavePointActionType.SavePositionSuccess:
      return {
        ...state,
        savePoints: savePointReducer(state.savePoints, action)
      };

    case UserGlobalActionType.LogoutSuccess:
      return programContextInitialState;

    default:
      return state;
  }
}
programContextReducer.displayName = 'programContextReducer';

export const ProgramContextReducerPrivates = {
  activityPositionsReducer,
  initialState: programContextInitialState
};
