import {
  IMicrophone,
  IMicsAvailableUpdate,
  ISreConfig
} from '@lexialearning/lobo-common/main-model/sre';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { SreConfigActionType } from '../sre-redux.model';

export const SreConfigAction = {
  checkMicPermission: createAction(SreConfigActionType.CheckMicPermission)(),
  configure: createAsyncAction(
    SreConfigActionType.Configure,
    SreConfigActionType.ConfigureSuccess,
    SreConfigActionType.ConfigureFailure
  )<void, ISreConfig, Error>(),
  configureValidated: createAction(SreConfigActionType.ConfigureValidated)(),
  selectMic: createAsyncAction(
    SreConfigActionType.SelectMic,
    SreConfigActionType.SelectMicSuccess,
    SreConfigActionType.SelectMicFailure
  )<ISreSelectMicPayload, void, void>(),
  setMicBlocked: createAction(SreConfigActionType.SetMicBlocked)<boolean>(),
  setMicsAvailable: createAction(
    SreConfigActionType.SetMicsAvailable
  )<IMicsAvailableUpdate>()
};

export interface ISreSelectMicPayload {
  mic: IMicrophone;
}

export type SreConfigActions = ActionType<typeof SreConfigAction>;
export type SreCheckMicPermissionAction = ActionType<
  typeof SreConfigAction.checkMicPermission
>;
export type SreConfigureAction = ActionType<
  typeof SreConfigAction.configure.request
>;
export type SreConfigureSuccessAction = ActionType<
  typeof SreConfigAction.configure.success
>;
export type SreConfigureFailureAction = ActionType<
  typeof SreConfigAction.configure.failure
>;
export type SreConfigureValidatedAction = ActionType<
  typeof SreConfigAction.configureValidated
>;
export type SreSelectMicAction = ActionType<
  typeof SreConfigAction.selectMic.request
>;
export type SreSelectMicSuccessAction = ActionType<
  typeof SreConfigAction.selectMic.success
>;
export type SreSelectMicFailureAction = ActionType<
  typeof SreConfigAction.selectMic.failure
>;
export type SreSetMicsAvailableAction = ActionType<
  typeof SreConfigAction.setMicsAvailable
>;
export type SreSetMicBlockedAction = ActionType<
  typeof SreConfigAction.setMicBlocked
>;
