import { ILevel } from '@lexialearning/lobo-common/main-model';
import { Mount } from 'storm';

export enum LevelActionType {
  Load = 'LEVEL.LOAD',
  LoadSuccess = 'LEVEL.LOAD_SUCCESS',
  LoadFailure = 'LEVEL.LOAD_FAILURE',
  MountVoiceovers = 'LEVEL.MOUNT_VOICEOVERS',
  MountVoiceoversSuccess = 'LEVEL.MOUNT_VOICEOVERS_SUCCESS',
  MountVoiceoversFailure = 'LEVEL.MOUNT_VOICEOVERS_FAILURE',
  Ready = 'LEVEL.READY',
  Unload = 'LEVEL.UNLOAD',
  UnloadSuccess = 'LEVEL.UNLOAD_SUCCESS',
  UnloadFailure = 'LEVEL.UNLOAD_FAILURE'
}

export interface ILevelState {
  isLoading: boolean;
  isReady: boolean;
  levelContent: ILevel | undefined;
  voiceovers: ILevelVoiceoversState;
}

export interface ILevelVoiceoversState {
  mount: Mount | undefined;
  status: LevelVoiceoverMountStatus;
}

export enum LevelVoiceoverMountStatus {
  Unmounted = 'Unmounted',
  Unmounting = 'Unmounting',
  Mounting = 'Mounting',
  Mounted = 'Mounted'
}
