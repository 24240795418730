import { StormStatus } from '../service';

export interface IStormState {
  status: StormStatus;
}

export enum StormActionType {
  Initialize = 'STORM.INITIALIZE',
  InitializeSuccess = 'STORM.INITIALIZE_SUCCESS',
  InitializeFailure = 'STORM.INITIALIZE_FAILURE'
}
