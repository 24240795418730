import { LoboContentType } from '@lexialearning/lobo-common/cms';
import * as React from 'react';
import { connect } from 'react-redux';
import { Sfx } from 'audio';
import { Modal, ModalHeader, Text } from 'common-ui';
import {
  IPlayActionPayload,
  ScreenplayAction,
  ScreenplayBuilder
} from 'screenplay';
import { AuthSelector } from 'services/auth';
import { BootstrappingSelector } from 'services/bootstrapping';
import { AppShellAction, AppShellSelector, IActiveModal } from 'shell/redux';
import { IModalConfig, ModalType } from './modal.model';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';
import { ModalUtils } from './ModalUtils';
import { SmartModalStyles } from './SmartModal.styles';

// TODO: Get this from contentful (LOBO-11413)
const getYourTeacherScreenplay = ScreenplayBuilder.create(
  'getYourTeacher'
).addAction({
  data: {
    character: 'Narrator',
    cms: {},
    cmsSys: {},
    transcript: 'Please get your teacher.',
    url: 'shared/narrator/38mzrcaep0po93ecypvhsz/4f77a08beaf07afa2c0e12e99f27fb2c.opus'
  },
  type: LoboContentType.Voiceover
}).screenplay;

export interface ISmartModalProps {
  activeModal?: IActiveModal;
  isAppInitialized: boolean;
  loginId?: string;
  hideModal(): void;
  playScreenplay(payload: IPlayActionPayload): void;
}
const modalTitleId = 'modalTitle';

export class SmartModalComponent extends React.PureComponent<ISmartModalProps> {
  public static readonly displayName: 'SmartModal';

  constructor(props: ISmartModalProps) {
    super(props);

    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  private get modalConfig(): IModalConfig | undefined {
    const { activeModal } = this.props;

    return ModalUtils.getConfig(activeModal?.id);
  }

  private get dismissible(): boolean {
    const { activeModal } = this.props;

    return ModalUtils.isDismissible(activeModal?.id);
  }

  public componentDidMount() {
    if (this.modalConfig) {
      this.playOpeningAudio();
    }
  }

  public componentDidUpdate(prevProps: ISmartModalProps) {
    const { activeModal } = this.props;
    const modalOpened = !prevProps.activeModal && !!activeModal;
    const modalChanged = activeModal && prevProps.activeModal !== activeModal;

    if (modalOpened || modalChanged) {
      this.playOpeningAudio();
    }
  }

  private playOpeningAudio(): void {
    const { getTeacherVO } = this.modalConfig!;
    if (this.props.isAppInitialized && getTeacherVO) {
      const { screenplay } = ScreenplayBuilder.create('modalOpening')
        .addSfx(Sfx.Error)
        .addScreenplay(getYourTeacherScreenplay);

      this.props.playScreenplay({ screenplay });
    }
  }

  /**
   * Native crashes if handlers like onPress are dynamically added/removed
   * (e.g. if going from a dismissible modal to a non-dismissible) so we always
   * set the handler and then inside of that handler check if dismissible or not
   */
  private handleRequestClose(): void {
    if (this.dismissible) {
      this.props.hideModal();
    }
  }

  private renderContent() {
    if (!this.modalConfig) {
      return null;
    }

    const { activeModal, loginId } = this.props;
    const {
      Component,
      headline,
      image,
      rawLayout,
      text,
      styleOverride,
      title,
      type = ModalType.Default
    } = this.modalConfig;
    const styles = SmartModalStyles.get();

    if (rawLayout) {
      return <Component {...activeModal!.props} />;
    }

    return (
      <ModalContainer styleOverride={styleOverride}>
        {!!title && (
          <ModalHeader
            title={title}
            id={modalTitleId}
            onClose={this.dismissible ? this.props.hideModal : undefined}
          />
        )}
        {type !== ModalType.Default ? (
          <ModalContent headline={headline} image={image} text={text}>
            <Component {...activeModal!.props} />
            {type === ModalType.Error && !!loginId && (
              <Text selectable style={styles.loginId} testId="loginId">
                Support ID: {loginId}
              </Text>
            )}
          </ModalContent>
        ) : (
          <Component {...activeModal!.props} />
        )}
      </ModalContainer>
    );
  }

  public render() {
    const { activeModal, isAppInitialized } = this.props;

    return (
      <Modal
        AnchorComponent={this.modalConfig?.AnchorComponent}
        id={activeModal?.id}
        isError={this.modalConfig?.type === ModalType.Error}
        isVisible={!!activeModal}
        styleOverride={this.modalConfig?.styleOverride}
        suppressOpeningSfx={
          !isAppInitialized ||
          this.modalConfig?.getTeacherVO ||
          this.modalConfig?.suppressOpeningSfx
        }
        suppressClosingSfx={
          !isAppInitialized || this.modalConfig?.suppressOpeningSfx
        }
        onRequestClose={this.handleRequestClose}
        ariaLabelledBy={this.modalConfig?.title && modalTitleId}
        ariaLabel={!this.modalConfig?.title && this.modalConfig?.ariaLabel}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
function mapStateToProps(state: unknown) {
  return {
    activeModal: AppShellSelector.getActiveModal(state),
    isAppInitialized: BootstrappingSelector.isBootstrapped(state),
    loginId: AuthSelector.getLoginId(state)
  };
}

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  hideModal: () => AppShellAction.hideModal(),
  playScreenplay: (payload: IPlayActionPayload) =>
    ScreenplayAction.play(payload)
};

export const SmartModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartModalComponent);

export const SmartModalPrivates = {
  getYourTeacherScreenplay
};
