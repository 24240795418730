import { EnumUtils } from 'utils/EnumUtil';
import {
  FaceStyle,
  HairColor,
  HairStyle,
  SkinColor
} from 'services/profile/avatar.model';
import { IAvatarImages, AvatarType } from '../avatar.model';
import { IAvatarStyles } from '../Avatar.styles';
import { Image } from '../../image';
import { ProfileSelector } from 'services/profile';
import { REPO_IMG_DIR } from 'services/app-config/appConfig';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export interface IAvatarLayeredImagesProps {
  styles: IAvatarStyles;
  type: AvatarType;
}
export function AvatarLayeredImages(props: IAvatarLayeredImagesProps) {
  const { styles, type } = props;

  const [avatarImages, setAvatarImages] = useState<IAvatarImages | undefined>();

  const avatar = useSelector(ProfileSelector.getAvatarMaybe);
  const hasCreatedAvatar = useSelector(ProfileSelector.hasCreatedAvatar);

  const imageDir = `${REPO_IMG_DIR}/avatar/${type}`;

  useEffect(() => {
    if (hasCreatedAvatar) {
      const { faceStyle, hairColor, hairStyle, skinColor } = avatar!;

      const isHijabSelected = avatar!.hairStyle === HairStyle.Hijab;
      const hairStyleNum = EnumUtils.getOrdinalValue(HairStyle, hairStyle);
      const hairColorNum = EnumUtils.getOrdinalValue(HairColor, hairColor);
      const faceStyleNum = EnumUtils.getOrdinalValue(FaceStyle, faceStyle!);
      const skinColorNum = EnumUtils.getOrdinalValue(SkinColor, skinColor);

      const hasHairBack = hairStyleNum < 5;

      setAvatarImages({
        eyebrows: `${imageDir}/eyebrows/eyebrows-${faceStyleNum}-c${hairColorNum}-${type}.png`,
        face: `${imageDir}/face/face-${faceStyleNum}-c${skinColorNum}-${type}.png`,
        hairBack: isHijabSelected
          ? `${imageDir}/hijab/hijab-c${hairColorNum}-back-${type}.png`
          : hasHairBack
          ? `${imageDir}/hair/hair-${hairStyle}-c${hairColorNum}-back-${type}.png`
          : undefined,
        hairFront: isHijabSelected
          ? `${imageDir}/hijab/hijab-c${hairColorNum}-front-${type}.png`
          : `${imageDir}/hair/hair-${hairStyle}-c${hairColorNum}-${
              hasHairBack ? 'front-' : ''
            }${type}.png`,
        outfit: `${imageDir}/outfit/outfit-1-${type}.png`
      });
    }
  }, [avatar, hasCreatedAvatar, type, imageDir]);

  if (!avatarImages) {
    return null;
  }

  const imgs = avatarImages;

  return (
    <>
      {imgs.hairBack !== undefined && (
        <Image testId="hairBkImg" source={imgs.hairBack} style={styles.image} />
      )}
      <Image testId="faceImg" source={imgs.face} style={styles.image} />
      <Image testId="eyebrowImg" source={imgs.eyebrows} style={styles.image} />
      <Image testId="outfitImg" source={imgs.outfit} style={styles.image} />
      <Image testId="hairImg" source={imgs.hairFront} style={styles.topImage} />
    </>
  );
}
AvatarLayeredImages.displayName = 'AvatarLayeredImages';
