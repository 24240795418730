import {
  ISreSession,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { SreSessionActionType } from '../sre-redux.model';
import { SreSessionActions } from './SreSession.action';

export const sessionInitialState: ISreSession = {
  isCanceling: false,
  isListening: false,
  result: undefined,
  sessionType: SreSessionType.None
};

export function sreSessionReducer(
  state = sessionInitialState,
  action: SreSessionActions | UserGlobalActionLogoutSuccess
): ISreSession {
  switch (action.type) {
    case SreSessionActionType.Cancel:
      return {
        ...state,
        isCanceling: true,
        isListening: false,
        sessionType: SreSessionType.None
      };

    case SreSessionActionType.CancelSuccess:
      return {
        ...state,
        isCanceling: false
      };

    case SreSessionActionType.Listen:
      return {
        ...state,
        result: undefined,
        sessionType: action.payload.sessionType
      };

    case SreSessionActionType.PrimeSuccess:
    case SreSessionActionType.Listening:
      return {
        ...state,
        isListening: true
      };

    case SreSessionActionType.ListenResult:
      return {
        ...sessionInitialState,
        isListening: false,
        result: action.payload.result,
        sessionType: SreSessionType.None
      };

    case SreSessionActionType.Prime:
      return {
        ...state,
        result: undefined,
        sessionType: SreSessionType.PrimedLanguageFrame
      };

    case UserGlobalActionType.LogoutSuccess:
      return sessionInitialState;

    default:
      return state;
  }
}
