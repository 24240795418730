import {
  HomeReactAnimationName,
  HomeSceneAnimationName
} from 'feature-areas/home';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { MockMicListenScreenplayFactory } from 'shared-components/faux-mic-button';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { HomeScreenplayId } from './home-screenplay-builders.model';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Home+to+Level+Transition
 */
export class HomeOutroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'HomeOutroScreenplayBuilder';

  public static create(): HomeOutroScreenplayBuilder {
    return new HomeOutroScreenplayBuilder();
  }

  private constructor() {
    super(HomeScreenplayId.Outro);

    this.disableUtilityBar()
      .playMockMicListen()
      .fadeOutReactElements()
      .playSceneOutro();
  }

  private playMockMicListen(): HomeOutroScreenplayBuilder {
    this.builder.addScreenplay(
      MockMicListenScreenplayFactory.create().screenplay
    );

    return this;
  }

  private fadeOutReactElements(): HomeOutroScreenplayBuilder {
    this.builder.addReactAnimation(HomeReactAnimationName.FadeOut, {
      concurrent: true
    });

    return this;
  }

  private playSceneOutro(): HomeOutroScreenplayBuilder {
    this.builder.addStormAnimation({
      name: HomeSceneAnimationName.Root.Outro,
      targetScene: SceneName.Home
    });

    return this;
  }
}
