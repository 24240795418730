import {
  BootstrappingActionBootstrap,
  BootstrappingActionType
} from 'services/bootstrapping/redux';
import { ILoggingDeps } from '../logging-epic.model';
import { LexiaError } from '@lexialearning/utils';
import { Observable } from 'rxjs';
import { SreConfigActionType } from 'sre';
import { StormActionType } from 'storm';
import { TimingSet } from './TimingSet';
import { ignoreElements, tap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';

export function logStartupTimingsEpic(
  action$: Observable<BootstrappingActionBootstrap>,
  _state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(BootstrappingActionType.Bootstrap),
    tap(() => {
      const { appLoggerDependencies: helper } = deps;

      try {
        TimingSet.create('Startup', helper.logger)
          .addTiming(
            'Bootstrapping',
            { type: BootstrappingActionType.Bootstrap },
            { type: BootstrappingActionType.ConfigurationComplete }
          )
          .addTiming(
            'SRE',
            { type: SreConfigActionType.Configure },
            { type: SreConfigActionType.ConfigureSuccess }
          )
          .addTiming(
            'Storm',
            { type: StormActionType.Initialize },
            { type: StormActionType.InitializeSuccess }
          )
          .startMonitoring(action$ as any);
      } catch (err) {
        void helper.logger.logError(
          new LexiaError(
            `Error attempting to log the startup timing event: ${err.message}`,
            logStartupTimingsEpic.displayName,
            '2311'
          ).withCause(err)
        );
      }
    }),
    ignoreElements()
  );
}
logStartupTimingsEpic.displayName = 'logStartupTimingsEpic';
