import {
  ICompletion,
  IScreenplay,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { cloneDeep } from 'lodash';
import { RoundContext } from 'curriculum-services';
import { ArrayUtils } from 'utils';
import {
  ITaskRegistration,
  ModelSection,
  ScreenplayType,
  SolutionScreenplayBuilder,
  TaskRegistrationBuilder
} from '../core';
import { Completion } from './Completion';
import {
  ICompletionAnswer,
  ICompletionChoice
} from './completion-component.model';

export function createCompletionTaskRegistration(): ITaskRegistration<
  TaskTypeName.Completion,
  ICompletion<ICompletionChoice>,
  ICompletionAnswer
> {
  const base = TaskRegistrationBuilder.create<
    TaskTypeName.Completion,
    ICompletion<ICompletionChoice>
  >(TaskTypeName.Completion, Completion)
    .withScreenplayBuilder(ScreenplayType.Solution, createSolutionScreenplay)
    .setModeledFields(ModelSection.Presentation, ['text'])
    .withPrepareContent(prepareContent).registration;

  return {
    ...base,
    serializeAnswer
  };
}

function serializeAnswer(answer: ICompletionAnswer): string {
  return answer.choices?.length
    ? answer.choices.map(c => `${c.sysId}:${c.text}`).join('|')
    : 'no answer provided';
}

function createSolutionScreenplay(context: RoundContext): IScreenplay {
  return SolutionScreenplayBuilder.createFor(context).addDelay(3000).screenplay;
}

function prepareContent(content: ICompletion): ICompletion<ICompletionChoice> {
  const choices = content.choices.map(c => ({ ...c, answerSlotIdx: -1 }));

  return cloneDeep({
    ...content,
    choices: ArrayUtils.shuffleArray(choices, {
      ensureReorder: true
    })
  });
}
