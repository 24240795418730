import * as React from 'react';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { BrowserCard } from './BrowserCard';
import { IPlayActionPayload, ScreenplayAction } from 'screenplay/redux';
import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { MicBlockedAction } from './MicBlockedAction';
import { MicPermissionsHandler } from 'sre/helpers/MicPermissionsHandler';
import { ModalRegistry, ModalType } from 'shared-components/modals';
import { connect } from 'react-redux';

export interface IMicBlockedModalComponentProps {
  micBlockedVO: IScreenplay;
  playScreenplay(payload: IPlayActionPayload): void;
}

export class MicBlockedModalComponent extends React.PureComponent<IMicBlockedModalComponentProps> {
  public static readonly displayName = 'MicBlockedModal';

  private refreshInterval?: NodeJS.Timeout;

  public componentDidMount() {
    const { micBlockedVO, playScreenplay } = this.props;
    playScreenplay({ screenplay: micBlockedVO });

    if (!MicPermissionsHandler.isListeningForUpdates) {
      this.setupPermissionListener();
    }
  }

  public componentWillUnmount() {
    // istanbul ignore else - trivial
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  /**
   * Listener is defined here (instead of internal to MicPermissionsHandler)
   * because of 2 reasons:
   *  1. To catch ALL cases when mic blocked is detected (both initially or
   *     during any session later on)
   *  2. To stop listening as soon as mic is unblocked, otherwise it can lead to
   *     memory issues
   */
  private setupPermissionListener(): void {
    this.refreshInterval = setInterval(async () => {
      MicPermissionsHandler.readPermission();
    }, 2500);
  }

  public render() {
    return (
      <>
        <BrowserCard />
        <MicBlockedAction />
      </>
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
function mapStateToProps(state: unknown) {
  return {
    micBlockedVO:
      BootstrapContentSelector.getMicBlockedContent(state)!.voiceover
  };
}

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  playScreenplay: (payload: IPlayActionPayload) =>
    ScreenplayAction.play(payload)
};

export const MicBlockedModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(MicBlockedModalComponent);

MicBlockedModal.ModalId = ModalRegistry.register({
  Component: MicBlockedModal,
  headline: 'Lexia English requires access to your microphone.',
  text: 'You can enable your mic with these instructions.',
  title: 'Microphone has been blocked',
  type: ModalType.Error
});
