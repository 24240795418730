import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, Direction, Font, ShadowStyle } from 'common-styles';
import { Types } from '../../../types';
import { KeyNavHelper } from 'common-ui/helpers';
import {
  ActiveComponentState,
  Border,
  Position
} from '@lexialearning/common-ui';

export enum TextButtonType {
  Primary,
  Secondary
}

export interface ITextButtonStyleOverride {
  buttonText?: Types.TextStyle;
  button?: Types.ViewStyle;
}

export interface ITextButtonStyle {
  buttonText: Types.TextStyle;
  button: Types.ViewStyle;
  iconContainer: Types.ViewStyle;
}

export class TextButtonStyles {
  private static getBackgroundColor(
    buttonType: TextButtonType,
    isDisabled: boolean
  ): string {
    if (buttonType === TextButtonType.Primary) {
      return isDisabled ? Color.Gray50 : Color.LexiaPurple;
    }

    return isDisabled ? Color.BlackTransparent04 : Color.NearWhite;
  }

  private static getTextColor(buttonType: TextButtonType): string {
    return buttonType === TextButtonType.Secondary
      ? Color.Gray70
      : Color.NearWhite;
  }

  private static buildStyles(
    buttonType: TextButtonType,
    isDisabled: boolean,
    hasIcon: boolean
  ): ITextButtonStyle {
    return {
      button: {
        alignItems: Align.Center,
        backgroundColor: TextButtonStyles.getBackgroundColor(
          buttonType,
          isDisabled
        ),
        borderColor: Color.Transparent,
        borderRadius: 8,
        borderStyle: Border.Solid,
        borderWidth: 5,
        flexDirection: Direction.Row,
        marginHorizontal: 15,
        marginVertical: 5,
        minWidth: 160,
        paddingLeft: hasIcon ? 35 : 11,
        paddingRight: 11,
        paddingVertical: 5,
        position: Position.Relative,
        ...ShadowStyle.button
      },
      buttonText: {
        color: TextButtonStyles.getTextColor(buttonType),
        flex: 1,
        fontSize: Font.Size.large
      },
      iconContainer: {
        left: 5,
        position: Position.Absolute
      }
    };
  }

  private static buildKeyNavStylesMaybe(
    activeComponentState: ActiveComponentState,
    isDisabled: boolean
  ) {
    if (!KeyNavHelper.isKeyNav(activeComponentState)) {
      return;
    }

    const backgroundColor = isDisabled
      ? Color.Gray50
      : Color.getSetFor(activeComponentState).background;
    const borderColor = Color.getSetFor(activeComponentState).border;
    const textColor = isDisabled ? Color.Gray30 : Color.Text;

    return {
      button: {
        backgroundColor,
        borderColor,
        borderWidth: 5
      },
      buttonText: {
        color: textColor
      }
    };
  }

  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      buttonType: TextButtonType,
      isDisabled: boolean,
      hasIcon: boolean,
      styleOverride?: ITextButtonStyleOverride
    ) => {
      const defaultStyles = TextButtonStyles.buildStyles(
        buttonType,
        isDisabled,
        hasIcon
      );
      const keyNavStylesMaybe = TextButtonStyles.buildKeyNavStylesMaybe(
        activeComponentState,
        isDisabled
      );

      return merge(
        {},
        defaultStyles,
        styleOverride,
        // Keynav styles need to override styleOverrides
        keyNavStylesMaybe
      );
    }
  );
}
