import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ScreenplayActionResume, ScreenplayActionType } from '../redux';
import { IScreenplayDeps } from './screenplay-epic.model';

/**
 * Resume any paused screenplay playbacks.
 */
export function resumeScreenplayEpic(
  action$: Observable<ScreenplayActionResume>,
  _: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<any> {
  return action$.pipe(
    ofType(ScreenplayActionType.Resume),
    tap(() => {
      deps.screenplayer.resume();
    }),
    ignoreElements()
  );
}
resumeScreenplayEpic.displayName = 'resumeScreenplayEpic';
