import { Border, Position } from '@lexialearning/common-ui';
import { Align, Color, Font, Justify } from 'common-styles';
import {
  IPageAnimatedValues,
  IPageAnimations,
  PageAnimatedStyles
} from 'shared-components/Page.animated-styles';

export class HomePageAnimatedStyles {
  public static dashboardBackgroundColor = '#F7D48E';

  private readonly styles: any = {
    dashboardAnimated: {},
    dashboard: {
      position: Position.Absolute,
      top: 90,
      justifyContent: Justify.Start,
      alignItems: Align.Center,
      alignSelf: Align.Center,
      borderColor: Color.White,
      borderRadius: 16,
      borderStyle: Border.Solid,
      borderWidth: 2,
      height: 175,
      width: 560,
      backgroundColor: HomePageAnimatedStyles.dashboardBackgroundColor
    },
    mic: {
      margin: 5
    },
    progressNumber: {
      color: Color.Text,
      fontFamily: Font.Family.semiBold,
      fontSize: 65
    },
    progressNumberContainer: {
      backgroundColor: Color.NearWhiteTransparent30,
      borderColor: Color.NearWhite,
      borderRadius: 48,
      borderStyle: Border.Dashed,
      borderWidth: 3,
      height: 94,
      margin: 5,
      minWidth: 94
    },
    progressText: {
      fontFamily: Font.Family.semiBold,
      fontSize: 20
    },
    section: {
      justifyContent: Justify.Start,
      width: 188
    },
    sectionText: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small
    },
    separator: {
      backgroundColor: Color.NearWhite,
      height: 140,
      width: 2
    }
  };

  private readonly animatedValues: IPageAnimatedValues;

  private readonly animations: IPageAnimations;

  constructor() {
    this.animatedValues = PageAnimatedStyles.createEntryExitAnimatedValues();
    this.animations = PageAnimatedStyles.createEntryExitAnimations(
      this.animatedValues
    );
    this.styles.dashboardAnimated.opacity = this.animatedValues.opacity;
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
