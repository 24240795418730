import { SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';

export class UnitCompleteSceneDefinitionBuilder {
  public static create(scenePath: string): UnitCompleteSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.UnitComplete,
      scenePath
    );

    return new UnitCompleteSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}
}
