/* eslint-disable max-classes-per-file */
import {
  CHARACTER_SET_NUMBERS,
  CharacterSetNumber,
  IAnimationDefinition,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { EncounterSceneElementName } from 'feature-areas/encounters/encounter-scene/encounter-scene.model';
import { LevelSceneElementName } from 'feature-areas/levels/level-scene/level-scene.model';
import { PlacementSceneElementName } from 'feature-areas/placement/placement-scene/placement-scene.model';
import {
  AnimationDefinitions,
  IAnimationDefinitionQuery,
  StormService
} from 'storm';
import { ILoboStormAssetFolders } from './lobo-storm-config.model';

export class CharacterPathGroup {
  private readonly paths: string[];

  /**
   *
   * @param pathsArray - Using deconstructed items in argument array, rather than a) just a single pathsArray argument
   * in order to be more explicit about the length of array needed here and the order the items should be in, or b)
   * just using individual arguments in order to facilitate easy instantiation using .map
   */
  constructor([path1, path2, path3]: string[]) {
    this.paths = [path1, path2, path3];
  }

  public get(setNumber: CharacterSetNumber = 1): string {
    return this.paths[setNumber - 1];
  }
}

export enum CharacterName {
  Abshir = 'Abshir',
  Annie = 'Annie',
  Esther = 'Esther',
  Farah = 'Farah',
  Gabriela = 'Gabriela',
  Isko = 'Isko',
  Javier = 'Javier',
  Juan = 'Juan',
  Klaus = 'Klaus',
  Lela = 'Lela',
  Mei = 'Mei',
  Nadia = 'Nadia',
  Placeholder = 'Placeholder',
  Rami = 'Rami',
  Sarika = 'Sarika',
  Sasha = 'Sasha',
  Ximena = 'Ximena',
  Yi = 'Yi'
}

export const TaskDemoType = [
  TaskTypeName.Completion,
  TaskTypeName.TextOrdering
];
export type TaskDemoType = (typeof TaskDemoType)[0];

export class StormAssets {
  public static readonly displayName = 'StormAssets';

  public static Backgrounds = '';

  public static BackgroundOverlays = {
    Stars: ''
  };

  /**
   * Paths to character sg files
   */
  public static readonly Character: { [key: string]: CharacterPathGroup } = {};

  public static Folders: ILoboStormAssetFolders;

  public static readonly Scene = {
    Encounter: '',
    Placement: ''
  };

  public static Level: ILevelStormAssets = {
    CharacterAnimations: []
  };

  public static Encounter: ICharacterSceneStormAssets = {
    CharacterAnimations: []
  };

  public static Placement: ICharacterSceneStormAssets = {
    CharacterAnimations: []
  };

  public static Unit: IUnitStormAssets = {
    Rewards: []
  };

  public static TaskDemo = {};

  public static async init(stormService: StormService): Promise<void> {
    StormAssets.Folders = stormService.getAssetPaths<ILoboStormAssetFolders>();

    StormAssets.validateSceneAssetFolders();
    StormAssets.Backgrounds = StormAssets.Folders.Backgrounds.path;
    StormAssets.BackgroundOverlays.Stars = `${StormAssets.Folders.BackgroundOverlays.path}/stars/stars.sg`;

    StormAssets.setCharacterPaths();
    StormAssets.setUnitRewardAssets();
    StormAssets.setTaskDemoAssets();

    StormAssets.Scene.Encounter = `${StormAssets.Folders.Encounter.path}/encounter_root.sg`;
    StormAssets.Scene.Placement = `${StormAssets.Folders.Placement.path}/placement_root.sg`;

    await stormService.initialization;

    StormAssets.setLevelAssets(stormService.animationDefinitions);
    StormAssets.setEncounterAssets(stormService.animationDefinitions);
    StormAssets.setPlacementAssets(stormService.animationDefinitions);
  }

  private static validateSceneAssetFolders(): void {
    const sceneFoldersNames: (keyof ILoboStormAssetFolders)[] = [
      'Backgrounds',
      'BackgroundOverlays',
      'Calibration',
      'Characters',
      'CharacterAnimations',
      'Encounter',
      'Home',
      'Introduction',
      'Level',
      'Placement',
      'UnitComplete',
      'TaskDemo'
    ];

    const missing = sceneFoldersNames.filter(f => !StormAssets.Folders[f]);
    if (missing.length) {
      throw new LexiaError(
        `The following storm asset folders are missing from the config: ${missing.join(
          ','
        )}`,
        StormAssets.displayName,
        StormAssetsError.MissingAssetPaths
      );
    }

    sceneFoldersNames.forEach((folder: keyof ILoboStormAssetFolders) => {
      const path = `${StormAssets.Folders[folder].path}`;
      const prefix = path.startsWith('/scenes') ? '' : '/scenes/';
      StormAssets.Folders[folder].path = `${prefix}${path}`;
    });
  }

  private static setCharacterPaths(): void {
    Object.values(CharacterName).map(c => {
      const name = c.toLocaleLowerCase() as string;
      StormAssets.Character[c] = new CharacterPathGroup(
        CHARACTER_SET_NUMBERS.map((setNumber: CharacterSetNumber) =>
          this.getCharacterPath(name, setNumber)
        )
      );
    });
  }

  private static getCharacterPath(
    charName: string,
    setNumb: CharacterSetNumber
  ): string {
    return `${StormAssets.Folders.Characters.path}/${charName}/0${setNumb}/lod-0/${charName}.sg`;
  }

  private static setEncounterAssets(animations: AnimationDefinitions): void {
    this.setCharacterAnimations(
      SceneName.Encounter,
      StormAssets.Encounter,
      EncounterSceneElementName.Character,
      animations
    );
  }

  private static setPlacementAssets(animations: AnimationDefinitions): void {
    this.setCharacterAnimations(
      SceneName.Placement,
      StormAssets.Placement,
      PlacementSceneElementName.Character,
      animations
    );
  }

  private static setCharacterAnimations(
    scene: SceneName,
    sceneAssets: ICharacterSceneStormAssets,
    characterElement: string,
    animations: AnimationDefinitions
  ): void {
    const query: IAnimationDefinitionQuery = {
      element: characterElement,
      scene
    };

    sceneAssets.CharacterAnimations = animations
      .select(query)
      .map(definition => ({
        ...definition,
        path: `${StormAssets.Folders.CharacterAnimations.path}/${definition.name}.sga`
      }));
  }

  private static setUnitRewardAssets(): void {
    StormAssets.Unit.Rewards = (
      StormAssets.Folders.UnitComplete.items || []
    ).map(r => `${StormAssets.Folders.UnitComplete.path}/${r}.sg`);
  }

  private static setLevelAssets(animations: AnimationDefinitions): void {
    const query: IAnimationDefinitionQuery = { scene: SceneName.Level };

    StormAssets.Level.CharacterAnimations = animations
      .select({ ...query, element: LevelSceneElementName.buildCharacter(1) })
      .map(ad => ({
        ...ad,
        path: `${StormAssets.Folders.CharacterAnimations.path}/${ad.name}.sga`
      }));
  }

  private static setTaskDemoAssets(): void {
    const taskDemos = TaskDemoType.map(taskType => {
      const lowercaseDashName = taskType
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase();
      const lowercaseUnderscoreName = taskType
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();

      return {
        [taskType]: `${StormAssets.Folders.TaskDemo.path}/${lowercaseDashName}/demo_${lowercaseUnderscoreName}.sg`
      };
    });

    StormAssets.TaskDemo = Object.assign({}, ...taskDemos);
  }
}

export enum SceneName {
  Calibration = 'Calibration',
  Encounter = 'Encounter',
  EncounterComplete = 'EncounterComplete',
  Home = 'Home',
  Introduction = 'Introduction',
  Level = 'Level',
  LevelComplete = 'LevelComplete',
  LevelIntro = 'LevelIntro',
  Placement = 'Placement',
  ProgramComplete = 'ProgramComplete',
  UnitComplete = 'UnitComplete',
  TaskDemo = 'TaskDemo'
}

export enum StormAssetsError {
  MissingMountPoints = 'MissingMountPoints',
  MissingAssetPaths = 'MissingAssetPaths'
}

export interface ILevelStormAssets {
  CharacterAnimations: IAnimationDefinition[];
}

export interface ICharacterSceneStormAssets {
  CharacterAnimations: IAnimationDefinition[];
}

export interface IUnitStormAssets {
  Rewards: string[];
}
