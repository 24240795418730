import { Border } from '@lexialearning/common-ui';
import { Color, Font, TextAlign } from 'common-styles';

const DROPDOWN_WIDTH = 100;
const ROW_HEIGHT = 30;

export class DevToolsDropdownStyles {
  private static readonly styles = {
    container: {
      width: DROPDOWN_WIDTH
    },
    input: {
      borderColor: Color.Gray30,
      borderStyle: Border.Solid,
      borderWidth: 1,
      height: ROW_HEIGHT,
      padding: 5,
      shadowOffset: { height: 0, width: 0 },
      shadowRadius: 2
    },
    item: {
      fontSize: Font.Size.xSmall,
      paddingHorizontal: 3
    },
    label: {
      fontSize: Font.Size.xSmall,
      marginRight: 10,
      marginVertical: 10,
      textAlign: TextAlign.Left
    },
    list: {
      width: DROPDOWN_WIDTH
    },
    row: {
      height: ROW_HEIGHT
    },
    value: {
      fontSize: 13,
      lineHeight: 17
    }
  };

  public static get() {
    return this.styles;
  }
}
