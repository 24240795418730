import { ActiveProgramContextSummary } from './active-program-context-summary/ActiveProgramContextSummary';
import { AvatarShowcase } from './avatar-showcase/AvatarShowcase';
import { Column, Row } from 'common-ui/components/layout';
import { HideUserInfoButton } from 'feature-areas/shell/utility-bar/user-info-button/HideUserInfoButton';
import { LevelCertificateButtons } from './level-certificate-buttons/LevelCertificateButtons';
import { LogoutWithConfirmationButton } from 'feature-areas/logout-confirmation';
import { MicInfo } from './mic-info/MicInfo';
import { ModalRegistry } from 'shared-components/modals';
import { ProfileSelector } from 'services/profile';
import { StudentInfoModalStyles } from './StudentInfoModal.styles';
import { StudentPropertyAction } from '@lexialearning/student-api';
import { TextButtonType, useDidMount } from 'common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import {
  LevelsCompletedSelector,
  LevelStatus,
  LoadStatus
} from 'services/curriculum-services';

export function StudentInfoModal() {
  const isStudent = useSelector(ProfileSelector.isStudent);
  const levelsCompletedLoadStatus = useSelector(
    LevelsCompletedSelector.getLoadStatus
  );
  const levelsCompletedStatuses = useSelector(
    LevelsCompletedSelector.getLevelsStatus
  );

  const dispatch = useDispatch();

  // This value should only be set once, on load
  const shouldFocusMic = useRef(
    !isStudent ||
      levelsCompletedStatuses.every(s => s.status !== LevelStatus.Completed)
  );

  const styles = StudentInfoModalStyles.get();

  useDidMount(() => {
    if (isStudent && levelsCompletedLoadStatus === LoadStatus.NotLoaded) {
      // load completed levels
      dispatch(StudentPropertyAction.load.request());
    }
  });

  return (
    <Row testId={StudentInfoModal.displayName}>
      {isStudent && <AvatarShowcase />}
      <Column style={styles.content}>
        <ActiveProgramContextSummary />
        {isStudent && (
          <LevelCertificateButtons
            autoFocus={!shouldFocusMic.current}
            levelStatuses={levelsCompletedStatuses}
          />
        )}
        <MicInfo autoFocus={shouldFocusMic.current} />
        <LogoutWithConfirmationButton
          buttonType={TextButtonType.Secondary}
          styleOverride={styles.logoutButtonOverrides}
        />
      </Column>
    </Row>
  );
}

StudentInfoModal.displayName = 'StudentInfoModal';

StudentInfoModal.ModalId = ModalRegistry.register({
  AnchorComponent: HideUserInfoButton,
  Component: StudentInfoModal,
  ariaLabel: 'Student Info',
  styleOverride: StudentInfoModalStyles.getModalOverride()
});
