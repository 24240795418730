import { ILevelEducator } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PositionActionLevelActivitySelected } from 'curriculum-services/program-context/redux';
import {
  IActivityPosition,
  LevelAction,
  LevelActionMountVoiceovers,
  PositionAction,
  ProgramPositionFactory
} from 'curriculum-services';
import { EducatorActionType, EducatorSelector } from '../redux';
import { EducatorActionSelectLevelActivity } from '../redux/Educator.action';

export function selectLevelActivityEpic(
  action$: Observable<EducatorActionSelectLevelActivity>,
  state$: StateObservable<unknown>
): Observable<
  PositionActionLevelActivitySelected | LevelActionMountVoiceovers
> {
  return action$.pipe(
    ofType(EducatorActionType.SelectLevelActivity),
    mergeMap(action => {
      const { levelId } = action.payload;
      const levels = EducatorSelector.getLevels(state$.value);
      const level = levels.find(l => l.sysId === levelId);

      if (!level) {
        throw new LexiaError(
          `Unable to find level "${levelId}"`,
          selectLevelActivityEpic.displayName,
          SelectLevelActivityError.LevelMissing
        ).withContext({ levelId, levels });
      }

      const levelPosition = ProgramPositionFactory.createLevelPosition(level);

      const { activityId } = action.payload;
      validateActivityId(activityId, levelPosition.activityPositions, level);
      levelPosition.activeActivityId = activityId;

      return [
        PositionAction.levelActivitySelected(levelPosition),
        LevelAction.mountVoiceovers.request({ levelTitle: level.title })
      ];
    })
  );
}
selectLevelActivityEpic.displayName = 'selectLevelActivityEpic';

function validateActivityId(
  activityId: string,
  activityPositions: IActivityPosition[],
  level: ILevelEducator
): void {
  const activity = activityPositions.find(a => a.activityId === activityId);

  if (!activity) {
    throw new LexiaError(
      `Unable to find activity "${activityId}"`,
      selectLevelActivityEpic.displayName,
      SelectLevelActivityError.ActivityMissing
    ).withContext({ activityId, level });
  }
}

export enum SelectLevelActivityError {
  ActivityMissing = 'ActivityMissing',
  LevelMissing = 'LevelMissing'
}
