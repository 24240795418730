import { FlexWrap } from '@lexialearning/common-ui';
import { Align, Justify } from 'common-styles';

export class UnitPageStyles {
  private static readonly styles = {
    buttonRow: {
      flexWrap: FlexWrap.Wrap
    },
    column: { flex: 1 },
    container: {
      alignItems: Align.Stretch,
      flex: 1,
      justifyContent: Justify.Start
    }
  };

  public static get() {
    return this.styles;
  }
}
