import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import login_conflictPng from './login_conflict.png';

export class LoginConflictModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    getTeacherVO: true,
    headline: 'Login Conflict!',
    id: 'LoginConflictModal',
    image: login_conflictPng,
    text: 'Someone has logged in using your username.',
    title: 'Oops!',
    type: ModalType.Error
  });
}
