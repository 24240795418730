import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { Sfx } from 'audio';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';

export function createNeutralCorrectFeedbackScreenplay(
  context: RoundContext
): IScreenplay {
  const { round } = context;

  return ScreenplayBuilder.create(`Feedback.${context.getTask().taskType}`)
    .addSfx(Sfx.Neutral)
    .addScreenplay(round.feedback.correct)
    .addDelay(round.feedback.correct ? 1000 : 500).screenplay;
}
