import { EffectCallback, useEffect } from 'react';

export const useDidMount = (effect: EffectCallback): void => {
  useEffect(
    () => effect(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
useDidMount.displayName = 'useDidMount';
