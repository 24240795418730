import { useDispatch } from 'react-redux';
import { Color } from 'common-styles';
import {
  ITextButtonStyleOverride,
  TextButton,
  TextButtonType
} from 'common-ui/components/button/text-button';
import { AppShellAction } from 'shell/redux';
import { LogoutWithConfirmationButtonStyles } from './LogoutWithConfirmationButtonStyles';
import { LogoutIconSvg } from 'common-ui';

export interface ILogoutWithConfirmationButtonProps {
  autoFocus?: boolean;
  buttonType?: TextButtonType;
  styleOverride?: ITextButtonStyleOverride;
}

export function LogoutWithConfirmationButton(
  props: ILogoutWithConfirmationButtonProps
) {
  const {
    autoFocus,
    buttonType = TextButtonType.Primary,
    styleOverride
  } = props;
  const iconColor =
    buttonType === TextButtonType.Secondary ? Color.Clementine : undefined;
  const dispatch = useDispatch();

  return (
    <TextButton
      autoFocus={autoFocus}
      Icon={LogoutIconSvg}
      onPress={() => dispatch(AppShellAction.requestLogout())}
      styleOverride={LogoutWithConfirmationButtonStyles.build(styleOverride)}
      text="Log Out"
      iconColor={iconColor}
      testId={LogoutWithConfirmationButton.displayName}
      buttonType={buttonType}
    />
  );
}
LogoutWithConfirmationButton.displayName = 'LogoutWithConfirmationButton';
