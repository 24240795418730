import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { LevelSelector } from 'curriculum-services';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { LevelIntroScene } from '../LevelIntroScene';
import { LevelIntroSceneDefinitionBuilder } from '../LevelIntroSceneDefinitionBuilder';
import {
  LevelIntroSceneActionType,
  LevelIntroScenePrepareAction
} from '../redux';

export interface IPrepareLevelIntroSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareLevelIntroSceneEpic(
  action$: Observable<LevelIntroScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareLevelIntroSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(LevelIntroSceneActionType.Prepare),
    switchMap(async action => {
      const { preparedScenes, stormService } = deps;
      preparedScenes.levelIntro = undefined;

      const level = LevelSelector.getLevel(state$.value).title;
      const builder = LevelIntroSceneDefinitionBuilder.create(level)
        .withLevelBackground()
        .withBackgroundOverlay();

      const { isLevelToLevel } = action.payload;
      if (isLevelToLevel) {
        builder.withPriorLevelBackground();
      }

      await stormService.initialization;
      const scene = await stormService.prepareScene(builder.definition);

      const levelIntro = new LevelIntroScene(
        scene,
        stormService,
        level,
        isLevelToLevel
      );
      preparedScenes.levelIntro = levelIntro;
    }),
    ignoreElements()
  );
}
prepareLevelIntroSceneEpic.displayName = 'prepareLevelIntroSceneEpic';
