import * as React from 'react';
import { AppState } from 'services';
import { ISreConfig, SreSelector } from 'sre';
import { InteractionState } from 'common-styles';
import { LexiaError } from '@lexialearning/utils';
import { MicButton, MicSelector } from 'common-ui';
import { SeeSpeakAction } from '../redux/SeeSpeak.action';
import { TaskPhase } from '@lexialearning/lobo-common';
import { TaskSelector } from 'task-components';
import { compose } from 'redux';
import { connect, ConnectedComponent } from 'react-redux';

export interface ISreMicButtonProps {
  disabled?: boolean;
  onPress?: () => void;
}

export interface ISreMicButtonReduxProps {
  interactionState: InteractionState;
  isSreInitialized: boolean;
  /** ⬇ Only for error logging, while trouble shooting error listening: 4106 error */
  sreConfig?: ISreConfig;
  taskPhase: TaskPhase;
  startListening(): void;
}

export class SreMicButtonComponent extends React.PureComponent<
  ISreMicButtonProps & ISreMicButtonReduxProps
> {
  public static readonly displayName = 'SreMicButton';

  private static readonly sreInitializedTimeoutMs = 5000;

  private sreInitializedTimeout: NodeJS.Timeout | undefined;

  constructor(props: ISreMicButtonProps & ISreMicButtonReduxProps) {
    super(props);

    this.handleMicPress = this.handleMicPress.bind(this);
  }

  public componentDidUpdate(
    prevProps: ISreMicButtonProps & ISreMicButtonReduxProps
  ) {
    const { isSreInitialized, taskPhase } = this.props;
    const { isSreInitialized: wasSreInitialized, taskPhase: prevTaskPhase } =
      prevProps;
    const wasTaskInteractive = prevTaskPhase === TaskPhase.Interactive;
    const isTaskInteractive = taskPhase === TaskPhase.Interactive;

    this.setSreInitializedTimeoutMaybe(
      wasTaskInteractive,
      isTaskInteractive,
      isSreInitialized
    );
    this.clearSreInitializedTimeoutMaybe(
      wasTaskInteractive,
      isTaskInteractive,
      wasSreInitialized,
      isSreInitialized
    );
  }

  private handleMicPress(): void {
    this.props.onPress?.();
    this.props.startListening();
  }

  private setSreInitializedTimeoutMaybe(
    wasTaskInteractive: boolean,
    isTaskInteractive: boolean,
    isSreInitialized: boolean
  ): void {
    const becameInteractive = !wasTaskInteractive && isTaskInteractive;

    if (becameInteractive && !isSreInitialized) {
      this.sreInitializedTimeout = setTimeout(() => {
        clearTimeout(this.sreInitializedTimeout);
        this.throwTimeoutError();
      }, SreMicButtonComponent.sreInitializedTimeoutMs);
    }
  }

  private throwTimeoutError() {
    const { sreConfig } = this.props;
    throw new LexiaError(
      'Timed out awaiting sreService initialized in interactive phase',
      SreMicButtonComponent.displayName,
      SreMicButtonError.sreInitializedTimeout
    ).withContext({ sreConfig });
  }

  private clearSreInitializedTimeoutMaybe(
    wasTaskInteractive: boolean,
    isTaskInteractive: boolean,
    wasSreInitialized: boolean,
    isSreInitialized: boolean
  ): void {
    if (this.sreInitializedTimeout) {
      const becameNonInteractive = wasTaskInteractive && !isTaskInteractive;
      const becameInitialized = !wasSreInitialized && isSreInitialized;
      if (becameNonInteractive || becameInitialized) {
        clearTimeout(this.sreInitializedTimeout);
      }
    }
  }

  public render() {
    const { disabled, interactionState } = this.props;

    return (
      <MicButton
        disabled={disabled}
        interactionState={interactionState}
        onPress={this.handleMicPress}
      />
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: AppState) {
  return {
    interactionState: MicSelector.getInteractionState(state),
    isSreInitialized: SreSelector.getIsInitialized(state),
    sreConfig: SreSelector.getConfig(state),
    taskPhase: TaskSelector.getPhase(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  startListening: () => SeeSpeakAction.toggleListen()
};

export const SreMicButton = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SreMicButtonComponent) as ConnectedComponent<
  typeof SreMicButtonComponent,
  ISreMicButtonProps
>;

export enum SreMicButtonError {
  sreInitializedTimeout = 'sreInitializedTimeout'
}
