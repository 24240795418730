import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MicBlockedModal } from 'feature-areas/errors/mic-blocked';
import {
  SreConfigActionType,
  SreConfigureSuccessAction,
  SreSelector,
  SreSetMicBlockedAction
} from 'sre/redux';
import { PredicateUtils } from 'utils/PredicateUtils';
import {
  AppShellAction,
  AppShellActionHideModal,
  AppShellActionShowModal
} from '../../AppShell.action';
import { AppShellSelector } from '../../AppShell.selector';
import { awaitAppInteractive } from '../awaitAppInteractive';

export function micBlockedModalEpic(
  action$: Observable<SreConfigureSuccessAction | SreSetMicBlockedAction>,
  state$: StateObservable<unknown>
): Observable<AppShellActionShowModal | AppShellActionHideModal> {
  return action$.pipe(
    ofType(
      SreConfigActionType.ConfigureSuccess,
      SreConfigActionType.SetMicBlocked
    ),
    awaitAppInteractive(state$),
    map(() => {
      const micBlocked = SreSelector.getMicBlocked(state$.value);
      const activeModalId = AppShellSelector.getActiveModal(state$.value)?.id;
      const alreadyOpen = activeModalId === MicBlockedModal.ModalId;

      if (micBlocked && !alreadyOpen) {
        return AppShellAction.showModal({ id: MicBlockedModal.ModalId });
      }

      if (alreadyOpen && !micBlocked) {
        return AppShellAction.hideModal();
      }
    }),
    filter(PredicateUtils.isDefined)
  );
}
micBlockedModalEpic.displayName = 'micBlockedModalEpic';
