import {
  IPositionChange,
  ITask,
  IUnit,
  PositionChangeType,
  UnitType
} from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileSelector } from 'services/profile';
import { PredicateUtils } from 'utils/PredicateUtils';
import { ICurriculumDependencies } from '../../../CurriculumDependencies';
import {
  PositionActionLevelUpPositionPrepared,
  PositionActionType,
  ProgramContextSelector,
  SavePointAction,
  SavePointActionRoundAdded,
  SavePointActionSavePosition,
  SavePointActionType
} from '../../redux';

export type MaybeRequestSaveActions =
  | SavePointActionRoundAdded
  | PositionActionLevelUpPositionPrepared;

/**
 * Determine whether a save to the API is required, or just a keep-alive
 * (or neither for non-students).
 */
export function maybeRequestSaveEpic(
  action$: Observable<MaybeRequestSaveActions>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<SavePointActionSavePosition> {
  return action$.pipe(
    ofType(
      SavePointActionType.RoundAdded,
      PositionActionType.LevelUpPositionPrepared
    ),
    filter(() => ProfileSelector.isStudentLoggedIn(state$.value)),
    map(action => {
      const { curriculumDependencies } = deps;
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const { imminentPosition, parentUnit } = context;
      const task = context.getTask();

      if (shouldSave(task, imminentPosition, parentUnit, action)) {
        return SavePointAction.savePosition.request({
          unitId: parentUnit.sysId
        });
      }

      curriculumDependencies.studentProgressApi.keepAlive();
    }),
    filter(PredicateUtils.isDefined)
  );
}
maybeRequestSaveEpic.displayName = 'maybeRequestSaveEpic';

/**
 * Save when
 *  - a level-up position has been prepared or
 *  - a PoK or Placement non-supporting round has completed or
 *  - a PoK unit is getting recycled or
 *  - we are moving to a different unit/subunit or
 *  - we have completed a unit/encounter/activity
 *
 * Do not save in response to the RoundAdded action when completing a level or
 * placement. Wait instead for the LevelUpPositionPrepared action that will
 * soon be dispatched, so that both level completion and level advance are
 * atomically saved.
 */
function shouldSave(
  task: ITask,
  imminentPosition: IPositionChange,
  parentUnit: IUnit,
  action: MaybeRequestSaveActions
): boolean {
  if (action.type === PositionActionType.LevelUpPositionPrepared) {
    return true;
  }

  const { changeType } = imminentPosition;
  if (
    [
      PositionChangeType.LevelCompletion,
      PositionChangeType.PlacementCompletion
    ].includes(changeType)
  ) {
    return false;
  }

  const isScoredPoKOrPlacement =
    task.isScored &&
    [UnitType.Placement, UnitType.PresentationOfKnowledge].includes(
      parentUnit.type
    );

  return (
    isScoredPoKOrPlacement || changeType !== PositionChangeType.RoundCompletion
  );
}
