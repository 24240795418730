import memoizeOne from 'memoize-one';
import { Color, Font, TabType } from 'common-styles';
import { LoginFormSharedStyles } from '../../LoginFormShared.styles';
import { ActiveComponentState, Border } from '@lexialearning/common-ui';
import { KeyNavHelper } from 'common-ui';

export class LoginTabButtonStyles {
  private static readonly buttonStyle = {
    backgroundColor: Color.NearWhiteTransparent80,
    borderColor: Color.NearWhite,
    borderRadius: 8,
    borderStyle: Border.Solid,
    minWidth: LoginFormSharedStyles.FormWidth / 2,
    paddingHorizontal: 6,
    paddingVertical: 7,

    /** Need to define these all separately in order to be able to override
     *  them separately for tabType (initial render with overrides is fine
     *  without this, but the rerender errors our without this)
     */

    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderTopWidth: 2
  };

  private static readonly buttonLeftStyle = {
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    paddingRight: 8
  };

  private static readonly buttonRightStyle = {
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    paddingLeft: 8
  };

  private static readonly textStyle = {
    fontSize: Font.Size.large
  };

  private static readonly styles = {
    button: {
      default: LoginTabButtonStyles.buttonStyle,
      disabled: {
        ...LoginTabButtonStyles.buttonStyle,
        backgroundColor: Color.Gray50
      },
      hovered: {
        ...LoginTabButtonStyles.buttonStyle,
        backgroundColor: Color.NearWhite
      },
      keyboardFocused: {
        ...LoginTabButtonStyles.buttonStyle,
        backgroundColor: Color.getSetFor(ActiveComponentState.KeyboardFocused)
          .background,
        borderColor: Color.getSetFor(ActiveComponentState.KeyboardFocused)
          .border,
        paddingLeft: 6,
        paddingRight: 6,
        paddingVertical: 4,

        /** Need to define these all separately in order to be able to override
         * them separately for tabType (initial render with overrides is fine
         * without this, but the rerender errors our without this)
         */

        borderBottomWidth: 5,
        borderLeftWidth: 5,
        borderRightWidth: 5,
        borderTopWidth: 5
      },
      selected: {
        ...LoginTabButtonStyles.buttonStyle,
        backgroundColor: Color.LexiaPurple
      }
    },
    text: {
      default: LoginTabButtonStyles.textStyle,
      keyNav: {
        ...LoginTabButtonStyles.textStyle,
        color: Color.Black
      },
      selected: {
        ...LoginTabButtonStyles.textStyle,
        color: Color.TextOpposite
      }
    }
  };

  private static getButtonStylesForTabType(
    activeComponentState: ActiveComponentState,
    isDisabled: boolean,
    isSelected: boolean,
    tabType: TabType
  ) {
    const buttonStyles = this.styles.button;

    const defaultButtonStyle =
      activeComponentState === ActiveComponentState.Hovered
        ? buttonStyles.hovered
        : isDisabled && isSelected
        ? buttonStyles.disabled
        : isSelected
        ? buttonStyles.selected
        : buttonStyles.default;

    const buttonTypeStyle =
      tabType === TabType.Left ? this.buttonLeftStyle : this.buttonRightStyle;
    const finalStyle = {
      ...defaultButtonStyle,
      ...buttonTypeStyle,
      ...(KeyNavHelper.isKeyNav(activeComponentState) &&
        buttonStyles.keyboardFocused)
    };

    return finalStyle;
  }

  private static getTextStyles(
    activeComponentState: ActiveComponentState,
    isSelected: boolean
  ) {
    const textStyles = this.styles.text;

    switch (activeComponentState) {
      case ActiveComponentState.KeyboardFocused:
      case ActiveComponentState.KeyboardPressed:
        return textStyles.keyNav;
      default:
        return isSelected ? textStyles.selected : textStyles.default;
    }
  }

  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      isDisabled: boolean,
      isSelected: boolean,
      tabType: TabType
    ) => ({
      button: this.getButtonStylesForTabType(
        activeComponentState,
        isDisabled,
        isSelected,
        tabType
      ),
      text: this.getTextStyles(activeComponentState, isSelected)
    })
  );
}
