import { debounce } from 'lodash';
import { SizingUtils, UseSizingResizeHelper } from '../helpers';
import { useState } from 'react';
import { useDidMount } from './useDidMount';

export function useSizing() {
  const [appSizes, setAppSizes] = useState(SizingUtils.getAppSizing());

  const handleResize = debounce((event: Event) => {
    const newAppSizes = UseSizingResizeHelper.handleResize(event);

    if (newAppSizes) {
      setAppSizes(newAppSizes);
    }
  }, 200);

  useDidMount(() => {
    UseSizingResizeHelper.addResizeListenerMaybe(handleResize);

    return () => {
      UseSizingResizeHelper.removeResizeListenerMaybe(handleResize);
    };
  });

  return appSizes;
}
useSizing.displayName = 'useSizing';
