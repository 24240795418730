export enum CalibrationUiActionType {
  SetHasPlayedFeedback = 'CALIBRATION_UI.SET_HAS_PLAYED_FEEDBACK',
  SetStep = 'CALIBRATION_UI.SET_STEP'
}

export enum CalibrationFeedback {
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral'
}

export enum CalibrationStep {
  Intro = 'intro',
  InteractiveIntro = 'interactive intro',
  Interactive = 'interactive',
  Feedback = 'feedback',
  TryAgain = 'try again',
  MicFix = 'mic fix',
  MicSetupTips = 'mic setup tips',
  MicChanged = 'mic changed'
}

export interface ICalibrationUiState {
  hasPlayedFeedback: boolean;
  /**
   * Flag to know if the current attempt is a retry.
   * Needed because on failed retry attempt we send the user to the mic fix step
   */
  isRetry: boolean;
  step: CalibrationStep;
}
