import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISreDependencies } from './sre-epics.model';
import {
  SreConfigAction,
  SreConfigActionType,
  SreSelectMicAction,
  SreSelectMicSuccessAction
} from '../redux';

export function selectMicEpic(
  action$: Observable<SreSelectMicAction>,
  _state$: StateObservable<any>,
  deps: ISreDependencies
): Observable<SreSelectMicSuccessAction> {
  return action$.pipe(
    ofType(SreConfigActionType.SelectMic),
    map(({ payload }) => {
      deps.sre.microphones.selectMic(payload.mic);

      return SreConfigAction.selectMic.success();
    })
  );
}
selectMicEpic.displayName = 'selectMicEpic';
