import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import { PlacementSelector } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';

import { PreparedScenes } from 'services/storm-lobo';
import { PlacementOutroScreenplayBuilder } from '../../builders/placement/PlacementOutroScreenplayBuilder';
import { TransitionScreenplayId } from '../../transition.model';

export interface IRoundToPlacementCompleteTransitionScreenplayDeps {
  preparedScenes: PreparedScenes;
}

export class RoundToPlacementCompleteTransitionFactory {
  public static readonly displayName =
    'RoundToPlacementCompleteTransitionFactory';

  public static createFor(
    state$: StateObservable<unknown>,
    deps: IRoundToPlacementCompleteTransitionScreenplayDeps
  ): IScreenplay {
    return new RoundToPlacementCompleteTransitionFactory(state$, deps)
      .screenplay;
  }

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  protected readonly builder: ScreenplayBuilder;

  constructor(
    state$: StateObservable<unknown>,
    deps: IRoundToPlacementCompleteTransitionScreenplayDeps
  ) {
    const state = state$.value;
    const { preparedScenes } = deps;
    const placementCompleteVo = PlacementSelector.getPlacement(state).closing;

    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.RoundToPlacementComplete
    ).addScreenplay(
      PlacementOutroScreenplayBuilder.create()
        .disableUtilityBar()
        .fadeOutControlPanel()
        .routeToPlacementComplete()
        .dispatchStartProgram()
        .centerCharacter()
        .attachCharacter(preparedScenes)
        .animateConfettiWithMusic()
        .playCongratulationsVo(placementCompleteVo).screenplay
    );
  }
}
