import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { LxStormNode } from '../service/LxStormNode';
import { SceneBase } from './SceneBase';
import { IMaterialDefinition } from './storm-scenes.model';

export class SceneElement extends SceneBase<SceneElement> {
  public static readonly displayName = 'SceneElement';

  constructor(
    placeholderName: string,
    node: LxStormNode,
    elements: SceneElement[],
    animationDefinitions: IAnimationDefinition[],
    materialDefinitions: IMaterialDefinition[]
  ) {
    super(
      placeholderName,
      node,
      elements,
      animationDefinitions,
      materialDefinitions
    );
  }

  public get isAttached(): boolean {
    return this.node.isAttached;
  }

  public parent?: SceneBase<SceneElement>;

  public attach(): void {
    if (!this.parent) {
      throw new LexiaError(
        'Cannot attach an orphan SceneElement',
        SceneElement.displayName,
        SceneElementError.Orphan
      ).withContext({ elementName: this.name });
    }

    if (!this.isAttached) {
      this.parent.node.attachChild(this.placeholderName, this.node);
      this.maybeAutoplayAnimation();
    }
  }

  public detach(): void {
    this.node.detach();
  }
}

export enum SceneElementError {
  Orphan = 'Orphan'
}
