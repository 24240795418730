import { ActionType, createAction } from 'typesafe-actions';
import { ProgramCompleteSceneActionType } from './program-complete-scene-redux.model';

export const ProgramCompleteSceneAction = {
  prepare: createAction(
    ProgramCompleteSceneActionType.Prepare
  )<IProgramCompleteScenePrepareActionPayload>()
};

interface IProgramCompleteScenePrepareActionPayload {
  levelNum: string;
}

export type ProgramCompleteScenePrepareAction = ActionType<
  typeof ProgramCompleteSceneAction.prepare
>;

export type ProgramCompleteSceneActions = ActionType<
  typeof ProgramCompleteSceneAction
>;
