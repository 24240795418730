import { ofType, StateObservable } from 'redux-observable';
import { lastValueFrom, Observable } from 'rxjs';
import { first, map, mergeMap, startWith } from 'rxjs/operators';
import { ProgramMode, StudentProgressApi } from 'curriculum-services';
import { UserRole } from 'lexia-service/auth-api';
import { RoutePath, RouterService } from 'router-service';
import { SpinnerHandler } from 'spinner-handler';
import {
  DeepLinkEducatorProfile,
  DeepLinkStudentProfile,
  ProfileSelector
} from '../../profile';
import { FakeAuth, FakeStudentProperties } from '../auth.model';
import {
  AuthActionLoginSsoUser,
  AuthActionLoginUser,
  AuthActionType,
  AuthSelector
} from '../redux';
import { LoginActionFactory, LoginOutputActions } from './login-action-factory';
import { IUserInfo, UserInfoFactory } from './user-info-factory';
import { AuthenticationMethod } from '@lexialearning/student-api';
import { CustomerSelector } from 'services/customer';
import {
  ISsoLink,
  SsoProvider
} from 'lexia-service/customer-api/customer-api-private.model';

export interface ILoginUserDependencies {
  studentProgressApi: StudentProgressApi;
}

/**
 * Log in the authenticated user/student. Dispatch actions based on user type:
 *  - auto log in (e.g. deep linking in lower environments - ever anything other than deep linking??)
 *    Dispatch FakeAuth and DeepLinkingStudent/DeepLinkingEducator
 *
 *  - standard student via log in form
 *    Dispatch auth info, profile info, program position
 *
 *  - non-student user via log in form
 *    Dispatch auth and (non-student) profile
 *
 *  - SSO student
 *    Dispatch same actions as for student log in
 *
 *  - SSO non-student
 *    Dispatch same actions as for non-student log in
 */
export function loginUserEpic(
  action$: Observable<AuthActionLoginUser | AuthActionLoginSsoUser>,
  state$: StateObservable<unknown>,
  deps: ILoginUserDependencies
): Observable<LoginOutputActions> {
  return action$.pipe(
    ofType(AuthActionType.Login, AuthActionType.LoginSso),
    mergeMap(async action => {
      SpinnerHandler.requestSpinner();
      const options = getLoginOptions(action, state$.value);
      const info = await login(deps.studentProgressApi, options);
      const ssoLink = CustomerSelector.getSsoLink(state$.value);

      return { action, info, ssoLink };
    }),
    map(data =>
      LoginActionFactory.createLoginDispatches(
        data.info,
        determineAuthMethod(data.action.type, data.info, data.ssoLink),
        CustomerSelector.getCustomerCode(state$.value),
        deps.studentProgressApi
      )
    ),
    mergeMap(async dispatches => {
      await isLoggedOut(state$);
      SpinnerHandler.dismissSpinner();

      return dispatches;
    }),
    mergeMap(dispatches => dispatches)
  );
}
loginUserEpic.displayName = 'loginUserEpic';

function determineAuthMethod(
  actionType: AuthActionType,
  info: IUserInfo,
  ssoLink: ISsoLink | undefined
): AuthenticationMethod {
  if (actionType === AuthActionType.LoginSso) {
    switch (ssoLink?.provider) {
      case SsoProvider.Clever:
        return AuthenticationMethod.SsoClever;
      case SsoProvider.Google:
        return AuthenticationMethod.SsoGoogle;
      case SsoProvider.Saml:
        return AuthenticationMethod.SsoClassLink;
      default:
        return AuthenticationMethod.Sso;
    }
  }

  if (info.auth.authToken === FakeAuth.authToken) {
    // see createDeepLinkUserInfo below
    return info.profile.role === UserRole.DeepLinkEducator
      ? AuthenticationMethod.DeepLinkEducator
      : AuthenticationMethod.DeepLinkStudent;
  }

  return AuthenticationMethod.Standard;
}

async function isLoggedOut(state$: StateObservable<unknown>): Promise<any> {
  return lastValueFrom(
    state$.pipe(
      startWith(state$.value),
      first(state => !ProfileSelector.getProfileMaybe(state))
    )
  );
}

function getLoginOptions(
  action: AuthActionLoginUser | AuthActionLoginSsoUser,
  state: unknown
): ILoginOptions | undefined {
  if (action.type === AuthActionType.Login) {
    return action.payload && action.payload.autoLogin
      ? undefined
      : AuthSelector.getAuthDetails(state);
  }

  const { personId, userRole: role, lexiaAuthToken: token } = action.payload;

  return {
    personId,
    role,
    token
  };
}

/**
 * Deep link users in lower envs are not authenticated so return fake user info
 */
function createDeepLinkUserInfo(): IUserInfo {
  const isEducatorDeepLink = RouterService.isOnRoute([
    RoutePath.Educator,
    RoutePath.EducatorTab
  ]);
  const profile = isEducatorDeepLink
    ? DeepLinkEducatorProfile
    : DeepLinkStudentProfile;

  return {
    auth: FakeAuth,
    profile,
    programMode: ProgramMode.Unknown,
    studentProperties: isEducatorDeepLink ? undefined : FakeStudentProperties
  };
}

async function login(
  api: StudentProgressApi,
  options: ILoginOptions | undefined
): Promise<IUserInfo> {
  if (!options) return createDeepLinkUserInfo();

  const { personId, role, token } = options;
  const loginResponse = await api.login(personId, role, token);

  return UserInfoFactory.create(loginResponse);
}

interface ILoginOptions {
  personId: number;
  role: UserRole;
  token: string;
}
