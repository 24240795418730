import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LevelShowScreenplayBuilder } from 'feature-areas/transitions/builders/levels';
import { LevelSelector } from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { SreSessionActionCancel } from 'sre';
import { DevToolsActionSkipToLevelPage } from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

interface IDeps {
  preparedScenes: PreparedScenes;
}

export function skipToLevelPageEpic(
  action$: Observable<DevToolsActionSkipToLevelPage>,
  state$: StateObservable<unknown>,
  deps: IDeps
): Observable<ScreenplayActionPlay | SreSessionActionCancel> {
  return action$.pipe(
    ofType(DevToolsActionType.SkipToLevelPage),
    map(() => {
      const { preparedScenes } = deps;
      const levelId = LevelSelector.getLevelId(state$.value);
      const { screenplay } = LevelShowScreenplayBuilder.createFor({
        levelId,
        preparedScenes,
        shouldDeselectActivity: false
      });

      // TODO would be nice to cancel calibration session, but it is error-prone

      return ScreenplayAction.play({ screenplay });
    })
  );
}
skipToLevelPageEpic.displayName = 'skipToLevelPageEpic';
