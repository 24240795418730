import memoizeOne from 'memoize-one';
import { Align, Color, InteractionState } from 'common-styles';
import { Border } from '@lexialearning/common-ui';
import {
  SPEECH_BUBBLE_PADDING,
  SpeechBubbleWidth
} from '../../speech-bubbles.model';
import { ThemeSize, ThemeType } from 'theme';

export class SpeechBubbleStyles {
  public static bubbleCommonStyles = {
    alignItems: Align.Center,
    borderRadius: 40,
    borderStyle: Border.Solid,
    borderWidth: 3,
    borderBottomWidth: 7,
    padding: SPEECH_BUBBLE_PADDING
  };

  public static getSpeechBubbleWidth(
    isInstruction: boolean,
    themeSize?: ThemeSize
  ): number {
    if (isInstruction) {
      return themeSize === ThemeSize.Large
        ? SpeechBubbleWidth.Instruction
        : SpeechBubbleWidth.InstructionSmall;
    }

    return SpeechBubbleWidth.Standard;
  }

  public static build = memoizeOne(
    (
      interactionState?: InteractionState,
      themeSize?: ThemeSize,
      themeType?: ThemeType
    ) => {
      const isInstruction = themeType === ThemeType.Instruction;
      const width = SpeechBubbleStyles.getSpeechBubbleWidth(
        isInstruction,
        themeSize
      );

      const borderColor =
        interactionState === InteractionState.Default
          ? Color.Black
          : Color.getSetFor(interactionState).border;
      const backgroundColor =
        interactionState === InteractionState.Inconclusive
          ? Color.getSetFor(InteractionState.Inconclusive).background
          : Color.NearWhite;

      return {
        container: {
          ...SpeechBubbleStyles.bubbleCommonStyles,
          ...(isInstruction && {
            minHeight: 217,
            padding: 46
          }),
          borderColor,
          backgroundColor,
          width
        }
      };
    }
  );
}
