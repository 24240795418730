import * as React from 'react';
import { AnimatedView } from '../view';
import { FadeAnimationAnimatedStyles } from './FadeAnimation.animated-styles';
import { useEffect, useRef, useState } from 'react';
import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { Types } from '@lexialearning/reactxp';

export interface IFadeAnimationProps {
  children?: React.ReactNode;
  duration?: number;
  renderWhenHidden?: boolean;
  restrictFocusWithin?: boolean;
  shouldShow: boolean;
  testId?: string;
  /**
   * This styleOverride is applied in the style constructor, and thus will not
   * update on subsequent renders. If such behavior becomes necessary we will
   * need to re-address this functionality
   */
  styleOverride?: Types.ViewStyle;
}

export function FadeAnimation(props: IFadeAnimationProps) {
  const {
    children,
    duration,
    renderWhenHidden,
    restrictFocusWithin,
    shouldShow,
    styleOverride,
    testId
  } = props;
  const animatedStyles = useRef(
    new FadeAnimationAnimatedStyles({ duration }, styleOverride)
  );
  const styles = animatedStyles.current.get();
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (shouldShow) {
      setIsHidden(false);
    } else {
      animatedStyles.current
        .getAnimations()
        .hide.start(() => setIsHidden(true));
    }
  }, [animatedStyles, shouldShow]);

  useEffect(() => {
    if (!isHidden) {
      // The show animation kicks off here, rather than in the shouldShow useEffect,
      // in order to to ensure that isHidden has updated first, and thus the
      // components are on the page in order to animate them
      animatedStyles.current.getAnimations().show.start();
    }
  }, [animatedStyles, isHidden]);

  if (isHidden && !renderWhenHidden) {
    return null;
  }

  return (
    <AnimatedView
      animatedStyle={styles.containerAnimated}
      importantForAccessibility={
        shouldShow ? undefined : ImportantForAccessibility.NoHideDescendants
      }
      restrictFocusWithin={restrictFocusWithin}
      style={styles.containerStatic}
      testId={testId || FadeAnimation.displayName}
    >
      {children}
    </AnimatedView>
  );
}

FadeAnimation.displayName = 'FadeAnimation';
