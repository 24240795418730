import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align } from 'common-styles';
import { ThemeType } from 'theme';

export class WorkAreaStyles {
  private static readonly styles = {
    default: {
      container: {
        alignItems: Align.Stretch,
        flex: 1,
        marginBottom: 50,
        marginTop: 55
      },
      leftColumn: {
        marginRight: 10,
        width: 300
      },
      workArea: {
        flex: 1
      }
    },
    instruction: {
      container: {
        alignSelf: Align.Center,
        flex: 0,
        height: 560,
        paddingTop: 50,
        width: 840
      }
    }
  };

  public static readonly build = memoizeOne((themeType: ThemeType) =>
    merge(
      {},
      WorkAreaStyles.styles.default,
      themeType === ThemeType.Instruction && WorkAreaStyles.styles.instruction
    )
  );
}
