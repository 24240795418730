import * as React from 'react';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { PopFadeAnimation } from 'common-ui';
import { HorizontalIndicatorSvg } from './svgs/HorizontalIndicatorSvg.lx-svg';

export interface IHorizontalIndicatorProps extends ISfxProps {
  isVisible: boolean;
  width: number;
  height: number;
}

export class HorizontalIndicatorComponent extends React.PureComponent<IHorizontalIndicatorProps> {
  public static readonly displayName = 'HorizontalIndicator';

  public componentDidUpdate(prevProps: IHorizontalIndicatorProps) {
    const { isVisible, playSfx } = this.props;

    if (!prevProps.isVisible && isVisible) {
      playSfx(Sfx.DropZone);
    }
  }

  public render() {
    const { width, isVisible } = this.props;

    return (
      <PopFadeAnimation trigger={isVisible} scaleX={1.05}>
        <HorizontalIndicatorSvg width={width} />
      </PopFadeAnimation>
    );
  }
}

export const HorizontalIndicator = withSfx(HorizontalIndicatorComponent);
