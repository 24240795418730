import { TaskRegistry } from 'task-components';
import { PositionPathBuilder } from './PositionPathBuilder';

export class LogContextFactory {
  public static readonly displayName = 'LogContextFactory';

  public constructor(private readonly taskRegistry: TaskRegistry) {}

  public create(state: unknown): string {
    return PositionPathBuilder.create(state, this.taskRegistry).setFullPath()
      .path;
  }
}
