import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TaskAction, TaskActionExit } from 'task-components';
import {
  ActivityPositionAction,
  ActivityPositionActionSkip,
  ActivityPositionActionSkipPrepared,
  ActivityPositionActionType,
  ProgramContextSelector
} from '../../redux';

export function skipToNextPositionEpic(
  action$: Observable<ActivityPositionActionSkip>,
  state$: StateObservable<unknown>
): Observable<TaskActionExit | ActivityPositionActionSkipPrepared> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Skip),
    mergeMap(() => {
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const determiner = context.taskRegistration.createPositionDeterminer(
        context,
        { forSkipping: true }
      );
      determiner.suppressImminentPositionUnexpectedError = true;
      const change = determiner.determine();
      // Ensure we are not carrying over some imminent position
      change.activityPosition.imminentPosition = undefined;

      return from([
        ActivityPositionAction.skipPrepared(change),
        TaskAction.exit()
      ]);
    })
  );
}

skipToNextPositionEpic.displayName = 'skipToNextPositionEpic';
