import { NavigationButtonStyles } from './NavigationButton.styles';
import { ProfileSelector } from 'services/profile';
import { Text, UtilityButton, UtilityButtonIconType, View } from 'common-ui';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavigationButtonType,
  AppShellSelector,
  AppShellAction
} from 'feature-areas/shell/redux';

export interface INavigationButtonProps {
  disabled: boolean;
  navigationButtonType?: NavigationButtonType;
  onPress?: () => void;
}

export function NavigationButton(props: INavigationButtonProps) {
  const {
    disabled,
    navigationButtonType: propsNavigationButtonType,
    onPress
  } = props;
  const isStudent = useSelector(ProfileSelector.isStudent);
  const reduxNavigationButtonType = useSelector(
    AppShellSelector.getNavigationButtonType
  );
  const navigationButtonType =
    propsNavigationButtonType || reduxNavigationButtonType;

  const dispatch = useDispatch();
  const handlePressLogout = () => {
    onPress?.();
    dispatch(AppShellAction.requestLogout());
  };
  const navBack = () => {
    onPress?.();
    dispatch(AppShellAction.navigateBack());
  };
  const handlePressNavBack = throttle(navBack, 1000, {
    leading: true,
    trailing: false
  });

  function renderNavigationButton() {
    if (navigationButtonType === NavigationButtonType.Back) {
      return (
        <UtilityButton
          iconType={UtilityButtonIconType.BackArrow}
          onPress={handlePressNavBack}
          testId="backButton"
          disabled={disabled}
        />
      );
    }

    return (
      <UtilityButton
        iconType={UtilityButtonIconType.Logout}
        onPress={handlePressLogout}
        testId="logoutButton"
        disabled={disabled}
      />
    );
  }

  const styles = NavigationButtonStyles.get();

  return (
    <View
      style={styles.navigationButtonContainer}
      testId={NavigationButton.displayName}
    >
      {renderNavigationButton()}
      {!isStudent && <Text style={styles.educatorMode}>Educator Mode</Text>}
    </View>
  );
}
NavigationButton.displayName = 'NavigationButton';
