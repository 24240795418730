import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { ITaskAudioSupportEventPayload, LoboLogItemCategory } from 'logging';
import { RoundSelector } from 'curriculum-services';
import { TaskActionPlayAudioSupport, TaskActionType } from 'task-components';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logTaskAudioSupportEpic(
  action$: Observable<TaskActionPlayAudioSupport>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(TaskActionType.PlayAudioSupport),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.TaskAudioSupport),
    ignoreElements()
  );
}
logTaskAudioSupportEpic.displayName = 'logTaskAudioSupportEpic';

function createEvent(
  action: TaskActionPlayAudioSupport,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<ITaskAudioSupportEventPayload, LoboLogItemCategory> {
  const round = RoundSelector.getRound(state);

  const payload: ITaskAudioSupportEventPayload = {
    context: helper.getContext(state),
    round: round.title,
    roundId: round.sysId,
    supportType: action.payload,
    taskType: round.task!.taskType
  };

  return {
    category: LoboLogItemCategory.TaskAudioSupport,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: `Play ${payload.supportType} for ${payload.round}`
  };
}
