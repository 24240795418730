import { Types } from 'common-ui';
import { IDropdownItem } from './DropdownItem';
import { KeyboardCode } from '@lexialearning/reactxp/dist/common/Types';

export class DropdownHelper {
  /**
   * Get new highlighted item's id based on keyUp event
   */
  public static getHighlightedItemId(
    e: Types.KeyboardEvent | KeyboardEvent,
    items: IDropdownItem[],
    selectedItemId: string | undefined,
    isOpen: boolean
  ): string | undefined {
    switch (e.code) {
      // These are called via the onPress
      // So shouldn't be handled here
      case KeyboardCode.Enter:
      case KeyboardCode.NumpadEnter:
      case KeyboardCode.Space:
        return;

      case KeyboardCode.ArrowDown:
      case KeyboardCode.ArrowUp:
        if (!isOpen) {
          return selectedItemId;
        }

        const index = items.findIndex(item => item.id === selectedItemId);
        const direction = e.code === KeyboardCode.ArrowDown ? 1 : -1;
        // Ensure newSelectedIndex is within 0 and items.length - 1
        const newSelectedIndex = Math.max(
          0,
          Math.min(index + direction, items.length - 1)
        );

        return items[newSelectedIndex].id;

      case KeyboardCode.End:
        return items[items.length - 1].id;

      case KeyboardCode.Home:
        return items[0].id;

      default:
        // ⬇ A printable character was pressed, move the visual focus to the
        // first option that matches the typed character
        // And if no matches, just return selectedItemId
        if (e.key?.length === 1) {
          const pressedKey = e.key.toLowerCase();
          const matchingItem = items.find(item =>
            item.label.toLowerCase().startsWith(pressedKey)
          );

          return matchingItem?.id || selectedItemId;
        }
    }
  }

  public static formatItems(
    items: IDropdownItem[] | string[] | { [key: string]: string }
  ): IDropdownItem[] {
    const isEnumTypeItems = !Array.isArray(items);

    return isEnumTypeItems
      ? Object.entries(items).map(this.formatEnumItem)
      : (items as any[]).map(this.formatArrayItem);
  }

  private static formatArrayItem(item: IDropdownItem | string): IDropdownItem {
    if (typeof item === 'string') {
      return {
        id: item,
        label: item
      };
    }

    return item;
  }

  private static formatEnumItem([key, value]: string[]): IDropdownItem {
    return {
      id: value,
      label: key
    };
  }
}
