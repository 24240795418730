import { IContentBase } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import {
  AncestorIndex,
  IDeepLinkContentIds,
  IDeepLinkSubject,
  SubjectType
} from '../position-builder-deep-link.model';

export class DeepLinkContentIdsFactory {
  public static readonly displayName = 'DeepLinkContentIdsFactory';

  public static create(
    subject: IDeepLinkSubject,
    ancestors: IContentBase[]
  ): IDeepLinkContentIds {
    const factory = new DeepLinkContentIdsFactory(subject, ancestors);

    return factory.create();
  }

  private constructor(
    private readonly subject: IDeepLinkSubject,
    private readonly ancestors: IContentBase[]
  ) {}

  private create(): IDeepLinkContentIds {
    this.validateAncestry();

    const base: IDeepLinkContentIds = {
      activity: this.ancestors[AncestorIndex.Activity].sysId,
      encounter: this.ancestors[AncestorIndex.Encounter].sysId,
      level: this.ancestors[AncestorIndex.Level].sysId,
      mainRound: this.ancestors[AncestorIndex.MainRound]?.sysId,
      mainUnit: this.ancestors[AncestorIndex.MainUnit]?.sysId
    };

    if (
      [SubjectType.InstructionRound, SubjectType.InstructionSubunit].includes(
        this.subject.type
      )
    ) {
      base.instructionRound =
        this.ancestors[AncestorIndex.InstructionRound]?.sysId;
      base.instructionSubunit =
        this.ancestors[AncestorIndex.InstructionSubunit]?.sysId;
    }

    if (
      [
        SubjectType.ForkRound,
        SubjectType.ForkSubunit,
        SubjectType.ForkedInstructionRound,
        SubjectType.ForkedInstructionSubunit
      ].includes(this.subject.type)
    ) {
      base.forkRound = this.ancestors[AncestorIndex.ForkRound]?.sysId;
      base.forkSubunit = this.ancestors[AncestorIndex.ForkSubunit]?.sysId;
    }

    if (
      [
        SubjectType.ForkedInstructionRound,
        SubjectType.ForkedInstructionSubunit
      ].includes(this.subject.type)
    ) {
      base.forkedInstructionRound =
        this.ancestors[AncestorIndex.ForkedInstructionRound]?.sysId;
      base.forkedInstructionSubunit =
        this.ancestors[AncestorIndex.ForkedInstructionSubunit]?.sysId;
    }

    return base;
  }

  private validateAncestry(): void {
    const actual = this.ancestors.map(a => a.contentType).join('>');
    const expected = this.subject.expectedAncestry.join('>');

    if (actual !== expected) {
      throw new LexiaError(
        `Unexpected deep link ancestry: "${actual}" should be "${expected}"`,
        DeepLinkContentIdsFactory.displayName,
        DeepLinkContentIdsFactoryError.AncestryInvalid
      ).withContext({ actual, expected });
    }
  }
}

export enum DeepLinkContentIdsFactoryError {
  AncestryInvalid = 'AncestryInvalid'
}
