import * as React from 'react';
import { DragLayer, DragLayerMonitor, XYCoord } from 'react-dnd';
import { connect } from 'react-redux';
import { AnimatedColumn, SizingUtils } from 'common-ui';
import { DragItemType } from '../DragItemType';
import { DndAction, DndSelector } from '../redux';
import { IDndForcedDragLayer } from '../redux/dnd-redux.model';
import { DndDragLayerAnimatedStyles } from './DndDragLayer.animated-styles';

export interface IDndDragLayerProps {
  dropTargetPosition?: XYCoord;
  isDraggingOrDropping: boolean;
  forcedDragLayer?: IDndForcedDragLayer;
  renderItem(itemType: DragItemType, itemProps: any): React.ReactElement | null;
  resetDndState(): void;
}
export interface IDndDragLayerState {
  itemType?: DragItemType;
  itemProps?: any;
}

export interface IDndDragLayerCollectedProps {
  itemType: DragItemType | undefined;
  itemProps?: any;
  currentOffset?: XYCoord;
  isDragging?: boolean;
}

export function collect(monitor: DragLayerMonitor) {
  return {
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    itemProps: monitor.getItem(),
    itemType: monitor.getItemType()
  };
}

export class DndDragLayerComponent extends React.PureComponent<
  IDndDragLayerProps & IDndDragLayerCollectedProps,
  IDndDragLayerState
> {
  public static readonly displayName = 'DndDragLayer';

  private readonly animatedStyles: DndDragLayerAnimatedStyles;

  constructor(props: IDndDragLayerProps & IDndDragLayerCollectedProps) {
    super(props);

    // Need to store these values in state in order for them to be available
    // when the item is dropped (at which point ReactDnd resets the prop values)
    // so they can be used for the drop animation
    this.state = {
      itemProps: props.itemProps,
      itemType: props.itemType
    };
    const { currentOffset } = props;
    this.animatedStyles = new DndDragLayerAnimatedStyles(currentOffset);
  }

  public componentDidUpdate(
    prevProps: IDndDragLayerProps & IDndDragLayerCollectedProps
  ) {
    const {
      currentOffset: prevOffset,
      dropTargetPosition: prevDropTargetPosition,
      itemProps: prevItemProps,
      itemType: prevItemType,
      forcedDragLayer: prevForcedDragLayer
    } = prevProps;
    const {
      currentOffset,
      dropTargetPosition,
      itemProps,
      itemType,
      forcedDragLayer
    } = this.props;

    if (!!currentOffset && prevOffset !== currentOffset) {
      const offset = SizingUtils.getOffsetRelativeToApp(currentOffset);
      this.animatedStyles.setTranslations(offset);
      if (itemProps?.onDrag) {
        itemProps.onDrag(offset);
      }
    }

    if (
      !!itemType &&
      (prevItemProps !== itemProps || prevItemType !== itemType)
    ) {
      this.setState({
        itemProps,
        itemType
      });
    }

    if (!prevForcedDragLayer && !!forcedDragLayer) {
      const { initialOffset } = forcedDragLayer;
      this.animatedStyles.setTranslations(initialOffset);
    }

    if (!prevDropTargetPosition && !!dropTargetPosition) {
      this.runDropAnimation();
    }
  }

  private runDropAnimation() {
    const { dropTargetPosition, resetDndState } = this.props;

    const drop = this.animatedStyles.buildDropAnimation(dropTargetPosition!);
    drop.start(() => {
      this.setState({
        itemProps: undefined,
        itemType: undefined
      });
      resetDndState();
    });
  }

  public render() {
    const { isDraggingOrDropping, forcedDragLayer } = this.props;
    const itemType = this.state.itemType || forcedDragLayer?.itemType;
    const itemProps = this.state.itemProps || forcedDragLayer?.itemProps;

    if (!itemType || !isDraggingOrDropping) {
      return null;
    }

    const { renderItem } = this.props;
    const styles = this.animatedStyles.get();

    return (
      <AnimatedColumn
        style={styles.container}
        animatedStyle={styles.containerAnimated}
        blockPointerEvents
      >
        {renderItem(itemType, itemProps)}
      </AnimatedColumn>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    dropTargetPosition: DndSelector.getDropTargetPosition(state),
    forcedDragLayer: DndSelector.getForcedDragLayer(state),
    isDraggingOrDropping: DndSelector.getIsDraggingOrDropping(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  resetDndState: () => DndAction.reset()
};

export const DndDragLayer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DragLayer<IDndDragLayerProps>(collect)(DndDragLayerComponent));
