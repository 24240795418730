import {
  IActivityPosition,
  IProgramPosition
} from '@lexialearning/lobo-common';
import { IProgressBase } from '@lexialearning/lobo-common/main-model/student';
import { zipWith } from 'lodash';
import {
  LevelSelector,
  ProgramContextSelector,
  ProgressSelector
} from 'curriculum-services';
import { ISessionInitialLevelPositionEventPayload } from 'logging';
import { AuthSelector } from 'services/auth';
import { ProfileSelector } from 'services/profile';
import { TaskRegistry } from 'task-components';
import { PositionPathBuilder } from '../helpers';
import { ILoboAppConfig } from 'services/app-config';
import { StudentApiHelper } from 'student-api';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppState } from 'services';

export class SessionPositionEventFactory {
  public static readonly displayName = 'SessionPositionEventFactory';

  public readonly levelTitle: string;

  private readonly activityProgress: IProgressBase[];

  private readonly position: IProgramPosition;

  public constructor(
    private readonly state: AppState,
    private readonly taskRegistry: TaskRegistry
  ) {
    this.createActivitySummary = this.createActivitySummary.bind(this);

    this.position = ProgramContextSelector.getPosition(state);
    this.activityProgress = this.position.activityPositions?.length
      ? ProgressSelector.getProgress(state).map(({ ...p }) => p)
      : [];

    const level = LevelSelector.getLevel(this.state);
    this.levelTitle = level.title;
  }

  public createData(): ISessionInitialLevelPositionEventPayload {
    const { token: authToken } = AuthSelector.getAuthDetails(this.state);
    const apiEndpoint = StudentApiHelper.getEndpoint(
      UniConfigSelector.getConfig<ILoboAppConfig>(this.state).studentApi
    );

    return {
      activityProgress: this.activityProgress,
      apiEndpoint,
      authToken,
      position: this.position
    };
  }

  public createSummary(): string {
    const grade = ProfileSelector.getGrade(this.state);

    const activitySummaries = zipWith(
      this.position.activityPositions,
      this.activityProgress,
      this.createActivitySummary
    );

    return `L${this.levelTitle} [${activitySummaries.join(', ')}] ${grade}`;
  }

  /**
   * Create summary of activity position such as: A1.E1.U01.R02 (10%)
   */
  private createActivitySummary(
    activityPosition: IActivityPosition,
    progressItem: IProgressBase
  ): string {
    const { path } = PositionPathBuilder.create(
      this.state,
      this.taskRegistry,
      activityPosition
    )
      .addActivity()
      .addEncounter()
      .addMainUnit()
      .addMainRound();

    const percentComplete = ` (${progressItem.percentComplete}%)`;

    return `${path}${percentComplete}`;
  }
}
