import { SpinnerHandler } from './SpinnerHandler';
import { useDidMount } from '../common-ui/hooks/useDidMount';
import { useEffect } from 'react';

export interface ISpinnerProps {
  isPageReady: boolean;
}

export function useSpinner(props) {
  const { isPageReady } = props;

  useDidMount(() => {
    if (!isPageReady) {
      SpinnerHandler.requestSpinner();
    }
  });

  useEffect(() => {
    if (isPageReady) {
      SpinnerHandler.dismissSpinner();
    } else {
      SpinnerHandler.requestSpinner();
    }

    return () => {
      if (!isPageReady) {
        SpinnerHandler.dismissSpinner();
      }
    };
  }, [isPageReady]);

  return { shouldBlockRender: !isPageReady };
}

useSpinner.displayName = 'useSpinner';

export enum UseSpinnerError {
  IsPageReadyUndefined = 'IsPageReadyUndefined'
}
