import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { LevelSelector } from 'services/curriculum-services';
import { PreparedScenes } from 'services/storm-lobo';
import { TransitionActionLevelIntroToLevel } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { LevelIntroToLevelTransitionScreenplayFactory } from './LevelIntroToLevelTransitionScreenplayFactory';

export interface ILevelIntroToLevelTransitionEpicDeps {
  preparedScenes: PreparedScenes;
}

export function levelIntroToLevelTransitionEpic(
  action$: Observable<TransitionActionLevelIntroToLevel>,
  state$: StateObservable<unknown>,
  deps: ILevelIntroToLevelTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.LevelIntroToLevel),
    map(() => {
      const { preparedScenes } = deps;
      const entryPromptVo = BootstrapContentSelector.getLevelScreenContent(
        state$.value
      )?.entryPrompt;
      const levelId = LevelSelector.getLevelId(state$.value);

      return LevelIntroToLevelTransitionScreenplayFactory.createFor({
        entryPromptVo,
        levelId,
        preparedScenes
      }).screenplay;
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
levelIntroToLevelTransitionEpic.displayName = 'levelIntroToLevelTransitionEpic';
