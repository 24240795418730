import {
  Align,
  Color,
  Font,
  TextAlign,
  TextDecorationLine
} from 'common-styles';
import { AccountSettingsModalStyles } from '../AccountSettingsModal.styles';
import { Display } from '@lexialearning/common-ui';

export class NoticesStyles {
  private static readonly styles = {
    container: {
      alignItems: Align.Start
    },
    headline: {
      color: Color.LexiaPurple,
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small,
      marginBottom: 10,
      marginTop: 5,
      textAlign: TextAlign.Left
    },
    lastRow: {
      alignItems: Align.Start,
      marginBottom: 20,
      width: 500
    },
    linkIcon: {
      transform: [{ scale: 0.4 }, { translateX: 5 }]
    },
    listItem: {
      alignItems: Align.Start,
      marginBottom: 10,
      marginLeft: 5,
      marginTop: 6,
      width: 475
    },
    listRow: {
      alignItems: Align.Start,
      width: 500
    },
    link: {
      display: Display.Flex,
      alignItems: Align.Center
    },
    linkText: {
      color: Color.Clementine,
      fontSize: Font.Size.xSmall,
      textAlign: TextAlign.Left,
      textDecorationLine: TextDecorationLine.Underline
    },
    listText: {
      fontSize: Font.Size.xSmall,
      textAlign: TextAlign.Left
    },
    paragraph: {
      fontSize: Font.Size.xSmall,
      marginBottom: 10,
      textAlign: TextAlign.Left
    },
    scrollContainer: {
      height: AccountSettingsModalStyles.webViewHeight
    }
  };

  public static get() {
    return this.styles;
  }
}
