import { LexiaError } from '@lexialearning/utils';
import { IModalConfig, ModalId } from './modal.model';

export class ModalRegistry {
  public static readonly displayName = 'ModalRegistry';

  private static readonly registry = new Map<ModalId, IModalConfig>();

  public static register(modal: IModalConfig): ModalId {
    const modalId = modal.id || modal.Component.displayName;

    if (!modalId) {
      throw new LexiaError(
        'Tried to register a modal without displayName',
        ModalRegistry.displayName,
        ModalRegistryErrorCode.MissingDisplayName
      );
    }

    ModalRegistry.registry.set(modalId, modal);

    return modalId;
  }

  public static get(modalId: string): IModalConfig {
    const modalConfig = ModalRegistry.registry.get(modalId);

    if (!modalConfig) {
      throw new LexiaError(
        `There is no modal registered by id ${modalId}`,
        ModalRegistry.displayName,
        ModalRegistryErrorCode.AccessingNonRegisteredModal
      );
    }

    return modalConfig;
  }
}

export enum ModalRegistryErrorCode {
  MissingDisplayName = 'MissingDisplayName',
  AccessingNonRegisteredModal = 'AccessingNonRegisteredModal'
}
