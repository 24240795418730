import { IScreenplay } from '@lexialearning/lobo-common';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import {
  LevelIntroSceneScreenplayBuilder,
  LevelIntroScreenplayBuilder
} from '../builders/levels';
import { TransitionScreenplayId } from '../transition.model';

export interface IPlacementCompleteToLevelTransitionScreenplayDeps {
  entryPromptVo: IScreenplay;
  levelId: string;
  preparedScenes: PreparedScenes;
  welcomeMessageVo: IScreenplay;
}

export class PlacementCompleteToLevelTransitionFactory {
  public static readonly displayName =
    'PlacementCompleteToLevelTransitionFactory';

  public static createFor(
    deps: IPlacementCompleteToLevelTransitionScreenplayDeps
  ): IScreenplay {
    return new PlacementCompleteToLevelTransitionFactory(deps).screenplay;
  }

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  protected readonly builder: ScreenplayBuilder;

  constructor(deps: IPlacementCompleteToLevelTransitionScreenplayDeps) {
    const { entryPromptVo, levelId, preparedScenes, welcomeMessageVo } = deps;

    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.PlacementToLevel
    )
      .addScreenplay(
        LevelIntroSceneScreenplayBuilder.createFor({
          preparedScenes,
          welcomeMessageVo
        }).screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.levels(levelId));
      })
      .addScreenplay(
        LevelIntroScreenplayBuilder.createFor({
          entryPromptVo,
          preparedScenes
        }).screenplay
      );
  }
}
