import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProgramCompleteSceneAction,
  ProgramCompleteScenePrepareAction
} from 'feature-areas/program-complete';
import { LevelAction, LevelActionLoad, LevelSelector } from '../../../level';
import {
  PositionActionLevelUp,
  PositionActionType,
  ProgramContextActionStartProgram,
  ProgramContextActionType,
  ProgramContextSelector
} from '../../redux';

export type LevelUpOutputActions =
  | LevelActionLoad
  | ProgramCompleteScenePrepareAction;

/**
 * Load the next level upon effecting a level-up or placement completion
 * position change. If the student has completed the program, prepare the
 * program complete scene instead of loading the next level.
 */
export function levelUpEpic(
  action$: Observable<PositionActionLevelUp | ProgramContextActionStartProgram>,
  state$: StateObservable<unknown>
): Observable<LevelUpOutputActions> {
  return action$.pipe(
    ofType(PositionActionType.LevelUp, ProgramContextActionType.StartProgram),
    map(() => {
      const position = ProgramContextSelector.getPosition(state$.value);

      if (position.isComplete) {
        const levelNum = LevelSelector.getLevel(state$.value).title;

        return ProgramCompleteSceneAction.prepare({ levelNum });
      }

      return LevelAction.load.request({ sysId: position.levelId });
    })
  );
}

levelUpEpic.displayName = 'levelUpEpic';
