import {
  AvatarEditorAction,
  AvatarEditorActionSetEncountersUntilEditable
} from '../AvatarEditor.action';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, from, map, mergeMap } from 'rxjs';
import { AppState } from 'services';
import { ProfileSelector } from 'services/profile';
import {
  StudentPropertyAction,
  StudentPropertyActionUpdate
} from '@lexialearning/student-api';
import { StudentProperty } from 'student-api';
import {
  ActivityPositionActionChange,
  ActivityPositionActionType,
  PositionChangeType,
  ProgramContextSelector
} from 'curriculum-services';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import { PredicateUtils } from 'utils';

export function setEncountersUntilEditableEpic(
  action$: Observable<ActivityPositionActionChange>,
  state$: StateObservable<AppState>
): Observable<
  AvatarEditorActionSetEncountersUntilEditable | StudentPropertyActionUpdate
> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Change),
    filter(() => {
      const { changeType } =
        ProgramContextSelector.getActivityPosition(state$.value)
          .imminentPosition || {};

      return (
        !!changeType &&
        [
          PositionChangeType.ActivityCompletion,
          PositionChangeType.LevelCompletion,
          PositionChangeType.EncounterCompletion
        ].includes(changeType)
      );
    }),
    map(() => {
      const prevEncountersUntilEditable =
        AvatarEditorSelector.getEncountersUntilEditable(state$.value);
      const encountersUntilEditable = Math.max(
        prevEncountersUntilEditable - 1,
        0
      );

      if (prevEncountersUntilEditable !== encountersUntilEditable) {
        return encountersUntilEditable;
      }
    }),
    filter(PredicateUtils.isDefined),
    mergeMap(encountersUntilEditable => {
      const avatar = ProfileSelector.getAvatarMaybe(state$.value);
      const actions = [
        AvatarEditorAction.setEncountersUntilEditable(encountersUntilEditable),
        StudentPropertyAction.update.request({
          studentProperties: [
            {
              key: StudentProperty.Avatar,
              value: JSON.stringify({
                encountersUntilEditable,
                selection: avatar
              })
            }
          ]
        })
      ];

      return from(actions);
    })
  );
}
setEncountersUntilEditableEpic.displayName = 'setEncountersUntilEditableEpic';
