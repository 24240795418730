import { ActionType, createAction } from 'typesafe-actions';
import { ActUiActionType } from './act-ui-redux.model';

export const ActUiAction = {
  addPlayedFunFact: createAction(ActUiActionType.AddPlayedFunFact)<{
    id: string;
  }>()
};

export type ActUiActions = ActionType<typeof ActUiAction>;
export type ActUiActionAddPlayedFunFact = ActionType<
  typeof ActUiAction.addPlayedFunFact
>;
