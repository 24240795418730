import { G, Path, Svg } from 'common-ui';
import { SvgStyles } from './Svg.styles';
import { Color } from 'common-styles';

export function ImageSlotTransparentBgSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={154}
      height={52}
      viewBox="0 0 154 52"
      style={SvgStyles.get().tab}
      data-test-id={ImageSlotTransparentBgSvg.displayName}
    >
      <G
        id="Image-slot-transparent-bg"
        transform="translate(-641 -851)"
        opacity="0.4"
      >
        <Path
          id="EndRight"
          d="M520.922,51.971H480.05v-52a81.806,81.806,0,0,1,9.356.486c12.635,1.707,24.049,9.851,31.333,22.357,8.6,14.791,21.565,25.107,36.267,28.859v.3Z"
          transform="translate(237.994 851.029)"
          fill={Color.White}
        />
        <Path
          id="EndLeft"
          d="M516.133,51.971h40.872v-52a81.809,81.809,0,0,0-9.356.486c-12.635,1.707-24.049,9.851-31.333,22.357-8.6,14.791-21.565,25.107-36.267,28.859v.3Z"
          transform="translate(160.95 851.029)"
          fill={Color.White}
        />
      </G>
    </Svg>
  );
}
ImageSlotTransparentBgSvg.displayName = 'ImageSlotTransparentBgSvg';
