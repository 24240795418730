import { ActionType, createAction } from 'typesafe-actions';
import { TaskDemoSceneActionType } from './task-demo-scene-redux.model';

export const TaskDemoSceneAction = {
  prepare: createAction(TaskDemoSceneActionType.Prepare)()
};

export type TaskDemoScenePrepareAction = ActionType<
  typeof TaskDemoSceneAction.prepare
>;

export type TaskDemoSceneActions = ActionType<typeof TaskDemoSceneAction>;
