import { Position } from '@lexialearning/common-ui';

export class NavigationButtonStyles {
  private static readonly styles = {
    educatorMode: {
      fontSize: 20,
      left: 50,
      position: Position.Absolute,
      width: 130
    },
    navigationButtonContainer: {
      left: 10,
      position: Position.Absolute,
      top: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
