import { ActionType } from '@lexialearning/main-model';
import { createAction } from '@reduxjs/toolkit';

export type SoundLogsActionLoadCollectionPolicies = ActionType<
  typeof SoundLogsAction.loadCollectionPolicies
>;

export const SoundLogsAction = {
  loadCollectionPolicies: createAction('soundLogs/loadCollectionPolicies')
};
