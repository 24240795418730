import { IStateManager } from '@lexialearning/lobo-common/lib';
import {
  LexiaErrorSeverity,
  LexiaStandardErrorCode
} from '@lexialearning/main-model';
import { LexiaError } from '@lexialearning/utils';
import { toNumber } from 'lodash';
import { LexiaService } from '../LexiaService';
import { LexiaServiceSelector } from '../redux/LexiaService.selector';
import {
  AuthApiEndpoint,
  IAuthErrorResponse,
  IAuthRequest,
  IAuthRequestBase,
  IAuthResponse,
  IAuthenticationData
} from './auth-api-private.model';
import { IAuthDetails } from './auth-api.model';

export class AuthApi {
  public static readonly displayName = 'AuthApi';

  private get authenticateRequestBase(): IAuthRequestBase {
    return LexiaServiceSelector.getLexiaServiceRequests(
      this.stateManager.getState()
    ).authenticate;
  }

  private get ApiUrl() {
    return {
      Authenticate: this.lexiaApiService.createUrl(AuthApiEndpoint.Authenticate)
    };
  }

  public constructor(
    private readonly lexiaApiService: LexiaService,
    private readonly stateManager: IStateManager
  ) {}

  public async authenticate(
    username: string,
    password: string
  ): Promise<IAuthDetails> {
    const request: IAuthRequest = {
      ...this.authenticateRequestBase,
      password,
      username
    };

    const response = await this.lexiaApiService.postRequest<
      IAuthResponse | IAuthErrorResponse
    >(request, this.ApiUrl.Authenticate);

    if (!response.valid) {
      throw this.createAuthError(response as IAuthErrorResponse);
    }

    return {
      name: response.name,
      personId: toNumber(response.personId),
      role: toNumber(response.role),
      token: response.token
    };
  }

  /**
   * User has authenticated, but there is a need to refetch the authentication data
   * (current use-case - LOBO-20423 - in certain Clever flows, the SSO redirect url
   *  does not contain the auth data, so must be fetched)
   * @param url
   * @returns
   */
  public async fetchAuthenticationData(
    url: string
  ): Promise<IAuthenticationData> {
    const requestBody = {
      plat: 'ios',
      prod: this.authenticateRequestBase.product,
      redirect_uri: url
    };

    const response =
      await this.lexiaApiService.postRequest<IAuthenticationData>(
        requestBody,
        url
      );

    if (!response) {
      throw new LexiaError(
        'Failed to fetch auth data',
        AuthApi.displayName,
        AuthApiError.FailedToFetchAuthData
      )
        .withContext({ requestBody, url })
        .withSeverity(LexiaErrorSeverity.Info);
    }

    return response;
  }

  private createAuthError(response: IAuthErrorResponse): LexiaError {
    return new LexiaError(
      `Authentication error: ${response.error}`,
      AuthApi.displayName,
      AuthApiError.AuthError
    )
      .withContext({ response })
      .withStandardCode(
        this.determineAuthStandardCode(response.validCredentials)
      );
  }

  private determineAuthStandardCode(
    validCredentials?: boolean
  ): LexiaStandardErrorCode {
    if (!validCredentials) {
      return LexiaStandardErrorCode.UnknownUsername;
    }

    return LexiaStandardErrorCode.UnexpectedError;
  }
}

export enum AuthApiError {
  AuthError = 'AuthError',
  FailedToFetchAuthData = 'FailedToFetchAuthData',
  UnhandledLogoutError = 'UnhandledLogoutError'
}
