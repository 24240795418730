import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterService, RoutePath } from 'router-service';
import {
  CustomerActionChangeAccount,
  CustomerActionSetupDeviceWithCustomerCodeSuccess,
  CustomerActionSetupDeviceWithEmailSuccess,
  CustomerActionType
} from 'services/customer/redux';
import { AppShellMode } from '../app-shell.model';
import {
  AppShellAction,
  AppShellActionNotifyNavigationHistoryUpdate,
  AppShellSetMode
} from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

const MODE_TO_ROUTES_MAP: Record<AppShellMode, string[]> = {
  [AppShellMode.Full]: [
    RoutePath.EncounterComplete,
    RoutePath.OnboardingRounds,
    RoutePath.PlacementRounds,
    RoutePath.Rounds,
    RoutePath.UnitComplete
  ],
  [AppShellMode.None]: [
    RoutePath.Introduction,
    RoutePath.LevelComplete,
    RoutePath.Login,
    RoutePath.LoginSsoInteraction,
    RoutePath.LoginTab,
    RoutePath.ProgramComplete,
    RoutePath.Root
  ],
  [AppShellMode.UtilitiesOnly]: [
    RoutePath.Acts,
    RoutePath.Calibration,
    RoutePath.Educator,
    RoutePath.EducatorTab,
    RoutePath.Home,
    RoutePath.Levels,
    RoutePath.Onboarding,
    RoutePath.OnboardingOrientation,
    RoutePath.PlacementComplete
  ]
};

export function updateAppShellModeEpic(
  action$: Observable<
    | AppShellActionNotifyNavigationHistoryUpdate
    | CustomerActionChangeAccount
    | CustomerActionSetupDeviceWithCustomerCodeSuccess
    | CustomerActionSetupDeviceWithEmailSuccess
  >
): Observable<AppShellSetMode> {
  return action$.pipe(
    ofType(
      AppShellActionType.NotifyNavigationHistoryUpdate,
      CustomerActionType.ChangeAccount,
      CustomerActionType.SetupDeviceWithCustomerCodeSuccess,
      CustomerActionType.SetupDeviceWithEmailSuccess
    ),
    map(() => {
      const pathBasedShellMode = Object.keys(MODE_TO_ROUTES_MAP).find(mode =>
        RouterService.isOnRoute(MODE_TO_ROUTES_MAP[mode])
      ) as AppShellMode | undefined;

      return AppShellAction.setMode(pathBasedShellMode || AppShellMode.None);
    })
  );
}
updateAppShellModeEpic.displayName = 'updateAppShellModeEpic';
