import {
  ITextboard,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { ITaskRegistration, TaskRegistrationBuilder } from '../core';
import { Textboard } from './Textboard';

export function createTextboardRegistration(): ITaskRegistration<TaskTypeName.Textboard> {
  return TaskRegistrationBuilder.create<TaskTypeName.Textboard, ITextboard>(
    TaskTypeName.Textboard,
    Textboard
  ).registration;
}
