import { ofType } from 'redux-observable';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProgramContextActionOnboardingCompleted,
  ProgramContextActionType,
  UnitAction,
  UnitActionLoad
} from 'curriculum-services';

export function onboardingCompletionEpic(
  action$: Observable<ProgramContextActionOnboardingCompleted>
): Observable<UnitActionLoad> {
  return action$.pipe(
    ofType(ProgramContextActionType.OnboardingCompleted),
    map(() => UnitAction.load.request())
  );
}

onboardingCompletionEpic.displayName = 'onboardingCompletionEpic';
