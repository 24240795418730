import { IScreenplay } from '@lexialearning/lobo-common/main-model';

export enum ScreenplayActionType {
  Cancel = 'SCREENPLAY.CANCEL',
  CancelMusic = 'SCREENPLAY.CANCEL_MUSIC',
  Canceled = 'SCREENPLAY.CANCELED',
  Pause = 'SCREENPLAY.PAUSE',
  Play = 'SCREENPLAY.PLAY',
  PlayComplete = 'SCREENPLAY.PLAY_COMPLETE',
  Playing = 'SCREENPLAY.PLAYING',
  Resume = 'SCREENPLAY.RESUME',
  Skip = 'SCREENPLAY.SKIP'
}

export interface IScreenplayState {
  currentActionInfo: IPlaybackActionInfo;
  screenplay: IScreenplay | undefined;
  status: ScreenplayStatus;
}

export interface IPlaybackActionInfo {
  index: number;
  type: string;
}

export enum ScreenplayStatus {
  None = 'None',
  Paused = 'Paused',
  Playing = 'Playing'
}
