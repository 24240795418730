import { FlexWrap, Justify } from '@lexialearning/common-ui';
import { Direction } from 'common-styles';

export class HairStyleOptionsStyles {
  public static readonly styles = {
    container: {
      flexDirection: Direction.Row,
      flexWrap: FlexWrap.Wrap,
      justifyContent: Justify.Start,
      marginLeft: 8,
      width: 312
    }
  };

  public static get() {
    return this.styles;
  }
}
