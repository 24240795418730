import { Bar } from './Bar';
import { IBarStyleOverride } from './Bar.animated-styles';
import { InteractionState } from 'common-styles';
import { Row, Types } from 'common-ui';
import { IUseMicEnergyOptions } from 'sre';

export const BARS_COUNT = 8;
const TARGET_BAR_IDX = 5;

export interface IVUMeterProps {
  activeBarColor?: string;
  interactionState?: InteractionState;
  micEnergy: number;
  styleOverride?: IVUMeterStyleOverrides;
  withCaret?: boolean;
  withSparkles?: boolean;
  testId?: string;
}

export interface IVUMeterStyleOverrides extends IBarStyleOverride {
  container?: Types.ViewStyle;
}

export function VUMeter(props: IVUMeterProps & IUseMicEnergyOptions) {
  const {
    interactionState,
    micEnergy,
    styleOverride,
    testId,
    withCaret,
    withSparkles
  } = props;
  const bars = [...Array(BARS_COUNT).keys()];
  const activeCount = BARS_COUNT * micEnergy;

  return (
    <Row
      testId={testId || VUMeter.displayName}
      style={styleOverride?.container}
    >
      {bars.map(bIdx => (
        <Bar
          key={bIdx}
          interactionState={interactionState}
          isActive={bIdx < activeCount}
          styleOverride={styleOverride}
          withCaret={withCaret && bIdx === TARGET_BAR_IDX}
          withSparkles={withSparkles && bIdx >= TARGET_BAR_IDX}
        />
      ))}
    </Row>
  );
}
VUMeter.displayName = 'VUMeter';
