import { ConfigSelector, SystemInfo } from '@lexialearning/utils-react';
import { ILoboAppConfig } from 'services/app-config';
import { IUtilsReactState } from '@lexialearning/utils-react/lib/bootstrapping/utils-react-redux.model';
import { useCallback } from 'react';
import { StudentProperty } from 'student-api';
import { StudentPropertyAction } from '@lexialearning/student-api';
import { TransitionAction } from 'feature-areas/transitions';
import { VideoPlayer } from 'common-ui/components/video-player';
import { VideoRole } from 'common-ui/components/video';
import { useDispatch, useSelector } from 'react-redux';

// ms to delay before fading out controls after user inactivity
const FADEOUT_DELAY_MS = 3200;

export function GettingStartedPage(): React.ReactElement {
  const { videosMountPoint } = useSelector((state: IUtilsReactState) =>
    ConfigSelector.getConfig<ILoboAppConfig>(state)
  );

  const dispatch = useDispatch();

  const transitionToCalibration = useCallback(() => {
    dispatch(
      StudentPropertyAction.update.request({
        studentProperties: [
          {
            key: StudentProperty.HasViewedOnboardingVideo,
            value: true
          }
        ]
      })
    );
    dispatch(TransitionAction.gettingStartedToCalibration());
  }, [dispatch]);

  const rootDir = `${videosMountPoint.webOrigin}${videosMountPoint.dir}getting-started`;

  const webChromebookPlaylist = [
    `${rootDir}/chromebook_2024_video_1_ver_2/01/chromebook_2024_video_1_ver_2_all.m3u8`,
    `${rootDir}/chromebook_2024_video_2/01/chromebook_2024_video_2_all.m3u8`
  ];

  const iOSPlaylist = [
    `${rootDir}/ipad_2024_video_1/01/ipad_2024_video_1_all.m3u8`,
    `${rootDir}/ipad_2024_video_2/01/ipad_2024_video_2_all.m3u8`
  ];

  const playlist = SystemInfo.isNative ? iOSPlaylist : webChromebookPlaylist;

  return (
    <VideoPlayer
      autoPlay
      controlsFadeoutDelay={FADEOUT_DELAY_MS}
      onPlaylistEnd={transitionToCalibration}
      playlist={playlist}
      videoRole={VideoRole.ShellCover}
    />
  );
}
GettingStartedPage.displayName = 'GettingStartedPage';
