import { uniq } from 'lodash';
import { ICompletedLevel } from '../../program-context/epics/student-progress-api/student-progress-api-private.model';
import { ILevelStatus, LevelStatus } from '../levels-completed.model';

export class LevelsStatusFactory {
  public static readonly displayName = 'LevelsStatusFactory';

  public static addCompletedLevel(
    completedLevelNumber: number,
    levelStatuses: ILevelStatus[]
  ): ILevelStatus[] {
    return levelStatuses.map(l =>
      l.levelNumber === completedLevelNumber
        ? { ...l, certificateViewed: false, status: LevelStatus.Completed }
        : l
    );
  }

  public static create(
    currentLevel: number | undefined,
    finalProgramLevel: number,
    completedLevels: number[] = [],
    levelCertificatesViewed: number[] = []
  ): ILevelStatus[] {
    const effectiveLevel = currentLevel ?? -1;

    return Array.from({ length: finalProgramLevel })
      .map((_, i) => i + 1)
      .map(levelNumber => {
        const status =
          levelNumber >= effectiveLevel
            ? LevelStatus.Incomplete
            : completedLevels.includes(levelNumber)
            ? LevelStatus.Completed
            : LevelStatus.Skipped;
        const certificateViewed =
          status === LevelStatus.Completed &&
          levelCertificatesViewed.includes(levelNumber);

        return {
          certificateViewed,
          levelNumber,
          status
        };
      });
  }

  public static createFromApiResult(
    currentLevel: number | undefined,
    finalProgramLevel: number,
    completedLevels: ICompletedLevel[],
    levelsStatus: ILevelStatus[],
    levelCertificatesViewed: number[] = []
  ): ILevelStatus[] {
    const completedArray = [
      ...completedLevels.map(l => parseInt(l.title, 10)),
      ...this.getLevelsLocallyMarkedCompleted(levelsStatus)
    ];
    const allLevelCertsViewed = uniq([
      ...levelCertificatesViewed,
      ...this.getLevelsLocallyMarkedAsCertificateViewed(levelsStatus)
    ]);

    return this.create(
      currentLevel,
      finalProgramLevel,
      completedArray,
      allLevelCertsViewed
    );
  }

  /**
   * Return level numbers marked as completed in local state. This caters to
   * the scenario where, for example, a level was just completed but the API
   * has yet to reflect this state (since there is an unknown amount of latency
   * there). So we trust local completion state over API completion state.
   */
  private static getLevelsLocallyMarkedCompleted(
    levelsStatus: ILevelStatus[]
  ): number[] {
    return levelsStatus
      .filter(l => l.status === LevelStatus.Completed)
      .map(l => l.levelNumber);
  }

  /**
   * Return level statuses marked as certificateViewed in local state. This caters to
   * the scenario where, for example, a level was just completed but the API
   * has yet to reflect this state (since there is an unknown amount of latency
   * there). So we trust local completion state over API completion state.
   */
  private static getLevelsLocallyMarkedAsCertificateViewed(
    levelsStatus: ILevelStatus[]
  ): number[] {
    return levelsStatus
      .filter(l => l.certificateViewed)
      .map(l => l.levelNumber);
  }
}
