import { first, map } from 'rxjs/operators';
import { OnboardingIntroScreenplayBuilder } from 'feature-areas/transitions/builders/onboarding';
import { ScreenplayAction } from 'screenplay';
import { StormService } from 'storm';
import {
  IUnitDeepLinkDeps,
  UnitDeepLinkObservableFactory
} from './UnitDeepLinkObservableFactory';

export interface IOnboardingDeepLinkDeps extends IUnitDeepLinkDeps {
  stormService: StormService;
}

export class OnboardingDeepLinkObservableFactory extends UnitDeepLinkObservableFactory {
  public static readonly displayName = 'OnboardingDeepLinkObservableFactory';

  public static createFor(
    deps: IOnboardingDeepLinkDeps
  ): OnboardingDeepLinkObservableFactory {
    return new OnboardingDeepLinkObservableFactory(deps)
      .loadProgramPosition()
      .thenPlayOnboardingIntroScreenplay();
  }

  constructor(protected readonly deps: IOnboardingDeepLinkDeps) {
    super(deps);
  }

  private thenPlayOnboardingIntroScreenplay(): OnboardingDeepLinkObservableFactory {
    const { state$, preparedScenes, stormService } = this.deps;

    const playOnboardingIntroScreenplay$ = state$.pipe(
      first(() => this.isReady()),
      map(
        () =>
          OnboardingIntroScreenplayBuilder.create()
            .showPlacementScene(preparedScenes, stormService)
            .dispatchOnboardingRoundIntro().screenplay
      ),
      map(screenplay => ScreenplayAction.play({ screenplay }))
    );
    this.deferredDispatches$.push(playOnboardingIntroScreenplay$);

    return this;
  }
}
