import { PositionChangeType } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  TaskActionEvaluated,
  TaskActionType
} from 'task-components/core/redux';
import { PredicateUtils } from 'utils/PredicateUtils';
import {
  ActivityPositionAction,
  ActivityPositionActionPrepared,
  ProgramContextSelector
} from '../../redux';

export function prepareNextPositionEpic(
  action$: Observable<TaskActionEvaluated>,
  state$: StateObservable<unknown>
): Observable<ActivityPositionActionPrepared> {
  return action$.pipe(
    ofType(TaskActionType.Evaluated),
    map(() => {
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const determiner =
        context.taskRegistration.createPositionDeterminer(context);
      const change = determiner.determine();

      return change.changeType === PositionChangeType.None
        ? undefined
        : ActivityPositionAction.prepared(change);
    }),
    filter(PredicateUtils.isDefined)
  );
}
prepareNextPositionEpic.displayName = 'prepareNextPositionEpic';
