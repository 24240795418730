import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { Path, Svg } from 'common-ui/components/svg';

export function FunFactsBubbleSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="92.411"
      height="81.628"
      style={{ position: Position.Absolute }}
    >
      <Path
        d="M12.405 70.507A20.008 20.008 0 0 1 0 52V20A20 20 0 0 1 20 0h52.411a20 20 0 0 1 20 20v32a20 20 0 0 1-20 20H22.569c-3.2 3.3-9.162 7.51-19.7 9.628a30.2 30.2 0 0 0 9.536-11.121Z"
        fill={Color.NearWhite}
      />
    </Svg>
  );
}
FunFactsBubbleSvg.displayName = 'FunFactsBubbleSvg';
