export class TextChoicesContainerStyles {
  private static readonly styles = {
    choiceContainer: {
      marginTop: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
