import { ILanguageFrameToken } from '@lexialearning/lobo-common';
import { CenterLeftAlignWrap } from 'common-ui/components/center-left-align-wrap/CenterLeftAlignWrap';
import { LanguageFrameToken } from './language-frame-token/LanguageFrameToken';
import { useSelector } from 'react-redux';
import { SeeSpeakSelector } from '../redux/SeeSpeak.selector';
import { useCallback } from 'react';
import { useTheme } from 'theme/useTheme';
import { TaskSelector } from 'task-components';
import { ProgramContextSelector } from 'curriculum-services';

export interface ILanguageFrameProps {
  width?: number;
}

export function LanguageFrame(props: ILanguageFrameProps) {
  const { width } = props;

  const { themeType } = useTheme();
  const languageFrameTokens = useSelector(
    SeeSpeakSelector.getLanguageFrameTokens
  );
  const mode = useSelector(SeeSpeakSelector.getMode)!;

  const feedbackInteractionState = useSelector(state =>
    TaskSelector.getFeedbackInteractionState(
      ProgramContextSelector.getLastEvaluationResultMaybe(state)
    )
  );

  const renderToken = useCallback(
    (token: ILanguageFrameToken, index: number) => (
      <LanguageFrameToken
        key={`token${index}`}
        feedbackInteractionState={feedbackInteractionState}
        index={index}
        token={token}
        mode={mode}
        themeType={themeType}
      />
    ),
    [feedbackInteractionState, mode, themeType]
  );

  return (
    <CenterLeftAlignWrap
      width={width}
      tokenArray={languageFrameTokens}
      renderToken={renderToken}
      testId={LanguageFrame.displayName}
    />
  );
}

LanguageFrame.displayName = 'LanguageFrame';
