import {
  AnimatableCSSProperty,
  LoboAnimatedValue,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import {
  IButtonMeterAnimatedValues,
  IButtonMeterStyles,
  IButtonMeterValues
} from './button-meter.model';

export class ButtonMeterAnimationHelper {
  public readonly animatedValues: IButtonMeterAnimatedValues;

  constructor(
    private readonly initialValues: IButtonMeterValues,
    private readonly meterOpenValues: IButtonMeterValues,
    private readonly styles: IButtonMeterStyles,
    additionalAnimatedValues?: {
      [key: string]: LoboAnimatedValue;
    }
  ) {
    this.animatedValues = this.createAnimatedValues(additionalAnimatedValues);
  }

  private createAnimatedValues(
    additionalAnimatedValues: {
      [key: string]: LoboAnimatedValue;
    } = {}
  ): IButtonMeterAnimatedValues {
    const animatedValues = {
      borderContainerOpacity: loboAnimated.createValue(
        this.initialValues.borderContainerOpacity
      ),
      borderContainerWidth: loboAnimated.createValue(
        this.initialValues.borderContainerWidth
      ),
      innerOpacity: loboAnimated.createValue(this.initialValues.innerOpacity),
      micOpacity: loboAnimated.createValue(this.initialValues.micOpacity),
      ...additionalAnimatedValues
    };

    this.styles.borderContainerAnimated.width =
      animatedValues.borderContainerWidth;
    this.styles.borderContainerAnimated.opacity =
      animatedValues.borderContainerOpacity;
    this.styles.vuMeterOpacityAnimated.opacity = animatedValues.innerOpacity;
    this.styles.micContainerOpacityAnimated.opacity = animatedValues.micOpacity;

    return animatedValues;
  }

  public createShowVuMeterAnimation(
    innerElementsHalfFadeInValues: {
      delay: number;
      duration: number;
    },
    speed: number,
    /** Additional animations to play after micFadeOut animation and before containerExpand animation */
    postMicFadeOutAnimations: Array<Types.Animated.CompositeAnimation> = [],
    /** Additional animations to play after containerExpand animation and in parallel with innerElementsFullFadeIn animation */
    postContainerExpandAnimations: Array<Types.Animated.CompositeAnimation> = []
  ) {
    const {
      containerExpand,
      innerElementsFullFadeIn,
      innerElementsHalfFadeIn,
      micFadeOut
    } = this.createShowVuMeterAnimationParts(
      innerElementsHalfFadeInValues,
      speed
    );

    return loboAnimated.sequence([
      micFadeOut,
      ...postMicFadeOutAnimations,
      loboAnimated.parallel([containerExpand, innerElementsHalfFadeIn]),
      loboAnimated.parallel([
        innerElementsFullFadeIn,
        ...postContainerExpandAnimations
      ])
    ]);
  }

  private createShowVuMeterAnimationParts(
    innerElementsHalfFadeInValues: {
      /** arbitrary minor delay to allow initial expansion before showing inner contents */
      delay: number;
      duration: number;
    },
    speed: number
  ) {
    const micFadeOutMs = 100;
    const expandMs = 500;

    const micFadeOut = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.micOpacity,
      {
        duration: micFadeOutMs * speed,
        toValue: this.meterOpenValues.micOpacity
      }
    );

    const containerExpand = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Width,
        this.animatedValues.borderContainerWidth,
        {
          duration: expandMs * speed,
          easing: loboAnimated.Easing.In(),
          toValue: this.meterOpenValues.borderContainerWidth
        }
      )
    ]);

    const innerElementsHalfFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.innerOpacity,
      {
        delay: innerElementsHalfFadeInValues.delay * speed,
        duration: innerElementsHalfFadeInValues.duration * speed,
        toValue: this.meterOpenValues.innerOpacity / 2
      }
    );

    const innerElementsFullFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.innerOpacity,
      {
        duration: 100 * speed,
        toValue: this.meterOpenValues.innerOpacity
      }
    );

    return {
      containerExpand,
      innerElementsFullFadeIn,
      innerElementsHalfFadeIn,
      micFadeOut
    };
  }

  public createResetAnimation(
    speed: number,
    /** Additional animations to play immediately before micFadeIn animation */
    preMicFadeInAnimations: Array<Types.Animated.CompositeAnimation> = []
  ) {
    const {
      borderContainerFadeOut,
      borderContainerOpacityReset,
      borderContainerWidthReset,
      innerElementsOpacityReset,
      micFadeIn
    } = this.createResetAnimationParts(speed);

    return loboAnimated.sequence([
      borderContainerFadeOut,
      borderContainerWidthReset,
      innerElementsOpacityReset,
      ...preMicFadeInAnimations,
      micFadeIn,
      borderContainerOpacityReset
    ]);
  }

  public createResetAnimationParts(speed: number) {
    const borderContainerFadeOut = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.borderContainerOpacity,
        {
          duration: 200 * speed,
          toValue: 0 // temp before reset back to 1
        }
      )
    ]);

    const borderContainerWidthReset = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Width,
        this.animatedValues.borderContainerWidth,
        {
          duration: 0,
          toValue: this.initialValues.borderContainerWidth
        }
      )
    ]);

    const innerElementsOpacityReset = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.innerOpacity,
        {
          duration: 0,
          toValue: this.initialValues.innerOpacity
        }
      )
    ]);

    const micFadeIn = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.micOpacity,
        {
          duration: 600 * speed,
          toValue: this.initialValues.micOpacity
        }
      )
    ]);

    const borderContainerOpacityReset = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.borderContainerOpacity,
        {
          duration: 0,
          toValue: this.initialValues.borderContainerOpacity
        }
      )
    ]);

    return {
      borderContainerFadeOut,
      borderContainerOpacityReset,
      borderContainerWidthReset,
      innerElementsOpacityReset,
      micFadeIn
    };
  }
}
