import { useDispatch } from 'react-redux';
import { IGlobalDisabledProps } from 'common-ui';
import {
  UtilityButton,
  UtilityButtonIconType
} from 'common-ui/components/button';
import { AppShellAction } from 'shell/redux/AppShell.action';
import { ContactSupportModal } from './ContactSupportModal';

export function ContactSupportButton(props: IGlobalDisabledProps) {
  const dispatch = useDispatch();
  const showContactSupport = () =>
    dispatch(AppShellAction.showModal({ id: ContactSupportModal.ModalId }));

  return (
    <UtilityButton
      {...props}
      iconType={UtilityButtonIconType.Tools}
      onPress={showContactSupport}
      ignoreGlobalDisabled
      testId={ContactSupportButton.displayName}
    />
  );
}
ContactSupportButton.displayName = 'ContactSupportButton';
