import { LexiaError } from '@lexialearning/utils';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { IAuthDetails, IAuthDetailsSso, UserRole } from 'lexia-service';
import { IAuth } from '../auth.model';
import { SsoActions } from './auth-redux.model';
import { AuthActionType } from './AuthActionType';

export const AuthAction = {
  authenticate: createAsyncAction(
    AuthActionType.Authenticate,
    AuthActionType.AuthenticateSuccess,
    AuthActionType.AuthenticateFailure
  )<IAuthenticateActionPayload, IAuthDetails, LexiaError>(),
  authenticatedByMdm: createAction(
    AuthActionType.AuthenticatedByMdm
  )<IAuthDetails>(),
  authenticatedBySso: createAction(
    AuthActionType.AuthenticatedBySso
  )<IAuthenticatedBySsoActionPayload>(),
  autoLogin: createAction(AuthActionType.AutoLogin)(),
  disableAutoLogin: createAction(AuthActionType.DisableAutoLogin)(),

  interacted: createAction(AuthActionType.Interacted)(),

  invalidToken: createAction(AuthActionType.InvalidToken)(),

  loginMdmUser: createAsyncAction(
    AuthActionType.LoginMdm,
    AuthActionType.LoginSuccess,
    AuthActionType.LoginFailure
  )<ILoginMdmUserActionPayload, IAuth, Error>(),

  loginSsoUser: createAsyncAction(
    AuthActionType.LoginSso,
    AuthActionType.LoginSuccess,
    AuthActionType.LoginFailure
  )<ILoginSsoUserActionPayload, IAuth, Error>(),

  loginUser: createAsyncAction(
    AuthActionType.Login,
    AuthActionType.LoginSuccess,
    AuthActionType.LoginFailure
  )<ILoginUserActionPayload | void, IAuth, Error>(),

  reLogin: createAction(AuthActionType.ReLogin)<IReLoginActionPayload>(),

  reloadApp: createAction(AuthActionType.ReloadApp)(),

  resetAuthError: createAction(AuthActionType.ResetAuthError)(),

  unauthenticate: createAction(AuthActionType.Unauthenticate)()
};

export interface IAuthenticateActionPayload {
  username: string;
  password: string;
}

export interface IAuthenticatedBySsoActionPayload extends IAuthDetailsSso {
  logoutUrl?: string;
}

export interface ILoginUserActionPayload {
  autoLogin?: boolean;
}

export interface ILoginSsoUserActionPayload {
  personId: number;
  userRole: UserRole;
  lexiaAuthToken: string;
}

export interface ILoginMdmUserActionPayload {
  username: string;
  password: string;
  teacherEmail: string;
}

export interface IReLoginActionPayload {
  ssoActions: SsoActions[];
}

export type AuthActionAuthenticate = ActionType<
  typeof AuthAction.authenticate.request
>;
export type AuthActionAuthenticateFailure = ActionType<
  typeof AuthAction.authenticate.failure
>;
export type AuthActionAuthenticateSuccess = ActionType<
  typeof AuthAction.authenticate.success
>;
export type AuthActionAuthenticatedByMdm = ActionType<
  typeof AuthAction.authenticatedByMdm
>;
export type AuthActionAuthenticatedBySso = ActionType<
  typeof AuthAction.authenticatedBySso
>;
export type AuthActionAutoLogin = ActionType<typeof AuthAction.autoLogin>;
export type AuthActionDisableAutoLogin = ActionType<
  typeof AuthAction.disableAutoLogin
>;
export type AuthActionInteracted = ActionType<typeof AuthAction.interacted>;
export type AuthActionLoginUser = ActionType<
  typeof AuthAction.loginUser.request
>;
export type AuthActionInvalidToken = ActionType<typeof AuthAction.invalidToken>;
export type AuthActionLoginUserFailure = ActionType<
  typeof AuthAction.loginUser.failure
>;
export type AuthActionLoginUserSuccess = ActionType<
  typeof AuthAction.loginUser.success
>;
export type AuthActionLoginMdmUser = ActionType<
  typeof AuthAction.loginMdmUser.request
>;
export type AuthActionLoginSsoUser = ActionType<
  typeof AuthAction.loginSsoUser.request
>;
export type AuthActionReloadApp = ActionType<typeof AuthAction.reloadApp>;
export type AuthActionReLoginUser = ActionType<typeof AuthAction.reLogin>;

export type AuthActions = ActionType<typeof AuthAction>;
