import { TaskPhase } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { ProgramContextSelector } from 'curriculum-services';
import { PostFeedbackPhaseDeterminerFactory } from 'task-components/core/epics/feedback-completion/PostFeedbackPhaseDeterminerFactory';
import {
  TaskAction,
  TaskActionExit,
  TaskActionInteractive,
  TaskActionSolution
} from '../../redux';

export type FeedbackCompletionOutputActions =
  | TaskActionExit
  | TaskActionInteractive
  | TaskActionSolution;

export class FeedbackCompletionHandler {
  public static displayName = 'FeedbackCompletionHandler';

  public static handle(state: unknown): FeedbackCompletionOutputActions {
    const context = ProgramContextSelector.getRoundContext(state);
    const determiner = PostFeedbackPhaseDeterminerFactory.create(context);
    const phase = determiner.determine();

    switch (phase) {
      case TaskPhase.Interactive:
        return TaskAction.interactive();

      case TaskPhase.Exit:
        return TaskAction.exit();

      case TaskPhase.Solution:
        return TaskAction.solution();

      default:
        throw new LexiaError(
          `Unsupported phase ${phase}`,
          FeedbackCompletionHandler.displayName,
          FeedbackCompletionHandlerError.UnknownPhase
        ).withContext({ phase });
    }
  }
}

export enum FeedbackCompletionHandlerError {
  UnknownPhase = 'UnknownPhase'
}
