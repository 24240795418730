import {
  Fetcher,
  FetchMethod,
  IFetchAttemptContext,
  IFetchRetryOptions,
  LexiaError
} from '@lexialearning/utils';
import {
  ICompletedLevel,
  IProgressResponse,
  ISendStudentUpdateRequest,
  StudentProgressAskArrayFor,
  StudentProgressAskArrayType
} from '../../program-context/epics/student-progress-api/student-progress-api-private.model';

export interface ILevelsCompletedLoaderOptions {
  authToken: string;
  retryThrottleSeconds: number[];
  studentId: number;
  url: string;
  version: string;
}

export enum LevelsCompletedLoaderError {
  FetchError = 'FetchError',
  FalsyCompletedLevels = 'FalsyCompletedLevels'
}

export class LevelsCompletedLoader {
  public static readonly displayName = 'LevelsCompletedLoader';

  public static async load(
    options: ILevelsCompletedLoaderOptions
  ): Promise<ICompletedLevel[]> {
    const body: ISendStudentUpdateRequest = {
      askArray: [
        {
          __type__: StudentProgressAskArrayType.Ask,
          for: StudentProgressAskArrayFor.CompletedLevels
        }
      ],
      attemptNum: 1,
      authToken: options.authToken,
      requestNum: 0,
      studentId: options.studentId,
      version: options.version
    };

    try {
      const response = await Fetcher.fetch<
        IProgressResponse,
        ISendStudentUpdateRequest
      >(options.url, {
        body,
        getRetryOptions: this.getRetryOptions,
        method: FetchMethod.Post,
        retryThrottleSeconds: options.retryThrottleSeconds
      });

      if (!response.completedLevelsArray) {
        throw new LexiaError(
          'Unexpected falsy completed levels array',
          LevelsCompletedLoader.displayName,
          LevelsCompletedLoaderError.FalsyCompletedLevels
        ).withContext({ options, response });
      }

      return response.completedLevelsArray;
    } catch (err) {
      throw err instanceof LexiaError &&
        err.code === LevelsCompletedLoaderError.FalsyCompletedLevels
        ? err
        : new LexiaError(
            'Error fetching completed levels',
            LevelsCompletedLoader.displayName,
            LevelsCompletedLoaderError.FetchError
          )
            .withCause(err)
            .withContext({ options });
    }
  }

  private static getRetryOptions(
    ctx: IFetchAttemptContext<ISendStudentUpdateRequest>
  ): IFetchRetryOptions<ISendStudentUpdateRequest> {
    const attemptNum = ctx.attempts.length + 1;

    const revisedBody = ctx.options?.body && {
      ...ctx.options.body,
      attemptNum
    };

    return {
      revisedBody,
      shouldRetry:
        attemptNum <= (ctx.options?.retryThrottleSeconds?.length ?? 0)
    };
  }
}
