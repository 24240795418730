import {
  CurriculumDependencies,
  ProgramContextSelector,
  ProgressSelector
} from 'curriculum-services';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PreparedScenes } from 'services/storm-lobo';
import { TransitionActionHomeToEncounter } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { ScreenplayActionPlay, ScreenplayAction } from 'screenplay';
import { HomeToActTransitionBuilder } from './HomeToActTransitionBuilder';
import { ActUiSelector } from 'feature-areas/acts';
import { LexiaError } from '@lexialearning/utils';
import { ILevelToActTransitionScreenplayDeps } from '../level-to-act/LevelToActTransitionScreenplayFactory';
import { LevelScene } from 'feature-areas/levels';

export interface IHomeToEncounterDeps {
  curriculumDependencies: CurriculumDependencies;
  preparedScenes: PreparedScenes;
}

export function homeToActTransitionEpic(
  action$: Observable<TransitionActionHomeToEncounter>,
  state$: StateObservable<unknown>,
  deps: IHomeToEncounterDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.HomeToEncounter),
    switchMap(async action => {
      const { preparedScenes } = deps;
      const { activityId } = action.payload;
      const levelScene = await preparedScenes.levelReady;
      const actIntroDependencies = getActIntroDependencies(
        state$.value,
        levelScene
      );

      const { screenplay } = HomeToActTransitionBuilder.create()
        .playHomeOutro()
        .navToActShowcase(activityId)
        .playActIntro(preparedScenes, actIntroDependencies);

      return screenplay;
    }),
    map(screenplay =>
      ScreenplayAction.play({
        screenplay
      })
    )
  );
}
homeToActTransitionEpic.displayName = 'homeToActTransitionEpic';

function getActIntroDependencies(
  state: unknown,
  levelScene: LevelScene
): ILevelToActTransitionScreenplayDeps {
  const context = ProgramContextSelector.getEncounterContext(state);
  const { act, encounter } = context;
  const funFactData = ActUiSelector.getAvailableFunFactData(state);
  const selectedActNumber = levelScene.getSelectedActNumber();

  return {
    act,
    actsEntered: [], // this should alway be empty because the user has just logged back in
    currentEncounter: context.findEncounterIndex() + 1,
    encounterId: encounter.sysId,
    encounterPercentCompleted: getEncounterPercentComplete(state, act.sysId),
    hasFunFact: funFactData.playbacksRemaining > 0,
    selectedActNumber
  };
}

function getEncounterPercentComplete(state: unknown, actId: string) {
  const actProgress = ProgressSelector.getProgress(state).find(
    ap => ap.sysId === actId
  );
  const encounterPosition = actProgress?.encounterPositions.find(
    ep => !ep.isComplete
  );

  if (!encounterPosition) {
    throw new LexiaError(
      `No encounter position found for act ${actId}`,
      homeToActTransitionEpic.displayName,
      HomeToActTransitionEpicError.NoEncounterPosition
    ).withContext({ actId, actProgress });
  }

  return encounterPosition.percentComplete;
}

export enum HomeToActTransitionEpicError {
  NoEncounterPosition = 'NoEncounterPosition'
}
