import { ActionType, ILogger, LoggingLevel } from '@lexialearning/main-model';
import { PredicateUtils } from '@lexialearning/utils';
import {
  ITimingDetail,
  ITimingEventPayload,
  LoboLogItemCategory
} from 'logging';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ITimingAction, Timing } from './Timing';

export class TimingSet {
  public static readonly displayName = 'TimingSet';

  public static create(
    name: string,
    logger: ILogger,
    loggingLevel: LoggingLevel = LoggingLevel.Verbose
  ): TimingSet {
    return new TimingSet(name, logger, loggingLevel);
  }

  private readonly timings: Timing[] = [];

  private readonly subscriptions: Subscription[] = [];

  private constructor(
    public readonly name: string,
    private readonly logger: ILogger,
    private readonly loggingLevel: LoggingLevel
  ) {}

  public addTiming(
    name: string,
    startAction: ITimingAction,
    endAction: ITimingAction
  ): TimingSet {
    this.timings.push(new Timing(name, startAction, endAction));

    return this;
  }

  private isComplete(): boolean {
    return this.timings.every(t => t.endTime);
  }

  private isApplicablePayload(
    currentAction: ActionType<any>,
    awaitedAction?: ITimingAction
  ): boolean {
    return Object.entries(awaitedAction?.payload || {}).every(
      ([k, v]) => v === currentAction.payload?.[k]
    );
  }

  public startMonitoring(action$: Observable<ActionType<any>>): void {
    const startMap = this.timings.reduce(
      (m, t) => m.set(t.startAction.type, t),
      new Map<string, Timing>()
    );
    const endMap = this.timings.reduce(
      (m, t) => m.set(t.endAction.type, t),
      new Map<string, Timing>()
    );

    const s1 = action$
      .pipe(
        map(a => {
          const timing = startMap.get(a.type);

          if (this.isApplicablePayload(a, timing?.startAction)) {
            return timing;
          }
        }),
        filter(PredicateUtils.isDefined)
      )
      .subscribe(timing => {
        timing!.startTime = new Date();
      });

    const s2 = action$
      .pipe(
        map(a => {
          const timing = endMap.get(a.type);

          if (this.isApplicablePayload(a, timing?.endAction)) {
            return timing;
          }
        }),
        filter(PredicateUtils.isDefined)
      )
      .subscribe(t => {
        t!.endTime = new Date();
        if (this.isComplete()) {
          this.log();
          this.reset();
        }
      });

    this.subscriptions.push(s1, s2);
  }

  private log(): void {
    const start = Math.min(...this.timings.map(t => t.startTime.valueOf()));
    const end = Math.max(...this.timings.map(t => t.endTime!.valueOf()));
    const payload: ITimingEventPayload = {
      durationMs: end - start,
      end: new Date(end).toISOString(),
      setName: this.name,
      start: new Date(start).toISOString(),
      timings: this.timings.map(
        (t): ITimingDetail => ({
          durationMs: t.endTime!.valueOf() - t.startTime.valueOf(),
          end: t.endTime!.toISOString(),
          name: t.name,
          start: t.startTime.toISOString()
        })
      )
    };

    // eslint-disable-next-line no-console
    // console.log('TIMING LOGGED', {
    //   data,
    //   eventName: LoboLogEventName.Timing,
    //   loggingLevel: this.loggingLevel,
    //   summary: `${this.name} took ${data.durationMs / 1000}s`
    // });

    void this.logger.log({
      category: LoboLogItemCategory.Timing,
      loggingLevel: this.loggingLevel,
      payload,
      summary: `${this.name} took ${payload.durationMs / 1000}s`
    });
  }

  private reset(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.timings.forEach(t => {
      t.startTime = new Date();
      t.endTime = undefined;
    });
  }
}
