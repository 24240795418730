import { Color } from 'common-styles';

const ButtonColors = {
  DefaultText: Color.NearWhite,
  Disabled: Color.Gray70,
  Enabled: Color.NearWhite,
  EnabledPrimary: Color.LexiaPurple
};

const BackgroundColors = {
  Default: Color.NearWhite,
  Disabled: Color.Gray50,
  Enabled: Color.LimeGreenPop2
};

const TextColors = {
  Default: Color.NearWhite,
  Error: Color.CrimsonRed,
  Opposite: Color.Black,
  Primary: Color.Text
};

const LoboColors = {
  BackgroundColors,
  Black: Color.Black,
  Border: Color.Gold,
  ButtonColors,
  Error: Color.CrimsonRed,
  Primary: Color.Gold,
  Secondary: Color.Gold,
  Text: Color.Black,
  TextColors, // default text color
  /*
  LOBO-16882 Quick fix for colors incorrectly set in Uni for modal title text,
  future work captured in UNI-2436.
  This color, theme should NOT be used anywhere in the code base.
  */
  // TextOpposite: Color.NearWhite,
  TextOpposite: Color.BlackTransparent80,
  Transparent: Color.Transparent,
  White: Color.NearWhite
};

export const LoboTheme = {
  Color: LoboColors,
  Logo: ''
};
