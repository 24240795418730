import { Border, Position, ScrollSnapAlign } from '@lexialearning/common-ui';
import {
  Align,
  Color,
  Direction,
  Font,
  Justify,
  TextAlign
} from 'common-styles';
import { SystemInfo } from 'utils';

export class ActDetailStyles {
  private static readonly styles = {
    actContainer: {
      alignItems: Align.Start,
      borderRadius: 10,
      height: 640,
      justifyContent: Justify.Start,
      width: 740,
      ...(!SystemInfo.isNative && { scrollSnapAlign: ScrollSnapAlign.Start })
    },
    arc: {
      alignItems: Align.Center,
      flex: 1,
      flexDirection: Direction.Column,
      height: 300,
      justifyContent: Justify.Start,
      width: 330
    },
    characterContainer: {
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
      flexDirection: Direction.Column,
      height: 640,
      justifyContent: Justify.Start,
      width: 330
    },
    characterImage: {
      height: 320,
      marginTop: 30,
      width: 180
    },
    characterName: {
      fontSize: 16,
      paddingTop: 5,
      textAlign: TextAlign.Left
    },
    characterNameContainer: {
      borderColor: Color.Text,
      borderStyle: Border.Solid,
      borderTopWidth: 1
    },
    encounterContent: {
      flex: 1
    },
    encounterDescContainer: {
      justifyContent: Justify.SpaceBetween,
      width: 360
    },
    encounterTabs: {
      justifyContent: Justify.End
    },
    levelCharInfo: {
      alignItems: Align.Start,
      left: 20,
      position: Position.Absolute,
      top: 20
    },
    levelNumber: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.large,
      textAlign: TextAlign.Left
    }
  };

  public static get() {
    return this.styles;
  }
}
