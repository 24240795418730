import {
  ISeeSpeak,
  ITaskAttempt,
  PositionChangeType,
  TaskTypeName
} from '@lexialearning/lobo-common';
import { last } from 'lodash';
import { PositionPathBuilder } from '../helpers';
import {
  ActivityPositionBuilder,
  IActivityPosition,
  RoundContext
} from 'curriculum-services';
import { ITaskAttemptEventPayload } from 'logging';
import { TaskRegistry } from 'task-components';
import { ISreSessionAnswer } from 'task-components/see-speak';
import { IToken } from '@lexialearning/sre';

export class TaskAttemptEventDataFactory {
  public static readonly displayOnly = 'TaskAttemptEventDataFactory';

  public static create(
    context: RoundContext,
    taskRegistry: TaskRegistry
  ): ITaskAttemptEventPayload {
    const factory = new TaskAttemptEventDataFactory(context, taskRegistry);

    return factory.create();
  }

  private constructor(
    private readonly context: RoundContext,
    private readonly taskRegistry: TaskRegistry
  ) {}

  private create(): ITaskAttemptEventPayload {
    const { activityPosition, mainUnit, parentUnit, round, subunitType } =
      this.context;
    const { isScored, sysId: taskId, taskType } = this.context.getTask();
    const activityPositionBuilder =
      ActivityPositionBuilder.create(activityPosition);
    const attempt = this.normalizeAnswer(
      this.trimSeeSpeakAnswer(taskType, activityPositionBuilder)
    );

    return {
      activityPosition: this.removeAttempts(activityPositionBuilder),
      attempt,
      imminentPositionChangeType: this.determineImminentPositionChangeType(),
      instructionalStep: parentUnit.instructionalStep,
      isScored,
      mainUnitId: mainUnit.sysId,
      parentUnitId: parentUnit.sysId,
      roundId: round.sysId,
      roundPath: this.createRoundPath(),
      subunitType,
      taskId,
      taskSubtype: this.determineTaskSubtype(),
      taskType,
      unitType: parentUnit.type
    };
  }

  private determineImminentPositionChangeType(): PositionChangeType {
    const determiner = this.context.taskRegistration.createPositionDeterminer(
      this.context
    );
    determiner.suppressImminentPositionUnexpectedError = true;

    return determiner.determine().changeType;
  }

  private removeAttempts(builder: ActivityPositionBuilder): IActivityPosition {
    return builder.updateActiveUnitPosition({ attempts: [] }).activityPosition;
  }

  private normalizeAnswer(attemptOriginal: any): ITaskAttempt {
    const attempt = { ...attemptOriginal };
    const originalAnswer = attempt.answer;

    if (attempt.answer === null) {
      attempt.answer = { value: '(null)' };
    } else if (typeof attempt.answer !== 'object') {
      attempt.answer = {
        valueJson:
          typeof attempt.answer === 'string'
            ? attempt.answer
            : JSON.stringify(attempt.answer)
      };
    } else if (Array.isArray(attempt.answer)) {
      attempt.answer = { valueArray: attempt.answer };
    }

    if (attempt.answer !== originalAnswer) {
      // This means the task is submitting a non-object answer, which does not log well
      // eslint-disable-next-line no-console
      console.warn('Malformed evaluation attempt answer has been normalized');
      attempt.answer.WARNING = 'WARNING: malformed answer';
    }

    return attempt;
  }

  private trimSeeSpeakAnswer(
    taskType: TaskTypeName,
    activityPositionBuilder: ActivityPositionBuilder
  ): ITaskAttempt {
    const attempt = last(activityPositionBuilder.activeUnitPosition.attempts)!;

    if (taskType !== TaskTypeName.SeeSpeak) {
      return attempt;
    }

    const { answer } = attempt as ITaskAttempt<ISreSessionAnswer>;

    const { options }: { options: any } = answer;
    if (options?.scoring) {
      delete options.scoring;
      options.tokens = options.tokens.map(({ text, wordType }: IToken) => ({
        text,
        wordType
      }));
    }

    return attempt;
  }

  private determineTaskSubtype(): string {
    const task = this.context.getTask<ISeeSpeak>();

    return task.taskType === TaskTypeName.SeeSpeak ? task.mode : '';
  }

  private createRoundPath(): string {
    const { path } = PositionPathBuilder.createFromContext(
      this.context,
      this.taskRegistry
    )
      .addLevel()
      .addActivity()
      .addEncounter()
      .addMainUnit()
      .addMainRound()
      .addSubunits();

    return `${path}`;
  }
}
