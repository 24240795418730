import {
  HomeReactAnimationName,
  HomeSceneAnimationName,
  HomeSceneElementName
} from 'feature-areas/home';
import { CurtainReactAnimationName } from 'feature-areas/shell/curtain/curtain.model';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes } from 'services/storm-lobo/PreparedScenes';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { HomeScreenplayId } from './home-screenplay-builders.model';

export interface IHomeShowScreenplayDeps {
  preparedScenes: PreparedScenes;
  levelNum: number;
}

/**
 * Creates a screenplay to immediately display all elements of the
 * home page without animated intro
 * - *no* storm or react animations, *nor* playing voiceovers
 */
export class HomeShowScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'HomeShowScreenplayBuilder';

  public static createFor(
    deps: IHomeShowScreenplayDeps
  ): HomeShowScreenplayBuilder {
    return new HomeShowScreenplayBuilder(deps);
  }

  private constructor(deps: IHomeShowScreenplayDeps) {
    super(HomeScreenplayId.Show);

    const { preparedScenes, levelNum } = deps;

    this.disableUtilityBar()
      .fadeInOverlay()
      .navToHomePage()
      .showReactElements()
      .showHomeScene(preparedScenes)
      .fadeOutOverlay()
      .enableUtilityBar()
      .playLevelMeterIdle(levelNum)
      .playHomeSceneIntro()
      .enableMic();
  }

  private fadeInOverlay(): HomeShowScreenplayBuilder {
    this.builder.addReactAnimation(CurtainReactAnimationName.BackToHomeFadeIn);

    return this;
  }

  private navToHomePage(): HomeShowScreenplayBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.home());
    });

    return this;
  }

  private showReactElements(): HomeShowScreenplayBuilder {
    this.builder.addReactAnimation(HomeReactAnimationName.Show);

    return this;
  }

  private showHomeScene(
    preparedScenes: PreparedScenes
  ): HomeShowScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const homeScene = await preparedScenes.homeReady;
        preparedScenes.level?.hide();
        homeScene.show();
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  private fadeOutOverlay(): HomeShowScreenplayBuilder {
    this.builder.addReactAnimation(
      CurtainReactAnimationName.BackToHomeFadeOut,
      { concurrent: true }
    );

    return this;
  }

  private playLevelMeterIdle(levelNum: number): HomeShowScreenplayBuilder {
    this.builder.addStormAnimation(
      {
        name: HomeSceneAnimationName.LevelMeter.buildIdle(levelNum),
        targetElement: HomeSceneElementName.LevelMeter,
        targetScene: SceneName.Home
      },
      { concurrent: true }
    );

    return this;
  }

  private playHomeSceneIntro(): HomeShowScreenplayBuilder {
    this.builder.addStormAnimation({
      name: HomeSceneAnimationName.Root.Intro,
      speed: 1.5,
      targetScene: SceneName.Home
    });

    return this;
  }
}
