import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { InteractionState } from 'common-styles';
import { MicActions } from './Mic.action';
import { IMicState, MicActionType } from './mic.model';

export const micInitialState: IMicState = {
  forceDisabled: false,
  interactionState: InteractionState.Disabled
};

export function micReducer(
  state: IMicState = micInitialState,
  action: MicActions | UserGlobalActionLogoutSuccess
): IMicState {
  switch (action.type) {
    case MicActionType.SetInteractionState:
      return {
        ...state,
        interactionState: action.payload
      };

    case MicActionType.SetForceDisabled:
      return {
        ...state,
        forceDisabled: action.payload
      };

    case UserGlobalActionType.LogoutSuccess:
      return micInitialState;

    default:
      return state;
  }
}
