import { createSelector } from '@reduxjs/toolkit';
import { MeterMarkerProgressStatus } from '../../meterMarker.model';
import { IMeterMarker, IMeterState } from '../../progress-meters.model';

type UnitMeterSelectorType = (state: any) => IMeterState;

export class UnitMeterSelector {
  public static getState: UnitMeterSelectorType;

  public static getMarkers: (state: unknown) => IMeterMarker[];

  /**
   * Return index of marker with status Active or, if no Active marker found,
   * returns index of last marker with status Completed
   * (so that this should always be the index of the current unit)
   */
  public static getActiveOrLastCompletedMarkerIndex: (state: unknown) => number;

  public static createSelectors(selector: UnitMeterSelectorType) {
    UnitMeterSelector.getState = createSelector(
      selector,
      (state: IMeterState) => state
    );
    UnitMeterSelector.getMarkers = createSelector(
      UnitMeterSelector.getState,
      (state: IMeterState) => state.markers
    );
    UnitMeterSelector.getActiveOrLastCompletedMarkerIndex = createSelector(
      UnitMeterSelector.getMarkers,
      (markers: IMeterMarker[]) => {
        const activeUnitMarkerIndex = markers.findIndex(
          m => m.status === MeterMarkerProgressStatus.Active
        );

        return activeUnitMarkerIndex > -1
          ? activeUnitMarkerIndex
          : markers
              .map(m => m.status)
              .lastIndexOf(MeterMarkerProgressStatus.Completed);
      }
    );
  }
}
