import {
  IActivityPosition,
  PositionChangeType
} from '@lexialearning/lobo-common';
import { PositionDeterminer } from '../../program-context/epics/progress/position-determiners/PositionDeterminer';

export class OnboardingPositionDeterminer extends PositionDeterminer {
  public static readonly displayName = 'OnboardingPositionDeterminer';

  protected get maxAttempts(): number {
    return 3;
  }

  protected determineChangeType(): PositionChangeType {
    const superChangeType = super.determineChangeType();

    return superChangeType === PositionChangeType.ActivityCompletion
      ? PositionChangeType.OnboardingCompletion
      : superChangeType;
  }

  protected buildActivityPosition(
    changeType: PositionChangeType
  ): IActivityPosition {
    return changeType === PositionChangeType.OnboardingCompletion
      ? this.toPlacement()
      : super.buildActivityPosition(changeType);
  }

  protected toPlacement(): IActivityPosition {
    const activity = this.context.level.acts[1];
    const encounter = activity.encounters[0];
    const unit = encounter.units[0];
    const { activityPosition } = this.activityPositionBuilder
      .removeSubunitPositions()
      .resetAllUnitPositions()
      .update({
        activityId: activity.sysId,
        encounterId: encounter.sysId
      })
      .withActiveUnitPosition(unit);

    return activityPosition;
  }
}
