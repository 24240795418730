import { useDispatch } from 'react-redux';
import {
  UtilityButton,
  UtilityButtonIconType
} from 'common-ui/components/button';
import { AccountSettingsModal } from 'feature-areas/account-settings';
import { AppShellAction } from '../redux/AppShell.action';

export function SettingsButton() {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(AppShellAction.showModal({ id: AccountSettingsModal.ModalId }));
  };

  return (
    <UtilityButton
      iconType={UtilityButtonIconType.Gear}
      onPress={onPress}
      testId={SettingsButton.displayName}
    />
  );
}
SettingsButton.displayName = 'SettingsButton';
