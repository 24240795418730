import { Position } from '@lexialearning/common-ui';

export class CharacterSpeechBubbleTailStyles {
  private static styles = {
    position: Position.Absolute,
    left: -44.5,
    top: 16
  };

  public static get() {
    return this.styles;
  }
}
