import { IStateManager } from '@lexialearning/lobo-common/lib';
import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { LexiaService } from '../LexiaService';
import { LexiaServiceSelector } from '../redux/LexiaService.selector';
import {
  CustomerApiEndpoint,
  IGetCustomerResponse,
  IRegisterRequestBase,
  IRegisterRequestWithCustomerCode,
  IRegisterRequestWithEmail
} from './customer-api-private.model';
import { ICustomerInfo } from './customer-api.model';

export class CustomerApi {
  public static readonly displayName = 'CustomerApi';

  private get registerRequest(): IRegisterRequestBase {
    return LexiaServiceSelector.getLexiaServiceRequests(
      this.stateManager.getState()
    ).register;
  }

  private get ApiUrl() {
    return {
      Get: this.lexiaApiService.createUrl(CustomerApiEndpoint.GetCustomer)
    };
  }

  public constructor(
    private readonly lexiaApiService: LexiaService,
    private readonly stateManager: IStateManager
  ) {}

  public async getCustomerByEmail(email: string): Promise<ICustomerInfo> {
    const request: IRegisterRequestWithEmail = {
      ...this.registerRequest,
      email
    };

    const response =
      await this.lexiaApiService.postRequest<IGetCustomerResponse>(
        request,
        this.ApiUrl.Get
      );

    if (!response.valid) {
      throw new LexiaError(
        'Invalid Email',
        CustomerApi.displayName,
        CustomerApiError.InvalidRegistration
      ).withStandardCode(LexiaStandardErrorCode.UnknownCustomer);
    }

    const { customerName, customerCode, ssoLink, settings = {} } = response;
    this.storeCustomerCode(customerCode);

    return {
      customerCode,
      customerName,
      settings,
      ssoLink
    };
  }

  public async getCustomerByCode(code: string): Promise<ICustomerInfo> {
    const request: IRegisterRequestWithCustomerCode = {
      ...this.registerRequest,
      customerCode: code
    };

    const response =
      await this.lexiaApiService.postRequest<IGetCustomerResponse>(
        request,
        this.ApiUrl.Get
      );

    if (!response.valid) {
      throw new LexiaError(
        'Invalid Customer Code',
        CustomerApi.displayName,
        CustomerApiError.InvalidCustomerCode
      )
        .withContext({ code, response })
        .withStandardCode(LexiaStandardErrorCode.InvalidSiteId);
    }

    const { customerName, customerCode, ssoLink, settings = {} } = response;
    this.storeCustomerCode(customerCode);

    return {
      customerCode,
      customerName,
      settings,
      ssoLink
    };
  }

  private storeCustomerCode(customerCode: string): void {
    this.lexiaApiService.updateRequestBase({
      authenticate: { customer_code: customerCode }
    });
  }
}

export enum CustomerApiError {
  InvalidCustomerCode = 'InvalidCustomerCode',
  InvalidRegistration = 'InvalidRegistration'
}
