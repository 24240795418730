import {
  ActivityPositionMap,
  IAct,
  IActivityPosition,
  IEncounter,
  ILevel,
  IProgramNode,
  IUnit,
  IUnitSnipped
} from '@lexialearning/lobo-common/main-model';
import { NumberUtils } from 'utils';

export class EncounterContext {
  public static readonly displayName = 'EncounterContext';

  public get level(): ILevel {
    return this.levelNode.content;
  }

  public get levelNode(): IProgramNode<ILevel> {
    return this.actNode.parent as any;
  }

  public get levelNumber(): number {
    return NumberUtils.parse(this.level.title);
  }

  public get act(): IAct {
    return this.actNode.content;
  }

  public get actNode(): IProgramNode<IAct, ILevel, IEncounter> {
    return this.encounterNode.parent as any;
  }

  public get encounter(): IEncounter {
    return this.encounterNode.content;
  }

  public get atLastEncounter(): boolean {
    return !this.encounterNode.next;
  }

  /**
   * Returns true if student's position is the first round of the first unit of
   * the first encounter of an act
   */
  public get isStartOfAct(): boolean {
    const isFirstEncounter =
      this.act.encounters[0].sysId === this.activityPosition.encounterId;

    return isFirstEncounter && this.isStartOfEncounter;
  }

  /**
   * Returns true if student's position is the first round of the first unit of
   * an encounter
   */
  public get isStartOfEncounter(): boolean {
    const { unitPosition } = this.activityPosition;
    const isOnSubunit = unitPosition.unitId !== this.unitMain.sysId;
    const isFirstUnit =
      !isOnSubunit && this.encounter.units[0].sysId === this.unitMain.sysId;
    const isFirstRound =
      !unitPosition.roundId ||
      this.unitMain.rounds[0].sysId === unitPosition.roundId;

    return isFirstUnit && isFirstRound;
  }

  /**
   * Note the unitMain is only the main unit (never a subunit) and is the content from
   * ILevel.IEncounter.units, not the full unit content.
   *
   * @param encounterNode - active encounter node
   * @param activityPositionMap - Map of activity positions with activity id as the key
   * @param activityPosition - active activity position
   * @param unitMain - current main unit content from encounter content
   */
  constructor(
    public readonly encounterNode: IProgramNode<IEncounter, IAct, IUnit>,
    public readonly activityPositionMap: ActivityPositionMap,
    public readonly activityPosition: IActivityPosition,
    public readonly unitMain: IUnitSnipped
  ) {}

  public findEncounterIndex(): number {
    return this.act.encounters.findIndex(e => e.sysId === this.encounter.sysId);
  }
}
