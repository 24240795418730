import {
  InstructionalStep,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UnitSelector } from 'curriculum-services';
import {
  LanguageFrameSession as LF,
  SreSessionActionListenResult,
  SreSessionActionType,
  SreSessionType
} from 'sre';
import { TaskAction, TaskActionEvaluated } from 'task-components/core';
import { SeeSpeakSelector } from '../redux/SeeSpeak.selector';
import { CalibrationResult } from '@lexialearning/sre';
import { AppState } from 'services';

export function evaluateSreSessionEpic(
  action$: Observable<SreSessionActionListenResult>,
  state$: StateObservable<AppState>
): Observable<TaskActionEvaluated> {
  return action$.pipe(
    ofType(SreSessionActionType.ListenResult),
    filter(
      action =>
        action.payload.sessionOptions.sessionType ===
        SreSessionType.LanguageFrame
    ),
    map(action => {
      const result = action.payload.result as LF.IResult;
      const options = action.payload.sessionOptions;
      const state = state$.value;
      const { calibrationResult } = result.audioQuality;
      const answer = { calibrationResult, options, result };
      const isChoral = SeeSpeakSelector.isChoral(state);
      const isInstruction =
        UnitSelector.getInstructionalStep(state) ===
        InstructionalStep.Instruction;
      const shouldReturnCorrect =
        result.passed || isChoral || (isInstruction && result.sreRawScore >= 3);
      const hasMicError = calibrationResult !== CalibrationResult.Ok;

      const payload = {
        answer,
        result: shouldReturnCorrect
          ? TaskEvaluationResult.Correct
          : hasMicError
          ? TaskEvaluationResult.Inconclusive
          : TaskEvaluationResult.Incorrect
      };

      return TaskAction.evaluated(payload);
    })
  );
}
evaluateSreSessionEpic.displayName = 'evaluateSreSessionEpic';
