import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import {
  IActionOptions,
  IScreenplayActionFactory,
  ScreenplayerType
} from '../screenplayer.model';
import { IScreenplayCallback } from './callback-player.model';

export class CallbackActionFactory
  implements IScreenplayActionFactory<IScreenplayCallback>
{
  public static create(
    data: IScreenplayCallback,
    actionOptions?: Partial<IActionOptions>
  ): IScreenplayAction<IScreenplayCallback> {
    return {
      data,
      type: ScreenplayerType.Callback,
      ...actionOptions
    };
  }

  public readonly type = ScreenplayerType.Callback;

  public create(
    data: IScreenplayCallback,
    actionOptions?: Partial<IActionOptions>
  ): IScreenplayAction<IScreenplayCallback> {
    return CallbackActionFactory.create(data, actionOptions);
  }
}
