import { IScreenplay } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, sample } from 'lodash';
import { StateObservable } from 'redux-observable';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import {
  ActivityPositionAction,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import { ActivityCarouselModal } from 'feature-areas/educator/level-tab-content/level-acts-carousel/ActivityCarouselModal';

import { TransitionScreenplayBuilderBase } from 'feature-areas/transitions/builders/TransitionScreenplayBuilderBase';
import { EducatorTabName, RouteBuilder, RouterService } from 'router-service';
import { SceneName } from 'services/storm-lobo';
import { TitlePillBadgeReactAnimationName } from 'shared-components/title-pill-badge';
import { ActReactAnimationName } from '../../../acts';
import { EncounterCompleteSceneAnimationName } from '../../../encounters/encounter-complete-scene';
import { EncounterSceneAction } from '../../../encounters/encounter-scene';
import {
  LevelSceneAction,
  LevelSceneAnimationName,
  LevelSceneElementName,
  LevelSceneLayout
} from '../../../levels/level-scene';
import { AppShellAction } from '../../../shell';
import { TransitionScreenplayId } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';
import {
  ControlPanelComponent,
  FadeAnimationType
} from 'feature-areas/shell/control-panel/ControlPanel';

export class RoundToEncounterCompleteTransitionBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundToEncounterTransitionBuilder';

  public static createFor(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): RoundToEncounterCompleteTransitionBuilder {
    return new RoundToEncounterCompleteTransitionBuilder(state$, deps);
  }

  /**
   * Index of encounter just completed
   */
  private readonly completedEncounterIndex: number;

  private readonly currentActNumber: number;

  private readonly context: RoundContext;

  private constructor(
    state$: StateObservable<unknown>,
    private readonly deps: IRoundToNextTransitionDeps
  ) {
    super(TransitionScreenplayId.RoundToEncounterComplete);

    this.context = ProgramContextSelector.getRoundContext(state$.value);
    this.completedEncounterIndex = this.context.act.encounters.findIndex(
      e => e.sysId === this.context.encounter.sysId
    );
    this.currentActNumber = this.getCurrentActNumber();
  }

  public prepareLevelScene(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addReduxAction(
      LevelSceneAction.prepare({ layout: LevelSceneLayout.Showcase })
    );

    return this;
  }

  public fadeOutControlPanel() {
    this.builder.addReactAnimation(
      ControlPanelComponent.getAnimationName(FadeAnimationType.FadeOut),
      { concurrent: true }
    );

    return this;
  }

  public navToEncounterComplete(): RoundToEncounterCompleteTransitionBuilder {
    const uri = RouteBuilder.encounterComplete();

    this.builder.addCallback(() => {
      RouterService.history.replace(uri);
    });

    return this;
  }

  public showEncounterReward(): RoundToEncounterCompleteTransitionBuilder {
    const { preparedScenes } = this.deps;

    this.builder
      .addCallback(async () => {
        await preparedScenes.encounterCompleteReady;
        preparedScenes.encounterComplete!.show();
      })
      .addStormAnimation(
        {
          name: EncounterCompleteSceneAnimationName.Root.Intro,
          targetScene: SceneName.EncounterComplete
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.EncounterComplete })
      .addDelay(1000);

    return this;
  }

  // #region Student flow
  public navToActPage(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.acts(this.context.act.sysId));
    });

    return this;
  }

  public showLevelScene(
    options = { withShowcase: true }
  ): RoundToEncounterCompleteTransitionBuilder {
    const { act } = this.context;
    this.builder
      .addCallback(async () => {
        const levelScene = await this.deps.preparedScenes.levelReady;
        if (options.withShowcase) {
          levelScene.prepareShowcase(act);
        }
        levelScene.show();
      })
      .addStormAnimation({
        name: EncounterCompleteSceneAnimationName.Root.Outro,
        targetScene: SceneName.EncounterComplete
      });

    return this;
  }

  public animateInShowcase(): RoundToEncounterCompleteTransitionBuilder {
    const newEncounterNumber = this.completedEncounterIndex + 2;
    this.builder
      .addStormAnimation(
        {
          loop: true,
          name: LevelSceneAnimationName.Root.buildCharacterIdle(
            this.currentActNumber
          ),
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.Showcase.buildIntro(newEncounterNumber),
          targetElement: LevelSceneElementName.Showcase,
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.ActIntro }, { concurrent: true });

    return this;
  }

  public hidePriorScenes(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addCallback(() => {
      this.deps.preparedScenes.encounterComplete?.hide();
      this.deps.preparedScenes.encounter?.hide();
    });

    return this;
  }

  public changeActivityPosition(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addReduxAction(ActivityPositionAction.change());

    return this;
  }

  public prepareNextEncounterScenes(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addReduxAction(EncounterSceneAction.prepare());

    return this;
  }

  public playCongratulations(): RoundToEncounterCompleteTransitionBuilder {
    const { character } = this.context.act;
    const congratsVo = this.addSpeakerInfo(sample(character.encounterCongrats));
    this.builder.addAction(congratsVo?.actions[0], { concurrent: true });

    return this;
  }

  public animateInFooter(
    concurrent = false
  ): RoundToEncounterCompleteTransitionBuilder {
    this.builder
      .addReactAnimation(
        TitlePillBadgeReactAnimationName.buildImageHide(
          `encounter_${this.completedEncounterIndex}`
        )
      )
      .addReactAnimation(ActReactAnimationName.FadeIn, { concurrent });

    return this;
  }

  public animateInBadge(): RoundToEncounterCompleteTransitionBuilder {
    this.builder
      .addSfx(Sfx.Flare, { concurrent: true })
      .addReactAnimation(
        TitlePillBadgeReactAnimationName.buildFlare(
          `encounter_${this.completedEncounterIndex}`
        )
      );

    return this;
  }

  public animateFunFacts(): RoundToEncounterCompleteTransitionBuilder {
    this.builder
      .addStormAnimation({
        name: LevelSceneAnimationName.FunFactsEffects.Intro,
        targetElement: LevelSceneElementName.Effects,
        targetScene: SceneName.Level
      })
      .addStormAnimation({
        loop: true,
        name: LevelSceneAnimationName.FunFactsEffects.Idle,
        targetElement: LevelSceneElementName.Effects,
        targetScene: SceneName.Level
      });

    return this;
  }

  private addSpeakerInfo(
    screenplay: IScreenplay | undefined
  ): IScreenplay | undefined {
    if (!screenplay) {
      return;
    }

    const clonedScreenplay = cloneDeep(screenplay);
    clonedScreenplay.actions.forEach(a => {
      a.data = {
        ...a.data,
        speaker: {
          directions: {},
          sceneId: SceneName.Level,
          speakerId: LevelSceneElementName.buildCharacter(this.currentActNumber)
        }
      };
    });

    return clonedScreenplay;
  }

  private getCurrentActNumber(): number {
    const { activityPositionMap, level, act } = this.context;

    const incompleteActs = level.acts.filter(
      a => !activityPositionMap.get(a.sysId)?.isComplete
    );
    const actId = act.sysId;
    const incompleteActIndex = incompleteActs.findIndex(a => a.sysId === actId);

    if (incompleteActIndex < 0) {
      throw new LexiaError(
        `Unable to find activity ${actId} among incomplete activities`,
        RoundToEncounterCompleteTransitionBuilder.displayName,
        RoundToEncounterCompleteTransitionBuilderError.ActivityMissing
      ).withContext({
        actId,
        activityPositions: [...activityPositionMap.values()]
      });
    }

    return incompleteActIndex + 1;
  }
  // #endregion

  // #region Educator flow
  public navToEducatorPage(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(
        RouteBuilder.educator(EducatorTabName.Level)
      );
    });

    return this;
  }

  /**
   * Reset imminentPosition to undefined when returning to Educator Mode
   */
  public resetImminentPosition(): RoundToEncounterCompleteTransitionBuilder {
    const { activityPosition } = this.context;

    this.builder.addReduxAction(
      ActivityPositionAction.changed({
        ...activityPosition,
        imminentPosition: undefined
      })
    );

    return this;
  }

  public showActivityCarouselModal(): RoundToEncounterCompleteTransitionBuilder {
    this.builder.addReduxAction(
      AppShellAction.showModal({ id: ActivityCarouselModal.ModalId })
    );

    return this;
  }
  // #endregion
}

export enum RoundToEncounterCompleteTransitionBuilderError {
  ActivityMissing = 'ActivityMissing'
}
