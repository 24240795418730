import { ISoundLogCollectionPolicy } from '@lexialearning/lobo-common/main-model/sre';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ProgramContextSelector } from 'curriculum-services';
import { ILoboAppConfig } from 'services/app-config';
import { AuthSelector } from 'services/auth';
import { CustomerSelector } from 'services/customer';
import { ProfileSelector } from 'services/profile';
import { SreSelector } from 'sre/redux';
import {
  SoundLogsAction,
  SoundLogsActionLoadCollectionPolicies
} from 'sre/sound-logs/SoundLogs.action';
import {
  IPolicyLoaderContext,
  SoundLogCollectionPolicyLoader
} from './SoundLogCollectionPoliciesLoader';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppConfigAction, AppConfigActionUpdateEntries } from 'app-config';
import { AppState } from 'services';

function createPolicyLoaderContext(state: unknown): IPolicyLoaderContext {
  const { grade, studentId } = ProfileSelector.getProfile(state);

  return {
    grade,
    mics: SreSelector.getAvailableMics(state),
    position: ProgramContextSelector.getPosition(state),
    sessionId: AuthSelector.getLoginId(state),
    siteID: CustomerSelector.getCustomerCode(state),
    studentId: studentId?.toString()
  };
}

async function loadPolicies(
  context: IPolicyLoaderContext,
  state: AppState
): Promise<ISoundLogCollectionPolicy[]> {
  const sreConfig = UniConfigSelector.getConfig<ILoboAppConfig>(state).sre;

  return SoundLogCollectionPolicyLoader.load(context, sreConfig);
}

/**
 * Update the app config with policies loaded from the sound log collector
 * service. These will be applied to determine the sound log collection mode
 * for each SRE session. The initial implementation is intended to allow
 * collection of lossless audion in sound logs on a certain percent of student
 * sessions.
 *
 * @see SoundLogCollectionPoliciesLoader
 * @see setSreOptionsEpic
 * @see LanguageFrameListener
 * @see CalibrationFrameListener
 */
export function loadSoundLogCollectionPoliciesEpic(
  action$: Observable<SoundLogsActionLoadCollectionPolicies>,
  state$: StateObservable<AppState>
): Observable<AppConfigActionUpdateEntries> {
  return action$.pipe(
    filter(SoundLogsAction.loadCollectionPolicies.match),
    map(() => createPolicyLoaderContext(state$.value)),
    mergeMap(async context => loadPolicies(context, state$.value)),
    map(value =>
      AppConfigAction.updateEntries([
        {
          key: 'sre.soundLogCollectionPolicies',
          value
        }
      ])
    )
  );
}
loadSoundLogCollectionPoliciesEpic.displayName =
  'loadSoundLogCollectionPoliciesEpic';
