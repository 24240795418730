import { Overflow } from '@lexialearning/common-ui';
import { Align, Color, Font, Justify, TextAlign } from 'common-styles';

export class AccountSettingsTabListStyles {
  private static readonly styles = {
    buttonText: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small,
      textAlign: TextAlign.Left
    },
    tabButton: {
      shadow: {
        width: 260
      },
      tab: {
        paddingLeft: 20,
        width: 260
      },
      tabContainer: {
        marginBottom: 2,
        marginTop: 0,
        width: 260
      }
    },
    tabList: {
      alignItems: Align.Stretch,
      backgroundColor: Color.BlackTransparent08,
      flex: 1,
      justifyContent: Justify.Start,
      overflow: Overflow.Hidden,
      width: 260
    }
  };

  public static get() {
    return this.styles;
  }
}
