import { ActionType, createAction } from 'typesafe-actions';
import { TaskDemoType } from 'services/storm-lobo/StormAssets';
import { CommonUiActionType } from './common-ui-redux.model';

export const CommonUiAction = {
  setGlobalDisabled: createAction(
    CommonUiActionType.SetGlobalDisabled
  )<ICommonUiGlobalDisabledPayload>(),
  setHasViewedOnboardingVideo: createAction(
    CommonUiActionType.SetHasViewedOnboardingVideo
  )<ICommonUiHasViewedOnboardingVideoPayload>(),
  setKeydownHandlingDisabled: createAction(
    CommonUiActionType.SetKeydownHandlingDisabled
  )<ICommonUiKeydownHandlingDisabledPayload>(),
  setTaskDemoShown: createAction(
    CommonUiActionType.SetTaskDemoShown
  )<TaskDemoType>(),
  setUnitRewards: createAction(CommonUiActionType.SetUnitRewards)<number[]>(),
  unitRewardShown: createAction(
    CommonUiActionType.UnitRewardShown
  )<IUnitRewardShownActionPayload>()
};

export interface ICommonUiGlobalDisabledPayload {
  isGlobalDisabled: boolean;
}
export interface ICommonUiHasViewedOnboardingVideoPayload {
  hasViewedOnboardingVideo: boolean;
}
export interface ICommonUiKeydownHandlingDisabledPayload {
  isKeydownHandlingDisabled: boolean;
}
export interface IUnitRewardShownActionPayload {
  idx: number;
}

export type CommonUiActions = ActionType<typeof CommonUiAction>;

export type CommonUiActionSetGlobalDisabled = ActionType<
  typeof CommonUiAction.setGlobalDisabled
>;
export type CommonUiActionSetHasViewedOnboardingVideo = ActionType<
  typeof CommonUiAction.setHasViewedOnboardingVideo
>;
export type CommonUiActionSetKeydownHandlingDisabled = ActionType<
  typeof CommonUiAction.setKeydownHandlingDisabled
>;
export type CommonUiActionSetUnitRewards = ActionType<
  typeof CommonUiAction.setUnitRewards
>;
export type CommonUiActionUnitRewardShown = ActionType<
  typeof CommonUiAction.unitRewardShown
>;
