import { TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { IMultiSelect } from '@lexialearning/lobo-common/tasks/multiple-choice';
import { cloneDeep } from 'lodash';
import { ArrayUtils } from 'utils';
import { ITaskRegistration, TaskRegistrationBuilder } from '../core';
import {
  ChoiceHelper,
  IChoiceAnswer
} from '../shared/choice-helper/ChoiceHelper';
import { IMultiSelectChoice } from './multi-select-component.model';
import { MultiSelect } from './MultiSelect';

export function createMultiSelectTaskRegistration(): ITaskRegistration<
  TaskTypeName.MultiSelect,
  IMultiSelect<IMultiSelectChoice>,
  IChoiceAnswer
> {
  const base = TaskRegistrationBuilder.create<
    TaskTypeName.MultiSelect,
    IMultiSelect<IMultiSelectChoice>
  >(TaskTypeName.MultiSelect, MultiSelect).withPrepareContent(
    prepareContent
  ).registration;

  return {
    ...base,
    serializeAnswer: ChoiceHelper.serializeAnswer
  };
}

function prepareContent(
  content: IMultiSelect
): IMultiSelect<IMultiSelectChoice> {
  const choices = content.choices.map(c => ({ ...c, pillOriginIdx: -1 }));

  return {
    ...cloneDeep(content),
    choices: content.shuffleChoices ? ArrayUtils.shuffleArray(choices) : choices
  };
}
