import * as React from 'react';
import { compose } from 'redux';
import { InteractionState } from 'common-styles';
import {
  Column,
  IPositionProps,
  Row,
  Text,
  Types,
  View,
  withPositionHandler
} from 'common-ui';
import { IDndDragSourceProps, IDropTargetCollectedProps } from 'dnd';
import { CompletionChoice } from '../choice/CompletionChoice';
import { ICompletionChoice } from '../completion-component.model';
import { CompletionAnswerSlotStyles } from './CompletionAnswerSlot.styles';

export interface IPlacedCompletionChoice extends ICompletionChoice {
  /** Index of the src item in the choice bank */
  srcIdx: number;
}

export interface ICompletionAnswerSlotProps
  extends IDndDragSourceProps,
    IDropTargetCollectedProps,
    IPositionProps {
  placedChoice?: IPlacedCompletionChoice;
  interactionState: InteractionState;
  dragStyleOverride?: any;
  prePunctuation?: string;
  postPunctuation?: string;
  pillWidth: number;
  activeSrcIdx: number;
  activeTargetIdx: number;
  onLayout(e: Types.ViewOnLayoutEvent): void;
  onEndDrag(srcIdx: number): void;
}

export class CompletionAnswerSlotComponent extends React.Component<ICompletionAnswerSlotProps> {
  public static readonly displayName = 'CompletionAnswerSlot';

  public render() {
    const {
      index,
      activeSrcIdx,
      activeTargetIdx,
      placedChoice,
      dragStyleOverride,
      pillWidth,
      prePunctuation,
      postPunctuation,
      onLayout,
      onBeginDrag,
      onDrag,
      onEndDrag,
      interactionState
    } = this.props;
    const isHovered = activeTargetIdx === index;
    const choiceShouldBeHidden = activeSrcIdx === placedChoice?.srcIdx;
    const styles = CompletionAnswerSlotStyles.build(
      isHovered,
      pillWidth,
      interactionState
    );

    return (
      <Row>
        {!!prePunctuation && (
          <Text style={styles.prePunctuation}>{prePunctuation}</Text>
        )}
        <Column
          style={styles.answerPill}
          onLayout={onLayout}
          testId={`completionAnswerSlotSource${index}`}
        >
          {placedChoice && (
            <CompletionChoice
              index={placedChoice.srcIdx}
              key={`CompletionAnswerSlotSource${index}`}
              styleOverride={styles.choiceStyleOverride}
              dragStyleOverride={dragStyleOverride}
              hasShadow={false}
              pillWidth={pillWidth}
              voiceover={placedChoice.voiceover}
              shouldBeHidden={choiceShouldBeHidden}
              activeTargetIdx={activeTargetIdx}
              onBeginDrag={onBeginDrag}
              onDrag={onDrag}
              onEndDrag={onEndDrag}
            >
              {placedChoice?.text}
            </CompletionChoice>
          )}
          {(!placedChoice || choiceShouldBeHidden) && (
            <View
              accessibilityLabel="blank line"
              blockPointerEvents
              style={styles.answerLine}
            />
          )}
        </Column>
        {!!postPunctuation && (
          <Text style={styles.postPunctuation}>{postPunctuation}</Text>
        )}
      </Row>
    );
  }
}

export const CompletionAnswerSlot = compose(withPositionHandler)(
  CompletionAnswerSlotComponent
);
