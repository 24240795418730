import {
  UserGlobalAction,
  UserGlobalActionLogout
} from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import { StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { NotConnectedModal } from 'feature-areas/errors';
import {
  BootstrappingAction,
  BootstrappingActionBootstrap,
  BootstrappingSelector
} from 'services/bootstrapping';
import {
  AppShellAction,
  AppShellActionHideModal,
  AppShellActionShowModal,
  AppShellSelector
} from 'shell';
import { PredicateUtils } from 'utils/PredicateUtils';
import { NetworkStateProvider } from './NetworkStateProvider';

export function monitorNetworkStateEpic(
  _action$: Observable<any>,
  state$: StateObservable<unknown>
): Observable<
  | AppShellActionShowModal
  | AppShellActionHideModal
  | UserGlobalActionLogout
  | BootstrappingActionBootstrap
> {
  return NetworkStateProvider.createState$().pipe(
    mergeMap(({ isOnline }) => {
      if (!isOnline) {
        return from([
          AppShellAction.showModal({ id: NotConnectedModal.ModalId })
        ]);
      }

      const isBootstrapped = BootstrappingSelector.isBootstrapped(state$.value);
      const wasOffline =
        AppShellSelector.getActiveModal(state$.value)?.id ===
        NotConnectedModal.ModalId;

      if (wasOffline) {
        // handles the case when app is started while offline
        if (isBootstrapped) {
          return from([UserGlobalAction.logout.request(LogoutReason.Error)]);
        }

        return from([
          AppShellAction.hideModal(),
          BootstrappingAction.bootstrap()
        ]);
      }

      return of(undefined);
    }),
    filter(PredicateUtils.isDefined)
  );
}
monitorNetworkStateEpic.displayName = 'monitorNetworkStateEpic';
