import { IMicrophone } from '@lexialearning/lobo-common/main-model/sre';
import memoizeOne from 'memoize-one';
import * as React from 'react';
import { connect } from 'react-redux';
import { AppShellSelector } from 'feature-areas/shell';
import {
  Column,
  Dropdown,
  IDropdownItem,
  Row,
  Text,
  TextButton,
  TextButtonType
} from 'common-ui';
import { RouterService, RoutePath } from 'router-service';
import { AppState } from 'services';
import { MicEnergyMeter } from 'shared-components';
import { SreConfigAction, SreSelector } from 'sre/redux';
import { SystemInfo } from 'utils/SystemInfo';
import { MicSelectionStyles } from './MicSelection.styles';

export interface IMicSelectionProps {
  availableMics: IMicrophone[];
  title?: string;
  selectedMic?: IMicrophone;
  isModalOpen?: boolean;
  onTipsPress?(): void;
  onOkPress(): void;
  selectMic(mic: IMicrophone): void;
}

export class MicSelectionComponent extends React.PureComponent<IMicSelectionProps> {
  public static displayName = 'MicSelection';
  public static buttonDisplayName = 'MicSelectionButton';

  public constructor(props: IMicSelectionProps) {
    super(props);
    this.handleMicSelect = this.handleMicSelect.bind(this);
  }

  private readonly memoizedDropdownItems = memoizeOne(
    (availableMics: IMicrophone[]): IDropdownItem[] =>
      availableMics.map(m => ({ id: m.id, label: m.name }))
  );

  private handleMicSelect(micId: string) {
    const mic = this.props.availableMics.find(m => m.id === micId)!;
    this.props.selectMic(mic);
  }

  public render() {
    const {
      availableMics,
      selectedMic,
      title,
      isModalOpen,
      onOkPress,
      onTipsPress
    } = this.props;
    const showTipsButton = !!onTipsPress;
    const isModal = !RouterService.isOnRoute(RoutePath.Calibration);
    const styles = MicSelectionStyles.get();

    return (
      <Column testId={MicSelectionComponent.displayName}>
        {title && (
          <Text testId="title" style={styles.title}>
            {title}
          </Text>
        )}
        {!SystemInfo.isNative && (
          <Dropdown
            accessibilityLabel="Microphone"
            triggerClose={isModalOpen}
            id="availableMics"
            items={this.memoizedDropdownItems(availableMics)}
            onChange={this.handleMicSelect}
            selectedItemId={selectedMic?.id}
          />
        )}
        <Row style={styles.vuMeterContainer}>
          <MicEnergyMeter />
        </Row>
        <Row style={showTipsButton ? styles.buttonsContainer : undefined}>
          {showTipsButton && (
            <TextButton testId="tipsButton" text="Tips" onPress={onTipsPress} />
          )}
          <TextButton
            buttonType={TextButtonType.Primary}
            disabled={!SystemInfo.isNative && !selectedMic}
            onPress={onOkPress}
            testId={MicSelectionComponent.buttonDisplayName}
            text={isModal ? 'OK' : 'Try Again'}
          />
        </Row>
      </Column>
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: AppState) => ({
  availableMics: SreSelector.getAvailableMics(state),
  isModalOpen: AppShellSelector.isModalOpen(state),
  selectedMic: SreSelector.getSelectedMic(state)
});

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  selectMic: (mic: IMicrophone) =>
    SreConfigAction.selectMic.request({
      // make serializable for redux serializableCheck
      mic: { ...mic }
    })
};

export const MicSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(MicSelectionComponent);
