import { Position } from '@lexialearning/common-ui';
import { Align } from 'common-styles';

export class StormContainerStyles {
  private static readonly styles = {
    default: {
      alignItems: Align.Stretch,
      bottom: 0,
      left: 0,
      position: Position.Absolute,
      right: 0,
      top: 0
    }
  };

  public static get() {
    return this.styles;
  }
}
