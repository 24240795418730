import { UserGlobalActionType } from '@lexialearning/lobo-common/main-model/user';
import { createReducer } from '@reduxjs/toolkit';
import { ILevelStatus, LoadStatus } from '../levels-completed.model';
import { ILevelsCompletedState } from './levels-completed-redux.model';
import { LevelsCompletedAction } from './LevelsCompleted.action';

function createInitialState(): ILevelsCompletedState {
  return { levels: [], loadStatus: LoadStatus.NotLoaded };
}

export const LevelsCompletedReducerPrivates = { createInitialState };

export const levelsCompletedReducer = createReducer(
  createInitialState(),
  builder =>
    builder
      .addCase(LevelsCompletedAction.add.success, (state, { payload }) => {
        state.levels = payload;
      })
      .addCase(LevelsCompletedAction.init.success, (state, { payload }) => {
        state.levels = payload;
        state.loadStatus = LoadStatus.NotLoaded;
      })
      .addCase(LevelsCompletedAction.load.request, state => {
        state.loadStatus = LoadStatus.Loading;
      })
      .addCase(LevelsCompletedAction.load.success, (state, { payload }) => {
        state.levels = payload;
        state.loadStatus = LoadStatus.Loaded;
      })
      .addCase(LevelsCompletedAction.load.failure, state => {
        state.loadStatus = LoadStatus.NotLoaded;
      })
      .addCase(
        LevelsCompletedAction.setCertificateViewed.request,
        (state, { payload }) => {
          state.levels = setCertificateViewed(state.levels, payload);
        }
      )
      .addCase(UserGlobalActionType.LogoutSuccess, createInitialState)
);

function setCertificateViewed(
  levels: ILevelStatus[],
  levelViewedNumber: number
) {
  return [...levels].map(l => {
    if (l.levelNumber === levelViewedNumber) {
      l.certificateViewed = true;
    }

    return l;
  });
}
