import {
  GlossyButtonAnimatedStyles,
  IGlossyButtonStyleOverride
} from './GlossyButton.animated-styles';
import { AnimatedColumn } from '../../layout';
import {
  GlossyButtonIconType,
  GlossyButtonType,
  IRoundGlossyButtonColorSet,
  RoundGlossyButtonSvg,
  SquareGlossyButtonSvg
} from './svgs';
import { IButtonBaseProps } from '../ButtonBase';
import { InteractionState } from 'common-styles';
import { InteractiveStateButtonBase } from '../InteractiveStateButtonBase';
import { JSX } from 'react';
import { KeyNavHelper } from 'common-ui/helpers';
import { PressScaleButton } from '../press-scale-button';
import { Types } from '../../../types';
import {
  IGlobalDisabledReduxProps,
  withGlobalDisabled
} from '../../withGlobalDisabled.hoc';
import { ActiveComponentState } from '@lexialearning/common-ui';

export const FeedbackInteractionStates = [
  InteractionState.Correct,
  InteractionState.Incorrect,
  InteractionState.Inconclusive
];

export const CALLOUT_TIMEOUT_MS = 7000;

export interface IGlossyButtonProps extends IButtonBaseProps {
  icon?: GlossyButtonIconType | JSX.Element;
  colorSet?: IRoundGlossyButtonColorSet;
  interactionState?: InteractionState;
  styleOverride?: IGlossyButtonStyleOverride;
  withCallout?: boolean;
  immediateCallout?: boolean;
  triggerState?: ActiveComponentState;
  buttonType?: GlossyButtonType;
  /**
   * If defined, will be called with the callout animation as an argument,
   * during componentDidMount, allowing the parent component to register it
   * for use in screenplays
   */
  registerAnimation?(animation: Types.Animated.CompositeAnimation): () => void;
}

export interface IGlossyButtonState {
  activeComponentState: ActiveComponentState;
}

export class GlossyButtonComponent extends InteractiveStateButtonBase<
  IGlossyButtonProps & IGlobalDisabledReduxProps,
  IGlossyButtonState
> {
  public static readonly displayName = 'GlossyButton';

  private readonly animatedStyles: GlossyButtonAnimatedStyles;

  private readonly unregisterAnimations?: () => void;

  private calloutInterval: any = null;

  constructor(props: IGlossyButtonProps & IGlobalDisabledReduxProps) {
    super(props);
    this.animatedStyles = new GlossyButtonAnimatedStyles();

    this.setCalloutAnimation = this.setCalloutAnimation.bind(this);
    this.startCalloutAnimation = this.startCalloutAnimation.bind(this);
    this.removeCalloutAnimation = this.removeCalloutAnimation.bind(this);

    if (props.registerAnimation) {
      const { buttonCallout } = this.animatedStyles.getAnimations();
      this.unregisterAnimations = props.registerAnimation(buttonCallout);
    }
  }

  public componentDidMount() {
    const { disabled, withCallout } = this.props;

    if (!disabled && withCallout) {
      this.setCalloutAnimation();
    }
  }

  public componentDidUpdate(prevProps: IGlossyButtonProps) {
    const wasDisabled = this.computeDisabled(prevProps);
    const isDisabled = this.computeDisabled();

    const { interactionState, withCallout } = this.props;
    const {
      interactionState: prevInteractionState,
      withCallout: prevWithCallout
    } = prevProps;

    if (prevWithCallout && !withCallout) {
      this.removeCalloutAnimation();
    }

    if (withCallout) {
      if ((!prevWithCallout || wasDisabled) && !isDisabled) {
        this.setCalloutAnimation();
      }
      if (!wasDisabled && isDisabled) {
        this.removeCalloutAnimation();
      }
    }

    if (
      prevInteractionState !== InteractionState.Incorrect &&
      interactionState === InteractionState.Incorrect
    ) {
      this.animatedStyles.getAnimations().buttonShake.start();
    }
  }

  public componentWillUnmount() {
    this.removeCalloutAnimation();
    this.unregisterAnimations?.();
  }

  private setCalloutAnimation() {
    this.props.immediateCallout && this.startCalloutAnimation();
    this.calloutInterval = setInterval(
      this.startCalloutAnimation,
      CALLOUT_TIMEOUT_MS
    );
  }

  private removeCalloutAnimation() {
    clearInterval(this.calloutInterval);
  }

  private shouldUpdateActiveStateOnCallout(): boolean {
    return !KeyNavHelper.isKeyNav(this.state.activeComponentState);
  }

  private startCalloutAnimation() {
    this.shouldUpdateActiveStateOnCallout() &&
      this.setState({ activeComponentState: ActiveComponentState.Callout });
    this.animatedStyles.getAnimations().buttonCallout.start(() => {
      this.shouldUpdateActiveStateOnCallout() &&
        this.setState({ activeComponentState: ActiveComponentState.Default });
    });
  }

  private computeDisabled(props?: IGlossyButtonProps) {
    const { disabled, interactionState } = props || this.props;

    return disabled || interactionState === InteractionState.Disabled;
  }

  private computeInteractionState() {
    const {
      globalDisabled,
      interactionState: interactionStateProp = InteractionState.Default
    } = this.props;

    return globalDisabled &&
      !FeedbackInteractionStates.includes(interactionStateProp)
      ? InteractionState.Disabled
      : interactionStateProp;
  }

  public render() {
    const {
      accessibilityLabel,
      buttonType = GlossyButtonType.Round,
      children,
      colorSet,
      globalDisabled,
      icon,
      style,
      styleOverride,
      withCallout,
      ...restProps
    } = this.props;
    const { activeComponentState } = this.state;
    const interactionState = this.computeInteractionState();
    const styles = this.animatedStyles.build(buttonType, styleOverride);
    const svgProps = { activeComponentState, icon, interactionState };
    const disabledOpacity =
      globalDisabled ||
      [InteractionState.Disabled, InteractionState.Inconclusive].includes(
        interactionState
      )
        ? 0.3
        : 1;

    return (
      <AnimatedColumn animatedStyle={styles.animatedColumn} style={style}>
        <PressScaleButton
          {...restProps}
          {...this.interactionHandlers}
          hoverScale={1.06}
          pressScale={0.95}
          disabled={this.computeDisabled()}
          style={styles.button}
          disabledOpacity={disabledOpacity}
        >
          {buttonType === GlossyButtonType.Square ? (
            <SquareGlossyButtonSvg
              {...svgProps}
              accessibilityLabel={accessibilityLabel}
            />
          ) : (
            <RoundGlossyButtonSvg
              {...svgProps}
              colorSet={colorSet}
              withDropShadow={
                buttonType === GlossyButtonType.RoundWithDropShadow
              }
              accessibilityLabel={accessibilityLabel}
            />
          )}
          {children}
        </PressScaleButton>
      </AnimatedColumn>
    );
  }
}

export const GlossyButton = withGlobalDisabled(GlossyButtonComponent);
