import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  LevelAction,
  LevelActionLoad,
  LevelSelector
} from 'curriculum-services';
import {
  EducatorActionSelectLevelActivity,
  EducatorActionType
} from '../redux';

export function maybeLoadLevelEpic(
  action$: Observable<EducatorActionSelectLevelActivity>,
  state$: StateObservable<unknown>
): Observable<LevelActionLoad> {
  return action$.pipe(
    ofType(EducatorActionType.SelectLevelActivity),
    filter(
      a => LevelSelector.getLevelIdMaybe(state$.value) !== a.payload.levelId
    ),
    map(a => LevelAction.load.request({ sysId: a.payload.levelId }))
  );
}
maybeLoadLevelEpic.displayName = 'maybeLoadLevelEpic';
