import { Color } from 'common-styles';
import {
  RadialGradient,
  Stop,
  Path,
  Svg,
  Defs,
  G,
  ClipPath,
  LinearGradient,
  Ellipse,
  Circle
} from '../../../svg';
import { RoundColorSets } from './ColorSets';
import { IRoundGlossyButtonColorSet } from './glossy-button-svg.model';
import {
  GlossyButtonBaseSvg,
  IGlossyButtonBaseSvgProps
} from './GlossyButtonBase.lx-svg';

export interface IRoundGlossyButtonSvgProps extends IGlossyButtonBaseSvgProps {
  colorSet?: IRoundGlossyButtonColorSet;
  withDropShadow?: boolean;
}

export class RoundGlossyButtonSvg extends GlossyButtonBaseSvg<IRoundGlossyButtonSvgProps> {
  public static readonly displayName = 'GlossyButton.lx-svg';

  private get glossyColorSet(): IRoundGlossyButtonColorSet {
    return this.props.colorSet || RoundColorSets[this.getButtonColorSetName()];
  }

  protected get viewBox(): string {
    return `0 0 ${this.props.withDropShadow ? '73 73' : '63 63'}`;
  }

  protected renderDropShadowDefsMaybe() {
    return (
      this.props.withDropShadow && (
        <RadialGradient
          id={`${this.id}_shadow-gradient`}
          cx="58.2"
          cy="46"
          r="35.5"
          gradientTransform="scale(1 0.969) translate(0 0.016)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopOpacity="0.286" />
          <Stop offset="0.867" stopOpacity="0.286" />
          <Stop offset="1" stopColor="#545454" stopOpacity="0" />
        </RadialGradient>
      )
    );
  }

  protected get gTranslate(): string {
    return this.props.withDropShadow ? 'translate(-10 5)' : 'translate(-15.28)';
  }

  protected renderDropShadowMaybe() {
    return (
      this.props.withDropShadow && (
        <Path
          d="M58,79.21A36.5,36.5,0,1,1,94.5,42.724,36.5,36.5,0,0,1,58,79.21Z"
          transform="translate(-11.5 -11.5)"
          fill={`url(#${this.id}_shadow-gradient)`}
        />
      )
    );
  }

  protected renderOuterRing(fill: string, stroke?: string) {
    return (
      <Path
        d="M46.78.5a31,31,0,1,0,31,31A31,31,0,0,0,46.78.5Zm0,56.27A25.28,25.28,0,1,1,72.06,31.5,25.28,25.28,0,0,1,46.78,56.77Z"
        stroke={stroke || fill}
        strokeMiterlimit="10"
        strokeWidth="1"
        fill={fill}
      />
    );
  }

  protected renderBackgroundCircle(fill: string, stroke?: string) {
    const variableProps = stroke
      ? {
          r: 26.74,
          stroke,
          strokeWidth: 3
        }
      : { r: 25.28 };

    return <Circle cx="47" cy="31.5" fill={fill} {...variableProps} />;
  }

  protected renderFlat() {
    const colorSet = this.getKeyNavColorSet();

    return (
      <Svg
        height="100%"
        width="100%"
        viewBox={this.viewBox}
        accessibilityLabel={this.getAccessibilityLabel()}
      >
        <Defs>
          {/* Drop shadow */}
          {this.renderDropShadowDefsMaybe()}
        </Defs>
        <G transform={this.gTranslate}>
          {/* Drop shadow */}
          {this.renderDropShadowMaybe()}
          {/* Outer ring */}
          {this.renderOuterRing(colorSet.outerStroke)}
          {/* Background circle */}
          {this.renderBackgroundCircle(colorSet.fill, colorSet.stroke)}
          {/* Icon */}
          {this.renderIcon({ iconColor: colorSet.icon })}
        </G>
      </Svg>
    );
  }

  protected renderGlossy() {
    return (
      <Svg
        height="100%"
        width="100%"
        viewBox={this.viewBox}
        accessibilityLabel={this.getAccessibilityLabel()}
      >
        <Defs>
          {/* Drop shadow */}
          {this.renderDropShadowDefsMaybe()}
          {/* Color background */}
          <RadialGradient
            id={`${this.id}_b`}
            cx="39"
            cy="27"
            r="31"
            fx="41"
            fy="27"
            gradientTransform="scale(.98 1) translate(0 0) skewX(5) skewY(1)"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0" stopColor={this.glossyColorSet.stop1} />
            <Stop offset="0.4" stopColor={this.glossyColorSet.stop1} />
            <Stop offset="1" stopColor={this.glossyColorSet.stop2} />
          </RadialGradient>
          {/* White outer ring */}
          <RadialGradient
            id={`${this.id}_a`}
            cx="47.16"
            cy="32.08"
            r="30.61"
            fx="41"
            fy="27"
            gradientTransform="translate(.25 .5)"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0.795" stopColor="#cecdc9" />
            <Stop offset="0.915" stopColor={Color.NearWhite} />
            <Stop offset="1" stopColor={Color.NearWhite} />
          </RadialGradient>
          {/* 2/3 height dark gloss - clip path */}
          <ClipPath id={`${this.id}_c`}>
            <Path
              d="M46.78,56.77A25.28,25.28,0,1,1,72.06,31.5,25.28,25.28,0,0,1,46.78,56.77Z"
              fill="none"
            />
          </ClipPath>
          {/* 2/3 height dark gloss - gradient */}
          <LinearGradient
            id={`${this.id}_d`}
            x1="0.5"
            y1="0.007"
            x2="0.5"
            y2="33"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0" stopColor={this.glossyColorSet.stop2} />
            <Stop
              offset="0.523"
              stopColor={this.glossyColorSet.stop2}
              stopOpacity="0.341"
            />
            <Stop
              offset="1"
              stopColor={this.glossyColorSet.stop2}
              stopOpacity="0"
            />
          </LinearGradient>
          {/* Small shine oval */}
          <LinearGradient
            id={`${this.id}_e`}
            x1="0.15"
            y1="0.16"
            x2="7.264"
            y2="14.241"
            gradientTransform="translate(6,0)"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0" stopColor={Color.NearWhite} stopOpacity="0.72" />
            <Stop offset="0" stopColor={Color.NearWhite} stopOpacity="0.72" />
            <Stop offset="1" stopColor={Color.NearWhite} stopOpacity="0" />
          </LinearGradient>
        </Defs>
        <G transform={this.gTranslate}>
          {/* Drop shadow */}
          {this.renderDropShadowMaybe()}
          {/* Outer ring */}
          {this.renderOuterRing(`url(#${this.id}_a)`, Color.NearWhite)}
          {/* Background circle */}
          {this.renderBackgroundCircle(`url(#${this.id}_b)`)}

          {/* 2/3 height dark gloss */}
          <G
            clipPath={`url(#${this.id}_c)`}
            style={{ WebkitClipPath: `url(#${this.id}_c)` }}
          >
            <Ellipse
              cx="46.78"
              cy="24.03"
              rx="46.78"
              ry="24.03"
              transform="translate(0 23.47)"
              fill={`url(#${this.id}_d)`}
            />
          </G>
          {/* Small shine oval */}
          <Ellipse
            cx="10.1"
            cy="3.53"
            rx="10.1"
            ry="3.53"
            transform="translate(26.589 18.781) rotate(-32.09)"
            fill={`url(#${this.id}_e)`}
          />
          {/* Icon */}
          {this.renderIcon({ shadowColor: this.glossyColorSet.stop2 })}
        </G>
      </Svg>
    );
  }
}
