import { AppShellAction } from 'feature-areas/shell/redux';
import { useDispatch } from 'react-redux';
import { TroubleshootButton } from './TroubleshootButton';
import { TroubleshootModal } from 'feature-areas/troubleshoot/TroubleshootModal';

interface IShowTroubleshootButtonProps {
  disabled?: boolean;
  onPress?: () => void;
}

export function ShowTroubleshootButton(props: IShowTroubleshootButtonProps) {
  const dispatch = useDispatch();

  const handlePress = () => {
    props.onPress?.();
    dispatch(AppShellAction.showModal({ id: TroubleshootModal.ModalId }));
  };

  return (
    <TroubleshootButton
      disabled={props.disabled}
      onPress={handlePress}
      testId={ShowTroubleshootButton.displayName}
    />
  );
}
ShowTroubleshootButton.displayName = 'ShowTroubleshootButton';
