import { PositionChangeType } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ICurriculumDependencies } from '../../../CurriculumDependencies';
import { LevelSelector } from '../../../level';
import {
  ActivityPositionActionPrepared,
  ActivityPositionActionType,
  PositionAction,
  PositionActionLevelUpPositionPrepared
} from '../../redux';
import { LevelUpFactory } from './LevelUpFactory';

export function prepareLevelUpEpic(
  action$: Observable<ActivityPositionActionPrepared>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<PositionActionLevelUpPositionPrepared> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Prepared),
    filter(a => a.payload.changeType === PositionChangeType.LevelCompletion),
    switchMap(async () => {
      const currentLevelId = LevelSelector.getLevelId(state$.value);
      const levelUpPosition = await LevelUpFactory.levelUp(
        currentLevelId,
        deps.curriculumDependencies.contentProviderFactory
      );

      return PositionAction.levelUpPositionPrepared(levelUpPosition);
    })
  );
}
prepareLevelUpEpic.displayName = 'prepareLevelUpEpic';
