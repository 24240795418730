import { Text as RxText, Types as RxTypes } from '@lexialearning/reactxp';
import { TextStyles } from './Text.styles';
import { ThemeSize } from 'theme';

export interface IPlainTextProps extends RxTypes.TextProps {
  themeSize?: ThemeSize;
  style?: RxTypes.TextStyle;
  testId?: string;
}

/**
 * View-only Text component - not connected to state -
 * LE should use Text component unless
 */
export function PlainText(props: IPlainTextProps) {
  const {
    children,
    style,
    testId,
    themeSize = ThemeSize.Large,
    ...textProps
  } = props;

  const textChildren =
    Array.isArray(children) &&
    children.every(child => typeof child === 'string')
      ? children.join('')
      : children;

  return (
    <RxText
      testId={testId || PlainText.displayName}
      allowFontScaling={false}
      {...textProps}
      style={TextStyles.build(themeSize, style)}
    >
      {textChildren}
    </RxText>
  );
}
PlainText.displayName = 'PlainText';
