import { ofType, StateObservable } from 'redux-observable';
import { lastValueFrom, Observable } from 'rxjs';
import { find, mergeMap, startWith } from 'rxjs/operators';
import { RouterService, RoutePath } from 'router-service';
import { ProgramMode } from 'curriculum-services/program-context/program-context.model';
import { ProfileSelector } from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { SreSelector } from 'sre';
import {
  LevelActionLoadSuccess,
  LevelActionType,
  LevelSelector
} from '../../../level';
import { ProgramContextSelector } from '../../redux';
import {
  LevelSetupBuilder,
  LevelSetupDispatchAction
} from './LevelSetupBuilder';
import { LevelSceneLayout } from 'feature-areas';

export interface ISetUpLevelDependencies {
  preparedScenes: PreparedScenes;
}

export function setUpLevelEpic(
  action$: Observable<LevelActionLoadSuccess>,
  state$: StateObservable<unknown>,
  deps: ISetUpLevelDependencies
): Observable<LevelSetupDispatchAction> {
  return action$.pipe(
    ofType(LevelActionType.LoadSuccess),
    mergeMap(async action => {
      // Await intro scene to be prepared and SRE to be initialized before loading level
      // content in order to avoid bottlenecks. Level can and should load after these and
      // so should not be allowed to hold them up. (Only applies on login in introduction
      // routes, not when loading level content at another point, eg, after completing a level)
      if (
        RouterService.isOnLoginRoute() ||
        RouterService.isOnRoute(RoutePath.Introduction)
      ) {
        await Promise.all([
          deps.preparedScenes.introductionReady,
          lastValueFrom(
            state$.pipe(
              startWith(state$.value),
              find(state => SreSelector.getIsInitialized(state))
            )
          )
        ]);
      }

      return action;
    }),
    mergeMap(() => {
      const state = state$.value;
      const level = LevelSelector.getLevel(state);
      const position = ProgramContextSelector.getPosition(state);
      const mode = ProgramContextSelector.getMode(state);
      const isOnboardingOrPlacement =
        ProgramContextSelector.isOnboardingOrPlacement(state);
      const isStudentLoggedIn = ProfileSelector.isStudentLoggedIn(state);

      const builder = LevelSetupBuilder.create(
        mode,
        position,
        level,
        isStudentLoggedIn
      )
        .setThemeSize(isOnboardingOrPlacement)
        .maybeSetLevelStartPosition();

      if (mode !== ProgramMode.Educator) {
        const isLevelToLevel = RouterService.isOnRoute(RoutePath.LevelComplete);

        let levelSceneLayout = LevelSceneLayout.ActSelection;
        if (!isLevelToLevel) {
          builder.initializeLevelsCompleted();

          const lastActiveEncounterActId =
            ProgramContextSelector.getLastEncounterActPositionMaybe(state)
              ?.activityId;
          if (lastActiveEncounterActId) {
            levelSceneLayout = LevelSceneLayout.Showcase;
            builder
              .setActPosition(lastActiveEncounterActId)
              .loadUnit()
              .prepareEncounterScene();
          }
        }

        builder.mountVoiceovers();

        if (isOnboardingOrPlacement) {
          builder.preparePlacementScene();
        } else {
          builder
            .prepareHomeScene()
            .prepareLevelIntroScene({ isLevelToLevel })
            .prepareLevelScene({ layout: levelSceneLayout })
            .loadSoundLogCollectionPolicies();
        }
      }

      return builder.dispatches;
    })
  );
}
setUpLevelEpic.displayName = 'setUpLevelEpic';
