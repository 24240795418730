import {
  IPageAnimatedValues,
  IPageAnimations,
  PageAnimatedStyles
} from 'shared-components/Page.animated-styles';

export class LoginAnimatedStyles {
  private readonly styles: any = {
    container: {
      flex: 1
    },
    containerAnimated: {
      flex: 1
    }
  };

  private readonly animatedValues: IPageAnimatedValues;

  private readonly animations: IPageAnimations;

  constructor() {
    this.animatedValues = PageAnimatedStyles.createEntryExitAnimatedValues();
    this.animations = PageAnimatedStyles.createEntryExitAnimations(
      this.animatedValues,
      { fadeIn: 500 }
    );
    this.styles.containerAnimated.opacity = this.animatedValues.opacity;
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
