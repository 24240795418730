import { CharacterSetNumber } from '@lexialearning/lobo-common';
import { StormAssets } from 'services/storm-lobo';
import { CharacterName, SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';
import { EncounterSceneElementName } from './encounter-scene.model';

export class EncounterSceneDefinitionBuilder {
  public static create(): EncounterSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Encounter,
      StormAssets.Scene.Encounter
    );

    return new EncounterSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}

  public withBackground(name: any): EncounterSceneDefinitionBuilder {
    const actualName = name || 'abshir/01/abshir_background_01';
    const background = SceneDefinitionBuilder.create(
      EncounterSceneElementName.Background,
      `${StormAssets.Backgrounds}/character/${actualName}.sg`
    ).definition;
    this.definition.elements.push(background);

    return this;
  }

  public withCharacter(
    charName: CharacterName,
    characterSetNumber: CharacterSetNumber
  ): EncounterSceneDefinitionBuilder {
    const charPathGroup =
      StormAssets.Character[charName] || StormAssets.Character.Placeholder;
    const scenePath = charPathGroup.get(characterSetNumber);
    const character = SceneDefinitionBuilder.create(
      EncounterSceneElementName.Character,
      scenePath,
      {
        animations: StormAssets.Encounter.CharacterAnimations,
        lazyLoad: true
      }
    ).definition;
    this.definition.elements.push(character);

    return this;
  }
}
