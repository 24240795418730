import {
  IActivityPosition,
  ILevel,
  IProgramPosition,
  IUnit,
  UnitType
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { toNumber } from 'lodash';
import {
  LevelsCompletedAction,
  LevelsCompletedActionInit
} from 'curriculum-services/levels-completed';
import { UnitAction, UnitActionLoad } from 'curriculum-services/unit';
import { HomeSceneAction } from 'feature-areas/home/home-scene/redux';
import { HomeScenePrepareAction } from 'feature-areas/home/home-scene/redux/HomeScene.action';
import { ILevelSceneActionPreparePayload } from 'feature-areas/levels';
import { LevelCompleteScenePrepareAction } from 'feature-areas/levels/level-complete-scene/redux';
import {
  LevelIntroSceneAction,
  LevelIntroScenePrepareAction
} from 'feature-areas/level-intro/level-intro-scene/redux';
import { ILevelIntroSceneActionPreparePayload } from 'feature-areas/level-intro/level-intro-scene/redux/LevelIntroScene.action';
import {
  LevelSceneAction,
  LevelScenePrepareAction
} from 'feature-areas/levels/level-scene/redux';
import {
  PlacementSceneAction,
  PlacementScenePrepareAction
} from 'feature-areas/placement/placement-scene/redux';
import { Services } from 'services/Services';
import {
  SoundLogsAction,
  SoundLogsActionLoadCollectionPolicies
} from 'sre/sound-logs/SoundLogs.action';
import { ThemeAction, ThemeActionUpdate, ThemeSize } from 'theme';
import { LevelAction, LevelActionMountVoiceovers } from '../../../level';
import { ProgramMode } from '../../program-context.model';
import {
  ActivityPositionAction,
  ActivityPositionActionAdjusted,
  PositionAction,
  PositionActionActivitySelected,
  PositionActionLevelStartPosition,
  ProgramContextAction,
  ProgramContextActionProgramPositionAdjustedForPlacement
} from '../../redux';
import { LexiaPlacementId } from '../student-progress-api/student-progress-api-private.model';
import { LevelSetupHelper } from './LevelSetup.helper';
import { ProgramPositionFactory } from './ProgramPositionFactory';
import {
  EncounterSceneAction,
  EncounterScenePrepareAction
} from 'feature-areas/encounters';
import { LexiaErrorSeverity } from '@lexialearning/main-model';

export type LevelSetupDispatchAction =
  | ThemeActionUpdate
  | PositionActionLevelStartPosition
  | PositionActionActivitySelected
  | ActivityPositionActionAdjusted
  | LevelActionMountVoiceovers
  | LevelsCompletedActionInit
  | PlacementScenePrepareAction
  | UnitActionLoad
  | HomeScenePrepareAction
  | LevelIntroScenePrepareAction
  | LevelCompleteScenePrepareAction
  | LevelScenePrepareAction
  | EncounterScenePrepareAction
  | ProgramContextActionProgramPositionAdjustedForPlacement
  | SoundLogsActionLoadCollectionPolicies;

export class LevelSetupBuilder {
  public static readonly displayName = 'LevelSetup';

  private levelId: string;

  public static create(
    programMode: ProgramMode,
    currentPosition: IProgramPosition,
    level: ILevel,
    isStudentLoggedIn: boolean
  ): LevelSetupBuilder {
    return new LevelSetupBuilder(
      programMode,
      currentPosition,
      level,
      isStudentLoggedIn
    );
  }

  public readonly dispatches: LevelSetupDispatchAction[] = [];

  public constructor(
    private readonly programMode: ProgramMode,
    private readonly currentPosition: IProgramPosition,
    public readonly level: ILevel,
    private readonly isStudentLoggedIn: boolean
  ) {
    this.levelId = currentPosition.levelId;

    this.maybeAdjustProgramPosition();
  }

  private maybeAdjustProgramPosition(): this {
    if (
      [ProgramMode.Onboarding, ProgramMode.Placement].includes(this.programMode)
    ) {
      this.adjustProgramPositionForPlacement();
    } else {
      this.maybeAdjustActivityPositions();
    }

    return this;
  }

  /**
   * Adjust level/activity/encounter/unit program position on
   * placement/onboarding mode
   */
  private adjustProgramPositionForPlacement(): this {
    // level should have 2 acts, one for onboarding and the
    // other for placement
    if (this.level.acts.length < 2) {
      throw new LexiaError(
        'Level has no activities',
        LevelSetupBuilder.displayName,
        LevelSetupBuilderError.InvalidPlacementLevel
      ).withContext({ level: this.level });
    }

    const activityId =
      this.programMode === ProgramMode.Onboarding
        ? this.level.acts[0].sysId
        : this.level.acts[1].sysId;

    const levelPosition = ProgramPositionFactory.createLevelPosition(
      this.level,
      activityId
    );

    // TODO: When constructing faux level, activity / encounter are chosen
    // based on current position.  Since there is only one element in placement
    // pools, this has not been done yet
    if (this.currentPosition.activityPositions.length) {
      if (
        this.currentPosition.activityPositions[0].activityId ===
        LexiaPlacementId.Activity
      ) {
        this.currentPosition.activityPositions[0].activityId =
          this.level.acts[1].sysId;
        this.currentPosition.activityPositions[0].encounterId =
          this.level.acts[1].encounters[0].sysId;
      }

      this.currentPosition.activityPositions =
        this.currentPosition.activityPositions.map(ap =>
          !ap.unitPosition.roundId ? this.setToFirstRound(ap) : ap
        );
      levelPosition.activityPositions = levelPosition.activityPositions.map(
        ap =>
          this.currentPosition.activityPositions.find(
            cp => cp.activityId === ap.activityId
          ) || ap
      );
      if (this.programMode === ProgramMode.Placement) {
        levelPosition.activityPositions[0].isComplete = true;
      }
    }

    const programPosition = {
      ...this.currentPosition,
      ...levelPosition
    };

    this.levelId = this.level.sysId;

    // the program position adjust updates the current position
    this.dispatches.push(
      ProgramContextAction.programPositionAdjustedForPlacement(programPosition)
    );

    this.loadUnit();

    return this;
  }

  public loadUnit(): LevelSetupBuilder {
    this.dispatches.push(UnitAction.load.request());

    return this;
  }

  /**
   * This is applied when either of the following are true:
   * - roundId is blank because it is returned that way from the API
   * - position has partial progress, but is not POK or Placement
   * In either case, we update the position to the first round in the unit.
   */
  private maybeAdjustActivityPositions(): this {
    const revisions = this.currentPosition.activityPositions
      .filter(
        ap => !ap.unitPosition.roundId || this.isImproperPartialProgress(ap)
      )
      .map(ap => this.setToFirstRound(ap));

    if (revisions.length) {
      this.dispatches.push(ActivityPositionAction.adjusted(revisions));
    }

    return this;
  }

  /**
   * For authenticated students:
   * Partial progress is only valid for POK and Placement rounds
   * - Returns true (is improper) for partial progress in other round types
   * - Returns false for any other userRole
   */
  private isImproperPartialProgress(
    activityPosition: IActivityPosition
  ): boolean {
    if (
      !this.isStudentLoggedIn ||
      this.programMode === ProgramMode.Placement ||
      this.isPok(activityPosition)
    ) {
      return false;
    }

    const firstRoundId =
      LevelSetupHelper.getFirstRoundIdForActivityPositionUnit(
        activityPosition,
        this.level
      );
    const isPartialProgress =
      !!activityPosition.unitPosition.roundId &&
      firstRoundId !== activityPosition.unitPosition.roundId;

    if (isPartialProgress) {
      void Services.logger!.logError(
        new LexiaError(
          'Improper partial progress',
          LevelSetupBuilder.displayName,
          LevelSetupBuilderError.ImproperPartialProgress
        )
          .withContext({ activityPosition })
          .withSeverity(LexiaErrorSeverity.Warning)
      );
    }

    return isPartialProgress;
  }

  /**
   * Checks by Unit.type, and if that is not found, checks if is last unit
   * in the encounter
   */
  private isPok(activityPosition: IActivityPosition) {
    const unit = LevelSetupHelper.getUnitForActivityPosition(
      activityPosition,
      this.level
    ) as IUnit; // Non-Onboarding/Placement units are the full IUnit at this point

    if (unit.type) {
      return unit.type === UnitType.PresentationOfKnowledge;
    }

    void Services.logger!.logError(
      new LexiaError(
        'Unit type missing',
        LevelSetupBuilder.displayName,
        LevelSetupBuilderError.MissingUnitType
      )
        .withContext({ activityPosition, unit })
        .withSeverity(LexiaErrorSeverity.Warning)
    );

    const encounter = LevelSetupHelper.getEncounterForActivityPosition(
      activityPosition,
      this.level
    );

    const pokUnitId = encounter?.units.at(-1)?.sysId;

    return pokUnitId === activityPosition.unitPosition.unitId;
  }

  private setToFirstRound(
    activityPosition: IActivityPosition
  ): IActivityPosition {
    const roundId = LevelSetupHelper.getFirstRoundIdForActivityPositionUnit(
      activityPosition,
      this.level
    );

    return {
      ...activityPosition,
      unitPosition: {
        ...activityPosition.unitPosition,
        attempts: [],
        roundExposureCount: 1,
        roundId
      }
    };
  }

  public loadSoundLogCollectionPolicies(): this {
    this.dispatches.push(SoundLogsAction.loadCollectionPolicies());

    return this;
  }

  public initializeLevelsCompleted(): this {
    this.dispatches.push(LevelsCompletedAction.init.request());

    return this;
  }

  /**
   * Use larger fonts for lower program levels
   */
  public setThemeSize(isOnboardingOrPlacement?: boolean): this {
    const levelNumber = toNumber(this.level.title);
    const size =
      levelNumber > 12 && !isOnboardingOrPlacement
        ? ThemeSize.Small
        : ThemeSize.Large;

    this.dispatches.push(ThemeAction.update({ theme: { size } }));

    return this;
  }

  /**
   * Set the position to the start of the level if no position has yet been set
   * (e.g. educator mode) or the current position level differs from the active
   * level (e.g. completing a level).
   */
  public maybeSetLevelStartPosition(): this {
    if (this.levelId === this.level.sysId) {
      return this;
    }

    const levelStartPosition = ProgramPositionFactory.create(this.level);
    this.dispatches.push(PositionAction.levelStartPosition(levelStartPosition));

    return this;
  }

  public setActPosition(activityId: string): this {
    this.dispatches.push(PositionAction.activitySelected({ activityId }));

    return this;
  }

  public mountVoiceovers(): this {
    this.dispatches.push(LevelAction.mountVoiceovers.request());

    return this;
  }

  public preparePlacementScene(): this {
    this.dispatches.push(PlacementSceneAction.prepare());

    return this;
  }

  public prepareHomeScene(): this {
    this.dispatches.push(HomeSceneAction.prepare());

    return this;
  }

  public prepareLevelIntroScene(
    payload: ILevelIntroSceneActionPreparePayload
  ): LevelSetupBuilder {
    this.dispatches.push(LevelIntroSceneAction.prepare(payload));

    return this;
  }

  public prepareLevelScene(payload: ILevelSceneActionPreparePayload): this {
    this.dispatches.push(LevelSceneAction.prepare(payload));

    return this;
  }

  public prepareEncounterScene(): this {
    this.dispatches.push(EncounterSceneAction.prepare());

    return this;
  }
}

export enum LevelSetupBuilderError {
  InvalidPlacementLevel = 'InvalidPlacementLevel',
  ImproperPartialProgress = 'ImproperPartialProgress',
  MissingUnitType = 'MissingUnitType'
}
