import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PositionAction,
  PositionActionLevelPositionUnloaded
} from '../program-context';

export function handleLogoutCurriculumEpic(
  action$: Observable<UserGlobalActionLogoutSuccess>
): Observable<PositionActionLevelPositionUnloaded> {
  return action$.pipe(
    ofType(UserGlobalActionType.LogoutSuccess),
    map(() => PositionAction.levelPositionUnloaded())
  );
}
handleLogoutCurriculumEpic.displayName = 'handleLogoutCurriculumEpic';
