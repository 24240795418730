import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  IListener,
  IListenResult,
  ISreAppConfig,
  ISreConfig,
  LanguageFrameSession as LF,
  RSpeechPlatform,
  SreConfigStatus,
  SreSessionOptions,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import {
  Sre,
  SreFactory,
  VoiceType as NfVoiceType,
  VoiceType,
  IMicrophoneOptions
} from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { LanguageFrameListener } from './listeners';
import { ListenerFactory } from './listeners/ListenerFactory';
import { MicrophoneManager } from './microphone/MicrophoneManager';
import { SoundLogConfigurator } from './sound-logs/SoundLogConfigurator';
import { Services } from 'services/Services';
import { LoggingLevel } from '@lexialearning/main-model';
import {
  ISessionInfo,
  SessionState,
  SessionType
} from '@lexialearning/sre/dist/sre-core/SessionQueue';
import { LoboLogItemCategory } from 'logging';
import { MicEnergyService } from './mic-energy/MicEnergyService';

export enum SreServiceError {
  Fatal = 'Fatal'
}

export class SreService {
  public static readonly displayName = 'SreService';

  public static ConfigKey = 'sre';

  public listener: IListener | undefined;

  public get options(): ISreAppConfig {
    return this.configProvider.getConfig<ISreAppConfig>(SreService.ConfigKey);
  }

  public readonly microphones = new MicrophoneManager();

  public soundLogs: SoundLogConfigurator;

  private sre: Sre | undefined;
  private readonly initializedSre: Promise<Sre>;

  private get currentSessionInfo(): ISessionInfo | undefined {
    return this.sre?.getCurrentSessionInfo();
  }

  public constructor(
    private readonly configProvider: IConfigProvider,
    sessionId: string
  ) {
    this.soundLogs = new SoundLogConfigurator(() => this.options, sessionId);
    this.initializedSre = this.createInitializedSrePromise();
    this.configureMicEnergyService();
  }

  private async createInitializedSrePromise(): Promise<Sre> {
    try {
      const sre = await SreFactory.create({
        onAbort: cause => {
          // istanbul ignore next - temp to allow for initial merge of 1.6
          throw new LexiaError(
            'SRE aborted',
            SreService.displayName,
            SreServiceError.Fatal
          ).withCause(cause);
        }
      });

      this.sre = sre;
      this.soundLogs.sre = sre;

      return sre;
    } catch (err) {
      // istanbul ignore next - TODO
      throw new LexiaError(
        'SreService creation error',
        SreService.displayName,
        SreErrorCode.CreationFailure
      ).withCause(err);
    }
  }

  public async configure(): Promise<ISreConfig> {
    const sre = await this.initializedSre;
    try {
      await sre.configure({
        difficulty: 3,
        languageCode: 'en-US',
        voiceType: VoiceType.Child as NfVoiceType
      });

      sre.configureSoundLogCollection(this.soundLogs.createConfig());
      this.soundLogs.configure();

      const { micsAvailable, mic, micBlocked } =
        await this.microphones.init(sre);

      return {
        language: sre.getConfiguredLanguage(),
        // make serializable for redux serializableCheck
        mic: mic && { ...mic },
        micBlocked,
        // make serializable for redux serializableCheck
        micsAvailable: micsAvailable.map(m => ({ ...m })),
        rSpeechPlatform: RSpeechPlatform.Unknown, // TODO remove from lobo common
        sreVersion: sre.sreVersion,
        status: SreConfigStatus.Initialized,
        voiceType: sre.getConfiguredVoiceType()
      };
    } catch (err) {
      throw new LexiaError(
        'Error configuring SRE',
        SreService.displayName,
        SreErrorCode.ConfigurationError
      ).withCause(err);
    }
  }

  public async openMicrophone(options: IMicrophoneOptions): Promise<void> {
    // istanbul ignore next - TODO
    if (!this.sre) {
      void Services.logger!.log({
        category: LoboLogItemCategory.SreUndefinedAtMicOpenRequest,
        loggingLevel: LoggingLevel.Info,
        payload: {},
        summary: 'openMicrophone called when sre not defined'
      });
    }

    const sre = await this.initializedSre;

    sre.getMicrophoneService().openMicrophone(options);
  }

  public closeMicrophone(): void {
    // istanbul ignore next - TODO
    if (!this.sre) {
      void Services.logger!.log({
        category: LoboLogItemCategory.SreUndefinedAtMicCloseRequest,
        loggingLevel: LoggingLevel.Info,
        payload: {},
        summary: 'closeMicrophone called when sre not defined'
      });

      return;
    }

    this.sre!.getMicrophoneService().closeMicrophone();
  }

  public async cancel(): Promise<void> {
    const sendSoundLogs = false;

    return this.interrupt(sendSoundLogs);
  }

  public async finish(): Promise<void> {
    const sendSoundLogs = true;

    return this.interrupt(sendSoundLogs);
  }

  private async interrupt(sendSoundLogs: boolean): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('SRE.INTERRUPT');

    const sre = await this.initializedSre;

    if (sendSoundLogs) {
      await sre.endSession();
    } else {
      await sre.cancelSession();
    }
  }

  /**
   * Cancel current session, if there is one
   * UNLESS the current session is a PrimeLanguageFrame sessionType
   * and the allowCancelPrimed arg is false
   *
   * @param allowCancelPrimed - if true, current session will be cancelled, regardless of whether it is a Primed session
   */
  // istanbul ignore next - TODO
  public async cancelCurrentSessionMaybe(
    allowCancelPrimed = true
  ): Promise<void> {
    const isPrimedSession =
      this.currentSessionInfo?.type === SessionType.PrimeLanguageFrame;
    // istanbul ignore next - TODO: finish Addressing specs - LOBO-19187
    if (allowCancelPrimed || !isPrimedSession) {
      await this.cancelCurrentSession();
    }
  }

  /**
   * Cancel current session, if there is one
   */
  public async cancelCurrentSession(): Promise<void> {
    // istanbul ignore if - will resolve after initial SRE 1.6 merge
    if (this.hasActiveSession()) {
      await this.cancel();
    }
  }

  private hasActiveSession() {
    return (
      // istanbul ignore next - TODO: finish Addressing specs - LOBO-19187
      !!this.currentSessionInfo &&
      this.currentSessionInfo.state !== SessionState.Ended
    );
  }

  /**
   * For LanguageFrame sessions only, this allows a session to be
   * ready for immediate use when a listen request comes in,
   * resolving listening lag at the beginning of the session
   * @param options
   * @param ignoreActiveSession - if true, will not throw if hasActiveSession is true
   */
  public async prime(options: LF.IConfig): Promise<{
    resultPromise: Promise<IListenResult>;
  }> {
    if (options.sessionType !== SreSessionType.LanguageFrame) {
      throw new LexiaError(
        'Sre prime called when SreSessionType not LanguageFrame',
        SreService.displayName,
        SreErrorCode.NonPrimableSessionType
      );
    }

    const sre = await this.initializedSre;

    // istanbul ignore next - TODO: finish addressing specs - LOBO-19187
    if (this.hasActiveSession()) {
      throw new LexiaError(
        'Sre prime called when another session is currently active',
        SreService.displayName,
        SreErrorCode.PrimeCalledWhenOtherSessionActive
      ).withContext({
        currentSessionInfo: this.currentSessionInfo
      });
    }

    const listener = new LanguageFrameListener(sre);
    this.listener = listener;

    return {
      // Returned only for handling of errors
      // This is ignored for the non-errored result, which is handled via call to
      // the listen method here
      resultPromise: listener.prime(options)
    };
  }

  public async listen(
    options: SreSessionOptions
  ): Promise<{ resultPromise: Promise<IListenResult> }> {
    this.listener = await this.getListener(options);

    return {
      resultPromise: this.getListenResult(this.listener!, options)
    };
  }

  private async getListener(options: SreSessionOptions): Promise<IListener> {
    const sre = await this.initializedSre;

    return (
      this.getPrimedListenerMaybe(options) ||
      ListenerFactory.create(options, sre)
    );
  }

  private getPrimedListenerMaybe(options: SreSessionOptions) {
    if (options.sessionType === SreSessionType.LanguageFrame) {
      const listener = this.listener as LanguageFrameListener;
      const { isPrimed } = listener || {};
      if (listener && isPrimed) {
        return listener;
      } else {
        void Services.logger!.log({
          category: LoboLogItemCategory.LackingPrimedSession,
          loggingLevel: LoggingLevel.Info,
          payload: { hasListener: !!listener, isPrimed, options },
          summary:
            'LanguageFrame listening session started without a primed session already running'
        });
      }
    }
  }

  private async getListenResult(
    listener: IListener,
    options: SreSessionOptions
  ): Promise<IListenResult> {
    let result: IListenResult;

    try {
      result = await listener.listen(options);
    } finally {
      this.listener = undefined;
    }

    return result;
  }

  private async configureMicEnergyService(): Promise<void> {
    const sre = await this.initializedSre;
    MicEnergyService.init(sre.getMicrophoneService());
  }

  /**
   * Console log the current SRE session info
   * @deprecated Marking as deprecated as this is intended for debugging, should not be used for Production code
   */
  // istanbul ignore next - not for production
  public logSreSessionInfo(): void {
    // eslint-disable-next-line no-console
    console.log('Current SRE session info', { ...this.currentSessionInfo });
  }
}

export enum SreErrorCode {
  ConfigurationError = 'ConfigurationError',
  CreationFailure = 'CreationFailure',
  NonPrimableSessionType = 'NonPrimableSessionType',
  PrimeCalledWhenOtherSessionActive = 'PrimeCalledWhenOtherSessionActive'
}
