import { SubmitButton } from 'task-components/shared';
import { InteractionState } from 'common-styles';
import { useDispatch } from 'react-redux';
import { AvatarEditorAction } from '../redux';
import { useRef } from 'react';
import { SubmitAvatarButtonStyles } from './SubmitAvatarButton.styles';
import { AvatarEditorEvent } from 'logging';

export interface ISubmitAvatarButtonProps {
  hasAvatarChanged: boolean;
}
export function SubmitAvatarButton(props: ISubmitAvatarButtonProps) {
  const { hasAvatarChanged } = props;
  const selectorStyles = useRef(new SubmitAvatarButtonStyles());
  const styles = selectorStyles.current.get();

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      AvatarEditorAction.addUserEvent({
        eventName: AvatarEditorEvent.SubmitButtonPressed
      })
    );
    dispatch(AvatarEditorAction.saveAndExit());
  };

  return (
    <SubmitButton
      interactionState={
        hasAvatarChanged ? InteractionState.Default : InteractionState.Disabled
      }
      onPress={handleSubmit}
      style={styles.submitButton}
      disabled={!hasAvatarChanged}
    />
  );
}
SubmitAvatarButton.displayName = 'SubmitAvatarButton';
