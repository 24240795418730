import { AppShellAction } from 'feature-areas/shell';
import { AvatarAnimationName } from 'feature-areas/avatar-editor/avatar-editor.model';
import { AvatarEditorAction } from 'feature-areas/avatar-editor/redux';
import { AvatarEditorEvent } from '@lexialearning/lobo-common/main-model/logging';
import { AvatarInstructionModal } from 'feature-areas/avatar-editor/instruction-modal/AvatarInstructionModal';
import { RouteBuilder, RouterService } from 'router-service';
import { Sfx } from 'audio';
import { AvatarEditorScreenplayId } from './avatar-editor-screenplay-builders.model';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { AvatarTimerDurationMs } from 'feature-areas/avatar-editor/redux/avatar-editor-redux.model';

export class AvatarEditorIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'AvatarEditorIntroScreenplayBuilder';

  public static create(): AvatarEditorIntroScreenplayBuilder {
    return new AvatarEditorIntroScreenplayBuilder();
  }

  private constructor() {
    super(AvatarEditorScreenplayId.Intro);
  }

  public setTimerDuration(): AvatarEditorIntroScreenplayBuilder {
    this.builder.addReduxAction(
      AvatarEditorAction.timerSetDuration(AvatarTimerDurationMs.Creating)
    );

    return this;
  }

  public navToAndShowAvatarEditor(): AvatarEditorIntroScreenplayBuilder {
    this.builder
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.avatarEditor());
      })
      .addReduxAction(AvatarEditorAction.showEditor());

    return this;
  }

  public playAvatarIntroduction(): AvatarEditorIntroScreenplayBuilder {
    this.builder
      // Without .addDelay(10), the AvatarAnimationName.ShowFaceSelector completes immediately
      .addDelay(10)
      .addSfx(Sfx.AvatarExpansion, { concurrent: true })
      .addReactAnimation(AvatarAnimationName.ShowFaceSelector);

    return this;
  }

  public addDelay(delayMs: number): AvatarEditorIntroScreenplayBuilder {
    this.builder.addDelay(delayMs);

    return this;
  }

  public showInstructionalModal(): AvatarEditorIntroScreenplayBuilder {
    this.builder.addReduxAction(
      AppShellAction.showModal({ id: AvatarInstructionModal.ModalId })
    );

    return this;
  }

  public addInstructionalGifUserEvent(): AvatarEditorIntroScreenplayBuilder {
    this.builder.addReduxAction(
      AvatarEditorAction.addUserEvent({
        data: { initiator: AvatarEditorIntroScreenplayBuilder.displayName },
        eventName: AvatarEditorEvent.InstructionalGifOpened
      })
    );

    return this;
  }
}
