import { fromEvent, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INetworkState } from './network-state.model';

export class NetworkStateProvider {
  public static readonly displayName = 'NetworkStateProvider';

  public static global = window;

  public static createState$(): Observable<INetworkState> {
    return merge(
      of(undefined), // force an initial emit to establish network state
      fromEvent(NetworkStateProvider.global, 'online'),
      fromEvent(NetworkStateProvider.global, 'offline')
    ).pipe(
      map(() => ({ isOnline: NetworkStateProvider.global.navigator.onLine }))
    );
  }

  // async to match native interface
  public static async getOnlineStatus(): Promise<boolean> {
    return Promise.resolve(NetworkStateProvider.global.navigator.onLine);
  }
}
