import { SceneName, StormAssets } from 'services/storm-lobo/StormAssets';
import {
  IMaterialDefinition,
  ISceneDefinition,
  SceneDefinitionBuilder
} from 'storm';
import { NumberUtils } from 'utils';
import {
  LevelIntroSceneElementName,
  LevelIntroSceneMaterialName
} from './level-intro-scene.model';

export class LevelIntroSceneDefinitionBuilder {
  public static readonly ScenePath = '/scenes/level-intro/level_intro.sg';

  public static create(level: string): LevelIntroSceneDefinitionBuilder {
    const materials: IMaterialDefinition[] = [
      {
        name: LevelIntroSceneMaterialName.PaperBack,
        texturePaths: [`/scenes/images/level-intro/l${level}_intro.tx`]
      }
    ];

    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.LevelIntro,
      this.ScenePath,
      { materials }
    );

    const levelNum = NumberUtils.parse(level);
    const priorLevel = NumberUtils.toTwoDigitString(levelNum - 1);

    return new LevelIntroSceneDefinitionBuilder(definition, priorLevel, level);
  }

  private constructor(
    public readonly definition: ISceneDefinition,
    private readonly priorLevel: string,
    private readonly level: string
  ) {}

  public withPriorLevelBackground(): LevelIntroSceneDefinitionBuilder {
    return this.withBackground(1, this.priorLevel);
  }

  public withLevelBackground(): LevelIntroSceneDefinitionBuilder {
    return this.withBackground(2, this.level);
  }

  private withBackground(
    backgroundNumber: number,
    level: string
  ): LevelIntroSceneDefinitionBuilder {
    const background = SceneDefinitionBuilder.create(
      LevelIntroSceneElementName.buildBackground(backgroundNumber),
      `${StormAssets.Backgrounds}/level/level_${level}_background.sg`
    ).definition;
    this.definition.elements.push(background);

    return this;
  }

  public withBackgroundOverlay(): LevelIntroSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      LevelIntroSceneElementName.BackgroundOverlay,
      StormAssets.BackgroundOverlays.Stars
    ).definition;
    this.definition.elements.push(def);

    return this;
  }
}
