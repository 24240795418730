import { ContentfulApi } from '@lexialearning/lobo-common/cms';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { Linking } from 'common-ui/helpers';
import { ConfigProvider } from 'app-config';
import { DevToolsActionOpenContentfulEntry } from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

interface IDeps {
  configProvider: ConfigProvider;
}

export function openContentfulEntryEpic(
  action$: Observable<DevToolsActionOpenContentfulEntry>,
  _: StateObservable<unknown>,
  deps: IDeps
): Observable<void> {
  return action$.pipe(
    ofType(DevToolsActionType.OpenContentfulEntry),
    tap((action: DevToolsActionOpenContentfulEntry) => {
      const { configProvider } = deps;
      const api = new ContentfulApi(configProvider);
      const url = api.buildEntryUrl(action.payload.entryId);
      void Linking.openUrl(url);
    }),
    ignoreElements()
  );
}
openContentfulEntryEpic.displayName = 'openContentfulEntryEpic';
