import { DeviceInfoReporter } from '@lexialearning/utils-react';
import {
  GradeName,
  ILoginResponse,
  LexiaService,
  LexiaServiceSelector,
  UserRole
} from 'lexia-service';
import {
  ILoginApiResponse,
  IProfileStudent,
  ILoginUserRequest
} from 'lexia-service/auth-api/auth-api-private.model';
import {
  ActStatus,
  IPosition,
  RoundLexiaResult,
  StudentProgressApiEndpoint
} from './student-progress-api-private.model';
import { LexiaError } from '@lexialearning/utils';
import { nanoid } from '@reduxjs/toolkit';
import { ProgramMode } from 'curriculum-services/program-context/program-context.model';
import { IPositionResponse } from './student-progress-api.model';

export class LoginHelper {
  public static readonly displayName = 'LoginHelper';

  public static async login(
    lexiaApiService: LexiaService,
    state: unknown,
    apiEndpoint: string,
    version: string,
    personId: number,
    role: UserRole,
    authserverToken: string
  ): Promise<ILoginResponse> {
    const url = apiEndpoint + StudentProgressApiEndpoint.Login;

    const deviceInfo = await DeviceInfoReporter.create();
    const { uniqueId } = deviceInfo.device;
    const deviceId = role === UserRole.Student ? uniqueId : undefined;
    const loginUserRequestBase =
      LexiaServiceSelector.getLexiaServiceRequests(state).login;

    const request: ILoginUserRequest = {
      ...loginUserRequestBase,
      authserverToken,
      deviceId,
      personId,
      retrieveStudentProperties: true,
      role,
      version
    };

    const apiLoginResponse = await lexiaApiService.postRequest<
      ILoginApiResponse<IProfileStudent>
    >(request, url);

    if (apiLoginResponse.error?.code) {
      throw this.createLoginError(request, apiLoginResponse);
    }

    const updateData = {
      authToken: apiLoginResponse.authToken,
      studentId: apiLoginResponse.studentId ?? undefined
    };

    lexiaApiService.updateRequestBase({
      logout: updateData,
      studentApi: updateData
    });

    lexiaApiService.updateServiceOptions({
      options: {
        apiTimeoutArray: apiLoginResponse.apiTimeoutArray,
        maxRetries: apiLoginResponse.apiTimeoutArray?.length - 1
      }
    });

    return this.buildLoginResponse(apiLoginResponse, role, personId);
  }

  private static createLoginError(
    request: ILoginUserRequest,
    loginResponse: ILoginApiResponse
  ): LexiaError {
    return new LexiaError(
      `Error logging in: ${loginResponse.error!.message}`,
      LoginHelper.displayName,
      LoginHelperError.LoginError
    )
      .withContext({ loginResponse, request })
      .withStandardCode(loginResponse.error!.code);
  }

  private static buildLoginResponse(
    loginInfo: ILoginApiResponse<IProfileStudent>,
    role: UserRole,
    personId: number
  ): ILoginResponse {
    const positionArray =
      role === UserRole.Student &&
      loginInfo.profile.strandStatusArray[0]?.positionArray
        ? this.mapPositionArray(
            loginInfo.profile.strandStatusArray[0]?.positionArray
          )
        : [];

    const studentId = loginInfo.profile.studentId
      ? loginInfo.profile.studentId
      : undefined;

    const programMode =
      ProgramMode[loginInfo.profile.programMode] ??
      (role === UserRole.Student
        ? ProgramMode.ActiveStudent
        : ProgramMode.Educator);

    const response: ILoginResponse = {
      apiTimeoutArray: loginInfo.apiTimeoutArray,
      authToken: loginInfo.authToken,
      firstName: loginInfo.profile.firstName,
      grade: loginInfo.profile.grade as GradeName,
      lastName: loginInfo.profile.lastName,
      loginId: loginInfo.studentLoginId ?? nanoid(),
      minutesSinceLastLogin:
        loginInfo.profile.minutesSinceLastLogin ?? undefined,
      personId,
      positionArray,
      programMode,
      role,
      studentId,
      studentProperties: loginInfo.studentPropertyArray ?? undefined
    };

    return response;
  }

  private static mapPositionArray(
    positionArray: IPosition[]
  ): IPositionResponse[] {
    return positionArray.map(pos => ({
      actSysId: pos.activityId,
      encounterSysId: pos.unitGroupId,
      isComplete: pos.activityStatus === ActStatus.Completed,
      lastProgressDate: pos.lastProgressDate || '',
      levelSysId: pos.conceptId,
      roundSysId:
        pos.unitProgress?.roundArray.find(
          r => r.status === RoundLexiaResult.Imminent
        )?.contentId ?? '',
      rounds:
        pos.unitProgress?.roundArray
          .filter(r => r.status !== RoundLexiaResult.Imminent)
          .map(r => ({
            attempts: r.attemptArray,
            isScored: r.status !== RoundLexiaResult.Excluded,
            roundId: r.contentId
          })) ?? [],
      unitSysId: pos.unitId
    }));
  }
}

export enum LoginHelperError {
  LoginError = 'LoginHelper'
}
