import memoizeOne from 'memoize-one';
import * as React from 'react';
import { ReactReduxContext } from 'react-redux';
import { Store } from 'redux';
import { DevShortcuts } from './DevShortcuts';

export interface IDevShortcutsProps {
  devShortcuts: DevShortcuts;
}

export function withDevShortcuts(WrappedComponent: any) {
  return class DevShortcutsHOC extends React.PureComponent<any> {
    public static readonly displayName = 'DevShortcutsHOC';

    private static readonly shortcutsCreator = memoizeOne(
      (store: Store) => new DevShortcuts(store)
    );

    public render() {
      return (
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <WrappedComponent
              {...this.props}
              devShortcuts={DevShortcutsHOC.shortcutsCreator(store as any)}
            />
          )}
        </ReactReduxContext.Consumer>
      );
    }
  };
}
