import { SreSessionType } from 'sre';
import { IMicEnergyMeterProps, MicEnergyMeter } from './MicEnergyMeter';

export const LanguageFrameMeter = (props: IMicEnergyMeterProps) => (
  <MicEnergyMeter
    {...props}
    subscribeToSessionType={SreSessionType.LanguageFrame}
    testId={LanguageFrameMeter.displayName}
  />
);
LanguageFrameMeter.displayName = 'LanguageFrameMeter';
