import { ActionType, createAction } from 'typesafe-actions';
import { ProfileActionType } from './profile-redux.model';
import { IProfile } from '../profile.model';
import { IAvatar } from '../avatar.model';

export const ProfileAction = {
  loadSuccess: createAction(ProfileActionType.LoadSuccess)<IProfile>(),
  updateAvatar: createAction(ProfileActionType.UpdateAvatar)<Partial<IAvatar>>()
};

export type ProfileActionLoadSuccess = ActionType<
  typeof ProfileAction.loadSuccess
>;
export type ProfileActionUpdateAvatar = ActionType<
  typeof ProfileAction.updateAvatar
>;

export type ProfileActions = ActionType<typeof ProfileAction>;
