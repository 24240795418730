import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { ICustomerState } from './customer-redux.model';
import { CustomerActions } from './Customer.action';
import { CustomerActionType } from './CustomerActionType';

const customerInitialState: ICustomerState = {
  customer: {
    customerCode: '',
    customerName: ''
  },
  deviceSetup: {
    attemptedToSetupDeviceWithCustomerCode: false,
    deviceSetupCompleted: false,
    invalidEmail: false,
    siteIdParsed: false
  }
};

export function customerReducer(
  state = customerInitialState,
  action: CustomerActions
): ICustomerState {
  switch (action.type) {
    case CustomerActionType.ChangeAccount:
      return customerInitialState;

    case CustomerActionType.ClearErrors:
      return {
        ...state,
        deviceSetup: { ...state.deviceSetup, invalidEmail: false }
      };

    case CustomerActionType.BootstrapSetup:
      return {
        ...state,
        customer: { ...state.customer, customerCode: action.payload },
        deviceSetup: {
          ...state.deviceSetup,
          siteIdParsed: true
        }
      };

    case CustomerActionType.SetupDeviceWithCustomerCodeAttempted:
      return {
        ...state,
        deviceSetup: {
          ...state.deviceSetup,
          attemptedToSetupDeviceWithCustomerCode: true,
          // we do optimistic update here, not to block user by this request (should be successful for the majority of attempts)
          deviceSetupCompleted: action.payload.completeDeviceSetupOptimistically
        }
      };

    case CustomerActionType.SetupDeviceWithCustomerCodeSuccess:
    case CustomerActionType.SetupDeviceWithEmailSuccess:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        },
        deviceSetup: {
          ...state.deviceSetup,
          deviceSetupCompleted: true,
          invalidEmail: false
        }
      };

    case CustomerActionType.SetupDeviceWithCustomerCodeFailure:
    case CustomerActionType.SetupDeviceWithEmailFailure:
      const err = action.payload as LexiaError;

      return {
        ...state,
        deviceSetup: {
          ...state.deviceSetup,
          deviceSetupCompleted: false,
          invalidEmail:
            !!err.standardCode &&
            [
              LexiaStandardErrorCode.InvalidSiteId,
              LexiaStandardErrorCode.UnknownCustomer
            ].includes(err.standardCode)
        }
      };

    default:
      return state;
  }
}

export const CustomerReducerPrivates = {
  initialState: customerInitialState
};
