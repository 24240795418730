import { StormAssets } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';
import { PlacementSceneElementName } from './placement-scene.model';

export class PlacementSceneDefinitionBuilder {
  public static create(): PlacementSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Placement,
      StormAssets.Scene.Placement
    );

    return new PlacementSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}

  public withBackgroundOverlay(): PlacementSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      PlacementSceneElementName.BackgroundOverlay,
      StormAssets.BackgroundOverlays.Stars
    ).definition;
    this.definition.elements.push(def);

    return this;
  }

  public withCharacter(): PlacementSceneDefinitionBuilder {
    const character = SceneDefinitionBuilder.create(
      PlacementSceneElementName.Character,
      `${StormAssets.Folders.Placement.path}/character/lydia.sg`,
      { animations: StormAssets.Placement.CharacterAnimations }
    ).definition;
    this.definition.elements.push(character);

    return this;
  }

  public withOverlay(): PlacementSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      PlacementSceneElementName.Overlay,
      `${StormAssets.Folders.Placement.path}/finish_effect.sg` // TODO: rename? celebration_overlay? etc?
    ).definition;
    this.definition.elements.push(def);

    return this;
  }
}
