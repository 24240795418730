import { Column, Image, View } from 'common-ui';
import { withKeyboard } from 'common-ui/components/withKeyboard.hoc';
import { IKeyboardProps } from 'common-ui/components/withKeyboard.model';
import { BackgroundGradientSvg } from 'feature-areas/shell';
import { LoboVersion } from 'shared-components';
import LoginPng from './images/40th-anniversary/Login.png';
import { LoginShellStyles } from './LoginShell.styles';

export function LoginShellComponent(props: IKeyboardProps) {
  const { children, keyboardHeight } = props;
  const styles = LoginShellStyles.build(keyboardHeight);

  return (
    <Column style={styles.container} testId={LoginShellComponent.displayName}>
      <BackgroundGradientSvg id={LoginShellComponent.displayName} />
      <View style={styles.formContainer}>
        <Image
          source={LoginPng}
          style={styles.loginImg}
          accessibilityLabel="Lexia English logo and characters."
        />
        {children}
        <View style={styles.versionContainer} testId="VersionView">
          <LoboVersion />
        </View>
      </View>
    </Column>
  );
}
LoginShellComponent.displayName = 'LoginShell';

export const LoginShell = withKeyboard(LoginShellComponent);
