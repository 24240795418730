import { addCompletedLevelEpic } from './addCompletedLevel.epic';
import { initLevelsCompletedEpic } from './initLevelsCompleted.epic';
import { loadLevelsCompletedEpic } from './loadLevelsCompleted.epic';
import { logCertificateViewedEpic } from './logCertificateViewed.epic';

export const levelsCompletedEpic = [
  addCompletedLevelEpic,
  initLevelsCompletedEpic,
  loadLevelsCompletedEpic,
  logCertificateViewedEpic
];
