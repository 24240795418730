export interface IApplyTextureOptions {
  materialName: string;
  channel: MaterialChannelName;
  index: number;
}

export enum MaterialChannelName {
  Ambient = 'AMBIENT',
  Diffuse = 'DIFFUSE',
  SpecularColor = 'SPECULAR_COLOR',
  SpecularLevel = 'SPECULAR_LEVEL',
  Gloss = 'GLOSS',
  SelfIllumination = 'SELF_ILLUMINATION',
  Bump = 'BUMP',
  Reflection = 'REFLECTION'
}
