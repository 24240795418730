import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  CmsService,
  CmsServiceFactory,
  ContentfulApi,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import {
  ITaskCmsRegistration,
  TaskCmsRegistry
} from '@lexialearning/lobo-common/cms/task-registries';
import { createCarouselCmsRegistration } from '@lexialearning/lobo-common/tasks/carousel/createCarouselCmsRegistration';
import { createCompletionCmsRegistration } from '@lexialearning/lobo-common/tasks/completion/createCompletionCmsRegistration';
import { createImageTaskCmsRegistration } from '@lexialearning/lobo-common/tasks/image-task/createImageTaskCmsRegistration';
import { createMultipleChoiceCmsRegistration } from '@lexialearning/lobo-common/tasks/multiple-choice/createMultipleChoiceCmsRegistration';
import { createPlaceholderCmsRegistration } from '@lexialearning/lobo-common/tasks/placeholder/createPlaceholderCmsRegistration';
import { createPodcastCmsRegistration } from '@lexialearning/lobo-common/tasks/podcast/createPodcastCmsRegistration';
import { createSeeSpeakCmsRegistration } from '@lexialearning/lobo-common/tasks/see-speak/createSeeSpeakCmsRegistration';
import { createTextOrderingCmsRegistration } from '@lexialearning/lobo-common/tasks/text-ordering/createTextOrderingCmsRegistration';
import { createTextboardCmsRegistration } from '@lexialearning/lobo-common/tasks/textboard/createTextboardCmsRegistration';
import { createUserChoiceCmsRegistration } from '@lexialearning/lobo-common/tasks/user-choice/createUserChoiceCmsRegistration';

export function createCmsService(
  configProvider: IConfigProvider,
  contentApi: ContentfulApi
): CmsService {
  const taskRegistry = new TaskCmsRegistry<ITaskCmsRegistration>([]);
  const cmsService = CmsServiceFactory.create(
    configProvider,
    taskRegistry,
    contentApi
  );
  taskRegistry.register([
    createCompletionCmsRegistration(LoboContentType.Completion, cmsService),
    createMultipleChoiceCmsRegistration(
      LoboContentType.MultipleChoice,
      cmsService
    ),
    createSeeSpeakCmsRegistration(LoboContentType.SeeSpeak, cmsService),
    createTextOrderingCmsRegistration(LoboContentType.TextOrdering, cmsService),
    createUserChoiceCmsRegistration(LoboContentType.UserChoice, cmsService),
    createImageTaskCmsRegistration(LoboContentType.LabeledImage, cmsService),
    createPodcastCmsRegistration(LoboContentType.LabeledImage, cmsService),
    createCarouselCmsRegistration(LoboContentType.Carousel, cmsService),
    createTextboardCmsRegistration(LoboContentType.Textboard),
    createPlaceholderCmsRegistration(LoboContentType.PlaceholderTask)
  ]);

  return cmsService;
}
