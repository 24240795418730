import { IAnimationDefinition } from '@lexialearning/lobo-common/main-model';

export class AnimationDefinitions {
  constructor(public readonly definitions: IAnimationDefinition[]) {}

  public select(query: IAnimationDefinitionQuery): IAnimationDefinition[] {
    return this.definitions.filter(
      d =>
        (!query.scene || d.sceneNames.includes(query.scene)) &&
        (!query.element || d.targetElements.includes(query.element)) &&
        (!query.category || d.category === query.category)
    );
  }
}

export interface IAnimationDefinitionQuery {
  category?: string;
  scene?: string;
  element?: string;
}
