import { createSelector } from '@reduxjs/toolkit';
import { DevToolsPosition, IDevToolsState } from './IDevToolsState';

type DevToolsSelectorType = (state: any) => IDevToolsState;

export class DevToolsSelector {
  public static readonly displayName = 'DevToolsSelector';

  public static getDevToolsState: DevToolsSelectorType;

  public static getIsVisible: (state: any) => boolean | undefined;

  public static getPosition: (state: any) => DevToolsPosition | undefined;

  public static shouldShowMicErrorShortcuts: (state: any) => boolean;

  public static shouldShowIosShortcuts: (state: any) => boolean;

  public static createSelectors(selector: DevToolsSelectorType) {
    DevToolsSelector.getDevToolsState = createSelector(
      selector,
      (devToolsState: IDevToolsState) => devToolsState
    );

    DevToolsSelector.getIsVisible = createSelector(
      DevToolsSelector.getDevToolsState,
      (state: IDevToolsState) => state.isVisible
    );

    DevToolsSelector.getPosition = createSelector(
      DevToolsSelector.getDevToolsState,
      (state: IDevToolsState) => state.position
    );

    DevToolsSelector.shouldShowMicErrorShortcuts = createSelector(
      DevToolsSelector.getDevToolsState,
      (state: IDevToolsState) => state.shouldShowMicErrorShortcuts
    );

    DevToolsSelector.shouldShowIosShortcuts = createSelector(
      DevToolsSelector.getDevToolsState,
      (state: IDevToolsState) => state.shouldShowIosShortcuts
    );
  }
}
