import { AnimatedView, PressScaleButton, useDidMount } from 'common-ui';
import { FunFactButtonAnimatedStyles } from './FunFactButton.animated-styles';
import { ExclamationMarkSvg } from './svgs/ExclamationMark.lx-svg';
import { useEffect, useRef, useState } from 'react';
import {
  ActiveComponentState,
  useActiveComponentState
} from '@lexialearning/common-ui';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';

export interface IFunFactButtonProps extends IButtonBaseProps {
  onPress: () => void;
}

export interface IFunFactButtonState {
  isEntryFinished: boolean;
}

export function FunFactButton(props: IFunFactButtonProps) {
  const { onPress, ...restProps } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const [isEntryFinished, setIsEntryFinished] = useState<boolean>(false);

  const animatedStyles = useRef(new FunFactButtonAnimatedStyles());

  const animations = animatedStyles.current.getAnimations();

  const styles = animatedStyles.current.build(activeComponentState);

  const disabled = !isEntryFinished;

  useDidMount(() => {
    animations.entry.start(() => {
      setIsEntryFinished(true);
    });
  });

  useEffect(() => {
    if (isEntryFinished) {
      switch (activeComponentState) {
        case ActiveComponentState.Hovered:
          animations.hovered.start();
          break;
        case ActiveComponentState.KeyboardFocused:
          animations.keyboardFocused.start();
          break;
        case ActiveComponentState.KeyboardPressed:
          animations.keyboardPressed.start();
          break;
        case ActiveComponentState.Pressed:
          animations.pressed.start();
          break;
        default:
          animations.toDefault.start();
          break;
      }
    }
  }, [activeComponentState, animations, isEntryFinished]);

  return (
    <AnimatedView
      animatedStyle={styles.translateAnimated}
      style={styles.translate}
      testId={FunFactButton.displayName}
    >
      <AnimatedView animatedStyle={styles.scaleAnimated}>
        <AnimatedView animatedStyle={styles.opacityAnimated}>
          <PressScaleButton
            {...restProps}
            {...interactionHandlers}
            disabled={disabled}
            hoverScale={1.06}
            onPress={onPress}
            pressScale={0.95}
          >
            <AnimatedView
              animatedStyle={styles.backgroundColorAnimated}
              style={styles.circle}
            >
              <ExclamationMarkSvg
                activeComponentState={activeComponentState}
                color={styles.exclamationMark.color}
              />
            </AnimatedView>
          </PressScaleButton>
        </AnimatedView>
      </AnimatedView>
    </AnimatedView>
  );
}
FunFactButton.displayName = 'FunFactButton';
