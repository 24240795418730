import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LevelAction, LevelActionLoadSuccess } from '../../level';
import { ProgramContextSelector, ProgramMode } from '../../program-context';
import { FauxLevelFactory } from '../faux-level-factory/FauxLevelFactory';
import { PlacementActionType } from '../redux/placement-redux.model';
import { PlacementActionLoadSuccess } from '../redux/Placement.action';

export function loadFauxLevelEpic(
  action$: Observable<PlacementActionLoadSuccess>,
  state$: StateObservable<unknown>
): Observable<LevelActionLoadSuccess> {
  return action$.pipe(
    ofType(PlacementActionType.LoadSuccess),
    map(action => {
      validate(state$.value);
      const levelContent = FauxLevelFactory.create(action.payload);

      return LevelAction.load.success({ levelContent });
    })
  );
}

function validate(state: unknown): void {
  const activityPositions = ProgramContextSelector.getActivityPositions(state);
  const programMode = ProgramContextSelector.getMode(state);

  if (!activityPositions.length) {
    // there can only be empty activity positions on onboarding
    if (programMode === ProgramMode.Onboarding) {
      return;
    }

    throw new LexiaError(
      'Placement activity position is missing!',
      loadFauxLevelEpic.displayName,
      LoadFauxLevelEpicError.PlacementActivityPositionMissing
    ).withContext({ activityPositions });
  }
}

loadFauxLevelEpic.displayName = 'loadFauxLevelEpic';

export enum LoadFauxLevelEpicError {
  PlacementActivityPositionMissing = 'PlacementActivityPositionMissing'
}
