import { UtilityButton, UtilityButtonIconType, View } from 'common-ui';
import { TroubleshootButtonStyles } from './TroubleshootButton.styles';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';

export interface ITroubleshootButtonProps extends IButtonBaseProps {
  isHighlighted?: boolean;
}

export function TroubleshootButton(props: ITroubleshootButtonProps) {
  const { isHighlighted, testId, ...restProps } = props;

  const styles = TroubleshootButtonStyles.build(isHighlighted);

  return (
    <View style={styles.container}>
      <UtilityButton
        {...restProps}
        styleOverride={styles.buttonOverride}
        iconType={UtilityButtonIconType.Apple}
        testId={testId || TroubleshootButton.displayName}
      />
    </View>
  );
}
TroubleshootButton.displayName = 'TroubleshootButton';
