import { Color } from 'common-styles';

export const UtilityButtonColors = {
  shadow: 'rgba(0,0,0,0.2)'
};

const borderWidth = 2;
export const UtilityButtonSharedStyles = {
  button: {
    borderRadius: 9,
    height: 38
  },
  buttonHighlight: {
    borderColor: Color.Gold,
    borderRadius: 11,
    borderWidth,
    right: -borderWidth,
    top: -borderWidth
  }
};

export enum UtilityButtonIconType {
  Apple = 'apple',
  BackArrow = 'backArrow',
  Close = 'close',
  Gear = 'gear',
  Logout = 'logout',
  Tools = 'tools'
}

export enum UtilityButtonType {
  /** Semi-opaque dark background, with white border and icon */
  Default = 'default',
  /** No background, black icon */
  Basic = 'basic'
}

export enum UtilityButtonColorSetName {
  Default = 'default',
  Light = 'light',
  Dark = 'dark',
  Black = 'black',
  BlackGray = 'blackGray',
  BlackWhite = 'blackWhite',
  WhiteBlack = 'whiteBlack'
}

export interface IUtilityButtonColorSet {
  backgroundColor?: string;
  borderColor?: string;
  iconColor: string;
  withShadow?: boolean;
}

export const UtilityButtonColorSetMap = new Map<
  UtilityButtonColorSetName,
  IUtilityButtonColorSet
>([
  // DEFAULT BUTTON COLOR SETS
  [
    UtilityButtonColorSetName.Default,
    {
      backgroundColor: Color.BlackTransparent50,
      borderColor: Color.NearWhite,
      iconColor: Color.NearWhite,
      withShadow: true
    }
  ],
  [
    UtilityButtonColorSetName.Light,
    {
      backgroundColor: Color.BlackTransparent40,
      borderColor: Color.NearWhite,
      iconColor: Color.NearWhite,
      withShadow: true
    }
  ],
  [
    UtilityButtonColorSetName.Dark,
    {
      backgroundColor: Color.BlackTransparent55,
      borderColor: Color.Gray10,
      iconColor: Color.Gray10,
      withShadow: true
    }
  ],
  [
    UtilityButtonColorSetName.BlackGray,
    {
      backgroundColor: Color.Gray10,
      borderColor: Color.Black,
      iconColor: Color.Black
    }
  ],
  [
    UtilityButtonColorSetName.BlackWhite,
    {
      backgroundColor: Color.White,
      borderColor: Color.Black,
      iconColor: Color.Black
    }
  ],
  // BASIC BUTTON COLOR SETS
  [
    UtilityButtonColorSetName.Black,
    {
      iconColor: Color.Black
    }
  ],
  [
    UtilityButtonColorSetName.WhiteBlack,
    {
      backgroundColor: Color.Black,
      iconColor: Color.White
    }
  ]
]);
