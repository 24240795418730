import {
  FaceStyle,
  HairColor,
  HairStyle,
  IAvatar,
  SkinColor
} from 'services/profile/avatar.model';
import { GradeName, UserRole } from 'lexia-service';
import { EnumUtils } from 'utils/EnumUtil';

export interface IProfile {
  avatar?: IAvatar;
  firstName: string;
  grade: GradeName;
  lastName: string;
  minutesSinceLastLogin: number | undefined;
  role: UserRole;
  studentId?: number;
}

/**
 * Profile used for educator mode deep-linking
 */
export const DeepLinkEducatorProfile: IProfile = {
  firstName: 'Lobo',
  grade: GradeName.Other,
  lastName: 'DeepLinkEducator',
  minutesSinceLastLogin: undefined,
  role: UserRole.DeepLinkEducator,
  studentId: -999
};

/**
 * Profile used for non-educator mode deep-linking
 */
export const DeepLinkStudentProfile: IProfile = {
  avatar: {
    faceStyle: EnumUtils.getRandomValue(FaceStyle),
    hairColor: EnumUtils.getRandomValue(HairColor),
    hairStyle: EnumUtils.getRandomValue(HairStyle),
    skinColor: EnumUtils.getRandomValue(SkinColor)
  },
  firstName: 'Lobo',
  grade: GradeName.G1,
  lastName: 'DeepLinkStudent',
  minutesSinceLastLogin: undefined,
  role: UserRole.DeepLinkStudent,
  studentId: -999
};
