import { IUnit } from '@lexialearning/lobo-common/main-model';
import { uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { UnitCompleteSceneAction } from 'feature-areas/units/unit-complete-scene/redux';
import { Checkbox, Column, IDropdownItem, Row, Text } from 'common-ui';
import { AppState } from 'services';
import { UnitSelector } from 'services/curriculum-services/unit';
import { StormAssets } from 'services/storm-lobo';
import { DevToolsStyles } from '../DevTools.styles';
import { DevToolsDropdown } from '../DevToolsDropdown';
import { DevToolsAction } from '../redux/DevTools.action';
import { DevToolsSelector } from '../redux/DevTools.selector';
import { DevToolsPosition } from '../redux/IDevToolsState';

interface IUnitDevToolsProps {
  position: DevToolsPosition;
  unit: IUnit | undefined;
  updateHasOnscreenCharacter(hasOnscreenCharacter: boolean): void;
  openContentfulEntry(entryId: string): void;
  prepareUnitCompleteScene(): void;
}

interface IUnitDevToolsState {
  unitReward: string;
}

const RANDOM = 'random';

class UnitDevToolsComponent extends React.PureComponent<
  IUnitDevToolsProps,
  IUnitDevToolsState
> {
  public static readonly displayName = 'UnitDevTools';

  private readonly unitRewardItems: IDropdownItem[];

  private readonly originalRewards: string[];

  constructor(props: IUnitDevToolsProps) {
    super(props);

    this.handleToggleHasOnscreenCharacter =
      this.handleToggleHasOnscreenCharacter.bind(this);
    this.handleUnitLink = this.handleUnitLink.bind(this);
    this.handleSelectUnitReward = this.handleSelectUnitReward.bind(this);

    const unitReward =
      uniq(StormAssets.Unit.Rewards).length === 1
        ? StormAssets.Unit.Rewards[0]
        : RANDOM;
    this.state = { unitReward };

    this.originalRewards = (StormAssets.Folders.UnitComplete.items || []).map(
      r => `${StormAssets.Folders.UnitComplete.path}/${r}.sg`
    );
    this.unitRewardItems = [
      { id: RANDOM, label: RANDOM },
      ...this.originalRewards.map(r => ({
        id: r,
        label: r.split('reward_')[1].replace('.sg', '')
      }))
    ];
  }

  private handleUnitLink(): void {
    this.props.openContentfulEntry(this.props.unit!.sysId);
  }

  private handleToggleHasOnscreenCharacter(): void {
    this.props.updateHasOnscreenCharacter(
      !this.props.unit?.hasOnscreenCharacter
    );
  }

  private handleSelectUnitReward(value: string): void {
    this.setState({ unitReward: value });
    StormAssets.Unit.Rewards =
      value === RANDOM
        ? [...this.originalRewards]
        : this.originalRewards.map(() => value);
    this.props.prepareUnitCompleteScene();
  }

  public render() {
    const { position, unit } = this.props;
    const styles = DevToolsStyles.build(position);

    if (!unit) {
      return (
        <Column style={{ ...styles.contentBlock, ...styles.unitInfoBox }}>
          <Text style={styles.contentBlockLabel}>Unit: N/A</Text>
        </Column>
      );
    }

    return (
      <Column style={{ ...styles.contentBlock, ...styles.unitInfoBox }}>
        <Text style={styles.contentBlockLabel}>Unit:</Text>
        <Column style={styles.contentItemsList}>
          <Row>
            <Text style={styles.contentItemLink} onPress={this.handleUnitLink}>
              {unit.title}
            </Text>
          </Row>

          <Row style={styles.checkboxRow}>
            <Checkbox
              isChecked={unit.hasOnscreenCharacter}
              label="Has character"
              labelId="hasCharacter"
              onToggle={this.handleToggleHasOnscreenCharacter}
              styleOverride={styles.checkboxOverrides}
            />
          </Row>

          <Row>
            <Row style={styles.contentItemBullet} />
            <Text style={styles.contentItemLabel}>Step:</Text>
            <Text style={styles.contentItemValue}>
              {unit.instructionalStep}
            </Text>
          </Row>
          <Row style={styles.contentItem}>
            <Row style={styles.contentItemBullet} />
            <Text style={styles.contentItemLabel}>Rounds: </Text>
            <Text style={styles.contentItemValue}>{unit.rounds.length}</Text>
          </Row>

          <DevToolsDropdown
            accessibilityLabel="Unit Reward"
            id="unitReward"
            label="Unit reward"
            items={this.unitRewardItems}
            selectedItemId={this.state.unitReward}
            onChange={this.handleSelectUnitReward}
          />
        </Column>
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    position: DevToolsSelector.getPosition(state)!,
    unit: UnitSelector.getUnitMaybe(state)
  };
}

const mapDispatchToProps = {
  openContentfulEntry: (entryId: string) =>
    DevToolsAction.openContentfulEntry({ entryId }),
  prepareUnitCompleteScene: () => UnitCompleteSceneAction.prepare(),
  updateHasOnscreenCharacter: (hasOnscreenCharacter: boolean) =>
    DevToolsAction.updateHasOnscreenCharacter({ hasOnscreenCharacter })
};

export const UnitDevTools = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitDevToolsComponent);
