import { IUnitSavePoint } from '@lexialearning/lobo-common/main-model/curriculum';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { SavePointActionType } from './save-point-redux.model';

export const SavePointAction = {
  created: createAction(SavePointActionType.Created)<IUnitSavePoint>(),
  roundAdded: createAction(SavePointActionType.RoundAdded)<IUnitSavePoint>(),
  savePosition: createAsyncAction(
    SavePointActionType.SavePosition,
    SavePointActionType.SavePositionSuccess,
    SavePointActionType.SavePositionFailure
  )<IProgramContextSaveActionPayload, IProgramContextSaveActionPayload, void>()
};

export interface IProgramContextSaveActionPayload {
  unitId: string;
}

export type SavePointActionCreated = ActionType<typeof SavePointAction.created>;
export type SavePointActionRoundAdded = ActionType<
  typeof SavePointAction.roundAdded
>;
export type SavePointActionSavePosition = ActionType<
  typeof SavePointAction.savePosition.request
>;
export type SavePointActionSavePositionSuccess = ActionType<
  typeof SavePointAction.savePosition.success
>;

export type SavePointActions = ActionType<typeof SavePointAction>;
