import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  CommonUiAction,
  CommonUiActionSetHasViewedOnboardingVideo
} from '../redux';

import {
  StudentPropertyAction,
  StudentPropertyActionLoadSuccess
} from '@lexialearning/student-api';
import { StudentApiHelper } from 'student-api';

/**
 * Set hasViewedOnboardingVideo from loaded studentProperties from the Student API
 */
export function setHasViewedOnboardingVideoEpic(
  action$: Observable<StudentPropertyActionLoadSuccess>
): Observable<CommonUiActionSetHasViewedOnboardingVideo> {
  return action$.pipe(
    filter(StudentPropertyAction.load.success.match),
    map(action => {
      const hasViewedOnboardingVideo =
        StudentApiHelper.getHasViewedOnboardingVideo(
          action.payload.studentProperties
        );

      return CommonUiAction.setHasViewedOnboardingVideo({
        hasViewedOnboardingVideo
      });
    })
  );
}
setHasViewedOnboardingVideoEpic.displayName = 'setHasViewedOnboardingVideoEpic';
