import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import { LoginConfirmationModal } from 'feature-areas/login/login-form';
import { AuthActionAuthenticateSuccess, AuthActionType } from '../redux';

export function showLoginConfirmationModalEpic(
  action$: Observable<AuthActionAuthenticateSuccess>
): Observable<AppShellActionShowModal> {
  return action$.pipe(
    ofType(AuthActionType.AuthenticateSuccess),
    map(() => AppShellAction.showModal({ id: LoginConfirmationModal.ModalId }))
  );
}
showLoginConfirmationModalEpic.displayName = 'showLoginConfirmationModalEpic';
