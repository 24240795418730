import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SreSessionType } from 'sre';
import {
  SreCalibrationAction,
  SreCalibrationActionCalibrating,
  SreSessionActionListening,
  SreSessionActionType
} from '../redux';

export function calibrationCalibratingEpic(
  action$: Observable<SreSessionActionListening>
): Observable<SreCalibrationActionCalibrating> {
  return action$.pipe(
    ofType(SreSessionActionType.Listening),
    filter(a => a.payload === SreSessionType.Calibration),
    map(() => SreCalibrationAction.calibrating())
  );
}
calibrationCalibratingEpic.displayName = 'calibrationCalibratingEpic';
