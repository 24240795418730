import { Svg, Path, G, Rect } from '@lexialearning/common-ui';
import { FaceStyle } from 'services/profile/avatar.model';
import { getFaceColors } from '../face-styles.model';
import { useSelector } from 'react-redux';
import { ProfileSelector } from 'services/profile';

export function FaceStyleOneSvg(): React.ReactElement {
  const { hairColor, skinColor } = useSelector(ProfileSelector.getAvatarMaybe)!;
  const {
    eyeLines,
    eyebrowLines,
    eyebrows,
    eyelashes,
    eyes,
    lips,
    skin,
    skinLines
  } = getFaceColors(FaceStyle.One, hairColor, skinColor);

  return (
    <Svg
      data-test-id={FaceStyleOneSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <G>
        <Rect fill={skin} strokeWidth="0" width="1000" height="1000" />
      </G>
      <G>
        <G>
          <Path
            fill={lips}
            strokeWidth="0"
            d="M674.73,871.69c-19.05-2.49-41.33-6.47-63.77-8.16-76.08-5.73-152.21-14.15-228.44-1.68-23.03,3.77-45.95,8.25-69.03,11.71-12.73,1.91-25.65,2.85-38.52,3.2-3.16.09-6.43-3.84-9.66-5.92,3.49-2.15,6.75-5.09,10.54-6.2,2.94-.86,6.55.56,9.87.97,15.38,1.91,29.61-.42,43.32-8.59,18.66-11.13,38.1-20.94,57.19-31.33,11.31-6.16,23.01-7.1,35.09-2.67,12.64,4.63,24.73,4.81,37.73-.37,20.63-8.21,41.88-5.46,62.69.55,36.52,10.55,72.62,22.85,109.61,31.34,17.31,3.97,36.28,1.24,54.45.54,6.8-.26,14.53-3.51,15.66,6.27,1.05,9.06-7.32,8.4-13.33,9.25-3.3.47-6.66.56-13.42,1.09Z"
          />
          <G>
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M368.34,538.14c-4.7,17.28-10.14,34.4-13.96,51.87-11.17,51.03,4.12,92,47.86,121.57,1.72,1.16,3.44,2.35,5.01,3.7.53.46.58,1.48,2.07,5.73-6.79-2.08-12.31-2.6-16.5-5.23-41.65-26.14-65.45-78.82-49.53-128.15,5.67-17.58,13.7-34.4,20.66-51.57,1.47.69,2.94,1.38,4.41,2.07Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M555.98,611.58c21.11,19.14,18.98,47.02,2.76,63.48-7.56,7.67-21.5,10.09-33.05,12.51-15.52,3.25-31.52,4.19-49.87,6.43,2.67-4.04,4.72-11.01,8.63-12.29,9.17-3.02,19.2-3.67,28.95-4.58,6.13-.57,12.39.41,18.58.22,27.36-.85,35.64-26.37,29.28-46.69-1.97-6.29-3.53-12.71-5.28-19.07Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M767.49,258.13c-50.2-.11-90.32,21.49-123.84,57.29,18.47-43.09,85-68.46,123.84-57.29Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M276.89,302.65c27.37,17.98,51.99,38.93,62.16,74.75-21.59-25.97-41.88-50.36-62.16-74.75Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M389.33,326.71c13.77,10.52,25.12,39.56,23.12,59.5-7.41-19.07-15.51-39.91-23.12-59.5Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M325.55,459.31c-8.76,57.28-56.73,85.09-110.25,83.93-60.72-1.32-100.86-63.17-100.86-108.91,0-49.88,35.21-108.91,118.12-90.48,52.26,11.61,100.54,66.15,92.99,115.46Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M648.48,454.71c8.76,57.28,67.6,94.93,133.03,87.53,66.98-7.57,105.15-66.75,105.15-112.49,0-49.88-53.61-117.82-137.53-104.72-77.67,12.12-108.19,80.36-100.65,129.67Z"
            />
            <Path
              fill={eyes}
              strokeWidth="0"
              d="M779.92,509.88c-6.3,0-12.74-.74-19.16-2.21-41.73-9.75-68.9-49.57-66.04-96.81,2.02-34,32.69-68.51,66.97-75.37,3.69-.45,8.08-.76,12.65-.76,17.94,0,35.38,4.83,51.83,14.37,26.94,15.62,38.73,45.05,36,89.95-7.38,26.22-21.18,45.6-42.18,59.21-11.94,7.72-25.43,11.62-40.08,11.62Z"
            />
            <Path
              fill={eyes}
              strokeWidth="0"
              d="M238.65,502.05c-28.51,0-48.32-13.44-64.22-43.55-12.46-23.86-5.97-48.01,1.67-64.06,11.21-23.62,32.41-32.79,48.21-36.31,1.97-.42,4.07-.64,6.37-.64,9.18,0,21.83.9,33.71,5.03,32.24,11.24,44.11,43.25,43.42,65.89-.96,37.27-15.29,49.93-25.75,59.16-6.38,5.69-21.32,12.65-33.46,13.96-3.41.34-6.75.52-9.95.52Z"
            />
            <G>
              <Path
                fill={eyeLines}
                strokeWidth="0"
                d="M827.61,422.8c-.32,42.23-51.48,64.47-80.47,33.22-17.32-17.5-15.34-56.86,4.03-72.7,30.71-26.43,78.04-4.96,76.5,36.51l-.06,2.97Z"
              />
              <Path
                fill={eyeLines}
                strokeWidth="0"
                d="M829.74,343.06c-25.68-14.91-49.82-16.89-69.46-14.37-37.04,7.42-70.3,44-72.54,81.75-3.15,53.1,28.36,93.98,71.49,104,21.85,5.08,44.83,2.4,64.51-10.3,23.57-15.22,37.91-37.12,45.41-63.9,2.66-41.72-6.18-77.89-39.41-97.18ZM751.4,497.05c-5.3-2.61-15.5-8.68-20.29-12.15-21.28-15.42-30.94-38.38-32.14-64.97-2.35-52.13,50.65-93.84,108.19-69.98,50.68,21.01,65.67,83.84,33.59,124.9-18.45,23.62-60.49,36.43-89.36,22.2Z"
              />
            </G>
            <G>
              <Path
                fill={eyeLines}
                strokeWidth="0"
                d="M241.26,388.42c52.34.79,51.84,85.55-.93,84-48.72-3.46-53.64-80.74-1.71-83.96l2.65-.04Z"
              />
              <Path
                fill={eyeLines}
                strokeWidth="0"
                d="M314.84,428.68c.75-27.74-16.53-58.55-50.21-70.28-15.66-5.46-29.58-9.75-41.82-7.05-22.56,4.98-42.39,17.64-53.06,40.12-11.01,23.19-13.16,48.04-1.46,70.24,17.08,32.42,40.68,50.88,81.07,46.73,13-1.34,29.55-8.58,37.4-15.57,13.08-11.65,27.07-26.13,28.09-64.19ZM235.69,501.14c-36.2-1.14-65.55-33.24-64.97-71.07.6-39.69,31.81-73.3,68.62-71.61,33.66,1.54,67.05,32.02,66.04,73.55-.87,35.7-35.33,70.23-69.69,69.14Z"
              />
            </G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M217.55,441.78c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M752.18,439.68c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
          </G>
          <G>
            <G>
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M734.19,539.67c50.14,4.47,81.22-3.75,117.49-31.7-1.22,5.83-4.05,9.71-7.58,12.9-19.89,17.99-44.48,24.33-70.37,25.39-13.38.54-32.97-2.8-46.41-3.83.26-1.25,5.59-2.86,6.86-2.75Z"
              />
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M870.75,434.48c-.17,11.87.2,28.72,0,35.61-.04,1.39,1.68,6.77,2.91,7.43,4.7-6.22,11.86-17.24,14.61-24.23,20.81-53.03,1.5-112.42-54.86-143.91-60.43-33.77-168.12-9.95-190,81.13-5.46,22.73-7.41,45.88,4.93,68.69.61-35.14,12.25-65.83,32.63-93.74,20.15-27.58,68.37-38.6,85.94-37.81,81.92,3.69,104.62,53.05,103.84,106.82Z"
              />
            </G>
            <G>
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M252.91,539.16c-35.38,15.84-79.54,10.25-91.52-14.29,30.59,16.25,60.64,18.56,91.52,14.29Z"
              />
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M315.85,428.73c3.18,10.21,6.46,20.38,9.7,30.57,7.49-14.27,5.48-27.95,1.72-41.59-19.86-72.11-87.94-121.59-169.17-88.56-19.82,8.06-36.9,22.32-46.28,42.48-15.79,33.94-17.73,67.68,5.94,99.58,4.13,5.57,8.11,11.26,12.16,16.89-11.7-59.2-7.08-97,19.04-119.89,21.37-18.73,46.12-21.85,75.71-17.62,37.99,5.43,71.72,15.63,91.18,78.15Z"
              />
            </G>
          </G>
        </G>
        <G>
          <G>
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M919.19,265.09c-.74-.1-1.97-.49-3.54-1.34-4.77-2.62-9.47-5.44-14.16-8.25-7.36-4.41-14.97-8.97-22.88-12.8-44.46-21.6-89.62-32.57-134.2-32.57-3.58,0-7.18.07-10.79.2-46.78,1.84-80.22,11.58-108.44,31.55-1.47,1.05-3,2.39-4.53,3.73-1.07.93-2.13,1.86-3.17,2.68,1.39-3.59,5.03-9.53,10.88-17.74,9.53-13.19,20.99-28.75,33.68-43.99,14.97-18,35.83-26.37,65.67-26.37,2.88,0,5.77.07,8.67.22,39.23,1.91,76.28,15.31,107.12,38.73l6.62,5.03c20.32,15.44,54.33,41.28,67.87,58.13.68.81,1.12,2.05,1.2,2.79Z"
            />
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M59.67,299.44c-.31,0-.43-.08-.48-.12-.3-.22-1.39-1.76,1.32-9.35,13.96-36.78,41.05-63.54,80.52-79.46,17.67-7.17,39.46-12.78,55.51-14.3,2.98-.29,6.04-.44,9.17-.44,27.95,0,66.47,11.83,90.74,45.02,5.44,7.43,10.42,19.61,8.97,24.81.21-.87-1.86-1.22-7.18-4.3-24.2-14.01-50.56-20.81-80.61-20.81-11.91,0-24.67,1.11-37.93,3.3-42.98,7.24-80.91,24.58-112.72,51.55-4.12,3.37-6.25,4.1-7.31,4.1Z"
            />
          </G>
          <G>
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M920.69,260.09c1.54,1.91,2.98,5.82,1.15,7.69-1.86,1.9-5.72.23-7.85-.95-12.39-6.82-24.17-14.81-36.86-20.99-45.14-21.97-92.85-33.92-143.41-31.99-39.16,1.5-74.86,8.51-106.5,30.95-2.93,2.08-6.11,5.32-9.12,7.34-1.4.94-4.79,1.69-4.79-1.23,0-5.12,7.34-15.54,12.28-22.43,10.78-15.05,21.91-29.92,33.78-44.15,20.04-24.05,48.2-28.86,77.18-27.41,40.14,2,77.78,15.61,109.09,39.43,19.59,14.91,59.64,44.55,75.06,63.75ZM627.21,238.8c74.53-53.87,176.18-34.73,263.31,4.06-35.46-34.31-76.25-58.78-123.59-73.37-83.94-17.46-101.05,14.12-139.72,69.31Z"
            />
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M308.76,266.62c-1.1,4.03-8.87-.37-12.25-2.32-36.48-21.11-75.77-23.75-116.2-17-41.57,6.95-78.9,23.44-111.12,50.77-2.97,2.52-8.5,6.54-11.93,4.07-3.63-2.6-1.45-9.42.02-13.32,14.84-39.52,43.99-66.03,82.47-81.59,20-8.09,41.79-13.17,56.52-14.57,33-3.13,77.15,10.73,102.99,46.05,5.36,7.33,11.45,20.79,9.52,27.91ZM60.93,295.33c73.92-54.7,152.03-76.7,240.26-38.94-9.67-26.03-48.41-55.93-95.61-53.86-71.41,3.12-126.08,41.38-144.65,92.8Z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
FaceStyleOneSvg.displayName = 'FaceStyleOneSvg';
