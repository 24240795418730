import { TextPillWidth } from '@lexialearning/lobo-common/main-model';
import memoizeOne from 'memoize-one';
import {
  Align,
  Color,
  Direction,
  FontFamily,
  InteractionState,
  Justify,
  TextAlign
} from 'common-styles';

export class TextChoiceStyles {
  private static readonly textChoiceWidth = {
    [TextPillWidth.Wide]: 800,
    [TextPillWidth.Narrow]: 600
  };

  private static readonly styles = {
    leftPipe: {
      textAlign: TextAlign.Right,
      width: 250
    },
    pipe: {
      color: Color.Gray20,
      fontFamily: FontFamily.FigmentSansSemiBold,
      marginHorizontal: 30
    },
    rightPipe: {
      textAlign: TextAlign.Left,
      width: 250
    }
  };

  public static readonly build = memoizeOne(
    (
      interactionState: InteractionState = InteractionState.Default,
      textPillWidth = TextPillWidth.Narrow
    ) => {
      const width = TextChoiceStyles.textChoiceWidth[textPillWidth];

      return {
        ...this.styles,
        choiceContainerStyleOverride: {
          button: {
            borderRadius: 29
          },
          interactiveContainerWrapper: {
            alignItems: Align.Center,
            borderRadius: 25,
            height: 50,
            justifyContent: Justify.Center,
            width,
            ...(interactionState === InteractionState.Highlighted && {
              backgroundColor: Color.getSetFor(InteractionState.Highlighted)
                .background
            })
          },
          interactiveContainerStyleOverride: {
            backgroundColor: Color.Transparent,
            borderRadius: 23,
            flexDirection: Direction.Row,
            height: 46,
            width: width - 4,
            ...(interactionState === InteractionState.Default && {
              borderColor: Color.Gray20,
              borderWidth: 3,
              height: 44,
              width: width - 6
            }),
            ...(interactionState === InteractionState.Highlighted && {
              backgroundColor: Color.getSetFor(InteractionState.Highlighted)
                .background,
              borderColor: Color.NearWhite
            })
          }
        }
      };
    }
  );
}
