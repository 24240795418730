import { Sfx } from 'audio';
import { InteractionState } from 'common-styles';
import { MicAction } from 'common-ui';
import { ScreenplayBuilder } from 'screenplay';

export class MockMicListenScreenplayFactory {
  public static readonly displayName = 'MockMicListenScreenplayFactory';

  public static readonly screenplayId = 'MockListen';

  private static readonly micListeningTimeoutMs = 2000;

  private static readonly transitionTimeoutMs = 1000;

  private static builder?: ScreenplayBuilder;

  public static create() {
    if (this.builder) {
      return this.builder;
    }

    this.builder = ScreenplayBuilder.create(this.screenplayId)
      // set mic to highlighted (listening) state and play listening sfx
      // to mirror UI when app is actually listening
      .addReduxAction(
        MicAction.setInteractionState(InteractionState.Highlighted)
      )
      .addSfx(Sfx.MicListening)
      // wait in 'listening' state (while the person is saying "Let's go"),
      .addDelay(MockMicListenScreenplayFactory.micListeningTimeoutMs)
      // then set mic to 'correct' state
      .addReduxAction(MicAction.setInteractionState(InteractionState.Correct))
      // wait again before completing the animation to show the user
      // this correct state before moving on
      .addDelay(MockMicListenScreenplayFactory.transitionTimeoutMs);

    return this.builder;
  }
}
