import { AvatarEditorActionTimerTimeout } from '../AvatarEditor.action';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import { AvatarEditorTimeoutModal } from 'feature-areas/avatar-editor/timer/timeout-modal/AvatarEditorTimeoutModal';
import { AvatarEditorActionType } from '../avatar-editor-redux.model';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import { AppState } from 'services';

export function handleTimerTimeoutEpic(
  action$: Observable<AvatarEditorActionTimerTimeout>,
  state$: StateObservable<AppState>
): Observable<AppShellActionShowModal> {
  return action$.pipe(
    ofType(AvatarEditorActionType.TimerTimeout),
    map(() => {
      const showRetryButton = !!AvatarEditorSelector.getExtensionsRemaining(
        state$.value
      );

      return AppShellAction.showModal({
        id: AvatarEditorTimeoutModal.ModalId,
        props: { showRetryButton }
      });
    })
  );
}
handleTimerTimeoutEpic.displayName = 'handleTimerTimeoutEpic';
