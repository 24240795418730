import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as Cui from '@lexialearning/common-ui';
import { StormAction, StormActionInitialize } from '../redux';

/**
 * begins the lobo storm implementation initialization once the Cui bootstrapping has completed
 */
export function initializeCuiStormEpic(
  action$: Observable<Cui.StormActionBootstrap>,
  _state$: StateObservable<unknown>
): Observable<StormActionInitialize> {
  return action$.pipe(
    ofType(Cui.StormAction.bootstrap.success.type),
    mergeMap(async () => StormAction.initialize.request())
  );
}
initializeCuiStormEpic.displayName = 'initializeCuiStormEpic';
