import {
  IScreenplay,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { ITextOrdering } from '@lexialearning/lobo-common/tasks/text-ordering';
import { RoundContext } from 'curriculum-services';
import {
  IOrderingAnswer,
  serializeOrderingAnswer
} from 'task-components/shared';
import {
  ITaskRegistration,
  ScreenplayType,
  SolutionScreenplayBuilder,
  TaskRegistrationBuilder
} from '../core';
import { VerticalOrdering } from './VerticalOrdering';

export function createVerticalOrderingTaskRegistration(): ITaskRegistration<
  TaskTypeName.VerticalOrdering,
  ITextOrdering,
  IOrderingAnswer
> {
  return {
    ...TaskRegistrationBuilder.create<
      TaskTypeName.VerticalOrdering,
      ITextOrdering
    >(TaskTypeName.VerticalOrdering, VerticalOrdering).withScreenplayBuilder(
      ScreenplayType.Solution,
      createSolutionScreenplay
    ).registration,
    serializeAnswer: serializeOrderingAnswer
  };
}

function createSolutionScreenplay(context: RoundContext): IScreenplay {
  return SolutionScreenplayBuilder.createFor(context).addScreenplayList(
    context.getTask<ITextOrdering>().segments.map(segment => segment.voiceover!)
  ).screenplay;
}
