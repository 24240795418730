import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { ITaskAttemptEventPayload, LoboLogItemCategory } from 'logging';
import { ProgramContextSelector } from 'curriculum-services';
import { AppLoggerDependencies } from 'services/logging/AppLoggerDependencies';
import { TaskActionEvaluated, TaskActionType } from 'task-components';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { TaskAttemptEventDataFactory } from './TaskAttemptEventDataFactory';
import { AppState } from 'services';

export function logTaskAttemptEpic(
  action$: Observable<TaskActionEvaluated>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(TaskActionType.Evaluated),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.TaskAttempt),
    ignoreElements()
  );
}
logTaskAttemptEpic.displayName = 'logTaskAttemptEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<ITaskAttemptEventPayload, LoboLogItemCategory> {
  const context = ProgramContextSelector.getRoundContext(state);
  const payload = TaskAttemptEventDataFactory.create(
    context,
    helper.taskRegistry
  );

  return {
    category: LoboLogItemCategory.TaskAttempt,
    loggingLevel: LoggingLevel.Info,
    payload,
    summary: buildSummary(payload)
  };
}

function buildSummary(payload: ITaskAttemptEventPayload): string {
  const maybeSubtype = payload.taskSubtype ? ` (${payload.taskSubtype})` : '';

  return `${payload.roundPath}|${payload.taskType}${maybeSubtype}|${payload.attempt.result}`;
}
