import { Align, Font, Justify, TextAlign } from 'common-styles';

export class ContactSupportModalStyles {
  private static readonly textStyles = {
    fontSize: Font.Size.small,
    textAlign: TextAlign.Left
  };

  private static readonly styles = {
    bold: {
      ...ContactSupportModalStyles.textStyles,
      fontFamily: Font.Family.semiBold
    },
    container: {
      height: 230,
      justifyContent: Justify.SpaceBetween,
      padding: 20,
      paddingVertical: 30,
      width: 490
    },
    sentTextContainer: {
      alignItems: Align.Start,
      alignSelf: Align.Start
    },
    text: ContactSupportModalStyles.textStyles
  };

  public static get() {
    return this.styles;
  }
}
