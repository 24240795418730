import { Image } from 'common-ui';
import { ResizeMode } from 'common-ui/components/image';
import Digit0Png from './digits/Digit0.png';
import Digit1Png from './digits/Digit1.png';
import Digit2Png from './digits/Digit2.png';
import Digit3Png from './digits/Digit3.png';
import Digit4Png from './digits/Digit4.png';
import Digit5Png from './digits/Digit5.png';
import Digit6Png from './digits/Digit6.png';
import Digit7Png from './digits/Digit7.png';
import Digit8Png from './digits/Digit8.png';
import Digit9Png from './digits/Digit9.png';
import { SlideNumberStyles } from './SlideNumber.styles';
import { ImportantForAccessibility } from '@lexialearning/common-ui';

const digits = [
  Digit0Png,
  Digit1Png,
  Digit2Png,
  Digit3Png,
  Digit4Png,
  Digit5Png,
  Digit6Png,
  Digit7Png,
  Digit8Png,
  Digit9Png
];

export interface ISlideNumberProps {
  number: number;
}

export function SlideNumber(props: ISlideNumberProps) {
  const { number } = props;
  const styles = SlideNumberStyles.get();
  const digit = digits[number];

  return (
    <Image
      resizeMode={ResizeMode.Contain}
      style={styles.digitImage}
      source={digit}
      importantForAccessibility={ImportantForAccessibility.NoHideDescendants}
    />
  );
}
