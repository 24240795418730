import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { IStateManager } from '@lexialearning/lobo-common/lib/state-manager/StateManager';
import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { has } from 'lodash';
import {
  ILexiaServiceConfigs,
  LEXIA_SERVICE_CONFIG_KEY
} from './lexia-config.model';
import {
  IUpdateRequestBaseActionPayload,
  IUpdateServiceOptionsActionPayload,
  LexiaServiceAction
} from './redux/lexiaService.action';
import { LexiaServiceSelector } from './redux/LexiaService.selector';

export class LexiaService {
  public static readonly displayName = 'LexiaService';

  public static readonly SecondsToMilliseconds = 1000;

  constructor(
    private readonly configProvider: IConfigProvider,
    private readonly stateManager: IStateManager
  ) {}

  public createUrl(path: string) {
    const apiUrl = this.configProvider.getConfig<ILexiaServiceConfigs>(
      LEXIA_SERVICE_CONFIG_KEY
    ).customerUrl;

    return apiUrl + path;
  }

  public async postRequest<T>(
    request: object,
    endpointUrl: string
  ): Promise<T> {
    const url = this.cleanUrl(endpointUrl);
    const options = LexiaServiceSelector.getLexiaServiceOptions(
      this.stateManager.getState()
    );

    if (options.isMakingRequest) {
      return Promise.reject(
        new LexiaError(
          'Currently already making a post request to myLexia',
          LexiaService.displayName,
          LexiaServiceError.ActiveRequestInProgress
        ).withContext({
          activeRequest: {
            options
          },
          incomingRequest: {
            request,
            url
          }
        })
      );
    }

    this.startRequest();

    return new Promise((resolve, reject) => {
      this.post(request, url, resolve, reject, 1);
    });
  }

  private post(
    request: object,
    url: string,
    resolve: (value?: any | PromiseLike<any> | undefined) => void,
    reject: (reason?: any) => void,
    attemptNumber: number
  ): void {
    const { apiTimeoutArray, maxRetries } =
      LexiaServiceSelector.getLexiaServiceOptions(this.stateManager.getState());

    const shouldShowError = (attempt: number) => attempt === maxRetries + 1;

    const showTimeoutError = () => {
      this.endRequest();
      reject(
        new LexiaError(
          `Too many timeouts or errors for request to ${url}`,
          LexiaService.displayName,
          LexiaServiceError.TooManyTimeouts
        )
          .withContext({ request })
          .withStandardCode(LexiaStandardErrorCode.CantConnectToServer)
      );
    };

    const showServerError = (cause?: LexiaError) => {
      this.endRequest();
      const error =
        cause ??
        new LexiaError(
          `Too many errors for request to ${url}`,
          LexiaService.displayName,
          LexiaServiceError.TooManyErrors
        );

      reject(
        error
          .withContext({ request })
          .withStandardCode(LexiaStandardErrorCode.CantConnectToServer)
      );
    };

    const xhr = new XMLHttpRequest();

    // Network resilience is implemented in two parts.
    //
    // This is the first part.
    //
    // Here we are handling when the server is taking too long to respond.
    // We have timeout times specified in user.apiTimeoutArray (received on login of the application)
    // we use a default for the couple services before login.
    // If that timeout time passes, the XHR will call the ontimeout handler
    xhr.ontimeout = async () => {
      if (shouldShowError(attemptNumber)) {
        showTimeoutError();
      } else {
        this.post(request, url, resolve, reject, attemptNumber + 1);
      }
    };

    // The second part of network resilience.
    //
    // This happens when the server sends an error back right away or we
    // do not have a good enough (or any) connection to the server.
    // These usually error out immediately.
    // The XHR will call the onerror handler and we will wait the timeout time before trying again.
    xhr.onerror = () => {
      if (shouldShowError(attemptNumber)) {
        showTimeoutError();
      } else {
        setTimeout(
          () => {
            this.post(request, url, resolve, reject, attemptNumber + 1);
          },
          apiTimeoutArray[attemptNumber - 1] *
            LexiaService.SecondsToMilliseconds
        );
      }
    };

    // This will handle the general client side errors dealing with incorrect API URLS
    // or if all goes well with the API connection, a successful send.
    //
    // Data related issues will be reported back as a success with an error object.
    xhr.onreadystatechange = () => {
      // request is DONE
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.endRequest();
          try {
            resolve(JSON.parse(xhr.response));
          } catch (error) {
            showServerError(
              new LexiaError(
                `JSON.parse error for response from ${url}`,
                LexiaService.displayName,
                LexiaServiceError.JsonParseFailure
              )
                .withContext({ response: xhr.response })
                .withCause(error)
            );
          }

          return;
        }

        // handle server down for maintenance by immediately rejecting
        if (xhr.status === 503) {
          this.endRequest();
          reject(
            new LexiaError(
              `Server down for maintenance at ${url}`,
              LexiaService.displayName,
              LexiaServiceError.DownForMaintenance
            )
              .withContext({ request })
              .withStandardCode(LexiaStandardErrorCode.ProgramDown)
          );

          return;
        }

        if (xhr.status >= 400) {
          if (shouldShowError(attemptNumber)) {
            showServerError();
          } else {
            setTimeout(
              () => {
                this.post(request, url, resolve, reject, attemptNumber + 1);
              },
              apiTimeoutArray[attemptNumber - 1] *
                LexiaService.SecondsToMilliseconds
            );
          }
        }
      }
    };

    xhr.timeout =
      apiTimeoutArray[attemptNumber - 1] * LexiaService.SecondsToMilliseconds;

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/json');

    if (has(request, 'attemptNum')) {
      const postRequest = {
        ...request,
        attemptNum: attemptNumber
      };
      xhr.send(JSON.stringify(postRequest));

      return;
    }
    xhr.send(JSON.stringify(request));
  }

  private cleanUrl(url: string): string {
    const groups = url.match(/(https?:\/\/[^/\r\n]+)(\/[^\r\n]*)?/);

    return groups ? groups[1] + groups[2].replace(/\/\//g, '/') : url;
  }

  private startRequest() {
    this.stateManager.updateState(
      LexiaServiceAction.updateServiceOptions({
        options: { isMakingRequest: true }
      })
    );
  }

  private endRequest() {
    this.stateManager.updateState(
      LexiaServiceAction.updateServiceOptions({
        options: { isMakingRequest: false }
      })
    );
  }

  public updateRequestBase(payload: IUpdateRequestBaseActionPayload): void {
    this.stateManager.updateState(
      LexiaServiceAction.updateRequestBase(payload)
    );
  }

  public updateServiceOptions(
    payload: IUpdateServiceOptionsActionPayload
  ): void {
    this.stateManager.updateState(
      LexiaServiceAction.updateServiceOptions(payload)
    );
  }
}

export enum LexiaServiceError {
  ActiveRequestInProgress = 'ActiveRequestInProgress',
  DownForMaintenance = 'DownForMaintenance',
  JsonParseFailure = 'JsonParseFailure',
  TooManyTimeouts = 'TooManyTimeouts',
  TooManyErrors = 'TooManyErrors'
}
