import { ActionType, createAction } from 'typesafe-actions';
import { UnitCompleteSceneActionType } from './unit-complete-scene-redux.model';

export const UnitCompleteSceneAction = {
  prepare: createAction(UnitCompleteSceneActionType.Prepare)()
};

export type UnitCompleteScenePrepareAction = ActionType<
  typeof UnitCompleteSceneAction.prepare
>;

export type UnitCompleteSceneActions = ActionType<
  typeof UnitCompleteSceneAction
>;
