import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { lastValueFrom, Observable } from 'rxjs';
import { first, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { LevelSelector } from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { SpinnerHandler } from 'spinner-handler';
import { TransitionActionLevelCompleteToNext } from '../../Transition.action';
import { TransitionActionType } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';
import { LevelCompleteToNextTransitionFactory } from './LevelCompleteToNextTransitionFactory';

export function levelCompleteToNextTransitionEpic(
  action$: Observable<TransitionActionLevelCompleteToNext>,
  state$: StateObservable<unknown>,
  deps: IRoundToNextTransitionDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.LevelCompleteToNext),
    tap(() => {
      SpinnerHandler.requestSpinner();
    }),
    mergeMap(async () => {
      // Throwing an error from a timeout as the level may be momentarily undefined
      // upon hitting this code, which should not throw, but if it continues to
      // not be loaded, there is likely an issue with the loading
      const levelLoadTimeout = setTimeout(() => {
        throw new LexiaError(
          'Timed out waiting for next level to load',
          levelCompleteToNextTransitionEpic.displayName,
          LevelCompleteToNextTransitionEpicError.LevelLoadTimeout
        );
      }, 6000);

      await nextLevelLoaded(state$);
      clearTimeout(levelLoadTimeout);

      return LevelCompleteToNextTransitionFactory.create(state$, deps);
    }),
    tap(() => {
      SpinnerHandler.dismissSpinner();
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
levelCompleteToNextTransitionEpic.displayName =
  'levelCompleteToNextTransitionEpic';

export enum LevelCompleteToNextTransitionEpicError {
  LevelLoadTimeout = 'LevelLoadTimeout'
}

async function nextLevelLoaded(
  state$: StateObservable<unknown>
): Promise<void> {
  return lastValueFrom(
    state$.pipe(
      startWith(state$.value),
      first(state => !!LevelSelector.getLevelMaybe(state))
    )
  ).then(() => void 0);
}
