import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LevelSelector } from '../../level';
import { ProgramContextSelector } from '../../program-context';
import { LoadStatus } from '../levels-completed.model';
import {
  LevelsCompletedAction,
  LevelsCompletedActionInit,
  LevelsCompletedActionInitSuccess,
  LevelsCompletedSelector
} from '../redux';
import { ILevelsCompletedStateWrapper } from '../redux/levels-completed-redux.model';
import { LevelsStatusFactory } from './LevelsStatus.factory';

/**
 * Initialize levels completed state unless the completed levels were loaded
 * from the API. (This is needed primarily to know whether there should be
 * 19 or 12 and which ones to assume were skipped)
 * @see LevelsStatusFactory
 *
 * Assumed to be triggered by setUpLevelEpic
 */
export function initLevelsCompletedEpic(
  action$: Observable<LevelsCompletedActionInit>,
  state$: StateObservable<ILevelsCompletedStateWrapper>
): Observable<LevelsCompletedActionInitSuccess> {
  return action$.pipe(
    filter(LevelsCompletedAction.init.request.match),
    filter(
      () =>
        LevelsCompletedSelector.getLoadStatus(state$.value) ===
        LoadStatus.NotLoaded
    ),
    map(() =>
      ProgramContextSelector.isOnboardingOrPlacement(state$.value)
        ? 0
        : LevelSelector.getLevelNumber(state$.value)
    ),
    map(levelNumber =>
      LevelsStatusFactory.create(
        levelNumber,
        ProgramContextSelector.getFinalLevel(state$.value)
      )
    ),
    map(completedLevels => LevelsCompletedAction.init.success(completedLevels))
  );
}
initLevelsCompletedEpic.displayName = 'initLevelsCompletedEpic';
