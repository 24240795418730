import {
  IActivityPosition,
  IPositionChange,
  ITaskEvaluation,
  IUnit,
  IUserChoice,
  PositionChangeType
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { isEqual } from 'lodash';
import { PositionDeterminer } from 'curriculum-services/program-context/epics/progress/position-determiners/PositionDeterminer';
import { IUserChoiceAnswer } from './user-choice-component.model';

export class UserChoicePositionDeterminer extends PositionDeterminer {
  public static readonly displayName: string = 'UserChoicePositionDeterminer';

  public determine(): IPositionChange {
    const { lastAttemptMaybe, round } = this.context;
    const selections = this.getSelections(lastAttemptMaybe);
    const task = round.task as IUserChoice;
    const path = task.paths.find(p => isEqual(p.selections, selections));

    if (!path) {
      throw this.createError(
        'Unable to find a path matching User Choice selection(s)',
        UserChoicePositionDeterminerError.PathNotFound
      );
    }

    return {
      activityPosition: this.fork(path.pathUnit),
      changeType: PositionChangeType.Fork
    };
  }

  private getSelections(attempt?: ITaskEvaluation): number[] {
    const answer = attempt?.answer as IUserChoiceAnswer;

    if (!answer?.selections) {
      throw this.createError(
        'Invalid User Choice answer.',
        UserChoicePositionDeterminerError.AnswerInvalid
      );
    }

    // selections in CMS are entered starting with index 1 not zero, so adjust
    // also sort in case selections were made out of order
    return answer.selections.map(s => s + 1).sort();
  }

  protected fork(subunit: IUnit): IActivityPosition {
    const { roundNode: parentRoundNode } = this.context;

    return this.activityPositionBuilder.addSubunitPositionFrom(
      subunit,
      parentRoundNode
    ).activityPosition;
  }

  private createError(
    message: string,
    code: UserChoicePositionDeterminerError
  ): LexiaError {
    const { lastAttemptMaybe, round } = this.context;

    return new LexiaError(
      message,
      UserChoicePositionDeterminer.displayName,
      code
    ).withContext({ attempt: lastAttemptMaybe, round });
  }
}

export enum UserChoicePositionDeterminerError {
  AnswerInvalid = 'AnswerInvalid',
  PathNotFound = 'PathNotFound'
}
