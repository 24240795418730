import { IConfigOverride } from '@lexialearning/utils';
import { QueryStringParam, RouterService } from 'router-service';
import { SystemInfo } from 'utils';

export class QueryStringConfigOverrides {
  public static create(): IConfigOverride[] {
    if (SystemInfo.isNative) {
      return [];
    }

    const searchParams = RouterService.getQueryParamMap();
    const params: { k: string; value: string }[] = [];
    searchParams.forEach((v, k) => {
      params.push({ k, value: v });
    });

    if (searchParams.has(QueryStringParam.AuthServer)) {
      params.push({
        k: QueryStringParam.ConfigCustomerUrl,
        value: searchParams.get(QueryStringParam.AuthServer)!
      });
    }

    // Previously we would override the entire endpoint url, including the path,
    // even though only the domain portion changed. Now the domain is used on its own
    // as well as for the api endpoint, so we need the override to be set without the path.
    // In order to make this compatible with how people are used to using it
    // we accept the url with or without the path, and just ignore that portion of it
    // eslint-disable-next-line deprecation/deprecation
    [QueryStringParam.StudentApiEndpoint, QueryStringParam.StudentApi].forEach(
      queryStringParam => {
        if (searchParams.has(queryStringParam)) {
          params.push({
            k: QueryStringParam.ConfigStudentApiUrl,
            value: new URL(searchParams.get(queryStringParam)!).origin
          });
        }
      }
    );

    return params
      .filter(({ k }) => k.startsWith(QueryStringParam.OverridePrefix))
      .map(({ k, value }) => ({
        key: k.replace(QueryStringParam.OverridePrefix, ''),
        value
      }));
  }
}
