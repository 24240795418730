import {
  FeedbackStrategy,
  IRound,
  IUnit,
  IUnitPosition
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';

export class PoKUnitRecycler {
  public static readonly displayName = 'PoKUnitRecycler';

  public static recycle(unit: IUnit, unitPosition: IUnitPosition): IUnit {
    const recycler = new PoKUnitRecycler(unit, unitPosition);

    return recycler.recycle();
  }

  private constructor(
    private readonly unit: IUnit,
    private readonly unitPosition: IUnitPosition
  ) {}

  private recycle(): IUnit {
    const roundIdsToRecycle = this.getRoundIdList();
    const rounds = this.unit.rounds.filter(r =>
      roundIdsToRecycle.includes(r.sysId)
    );

    this.crosscheck(roundIdsToRecycle, rounds);

    return {
      ...this.unit,
      feedbackStrategy: FeedbackStrategy.Minimal,
      rounds
    };
  }

  private getRoundIdList(): string[] {
    if (this.unitPosition.recycling?.pass !== 1) {
      throw new LexiaError(
        'Invalid attempt to recycle a non-recycling unit position',
        PoKUnitRecycler.displayName,
        PokUnitRecyclerError.RecyclingInvalid
      ).withContext({ unitPosition: this.unitPosition });
    }

    const roundIdList = this.unitPosition.recycling.roundIdsToRecycle;

    if (!roundIdList.length) {
      throw new LexiaError(
        'Invalid attempt to recycle without any round IDs',
        PoKUnitRecycler.displayName,
        PokUnitRecyclerError.RoundIdListEmpty
      ).withContext({ unitPosition: this.unitPosition });
    }

    return roundIdList;
  }

  private crosscheck(roundIdsToRecycle: string[], rounds: IRound[]): void {
    const actual = rounds.map(r => r.sysId).join(',');
    const expected = roundIdsToRecycle.join(',');
    if (actual !== expected) {
      throw new LexiaError(
        'Some round IDs to recycle were not found in the original unit',
        PoKUnitRecycler.displayName,
        PokUnitRecyclerError.RoundIdListInvalid
      ).withContext({ actual, expected });
    }
  }
}

export enum PokUnitRecyclerError {
  RecyclingInvalid = 'RecyclingInvalid',
  RoundIdListEmpty = 'RoundIdListEmpty',
  RoundIdListInvalid = 'RoundIdListInvalid'
}
