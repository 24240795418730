import * as React from 'react';
import { View } from 'common-ui';
import { ILevelStatus, LevelStatus } from 'curriculum-services';
import { LevelCertificateButton } from '../level-certificate-button';
import { StudentInfoSection } from '../student-info-section/StudentInfoSection';
import { LevelCertificateButtonsStyles } from './LevelCertificateButtons.styles';

export interface ILevelCertificatesProps {
  autoFocus?: boolean;
  levelStatuses: ILevelStatus[];
}

export class LevelCertificateButtons extends React.PureComponent<ILevelCertificatesProps> {
  public static readonly displayName = 'LevelCertificates';
  private readonly firstCompletedIdx;

  constructor(props: ILevelCertificatesProps) {
    super(props);

    this.firstCompletedIdx = this.props.levelStatuses.findIndex(
      s => s.status === LevelStatus.Completed
    );
  }

  public render() {
    const { autoFocus, levelStatuses } = this.props;
    const styles = LevelCertificateButtonsStyles.get();

    return (
      <StudentInfoSection title="Level Certificates">
        <View
          testId={LevelCertificateButtons.displayName}
          style={styles.buttonContainer}
        >
          {levelStatuses.map((levelStatus, i) => (
            <LevelCertificateButton
              autoFocus={autoFocus && i === this.firstCompletedIdx}
              key={`certificate-button-${i + 1}`}
              levelStatus={levelStatus}
            />
          ))}
        </View>
      </StudentInfoSection>
    );
  }
}
