import { Position } from '@lexialearning/common-ui';
import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Direction, Justify } from 'common-styles';
import { ITextPillStyleOverride } from '../text-pill/TextPill.styles';

export interface ICardStyleOverride {
  image?: {
    width?: number;
    height?: number;
    marginLeft?: number;
    marginRight?: number;
  };
  label?: ITextPillStyleOverride;
}

export class CardStyles {
  public static readonly build = memoizeOne(
    (styleOverride?: ICardStyleOverride) =>
      merge(
        {},
        {
          image: {
            alignItems: Align.Center,
            borderRadius: 13,
            flexDirection: Direction.Column,
            height: 180,
            justifyContent: Justify.End,
            width: 180
          },
          label: {
            container: {
              borderRadius: 15,
              bottom: 14,
              height: 36,
              position: Position.Absolute,
              width: 168
            }
          }
        },
        styleOverride
      )
  );
}
