export class SlideNumberStyles {
  private static readonly styles = {
    digitImage: {
      height: 21,
      marginTop: 6
    }
  };

  public static get() {
    return this.styles;
  }
}
