import * as React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Column, Row, Text, TextButton } from 'common-ui';
import { AppShellAction, AppShellSelector } from 'feature-areas/shell';
import { AppState } from 'services';
import { ModalId } from 'shared-components/modals/modal.model';
import { SpinnerAction } from 'spinner-handler';
import { ThemeSelector } from 'theme';
import { DevToolsStyles } from '../DevTools.styles';
import { DevToolsAction } from '../redux/DevTools.action';
import { DevToolsSelector } from '../redux/DevTools.selector';
import { CheatSheetModal } from './cheat-sheet-modal/CheatSheetModal';

interface IShortcutsDevToolsProps {
  shouldShowIosShortcuts: boolean;
  shouldShowMicErrorShortcuts: boolean;
  isSpinnerShown: boolean;
  setShouldShowIosShortcuts(shouldShow: boolean): void;
  setShouldShowMicErrorShortcuts(shouldShow: boolean): void;
  showSpinner(): void;
  hideSpinner(): void;
  showModal(modalId: ModalId): void;
}

class ShortcutsDevToolsComponent extends React.PureComponent<IShortcutsDevToolsProps> {
  public static readonly displayName = 'ShortcutsDevTools';

  constructor(props: IShortcutsDevToolsProps) {
    super(props);

    this.handleToggleShowMicErrorShortcuts =
      this.handleToggleShowMicErrorShortcuts.bind(this);
    this.handleToggleShowIosShortcuts =
      this.handleToggleShowIosShortcuts.bind(this);
    this.toggleSpinner = this.toggleSpinner.bind(this);
    this.handleCheatSheetPress = this.handleCheatSheetPress.bind(this);
  }

  private handleToggleShowMicErrorShortcuts() {
    this.props.setShouldShowMicErrorShortcuts(
      !this.props.shouldShowMicErrorShortcuts
    );
  }

  private handleToggleShowIosShortcuts() {
    this.props.setShouldShowIosShortcuts(!this.props.shouldShowIosShortcuts);
  }

  private toggleSpinner() {
    if (this.props.isSpinnerShown) {
      this.props.hideSpinner();
    } else {
      this.props.showSpinner();
    }
  }

  private handleCheatSheetPress() {
    this.props.showModal(CheatSheetModal.ModalId);
  }

  public render() {
    const styles = DevToolsStyles.build();

    return (
      <Column style={{ ...styles.contentBlock, ...styles.shortcutsBox }}>
        <Text style={styles.contentBlockLabel}>Shortcuts:</Text>
        {/* <Column style={styles.infoBoxes}> */}
        {/* MIC ERRORS SHORTCUT CHECKBOX */}
        <Row style={styles.checkboxRow}>
          <Checkbox
            isChecked={this.props.shouldShowMicErrorShortcuts}
            label="Show mic errors shortcut buttons (See Speak only)"
            labelId="show-mic-errors-shortcuts"
            onToggle={this.handleToggleShowMicErrorShortcuts}
            styleOverride={styles.checkboxOverrides}
          />
        </Row>

        {/* IPAD SHORTCUT CHECKBOX */}
        {/* {SystemInfo.isNative && ( */}
        <Row style={styles.checkboxRow}>
          <Checkbox
            isChecked={this.props.shouldShowIosShortcuts}
            label="Show task shortcut buttons (iOS only)"
            labelId="show-task-shortcuts"
            onToggle={this.handleToggleShowIosShortcuts}
            styleOverride={styles.checkboxOverrides}
          />
        </Row>
        {/* )} */}

        <Row>
          {/* CHEAT SHEET */}
          <TextButton
            text="Cheat Sheet"
            onPress={this.handleCheatSheetPress}
            styleOverride={styles.shortcutsButtonOverrides}
            ignoreGlobalDisabled
          />

          {/* TOGGLE SPINNER BUTTON */}
          <TextButton
            onPress={this.toggleSpinner}
            text="Toggle Spinner"
            styleOverride={styles.shortcutsButtonOverrides}
            ignoreGlobalDisabled
          />
        </Row>
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    isSpinnerShown: AppShellSelector.getShowSpinner(state),
    shouldShowIosShortcuts: DevToolsSelector.shouldShowIosShortcuts(state),
    shouldShowMicErrorShortcuts:
      DevToolsSelector.shouldShowMicErrorShortcuts(state),
    themeSize: ThemeSelector.getThemeSize(state)
  };
}

const mapDispatchToProps = {
  hide: () => DevToolsAction.hide(),
  hideSpinner: () => SpinnerAction.hide(),
  setShouldShowIosShortcuts: (shouldShow: boolean) =>
    DevToolsAction.setShouldShowIosShortcuts(shouldShow),
  setShouldShowMicErrorShortcuts: (shouldShow: boolean) =>
    DevToolsAction.setShouldShowMicErrorShortcuts(shouldShow),
  showModal: (id: ModalId) => AppShellAction.showModal({ id }),
  showSpinner: () => SpinnerAction.show()
};

export const ShortcutsDevTools = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortcutsDevToolsComponent);
