import merge from 'lodash/merge';
import {
  AnimatableCSSProperty,
  Color,
  InteractionState,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import { Position } from '@lexialearning/common-ui';

export interface IBarStyleOverride {
  bar?: Types.ViewStyle;
  caret?: Types.ViewStyle;
}

interface IBarAnimations {
  brighten: Types.Animated.CompositeAnimation;
}

interface IBarStyles {
  bar: Types.ViewStyle;
  barBrighten: Types.ViewStyle;
  barBrightenAnimated: Types.AnimatedViewStyle;
  caret: Types.ViewStyle;
}

const ANIMATION_DURATION = 1000 * (2 / 3); // 20 frames at 30fps

export class BarAnimatedStyles {
  private readonly animations: IBarAnimations;

  private readonly sharedBarStyles = {
    borderRadius: 20,
    height: 68,
    marginHorizontal: 5,
    width: 21
  };

  private readonly styles: IBarStyles = {
    bar: {},
    barBrighten: {
      ...this.sharedBarStyles,
      backgroundColor: Color.DodgerBlueLight
    },
    barBrightenAnimated: {},
    caret: {
      bottom: -15,
      position: Position.Absolute,
      right: -8
    }
  };

  constructor() {
    const animatedValues = {
      highlightOpacity: loboAnimated.createValue(0)
    };

    const brighten = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        animatedValues.highlightOpacity,
        {
          duration: ANIMATION_DURATION / 20,
          easing: loboAnimated.Easing.In(),
          toValue: 1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        animatedValues.highlightOpacity,
        {
          duration: ANIMATION_DURATION / 4,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      )
    ]);

    this.animations = {
      brighten
    };

    this.styles.barBrightenAnimated.opacity = animatedValues.highlightOpacity;
  }

  public static computeBarColor(
    interactionState?: InteractionState,
    activeBarColor?: string
  ): string {
    switch (interactionState) {
      case InteractionState.Correct:
        return Color.Green2;
      case InteractionState.Incorrect:
        return Color.CrimsonRed;
      default:
        return activeBarColor || Color.DodgerBlue;
    }
  }

  public getAnimations(): IBarAnimations {
    return this.animations;
  }

  public build(
    isActive: boolean,
    interactionState?: InteractionState,
    activeBarColor?: string,
    styleOverride?: IBarStyleOverride
  ) {
    return merge(
      {
        ...this.styles,
        bar: {
          ...this.sharedBarStyles,
          backgroundColor: isActive
            ? BarAnimatedStyles.computeBarColor(
                interactionState,
                activeBarColor
              )
            : Color.Gray10
        }
      },
      styleOverride
    );
  }
}
