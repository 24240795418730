import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function RehearIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={RehearIconSvg.displayName}>
      {/* Rehear shadow */}
      {shadowColor && (
        <G transform="translate(0.25 0.25)" opacity="0.28">
          <Path
            d="M51.22,40.23a1.65,1.65,0,0,1-.86-.25h0c-1.93-1.31-5.05-3.39-5.59-3.69H40A3.19,3.19,0,0,1,36.92,33V29A3.2,3.2,0,0,1,40,25.77h4.79c.54-.3,3.66-2.39,5.59-3.69h0a1.63,1.63,0,0,1,1.7,0h0a1.81,1.81,0,0,1,.79,1.56V38.43A1.82,1.82,0,0,1,52.08,40h0A1.59,1.59,0,0,1,51.22,40.23Z"
            fill={shadowColor}
          />
          <Path
            d="M63.82,35.43A17.66,17.66,0,1,1,62.67,24.1"
            fill="none"
            stroke={shadowColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <Path
            d="M58.84,21.37l-3.4,2.12a.9.9,0,0,0-.28,1.24.85.85,0,0,0,.56.39L63.63,27a.89.89,0,0,0,1.07-.67h0l1.83-7.91a.89.89,0,0,0-.67-1.07.94.94,0,0,0-.68.11l-3.31,2.07"
            fill={shadowColor}
          />
        </G>
      )}
      {/* Rehear */}
      <G>
        <Path
          d="M51.22,40.23a1.65,1.65,0,0,1-.86-.25h0c-1.93-1.31-5.05-3.39-5.59-3.69H40A3.19,3.19,0,0,1,36.92,33V29A3.2,3.2,0,0,1,40,25.77h4.79c.54-.3,3.66-2.39,5.59-3.69h0a1.63,1.63,0,0,1,1.7,0h0a1.81,1.81,0,0,1,.79,1.56V38.43A1.82,1.82,0,0,1,52.08,40h0A1.59,1.59,0,0,1,51.22,40.23Z"
          fill={iconColor}
        />
        <Path
          d="M63.82,35.43A17.66,17.66,0,1,1,62.67,24.1"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <Path
          d="M58.84,21.37l-3.4,2.12a.9.9,0,0,0-.28,1.24.85.85,0,0,0,.56.39L63.63,27a.89.89,0,0,0,1.07-.67h0l1.83-7.91a.89.89,0,0,0-.67-1.07.94.94,0,0,0-.68.11l-3.31,2.07"
          fill={iconColor}
        />
      </G>
    </G>
  );
}
RehearIconSvg.displayName = 'RehearIconSvg';
