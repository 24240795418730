import { IVideoPlayerModifiedProps, IVideoPlayerProps } from '../video';
import { cloneDeep } from 'lodash';

type IVideoPlayerPropsWithoutToggles = Omit<
  IVideoPlayerProps,
  'toggleAudioDescriptions' | 'toggleCaptions'
>;

type IVideoPlayerModifiedPropsWithoutToggles = Omit<
  IVideoPlayerModifiedProps,
  'toggleAudioDescriptions' | 'toggleCaptions'
>;
export class VideoPlayerHelper {
  public static onCanPlay = (
    setLoadingState: ((state: boolean) => void) | undefined
  ): void => {
    setLoadingState?.(true);
  };

  public static emptyDeferConfig = () => ({
    sources: {
      captionsSource: '',
      src: '',
      type: ''
    }
  });

  public static addDeferredSource = (
    srcProps: IVideoPlayerPropsWithoutToggles
  ): IVideoPlayerModifiedPropsWithoutToggles => {
    const modifiedProps: IVideoPlayerModifiedPropsWithoutToggles = {
      deferConfig: this.emptyDeferConfig(),
      videoConfig: {
        html5: {
          nativeTextTracks: false
        }
      },
      ...cloneDeep(srcProps)
    };

    // destroy sources so that video.js doesn't try to load them before error handler is attached
    delete modifiedProps.sources;

    /* istanbul ignore next */
    modifiedProps.onCanPlayThrough = () =>
      this.onCanPlay(srcProps.setLoadingState);

    // move sources to an object that will be loaded after error listener is attached to the player instance
    modifiedProps.deferConfig = {
      sources: {
        src: modifiedProps.source,
        type: 'application/x-mpegURL'
      }
    };

    return modifiedProps;
  };

  public static getVideoConfigAndControls = (
    srcProps: IVideoPlayerPropsWithoutToggles
  ): IVideoPlayerModifiedPropsWithoutToggles => {
    let modifiedProps: IVideoPlayerModifiedPropsWithoutToggles = {
      deferConfig: this.emptyDeferConfig(),
      videoConfig: {
        poster: ''
      },
      ...cloneDeep(srcProps)
    };

    const videoConfigDefaults = () => ({
      bigPlayButton: srcProps.bigPlayButton,
      captionsButton: !srcProps.captionsBlocked,
      captionsSettingsMenuItem: !srcProps.captionsBlocked,
      html5: {
        nativeTextTracks: false
      },
      inactivityTimeout: srcProps.controlsFadeoutDelay || 5000,
      resizeManager: true,
      textTrackSettings: false
    });

    // Media Task sends an empty array as "controls" prop.
    if (Array.isArray(modifiedProps.controls)) {
      modifiedProps.controls = !(modifiedProps.controls.length === 0);
    }

    modifiedProps = this.addDeferredSource(modifiedProps);

    modifiedProps.loadingSpinner = true;

    // videoConfig object gets passed into video.js, so we map the incoming props to the appropriate functionality here
    modifiedProps.videoConfig = {
      ...videoConfigDefaults(),
      autoPlay: modifiedProps.autoPlay,
      poster: modifiedProps.poster || ''
    };

    modifiedProps.videoConfig = {
      ...videoConfigDefaults(),
      captionsSettingsMenuItem: false,
      controlBar: {
        audioTrackButton: false,
        chaptersButton: false,
        fullscreenToggle: false,
        pictureInPictureToggle: false,
        playToggle: true,
        remainingTimeDisplay: false,
        seekToLive: false,
        subsCapsButton: false,
        subtitlesButton: false,
        volumePanel: false
      },
      controls: true
    };

    return modifiedProps;
  };
}
