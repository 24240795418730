import { SceneControllerBase, SceneZIndex } from 'services/storm-lobo';
import { IApplyTextureOptions, Scene, StormService } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  ProgramCompleteSceneAnimationName,
  ProgramCompleteSceneElementName,
  ProgramCompleteSceneMaterialName
} from './program-complete-scene.model';

export class ProgramCompleteScene extends SceneControllerBase {
  public static readonly displayName = 'ProgramCompleteScene';

  private readonly AnimationName = ProgramCompleteSceneAnimationName;

  private readonly ElementName = ProgramCompleteSceneElementName;

  public constructor(
    scene: Scene,
    private readonly stormService: StormService,
    private readonly levelNum: string
  ) {
    super(scene, SceneZIndex.ProgramComplete);
    this.init();
    this.animateBackground();
    this.animateBackgroundOverlay();
    this.prepareTexture();
  }

  private init(): void {
    const request: ISceneAnimationRequest = {
      name: ProgramCompleteSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  private animateBackground(): void {
    const request: ISceneAnimationRequest = {
      name: this.AnimationName.Background.Idle,
      targetElement: this.ElementName.Background
    };
    this.scene.startAnimation(request);
  }

  private animateBackgroundOverlay(): void {
    const request: ISceneAnimationRequest = {
      loop: true,
      name: this.AnimationName.BackgroundOverlay.Idle,
      targetElement: this.ElementName.BackgroundOverlay
    };
    this.scene.startAnimation(request);
  }

  private prepareTexture(): void {
    const options: Partial<IApplyTextureOptions> = {
      materialName: ProgramCompleteSceneMaterialName.Certificate
    };
    const path = `/scenes/images/level-complete/l${this.levelNum}_complete.tx`;
    const texture = this.stormService.loadTexture(path);
    this.scene.applyTexture(texture, options);
  }
}
