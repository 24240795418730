import {
  IRound,
  ITaskEvaluation,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { last } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ProgramContextSelector, RoundSelector } from 'curriculum-services';
import { Column, Row, Text } from 'common-ui';
import { AppState } from 'services';
import { TaskAction, TaskActions, TaskSelector } from 'task-components/core';
import { ISreSessionAnswer } from 'task-components/see-speak';
import { DevToolsStyles } from '../DevTools.styles';
import { DevToolsDropdown } from '../DevToolsDropdown';
import { DevToolsAction } from '../redux/DevTools.action';
import { DevToolsSelector } from '../redux/DevTools.selector';
import { DevToolsPosition } from '../redux/IDevToolsState';

interface IRoundDevToolsProps {
  attempts: ITaskEvaluation[];
  evaluationResult: TaskEvaluationResult | undefined;
  phase: TaskPhase;
  position: DevToolsPosition;
  round: IRound | undefined;
  onSelectPhase(phase: TaskPhase, roundId: string): void;
  openContentfulEntry(entryId: string): void;
}

class RoundDevToolsComponent extends React.PureComponent<IRoundDevToolsProps> {
  public static readonly displayName = 'RoundDevTools';

  constructor(props: IRoundDevToolsProps) {
    super(props);

    this.handlePhaseChange = this.handlePhaseChange.bind(this);
    this.handleRoundLink = this.handleRoundLink.bind(this);
    this.handleBranchLink = this.handleBranchLink.bind(this);
  }

  private handlePhaseChange(phase: TaskPhase): void {
    this.props.onSelectPhase(phase, this.props.round!.sysId);
  }

  private handleRoundLink(): void {
    this.props.openContentfulEntry(this.props.round!.sysId);
  }

  private handleBranchLink(): void {
    this.props.openContentfulEntry(this.props.round!.branch!.sysId);
  }

  public render() {
    const { attempts, evaluationResult, phase, position, round } = this.props;
    const styles = DevToolsStyles.build(position);

    if (!round) {
      return (
        <Column style={{ ...styles.contentBlock, ...styles.roundInfoBox }}>
          <Text style={styles.contentBlockLabel}>Round: N/A</Text>
        </Column>
      );
    }

    const { branch } = round;
    // Get calibration result, if exists (see speak tasks)
    const calibrationResult = (last(attempts)?.answer as ISreSessionAnswer)
      ?.calibrationResult;

    return (
      <Column style={{ ...styles.contentBlock, ...styles.roundInfoBox }}>
        <Text style={styles.contentBlockLabel}>Round:</Text>
        <Column style={styles.contentItemsList}>
          <Row>
            <Text style={styles.contentItemLink} onPress={this.handleRoundLink}>
              {round.title}
            </Text>
          </Row>
          <Row>
            <DevToolsDropdown
              accessibilityLabel="Task Phase"
              id="taskPhases"
              label="Phase"
              items={TaskPhase}
              selectedItemId={phase}
              onChange={this.handlePhaseChange}
            />
          </Row>
          <Row style={styles.contentItem}>
            <Row style={styles.contentItemBullet} />
            <Text style={styles.contentItemLabel}>Branch: </Text>
            {branch ? (
              <Text
                style={styles.contentItemLink}
                onPress={this.handleBranchLink}
              >
                {branch.title}
              </Text>
            ) : (
              <Text style={styles.contentItemValue}>(none)</Text>
            )}
          </Row>
          <Row style={styles.contentItem}>
            <Row style={styles.contentItemBullet} />
            <Text style={styles.contentItemLabel}>No. of attempts: </Text>
            <Text style={styles.contentItemValue}>{attempts.length}</Text>
          </Row>
          <Row style={styles.contentItem}>
            <Row style={styles.contentItemBullet} />
            <Text style={styles.contentItemLabel}>Evaluation result: </Text>
            <Text style={styles.contentItemValue}>{evaluationResult}</Text>
          </Row>
          {!!calibrationResult && (
            <Row style={styles.contentItem}>
              <Row style={styles.contentItemBullet} />
              <Text style={styles.contentItemLabel}>Calibration result: </Text>
              <Text style={styles.contentItemValue}>{calibrationResult}</Text>
            </Row>
          )}
        </Column>
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  const phase = TaskSelector.getPhase(state);
  const context = ProgramContextSelector.getRoundContextMaybe(state);

  return {
    attempts: context?.attempts ?? [],
    evaluationResult: context?.lastAttemptMaybe?.result,
    phase,
    position: DevToolsSelector.getPosition(state)!,
    round: RoundSelector.getRoundMaybe(state)
  };
}

const mapDispatchToProps = {
  onSelectPhase: handleSelectPhase,
  openContentfulEntry: (entryId: string) =>
    DevToolsAction.openContentfulEntry({ entryId })
};

function handleSelectPhase(phase: TaskPhase): TaskActions {
  switch (phase) {
    case TaskPhase.Entry:
      return TaskAction.entry();
    case TaskPhase.Exit:
      return TaskAction.exit();
    case TaskPhase.Feedback:
      return TaskAction.evaluated({
        answer: {},
        result: TaskEvaluationResult.Inconclusive
      });
    case TaskPhase.Interactive:
      return TaskAction.interactive();
    case TaskPhase.Preamble:
      return TaskAction.preamble();
    case TaskPhase.Solution:
      return TaskAction.solution();
    default:
      // eslint-disable-next-line no-console
      console.log('Unknown round phase');

      return TaskAction.interactive();
  }
}

export const RoundDevTools = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoundDevToolsComponent);
