import { ActionType, createAsyncAction } from 'typesafe-actions';
import { StormActionType } from './storm-redux.model';

export const StormAction = {
  initialize: createAsyncAction(
    StormActionType.Initialize,
    StormActionType.InitializeSuccess,
    StormActionType.InitializeFailure
  )<void, void, Error>()
};

export type StormActionInitialize = ActionType<
  typeof StormAction.initialize.request
>;
export type StormActionInitializeSuccess = ActionType<
  typeof StormAction.initialize.success
>;
export type StormActions = ActionType<typeof StormAction>;
