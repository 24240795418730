import { ILevel } from '@lexialearning/lobo-common';
import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ICurriculumDependencies } from 'curriculum-services';
import {
  LevelAction,
  LevelActionLoad,
  LevelActionLoadSuccess,
  LevelActionType,
  LevelSelector
} from '../redux';

export function loadLevelEpic(
  action$: Observable<LevelActionLoad>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<LevelActionLoadSuccess> {
  return action$.pipe(
    ofType(LevelActionType.Load),
    switchMap(async action => {
      const { curriculumDependencies } = deps;
      const levelId = action.payload.sysId;
      if (!levelId) {
        // This epic should never be hit without a level id, however, it was
        // possible before the fixes in LOBO-16958. While that is now fixed,
        // it seems worth leaving an explicit error in place here to better
        // handle any future issues. (It otherwise fails with a confusing white
        // screen and no useful info, given that loading levels without a level id,
        // instead of loading a single level, loads an array of all student levels)
        throw new LexiaError(
          'Level load requested with no levelId',
          loadLevelEpic.displayName,
          LoadLevelEpicError.MissingLevelId
        );
      }

      if (LevelSelector.getLevelIdMaybe(state$.value) === levelId) {
        throw new LexiaError(
          `Requested level is already loaded: ${levelId}`,
          loadLevelEpic.displayName,
          LoadLevelEpicError.LevelAlreadyLoaded
        );
      }

      const provider = curriculumDependencies.contentProviderFactory.create();
      const level = await provider.loadById<ILevel>(
        LoboContentType.Level,
        levelId,
        { nestingDepth: 4 }
      );

      return LevelAction.load.success({ levelContent: level });
    })
  );
}
loadLevelEpic.displayName = 'loadLevelEpic';

export enum LoadLevelEpicError {
  LevelAlreadyLoaded = 'LevelAlreadyLoaded',
  MissingLevelId = 'MissingLevelId'
}
