import { MutableRefObject, SyntheticEvent } from 'react';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

export enum TimeoutDurations {
  ErrorMs = 20000
}

interface IDeferConfigSources {
  captionSource?: string;
  src: string;
  type: string;
}

interface IDeferConfig {
  sources: IDeferConfigSources;
}

interface IVideoConfig {
  autoPlay?: boolean;
  captionsButton?: boolean;
  captionsSettingsMenuItem?: boolean;
  controlBar?: VideoJsPlayerOptions['controlBar'];
  controls?: VideoJsPlayerOptions['controls'];
  html5?: VideoJsPlayerOptions['html5'];
  poster?: VideoJsPlayerOptions['poster'];
  resizeManager?: boolean;
}

export interface IVideoPlayerProps {
  addMobilePauseListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  addMobileResignListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  addMobileResumeListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  isAudioDescriptionEnabled?: boolean;
  autoPlay?: boolean;
  bigPlayButton?: VideoJsPlayerOptions['bigPlayButton'];
  captions?: boolean;
  captionsBlocked?: boolean;
  controlsFadeoutDelay?: number;
  isCaptionEnabled?: boolean;
  checkBrowser?: (window: Window | never) => string;
  checkForWifi?: () => void;
  controlBar?: VideoJsPlayerOptions['controlBar'];
  controls?: boolean | Record<PropertyKey, string | boolean> | string[];
  id?: string;
  onCanPlayThrough?: () => void;
  onClick?: () => void;
  onEnded?: () => void;
  onError?: () => void;
  onSeeking?: () => void;
  onSkipped?: () => void;
  onTimeUpdate?: () => void;
  poster?: string;
  removeMobilePauseListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  removeMobileResignListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  removeMobileResumeListener?: (
    callback: (e: SyntheticEvent) => unknown
  ) => () => void;
  seekable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHlsCanPlay?: (player: VideoJsPlayer | any) => void;
  setLoadingState?: (state: boolean) => void;
  showScrubber?: boolean;
  sources?: Record<PropertyKey, unknown>;
  testId?: string;
  triggerVideoControls?: (
    type: string | Record<string | number | symbol, string | number> | undefined
  ) => void;
  videoRef?: MutableRefObject<unknown>;
  videoRole: VideoRole;
  source: string;
  videoTagClass?: string;
}

export interface IVideoPlayerModifiedProps extends IVideoPlayerProps {
  captionSource?: string;
  controlsFadeoutDelay?: number;
  deferConfig: IDeferConfig;
  loadingSpinner?: boolean;
  pathname?: string;
  pauseFlag?: boolean;
  videoConfig: IVideoConfig;
  resetPauseFlag?: () => void;
}

export interface IPlayerArgsTypes extends IVideoPlayerModifiedProps {
  event?: MouseEvent | SyntheticEvent;
  player: MutableRefObject<VideoJsPlayer | null> | Record<PropertyKey, any>;
  playerConfig?: Record<PropertyKey, string>;
  videoStyles?: Record<PropertyKey, string | number> | undefined;
}

export enum VideoRole {
  ShellCover = 'shell_video_cover_role',
  ShellContain = 'shell_video_contain_role',
  MediaTask = 'media_task_video_role',
  ExcerptTask = 'excerpt_video_role',
  Popup = 'replay_popup_video_role',
  None = ''
}

export enum VideoUIAsset {
  playButtonTestId = 'Play Button',
  playButtonPath = 'shell/theme/cards/ui/buttons/b_play_shadow.svg'
}
