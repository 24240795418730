import { Dropdown, ISizing, Row, Text } from 'common-ui';
import { IDropdownProps } from 'common-ui/components/dropdown/Dropdown';
import { DevToolsDropdownStyles } from './DevToolsDropdown.styles';

export interface IDevToolsDropdownProps
  extends Omit<IDropdownProps, keyof ISizing> {
  label: string;
}

export function DevToolsDropdown(props: IDevToolsDropdownProps) {
  const { label, ...restProps } = props;
  const styles = DevToolsDropdownStyles.get();
  const { label: labelStyles, ...dropdownStyleOverride } = styles;

  return (
    <Row>
      <Text style={labelStyles}>{label}:</Text>
      <Dropdown {...restProps} styleOverride={dropdownStyleOverride} />
    </Row>
  );
}
DevToolsDropdown.displayName = 'DevToolsDropdown';
