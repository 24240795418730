// istanbul ignore file - development helper
import { useEffect, useRef } from 'react';

/**
 * Console logs the component props which have changed
 * Useful during development for understanding what is
 * causing re-renders, not intended for Production
 * Src: https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
 */
export function useTraceUpdate(props, displayName?: string) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }

      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line no-console
      console.log(displayName, 'Changed props:', changedProps);
    }
    prev.current = props;
  });
}
