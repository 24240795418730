import { IAnimationDefinition } from '@lexialearning/lobo-common';

export class AnimationDefinitionFactory {
  public static readonly displayName = 'AnimationDefinitionFactory';

  public static create(
    name: string,
    partial?: Omit<Partial<IAnimationDefinition>, 'name'>
  ): IAnimationDefinition {
    return {
      autoplay: false,
      blendTimeSeconds: 0,
      category: '',
      lazyLoad: false,
      loop: false,
      name,
      path: '',
      sceneNames: [],
      speed: 1,
      stopWhenFinished: false,
      tags: [],
      targetElements: [],
      ...partial
    };
  }
}
