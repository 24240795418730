import {
  ILanguageFrameToken,
  IWordWeights,
  LanguageFrameTokenDecoration
} from '@lexialearning/lobo-common/tasks/see-speak';
import { WordType } from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep } from 'lodash';

export class LanguageFrameManager {
  public static readonly displayName = 'LanguageFrameManager';

  public static manage(tokens: ILanguageFrameToken[]): LanguageFrameManager {
    return new LanguageFrameManager(tokens);
  }

  public readonly tokens: IManagedToken[];

  constructor(tokens: ILanguageFrameToken[]) {
    this.tokens = tokens.map((t: ILanguageFrameToken) => ({
      ...t,
      decorations: new Set<LanguageFrameTokenDecoration>(t.decorations)
    }));
  }

  public decorate(
    index: number,
    decorations: LanguageFrameTokenDecoration[]
  ): LanguageFrameManager {
    const token = this.getTokenFor(index, 'decorate');

    const clone = cloneDeep(token);
    decorations.forEach(decoration => {
      if (!clone.decorations.has(decoration)) {
        clone.decorations.add(decoration);
      }
    });
    this.tokens[index] = clone;

    return this;
  }

  public filterByDecoration(
    decoration: LanguageFrameTokenDecoration
  ): IManagedToken[] {
    return this.tokens.filter(t => t.decorations.has(decoration));
  }

  public getScoringWeight(
    token: IManagedToken,
    weights = this.getWeights()
  ): number {
    switch (this.getWordType(token)) {
      case WordType.Grammar:
        return weights.grammar;
      case WordType.Syntax:
        return weights.syntax;
      default:
        return weights.standard;
    }
  }

  private getWeights(): IWordWeights {
    return {
      grammar: ScoringWeight.Grammar,
      standard: ScoringWeight.Standard,
      syntax: ScoringWeight.Syntax
    };
  }

  public getWordType(token: IManagedToken): WordType {
    return token.decorations.has(LanguageFrameTokenDecoration.GrammarWord)
      ? WordType.Grammar
      : token.decorations.has(LanguageFrameTokenDecoration.SyntaxWord)
      ? WordType.Syntax
      : WordType.Standard;
  }

  public setScaffolded(): LanguageFrameManager {
    this.tokens.forEach((t: IManagedToken, idx: number) => {
      if (t.decorations.has(LanguageFrameTokenDecoration.ChallengeWord)) {
        this.decorate(idx, [LanguageFrameTokenDecoration.Scaffolded]);
      }
    });

    return this;
  }

  public unsetScaffolded(index: number): LanguageFrameManager {
    this.undecorate(index, LanguageFrameTokenDecoration.Scaffolded);

    return this;
  }

  public toUnManaged(): ILanguageFrameToken[] {
    return this.tokens.map(t => ({
      ...t,
      decorations: Array.from(t.decorations.values())
    }));
  }

  public undecorate(
    index: number,
    decoration: LanguageFrameTokenDecoration
  ): LanguageFrameManager {
    const token = this.getTokenFor(index, 'undecorate');
    if (!token.decorations.has(decoration)) {
      return this;
    }

    const clone = cloneDeep(token);
    clone.decorations.delete(decoration);
    this.tokens[index] = clone;

    return this;
  }

  public undecorateAll(
    decoration: LanguageFrameTokenDecoration
  ): LanguageFrameManager {
    this.tokens.forEach((token, index) => {
      if (token.decorations.has(decoration)) {
        this.undecorate(index, decoration);
      }
    });

    return this;
  }

  private getTokenFor(
    index: number,
    op: 'decorate' | 'undecorate'
  ): IManagedToken {
    const token = this.tokens[index];

    if (!token) {
      throw new LexiaError(
        `Error attempting to ${op} language frame token: Invalid token index`,
        LanguageFrameManager.displayName,
        LanguageFrameManagerErrorCode.InvalidTokenIndex
      ).withContext({ index, tokens: this.tokens });
    }

    return token;
  }
}

export enum LanguageFrameManagerErrorCode {
  InvalidTokenIndex = 'InvalidTokenIndex'
}

export interface IManagedToken {
  cleanText: string;
  decorations: Set<LanguageFrameTokenDecoration>;
  postPunctuation: string;
  prePunctuation: string;
  text: string;
}

/**
 * These are fallback/hard-coded. Typically the values should be passed into
 * getScoringWeight and derived from data-driven configuration.
 */
export enum ScoringWeight {
  Grammar = 0.5,
  Syntax = 0.35,
  Standard = 0.15
}
