export class CalibrationContentStyles {
  private static readonly styles = {
    container: {
      minWidth: 470
    }
  };

  public static get() {
    return this.styles;
  }
}
