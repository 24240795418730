import { ActionType, createAsyncAction } from '@lexialearning/main-model';
import { ILevelStatus } from '../levels-completed.model';

export type LevelsCompletedActionAdd = ActionType<
  typeof LevelsCompletedAction.add.request
>;
export type LevelsCompletedActionAddSuccess = ActionType<
  typeof LevelsCompletedAction.add.success
>;

export type LevelsCompletedActionInit = ActionType<
  typeof LevelsCompletedAction.init.request
>;
export type LevelsCompletedActionInitSuccess = ActionType<
  typeof LevelsCompletedAction.init.success
>;

export type LevelsCompletedActionLoad = ActionType<
  typeof LevelsCompletedAction.load.request
>;
export type LevelsCompletedActionLoadSuccess = ActionType<
  typeof LevelsCompletedAction.load.success
>;
export type LevelsCompletedActionLoadFailure = ActionType<
  typeof LevelsCompletedAction.load.failure
>;

export type LevelsCompletedActionSetCertificateViewed = ActionType<
  typeof LevelsCompletedAction.setCertificateViewed.request
>;

export const LevelsCompletedAction = {
  /**
   * Add the just-completed level to the set of completed levels (i.e. set its
   * status to completed)
   * @see addCompletedLevelEpic
   */
  add: createAsyncAction<void, ILevelStatus[]>('levelsCompleted/add'),

  /**
   * Initialize the level status array based on the student's grade and current
   * level, unless its already been loaded.
   * @see initLevelsCompletedEpic
   */
  init: createAsyncAction<void, ILevelStatus[]>('levelsCompleted/init'),

  /**
   * Update the level status array with levels considered completed by the API.
   */
  load: createAsyncAction<void, ILevelStatus[]>('levelsCompleted/load'),

  setCertificateViewed: createAsyncAction<number>(
    'levelsCompleted/setCertificateViewed'
  )
};
