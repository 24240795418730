import * as React from 'react';
import { GripDots } from 'common-ui/components/grip-dots/GripDots';
import { Row } from 'common-ui/components/layout/row/Row';
import { AnimatedText } from 'common-ui/components/text/AnimatedText';
import { AnimatedView } from 'common-ui/components/view/AnimatedView';
import { IDndDragSourceProps } from 'dnd';
import { ThemeSize, withTheme } from 'theme';
import { TextSegmentAnimatedStyles } from './TextSegment.animated-styles';

export interface ITextSegmentProps extends IDndDragSourceProps {
  children?: React.ReactNode;
  draggingOpacity: number;
  hasGripDots?: boolean;
  horizontalSpacing?: number;
  isPreviewing?: boolean;
  showCorrect: boolean;
  showPillOnCorrect?: boolean;
  themeSize?: ThemeSize;
  verticalSpacing?: number;
  width?: number;
}

export class TextSegmentComponent extends React.PureComponent<ITextSegmentProps> {
  public static readonly displayName = 'TextSegment';

  private readonly textSegmentAnimatedStyles: TextSegmentAnimatedStyles;

  public static defaultProps = {
    draggingOpacity: 0.45,
    showPill: true,
    showPillOnCorrect: true
  };

  constructor(props: ITextSegmentProps) {
    super(props);

    this.textSegmentAnimatedStyles = new TextSegmentAnimatedStyles();
  }

  public componentDidUpdate(prevProps: ITextSegmentProps) {
    const { isDraggingOrDropping, showCorrect, showPillOnCorrect } = this.props;
    const {
      setTextBlack,
      setTextTransparent,
      setTextGreen,
      setTextGreenAndHidePill
    } = this.textSegmentAnimatedStyles.getAnimations();

    if (!prevProps.showCorrect && showCorrect) {
      if (showPillOnCorrect) {
        setTextGreen.start();
      } else {
        setTextGreenAndHidePill.start();
      }
    } else if (!prevProps.isDraggingOrDropping && isDraggingOrDropping) {
      setTextTransparent.start();
    } else if (prevProps.isDraggingOrDropping && !isDraggingOrDropping) {
      setTextBlack.start();
    }
  }

  public componentDidMount() {
    const { isPreviewing } = this.props;

    const { setTextBlue } = this.textSegmentAnimatedStyles.getAnimations();

    if (isPreviewing) {
      setTextBlue.start();
    }
  }

  public render() {
    const { hasGripDots, children } = this.props;
    const styles = this.textSegmentAnimatedStyles.build(this.props);

    return (
      <Row style={styles.container}>
        <AnimatedView
          style={styles.background}
          animatedStyle={styles.backgroundAnimated}
        />
        {hasGripDots && <GripDots dotRowPosition="left" />}
        <AnimatedText style={styles.text} animatedStyle={styles.textAnimated}>
          {children}
        </AnimatedText>
        {hasGripDots && <GripDots dotRowPosition="right" />}
      </Row>
    );
  }
}

export const TextSegment = withTheme(TextSegmentComponent);
