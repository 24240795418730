import { IScreenplay, IScreenplayAction } from '@lexialearning/lobo-common';
import { CharacterSceneElementName } from 'feature-areas/character-scene/character-scene.model';
import { PlacementSceneAnimationName } from 'feature-areas/placement/placement-scene/placement-scene.model';
import {
  ControlPanelComponent,
  FadeAnimationType
} from 'feature-areas/shell/control-panel/ControlPanel';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { StormService } from 'storm';
import { RoundAction, RoundIntroType } from '../rounds';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { cloneDeep } from 'lodash';

/**
 * Creates a screenplay for the animated intro to the placement page
 * - running storm and react animations, and playing voiceovers
 */
export class PlacementScreenplayBuilderBase extends TransitionScreenplayBuilderBase {
  public static readonly displayName: string = 'PlacementScreenplayBuilderBase';

  protected constructor(screenplayId: string) {
    super(screenplayId);
  }

  public showPlacementScene(
    preparedScenes: PreparedScenes,
    stormService: StormService
  ) {
    this.builder.addCallback(
      async () => {
        const placementScene = await preparedScenes.placementReady;
        placementScene.show();

        // TODO: https://jira.lexialearning.com/browse/LOBO-15621
        if (preparedScenes.calibration) {
          stormService.unloadScene(preparedScenes.calibration.scene);
          preparedScenes.calibration = undefined;
        }
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  public fadeOutControlPanel() {
    this.builder.addReactAnimation(
      ControlPanelComponent.getAnimationName(FadeAnimationType.FadeOut)
    );

    return this;
  }

  public attachCharacter(preparedScenes: PreparedScenes) {
    this.builder.addCallback(() => {
      preparedScenes.placement!.attachCharacter();
    });

    return this;
  }

  public centerCharacter() {
    this.builder.addStormAnimation({
      name: PlacementSceneAnimationName.Root.Center,
      speed: 0,
      targetScene: SceneName.Placement,
      timeAsPercent: 100
    });

    return this;
  }

  public dispatchRoundIntro(introType: RoundIntroType) {
    this.builder.addReduxAction(RoundAction.intro({ type: introType }));

    return this;
  }

  protected addSpeaker(screenplay: IScreenplay): IScreenplayAction[] {
    return cloneDeep(screenplay).actions.map(a => ({
      ...a,
      data: {
        ...a.data,
        speaker: {
          directions: {},
          sceneId: SceneName.Placement,
          speakerId: CharacterSceneElementName.Character
        }
      }
    }));
  }
}
