import { identifyLogsEpic } from './setup/identifyLogs.epic';
import { initializeLoggerEpic } from './setup/initializeLogger.epic';
import { logActivitySelectionEpic } from './navigation/logActivitySelection.epic';
import { logCalibrationEpic } from './calibration/logCalibration.epic';
import { logEncounterEntryEpic } from './navigation/logEncounterEntry.epic';
import { logFunFactPlayedEventEpic } from './fun-fact/logFunFactPlayedEvent.epic';
import { logMicChangeEpic } from './settings/logMicChange.epic';
import { logNavigateBackEpic } from './navigation/logNavigateBack.epic';
import { logOnboardingCalibrationTimingsEpic } from './timings/logOnboardingCalibrationTimings.epic';
import { logPositionSaveEpic } from './save-position/logPositionSave.epic';
import { logSessionInitialLevelPositionEpic } from './session-position/logSessionInitialLevelPosition.epic';
import { logSpinnerDisplayEpic } from './spinner/logSpinnerDisplay.epic';
import { logStartupTimingsEpic } from './timings/logStartupTimings.epic';
import { logStudentInfoModalEpic } from './settings/logStudentInfoModal.epic';
import { logTaskAttemptEpic } from './task-attempt/logTaskAttempt.epic';
import { logTaskAudioSupportEpic } from './audio-support/logTaskAudioSupport.epic';
import { logTroubleshootModalTimingsEpic } from './timings/logTroubleshootModalTimings.epic';
import { logSkipButtonPressedEpic } from './skip-button/logSkipButtonPressed.epic';
import { logSeeSpeakListenDelayEpic } from './timings/logSeeSpeakListenDelay.epic';
import { logCalibrationListenDelayEpic } from './timings/logCalibrationListenDelay.epic';

export const loggingEpics = [
  identifyLogsEpic,
  initializeLoggerEpic,
  logActivitySelectionEpic,
  logCalibrationEpic,
  logCalibrationListenDelayEpic,
  logEncounterEntryEpic,
  logFunFactPlayedEventEpic,
  logMicChangeEpic,
  logNavigateBackEpic,
  logOnboardingCalibrationTimingsEpic,
  logPositionSaveEpic,
  logSeeSpeakListenDelayEpic,
  logSessionInitialLevelPositionEpic,
  logSpinnerDisplayEpic,
  logSkipButtonPressedEpic,
  logStartupTimingsEpic,
  logStudentInfoModalEpic,
  logTaskAttemptEpic,
  logTaskAudioSupportEpic,
  logTroubleshootModalTimingsEpic
];
