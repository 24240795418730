import { ITopic } from '@lexialearning/lobo-common';
import { IActProgress } from '@lexialearning/lobo-common/main-model/student';
import { HeaderTitlePill, Text } from 'common-ui';
import { TitlePillBadge } from 'shared-components/title-pill-badge';
import { LevelHeaderStyles } from './LevelHeader.styles';

export interface ILevelHeaderProps {
  levelNumber: string;
  topics: ITopic[];
  actsProgress: IActProgress[];
}

export function LevelHeader(props: ILevelHeaderProps) {
  const { levelNumber, topics, actsProgress } = props;
  const styles = LevelHeaderStyles.get();

  const badges = topics.map((t: ITopic, i: number) => {
    const badge = actsProgress[i].isComplete ? t.badge : undefined;

    return (
      <TitlePillBadge
        key={i}
        id={`act_${i}`}
        imageSrc={badge?.imageSource}
        accessibilityLabel={badge?.altText}
      />
    );
  });

  return (
    <HeaderTitlePill>
      {badges[0]}
      {badges[1]}

      <Text style={styles.title}>
        Level <Text style={styles.titleNumber}>{levelNumber}</Text>
      </Text>

      {badges[2]}
      {badges[3]}
    </HeaderTitlePill>
  );
}
LevelHeader.displayName = 'LevelHeader';
