import {
  ITask,
  IUnitPosition,
  IUnitSavePoint,
  SubunitTypeExtended,
  UnitSavePointStatus
} from '@lexialearning/lobo-common';
import { cloneDeep } from 'lodash';

export class SavePointBuilder {
  public static readonly displayName = 'SavePointBuilder';

  public static create(
    unitId: string,
    subunitType: SubunitTypeExtended,
    partial?: Partial<IUnitSavePoint>
  ): SavePointBuilder {
    return new SavePointBuilder({
      rounds: [],
      status: UnitSavePointStatus.Deferred,
      workStartTime: Date.now(),
      ...partial,
      subunitType,
      unitId
    });
  }

  public static from(savePoint: IUnitSavePoint): SavePointBuilder {
    return new SavePointBuilder(cloneDeep(savePoint));
  }

  private constructor(public readonly savePoint: IUnitSavePoint) {}

  public addRound(
    { attempts, roundId }: IUnitPosition,
    { taskType, isScored }: ITask
  ): SavePointBuilder {
    this.savePoint.rounds = [
      ...this.savePoint.rounds,
      {
        attempts,
        isScored,
        roundId,
        taskType
      }
    ];

    return this;
  }
}
