/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
export const ColorHelper = {
  hexToRgb: (hex: string) => `rgb(${ColorHelper.hexToRgbValues(hex)})`,

  hexToRgbValues: (hex: string) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHex = hex.replace(
      shorthandRegex,
      (_: string, r: string, g: string, b: string) => r + r + g + g + b + b
    );

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
    const rgb = result && {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };

    return rgb && Object.values(rgb).toString();
  },

  hexToRgba: (hex: string, alpha: number) =>
    `rgba(${ColorHelper.hexToRgbValues(hex)},${alpha})`
};
