export enum BootstrappingActionType {
  AutoLoginComplete = 'BOOTSTRAPPING.AUTO_LOGIN_COMPLETE',
  Bootstrap = 'BOOTSTRAPPING.BOOTSTRAP',
  ConfigurationComplete = 'BOOTSTRAPPING.CONFIGURATION_COMPLETE',
  DeviceSetupComplete = 'BOOTSTRAPPING.DEVICE_SETUP_COMPLETE',
  DeepLinkPositionSetupComplete = 'BOOTSTRAPPING.DEEP_LINK_POSITION_SETUP_COMPLETE'
}

export interface IBootstrappingState {
  isAutoLoginComplete: boolean;
  isConfigurationComplete: boolean;
  isDeviceSetupComplete: boolean;
  isFeatureAreaBootstrapContentLoadingComplete: boolean;
  isDeepLinkPositionSetupComplete: boolean;
}
