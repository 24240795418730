// istanbul ignore file - static SVG, nothing to test
import { G, ITextButtonIconProps, Path, Svg } from '../components';

export function LogoutIconSvg({ color }: ITextButtonIconProps) {
  return (
    <Svg
      data-test-id={LogoutIconSvg.displayName}
      height={22}
      viewBox="0 0 24.497 23.479"
      width={22}
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      >
        <Path stroke={color} d="M8.349 11.329h14.147l-4.348-3.952" />
        <Path stroke={color} d="M8.349 11.657h14.147l-4.348 3.952" />
        <Path stroke={color} d="M11.402 4.656V2h-9.4v19.479h9.4v-2.656" />
      </G>
    </Svg>
  );
}
LogoutIconSvg.displayName = 'LogoutIconSvg';
