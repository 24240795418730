import { LexiaError } from '@lexialearning/utils';
import { StateObservable } from 'redux-observable';
import {
  PositionChangeType,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import { TransitionAction } from 'feature-areas';
import {
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import { ProfileSelector } from 'services/profile';
import { RoundIntroType } from '../builders/rounds';
import { RoundToActCompleteTransitionFactory } from './act-complete/RoundToActCompleteTransitionFactory';
import { RoundToEncounterCompleteTransitionFactory } from './encounter-complete/RoundToEncounterCompleteTransitionFactory';
import { RoundToLevelCompleteTransitionFactory } from './level-complete/RoundToLevelCompleteTransitionFactory';
import { RoundToOnboardingCompleteTransitionFactory } from './onboarding-complete/RoundToOnboardingCompleteTransitionFactory';
import { RoundToPlacementCompleteTransitionFactory } from './placement-complete/RoundToPlacementCompleteTransitionFactory';
import { RoundToRoundTransitionFactory } from './round-complete/RoundToRoundTransitionFactory';
import { IRoundToNextTransitionDeps } from './round-transition.model';
import { RoundToUnitCompleteTransitionFactory } from './unit-complete/RoundToUnitCompleteTransitionFactory';

export class RoundToNextTransitionFactory {
  public static readonly displayName = 'RoundToNextTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): ScreenplayActionPlay {
    const context = ProgramContextSelector.getRoundContext(state$.value);
    const action = this.prependExitScreenplay(
      context,
      this.createMainScreenplay(context, state$, deps)
    );

    return action;
  }

  private static createMainScreenplay(
    context: RoundContext,
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): ScreenplayActionPlay {
    const isStudent = ProfileSelector.isStudent(state$.value);
    const changeType = this.determineChangeType(context, isStudent);

    switch (changeType) {
      case PositionChangeType.OnboardingCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToOnboardingCompleteTransitionFactory.createFor(
            state$,
            deps,
            context
          )
        });

      case PositionChangeType.LevelCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToLevelCompleteTransitionFactory.create(state$, deps)
        });

      case PositionChangeType.ActivityCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToActCompleteTransitionFactory.create(state$, deps)
        });

      case PositionChangeType.EncounterCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToEncounterCompleteTransitionFactory.create(
            state$,
            deps
          )
        });

      case PositionChangeType.UnitCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToUnitCompleteTransitionFactory.create(
            state$,
            deps,
            context
          )
        });

      case PositionChangeType.RoundCompletion:
        return ScreenplayAction.play({
          screenplay: RoundToRoundTransitionFactory.create(
            state$,
            deps,
            context,
            RoundIntroType.RoundIntro
          )
        });

      // returning from instruction or UserChoice unit
      case PositionChangeType.Join:
      case PositionChangeType.StepUp:
        return ScreenplayAction.play({
          screenplay: RoundToRoundTransitionFactory.create(
            state$,
            deps,
            context,
            RoundIntroType.SubUnitOutro,
            changeType
          )
        });

      // going to instruction or UserChoice unit, or recycling
      case PositionChangeType.Fork:
      case PositionChangeType.StepDown:
      case PositionChangeType.UnitRecycling:
        return ScreenplayAction.play({
          screenplay: RoundToRoundTransitionFactory.create(
            state$,
            deps,
            context,
            RoundIntroType.UnitIntro
          )
        });

      case PositionChangeType.PlacementCompletion:
        return ScreenplayAction.play({
          nextAction: TransitionAction.placementCompleteToLevel(),
          screenplay: RoundToPlacementCompleteTransitionFactory.createFor(
            state$,
            deps
          )
        });

      default:
        throw new LexiaError(
          `Unrecognized position change type "${changeType}"`,
          RoundToNextTransitionFactory.displayName,
          RoundToNextTransitionFactoryError.ChangeTypeInvalid
        ).withContext({ activityPosition: context.activityPosition });
    }
  }

  private static determineChangeType(
    context: RoundContext,
    isStudent: boolean
  ): PositionChangeType {
    const changeTypePrelim =
      context.activityPosition.imminentPosition?.changeType;

    // For educators, should always be an encounter achievement if not a round or unit
    return !isStudent &&
      [
        PositionChangeType.ActivityCompletion,
        PositionChangeType.LevelCompletion
      ].includes(changeTypePrelim!)
      ? PositionChangeType.EncounterCompletion
      : changeTypePrelim ?? PositionChangeType.None;
  }

  private static prependExitScreenplay(
    context: RoundContext,
    action: ScreenplayActionPlay
  ): ScreenplayActionPlay {
    const mainScreenplay = action.payload.screenplay;
    const exitScreenplay =
      context.taskRegistration.buildExitScreenplay(context);

    action.payload.screenplay = ScreenplayBuilder.create(mainScreenplay.id)
      .addScreenplay(exitScreenplay)
      .addScreenplay(mainScreenplay).screenplay;

    return action;
  }
}

export enum RoundToNextTransitionFactoryError {
  ChangeTypeInvalid = 'ChangeTypeInvalid'
}
