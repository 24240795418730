import { ActionType, createAction } from 'typesafe-actions';
import { ISfx, SfxActionType } from './sfx.model';

export const SfxAction = {
  play: createAction(SfxActionType.Play)<ISfx>(),
  playComplete: createAction(SfxActionType.PlayComplete)()
};

export type SfxActions = ActionType<typeof SfxAction>;
export type SfxActionPlay = ActionType<typeof SfxAction.play>;
export type SfxActionPlayComplete = ActionType<typeof SfxAction.playComplete>;
