import { Color } from 'common-styles';

export class CleverWebviewModalStyles {
  public static readonly styles = {
    webView: {
      backgroundColor: Color.NearWhite,
      height: 700,
      width: 700
    },
    splashScreen: {
      height: 705,
      left: 10,
      top: 90,
      width: 680
    }
  };

  public static get() {
    return this.styles;
  }
}
