import {
  IActEducator,
  IEncounterEducator,
  ILevelEducator
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { toNumber } from 'lodash';
import { IProgramPosition } from 'curriculum-services';
import { EducatorActivityDetail } from './EducatorActivityDetail';

export class EducatorContext {
  public static readonly displayName = 'EducatorContext';

  public get level(): ILevelEducator {
    const level = this.levelMaybe;
    if (!level) {
      throw new LexiaError(
        'No selected level',
        EducatorContext.displayName,
        EducatorContextError.LevelMissing
      ).withContext({ position: this.position });
    }

    return level;
  }

  public readonly levelMaybe: ILevelEducator | undefined;

  public get levelNumber(): number {
    return toNumber(this.level.title);
  }

  public get act(): IActEducator {
    const act = this.actMaybe;
    if (!act) {
      throw new LexiaError(
        'No selected activity',
        EducatorContext.displayName,
        EducatorContextError.ActMissing
      ).withContext({ position: this.position });
    }

    return act;
  }

  public get actIndex(): number {
    const index = this.actIndexMaybe;
    if (index === undefined) {
      throw new LexiaError(
        'No activity index',
        EducatorContext.displayName,
        EducatorContextError.ActIndexMissing
      ).withContext({ position: this.position });
    }

    return index;
  }

  public readonly actIndexMaybe: number | undefined;

  public readonly actMaybe: IActEducator | undefined;

  public readonly isFirstAct: boolean;

  public readonly isLastAct: boolean;

  public get encounter(): IEncounterEducator {
    const encounter = this.encounterMaybe;
    if (!encounter) {
      throw new LexiaError(
        'No selected encounter',
        EducatorContext.displayName,
        EducatorContextError.EncounterMissing
      ).withContext({ position: this.position });
    }

    return encounter;
  }

  public readonly encounterMaybe: IEncounterEducator | undefined;

  public readonly activityPositionDetail: EducatorActivityDetail[];

  public constructor(
    public readonly levels: ILevelEducator[],
    public readonly position: IProgramPosition
  ) {
    this.levelMaybe = this.levels.find(l => l.sysId === position.levelId);

    const actIndex = this.levelMaybe
      ? this.levelMaybe.acts.findIndex(
          a => a.sysId === position.activeActivityId
        )
      : -1;
    this.actIndexMaybe = actIndex >= 0 ? actIndex : undefined;
    this.actMaybe = actIndex >= 0 ? this.levelMaybe?.acts[actIndex] : undefined;
    this.isFirstAct = actIndex === 0;
    this.isLastAct = this.levelMaybe
      ? actIndex === this.levelMaybe.acts.length - 1
      : false;

    const activityPosition = this.position.activityPositions.find(
      ap => ap.activityId === position.activeActivityId
    );
    this.encounterMaybe = this.actMaybe?.encounters.find(
      e => e.sysId === activityPosition?.encounterId
    );
    this.activityPositionDetail = this.position.activityPositions.map(
      (pos, idx) => new EducatorActivityDetail(this.level, pos, idx)
    );
  }
}

export enum EducatorContextError {
  LevelMissing = 'LevelMissing',
  ActMissing = 'ActMissing',
  ActIndexMissing = 'ActIndexMissing',
  EncounterMissing = 'EncounterMissing'
}
