import {
  IAct,
  IEncounter,
  IUnitSnipped
} from '@lexialearning/lobo-common/main-model/curriculum';
import * as React from 'react';
import { connect } from 'react-redux';
import { LevelSelector, ProgramContextSelector } from 'curriculum-services';
import { AppState } from 'services';
import { StudentInfoSection } from '../student-info-section/StudentInfoSection';

export interface IActiveProgramContextSummaryProps {
  levelNumber?: number;
  characterName: string;
  act?: IAct;
  encounter?: IEncounter;
  unit?: IUnitSnipped;
  isOnboardingOrPlacement: boolean;
}

export class ActiveProgramContextSummaryComponent extends React.PureComponent<IActiveProgramContextSummaryProps> {
  public static readonly displayName = 'ActiveProgramContextSummary';

  private getEncounterNumber(): number | undefined {
    const { act, encounter } = this.props;

    if (!act || !encounter) {
      return undefined;
    }

    return act.encounters.findIndex(e => e.sysId === encounter.sysId) + 1;
  }

  private getUnitNumber(): number | undefined {
    const { encounter, unit } = this.props;

    if (!encounter || !unit) {
      return undefined;
    }

    return encounter.units.findIndex(u => u.sysId === unit.sysId) + 1;
  }

  private getActiveProgramContextSummary(): string {
    const { isOnboardingOrPlacement, levelNumber, characterName } = this.props;

    if (isOnboardingOrPlacement) {
      return 'Placement';
    }

    const encounterNumber = this.getEncounterNumber();
    const unitNumber = this.getUnitNumber();

    const summaryArray: string[] = [];
    if (levelNumber) {
      summaryArray.push(`Level ${levelNumber}`);
    }
    if (characterName !== '') {
      summaryArray.push(characterName);
    }
    if (encounterNumber) {
      summaryArray.push(`Encounter ${encounterNumber}`);
    }
    if (unitNumber) {
      summaryArray.push(`Unit ${unitNumber}`);
    }

    return summaryArray.join(' | ');
  }

  public render() {
    const summary = this.getActiveProgramContextSummary();

    return <StudentInfoSection title={summary} />;
  }
}
function mapStateToProps(state: AppState) {
  const context = ProgramContextSelector.getEncounterContextMaybe(state);
  const isOnboardingOrPlacement =
    ProgramContextSelector.isOnboardingOrPlacement(state);

  return {
    act: context?.act,
    characterName: context?.act.character.displayName ?? '',
    encounter: context?.encounter,
    isOnboardingOrPlacement,
    levelNumber: LevelSelector.getLevelNumberMaybe(state),
    unit: context?.unitMain
  };
}

export const ActiveProgramContextSummary = connect(mapStateToProps)(
  ActiveProgramContextSummaryComponent
);

export const ActiveProgramContextSummaryPrivates = {
  mapStateToProps
};
