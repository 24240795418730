import {
  AvatarUserNameStyles,
  IAvatarUserNameStyleOverride
} from './AvatarUserName.styles';
import { ProfileSelector } from 'services/profile';
import { Text } from '../../text';
import { useSelector } from 'react-redux';

export interface IAvatarUserNameProps {
  styleOverride?: IAvatarUserNameStyleOverride;
}
export function AvatarUserName(props: IAvatarUserNameProps) {
  const displayName = useSelector(ProfileSelector.getDisplayName);
  const styles = AvatarUserNameStyles.build(props.styleOverride);

  return <Text style={styles.name}>{displayName}</Text>;
}
AvatarUserName.displayName = 'AvatarUserName';
