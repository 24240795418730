import { IEncounterProgress } from '@lexialearning/lobo-common/main-model/student';
import memoizeOne from 'memoize-one';
import { Color, Justify } from 'common-styles';
import { Overflow } from '@lexialearning/common-ui';

const ENCOUNTER_BAR_WIDTH = 85;

export class EncounterProgressBarStyles {
  private static readonly styles = {
    encounter: {
      backgroundColor: Color.BlackTransparent04,
      height: 20,
      justifyContent: Justify.Start,
      overflow: Overflow.Hidden,
      width: ENCOUNTER_BAR_WIDTH
    },
    encounterProgress: {
      backgroundColor: Color.BrightGreen,
      height: 20
    }
  };

  public static readonly build = memoizeOne(
    (encounterPositions: IEncounterProgress[]) => ({
      encounter1: {
        ...EncounterProgressBarStyles.styles.encounter,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10
      },
      encounter1progress: {
        ...EncounterProgressBarStyles.styles.encounterProgress,
        width:
          encounterPositions[0].percentComplete * 0.01 * ENCOUNTER_BAR_WIDTH
      },
      encounter2: {
        ...EncounterProgressBarStyles.styles.encounter,
        marginHorizontal: 5
      },
      encounter2progress: {
        ...EncounterProgressBarStyles.styles.encounterProgress,
        width:
          encounterPositions[1].percentComplete * 0.01 * ENCOUNTER_BAR_WIDTH
      },
      encounter3: {
        ...EncounterProgressBarStyles.styles.encounter,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10
      },
      encounter3progress: {
        ...EncounterProgressBarStyles.styles.encounterProgress,
        width:
          encounterPositions[2].percentComplete * 0.01 * ENCOUNTER_BAR_WIDTH
      }
    })
  );
}
