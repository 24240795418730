import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { LevelSelector } from 'curriculum-services';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { LevelCompleteScene } from '../LevelCompleteScene';
import { LevelCompleteSceneDefinitionBuilder } from '../LevelCompleteSceneDefinitionBuilder';
import { LevelCompleteSceneActionType } from '../redux/level-complete-scene-redux.model';
import { LevelCompleteScenePrepareAction } from '../redux/LevelCompleteScene.action';

export interface IPrepareLevelCompleteSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareLevelCompleteSceneEpic(
  action$: Observable<LevelCompleteScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareLevelCompleteSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(LevelCompleteSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      preparedScenes.levelComplete = undefined;

      const level = LevelSelector.getLevel(state$.value).title;
      const { definition } = LevelCompleteSceneDefinitionBuilder.create(level)
        .withBackground()
        .withBackgroundOverlay();

      await stormService.initialization;
      const scene = await stormService.prepareScene(definition);

      const levelCompleteScene = new LevelCompleteScene(
        scene,
        stormService,
        level
      );

      preparedScenes.levelComplete = levelCompleteScene;
    }),
    ignoreElements()
  );
}
prepareLevelCompleteSceneEpic.displayName = 'prepareLevelCompleteSceneEpic';
