import {
  ChoicesLayout,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { IMultiSelect } from '@lexialearning/lobo-common/tasks/multiple-choice';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { InteractionState } from 'common-styles';
import {
  Column,
  RoundedTabPosition,
  RoundedTabSvg,
  Row,
  TextPrompt,
  View
} from 'common-ui';
import { ITaskProps, ModelingSelector, TaskSelector } from '../core';
import { SubmitButton } from '../shared';
import { GabberPrompt } from './gabber-prompt/GabberPrompt';
import { CheckMarkTab } from './checkMark-tab/checkMarkTab';
import { ImageChoice } from './image-choice/ImageChoice';
import {
  IMultiSelectChoice,
  IMultiSelectChoiceProps
} from './multi-select-component.model';
import { MultiSelectStyles } from './MultiSelect.styles';
import { TextChoice } from './text-choice/TextChoice';
import { AnimatedPill } from './animated-pill';
import { IPositionTransitionProps } from 'common-ui/components/position-transition';

export interface IMultiSelectProps
  extends ITaskProps<IMultiSelect<IMultiSelectChoice>>,
    ISfxProps {
  highlightedItemId: string | undefined;
  showSolution: boolean;
}

export interface IMultiSelectState {
  choiceSlots: IMultiSelectChoice[];
  pickCount: number;
  slotPressSrcIdx: number;
  slotPressTargetIdx: number;
  animatedPillProps?: IPositionTransitionProps;
}

export class MultiSelectComponent extends React.PureComponent<
  IMultiSelectProps,
  IMultiSelectState
> {
  public static readonly displayName = 'MultiSelect';

  private readonly checkMarkOriginPositions: XYCoord[];

  private readonly choiceSlotPositions: XYCoord[];

  constructor(props: IMultiSelectProps) {
    super(props);

    this.state = this.createInitialState();

    this.checkMarkOriginPositions = [];
    this.choiceSlotPositions = [];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChoicePress = this.handleChoicePress.bind(this);
    this.handleCheckMarkOriginPosition =
      this.handleCheckMarkOriginPosition.bind(this);
    this.handleChoiceSlotPosition = this.handleChoiceSlotPosition.bind(this);
  }

  public componentDidUpdate(prevProps: IMultiSelectProps) {
    const { phase: prevPhase } = prevProps;
    const { phase, showSolution } = this.props;
    const { choices: prevChoices } = prevProps.taskContent;
    const { choices } = this.props.taskContent;

    if (prevChoices !== choices) {
      this.setState(this.createInitialState());
    }

    if (prevPhase === TaskPhase.Feedback && phase === TaskPhase.Interactive) {
      this.resetChoicePositions();
    }

    if (!prevProps.showSolution && showSolution) {
      this.showSolution();
    }
  }

  private createInitialState() {
    const { choices: choiceSlots } = this.props.taskContent;

    return {
      choiceSlots,
      pickCount: choiceSlots.filter(c => c.isCorrectChoice).length,
      slotPressSrcIdx: -1,
      slotPressTargetIdx: -1
    };
  }

  private resetChoicePositions() {
    const updatedChoices = this.state.choiceSlots.map(c => ({
      ...c,
      pillOriginIdx: c.isCorrectChoice ? c.pillOriginIdx : -1
    }));
    this.updateState(updatedChoices);
  }

  private updateState(choiceSlots: IMultiSelectChoice[], pc?: number) {
    const selectedChoiceCount = choiceSlots.filter(
      n => n.pillOriginIdx > -1
    ).length;
    const pickCount = pc || this.state.pickCount;
    const canSubmit = selectedChoiceCount === pickCount;

    this.props.updateCanSubmit({ canSubmit });
    this.setState({ choiceSlots, pickCount });
  }

  private showSolution() {
    let tokenIdx = -1;
    const choiceSlots = this.state.choiceSlots.map(c => {
      if (c.isCorrectChoice) {
        tokenIdx += 1;
      }

      return { ...c, pillOriginIdx: c.isCorrectChoice ? tokenIdx : -1 };
    });
    this.updateState(choiceSlots);
  }

  private checkAnswers() {
    const { choiceSlots } = this.state;
    const isIncorrect = choiceSlots.some(
      c => c.isCorrectChoice && c.pillOriginIdx === -1
    );

    this.props.onEvaluated(
      isIncorrect
        ? TaskEvaluationResult.Incorrect
        : TaskEvaluationResult.Correct,
      { choiceSlots }
    );
  }

  private handleSubmit(): void {
    this.props.updateCanSubmit({ canSubmit: false });
    this.checkAnswers();
  }

  private handleChoicePress(targetIdx: number) {
    const { choiceSlots } = this.state;
    const unusedIconPills = this.getUnusedIconPills();

    const slotIsEmpty = choiceSlots[targetIdx].pillOriginIdx === -1;
    const noAvailableChoices = !unusedIconPills.length;

    if (slotIsEmpty && noAvailableChoices) {
      this.props.playSfx(Sfx.CantDo);
    } else {
      this.props.playSfx(Sfx.Neutral);
      if (!slotIsEmpty) {
        const srcIdx = choiceSlots[targetIdx].pillOriginIdx;
        this.returnPillToOrigin(srcIdx, targetIdx);
      } else {
        const srcIdx = unusedIconPills[0];
        this.placePillOnChoiceSlot(srcIdx, targetIdx);
      }
    }
  }

  private returnPillToOrigin(srcIdx: number, targetIdx: number) {
    const fromOffset = this.choiceSlotPositions[targetIdx];
    const toOffset = this.checkMarkOriginPositions[srcIdx];
    this.animatePillPosition(srcIdx, -1, fromOffset, toOffset);
  }

  private placePillOnChoiceSlot(srcIdx: number, targetIdx: number) {
    const fromOffset = this.checkMarkOriginPositions[srcIdx];
    const toOffset = this.choiceSlotPositions[targetIdx];
    this.animatePillPosition(srcIdx, targetIdx, fromOffset, toOffset);
  }

  private animatePillPosition(
    srcIdx: number,
    targetIdx: number,
    fromOffset: XYCoord,
    toOffset: XYCoord
  ) {
    this.setState({
      animatedPillProps: {
        onAfterTransition: () => {
          this.updatePillLocations(srcIdx, targetIdx);
          this.setState({
            animatedPillProps: undefined,
            slotPressSrcIdx: -1,
            slotPressTargetIdx: -1
          });
        },
        position: fromOffset,
        toPosition: toOffset
      },
      slotPressSrcIdx: srcIdx,
      slotPressTargetIdx: targetIdx
    });
  }

  private updatePillLocations(srcIdx: number, targetSlotIdx: number) {
    const choiceSlots = [...this.state.choiceSlots];
    const srcSlotIdx = choiceSlots.findIndex(c => c.pillOriginIdx === srcIdx);
    const fromSlot = srcSlotIdx > -1; // coming from a target
    const toSlot = targetSlotIdx > -1; // moving to a target

    if (!fromSlot && !toSlot) {
      return;
    }

    if (fromSlot) {
      choiceSlots[srcSlotIdx].pillOriginIdx = -1;
    }
    if (toSlot) {
      choiceSlots[targetSlotIdx].pillOriginIdx = srcIdx;
    }

    this.updateState(choiceSlots);
  }

  private getUnusedIconPills() {
    const { choiceSlots, pickCount } = this.state;
    const droppedIconPills = choiceSlots
      .map(c => c.pillOriginIdx)
      .filter(n => n > -1);
    const iconPills = [...Array(pickCount).keys()];

    return iconPills.filter(n => !droppedIconPills.includes(n));
  }

  private getPillState(
    choice: IMultiSelectChoice,
    isPillAnimatingToChoice: boolean
  ): InteractionState {
    const { evaluationResult, highlightedItemId, phase, showSolution } =
      this.props;

    if (isPillAnimatingToChoice) {
      return InteractionState.Selected;
    }
    if (
      choice.card.sysId === highlightedItemId ||
      (showSolution && choice.isCorrectChoice)
    ) {
      return InteractionState.Highlighted;
    }

    if (phase === TaskPhase.Feedback && choice.pillOriginIdx !== -1) {
      if (
        evaluationResult === TaskEvaluationResult.Incorrect &&
        !choice.isCorrectChoice
      ) {
        return InteractionState.Incorrect;
      }
      if (evaluationResult === TaskEvaluationResult.Correct) {
        return InteractionState.Correct;
      }
    }

    return InteractionState.Default;
  }

  private async handleCheckMarkOriginPosition(position: XYCoord, idx: number) {
    this.checkMarkOriginPositions[idx] = position;
  }

  private async handleChoiceSlotPosition(position: XYCoord, idx: number) {
    this.choiceSlotPositions[idx] = position;
  }

  private getChoiceProps(
    choiceIdx: number,
    choice: IMultiSelectChoice
  ): IMultiSelectChoiceProps {
    const { slotPressSrcIdx, slotPressTargetIdx } = this.state;
    const isPillAnimatingToChoice = slotPressTargetIdx === choiceIdx;
    const iconPillState = this.getPillState(choice, isPillAnimatingToChoice);
    const pillOriginIdx =
      slotPressSrcIdx === choice.pillOriginIdx ? -1 : choice.pillOriginIdx;

    return {
      ...choice,
      index: choiceIdx,
      interactionState: iconPillState,
      onPress: this.handleChoicePress,
      onSlotPosition: this.handleChoiceSlotPosition,
      pillOriginIdx
    };
  }

  public render() {
    const { taskContent, themeSize } = this.props;
    const { textPrompt, choicesLayout } = taskContent;
    const { choiceSlots, pickCount } = this.state;
    const styles = MultiSelectStyles.build(
      choicesLayout,
      textPrompt,
      themeSize
    );

    return (
      <Column style={styles.container}>
        <GabberPrompt pickCount={pickCount} />
        <TextPrompt styleOverride={styles.textPrompt}>
          {textPrompt && textPrompt.text}
        </TextPrompt>
        <CheckMarkTab
          pickCount={pickCount}
          hiddenPills={[
            this.state.slotPressSrcIdx,
            ...choiceSlots.map(c => c.pillOriginIdx)
          ].filter(n => n > -1)}
          onCheckMarkPosition={this.handleCheckMarkOriginPosition}
        />
        <Column style={styles.choiceBox}>
          <Row style={styles.choiceContainer}>
            <View style={styles.innerChoiceContainer}>
              {choiceSlots.map((choice, idx) => {
                const props = this.getChoiceProps(idx, choice);

                return choicesLayout === ChoicesLayout.Image ? (
                  <ImageChoice
                    key={`multiSelectImage${idx}`}
                    {...props}
                    choicesCount={choiceSlots.length}
                  />
                ) : (
                  <TextChoice key={`multiSelectText${idx}`} {...props} />
                );
              })}
            </View>
          </Row>
        </Column>
        <AnimatedPill {...this.state.animatedPillProps} />
        <Column style={styles.submitContainer}>
          <RoundedTabSvg position={RoundedTabPosition.Bottom} />
          <SubmitButton onPress={this.handleSubmit} />
        </Column>
      </Column>
    );
  }
}

function mapStateToProps(state: unknown) {
  return {
    highlightedItemId: ModelingSelector.getHighlightedItemId(state),
    showSolution: TaskSelector.getShowSolution(state)
  };
}

export const MultiSelect = compose(
  withSfx,
  connect(mapStateToProps)
)(MultiSelectComponent);

export const MultiSelectComponentPrivates = {
  mapStateToProps
};
