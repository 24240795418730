import memoizeOne from 'memoize-one';
import { Color } from 'common-styles';

export class TextPromptSegmentStyles {
  private static readonly styles = {
    line: {
      borderBottomColor: Color.Text,
      borderBottomWidth: 2,
      minWidth: 75
    },
    noLine: {
      borderBottomWidth: 2,
      borderColor: Color.Transparent
    }
  };

  public static readonly build = memoizeOne((shouldFillInText: boolean) => ({
    ...TextPromptSegmentStyles.styles,
    lineText: {
      color: shouldFillInText ? Color.Text : Color.Transparent
    }
  }));
}
