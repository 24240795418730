import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Color } from 'common-styles';
import { Types } from '../../types';
import { KeyNavHelper } from 'common-ui/helpers';
import {
  ActiveComponentState,
  Align,
  Border,
  Cursor,
  Direction
} from '@lexialearning/common-ui';

export interface ICheckboxStyleOverride {
  container?: Types.ViewStyle;
  box?: Types.ViewStyle;
  label?: Types.TextStyle;
}
export class CheckboxStyles {
  public static readonly styles = {
    box: {
      borderColor: Color.Gray40,
      borderRadius: 3,
      borderStyle: Border.Solid,
      borderWidth: 2,
      height: 20,
      marginLeft: 5,
      marginRight: 8,
      width: 20
    },
    container: {
      alignItems: Align.Center,
      cursor: Cursor.Pointer,
      flexDirection: Direction.Row
    },
    label: {}
  };

  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      styleOverride?: ICheckboxStyleOverride
    ) =>
      merge(
        {},
        CheckboxStyles.styles,
        styleOverride,
        KeyNavHelper.isKeyNav(activeComponentState) && {
          box: {
            backgroundColor: Color.White,
            borderColor: Color.Black,
            borderWidth: 3
          }
        }
      )
  );
}
