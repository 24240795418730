import { ButtonBase, Column, Text } from 'common-ui';
import { IconSlot } from '../icon-slot/IconSlot';
import { IMultiSelectChoiceProps } from '../multi-select-component.model';
import { TextChoiceStyles } from './TextChoice.styles';
import { useSelector } from 'react-redux';
import { TaskSelector } from 'task-components/core';
import { TaskPhase } from '@lexialearning/lobo-common';
import { useActiveComponentState } from '@lexialearning/common-ui';

export function TextChoice(props: IMultiSelectChoiceProps) {
  const taskPhase = useSelector(TaskSelector.getPhase);
  const {
    card,
    index,
    interactionState,
    onPress,
    onSlotPosition,
    pillOriginIdx,
    ...restProps
  } = props;

  const handlePress = () => {
    onPress(index);
  };

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const disabled = taskPhase !== TaskPhase.Interactive;
  const styles = TextChoiceStyles.build(activeComponentState, interactionState);

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      disabled={disabled}
      onPress={handlePress}
      style={styles.button}
    >
      <IconSlot
        index={index}
        pillOriginIdx={pillOriginIdx}
        activeComponentState={activeComponentState}
        interactionState={interactionState}
        onPosition={onSlotPosition}
      />
      <Column style={styles.textContainer}>
        <Text style={styles.text}>{card.label}</Text>
      </Column>
    </ButtonBase>
  );
}
TextChoice.displayName = 'TextChoice';
