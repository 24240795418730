import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import {
  IScreenplayActionPlayer,
  ScreenplayerType
} from '../screenplayer.model';
import { IScreenplayDelay } from './delay-player.model';

export class DelayActionPlayer
  implements IScreenplayActionPlayer<IScreenplayDelay>
{
  private static readonly displayName = 'DelayActionPlayer';

  public readonly type = ScreenplayerType.Delay;

  private delayPromise?(): void;

  private delayRequestMs?: number;

  private delayStartTime?: number;

  private promiseTimeout?: NodeJS.Timeout;

  public cancel(): void {
    this.complete();
  }

  public pause(): void {
    if (!this.delayStartTime || !this.delayRequestMs) {
      throw new LexiaError(
        'Error attempting to pause delay: delayStartTime and delayRequestMs must both be defined',
        DelayActionPlayer.displayName,
        DelayActionPlayerError.NotProperlyDefined
      );
    }

    // istanbul ignore else - trivial
    if (this.promiseTimeout) {
      clearTimeout(this.promiseTimeout);
    }
    const elapsedMs = Date.now() - this.delayStartTime;
    // Store the remaining time as the new delayRequestMs
    this.delayRequestMs -= elapsedMs;
    this.delayStartTime = undefined;
  }

  public async play(
    action: IScreenplayAction<IScreenplayDelay>
  ): Promise<void> {
    const { delayMs } = action.data;
    this.delayRequestMs = delayMs;
    await new Promise<void>(resolve => {
      this.delayPromise = resolve;
      this.delayStartTime = Date.now();
      this.promiseTimeout = setTimeout(() => {
        this.complete();
      }, delayMs);
    });
  }

  public resume(): void {
    this.delayStartTime = Date.now();
    this.promiseTimeout = setTimeout(() => {
      this.complete();
    }, this.delayRequestMs);
  }

  private complete(): void {
    this.delayRequestMs = undefined;
    if (this.delayPromise) {
      this.delayPromise();
    }
    this.delayPromise = undefined;
    // istanbul ignore else - trivial
    if (this.promiseTimeout) {
      clearTimeout(this.promiseTimeout);
    }
  }
}

export enum DelayActionPlayerError {
  NotProperlyDefined = 'NotProperlyDefined'
}
