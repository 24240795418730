import * as React from 'react';
import { connect } from 'react-redux';
import { AnimatedText } from 'common-ui';
import { AppShellSelector } from 'feature-areas/shell';
import { AppState } from 'services';
import { LevelCertificateEncouragementAnimatedStyles } from './LevelCertificateEncouragement.animated-styles';

export interface ILevelCertificateEncouragementProps {
  shouldCalloutLevelCertificates: boolean;
}

export class LevelCertificateEncouragementComponent extends React.PureComponent<ILevelCertificateEncouragementProps> {
  public static readonly displayName = 'LevelCertificateEncouragement';

  private readonly animatedStyles: LevelCertificateEncouragementAnimatedStyles;

  constructor(props: ILevelCertificateEncouragementProps) {
    super(props);

    this.animatedStyles = new LevelCertificateEncouragementAnimatedStyles();
  }

  public componentDidUpdate(prevProps: ILevelCertificateEncouragementProps) {
    const { shouldCalloutLevelCertificates } = this.props;
    const {
      shouldCalloutLevelCertificates: prevShouldCalloutLevelCertificates
    } = prevProps;

    if (shouldCalloutLevelCertificates && !prevShouldCalloutLevelCertificates) {
      this.animatedStyles.getAnimations().encouragement.start();
    }
  }

  public render() {
    if (!this.props.shouldCalloutLevelCertificates) {
      return null;
    }

    const styles = this.animatedStyles.get();

    return (
      <AnimatedText
        testId={LevelCertificateEncouragementComponent.displayName}
        style={styles.text}
        animatedStyle={styles.textAnimated}
      >
        Well Done!
      </AnimatedText>
    );
  }
}

// istanbul ignore next - trivial
const mapStateToProps = (state: AppState) => ({
  shouldCalloutLevelCertificates:
    AppShellSelector.getShouldCalloutLevelCertificates(state)
});

export const LevelCertificateEncouragement = connect(mapStateToProps)(
  LevelCertificateEncouragementComponent
);
