import { Font } from 'common-styles';

export class LevelHeaderStyles {
  private static readonly styles = {
    title: {
      flex: 1,
      fontFamily: Font.Family.semiBold,
      fontSize: 30,
      letterSpacing: 15,
      lineHeight: 45
    },
    titleNumber: {
      fontFamily: Font.Family.semiBold,
      fontSize: 40,
      letterSpacing: 8
    }
  };

  public static get() {
    return this.styles;
  }
}
