import { PositionAction } from 'curriculum-services';
import {
  LevelReactAnimationName,
  LevelSceneAnimationName
} from 'feature-areas/levels';
import { CurtainReactAnimationName } from 'feature-areas/shell/curtain/curtain.model';
import { AppShellAction, AppShellMode } from 'feature-areas/shell/redux';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes } from 'services/storm-lobo/PreparedScenes';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { LevelScreenplayId } from './level-screenplay-builders.model';

export interface ILevelShowScreenplayDeps {
  levelId: string;
  preparedScenes: PreparedScenes;
  shouldDeselectActivity: boolean;
}

/**
 * Creates a screenplay to immediately display all elements of the
 * level page without animated intro
 * - *no* storm or react animations, *nor* playing voiceovers
 */
export class LevelShowScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'LevelShowScreenplayBuilder';

  public static createFor(
    deps: ILevelShowScreenplayDeps
  ): LevelShowScreenplayBuilder {
    return new LevelShowScreenplayBuilder(deps);
  }

  private constructor(deps: ILevelShowScreenplayDeps) {
    super(LevelScreenplayId.Show);

    const { levelId, preparedScenes, shouldDeselectActivity } = deps;
    this.disableUtilityBar()
      .fadeInOverlay()
      .navToLevelPage(levelId)
      .deselectActivityMaybe(shouldDeselectActivity)
      .showLevelScene(preparedScenes)
      .fadeOutOverlay()
      .showShellUtilitiesOnly()
      .showReactElements()
      .enableUtilityBar()
      .playLevelSceneIntro();
  }

  private fadeInOverlay(): LevelShowScreenplayBuilder {
    this.builder.addReactAnimation(CurtainReactAnimationName.BackToLevelFadeIn);

    return this;
  }

  private navToLevelPage(levelId: string): LevelShowScreenplayBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.levels(levelId));
    });

    return this;
  }

  private deselectActivityMaybe(
    shouldDeselectActivity: boolean
  ): LevelShowScreenplayBuilder {
    if (!shouldDeselectActivity) {
      return this;
    }

    this.builder.addReduxAction(PositionAction.activityDeselected());

    return this;
  }

  private showLevelScene(
    preparedScenes: PreparedScenes
  ): LevelShowScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const levelScene = await preparedScenes.levelReady;
        levelScene.show();
        preparedScenes.encounter?.hide();
        // Update progress meters in case progress has changed since scene was prepared
        levelScene.setActProgressMeters();
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  private fadeOutOverlay(): LevelShowScreenplayBuilder {
    this.builder.addReactAnimation(
      CurtainReactAnimationName.BackToLevelFadeOut,
      { concurrent: true }
    );

    return this;
  }

  private showShellUtilitiesOnly(): LevelShowScreenplayBuilder {
    this.builder.addReduxAction(
      AppShellAction.setMode(AppShellMode.UtilitiesOnly)
    );

    return this;
  }

  private showReactElements(): LevelShowScreenplayBuilder {
    this.builder.addReactAnimation(LevelReactAnimationName.Show, {
      concurrent: true
    });

    return this;
  }

  private playLevelSceneIntro(): LevelShowScreenplayBuilder {
    this.builder
      .addStormAnimation({
        name: LevelSceneAnimationName.Root.Intro,
        targetScene: SceneName.Level
      })
      .addStormAnimation({
        name: LevelSceneAnimationName.Root.Idle,
        targetScene: SceneName.Level
      });

    return this;
  }
}
