import { Color } from 'common-styles';
import { Path, Svg } from 'common-ui/components/svg';

export function ChangeIconSvg() {
  return (
    <Svg
      data-test-id={ChangeIconSvg.displayName}
      x="0"
      y="0"
      viewBox="0 0 26 21"
      height={22}
      width={22}
    >
      <Path
        fill={Color.Clementine}
        d="M4.4 5.7C5 5.7 6 6.6 7 7.9c.3-.7.8-1.5 1.3-2.3-1.1-1.3-2.5-2.3-3.9-2.4H0l.1 2.5s1.9-.1 4.3 0zM15.8 5.7h5L19.5 7l1.7 1.8L26 4.4 21.2 0l-1.7 1.8L21 3.2h-5.3c-3.7 0-6.3 5.7-6.8 7-1.3 2.4-3.5 5.1-4.5 5.1H.1L0 17.8h4.5c3-.1 5.9-5 6.7-6.5l.1-.1c.9-2.2 3-5.5 4.5-5.5zm6.5-1.3z"
      />
      <Path
        fill={Color.Clementine}
        d="M19.5 14l1.4 1.3h-5.1c-.8 0-1.7-.9-2.5-2-.4.7-.9 1.5-1.4 2.2 1.1 1.3 2.4 2.3 4 2.3H21l-1.5 1.4 1.7 1.8 4.8-4.4-4.8-4.4-1.7 1.8z"
      />
    </Svg>
  );
}
ChangeIconSvg.displayName = 'ChangeIconSvg';
