import * as React from 'react';
import { BackgroundImage, ResizeMode } from 'common-ui';
import { SystemInfo } from 'utils';
import { AppBackgroundStyles } from './AppBackground.styles';
import appBgPng from './assets/appBg.png';

export class AppBackground extends React.PureComponent {
  public static readonly displayName = 'AppBackground';

  public render() {
    const styles = AppBackgroundStyles.get();

    return (
      <BackgroundImage
        source={appBgPng}
        resizeMode={SystemInfo.isNative ? ResizeMode.Cover : ResizeMode.Repeat}
        style={styles.image}
      />
    );
  }
}
