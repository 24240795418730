import {
  FeedbackStrategy,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { ISingleSelect } from '@lexialearning/lobo-common/tasks/multiple-choice';
import * as React from 'react';
import { connect } from 'react-redux';
import { InteractionState } from 'common-styles';
import { Column } from 'common-ui/components/layout';
import { SpotlightArea } from 'common-ui/components/spotlight-area';
import { FillInTextPrompt } from 'common-ui/components/text-prompt';
import { ProgramContextSelector, UnitSelector } from 'curriculum-services';
import { ITaskProps, TaskAction, TaskSelector } from 'task-components';
import { ThemeType } from 'theme';
import { withTheme } from 'theme/withTheme.hoc';
import { Choices } from '../choices';
import { SingleSelectStyles } from './SingleSelect.styles';

export interface ISingleSelectProps extends ITaskProps<ISingleSelect> {
  themeType: ThemeType;
  feedbackInteractionState: InteractionState;
  feedbackStrategy: FeedbackStrategy | undefined;
  showSolution: boolean;
  clearSelectedChoices(): void;
}

export class SingleSelectComponent extends React.PureComponent<ISingleSelectProps> {
  public static readonly displayName = 'SingleSelect';

  public componentDidUpdate(prevProps: ISingleSelectProps) {
    if (
      prevProps.phase === TaskPhase.Feedback &&
      this.props.phase === TaskPhase.Interactive
    ) {
      this.props.clearSelectedChoices();
    }
  }

  public render() {
    const {
      themeType,
      feedbackInteractionState,
      phase,
      feedbackStrategy,
      showSolution
    } = this.props;
    const {
      choices,
      choicesLayout,
      instructionalImage,
      spotlights,
      textPillWidth,
      textPrompt
    } = this.props.taskContent;
    const hasSpotlightArea = !!(instructionalImage || spotlights.length);
    const styles = SingleSelectStyles.build(
      choicesLayout,
      !!textPrompt,
      themeType,
      hasSpotlightArea
    );
    const correctChoice = choices.find(c => c.isCorrectChoice)?.card.label;
    const shouldFillInText =
      feedbackStrategy !== FeedbackStrategy.Neutral &&
      ((phase === TaskPhase.Feedback &&
        feedbackInteractionState === InteractionState.Correct) ||
        showSolution);

    return (
      <Column style={styles.container}>
        {hasSpotlightArea && (
          <SpotlightArea {...{ instructionalImage, spotlights }} />
        )}
        <Column style={styles.innerContainer}>
          {!!textPrompt?.text && (
            <FillInTextPrompt
              styleOverride={styles.textPrompt}
              correctChoice={correctChoice}
              shouldFillInText={shouldFillInText}
              textPrompt={textPrompt.text}
            />
          )}
          <Choices
            choices={choices}
            choicesLayout={choicesLayout}
            textPillWidth={textPillWidth}
            onEvaluated={this.props.onEvaluated}
          />
        </Column>
      </Column>
    );
  }
}
// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    feedbackInteractionState: TaskSelector.getFeedbackInteractionState(
      ProgramContextSelector.getLastEvaluationResultMaybe(state)
    ),
    feedbackStrategy: UnitSelector.getFeedbackStrategy(state),
    showSolution: TaskSelector.getShowSolution(state)
  };
}

const mapDispatchToProps = {
  clearSelectedChoices: () => TaskAction.clearSelectedChoices()
};

export const SingleSelect = withTheme(
  connect(mapStateToProps, mapDispatchToProps)(SingleSelectComponent)
);

export const SingleSelectPrivates = {
  mapDispatchToProps
};
