import { TextPillWidth } from '@lexialearning/lobo-common/main-model';
import { ThemeSize } from 'theme';

export class OrderingDimensions {
  public static textPillHeight(themeSize: ThemeSize) {
    return themeSize === ThemeSize.Large ? 50 : 40;
  }

  public static textPillWidth(textPillWidth: TextPillWidth) {
    return textPillWidth === TextPillWidth.Wide ? 900 : 650;
  }
}
