import { Sfx } from 'audio';
import { useSfx } from 'audio/sfx/useSfx';
import { useDispatch } from 'react-redux';
import { ProfileAction } from 'services/profile';
import { IAvatar } from 'services/profile/avatar.model';

export function useHandleAvatarEditorButtonPress() {
  const dispatch = useDispatch();
  const playSfx = useSfx();

  return (payload: Partial<IAvatar>) => {
    dispatch(ProfileAction.updateAvatar(payload));
    playSfx(Sfx.Neutral);
  };
}
