// istanbul ignore file - TODO: finish Addressing specs - LOBO-19187
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SreSelector, SreSessionAction } from '../redux';
import {
  SreSessionOptions,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import { useDidMount, usePreviousPersistent } from 'common-ui';
import {
  PositionChangeType,
  ProgramContextSelector
} from 'curriculum-services';
import { TaskSelector } from 'task-components';
import { TaskPhase } from '@lexialearning/lobo-common';

export function usePrimedSession() {
  const [sreOptions, setSreOptions] = useState<SreSessionOptions | undefined>();

  const dispatch = useDispatch();

  const isMicBlocked = useSelector(SreSelector.getMicBlocked);
  const isListening = useSelector(SreSelector.getIsListening);
  const sessionType = useSelector(SreSelector.getSessionType);
  const prevSessionType = usePreviousPersistent(sessionType);

  const taskPhase = useSelector(TaskSelector.getPhase);
  const isInteractive = taskPhase === TaskPhase.Interactive;
  const wasFeedback = usePreviousPersistent(taskPhase) === TaskPhase.Feedback;
  const isRetry = wasFeedback && isInteractive;

  const changeType = useSelector(ProgramContextSelector.getRoundContextMaybe)
    ?.imminentPositionMaybe?.changeType;
  const hasTaskCompleted = ![undefined, PositionChangeType.None].includes(
    changeType
  );

  const sessionTypeRef = useRef(sessionType);
  useEffect(() => {
    sessionTypeRef.current = sessionType;
  }, [sessionType]);

  const dispatchPrimeMaybe = () => {
    if (sreOptions && !isMicBlocked && !isListening && !hasTaskCompleted) {
      dispatch(SreSessionAction.prime.request(sreOptions));
    }
  };

  // Reprime if last attempt not successful
  useEffect(() => {
    if (isRetry) {
      dispatchPrimeMaybe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRetry]);

  // Reprime when a calibration session ends
  useEffect(() => {
    if (!isListening && prevSessionType === SreSessionType.Calibration) {
      dispatchPrimeMaybe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListening]);

  // Reprime when a language frame session is canceled and is in interactive
  // phase (eg, when StudentInfoModal is opened and cancels a LF listening
  // session)
  useEffect(() => {
    if (
      !isListening &&
      prevSessionType === SreSessionType.LanguageFrame &&
      isInteractive
    ) {
      dispatchPrimeMaybe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListening]);

  useDidMount(() =>
    // Cancel priming when unmounted
    () => {
      if (sessionTypeRef.current === SreSessionType.PrimedLanguageFrame) {
        dispatch(SreSessionAction.cancel.request());
      }
    }
  );

  /** Set initial SRE options, and dispatch SreSessionAction.prime.request  */
  const initializePrimedState = useCallback(
    (options: SreSessionOptions) => {
      setSreOptions(options);
      dispatch(SreSessionAction.prime.request(options));
    },
    [dispatch]
  );

  /** Update SRE options to be used for next primed session to be run  */
  const updatePrimedStateSreOptions = useCallback(
    (options: SreSessionOptions) => {
      setSreOptions(options);
    },
    []
  );

  return { initializePrimedState, updatePrimedStateSreOptions };
}
usePrimedSession.displayName = 'usePrimedSession';
