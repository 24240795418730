import { LexiaError } from '@lexialearning/utils';
import { Services } from 'services';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { DeviceInfoHelperError } from './device-info-helper.model';

/**
 * Now used just for 'getSummary()', as the main deviceInfo now comes from Uni's DeviceInfoReporter.create()
 * It might be worth moving 'getSummary()' to DeviceInfoReporter as well in the future
 */
export class DeviceInfoHelper {
  public static readonly displayName = 'DeviceInfoHelper';

  public static getSummary(): string {
    let summary = '';

    try {
      const browser = UserAgentUtils.getBrowser();
      summary = `${browser.name} ${browser.version}`;
    } catch (e) {
      this.handleError(e);
    }

    return summary;
  }

  private static handleError(e: Error): void {
    Services.logError(
      new LexiaError(
        'Failed to get device info',
        DeviceInfoHelper.displayName,
        DeviceInfoHelperError.FailedToGetInfo
      ).withCause(e)
    );
  }
}
