import { Color } from 'common-styles';
import { Polygon, Svg } from 'common-ui';

const SIZE = 16;

export function TailSvg() {
  return (
    <Svg
      data-test-id={TailSvg.displayName}
      height={SIZE}
      width={SIZE}
      style={{ marginTop: -1 }}
    >
      <Polygon
        points={`0,0, ${SIZE},0 ${SIZE * 0.5},${SIZE * 0.87}`}
        fill={Color.Gray60}
        stroke={Color.Gray60}
      />
    </Svg>
  );
}
TailSvg.displayName = 'TailSvg';
