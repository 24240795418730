import { Color, Font, Justify, TextAlign } from 'common-styles';
import { Position } from '@lexialearning/common-ui';
import { ISize } from 'common-ui/helpers/SizingUtils';

export class VideoPlayerStyles {
  private static readonly styles = {
    buttonRow: {
      flex: 1,
      justifyContent: Justify.SpaceBetween,
      position: Position.Absolute,
      width: 210
    },
    buttonText: {
      color: Color.White,
      fontFamily: Font.Family.regular,
      fontSize: Font.Size.small,
      letterSpacing: 0,
      marginTop: 7,
      textAlign: TextAlign.Left
    }
  };

  public static build(appSize: ISize) {
    return {
      ...this.styles,
      appSize: {
        height: appSize.h,
        width: appSize.w
      },
      buttonOverlay: {
        backgroundColor: Color.BlackTransparent70,
        flex: 1,
        height: appSize.h,
        justifyContent: Justify.Center,
        position: Position.Absolute,
        width: appSize.w
      }
    };
  }
}
