import { IButtonBaseProps } from '../ButtonBase';
import {
  UtilityButtonIconType,
  UtilityButtonType
} from './utility-button-model';
import { UtilityButtonSvg } from './UtilityButton.lx-svg';
import { UtilityButtonStyles } from './UtilityButton.styles';
import { PressScaleButton } from '../press-scale-button';
import { UtilityButtonHelper } from './UtilityButtonHelper';
import { useActiveComponentState } from '@lexialearning/common-ui';
import { Types } from 'common-ui';

export interface IUtilityButtonProps extends IButtonBaseProps {
  buttonType?: UtilityButtonType;
  iconType: UtilityButtonIconType;
  scale?: number;
  styleOverride?: Types.ButtonStyle;
  testId?: string;
}

export function UtilityButton(props: IUtilityButtonProps) {
  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const { buttonType, iconType, scale, styleOverride, testId, ...restProps } =
    props;
  const colorSetName = UtilityButtonHelper.getColorSetName(
    activeComponentState,
    buttonType
  )!;
  // The SVG shadows are set from the colorSet, but the button's own shadow
  // should be present regardless of colorSet if button type is not basic
  const withShadow = buttonType !== UtilityButtonType.Basic;
  const styles = UtilityButtonStyles.build(withShadow, styleOverride);

  return (
    <PressScaleButton
      testId={testId || UtilityButton.displayName}
      {...restProps}
      {...interactionHandlers}
      {...UtilityButtonHelper.getSharedProps()}
      style={styles.button}
    >
      <UtilityButtonSvg
        scale={scale}
        iconType={iconType}
        colorSetName={colorSetName}
      />
    </PressScaleButton>
  );
}
UtilityButton.displayName = 'UtilityButton';
