import { ActionType, createAction } from 'typesafe-actions';
import { IThemeState } from './theme.model';
import { ThemeActionType } from './ThemeActionType';

const action = {
  update: createAction(ThemeActionType.Update)<IThemeState>()
};

export { action as ThemeAction };
export type ThemeActionUpdate = ActionType<typeof action.update>;
export type ThemeActions = ActionType<typeof action>;
