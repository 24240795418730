import { ofType, StateObservable } from 'redux-observable';
import { AppShellActionNotifyNavigationHistoryUpdate } from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';
import { Observable } from 'rxjs';
import { filter, ignoreElements, map } from 'rxjs/operators';
import {
  RouterService,
  RoutePath,
  EducatorTabName,
  LoginTabName
} from 'router-service';
import { matchRoutes } from 'react-router-dom';
import * as packageJson from '../../../../../package.json';
import { SystemInfo } from 'utils';
import { AgnosticRouteMatch } from '@remix-run/router';
import { LexiaError } from '@lexialearning/utils';
import { ActSelector } from 'curriculum-services';
import { Services } from 'services';
import { LexiaErrorSeverity } from '@lexialearning/main-model';

export function updatePageTitleEpic(
  action$: Observable<AppShellActionNotifyNavigationHistoryUpdate>,
  state$: StateObservable<unknown>
): Observable<void> {
  return action$.pipe(
    ofType(AppShellActionType.NotifyNavigationHistoryUpdate),
    filter(() => !SystemInfo.isNative && !!document),
    map(() => {
      document.title = `${getPageTitleByRoute(state$.value)} - ${
        packageJson.lobo.indexHTML.title
      } `;
    }),
    ignoreElements()
  );
}
updatePageTitleEpic.displayName = 'updatePageTitleEpic';

function getPageTitleByRoute(state: unknown): string {
  const routeMatch = getRouteMatch();
  const { params, route } = routeMatch || {};

  if (route?.path === RoutePath.Rounds) {
    return ActSelector.getDescriptiveTitle(state);
  }

  const selectedTab = params?.selectedTab;

  switch (route?.path) {
    case RoutePath.Acts:
      return 'Encounter Showcase';
    case RoutePath.AvatarEditor:
      return 'Avatar Editor';
    case RoutePath.Calibration:
      return 'Warm Up';
    case RoutePath.DeviceSetup:
      return 'Device Setup';
    case RoutePath.Educator:
    case RoutePath.EducatorTab:
      return getEducatorPageTitle(selectedTab);
    case RoutePath.GettingStarted:
      return 'Getting Started';
    case RoutePath.Home:
    case RoutePath.LevelIntroduction:
      return 'Home';
    case RoutePath.Introduction:
      return 'Welcome';
    case RoutePath.Root:
    case RoutePath.Login:
    case RoutePath.LoginSsoInteraction:
    case RoutePath.LoginTab:
      return getLoginPageTitle(selectedTab);
    case RoutePath.Levels:
      return 'Character Select';
    case RoutePath.PlacementComplete:
    case RoutePath.PlacementRounds:
    case RoutePath.Onboarding:
    case RoutePath.OnboardingOrientation:
    case RoutePath.OnboardingRounds:
      return 'Auto Placement';
    case RoutePath.EncounterComplete:
    case RoutePath.LevelComplete:
    case RoutePath.ProgramComplete:
    case RoutePath.UnitComplete:
      return 'Congratulations!';
    default:
      void Services.logger!.logError(
        new LexiaError(
          'Invalid route',
          updatePageTitleEpic.displayName,
          UpdatePageTitleEpicError.InvalidRoute
        )
          .withContext({ activeRoute: RouterService.activeRoute })
          .withSeverity(LexiaErrorSeverity.Warning)
      );

      return 'Unknown';
  }
}

function getRouteMatch(): AgnosticRouteMatch | undefined {
  const routes = matchRoutes(
    Object.values(RoutePath).map(path => ({ path })),
    RouterService.activeRoute
  );

  return routes?.[0];
}

const EducatorTabTitleMap = new Map<string, string>([
  [EducatorTabName.Level, 'Level Select'],
  [EducatorTabName.Overview, 'Overview']
]);

const LoginTabTitleMap = new Map<string, string>([
  [LoginTabName.Student, 'Student'],
  [LoginTabName.Educator, 'Educator']
]);

function getLoginPageTitle(selectedTab?: string) {
  const pageTitle = 'Login';
  const tabTitle = getTabTitle(
    selectedTab || LoginTabName.Student,
    LoginTabTitleMap
  );

  return tabTitle ? `${tabTitle} ${pageTitle}` : pageTitle;
}

function getEducatorPageTitle(selectedTab?: string) {
  const pageTitle = 'Educator';
  const tabTitle = getTabTitle(
    selectedTab || EducatorTabName.Overview,
    EducatorTabTitleMap
  );

  return tabTitle ? `${pageTitle} ${tabTitle}` : pageTitle;
}

function getTabTitle(selectedTab: string, tabTitleMap: Map<string, string>) {
  const tabTitle = tabTitleMap.get(selectedTab);

  if (!tabTitle) {
    void Services.logger!.logError(
      new LexiaError(
        'Invalid selectedTab param',
        updatePageTitleEpic.displayName,
        UpdatePageTitleEpicError.InvalidTabName
      )
        .withContext({ selectedTab })
        .withSeverity(LexiaErrorSeverity.Warning)
    );
  }

  return tabTitle;
}

export enum UpdatePageTitleEpicError {
  InvalidRoute = 'InvalidRoute',
  InvalidTabName = 'InvalidTabName'
}

export const UpdatePageTitleEpicPrivates = {
  getPageTitleByRoute
};
