import { DropTargetConnector, DropTargetMonitor } from 'react-dnd';
import { Sfx } from 'audio/sfx';
import {
  IDropTargetCollectedProps,
  IDropTargetCombinedProps,
  IDropTargetProps,
  IDropTargetSpec
} from './drop-target.model';

export class DropTargetHelper {
  public static collect(
    connect: DropTargetConnector,
    monitor: DropTargetMonitor
  ): IDropTargetCollectedProps {
    const isOver = monitor.isOver();

    return {
      canDrop: monitor.canDrop(),
      connectDropTarget: connect.dropTarget(),
      isOver,
      srcIdx: isOver ? monitor.getItem<any>().index : undefined
    };
  }

  public static createSpec(spec?: Partial<IDropTargetSpec>): IDropTargetSpec {
    const useSfx =
      spec === undefined || spec.useSfx === undefined ? true : spec.useSfx;

    return {
      drop,
      hoverBegin: createBeginHoverHandler(useSfx),
      ...spec
    };
  }
}

function drop(props: IDropTargetProps, monitor: DropTargetMonitor) {
  if (props.onDrop) {
    const item = monitor.getItem<any>();
    props.onDrop(props.index, item.index, item);
  }
}

/**
 * Called when a drag source first hovers over a drop target.
 * We use this instead of the standard hover callback because the latter is
 * repeatedly called while hovering whereas this one is called only once.
 * Note this one is called from DndDropTarget.hoc componentDidUpdate, which is
 * after the standard hover and therefore gets the collected props.
 */
function createBeginHoverHandler(useSfx: boolean) {
  return (props: IDropTargetCombinedProps): void => {
    if (useSfx && props.canDrop) {
      props.playSfx(Sfx.DropZone);
    }

    if (props.onHover) {
      props.onHover(props.index, props.srcIdx!);
    }
  };
}
