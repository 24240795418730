import { Align, Justify } from 'common-styles';
import {
  IPageAnimatedValues,
  IPageAnimations,
  PageAnimatedStyles
} from 'shared-components/Page.animated-styles';

export class ActPageAnimatedStyles {
  private readonly styles: any = {
    container: {
      alignItems: Align.Center,
      flex: 1,
      justifyContent: Justify.Center
    }
  };

  private readonly animatedValues: IPageAnimatedValues;

  private readonly animations: IPageAnimations;

  constructor() {
    this.animatedValues = PageAnimatedStyles.createEntryExitAnimatedValues();
    this.animations = PageAnimatedStyles.createEntryExitAnimations(
      this.animatedValues
    );
    this.styles.container.opacity = this.animatedValues.opacity;
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
