import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { TransitionActionType } from '../transition.model';
import { IntroductionToCalibrationTransitionScreenplayFactory } from './IntroductionToCalibrationTransitionScreenplayFactory';
import { EMPTY, Observable, of } from 'rxjs';
import { PreparedScenes } from 'services/storm-lobo';
import { ProfileSelector } from 'services/profile';
import { RouteBuilder, RouterService } from 'router-service';
import { StormService } from 'storm';
import { TransitionActionIntroductionToNext } from '../Transition.action';
import { mergeMap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { CommonUiSelector } from 'common-ui';
import { IntroductionToAvatarEditorTransitionScreenplayFactory } from './IntroductionToAvatarEditorTransitionScreenplayFactory';

export interface IIntroductionToNextTransitionEpicDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function introductionToNextTransitionEpic(
  action$: Observable<TransitionActionIntroductionToNext>,
  state$: StateObservable<unknown>,
  deps: IIntroductionToNextTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.IntroductionToNext),
    mergeMap(() => {
      const state = state$.value;

      // Route to Educator
      const isStudent = ProfileSelector.isStudent(state);
      const shouldRouteToEducator = !isStudent;
      if (shouldRouteToEducator) {
        RouterService.history.replace(RouteBuilder.educator());
        deps.preparedScenes.introduction?.hide();

        return EMPTY;
      }

      // Route to Avatar Editor
      const hasCreatedAvatar = ProfileSelector.hasCreatedAvatar(state);
      const shouldRouteToAvatarEditor = !hasCreatedAvatar;
      if (shouldRouteToAvatarEditor) {
        const screenplay =
          IntroductionToAvatarEditorTransitionScreenplayFactory.create()
            .screenplay;

        return of(ScreenplayAction.play({ screenplay }));
      }

      // Route to Getting Started videos
      const hasViewedOnboardingVideo =
        CommonUiSelector.hasViewedOnboardingVideo(state);
      if (!hasViewedOnboardingVideo) {
        RouterService.history.replace(RouteBuilder.gettingStarted());

        return EMPTY;
      }

      // Route to Calibration
      const screenplay =
        IntroductionToCalibrationTransitionScreenplayFactory.createFor({
          preparedScenes: deps.preparedScenes,
          state$,
          stormService: deps.stormService
        }).screenplay;

      return of(ScreenplayAction.play({ screenplay }));
    })
  );
}
introductionToNextTransitionEpic.displayName =
  'introductionToNextTransitionEpic';
