import { debounce } from 'lodash';
import * as React from 'react';
import { compose } from 'redux';
import { InteractionState } from 'common-styles';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { Types } from '../../../types';
import { withGlobalDisabled } from '../../withGlobalDisabled.hoc';
import {
  GlossyButton,
  GlossyButtonIconType,
  GlossyButtonType
} from '../glossy-button';
import {
  IUserControlButtonStyleOverride,
  UserControlButton
} from '../user-control-button';

export enum MicButtonType {
  DropShadow = 'DropShadow',
  UserControl = 'UserControl'
}

export interface IMicButtonProps extends ISfxProps {
  disabled?: boolean;
  interactionState: InteractionState;
  type?: MicButtonType;
  styleOverride?: IUserControlButtonStyleOverride;
  immediateCallout?: boolean;
  registerAnimation?(animation: Types.Animated.CompositeAnimation): () => void;
  onPress(): void;
}

export const DISABLED_STATES = [
  InteractionState.Disabled,
  InteractionState.Correct,
  InteractionState.Incorrect,
  InteractionState.Inconclusive
];

export class MicButtonComponent extends React.PureComponent<IMicButtonProps> {
  public static readonly displayName = 'MicButton';

  private readonly handlePress: () => void;

  private get isListening() {
    return [InteractionState.Highlighted, InteractionState.Selected].includes(
      this.props.interactionState
    );
  }

  constructor(props: IMicButtonProps) {
    super(props);

    this.handlePress = debounce(this.props.onPress, 750, {
      leading: true,
      trailing: false
    });
  }

  public componentDidUpdate(prevProps: IMicButtonProps) {
    const wasDisabled = this.computeDisabled(prevProps);
    const becameEnabled = wasDisabled && !this.isDisabled;

    if (becameEnabled && !this.isListening) {
      this.props.playSfx(Sfx.MicAvailable);
    }
  }

  private get isDisabled() {
    return this.computeDisabled(this.props);
  }

  private computeDisabled(props: IMicButtonProps) {
    return props.disabled || DISABLED_STATES.includes(props.interactionState);
  }

  public render() {
    const {
      immediateCallout,
      interactionState,
      styleOverride,
      type = MicButtonType.DropShadow,
      registerAnimation
    } = this.props;

    const buttonProps = {
      disabled: this.isDisabled,
      icon: GlossyButtonIconType.Mic,
      immediateCallout,
      interactionState,
      onPress: this.handlePress,
      registerAnimation,
      styleOverride,
      testId: MicButtonComponent.displayName,
      withCallout: !this.isListening
    };

    return type === MicButtonType.UserControl ? (
      <UserControlButton {...buttonProps} />
    ) : (
      <GlossyButton
        {...buttonProps}
        buttonType={GlossyButtonType.RoundWithDropShadow}
      />
    );
  }
}

export const MicButton = compose(
  withSfx,
  withGlobalDisabled
)(MicButtonComponent);
