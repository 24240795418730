import { sample } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { CommonUiSelector } from 'common-ui';
import { PreparedScenes, StormAssets } from 'services/storm-lobo';
import { StormService } from 'storm';
import {
  UnitCompleteSceneActionType,
  UnitCompleteScenePrepareAction
} from '../redux';
import { UnitCompleteScene } from '../UnitCompleteScene';
import { UnitCompleteSceneDefinitionBuilder } from '../UnitCompleteSceneDefinitionBuilder';

export interface IPrepareUnitCompleteSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}
export function prepareUnitCompleteSceneEpic(
  action$: Observable<UnitCompleteScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareUnitCompleteSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(UnitCompleteSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      preparedScenes.unitComplete = undefined;

      const availableRewardIdxs = CommonUiSelector.getAvailableUnitRewards(
        state$.value
      );
      // Get random reward index, from array of currently available reward indices
      const unitRewardIdx = sample(availableRewardIdxs)!;

      const unitCompleteScenePath = StormAssets.Unit.Rewards[unitRewardIdx];
      const unitCompleteSceneDefinition =
        UnitCompleteSceneDefinitionBuilder.create(
          unitCompleteScenePath
        ).definition;

      await stormService.initialization;
      const unitCompleteScene = await stormService.prepareScene(
        unitCompleteSceneDefinition
      );

      preparedScenes.unitComplete = new UnitCompleteScene(unitCompleteScene);
    }),
    ignoreElements()
  );
}
prepareUnitCompleteSceneEpic.displayName = 'prepareUnitCompleteSceneEpic';
