import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function QuestionMarkIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G
      data-test-id={QuestionMarkIconSvg.displayName}
      transform="translate(14 -5)"
    >
      {/* QuestionMark shadow */}
      {shadowColor && (
        <Path
          d="M32.042,57.584a6,6,0,1,1,6-6,6,6,0,0,1-6,6Zm.526-17.3a3.07,3.07,0,0,1-3.392-3.392,11.058,11.058,0,0,1,2.459-6.071,12.245,12.245,0,0,0,2.7-6.784c0-1.781-.848-3.7-2.73-3.7-2.222,0-2.425,2.12-2.544,3.392v.085c-.407,3.748-2.595,5.817-6.139,5.817A3.663,3.663,0,0,1,19,25.955c0-5.088,5.291-10.735,13.839-10.735C38.622,15.22,47,19,47,25.837c0,3.392-2.188,6.054-6.292,7.615a7.123,7.123,0,0,0-4.664,4.562C35.451,39.472,35.078,40.286,32.568,40.286Z"
          transform="translate(0.25 0.25)"
          opacity="0.28"
          fill={shadowColor}
        />
      )}
      {/* QuestionMark */}
      <Path
        d="M32.042,57.584a6,6,0,1,1,6-6,6,6,0,0,1-6,6Zm.526-17.3a3.07,3.07,0,0,1-3.392-3.392,11.058,11.058,0,0,1,2.459-6.071,12.245,12.245,0,0,0,2.7-6.784c0-1.781-.848-3.7-2.73-3.7-2.222,0-2.425,2.12-2.544,3.392v.085c-.407,3.748-2.595,5.817-6.139,5.817A3.663,3.663,0,0,1,19,25.955c0-5.088,5.291-10.735,13.839-10.735C38.622,15.22,47,19,47,25.837c0,3.392-2.188,6.054-6.292,7.615a7.123,7.123,0,0,0-4.664,4.562C35.451,39.472,35.078,40.286,32.568,40.286Z"
        fill={iconColor}
      />
    </G>
  );
}
QuestionMarkIconSvg.displayName = 'QuestionMarkIconSvg';
