import {
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { PositionChangeType, RoundContext } from 'services/curriculum-services';

export type PostFeedbackPhase =
  | TaskPhase.Exit
  | TaskPhase.Interactive
  | TaskPhase.Solution;

/**
 * Determines the phase that should follow task feedback: Interactive,
 * Solution, or Feedback
 */
export class PostFeedbackPhaseDeterminer {
  public static readonly displayName: string = 'PostFeedbackPhaseDeterminer';

  public constructor(protected readonly context: RoundContext) {}

  public determine(): PostFeedbackPhase {
    const hasImminentPosition =
      !!this.context.activityPosition.imminentPosition;
    const wasIncorrect =
      this.context.lastAttemptMaybe?.result === TaskEvaluationResult.Incorrect;

    const phase = !hasImminentPosition
      ? TaskPhase.Interactive
      : wasIncorrect && this.shouldShowSolution()
      ? TaskPhase.Solution
      : TaskPhase.Exit;

    return phase;
  }

  protected shouldShowSolution(): boolean {
    const changeType =
      this.context.activityPosition.imminentPosition?.changeType;

    return changeType !== PositionChangeType.StepDown;
  }
}
