import memoizeOne from 'memoize-one';
import { Align, Color, InteractionState } from 'common-styles';
import { KeyNavHelper } from 'common-ui';
import { ActiveComponentState, Border } from '@lexialearning/common-ui';

export class ImageChoiceStyles {
  public static readonly build = memoizeOne(
    (activeComponentState: ActiveComponentState, choicesCount: number) => ({
      button: {
        alignItems: Align.Center,
        height: 240,
        marginHorizontal: choicesCount === 5 ? 5 : 11,
        width: 188
      },
      card: {
        label: {
          text: {
            color: Color.getSetFor(InteractionState.Default).text
          }
        }
      },
      cardContainer: {
        borderRadius: 17,
        borderWidth: 4,
        ...(KeyNavHelper.isKeyNav(activeComponentState) && {
          backgroundColor: Color.getSetFor(activeComponentState).background,
          borderColor: Color.getSetFor(activeComponentState).border,
          borderStyle: Border.Solid,
          borderWidth: 5,
          marginVertical: -1
        })
      },
      slotTab: { height: 52, width: 154 }
    })
  );
}
