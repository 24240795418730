import { G, Path } from '@lexialearning/common-ui';
import {
  UtilityButtonColors,
  UtilityButtonIconType
} from './utility-button-model';

interface IUtilityButtonIconProps {
  iconColor: string;
  withShadow: boolean;
}

export class UtilityButtonIcons {
  public static readonly displayName = 'UtilityButtonIcons';

  private static readonly AppleIconSvg = (props: IUtilityButtonIconProps) => (
    <G transform="translate(9 7)">
      {props.withShadow /* Apple icon shadow */ && (
        <G fill={UtilityButtonColors.shadow} transform="translate(1 1)">
          <Path d="M14.97 26.976h-.3v-8.592h-.006a10.226 10.226 0 0 1-2.384.33 10.731 10.731 0 0 1-2.432-.332v8.522h-.117c-.5 0-.98-.017-1.455-.052a5.114 5.114 0 0 1-2.84-1.247 11.662 11.662 0 0 1-2.518-2.958 16.789 16.789 0 0 1-1.784-4.028A14.933 14.933 0 0 1 .5 14.153a7.591 7.591 0 0 1 2.286-5.854 6.559 6.559 0 0 1 1.97-1.169A9.712 9.712 0 0 1 6.6 6.636a7.715 7.715 0 0 1 .911-.054 7.86 7.86 0 0 1 3.513.835l.018.006a4.178 4.178 0 0 1 .692-1.777 5.529 5.529 0 0 1 2.485-1.927c.416.257.506.318.7.452l.044.03c.095.064.213.145.393.264-1.913.776-1.948 2.014-1.641 2.917a7.836 7.836 0 0 1 3.421-.79 7.92 7.92 0 0 1 .908.053 7.806 7.806 0 0 1 3.833 1.692c1.449 1.282 2.208 3.249 2.255 5.848a16.181 16.181 0 0 1-2.264 8.407 11.164 11.164 0 0 1-2.5 2.95 5.866 5.866 0 0 1-3.011 1.344 10.4 10.4 0 0 1-1.387.09ZM12.3 11.044a2.814 2.814 0 1 0 2.813 2.814 2.818 2.818 0 0 0-2.813-2.814Z" />
          <Path d="M9.292 1.615C7.267.417 5.586 1.085 3.912.483A5.306 5.306 0 0 1 5.401 2.27c.661 1.212.15 1.932 1.39 2.823a6.5 6.5 0 0 0 3.57.743c.12.245.955-3.021-1.07-4.221Z" />
        </G>
      )}
      {/* Apple icon */}
      <G fill={props.iconColor}>
        <Path d="M14.97 26.976h-.3v-8.592h-.006a10.226 10.226 0 0 1-2.384.33 10.731 10.731 0 0 1-2.432-.332v8.522h-.117c-.5 0-.98-.017-1.455-.052a5.114 5.114 0 0 1-2.84-1.247 11.662 11.662 0 0 1-2.518-2.958 16.789 16.789 0 0 1-1.784-4.028A14.933 14.933 0 0 1 .5 14.153a7.591 7.591 0 0 1 2.286-5.854 6.559 6.559 0 0 1 1.97-1.169A9.712 9.712 0 0 1 6.6 6.636a7.715 7.715 0 0 1 .911-.054 7.86 7.86 0 0 1 3.513.835l.018.006a4.178 4.178 0 0 1 .692-1.777 5.529 5.529 0 0 1 2.485-1.927c.416.257.506.318.7.452l.044.03c.095.064.213.145.393.264-1.913.776-1.948 2.014-1.641 2.917a7.836 7.836 0 0 1 3.421-.79 7.92 7.92 0 0 1 .908.053 7.806 7.806 0 0 1 3.833 1.692c1.449 1.282 2.208 3.249 2.255 5.848a16.181 16.181 0 0 1-2.264 8.407 11.164 11.164 0 0 1-2.5 2.95 5.866 5.866 0 0 1-3.011 1.344 10.4 10.4 0 0 1-1.387.09ZM12.3 11.044a2.814 2.814 0 1 0 2.813 2.814 2.818 2.818 0 0 0-2.813-2.814Z" />
        <Path d="M9.292 1.615C7.267.417 5.586 1.085 3.912.483A5.306 5.306 0 0 1 5.401 2.27c.661 1.212.15 1.932 1.39 2.823a6.5 6.5 0 0 0 3.57.743c.12.245.955-3.021-1.07-4.221Z" />
      </G>
    </G>
  );

  private static readonly BackArrowIconSvg = (
    props: IUtilityButtonIconProps
  ) => (
    <>
      {props.withShadow /* Back arrow icon shadow */ && (
        <Path
          transform="translate(1 1)"
          fill={UtilityButtonColors.shadow}
          d="M11.87,19.51,25.07,9a2.57,2.57,0,0,1,.44-.29,2.71,2.71,0,0,1,3.64,4h0l-.18.13a3.11,3.11,0,0,1-.28.23L20,20a1.41,1.41,0,0,0,0,2.3l8.5,6.77a2.8,2.8,0,0,1,.57.46h0a2.69,2.69,0,0,1,.71,1.82,2.72,2.72,0,0,1-2.72,2.72,2.68,2.68,0,0,1-1.66-.58,1.69,1.69,0,0,1-.41-.24L11.87,22.77a2,2,0,0,1,0-3.26Z"
        />
      )}
      {/* Back arrow icon */}
      <Path
        fill={props.iconColor}
        d="M11.87,19.51,25.07,9a2.57,2.57,0,0,1,.44-.29,2.71,2.71,0,0,1,3.64,4h0l-.18.13a3.11,3.11,0,0,1-.28.23L20,20a1.41,1.41,0,0,0,0,2.3l8.5,6.77a2.8,2.8,0,0,1,.57.46h0a2.69,2.69,0,0,1,.71,1.82,2.72,2.72,0,0,1-2.72,2.72,2.68,2.68,0,0,1-1.66-.58,1.69,1.69,0,0,1-.41-.24L11.87,22.77a2,2,0,0,1,0-3.26Z"
      />
    </>
  );

  private static readonly GearIconSvg = (props: IUtilityButtonIconProps) => (
    <>
      {props.withShadow /* Gear icon shadow */ && (
        <Path
          transform="translate(1 1)"
          fill={UtilityButtonColors.shadow}
          d="M32,19.17H31a1.61,1.61,0,0,1-1.56-1.61A1.58,1.58,0,0,1,30,16.4l.61-.61a1.52,1.52,0,0,0,0-2.13l-1.44-1.44a1.5,1.5,0,0,0-1.07-.43,1.55,1.55,0,0,0-1.07.43l-.62.62a1.52,1.52,0,0,1-1.14.51c-.9,0-2.11-.77-2.11-1.56v-1a1.45,1.45,0,0,0-1.47-1.47H20.21a1.45,1.45,0,0,0-1.47,1.47v1a1.61,1.61,0,0,1-1.62,1.56A1.57,1.57,0,0,1,16,12.83l-.6-.61a1.5,1.5,0,0,0-1.07-.43,1.55,1.55,0,0,0-1.07.43l-1.44,1.44a1.5,1.5,0,0,0,0,2.13l.63.63a1.5,1.5,0,0,1,.51,1.14,1.61,1.61,0,0,1-1.56,1.61h-1a1.46,1.46,0,0,0-1.5,1.48v2a1.46,1.46,0,0,0,1.47,1.48h1a1.61,1.61,0,0,1,1.56,1.61,1.56,1.56,0,0,1-.53,1.16l-.61.61a1.5,1.5,0,0,0,0,2.13L13.22,31a1.55,1.55,0,0,0,2.14,0l.62-.62a1.52,1.52,0,0,1,1.14-.51,1.61,1.61,0,0,1,1.62,1.56v1a1.46,1.46,0,0,0,1.47,1.48h2a1.46,1.46,0,0,0,1.47-1.48v-1a1.61,1.61,0,0,1,1.62-1.56,1.58,1.58,0,0,1,1.16.52L27,31a1.55,1.55,0,0,0,2.14,0l1.44-1.44a1.52,1.52,0,0,0,0-2.13L30,26.85a1.54,1.54,0,0,1-.51-1.15A1.61,1.61,0,0,1,31,24.09h1a1.46,1.46,0,0,0,1.48-1.48v-2A1.46,1.46,0,0,0,32,19.17ZM21.19,26.25a4.62,4.62,0,1,1,3.273-1.35,4.62,4.62,0,0,1-3.273,1.35Z"
        />
      )}
      {/* Gear icon */}
      <Path
        fill={props.iconColor}
        d="M32,19.17H31a1.61,1.61,0,0,1-1.56-1.61A1.58,1.58,0,0,1,30,16.4l.61-.61a1.52,1.52,0,0,0,0-2.13l-1.44-1.44a1.5,1.5,0,0,0-1.07-.43,1.55,1.55,0,0,0-1.07.43l-.62.62a1.52,1.52,0,0,1-1.14.51c-.9,0-2.11-.77-2.11-1.56v-1a1.45,1.45,0,0,0-1.47-1.47H20.21a1.45,1.45,0,0,0-1.47,1.47v1a1.61,1.61,0,0,1-1.62,1.56A1.57,1.57,0,0,1,16,12.83l-.6-.61a1.5,1.5,0,0,0-1.07-.43,1.55,1.55,0,0,0-1.07.43l-1.44,1.44a1.5,1.5,0,0,0,0,2.13l.63.63a1.5,1.5,0,0,1,.51,1.14,1.61,1.61,0,0,1-1.56,1.61h-1a1.46,1.46,0,0,0-1.5,1.48v2a1.46,1.46,0,0,0,1.47,1.48h1a1.61,1.61,0,0,1,1.56,1.61,1.56,1.56,0,0,1-.53,1.16l-.61.61a1.5,1.5,0,0,0,0,2.13L13.22,31a1.55,1.55,0,0,0,2.14,0l.62-.62a1.52,1.52,0,0,1,1.14-.51,1.61,1.61,0,0,1,1.62,1.56v1a1.46,1.46,0,0,0,1.47,1.48h2a1.46,1.46,0,0,0,1.47-1.48v-1a1.61,1.61,0,0,1,1.62-1.56,1.58,1.58,0,0,1,1.16.52L27,31a1.55,1.55,0,0,0,2.14,0l1.44-1.44a1.52,1.52,0,0,0,0-2.13L30,26.85a1.54,1.54,0,0,1-.51-1.15A1.61,1.61,0,0,1,31,24.09h1a1.46,1.46,0,0,0,1.48-1.48v-2A1.46,1.46,0,0,0,32,19.17ZM21.19,26.25a4.62,4.62,0,1,1,3.273-1.35,4.62,4.62,0,0,1-3.273,1.35Z"
      />
    </>
  );

  private static readonly CloseIconSvg = (props: IUtilityButtonIconProps) => (
    <>
      {props.withShadow /* Close X icon shadow */ && (
        <Path
          transform="translate(1 1)"
          fill={UtilityButtonColors.shadow}
          d="M31.64,4.15a6.56,6.56,0,0,1,6.55,6.55V31.59a6.56,6.56,0,0,1-6.55,6.56H10.75a6.56,6.56,0,0,1-6.56-6.56V10.7a6.56,6.56,0,0,1,6.56-6.55H31.64m0-2H10.75A8.58,8.58,0,0,0,2.19,10.7V31.59a8.58,8.58,0,0,0,8.56,8.56H31.64a8.58,8.58,0,0,0,8.55-8.56V10.7a8.58,8.58,0,0,0-8.55-8.55Z"
        />
      )}
      {/* Close X icon */}
      <Path
        fill={props.iconColor}
        d="M25.17,21.15l6.7-7.63A3.78,3.78,0,0,0,33,11a3.17,3.17,0,0,0-1-2.3,3.28,3.28,0,0,0-2.42-.92,3.07,3.07,0,0,0-2.49,1.13l-5.93,7.24-5.6-6.86A3.61,3.61,0,0,0,14.18,8a3.88,3.88,0,0,0-1.41-.26,3.28,3.28,0,0,0-2.42.92A3.17,3.17,0,0,0,9.43,11a3.86,3.86,0,0,0,1,2.4l6.79,7.72-6.79,7.72a3.82,3.82,0,0,0-1,2.4,3.14,3.14,0,0,0,.92,2.32,3.28,3.28,0,0,0,2.42.92,3.88,3.88,0,0,0,1.41-.26A3.65,3.65,0,0,0,15.59,33l5.6-6.87,5.93,7.24a3.07,3.07,0,0,0,2.49,1.13A3.28,3.28,0,0,0,32,33.59a3.14,3.14,0,0,0,1-2.32,3.78,3.78,0,0,0-1.08-2.5Z"
      />
    </>
  );

  private static readonly ToolsIconSvg = (props: IUtilityButtonIconProps) => (
    <>
      {props.withShadow /* Tools icon shadow */ && (
        <>
          <Path
            fill={UtilityButtonColors.shadow}
            d="M10.57 28.948l11.933-11.792a5.77 5.77 0 011.506-6.433 5.938 5.938 0 016.618-.895l-3.605 3.564c-.995.984-.26 1.86.716 2.828s1.867 1.69 2.862.706l3.604-3.562a5.765 5.765 0 01-.906 6.542 5.931 5.931 0 01-6.507 1.492L14.858 33.19a3.06 3.06 0 01-4.292 0 2.975 2.975 0 010-4.243z"
          />
          <Path
            fill={UtilityButtonColors.shadow}
            d="M32.732 34.124a3.5 3.5 0 01-4.95 0l-6.01-6.01 4.95-4.95 6.01 6.01a3.5 3.5 0 010 4.95zM17.174 21.046l-5.116-5.116-.333.332a1 1 0 01-1.637-.342l-1.79-4.575a1 1 0 011.296-1.296l4.576 1.789a1 1 0 01.342 1.637l-.333.333 5.114 5.114z"
          />
        </>
      )}
      <Path
        fill={props.iconColor}
        d="M10.603 27.927l11.794-11.793a5.817 5.817 0 018.033-7.327l-3.564 3.564c-.981.982-.26 1.862.707 2.829s1.848 1.687 2.829.707l3.563-3.564a5.818 5.818 0 01-7.327 8.034L14.845 32.171a3 3 0 01-4.243-4.243z"
      />
      <Path
        fill={props.iconColor}
        d="M32.43 33.12a3.5 3.5 0 01-4.949 0l-6.01-6.01 4.95-4.95 6.01 6.01a3.5 3.5 0 010 4.95zM17.232 20.04l-5.114-5.112-.333.333a1 1 0 01-1.638-.342L8.36 10.342a1 1 0 011.295-1.295l4.575 1.788a1 1 0 01.342 1.638l-.333.333 5.114 5.113z"
      />
    </>
  );

  private static readonly LogoutIconSvg = (props: IUtilityButtonIconProps) => (
    <>
      {props.withShadow /* Logout icon shadow */ && (
        <>
          <Path
            d="M21.887 28.015a2.261 2.261 0 00-2.261 2.261v.741h-6.1V13.521h6.1v.741a2.261 2.261 0 104.521 0v-3A2.261 2.261 0 0021.887 9H11.263A2.261 2.261 0 009 11.261v22.016a2.261 2.261 0 002.261 2.261h10.626a2.261 2.261 0 002.261-2.261v-3a2.261 2.261 0 00-2.261-2.262z"
            fill={UtilityButtonColors.shadow}
          />
          <Path
            d="M35.948 20.133l-4.915-4.467a2.261 2.261 0 00-3.041 3.345l.587.533H18.437a2.261 2.261 0 00-2.261 2.257c0 .063.014.123.019.185s-.019.122-.019.185a2.261 2.261 0 002.261 2.266h10.14l-.585.533a2.26 2.26 0 003.035 3.347l4.916-4.467a2.252 2.252 0 00.727-1.859 2.254 2.254 0 00-.722-1.858z"
            fill={UtilityButtonColors.shadow}
          />
        </>
      )}
      <Path
        d="M20.887 27.015a2.261 2.261 0 00-2.261 2.261v.741h-6.1V12.521h6.1v.741a2.261 2.261 0 104.521 0v-3A2.261 2.261 0 0020.887 8H10.263A2.261 2.261 0 008 10.261v22.016a2.261 2.261 0 002.261 2.261h10.626a2.261 2.261 0 002.261-2.261v-3a2.261 2.261 0 00-2.261-2.262z"
        fill={props.iconColor}
      />
      <Path
        d="M34.948 19.133l-4.915-4.467a2.261 2.261 0 00-3.041 3.345l.587.533H17.437a2.261 2.261 0 00-2.261 2.257c0 .063.014.123.019.185s-.019.122-.019.185a2.261 2.261 0 002.261 2.266h10.14l-.585.533a2.26 2.26 0 003.035 3.347l4.916-4.467a2.252 2.252 0 00.727-1.859 2.254 2.254 0 00-.722-1.858z"
        fill={props.iconColor}
      />
    </>
  );

  public static readonly IconMap: Map<UtilityButtonIconType, any> = new Map([
    [UtilityButtonIconType.Apple, UtilityButtonIcons.AppleIconSvg],
    [UtilityButtonIconType.BackArrow, UtilityButtonIcons.BackArrowIconSvg],
    [UtilityButtonIconType.Close, UtilityButtonIcons.CloseIconSvg],
    [UtilityButtonIconType.Gear, UtilityButtonIcons.GearIconSvg],
    [UtilityButtonIconType.Logout, UtilityButtonIcons.LogoutIconSvg],
    [UtilityButtonIconType.Tools, UtilityButtonIcons.ToolsIconSvg]
  ]);
}
