import { createSelector } from '@reduxjs/toolkit';
import {
  ILexiaServiceOptions,
  ILexiaServiceRequestsBases,
  ILexiaServiceState
} from './lexia-service-redux.model';

type LexiaServiceSelectorType = (state: any) => ILexiaServiceState;

export class LexiaServiceSelector {
  public static readonly displayName = 'LexiaServiceSelector';

  public static getLexiaServiceState: LexiaServiceSelectorType;

  public static getLexiaServiceRequests: (
    state: unknown
  ) => ILexiaServiceRequestsBases;

  public static getLexiaServiceOptions: (
    state: unknown
  ) => ILexiaServiceOptions;

  public static getIsMakingRequest: (state: unknown) => boolean;

  public static createSelectors(selector: LexiaServiceSelectorType) {
    LexiaServiceSelector.getLexiaServiceState = createSelector(
      selector,
      (state: ILexiaServiceState) => state
    );

    LexiaServiceSelector.getLexiaServiceRequests = createSelector(
      LexiaServiceSelector.getLexiaServiceState,
      (state: ILexiaServiceState) => state.requestsBases
    );

    LexiaServiceSelector.getLexiaServiceOptions = createSelector(
      LexiaServiceSelector.getLexiaServiceState,
      (state: ILexiaServiceState) => state.options
    );

    LexiaServiceSelector.getIsMakingRequest = createSelector(
      LexiaServiceSelector.getLexiaServiceOptions,
      (options: ILexiaServiceOptions) => options.isMakingRequest
    );
  }
}
