import memoizeOne from 'memoize-one';
import { ZIndex } from 'common-styles';
import { Position } from '@lexialearning/common-ui';

export class UtilityBarStyles {
  public static readonly build = memoizeOne(
    (appWidth, isZIndexDisabled = false) => ({
      container: {
        position: Position.Absolute,
        top: 0,
        width: appWidth,
        // zIndex should be used only when necessary, see documentation:
        // https://jira.lexialearning.com/wiki/pages/viewpage.action?pageId=169084866
        // We need the UtilityBar to have a higher zIndex than the main content area, and
        // reordering them in the dom to achieve this was throwing off the ordering for
        // keyboard navigation. So using zIndex here makes sense.
        ...(!isZIndexDisabled && { zIndex: ZIndex.Shared })
      }
    })
  );
}
