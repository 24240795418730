import { UserRole } from '@lexialearning/main-model';
import { LexiaError } from '@lexialearning/utils';
import { UserRole as UserRoleCode } from 'lexia-service';

export enum UserRoleTransformerError {
  UnknownCode = 'UnknownCode'
}

export class UserRoleTransformer {
  public static readonly displayName = 'UserRoleTransformer';

  public static readonly CodeToNameMap =
    UserRoleTransformer.createCodeToNameMap();

  public static toName(code: UserRoleCode): UserRole {
    const role = this.CodeToNameMap.get(code);

    if (!role) {
      throw new LexiaError(
        `Cannot map unknown user role code ${code}`,
        UserRoleTransformer.displayName,
        UserRoleTransformerError.UnknownCode
      ).withContext({ code });
    }

    return role;
  }

  private static createCodeToNameMap(): Map<UserRoleCode, UserRole> {
    return new Map<UserRoleCode, UserRole>([
      [UserRoleCode.ClassAccess, UserRole.ClassAccess],
      [UserRoleCode.ClassAccessRO, UserRole.ClassAccessRO],
      [UserRoleCode.CustomerAccess, UserRole.CustomerAccess],
      [UserRoleCode.CustomerAdmin, UserRole.CustomerAdmin],
      [UserRoleCode.DeepLinkEducator, UserRole.LexiaAdmin],
      [UserRoleCode.DeepLinkStudent, UserRole.Student],
      [UserRoleCode.LexiaAdmin, UserRole.LexiaAdmin],
      [UserRoleCode.LexiaOperations, UserRole.LexiaOperations],
      [UserRoleCode.LexiaResearch, UserRole.LexiaResearch],
      [UserRoleCode.LexiaSales, UserRole.LexiaSales],
      [UserRoleCode.SchoolAccess, UserRole.SchoolAccess],
      [UserRoleCode.SchoolAdmin, UserRole.SchoolAdmin],
      [UserRoleCode.Student, UserRole.Student]
    ]);
  }
}
