import { IScreenplay } from '@lexialearning/lobo-common';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { CalibrationOutroScreenplayBuilder } from '../builders/calibration';
import {
  HomeIntroScreenplayBuilder,
  IHomeIntroScreenplayDeps
} from '../builders/home';
import { TransitionScreenplayId } from '../transition.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICalibrationToHomeTransitionScreenplayDeps
  extends IHomeIntroScreenplayDeps {}

export class CalibrationToHomeTransitionScreenplayFactory {
  public static readonly displayName =
    'CalibrationToHomeTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: ICalibrationToHomeTransitionScreenplayDeps
  ): CalibrationToHomeTransitionScreenplayFactory {
    return new CalibrationToHomeTransitionScreenplayFactory(deps);
  }

  private constructor(deps: ICalibrationToHomeTransitionScreenplayDeps) {
    this.screenplay = ScreenplayBuilder.create(
      TransitionScreenplayId.CalibrationToHome
    )
      .addScreenplay(
        CalibrationOutroScreenplayBuilder.create()
          .disableUtilityBar()
          .fadeOutReactElements()
          .playSceneOutro().screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.home());
      })
      .addScreenplay(
        HomeIntroScreenplayBuilder.createFor(deps).screenplay
      ).screenplay;
  }
}
