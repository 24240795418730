import {
  AnimatableCSSProperty,
  loboAnimated,
  LoboAnimatedValue
} from 'common-styles';
import { Types } from 'common-ui';
import { ThemeType } from 'theme';
import { IMarkerAnimations, MeterMarkerHelper } from '../../MeterMarker.helper';
import {
  MeterMarkerProgressStatus,
  MeterMarkerType
} from '../../meterMarker.model';

interface IBarAnimations extends IMarkerAnimations {
  animateIntro: Types.Animated.CompositeAnimation;
}
export class BarAnimatedStyles {
  private readonly animations: IBarAnimations;

  private readonly bgColorValue: LoboAnimatedValue;

  public static readonly AnimationTiming = 200;

  private readonly styles: {
    bar: Types.ViewStyle | { transform: { skewX: string }[] };
    barAnimated: Types.AnimatedViewStyle;
  } = {
    bar: {
      borderRadius: 3,
      flex: 1,
      height: 11,
      margin: 2,
      transform: [{ skewX: '-30deg' }]
    },
    barAnimated: {
      backgroundColor: undefined
    }
  };

  constructor(
    themeType: ThemeType,
    progress: MeterMarkerProgressStatus,
    index: number,
    isEncounterEntry?: boolean
  ) {
    /**  when isEncounterEntry is set to True, it indicates this has be initiated from the constructor rather than componentDidUpdate
     */
    this.bgColorValue = loboAnimated.createValue(
      isEncounterEntry ? MeterMarkerProgressStatus.Upcoming : progress
    );
    const bgColorInterpolation =
      MeterMarkerHelper.getMarkerBgColorInterpolation(
        MeterMarkerType.Bar,
        this.bgColorValue,
        themeType
      );
    this.styles.barAnimated.backgroundColor = bgColorInterpolation;

    this.animations = {
      animateCompleted: this.createCompletedAnimation(),
      animateIntro: this.createIntroAnimation(progress, index),
      setProgress: (_progress: MeterMarkerProgressStatus) => {
        this.bgColorValue.setValue(_progress);
      }
    };
  }

  private createCompletedAnimation() {
    return loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.bgColorValue,
        {
          // Delay by the length of the full animation duration to let the round animation
          // (using the same duration) to run first, and this to run immediately after
          delay: MeterMarkerHelper.PopDurationMs,
          duration: MeterMarkerHelper.PopDurationMs * 0.5,
          toValue: MeterMarkerProgressStatus.CompletedPop2
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.bgColorValue,
        {
          duration: MeterMarkerHelper.PopDurationMs * 0.5,
          toValue: MeterMarkerProgressStatus.Completed
        }
      )
    ]);
  }

  private createIntroAnimation(
    progress: MeterMarkerProgressStatus,
    index: number
  ) {
    // for Active & Upcoming Bars, duration is set to 1, so the RoundMeter will pop as soon as it's shown
    return loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.bgColorValue,
      {
        delay: BarAnimatedStyles.AnimationTiming * index,
        duration:
          progress === MeterMarkerProgressStatus.Completed
            ? BarAnimatedStyles.AnimationTiming
            : 1,
        toValue: progress
      }
    );
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
