import { ActionType, createAction } from 'typesafe-actions';
import { EncounterSceneActionType } from './encounter-scene-redux.model';

export const EncounterSceneAction = {
  prepare: createAction(EncounterSceneActionType.Prepare)()
};

export type EncounterScenePrepareAction = ActionType<
  typeof EncounterSceneAction.prepare
>;

export type EncounterSceneActions = ActionType<typeof EncounterSceneAction>;
