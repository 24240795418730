import { Justify } from 'common-styles';

export class ConfigEditorStyles {
  private static readonly styles = {
    inspectorContainer: {
      justifyContent: Justify.Start,
      maxHeight: 400,
      paddingHorizontal: 20
    }
  };

  public static get() {
    return this.styles;
  }
}
