import { StormAssets } from 'services/storm-lobo';
import { SceneName, TaskDemoType } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';

export class TaskDemoSceneDefinitionBuilder {
  public static create(taskType: TaskDemoType): TaskDemoSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.TaskDemo,
      StormAssets.TaskDemo[taskType]
    );

    return new TaskDemoSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}
}
