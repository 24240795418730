import { flatten } from 'lodash';
import { LxStormEngineControl, LxStormNode, StormService } from '../service';
import { Scene } from './Scene';
import { SceneElement } from './SceneElement';
import {
  ISceneDefinition,
  ISceneElementDefinition
} from './storm-scenes.model';

/**
 * Create a scene and its elements from a given scene definition.
 * Cache storm nodes and flags for loaded animations.
 *
 * Cached nodes are owned by a single Scene object and can only be reused after
 * being releases by the owning Scene object.
 */
export class SceneFactory {
  public static readonly displayName = 'SceneFactory';

  public constructor(
    private readonly stormService: StormService,
    private readonly ctrl: LxStormEngineControl
  ) {}

  public async create(sceneDefinition: ISceneDefinition): Promise<Scene> {
    await this.preload(sceneDefinition);

    const sceneNode = await this.createNode(sceneDefinition);
    const sceneElements: SceneElement[] = await Promise.all(
      sceneDefinition.elements.map(async el => this.addElement(el, sceneNode))
    );

    const rootAnimations = sceneDefinition.animations;
    const materialDefinitions = sceneDefinition.materials;

    return new Scene(
      sceneDefinition.id,
      sceneNode,
      sceneElements,
      rootAnimations,
      materialDefinitions
    );
  }

  private async preload(sceneDefinition: ISceneDefinition): Promise<void> {
    const pathsSet = [
      sceneDefinition.path,
      ...flatten(
        sceneDefinition.elements.map(e => [
          e.path,
          ...e.animations.map(a => a.path),
          ...flatten(e.materials.map(m => m.texturePaths))
        ])
      ),
      ...flatten(sceneDefinition.materials.map(m => m.texturePaths))
    ]
      .filter(p => p)
      .reduce((acc, p) => acc.add(p), new Set<string>());
    const paths = Array.from(pathsSet);

    await Promise.all(paths.map(async p => this.stormService.preload(p)));
  }

  private async addElement(
    elementDefinition: ISceneElementDefinition,
    sceneNode: LxStormNode
  ): Promise<SceneElement> {
    const node = await this.createNode(elementDefinition);
    const element = new SceneElement(
      elementDefinition.placeholderName,
      node,
      [],
      elementDefinition.animations,
      elementDefinition.materials
    );

    if (!elementDefinition.lazyLoad) {
      sceneNode.attachChild(element.placeholderName, node);
    }

    return element;
  }

  private async createNode(
    definition: ISceneDefinition | ISceneElementDefinition
  ): Promise<LxStormNode> {
    const node = await this.ctrl.createNode(definition);

    definition.animations.forEach(a => {
      node.loadAnimations(a.path);
    });

    return node;
  }
}
