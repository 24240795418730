import { IViewProps, View } from '../../view/View';
import { ColumnStyles } from './Column.styles';

export function Column(props: IViewProps) {
  const { children, style, ...viewProps } = props;

  return (
    <View
      testId={Column.displayName}
      {...viewProps}
      style={ColumnStyles.build(props.style)}
    >
      {children}
    </View>
  );
}
Column.displayName = 'Column';
