import * as React from 'react';
import { connect } from 'react-redux';
import {
  AnimatedView,
  Column,
  Row,
  Text,
  TextButton,
  TextButtonType
} from 'common-ui';
import { ScreenplayAction, ScreenplaySelector } from 'screenplay';
import { IPlaybackActionInfo } from 'screenplay/redux/screenplay-redux.model';
import { Services } from 'services';
import { ScreenplayTesterAction } from './ScreenplayTester.action';
import { ScreenplayTesterAnimatedStyles } from './ScreenplayTester.animated-styles';

export enum ScreenplayTesterReactAnimationName {
  Item1 = 'Item1',
  Item2 = 'Item2'
}
interface IScreenplayTesterProps {
  currentActionInfo: IPlaybackActionInfo;
  hasActiveScreenplay: boolean;
  isScreenplayPlaying: boolean;
  playTestScreenplay(): void;
  cancelScreenplay(): void;
  pauseScreenplay(): void;
  resumeScreenplay(): void;
}
export class ScreenplayTesterComponent extends React.PureComponent<IScreenplayTesterProps> {
  public static readonly displayName = 'ScreenplayTester';

  private readonly animatedStyles: ScreenplayTesterAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: IScreenplayTesterProps) {
    super(props);

    this.animatedStyles = new ScreenplayTesterAnimatedStyles();
    this.unregisterAnimations = this.registerAnimations();

    this.handleTogglePauseResumeScreenplay =
      this.handleTogglePauseResumeScreenplay.bind(this);
    this.handleTogglePlayCancelScreenplay =
      this.handleTogglePlayCancelScreenplay.bind(this);
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { item1, item2 } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      { name: ScreenplayTesterReactAnimationName.Item1, animation: item1 },
      { name: ScreenplayTesterReactAnimationName.Item2, animation: item2 }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  private handleTogglePlayCancelScreenplay() {
    const { isScreenplayPlaying, playTestScreenplay, cancelScreenplay } =
      this.props;
    if (isScreenplayPlaying) {
      cancelScreenplay();
    } else {
      playTestScreenplay();
    }
  }

  private handleTogglePauseResumeScreenplay() {
    const {
      hasActiveScreenplay,
      isScreenplayPlaying,
      pauseScreenplay,
      resumeScreenplay
    } = this.props;
    if (isScreenplayPlaying) {
      pauseScreenplay();
    } else if (hasActiveScreenplay) {
      resumeScreenplay();
    }
  }

  public render() {
    const { hasActiveScreenplay, isScreenplayPlaying, currentActionInfo } =
      this.props;
    const styles = this.animatedStyles.get();
    const isPaused = hasActiveScreenplay && !isScreenplayPlaying;

    return (
      <Column style={styles.container}>
        <Row>
          <TextButton
            text={hasActiveScreenplay ? 'Cancel' : 'Play'}
            onPress={this.handleTogglePlayCancelScreenplay}
            buttonType={TextButtonType.Primary}
          />
          <TextButton
            text={isPaused ? 'Resume' : 'Pause'}
            onPress={this.handleTogglePauseResumeScreenplay}
            disabled={!hasActiveScreenplay}
          />
        </Row>
        <Row>
          <Column style={styles.info}>
            <Text>Is screenplay playing: {isScreenplayPlaying.toString()}</Text>
            <Text>
              Last played action:{' '}
              {currentActionInfo.index > -1
                ? `${currentActionInfo.index} - ${currentActionInfo.type}`
                : ''}
            </Text>
          </Column>
        </Row>
        <Row style={styles.columnContainer}>
          <Column>
            <AnimatedView
              style={styles.item1static}
              animatedStyle={styles.item1animated}
            />
          </Column>
          <Column>
            <AnimatedView
              style={styles.item2static}
              animatedStyle={styles.item2animated}
            />
          </Column>
        </Row>
      </Column>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    currentActionInfo: ScreenplaySelector.getCurrentActionInfo(state),
    hasActiveScreenplay: !!ScreenplaySelector.getScreenplayMaybe(state),
    isScreenplayPlaying: ScreenplaySelector.isPlaying(state) //
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  cancelScreenplay: () => ScreenplayAction.cancel(),
  pauseScreenplay: () => ScreenplayAction.pause(),
  playTestScreenplay: () => ScreenplayTesterAction.play(),
  resumeScreenplay: () => ScreenplayAction.resume()
};

export const ScreenplayTester = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenplayTesterComponent);
