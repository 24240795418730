import { Position } from '@lexialearning/common-ui';
import { Align, Color, Justify, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { ScreenplayTestHelper } from './ScreenplayTest.helper';

export class ScreenplayTesterAnimatedStyles {
  private readonly styles: any;

  private readonly animations: {
    item1: Types.Animated.CompositeAnimation;
    item2: Types.Animated.CompositeAnimation;
  };

  constructor() {
    const animatedValues = {
      item1: {
        scale: loboAnimated.createValue(1),
        translateY: loboAnimated.createValue(0)
      },
      item2: {
        scaleY: loboAnimated.createValue(1)
      }
    };

    this.animations = {
      item1: ScreenplayTestHelper.createItem1Animation(
        animatedValues.item1.scale,
        animatedValues.item1.translateY
      ),
      item2: ScreenplayTestHelper.createItem2Animation(
        animatedValues.item2.scaleY
      )
    };

    const itemSize = 50;

    this.styles = {
      columnContainer: {
        flex: 1,
        justifyContent: Justify.SpaceBetween,
        width: 200
      },
      container: {
        bottom: 0,
        left: 0,
        position: Position.Absolute,
        right: 0,
        top: 0
      },
      info: { alignSelf: Align.Start },
      item1animated: {
        transform: [
          { scale: animatedValues.item1.scale },
          { translateY: animatedValues.item1.translateY }
        ]
      },
      item1static: {
        backgroundColor: Color.Blue,
        borderRadius: itemSize / 2,
        height: itemSize,
        width: itemSize
      },
      item2animated: {
        transform: [{ scaleY: animatedValues.item2.scaleY }]
      },
      item2static: {
        backgroundColor: Color.Black,
        height: itemSize,
        width: itemSize
      }
    };
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
