import resume_button_backPng from './images/resume_button_back.png';
import { AuthAction } from 'services/auth';
import { CloudGabberArmsSvg } from './images/cloud_gabber_arms.lx-svg';
import { Sfx } from 'audio/sfx';
import { LoginSsoInteractionPageStyles } from './LoginSsoInteractionPage.styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
  GlossyButton,
  GlossyButtonIconType,
  View,
  UserControlButtonSize,
  GlossyButtonType,
  BackgroundImage
} from 'common-ui';
import { BackgroundGradientSvg } from 'feature-areas/shell';
import { useSfx } from 'audio/sfx/useSfx';

export function LoginSsoInteractionPage() {
  const dispatch = useDispatch();
  const playSfx = useSfx();

  const [hasBeenClicked, setHasBeenClicked] = useState<boolean>(false);

  function handleNavToIntroduction() {
    setHasBeenClicked(true);
    playSfx(Sfx.Neutral);
    dispatch(AuthAction.interacted());
  }

  const styles = LoginSsoInteractionPageStyles.get();

  return (
    <View style={styles.container} testId={LoginSsoInteractionPage.displayName}>
      <BackgroundGradientSvg id={LoginSsoInteractionPage.displayName} />
      <BackgroundImage
        style={styles.absolutePosition}
        source={resume_button_backPng}
      />
      <GlossyButton
        accessibilityLabel="Select to enter Lexia English."
        buttonType={GlossyButtonType.RoundWithDropShadow}
        disabled={hasBeenClicked}
        icon={GlossyButtonIconType.Play}
        onPress={handleNavToIntroduction}
        size={UserControlButtonSize.Large}
        styleOverride={styles.button}
      >
        <CloudGabberArmsSvg style={styles.arms} />
      </GlossyButton>
      {/* </BackgroundImage> */}
    </View>
  );
}
LoginSsoInteractionPage.displayName = 'LoginSsoInteractionPage';
