import {
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { ITextOrdering } from '@lexialearning/lobo-common/tasks/text-ordering';
import * as React from 'react';
import { Column, SpotlightArea, TextPrompt } from 'common-ui';
import { ITaskProps } from 'task-components/core';
import { ISegment, OrderingContainer } from 'task-components/shared';
import { TextOrderingContent } from './text-ordering-content';
import { TextOrderingStyles } from './TextOrdering.styles';

export type ITextOrderingProps = ITaskProps<ITextOrdering>;

export class TextOrdering extends React.PureComponent<ITextOrderingProps> {
  public static readonly displayName = 'TextOrdering';

  constructor(props: ITextOrderingProps) {
    super(props);

    this.shouldShowCorrectFeedback = this.shouldShowCorrectFeedback.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  private shouldShowCorrectFeedback() {
    const { evaluationResult, phase } = this.props;

    return (
      evaluationResult === TaskEvaluationResult.Correct &&
      phase === TaskPhase.Feedback
    );
  }

  private renderContent(
    segments: ISegment[],
    moveSegment: (targetIndex: number, segmentIndex: number) => void
  ) {
    return (
      <TextOrderingContent
        segments={segments}
        moveSegment={moveSegment}
        shouldShowCorrectFeedback={this.shouldShowCorrectFeedback()}
      />
    );
  }

  public render() {
    const { taskContent } = this.props;
    const {
      spotlights,
      instructionalImage,
      segments: orderedSegments,
      textPrompt
    } = taskContent;
    const styles = TextOrderingStyles.build(
      !!(spotlights.length || instructionalImage)
    );

    return (
      <Column style={styles.taskContainer}>
        <SpotlightArea
          spotlights={spotlights}
          instructionalImage={instructionalImage}
        />
        {textPrompt && <TextPrompt>{textPrompt.text}</TextPrompt>}
        <OrderingContainer
          orderedSegments={orderedSegments}
          renderContent={this.renderContent}
          extraData={this.shouldShowCorrectFeedback()}
        />
      </Column>
    );
  }
}
