import { ScrollView as RxScrollView } from '@lexialearning/reactxp';
import * as React from 'react';
import { compose } from 'redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { SystemInfo } from 'utils';
import { View } from '../view';
import { ScrollView } from '../view/ScrollView';
import { withGlobalDisabled } from '../withGlobalDisabled.hoc';
import { ArrowButton } from './arrow-button';
import { ArrowButtonType } from './arrow-button/ArrowButton.lx-svg';
import { CarouselStyles, ICarouselStyleOverride } from './Carousel.styles';
import { AutoFocusView } from '../auto-focus-view';
import { Slide } from './slide';
import {
  AccessibilityRole,
  KeyboardCode
} from '@lexialearning/reactxp/dist/common/Types';

export interface ICarouselProps extends ISfxProps {
  accessibilityLabel?: string;
  disabled?: boolean;
  currentSlideIdx: number;
  onPressNext(): void;
  onPressPrevious(): void;
  renderBottomControl?(): React.ReactElement;
  renderRightControl?(): React.ReactElement;
  scrollEnabled?: boolean;
  disabledLeftCtrl: boolean;
  disabledRightCtrl: boolean;
  slideWidth: number;
  slides: React.ReactElement[];
  styleOverrides: ICarouselStyleOverride;
  updateCurrentSlide(idx: number): void;
}

export enum NavigationType {
  Arrow = 'Arrow',
  Drag = 'Drag',
  None = 'None'
}

interface ICarouselState {
  navigationType: NavigationType;
}

export class CarouselComponent extends React.PureComponent<
  ICarouselProps,
  ICarouselState
> {
  public static readonly displayName = 'Carousel';

  private readonly scrollViewRef = React.createRef<RxScrollView>() as any;

  private lastPlayedSfx = '';

  constructor(props: ICarouselProps) {
    super(props);
    this.state = {
      navigationType: NavigationType.None
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePressArrow = this.handlePressArrow.bind(this);
    this.playScrollSound = this.playScrollSound.bind(this);
    this.handleBeginDrag = this.handleBeginDrag.bind(this);
  }

  public componentDidMount() {
    const { currentSlideIdx, slideWidth } = this.props;

    if (window?.addEventListener) {
      window.addEventListener('keydown', this.handleKeyDown);
    }

    if (currentSlideIdx !== 0) {
      const newX = currentSlideIdx * slideWidth;
      // Two setScrollLeft calls needed because iPad requires it be called from
      // the setTimeout, or it never updates to the correct slide,
      // but if it is only called from there, on web the first slide shows up briefly
      // before updating, which is incorrect
      this.setScrollLeft(newX);
      setTimeout(() => {
        this.setScrollLeft(newX);
      }, 0);
    }
  }

  public componentDidUpdate(prevProps: ICarouselProps) {
    const { currentSlideIdx, slideWidth } = this.props;
    if (
      prevProps.currentSlideIdx !== currentSlideIdx &&
      this.state.navigationType === NavigationType.Arrow
    ) {
      const newX = currentSlideIdx * slideWidth;
      this.setScrollLeft(newX, true);
    }
  }

  public componentWillUnmount() {
    if (window?.removeEventListener) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  private setScrollLeft(scrollLeft: number, animate = false) {
    this.scrollViewRef.current?.setScrollLeft(scrollLeft, animate);
  }

  /**
   * The default behavior is to allow scrolling with the keyboard arrows
   * which we don't want, as it conflicts with screen reader keyboard navigation
   */
  private handleKeyDown(e: KeyboardEvent) {
    if (
      [
        KeyboardCode.ArrowLeft as string,
        KeyboardCode.ArrowRight as string
      ].includes(e.code)
    ) {
      e.preventDefault();
    }
  }

  private playScrollSound(slidePercentVisible: number) {
    const { playSfx } = this.props;

    if (slidePercentVisible <= 0) {
      this.lastPlayedSfx = '';
    }
    if (
      slidePercentVisible >= 20 &&
      slidePercentVisible < 40 &&
      this.lastPlayedSfx !== Sfx.Carousel20
    ) {
      playSfx(Sfx.Carousel20);
      this.lastPlayedSfx = Sfx.Carousel20;

      return;
    }
    if (
      slidePercentVisible >= 40 &&
      slidePercentVisible < 60 &&
      this.lastPlayedSfx !== Sfx.Carousel40
    ) {
      playSfx(Sfx.Carousel40);
      this.lastPlayedSfx = Sfx.Carousel40;

      return;
    }
    if (
      slidePercentVisible >= 60 &&
      slidePercentVisible < 80 &&
      this.lastPlayedSfx !== Sfx.Carousel60
    ) {
      playSfx(Sfx.Carousel60);
      this.lastPlayedSfx = Sfx.Carousel60;

      return;
    }
    if (
      slidePercentVisible >= 80 &&
      slidePercentVisible < 100 &&
      this.lastPlayedSfx !== Sfx.Carousel80
    ) {
      playSfx(Sfx.Carousel80);
      this.lastPlayedSfx = Sfx.Carousel80;
    }
  }

  private handleScroll(_newScrollTop: number, newScrollLeft: number) {
    const { currentSlideIdx, updateCurrentSlide, slideWidth } = this.props;

    // Disallow invalid scroll - scroll is valid only if initiated by the
    // user, ie, by dragging the carousel or clicking the prev/next arrows
    // An invalid scroll has been seen to be initiated when focus is
    // improperly grabbed during keynav and/or a screen reader is running
    if (this.state.navigationType === NavigationType.None) {
      const scrollLeft = slideWidth * currentSlideIdx;
      this.setScrollLeft(scrollLeft);

      return;
    }

    const slideMostVisibleIdx = Math.round(newScrollLeft / slideWidth);

    const slidePercentVisible = Math.ceil(
      ((newScrollLeft % slideWidth) / slideWidth) * 100 || 100
    );

    this.playScrollSound(slidePercentVisible);

    const { navigationType } = this.state;
    const isArrowNav = navigationType === NavigationType.Arrow;
    const isDragNav = navigationType === NavigationType.Drag;
    if (slidePercentVisible === 100) {
      if (
        (isArrowNav && slideMostVisibleIdx === currentSlideIdx) ||
        (isDragNav && slideMostVisibleIdx !== currentSlideIdx)
      ) {
        // Finished navigating or dragging to a new slide
        if (isDragNav) {
          updateCurrentSlide(slideMostVisibleIdx);
        }
        this.setState({ navigationType: NavigationType.None });
      }
    }
  }

  private handlePressArrow(type: ArrowButtonType) {
    this.setState({ navigationType: NavigationType.Arrow });
    if (type === ArrowButtonType.Prev) {
      this.props.onPressPrevious();
    } else {
      this.props.onPressNext();
    }
  }

  private handleBeginDrag() {
    this.setState({ navigationType: NavigationType.Drag });
  }

  private isLeftControlDisabled(): boolean {
    const { currentSlideIdx, disabledLeftCtrl } = this.props;
    const isFirstSlide = currentSlideIdx === 0;

    return isFirstSlide || disabledLeftCtrl;
  }

  private isRightControlDisabled(): boolean {
    const { currentSlideIdx, disabledRightCtrl, renderRightControl, slides } =
      this.props;
    const isLastSlide = currentSlideIdx === slides.length - 1;
    const disableRightArrow = isLastSlide || disabledRightCtrl;

    // Right control can be arrow or custom control, and custom control
    // shouldn't automatically disabled when on last slide
    return renderRightControl ? disabledRightCtrl : disableRightArrow;
  }

  public render() {
    const {
      accessibilityLabel,
      disabled,
      renderBottomControl,
      renderRightControl,
      scrollEnabled = SystemInfo.isNative, // revisit with LOBO-12338
      slideWidth,
      slides,
      styleOverrides
    } = this.props;

    const disabledLeftCtrl = this.isLeftControlDisabled();
    const disabledRightCtrl = this.isRightControlDisabled();

    const styles = CarouselStyles.build(
      slideWidth,
      styleOverrides,
      this.state.navigationType === NavigationType.Arrow,
      disabledLeftCtrl,
      disabledRightCtrl
    );

    return (
      <View
        testId={CarouselComponent.displayName}
        style={styles.container}
        accessibilityRole={AccessibilityRole.Group}
        accessibilityLabel={accessibilityLabel}
      >
        <View style={styles.controlContainerLeft}>
          <ArrowButton
            disabled={disabledLeftCtrl}
            type={ArrowButtonType.Prev}
            onPress={this.handlePressArrow}
            styleOverride={styles.arrowButtonSvg}
          />
        </View>
        <View style={styles.scrollContainer}>
          <ScrollView
            ref={this.scrollViewRef}
            horizontal
            onScrollBeginDrag={this.handleBeginDrag}
            showsHorizontalScrollIndicator={false}
            style={styles.scrollView}
            onScroll={this.handleScroll}
            scrollEventThrottle={0}
            pagingEnabled
            scrollEnabled={scrollEnabled && !disabled}
          >
            {slides.map((slideContent: any, idx: number) => (
              <Slide
                key={`slide${idx}`}
                idx={idx}
                isCurrentSlideInPosition={
                  this.props.currentSlideIdx === idx &&
                  this.state.navigationType === NavigationType.None
                }
                slideContent={slideContent}
              />
            ))}
          </ScrollView>
        </View>

        {/* Tab ordering for carousel should start on the Next button, if not otherwise set within the carousel contents */}
        <AutoFocusView />
        <View style={styles.controlContainerRight}>
          {renderRightControl ? (
            renderRightControl()
          ) : (
            <ArrowButton
              disabled={disabledRightCtrl}
              type={ArrowButtonType.Next}
              onPress={this.handlePressArrow}
              styleOverride={styles.arrowButtonSvg}
            />
          )}
        </View>

        {renderBottomControl && (
          <View style={styles.controlContainerBottom}>
            {renderBottomControl()}
          </View>
        )}
      </View>
    );
  }
}

export const Carousel = compose(withSfx, withGlobalDisabled)(CarouselComponent);
