// istanbul ignore file - code generated by svgr
import { G, Path, Svg } from 'common-ui';
import { IUserInfoButtonIconProps } from './user-info-button-icon.model';

export function PokIconSvg(props: IUserInfoButtonIconProps) {
  const { colorSet } = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="34"
      viewBox="-6 -4 35 35"
      accessibilityLabel="Presentation Of Knowledge, user information."
      data-test-id={PokIconSvg.displayName}
    >
      {colorSet.withShadow && (
        <G fill="#214c98">
          <Path d="M21.485 25.761H2.237V2.246h19.248v23.515l1.237 1.246V1H1v26.011h21.722Z" />
          <Path d="M8.781 6.918h9.737a1.073 1.073 0 0 0 0-2.146H8.781a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M8.781 12.324h9.862a1.073 1.073 0 0 0 0-2.146H8.781a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M8.781 23.139h9.862a1.073 1.073 0 0 0 0-2.146H8.781a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M8.781 17.732h9.862a1.073 1.073 0 0 0 0-2.146H8.781a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M4.936 6.918h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M4.936 12.324h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M4.936 23.139h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
          <Path d="M4.936 17.732h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
        </G>
      )}
      <G fill={colorSet.iconColor}>
        <Path d="M20.485 24.761H1.237V1.246h19.248v23.515l1.237 1.246V0H0v26.011h21.722Z" />
        <Path d="M7.781 5.918h9.737a1.073 1.073 0 0 0 0-2.146H7.781a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M7.781 11.324h9.862a1.073 1.073 0 0 0 0-2.146H7.781a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M7.781 22.139h9.862a1.073 1.073 0 0 0 0-2.146H7.781a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M7.781 16.732h9.862a1.073 1.073 0 0 0 0-2.146H7.781a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M3.936 5.918h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M3.936 11.324h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M3.936 22.139h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
        <Path d="M3.936 16.732h.029a1.073 1.073 0 0 0 0-2.146h-.029a1.073 1.073 0 0 0 0 2.146Z" />
      </G>
    </Svg>
  );
}

PokIconSvg.displayName = 'PokIconSvg';
