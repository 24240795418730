import { SourceType } from 'dnd-core';
import * as React from 'react';
import { DndOptions, DragSource } from 'react-dnd';
import { connect } from 'react-redux';
import { withSfx } from 'audio/sfx';
import { DndConnectDragPreview } from '../container';
import { DndAction, DndSelector } from '../redux';
import {
  IDndDragSourceCollectedProps,
  IDndDragSourceHocProps,
  IDndDragSourceHocState,
  IDndDragSourceProps,
  IDndDragSourcePropsWithSfx
} from './drag-source.model';
import { DragSourceHelper } from './DragSource.helper';

/**
 * Decorates a component to make it a drag source. Use the spec, collect and
 * options parameters to override standard behavior.
 *
 * @see DragSourceHelper
 *
 * @param type - The type of component to associate with relevant drop targets
 * @param spec - Spec object with callbacks for beginDrag, endDrag and canDrag
 * @param collect - Props collector function
 * @param options - Dnd options
 */
export function DndDragSource(
  type: SourceType | ((props: IDndDragSourceProps) => SourceType),
  spec = DragSourceHelper.createSpec(),
  collect = DragSourceHelper.collect,
  options?: DndOptions<IDndDragSourceProps>
) {
  return (WrappedComponent: any) => {
    const component = class extends React.Component<
      IDndDragSourceHocProps,
      IDndDragSourceHocState
    > {
      public static readonly displayName = `DndDragSourceComponent_${WrappedComponent.displayName}`;

      constructor(props: IDndDragSourceHocProps) {
        super(props);
        this.state = {};
      }

      public componentDidMount() {
        if (this.props.connectDragPreview) {
          DndConnectDragPreview(this.props.connectDragPreview);
        }
      }

      // istanbul ignore next - TODO - how to test this?
      public componentDidUpdate(prevProps: IDndDragSourceHocProps) {
        if (!prevProps.isDragging && this.props.isDragging) {
          // Local state value will be true for only this element
          this.setState({ isDraggingOrDropping: true });
          // Dnd store value will be true across all elements
          this.props.setDndIsDraggingOrDropping(true);
        }

        // Reset local isDragginOrDropping state value when global isDndDraggingOrDropping value becomes false
        if (
          prevProps.isDndDraggingOrDropping &&
          !this.props.isDndDraggingOrDropping
        ) {
          this.setState({ isDraggingOrDropping: false });
        }
      }

      public render() {
        const {
          connectDragSource,
          isDragging: isSelfDragging,
          onPress,
          isDndDraggingOrDropping,
          ...restProps
        } = this.props;
        const { isDraggingOrDropping } = this.state;
        // Block interaction when any item is dropping or when an item other than
        // this is dragging (can't block interactions for the item being dragged
        // as they are needed to do the dragging)
        const shouldBlockInteraction =
          isDndDraggingOrDropping && !isSelfDragging;

        return (
          <div
            style={{
              // 'grabbing' and 'grab' are not included in Cursor enum as they are not part of ReactXp
              // and thus not generally allowed, or desired to be exposed as general cursor options,
              // however, this component is web only and these values have worked here,
              // so leaving them in this one case
              cursor: isSelfDragging ? 'grabbing' : 'grab',

              display: 'flex',
              // Disallow pointer events when dropping to avoid starting
              // a new drag event until drop animation has completed
              ...(shouldBlockInteraction && { pointerEvents: 'none' })
            }}
            ref={connectDragSource}
            className="drag-source"
            onClick={onPress}
          >
            <WrappedComponent
              isDraggingOrDropping={isDraggingOrDropping}
              {...restProps}
            />
          </div>
        );
      }
    };

    const { useSfx, ...dndSpec } = spec;

    // istanbul ignore next - trivial
    function mapStateToProps(state: unknown) {
      return {
        isDndDraggingOrDropping: DndSelector.getIsDraggingOrDropping(state)
      };
    }

    // istanbul ignore next - trivial
    const mapDispatchToProps = {
      setDndIsDraggingOrDropping: (isDraggingOrDropping: boolean) =>
        DndAction.setIsDraggingOrDropping(isDraggingOrDropping)
    };

    return withSfx(
      DragSource<IDndDragSourcePropsWithSfx, IDndDragSourceCollectedProps>(
        type,
        dndSpec,
        collect,
        options
      )(connect(mapStateToProps, mapDispatchToProps)(component))
    ) as typeof WrappedComponent;
  };
}
