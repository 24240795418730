import * as React from 'react';
import { AnimatedView, Types } from 'common-ui';
import { AppState } from 'services';
import { HintBox } from '../../../hint-box/HintBox';
import { ISpeechBubbleAnimatedStyles, SpeechBubble } from './SpeechBubble';
import { InteractionState } from 'common-styles';
import { LanguageFrame } from '../../../language-frame/LanguageFrame';
import { SeeSpeakMode } from '@lexialearning/lobo-common';
import { SreButtonMeter } from '../../../sre-button-meter';
import { useSelector } from 'react-redux';
import {
  IGetInteractionStateArgs,
  SeeSpeakHelper
} from 'task-components/see-speak/SeeSpeak.helper';

export interface IResponseSpeechBubbleAnimatedStyles
  extends ISpeechBubbleAnimatedStyles {
  hintBox: Types.AnimatedViewStyle;
  languageFrame: Types.AnimatedViewStyle;
  sreButtonMeter: Types.AnimatedViewStyle;
  testId?: string;
}

export interface IResponseSpeechBubbleProps {
  animatedStyles?: IResponseSpeechBubbleAnimatedStyles;
  hasAvatar?: boolean;
  hasTailLeft?: boolean;
  isInstruction?: boolean;
  languageFrameWidth?: number;
  mode: SeeSpeakMode;
  testId?: string;
}

export function ResponseSpeechBubble(
  props: IResponseSpeechBubbleProps
): React.ReactElement {
  const {
    animatedStyles,
    hasAvatar = true,
    hasTailLeft = false,
    isInstruction = false,
    languageFrameWidth,
    mode,
    testId
  } = props;

  const interactionState = useSelector<AppState, InteractionState>(s =>
    SeeSpeakHelper.getInteractionState(s, getResponseInteractionState)
  );

  return (
    <SpeechBubble
      animatedStyles={animatedStyles}
      hasAvatar={hasAvatar}
      hasTailLeft={hasTailLeft}
      interactionState={interactionState}
      testId={testId}
    >
      {mode === SeeSpeakMode.OpenScaffold && !isInstruction && (
        <AnimatedView
          animatedStyle={animatedStyles?.hintBox}
          testId="hintBoxAnimatedView"
        >
          <HintBox />
        </AnimatedView>
      )}
      <LanguageFrame width={languageFrameWidth} />
      <AnimatedView animatedStyle={animatedStyles?.sreButtonMeter}>
        <SreButtonMeter />
      </AnimatedView>
    </SpeechBubble>
  );
}
ResponseSpeechBubble.displayName = 'ResponseSpeechBubble';

// istanbul ignore next - LOBO-18576 to resolve in separate PR
function getResponseInteractionState({
  feedbackInteractionState,
  isFeedbackPhase,
  isListeningToLf,
  isNeutralFeedback
}: IGetInteractionStateArgs): InteractionState {
  if (isListeningToLf || (isFeedbackPhase && isNeutralFeedback)) {
    return InteractionState.Selected;
  }

  if (isFeedbackPhase) {
    return feedbackInteractionState;
  }

  return InteractionState.Default;
}
