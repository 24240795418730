import { Align, Font } from 'common-styles';

export class ConfigAdminModalStyles {
  private static readonly styles = {
    container: {
      padding: 20
    },
    instruction: {
      alignSelf: Align.Start,
      fontSize: Font.Size.xSmall,
      marginBottom: 20
    }
  };

  public static get() {
    return this.styles;
  }
}
