import { AppShellAction, ControlPanelLayout } from 'feature-areas/shell';
import { RoundIntroType } from '../rounds';
import { PlacementScreenplayId } from './placement-screenplay-builders.model';
import { PlacementScreenplayBuilderBase } from './PlacementScreenplayBuilderBase';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=ELKMK&title=Student+Placement
 */
export class PlacementIntroScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'PlacementIntroScreenplayBuilder';

  public static create(): PlacementIntroScreenplayBuilder {
    return new PlacementIntroScreenplayBuilder();
  }

  protected constructor() {
    super(PlacementScreenplayId.Intro);
  }

  public setStandardControlPanelLayout(): PlacementIntroScreenplayBuilder {
    this.builder.addReduxAction(
      AppShellAction.setControlPanelLayout(ControlPanelLayout.Standard)
    );

    return this;
  }

  public dispatchPlacementRoundIntro(): PlacementIntroScreenplayBuilder {
    super.dispatchRoundIntro(RoundIntroType.PlacementIntro);

    return this;
  }
}
