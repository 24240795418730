import {
  IAct,
  ICharacter,
  IEncounter,
  IScreenplay
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, sample } from 'lodash';
import { ActReactAnimationName } from 'feature-areas/acts';
import {
  LevelSceneAnimationName,
  LevelSceneElementName
} from 'feature-areas/levels';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { ActScreenplayId } from './act-screenplay-builder.model';
import { ActFooterComponent } from 'feature-areas/acts/footer/ActFooter';

export interface IActIntroScreenplayDeps {
  act: IAct;
  selectedActNumber: number;
  hasFunFact: boolean;
  actsEntered: string[];
  encounterId: string;
  currentEncounter: number;
  encounterPercentCompleted: number;
}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Level+to+Showcase+Transition
 */
export class ActIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'ActIntroScreenplayBuilder';

  private get actId(): string {
    return this.deps.act.sysId;
  }

  private get character(): ICharacter | undefined {
    return this.deps.act.character;
  }

  private get encounters(): IEncounter[] {
    return this.deps.act.encounters;
  }

  private get actsEntered(): string[] {
    return this.deps.actsEntered;
  }

  private get encounterId(): string {
    return this.deps.encounterId;
  }

  public static createFor(
    deps: IActIntroScreenplayDeps
  ): ActIntroScreenplayBuilder {
    return new ActIntroScreenplayBuilder(deps);
  }

  private constructor(private readonly deps: IActIntroScreenplayDeps) {
    super(ActScreenplayId.Intro);

    this.playShowcaseIntroAnimations()
      .animateInPageElements()
      .enableMic()
      .enableUtilityBar()
      .playFunFactsIntroMaybe()
      .playShowcaseVoiceoversMaybe();
  }

  private playShowcaseIntroAnimations(): ActIntroScreenplayBuilder {
    const { currentEncounter, encounterPercentCompleted } = this.deps;

    this.builder
      .addStormAnimation(
        {
          blendTimeSeconds: 0.5,
          loop: true,
          name: LevelSceneAnimationName.Root.buildCharacterIdle(
            this.deps.selectedActNumber
          ),
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addStormAnimation({
        name: LevelSceneAnimationName.Showcase.buildMeterFill(currentEncounter),
        speed: 0,
        targetElement: LevelSceneElementName.Showcase,
        targetScene: SceneName.Level,
        timeAsPercent: encounterPercentCompleted
      });

    return this;
  }

  private animateInPageElements(): ActIntroScreenplayBuilder {
    this.builder.addReactAnimation(ActReactAnimationName.FadeIn);

    return this;
  }

  private playFunFactsIntroMaybe(): ActIntroScreenplayBuilder {
    const { hasFunFact } = this.deps;

    if (hasFunFact) {
      this.builder
        .addDelay(200)
        .addStormAnimation({
          name: LevelSceneAnimationName.FunFactsEffects.Intro,
          targetElement: LevelSceneElementName.Effects,
          targetScene: SceneName.Level
        })
        .addStormAnimation({
          loop: true,
          name: LevelSceneAnimationName.FunFactsEffects.Idle,
          targetElement: LevelSceneElementName.Effects,
          targetScene: SceneName.Level
        });
    }

    return this;
  }

  private playShowcaseVoiceoversMaybe(): ActIntroScreenplayBuilder {
    const voiceovers = this.createActPageVoiceoversScreenplaysMaybe().filter(
      vo => !!vo
    );

    if (voiceovers.length) {
      this.builder
        // Add delay in order to allow Act Intro music (added in Level Outro) to finish
        // before playing voiceovers
        .addDelay(1000)
        .addScreenplayList(voiceovers);

      if (this.shouldPlayEncounterDirections()) {
        this.builder.addReactAnimation(
          ActFooterComponent.FauxMicCalloutReactAnimation
        );
      }
    }

    return this;
  }

  // #region Helper methods
  private createActPageVoiceoversScreenplaysMaybe(): IScreenplay[] {
    if (!this.character) {
      return [];
    }

    return [
      this.createEncounterGreetingScreenplayMaybe(),
      this.createEncounterDirectionsScreenplayMaybe()
    ].filter(s => !!s) as IScreenplay[];
  }

  private createEncounterGreetingScreenplayMaybe(): IScreenplay | undefined {
    const hasPreviouslyEnteredCurrentAct = this.actsEntered.includes(
      this.actId
    );
    if (hasPreviouslyEnteredCurrentAct) {
      return undefined;
    }

    const greetingPool = this.getGreetingPoolForCurrentEncounter();

    return this.addSpeakerInfo(sample(greetingPool));
  }

  private getGreetingPoolForCurrentEncounter() {
    const encounterIdx = this.encounters.findIndex(
      e => e.sysId === this.encounterId
    );

    const greetingPools = [
      this.character?.encounter1Greeting,
      this.character?.encounter2Greeting,
      this.character?.encounter3Greeting
    ];
    const greetingPool = greetingPools[encounterIdx];

    if (!greetingPool) {
      if (encounterIdx < 0 || encounterIdx >= greetingPools.length) {
        throw new LexiaError(
          `Encounter index ${encounterIdx} is outside the range of expected encounters`,
          ActIntroScreenplayBuilder.displayName,
          ActIntroScreenplayBuilderError.InvalidEncounterIdx
        );
      }

      throw new LexiaError(
        `Greeting pool for current encounter index ${encounterIdx} is undefined`,
        ActIntroScreenplayBuilder.displayName,
        ActIntroScreenplayBuilderError.GreetingPoolUndefined
      );
    }

    return greetingPool;
  }

  private createEncounterDirectionsScreenplayMaybe(): IScreenplay | undefined {
    if (!this.shouldPlayEncounterDirections()) {
      return undefined;
    }

    return this.addSpeakerInfo(
      sample(this.character?.encounterDirections || [])
    );
  }

  private shouldPlayEncounterDirections(): boolean {
    const isFirstActEntryThisSession = !this.actsEntered.length;

    return isFirstActEntryThisSession;
  }

  private addSpeakerInfo(
    screenplay: IScreenplay | undefined
  ): IScreenplay | undefined {
    if (!screenplay) {
      return;
    }

    const clonedScreenplay = cloneDeep(screenplay);
    clonedScreenplay.actions.forEach(a => {
      a.data = {
        ...a.data,
        speaker: {
          directions: {},
          sceneId: SceneName.Level,
          speakerId: LevelSceneElementName.buildCharacter(
            this.deps.selectedActNumber
          )
        }
      };
    });

    return clonedScreenplay;
  }
  // #endregion Helper methods
}

export enum ActIntroScreenplayBuilderError {
  GreetingPoolUndefined = 'GreetingPoolUndefined',
  InvalidEncounterIdx = 'InvalidEncounterIdx'
}
