import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import {
  CalibrationModal,
  CalibrationStep,
  CalibrationUiAction,
  CalibrationUiActionSetStep
} from 'feature-areas/calibration';
import { MicChangedModal } from 'feature-areas/calibration/mic-change';
import { RouterService, RoutePath } from 'router-service';
import { SreConfigActionType, SreSetMicsAvailableAction } from 'sre/redux';
import { AppShellAction, AppShellActionShowModal } from '../../AppShell.action';
import { AppShellSelector } from '../../AppShell.selector';
import { awaitAppInteractive } from '../awaitAppInteractive';

export function micChangedModalEpic(
  action$: Observable<SreSetMicsAvailableAction>,
  state$: StateObservable<unknown>
): Observable<AppShellActionShowModal | CalibrationUiActionSetStep> {
  return action$.pipe(
    ofType(SreConfigActionType.SetMicsAvailable),
    // first emit is the initial mic registration, not an actual change
    skip(1),
    awaitAppInteractive(state$),
    map(() => {
      const isCalibrationModalOpen =
        AppShellSelector.getActiveModal(state$.value)?.id ===
        CalibrationModal.ModalId;
      const isOnCalibrationPage = RouterService.isOnRoute(
        RoutePath.Calibration
      );

      if (isCalibrationModalOpen || isOnCalibrationPage) {
        return CalibrationUiAction.setStep({
          step: CalibrationStep.MicChanged
        });
      }

      return AppShellAction.showModal({ id: MicChangedModal.ModalId });
    })
  );
}
micChangedModalEpic.displayName = 'micChangedModalEpic';
