import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { RoutePath } from 'router-service';
import {
  ActPage,
  CalibrationPage,
  GettingStartedPage,
  HomePage,
  LevelPage,
  LoginPage,
  LoginSsoInteractionPage,
  UnitPage
} from 'feature-areas';

export function AppRoutes() {
  return (
    <React.Suspense fallback={null}>
      {useRoutes([
        { element: <LoginPage />, path: RoutePath.Root },
        { element: <LoginPage />, path: RoutePath.Login },
        { element: <LoginPage />, path: RoutePath.LoginTab },
        { element: <LoginPage />, path: RoutePath.DeviceSetup },
        {
          children: [
            { element: <ActPage />, path: RoutePath.Acts },
            { element: <CalibrationPage />, path: RoutePath.Calibration },
            { element: <HomePage />, path: RoutePath.Home },
            { element: <LevelPage />, path: RoutePath.Levels },
            {
              element: <LoginSsoInteractionPage />,
              path: RoutePath.LoginSsoInteraction
            },
            ...[
              RoutePath.Rounds,
              RoutePath.OnboardingRounds,
              RoutePath.PlacementRounds
            ].map(path => ({ element: <UnitPage />, path })),
            { element: <EducatorPage />, path: RoutePath.Educator },
            { element: <EducatorPage />, path: RoutePath.EducatorTab },
            { element: <GettingStartedPage />, path: RoutePath.GettingStarted },
            // transitions are handled through Storm, no need to render anything.
            // Using dedicated routes just for automated tests.
            ...[
              RoutePath.AvatarEditor, // Avatar component is not a page, but a component overlay
              RoutePath.EncounterComplete,
              RoutePath.Introduction,
              RoutePath.LevelComplete,
              RoutePath.LevelIntroduction,
              RoutePath.Onboarding,
              RoutePath.OnboardingOrientation,
              RoutePath.PlacementComplete,
              RoutePath.ProgramComplete,
              RoutePath.UnitComplete
            ].map(path => ({ element: <EmptyComponent />, path }))
          ],
          element: <PrivateRoute />
        },
        { element: <Navigate to={RoutePath.Login} replace />, path: '*' }
      ])}
    </React.Suspense>
  );
}
AppRoutes.displayName = 'AppRoutes';

// istanbul ignore next - trivial
export const EducatorPage = React.lazy(async () =>
  import('feature-areas/educator/EducatorPage').then(module => ({
    default: module.EducatorPage
  }))
);

// istanbul ignore next - trivial
function EmptyComponent() {
  return null;
}
