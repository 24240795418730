import { Path, Svg } from '@lexialearning/common-ui';

export function CloudGabberArmsSvg(props) {
  return (
    <Svg viewBox="0 0 231 234" style={props.style}>
      <Path
        fill="#085f5f"
        d="m50.13,151.31c.89-1.02,2.03-.88,3.12-.54,2.36.75,4.67,1.62,7.02,2.4,6.73,2.22,13.54,2.8,20.44.84,2.82-.8,5.47-1.95,7.91-3.59.64-.43,1.23-.91,1.81-1.42,1.02-.9,1.61-1.78,1.27-3.37-.51-2.35-.62-4.8.01-7.2.23-.89.6-1.72,1.26-2.39.69-.69,1.49-1.07,2.48-.78.92.26,1.21,1.02,1.39,1.85.03.13.06.26.12.51.27-.42.48-.76.71-1.09,1.1-1.6,2.4-2.93,4.34-3.49.06-.02.1-.09.15-.14.43.03.86.07,1.3.1,1.82,1.12,2.1,2.3,1.02,4.2-.08.14-.16.29-.31.57.58-.19,1.03-.34,1.5-.48,1.18-.35,2.36-.43,3.55-.07.49.15.94.38,1.31.73,1.07,1.02,1.1,2.32.06,3.36-.78.78-1.74,1.27-2.75,1.69-1.35.56-2.72,1.08-4.03,1.76-.24.13-.48.27-.87.5,1.24.18,2.32.23,3.35.53,1.14.33,2.07.91,2.55,2.04.55,1.29-.08,2.49-1.45,2.81-1.15.27-2.24-.03-3.34-.32-.79-.21-1.58-.4-2.37-.63-.28-.08-.44-.04-.56.25-.16.38-.46.67-.77.94-5.8,4.97-12.37,8.52-19.8,10.38-5.21,1.3-10.45,1.09-15.69.11-3.61-.68-7-1.92-10.08-3.94-1.55-1.02-3.01-2.17-3.97-3.81-.27-.47-.5-.96-.75-1.44.02-.29.05-.57.07-.86Z"
      />
      <Path
        fill="#096060"
        d="m198.57,112.92c-.37.16-.55.24-.73.3-2.35.89-4.16-.44-3.87-2.91.09-.72.1-1.01-.77-.84-.73.15-1.48-.07-2.12-.49-1.03-.67-1.41-1.85-.79-2.91.99-1.69,2.31-3.05,4.3-3.57.59-.16,1.18-.21,1.78-.08,1.04.23,1.89.85,1.99,1.87.1,1.06.39,1.48,1.49,1.66,1.18.2,1.8,1.5,1.65,2.83-.05.39-.15.77-.23,1.16,2.44.18,3.55,1.72,2.97,4.08-.2.83-.49,1.64-.89,2.4-.54,1.04-1.31,1.65-2.57,1.65-1.46,0-2.53-.88-2.52-2.37,0-.87-.09-1.77.31-2.81Z"
      />
    </Svg>
  );
}
