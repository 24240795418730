import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ScreenplayAction,
  ScreenplayActionCancel,
  ScreenplaySelector
} from 'screenplay';
import { SEE_SPEAK_LISTEN_SCREENPLAY_ID } from './toggleListen.epic';
import {
  AppShellActionShowModal,
  AppShellActionType
} from 'feature-areas/shell';
import { ChoralScreenplayInfo } from '../see-speak.model';
import { AppState } from 'services';

export function cancelListenScreenplayOnShowModalEpic(
  action$: Observable<AppShellActionShowModal>,
  state$: StateObservable<AppState>
): Observable<ScreenplayActionCancel> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal),
    filter(() =>
      [
        ChoralScreenplayInfo.ScreenplayId,
        SEE_SPEAK_LISTEN_SCREENPLAY_ID
      ].includes(ScreenplaySelector.getActiveScreenplayId(state$.value)!)
    ),
    map(() => ScreenplayAction.cancel())
  );
}
cancelListenScreenplayOnShowModalEpic.displayName =
  'cancelListenScreenplayOnShowModalEpic';
