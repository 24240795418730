import { SizingUtils } from './SizingUtils';

export class UseSizingResizeHelper {
  public static readonly displayName = 'UseSizingResizeHelper';

  private static _totalListeners = 0;

  private static _eventsHandled = 0;

  public static addResizeListenerMaybe(listener: (event: Event) => void) {
    if (window?.addEventListener) {
      window.addEventListener('resize', listener);
      this._totalListeners += 1;
    }
  }

  public static removeResizeListenerMaybe(listener: (event: Event) => void) {
    if (window?.removeEventListener) {
      window.removeEventListener('resize', listener);
      this._totalListeners -= 1;
    }
  }

  public static handleResize = (event: Event) => {
    // Events that are initiated by user are trusted, initiated by code are not
    if (!event.isTrusted) {
      return;
    }

    // Advance the handle counter
    UseSizingResizeHelper._eventsHandled += 1;

    // If the counter reached the total number of listeners, it's time to
    // trigger an additional resize event to signal Storm to update
    if (
      window?.dispatchEvent &&
      UseSizingResizeHelper._eventsHandled ===
        UseSizingResizeHelper._totalListeners
    ) {
      window.dispatchEvent(new Event('resize'));

      // Reset the counter on handled events (we are done with this cycle)
      UseSizingResizeHelper._eventsHandled = 0;
    }

    return SizingUtils.getAppSizing();
  };
}
