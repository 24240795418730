import { ActiveComponentState } from '@lexialearning/common-ui';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  loboAnimated
} from 'common-styles';
import { KeyNavHelper, Types } from 'common-ui';

interface IColorChoiceAnimatedValues {
  scale: Types.AnimatedValue;
}

interface IColorChoiceAnimations {
  toDefault: Types.Animated.CompositeAnimation;
  hovered: Types.Animated.CompositeAnimation;
  pressed: Types.Animated.CompositeAnimation;
}

interface IColorChoiceStyles {
  colorChoice: {
    alignItems: Align;
    flex: number;
    flexGrow: number;
    flexBasis: number;
    width: number;
  };
  scaleAnimated: {
    transform: [{ scale: Types.AnimatedValue }];
  };
  pip: {
    backgroundColor: string;
    borderColor: string;
    borderRadius: number;
    borderWidth: number;
    height: number;
    width: number;
  };
}

const AnimationDuration = {
  Scale: 100
};

export class ColorChoiceAnimatedStyles {
  private readonly animatedValues: IColorChoiceAnimatedValues;
  private readonly animations: IColorChoiceAnimations;
  private readonly styles: IColorChoiceStyles;

  private readonly hoveredAnimation: Types.Animated.CompositeAnimation;
  private readonly pressedAnimation: Types.Animated.CompositeAnimation;
  private readonly toDefaultAnimation: Types.Animated.CompositeAnimation;

  constructor() {
    this.animatedValues = {
      scale: loboAnimated.createValue(1)
    };

    this.hoveredAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Scale,
      this.animatedValues.scale,
      {
        duration: AnimationDuration.Scale,
        easing: loboAnimated.Easing.Out(),
        toValue: 1.25
      }
    );

    this.pressedAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Scale,
      this.animatedValues.scale,
      {
        duration: AnimationDuration.Scale,
        easing: loboAnimated.Easing.In(),
        toValue: 0.7
      }
    );

    this.toDefaultAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Scale,
      this.animatedValues.scale,
      {
        duration: AnimationDuration.Scale,
        easing: loboAnimated.Easing.Out(),
        toValue: 1
      }
    );

    this.animations = {
      toDefault: this.toDefaultAnimation,
      hovered: this.hoveredAnimation,
      pressed: this.pressedAnimation
    };

    this.styles = {
      colorChoice: {
        alignItems: Align.Center,
        flex: 1,
        flexGrow: 0,
        flexBasis: 0,
        width: 41
      },
      scaleAnimated: {
        transform: [{ scale: this.animatedValues.scale }]
      },
      pip: {
        backgroundColor: Color.NearWhite,
        borderColor: Color.Transparent,
        borderRadius: 5,
        borderWidth: 1,
        height: 7,
        width: 7
      }
    };
  }

  public getAnimations(): IColorChoiceAnimations {
    return this.animations;
  }

  public build(
    activeComponentState: ActiveComponentState,
    color: string | undefined
  ) {
    const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);

    return {
      ...this.styles,
      colorChoice: {
        ...this.styles.colorChoice,
        backgroundColor: color
      },
      indicator: {
        backgroundColor: color,
        borderColor: Color.NearWhite,
        ...(isKeyNav && {
          backgroundColor: Color.Black,
          borderColor: Color.White
        })
      },
      pip: {
        ...this.styles.pip,
        ...(isKeyNav && {
          backgroundColor: Color.Black,
          borderColor: Color.White
        })
      }
    };
  }
}
