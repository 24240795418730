import { IProgramPosition } from '@lexialearning/lobo-common';
import { ProgramPositionFactory } from '../../epics';
import { ProgramContextActionType } from '../program-context-redux.model';
import { ProgramContextActionStartProgram } from '../ProgramContext.action';
import { PositionActionType } from './position-redux.model';
import { PositionActions } from './Position.action';

export const positionInitialState: IProgramPosition = {
  activeActivityId: undefined,
  activityPositions: [],
  isComplete: false,
  levelId: ''
};

/**
 * NOTE: This reducer is called by programContextReducer not the root reducer.
 * So all actions here must first be recognized by programContextReducer.
 */
export function positionReducer(
  state = positionInitialState,
  action: PositionActions | ProgramContextActionStartProgram
): IProgramPosition {
  switch (action.type) {
    case PositionActionType.ActivityDeselected:
      return { ...state, activeActivityId: undefined };

    case PositionActionType.ActivitySelected:
      return { ...state, activeActivityId: action.payload.activityId };

    case PositionActionType.LevelActivitySelected:
    case PositionActionType.LevelStartPosition:
      return {
        ...state,
        ...action.payload
      };

    case PositionActionType.LevelPositionUnloaded:
      return { ...state, ...ProgramPositionFactory.createEmptyLevelPosition() };

    case PositionActionType.LevelUpPositionPrepared:
      return { ...state, levelUpPosition: action.payload };

    case PositionActionType.LevelUp:
    case ProgramContextActionType.StartProgram:
      const { isProgramComplete: isComplete, ...levelPosition } =
        state.levelUpPosition!;

      return {
        ...state,
        ...levelPosition,
        isComplete,
        levelUpPosition: undefined
      };

    default:
      return state;
  }
}
positionReducer.displayName = 'positionReducer';
