import { Color } from './Color';

export const ShadowStyle = {
  activity: {
    shadowColor: Color.BlackTransparent30,
    shadowOffset: { height: 3, width: 3 },
    shadowOpacity: 1,
    shadowRadius: 3
  },
  button: {
    shadowColor: Color.BlackTransparent30,
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 1,
    shadowRadius: 10
  }
};
