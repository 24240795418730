import { LogoutReason } from '@lexialearning/main-model';
import { IStrandStatus } from 'curriculum-services/program-context/epics/student-progress-api/student-progress-api-private.model';
import { ProgramMode } from 'curriculum-services/program-context/program-context.model';
import {
  ILexiaError,
  IStudentApiRequestBase,
  LexiaProduct
} from '../lexia-service-private.model';
import { UserRole } from './auth-api.model';
import { IStudentProperty } from '@lexialearning/student-api';

export enum AuthApiEndpoint {
  Authenticate = '/authenticate'
}

export enum AuthApiPayloadType {
  AuthResponse = 'authenticateResponse',
  AuthenticationDataResponse = 'authenticationDataResponse',
  LoginResponse = 'LoginResponse',
  StaffProfile = 'StaffProfile',
  StudentProfile = 'StudentProfile'
}

/**
 * Request params to the authentication api
 */
export interface IAuthRequest extends IAuthRequestBase {
  username: string;
  password: string;
}

export interface IAuthRequestBase {
  customer_code: string;
  product: LexiaProduct;
}

/**
 * Response from the auth api
 */
export interface IAuthResponse {
  __type__: AuthApiPayloadType.AuthResponse;
  /**
   * Endpoint for student API
   */
  endpoint: string;
  /**
   * Student/user name (first and last). Shown to user so they can confirm
   */
  name: string;
  /**
   * each student has a person ID that must be passed to the login api
   */
  personId: string; // assumed to be a number
  /**
   * For students, we get back a number whereas for non-students it is a
   * string representation of a number.
   */
  role: UserRole | string;
  /**
   * authenticate token used to authenticate person
   */
  token: string;
  valid: true;
  validCredentials?: boolean;
}

/**
 * Error response from authenticate
 */
export interface IAuthErrorResponse {
  __type__: AuthApiPayloadType.AuthResponse;
  error: string | null;
  valid: false;
  validCredentials?: boolean;
}

export interface IAuthenticationData {
  __type__: AuthApiPayloadType.AuthenticationDataResponse;
  apiUrl: string;
  lexiaAuthToken: string;
  logoutUrl?: string;
  personId: string;
  role: string;
}

/**
 * Login user Params
 */
export interface ILoginUserRequest extends ILoginUserRequestBase {
  authserverToken: string;
  deviceId?: string;
  personId: number;
  retrieveStudentProperties?: boolean;
  role: UserRole;
}

export interface ILoginUserRequestBase {
  version: string;
  requestNum: number;
  attemptNum: number;
}

/**
 * Logout user Params
 */
export interface ILogoutUserRequestBase extends IStudentApiRequestBase {
  requestNum: number;
  attemptNum: number;
}

export interface ILogoutUserRequest extends ILogoutUserRequestBase {
  reason?: LogoutReason;
}

/**
 * Login user success payload
 */
export interface ILoginApiResponse<
  TProfile extends IProfileBase = IProfileBase
> {
  __type__: AuthApiPayloadType.LoginResponse;
  /**
   * timeouts to wait on each api call before issuing next request in seconds
   */
  apiTimeoutArray: number[];
  attemptNum: number;
  /**
   * token used on all calls to myLexia student progress api
   */
  authToken: string;
  error: ILexiaError | undefined;
  nextExpectedRequestNum: number | null;

  /**
   * profile of the student containing name, grade, last login, school id, current position in the app, etc
   */
  profile: TProfile;
  requestNum: number | null;
  /**
   * Student ID. Null for non-students
   */
  studentId: number | null;
  /**
   * Student login ID. Unique identifier for this login session.
   * Null for non-students.
   */
  studentLoginId: string | null;
  studentPropertyArray: IStudentProperty[] | null;
}

export interface ILogoutUserResponse {
  authToken: string;
  studentId: number;
  requestNum: number | null;
  attemptNum: number;
  __type__: string;
  error: ILexiaError | undefined;
}

/**
 * Student profile
 */
export interface IProfileStudent extends IProfileBase {
  allowListenMode: string;
  customerId: number;
  grade: string;
  minutesSinceLastLogin: number | null;
  /**
   * field to indicate what mode to present to the student, it matches value with ProgramMode
   * @see ProgramMode
   */
  programMode: ProgramMode;
  schoolId: number;
  /**
   * student position and preliminary progress information
   */
  strandStatusArray: IStrandStatus[];
  studentId: number | null;
  timeInProgramAllTime: string | null;
  timeInProgramCurrentWeek: string | null;
  timeInProgramToday: string | null;
  unitsCompletedAllTime: string | null;
  unitsCompletedCurrentWeek: string | null;
  unitsCompletedToday: string | null;
}

export interface IProfileBase<
  T extends ProfileTypes = AuthApiPayloadType.StudentProfile
> {
  __type__: T;
  firstName: string;
  language: string;
  lastName: string;
  region: string;
  error: ILexiaError;
}

type ProfileTypes =
  | AuthApiPayloadType.StaffProfile
  | AuthApiPayloadType.StudentProfile;
