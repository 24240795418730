import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ScreenplayActionSkip, ScreenplayActionType } from '../redux';
import { IScreenplayDeps } from './screenplay-epic.model';

/**
 * Skip any active screenplay playback.
 * No action is dispatched post-process. The playEpic is expected
 * to dispatch instead.
 */
export function skipScreenplayEpic(
  action$: Observable<ScreenplayActionSkip>,
  _: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<any> {
  return action$.pipe(
    ofType(ScreenplayActionType.Skip),
    tap(() => {
      deps.screenplayer.skip();
    }),
    ignoreElements()
  );
}
skipScreenplayEpic.displayName = 'skipScreenplayEpic';
