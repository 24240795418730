export class AvatarInstructionalModalStyles {
  private static readonly styles = {
    container: {
      height: 519,
      width: 580
    },
    gif: {
      height: 404,
      width: 540
    },
    submitButton: {
      marginTop: 15
    }
  };

  public static get() {
    return this.styles;
  }
}
