import {
  EmptyGrammarSkill,
  EmptyImage,
  IAct,
  IEncounter,
  IPlacement,
  IPlacementForm,
  IPlacementStep,
  IUnitSnipped,
  UnknownTopic
} from '@lexialearning/lobo-common';

export enum PlacementActivityType {
  Onboarding = 'ONBOARDING',
  Placement = 'PLACEMENT'
}

export class FauxActivityFactory {
  public static readonly displayName = 'FauxActivityFactory';

  public static create(
    placement: IPlacement,
    placementForm?: IPlacementForm
  ): IAct {
    const factory = new FauxActivityFactory(placement, placementForm);
    const sysId = placementForm?.sysId ?? placement.sysId;
    const title = placementForm
      ? PlacementActivityType.Placement
      : PlacementActivityType.Onboarding;

    return {
      character: placement.character,
      characterFunFacts: [],
      characterSetNumber: placement.characterSet,
      encounters: [factory.createEncounter()],
      grammarSkill: EmptyGrammarSkill,
      languagePurpose: '',
      levelRecap: [],
      sysId,
      title,
      topic: UnknownTopic
    };
  }

  private constructor(
    private readonly placement: IPlacement,
    private readonly placementForm?: IPlacementForm
  ) {}

  private createEncounter(): IEncounter {
    const units = this.placementForm
      ? this.concatenateUnits(this.placementForm)
      : [this.placement.onboarding];
    const sysId = this.placementForm?.sysId ?? this.placement.onboarding.sysId;
    const title = this.placementForm?.title ?? PlacementActivityType.Onboarding;

    return {
      badge: EmptyImage,
      // below props not relevant for placement
      sceneBackground: '',

      // only used for encounter scene
      showcaseTexturePath: '',

      sysId,

      title,
      units // only used for level scene
    };
  }

  private concatenateUnits(form: IPlacementStep): IUnitSnipped[] {
    const nextForm = form.rules.find(r => r.nextPlacementStep)
      ?.nextPlacementStep;

    const units = [form.unit];

    return nextForm ? units.concat(this.concatenateUnits(nextForm)) : units;
  }
}
