import * as React from 'react';
import { AccessibilityRole } from '@lexialearning/common-ui';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppShellSelector } from 'feature-areas/shell';
import { AuthAction, AuthSelector, IAuthError } from 'services/auth';
import { ButtonType } from '@lexialearning/reactxp/dist/common/Types';
import { Column } from 'common-ui/components/layout';
import { CustomerSelector } from 'services/customer';
import { EducatorLinks } from './educator-links/EducatorLinks';
import { ILoboAppConfig } from 'services/app-config';
import { IMdmConfigParameters } from '@lexialearning/mdm-config';
import {
  ISsoLink,
  SsoProvider
} from 'lexia-service/customer-api/customer-api-private.model';
import { KeyboardType } from 'common-ui/components/inputs/text-input/TextInput';
import { LexiaServiceSelector } from 'lexia-service/redux/LexiaService.selector';
import { Linking } from 'common-ui/helpers';
import { LockIconSvg, MailIconSvg, UserIconSvg } from '../login-icons';
import { LoginErrorMessage } from '../login.model';
import { LoginFormSharedStyles } from '../LoginFormShared.styles';
import { LoginTabs } from './login-tabs/LoginTabs';
import { LoginTabName, RouteBuilder, RouterService } from 'router-service';
import { SettingsButton } from 'shell/settings-button';
import { SsoButton } from '../sso-button/SsoButton';
import { SystemInfo } from 'utils';
import { TextButton } from 'common-ui/components/button/text-button';
import { TextButtonType } from 'common-ui';
import { TextInput } from 'common-ui/components/inputs/text-input';
import { UserInterface } from 'common-ui/helpers/UserInterface';
import { View } from 'common-ui/components/view';
import { connect } from 'react-redux';
import { isValidEmailFormat } from 'utils/ValidationUtils';
import { LexiaError } from '@lexialearning/utils';
import { AppState } from 'services';
import { AppShellAction } from 'shell/redux/AppShell.action';
import { CleverWebviewModal } from '../CleverWebviewModal';

export interface ILoginFormState {
  password: string;
  username: string;
}

export interface ILoginFormProps {
  authError: IAuthError;
  hasSso: boolean;
  hasValidationError: boolean;
  isLoggedOrLoggingIn: boolean;
  isMakingRequest: boolean;
  isModalOpen: boolean;
  mainInputRef?: any;
  mdmConfig?: IMdmConfigParameters;
  selectedTab: LoginTabName;
  ssoLink?: ISsoLink;
  authenticate(username: string, password: string): void;
  openCleverModal(): void;
  resetAuthError(): void;
}

export class LoginFormComponent extends React.PureComponent<
  ILoginFormProps,
  ILoginFormState
> {
  public static readonly displayName = 'LoginForm';

  public static location = window?.location; // exposed for unit tests to change

  private readonly passwordRef: any = React.createRef();

  constructor(props: ILoginFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSsoButtonPress = this.handleSsoButtonPress.bind(this);
    this.resetError = this.resetError.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.hasError = this.hasError.bind(this);
    this.checkAndResetError = this.checkAndResetError.bind(this);

    const { mdmConfig } = props;

    if (mdmConfig?.password && mdmConfig?.username) {
      this.state = {
        password: mdmConfig.password,
        username: mdmConfig.username
      };
    } else {
      this.state = {
        password: '',
        username: ''
      };
    }
  }

  get isFormDisabled(): boolean {
    const { isMakingRequest, isLoggedOrLoggingIn } = this.props;

    return isLoggedOrLoggingIn || isMakingRequest || RouterService.isSsoUrl();
  }

  get isLoginButtonDisabled(): boolean {
    const { username, password } = this.state;
    const { hasValidationError, selectedTab } = this.props;
    const invalidEmail =
      selectedTab === LoginTabName.Educator && !isValidEmailFormat(username);

    return (
      this.isFormDisabled ||
      username.length < 4 ||
      password.length < 4 ||
      hasValidationError ||
      invalidEmail
    );
  }

  get errorMessage(): string {
    return LoginErrorMessage.UsernameOrPassword;
  }

  public componentDidMount() {
    this.checkAndResetError();
  }

  public componentDidUpdate(prevProps: ILoginFormProps) {
    if (
      prevProps.isModalOpen &&
      !this.props.isModalOpen &&
      !SystemInfo.isNative
    ) {
      this.props.mainInputRef?.current?.focus();
    }

    if (
      !prevProps.authError.incorrectUsernameOrPassword &&
      this.props.authError.incorrectUsernameOrPassword
    ) {
      this.setState({ password: '' });
    }
  }

  private handleChange(value: string, field: keyof ILoginFormState) {
    // @ts-ignore
    this.setState({ [field]: value });

    // reset error flag when user changes the value
    if (this.props.authError.incorrectUsernameOrPassword) {
      this.resetError();
    }
  }

  private handleLogin() {
    if (!this.isLoginButtonDisabled) {
      this.props.authenticate(this.state.username, this.state.password);
    }
  }

  private async handleSsoButtonPress(): Promise<void> {
    let href = this.props.ssoLink!.href!;

    if (SystemInfo.isNative) {
      if (this.props.ssoLink?.provider === SsoProvider.Clever) {
        this.props.openCleverModal();
      } else {
        // condition is here to ensure the sso link is opened in the same tab. Linking.openUrl opens in new tab
        await Linking.openUrl(href).catch(
          // istanbul ignore next - TODO: figure out how to test
          error => {
            throw new LexiaError(
              'Error opening SSO link on native',
              LoginFormComponent.displayName,
              LoginFormError.NativeSsoLinkError
            ).withContext({ error, href });
          }
        );
      }
    } else {
      // istanbul ignore next - ⬇ temp fix until MINT-1095 is addressed. LOBO-20367 ticket to remove this fix
      if (this.props.ssoLink?.provider === SsoProvider.Clever) {
        /* this plat param is what dictates if the clever app returns the logoutURL param.
        This is needed to determine if the app should send the app back to the clever portal
        or not as stated it should in the functional spec found here: 
        https://docs.google.com/document/d/1MuIxQ_YqFFmi4_QRtcQItkbPsRm31uuMyEuSAo46urY/edit
        web = clever portal so send it back to portal url (logoutURL), 
        liwcb = log in with clever button. will send it back to LE login route with no redirect to portal url (no logoutURL) */
        href = href.replace('plat%3Dweb', 'plat%3Dliwcb');
      }
      LoginFormComponent.location.href = href; // window.location.href
    }
  }

  private handleTabSelect(tab: LoginTabName) {
    this.setState({ password: '', username: '' });
    this.checkAndResetError();

    RouterService.history.replace(RouteBuilder.login(tab));

    // If you autofocus an input on native, it will bring
    // up the keyboard and slide the login page up.
    // And focus should not be stolen when user is navigating with keyboard.
    if (!SystemInfo.isNative && !UserInterface.isNavigatingWithKeyboard()) {
      // timeout needed so that the AutoFocusView doesn't grab focus after this
      setTimeout(() => {
        this.props.mainInputRef?.current?.focus();
      }, 10);
    }
  }

  private checkAndResetError() {
    if (this.hasError()) {
      this.resetError();
    }
  }

  private resetError() {
    this.props.resetAuthError();
  }

  private hasError(): boolean {
    const { authError: errors } = this.props;

    return Object.values(errors).some(er => er);
  }

  public render() {
    const { username, password } = this.state;
    const { authError, mainInputRef, selectedTab } = this.props;
    const styles = LoginFormSharedStyles.get();
    const loginAsStudent = selectedTab === LoginTabName.Student;
    const tabPanelId = 'loginPanel';

    return (
      <>
        <Column
          testId={LoginFormComponent.displayName}
          style={this.isFormDisabled ? styles.formDisabled : {}}
        >
          <LoginTabs
            selectedTab={selectedTab!}
            onSelect={this.handleTabSelect}
            tabPanelId={tabPanelId}
            disabled={this.isFormDisabled}
          />
          <View
            id={tabPanelId}
            accessibilityRole={AccessibilityRole.TabPanel}
            ariaLabelledBy={selectedTab}
          >
            <TextInput
              name="username"
              // If you autofocus on native, it will bring up the keyboard and slide the login page up.
              autoFocus={!SystemInfo.isNative}
              placeholder={loginAsStudent ? 'Username' : 'Email'}
              keyboardType={
                selectedTab === LoginTabName.Educator
                  ? KeyboardType.EmailAddress
                  : undefined
              }
              onSubmitEditing={this.handleLogin}
              value={username}
              onChange={this.handleChange}
              inputRef={mainInputRef}
              errorInfo={{
                emphasizeError: loginAsStudent,
                error: authError.incorrectUsernameOrPassword,
                errorMessage: this.errorMessage,
                errorTitle: LoginErrorMessage.Title
              }}
              Icon={loginAsStudent ? UserIconSvg : MailIconSvg}
              styleOverrides={styles.textInput}
              disallowSpaces
              testId={
                loginAsStudent ? 'studentUsernameInput' : 'teacherEmailInput'
              }
              disabled={this.isFormDisabled}
            />
            <TextInput
              name="password"
              placeholder="Password"
              value={password}
              secureTextEntry
              showSecureTextOption={loginAsStudent}
              onChange={this.handleChange}
              onSubmitEditing={this.handleLogin}
              inputRef={this.passwordRef}
              maxLength={50}
              Icon={LockIconSvg}
              disallowSpaces
              testId="passwordInput"
              styleOverrides={styles.textInput}
              disabled={this.isFormDisabled}
            />
            <EducatorLinks isVisible={!loginAsStudent} />
            <TextButton
              disabled={this.isLoginButtonDisabled}
              onPress={this.handleLogin}
              text="Log In"
              buttonType={TextButtonType.Primary}
              styleOverride={styles.buttonOverride}
              type={ButtonType.Submit}
            />
            {this.props.hasSso && !!this.props.ssoLink?.href && (
              <SsoButton
                provider={this.props.ssoLink?.provider}
                href={this.props.ssoLink.href}
                disabled={this.isFormDisabled}
                onPress={this.handleSsoButtonPress}
              />
            )}
          </View>
        </Column>
        <View style={styles.settingsButtonContainer}>
          <SettingsButton />
        </View>
      </>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    authError: AuthSelector.getAuthError(state),
    hasSso: CustomerSelector.getHasSso(state),
    hasValidationError: AuthSelector.hasValidationError(state),
    isLoggedOrLoggingIn: !!AuthSelector.getAuthDetailsMaybe(state),
    isMakingRequest: LexiaServiceSelector.getIsMakingRequest(state),
    isModalOpen: AppShellSelector.isModalOpen(state),
    mdmConfig: UniConfigSelector.getConfig<ILoboAppConfig>(state).mdm,
    ssoLink: CustomerSelector.getSsoLink(state)
  };
}

const mapDispatchToProps = {
  authenticate: (username: string, password: string) =>
    AuthAction.authenticate.request({ password, username }),
  openCleverModal: () =>
    AppShellAction.showModal({ id: CleverWebviewModal.ModalId }),
  resetAuthError: () => AuthAction.resetAuthError()
};

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormComponent);

export const LoginFormPrivates = { mapDispatchToProps, mapStateToProps };

export enum LoginFormError {
  NativeSsoLinkError = 'NativeSsoLinkError'
}
