import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, Direction, Justify, ZIndex } from 'common-styles';
import { Types } from '../../../types';
import {
  ActiveComponentState,
  Border,
  Display,
  Overflow,
  Position
} from '@lexialearning/common-ui';
import { KeyNavHelper } from 'common-ui/helpers';

export interface ITabButtonStyleOverride {
  tabContainer?: Types.ViewStyle;
  shadow?: Types.ViewStyle;
  tab?: Types.ViewStyle;
}

export class TabButtonStyles {
  private static getBackgroundColor(
    isSelected: boolean,
    activeComponentState: ActiveComponentState
  ) {
    switch (activeComponentState) {
      case ActiveComponentState.KeyboardFocused:
      case ActiveComponentState.KeyboardPressed:
        return Color.White;
      case ActiveComponentState.Hovered:
        return Color.NearWhite;
      default:
        return isSelected ? Color.NearWhite : Color.NearWhiteTransparent90;
    }
  }

  public static readonly build = memoizeOne(
    (
      isSelected: boolean,
      activeComponentState: ActiveComponentState,
      styleOverride?: any
    ) => {
      const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);

      return merge(
        {},
        {
          shadow: {
            display: isSelected ? Display.Flex : Display.None,
            height: 120,
            position: Position.Absolute,
            top: -20,
            width: 331,
            zIndex: ZIndex.Shared
          },
          tab: {
            alignItems: Align.Center,
            backgroundColor: this.getBackgroundColor(
              isSelected,
              activeComponentState
            ),
            borderColor: isKeyNav ? Color.Black : Color.Transparent,
            borderStyle: Border.Solid,
            borderWidth: 5,
            flexDirection: Direction.Row,
            height: 80,
            justifyContent: Justify.Start,
            width: 331,
            zIndex: isSelected
              ? ZIndex.Shared
              : isKeyNav
              ? ZIndex.Shared + 1
              : undefined
          },
          tabContainer: {
            alignItems: Align.Center,
            height: 80,
            marginTop: 2,
            overflow: isSelected ? Overflow.Visible : Overflow.Hidden,
            width: 330
          }
        },
        styleOverride
      );
    }
  );
}
