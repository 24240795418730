import { IPlacementWithPool } from '@lexialearning/lobo-common';
import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ProfileSelector } from 'services/profile';
import { ICurriculumDependencies } from '../../CurriculumDependencies';
import { PlacementActionType } from '../redux/placement-redux.model';
import {
  PlacementAction,
  PlacementActionLoad,
  PlacementActionLoadSuccess
} from '../redux/Placement.action';
import { PlacementTransformer } from './PlacementTransformer';

/**
 * Load the full placement content.
 * Note: This is not used when deep-linking.
 * @see loadPlacementContentForDeepLinkEpic
 */
export function loadPlacementContentEpic(
  action$: Observable<PlacementActionLoad>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<PlacementActionLoadSuccess> {
  return action$.pipe(
    ofType(PlacementActionType.Load),
    mergeMap(async () => {
      const gradeName = ProfileSelector.getGrade(state$.value);
      const provider =
        deps.curriculumDependencies.contentProviderFactory.create();
      const placements = await provider.loadByContentType<IPlacementWithPool>(
        LoboContentType.Placement
      );

      if (!placements.length) {
        throw new LexiaError(
          'No placement content found!',
          loadPlacementContentEpic.displayName,
          LoadPlacementContentEpicError.PlacementMissing
        ).withContext({ contentType: LoboContentType.Placement });
      }

      const placement = PlacementTransformer.transform(
        gradeName,
        placements[0]
      )!;

      return PlacementAction.load.success(placement);
    })
  );
}
loadPlacementContentEpic.displayName = 'loadPlacementContentEpic';

export enum LoadPlacementContentEpicError {
  PlacementMissing = 'PlacementMissing'
}
