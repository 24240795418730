import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LevelActionLoadSuccess, LevelActionType } from 'curriculum-services';
import { CommonUiAction, CommonUiActionSetUnitRewards } from 'common-ui';

export function setUnitRewardsEpic(
  action$: Observable<LevelActionLoadSuccess>
): Observable<CommonUiActionSetUnitRewards> {
  return action$.pipe(
    ofType(LevelActionType.LoadSuccess),
    map(action => {
      const levelNum = +action.payload.levelContent.title;
      const unitRewards = getRewards(levelNum);

      return CommonUiAction.setUnitRewards(unitRewards);
    })
  );
}
setUnitRewardsEpic.displayName = 'setUnitRewardsEpic';

/**
 *
 * @param levelNum
 * @returns An array of the indices of unit rewards which a given user may be shown.
 * There are two sets of rewards, one for lower level students, one for upper.
 */
function getRewards(levelNum: number): number[] {
  // TODO: should this be 13? as that is our excelling level?
  const ageUpLevel = 14;
  // Reward indices 0-9 are for lower levels, 10-19 are for upper
  const shiftBy = levelNum < ageUpLevel ? 0 : 10;

  return (
    Array.from({ length: 10 })
      .map((_, i) => i + shiftBy)
      // Idx 17 should be excluded, as art was never approved for that
      .filter(i => i !== 17)
  );
}
