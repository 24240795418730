import * as React from 'react';
import { Column, Text } from 'common-ui';
import { StudentInfoSectionStyles } from './StudentInfoSection.styles';

export interface IStudentInfoSectionProps {
  children?: React.ReactNode;
  title?: string;
}

export class StudentInfoSection extends React.PureComponent<IStudentInfoSectionProps> {
  public static readonly displayName = 'StudentInfoSection';

  public render() {
    const { title, children } = this.props;
    const styles = StudentInfoSectionStyles.get();

    return (
      <Column testId={StudentInfoSection.displayName} style={styles.container}>
        <Text style={styles.title}>{title}</Text>
        {children}
      </Column>
    );
  }
}
