import * as React from 'react';
import { Column } from 'common-ui';
import {
  IModalContainerStyleOverride,
  ModalContainerStyles
} from './ModalContainer.styles';

export interface IModalContainerProps {
  children?: React.ReactNode;
  styleOverride?: IModalContainerStyleOverride;
}

export class ModalContainer extends React.PureComponent<IModalContainerProps> {
  public static readonly displayName: 'ModalContainer';

  public render() {
    const { styleOverride } = this.props;
    const styles = ModalContainerStyles.build(styleOverride);

    return <Column style={styles.modalContainer}>{this.props.children}</Column>;
  }
}
