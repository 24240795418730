import { IProgressStatus, IRound } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { StateObservable } from 'redux-observable';
import { lastValueFrom } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { SpinnerHandler } from 'spinner-handler';
import { TaskRegistry } from 'task-components';
import { UnitSelector } from '../unit/redux';
import { ActivityPositionBuilder } from './epics';
import { IContentReadiness } from './program-context.model';
import { ProgramContextSelector } from './redux/ProgramContext.selector';
import { UnitProgressStatusFactory } from './redux/progress/UnitProgressStatusFactory';
import { RoundContext } from './RoundContext';
import { ContentReadiness } from './service-helpers/ContentReadiness';

export class ProgramContextService {
  public static readonly displayName = 'ProgramContextService';

  public constructor(private readonly taskRegistry: TaskRegistry) {}

  /**
   * Allow awaiting for completion of an actively loading unit.
   * Will request and dismiss the spinner.
   */
  public async contentLoaded(
    state$: StateObservable<unknown>,
    options?: IContentLoadedOptions
  ): Promise<void> {
    const withSpinner = !options?.suppressSpinner;
    const withRequestSpinner = withSpinner && !options?.suppressSpinnerRequest;
    const withDismissSpinner = withSpinner && !options?.suppressSpinnerDismiss;

    if (withRequestSpinner) {
      SpinnerHandler.requestSpinner();
    }

    const desiredReadiness = options?.desiredReadiness ?? { all: true };

    return lastValueFrom(
      state$.pipe(
        startWith(state$.value),
        first(state =>
          ContentReadiness.isSufficientlyReady(
            this.determineContentReadiness(state),
            desiredReadiness
          )
        ),
        map(() => {
          if (withDismissSpinner) {
            SpinnerHandler.dismissSpinner();
          }
        })
      )
    );
  }

  /**
   * Returns an IContentReadiness object that specifies whether the redux content
   * (level, unit, task) matches the program position. If it does not, it likely
   * means content is being (or will be) loaded.
   *
   * @see IContentReadiness
   * @see ContentReadiness
   */
  public determineContentReadiness(state: unknown): IContentReadiness {
    return ContentReadiness.determine(state);
  }

  /**
   * Return the status of each round in the active unit:
   * Pending - round has yet to be presented
   * Active - round is being worked on
   * Done - round was completed (could still be active)
   * Skip - round will not be presented in the recycled unit
   */
  public determineUnitProgress(context: RoundContext): IProgressStatus[] {
    return UnitProgressStatusFactory.create(context, this.taskRegistry);
  }

  /**
   * Find the active round specified by the active unit position within the
   * active unit.
   * This should only be used after the active unit has been loaded onto state
   * but before the task has been loaded/prepared. Prefer to use the Program
   * Context, otherwise.
   */
  public findRoundUnprepared(state: unknown): IRound {
    const { roundId } = ActivityPositionBuilder.create(
      ProgramContextSelector.getActivityPosition(state)
    ).activeUnitPosition;

    const unit = UnitSelector.getUnit(state);
    const round = unit.rounds.find(r => r.sysId === roundId);
    if (!round) {
      throw new LexiaError(
        `Unable to find round "${roundId}" in active unit`,
        ProgramContextService.displayName,
        ProgramContextServiceError.RoundMissing
      ).withContext({ roundId, rounds: unit.rounds });
    }

    return round;
  }
}

export enum ProgramContextServiceError {
  RoundMissing = 'RoundMissing'
}

export interface IContentLoadedOptions {
  /**
   * Wait for the desired readiness
   * @see ContentReadiness.isSufficientlyReady
   */
  desiredReadiness?: Partial<IContentReadiness>;
  /**
   * Suppress both spinner request and spinner dismiss
   */
  suppressSpinner?: boolean;
  /**
   * Suppress spinner request only
   * (spinner will still be dismissed, so this should
   *  only be used if the request happens elsewhere in
   *  the preceding logic)
   */
  suppressSpinnerRequest?: boolean;
  /**
   * Suppress spinner dismiss only
   * (spinner will still be requested, so this should
   *  only be used if the dismiss happens elsewhere in
   *  the subsequent logic)
   */
  suppressSpinnerDismiss?: boolean;
}
