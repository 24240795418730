import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  PositionActionActivityDeselected,
  PositionActionType
} from 'curriculum-services';
import { IMeterState } from '../../progress-meters.model';
import { UnitMeterActions } from './UnitMeter.action';
import { UnitMeterActionType } from './UnitMeterActionType';

export const unitMeterInitialState: IMeterState = {
  markers: []
};

export function unitMeterReducer(
  state: IMeterState = unitMeterInitialState,
  action:
    | UnitMeterActions
    | PositionActionActivityDeselected
    | UserGlobalActionLogoutSuccess
): IMeterState {
  switch (action.type) {
    case UnitMeterActionType.Updated: {
      return { ...state, ...action.payload };
    }

    case PositionActionType.ActivityDeselected:
    case UserGlobalActionType.LogoutSuccess:
      return unitMeterInitialState;

    default:
      return state;
  }
}
unitMeterReducer.displayName = 'unitMeterReducer';
