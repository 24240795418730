import {
  ILevel,
  ILevelEducator,
  ILevelPosition,
  ILevelSnipped,
  ILevelUpPosition,
  IProgramPosition
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ActivityPositionBuilder } from '../progress/position-determiners/ActivityPositionBuilder';

export class ProgramPositionFactory {
  public static readonly displayName = 'ProgramPositionFactory';

  public static create(level: ILevel): IProgramPosition {
    return {
      ...this.createLevelPosition(level),
      isComplete: false
    };
  }

  /**
   * Create a level position for when there is no active level
   */
  public static createEmptyLevelPosition(): ILevelPosition {
    return {
      activeActivityId: undefined,
      activityPositions: [],
      levelId: ''
    };
  }

  public static validateLevelPosition(
    levelPosition: ILevelPosition,
    expectedActivityCount = 4
  ): void {
    levelPosition.activityPositions.forEach(ap => {
      ActivityPositionBuilder.create(ap).validate();
    });

    if (levelPosition.activityPositions.length !== expectedActivityCount) {
      throw new LexiaError(
        `Level position has ${levelPosition.activityPositions.length} expected activity positions, ${levelPosition.activityPositions.length} was found`,
        ProgramPositionFactory.displayName,
        ProgramPositionFactoryError.Invalid
      ).withContext({ levelPosition });
    }
  }

  /**
   * Create a level position for when the program is complete
   */
  public static createProgramCompletePosition(): ILevelUpPosition {
    return {
      ...this.createEmptyLevelPosition(),
      isProgramComplete: true
    };
  }

  public static createLevelPosition(
    level: ILevelEducator | ILevel | ILevelSnipped,
    activeActivityId?: string
  ): ILevelPosition {
    return {
      activeActivityId,
      activityPositions: this.toSnippedLevel(level).activities.map(
        a =>
          ActivityPositionBuilder.create({
            activityId: a.sysId,
            encounterId: a.encounters[0]?.sysId ?? ''
          }).withUnitPosition({
            roundId: a.encounters[0]?.units[0]?.rounds[0]?.sysId ?? '',
            unitId: a.encounters[0]?.units[0]?.sysId ?? ''
          }).raw
      ),
      levelId: level.sysId
    };
  }

  public static createLevelUpPosition(
    level: ILevel | ILevelSnipped
  ): ILevelUpPosition {
    return {
      ...this.createLevelPosition(level),
      isProgramComplete: false
    };
  }

  public static toSnippedLevel(
    level: ILevel | ILevelEducator | ILevelSnipped
  ): ILevelSnipped {
    return 'acts' in level
      ? { activities: level.acts, sysId: level.sysId }
      : level;
  }
}

export enum ProgramPositionFactoryError {
  Invalid = 'Invalid'
}
