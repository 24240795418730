import {
  AnimatedView,
  Avatar,
  AvatarType,
  AvatarUserName,
  Types,
  View
} from 'common-ui';
import { MirrorSvg } from './Mirror.lx-svg';
import { AvatarAndMirrorStyles } from './AvatarAndMirror.styles';
import { SilhouetteFaceSvg } from 'common-ui/components/avatar/silhouette/SilhouetteFace.lx-svg';

export interface IAvatarAndMirrorAnimatedStyles {
  nameAnimated: Types.AnimatedViewStyle;
  avatarAnimated: Types.AnimatedViewStyle;
  thumbnailAnimated: Types.AnimatedViewStyle;
}

export interface IAvatarAndMirrorProps {
  animatedStyles: IAvatarAndMirrorAnimatedStyles;
}

export function AvatarAndMirror({ animatedStyles }: IAvatarAndMirrorProps) {
  const styles = AvatarAndMirrorStyles.get();

  return (
    <View testId={AvatarAndMirror.displayName}>
      <AnimatedView
        style={styles.thumbnail}
        animatedStyle={animatedStyles.thumbnailAnimated}
      >
        <SilhouetteFaceSvg width={157} height={157} />
      </AnimatedView>
      <AnimatedView
        style={styles.avatar}
        animatedStyle={animatedStyles.avatarAnimated}
      >
        <MirrorSvg />
        <Avatar type={AvatarType.Full} />
      </AnimatedView>
      <AnimatedView
        style={styles.name}
        animatedStyle={animatedStyles.nameAnimated}
      >
        <AvatarUserName />
      </AnimatedView>
    </View>
  );
}
AvatarAndMirror.displayName = 'ThumbnailAndSilhouette';
