import { connect, useSelector } from 'react-redux';
import { Column } from 'common-ui';
import { AppState } from 'services';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { withSpinner } from 'spinner-handler';
import { CalibrationContentStyles } from './CalibrationContent.styles';
import { CalibrationStep, CalibrationUiSelector } from './redux';
import {
  CalibrationInteractive,
  CalibrationIntro,
  CalibrationMicFix,
  CalibrationMicSetupTips
} from './steps';

export function CalibrationContentComponent() {
  // const isPageReady = !!useSelector(BootstrapContentSelector.getCalibrationScreenContent);
  const step = useSelector(CalibrationUiSelector.getStep);

  const getCalibrationContent = (step: CalibrationStep) => {
    switch (step) {
      case CalibrationStep.Intro:
        return <CalibrationIntro />;

      case CalibrationStep.MicFix:
      case CalibrationStep.MicChanged:
        return <CalibrationMicFix />;

      case CalibrationStep.MicSetupTips:
        return <CalibrationMicSetupTips />;

      default:
        return <CalibrationInteractive />;
    }
  };

  const styles = CalibrationContentStyles.get();

  return (
    <Column
      testId={CalibrationContentComponent.displayName}
      style={styles.container}
    >
      {getCalibrationContent(step)}
    </Column>
  );
}
CalibrationContentComponent.displayName = 'CalibrationContent';

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: AppState) => ({
  // Needed for withSpinner hoc
  isPageReady: !!BootstrapContentSelector.getCalibrationScreenContent(state)
});

export const CalibrationContent = connect(mapStateToProps)(
  withSpinner(CalibrationContentComponent)
);
