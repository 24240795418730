import {
  UserGlobalAction,
  UserGlobalActionLogout
} from '@lexialearning/lobo-common/main-model/user';
import { ILogger, LoggingLevel, LogoutReason } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ICurriculumDependencies } from '../../../CurriculumDependencies';
import {
  ProgramContextActionMonitorSessionTimeout,
  ProgramContextActionType
} from '../../redux';
import { AppState } from 'services/bootstrapping';
import { LoboLogItemCategory } from 'logging';

interface IHandleSessionTimeoutDeps extends ICurriculumDependencies {
  logger: ILogger;
}
export function handleSessionTimeoutEpic(
  action$: Observable<ProgramContextActionMonitorSessionTimeout>,
  _state$: StateObservable<AppState>,
  deps: IHandleSessionTimeoutDeps
): Observable<UserGlobalActionLogout> {
  return action$.pipe(
    ofType(ProgramContextActionType.MonitorSessionTimeout),
    switchMap(
      () => deps.curriculumDependencies.studentProgressApi.sessionTimeout$
    ),
    tap(() => {
      deps.logger.log({
        category: LoboLogItemCategory.SessionTimeout,
        loggingLevel: LoggingLevel.Verbose,
        payload: {},
        summary: 'App timed out'
      });
    }),
    map(() => UserGlobalAction.logout.request(LogoutReason.Timeout))
  );
}
handleSessionTimeoutEpic.displayName = 'handleSessionTimeoutEpic';
