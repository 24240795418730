import {
  ICalibrationScreen,
  ITextWithVoiceover
} from '@lexialearning/lobo-common/main-model';
import { sample } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { InteractionState } from 'common-styles';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { SreSessionType, SreStatus } from 'sre';
import { SreSelector } from 'sre/redux';
import {
  CalibrationFeedback,
  CalibrationStep,
  ICalibrationUiState
} from './calibration-ui-redux.model';
import { CalibrationResult } from '@lexialearning/sre';

export class CalibrationUiSelector {
  public static readonly displayName = 'CalibrationUiSelector';

  public static getState: (state: unknown) => ICalibrationUiState;

  public static getFeedbackContent: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static getHasPlayedFeedback: (state: unknown) => boolean;

  public static getIsRetry: (state: unknown) => boolean;

  public static getStep: (state: unknown) => CalibrationStep;

  public static getMicInteractionState: (state: unknown) => InteractionState;

  public static getCalibrationFeedback: (state: unknown) => CalibrationFeedback;

  public static createSelectors(
    selector: (state: any) => ICalibrationUiState
  ): void {
    CalibrationUiSelector.getState = createSelector(
      selector,
      (state: ICalibrationUiState) => state
    );

    CalibrationUiSelector.getHasPlayedFeedback = createSelector(
      CalibrationUiSelector.getState,
      (state: ICalibrationUiState) => state.hasPlayedFeedback
    );

    CalibrationUiSelector.getIsRetry = createSelector(
      CalibrationUiSelector.getState,
      (state: ICalibrationUiState) => state.isRetry
    );

    CalibrationUiSelector.getStep = createSelector(
      CalibrationUiSelector.getState,
      (state: ICalibrationUiState) => state.step
    );

    CalibrationUiSelector.getFeedbackContent = createSelector(
      CalibrationUiSelector.getStep,
      BootstrapContentSelector.getCalibrationScreenContent,
      SreSelector.getCalibrationResult,
      CalibrationUiSelector.calculateFeedbackContent
    );

    CalibrationUiSelector.getMicInteractionState = createSelector(
      CalibrationUiSelector.getStep,
      SreSelector.getCalibrationResult,
      SreSelector.getStatus,
      SreSelector.getSessionType,
      CalibrationUiSelector.calculateMicInteractionState
    );

    CalibrationUiSelector.getCalibrationFeedback = createSelector(
      CalibrationUiSelector.getStep,
      SreSelector.getCalibrationResult,
      CalibrationUiSelector.calculateCalibrationFeedback
    );
  }

  private static calculateFeedbackContent(
    step: CalibrationStep,
    content: ICalibrationScreen | undefined,
    calibrationResult: CalibrationResult
  ): ITextWithVoiceover | undefined {
    if (!content) {
      return undefined;
    }

    const { tooSoft, tooLoud, noMicSignal } = content.narrator.feedback;

    if (step === CalibrationStep.MicChanged) {
      return content.feedback.micChanged;
    }

    switch (calibrationResult) {
      case CalibrationResult.TooSoft:
        return sample(tooSoft);

      case CalibrationResult.TooLoud:
        return sample(tooLoud);

      case CalibrationResult.NoSignal:
      default:
        return sample(noMicSignal);
    }
  }

  private static calculateMicInteractionState(
    step: CalibrationStep,
    calibrationResult: CalibrationResult,
    sreStatus: SreStatus,
    sreSessionType: SreSessionType
  ): InteractionState {
    if (step === CalibrationStep.InteractiveIntro) {
      return InteractionState.Disabled;
    }

    if (step === CalibrationStep.Feedback) {
      if (calibrationResult === CalibrationResult.Ok) {
        return InteractionState.Correct;
      }

      if (calibrationResult !== CalibrationResult.None) {
        return InteractionState.Incorrect;
      }
    }

    if (step === CalibrationStep.TryAgain) {
      return InteractionState.Default;
    }

    if (
      sreStatus === SreStatus.Listening &&
      sreSessionType === SreSessionType.Calibration
    ) {
      return InteractionState.Highlighted;
    }

    if (
      step === CalibrationStep.Interactive &&
      sreSessionType !== SreSessionType.Calibration
    ) {
      return InteractionState.Default;
    }

    if (![SreStatus.Ready, SreStatus.Listening].includes(sreStatus)) {
      return InteractionState.Disabled;
    }

    return InteractionState.Default;
  }

  private static calculateCalibrationFeedback(
    step: CalibrationStep,
    calibrationResult: CalibrationResult
  ): CalibrationFeedback {
    if (step === CalibrationStep.MicFix || step === CalibrationStep.TryAgain) {
      return CalibrationFeedback.Negative;
    }

    if (step === CalibrationStep.Feedback) {
      switch (calibrationResult) {
        case CalibrationResult.None:
          return CalibrationFeedback.Neutral;
        case CalibrationResult.Ok:
          return CalibrationFeedback.Positive;
        default:
          return CalibrationFeedback.Negative;
      }
    }

    return CalibrationFeedback.Neutral;
  }
}
