export interface ITimingAction {
  /** Action type to listen for */
  readonly type: string;
  /** Optional: Only listen for given action type where payload contains these key/values */
  readonly payload?: { [key: string]: any };
}

export class Timing {
  public startTime = new Date();

  public endTime?: Date;

  public constructor(
    public readonly name: string,
    public readonly startAction: ITimingAction,
    public readonly endAction: ITimingAction
  ) {}
}
