import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import not_enrolledPng from './not_enrolled.png';

export class NotEnrolledModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    getTeacherVO: true,
    headline: 'You are not enrolled in\nLexia English Language Development.',
    id: 'NotEnrolledModal',
    image: not_enrolledPng,
    text: 'You might be enrolled in another program.\n  Please get your teacher.',
    title: 'Oops!',
    type: ModalType.Error
  });
}
