import { Column } from '../../layout';
import {
  GlossyButton,
  IGlossyButtonProps
} from '../glossy-button/GlossyButton';
import {
  IUserControlButtonStyleOverride,
  UserControlButtonSize,
  UserControlButtonStyles
} from './UserControlButton.styles';

export interface IUserControlButtonProps
  extends Omit<IGlossyButtonProps, 'styleOverride'> {
  size?: UserControlButtonSize;
  styleOverride?: IUserControlButtonStyleOverride;
}

export function UserControlButton(props: IUserControlButtonProps) {
  const {
    size = UserControlButtonSize.Large,
    styleOverride,
    ...glossyButtonProps
  } = props;
  const styles = UserControlButtonStyles.build(size, styleOverride);

  return (
    <Column style={styles.container}>
      <GlossyButton
        {...glossyButtonProps}
        styleOverride={styles.buttonStyleOverride}
      />
    </Column>
  );
}
UserControlButton.displayName = 'UserControlButton';
