import { IPlacement } from '@lexialearning/lobo-common';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { PlacementActionType } from './placement-redux.model';

export const PlacementAction = {
  load: createAsyncAction(
    PlacementActionType.Load,
    PlacementActionType.LoadSuccess,
    PlacementActionType.LoadFailure
  )<void, IPlacement, Error>(),
  loadForDeepLink: createAction(PlacementActionType.LoadForDeepLink)(),
  unloaded: createAction(PlacementActionType.Unloaded)(),
  updateHasPlayedLookbackIntro: createAction(
    PlacementActionType.UpdateHasPlayedLookbackIntro
  )()
};

export type PlacementActions = ActionType<typeof PlacementAction>;

export type PlacementActionLoad = ActionType<
  typeof PlacementAction.load.request
>;

export type PlacementActionLoadSuccess = ActionType<
  typeof PlacementAction.load.success
>;

export type PlacementActionLoadForDeepLink = ActionType<
  typeof PlacementAction.loadForDeepLink
>;

export type PlacementActionUpdateHasPlayedLookbackIntro = ActionType<
  typeof PlacementAction.updateHasPlayedLookbackIntro
>;

export type PlacementActionUnloaded = ActionType<
  typeof PlacementAction.unloaded
>;
