import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { InteractionState } from 'common-styles';
import { Image } from '../image';
import { TextPill } from '../text-pill';
import { CardStyles, ICardStyleOverride } from './Card.styles';

export interface ICardProps {
  accessibilityLabel: string | undefined;
  imageSource?: string;
  text?: string;
  interactionState?: InteractionState;
  styleOverride?: ICardStyleOverride;
}

export function Card(props: ICardProps) {
  const {
    accessibilityLabel,
    text,
    imageSource,
    interactionState,
    styleOverride
  } = props;
  const styles = CardStyles.build(styleOverride);

  if (!imageSource) {
    return null;
  }

  return (
    <>
      <Image
        style={styles.image}
        source={imageSource}
        accessibilityLabel={accessibilityLabel}
        importantForAccessibility={
          text
            ? ImportantForAccessibility.NoHideDescendants
            : ImportantForAccessibility.Yes
        }
      />
      {!!text && (
        <TextPill
          interactionState={interactionState}
          styleOverride={styles.label}
        >
          {text}
        </TextPill>
      )}
    </>
  );
}
Card.displayName = 'Card';
