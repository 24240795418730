import { Text } from 'common-ui';
import { CompletionSentenceFragmentStyles } from './CompletionSentenceFragment.styles';

export interface ICompletionSentenceFragmentProps {
  text: string;
  idx: number;
}

export function CompletionSentenceFragment(
  props: ICompletionSentenceFragmentProps
) {
  const { text, idx } = props;
  const styles = CompletionSentenceFragmentStyles.get();
  const textSplit = text.split(/\s/);

  const fragmentArray = textSplit
    .filter(t => t.length)
    .map((t, i) => (
      <Text key={`sentenceText${idx}.${i}`} style={styles}>
        {t}
      </Text>
    ));

  return <>{fragmentArray}</>;
}
CompletionSentenceFragment.displayName = 'CompletionSentenceFragment';
