import { ActionType, createAction } from 'typesafe-actions';
import { TransitionActionType } from './transition.model';

export const TransitionAction = {
  actToEncounter: createAction(TransitionActionType.ActToEncounter)(),
  avatarToNext: createAction(TransitionActionType.AvatarToNext)(),
  calibrationToNext: createAction(TransitionActionType.CalibrationToNext)(),
  educatorToEncounter: createAction(
    TransitionActionType.EducatorToEncounter
  )<ITransitionActionEducatorToEncounterPayload>(),
  gettingStartedToCalibration: createAction(
    TransitionActionType.GettingStartedToCalibration
  )(),
  homeToEncounter: createAction(
    TransitionActionType.HomeToEncounter
  )<ITransitionActionHomeToEncounterPayload>(),
  homeToLevel: createAction(TransitionActionType.HomeToLevel)(),
  homeToNext: createAction(TransitionActionType.HomeToNext)(),
  introductionToNext: createAction(TransitionActionType.IntroductionToNext)(),
  levelCompleteToNext: createAction(TransitionActionType.LevelCompleteToNext)(),
  levelIntroToLevel: createAction(TransitionActionType.LevelIntroToLevel)(),
  loginToIntroduction: createAction(
    TransitionActionType.LoginToIntroduction
  )<ITransitionActionLoginToIntroductionPayload>(),
  onboardingToPlacement: createAction(
    TransitionActionType.OnboardingToPlacement
  )(),
  placementCompleteToLevel: createAction(
    TransitionActionType.PlacementCompleteToLevel
  )()
};
export interface ITransitionActionEducatorToEncounterPayload {
  roundId: string;
  unitId: string;
}
export interface ITransitionActionHomeToEncounterPayload {
  activityId: string;
}
export interface ITransitionActionLoginToIntroductionPayload {
  isSso: boolean;
  minutesSinceLastLogin: number | undefined;
}
export type TransitionActions = ActionType<typeof TransitionAction>;
export type TransitionActionActToEncounter = ActionType<
  typeof TransitionAction.actToEncounter
>;
export type TransitionActionAvatarToNext = ActionType<
  typeof TransitionAction.avatarToNext
>;
export type TransitionActionCalibrationToNext = ActionType<
  typeof TransitionAction.calibrationToNext
>;
export type TransitionActionEducatorToEncounter = ActionType<
  typeof TransitionAction.educatorToEncounter
>;
export type TransitionActionGettingStartedToCalibration = ActionType<
  typeof TransitionAction.gettingStartedToCalibration
>;
export type TransitionActionHomeToEncounter = ActionType<
  typeof TransitionAction.homeToEncounter
>;
export type TransitionActionHomeToLevel = ActionType<
  typeof TransitionAction.homeToLevel
>;
export type TransitionActionHomeToNext = ActionType<
  typeof TransitionAction.homeToNext
>;
export type TransitionActionLevelIntroToLevel = ActionType<
  typeof TransitionAction.levelIntroToLevel
>;
export type TransitionActionIntroductionToNext = ActionType<
  typeof TransitionAction.introductionToNext
>;
export type TransitionActionLevelCompleteToNext = ActionType<
  typeof TransitionAction.levelCompleteToNext
>;
export type TransitionActionLoginToIntroduction = ActionType<
  typeof TransitionAction.loginToIntroduction
>;
export type TransitionActionOnboardingToPlacement = ActionType<
  typeof TransitionAction.onboardingToPlacement
>;
export type TransitionActionPlacementCompleteToLevel = ActionType<
  typeof TransitionAction.placementCompleteToLevel
>;
