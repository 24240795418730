import {
  ILoggingConfig as IUniILoggingConfig,
  ILogRocketConfig as IUniLogRocketConfig
} from '@lexialearning/utils-react';

export interface ILoggingConfig extends IUniILoggingConfig {
  logRocket: ILogRocketConfig;
}

export interface ILogRocketConfig extends IUniLogRocketConfig {
  enabled: boolean;
}

export const LOGGER_CONFIG_KEY = 'logger';
