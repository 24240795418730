import { AccessibilityRole } from '@lexialearning/common-ui';
import {
  AvatarLayeredImages,
  IAvatarLayeredImagesProps
} from './layered-images/AvatarLayeredImages';
import { AvatarSilhouetteSvg } from './silhouette/AvatarSilhouette.lx-svg';
import { AvatarStyles } from './Avatar.styles';
import { AvatarType } from './avatar.model';
import { ProfileSelector } from 'services/profile';
import { Types } from '@lexialearning/reactxp';
import { View } from '../view';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

interface IAvatarProps {
  accessibilityLabel?: string;
  styleOverride?: Types.ViewStyle;
  type: AvatarType;
}

const AvatarImagesWithFallback = (props: IAvatarLayeredImagesProps) =>
  AvatarLayeredImages(props) || AvatarSilhouetteSvg(props);

export function Avatar(props: IAvatarProps) {
  const {
    accessibilityLabel: accessibilityLabelProp,
    styleOverride,
    type
  } = props;

  const hasCreatedAvatar = useSelector(ProfileSelector.hasCreatedAvatar);
  const imageTypeLabel = type === AvatarType.Full ? '' : type;
  const accessibilityLabel =
    accessibilityLabelProp ||
    `Student Avatar ${capitalize(imageTypeLabel)}${
      hasCreatedAvatar ? '' : ' Silhouette'
    }`;

  const styles = AvatarStyles.build(type, styleOverride);

  return (
    <View
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={AccessibilityRole.Image}
      style={styles.container}
      testId={Avatar.displayName}
    >
      <AvatarImagesWithFallback styles={styles} type={type} />
      {/* ⬇ border had to be added via a separate component because react-native allows contents to overflow the border when the border width is not heterogenous on all sides */}
      {type === AvatarType.Thumbnail && <View style={styles.thumbnailBorder} />}
    </View>
  );
}

Avatar.displayName = 'Avatar';
