import { UnitType } from '@lexialearning/lobo-common/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterService, RoutePath } from 'router-service';
import { ProgramContextSelector } from 'curriculum-services';
import {
  UnitActionLoadSuccess,
  UnitActionType,
  UnitSelector
} from 'services/curriculum-services/unit/redux';
import { UserInfoButtonType } from '../app-shell.model';
import {
  AppShellAction,
  AppShellActionNotifyNavigationHistoryUpdate,
  AppShellActionSetUserInfoButtonType
} from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

export function updateUserInfoButtonTypeEpic(
  action$: Observable<
    UnitActionLoadSuccess | AppShellActionNotifyNavigationHistoryUpdate
  >,
  state$: StateObservable<unknown>
): Observable<AppShellActionSetUserInfoButtonType> {
  return action$.pipe(
    ofType(
      UnitActionType.LoadSuccess,
      AppShellActionType.NotifyNavigationHistoryUpdate
    ),
    map(() => {
      const isOnboardingOrPlacement =
        ProgramContextSelector.isOnboardingOrPlacement(state$.value);
      const isOnHomePage = RouterService.isOnRoute(RoutePath.Home);
      const isOnUnitPage = RouterService.isOnRoute(RoutePath.Rounds);
      const unitType = isOnUnitPage
        ? UnitSelector.getType(state$.value)
        : UnitType.Unknown;

      const buttonType = isOnboardingOrPlacement
        ? UserInfoButtonType.Placement
        : isOnHomePage
        ? UserInfoButtonType.Minimal
        : unitType === UnitType.PresentationOfKnowledge
        ? UserInfoButtonType.PresentationOfKnowledge
        : UserInfoButtonType.Standard;

      return AppShellAction.setUserInfoButtonType(buttonType);
    })
  );
}
updateUserInfoButtonTypeEpic.displayName = 'updateUserInfoButtonTypeEpic';
