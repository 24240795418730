import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import { DeviceSetupConfirmationModal } from 'feature-areas/login/device-setup-form';
import { SpinnerHandler } from 'spinner-handler';
import {
  CustomerAction,
  CustomerActionSettingsLoaded,
  CustomerActionSetupDeviceWithEmailFailure,
  CustomerActionSetupDeviceWithEmailRequest,
  CustomerActionSetupDeviceWithEmailSuccess,
  CustomerActionType
} from '../redux';
import {
  DeviceSetupLocalStorageKeys,
  IDeviceSetupDependencies
} from './device-setup-epic.model';

export type SetupDeviceWithEmailOutputActions =
  | CustomerActionSetupDeviceWithEmailSuccess
  | CustomerActionSetupDeviceWithEmailFailure
  | CustomerActionSettingsLoaded
  | AppShellActionShowModal;

export function setupDeviceFromUserActionEpic(
  action$: Observable<CustomerActionSetupDeviceWithEmailRequest>,
  _state$: StateObservable<unknown>,
  deps: IDeviceSetupDependencies
): Observable<SetupDeviceWithEmailOutputActions> {
  return action$.pipe(
    ofType(CustomerActionType.SetupDeviceWithEmail),
    mergeMap(async action => {
      try {
        SpinnerHandler.requestSpinner();
        const { customerApi, localStorageService } = deps;
        const { email } = action.payload;
        const customerInfo = await customerApi.getCustomerByEmail(email);

        await localStorageService.setItem(
          DeviceSetupLocalStorageKeys.CustomerCode,
          customerInfo.customerCode
        );

        const { settings, ...customer } = customerInfo;

        return [
          CustomerAction.setupDeviceWithEmail.success(customer),
          CustomerAction.settingsLoaded(settings),
          AppShellAction.showModal({ id: DeviceSetupConfirmationModal.ModalId })
        ];
      } catch (error) {
        return maybeHandleError(error);
      } finally {
        SpinnerHandler.dismissSpinner();
      }
    }),
    mergeMap(action => action) // TODO: fix this https://jira.lexialearning.com/browse/LOBO-11373
  );
}

function maybeHandleError(
  error: Error
): CustomerActionSetupDeviceWithEmailFailure[] {
  if (
    error instanceof LexiaError &&
    error.standardCode === LexiaStandardErrorCode.UnknownCustomer
  ) {
    return [CustomerAction.setupDeviceWithEmail.failure(error)];
  }

  throw error;
}

setupDeviceFromUserActionEpic.displayName = 'setupDeviceFromUserActionEpic';
