import { SceneName, StormAssets } from 'services/storm-lobo/StormAssets';
import {
  IMaterialDefinition,
  ISceneDefinition,
  SceneDefinitionBuilder
} from 'storm';
import {
  LevelCompleteSceneElementName,
  LevelCompleteSceneMaterialName
} from './level-complete-scene.model';

export class LevelCompleteSceneDefinitionBuilder {
  public static readonly ScenePath = '/scenes/level-complete/level_complete.sg';

  public static create(level: string): LevelCompleteSceneDefinitionBuilder {
    const materials: IMaterialDefinition[] = [
      {
        name: LevelCompleteSceneMaterialName.Certificate,
        texturePaths: [`/scenes/images/level-complete/l${level}_complete.tx`]
      }
    ];

    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.LevelComplete,
      this.ScenePath,
      { materials }
    );

    return new LevelCompleteSceneDefinitionBuilder(definition, level);
  }

  private constructor(
    public readonly definition: ISceneDefinition,
    private readonly level: string
  ) {}

  public withBackground(): LevelCompleteSceneDefinitionBuilder {
    const background = SceneDefinitionBuilder.create(
      LevelCompleteSceneElementName.Background,
      `${StormAssets.Backgrounds}/level/level_${this.level}_background.sg`
    ).definition;
    this.definition.elements.push(background);

    return this;
  }

  public withBackgroundOverlay(): LevelCompleteSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      LevelCompleteSceneElementName.BackgroundOverlay,
      StormAssets.BackgroundOverlays.Stars
    ).definition;
    this.definition.elements.push(def);

    return this;
  }
}
