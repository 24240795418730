import memoizeOne from 'memoize-one';
import {
  AnimatableCSSProperty,
  Color,
  LoboAnimatedValue,
  ZIndex,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import { ThemeType } from 'theme';
import { IMarkerAnimations, MeterMarkerHelper } from '../../MeterMarker.helper';
import {
  MeterMarkerProgressStatus,
  MeterMarkerType
} from '../../meterMarker.model';
import { Position } from '@lexialearning/common-ui';

interface IPipAnimations extends IMarkerAnimations {
  animateIntro?: Types.Animated.CompositeAnimation;
  recycleFlash: Types.Animated.CompositeAnimation;
}

export class PipAnimatedStyles {
  private readonly SkipOpacity = 0.3;
  private readonly PipSize = 18;
  private readonly PipRadius = this.PipSize / 2;
  private readonly animations: IPipAnimations;
  private readonly pipBgColorValue: LoboAnimatedValue;
  private readonly pipScaleValue: LoboAnimatedValue;
  private readonly pipScaleXValue: LoboAnimatedValue;
  private readonly pipOpacityValue: LoboAnimatedValue;
  private readonly ringScaleValue: LoboAnimatedValue;
  private readonly ringOpacityValue: LoboAnimatedValue;

  private readonly styles: {
    container: Types.ViewStyle;
    pipScale: Types.ViewStyle;
    pipScaleAnimated: Types.AnimatedViewStyle;
    pipColor: Types.ViewStyle;
    pipColorAnimated: Types.AnimatedViewStyle;
    ringScale: Types.ViewStyle;
    ringScaleAnimated: Types.AnimatedViewStyle;
    ringOpacity: Types.ViewStyle;
    ringOpacityAnimated: Types.AnimatedViewStyle;
  } = {
    container: {
      backgroundColor: Color.Black,
      borderRadius: this.PipRadius,
      height: this.PipSize,
      width: this.PipSize
    },
    pipColor: {},
    pipColorAnimated: {
      backgroundColor: undefined,
      opacity: undefined
    },
    pipScale: {
      height: this.PipSize,
      width: this.PipSize
    },
    pipScaleAnimated: {
      transform: []
    },
    ringOpacity: {
      height: this.PipSize,
      width: this.PipSize
    },
    ringOpacityAnimated: {
      opacity: undefined
    },
    ringScale: {
      height: this.PipSize,
      position: Position.Absolute,
      width: this.PipSize,
      zIndex: ZIndex.Shared // needed for overflow
    } as Types.ViewStyle,
    ringScaleAnimated: {
      transform: []
    }
  };

  constructor(
    themeType: ThemeType,
    progress: MeterMarkerProgressStatus,
    delayStart: number,
    isRecyclePass?: boolean
  ) {
    this.pipScaleValue = loboAnimated.createValue(1);
    this.pipScaleXValue = loboAnimated.createValue(1);
    this.pipBgColorValue = loboAnimated.createValue(
      isRecyclePass ? MeterMarkerProgressStatus.RecycleSkip : progress
    );
    this.pipOpacityValue = loboAnimated.createValue(
      isRecyclePass ? this.SkipOpacity : 1
    );
    this.ringScaleValue = loboAnimated.createValue(1.5);
    this.ringOpacityValue = loboAnimated.createValue(0);

    const bgColorInterpolation =
      MeterMarkerHelper.getMarkerBgColorInterpolation(
        MeterMarkerType.Pip,
        this.pipBgColorValue,
        themeType,
        isRecyclePass
      );
    this.styles.pipScaleAnimated.transform = [
      { scale: this.pipScaleValue },
      { scaleX: this.pipScaleXValue }
    ];
    this.styles.pipColorAnimated.backgroundColor = bgColorInterpolation;
    this.styles.ringScaleAnimated.transform = [{ scale: this.ringScaleValue }];
    this.styles.ringOpacityAnimated.opacity = this.ringOpacityValue;

    this.animations = {
      animateCompleted: this.createCompletedPopAnimation(),
      animateIntro:
        progress === MeterMarkerProgressStatus.Active
          ? this.createIntroPopAnimation(delayStart)
          : undefined,
      recycleFlash: this.createRecycleFlashAnimation(delayStart, progress),
      setProgress: (_progress: MeterMarkerProgressStatus) => {
        this.pipBgColorValue.setValue(_progress);
      }
    };
  }

  private createCompletedPopAnimation() {
    // Using a 'speed' variable in order to be able to adjust the speed of the entire animation
    // by just changing one variable
    const speed = 0.45;
    const popOutCompleteScale = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        duration: 300 * speed,
        toValue: 1.6
      }),
      loboAnimated.timing(AnimatableCSSProperty.ScaleX, this.pipScaleXValue, {
        delay: 67 * speed,
        duration: 233 * speed,
        toValue: 0.24
      }),
      loboAnimated.timing(AnimatableCSSProperty.ScaleX, this.pipScaleXValue, {
        duration: 333 * speed,
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.ScaleX, this.pipScaleXValue, {
        duration: 300 * speed,
        toValue: 0.24
      }),
      loboAnimated.timing(AnimatableCSSProperty.ScaleX, this.pipScaleXValue, {
        duration: 333 * speed,
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        duration: 600 * speed,
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        duration: 33 * speed,
        toValue: 0.9
      }),
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        duration: 67 * speed,
        toValue: 1
      })
    ]);

    const toPopColor = (delay: number) =>
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          delay,
          duration: 67 * speed,
          toValue: MeterMarkerProgressStatus.CompletedPop1
        }
      );
    const toCompletedColor = (delay: number) =>
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          delay,
          duration: 67 * speed,
          toValue: MeterMarkerProgressStatus.Completed
        }
      );

    const popOutCompleteColor = loboAnimated.sequence([
      toCompletedColor(167 * speed),
      toPopColor(0),
      toCompletedColor(233 * speed),
      toPopColor(267 * speed),
      toCompletedColor(233 * speed),
      toPopColor(267 * speed),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          duration: 367 * speed,
          toValue: MeterMarkerProgressStatus.CompletedPop2
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          duration: 467 * speed,
          toValue: MeterMarkerProgressStatus.Completed
        }
      )
    ]);

    const ringScale = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.ringScaleValue, {
        delay: 1833 * speed,
        duration: 933 * speed,
        toValue: 3.1
      })
    ]);

    const ringOpacity = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.ringOpacityValue,
        {
          delay: 1733 * speed,
          duration: 433 * speed,
          toValue: 1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.ringOpacityValue,
        {
          duration: 600,
          toValue: 0
        }
      )
    ]);

    return loboAnimated.parallel([
      popOutCompleteScale,
      popOutCompleteColor,
      ringScale,
      ringOpacity
    ]);
  }

  private createIntroPopAnimation(delayStart: number) {
    const popOutScale = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        delay: delayStart,
        duration: MeterMarkerHelper.PopDurationMs * 0.4,
        toValue: 1.5
      }),
      loboAnimated.timing(AnimatableCSSProperty.Scale, this.pipScaleValue, {
        duration: MeterMarkerHelper.PopDurationMs * 0.1,
        toValue: 1.75
      })
    ]);

    const popOutColor = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.pipBgColorValue,
      {
        duration: MeterMarkerHelper.PopDurationMs * 0.5,
        toValue: MeterMarkerProgressStatus.ActivePop
      }
    );

    const popInScale = loboAnimated.timing(
      AnimatableCSSProperty.Scale,
      this.pipScaleValue,
      {
        delay: 200,
        duration: MeterMarkerHelper.PopDurationMs * 0.5,
        toValue: 1
      }
    );
    const popInColor = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.pipBgColorValue,
      {
        duration: MeterMarkerHelper.PopDurationMs * 0.5,
        toValue: MeterMarkerProgressStatus.Active
      }
    );

    const popOut = loboAnimated.parallel([popOutScale, popOutColor]);
    const popIn = loboAnimated.parallel([popInScale, popInColor]);

    return loboAnimated.sequence([popOut, popIn]);
  }

  private createRecycleFlashAnimation(
    delayStart: number,
    progress: MeterMarkerProgressStatus
  ) {
    const flashAnimationMs = 2000;
    // There are 3 flashes, each with 2 parts (in and out), so 6 total flash segments
    const segmentDurationMs = flashAnimationMs / 6;

    const delay = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.pipOpacityValue,
      {
        delay: delayStart,
        toValue: 1
      }
    );

    const pipOpacityFlash = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Opacity, this.pipOpacityValue, {
        duration: segmentDurationMs,
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Opacity, this.pipOpacityValue, {
        duration: segmentDurationMs,
        toValue: this.SkipOpacity
      })
    ]);

    const pipColorFlash = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          duration: segmentDurationMs,
          toValue: MeterMarkerProgressStatus.RecycleFlash
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.pipBgColorValue,
        {
          duration: segmentDurationMs,
          toValue: MeterMarkerProgressStatus.RecycleSkip
        }
      )
    ]);

    const pipFinalOpacity = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.pipOpacityValue,
      {
        duration: segmentDurationMs,
        toValue: 1
      }
    );

    const pipFinalColor = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.pipBgColorValue,
      {
        duration: segmentDurationMs,
        toValue: progress
      }
    );

    return loboAnimated.sequence([
      delay,
      loboAnimated.parallel([pipOpacityFlash, pipColorFlash]),
      loboAnimated.parallel([pipOpacityFlash, pipColorFlash]),
      loboAnimated.parallel([pipOpacityFlash, pipColorFlash]),
      loboAnimated.parallel([pipFinalOpacity, pipFinalColor])
    ]);
  }

  private readonly buildMemoized = memoizeOne(
    (progressStatus: MeterMarkerProgressStatus, isRecyclePass: boolean) => {
      const borderColor =
        isRecyclePass || progressStatus !== MeterMarkerProgressStatus.Upcoming
          ? Color.NearWhite
          : Color.Black;
      const borderWidth = [
        MeterMarkerProgressStatus.Completed,
        MeterMarkerProgressStatus.RecycleSkip
      ].includes(progressStatus)
        ? 2
        : 1;

      return {
        ...this.styles,
        pipColor: {
          borderColor,
          borderRadius: this.PipRadius,
          borderWidth,
          height: this.PipSize,
          width: this.PipSize
        }
      };
    }
  );

  public build(
    progressStatus: MeterMarkerProgressStatus,
    isRecyclePass: boolean
  ) {
    return this.buildMemoized(progressStatus, isRecyclePass);
  }

  public getAnimations() {
    return this.animations;
  }
}
