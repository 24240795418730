import { SceneName, StormAssets } from 'services/storm-lobo/StormAssets';
import {
  IMaterialDefinition,
  ISceneDefinition,
  SceneDefinitionBuilder
} from 'storm';
import {
  ProgramCompleteSceneElementName,
  ProgramCompleteSceneMaterialName
} from './program-complete-scene.model';

export class ProgramCompleteSceneDefinitionBuilder {
  public static readonly ScenePath =
    '/scenes/program-complete/program_complete.sg';

  public static create(
    levelNum: string
  ): ProgramCompleteSceneDefinitionBuilder {
    const materials: IMaterialDefinition[] = [
      {
        name: ProgramCompleteSceneMaterialName.Certificate,
        texturePaths: [`/scenes/images/level-complete/l${levelNum}_complete.tx`]
      }
    ];

    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.ProgramComplete,
      this.ScenePath,
      { materials }
    );

    return new ProgramCompleteSceneDefinitionBuilder(definition, levelNum);
  }

  private constructor(
    public readonly definition: ISceneDefinition,
    private readonly levelNum: string
  ) {}

  public withBackground(): ProgramCompleteSceneDefinitionBuilder {
    const background = SceneDefinitionBuilder.create(
      ProgramCompleteSceneElementName.Background,
      `${StormAssets.Backgrounds}/level/level_${this.levelNum}_background.sg`
    ).definition;
    this.definition.elements.push(background);

    return this;
  }

  public withBackgroundOverlay(): ProgramCompleteSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      ProgramCompleteSceneElementName.BackgroundOverlay,
      StormAssets.BackgroundOverlays.Stars
    ).definition;
    this.definition.elements.push(def);

    return this;
  }
}
