import { Image } from '../image';
import { Column } from '../layout';
import { SpotlightAreaLayout } from '../spotlight/Spotlight.styles';
import { InstructionalImageStyles } from './InstructionalImage.styles';

export interface IInstructionalImageProps {
  source: string;
  sysId: string;
  isHighlighted?: boolean;
  layout?: SpotlightAreaLayout;
  accessibilityLabel: string | undefined;
}

export function InstructionalImage(props: IInstructionalImageProps) {
  const {
    accessibilityLabel,
    source,
    isHighlighted = false,
    layout = SpotlightAreaLayout.Default
  } = props;
  const styles = InstructionalImageStyles.build(isHighlighted, layout);

  return (
    <Column style={styles.container}>
      <Image
        source={source}
        style={styles.image}
        accessibilityLabel={accessibilityLabel}
      />
    </Column>
  );
}
InstructionalImage.displayName = 'InstructionalImage';
