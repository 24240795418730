import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import {
  SreSelector,
  SreSessionActionCancel,
  SreSessionActionFinish,
  SreSessionActionListening,
  SreSessionActionListenResult,
  SreSessionActionType
} from '../redux';
import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { MicEnergyService } from '../mic-energy/MicEnergyService';

export function setMicEnergySessionTypeEpic(
  action$: Observable<
    | SreSessionActionListening
    | SreSessionActionCancel
    | SreSessionActionFinish
    | SreSessionActionListenResult
    | UserGlobalActionLogout
  >,
  state$: StateObservable<unknown>
): Observable<void> {
  return action$.pipe(
    ofType(
      SreSessionActionType.Listening,
      SreSessionActionType.Cancel,
      SreSessionActionType.Finish,
      SreSessionActionType.ListenResult,
      UserGlobalActionType.Logout
    ),
    tap(async () => {
      const sessionType = SreSelector.getSessionType(state$.value);

      MicEnergyService.setSessionType(sessionType);
    }),
    ignoreElements()
  );
}
setMicEnergySessionTypeEpic.displayName = 'setMicEnergySessionTypeEpic';
