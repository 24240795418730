import { TaskPhase, TaskTypeName } from '@lexialearning/lobo-common';
import { PredicateUtils } from '@lexialearning/utils';
import { difference } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InteractionState } from 'common-styles';
import {
  MicAction,
  MicActionSetInteractionState,
  MicSelector
} from 'common-ui';
import { ProgramContextSelector } from 'curriculum-services';
import { AppShellActionType } from 'feature-areas/shell';
import { RouterService } from 'router-service';
import { ScreenplaySelector } from 'screenplay';
import { SreSelector, SreSessionType, SreStatus } from 'sre';
import { TaskSelector } from 'task-components';
import { ChoralScreenplayInfo } from 'task-components/see-speak';
import { UpdateUserControlInteractionStateInputActionTypes } from './updateUserControlInteractionState.epic';
import { AppState } from 'services';

const SeeSpeakInputActionTypes = difference(
  UpdateUserControlInteractionStateInputActionTypes,
  [AppShellActionType.ShowModal, AppShellActionType.HideModal]
);
export const UpdateMicInteractionStateInputActionTypes = [
  ...SeeSpeakInputActionTypes,
  AppShellActionType.NotifyNavigationHistoryUpdate
];

/**
 * Sets mic interaction state to disabled upon navigating to new route
 * or per specific requirements for See Speak rounds
 */
export function updateMicInteractionStateEpic(
  action$: Observable<any>,
  state$: StateObservable<AppState>
): Observable<MicActionSetInteractionState> {
  return action$.pipe(
    // @ts-ignore: A spread argument must either have a tuple type or be passed to a rest parameter.  Function runs as expected
    ofType(...UpdateMicInteractionStateInputActionTypes),
    map(a => getInteractionState(state$.value, a.type)),
    filter(PredicateUtils.isDefined),
    filter(
      interactionState =>
        interactionState !== MicSelector.getStoredInteractionState(state$.value)
    ),
    map(MicAction.setInteractionState)
  );
}
updateMicInteractionStateEpic.displayName = 'updateMicInteractionStateEpic';

function getInteractionState(
  state: AppState,
  actionType: any
): InteractionState | undefined {
  // Mic should be disabled upon first navigating to any new route
  if (actionType === AppShellActionType.NotifyNavigationHistoryUpdate) {
    return InteractionState.Disabled;
  }

  return getSeeSpeakInteractionStateMaybe(state, actionType);
}

function getSeeSpeakInteractionStateMaybe(
  state: AppState,
  actionType: any
): InteractionState | undefined {
  const isOnRoundRoute = RouterService.isOnRoundRoute();
  const taskType = TaskSelector.getTaskContentMaybe(state)?.taskType;
  const isSeeSpeak = isOnRoundRoute && taskType === TaskTypeName.SeeSpeak;

  if (!isSeeSpeak || !SeeSpeakInputActionTypes.includes(actionType)) {
    return undefined;
  }

  const phase = TaskSelector.getPhase(state);
  const sreStatus = SreSelector.getStatus(state);
  const sessionType = SreSelector.getSessionType(state);
  const isReady = sreStatus === SreStatus.Ready;
  const isListeningToPrimedLF =
    sreStatus === SreStatus.Listening &&
    sessionType === SreSessionType.PrimedLanguageFrame;
  const isPlayingChoralCountdown =
    ScreenplaySelector.getActiveScreenplayId(state) ===
      ChoralScreenplayInfo.ScreenplayId && sreStatus !== SreStatus.Listening;
  const feedbackInteractionState = TaskSelector.getFeedbackInteractionState(
    ProgramContextSelector.getLastEvaluationResultMaybe(state)
  );

  if (phase === TaskPhase.Feedback) {
    return feedbackInteractionState;
  }

  if (
    phase === TaskPhase.Interactive &&
    (isReady || isListeningToPrimedLF) &&
    !isPlayingChoralCountdown
  ) {
    return InteractionState.Default;
  }

  return InteractionState.Disabled;
}
