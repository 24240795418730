import {
  ITask,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { WorkAreaLayout } from 'common-ui/components/work-area/WorkArea';
import { ThemeSize } from 'theme';
import { ITaskActionUpdateCanSubmitPayload } from './redux/Task.action';

export interface ITaskProps<T extends ITask = ITask, A extends object = object>
  extends ITaskBasicInfo<T> {
  evaluationResult: TaskEvaluationResult | undefined;
  themeSize: ThemeSize;
  workAreaLayout: WorkAreaLayout;
  onEvaluated(result: TaskEvaluationResult, answer: A): void;
  updateCanSubmit(cs: ITaskActionUpdateCanSubmitPayload): void;
}

export interface ITaskBasicInfo<T extends ITask = ITask> {
  taskContent: T;
  isInteractive: boolean;
  phase: TaskPhase;
}

export enum TaskReactAnimationName {
  InstructionUnitEntry = 'InstructionUnitEntry',
  InstructionUnitExit = 'InstructionUnitExit',
  StandardRoundEntry = 'StandardRoundEntry',
  StandardRoundExit = 'StandardRoundExit'
}
