import {
  IListenResult,
  SreSessionOptions,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { SreSessionActionType } from '../sre-redux.model';

export const SreSessionAction = {
  /** Stop the active SRE session, without processing results or sending soundlogs */
  cancel: createAsyncAction(
    SreSessionActionType.Cancel,
    SreSessionActionType.CancelSuccess,
    SreSessionActionType.CancelFailure
  )<void, void, void>(),
  /** Stop the active SRE session (results will be processed) */
  finish: createAsyncAction(
    SreSessionActionType.Finish,
    SreSessionActionType.FinishSuccess,
    SreSessionActionType.FinishFailure
  )<void, void, void>(),
  listen: createAction(SreSessionActionType.Listen)<SreSessionOptions>(),
  listenResult: createAction(
    SreSessionActionType.ListenResult
  )<IListenResultActionPayload>(),
  listening: createAction(SreSessionActionType.Listening)<SreSessionType>(),
  prime: createAsyncAction(
    SreSessionActionType.Prime,
    SreSessionActionType.PrimeSuccess,
    SreSessionActionType.PrimeFailure
  )<SreSessionOptions, void, void>()
};

export interface IListenResultActionPayload<
  R extends IListenResult = IListenResult
> {
  result: R;
  sessionOptions: SreSessionOptions;
}

export type SreSessionActions = ActionType<typeof SreSessionAction>;
export type SreSessionActionCancel = ActionType<
  typeof SreSessionAction.cancel.request
>;
export type SreSessionActionCancelSuccess = ActionType<
  typeof SreSessionAction.cancel.success
>;
export type SreSessionActionFinish = ActionType<
  typeof SreSessionAction.finish.request
>;
export type SreSessionActionFinishSuccess = ActionType<
  typeof SreSessionAction.finish.success
>;
export type SreSessionActionListen = ActionType<typeof SreSessionAction.listen>;
export type SreSessionActionListening = ActionType<
  typeof SreSessionAction.listening
>;
export type SreSessionActionListenResult = ActionType<
  typeof SreSessionAction.listenResult
>;
export type SreSessionActionPrime = ActionType<
  typeof SreSessionAction.prime.request
>;
export type SreSessionActionPrimeSuccess = ActionType<
  typeof SreSessionAction.prime.success
>;
export type SreSessionActionPrimeFailure = ActionType<
  typeof SreSessionAction.prime.failure
>;
