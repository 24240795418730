import { InteractionState } from 'common-styles';
import { ModalId } from 'shared-components/modals/modal.model';
import { IProgressMetersState } from '../progress-meters/redux/progress-meters-redux.model';

export interface IActiveModal<TProps extends object = object> {
  id: ModalId;
  props?: TProps;
}

export enum AppShellMode {
  Full = 'full',
  None = 'none',
  UtilitiesOnly = 'utilitiesOnly'
}

export enum ControlPanelLayout {
  Minimized = 'minimized',
  Standard = 'standard',
  AudioSupportOnly = 'audioSupportOnly'
}

export enum NavigationButtonType {
  Back = 'back',
  Logout = 'logout'
}

export enum UserInfoButtonType {
  Minimal = 'minimal',
  Standard = 'standard',
  Placement = 'placement',
  PresentationOfKnowledge = 'presentation-of-knowledge'
}

export enum ControlPanelButtonAnimation {
  DirectionsCallout = 'directionsCallout',
  PresentationCallout = 'presentationCallout'
}

export interface IUserControlInteractionState {
  directions: InteractionState;
  presentation: InteractionState;
  lookback: InteractionState;
}

export interface IAppShellState {
  base: IAppShellBaseState;
  progressMeters: IProgressMetersState;
}

export interface IAppShellBaseState {
  activeModal: IActiveModal | undefined;
  audioSupportDisabled: boolean;
  controlPanelLayout: ControlPanelLayout;
  mode: AppShellMode;
  navigationButtonType: NavigationButtonType;
  shouldCalloutLevelCertificates: boolean;
  shouldShowSkipButton: boolean;
  showSpinner: boolean;
  /**
   * Date/time value when loader/spinner was last shown
   */
  spinnerShowTimestamp: number;
  userControlInteractionState: IUserControlInteractionState;
  userInfoButtonType: UserInfoButtonType;
  utilityBarDisabled: boolean;
  utilityBarZIndexDisabled: boolean;
}
