import { IChoice } from '@lexialearning/lobo-common';
import * as React from 'react';
import { Row, View } from 'common-ui';
import { ImageChoice } from './ImageChoice';
import { ImageChoicesContainerStyles } from './ImageChoicesContainer.styles';

export interface IImageChoicesContainerProps {
  choices: IChoice[];
  isInstruction: boolean;
}

export const ImageChoicesContainer = React.memo(
  (props: IImageChoicesContainerProps) => {
    const { choices, isInstruction } = props;
    const styles = ImageChoicesContainerStyles.build(
      choices.length,
      isInstruction
    );

    return (
      <Row>
        {choices.map((item: IChoice, idx: number) => (
          <View key={idx} style={styles.choiceContainer}>
            <ImageChoice
              sysId={item.card.sysId}
              choicesCount={choices.length}
              isCorrectChoice={item.isCorrectChoice}
              imageSource={item.card.image?.imageSource}
              text={item.card.label}
              accessibilityLabel={item.card.image?.altText}
            />
          </View>
        ))}
      </Row>
    );
  }
);

ImageChoicesContainer.displayName = 'ImageChoicesContainer';
