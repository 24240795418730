import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';

export const ANIMATION_DURATION = 250;

export interface IPageAnimations {
  fadeIn: Types.Animated.CompositeAnimation;
  fadeOut: Types.Animated.CompositeAnimation;
  show: Types.Animated.CompositeAnimation;
}

type CustomDurations = Partial<Record<keyof IPageAnimations, number>>;

export interface IPageAnimatedValues {
  opacity: Types.AnimatedValue;
}

export class PageAnimatedStyles {
  public static createEntryExitAnimatedValues() {
    return {
      opacity: loboAnimated.createValue(0)
    };
  }

  public static createEntryExitAnimations(
    animatedValues: IPageAnimatedValues,
    customDurations?: CustomDurations
  ) {
    return {
      fadeIn: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          duration: customDurations?.fadeIn || ANIMATION_DURATION,
          easing: loboAnimated.Easing.Out(),
          toValue: 1
        }
      ),
      fadeOut: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          duration: customDurations?.fadeOut || ANIMATION_DURATION,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      ),
      show: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          duration: customDurations?.show || 0,
          toValue: 1
        }
      )
    };
  }
}
