import * as React from 'react';
import memoizeOne from 'memoize-one';
import { AnimatedView } from 'common-ui';
import { AppShellSelector } from '../redux/AppShell.selector';
import { AppState, Services } from 'services';
import { AudioSupport } from '../audio-support';
import { ControlPanelLayout } from '../redux/app-shell.model';
import { FadeAnimationAnimatedStyles } from 'common-ui/components/fade-animation/FadeAnimation.animated-styles';
import { ProgressMeters } from '../progress-meters';
import { SystemInfo } from 'utils';
import { connect } from 'react-redux';

export interface IControlPanelProps {
  layout: ControlPanelLayout;
}

export enum FadeAnimationType {
  FadeIn = 'FadeIn',
  FadeOut = 'FadeOut'
}

export class ControlPanelComponent extends React.PureComponent<IControlPanelProps> {
  public static readonly displayName = 'ControlPanel';

  private static readonly FadeDurationMS = 500;

  private readonly animatedStyles: FadeAnimationAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  public static getAnimationName(animationType: FadeAnimationType) {
    return `${ControlPanelComponent.displayName}_${animationType}`;
  }

  constructor(props: IControlPanelProps) {
    super(props);

    this.animatedStyles = new FadeAnimationAnimatedStyles({
      duration: ControlPanelComponent.FadeDurationMS
    });
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { show, hide } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: ControlPanelComponent.getAnimationName(FadeAnimationType.FadeIn),
        animation: show
      },
      {
        name: ControlPanelComponent.getAnimationName(FadeAnimationType.FadeOut),
        animation: hide
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  private readonly getRenderSettings = memoizeOne(
    (layout: ControlPanelLayout) => {
      const shouldRenderAudioSupport = [
        ControlPanelLayout.Standard,
        ControlPanelLayout.Minimized,
        ControlPanelLayout.AudioSupportOnly
      ].includes(layout);
      const shouldRenderProgressMeters =
        layout !== ControlPanelLayout.AudioSupportOnly;

      return {
        shouldRenderAudioSupport,
        shouldRenderProgressMeters
      };
    }
  );

  public render() {
    const { shouldRenderAudioSupport, shouldRenderProgressMeters } =
      this.getRenderSettings(this.props.layout);

    // FIXME: https://jira.lexialearning.com/browse/LOBO-14918
    // istanbul ignore next - temporary & trivial
    const styles = SystemInfo.isAndroid
      ? undefined
      : this.animatedStyles.get().containerAnimated;

    return (
      <AnimatedView
        animatedStyle={styles}
        testId={ControlPanelComponent.displayName}
      >
        {shouldRenderAudioSupport && <AudioSupport />}
        {shouldRenderProgressMeters && <ProgressMeters />}
      </AnimatedView>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: AppState) {
  return {
    layout: AppShellSelector.getControlPanelLayout(state)
  };
}

export const ControlPanel = connect(mapStateToProps)(ControlPanelComponent);
