import { Logger } from '@lexialearning/utils-react';
import { StateObservable } from 'redux-observable';
import { Observable, lastValueFrom } from 'rxjs';
import { filter, first, map, mergeMap, startWith } from 'rxjs/operators';
import { ICompletedLevel } from 'curriculum-services/program-context/epics/student-progress-api/student-progress-api-private.model';
import { ICurriculumDependencies } from 'curriculum-services/CurriculumDependencies';
import { Version } from 'services/app-config';
import { LevelSelector } from '../../level/redux';
import {
  ProgramContextSelector,
  StudentProgressApi
} from '../../program-context';
import {
  LevelsCompletedAction,
  LevelsCompletedActionLoadFailure,
  LevelsCompletedActionLoadSuccess,
  LevelsCompletedSelector
} from '../redux';
import { ILevelsCompletedStateWrapper } from '../redux/levels-completed-redux.model';
import {
  ILevelsCompletedLoaderOptions,
  LevelsCompletedLoader
} from './LevelsCompletedLoader';
import { LevelsStatusFactory } from './LevelsStatus.factory';
import {
  StudentPropertyAction,
  StudentPropertyActionLoadSuccess
} from '@lexialearning/student-api';
import { StudentApiHelper } from 'student-api';

/**
 * Load array of completed levels from the Student API. If student is onboarding
 * or in placement, bypass the API call since we know they haven't completed
 * anything yet. Transform into a full set of levels status to be stored in
 * redux.
 *
 * Rather than throwing any error from the API, log it and dispatch a failure
 * action that can be reduced to revert load status to NotLoaded.
 * Similarly, if the level number is unavailable (e.g. level content has yet
 * to load), avoid throwing an error and dispatch a failure instead.
 */
export function loadLevelsCompletedEpic(
  action$: Observable<StudentPropertyActionLoadSuccess>,
  state$: StateObservable<ILevelsCompletedStateWrapper>,
  { curriculumDependencies }: ICurriculumDependencies
): Observable<
  LevelsCompletedActionLoadSuccess | LevelsCompletedActionLoadFailure
> {
  const api = curriculumDependencies.studentProgressApi;

  return action$.pipe(
    filter(StudentPropertyAction.load.success.match),
    mergeMap(async action => {
      // Await this for Placement/Onboarding as well, even though it won't be used,
      // as we need levels loaded for later logic, and this denotes when levels
      // have loaded
      const levelNumber = await getLevelNumber(state$);

      if (ProgramContextSelector.isOnboardingOrPlacement(state$.value)) {
        // levelNumber is treated as undefined rather than tha magic 42
        return { completedLevelsArray: [], levelNumber: undefined };
      }

      const levelCertificatesViewed =
        StudentApiHelper.getLevelCertificatesViewed(
          action.payload.studentProperties
        );

      const completedLevelsArray = await loadLevelsCompletedMaybe(api);

      return completedLevelsArray
        ? { completedLevelsArray, levelCertificatesViewed, levelNumber }
        : undefined;
    }),
    map(info =>
      info
        ? LevelsStatusFactory.createFromApiResult(
            info.levelNumber,
            ProgramContextSelector.getFinalLevel(state$.value),
            info.completedLevelsArray,
            LevelsCompletedSelector.getLevelsStatus(state$.value),
            info.levelCertificatesViewed
          )
        : undefined
    ),
    map(completedLevels =>
      completedLevels
        ? LevelsCompletedAction.load.success(completedLevels)
        : LevelsCompletedAction.load.failure()
    )
  );
}
loadLevelsCompletedEpic.displayName = 'loadLevelsCompletedEpic';

async function getLevelNumber(
  state$: StateObservable<unknown>
): Promise<number> {
  return lastValueFrom(
    state$.pipe(
      startWith(state$.value),
      map(state => LevelSelector.getLevelNumberMaybe(state)),
      first(n => n !== undefined)
    )
  ) as Promise<number>;
}

async function loadLevelsCompletedMaybe(
  api: StudentProgressApi
): Promise<ICompletedLevel[] | undefined> {
  const { authToken, studentId } = api.createRequestInfo();
  const options: ILevelsCompletedLoaderOptions = {
    authToken,
    retryThrottleSeconds: api.config.fetchRetryThrottleSeconds,
    studentId,
    url: api.updateEndpoint,
    version: Version.Main
  };

  try {
    return await LevelsCompletedLoader.load(options);
  } catch (err) {
    void Logger.logError(err);

    return undefined;
  }
}
