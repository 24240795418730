import { ActSelector } from 'curriculum-services';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  TransitionAction,
  TransitionActionHomeToEncounter,
  TransitionActionHomeToLevel,
  TransitionActionHomeToNext
} from '../Transition.action';
import { TransitionActionType } from '../transition.model';

type OutputActions =
  | TransitionActionHomeToEncounter
  | TransitionActionHomeToLevel;

export function homeToNextTransitionEpic(
  action$: Observable<TransitionActionHomeToNext>,
  state$: StateObservable<unknown>
): Observable<OutputActions> {
  return action$.pipe(
    ofType(TransitionActionType.HomeToNext),
    map(() => {
      const act = ActSelector.getActMaybe(state$.value);

      // Act will have been loaded after login for default to last encounter,
      // if valid (see LevelSetupBuilder), in which case user will be sent
      // through the flow of homeToAct (the Act Page being a precursor to the
      // Encounter).
      // However, when returning to home from level page, act will be undefined,
      // and user will follow the expected flow of homeToLevel
      return !!act
        ? TransitionAction.homeToEncounter({ activityId: act.sysId })
        : TransitionAction.homeToLevel();
    })
  );
}
homeToNextTransitionEpic.displayName = 'homeToNextTransitionEpic';
