import {
  ScrollView as RxScrollView,
  Types as RxTypes
} from '@lexialearning/reactxp';
import * as React from 'react';
import { ScrollViewStyles } from './ScrollView.styles';
import { View } from './View';

export interface IScrollViewProps extends RxTypes.ScrollViewProps {
  id?: string;
}

export const ScrollView = React.forwardRef(
  (
    {
      accessibilityLabelledBy,
      accessibilityRole,
      children,
      id,
      testId,
      ...restProps
    }: IScrollViewProps,
    ref: any
  ) => (
    <RxScrollView ref={ref} {...restProps}>
      <View
        accessibilityRole={accessibilityRole}
        ariaLabelledBy={accessibilityLabelledBy}
        id={id}
        testId={testId || id}
        /* When scrolling on iOS, if you tap on an Image or a Text element inside the
          ScrollView and there's an onPress handler on an element higher in the tree
          (outside the ScrollView) it will trigger that handler instead of scrolling.
          This is here to prevent that. */
        stopPressPropagation
        style={ScrollViewStyles.build(restProps.horizontal)}
      >
        {children}
      </View>
    </RxScrollView>
  )
);

ScrollView.displayName = 'ScrollView';
