import { isEqual, last } from 'lodash';
import { matchRoutes } from 'react-router-dom';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RoutePath, RouterService } from 'router-service';
import {
  AppShellActionNotifyNavigationHistoryUpdate,
  AppShellActionType
} from 'shell';
import { PredicateUtils } from 'utils/PredicateUtils';
import {
  ISessionHistoryEvent,
  SessionHistoryPageType
} from '../../program-context.model';
import {
  ProgramContextAction,
  ProgramContextActionUpdateSessionHistory,
  ProgramContextSelector
} from '../../redux';

const routePageTypeMap = new Map<string, SessionHistoryPageType>([
  [RoutePath.Home, SessionHistoryPageType.Home],
  [RoutePath.Levels, SessionHistoryPageType.Level],
  [RoutePath.Acts, SessionHistoryPageType.Act],
  [RoutePath.Educator, SessionHistoryPageType.Educator],
  [RoutePath.EducatorTab, SessionHistoryPageType.Educator]
]);

export function sessionHistoryEpic(
  action$: Observable<AppShellActionNotifyNavigationHistoryUpdate>,
  state$: StateObservable<unknown>
): Observable<ProgramContextActionUpdateSessionHistory> {
  return action$.pipe(
    ofType(AppShellActionType.NotifyNavigationHistoryUpdate),
    map(getMatchedRoutePath),
    filter(PredicateUtils.isDefined),
    map(routePath => {
      const hist = maybeCreateSessionHistory(state$, routePath);

      return hist;
    }),
    filter(PredicateUtils.isDefined),
    map(sessionHistoryEvent =>
      ProgramContextAction.updateSessionHistory({
        sessionHistoryEvent
      })
    )
  );
}
sessionHistoryEpic.displayName = 'sessionHistoryEpic';

function getMatchedRoutePath(): string | undefined {
  const routes = matchRoutes(
    [
      RoutePath.Home,
      RoutePath.Levels,
      RoutePath.Acts,
      RoutePath.Educator,
      RoutePath.EducatorTab
    ].map(path => ({ path })),
    RouterService.activeRoute
  );

  return routes?.[0].route.path;
}

function maybeCreateSessionHistory(
  state$: StateObservable<unknown>,
  path: string
): ISessionHistoryEvent | undefined {
  const state = state$.value;
  const { levelId, activeActivityId: actId = '' } =
    ProgramContextSelector.getPosition(state);
  const encounterId = actId
    ? ProgramContextSelector.getActivityPosition(state).encounterId
    : '';
  const pageType = routePageTypeMap.get(path)!;

  const sessionHistoryEvent: ISessionHistoryEvent = {
    actId,
    encounterId,
    levelId,
    pageType
  };
  const sessionHistory = ProgramContextSelector.getSessionHistory(state$.value);
  const lastSessionHistoryEvent = last(sessionHistory);

  return !isEqual(sessionHistoryEvent, lastSessionHistoryEvent)
    ? sessionHistoryEvent
    : undefined;
}

export enum SessionHistoryEpicErrorCode {
  InvalidRoutePath = 401
}
