import { IListenResult } from '@lexialearning/lobo-common/main-model/sre';
import {
  SreSessionOptions,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre/sre.model';
import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { SreError, SreErrorType } from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { from, merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, takeWhile } from 'rxjs/operators';
import {
  SreConfigAction,
  SreSelector,
  SreSessionAction,
  SreSessionActionListen,
  SreSessionActionListening,
  SreSessionActionListenResult,
  SreSessionActionType
} from '../redux';
import { ISreDependencies } from './sre-epics.model';
import { AppState } from 'services';
import { LoboLogItemCategory } from 'logging';

export function listenEpic(
  action$: Observable<SreSessionActionListen>,
  state$: StateObservable<AppState>,
  deps: IListenEpicDependencies
): Observable<SreSessionActionListening | SreSessionActionListenResult> {
  return action$.pipe(
    ofType(SreSessionActionType.Listen),
    mergeMap(async action => {
      const sessionOptions = action.payload;

      await deps.sre.cancelCurrentSessionMaybe(
        sessionOptions.sessionType !== SreSessionType.LanguageFrame
      );

      const { resultPromise } = await deps.sre.listen(sessionOptions);

      return merge(
        of(SreSessionAction.listening(sessionOptions.sessionType)),
        listenResult$(resultPromise, state$, deps, sessionOptions)
      );
    }),
    mergeMap(r => r)
  );
}
listenEpic.displayName = 'listenEpic';

/**
 * Wait for the SRE listen session to complete (promise to resolve) and package
 * the result into a listenResult action.
 * But ignore any results that come in when the status is not listening, as
 * that means the session was cancelled (e.g. user back-buttoned out of the task)
 */
function listenResult$(
  resultPromise: Promise<IListenResult>,
  state$: StateObservable<unknown>,
  deps: IListenEpicDependencies,
  sessionOptions: SreSessionOptions
): Observable<SreSessionActionListenResult> {
  return from(resultPromise).pipe(
    map(result => SreSessionAction.listenResult({ result, sessionOptions })),
    takeWhile(() =>
      SreSelector.getIsListeningTo(sessionOptions.sessionType, state$.value)
    ),
    catchError((err: LexiaError) =>
      handleListenErrors(err, deps, sessionOptions.sessionType)
    )
  );
}

function handleListenErrors(
  error: LexiaError,
  deps: IListenEpicDependencies,
  sessionType: SreSessionType
): Observable<any> {
  const origError: SreError | undefined = (error.context as any)?.sreErr;
  const err = origError instanceof SreError ? origError.type : undefined;

  if (err === SreErrorType.SreSessionInterruptedBeforeActivation) {
    void deps.logger.log({
      category: LoboLogItemCategory.SessionInterruptedBeforeActivation,
      loggingLevel: LoggingLevel.Info,
      payload: { error: origError as SreError, sessionType },
      summary: 'Listen session was cancelled before it was activated'
    });

    return from([SreSessionAction.cancel.request()]);
  }
  if (
    // blocked mic beginning a session
    err === SreErrorType.EmscriptenMediastreamNotAllowedError ||
    // blocked mic during an active session
    err === SreErrorType.ListentaskNoMicrophone
  ) {
    return from([
      SreSessionAction.cancel.request(),
      SreConfigAction.setMicBlocked(true)
    ]);
  }

  throw error;
}

export interface IListenEpicDependencies extends ISreDependencies {
  logger: ILogger;
}
