import { Position } from '@lexialearning/common-ui';
import { AnimatableCSSProperty, Color, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';

const ANIMATION_DURATION = 500;

export interface ITitlePillBadgeAnimations {
  imageHide: Types.Animated.CompositeAnimation;
  flare: Types.Animated.CompositeAnimation;
}

export class TitlePillBadgeAnimatedStyles {
  private readonly baseStyles = {
    borderRadius: 20,
    height: 40,
    width: 40
  };

  private readonly styles: any = {
    container: {
      ...this.baseStyles,
      backgroundColor: Color.NearWhiteTransparent70,
      marginHorizontal: 4
    },
    flare: {
      position: Position.Absolute
    },
    image: {
      ...this.baseStyles,
      borderColor: Color.NearWhite,
      borderWidth: 2
    },
    imageContainer: {}
  };

  private readonly animations: ITitlePillBadgeAnimations;

  constructor() {
    const animatedValues = {
      flareRotate: loboAnimated.createValue(0),
      flareScale: loboAnimated.createValue(0),
      imageOpacity: loboAnimated.createValue(1)
    };

    const flareScaleAnimation = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        animatedValues.flareScale,
        {
          duration: ANIMATION_DURATION * 0.5,
          easing: loboAnimated.Easing.Linear(),
          toValue: 5
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        animatedValues.flareScale,
        {
          duration: ANIMATION_DURATION * 0.5,
          easing: loboAnimated.Easing.Linear(),
          toValue: 0
        }
      )
    ]);
    const flareRotateSingleAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Rotate,
      animatedValues.flareRotate,
      {
        duration: ANIMATION_DURATION * 0.05,
        easing: loboAnimated.Easing.Linear(),
        toValue: 1
      }
    );
    const flareRotateAnimation = loboAnimated.sequence(
      Array.from({ length: 20 }, () => flareRotateSingleAnimation)
    );
    const flareOpacityAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      animatedValues.imageOpacity,
      {
        delay: ANIMATION_DURATION * 0.5,
        duration: ANIMATION_DURATION * 0.5,
        easing: loboAnimated.Easing.Linear(),
        toValue: 1
      }
    );

    this.animations = {
      flare: loboAnimated.parallel([
        flareScaleAnimation,
        flareRotateAnimation,
        flareOpacityAnimation
      ]),
      imageHide: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.imageOpacity,
        {
          duration: 0,
          toValue: 0
        }
      )
    };
    this.styles.imageContainer.opacity = animatedValues.imageOpacity;
    this.styles.flare.transform = [
      { scale: animatedValues.flareScale },
      {
        rotate: loboAnimated.interpolate(
          animatedValues.flareRotate,
          [0, 1],
          ['0deg', '360deg']
        )
      }
    ];
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
