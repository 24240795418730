import { IContentBase, LoboContentType } from '@lexialearning/lobo-common/cms';
import { ProgramMode } from 'curriculum-services';
import { RoutePath, RouterService } from 'router-service';
import {
  IDeepLinkSubject,
  SubjectType
} from './position-builder-deep-link.model';

export abstract class DeepLinkSubjectFactory {
  public constructor(
    protected readonly id: string,
    protected readonly ancestors: IContentBase[]
  ) {}

  public appendToAncestors(): IDeepLinkSubject {
    const { id } = this;
    const type = this.determineSubjectType();
    const contentType = this.determineContentType(type);

    this.ancestors.push({ contentType, sysId: id, updatedAt: '' });

    const expectedAncestry = this.buildExpectedAncestry(type);
    const programMode = this.getProgramMode(type);

    return { expectedAncestry, id, programMode, type };
  }

  private getProgramMode(type: SubjectType) {
    switch (type) {
      case SubjectType.OnboardingRound:
      case SubjectType.OnboardingUnit:
        return ProgramMode.Onboarding;
      case SubjectType.PlacementRound:
      case SubjectType.PlacementUnit:
        return ProgramMode.Placement;
      default:
        return RouterService.isOnRoute([
          RoutePath.Educator,
          RoutePath.EducatorTab
        ])
          ? ProgramMode.Educator
          : ProgramMode.ActiveStudent;
    }
  }

  protected abstract determineSubjectType(): SubjectType;

  protected abstract determineContentType(
    subjectType: SubjectType
  ): LoboContentType;

  protected abstract buildExpectedAncestry(
    subjectType: SubjectType
  ): LoboContentType[];
}
