import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import cant_connectPng from './cant_connect.png';

export class CantConnectModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    headline: 'Cannot connect to the server.',
    id: 'CantConnectModal',
    image: cant_connectPng,
    title: 'Oops!',
    type: ModalType.Error
  });
}
