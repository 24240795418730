import { ILevel } from '@lexialearning/lobo-common/main-model';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Mount } from 'storm';
import { LevelActionType } from './level-redux.model';

export const LevelAction = {
  load: createAsyncAction(
    LevelActionType.Load,
    LevelActionType.LoadSuccess,
    LevelActionType.LoadFailure
  )<ILevelLoadActionPayload, ILevelLoadSuccessActionPayload, Error>(),

  mountVoiceovers: createAsyncAction(
    LevelActionType.MountVoiceovers,
    LevelActionType.MountVoiceoversSuccess,
    LevelActionType.MountVoiceoversFailure
  )<ILevelMountVoiceoversActionPayload | void, Mount, void>(),

  /**
   * Intended to signal the level is ready for display in the app
   */
  ready: createAction(LevelActionType.Ready)(),

  unload: createAsyncAction(
    LevelActionType.Unload,
    LevelActionType.UnloadSuccess,
    LevelActionType.UnloadFailure
  )<void, void, void>()
};

export interface ILevelMountVoiceoversActionPayload {
  levelTitle: string;
}

export interface ILevelLoadActionPayload {
  sysId: string;
}

export interface ILevelLoadSuccessActionPayload {
  levelContent: ILevel;
}

export type LevelActions = ActionType<typeof LevelAction>;
export type LevelActionLoad = ActionType<typeof LevelAction.load.request>;
export type LevelActionLoadSuccess = ActionType<
  typeof LevelAction.load.success
>;
export type LevelActionMountVoiceovers = ActionType<
  typeof LevelAction.mountVoiceovers.request
>;
export type LevelActionMountVoiceoversSuccess = ActionType<
  typeof LevelAction.mountVoiceovers.success
>;
export type LevelActionReady = ActionType<typeof LevelAction.ready>;
export type LevelActionUnload = ActionType<typeof LevelAction.unload.request>;
export type LevelActionUnloadSuccess = ActionType<
  typeof LevelAction.unload.success
>;
