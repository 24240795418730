import {
  StormAnimPlayer,
  StormNode,
  StormTexture
} from '@lexialearning/storm-react';
import { LexiaError } from '@lexialearning/utils';
import { IApplyTextureOptions } from '../materials';
import { TextureApplier } from '../materials/TextureApplier';

export class LxStormNode {
  public static readonly displayName = 'LxStormNode';

  public isAttached = false;

  private readonly loadedAnimations = new Set<string>();

  private readonly activeAnimationPlayers = new Map<string, StormAnimPlayer>();

  constructor(
    public readonly stormNode: StormNode,
    public readonly path: string,
    public readonly isStubbed?: boolean
  ) {}

  public applyTexture(
    texture: StormTexture,
    options: IApplyTextureOptions
  ): void {
    TextureApplier.applyTexture(texture, options, this.stormNode);
  }

  public attachChild(placeholderName: string, child: LxStormNode): LxStormNode {
    if (!placeholderName) {
      throw new LexiaError(
        'Cannot attach a child node that lacks an placeholder name',
        LxStormNode.displayName,
        LxStormNodeError.MissingPlaceholderInChild
      ).withContext({ child: child.stormNode.name, node: this.stormNode.name });
    }

    const childNode = this.stormNode.find(placeholderName);
    if (!childNode) {
      throw new LexiaError(
        `Unable to find placeholder '${placeholderName} in storm node ${this.stormNode.name}`,
        LxStormNode.displayName,
        LxStormNodeError.PlaceholderNotFound
      ).withContext({ child: child.stormNode.name, node: this.stormNode.name });
    }

    childNode.attachScene(child.stormNode);
    child.isAttached = true;

    return child;
  }

  /**
   * Play the specified animation and wait for it to finish.
   * (Note that looping animations will resolve immediately)
   */
  public async animate(request: IStormNodeAnimationRequest): Promise<void> {
    const animationPlayer = this.stormNode.playAnimation(
      request.name,
      request.loop ?? false,
      request.stopWhenFinished ?? true,
      request.blendTimeSeconds ?? 0
    );

    if (!animationPlayer) {
      throw new LexiaError(
        `Unable to play animation ${request.name} on ${this.stormNode.name}`,
        LxStormNode.displayName,
        LxStormNodeError.AnimationPlay
      ).withContext({ name: this.stormNode.name, request });
    }

    if (request.speed !== undefined) {
      animationPlayer.speed = request.speed;
    }
    if (request.timeAsPercent !== undefined) {
      animationPlayer.time =
        (animationPlayer.info!.length * request.timeAsPercent) / 100;
    } else if (request.time !== undefined) {
      animationPlayer.time = request.time;
    }

    this.activeAnimationPlayers.set(request.name, animationPlayer);

    if (request.loop) {
      animationPlayer.onended = () => {
        this.activeAnimationPlayers.delete(request.name);
      };

      return Promise.resolve();
    }

    return new Promise(resolve => {
      animationPlayer.onended = _ev => {
        this.activeAnimationPlayers.delete(request.name);
        resolve();
      };
    });
  }

  /**
   * Cancel the specified animation
   */
  public cancel(name: string): void {
    this.end(name);
    this.activeAnimationPlayers.delete(name);
  }

  public detach(): void {
    const detached = this.stormNode.detachFromParentScene();
    if (!detached) {
      throw new LexiaError(
        'Unable to detach scene from parent',
        LxStormNode.displayName,
        LxStormNodeError.DetachSceneFailure
      ).withContext({ node: this.stormNode.name });
    }
    this.isAttached = !detached;
  }

  public loadAnimations(path: string): void {
    if (!path || this.loadedAnimations.has(path)) {
      return;
    }

    if (!this.stormNode.loadAnimations(path)) {
      throw new LexiaError(
        `Unable to load requested animation ${path}`,
        LxStormNode.displayName,
        LxStormNodeError.AnimationLoadError
      ).withContext({ node: this.stormNode.name, path });
    }

    this.loadedAnimations.add(path);
  }

  /**
   * Pause the specified animation - set speed to 0
   */
  public pause(name: string): void {
    const player = this.activeAnimationPlayers.get(name);
    if (!player) {
      throw new LexiaError(
        `Attempting pause: No player found for ${name}`,
        LxStormNode.displayName,
        LxStormNodeError.PlayerNotFound
      );
    }
    player.speed = 0;
  }

  /**
   * End the specified animation - set time to info.length if defined and set speed to 0
   */
  public end(name: string): void {
    const player = this.activeAnimationPlayers.get(name);
    if (!player) {
      throw new LexiaError(
        `Attempting end: No player found for ${name}`,
        LxStormNode.displayName,
        LxStormNodeError.PlayerNotFound
      );
    }
    player.speed = 0;
    player.info?.length && (player.time = player.info.length);
  }

  /**
   * Resume the specified animation - set speed to given value
   */
  public resume(name: string, speed = 1): void {
    const player = this.activeAnimationPlayers.get(name);
    if (!player) {
      throw new LexiaError(
        `Attempting resume: No player found for ${name}`,
        LxStormNode.displayName,
        LxStormNodeError.PlayerNotFound
      );
    }
    player.speed = speed;
  }
}

export enum LxStormNodeError {
  AnimationLoadError = 'AnimationLoadError',
  AnimationPlay = 'AnimationPlay',
  DetachSceneFailure = 'DetachSceneFailure',
  MissingPlaceholderInChild = 'MissingPlaceholderInChild',
  PlayerNotFound = 'PlayerNotFound',
  PlaceholderNotFound = 'PlaceholderNotFound'
}

export interface IStormNodeAnimationRequest {
  /**
   * The name of the animation to play
   */
  name: string;
  /**
   * Whether the animation should loop or not (defaults to false)
   */
  loop?: boolean;
  /**
   * Whether to stop a partial animation when it finishes (defaults to false)
   */
  stopWhenFinished?: boolean;
  /**
   * The time it takes for the animation to fully transition in (defaults to 0 - i.e. hard transition)
   */
  blendTimeSeconds?: number;
  /**
   * The speed at which the animation playback should run. Set to 0 for just
   * positioning at a point in the timeline and leaving there. 0.5 for
   * half-rate, 2.0 for double-speed, etc. Negative values are ignored.
   */
  speed?: number;
  /**
   * The point in the timeline at which the animation should start or be set to.
   */
  time?: number;
  /**
   * The point in the timeline at which the animation should start as a percent
   * of the full timeline length. For example, setting it to 50 will start the
   * animation half-way through the full timeline.
   */
  timeAsPercent?: number;
}
