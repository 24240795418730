import { ILevelPosition, ILevelUpPosition } from '@lexialearning/lobo-common';
import { ActionType, createAction } from 'typesafe-actions';
import { PositionActionType } from './position-redux.model';

export const PositionAction = {
  /**
   * Clears the active activity ID to indicate no activity is currently
   * selected/active.
   */
  activityDeselected: createAction(PositionActionType.ActivityDeselected)(),

  /**
   * Sets the active activity ID to indicate an activity is now selected/active
   */
  activitySelected: createAction(
    PositionActionType.ActivitySelected
  )<IPositionActionActivitySelectedPayload>(),

  /**
   * Specifies the level position and the active activity id,
   * used in educator mode.
   */
  levelActivitySelected: createAction(
    PositionActionType.LevelActivitySelected
  )<ILevelPosition>(),

  /**
   * There is no longer an active level, and hence no program level position
   */
  levelPositionUnloaded: createAction(
    PositionActionType.LevelPositionUnloaded
  )(),

  /**
   * Specifies the full level position,
   * such as when leveling up.
   * The student should be positioned at the beginning of every activity.
   */
  levelStartPosition: createAction(
    PositionActionType.LevelStartPosition
  )<ILevelPosition>(),

  /**
   * Make the level up position active. This action request officially changes
   * the student's program position from the level they just completed to the
   * next level.
   */
  levelUp: createAction(PositionActionType.LevelUp)(),

  /**
   * The student has completed the level (a.k.a. leveled up), so prepare the
   * level up position, but without making it the active position. That is done
   * when the levelUp action is dispatched.
   */
  levelUpPositionPrepared: createAction(
    PositionActionType.LevelUpPositionPrepared
  )<ILevelUpPosition>()
};

export interface IPositionActionActivitySelectedPayload {
  activityId: string;
}

export type PositionActionActivityDeselected = ActionType<
  typeof PositionAction.activityDeselected
>;
export type PositionActionActivitySelected = ActionType<
  typeof PositionAction.activitySelected
>;
export type PositionActionLevelActivitySelected = ActionType<
  typeof PositionAction.levelActivitySelected
>;
export type PositionActionLevelPositionUnloaded = ActionType<
  typeof PositionAction.levelPositionUnloaded
>;
export type PositionActionLevelStartPosition = ActionType<
  typeof PositionAction.levelStartPosition
>;
export type PositionActionLevelUp = ActionType<typeof PositionAction.levelUp>;
export type PositionActionLevelUpPositionPrepared = ActionType<
  typeof PositionAction.levelUpPositionPrepared
>;

export type PositionActions = ActionType<typeof PositionAction>;
