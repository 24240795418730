import { AppShellAction } from 'feature-areas/shell/redux';
import { StudentInfoModal } from 'feature-areas/student-info';
import { useDispatch } from 'react-redux';
import { UserInfoButton } from './UserInfoButton';

interface IShowUserInfoButtonProps {
  disabled?: boolean;
}

export function ShowUserInfoButton(props: IShowUserInfoButtonProps) {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(AppShellAction.showModal({ id: StudentInfoModal.ModalId }));
  };

  return (
    <UserInfoButton
      disabled={props.disabled}
      onPress={onPress}
      testId={ShowUserInfoButton.displayName}
    />
  );
}
ShowUserInfoButton.displayName = 'ShowUserInfoButton';
