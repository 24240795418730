import { TopicCode } from '@lexialearning/lobo-common';
import { PressableView } from 'common-ui';
import { TopicButton } from '../topic-button';
import { ActColumnStyles } from './ActColumn.styles';
import { useActiveComponentState } from '@lexialearning/common-ui';
import { useCallback } from 'react';
import { IUseActiveComponentStateProps } from '@lexialearning/common-ui/lib/base-components/hooks/active-component-state/useActiveComponentState';

export interface IActColumnProps extends IUseActiveComponentStateProps {
  accessibilityLabel?: string;
  actId: string;
  topic: TopicCode;
  onPress: (actId: string) => void;
}

export function ActColumn(props: IActColumnProps) {
  const { accessibilityLabel, actId, topic, onPress } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const handleSelectAct = useCallback(() => {
    onPress(actId);
  }, [actId, onPress]);

  const styles = ActColumnStyles.get();

  return (
    <PressableView
      {...interactionHandlers}
      style={styles.column}
      onPress={handleSelectAct}
      testId={actId}
    >
      <TopicButton
        topic={topic}
        onPress={handleSelectAct}
        triggerState={activeComponentState}
        accessibilityLabel={accessibilityLabel}
      />
    </PressableView>
  );
}
ActColumn.displayName = 'ActColumn';
