import {
  BootstrapContentActionLoadSuccess,
  BootstrapContentActionType
} from 'services/bootstrapping/bootstrap-content';
import {
  BootstrappingActionType,
  IBootstrappingState
} from './bootstrapping-redux.model';
import { BootstrappingActionTypes } from './Bootstrapping.action';

const bootstrappingInitialState: IBootstrappingState = {
  isAutoLoginComplete: false,
  isConfigurationComplete: false,
  isDeepLinkPositionSetupComplete: false,
  isDeviceSetupComplete: false,
  isFeatureAreaBootstrapContentLoadingComplete: false
};

export function bootstrappingReducer(
  state = bootstrappingInitialState,
  action: BootstrappingActionTypes | BootstrapContentActionLoadSuccess
): IBootstrappingState {
  switch (action.type) {
    case BootstrappingActionType.AutoLoginComplete:
      return {
        ...state,
        isAutoLoginComplete: true
      };

    case BootstrappingActionType.Bootstrap:
      return {
        ...state,
        isConfigurationComplete: false
      };

    case BootstrappingActionType.ConfigurationComplete:
      return {
        ...state,
        isConfigurationComplete: true
      };

    case BootstrappingActionType.DeviceSetupComplete:
      return {
        ...state,
        isDeviceSetupComplete: true
      };

    case BootstrappingActionType.DeepLinkPositionSetupComplete:
      return {
        ...state,
        isDeepLinkPositionSetupComplete: true
      };

    case BootstrapContentActionType.LoadSuccess:
      return {
        ...state,
        isFeatureAreaBootstrapContentLoadingComplete: true
      };

    default:
      return state;
  }
}
