import {
  LevelsCompletedAction,
  LevelsCompletedActionSetCertificateViewed
} from '../redux';
import {
  IStudentApiStateSlice,
  StudentApiSelector,
  StudentPropertyAction,
  StudentPropertyActionUpdate
} from '@lexialearning/student-api';
import { ILoggingDeps } from 'services/logging/epics/logging-epic.model';
import { LoboLogItemCategory } from 'logging';
import { LoggingLevel } from '@lexialearning/main-model';
import { Observable } from 'rxjs';
import { StateObservable } from 'redux-observable';
import { filter, map, tap } from 'rxjs/operators';
import { ProfileSelector } from 'services/profile';
import { StudentApiHelper, StudentProperty } from 'student-api';

export function logCertificateViewedEpic(
  action$: Observable<LevelsCompletedActionSetCertificateViewed>,
  state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<StudentPropertyActionUpdate> {
  return action$.pipe(
    filter(LevelsCompletedAction.setCertificateViewed.request.match),
    filter(() => ProfileSelector.isStudentLoggedIn(state$.value)),
    tap((a: LevelsCompletedActionSetCertificateViewed) => {
      const {
        appLoggerDependencies: { logger }
      } = deps;

      void logger.log({
        category: LoboLogItemCategory.CertificateViewed,
        loggingLevel: LoggingLevel.Info,
        payload: { certificateLevel: a.payload },
        summary: `Viewed certificate for Level ${a.payload}`
      });
    }),
    map(action => {
      const studentProperties = StudentApiSelector.getStudentProperties(
        state$.value as IStudentApiStateSlice
      );
      const newLevelCertsViewedStringValue =
        StudentApiHelper.addViewedLevelCertificate(
          studentProperties,
          action.payload
        );

      return StudentPropertyAction.update.request({
        studentProperties: [
          {
            key: StudentProperty.LevelCertificatesViewed,
            value: newLevelCertsViewedStringValue
          }
        ]
      });
    })
  );
}
logCertificateViewedEpic.displayName = 'logCertificateViewedEpic';
