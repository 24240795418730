import { TaskPhase } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ActivityPositionAction } from 'curriculum-services';
import { TextButton, View } from 'common-ui';
import { AppState } from 'services';
import { SreSelector, SreSessionType } from 'sre';
import { TaskSelector } from 'task-components';
import { SkipSvg } from './Skip.lx-svg';
import { SkipToNextPositionButtonStyles } from './SkipToNextPositionButton.styles';

export interface ISkipToNextPositionButtonProps {
  disabled?: boolean;
  skip(): void;
}

export class SkipToNextPositionButtonComponent extends React.PureComponent<ISkipToNextPositionButtonProps> {
  public static readonly displayName = 'SkipToNextPositionButton';

  public render() {
    const { disabled, skip } = this.props;
    const styles = SkipToNextPositionButtonStyles.get();

    return (
      <View style={styles.container}>
        <TextButton
          Icon={SkipSvg}
          disabled={disabled}
          onPress={skip}
          styleOverride={styles.buttonOverrides}
          text="Skip"
          disabledOpacity={0.5}
        />
      </View>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const isListeningToLF = SreSelector.getIsListeningTo(
    SreSessionType.LanguageFrame,
    state
  );

  return {
    disabled:
      isListeningToLF ||
      [TaskPhase.Entry, TaskPhase.Exit, TaskPhase.None].includes(
        TaskSelector.getPhase(state)
      )
  };
};

const mapDispatchToProps = {
  skip: () => ActivityPositionAction.skip()
};

export const SkipToNextPositionButton = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SkipToNextPositionButtonComponent);

export const SkipToNextPositionButtonPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
