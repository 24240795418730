import { InteractionState } from 'common-styles';

export enum MicActionType {
  SetForceDisabled = 'MIC.SET_FORCE_DISABLED',
  SetInteractionState = 'MIC.SET_INTERACTION_STATE'
}

export interface IMicState {
  interactionState: InteractionState;
  /**
   * Intended for use when the current interactionState should be preserved,
   * but needs to be temporarily overridden to disabled, eg, when a modal is open
   */
  forceDisabled: boolean;
}
