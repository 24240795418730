import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ICurriculumDependencies } from '../../CurriculumDependencies';
import {
  LevelAction,
  LevelActionMountVoiceovers,
  LevelActionMountVoiceoversSuccess,
  LevelActionType,
  LevelSelector
} from '../redux';
import { LevelVoMountPointFactory } from './LevelVoMountPointFactory';

export function mountLevelVoiceoversEpic(
  action$: Observable<LevelActionMountVoiceovers>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<LevelActionMountVoiceoversSuccess> {
  return action$.pipe(
    ofType(LevelActionType.MountVoiceovers),
    mergeMap(async action => {
      const { configProvider, stormService } = deps.curriculumDependencies;
      const levelTitle =
        (action.payload && action.payload.levelTitle) ||
        LevelSelector.getLevel(state$.value).title;
      const mp = LevelVoMountPointFactory.create(configProvider, levelTitle);

      return stormService.mount(mp);
    }),
    map(m => LevelAction.mountVoiceovers.success(m))
  );
}
mountLevelVoiceoversEpic.displayName = 'mountLevelVoiceoversEpic';
