import memoizeOne from 'memoize-one';
import { Align, Color, Justify, TransformOrigin } from 'common-styles';
import { ISize } from 'common-ui/helpers/SizingUtils';
import { SystemInfo } from 'utils';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { Overflow } from '@lexialearning/common-ui';

export class AppStyles {
  public static readonly build = memoizeOne(
    (appSize: ISize, containerSize: ISize, scale: number, offset: any) => {
      const hasOffsetLeft = offset.h > 0;
      const hasOffsetTop = offset.v > 0;
      const zoomCompatibleBrowser =
        !UserAgentUtils.isFirefox() && !SystemInfo.isNative;

      return {
        app: {
          alignItems: Align.Stretch,
          backgroundColor: Color.DarkOrange,
          height: appSize.h,
          shadowColor: Color.BlackTransparent40,
          shadowOffset: { height: 0, width: 0 },
          shadowOpacity: 1,
          shadowRadius: 30,
          transform: [{ scale }],
          width: appSize.w,
          ...(zoomCompatibleBrowser && {
            transformOrigin: `${
              !hasOffsetLeft ? TransformOrigin.Left : TransformOrigin.Center
            } ${!hasOffsetTop ? TransformOrigin.Top : TransformOrigin.Center}`
          })
        },
        container: {
          flex: 1,
          ...(!zoomCompatibleBrowser
            ? { overflow: Overflow.Hidden }
            : {
                alignItems: !hasOffsetLeft ? Align.Start : Align.Center,
                height: containerSize.h,
                justifyContent: !hasOffsetTop ? Justify.Start : Justify.Center,
                minWidth: containerSize.w,
                overflow: Overflow.Visible
              })
        }
      };
    }
  );
}
