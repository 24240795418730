import * as React from 'react';
import { Column } from 'common-ui';
import { ModalRegistry } from 'shared-components/modals';
import { CalibrationContent } from './CalibrationContent';
import { CalibrationModalStyles } from './CalibrationModal.styles';

export class CalibrationModal extends React.PureComponent {
  public static displayName = 'CalibrationModal';

  public static ModalId = ModalRegistry.register({
    Component: CalibrationModal,
    preventDismiss: true,
    title: 'Calibration'
  });

  public render() {
    const styles = CalibrationModalStyles.get();

    return (
      <Column testId={CalibrationModal.displayName} style={styles.modal}>
        <CalibrationContent />
      </Column>
    );
  }
}
