import { EducatorTabName, LoginTabName, RoutePath } from './routing.model';
import { ProgramMode } from 'curriculum-services';

export class RouteBuilder {
  public static readonly FirstRound = 'START';

  public static acts(actId: string): string {
    return RoutePath.Acts.replace(':actId', actId);
  }

  public static avatarEditor(): string {
    return RoutePath.AvatarEditor;
  }

  public static calibration(): string {
    return RoutePath.Calibration;
  }

  public static deviceSetup(): string {
    return RoutePath.DeviceSetup;
  }

  public static educator(selectedTab?: EducatorTabName): string {
    return RoutePath.EducatorTab.replace(
      ':selectedTab',
      selectedTab || EducatorTabName.Overview
    );
  }

  public static encounterComplete(): string {
    return RoutePath.EncounterComplete;
  }

  public static gettingStarted(): string {
    return RoutePath.GettingStarted;
  }

  public static home(): string {
    return RoutePath.Home;
  }

  public static introduction(): string {
    return RoutePath.Introduction;
  }

  public static levelComplete(): string {
    return RoutePath.LevelComplete;
  }

  public static levelIntro(): string {
    return RoutePath.LevelIntroduction;
  }

  public static levels(levelId): string {
    return RoutePath.Levels.replace(':levelId', levelId);
  }

  public static login(selectedTab?: LoginTabName): string {
    return RoutePath.LoginTab.replace(
      ':selectedTab',
      selectedTab || LoginTabName.Student
    );
  }

  public static loginSsoInteraction(): string {
    return RoutePath.LoginSsoInteraction;
  }

  public static onboarding(): string {
    return RoutePath.Onboarding;
  }

  public static onboardingRounds(
    unitId: string,
    roundId = RouteBuilder.FirstRound
  ): string {
    return RoutePath.OnboardingRounds.replace(':unitId', unitId).replace(
      ':roundId',
      roundId
    );
  }

  public static onboardingOrientation(): string {
    return RoutePath.OnboardingOrientation;
  }

  public static placementRounds(
    unitId: string,
    roundId = RouteBuilder.FirstRound
  ): string {
    return RoutePath.PlacementRounds.replace(':unitId', unitId).replace(
      ':roundId',
      roundId
    );
  }

  public static placementComplete(): string {
    return RoutePath.PlacementComplete;
  }

  public static programComplete(): string {
    return RoutePath.ProgramComplete;
  }

  public static root(): string {
    return RoutePath.Root;
  }

  public static rounds(
    unitId: string,
    roundId = RouteBuilder.FirstRound
  ): string {
    return RoutePath.Rounds.replace(':unitId', unitId).replace(
      ':roundId',
      roundId
    );
  }

  public static unitComplete(): string {
    return RoutePath.UnitComplete;
  }

  public static modeSpecificRound(
    programMode: ProgramMode,
    unitId: string,
    roundId?: string
  ): string {
    const builder = this.getModeSpecificRoundBuilder(programMode);

    return builder(unitId, roundId);
  }

  private static getModeSpecificRoundBuilder(
    programMode: ProgramMode
  ): (unitId: string, roundId?: string) => string {
    switch (programMode) {
      case ProgramMode.Onboarding:
        return RouteBuilder.onboardingRounds;
      case ProgramMode.Placement:
        return RouteBuilder.placementRounds;
      default:
        return RouteBuilder.rounds;
    }
  }
}
