import { LevelSelector } from 'curriculum-services/level';
import { UnitSelector } from 'curriculum-services/unit';
import { TaskSelector } from 'task-components/core/redux/Task.selector';
import { ActivityPositionBuilder } from '../epics';
import { IContentReadiness } from '../program-context.model';
import { ProgramContextSelector } from '../redux';

export class ContentReadiness {
  public static readonly displayName = 'ContentReadiness';

  public static determine(state: unknown): IContentReadiness {
    const position = ProgramContextSelector.getPosition(state);
    const activityPosition = position.activeActivityId
      ? ProgramContextSelector.getActivityPosition(state)
      : undefined;
    const builder =
      activityPosition && ActivityPositionBuilder.create(activityPosition);
    const unitPosition = builder?.activeUnitPosition;
    const levelId = LevelSelector.getLevelIdMaybe(state);
    const unit = UnitSelector.getUnitMaybe(state);
    const isUnitLoading = UnitSelector.isLoading(state);
    const round = unit?.rounds.find(r => r.sysId === unitPosition?.roundId);
    const taskId = round?.task?.sysId;
    const task = TaskSelector.getTaskContentMaybe(state);

    const readiness: IContentReadiness = {
      all: false,
      level: levelId === position.levelId,

      task: !!(taskId && task?.sysId === taskId),
      // Have to check isUnitLoading, and not just for the loaded unit's ID
      // For the case of recycling where the sysId is the same from the prior unit
      // so the sysId check may pass, even if the new unit has not finished loading
      unit: !isUnitLoading && unit?.sysId === unitPosition?.unitId
    };
    readiness.all = readiness.level && readiness.unit && readiness.task;

    return readiness;
  }

  /**
   * Returns true when the actual content readiness matches the minimum desired
   * readiness. Examples:
   * - {all: true} - true only if all content has been loaded
   * - {level: true} - true if at least level has been loaded
   * - {unit: true}  - true if at least unit has been loaded
   *
   * @param actualReadiness - actual content readiness state
   * @param desiredReadiness - desired content readiness state
   */
  public static isSufficientlyReady(
    actualReadiness: IContentReadiness,
    desiredReadiness: Partial<IContentReadiness>
  ): boolean {
    return Object.getOwnPropertyNames(desiredReadiness).every(
      p => desiredReadiness[p] && actualReadiness[p]
    );
  }
}
