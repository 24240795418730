import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ScreenplayActionCancel, ScreenplayActionType } from '../redux';
import { IScreenplayDeps } from './screenplay-epic.model';

/**
 * Cancel any active screenplay playback.
 * No action is dispatched post-process. The playEpic is expected
 * to dispatch Canceled instead.
 */
export function cancelScreenplayEpic(
  action$: Observable<ScreenplayActionCancel | UserGlobalActionLogout>,
  _: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<any> {
  return action$.pipe(
    ofType(ScreenplayActionType.Cancel, UserGlobalActionType.Logout),
    tap(() => {
      deps.screenplayer.cancel();
    }),
    ignoreElements()
  );
}
cancelScreenplayEpic.displayName = 'cancelScreenplayEpic';
