import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { LexiaError } from '@lexialearning/utils';
import { CommonUiActionType, ICommonUiState } from './common-ui-redux.model';
import { CommonUiActions } from './CommonUi.action';

export const commonUiInitialState: ICommonUiState = {
  hasViewedOnboardingVideo: false,
  isGlobalDisabled: false,
  isKeydownHandlingDisabled: false,
  taskDemosShown: [],
  unitRewards: [],
  unitRewardsAvailable: []
};

export function commonUiReducer(
  state: ICommonUiState = commonUiInitialState,
  action: CommonUiActions | UserGlobalActionLogoutSuccess
): ICommonUiState {
  switch (action.type) {
    case CommonUiActionType.SetHasViewedOnboardingVideo:
      return {
        ...state,
        hasViewedOnboardingVideo: action.payload.hasViewedOnboardingVideo
      };

    case CommonUiActionType.SetKeydownHandlingDisabled:
      return {
        ...state,
        isKeydownHandlingDisabled: action.payload.isKeydownHandlingDisabled
      };

    case CommonUiActionType.SetGlobalDisabled:
      return {
        ...state,
        isGlobalDisabled: action.payload.isGlobalDisabled
      };

    case CommonUiActionType.SetTaskDemoShown:
      return {
        ...state,
        taskDemosShown: [...state.taskDemosShown, action.payload]
      };

    case CommonUiActionType.SetUnitRewards:
      return {
        ...state,
        unitRewards: action.payload,
        unitRewardsAvailable: action.payload
      };

    case CommonUiActionType.UnitRewardShown:
      return {
        ...state,
        unitRewardsAvailable: getUnitRewardsAvailable(action.payload.idx, state)
      };

    case UserGlobalActionType.LogoutSuccess:
      return {
        ...state,
        taskDemosShown: [],
        unitRewards: [],
        unitRewardsAvailable: []
      };

    default:
      return state;
  }
}
commonUiReducer.displayName = 'commonUiReducer;';

function getUnitRewardsAvailable(
  rewardShownIdx: number,
  state: ICommonUiState
) {
  const { unitRewardsAvailable: currentUnitRewardsAvailable, unitRewards } =
    state;

  if (!currentUnitRewardsAvailable.includes(rewardShownIdx)) {
    throw new LexiaError(
      'Invalid unit reward index',
      commonUiReducer.displayName,
      CommonUiReducerErrors.InvalidUnitRewardIndex
    );
  }

  const newRewardsAvailable = currentUnitRewardsAvailable.filter(
    r => r !== rewardShownIdx
  );

  return newRewardsAvailable.length ? newRewardsAvailable : unitRewards;
}

export enum CommonUiReducerErrors {
  InvalidUnitRewardIndex = 'InvalidUnitRewardIndex'
}
