export class LoginTabsStyles {
  private static readonly styles = {
    container: {
      marginBottom: 8,
      marginTop: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
