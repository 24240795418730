// Modal should only be rendered in a native environment
import * as React from 'react';
import { Linking } from 'common-ui/helpers';
import {
  ModalActions,
  ModalRegistry,
  ModalType
} from 'shared-components/modals';
import { IAppStoreUrlProps, withAppStoreUrl } from '../withAppStoreUrl.hoc';
import old_versionPng from './old_version.png';

export class OldVersionModalComponent extends React.PureComponent<IAppStoreUrlProps> {
  public static readonly displayName = 'OldVersionModal';

  constructor(props: IAppStoreUrlProps) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }

  private handlePress() {
    void Linking.openUrl(this.props.appStoreUrl);
  }

  public render() {
    return (
      <ModalActions
        primaryAction={{
          onPress: this.handlePress,
          text: 'Upgrade'
        }}
      />
    );
  }
}

export const OldVersionModal = withAppStoreUrl(OldVersionModalComponent) as any;

OldVersionModal.ModalId = ModalRegistry.register({
  Component: OldVersionModal,
  getTeacherVO: true,
  headline: 'You have an old version.',
  image: old_versionPng,
  text: 'Please get your teacher.',
  title: 'Oops!',
  type: ModalType.Error
});
