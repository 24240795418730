export enum AuthActionType {
  Authenticate = 'AUTH.AUTHENTICATE',
  AuthenticateFailure = 'AUTH.AUTHENTICATE_FAILURE',
  AuthenticateSuccess = 'AUTH.AUTHENTICATE_SUCCESS',
  AuthenticatedByMdm = 'AUTH.AUTHENTICATED_BY_MDM',
  AuthenticatedBySso = 'AUTH.AUTHENTICATED_BY_SSO',
  AutoLogin = 'AUTH.AUTO_LOGIN',
  DisableAutoLogin = 'AUTH.DISABLE_AUTO_LOGIN',
  Interacted = 'AUTH.INTERACTED',
  InvalidToken = 'AUTH.INVALID_TOKEN',
  Login = 'AUTH.LOGIN',
  LoginFailure = 'AUTH.LOGIN_FAILURE',
  LoginMdm = 'AUTH.LOGIN_MDM',
  LoginSso = 'AUTH.LOGIN_SSO',
  LoginSuccess = 'AUTH.LOGIN_SUCCESS',
  ReLogin = 'AUTH.RE_LOGIN',
  ReloadApp = 'AUTH.RELOAD_APP',
  ResetAuthError = 'AUTH.RESET_ERROR',
  Unauthenticate = 'AUTH.UNAUTHENTICATE'
}
