import { ITextOrderingSegment } from '@lexialearning/lobo-common/main-model';
import { ArrayUtils } from 'utils';

export interface ISegment {
  key: string;
  content: ITextOrderingSegment;
}

export class OrderingHelper {
  public static buildInitialSegments(
    orderedSegments: ITextOrderingSegment[]
  ): ISegment[] {
    return orderedSegments.map((segment, index) => ({
      content: segment,
      key: `ordering-segment-${index}`
    }));
  }

  public static shuffle<T>(items: T[]): T[] {
    return ArrayUtils.shuffleArray(items, { ensureReorder: true });
  }

  public static evaluate(
    items: ISegment[],
    orderedSegments: ITextOrderingSegment[]
  ): boolean {
    return (
      items.map(i => i.content.text).join(' ') ===
      orderedSegments.map(segment => segment.text).join(' ')
    );
  }

  public static reorderItems<T>(
    itemIdx: number,
    moveToIdx: number,
    items: T[]
  ): T[] {
    const item = items[itemIdx];
    const reorderedItems =
      itemIdx < moveToIdx
        ? [
            ...items.slice(0, itemIdx),
            ...items.slice(itemIdx + 1, moveToIdx + 1),
            item,
            ...items.slice(moveToIdx + 1, items.length)
          ]
        : [
            ...items.slice(0, moveToIdx),
            item,
            ...items.slice(moveToIdx, itemIdx),
            ...items.slice(itemIdx + 1, items.length)
          ];

    return reorderedItems;
  }
}
