import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { CharacterSceneScreenplayEditor } from 'feature-areas/character-scene';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { StormService } from 'storm';
import { TaskRegistry } from 'task-components';
import { PlacementScene } from '../PlacementScene';
import { PlacementSceneDefinitionBuilder } from '../PlacementSceneDefinitionBuilder';
import {
  PlacementSceneActionType,
  PlacementScenePrepareAction
} from '../redux';

export interface IPreparePlacementScenesDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
  taskRegistry: TaskRegistry;
}

export function preparePlacementSceneEpic(
  action$: Observable<PlacementScenePrepareAction>,
  _state$: StateObservable<unknown>,
  deps: IPreparePlacementScenesDep
): Observable<void> {
  return action$.pipe(
    ofType(PlacementSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService, taskRegistry } = deps;
      preparedScenes.placement = undefined;

      await stormService.initialization;
      preparedScenes.placement = await createPlacementScene(stormService);

      taskRegistry.screenplayEditor = new CharacterSceneScreenplayEditor(
        preparedScenes.placement,
        SceneName.Placement
      );
    }),
    ignoreElements()
  );
}
preparePlacementSceneEpic.displayName = 'preparePlacementSceneEpic';

async function createPlacementScene(
  stormService: StormService
): Promise<PlacementScene> {
  const placementSceneDefinition = PlacementSceneDefinitionBuilder.create()
    .withBackgroundOverlay()
    .withOverlay()
    .withCharacter().definition;

  await stormService.initialization;
  const placementScene = await stormService.prepareScene(
    placementSceneDefinition
  );

  return new PlacementScene(placementScene);
}
