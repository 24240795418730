import {
  ButtonBase,
  IButtonBaseProps
} from 'common-ui/components/button/ButtonBase';
import { Text } from 'common-ui/components/text/Text';
import { PlainTextButtonStyles } from './PlainTextButton.styles';
import {
  ButtonStyle as CuiButtonStyle,
  TextStyle as CuiTextStyle,
  useActiveComponentState
} from '@lexialearning/common-ui';

export interface IPlainTextButtonProps extends IButtonBaseProps {
  disabled?: boolean;
  testId?: string;
  text: string;
  styleOverride?: {
    button?: CuiButtonStyle;
    text?: CuiTextStyle;
  };
}

export function PlainTextButton(props: IPlainTextButtonProps) {
  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);
  const { disabled, onPress, styleOverride, testId, text, ...restProps } =
    props;
  const styles = PlainTextButtonStyles.build(
    activeComponentState,
    styleOverride
  );

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      onPress={onPress}
      disabled={disabled}
      style={styles.button}
      testId={testId || PlainTextButton.displayName}
    >
      <Text style={styles.text}>{text}</Text>
    </ButtonBase>
  );
}
PlainTextButton.displayName = 'PlainTextButton';
