/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
import { ColorHelper } from './Color.helper';
import { InteractionState } from './enums';
import { ActiveComponentState } from '@lexialearning/common-ui';

const ColorValue = {
  Red: '#ED1C24',
  CrimsonRed: '#D51A27',
  DarkRed: '#B20212',
  Orange: '#F86D23',
  BrightOrange: '#FE962D', // UserChoice border
  DarkOrange: '#F5AE17', // Splash screen, App background
  Clementine: '#FF9900',
  Gold: '#FEC338',
  Yellow: '#FFEF1F',
  Yellow1: '#E5DB4E',
  DarkYellow: '#FFD800',
  LimeGreen: '#89C542',
  LimeGreenPop1: '#BDFB66', // Pip completed spinning pop color - default rounds
  LimeGreenPop2: '#EDFFD8', // Meter completed pop color - default rounds
  Green: '#41B649',
  BrightGreen: '#88D62C',
  Green2: '#1CDD03', // VU meter
  MidGreen: '#36963C',
  DarkGreen: '#116434',
  Blue: '#015999',
  BrightBlue: '#0088E1',
  DodgerBlue: '#41A0ff', // VU meter
  DodgerBlueLight: '#38BCFC', // Used to flash the VU meter bars a lighter blue along side the sparkle animation
  DeepBlue: '#2D6CE0',
  DeepBlue2: '#006CDC', // Level cert notifications
  DeepBluePop1: '#41A0ff', // Pip completed spinning pop color - pok & placement rounds
  DeepBluePop2: '#D4EFFF', // Meter completed pop color - pok & placement rounds
  LightBlue: '#A8DDF1',
  Aqua: '#56B2C9', // UserChoice border, podcast player
  LexiaPurple: '#21145F',
  Pink: '#FE618F', // UserChoice border
  White: '#FFF',
  NearWhite: '#FEFDF8',
  Gray5: '#F2F2F2',
  Gray7: '#EFF0F5',
  Gray10: '#E6E6E6',
  Gray20: '#D0D0D0',
  Gray30: '#B2B1B1',
  Gray40: '#959595',
  Gray50: '#8D8D8D',
  Gray55: '#737373',
  Gray60: '#606060',
  Gray70: '#4E4E4E',
  Black: '#000',

  Transparent: 'transparent'
};

const Overlays = {
  BlackTransparent04: ColorHelper.hexToRgba(ColorValue.Black, 0.04),
  BlackTransparent08: ColorHelper.hexToRgba(ColorValue.Black, 0.08),
  BlackTransparent10: ColorHelper.hexToRgba(ColorValue.Black, 0.1),
  BlackTransparent20: ColorHelper.hexToRgba(ColorValue.Black, 0.2),
  BlackTransparent30: ColorHelper.hexToRgba(ColorValue.Black, 0.3),
  BlackTransparent40: ColorHelper.hexToRgba(ColorValue.Black, 0.4),
  BlackTransparent50: ColorHelper.hexToRgba(ColorValue.Black, 0.5),
  BlackTransparent55: ColorHelper.hexToRgba(ColorValue.Black, 0.55),
  BlackTransparent70: ColorHelper.hexToRgba(ColorValue.Black, 0.7),
  BlackTransparent80: ColorHelper.hexToRgba(ColorValue.Black, 0.8),
  GoldTransparent30: ColorHelper.hexToRgba(ColorValue.Gold, 0.3),
  LexiaPurpleTransparent10: ColorHelper.hexToRgba(ColorValue.LexiaPurple, 0.1),
  NearWhiteTransparent30: ColorHelper.hexToRgba(ColorValue.NearWhite, 0.3),
  NearWhiteTransparent40: ColorHelper.hexToRgba(ColorValue.NearWhite, 0.4),
  NearWhiteTransparent70: ColorHelper.hexToRgba(ColorValue.NearWhite, 0.7),
  NearWhiteTransparent80: ColorHelper.hexToRgba(ColorValue.NearWhite, 0.8),
  NearWhiteTransparent90: ColorHelper.hexToRgba(ColorValue.NearWhite, 0.9)
};

const Colors = {
  ...ColorValue,
  ...Overlays,
  Text: ColorValue.Black, // default text color
  TextOpposite: ColorValue.NearWhite
};

function getSetFor(
  state: ActiveComponentState | InteractionState | undefined
): {
  background: string;
  border: string;
  text: string;
} {
  switch (state) {
    case InteractionState.Highlighted:
      return {
        background: ColorValue.Yellow,
        border: ColorValue.Yellow,
        text: ColorValue.Orange
      };
    case InteractionState.Selected:
      return {
        background: ColorValue.Blue,
        border: ColorValue.BrightBlue,
        text: ColorValue.Blue
      };
    case InteractionState.Correct:
      return {
        background: ColorValue.DarkGreen,
        border: ColorValue.Green,
        text: ColorValue.DarkGreen
      };
    case InteractionState.Incorrect:
      return {
        background: ColorValue.DarkRed,
        border: ColorValue.Red,
        text: ColorValue.DarkRed
      };
    case InteractionState.Inconclusive:
      return {
        background: ColorValue.Gray10,
        border: ColorValue.Black,
        text: Colors.Text
      };
    case ActiveComponentState.KeyboardFocused:
      return {
        background: ColorValue.White,
        border: ColorValue.Black,
        text: Colors.Text
      };
    case ActiveComponentState.KeyboardPressed:
      return {
        background: ColorValue.Gray7,
        border: ColorValue.Black,
        text: Colors.Text
      };
    case ActiveComponentState.Pressed:
      return {
        background: ColorValue.Gray7,
        border: ColorValue.Transparent,
        text: Colors.Text
      };
    default:
      return {
        background: ColorValue.NearWhite,
        border: ColorValue.Transparent,
        text: Colors.Text
      };
  }
}

export const Color = {
  ...Colors,
  getSetFor
};
