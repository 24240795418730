import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function PlayIconSvg({
  iconColor = Color.NearWhite
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={PlayIconSvg.displayName} fill={iconColor}>
      <Path
        d="M26.432,4.25a3,3,0,0,1,5.135,0l23.682,39.2A3,3,0,0,1,52.682,48H5.318A3,3,0,0,1,2.75,43.449Z"
        transform="translate(63 17) rotate(90) scale(0.5)"
        fill={iconColor}
      />
    </G>
  );
}
PlayIconSvg.displayName = 'PlayIconSvg';
