import { ActiveComponentState, Direction } from '@lexialearning/common-ui';
import { Color, ShadowStyle } from 'common-styles';
import { KeyNavHelper } from 'common-ui';

export class IconSlotStyles {
  public static build(
    activeComponentState: ActiveComponentState,
    isChecked: boolean
  ) {
    const isKeyboardNavigated = KeyNavHelper.isKeyNav(activeComponentState);
    const shouldApplyShadow = !(isKeyboardNavigated || isChecked);
    const shouldShrink = [
      ActiveComponentState.Pressed,
      ActiveComponentState.KeyboardPressed
    ].includes(activeComponentState);
    const shouldGrow = activeComponentState === ActiveComponentState.Hovered;

    let pillContainer = {
      backgroundColor: Color.Blue,
      borderRadius: 25,
      flexDirection: Direction.Row,
      height: 37,
      transform: [
        {
          scale: shouldShrink ? 0.85 : shouldGrow ? 1.09 : 1
        }
      ],
      width: 54
    };

    if (shouldApplyShadow) {
      pillContainer = {
        ...pillContainer,
        ...ShadowStyle.activity,
        backgroundColor: Color.Transparent,
        height: 34
      };
    }

    return {
      pillContainer
    };
  }
}
