// istanbul ignore file - static SVG, nothing to test
import { G, Path, Svg } from 'common-ui';

export function SkipSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.43}
      height={17}
      viewBox="0 0 21.43 17"
    >
      <G transform="translate(-784 -703)">
        <Path
          fill="#000000"
          d="M41.553,23.028a2.159,2.159,0,0,0,0-3.253l-4.512-3.313-4.528-3.308a1.214,1.214,0,0,0-1.48,0,2.016,2.016,0,0,0-.74,1.627V28.028a2.014,2.014,0,0,0,.742,1.623,1.213,1.213,0,0,0,1.478,0l4.512-3.313Z"
          transform="translate(753.708 690.099)"
        />
        <Path
          fill="#000000"
          d="M41.553,23.028a2.159,2.159,0,0,0,0-3.253l-4.512-3.313-4.528-3.308a1.214,1.214,0,0,0-1.48,0,2.016,2.016,0,0,0-.74,1.627V28.028a2.014,2.014,0,0,0,.742,1.623,1.213,1.213,0,0,0,1.478,0l4.512-3.313Z"
          transform="translate(763.138 690.099)"
        />
      </G>
    </Svg>
  );
}

SkipSvg.displayName = 'SkipSvg';
