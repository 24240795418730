import { Sfx } from './sfx.model';
import { useDispatch } from 'react-redux';
import { SfxAction } from './Sfx.action';

export const useSfx = (): ((path: Sfx) => void) => {
  const dispatch = useDispatch();

  const playSfx = (path: Sfx) => {
    dispatch(SfxAction.play({ path }));
  };

  return playSfx;
};
useSfx.displayName = 'useSfx';
