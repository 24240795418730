import { Cursor, Position } from '@lexialearning/common-ui';
import { CONSTANTS } from 'common-ui';

export class FunFactStyles {
  private static readonly styles = {
    bubbleOverlay: {
      bottom: 405,
      cursor: Cursor.Pointer,
      height: 297,
      position: Position.Absolute,
      right: 80,
      width: 337
    },
    characterContainer: {
      height: CONSTANTS.BaseDimensions.Height,
      width: CONSTANTS.BaseDimensions.Width
    },
    characterOverlay: {
      bottom: 90,
      cursor: Cursor.Pointer,
      height: 315,
      position: Position.Absolute,
      right: 145,
      width: 225
    }
  };

  public static get() {
    return this.styles;
  }
}
