import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function CheckIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={CheckIconSvg.displayName}>
      {/* Check shadow */}
      {shadowColor && (
        <G transform="translate(0.25 0.25)" opacity="0.28">
          <Path
            d="M504.211,573.157a3.325,3.325,0,0,1-2.462,1.073,3.4,3.4,0,0,1-2.42-1.074l-9.722-9.722a3.571,3.571,0,1,1,5.051-5.05l7.113,7.112,14.814-14.814a3.405,3.405,0,0,1,2.421-1.074,3.983,3.983,0,0,1,3.7,3.7,3.4,3.4,0,0,1-1.073,2.42Z"
            transform="translate(-459.774 -529.982)"
            fill={shadowColor}
          />
        </G>
      )}
      {/* Check */}
      <Path
        d="M504.211,573.157a3.325,3.325,0,0,1-2.462,1.073,3.4,3.4,0,0,1-2.42-1.074l-9.722-9.722a3.571,3.571,0,1,1,5.051-5.05l7.113,7.112,14.814-14.814a3.405,3.405,0,0,1,2.421-1.074,3.983,3.983,0,0,1,3.7,3.7,3.4,3.4,0,0,1-1.073,2.42Z"
        transform="translate(-459.774 -529.982)"
        fill={iconColor}
      />
    </G>
  );
}
CheckIconSvg.displayName = 'CheckIconSvg';
