import { G, Path, Rect, Svg } from 'common-ui';

export function IndicatorVerticalSvg() {
  const fillColor = '#0367B3';

  return (
    <Svg width={40} height={66} viewBox="0 0 37 66">
      <G id="DropIndicator" transform="translate(-104.511 -681.606)">
        <Path
          d="M1158.511-168.394h37s-7.772,2.6-11.844,7.469-3.948,12.023-3.948,12.023h-4.935s.122-7.15-3.948-12.023S1158.511-168.394,1158.511-168.394Z"
          transform="translate(-1054 850)"
          fill={fillColor}
        />
        <Rect
          width={5}
          height={28}
          transform="translate(120.752 700.752)"
          fill={fillColor}
        />
        <Path
          d="M1158.511-148.9h37s-7.772-2.6-11.844-7.469-3.948-12.023-3.948-12.023h-4.935s.122,7.15-3.948,12.023S1158.511-148.9,1158.511-148.9Z"
          transform="translate(-1054 896.508)"
          fill={fillColor}
        />
      </G>
    </Svg>
  );
}
IndicatorVerticalSvg.displayName = 'IndicatorVerticalSvg';
