import { IChoice, TextPillWidth } from '@lexialearning/lobo-common';
import { Column } from 'common-ui';
import { TextChoice } from './TextChoice';
import { TextChoicesContainerStyles } from './TextChoicesContainer.styles';

export interface ITextChoicesContainerProps {
  choices: IChoice[];
  textPillWidth: TextPillWidth;
}

export function TextChoicesContainer(props: ITextChoicesContainerProps) {
  const { choices, textPillWidth } = props;
  const styles = TextChoicesContainerStyles.get();

  return (
    <Column style={styles.choiceContainer}>
      {choices.map((item: IChoice) => (
        <TextChoice
          key={item.card.sysId}
          sysId={item.card.sysId}
          isCorrectChoice={item.isCorrectChoice}
          text={item.card.label || '(?)'}
          textPillWidth={textPillWidth}
        />
      ))}
    </Column>
  );
}
TextChoicesContainer.displayName = 'TextChoicesContainer';
