import {
  TaskEvaluationResult,
  UnitType
} from '@lexialearning/lobo-common/main-model';
import { IImageTask } from '@lexialearning/lobo-common/tasks/image-task';
import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Image, Row } from 'common-ui';
import {
  ProgramContextSelector,
  UnitSelector
} from 'services/curriculum-services';
import { SubmitButton } from 'task-components/shared';
import { ThemeType, withTheme } from 'theme';
import { ITaskProps } from '../core';
import { ImageTaskStyles } from './ImageTask.styles';

export interface IImageTaskProps extends ITaskProps<IImageTask> {
  unitType: UnitType;
  hasOnscreenCharacter: boolean;
  themeType: ThemeType;
}

export class ImageTaskComponent extends React.PureComponent<IImageTaskProps> {
  public static readonly displayName = 'ImageTask';

  constructor(props: IImageTaskProps) {
    super(props);

    this.handlePressSubmit = this.handlePressSubmit.bind(this);
  }

  private handlePressSubmit(): void {
    this.props.onEvaluated(TaskEvaluationResult.Correct, {});
  }

  public componentDidUpdate(prevProps: IImageTaskProps) {
    if (!prevProps.isInteractive && this.props.isInteractive) {
      this.props.updateCanSubmit({ canSubmit: true });
    }
  }

  public render() {
    const { unitType, hasOnscreenCharacter, taskContent, themeType } =
      this.props;
    const img = taskContent.image;
    const styles = ImageTaskStyles.build(
      unitType,
      hasOnscreenCharacter,
      themeType
    );

    return (
      <Column style={styles.taskContainer}>
        <Column style={styles.container}>
          <Image
            source={img?.imageSource}
            style={styles.imageContainer}
            accessibilityLabel={img?.altText}
          />
          <Row style={styles.buttonContainer}>
            <SubmitButton onPress={this.handlePressSubmit} />
          </Row>
        </Column>
      </Column>
    );
  }
}

function mapStateToProps(state: unknown) {
  const context = ProgramContextSelector.getRoundContext(state);

  return {
    hasOnscreenCharacter: context.hasOnscreenCharacter,
    unitType: UnitSelector.getType(state)
  };
}

export const ImageTask = withTheme(
  connect(mapStateToProps)(ImageTaskComponent)
);

export const ImageTaskPrivates = {
  mapStateToProps
};
