import {
  ITextWithVoiceover,
  TextPillWidth
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { compose } from 'redux';
import {
  DndDragSource,
  DndDropTarget,
  DragItemType,
  DropTargetHelper,
  IDropTargetCollectedProps,
  IDropTargetProps
} from 'dnd';
import {
  AnimatedView,
  IPositionProps,
  View,
  withPositionHandler
} from 'common-ui';
import {
  OrderingDimensions,
  TextSegment,
  withTapAndHear
} from 'task-components/shared';
import { ThemeSize, withTheme } from 'theme';
import { HorizontalIndicator } from '../horizontal-indicator';
import { VerticalOrderingSegmentAnimatedStyles } from './VerticalOrderingSegment.animated-styles';

export interface IVerticalOrderingSegmentProps
  extends IDropTargetProps,
    IDropTargetCollectedProps,
    IPositionProps {
  content: ITextWithVoiceover;
  activeTargetIdx: number;
  activeSrcIdx: number;
  themeSize?: ThemeSize;
  textPillWidth: TextPillWidth;
  dndIsDropping: boolean;
  showCorrect: boolean;
  onBeginDrag(): void;
  onEndDrag(): void;
}

const DraggableTextSegment = withTapAndHear(
  DndDragSource(DragItemType.TextSegment)(TextSegment)
);

export class VerticalOrderingSegmentComponent extends React.PureComponent<IVerticalOrderingSegmentProps> {
  public static readonly displayName = 'VerticalOrderingSegment';

  private readonly animatedStyles: VerticalOrderingSegmentAnimatedStyles;

  private static readonly IndicatorOffset = 20;

  constructor(props: IVerticalOrderingSegmentProps) {
    super(props);
    const { themeSize } = props;

    this.animatedStyles = new VerticalOrderingSegmentAnimatedStyles({
      themeSize
    });
  }

  public componentDidUpdate() {
    const { activeSrcIdx, activeTargetIdx, index, dndIsDropping } = this.props;

    const { moveUp, moveDown, moveToDefault, reset } =
      this.animatedStyles.getAnimations();

    const isDragging = activeSrcIdx === index;

    if (dndIsDropping || isDragging) {
      return;
    }

    const noDragSource = activeSrcIdx === -1;
    if (noDragSource) {
      reset.start();

      return;
    }

    if (activeTargetIdx >= index && index > activeSrcIdx) {
      moveUp.start();
    } else if (activeSrcIdx > index && index >= activeTargetIdx) {
      moveDown.start();
    } else {
      moveToDefault.start();
    }
  }

  public render() {
    const {
      content,
      activeTargetIdx,
      index: segmentIndex,
      onBeginDrag,
      onEndDrag,
      showCorrect,
      textPillWidth: textPillWidthType,
      themeSize,
      dndIsDropping
    } = this.props;
    const styles = this.animatedStyles.get();

    const textPillWidth = OrderingDimensions.textPillWidth(textPillWidthType);
    const textPillHeight = OrderingDimensions.textPillHeight(themeSize!);

    return (
      <View>
        <View style={styles.indicatorContainer}>
          <HorizontalIndicator
            isVisible={activeTargetIdx === segmentIndex && !dndIsDropping}
            width={
              textPillWidth + VerticalOrderingSegmentComponent.IndicatorOffset
            }
            height={textPillHeight}
          />
        </View>
        <AnimatedView animatedStyle={styles.textPillContainerAnimated}>
          <DraggableTextSegment
            hasGripDots
            draggingOpacity={0}
            verticalSpacing={4}
            index={segmentIndex}
            activeTargetIdx={activeTargetIdx}
            onBeginDrag={onBeginDrag}
            onEndDrag={onEndDrag}
            voiceover={content.voiceover}
            width={textPillWidth}
            showCorrect={showCorrect}
          >
            {content.text}
          </DraggableTextSegment>
        </AnimatedView>
      </View>
    );
  }
}

export const VerticalOrderingSegment = compose(
  DndDropTarget(
    DragItemType.TextSegment,
    DropTargetHelper.createSpec({
      canDrop(props: IDropTargetProps, monitor: DropTargetMonitor) {
        return props.index !== monitor.getItem<any>().index;
      },
      useSfx: false
    })
  ),
  withPositionHandler,
  withTheme
)(VerticalOrderingSegmentComponent);
