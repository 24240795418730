import {
  Align,
  AnimatableCSSProperty,
  Justify,
  ZIndex,
  loboAnimated
} from 'common-styles';
import { CONSTANTS, Types } from 'common-ui';
import { IAvatarAndMirrorAnimatedStyles } from './AvatarAndMirror';
import { IAvatarEditorAnimatedStyles } from './avatar-editor/AvatarEditor';
import { Position } from '@lexialearning/common-ui';

const ENTRY_DELAY = 300; // Delay before the entry animation starts
const ANIMATION_DURATION = 300; // General animation duration

export class AvatarEditorScreenAnimatedStyles {
  private readonly animations: {
    slideOutFaceSelector: Types.Animated.CompositeAnimation;
    expandToFullEditor: Types.Animated.CompositeAnimation;
  };
  private readonly styles: {
    fadeOverride: Types.ViewStyle & { zIndex: number };
    container: Types.ViewStyle;
    avatarEditorAnimated: IAvatarEditorAnimatedStyles;
    avatarAnimated: IAvatarAndMirrorAnimatedStyles;
  };

  private readonly animatedValues;

  private readonly fullViewValues = {
    fullViewComponentsOpacity: 1,
    nameTranslateY: 344,
    selectionContainerWidth: 601,
    avatarEditorOpacity: 1,
    avatarEditorWidth: 704,
    avatarOpacity: 1,
    thumbnailOpacity: 0
  };
  private readonly faceSelectorOnlyViewValues = {
    fullViewComponentsOpacity: 0,
    nameTranslateY: 120,
    selectionContainerWidth: 202,
    avatarEditorOpacity: 0,
    avatarEditorWidth: 0,
    avatarOpacity: 0,
    thumbnailOpacity: 1
  };

  constructor(showFullEditor: boolean) {
    const vals = showFullEditor
      ? this.fullViewValues
      : this.faceSelectorOnlyViewValues;

    this.animatedValues = {
      fullViewComponentsOpacity: loboAnimated.createValue(
        vals.fullViewComponentsOpacity
      ),
      nameTranslateY: loboAnimated.createValue(vals.nameTranslateY),
      selectionContainerWidth: loboAnimated.createValue(
        vals.selectionContainerWidth
      ),
      avatarEditorOpacity: loboAnimated.createValue(vals.avatarEditorOpacity),
      avatarEditorWidth: loboAnimated.createValue(vals.avatarEditorWidth),
      avatarOpacity: loboAnimated.createValue(vals.avatarOpacity),
      thumbnailOpacity: loboAnimated.createValue(vals.thumbnailOpacity),
      thumbnailTranslateY: loboAnimated.createValue(-30)
    };

    this.animations = {
      slideOutFaceSelector: this.createSlideOutFaceSelectorAnimation(),
      expandToFullEditor: this.createExpandToFullEditorAnimation()
    };

    this.styles = {
      fadeOverride: {
        zIndex: ZIndex.Shared,
        position: Position.Absolute,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      container: {
        alignItems: Align.Center,
        justifyContent: Justify.Center,
        width: CONSTANTS.BaseDimensions.Width
      },
      avatarEditorAnimated: {
        fullViewComponentsAnimated: {
          opacity: this.animatedValues.fullViewComponentsOpacity
        },
        editorAnimated: {
          opacity: this.animatedValues.avatarEditorOpacity,
          width: this.animatedValues.avatarEditorWidth
        },
        selectionContainerAnimated: {
          width: this.animatedValues.selectionContainerWidth
        }
      },
      avatarAnimated: {
        nameAnimated: {
          transform: [
            {
              translateY: this.animatedValues.nameTranslateY
            }
          ]
        },
        avatarAnimated: {
          opacity: this.animatedValues.avatarOpacity
        },
        thumbnailAnimated: {
          opacity: this.animatedValues.thumbnailOpacity,
          transform: [{ translateY: this.animatedValues.thumbnailTranslateY }]
        }
      }
    };
  }

  private createSlideOutFaceSelectorAnimation() {
    const nameShiftDown = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        this.animatedValues.nameTranslateY,
        {
          delay: ENTRY_DELAY,
          duration: ANIMATION_DURATION,
          easing: loboAnimated.Easing.In(),
          toValue: this.fullViewValues.nameTranslateY
        }
      )
    ]);

    const thumbnailShiftUp = loboAnimated.timing(
      AnimatableCSSProperty.TranslateY,
      this.animatedValues.thumbnailTranslateY,
      {
        delay: ENTRY_DELAY,
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: -170
      }
    );

    const thumbnailFadeOut = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.thumbnailOpacity,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.thumbnailOpacity
      }
    );

    const avatarFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.avatarOpacity,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.avatarOpacity
      }
    );

    const avatarEditorFullOpacity = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.avatarEditorOpacity,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.avatarEditorOpacity
      }
    );

    const avatarEditorWidthExpand = loboAnimated.timing(
      AnimatableCSSProperty.Width,
      this.animatedValues.avatarEditorWidth,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: 310
      }
    );

    return loboAnimated.sequence([
      loboAnimated.parallel([nameShiftDown, thumbnailShiftUp]),
      loboAnimated.parallel([thumbnailFadeOut, avatarFadeIn]),
      loboAnimated.parallel([avatarEditorFullOpacity, avatarEditorWidthExpand])
    ]);
  }

  private createExpandToFullEditorAnimation() {
    const avatarEditorWidthExpand = loboAnimated.timing(
      AnimatableCSSProperty.Width,
      this.animatedValues.avatarEditorWidth,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.avatarEditorWidth
      }
    );

    const selectionContainerWidthExpand = loboAnimated.timing(
      AnimatableCSSProperty.Width,
      this.animatedValues.selectionContainerWidth,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.selectionContainerWidth
      }
    );

    const fullViewComponentsFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.fullViewComponentsOpacity,
      {
        duration: ANIMATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: this.fullViewValues.fullViewComponentsOpacity
      }
    );

    return loboAnimated.sequence([
      loboAnimated.parallel([
        avatarEditorWidthExpand,
        selectionContainerWidthExpand
      ]),
      fullViewComponentsFadeIn
    ]);
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
