import { IBootstrapContent } from '@lexialearning/lobo-common';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { BootstrapContentActionType } from './bootstrap-content-redux.model';

export const BootstrapContentAction = {
  load: createAsyncAction(
    BootstrapContentActionType.Load,
    BootstrapContentActionType.LoadSuccess,
    BootstrapContentActionType.LoadFailure
  )<undefined, IBootstrapContentLoadSuccessPayload, Error>()
};

export interface IBootstrapContentLoadSuccessPayload {
  content: IBootstrapContent;
}

export type BootstrapContentActions = ActionType<typeof BootstrapContentAction>;
export type BootstrapContentActionLoad = ActionType<
  typeof BootstrapContentAction.load.request
>;
export type BootstrapContentActionLoadSuccess = ActionType<
  typeof BootstrapContentAction.load.success
>;
