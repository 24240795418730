import { useMemo } from 'react';
import { CenterLeftAlignWrap } from '../center-left-align-wrap/CenterLeftAlignWrap';
import { View } from '../view';
import {
  FillInTextPromptStyles,
  IFillInTextPromptStyleOverride
} from './FillInTextPrompt.styles';
import {
  BLANK_LINE,
  TextPromptSegment
} from './text-prompt-segment/TextPromptSegment';

export interface IFillInTextPromptProps {
  textPrompt: string;
  correctChoice?: string;
  shouldFillInText?: boolean;
  styleOverride?: IFillInTextPromptStyleOverride;
}
export function FillInTextPrompt(props: IFillInTextPromptProps) {
  const { textPrompt, correctChoice, shouldFillInText, styleOverride } = props;
  const styles = FillInTextPromptStyles.get();

  /**
   * Tokenizes received text prompt accounting for blank lines.
   * Regex looks for different variations of blank lines
   * Which are then replaced with a ' __ ' for consistency
   */
  const textPromptTokens = textPrompt
    .replace(/(?:\s*_+\s*)+/g, ` ${BLANK_LINE} `)
    .trim()
    .split(' ');

  const solutionMap = useMemo(() => {
    const fillInTextValues = correctChoice?.split('|') || [];
    let fillInTextValuesIndex = 0;

    return textPromptTokens.reduce((accMap, token, index) => {
      if (token === BLANK_LINE) {
        accMap.set(index, fillInTextValues[fillInTextValuesIndex]);
        fillInTextValuesIndex += 1;
      }

      return accMap;
    }, new Map<number, string>());
  }, [correctChoice, textPromptTokens]);

  const renderToken = (token: string, index: number, tokenProps) => {
    const isAnswerBlank = token === BLANK_LINE;
    const includesSentencePunctuation = /^[.,?!]/.test(token);
    const isNextTokenBlank = !!solutionMap.get(index + 1);
    const correctChoice = isAnswerBlank ? solutionMap.get(index)! : ' ';
    const textToken =
      isAnswerBlank || includesSentencePunctuation
        ? token
        : isNextTokenBlank
        ? ` ${token} `
        : ` ${token}`;
    const { shouldFillInText, styleOverride } = tokenProps;
    const styles = FillInTextPromptStyles.build(styleOverride);

    return (
      <TextPromptSegment
        correctChoice={correctChoice}
        shouldFillInText={shouldFillInText}
        style={styles.text}
        textToken={textToken}
      />
    );
  };

  return (
    <View style={styles.outerContainer} testId={FillInTextPrompt.displayName}>
      <CenterLeftAlignWrap
        width={styles.outerContainer.width}
        tokenArray={textPromptTokens}
        renderToken={renderToken}
        tokenProps={{ shouldFillInText, styleOverride }}
      />
    </View>
  );
}

FillInTextPrompt.displayName = 'FillInTextPrompt';
