import {
  IModeling,
  TaskAudioSupport,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { Music } from 'audio/music';
import { CommonUiAction } from 'common-ui';
import { RoundContext } from 'curriculum-services';
import { AppShellAction } from 'feature-areas/shell';
import { TaskDemoSceneAnimationName } from 'feature-areas/tasks/task-demo-scene/task-demo-scene.model';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TaskAction, TaskAudioSupportFactory } from 'task-components';
import { TaskReactAnimationName } from 'task-components/core/task-component.model';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { UnitIntroScreenplayBuilder } from '../units';
import { RoundIntroType } from './redux';

export interface IRoundIntroScreenplayDeps {
  roundContext: RoundContext;
}
export class RoundIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundIntroScreenplayBuilder';
  public static readonly ScreenplayId = 'RoundIntro';

  public static createFor(
    deps: IRoundIntroScreenplayDeps
  ): RoundIntroScreenplayBuilder {
    return new RoundIntroScreenplayBuilder(deps);
  }

  private constructor(private readonly deps: IRoundIntroScreenplayDeps) {
    super(RoundIntroScreenplayBuilder.ScreenplayId);
  }

  public dispatchTaskEntry(): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(TaskAction.entry());

    return this;
  }

  public attachCharacterMaybe(
    preparedScenes: PreparedScenes
  ): RoundIntroScreenplayBuilder {
    if (this.deps.roundContext.hasOnscreenCharacter) {
      this.builder.addCallback(() => {
        preparedScenes.encounterOrPlacement?.attachCharacter();
      });
    }

    return this;
  }

  public playUnitIntroMaybe(
    preparedScenes: PreparedScenes,
    roundIntroType: RoundIntroType
  ): RoundIntroScreenplayBuilder {
    const { roundContext } = this.deps;
    // Only play full unit intro when entering a unit
    // ie, not when going between rounds in the same unit
    if (roundIntroType !== RoundIntroType.RoundIntro) {
      const unitIntroScreenplay = UnitIntroScreenplayBuilder.createFor({
        preparedScenes,
        roundContext,
        roundIntroType
      }).screenplay;

      this.builder.addScreenplay(unitIntroScreenplay);
    }

    return this;
  }

  public playTaskDemo(
    preparedScenes: PreparedScenes,
    taskType: TaskTypeName
  ): RoundIntroScreenplayBuilder {
    this.builder
      .addCallback(async () => {
        const taskDemo = await preparedScenes.taskDemoReady;
        taskDemo.show();
      })
      .addReduxAction(AppShellAction.showSkipButton())
      .addStormAnimation(
        {
          name: TaskDemoSceneAnimationName.Root.Intro,
          targetScene: SceneName.TaskDemo
        },
        { concurrent: true }
      )
      .addMusic({
        path: Music.TaskDemo[taskType]
      });

    return this;
  }

  public setTaskDemoShown(taskType: TaskTypeName): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(CommonUiAction.setTaskDemoShown(taskType));

    return this;
  }

  public hideSkipButton(): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(AppShellAction.hideSkipButton());

    return this;
  }

  public fadeInTaskElements(): RoundIntroScreenplayBuilder {
    if (!this.deps.roundContext.isInstructionRound) {
      this.builder.addReactAnimation(TaskReactAnimationName.StandardRoundEntry);
    }

    return this;
  }

  public playPostEntryScreenplayMaybe(): RoundIntroScreenplayBuilder {
    const { roundContext } = this.deps;
    const { buildPostEntryScreenplay } = roundContext.taskRegistration;
    if (buildPostEntryScreenplay) {
      this.builder.addScreenplay(buildPostEntryScreenplay(roundContext));
    }

    return this;
  }

  public playPreambleCompleteScreenplayMaybe(): RoundIntroScreenplayBuilder {
    const { roundContext } = this.deps;
    const { buildPreambleCompleteScreenplay } = roundContext.taskRegistration;
    if (buildPreambleCompleteScreenplay) {
      this.builder.addScreenplay(buildPreambleCompleteScreenplay(roundContext));
    }

    return this;
  }

  public dispatchTaskEntryComplete(): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(TaskAction.entryComplete());

    return this;
  }

  public dispatchTaskPreamble(): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(TaskAction.preamble());

    return this;
  }

  public addPreambleScreenplay(
    modeling: IModeling
  ): RoundIntroScreenplayBuilder {
    const { roundContext } = this.deps;
    const audioSupportScreenplay = TaskAudioSupportFactory.create(
      roundContext.round,
      modeling,
      TaskAudioSupport.Preamble
    );
    // Adding delay for better UX (https://jira.lexialearning.com/browse/LOBO-16300)
    this.builder.addDelay(500).addScreenplay(audioSupportScreenplay);

    return this;
  }

  public dispatchTaskPreambleComplete(): RoundIntroScreenplayBuilder {
    this.builder.addReduxAction(TaskAction.preambleComplete());

    return this;
  }
}
