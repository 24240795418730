import { CalibrationButtonMeterAnimatedStyles } from './CalibrationButtonMeter.animated-styles';
import {
  AnimationEndState,
  ButtonMeter,
  CalibrationMeter,
  IButtonMeterAnimations,
  useButtonMeterTransitions
} from 'shared-components';
import { CalibrationMicButton } from '../calibration-mic-button';
import { CalibrationStatus, SreSelector, SreSessionType } from 'sre';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CalibrationStep,
  CalibrationUiAction,
  CalibrationUiSelector
} from '../../redux';
import { Types } from '@lexialearning/reactxp';

export function CalibrationButtonMeter(): React.ReactElement {
  const { calibrationStatus, isListening, interactionState, step } =
    useSelectors();

  const { animateTransition, isAnimating, showMeter, showMic } =
    useButtonMeterTransitions();

  // STYLES AND ANIMATIONS
  const animatedStyles = useRef(new CalibrationButtonMeterAnimatedStyles());
  const styles = animatedStyles.current.get();
  const animations = animatedStyles.current.getAnimations();

  useAnimationEffects(
    animations,
    animateTransition,
    isListening,
    calibrationStatus,
    step
  );

  return (
    <ButtonMeter
      showMeter={showMeter}
      showMic={showMic}
      styles={styles}
      renderMeter={() => (
        <CalibrationMeter
          interactionState={interactionState}
          styleOverride={styles.vuMeterOverrides}
          withCaret
          withSparkles
        />
      )}
      renderMic={() => (
        <CalibrationMicButton
          disabled={isAnimating || isListening}
          interactionState={interactionState}
        />
      )}
    />
  );
}
CalibrationButtonMeter.displayName = 'CalibrationButtonMeter';

function useSelectors() {
  const calibrationStatus = useSelector(SreSelector.getCalibration).status;
  const isListening = useSelector((state: unknown) =>
    SreSelector.getIsListeningTo(SreSessionType.Calibration, state)
  );
  const interactionState = useSelector(
    CalibrationUiSelector.getMicInteractionState
  );
  const step = useSelector(CalibrationUiSelector.getStep);

  return {
    calibrationStatus,
    interactionState,
    isListening,
    step
  };
}

function useAnimationEffects(
  animations: IButtonMeterAnimations,
  animateTransition: (args: {
    animation: Types.Animated.CompositeAnimation;
    endState: AnimationEndState;
  }) => Promise<void>,
  isListening: boolean,
  calibrationStatus: CalibrationStatus,
  step: CalibrationStep
) {
  const { reset, showVuMeter } = animations;

  // DISPATCHES
  const dispatch = useDispatch();
  const setInteractive = useCallback(() => {
    dispatch(
      CalibrationUiAction.setStep({ step: CalibrationStep.Interactive })
    );
  }, [dispatch]);

  useEffect(() => {
    if (isListening) {
      if (step === CalibrationStep.Interactive) {
        animateTransition({
          animation: showVuMeter,
          endState: AnimationEndState.ShowMeter
        });
      }
    } else if (step === CalibrationStep.TryAgain) {
      animateTransition({
        animation: reset,
        endState: AnimationEndState.ShowMic
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListening, step]);

  // Upon mic clicked in TryAgain, step should be set to Interactive
  useEffect(() => {
    if (isListening && step !== CalibrationStep.Interactive) {
      setInteractive();
    }
  }, [isListening, setInteractive, step]);

  // Reset if Calibration canceled
  // (eg, if mic permissions modal came up after calibration started)
  useEffect(() => {
    if (
      !isListening &&
      calibrationStatus === CalibrationStatus.NotCalibrated &&
      step === CalibrationStep.Interactive
    ) {
      animateTransition({
        animation: reset,
        endState: AnimationEndState.ShowMic
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListening]);
}
