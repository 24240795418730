import { IProgramPosition } from '@lexialearning/lobo-common';
import {
  IMicrophone,
  ISoundLogCollectionPolicy,
  ISoundLogsCollectionPoliciesRequest,
  ISoundLogsCollectionPoliciesResponse,
  ISreAppConfig,
  SoundLogCollection
} from '@lexialearning/lobo-common/main-model/sre';
import { ProductCode } from '@lexialearning/main-model';
import { Fetcher, FetchMethod, LexiaError } from '@lexialearning/utils';
import { DeviceInfoReporter, Logger } from '@lexialearning/utils-react';
import { GradeName } from 'lexia-service';
import { Version } from 'services/app-config';

export interface IPolicyLoaderContext {
  grade: GradeName;
  mics: IMicrophone[];
  position: IProgramPosition;
  sessionId: string | undefined;
  siteID: string;
  studentId: string | undefined;
}

export enum SoundLogCollectionPoliciesLoaderError {
  Error = 'Error'
}

export class SoundLogCollectionPolicyLoader {
  public static readonly displayName = 'SoundLogCollectionPoliciesLoader';

  public static readonly Endpoint = 'get-collection-policies';

  public static async load(
    context: IPolicyLoaderContext,
    sreConfig: ISreAppConfig
  ): Promise<ISoundLogCollectionPolicy[]> {
    if (sreConfig.soundLogCollectionMode !== SoundLogCollection.Full) {
      return [];
    }

    try {
      const url = [sreConfig.soundLogUrl, this.Endpoint].join('/');
      const payload = await this.createRequestPayload(context);

      const response =
        await Fetcher.fetch<ISoundLogsCollectionPoliciesResponse>(url, {
          body: payload,
          method: FetchMethod.Post,
          retryThrottleSeconds: [5, 10, 15]
        });

      return response.policies;
    } catch (err) {
      void Logger.logError(
        new LexiaError(
          'Error loading sound log collection policies',
          SoundLogCollectionPolicyLoader.displayName,
          SoundLogCollectionPoliciesLoaderError.Error
        )
          .withCause(err)
          .withContext({ context, sreConfig })
      );

      return [];
    }
  }

  private static async createRequestPayload(
    context: IPolicyLoaderContext
  ): Promise<ISoundLogsCollectionPoliciesRequest> {
    const deviceInfo = await DeviceInfoReporter.create();

    return {
      deviceInfo,
      micsAvailable: context.mics,
      positionInfo: { position: context.position },
      product: {
        name: ProductCode.Lobo,
        version: Version.Main
      },
      user: {
        grade: context.grade,
        sessionId: context.sessionId ?? 'n/a',
        siteID: context.siteID,
        studentId: context.studentId ?? 'n/a'
      }
    };
  }
}
