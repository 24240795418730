import { IQuestionAndAnswerBubblesAnimatedStyles } from './question-and-answer/QuestionAndAnswerSpeechBubbles';
import { IResponseSpeechBubbleAnimatedStyles } from './shared/speech-bubble/ResponseSpeechBubble';
import { ISayItAfterSpeechBubblesAnimatedStyles } from './say-it-after/SayItAfterSpeechBubbles';
import { SeeSpeakMode } from '@lexialearning/lobo-common';
import { Justify } from '@lexialearning/common-ui';

export interface ISpeechBubbleCommonProps {
  mode: SeeSpeakMode;
  animatedStyles:
    | ISpeechBubblesAnimatedStyles
    | IQuestionAndAnswerBubblesAnimatedStyles
    | ISayItAfterSpeechBubblesAnimatedStyles;
}

export type ISpeechBubblesAnimatedStyles =
  | IQuestionAndAnswerBubblesAnimatedStyles
  | ISayItAfterSpeechBubblesAnimatedStyles
  | {
      response: IResponseSpeechBubbleAnimatedStyles;
    };

export enum SpeechBubbleWidth {
  Standard = 565,
  Instruction = 660,
  InstructionSmall = 610
}

export const SPEECH_BUBBLE_PADDING = 32;
export const STANDARD_SPEECH_BUBBLE_CONTENTS_WIDTH =
  SpeechBubbleWidth.Standard - 2 * SPEECH_BUBBLE_PADDING;
export const STANDARD_SPEECH_BUBBLE_TEXT_SHARED = {
  justifyContent: Justify.Center,
  minHeight: 32
};

export enum SeeSpeakType {
  Choral = 'choral',
  QuestionAnswer = 'questionAnswer',
  SayItAfter = 'sayItAfter',
  SayItAgain = 'sayItAgain'
}

export enum SpeechBubbleReactAnimationName {
  BubblesFadeIn = 'BubblesFadeIn',
  MicFadeIn = 'MicFadeIn',
  ResponseEntry = 'ResponseEntry'
}

export enum SpeechBubbleAnimationTimes {
  FadeIn = 400,
  Long = 500,
  Short = 200,
  Standard = 300
}

export enum BorderColorValues {
  Black = 1,
  Transparent = 0
}
