import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from 'common-ui';
import { G, Path } from 'common-ui/components/svg';

const shadowStrokeProps = {
  stroke: '#03708E',
  strokeWidth: '1'
};

export function MathIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  // Shadow color not used directly, but only to determine if a
  // 'shadow' (stroke in this case) is desired
  const strokeProps = shadowColor ? shadowStrokeProps : {};

  return (
    <G
      transform="translate(11 0) scale(0.67)"
      fill={iconColor}
      {...strokeProps}
    >
      <Path
        transform="translate(8.12 -7.443)"
        d="M33.38,32.54,16.12,45.2l19.594,8.619ZM22.769,44.472l7.919-5.812,1.07,9.769Z"
      />
      <Path
        transform="translate(-0.877 -5.856)"
        d="M82.026,46.448a17.647,17.647,0,1,0-33.758,7.258L36.45,58.067l8.072,21.888,21.894-8.072-2.9-7.8c.273,0,.569.028.825.028A17.687,17.687,0,0,0,82.026,46.448ZM46.5,75.663,40.748,60.048l15.615-5.761,5.761,15.638ZM58.344,50l-6.934,2.55a14.357,14.357,0,1,1,10.845,8.061Z"
      />
    </G>
  );
}
MathIconSvg.displayName = 'MathIconSvg';
