import * as React from 'react';
import { connect } from 'react-redux';
import { Color } from 'common-styles';
import {
  AnimatedRow,
  RoundedTabPosition,
  RoundedTabSvg,
  View
} from 'common-ui';
import { Services } from 'services';
import { ProgramContextSelector } from 'services/curriculum-services';
import { IMeterMarker } from '../progress-meters.model';
import { BarAnimatedStyles } from '../unit-meter/bar/Bar.animated-styles';
import { UnitMeterSelector } from '../unit-meter/redux/UnitMeter.selector';
import { Pip } from './pip/Pip';
import { RoundMeterSelector } from './redux/RoundMeter.selector';
import { RoundMeterAnimatedStyles } from './RoundMeter.animated-styles';

export interface IRoundMeterProps {
  markers: IMeterMarker[];
  isRecyclePass: boolean;
  currentUnitIndex: number;
}

export class RoundMeterComponent extends React.PureComponent<IRoundMeterProps> {
  public static readonly displayName = 'RoundMeter';

  public static readonly entryAnimationName = 'RoundMeterEntry';

  private animatedStyles: RoundMeterAnimatedStyles;
  private readonly unregisterAnimations: void | (() => void);

  constructor(props: IRoundMeterProps) {
    super(props);
    this.animatedStyles = new RoundMeterAnimatedStyles(props.currentUnitIndex);
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentDidUpdate() {
    const { currentUnitIndex } = this.props;
    const { animateEntry } = this.animatedStyles.getAnimations();
    if (!animateEntry && currentUnitIndex !== -1) {
      this.animatedStyles = new RoundMeterAnimatedStyles(
        this.props.currentUnitIndex
      );
      this.registerAnimations();
      this.forceUpdate();
    }
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations?.();
  }

  private registerAnimations(): void | (() => void) {
    const { animateEntry } = this.animatedStyles.getAnimations();

    if (animateEntry) {
      return Services.reactAnimationScreenplayer.registerAnimations([
        /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
        {
          name: RoundMeterComponent.entryAnimationName,
          animation: animateEntry
        }
        /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
      ]);
    }
  }

  public render() {
    const { currentUnitIndex, markers, isRecyclePass } = this.props;

    if (!markers.length) {
      return null;
    }

    const roundedTabRectLength = (markers.length - 1) * 46;
    const roundedTabLength = roundedTabRectLength + 118;
    const roundedTabDepth = 33;
    const styles = this.animatedStyles.build(roundedTabLength, roundedTabDepth);
    const delayStart =
      currentUnitIndex * BarAnimatedStyles.AnimationTiming +
      RoundMeterAnimatedStyles.EntryAnimationTiming;

    return (
      <View style={styles.container} testId={RoundMeterComponent.displayName}>
        <AnimatedRow
          style={styles.pipRow.static}
          animatedStyle={styles.pipRow.animated}
        >
          <RoundedTabSvg
            position={RoundedTabPosition.Top}
            fill={Color.Black}
            opacity={0.3}
            depth={roundedTabDepth}
            length={roundedTabLength}
            rectLength={roundedTabRectLength}
          />
          {markers.map((marker, idx) => (
            <Pip
              key={`marker_${idx}`}
              sysId={marker.sysId}
              progress={marker.status}
              isRecyclePass={isRecyclePass}
              delayStart={delayStart}
            />
          ))}
        </AnimatedRow>
      </View>
    );
  }
}

export function mapStateToProps(state: unknown) {
  const context = ProgramContextSelector.getRoundContextMaybe(state);
  const isRecyclePass = !!context?.parentUnitRecyclePass;

  return {
    currentUnitIndex:
      UnitMeterSelector.getActiveOrLastCompletedMarkerIndex(state),
    isRecyclePass,
    markers: RoundMeterSelector.getMarkers(state)
  };
}

export const RoundMeter = connect(mapStateToProps)(RoundMeterComponent);

export const RoundMeterPrivates = { mapStateToProps };
