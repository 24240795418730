import memoizeOne from 'memoize-one';
import { Align, Justify } from 'common-styles';
import { FlexWrap } from '@lexialearning/common-ui';

export class CenterLeftAlignWrapStyles {
  public static readonly build = memoizeOne(
    (width: number, isSingleLine: boolean) => ({
      textContainer: {
        alignItems: Align.End,
        justifyContent: Justify.Start,
        ...(!isSingleLine && { flexWrap: FlexWrap.Wrap }), // Firefox will always wrap with this on, even if it doesn't have to
        ...(width && { maxWidth: width }) // set maxWidth, so lines will center even though aligned to the left
      }
    })
  );
}
