export enum AppShellActionType {
  DisableAudioSupport = 'APP_SHELL.DISABLE_AUDIO_SUPPORT',
  DisableUtilityBar = 'APP_SHELL.DISABLE_UTILITY_BAR',
  DisableUtilityBarZIndex = 'APP_SHELL.DISABLE_UTILITY_BAR_Z_INDEX',
  EnableAudioSupport = 'APP_SHELL.ENABLE_AUDIO_SUPPORT',
  EnableUtilityBar = 'APP_SHELL.ENABLE_UTILITY_BAR',
  EnableUtilityBarZIndex = 'APP_SHELL.ENABLE_UTILITY_BAR_Z_INDEX',
  HideModal = 'APP_SHELL.HIDE_MODAL',
  HideSkipButton = 'APP_SHELL.HIDE_SKIP_BUTTON',
  NavigateBack = 'APP_SHELL.NAVIGATE_BACK',
  NavigateBackToEducator = 'APP_SHELL.NAVIGATE_BACK_TO_EDUCATOR',
  NotifyNavigationHistoryUpdate = 'APP_SHELL.NOTIFY_NAVIGATION_HISTORY_UPDATE',
  RequestLogout = 'APP_SHELL.REQUEST_LOGOUT',
  SetControlPanelLayout = 'APP_SHELL.SET_CONTROL_PANEL_LAYOUT',
  SetMode = 'APP_SHELL.SET_MODE',
  SetNavigationButtonType = 'APP_SHELL.SET_NAVIGATION_BUTTON_TYPE',
  SetShouldCalloutLevelCertificates = 'APP_SHELL.SET_SHOULD_CALLOUT_LEVEL_CERTIFICATES',
  SetUserControlInteractionState = 'APP_SHELL.SET_USER_CONTROL_INTERACTION_STATE',
  SetUserInfoButtonType = 'APP_SHELL.SET_INFO_BUTTON_TYPE',
  ShowModal = 'APP_SHELL.SHOW_MODAL',
  ShowSkipButton = 'APP_SHELL.SHOW_SKIP_BUTTON',
  SkipButtonPressed = 'APP_SHELL.SKIP_BUTTON_PRESSED'
}
