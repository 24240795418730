import memoizeOne from 'memoize-one';
import { Color, InteractionState, Justify } from 'common-styles';

export class CompletionAnswerSlotStyles {
  public static readonly answerSlotHeight = 34;

  public static readonly build = memoizeOne(
    (
      isHovered: boolean,
      pillWidth: number,
      interactionState: InteractionState
    ) => {
      const isHighlighted = interactionState === InteractionState.Highlighted;

      return {
        answerLine: {
          borderWidth: 0,
          borderBottomWidth: 2,
          borderColor: Color.Black,
          width: pillWidth - 40
        },
        answerPill: {
          backgroundColor: isHovered
            ? Color.NearWhiteTransparent40
            : Color.Transparent,
          borderRadius: 30,
          height: 34,
          justifyContent: Justify.End,
          marginBottom: 3,
          marginTop: 2,
          width: pillWidth
        },
        choiceStyleOverride: {
          textPill: {
            container: {
              backgroundColor: isHighlighted
                ? Color.getSetFor(InteractionState.Highlighted).background
                : Color.NearWhiteTransparent40,
              borderBottomWidth: isHighlighted ? 3 : 0,
              borderWidth: isHighlighted ? 3 : 0,
              height: CompletionAnswerSlotStyles.answerSlotHeight
            },
            text: {
              color: Color.getSetFor(
                isHighlighted ? InteractionState.Default : interactionState
              ).text
            }
          }
        },
        postPunctuation: {
          paddingRight: 3
        },
        prePunctuation: {
          paddingLeft: 2
        }
      };
    }
  );
}
