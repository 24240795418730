import { toNumber } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Row, TextButton, TextButtonType, TextInput } from 'common-ui';
import { AppConfigAction } from 'app-config';
import { EditConfigValueStyles } from './EditConfigValue.styles';
import { ButtonType } from '@lexialearning/reactxp/dist/common/Types';
import { IConfigOverride } from '@lexialearning/utils';

export interface IEditConfigValueProps {
  initialKey: string;
  initialValue: boolean | string | number;
  apply(overrides: IConfigOverride[]): void;
}

interface IEditConfigValueModalState {
  key: string;
  value: string;
}

export class EditConfigValueComponent extends React.PureComponent<
  IEditConfigValueProps,
  IEditConfigValueModalState
> {
  public static readonly displayName = 'EditConfigValue';

  constructor(props: IEditConfigValueProps) {
    super(props);
    this.state = {
      key: props.initialKey,
      value: props.initialValue.toString()
    };
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  public componentDidUpdate(prevProps: IEditConfigValueProps) {
    const { initialKey, initialValue } = this.props;
    if (prevProps.initialKey !== initialKey) {
      this.setState({ key: initialKey });
    }
    if (prevProps.initialValue !== initialValue) {
      this.setState({ value: initialValue.toString() });
    }
  }

  private getTypedValue(valueStr: string): boolean | string | number {
    switch (typeof this.props.initialValue) {
      case 'boolean':
        return valueStr.toLowerCase() === 'true';
      case 'number':
        return toNumber(valueStr);
      case 'string':
      default:
        return valueStr;
    }
  }

  private handleKeyChange(key: string) {
    this.setState({ key });
  }

  private handleValueChange(value: string): void {
    this.setState({ value });
  }

  private handleApply(): void {
    this.props.apply([
      { key: this.state.key, value: this.getTypedValue(this.state.value) }
    ]);
    this.setState({ key: '', value: '' });
  }

  public render() {
    const { key, value } = this.state;
    const styles = EditConfigValueStyles.get();

    return (
      <Row>
        <TextInput
          name="key"
          placeholder="Key"
          value={key}
          onChange={this.handleKeyChange}
          styleOverrides={styles.textInput}
        />
        <TextInput
          name="value"
          placeholder="Value"
          value={value}
          multiline
          onChange={this.handleValueChange}
          styleOverrides={styles.textInput}
        />
        <TextButton
          disabled={!key || !value}
          onPress={this.handleApply}
          text="Apply"
          buttonType={TextButtonType.Primary}
          styleOverride={styles.applyButton}
          type={ButtonType.Submit}
        />
      </Row>
    );
  }
}
const mapDispatchToProps = {
  apply: (overrides: IConfigOverride[]) =>
    AppConfigAction.applyOverrides({ overrides })
};

export const EditConfigValue: any = connect(
  undefined,
  mapDispatchToProps
)(EditConfigValueComponent);
