import { IScreenplay, TaskAudioSupport } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { InteractionState } from 'common-styles';
import {
  AnimatedView,
  GlossyButton,
  GlossyButtonIconType,
  Text
} from 'common-ui';
import { IGlossyButtonProps } from 'common-ui/components/button/glossy-button/GlossyButton';
import {
  PlacementAction,
  PlacementSelector,
  ProgramContextSelector,
  UnitSelector,
  UnitType
} from 'curriculum-services';
import { ScreenplayAction, ScreenplaySelector } from 'screenplay';
import { AppShellAction } from '../redux';
import { LookbackButtonAnimatedStyles } from './LookbackButton.animated-styles';

export interface ILookbackButtonProps extends IGlossyButtonProps {
  shouldShow: boolean;
  limitRehears: boolean;
  isPlayingLookback: boolean;
  isPlayingScreenplay: boolean;
  isOnboardingOrPlacement: boolean;
  lookbackSingleClickVoiceover: IScreenplay | undefined;
  disableAudioSupport(): void;
  enableAudioSupport(): void;
  updateHasPlayedLookbackIntro(): void;
  playIntro(vo: IScreenplay | undefined): void;
}

export interface ILookbackButtonState {
  remainingRehearCount: number;
}

export class LookbackButtonComponent extends React.PureComponent<
  ILookbackButtonProps,
  ILookbackButtonState
> {
  public static readonly displayName = 'LookbackButton';

  private readonly animatedStyles: LookbackButtonAnimatedStyles;

  constructor(props: ILookbackButtonProps) {
    super(props);

    this.state = {
      remainingRehearCount: props.limitRehears ? 1 : -1
    };

    this.animatedStyles = new LookbackButtonAnimatedStyles({
      hasBadge: props.limitRehears
    });
    this.handlePress = this.handlePress.bind(this);
    this.showLookback = this.showLookback.bind(this);
  }

  public componentDidMount() {
    const { shouldShow } = this.props;

    if (shouldShow) {
      this.showLookback();
    }
  }

  public componentDidUpdate(prevProps: ILookbackButtonProps) {
    const { shouldShow, limitRehears, isPlayingLookback, isPlayingScreenplay } =
      this.props;
    const animations = this.animatedStyles.getAnimations();
    const { hide } = animations;

    if (!prevProps.shouldShow && shouldShow) {
      this.showLookback();
    } else if (prevProps.shouldShow && !shouldShow) {
      hide.start();
    }

    if (limitRehears) {
      if (prevProps.isPlayingLookback && !isPlayingLookback) {
        this.props.enableAudioSupport();
        if (shouldShow && !isPlayingScreenplay) {
          // do not reduce count if visibility changed or another VO took over
          this.setState({
            remainingRehearCount: this.state.remainingRehearCount - 1
          });
        }
      }
    }
  }

  private showLookback() {
    const { show } = this.animatedStyles.getAnimations();
    const {
      lookbackSingleClickVoiceover,
      updateHasPlayedLookbackIntro,
      isOnboardingOrPlacement,
      playIntro
    } = this.props;

    if (isOnboardingOrPlacement && !!lookbackSingleClickVoiceover) {
      updateHasPlayedLookbackIntro();
      playIntro(lookbackSingleClickVoiceover);
    }
    show.start();
  }

  private handlePress(): void {
    const { limitRehears, onPress, disableAudioSupport } = this.props;

    if (onPress) {
      if (limitRehears) {
        disableAudioSupport();
      }
      onPress();
    }
  }

  private getInteractiveState() {
    const { limitRehears, interactionState, isPlayingLookback } = this.props;
    const { remainingRehearCount } = this.state;

    if (limitRehears && remainingRehearCount < 1) {
      return !isPlayingLookback
        ? InteractionState.Disabled
        : InteractionState.Default;
    }

    return interactionState;
  }

  public render() {
    const { limitRehears, isPlayingLookback, shouldShow } = this.props;
    const { remainingRehearCount } = this.state;

    const disabled =
      limitRehears &&
      (isPlayingLookback || remainingRehearCount < 1 || !shouldShow)
        ? true
        : this.props.disabled;

    const styles = this.animatedStyles.build(!!disabled);
    const interactionState = this.getInteractiveState();

    return (
      <AnimatedView
        style={styles.container}
        animatedStyle={styles.containerAnimated}
        testId={LookbackButtonComponent.displayName}
      >
        <GlossyButton
          icon={GlossyButtonIconType.LookBack}
          disabled={disabled}
          interactionState={interactionState}
          testId="lookBackButton"
          onPress={this.handlePress}
          styleOverride={styles.buttonOverride}
        />
        {limitRehears && (
          <AnimatedView animatedStyle={styles.badgeAnimated}>
            <Text style={styles.badgeText}>x{remainingRehearCount}</Text>
          </AnimatedView>
        )}
      </AnimatedView>
    );
  }
}

function mapStateToProps(state: unknown) {
  const activeScreenplay = ScreenplaySelector.getActiveScreenplayId(state);
  const isOnboardingOrPlacement =
    ProgramContextSelector.isOnboardingOrPlacement(state);
  const isPoK =
    UnitSelector.getType(state) === UnitType.PresentationOfKnowledge;

  return {
    isOnboardingOrPlacement,
    isPlayingLookback: activeScreenplay === TaskAudioSupport.LookBack,
    isPlayingScreenplay: !!activeScreenplay,
    limitRehears: isOnboardingOrPlacement || isPoK,
    lookbackSingleClickVoiceover:
      PlacementSelector.getLookbackSingleClickVoiceover(state)
  };
}

const mapDispatchToProps = {
  disableAudioSupport: () => AppShellAction.disableAudioSupport(),
  enableAudioSupport: () => AppShellAction.enableAudioSupport(),
  playIntro: (vo: IScreenplay) => ScreenplayAction.play({ screenplay: vo }),
  updateHasPlayedLookbackIntro: () =>
    PlacementAction.updateHasPlayedLookbackIntro()
};

export const LookbackButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(LookbackButtonComponent);

export const LookbackButtonPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
