import { StudentApiSelector } from '@lexialearning/student-api';
import { useSelector } from 'react-redux';

export const useGetCumulativeWeeklyMinutes = ({ loginTime }) => {
  const currentWeekMinutes =
    (useSelector(StudentApiSelector.getUsageMetrics)?.secondsInProgram
      ?.currentWeek || 0) / 60;

  const diffMs = Date.now() - (loginTime || 0);
  const accruedMinutes = diffMs / 60000;

  const cumulativeWeeklyMinutes = Math.ceil(
    currentWeekMinutes + accruedMinutes
  );

  return { cumulativeWeeklyMinutes };
};
