import * as React from 'react';
import {
  AccessibilityOrientation,
  AccessibilityRole
} from '@lexialearning/common-ui';
import { LoginTabButton } from './LoginTabButton';
import { LoginTabName } from 'router-service';
import { LoginTabsStyles } from './LoginTabs.styles';
import { Row } from 'common-ui/components/layout';
import { TabType } from 'common-styles';

export interface ILoginTabsProps {
  disabled?: boolean;
  selectedTab: LoginTabName;
  onSelect(tabName: string): void;
  tabPanelId: string;
}

export class LoginTabs extends React.PureComponent<ILoginTabsProps> {
  public static readonly displayName = 'LoginTabs';

  public render() {
    const { disabled, selectedTab, onSelect, tabPanelId } = this.props;
    const styles = LoginTabsStyles.get();

    return (
      <Row
        accessibilityLabel="Login Tabs."
        accessibilityRole={AccessibilityRole.TabList}
        ariaOrientation={AccessibilityOrientation.Horizontal}
        style={styles.container}
      >
        <LoginTabButton
          onTabPress={onSelect}
          selected={selectedTab === LoginTabName.Student}
          tabButtonId={LoginTabName.Student}
          tabPanelId={tabPanelId}
          tabType={TabType.Left}
          text={LoginTabName.Student}
          disabled={disabled}
        />
        <LoginTabButton
          onTabPress={onSelect}
          selected={selectedTab === LoginTabName.Educator}
          tabButtonId={LoginTabName.Educator}
          tabPanelId={tabPanelId}
          tabType={TabType.Right}
          text={LoginTabName.Educator}
          disabled={disabled}
        />
      </Row>
    );
  }
}
