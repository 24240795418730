import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { get } from 'lodash';
import { CharacterAnimationCategory, SceneZIndex } from 'services/storm-lobo';
import { SceneControllerBase } from 'services/storm-lobo/SceneControllerBase';
import { Scene, SceneElement } from 'storm';
import { ITalkInfo } from 'storm/scenes/storm-scenes.model';
import {
  CharacterSceneCharacterAnimationLayer,
  CharacterSceneElementName
} from './character-scene.model';

/**
 * Base class to be extended by scenes which have a character (currently PlacementScene and EncounterScene)
 */
export class CharacterSceneBase extends SceneControllerBase {
  public get character(): SceneElement {
    return this.scene.elementMap.get(CharacterSceneElementName.Character)!;
  }

  private gestureAnimation: IAnimationDefinition | undefined;

  constructor(scene: Scene, zIndex: SceneZIndex) {
    super(scene, zIndex);
    this.character.onTalk = this.handleTalking.bind(this);
    this.character.onCancelTalking = this.handleCancelTalking.bind(this);
  }

  private handleTalking(info: ITalkInfo): void {
    const animationCategory =
      get(info.directions, 'category') ||
      CharacterAnimationCategory.TalkingGestures;

    this.gestureAnimation = this.character.pickOneAnimation(animationCategory);

    this.character
      .playAnimation({
        animationLayer: CharacterSceneCharacterAnimationLayer.Gesture,
        blendTimeSeconds: 0.25,
        loop: false,
        name: this.gestureAnimation.name
      })
      .finally(() => {
        this.gestureAnimation = undefined;
      });
  }

  private handleCancelTalking(): void {
    if (this.gestureAnimation) {
      this.character.stopAnimation({
        animationLayer: CharacterSceneCharacterAnimationLayer.Gesture,
        name: this.gestureAnimation.name
      });
    }
  }

  public attachCharacter() {
    this.character.attach();
  }

  public detachCharacter() {
    this.character.detach();
  }
}
