import { TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { createCarouselTaskRegistration } from 'task-components/carousel-task/createCarouselTaskRegistration';
import { createCompletionTaskRegistration } from 'task-components/completion/createCompletionTaskRegistration';
import { TaskRegistry } from 'task-components/core';
import { createImageTaskRegistration } from 'task-components/image-task/createImageTaskRegistration';
import { createMultiSelectTaskRegistration } from 'task-components/multi-select';
import {
  createSingleSelectTaskRegistration,
  createYesNoTaskRegistration
} from 'task-components/multiple-choice';
import { createPlaceholderTaskRegistration } from 'task-components/placeholder-task/createPlaceholderTaskRegistration';
import { createPodcastRegistration } from 'task-components/podcast/createPodcastRegistration';
import { SeeSpeakTaskRegistrationFactory } from 'task-components/see-speak';
import { createTextOrderingTaskRegistration } from 'task-components/text-ordering';
import { createTextboardRegistration } from 'task-components/textboard/createTextboardRegistration';
import { UserChoiceRegistrationFactory } from 'task-components/user-choice';
import { createVerticalOrderingTaskRegistration } from 'task-components/vertical-ordering';

export const taskRegistry = new TaskRegistry<TaskTypeName>([
  createCompletionTaskRegistration(),
  createSingleSelectTaskRegistration(),
  createYesNoTaskRegistration(),
  createMultiSelectTaskRegistration(),
  createPlaceholderTaskRegistration(),
  createImageTaskRegistration(),
  createPodcastRegistration(),
  SeeSpeakTaskRegistrationFactory.create(),
  UserChoiceRegistrationFactory.create(),
  createTextboardRegistration(),
  createTextOrderingTaskRegistration(),
  createVerticalOrderingTaskRegistration(),
  createCarouselTaskRegistration()
]);

taskRegistry.placeholder = taskRegistry.get(TaskTypeName.Placeholder);
