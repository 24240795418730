import { memo } from 'react';
import { Sparkle } from './Sparkle';
import { shuffle } from 'lodash';

export interface ISparklesProps {
  triggerAnimation: number;
}

const sparkleProps = [
  /* eslint-disable sort-keys */
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  { fromX: 8, toX: 14, fromY: 8, toY: -67 },
  { fromX: -5, toX: -18, fromY: 8, toY: -85 },
  { fromX: -2, toX: 0, fromY: 5, toY: -67 },
  { fromX: -5, toX: -13, fromY: -3, toY: -75 }
  /* eslint-enable sort-keys */
  /* eslint-enable sort-keys-fix/sort-keys-fix */
];

// Uses a numeric prop to trigger the sparkle animations so it can be
// incremented in order to start the animations again
// (unlike a boolean which needs to be reset to false before setting to
// true again in order to re-trigger, which causes undesired rerenders)
export const Sparkles = memo(({ triggerAnimation }: ISparklesProps) => (
  // Shuffle the props so that they are in a different order for each bar
  <>
    {shuffle(sparkleProps).map((props, idx) => (
      <Sparkle {...props} key={idx} triggerAnimation={triggerAnimation} />
    ))}
  </>
));

Sparkles.displayName = 'Sparkles';
