import { capitalize } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CharacterSceneScreenplayEditor } from 'feature-areas/character-scene';
import { EncounterContext, ProgramContextSelector } from 'curriculum-services';
import { CharacterName, PreparedScenes, SceneName } from 'services/storm-lobo';
import { StormService } from 'storm';
import { TaskRegistry } from 'task-components';
import {
  UnitCompleteSceneAction,
  UnitCompleteScenePrepareAction
} from '../../../units/unit-complete-scene/redux';
import { EncounterCompleteSceneDefinitionBuilder } from '../../encounter-complete-scene';
import { EncounterCompleteScene } from '../../encounter-complete-scene/EncounterCompleteScene';
import { EncounterCompleteSceneHelper } from '../../encounter-complete-scene/EncounterCompleteScene.helper';
import { EncounterScene } from '../EncounterScene';
import { EncounterSceneDefinitionBuilder } from '../EncounterSceneDefinitionBuilder';
import {
  EncounterSceneActionType,
  EncounterScenePrepareAction
} from '../redux';

export interface IPrepareEncounterScenesDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
  taskRegistry: TaskRegistry;
}

export function prepareEncounterScenesEpic(
  action$: Observable<EncounterScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareEncounterScenesDep
): Observable<UnitCompleteScenePrepareAction> {
  return action$.pipe(
    ofType(EncounterSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService, taskRegistry } = deps;
      preparedScenes.encounter = undefined;
      preparedScenes.encounterComplete = undefined;

      const context = ProgramContextSelector.getEncounterContext(state$.value);

      await stormService.initialization;
      // Running the awaits for these two scenes separately rather than using Promise.all
      // to avoid potential race conditions for unexplained scene errors
      // https://jira.lexialearning.com/browse/LOBO-13300
      // (also, no need for things that need encounter scene to have to wait for the
      //  encounter complete scene to be set up as well)
      const encounterScene = await createEncounterScene(context, stormService);
      taskRegistry.screenplayEditor = new CharacterSceneScreenplayEditor(
        encounterScene,
        SceneName.Encounter
      );
      preparedScenes.encounter = encounterScene;

      preparedScenes.encounterComplete = await createEncounterCompleteScene(
        context,
        stormService
      );
    }),
    // map UnitCompleteScene.prepare to ensure these run synchronously
    // and hopefully avoid the undefined scene error
    // https://jira.lexialearning.com/browse/LOBO-13300
    map(() => UnitCompleteSceneAction.prepare())
  );
}
prepareEncounterScenesEpic.displayName = 'prepareEncounterScenesEpic';

async function createEncounterScene(
  { act, encounter }: EncounterContext,
  stormService: StormService
): Promise<EncounterScene> {
  const { character } = act;
  const characterName = character && capitalize(character.code);
  const encounterSceneDefinition = EncounterSceneDefinitionBuilder.create()
    .withBackground(encounter.sceneBackground)
    .withCharacter(
      characterName as CharacterName,
      act.characterSetNumber
    ).definition;
  const encounterScene = await stormService.prepareScene(
    encounterSceneDefinition
  );

  return new EncounterScene(encounterScene);
}

async function createEncounterCompleteScene(
  context: EncounterContext,
  stormService: StormService
): Promise<EncounterCompleteScene> {
  const position = EncounterCompleteSceneHelper.getEncounterPosition(context);
  const encounterCompleteSceneDefinition =
    EncounterCompleteSceneDefinitionBuilder.create(position).definition;
  const encounterCompleteScene = await stormService.prepareScene(
    encounterCompleteSceneDefinition
  );

  return new EncounterCompleteScene(
    encounterCompleteScene,
    stormService,
    position
  );
}
