import { IStudentProperty } from '@lexialearning/student-api';
import { ProgramMode } from 'curriculum-services';
import { IPositionResponse } from 'curriculum-services/program-context/epics/student-progress-api';

export interface ILoginResponse {
  apiTimeoutArray: number[];
  authToken: string;
  firstName: string;
  grade: GradeName;
  lastName: string;
  loginId: string;
  minutesSinceLastLogin: number | undefined;
  personId: number;
  positionArray: IPositionResponse[];
  programMode: ProgramMode;
  role: UserRole;
  studentId: number | undefined;
  studentProperties: IStudentProperty[] | undefined;
}

export enum GradeName {
  UndefinedUK = 'Undefined (UK)',
  Undefined = 'Undefined',
  PK = 'PreK',
  K = 'Kindergarten',
  G1 = '1st Grade',
  G2 = '2nd Grade',
  G3 = '3rd Grade',
  G4 = '4th Grade',
  G5 = '5th Grade',
  G6 = '6th Grade',
  G7 = '7th Grade',
  G8 = '8th Grade',
  G9 = '9th Grade',
  G10 = '10th Grade',
  G11 = '11th Grade',
  G12 = '12th Grade',
  Graduate = 'Adult/Graduate',
  Nursery = 'Nursery',
  YR = 'Year R',
  Y1 = 'Year 1',
  Y2 = 'Year 2',
  Y3 = 'Year 3',
  Y4 = 'Year 4',
  Y5 = 'Year 5',
  Y6 = 'Year 6',
  Y7 = 'Year 7',
  Y8 = 'Year 8',
  Y9 = 'Year 9',
  Y10 = 'Year 10',
  Y11 = 'Year 11',
  Post16 = 'Post 16/Adult',
  Other = 'Other',
  OtherUK = 'Other (UK)'
}

export enum UserRole {
  Student = 0,
  LexiaAdmin = 1,
  LexiaOperations = 2,
  LexiaResearch = 3,
  LexiaSales = 4,
  CustomerAdmin = 5,
  CustomerAccess = 6,
  SchoolAdmin = 7,
  SchoolAccess = 8,
  ClassAccess = 9,
  /**
   * Read Only
   */
  ClassAccessRO = 10,

  /**
   * User role has not been set
   */
  Unknown = -1,

  /**
   * Deep link user to be treated as a student
   */
  DeepLinkStudent = -2,

  /**
   * Deep link user to be treated as an educator
   */
  DeepLinkEducator = -3
}

export interface IAuthDetails extends IAuthDetailsSso {
  name: string;
}

export interface IAuthDetailsSso {
  /**
   * name can be undefined in SSO
   */
  name?: string;
  personId: number;
  role: UserRole;
  token: string;
}
