import memoizeOne from 'memoize-one';
import { Color, Justify } from 'common-styles';

export class YesNoStyles {
  private static readonly styles = {
    innerContainer: {
      backgroundColor: Color.NearWhiteTransparent40,
      borderRadius: 20,
      width: 696
    }
  };

  public static readonly build = memoizeOne(
    (isInstruction: boolean, hasSpotlightArea: boolean) => ({
      ...YesNoStyles.styles,
      container: {
        flex: 1,
        justifyContent:
          isInstruction || !hasSpotlightArea ? Justify.Center : Justify.Start
      }
    })
  );
}
