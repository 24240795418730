import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  CmsService,
  ContentProviderFactory
} from '@lexialearning/lobo-common/cms';
import { StormService } from 'storm';
import { TaskRegistry } from 'task-components';
import { ProgramContextService, StudentProgressApi } from './program-context';

export class CurriculumDependencies {
  public static readonly displayName = 'CurriculumDependencies';

  public readonly programContextService: ProgramContextService;

  /**
   * Provide dependencies needed by the Curriculum service layer.
   *
   * @param cmsService - CMS service.
   * @param configProvider - to get config key for level voiceovers mount location
   * @param contentProviderFactory - Content provider factory that creates the
   * content provider with which to load content (e.g. from CMS or JSON files)
   * @param stormService - Storm service to mount level voiceovers
   * @param recycler - Optional unit recycler to override default UnitRecycler
   */
  public constructor(
    public readonly cmsService: CmsService,
    public readonly configProvider: IConfigProvider,
    public readonly contentProviderFactory: ContentProviderFactory,
    public readonly stormService: StormService,
    public readonly studentProgressApi: StudentProgressApi,
    public readonly taskRegistry: TaskRegistry
  ) {
    this.programContextService = new ProgramContextService(taskRegistry);
  }
}

export interface ICurriculumDependencies {
  curriculumDependencies: CurriculumDependencies;
}
