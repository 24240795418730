import memoizeOne from 'memoize-one';
import { Font, FontFamily } from 'common-styles';

export interface ICopyrightStyleOverride {
  container: {
    height?: number;
    width?: number;
  };
  text: {
    fontFamily?: FontFamily;
    fontSize?: number;
  };
}

export class CopyrightStyles {
  public static readonly build = memoizeOne(
    (styleOverride?: ICopyrightStyleOverride) => ({
      container: {
        height: 60
      },
      text: {
        fontSize: Font.Size.xSmall
      },
      ...styleOverride
    })
  );
}
