import { TaskEvaluationResult } from '@lexialearning/lobo-common/main-model';
import { IPodcast } from '@lexialearning/lobo-common/tasks/podcast';
import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Image, View } from 'common-ui';
import { SubmitButton } from 'task-components/shared';
import { ITaskProps, ModelingSelector } from '../core';
import { AmbienceBar } from './ambience-bar/AmbienceBar';
import { PodcastStyles } from './Podcast.styles';
import { HeadphoneSize, HeadphoneSvg } from './svg/Headphone.lx-svg';
import { PodcastStringSvg } from './svg/PodcastString.lx-svg';

export interface IPodcastProps extends ITaskProps<IPodcast> {
  isHighlighted: boolean;
}

export class PodcastComponent extends React.PureComponent<IPodcastProps> {
  public static readonly displayName = 'Podcast';

  constructor(props: IPodcastProps) {
    super(props);

    this.handlePressSubmit = this.handlePressSubmit.bind(this);
  }

  private handlePressSubmit(): void {
    this.props.onEvaluated(TaskEvaluationResult.Correct, {});
  }

  public componentDidUpdate(prevProps: IPodcastProps) {
    if (!prevProps.isInteractive && this.props.isInteractive) {
      this.props.updateCanSubmit({ canSubmit: true });
    }
  }

  public render() {
    const { taskContent } = this.props;
    const img = taskContent.image;
    const imageSource = img?.imageSource || '';
    const hasImage = imageSource !== '';
    const styles = PodcastStyles.get();

    return (
      <View style={styles.taskContainer}>
        <PodcastStringSvg />
        <View style={styles.container}>
          <Column style={styles.innerContainer}>
            {hasImage ? (
              <Image
                source={imageSource}
                style={styles.imageContainer}
                accessibilityLabel={img?.altText}
              />
            ) : (
              <Column style={styles.imageContainer}>
                <HeadphoneSvg size={HeadphoneSize.Large} />
              </Column>
            )}

            <AmbienceBar
              hasImage={!!imageSource}
              playAnimation={this.props.isHighlighted}
            />
            <SubmitButton onPress={this.handlePressSubmit} />
          </Column>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state: unknown, ownProps: IPodcastProps) {
  const highlightedItemId = ModelingSelector.getHighlightedItemId(state);
  const isHighlighted =
    highlightedItemId === ownProps.taskContent.voiceover?.id;

  return {
    isHighlighted
  };
}

export const Podcast = connect(mapStateToProps)(PodcastComponent);

export const PodcastPrivates = {
  mapStateToProps
};
