import { ActionType, createAction } from 'typesafe-actions';
import { IntroductionSceneActionType } from './introduction-scene-redux.model';

export const IntroductionSceneAction = {
  prepare: createAction(IntroductionSceneActionType.Prepare)()
};

export type IntroductionScenePrepareAction = ActionType<
  typeof IntroductionSceneAction.prepare
>;

export type IntroductionSceneActions = ActionType<
  typeof IntroductionSceneAction
>;
