import {
  IActivityPosition,
  PositionChangeType,
  TaskTypeName
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { PositionDeterminer } from '../../program-context/epics/progress/position-determiners/PositionDeterminer';
import { PlacementFormHelper } from './PlacementForm.helper';

export class PlacementPositionDeterminer extends PositionDeterminer {
  public static readonly displayName = 'PlacementPositionDeterminer';

  protected get maxAttempts(): number {
    return this.context.round.task?.taskType === TaskTypeName.SeeSpeak ? 2 : 1;
  }

  /**
   * Override base logic:
   * - LevelCompletion becomes PlacementCompletion just to be explicit
   * - UnitCompletion may become PlacementCompletion if unit accuracy falls
   *    below the placement form progression rule accuracy
   * - Everything else follows base logic (except that some changeTypes would
   * be unexpected, so we do a sanity check)
   */
  protected determineChangeType(): PositionChangeType {
    const changeType = this.validateChangeType(super.determineChangeType());

    if (changeType === PositionChangeType.LevelCompletion) {
      return PositionChangeType.PlacementCompletion;
    }

    if (changeType === PositionChangeType.UnitCompletion) {
      const rule = PlacementFormHelper.findMatchingRule(this.context);

      if (rule?.level) {
        return PositionChangeType.PlacementCompletion;
      }
    }

    return changeType;
  }

  /**
   * Sanity check for change type. We only expect:
   *  - LevelCompletion: (which is converted to PlacementCompletion)
   *  - None
   *  - RoundCompletion
   *  - UnitCompletion: (which may get converted to PlacementCompletion)
   *
   * Anything else means something has gone wrong:
   *  - ActivityCompletion: should never happen since we should get LevelCompletion instead
   *  - EncounterCompletion: ditto
   *  - Fork, Join, StepDown, StepUp, UnitRecycling: unsupported in placement units
   *  - PlacementCompletion: base determiner should not set this
   *  - anything else was unsupported when this determiner was written
   *
   */
  private validateChangeType(
    changeType: PositionChangeType
  ): PositionChangeType {
    if (
      ![
        PositionChangeType.LevelCompletion,
        PositionChangeType.None,
        PositionChangeType.RoundCompletion,
        PositionChangeType.UnitCompletion
      ].includes(changeType)
    ) {
      const { activityPosition, lastAttemptMaybe } = this.context;
      throw new LexiaError(
        `Unexpected change type ${changeType}`,
        PlacementPositionDeterminer.displayName,
        PlacementPositionDeterminerError.ChangeTypeUnexpected
      ).withContext({ activityPosition, lastAttempt: lastAttemptMaybe });
    }

    return changeType;
  }

  /**
   * Override base logic only for:
   * - RoundCompletion: since we want to updatePlacementProgress
   * - PlacementCompletion: since this is unsupported by the base class and we
   *  want to make sure to retain the round's attempt history as it is used to
   *  determine the "levelUp" position
   *
   * (Placement progress is irrelevant for other change types:
   * - None: We don't know yet if the round will be pass or fail
   * - PlacementCompletion: the rule eval is done again to determine the level
   *  to place the student, but it uses the same position + last attempt, rather
   *  then the imminentPosition.
   * - UnitCompletion: the position returned is for the next unit for which we
   *    want to have no placement progress yet
   */
  protected buildActivityPosition(
    changeType: PositionChangeType
  ): IActivityPosition {
    if (changeType === PositionChangeType.RoundCompletion) {
      const { lastAttemptMaybe, parentUnit } = this.context;
      this.activityPositionBuilder.updatePlacementProgress(
        lastAttemptMaybe,
        parentUnit
      );
    }

    return changeType === PositionChangeType.PlacementCompletion
      ? this.toCompletedActivity()
      : super.buildActivityPosition(changeType);
  }
}

export enum PlacementPositionDeterminerError {
  ChangeTypeUnexpected = 'ChangeTypeUnexpected'
}
