import { ModalRegistry, ModalType } from 'shared-components/modals';
import no_micPng from './no_mic.png';

export class NoMicModal {
  // istanbul ignore next - trivial
  public static readonly ModalId = ModalRegistry.register({
    Component: () => null,
    headline: 'Microphone not found.',
    id: 'NoMicModal',
    image: no_micPng,
    text: 'Please attach a microphone to continue.',
    title: 'Oops!',
    type: ModalType.Error
  });
}
