import { CharacterSceneBase } from 'feature-areas/character-scene';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { Scene } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  EncounterSceneAnimationName,
  EncounterSceneElementName
} from './encounter-scene.model';

export class EncounterScene extends CharacterSceneBase {
  public static readonly displayName = 'EncounterScene';

  constructor(scene: Scene) {
    super(scene, SceneZIndex.Encounter);
    this.init();
  }

  private init() {
    const request: ISceneAnimationRequest = {
      name: EncounterSceneAnimationName.Background.Intro,
      speed: 0,
      targetElement: EncounterSceneElementName.Background,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }
}
