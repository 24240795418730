import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { LexiaError } from '@lexialearning/utils';
import { Action } from 'redux';
import { ILoggingConfig } from 'logging/logging-config.model';
import { TaskRegistry } from 'task-components';
import { Version } from '../app-config';
import { DeployConfig } from '../app-config/DeployConfig';
import { LogContextFactory } from './epics/helpers';
import { EventFactory } from './epics/logging-epic.model';
import { AppState } from 'services';
import { CargoService } from '@lexialearning/utils-react';
import { LOGGER_CONFIG_KEY, LoboLogItemCategory } from 'logging';
import { LocalStorageService } from 'services/local-storage';
import { ProdDebugFlagService } from './prod-debug-flag';
import { LogRocketInitializer } from './log-rocket';

export class AppLoggerDependencies {
  public static readonly displayName = 'AppLoggerDependencies';

  public readonly logger: CargoService;

  public readonly contextFactory: LogContextFactory;

  public constructor(
    private readonly configProvider: IConfigProvider,
    private readonly localStorageService: LocalStorageService,
    public readonly taskRegistry: TaskRegistry
  ) {
    this.contextFactory = new LogContextFactory(taskRegistry);
    this.logger = new CargoService(
      () => configProvider.getConfig<ILoggingConfig>(LOGGER_CONFIG_KEY),
      DeployConfig.deployEnvironment,
      `${Version.Main}+${Version.Build}`
    );

    /* eslint-disable no-console */
    console.log('Logger app info', this.logger.appInfo);
    console.log('Instance ID', this.logger.appInfo.instanceId);
    /* eslint-enable no-console */

    this.maybeEnableProdDebugTelemetry();
  }

  private async maybeEnableProdDebugTelemetry(): Promise<void> {
    const debugService = new ProdDebugFlagService(
      this.localStorageService,
      this.logger
    );
    const shouldEnableDebug = await debugService.shouldEnableDebug();

    if (!shouldEnableDebug) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Enabling enhanced telemetry...');

    const config =
      this.configProvider.getConfig<ILoggingConfig>(LOGGER_CONFIG_KEY);
    await LogRocketInitializer.init(config.logRocket, this.logger, {
      enabledForDebug: true
    });
  }

  public setLevelInfo(levelTitle: string) {
    this.logger.userInfo.level = levelTitle;
  }

  public getContext(state: unknown): string {
    return this.contextFactory.create(state);
  }

  /**
   * Log the event returned by the eventFactory function. Handle any thrown
   * error by logging it.
   */
  public async logSafely<TAction extends Action, TPayload extends object>(
    action: TAction,
    state: AppState,
    eventFactory: EventFactory<TAction, TPayload>,
    category: LoboLogItemCategory
  ): Promise<void> {
    try {
      const event = await eventFactory(action, state, this);
      void this.logger.log(event);
    } catch (err) {
      void this.logger.logError(
        new LexiaError(
          `Error attempting to log event with category "${category}"`,
          AppLoggerDependencies.displayName,
          AppLoggerDependenciesError.LoggingError
        ).withCause(err)
      );
    }
  }
}

export enum AppLoggerDependenciesError {
  LoggingError = 'LoggingError'
}
