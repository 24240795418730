import { AppShellAction } from 'feature-areas/shell';
import { IContentLoadedOptions } from 'curriculum-services/program-context/ProgramContextService';
import { IScreenplay } from '@lexialearning/lobo-common';
import { InteractionState } from 'common-styles';
import { MicAction } from 'common-ui';
import { PreparedScenes } from 'services/storm-lobo';
import { ProgramContextService } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';
import { StateObservable } from 'redux-observable';

export interface ITransitionScreenplayBaseDeps {
  preparedScenes: PreparedScenes;
}

/**
 * Provides commonly-used methods for transition screenplays
 */
export class TransitionScreenplayBuilderBase {
  public static readonly displayName: string =
    'TransitionScreenplayBuilderBase';

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  protected readonly builder: ScreenplayBuilder;

  protected constructor(screenplayId: string) {
    this.builder = ScreenplayBuilder.create(screenplayId);
  }

  public awaitContentLoaded(
    programContextService: ProgramContextService,
    state$: StateObservable<unknown>,
    options?: IContentLoadedOptions
  ) {
    this.builder.addCallback(async () =>
      programContextService.contentLoaded(state$, options)
    );

    return this;
  }

  public disableUtilityBar() {
    this.builder.addReduxAction(AppShellAction.disableUtilityBar());

    return this;
  }

  public enableMic() {
    this.builder.addReduxAction(
      MicAction.setInteractionState(InteractionState.Default)
    );

    return this;
  }

  public enableUtilityBar() {
    this.builder.addReduxAction(AppShellAction.enableUtilityBar());

    return this;
  }
}
