import { ActiveComponentState } from '@lexialearning/common-ui';
import {
  Circle,
  G,
  ITextButtonIconProps,
  KeyNavHelper,
  Path,
  Svg
} from 'common-ui';

export interface IExclamationMarkSvgProps extends ITextButtonIconProps {
  activeComponentState: ActiveComponentState;
}

export function ExclamationMarkSvg({
  activeComponentState,
  color
}: IExclamationMarkSvgProps) {
  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
  const translateX = isKeyNav ? '15' : '16.097';

  return (
    <Svg
      data-test-id={ExclamationMarkSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="3 0 39 39"
    >
      <G>
        <Circle
          cx="3.807"
          cy="3.807"
          r="3.807"
          transform={`translate(${translateX} 26.41)`}
          fill={color}
        />
        <Path
          d="M3.807,0A3.775,3.775,0,0,1,7.613,3.742l-.952,13.1c0,2.067-.753,3.742-2.855,3.742S.952,18.9.952,16.838L0,3.742A3.775,3.775,0,0,1,3.807,0Z"
          transform={`translate(${translateX} 3.571)`}
          fill={color}
        />
      </G>
    </Svg>
  );
}
ExclamationMarkSvg.displayName = 'ExclamationMarkSvg';
