import { cancelMusicScreenplayEpic } from './cancelMusicScreenplay.epic';
import { cancelScreenplayEpic } from './cancelScreenplay.epic';
import { pauseScreenplayEpic } from './pauseScreenplay.epic';
import { playCompleteEpic } from './playComplete.epic';
import { playScreenplayEpic } from './playScreenplay.epic';
import { resumeScreenplayEpic } from './resumeScreenplay.epic';
import { skipScreenplayEpic } from './skipScreenplay.epic';

export const screenplayEpics = [
  cancelMusicScreenplayEpic,
  cancelScreenplayEpic,
  pauseScreenplayEpic,
  playCompleteEpic,
  playScreenplayEpic,
  resumeScreenplayEpic,
  skipScreenplayEpic
];
