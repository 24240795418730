import {
  CalibrationReactAnimationName,
  CalibrationSceneAnimationName
} from 'feature-areas/calibration';
import { SceneName } from 'services/storm-lobo';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { CalibrationScreenplayId } from './calibration-screenplay-builders.model';

export class CalibrationOutroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'CalibrationOutroScreenplayBuilder';

  public static create(): CalibrationOutroScreenplayBuilder {
    return new CalibrationOutroScreenplayBuilder();
  }

  private constructor() {
    super(CalibrationScreenplayId.Outro);
  }

  public fadeOutReactElements(): CalibrationOutroScreenplayBuilder {
    this.builder.addReactAnimation(CalibrationReactAnimationName.FadeOut, {
      concurrent: true
    });

    return this;
  }

  public playSceneOutro(): CalibrationOutroScreenplayBuilder {
    this.builder.addStormAnimation({
      name: CalibrationSceneAnimationName.Root.Outro,
      targetScene: SceneName.Calibration
    });

    return this;
  }
}
