import { createSelector } from '@reduxjs/toolkit';
import { StormStatus } from '../service';
import { IStormState } from './storm-redux.model';

export class StormSelector {
  public static readonly displayName = 'StormSelector';

  public static getState: (state: unknown) => IStormState;

  public static getStatus: (state: unknown) => StormStatus;

  public static isInitialized: (state: unknown) => boolean;

  public static createSelectors(selector: (state: any) => IStormState): void {
    StormSelector.getState = createSelector(
      selector,
      (state: IStormState) => state
    );
    StormSelector.getStatus = createSelector(
      StormSelector.getState,
      (storm: IStormState) => storm.status
    );
    StormSelector.isInitialized = createSelector(
      StormSelector.getStatus,
      status => status === StormStatus.Initialized
    );
  }
}
