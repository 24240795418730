import { WebView, WebViewSandboxMode } from '@lexialearning/common-ui';
import { AccountSettingsTabId } from '../account-settings-tab-list/AccountSettingsTabList';
import { LegalInfoWebViewStyles } from './LegalInfoWebView.styles';

export interface ILegalInfoWebViewProps {
  tabId: AccountSettingsTabId;
}

export const LICENSE_URL =
  'https://legal.lexialearning.com/legal/k-12eala.html';
export const PRIVACY_URL =
  'https://legal.lexialearning.com/legal/student-records-privacy.html';

export function LegalInfoWebView(
  props: ILegalInfoWebViewProps
): React.ReactElement {
  const { tabId } = props;
  const styles = LegalInfoWebViewStyles.get();
  const url =
    tabId === AccountSettingsTabId.License ? LICENSE_URL : PRIVACY_URL;

  return (
    <WebView
      testId={LegalInfoWebView.displayName}
      url={url}
      style={styles}
      sandbox={WebViewSandboxMode.AllowPopups}
    />
  );
}
LegalInfoWebView.displayName = 'LegalInfoWebView';
