import { IAct } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { ActHelper } from '../service-helpers/Act.helper';
import { ProgramContextSelector } from './ProgramContext.selector';

export class ActSelector {
  public static readonly displayName = 'ActSelector';

  public static getAct(state: unknown): IAct {
    const act = this.getActMaybe(state);

    if (!act) {
      throw new LexiaError(
        'No active act',
        ActSelector.displayName,
        ActSelectorError.NoAct
      );
    }

    return act;
  }

  public static getActMaybe(state: unknown): IAct | undefined {
    return ProgramContextSelector.getEncounterContextMaybe(state)?.act;
  }

  public static getDescriptiveTitle(state: unknown): string {
    const act = this.getActMaybe(state);

    return act ? ActHelper.getDescriptiveTitle(act) : '';
  }
}

export enum ActSelectorError {
  NoAct = 'NoAct'
}
