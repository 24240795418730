import { isEqual } from 'lodash';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { PositionTransitionAnimatedStyles } from './PositionTransition.animated-styles';
import { AnimatedView } from '../view';
import { IAnimatedViewProps } from '../view/AnimatedView';

export interface IPositionTransitionProps extends IAnimatedViewProps {
  offset?: XYCoord;
  onAfterTransition?: () => void;
  position?: XYCoord;
  toPosition?: XYCoord;
  transitionDuration?: number;
}

export interface IPositionTransitionState {
  currentPosition: XYCoord | undefined;
}

export class PositionTransition extends React.PureComponent<
  IPositionTransitionProps,
  IPositionTransitionState
> {
  public static readonly displayName = 'PositionTransition';

  private readonly positionTransitionAnimatedStyles: PositionTransitionAnimatedStyles;

  constructor(props: IPositionTransitionProps) {
    super(props);

    this.positionTransitionAnimatedStyles =
      new PositionTransitionAnimatedStyles();

    this.state = {
      currentPosition: undefined
    };
  }

  public componentDidUpdate(prevProps: IPositionTransitionProps) {
    const { position, toPosition } = this.props;
    const { position: prevPosition, toPosition: prevToPosition } = prevProps;

    if ((!position && !toPosition) || isEqual(position, toPosition)) {
      return;
    }

    if (prevPosition !== position) {
      this.setState({ currentPosition: position });
    }

    if (!!toPosition && !isEqual(prevToPosition, toPosition)) {
      this.startTransition(position!, toPosition!);
    }
  }

  private startTransition(startPosition: XYCoord, endPosition: XYCoord) {
    const { transitionAnimation } =
      this.positionTransitionAnimatedStyles.buildAnimations(
        startPosition,
        endPosition,
        this.props.transitionDuration
      );

    transitionAnimation.start(() => {
      this.positionTransitionAnimatedStyles.updateAnimatedValues(0, 0);
      this.setState({
        currentPosition: endPosition
      });
      this.props.onAfterTransition?.();
    });
  }

  public render() {
    const {
      offset,
      onAfterTransition,
      position,
      toPosition,
      transitionDuration,
      ...restProps
    } = this.props;
    const styles = this.positionTransitionAnimatedStyles.build(
      this.state.currentPosition,
      this.props.offset
    );

    return (
      <AnimatedView
        {...restProps}
        style={styles.absolutePositionedWrapper}
        animatedStyle={styles.absolutePositionedWrapperAnimated}
        importantForLayout
      />
    );
  }
}
