import { createSelector } from '@reduxjs/toolkit';
import {
  AvatarTimerStatus,
  IAvatarEditorState,
  IAvatarEditorUserEvent
} from './avatar-editor-redux.model';
import { AppState } from 'services';
import { AvatarEditorEvent } from '@lexialearning/lobo-common/main-model/logging';

export class AvatarEditorSelector {
  public static readonly displayName = 'AvatarEditorSelector';

  public static getState: (state: AppState) => IAvatarEditorState;

  public static getEncountersUntilEditable: (state: AppState) => number;

  public static getCanEditAvatar: (state: AppState) => boolean;

  public static getShouldShowEditor: (state: AppState) => boolean;

  public static getTimerDuration: (state: AppState) => number;

  public static getExtensionsRemaining: (state: AppState) => number;

  public static getTimerStatus: (state: AppState) => AvatarTimerStatus;

  public static getUserEvents: (state: AppState) => IAvatarEditorUserEvent[];

  public static getHasAvatarChanged: (state: AppState) => boolean;

  public static createSelectors(
    selector: (state: AppState) => IAvatarEditorState
  ) {
    AvatarEditorSelector.getState = createSelector(
      selector,
      (state: IAvatarEditorState) => state
    );

    AvatarEditorSelector.getEncountersUntilEditable = createSelector(
      selector,
      (state: IAvatarEditorState) => state.encountersUntilEditable
    );

    AvatarEditorSelector.getTimerDuration = createSelector(
      selector,
      (state: IAvatarEditorState) => state.timer.duration
    );

    AvatarEditorSelector.getExtensionsRemaining = createSelector(
      selector,
      (state: IAvatarEditorState) => state.timer.extensionsRemaining
    );

    AvatarEditorSelector.getTimerStatus = createSelector(
      selector,
      (state: IAvatarEditorState) => state.timer.status
    );

    AvatarEditorSelector.getCanEditAvatar = createSelector(
      selector,
      (state: IAvatarEditorState) => state.encountersUntilEditable === 0
    );

    AvatarEditorSelector.getShouldShowEditor = createSelector(
      selector,
      (state: IAvatarEditorState) => state.shouldShowEditor
    );

    AvatarEditorSelector.getUserEvents = createSelector(
      selector,
      (state: IAvatarEditorState) => state.userEvents
    );

    AvatarEditorSelector.getHasAvatarChanged = createSelector(
      selector,
      ({ userEvents }: IAvatarEditorState) =>
        userEvents.some(
          userEvent =>
            userEvent.eventName === AvatarEditorEvent.AvatarSelectionUpdated
        )
    );
  }
}
