import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthActionAutoLogin, AuthActionType } from '../redux';
import { LoginActionFactory, LoginReturnActions } from './login-action-factory';

export interface IAutoLoginDeps {
  configProvider: IConfigProvider;
}

export function autoLoginEpic(
  action$: Observable<AuthActionAutoLogin>,
  _state$: StateObservable<unknown>,
  deps: IAutoLoginDeps
): Observable<LoginReturnActions> {
  return action$.pipe(
    ofType(AuthActionType.AutoLogin),
    mergeMap(async () => LoginActionFactory.create(deps.configProvider)),
    mergeMap(action => action)
  );
}
autoLoginEpic.displayName = 'autoLoginEpic';
