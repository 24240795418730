import { IScreenplay, IScreenplayAction } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { flatten } from 'lodash';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  ReplaySubject
} from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
import { NoOpActionPlayer } from './NoOpActionPlayer';
import {
  IAnimationRequest,
  ILoopedActionRequest,
  IScreenplayActionPlayer,
  IScreenplayEvent,
  ScreenplayEvent
} from './screenplayer.model';
import { ILogger } from '@lexialearning/main-model';

/**
 * Handles playback of a screenplay.
 * Helper to Screenplayer
 *
 * @see Screenplayer
 */
export class Playback {
  public static readonly displayName = 'Playback';

  private readonly eventSubject = new ReplaySubject<IScreenplayEvent>();

  private eventStack: IScreenplayEvent[];

  /**
   * The currently playing synchronous event
   */
  private activeEvent?: IScreenplayEvent;

  /**
   * Emits an array of the currently playing asynchronous (concurrent) events
   */
  private readonly concurrentEventsSubject = new BehaviorSubject<
    Map<number, IScreenplayEvent>
  >(new Map<number, IScreenplayEvent>());

  private readonly isPausedSubject = new BehaviorSubject<boolean>(false);

  /**
   * Holds a request to cancel/skip
   * @see cancel()
   * @see skip()
   */
  private interrupt?: ScreenplayEvent;

  public constructor(
    public readonly screenplay: IScreenplay,
    public readonly actionPlayerMap: Map<string, IScreenplayActionPlayer>,
    private readonly logger: ILogger,
    private readonly onComplete: () => void
  ) {
    this.eventStack = this.buildEventStack();

    this.actionPlayerMap.set(NoOpActionPlayer.Type, new NoOpActionPlayer());
  }

  /**
   * Create the events to emit during playback.
   * Events are popped of the stack (by this.emitNext) and then processed.
   */
  private buildEventStack(): IScreenplayEvent[] {
    const stack = flatten<IScreenplayEvent>(
      this.screenplay.actions.map((action, actionIndex) =>
        [ScreenplayEvent.BeforeAction, ScreenplayEvent.AfterAction].map(
          type => ({
            action,
            actionIndex,
            totalActions: this.screenplay.actions.length,
            type
          })
        )
      )
    );
    stack.push({
      totalActions: this.screenplay.actions.length,
      type: ScreenplayEvent.Completed
    });

    stack.reverse();

    return stack;
  }

  /**
   * Flag an interrupt and pause all active action players
   */
  public pause(): void {
    this.isPausedSubject.next(true);
    // Pause active synchronous event
    if (this.activeEvent?.action) {
      const player = this.getPlayer();
      player.pause(this.activeEvent.action);
    }
    // Pause any active concurrent events
    this.concurrentEventsSubject.value.forEach(e => {
      const player = this.getPlayer(e.action);
      player.pause(e.action);
    });
  }

  /**
   * Reset interrupt and resume all active action players
   */
  public resume(): void {
    this.isPausedSubject.next(false);
    // Pause active synchronous event
    if (this.activeEvent?.action) {
      this.getPlayer().resume(this.activeEvent.action);
    }
    // Pause any active concurrent events
    this.concurrentEventsSubject.value.forEach(e => {
      const player = this.getPlayer(e.action);
      player.resume(e.action);
    });
  }

  /**
   * Flag an interrupt and cancel all active action players
   * The eventStack is altered within emitNext
   */
  public cancel(): void {
    this.isPausedSubject.next(false);
    this.cancelAll(ScreenplayEvent.Canceled);
  }

  /**
   * Flag an interrupt and cancel all active action players
   * The eventStack is altered within emitNext
   */
  public skip(): void {
    this.isPausedSubject.next(false);
    this.cancelAll(ScreenplayEvent.Skipped);
  }

  private cancelAll(screenplayEvent: ScreenplayEvent): void {
    this.interrupt = screenplayEvent;
    // Cancel current synchronous event
    if (this.activeEvent?.action) {
      this.getPlayer().cancel(this.activeEvent?.action);
    }
    // Cancel any current concurrent events
    this.concurrentEventsSubject.value.forEach(e => {
      const player = this.getPlayer(e.action);
      player.cancel(e.action);
    });
    this.concurrentEventsSubject.next(new Map<number, IScreenplayEvent>());
  }

  public start(): Observable<IScreenplayEvent> {
    const event$ = this.eventSubject.pipe(
      concatMap(async event => {
        // Await screenplay resume if was paused
        await lastValueFrom(
          this.isPausedSubject.pipe(first(isPaused => !isPaused))
        );
        this.activeEvent = event;

        if (!this.eventStack.length) {
          // wait for all concurrent events to complete before emitting final action
          await lastValueFrom(
            this.concurrentEventsSubject.pipe(
              first(events => events.size === 0)
            )
          );

          // notify Screenplayer right before emitting last event
          this.onComplete();
        }

        return event.type === ScreenplayEvent.AfterAction
          ? this.playAction()
          : event;
      }),
      tap(() => {
        this.emitNext();
      })
    );

    this.emitNext();

    return event$;
  }

  private emitNext(): void {
    if (!this.eventStack.length) {
      this.eventSubject.complete();

      return;
    }

    if (
      this.interrupt &&
      [ScreenplayEvent.Canceled, ScreenplayEvent.Skipped].includes(
        this.interrupt
      )
    ) {
      this.eventStack = this.truncateEventStack();
    }

    const event = this.eventStack.pop();

    event && this.eventSubject.next(event);
  }

  private truncateEventStack(): IScreenplayEvent[] {
    const type = this.interrupt!;
    this.interrupt = undefined;

    return [
      {
        ...this.activeEvent!,
        type
      }
    ];
  }

  private async playConcurrentWithErrorHandling(event: IScreenplayEvent) {
    const idx = event.actionIndex || 0;
    this.updateActiveConcurrentEvents(idx, event);

    await this.playWithErrorHandling(event);

    this.updateActiveConcurrentEvents(idx);
  }

  private updateActiveConcurrentEvents(idx: number, value?: IScreenplayEvent) {
    const activeConcurrentEvents = new Map(this.concurrentEventsSubject.value);
    if (value) {
      activeConcurrentEvents.set(idx, value);
    } else {
      activeConcurrentEvents.delete(idx);
    }
    this.concurrentEventsSubject.next(activeConcurrentEvents);
  }

  private async playWithErrorHandling(event: IScreenplayEvent) {
    const player = this.getPlayer(event.action);

    try {
      // console.error(
      //   'Screenplay:',
      //   this.screenplay.id,
      //   event.action?.type,
      //   event.actionIndex,
      //   'of',
      //   this.screenplay.actions.length
      // );

      await player.play(event.action!);

      return;
    } catch (err) {
      void this.logger.logError(
        new LexiaError(
          `Error playing action ${event.actionIndex} of type ${event.action?.type} in screenplay ${this.screenplay?.id}: ${err.message}`,
          Playback.displayName,
          PlaybackErrorCode.ActionPlayerError
        )
          .withCause(err)
          .withContext({
            actionIndex: event.actionIndex,
            actionType: event.action?.type,
            screenplay: this.screenplay,
            screenplayId: this.screenplay.id
          })
      );
    }
  }

  private async playAction(): Promise<IScreenplayEvent> {
    const event = this.activeEvent!;
    const isLooped = (event.action?.data as ILoopedActionRequest).loop;
    const isZeroSpeedAction =
      (event.action?.data as IAnimationRequest).speed === 0;
    const isConcurrent = event.action?.concurrent;

    if (isLooped || isZeroSpeedAction) {
      void this.playWithErrorHandling(event);
    } else if (isConcurrent) {
      void this.playConcurrentWithErrorHandling(event);
    } else {
      await this.playWithErrorHandling(event);
    }

    this.activeEvent = undefined;

    return event;
  }

  private getPlayer(maybeAction?: IScreenplayAction): IScreenplayActionPlayer {
    const action = maybeAction ?? this.activeEvent?.action!;

    return this.actionPlayerMap.get(action.type) ?? this.getNoOpPlayer(action);
  }

  private getNoOpPlayer(action: IScreenplayAction): IScreenplayActionPlayer {
    const { actionIndex } = this.activeEvent!;
    void this.logger.logError(
      new LexiaError(
        `No action player found for action type ${action?.type} on action index ${actionIndex} of screenplay ${this.screenplay?.id}`,
        Playback.displayName,
        PlaybackErrorCode.MissingActionPlayer
      ).withContext({
        actionIndex,
        actionType: action?.type,
        screenplay: this.screenplay,
        screenplayId: this.screenplay.id
      })
    );

    const noOpPlayer = this.actionPlayerMap.get(NoOpActionPlayer.Type);

    if (!noOpPlayer) {
      throw new LexiaError(
        'NoOp player not found!',
        Playback.displayName,
        PlaybackErrorCode.MissingNoOpPlayer
      );
    }

    return noOpPlayer;
  }
}

export enum PlaybackErrorCode {
  MissingActionPlayer = 'MissingActionPlayer',
  MissingNoOpPlayer = 'MissingNoOpPlayer',
  ActionPlayerError = 'ActionPlayerError'
}
