import { Text } from 'common-ui';
import { CalibrationIntroStyles } from './CalibrationIntro.styles';

export function CalibrationIntro() {
  const styles = CalibrationIntroStyles.get();

  return (
    <Text testId={CalibrationIntro.displayName} style={styles.introText}>
      Warm Up
    </Text>
  );
}
CalibrationIntro.displayName = 'CalibrationIntro';
