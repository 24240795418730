import { IRound } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { createSelector } from '@reduxjs/toolkit';
import { RoundContext } from '../RoundContext';

export class RoundSelector {
  public static readonly displayName = 'RoundSelector';

  /**
   * Returns the round from the round context.
   * The round context must be constructable. We rely on the round from the
   * round context as it has the prepared task content and is therefore safer
   * (and more correct) than getting the round directly from the unit content.
   */
  public static getRound: (state: unknown) => IRound;

  public static getRoundMaybe: (state: unknown) => IRound | undefined;

  public static createSelectors(
    getRoundContextMaybe: (state: unknown) => RoundContext | undefined
  ) {
    this.getRoundMaybe = createSelector(
      getRoundContextMaybe,
      (context: RoundContext | undefined) => context?.round
    );

    this.getRound = createSelector(this.getRoundMaybe, (round?: IRound) => {
      if (!round) {
        throw new LexiaError(
          'No active round',
          RoundSelector.displayName,
          RoundSelectorError.RoundMissing
        );
      }

      return round;
    });
  }
}

export enum RoundSelectorError {
  RoundMissing = 'RoundMissing'
}
