import { SreSessionType } from '@lexialearning/lobo-common/main-model/sre';
import { MicEnergyService } from './MicEnergyService';
import { useState } from 'react';
import { useDidMount } from 'common-ui';

export interface IUseMicEnergyOptions {
  /**
   * Mic energy will be ignored unless the current session is of the given type
   */
  subscribeToSessionType?: SreSessionType;
}

export function useMicEnergy(options: IUseMicEnergyOptions): {
  micEnergy: number;
} {
  const [micEnergy, setMicEnergy] = useState(0);

  useDidMount(() => {
    const { subscribeToSessionType } = options;
    const subscription = MicEnergyService.subscribeTo(
      subscribeToSessionType,
      micEnergy => setMicEnergy(micEnergy)
    );

    return () => {
      subscription.unsubscribe();
    };
  });

  return { micEnergy };
}
useMicEnergy.displayName = 'useMicEnergy';
