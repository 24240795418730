import { GradeName } from 'lexia-service';

export class ExcellingLevelDeterminer {
  public static readonly displayName = 'ExcellingLevelDeterminer';

  /**
   * Determine the excelling level number based on grade
   * K - 2 the excelling level is 13
   * 3 - 12 is not applicable, so return undefined
   */
  public static determine(gradeName: GradeName): number | undefined {
    return this.determineFinalLevel(gradeName, 1) === 12 ? 13 : undefined;
  }

  /**
   * Determine the final/last level of the program based on the student's grade.
   * Lower grades end at level 12 (unless they are "excelling", meaning they
   * already are above level 12).
   */
  public static determineFinalLevel(
    gradeName: GradeName,
    currentLevel: number
  ): number {
    return currentLevel <= 12 &&
      [GradeName.PK, GradeName.K, GradeName.G1, GradeName.G2].includes(
        gradeName
      )
      ? 12
      : 19;
  }

  /**
   * Returns true/false for grades which have an excel level
   * and undefined if no excel level applies for the given grade
   */
  public static isExcellingLevel(
    gradeName: GradeName,
    levelNum: number
  ): boolean | undefined {
    const excellingLevelNum = this.determine(gradeName);

    if (excellingLevelNum === undefined) {
      return undefined;
    }

    return (
      levelNum >= excellingLevelNum &&
      // 42 is the level number given to the placement/onboarding "level"
      // (student can't be excelling if they aren't yet placed)
      levelNum !== 42
    );
  }
}
