import * as React from 'react';
import { compose } from 'redux';
import { TextPill } from 'common-ui/components/text-pill/TextPill';
import { DndDragSource, DragItemType } from 'dnd';
import { withTapAndHear } from 'task-components/shared';
import { ICompletionChoiceProps } from '../completion-component.model';
import { CompletionChoiceStyles } from './CompletionChoice.styles';
import { ImportantForAccessibility } from '@lexialearning/common-ui';

export class CompletionChoiceComponent extends React.PureComponent<ICompletionChoiceProps> {
  public static readonly displayName: 'CompletionChoice';

  public render() {
    const {
      styleOverride,
      pillWidth,
      children,
      onLayout,
      shouldBeHidden,
      hasShadow = true,
      isDraggingOrDropping = false
    } = this.props;
    const styles = CompletionChoiceStyles.build({
      pillWidth,
      shouldBeHidden: shouldBeHidden || isDraggingOrDropping,
      styleOverride
    });

    return (
      <TextPill
        styleOverride={styles.textPill}
        hasShadow={hasShadow}
        onLayout={onLayout}
        blockPointerEvents={shouldBeHidden}
        importantForAccessibility={
          shouldBeHidden
            ? ImportantForAccessibility.NoHideDescendants
            : undefined
        }
      >
        {children}
      </TextPill>
    );
  }
}

export const CompletionChoice = compose(
  withTapAndHear,
  DndDragSource(DragItemType.TextPill)
)(CompletionChoiceComponent);
