import * as React from 'react';
import { View } from '../../view';
import {
  AccessibilityRole,
  ImportantForAccessibility,
  LimitFocusType
} from '@lexialearning/common-ui';
import { useEffect, useRef } from 'react';

export interface ISlideProps {
  slideContent: React.ReactElement;
  idx: number;
  /**
   * True only if this slide is the active (current) slide, and is in position,
   * ie, not in-process of sliding into or out of place
   */
  isCurrentSlideInPosition: boolean;
}

export function Slide(props: ISlideProps): React.ReactElement {
  const { idx, isCurrentSlideInPosition, slideContent } = props;

  const slideRef = useRef<any>();
  useEffect(() => {
    slideRef.current?.setFocusLimited(!isCurrentSlideInPosition);
  }, [isCurrentSlideInPosition]);

  return (
    <View
      accessibilityRole={AccessibilityRole.Group}
      accessibilityLabel={`Slide ${idx + 1}`}
      testId={`${Slide.displayName}_${idx}`}
      forwardedRef={slideRef}
      blockPointerEvents={!isCurrentSlideInPosition}
      importantForAccessibility={
        isCurrentSlideInPosition
          ? undefined
          : ImportantForAccessibility.NoHideDescendants
      }
      limitFocusWithin={LimitFocusType.Limited}
    >
      {slideContent}
    </View>
  );
}
Slide.displayName = 'Slide';
