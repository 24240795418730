import { AnimatedView, Types } from 'common-ui';
import { useTheme } from 'theme/useTheme';
import { CharacterSpeechBubbleTail } from '../shared/speech-bubble/tail/CharacterSpeechBubbleTail';
import { QuestionSpeechBubbleStyles } from './QuestionSpeechBubble.styles';
import { SeeSpeakTextPrompt } from 'task-components/see-speak/text-prompt/SeeSpeakTextPrompt';
import { STANDARD_SPEECH_BUBBLE_CONTENTS_WIDTH } from '../speech-bubbles.model';
import { ITextPrompt } from '@lexialearning/lobo-common';

export interface IQuestionSpeechBubbleAnimatedStyles {
  characterSpeechTail: Types.AnimatedViewStyle;
  container: Types.AnimatedViewStyle & { borderColor?: Types.AnimatedValue };
  containerVerticalPadding: Types.AnimatedViewStyle;
  text: Types.AnimatedViewStyle;
}

export interface IQuestionSpeechBubbleProps {
  animatedStyles?: IQuestionSpeechBubbleAnimatedStyles;
  hasTailLeft?: boolean;
  textPromptArray: ITextPrompt[];
}

export function QuestionSpeechBubble(props: IQuestionSpeechBubbleProps) {
  const { animatedStyles, hasTailLeft, textPromptArray } = props;
  const { themeSize } = useTheme();
  const styles = QuestionSpeechBubbleStyles.build(themeSize);

  return (
    <AnimatedView
      testId={QuestionSpeechBubble.displayName}
      animatedStyle={animatedStyles?.container}
      style={styles.container}
    >
      <AnimatedView animatedStyle={animatedStyles?.containerVerticalPadding} />

      {hasTailLeft && (
        <CharacterSpeechBubbleTail
          animatedStyle={animatedStyles?.characterSpeechTail}
        />
      )}

      <AnimatedView animatedStyle={animatedStyles?.text} style={styles.text}>
        <SeeSpeakTextPrompt
          textPromptArray={textPromptArray}
          width={STANDARD_SPEECH_BUBBLE_CONTENTS_WIDTH}
        />
      </AnimatedView>

      <AnimatedView animatedStyle={animatedStyles?.containerVerticalPadding} />
    </AnimatedView>
  );
}
QuestionSpeechBubble.displayName = 'QuestionSpeechBubble';
