import { Card } from 'common-ui';
import { ChoiceContainer } from '../../../shared';
import { IChoiceProps } from '../../../shared/choice.model';
import { ImageChoiceStyles } from './ImageChoice.styles';
import { useChoiceState } from 'task-components/shared/useChoiceState';
import { useTheme } from 'theme';

export interface IImageChoiceProps extends IChoiceProps {
  accessibilityLabel: string | undefined;
  imageSource: string | undefined;
  choicesCount: number;
}

export function ImageChoice(props: IImageChoiceProps) {
  const { sysId, isCorrectChoice, imageSource, text, accessibilityLabel } =
    props;

  const { interactionState } = useChoiceState(props);
  const { themeType } = useTheme();

  const styles = ImageChoiceStyles.build(interactionState, themeType);

  return (
    <ChoiceContainer
      sysId={sysId}
      isCorrectChoice={isCorrectChoice}
      styleOverride={styles.choiceContainerStyleOverride}
    >
      <Card
        imageSource={imageSource}
        text={text}
        interactionState={interactionState}
        styleOverride={styles.cardStyleOverride}
        accessibilityLabel={accessibilityLabel}
      />
    </ChoiceContainer>
  );
}
ImageChoice.displayName = 'ImageChoice';
