import memoizeOne from 'memoize-one';
import { Align, Justify, loboViewStyle } from 'common-styles';
import { Overflow } from '@lexialearning/common-ui';

export class ViewStyles {
  public static readonly build = memoizeOne((style?: any) =>
    loboViewStyle({
      alignItems: Align.Center,
      justifyContent: Justify.Center,
      overflow: Overflow.Visible,
      ...style
    })
  );
}
