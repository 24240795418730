import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import { ProfileSelector } from 'services/profile';
import { IRoundToNextTransitionDeps } from '../round-transition.model';
import { RoundToEncounterCompleteTransitionBuilder } from './RoundToEncounterCompleteTransitionBuilder';

export class RoundToEncounterCompleteTransitionFactory {
  public static readonly displayName = 'RoundToEncounterTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): IScreenplay {
    return new RoundToEncounterCompleteTransitionFactory(state$, deps)
      .screenplay;
  }

  private readonly screenplay: IScreenplay;

  private constructor(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ) {
    // Add full completion transition for students
    const isStudent = ProfileSelector.isStudent(state$.value);
    if (isStudent) {
      this.screenplay = RoundToEncounterCompleteTransitionBuilder.createFor(
        state$,
        deps
      )
        .disableUtilityBar()
        .prepareLevelScene()
        .fadeOutControlPanel()
        .navToEncounterComplete()
        .showEncounterReward()
        .navToActPage()
        .showLevelScene()
        .animateInShowcase()
        .hidePriorScenes()
        .changeActivityPosition()
        .prepareNextEncounterScenes()
        .playCongratulations()
        .enableUtilityBar()
        .animateInFooter()
        .enableMic()
        .animateInBadge()
        .animateFunFacts().screenplay;
    }
    // Add only encounter reward certificate for educators, then nav back to educator mode
    else {
      this.screenplay = RoundToEncounterCompleteTransitionBuilder.createFor(
        state$,
        deps
      )
        .fadeOutControlPanel()
        .navToEncounterComplete()
        .showEncounterReward()
        .navToEducatorPage()
        .resetImminentPosition()
        .hidePriorScenes()
        .showActivityCarouselModal().screenplay;
    }
  }
}
