import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { PreparedScenes } from 'services/storm-lobo';
import { TaskDemoType } from 'services/storm-lobo/StormAssets';
import { StormService } from 'storm';
import { TaskSelector } from 'task-components';
import { TaskDemoSceneActionType, TaskDemoScenePrepareAction } from '../redux';
import { TaskDemoScene } from '../TaskDemoScene';
import { TaskDemoSceneDefinitionBuilder } from '../TaskDemoSceneDefinitionBuilder';

export interface IPrepareTaskDemoSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareTaskDemoSceneEpic(
  action$: Observable<TaskDemoScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareTaskDemoSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(TaskDemoSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      const state = state$.value;

      preparedScenes.taskDemo = undefined;

      const { taskType } = TaskSelector.getTaskContent(state);

      await stormService.initialization;
      const taskDemoScene = await createTaskDemoScene(taskType, stormService);

      preparedScenes.taskDemo = taskDemoScene;
    }),
    ignoreElements()
  );
}
prepareTaskDemoSceneEpic.displayName = 'prepareTaskDemoSceneEpic';

async function createTaskDemoScene(
  taskType: TaskDemoType,
  stormService: StormService
): Promise<TaskDemoScene> {
  const taskDemoSceneDefinition =
    TaskDemoSceneDefinitionBuilder.create(taskType).definition;
  const taskDemoScene = await stormService.prepareScene(
    taskDemoSceneDefinition
  );

  return new TaskDemoScene(taskDemoScene);
}
