import { Overflow, ScrollSnapAlign } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { SystemInfo } from 'utils';

export class CarouselTaskStyles {
  public static readonly SlideWidth = 990;

  private static readonly SlideHeight = 590;

  private static readonly DefaultControlBackground = Color.BlackTransparent40;

  private static readonly DisabledControlBackground = Color.BlackTransparent30;

  private static readonly styles = {
    carouselOverrides: {
      container: {
        borderRadius: 25,
        height: CarouselTaskStyles.SlideHeight,
        overflow: Overflow.Hidden,
        width: CarouselTaskStyles.SlideWidth
      },
      controlContainerBottom: {
        backgroundColor: this.DefaultControlBackground,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 60,
        borderTopRightRadius: 60,
        height: 60,
        width: 120
        // Because of iOS issues, had to set this to double the intended height, with the overflow hidden,
        // because without this iOS wasn't respecting the 60px border-radius, but instead was using a max
        // border-radius of half the height
        // height: 120,
        // borderRadius: 60
      },
      controlContainerLeft: {
        backgroundColor: this.DefaultControlBackground,
        borderBottomRightRadius: 42,
        borderTopRightRadius: 42,
        height: 84,
        width: 97
      },
      controlContainerLeftDisabled: {
        backgroundColor: this.DisabledControlBackground
      },
      controlContainerRight: {
        backgroundColor: this.DefaultControlBackground,
        borderBottomLeftRadius: 42,
        borderTopLeftRadius: 42,
        height: 84,
        width: 97
      },
      controlContainerRightDisabled: {
        backgroundColor: this.DisabledControlBackground
      }
    },
    container: {
      backgroundColor: Color.Black,
      borderRadius: 30,
      height: CarouselTaskStyles.SlideHeight + 34,
      marginBottom: 30,
      width: CarouselTaskStyles.SlideWidth + 34
    },
    image: {
      borderRadius: 25,
      height: CarouselTaskStyles.SlideHeight,
      width: CarouselTaskStyles.SlideWidth,
      ...(!SystemInfo.isNative && { scrollSnapAlign: ScrollSnapAlign.Start })
    }
  };

  public static get() {
    return this.styles;
  }
}
