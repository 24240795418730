import {
  IActivityPosition,
  IPlacementForm,
  IPlacementRule,
  IPlacementStep
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ActivityPositionBuilder } from '../../program-context/epics/progress/position-determiners/ActivityPositionBuilder';
import { RoundContext } from '../../program-context/RoundContext';

export class PlacementFormHelper {
  public static readonly displayName = 'PlacementFormHelper';

  public static createFor(
    form: IPlacementForm,
    unitId: string
  ): PlacementFormHelper {
    return new PlacementFormHelper(form, unitId);
  }

  /**
   * Find the first placement rule that matches the round context. The round
   * context MUST contain attempt info as this is meant to be called only once
   * the round (and unit) has been completed and before any attempt history has
   * been removed. This can happen as part of the task evaluation flow either
   * when determining the position change type or, later on in the placement
   * completion flow, to determine what level the student placed at.
   */
  public static findMatchingRule({
    activityPosition,
    placementForm,
    lastAttemptMaybe,
    parentUnit
  }: RoundContext): IPlacementRule {
    const lastAttempt = lastAttemptMaybe;
    if (!lastAttempt) {
      throw new LexiaError(
        'Round context lacks attempts',
        PlacementFormHelper.displayName,
        PlacementFormHelperError.AttemptMissing
      ).withContext({ activityPosition });
    }
    const updatedPosition = ActivityPositionBuilder.create(
      activityPosition
    ).updatePlacementProgress(lastAttempt, parentUnit).activityPosition;

    return this.createFor(placementForm, parentUnit.sysId).match(
      updatedPosition
    );
  }

  public get step(): IPlacementStep {
    return this.steps[this.unitIndex];
  }

  public readonly steps: IPlacementStep[];

  public readonly unitIndex: number;

  private constructor(
    public readonly form: IPlacementForm,
    public readonly unitId: string
  ) {
    this.steps = this.chainToArray(form);
    this.unitIndex = this.findUnitIndex();
  }

  /**
   * Convert the linked list of placement forms to an array by recursively
   * navigating the chain (root->form1->form2->...)
   */
  private chainToArray(placementStep?: IPlacementStep): IPlacementStep[] {
    if (!placementStep) {
      return [];
    }

    const nextForm = placementStep.rules.find(r => r.nextPlacementStep)
      ?.nextPlacementStep;

    return [placementStep].concat(this.chainToArray(nextForm));
  }

  private findUnitIndex(): number {
    const unitIndex = this.steps.findIndex(f => f.unit.sysId === this.unitId);
    if (unitIndex < 0) {
      throw new LexiaError(
        `Unable to find unit ID "${this.unitId}" in placement form`,
        PlacementFormHelper.displayName,
        PlacementFormHelperError.UnitIdInvalid
      ).withContext({
        placementForm: this.form,
        unitId: this.unitId
      });
    }

    return unitIndex;
  }

  /**
   * Find the rule that matches the placement unit accuracy.
   * Rules are expected to be in descending order of accuracyThreshold.
   */
  public match(activityPosition: IActivityPosition): IPlacementRule {
    const accuracy = this.getAccuracy(activityPosition);
    const rule = this.step.rules.find(r => accuracy >= r.accuracyThreshold);

    return this.validateRuleMatch(activityPosition, rule);
  }

  private getAccuracy(activityPosition: IActivityPosition): number {
    const accuracy = activityPosition.unitPosition.placementProgress?.accuracy;
    if (accuracy === undefined) {
      throw this.createError(
        'Placement unit progress accuracy is undefined!',
        PlacementFormHelperError.AccuracyUndefined,
        activityPosition
      );
    }

    return accuracy;
  }

  private validateRuleMatch(
    activityPosition: IActivityPosition,
    rule: IPlacementRule | undefined
  ): IPlacementRule {
    if (activityPosition.unitPosition.unitId !== this.step.unit.sysId) {
      throw this.createError(
        'Activity position unit and placement unit IDs do not match',
        PlacementFormHelperError.PositionMismatch,
        activityPosition
      );
    }

    if (!rule) {
      const accuracy = this.getAccuracy(activityPosition);
      throw this.createError(
        `No rule found for an accuracy of ${accuracy}`,
        PlacementFormHelperError.RuleMissing,
        activityPosition
      );
    }

    return rule;
  }

  private createError(
    message: string,
    code: PlacementFormHelperError,
    activityPosition: IActivityPosition
  ): LexiaError {
    return new LexiaError(
      message,
      PlacementFormHelper.displayName,
      code
    ).withContext({ activityPosition, placementStep: this.step });
  }
}

export enum PlacementFormHelperError {
  AccuracyUndefined = 'AccuracyUndefined',
  AttemptMissing = 'AttemptMissing',
  RuleMissing = 'RuleMissing',
  PositionMismatch = 'PositionMismatch',
  UnitIdInvalid = 'UnitIdInvalid'
}
