import { ActionType, createAction } from 'typesafe-actions';
import {
  AvatarEditorActionType,
  AvatarTimerDurationMs,
  IAvatarEditorUserEventPayload
} from './avatar-editor-redux.model';
import { IAvatar } from 'services/profile/avatar.model';

export const AvatarEditorAction = {
  addUserEvent: createAction(
    AvatarEditorActionType.AddUserEvent
  )<IAvatarEditorUserEventPayload>(),
  hideEditor: createAction(AvatarEditorActionType.HideEditor)(),
  reset: createAction(AvatarEditorActionType.Reset)(),
  saveAndExit: createAction(AvatarEditorActionType.SaveAndExit)(),
  setEncountersUntilEditable: createAction(
    AvatarEditorActionType.SetEncountersUntilEditable
  )<number>(),
  showEditor: createAction(
    AvatarEditorActionType.ShowEditor,
    (avatar?: IAvatar) => avatar
  )(),
  timeoutUserConfirmation: createAction(
    AvatarEditorActionType.TimeoutUserConfirmation
  )(),
  timerPause: createAction(AvatarEditorActionType.TimerPause)(),
  timerRestart: createAction(AvatarEditorActionType.TimerRestart)(),
  timerSetDuration: createAction(
    AvatarEditorActionType.TimerSetDuration
  )<AvatarTimerDurationMs>(),
  timerStart: createAction(AvatarEditorActionType.TimerStart)(),
  timerTimeout: createAction(AvatarEditorActionType.TimerTimeout)()
};

export type AvatarEditorActions = ActionType<typeof AvatarEditorAction>;
export type AvatarEditorActionAddUserEvent = ActionType<
  typeof AvatarEditorAction.addUserEvent
>;
export type AvatarEditorActionHideEditor = ActionType<
  typeof AvatarEditorAction.hideEditor
>;
export type AvatarEditorActionReset = ActionType<
  typeof AvatarEditorAction.reset
>;
export type AvatarEditorActionSaveAndExit = ActionType<
  typeof AvatarEditorAction.saveAndExit
>;
export type AvatarEditorActionSetEncountersUntilEditable = ActionType<
  typeof AvatarEditorAction.setEncountersUntilEditable
>;
export type AvatarEditorActionShowEditor = ActionType<
  typeof AvatarEditorAction.showEditor
>;
export type AvatarEditorActionTimeoutUserConfirmation = ActionType<
  typeof AvatarEditorAction.timeoutUserConfirmation
>;
export type AvatarEditorActionTimerRestart = ActionType<
  typeof AvatarEditorAction.timerRestart
>;
export type AvatarEditorActionTimerPause = ActionType<
  typeof AvatarEditorAction.timerPause
>;
export type AvatarEditorActionTimerStart = ActionType<
  typeof AvatarEditorAction.timerStart
>;
export type AvatarEditorActionTimerTimeout = ActionType<
  typeof AvatarEditorAction.timerTimeout
>;
