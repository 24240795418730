import {
  ICalibrationResult,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  SreCalibrationAction,
  SreCalibrationActionComplete,
  SreSessionActionListenResult,
  SreSessionActionType
} from '../redux';

export function calibrationCompletedEpic(
  action$: Observable<SreSessionActionListenResult>
): Observable<SreCalibrationActionComplete> {
  return action$.pipe(
    ofType(SreSessionActionType.ListenResult),
    filter(
      action =>
        action.payload.sessionOptions.sessionType === SreSessionType.Calibration
    ),
    map(action => {
      const result = action.payload.result;

      return SreCalibrationAction.calibrationComplete({
        ...(result as ICalibrationResult)
      });
    })
  );
}
calibrationCompletedEpic.displayName = 'calibrationCompletedEpic';
