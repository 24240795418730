import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { CalibrationIntroScreenplayBuilder } from '../builders/calibration';
import { TransitionScreenplayId } from '../transition.model';

export interface IIntroductionToCalibrationTransitionScreenplayDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
  state$: StateObservable<unknown>;
}

export class IntroductionToCalibrationTransitionScreenplayFactory {
  public static readonly displayName =
    'IntroductionToCalibrationTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: IIntroductionToCalibrationTransitionScreenplayDeps
  ): IntroductionToCalibrationTransitionScreenplayFactory {
    return new IntroductionToCalibrationTransitionScreenplayFactory(deps);
  }

  private constructor(
    private readonly deps: IIntroductionToCalibrationTransitionScreenplayDeps
  ) {
    this.screenplay = this.buildCalibrationScreenplay();
  }

  private buildCalibrationScreenplay(): IScreenplay {
    const { preparedScenes, stormService, state$ } = this.deps;
    const calibrationContent =
      BootstrapContentSelector.getCalibrationScreenContent(state$.value)!;
    const calibrationIntroductionVo = calibrationContent.introduction.cover;
    const calibrationInteractiveVo =
      calibrationContent.introduction.interactive;
    const calibrationSentenceVo =
      BootstrapContentSelector.getCalibrationSentence(state$.value)?.voiceover;

    return ScreenplayBuilder.create(
      TransitionScreenplayId.IntroductionToCalibration
    )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.calibration());
      })
      .addScreenplay(
        CalibrationIntroScreenplayBuilder.create()
          .loadUsageMetrics()
          .showCalibrationScene(preparedScenes, stormService)
          .fadeInPageElements()
          .animateScene()
          .playIntroVoiceovers(
            calibrationIntroductionVo,
            calibrationInteractiveVo,
            calibrationSentenceVo
          )
          .setInteractive().screenplay
      ).screenplay;
  }
}
