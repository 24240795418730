import { IMdmConfigParameters } from '@lexialearning/mdm-config';
import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CustomerApi } from 'lexia-service/customer-api';
import { LocalStorageService } from 'services/local-storage';
import { SystemInfo } from 'utils';
import {
  CustomerAction,
  CustomerActionBootstrapSetup,
  CustomerActionSettingsLoaded,
  CustomerActionSetupDeviceWithCustomerCodeAttempted,
  CustomerActionSetupDeviceWithCustomerCodeFailure,
  CustomerActionSetupDeviceWithCustomerCodeSuccess,
  CustomerActionType,
  CustomerSelector
} from '../redux';
import {
  DeviceSetupLocalStorageKeys,
  IDeviceSetupDependencies
} from './device-setup-epic.model';

export type OutputActions =
  | CustomerActionSetupDeviceWithCustomerCodeAttempted
  | CustomerActionSetupDeviceWithCustomerCodeSuccess
  | CustomerActionSettingsLoaded
  | CustomerActionSetupDeviceWithCustomerCodeFailure;

export function bootstrapDeviceSetupEpic(
  action$: Observable<CustomerActionBootstrapSetup>,
  state$: StateObservable<unknown>,
  deps: IDeviceSetupDependencies
): Observable<OutputActions> {
  return action$.pipe(
    ofType(CustomerActionType.BootstrapSetup),
    mergeMap(async () => {
      const { localStorageService, customerApi, configProvider } = deps;
      const mdmEmail = configProvider.getConfig<
        IMdmConfigParameters | undefined
      >('mdm')?.teacherEmail;
      if (mdmEmail) {
        return setupWithMdmEmail(mdmEmail, localStorageService, customerApi);
      }

      const customerCodeFromUrl = CustomerSelector.getCustomerCode(
        state$.value
      );
      const isWebPlatform = !SystemInfo.isNative;

      if (isWebPlatform && customerCodeFromUrl) {
        return setupWithCodeFromUrl(
          customerCodeFromUrl,
          localStorageService,
          customerApi
        );
      }

      return setupWithCodeFromLocalStorage(localStorageService, customerApi);
    }),
    mergeMap(action => action)
  );
}

bootstrapDeviceSetupEpic.displayName = 'bootstrapDeviceSetupEpic';

async function setupWithCodeFromUrl(
  customerCodeFromUrl: string,
  localStorageService: LocalStorageService,
  customerApi: CustomerApi
): Promise<OutputActions[]> {
  let completeDeviceSetupOptimistically = false;
  const outputActions: OutputActions[] = [];

  try {
    const customerInfo =
      await customerApi.getCustomerByCode(customerCodeFromUrl);

    await localStorageService.setItem(
      DeviceSetupLocalStorageKeys.CustomerCode,
      customerInfo.customerCode
    );

    const { settings, ...customer } = customerInfo;

    outputActions.push(
      CustomerAction.setupDeviceWithCustomerCode.success(customer)
    );
    outputActions.push(CustomerAction.settingsLoaded(settings));
    completeDeviceSetupOptimistically = true;
  } catch (err) {
    await localStorageService.removeItem(
      DeviceSetupLocalStorageKeys.CustomerCode
    );

    if (
      (err as LexiaError).standardCode === LexiaStandardErrorCode.InvalidSiteId
    ) {
      throw err;
    }

    outputActions.push(CustomerAction.setupDeviceWithCustomerCode.failure(err));
  }

  outputActions.push(
    CustomerAction.setupDeviceWithCustomerCodeAttempted({
      completeDeviceSetupOptimistically
    })
  );

  return outputActions;
}

async function setupWithCodeFromLocalStorage(
  localStorageService: LocalStorageService,
  customerApi: CustomerApi
): Promise<OutputActions[]> {
  let completeDeviceSetupOptimistically = false;
  const outputActions: OutputActions[] = [];

  try {
    const customerCodeFromLocalStorage = await localStorageService.getItem(
      DeviceSetupLocalStorageKeys.CustomerCode
    );

    if (customerCodeFromLocalStorage) {
      const customerInfo = await customerApi.getCustomerByCode(
        customerCodeFromLocalStorage
      );

      const { settings, ...customer } = customerInfo;
      outputActions.push(
        CustomerAction.setupDeviceWithCustomerCode.success(customer)
      );
      outputActions.push(CustomerAction.settingsLoaded(settings));
      completeDeviceSetupOptimistically = true;
    }
  } catch (error) {
    if (
      (error as LexiaError).standardCode ===
      LexiaStandardErrorCode.InvalidSiteId
    ) {
      await localStorageService.removeItem(
        DeviceSetupLocalStorageKeys.CustomerCode
      );
    } else {
      outputActions.push(
        CustomerAction.setupDeviceWithCustomerCode.failure(error)
      );
    }
  }

  outputActions.push(
    CustomerAction.setupDeviceWithCustomerCodeAttempted({
      completeDeviceSetupOptimistically
    })
  );

  return outputActions;
}

async function setupWithMdmEmail(
  email: string,
  localStorageService: LocalStorageService,
  customerApi: CustomerApi
): Promise<OutputActions[]> {
  let completeDeviceSetupOptimistically = false;
  const outputActions: OutputActions[] = [];

  const customerInfo = await customerApi.getCustomerByEmail(email);

  await localStorageService.setItem(
    DeviceSetupLocalStorageKeys.CustomerCode,
    customerInfo.customerCode
  );

  const { settings, ...customer } = customerInfo;

  outputActions.push(
    CustomerAction.setupDeviceWithCustomerCode.success(customer)
  );
  outputActions.push(CustomerAction.settingsLoaded(settings));
  completeDeviceSetupOptimistically = true;

  outputActions.push(
    CustomerAction.setupDeviceWithCustomerCodeAttempted({
      completeDeviceSetupOptimistically
    })
  );

  return outputActions;
}
