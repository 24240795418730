export interface IDevToolsState {
  isVisible: boolean;
  position: DevToolsPosition;
  // value controlled in dev tools, determines whether shortcut buttons are shown in DeveloperAccessMenu
  shouldShowMicErrorShortcuts: boolean;
  shouldShowIosShortcuts: boolean;
}

export enum DevToolsPosition {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right'
}
