import { IScreenplay } from '@lexialearning/lobo-common';
import { Music } from 'audio/music';
import { PlacementScreenplayId } from './placement-screenplay-builders.model';
import { PlacementScreenplayBuilderBase } from './PlacementScreenplayBuilderBase';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=ELKMK&title=Student+Placement
 */
export class PlacementUnitToUnitScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'PlacementIntroScreenplayBuilder';

  public static create(): PlacementUnitToUnitScreenplayBuilder {
    return new PlacementUnitToUnitScreenplayBuilder();
  }

  protected constructor() {
    super(PlacementScreenplayId.UnitToUnit);
  }

  public preEncouragementDelay(): PlacementUnitToUnitScreenplayBuilder {
    this.builder.addDelay(500);

    return this;
  }

  public playEncouragementMusic(): PlacementUnitToUnitScreenplayBuilder {
    this.builder.addMusic({ path: Music.PlacementUnitComplete });

    return this;
  }

  public playEncouragementVo(
    unitCompleteVo: IScreenplay
  ): PlacementUnitToUnitScreenplayBuilder {
    this.builder.addActionList(this.addSpeaker(unitCompleteVo));

    return this;
  }
}
