import { ContentProviderStrategy } from '@lexialearning/lobo-common/cms/cms.model';
import { ContentfulEnv } from '@lexialearning/lobo-common/cms/contentful/ContentfulApi';
import { DeployConfig } from './DeployConfig';
import { ILoboAppConfig } from './lobo-config.model';
import {
  LanguageFrameSession as LF,
  SoundLogCollection
} from '@lexialearning/lobo-common/main-model/sre';
import { LoboSfx } from 'audio';
import { MdmConfig } from '@lexialearning/mdm-config';
import {
  IStormWorkaroundSettings,
  MountType,
  StormFeatureFlag,
  StormFeatureFlagSetName
} from '@lexialearning/common-ui';
import { SystemInfo } from 'utils/SystemInfo';
import { Version } from './Version';
import {
  DeployEnvironment,
  LoggingLevel,
  OSName,
  ProductCode
} from '@lexialearning/main-model';
import { ILoggingConfig } from '@lexialearning/utils-react';
import { DeployEnvironmentUrls } from './deploy.model';

declare const __CONTENTFUL_ACCESS_KEY__: string;

/* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
const STORM_WORKAROUND_SETTINGS: IStormWorkaroundSettings[] = [
  // LOBO-19169
  {
    key: StormFeatureFlag.DisableDisjointTimerQuery,
    featureFlags: [StormFeatureFlag.DisableDisjointTimerQuery],
    machineSpecifications: {
      gpuPattern: ['Mali-G52']
    }
  },
  // LOBO-19129, LOBO-20463
  {
    key: StormFeatureFlag.DisableMsaa,
    featureFlags: [StormFeatureFlag.DisableMsaa],
    machineSpecifications: {
      gpuPattern: ['Mali-G72', '(JSL)']
    }
  },
  // LOBO-19191, LOBO-19365
  {
    key: StormFeatureFlagSetName.LowPerformanceMode,
    featureFlags: [StormFeatureFlag.DisableMsaa, StormFeatureFlag.HalfRate],
    machineSpecifications: {
      coreCount: [1, 2, 4],
      osName: [OSName.Windows],
      gpuPattern: ['Intel(R) HD Graphics', 'Intel(R) UHD Graphics']
    }
  }
];
/* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */

const baseAppUrl = determineBaseAppUrl(
  DeployConfig.deployEnvironment,
  Version.Branch
);
const assetsUrl = buildAssetsUrl(
  baseAppUrl,
  Version.Main,
  Version.Branch,
  Version.Build
);

/**
 * Base dir of images in S3 that are uploaded from/maintained in the lobo-assets repo
 */
export const REPO_IMG_DIR = `${assetsUrl}/images`;
export const REPO_HTML_DIR = `${assetsUrl}/html`;

const audioGlobalPath = '/audio-global';
const imgDir = `/static/${Version.Major}/images`;
const videoDir = `/static/${Version.Major}/videos`;
const voDir = `/static/${Version.Major}/vo`;
const voPath = '/vo-';
const voSharedPath = '/vo-shared';

const isDebug = [
  DeployEnvironment.DevelopDebug,
  DeployEnvironment.MainDebug,
  DeployEnvironment.QADebug,
  DeployEnvironment.StagingDebug,
  DeployEnvironment.Local
].includes(DeployConfig.deployEnvironment);

const {
  password: mdmPassword,
  teacherEmail: mdmTeacherEmail,
  username: mdmUsername
} = MdmConfig.getConstants();

const loggingLevel = getLoggingLevel();

const cargoOverrides = getCargoConfigOverrides(DeployConfig.deployEnvironment);

const sfxPath = `${audioGlobalPath}/sfx`;
// istanbul ignore next - trivial and hard to test
export const appConfig: ILoboAppConfig = {
  accountSettings: {
    licensesUrl: `${baseAppUrl}/licenses.txt`
  },
  bootstrapping: {
    bootstrapContentSysId: '6TKdHskVVL1eUNlIOp7m11'
  },
  bootstrappingOrchestrator: {
    logging: {
      apiKey: cargoOverrides.apiKey || '',
      endpoint: cargoOverrides.endpoint || '',
      loggingLevel
    }
  },
  commonUi: {
    storm: {
      assetDefaultFolders: {
        sfx: { path: sfxPath },
        soundtrack: { path: '' },
        voiceover: { path: '' }
      },
      audio: {
        customSfx: Object.values(LoboSfx),
        defaultVolumeLevels: {
          music: 0.7,
          sfx: 1,
          soundtrack: 1,
          voiceover: 1
        },
        // TODO: add path when Uni Modals requiring audio are implemented (please get your teacher)
        voiceoverPath: ''
      },
      bootstrap: {
        virtualFileSystems: [
          {
            preloads: ['/sfx', '/music/app-intro'],
            source: `${
              SystemInfo.isNative ? '/assets' : assetsUrl
            }/audio/global`,
            target: audioGlobalPath,
            type: SystemInfo.isNative ? MountType.ASSETS : MountType.HTTP
          },
          {
            source: `${assetsUrl}/scenes`,
            target: '/scenes',
            type: MountType.HTTP
          },
          {
            preloads: [
              // TODO: Temp fix for the slow-loading Calibration VO's on slow connections.
              // Hardcoding these for 1.5, but they should be moved to a Calibration-specific
              // directory within narrator to just pull in that directory here instead.
              // https://jira.lexialearning.com/browse/LOBO-13681
              '/narrator/2dHUYfXtrJRO8sSaDusEbF', // Cover intro VO: 'Hi, is your headset on? ...'
              '/narrator/1Oj0WT8kUFYnxqA3vjfVMM', // Interactive intro VO: 'Select the mic...'
              '/narrator/UfoPtBkFZqzyho3aut8uJ' // Calibration sentence: '1, 2, 3, 4, 5'
            ],
            source: `${baseAppUrl}${voDir}/shared`,
            target: voSharedPath,
            type: MountType.HTTP
          }
        ]
      },
      debug: false,
      lateOptionsSettings: STORM_WORKAROUND_SETTINGS
    }
  },
  content: {
    endpoint: `${SystemInfo.isNative ? '/assets' : assetsUrl}/content`,
    strategy: ContentProviderStrategy.Json
  },
  contentful: {
    accessToken: __CONTENTFUL_ACCESS_KEY__,
    environment: ContentfulEnv.Develop,
    host: 'preview.contentful.com',
    space: 'lbek1h66tqmc'
  },
  dynamicNotifications: {
    productVersion: {
      name: ProductCode.Lobo,
      version: Version.Main
    }
  },
  imageSubstitutions: {},
  imagesMountPoint: {
    dir: `${imgDir}/`,
    name: '',
    webOrigin: baseAppUrl
  },
  isDebug,
  levelVoiceoverMountPoint: {
    dir: `${voDir}/`,
    name: voPath,
    webOrigin: baseAppUrl
  },
  lexiaService: {
    customerUrl: determineAuthServerUrl(DeployConfig.deployEnvironment)
  },
  // TODO: LOBO-20171 remove and use bootstrappingOrchestrator.logging instead?
  // can logRocket prop be added there? otherwise where would that go?
  logger: {
    apiKey: cargoOverrides.apiKey || '',
    endpoint: cargoOverrides.endpoint || '',
    logRocket: {
      appId: 'dyoozu/lobo-dev',
      configUrl: '',
      enabled: false
    },
    loggingLevel
  },
  mdm: {
    password: mdmPassword,
    teacherEmail: mdmTeacherEmail,
    username: mdmUsername
  },
  mobileApp: {
    appStoreUrl: 'https://apps.apple.com/app/id1444111004'
  },
  seeSpeak: {
    scoring: {
      strategy: LF.ScoringStrategy.Rubric,
      threshold: LF.SCORING_SRE_THRESHOLD_DEFAULT
    },
    wordWeights: {
      grammar: 1,
      standard: 0.24,
      syntax: 0.39
    }
  },
  showAuthUrl: false,
  spinnerHandler: {
    delay: 3000
  },
  sre: {
    appId: `Lobo-${DeployConfig.deployEnvironment}`,
    soundLogCollectionMode: SoundLogCollection.Full,
    soundLogCollectionPolicies: [],
    soundLogUrl: `${determineSoundLogDomain(
      DeployConfig.deployEnvironment
    )}/sound-logs`,
    speechModelUrl: `${determineSreDomain(
      DeployConfig.deployEnvironment
    )}/speech-models`
  },
  storm: {
    assetPaths: {
      BackgroundOverlays: { items: ['stars'], path: 'background-overlays' },
      Backgrounds: { path: 'backgrounds' },
      Calibration: { path: 'calibration' },
      CharacterAnimations: { path: 'character-animations' },
      Characters: { path: 'characters' },
      Encounter: { path: 'encounter' },
      Home: { path: 'home' },
      Introduction: { path: 'introduction' },
      Level: { path: 'level' },
      Music: { path: `${audioGlobalPath}/music/` },
      Placement: { path: 'placement' },
      Sfx: { path: `${audioGlobalPath}/sfx/` },
      TaskDemo: { path: 'task-demo' },
      UnitComplete: {
        items: [
          '01/reward_awesome',
          '02/reward_wow',
          '03/reward_youdidit',
          '04/reward_yourock',
          '05/reward_smashing',
          '06/reward_greatjob',
          '07/reward_ootw',
          '08/reward_yougotthis',
          '09/reward_incredible',
          '10/reward_keepgoing',
          '11/reward_2_bravo',
          '12/reward_2_cool',
          '13/reward_2_dance',
          '14/reward_2_fresh',
          '15/reward_2_paintbrush',
          '16/reward_2_rocksolid',
          '17/reward_2_skateboard',
          '18/reward_2_spraypaint',
          '19/reward_2_stellar',
          '20/reward_2_viking'
        ],
        path: 'unit-complete'
      },
      Voiceovers: { path: voPath }
    },
    sfx: Object.values(LoboSfx).map(path => ({ path }))
  },
  studentApi: {
    apiDomain: determineStudentApiUrl(DeployConfig.deployEnvironment),
    fetchRetryThrottleSeconds: [],
    idleSession: {
      timeoutMinutes: 20,
      warningMinutes: 15
    },
    keepAliveSeconds: 5 * 60,
    licenseViolationImageUrl: '',
    product: ProductCode.Lobo,
    productVersion: Version.Main
  },
  videosMountPoint: {
    dir: `${videoDir}/`,
    name: '',
    webOrigin: baseAppUrl
  }
};

/** Returns LoggingLevel.Analytics 10% of the time */
function getLoggingLevel() {
  // Set to LoggingLevel.Analytics for 10% of users
  const randomOutOf100 = Math.round(Math.random() * 100);

  return randomOutOf100 <= 10 ? LoggingLevel.Analytics : LoggingLevel.Verbose;
}

function getCargoConfigOverrides(
  env: DeployEnvironment
): Partial<ILoggingConfig> {
  switch (env) {
    case DeployEnvironment.Production:
      return {
        apiKey: '0fdc0525-c35a-4c4b-84cd-4e740dd9af96',
        endpoint: 'https://cargo.mylexia.com/lobo'
      };

    case DeployEnvironment.Staging:
    case DeployEnvironment.StagingDebug:
    case DeployEnvironment.PerformanceProfiling:
      return {
        apiKey: 'a088fc93-81f5-4f13-b90a-722cd46ff94c',
        endpoint: 'https://cargostage.mylexia.com/lobo'
      };

    default:
      return {
        apiKey: 'ec1a21ac-3a5b-4150-8ae8-8fcf4194deba',
        endpoint: 'https://cargodev.lexia-dev.com/lobo'
      };
  }
}

function determineIsReleaseBranch(branch: string) {
  const releaseRegEx = /release\/(\d+\.\d+\.\d+)$/;

  return releaseRegEx.test(branch);
}

function determineBaseAppUrl(env: DeployEnvironment, branch: string): string {
  const isReleaseBranch = determineIsReleaseBranch(branch);

  if (env === DeployEnvironment.Local) {
    if (isReleaseBranch) {
      return DeployEnvironmentUrls.Staging;
    }

    return `https://lobo-${branch}.lexia-dev.com`;
  }

  if (SystemInfo.isNative) {
    switch (env) {
      case DeployEnvironment.Production:
        return DeployEnvironmentUrls.Production;
      case DeployEnvironment.Main:
        return DeployEnvironmentUrls.Main;
      default:
        return DeployEnvironmentUrls.Develop;
    }
  }

  return '';
}

function determineSreDomain(env: DeployEnvironment): string {
  switch (env) {
    case DeployEnvironment.Production:
      return 'https://sre.mylexia.com';

    case DeployEnvironment.Staging:
    case DeployEnvironment.StagingDebug:
    case DeployEnvironment.PerformanceProfiling:
      return 'https://sre-staging.mylexia.com';

    default:
      return 'https://sre.lexia-dev.com';
  }
}

function determineSoundLogDomain(env: DeployEnvironment): string {
  switch (env) {
    case DeployEnvironment.Production:
      return 'https://sound-logs.mylexia.com';

    case DeployEnvironment.Staging:
    case DeployEnvironment.StagingDebug:
    case DeployEnvironment.PerformanceProfiling:
      return 'https://sound-logs-staging.mylexia.com';

    default:
      return 'https://sound-logs.lexia-dev.com';
  }
}

export function determineAuthServerUrl(env: DeployEnvironment): string {
  switch (env) {
    case DeployEnvironment.Production:
      return 'https://auth.mylexia.com';

    case DeployEnvironment.Staging:
    case DeployEnvironment.StagingDebug:
    case DeployEnvironment.PerformanceProfiling:
    case DeployEnvironment.Main:
    case DeployEnvironment.MainDebug:
    case DeployEnvironment.MainLatest:
      return 'https://authstaging.mylexia.com';

    // Dev environment values come from Contentful config overrides
    default:
      return '';
  }
}

export function determineStudentApiUrl(env: DeployEnvironment): string {
  switch (env) {
    case DeployEnvironment.Production:
      return 'https://uni-student-lobo.mylexia.com';

    case DeployEnvironment.Staging:
    case DeployEnvironment.StagingDebug:
    case DeployEnvironment.PerformanceProfiling:
    case DeployEnvironment.Main:
    case DeployEnvironment.MainDebug:
    case DeployEnvironment.MainLatest:
      return 'https://uni-student-lobo-staging.mylexia.com';

    // Dev environment values come from Contentful config overrides
    default:
      return '';
  }
}

function buildAssetsUrl(
  baseUrl: string,
  versionMain: string,
  versionBranch: string,
  versionBuild: string
): string {
  const isReleaseBranch = determineIsReleaseBranch(versionBranch);

  return isReleaseBranch
    ? `${baseUrl}/version/${versionMain}/${versionBuild}/assets`
    : `${baseUrl}/version/${versionMain}/${versionBranch}/${versionBuild}/assets`;
}

export const AppConfigPrivates = {
  buildAssetsUrl,
  determineAuthServerUrl,
  determineBaseAppUrl,
  determineIsReleaseBranch,
  determineSoundLogDomain,
  determineSreDomain,
  determineStudentApiUrl,
  getCargoConfigOverrides
};
