import { Svg, Path, G, Rect } from '@lexialearning/common-ui';
import { useSelector } from 'react-redux';
import { ProfileSelector } from 'services/profile';
import { FaceStyle } from 'services/profile/avatar.model';
import { getFaceColors } from '../face-styles.model';

export function FaceStyleFourSvg(): React.ReactElement {
  const { hairColor, skinColor } = useSelector(ProfileSelector.getAvatarMaybe)!;
  const {
    eyeLines,
    eyebrowLines,
    eyebrows,
    eyelashes,
    eyes,
    lips,
    skin,
    skinLines
  } = getFaceColors(FaceStyle.Four, hairColor, skinColor);

  return (
    <Svg
      data-test-id={FaceStyleFourSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <G>
        <Rect fill={skin} strokeWidth="0" width="1000" height="1000" />
      </G>
      <G>
        <G>
          <Path
            fill={lips}
            strokeWidth="0"
            d="M648.4,902.43c-96.29-14.31-219.86-14.32-274.68-8.25-27.56,3.05-62.46,3.15-92.5-3.22-4.22-.89-12.37-2.82-12.54-7.79.67-7,16.19-12.03,22.71-8.36,12.97,5.24,24.36,7.66,33.69-.51,31.18-28.17,67.88-66.28,112.72-56.34,17.44,3.24,34.08,6.64,51.48-1.08,16.63-7.38,45.7-1.73,60.85,4.78,37.88,16.52,62.9,35.02,100.03,53.2,19.21,10.18,38.95,11.6,58.78.95,6.76-2.79,17.12-.36,14.24,5.97-8.04,17.65-55.6,23.51-74.78,20.66Z"
          />
          <G>
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M495.58,720.42c16.84-.78,30.74,4.56,44.83,6.49,29.48,4.04,61.31-14.9,51.47-55.6-.67-2.79-2.17-7.66.54-8.99,3.11-1.53,6.94,3.14,8.49,5.82,14.09,24.28,1.9,56.49-17.28,66.91-21.94,11.93-43.87,8.21-66.13,5.94-12.43-1.27-23.58-1.26-34.84,5.96-4.06,2.6-13.14.93-17.94-2.03-7.56-4.65-5.73-14.67,2.87-17.55,9.81-3.29,19.91-6.59,28-6.96Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M333.69,676.03c-1.9-24.07,8.28-43.79,20.46-62.53,6.35-9.77,14.14-18.7,22.04-27.31,2.29-2.5,8.05-6.42,10.92-3.78s-.93,7.67-2.64,10.83c-9.49,17.56-21.83,34-28.6,52.51-12.6,34.45-.75,68.1,28.2,90.22,2.33,1.78,5.07,3.12,7.07,5.19,3.19,3.32,10.99,6.58,8.84,10.68s-9.98.2-14.4-1.34c-27.15-9.49-53.25-35.63-51.89-74.48Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M757.16,308.36c8.52,3.32-4.31,9.06-9.24,10.24-47.29,11.25-79.76,48.5-106.75,86.8-4.11,4.38-7.35,3.64-5.84-2.64,20.56-47.47,66.32-90.96,120.08-94.7l1.75.3Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M340.65,411.31c-.38,1.02-2.31.23-4.75-1.64-11.97-10.27-24.65-24.25-37.13-34.81-15.11-16.33-39.88-21.63-54.01-33.68,38.73.81,78,35.25,95.96,69.13l-.06,1.01Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M114.9,498c14.06,54.94,60.82,88.01,120.68,85.93,68.44-2.38,82.75-17.27,91.09-71.92,9.66-63.25-41.92-118.32-111.09-116.95-78.59,1.56-113.05,54.62-100.68,102.94Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M924.24,476.17c-14.06,54.94-58.6,97.87-115.16,97.06-28.3-.4-58.16-2.45-84.07-14.97-28.22-13.64-50.34-22.46-55.71-47.78-13.27-62.59,31.2-152.37,126.29-152.37,78.61,0,141.02,69.73,128.65,118.05Z"
            />
            <G>
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M799.57,567.35c-4.7,0-9.27-.31-13.57-.94-45.14-6.69-83.81-63.13-76.61-111.83,3.93-26.03,18.86-58.49,39.77-70.16,7.24-4.08,32.41-17.36,52.39-17.36,1.23,0,2.43.05,3.61.16,28.09,2.81,64.46,16.44,82.21,46.27,7.62,12.74,12.63,40.13,12.37,56.75-.7,28.21-12.36,55.46-31.18,72.85-15.93,14.73-43.01,24.25-68.99,24.25Z"
              />
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M232.35,578.93c-13.48,0-26.73-3.47-38.33-10.02-31.42-17.88-47.69-45.72-47.03-80.5.57-36.01,25.86-70.46,49.99-82.97,9.98-5.18,20.56-7.81,31.46-7.81,6.68,0,13.51.96,20.85,2.91,36.99,10.23,65.44,47.77,69.87,90.94l-.56,4.56c-2.27,18.54-9.72,35.53-22.76,51.95-15.64,19.66-38.78,30.96-63.48,30.96Z"
              />
            </G>
            <G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M870.86,545.7c20.28-18.73,31.67-47.25,32.3-75.44.4-17.86-5.05-45.75-12.75-58.61-17.58-29.34-52.87-44.64-84.95-47.84-17.78-1.78-42.4,8.79-57.99,17.52-22.81,12.77-37.64,46.82-41.48,72.75-7.28,49.16,30.41,108.52,79.48,115.72,28.63,4.2,64.9-5.18,85.39-24.1ZM717.33,464.06c-1.17-51.58,38.85-88.2,84.42-86.42,54.84.93,85.34,40.1,89.33,88.56,4.1,49.81-34.59,93.55-83.39,94.28-52.78.79-89.19-45.18-90.35-96.42Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M802.23,514.36c-27.08-.12-49.3-22.66-49.25-49.96.05-26.53,23.41-51.2,48.92-50.69,23.56.48,51,19.65,51.29,54.22.21,25.07-23.5,46.55-50.95,46.42Z"
                />
              </G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M276.63,489.75c.25,22.58-19.98,41.87-44.36,42.3-24.56.43-45.47-19.74-45.59-43.98-.12-24.56,19.82-46.11,43.56-46.42,19.17-.25,45.94,15.87,46.38,48.09Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M195.32,402.38c-26.65,13.8-51.24,50.23-51.86,85.91-.65,37.13,17.61,65.91,48.9,83.65,34.39,19.5,79.71,11.68,106.27-21.83,12.44-15.69,20.96-33.06,23.51-53.66.36,1.01.68,1.89,1.02,2.85-1.54-45.94-30.42-90.43-72.93-102.11-14.1-3.88-33.54-5.88-54.9,5.18ZM305.01,471.36c16.03,40.87-10.62,85.41-50.4,95.33-43.49,10.85-85.45-15.55-96.94-58.07-11.16-41.28,15.28-87.46,55.79-95.89,58.48-12.17,79.28,27.31,91.55,58.62Z"
                />
              </G>
            </G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M201.36,506.95c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M776.88,503.66c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
          </G>
          <G>
            <Path
              fill={eyelashes}
              strokeWidth="0"
              d="M808.75,368.9c28.21,1.98,64.32,20.07,84.07,46.23,6.95,9.21,18.52,29.33,21.61,40.45,10.76,38.78-11.36,69.19-39.54,88.36-20.07,13.65-35.04,26.65-94.59,25.81-19.92-.28-40.48-6.06-57.87-14.93-2.05-1.04-5.61-3.42-6.74-1.42-1.13,1.97,2.76,3.86,4.63,4.96,20.64,12.1,38.1,15.52,62.45,17.83,9.1.86,22.83.22,31.86-1.13,41.01-6.13,74.37-24.31,94.97-61.83,7.44-13.55,13.6-27.86,21.79-40.91,6.75-10.76,8.67-21.39,5.87-33.45-9.97-42.99-37.62-71.07-76.45-88.76-79.3-36.14-169.03,7.69-195.27,77.84-9.46,25.3-14.61,51.73-4.26,78.47,1.85,4.78,7.25,13.7,7.25,13.7,4.97-1.09,1.38-10.25.78-15.22-5.74-47.2,27.93-99.37,67.04-120.54,19.63-10.62,53.66-16.76,72.41-15.45Z"
            />
            <Path
              fill={eyelashes}
              strokeWidth="0"
              d="M332.68,489.81c-6.95-46.54-32.36-80-73.96-100.45-58.41-28.71-132.65-2.04-160.06,56.77-7.49,16.07-15.35,34.32-4.81,50.43,13.59,20.76,29.67,39.14,49.4,56,3.03,2.59,25.71,25.85,55.22,31.69,29.43,5.82,64.99-.4,84.06-5.38,9.24-2.41,12.42-3.95,13.33-4.81-4.77-2.79-53.04,13.54-95.67,3.78-28.67-6.57-49.75-28.83-51.67-30.58-21.89-19.97-36.59-42.23-28.09-74.06,9.77-36.55,30.61-66.57,67.94-72.35,15.27-2.37,47.43-5.68,61.81-.01,37.53,14.81,71.42,52.55,72.95,98.49,1.58,4.43.46,14.46,4.46,12.48,2.25-1.12,5.9-16.4,5.07-21.98Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M930.67,320.93c-.98-.79-1.84-1.61-2.46-2.51-24.18-35.46-63.06-42.25-97.61-45.84-12.14-1.28-24.65-1.93-37.16-1.93-42.31,0-87.55,7.35-138.29,22.49-12.38,3.74-24.74,7.09-37.1,10.42-1.3.36-2.46.52-3.62.52-6.65,0-11.93-5.04-12.56-11.96-1.93-22.64,1.78-42.58,11.04-59.25,2.68-4.94,8.46-8.77,14.05-12.48l2.33-1.56c27.27-17.85,57.65-26.53,92.83-26.53,6.4,0,12.88.29,19.45.83,29.94,2.64,61.62,6.4,90.6,20.91,38.9,19.1,68.48,47.85,87.95,85.46,3.35,6.38,6.46,12.89,9.57,19.38l.99,2.06Z"
            />
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M62.39,346.45c.66-2.94,1.35-5.86,2.32-8.71,24.84-73.67,82.43-118.37,154.07-119.57l6.01-.05c20.15,0,43.44,1.91,63.37,16.06,26.72,19.12,33.2,48.07,36.02,73.88.17,1.41.14,3.32-.96,4.56-.97,1.09-2.51,1.31-3.63,1.31-1.54,0-3.35-.42-5.24-1.23-36.79-16.18-75.57-25.03-115.22-26.29-2.9-.1-5.78-.15-8.64-.15-53.22,0-93.12,17.45-122,53.33-1.78,2.23-3.73,4.32-5.7,6.45l-.38.42Z"
            />
          </G>
          <G>
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M932.44,326.88c-2.4-2.13-5.36-3.87-7.1-6.44-22.77-33.54-58.55-40.63-95.08-44.45-59.47-6.23-117.31,3.52-174.15,20.55-12.37,3.7-24.84,7.06-37.29,10.46-9.68,2.65-19.39-3.76-20.37-14.59-1.92-21.37.91-42.38,11.38-61.31,3.5-6.33,11.07-10.86,17.52-15.11,34.77-22.87,72.79-29.92,114.44-26.29,31.99,2.79,63.11,6.83,91.97,21.13,39.21,19.42,69.4,48.23,89.48,87.01,4.6,8.88,8.79,17.96,13.17,26.95-1.32.7-2.64,1.4-3.96,2.09ZM894.26,279.79c-14.29-21.39-31.53-40.52-53.4-52.88-64.2-36.29-130.58-37.94-197.88-7.06-30.2,13.86-36.46,39.75-36.85,68.64-.16,11.33,4.69,13.78,15.24,10.11,12.12-4.22,24.33-8.17,36.51-12.21,40.62-13.44,82.21-21.64,125.17-21.97,37.61-.28,74.83,2.43,111.22,15.37Z"
            />
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M57.9,350.36c1.13-4.61,1.93-9.32,3.44-13.8,24.81-73.36,82.4-120.66,157.4-121.89,24.55-.4,49.72,1.24,71.44,16.7,26.63,18.95,34.23,46.76,37.52,76.32.9,8.06-5.55,12.2-14.69,8.21-36.28-15.87-74.17-24.79-113.93-26.05-50.55-1.61-94.98,11.05-127.83,51.89-2.97,3.69-6.33,7.08-9.51,10.61-1.28-.66-2.55-1.32-3.83-1.99ZM84.29,309.1c77.26-48.94,154.77-29.91,234.36-1.78-2.93-10.15-5.03-18.82-7.94-27.22-10.87-31.4-32.04-50.04-66.36-53.95-37.13-4.23-71.15,3.41-103.49,21.46-25.66,14.32-42.87,35.84-56.56,61.49Z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
FaceStyleFourSvg.displayName = 'FaceStyleFourSvg';
