import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { IScreenplayActionPlayer } from './screenplayer.model';

export class NoOpActionPlayer implements IScreenplayActionPlayer {
  public static readonly Type = 'NOOP';

  public readonly type = NoOpActionPlayer.Type;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public cancel(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public pause(): void {}

  public async play(action: IScreenplayAction): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('Screenplayer NoOpActionPlayer: ', action);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public resume(): void {}
}
