import {
  IYesNo,
  TaskTypeName,
  YesNoChoiceType
} from '@lexialearning/lobo-common/main-model';
import { cloneDeep, sample } from 'lodash';
import { EncounterContext } from 'curriculum-services';
import { ITaskRegistration, TaskRegistrationBuilder } from '../../core';
import {
  ChoiceHelper,
  IChoiceAnswer
} from '../../shared/choice-helper/ChoiceHelper';
import { YesNo } from './YesNo';

export function createYesNoTaskRegistration(): ITaskRegistration<
  TaskTypeName.YesNo,
  IYesNo,
  IChoiceAnswer
> {
  const base = TaskRegistrationBuilder.create<TaskTypeName.YesNo, IYesNo>(
    TaskTypeName.YesNo,
    YesNo
  ).withPrepareContent(addChoiceVoiceovers).registration;

  return {
    ...base,
    serializeAnswer: ChoiceHelper.serializeAnswer
  };
}

function addChoiceVoiceovers(
  content: IYesNo,
  context: EncounterContext
): IYesNo {
  const contentCopy = cloneDeep(content);
  const yesVoiceover = sample(context.act.character.yesVoiceover);
  const noVoiceover = sample(context.act.character.noVoiceover);

  const choices = contentCopy.choices.map(c => ({
    ...c,
    card: {
      ...c.card,
      voiceover:
        c.yesNoType === YesNoChoiceType.Yes ? yesVoiceover : noVoiceover
    }
  }));

  return {
    ...contentCopy,
    choices
  };
}
