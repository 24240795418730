import { Overflow } from '@lexialearning/common-ui';
import { Direction, Color } from 'common-styles';

export class LevelCertificateCarouselModalStyles {
  public static readonly SlideWidth = 800;

  private static readonly SlideHeight = 600;

  private static readonly styles = {
    carouselOverrides: {
      arrowButtonSvg: {
        height: 54,
        width: 64
      },
      container: {
        height: LevelCertificateCarouselModalStyles.SlideHeight,
        margin: 15,
        width: LevelCertificateCarouselModalStyles.SlideWidth
      },
      controlContainerLeft: {
        left: -85
      },
      controlContainerLeftDisabled: {
        opacity: 0
      },
      controlContainerRight: {
        right: -85
      },
      controlContainerRightDisabled: {
        opacity: 0
      },
      scrollContainer: {
        backgroundColor: Color.NearWhite,
        borderRadius: 10,
        height: LevelCertificateCarouselModalStyles.SlideHeight + 20,
        overflow: Overflow.Hidden,
        padding: 10,
        width: LevelCertificateCarouselModalStyles.SlideWidth + 20
      }
    },
    closeButton: { right: 10, top: 10 },
    image: {
      flexDirection: Direction.Column,
      height: LevelCertificateCarouselModalStyles.SlideHeight,
      overflow: Overflow.Visible,
      width: LevelCertificateCarouselModalStyles.SlideWidth
    }
  };

  public static get() {
    return this.styles;
  }
}
