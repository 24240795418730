import { AvatarEditorActionShowEditor } from '../AvatarEditor.action';
import { LoboLogItemCategory } from '@lexialearning/lobo-common/main-model/logging';
import { StateObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { AppState } from 'services';

import { AvatarEditorActionType } from '../avatar-editor-redux.model';

export interface IHandleSaveAvatarEpicDeps {
  logger: ILogger;
}

export function logAvatarEditorEntryEpic(
  action$: Observable<AvatarEditorActionShowEditor>,
  _state$: StateObservable<AppState>,
  deps: IHandleSaveAvatarEpicDeps
): Observable<void> {
  return action$.pipe(
    ofType(AvatarEditorActionType.ShowEditor),
    tap(() => {
      void deps.logger.log({
        category: LoboLogItemCategory.AvatarEditorEntry,
        loggingLevel: LoggingLevel.Info,
        payload: {},
        summary: 'Student entered the Avatar Editor'
      });
    }),
    ignoreElements()
  );
}
logAvatarEditorEntryEpic.displayName = 'logAvatarEditorEntryEpic';
