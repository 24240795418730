import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';
import { AnimationDefinitionFactory } from 'storm/animations';
import {
  CalibrationSceneAnimationName,
  CalibrationSceneElementName
} from './calibration-scene.model';

export class CalibrationSceneDefinitionBuilder {
  public static readonly ScenePath = '/scenes/calibration/calibration_root.sg';

  public static create(): CalibrationSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Calibration,
      this.ScenePath
    );

    return new CalibrationSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}

  public withGabber() {
    const placeholder = CalibrationSceneElementName.Gabber;
    const path = '/scenes/calibration/gabber.sg';
    const animations = this.buildGabberAnimations(placeholder);
    const gabber = SceneDefinitionBuilder.create(placeholder, path, {
      animations
    }).definition;

    this.definition.elements.push(gabber);

    return this;
  }

  private buildGabberAnimations(placeholder: string): IAnimationDefinition[] {
    const animationNames = [
      CalibrationSceneAnimationName.Gabber.Idle,
      CalibrationSceneAnimationName.Gabber.Negative,
      CalibrationSceneAnimationName.Gabber.Positive,
      CalibrationSceneAnimationName.Gabber.TooLoud,
      CalibrationSceneAnimationName.Gabber.TooQuiet
    ];

    return animationNames.map(animName =>
      AnimationDefinitionFactory.create(animName, {
        autoplay: true,
        loop: true,
        path: `/scenes/calibration/gabber-animations/${animName}.sga`,
        targetElements: [placeholder]
      })
    );
  }
}
