import { Overflow, Position } from '@lexialearning/common-ui';
import { Align, Color } from 'common-styles';
import { merge } from 'lodash';
import memoizeOne from 'memoize-one';

export interface IModalContainerStyleOverride {
  modalContainer?: {
    borderBottomWidth?: number;
  };
}

export class ModalContainerStyles {
  private static readonly styles = {
    modalContainer: {
      alignItems: Align.Stretch,
      backgroundColor: Color.NearWhite,
      borderColor: Color.Gold,
      borderRadius: 10,
      borderWidth: 3,
      overflow: Overflow.Hidden,
      position: Position.Relative
    }
  };

  public static readonly build = memoizeOne(
    (styleOverride?: IModalContainerStyleOverride) =>
      merge({}, this.styles, styleOverride)
  );
}
