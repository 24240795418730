export interface IAvatar {
  faceStyle?: FaceStyle;
  skinColor: SkinColor;
  hairStyle: HairStyle;
  hairColor: HairColor;
}

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum FaceStyle {
  One = 'faceStyleOne',
  Two = 'faceStyleTwo',
  Three = 'faceStyleThree',
  Four = 'faceStyleFour'
}

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum HairStyle {
  LongStraight = 'l1',
  LongWavy = 'l2',
  LongCurly = 'l3',
  LongSmallBraids = 'l4',
  ShortStraight = 's1',
  ShortWavy = 's2',
  ShortCurly = 's3',
  CurlyAboveEars = 's4',
  Buzz = 'buzz',
  Hijab = 'hijab'
}

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum SkinColor {
  Light = 'light',
  MediumLight = 'mediumLight',
  MediumDark = 'mediumDark',
  Dark = 'dark'
}

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum HairColor {
  Blond = 'blond',
  LightBrown = 'lightBrown',
  DarkBrown = 'darkBrown',
  Black = 'black'
}

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum HijabColor {
  Pink = 'pink',
  Yellow = 'yellow',
  Green = 'green',
  Purple = 'purple'
}
