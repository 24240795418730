import { StateObservable } from 'redux-observable';
import {
  ActivityPositionAction,
  IActivityPosition,
  ProgramContextService,
  UnitAction
} from 'curriculum-services';
import { EncounterSceneAction } from 'feature-areas/encounters';
import { AppShellAction } from 'feature-areas/shell';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes } from 'services/storm-lobo';
import { SpinnerHandler } from 'spinner-handler';
import { TransitionScreenplayBuilderBase } from '../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../transition.model';

export class EducatorToEncounterTransitionBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'EducatorToEncounterTransitionBuilder';

  public static create(): EducatorToEncounterTransitionBuilder {
    return new EducatorToEncounterTransitionBuilder();
  }

  private constructor() {
    super(TransitionScreenplayId.EducatorToEncounter);
  }

  public setActivityPosition(
    activityPosition: IActivityPosition
  ): EducatorToEncounterTransitionBuilder {
    this.builder.addReduxAction(
      ActivityPositionAction.selected(activityPosition)
    );

    return this;
  }

  public awaitLevelLoaded(
    programContextService: ProgramContextService,
    state$: StateObservable<unknown>
  ): EducatorToEncounterTransitionBuilder {
    super.awaitContentLoaded(programContextService, state$, {
      desiredReadiness: { level: true },
      suppressSpinnerDismiss: true
    });

    return this;
  }

  public prepareEncounterScenes(
    preparedScenes: PreparedScenes
  ): EducatorToEncounterTransitionBuilder {
    this.builder
      .addCallback(() => {
        // TODO: @jv we are 'borrowing' the yellow background state of the introduction scene
        // to hide the unit scene when navigating in educator mode (LOBO-11367)
        // Consider revisiting and implement a way to handle this better
        preparedScenes.introduction?.show();
      })
      .addReduxAction(EncounterSceneAction.prepare());

    return this;
  }

  public loadUnit(): EducatorToEncounterTransitionBuilder {
    this.builder.addReduxAction(UnitAction.load.request());

    return this;
  }

  public awaitContentLoaded(
    programContextService: ProgramContextService,
    state$: StateObservable<unknown>
  ) {
    super.awaitContentLoaded(programContextService, state$, {
      suppressSpinner: true
    });

    return this;
  }

  public closeActivitySelectionModal(): EducatorToEncounterTransitionBuilder {
    this.builder.addReduxAction(AppShellAction.hideModal());

    return this;
  }

  public navToRound(
    unitId: string,
    roundId: string
  ): EducatorToEncounterTransitionBuilder {
    this.builder.addCallback(() => {
      const roundsRoute = RouteBuilder.rounds(unitId, roundId);
      RouterService.history.replace(roundsRoute);
      SpinnerHandler.dismissSpinner();
    });

    return this;
  }
}

export enum EducatorToEncounterTransitionBuilderError {
  InvalidStudentOpInProd = 'InvalidStudentOpInProd'
}
