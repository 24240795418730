import * as React from 'react';
import { ObjectLabel, ObjectRootLabel } from 'react-inspector';
import { PressableView } from 'common-ui';
import { ConfigNodeStyles } from './ConfigNode.styles';

type InspectorNodeProps = typeof ObjectLabel.prototype.props;
export interface IConfigNodeProps extends InspectorNodeProps {
  path: string;
  onPress(key: string, value: boolean | string | number): void;
}
interface IConfigNodeState {
  isMouseOver: boolean;
}

export class ConfigNode extends React.PureComponent<
  IConfigNodeProps,
  IConfigNodeState
> {
  public static readonly displayName = 'ConfigNode';

  private readonly editable: boolean;

  constructor(props: IConfigNodeProps) {
    super(props);

    this.state = { isMouseOver: false };
    this.editable = typeof this.props.data !== 'object';

    this.handleHoverStart = this.handleHoverStart.bind(this);
    this.handleHoverEnd = this.handleHoverEnd.bind(this);
    this.handlePress = this.handlePress.bind(this);
  }

  private handleHoverStart() {
    if (this.editable) {
      this.setState({ isMouseOver: true });
    }
  }

  private handleHoverEnd() {
    if (this.editable) {
      this.setState({ isMouseOver: false });
    }
  }

  private handlePress() {
    if (this.editable) {
      const { path, data } = this.props;
      this.props.onPress(path.replace('$.', ''), data);
    }
  }

  public render() {
    const { depth, name, data, isNonenumerable } = this.props;
    const styles = ConfigNodeStyles.build(this.state.isMouseOver);

    return (
      <PressableView
        onPress={this.handlePress}
        onHoverStart={this.handleHoverStart}
        onHoverEnd={this.handleHoverEnd}
        style={styles.container}
      >
        {depth === 0 ? (
          <ObjectRootLabel name={name} data={data} />
        ) : (
          <ObjectLabel
            name={name}
            data={data}
            isNonenumerable={isNonenumerable}
          />
        )}
      </PressableView>
    );
  }
}
