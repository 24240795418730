import * as React from 'react';
import { ITextPrompt, ThemeSize } from '@lexialearning/lobo-common';
import {
  IResponseSpeechBubbleAnimatedStyles,
  ResponseSpeechBubble
} from '../shared/speech-bubble/ResponseSpeechBubble';
import { ISpeechBubbleCommonProps } from '../speech-bubbles.model';
import {
  IQuestionSpeechBubbleAnimatedStyles,
  QuestionSpeechBubble
} from './QuestionSpeechBubble';

export interface IQuestionAndAnswerBubblesAnimatedStyles {
  question: IQuestionSpeechBubbleAnimatedStyles;
  response: IResponseSpeechBubbleAnimatedStyles;
}

export interface IQuestionAndAnswerSpeechBubblesProps
  extends ISpeechBubbleCommonProps {
  animatedStyles: IQuestionAndAnswerBubblesAnimatedStyles;
  hasOnscreenCharacter: boolean;
  textPromptArray: ITextPrompt[];
  themeSize: ThemeSize;
}

export function QuestionAndAnswerSpeechBubbles(
  props: IQuestionAndAnswerSpeechBubblesProps
): React.ReactElement {
  const {
    animatedStyles,
    hasOnscreenCharacter,
    textPromptArray,
    ...restProps
  } = props;

  return (
    <>
      <QuestionSpeechBubble
        animatedStyles={animatedStyles.question}
        hasTailLeft={hasOnscreenCharacter}
        textPromptArray={textPromptArray}
      />
      <ResponseSpeechBubble
        {...restProps}
        animatedStyles={animatedStyles.response}
        testId={QuestionAndAnswerSpeechBubbles.displayName}
      />
    </>
  );
}
QuestionAndAnswerSpeechBubbles.displayName = 'QuestionAndAnswerSpeechBubbles';
