import {
  IModeling,
  IRound,
  TaskAudioSupport,
  TaskPhase
} from '@lexialearning/lobo-common';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RouterService } from 'router-service';
import { InteractionState } from 'common-styles';
import { ScreenplayActionType, Screenplayer } from 'screenplay';
import { RoundSelector } from 'services/curriculum-services';
import { SreSelector, SreSessionActionType, SreSessionType } from 'sre';
import {
  ModelingActionType,
  ModelingSelector
} from 'task-components/core/modeling';
import { TaskActionType, TaskSelector } from 'task-components/core/redux';
import { ChoralScreenplayInfo } from 'task-components/see-speak';
import {
  AppShellAction,
  AppShellActionSetUserControlInteractionState,
  ISetUserControlInteractionStateActionPayload
} from '../AppShell.action';
import { AppShellSelector } from '../AppShell.selector';
import { AppShellActionType } from '../AppShellActionType';

export interface IUpdateUserControlInteractionStateEpicDep {
  screenplayer: Screenplayer;
}

export const UpdateUserControlInteractionStateInputActionTypes = [
  AppShellActionType.DisableAudioSupport,
  AppShellActionType.EnableAudioSupport,
  AppShellActionType.ShowModal,
  AppShellActionType.HideModal,
  TaskActionType.Loaded,
  TaskActionType.Entry,
  TaskActionType.Preamble,
  TaskActionType.Interactive,
  TaskActionType.Evaluated,
  TaskActionType.Feedback,
  TaskActionType.Solution,
  TaskActionType.Exit,
  ModelingActionType.SetModeling,
  ScreenplayActionType.Canceled,
  ScreenplayActionType.Play,
  ScreenplayActionType.Playing,
  ScreenplayActionType.PlayComplete,
  SreSessionActionType.CancelSuccess,
  SreSessionActionType.Listening,
  SreSessionActionType.Finish
];

/**
 * Applies only for rounds
 * Outside of rounds, the interaction state is updated via updateMicInteractionState.epic.ts
 * and/or direct dispatches of AppShellAction.setUserControlInteractionState
 */
export function updateUserControlInteractionStateEpic(
  action$: Observable<any>,
  state$: StateObservable<unknown>,
  deps: IUpdateUserControlInteractionStateEpicDep
): Observable<AppShellActionSetUserControlInteractionState> {
  return action$.pipe(
    // @ts-ignore: A spread argument must either have a tuple type or be passed to a rest parameter.  Function runs as expected
    ofType(...UpdateUserControlInteractionStateInputActionTypes),
    filter(() => RouterService.isOnRoundRoute()),
    map(() => getAudioSupportInteractionState(state$.value, deps.screenplayer)),
    filter(payload => {
      const priorState = AppShellSelector.getUserControlInteractionState(
        state$.value
      );

      return !isEqual(payload, priorState);
    }),
    map(AppShellAction.setUserControlInteractionState)
  );
}
updateUserControlInteractionStateEpic.displayName =
  'updateUserControlInteractionStateEpic';

function getAudioSupportInteractionState(
  state: unknown,
  screenplayer: Screenplayer
): ISetUserControlInteractionStateActionPayload {
  const phase = TaskSelector.getPhase(state);
  const isListenToLF = SreSelector.getIsListeningTo(
    SreSessionType.LanguageFrame,
    state
  );
  const isPlayingChoralCountdown =
    screenplayer.isPlayingId(ChoralScreenplayInfo.ScreenplayId) &&
    !isListenToLF;
  const modeling = ModelingSelector.getState(state);
  const isAudioSupportDisabled = AppShellSelector.isAudioSupportDisabled(state);
  const round = RoundSelector.getRoundMaybe(state);

  const isPlayingDirection = screenplayer.isPlayingId(
    TaskAudioSupport.Direction
  );
  const isPlayingPresentation = screenplayer.isPlayingId(
    TaskAudioSupport.Presentation
  );
  const isPlayingLookBack = screenplayer.isPlayingId(TaskAudioSupport.LookBack);

  return getAudioSupportInteractionStateMemoized(
    modeling,
    isAudioSupportDisabled,
    isListenToLF,
    phase,
    round,
    isPlayingChoralCountdown,
    isPlayingDirection,
    isPlayingPresentation,
    isPlayingLookBack
  );
}

const getAudioSupportInteractionStateMemoized = memoizeOne(
  (
    modeling: IModeling | undefined,
    isAudioSupportDisabled: boolean,
    isListenToLF: boolean,
    phase: TaskPhase,
    round: IRound | undefined,
    isPlayingChoralCountdown: boolean,
    isPlayingDirection: boolean,
    isPlayingPresentation: boolean,
    isPlayingLookBack: boolean
  ): ISetUserControlInteractionStateActionPayload => {
    const commonAudioSupportInteractionState =
      getCommonAudioSupportInteractionStateMemoized(
        isListenToLF,
        isAudioSupportDisabled,
        phase,
        isPlayingChoralCountdown
      );

    const presentationScreenplay = round?.presentation;
    const directionsScreenplay = round?.direction;
    const lookBackScreenplay = round?.lookBack;

    const hasPresentation =
      !!presentationScreenplay ||
      !!(modeling && modeling.presentation.items.length > 0);
    const hasDirections =
      !!directionsScreenplay ||
      !!(modeling && modeling.direction.items.length > 0);
    const hasLookBack = !!lookBackScreenplay;

    const directions = !hasDirections
      ? InteractionState.Disabled
      : isPlayingDirection
      ? InteractionState.Highlighted
      : commonAudioSupportInteractionState;

    const presentation = !hasPresentation
      ? InteractionState.Disabled
      : isPlayingPresentation
      ? InteractionState.Highlighted
      : commonAudioSupportInteractionState;

    const lookBack = !hasLookBack
      ? InteractionState.Disabled
      : isPlayingLookBack
      ? InteractionState.Highlighted
      : commonAudioSupportInteractionState;

    return {
      directions,
      lookback: lookBack,
      presentation
    };
  }
);

const getCommonAudioSupportInteractionStateMemoized = memoizeOne(
  (
    isListenToLF: boolean,
    disableAudioSupport: boolean,
    phase: TaskPhase,
    isPlayingChoralCountdown: boolean
  ) => {
    if (disableAudioSupport || isListenToLF || isPlayingChoralCountdown) {
      return InteractionState.Disabled;
    }

    if (phase && phase === TaskPhase.Interactive) {
      return InteractionState.Default;
    }

    return InteractionState.Disabled;
  }
);
