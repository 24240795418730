import {
  IAct,
  IEncounter,
  ILevel,
  IProgramPosition,
  IUnitSnipped
} from '@lexialearning/lobo-common';
import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { ProgramMode } from 'curriculum-services';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum AncestorIndex {
  Level = 0,
  Activity = 1,
  Encounter = 2,
  MainUnit = 3,
  MainRound = 4,
  InstructionSubunit = 5,
  InstructionRound = 6,
  ForkTask = 5,
  ForkSubunit = 6,
  ForkRound = 7,
  ForkedInstructionSubunit = 8,
  ForkedInstructionRound = 9
}

export enum SubjectType {
  MainUnit = 'MainUnit',
  MainRound = 'MainRound',
  InstructionSubunit = 'InstructionSubunit',
  InstructionRound = 'InstructionRound',
  ForkSubunit = 'ForkSubunit',
  ForkRound = 'ForkRound',
  ForkedInstructionSubunit = 'ForkedInstructionSubunit',
  ForkedInstructionRound = 'ForkedInstructionRound',

  OnboardingRound = 'OnboardingRound',
  OnboardingUnit = 'OnboardingUnit',
  PlacementRound = 'PlacementRound',
  PlacementUnit = 'PlacementUnit',

  Unknown = 'Unknown'
}

export interface IDeepLinkPositionInfo {
  mode: ProgramMode;
  position: IProgramPosition;
}

export interface IDeepLinkSubject {
  expectedAncestry: LoboContentType[];
  id: string;
  programMode: ProgramMode;
  type: SubjectType;
}

export interface IActiveBranch {
  level: ILevel;
  activity: IAct;
  encounter: IEncounter;
  mainUnit: IUnitSnipped;
  mainRoundId?: string;
}

export interface IDeepLinkContentIds {
  level: string;
  activity: string;
  encounter: string;
  mainUnit?: string;
  mainRound?: string;
  instructionSubunit?: string;
  instructionRound?: string;
  forkSubunit?: string;
  forkRound?: string;
  forkedInstructionSubunit?: string;
  forkedInstructionRound?: string;
}
