import * as React from 'react';
import { AnimatedView, Row, Types } from 'common-ui';
import { TextSegment } from 'task-components/shared';
import { ThemeSize, withTheme } from 'theme';
import { LineBackgroundAnimatedStyles } from './LineBackground.animated-styles';
import { DashedLinesSvg } from './svgs/DashedLines.lx-svg';

interface ILineBackgroundProps {
  themeSize: ThemeSize;
  showTextPill: boolean;
  lineLayout: Types.LayoutInfo;
}

/**
 * Line background consist of dashed line that is displayed under the line, and TextSegment that
 * spans the whole line width. TextSegment is only visible when positive feedback is given, when
 * text segments are displayed as one 'fuzed' text segment per line.
 */
class LineBackgroundComponent extends React.PureComponent<ILineBackgroundProps> {
  private readonly lineBackgroundAnimatedStyles: LineBackgroundAnimatedStyles;

  constructor(props: ILineBackgroundProps) {
    super(props);

    this.lineBackgroundAnimatedStyles = new LineBackgroundAnimatedStyles();
  }

  public componentDidUpdate(prevProps: ILineBackgroundProps) {
    const { showTextPill } = this.props;
    const { showTextPill: showTextPillAnimation } =
      this.lineBackgroundAnimatedStyles.getAnimations();

    if (!prevProps.showTextPill && showTextPill) {
      showTextPillAnimation.start();
    }
  }

  public render() {
    const { themeSize, showTextPill, lineLayout, ...restProps } = this.props;
    const styles = this.lineBackgroundAnimatedStyles.build(lineLayout);

    return (
      <>
        <Row style={styles.dashedLineContainer} {...restProps}>
          <DashedLinesSvg themeSize={themeSize} />
        </Row>
        <AnimatedView
          style={styles.textPillContainer}
          animatedStyle={styles.textPillContainerAnimated}
        >
          <TextSegment width={lineLayout.width} hasShadow />
        </AnimatedView>
      </>
    );
  }
}

export const LineBackground = withTheme(LineBackgroundComponent);
