import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import {
  Align,
  Color,
  Direction,
  Font,
  Justify,
  TextAlign,
  TextDecorationLine,
  TextDecorationStyle
} from 'common-styles';
import { DevToolsPosition } from './redux/IDevToolsState';
import { Border, FlexWrap, Position } from '@lexialearning/common-ui';

export class DevToolsStyles {
  private static readonly buttonOverrides = {
    button: {
      borderColor: Color.Gray30,
      borderStyle: Border.Solid,
      borderWidth: 1,
      marginHorizontal: 0,
      marginTop: 10,
      minWidth: 100,
      paddingLeft: 5,
      // Bc this is for an override, it needs Vertical, R and L styles separate as those are what are being overriden
      paddingRight: 5,
      paddingVertical: 5,
      shadowOffset: { height: 0, width: 0 },
      shadowRadius: 2
    },
    buttonText: {
      color: Color.Text,
      fontSize: Font.Size.xSmall
    }
  };

  public static styles = {
    buttonOverrides: DevToolsStyles.buttonOverrides,
    checkboxOverrides: {
      label: {
        fontSize: Font.Size.xSmall
      }
    },
    checkboxRow: {
      alignItems: Align.Start,
      justifyContent: Justify.Start,
      marginLeft: -5,
      marginVertical: 5
    },
    closeIcon: {
      fontFamily: Font.Family.semiBold,
      fontSize: 20,
      marginLeft: 25
    },
    closeLink: {
      color: Color.Blue,
      fontSize: Font.Size.small,
      textDecorationLine: TextDecorationLine.Underline
    },
    contentBlock: {
      alignItems: Align.Start,
      borderBottomColor: Color.Gray40,
      borderBottomWidth: 2,
      borderColor: Color.Gray20,
      borderRadius: 8,
      borderRightColor: Color.Gray40,
      borderStyle: Border.Solid,
      borderWidth: 1,
      height: 170,
      justifyContent: Justify.Start,
      margin: 5,
      padding: 10
    },
    contentBlockLabel: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small
    },
    contentItem: {
      flexWrap: FlexWrap.Wrap,
      justifyContent: Justify.Start
    },
    contentItemBullet: {
      backgroundColor: Color.Black,
      borderRadius: 3,
      height: 6,
      marginRight: 5,
      width: 6
    },
    contentItemLabel: {
      fontFamily: Font.Family.semiBold,
      fontSize: 14,
      minWidth: 35
    },
    contentItemLink: {
      color: Color.DeepBlue,
      fontSize: 14,
      textDecorationLine: TextDecorationLine.Underline,
      textDecorationStyle: TextDecorationStyle.Solid
    },
    contentItemValue: {
      fontSize: 14
    },
    contentItemsList: {
      alignItems: Align.Start
    },
    footer: {
      alignItems: Align.End,
      justifyContent: Justify.SpaceBetween
    },
    header: {
      alignSelf: Align.Stretch,
      backgroundColor: Color.LightBlue,
      paddingBottom: 3,
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 3
    },
    headerText: {
      flex: 1,
      fontSize: Font.Size.small,
      textAlign: TextAlign.Left
    },
    infoBoxes: {
      alignItems: Align.Start,
      justifyContent: Justify.Start
    },
    linkColumn: {
      alignItems: Align.Start
    },
    positionButton: {
      marginLeft: 10
    },
    positionIcons: Object.keys(DevToolsPosition).reduce((a, p) => {
      a[p] = {
        borderStyle: Border.Solid,
        borderWidth: 2,
        height: 20,
        width: 20,
        [`border${p}Width`]: 5
      };

      return a;
    }, {}),
    roundInfoBox: {
      width: 300
    },
    settingsBlockLabel: {
      fontFamily: Font.Family.semiBold,
      fontSize: 16
    },
    shortcutsBox: {
      height: undefined,
      width: 610
    },
    shortcutsButtonOverrides: merge({}, DevToolsStyles.buttonOverrides, {
      button: {
        marginRight: 10
      }
    }),
    title: {
      fontFamily: Font.Family.semiBold
    },
    unitInfoBox: {
      width: 300
    }
  };

  public static readonly build = memoizeOne(
    (position = DevToolsPosition.Top, appHeight = 0) => ({
      ...DevToolsStyles.styles,
      container: {
        alignItems: Align.Stretch,
        backgroundColor: Color.NearWhite,
        borderColor: Color.Gray20,
        borderStyle: Border.Solid,
        borderWidth: 1,
        bottom: 0,
        justifyContent: Justify.Start,
        left: 0,
        minHeight: 200,
        minWidth: 200,
        position: Position.Absolute,
        right: 0,
        top: 0,
        ...(position === DevToolsPosition.Top && { bottom: undefined }),
        ...(position === DevToolsPosition.Bottom && {
          bottom: -appHeight,
          top: undefined
        }),
        ...(position === DevToolsPosition.Left && {
          height: appHeight,
          right: undefined
        }),
        ...(position === DevToolsPosition.Right && {
          height: appHeight,
          left: undefined
        })
      },
      content: {
        alignItems: Align.Stretch,
        flex: 1,
        flexWrap: FlexWrap.Wrap,
        justifyContent: Justify.Start,
        padding: 10,
        ...((position === DevToolsPosition.Left ||
          position === DevToolsPosition.Right) && {
          flexDirection: Direction.Column
        })
      },
      settingsBlock: {
        borderWidth: 2,
        height: 300,
        ...((position === DevToolsPosition.Left ||
          position === DevToolsPosition.Right) && {
          marginBottom: 40
        }),
        ...((position === DevToolsPosition.Top ||
          position === DevToolsPosition.Bottom) && {
          marginRight: 40
        })
      }
    })
  );
}
