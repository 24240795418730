import { Sfx } from 'audio';

export const STORM_CONFIG_KEY = 'storm';

export interface IStormConfig<
  A extends IStormAssetFolders = IStormAssetFolders
> {
  assetPaths: A;
  sfx: ISfxConfig[];
}

export interface IMountPoint {
  dir: string;
  name: string;
  webOrigin: string;
}

export interface IStormAssetFolders {
  Music: IStormAssetPath;
  Sfx: IStormAssetPath;
  Voiceovers: IStormAssetPath;
}

export interface IStormAssetPath {
  path: string;
  items?: string[];
}

export interface ISfxConfig {
  /**
   * path or name of sfx. Path should be absolute and may include a .opus
   * extension. Otherwise, it is assumed to be a name, which will be
   * converted to a standard path.
   * @see LxStormAudioPlayer.play
   */
  path: Sfx;
}
