import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, Justify } from 'common-styles';

export interface ITitlePillStyleOverrides {
  container?: {
    marginTop?: number;
    flex?: number;
  };
  pillOutline?: {
    minWidth?: number;
  };
  pill?: {
    minWidth?: number;
    paddingHorizontal?: number;
  };
  lineLeft?: {
    width?: number;
    flex?: number;
  };
}
export class TitlePillStyles {
  public static lineStyles = {
    borderColor: Color.NearWhite,
    borderWidth: 0,
    borderBottomWidth: 2,
    flex: 1
  };

  private static readonly styles = {
    container: {
      alignItems: Align.Center,
      height: 60
    },
    lineLeft: {
      ...TitlePillStyles.lineStyles
    },
    lineRight: {
      ...TitlePillStyles.lineStyles
    },
    pill: {
      alignItems: Align.Center,
      backgroundColor: Color.NearWhiteTransparent40,
      borderRadius: 25,
      height: 50,
      justifyContent: Justify.Center,
      paddingHorizontal: 2,
      paddingVertical: 5
    },
    pillOutline: {
      backgroundColor: Color.Transparent,
      borderColor: Color.NearWhite,
      borderRadius: 30,
      borderWidth: 2,
      padding: 3
    }
  };

  public static readonly build = memoizeOne(
    (styleOverrides?: ITitlePillStyleOverrides) =>
      merge({}, TitlePillStyles.styles, styleOverrides)
  );
}
