import { BackgroundImage, Row } from 'common-ui';
import { IPickCount } from '..';
import { Pick2Svg, Pick3Svg, Pick4Svg } from '../svg';
import { GabberPromptStyles } from './GabberPrompt.styles';
import GabberOrangePng from './images/GabberOrange.png';

export function GabberPrompt(props: IPickCount) {
  const { pickCount } = props;
  const styles = GabberPromptStyles.get();

  const PickSvg =
    pickCount === 2 ? Pick2Svg : pickCount === 3 ? Pick3Svg : Pick4Svg;

  return (
    <Row style={styles.container}>
      <BackgroundImage source={GabberOrangePng} style={styles.gabber} />
      <PickSvg />
    </Row>
  );
}
GabberPrompt.displayName = 'GabberPrompt';
