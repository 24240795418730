import {
  IModeling,
  ITask,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { createSelector } from '@reduxjs/toolkit';
import { InteractionState } from 'common-styles';
import { ModelingSelector } from '../modeling/Modeling.selector';
import { ITaskBasicInfo } from '../task-component.model';
import { ITaskState } from './task-redux.model';

export class TaskSelector {
  public static readonly displayName = 'TaskSelector';

  public static getBasicInfoForComponent: (
    state: unknown
  ) => ITaskBasicInfo | undefined;

  public static getCanSubmit: (state: unknown) => boolean;

  public static getModeling: (state: unknown) => IModeling | undefined;

  public static getPhase: (state: unknown) => TaskPhase;

  public static getSelectedChoice: (state: unknown) => string;

  public static getSelectedChoices: (state: unknown) => string[];

  public static getShowSolution: (state: unknown) => boolean;

  public static getTaskContent: (state: unknown) => ITask;

  public static getTaskContentMaybe: (state: unknown) => ITask | undefined;

  public static createSelectors(selector: (state: any) => ITaskState) {
    this.getBasicInfoForComponent = createSelector(
      selector,
      (state: ITaskState) =>
        state.taskContent && {
          isInteractive: state.phase === TaskPhase.Interactive,
          phase: state.phase,
          taskContent: state.taskContent
        }
    );
    this.getCanSubmit = createSelector(
      selector,
      (state: ITaskState) => state.canSubmit
    );
    this.getModeling = createSelector(
      selector,
      (state: ITaskState) => state.modeling
    );
    this.getPhase = createSelector(
      selector,
      (state: ITaskState) => state.phase
    );
    this.getSelectedChoices = createSelector(
      selector,
      (state: ITaskState) => state.selectedChoices || []
    );
    this.getSelectedChoice = createSelector(
      this.getSelectedChoices,
      (selected: string[]) => (selected && selected[0]) || ''
    );
    this.getShowSolution = createSelector(
      selector,
      (state: ITaskState) => state.showSolution
    );
    this.getTaskContentMaybe = createSelector(
      selector,
      (state: ITaskState) => state.taskContent
    );
    this.getTaskContent = createSelector(
      (state: unknown) => TaskSelector.getTaskContentMaybe(state),
      (taskContent: ITask | undefined) => {
        if (!taskContent) {
          throw new LexiaError(
            'Redux task state lacks task content',
            TaskSelector.displayName,
            TaskSelectorError.TaskMissing
          );
        }

        return taskContent;
      }
    );

    ModelingSelector.createSelectors(TaskSelector.getModeling);
  }

  public static getFeedbackInteractionState(
    result: TaskEvaluationResult | undefined
  ) {
    switch (result) {
      case TaskEvaluationResult.Correct:
        return InteractionState.Correct;
      case TaskEvaluationResult.Incorrect:
        return InteractionState.Incorrect;
      case TaskEvaluationResult.Inconclusive:
        return InteractionState.Inconclusive;
      default:
        return InteractionState.Default;
    }
  }
}

export enum TaskSelectorError {
  TaskMissing = 'TaskMissing'
}
