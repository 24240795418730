export enum ActivityPositionActionType {
  Adjusted = 'ACTIVITY_POSITION.ADJUSTED',
  Change = 'ACTIVITY_POSITION.CHANGE',
  Changed = 'ACTIVITY_POSITION.CHANGED',
  EncounterSelected = 'ACTIVITY_POSITION.SELECT_ENCOUNTER',
  Prepared = 'ACTIVITY_POSITION.PREPARED',
  Selected = 'ACTIVITY_POSITION.SELECTED',
  Skip = 'ACTIVITY_POSITION.SKIP',
  SkipPrepared = 'ACTIVITY_POSITION.SKIP_PREPARED'
}
