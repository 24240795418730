import {
  IPlacement,
  IPlacementRule,
  IPlacementRuleWithPool,
  IPlacementStep,
  IPlacementStepWithPool,
  IPlacementWithPool
} from '@lexialearning/lobo-common';
import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { GradeName } from 'lexia-service';

export class PlacementTransformer {
  public static readonly displayName = 'PlacementTransformer';

  public static transform(
    gradeName: GradeName,
    placement: IPlacementWithPool | undefined
  ): IPlacement {
    if (!placement) {
      throw new LexiaError(
        'No placement content found!',
        PlacementTransformer.displayName,
        PlacementTransformerError.PlacementMissing
      ).withContext({ contentType: LoboContentType.Placement });
    }

    const { gradeFormPoolMap, ...rest } = placement;
    const placementFormWithPool = PlacementTransformer.getFormWithPool(
      gradeName,
      gradeFormPoolMap
    );
    const form = PlacementTransformer.transformFormOrStep(
      placementFormWithPool
    );

    return {
      form,
      ...rest
    };
  }

  private static getFormWithPool(
    gradeName: GradeName,
    gradeFormPoolMap: {
      k2: IPlacementStepWithPool[];
      g3Plus: IPlacementStepWithPool[];
    }
  ): IPlacementStepWithPool {
    const pool = [
      GradeName.PK,
      GradeName.K,
      GradeName.G1,
      GradeName.G2
    ].includes(gradeName)
      ? gradeFormPoolMap.k2
      : gradeFormPoolMap.g3Plus;

    // TODO: handle pool with length > 1
    const placementForm = pool?.[0];

    if (!placementForm) {
      throw new LexiaError(
        'No placement step content found!',
        PlacementTransformer.displayName,
        PlacementTransformerError.PlacementStepMissing
      ).withContext({ grade: gradeName, gradeFormPoolMap });
    }

    return placementForm;
  }

  /**
   * Transform IPlacementStepWithPool -> IPlacementStep
   * It calls transformRule on the rules field which can possibly call transFormStep on
   * IPlacementRuleWithPool's nextPlacementStepPool
   */
  private static transformFormOrStep(
    step: IPlacementStepWithPool
  ): IPlacementStep {
    const { unitPool, rules, ...rest } = step;
    const rulesTransformed = rules.map(r =>
      PlacementTransformer.transformRule(r)
    );
    // TODO: handle pool with length > 1
    const unit = step.unitPool[0];

    return {
      rules: rulesTransformed,
      unit,
      ...rest
    };
  }

  private static transformRule(rule: IPlacementRuleWithPool): IPlacementRule {
    const { nextPlacementStepPool, ...rest } = rule;
    // TODO: handle pool with length > 1
    const pooledStep = rule.nextPlacementStepPool?.[0];
    const nextPlacementStep = pooledStep
      ? this.transformFormOrStep(pooledStep)
      : undefined;

    return {
      nextPlacementStep,
      ...rest
    };
  }
}

export enum PlacementTransformerError {
  PlacementMissing = 'PlacementMissing',
  PlacementStepMissing = 'PlacementStepMissing'
}
