import { IScreenplay } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActUiSelector } from 'feature-areas/acts';
import { LevelScene } from 'feature-areas/levels';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import {
  PositionActionActivitySelected,
  PositionActionType,
  ProgramContextSelector,
  ProgressSelector
} from 'services/curriculum-services';
import { PreparedScenes } from 'services/storm-lobo';
import {
  ILevelToActTransitionScreenplayDeps,
  LevelToActTransitionScreenplayFactory
} from './LevelToActTransitionScreenplayFactory';
import { RouterService, RoutePath } from 'router-service';

export interface ILevelToActTransitionEpicDeps {
  preparedScenes: PreparedScenes;
}

export function levelToActTransitionEpic(
  action$: Observable<PositionActionActivitySelected>,
  state$: StateObservable<unknown>,
  deps: ILevelToActTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(PositionActionType.ActivitySelected),
    filter(() => RouterService.isOnRoute(RoutePath.Levels)),
    switchMap(async () => deps.preparedScenes.levelReady),
    filter(levelScene => levelScene.scene.active),
    map(levelScene => createActSelectionScreenplay(state$.value, levelScene)),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
levelToActTransitionEpic.displayName = 'levelToActTransitionEpic';

function createActSelectionScreenplay(
  state: unknown,
  levelScene: LevelScene
): IScreenplay {
  const context = ProgramContextSelector.getEncounterContext(state);
  const { act, encounter } = context;

  levelScene.prepareShowcase(act);
  const selectedActNumber = levelScene.getSelectedActNumber();

  const funFactData = ActUiSelector.getAvailableFunFactData(state);

  const screenplayBuilderDeps: ILevelToActTransitionScreenplayDeps = {
    act,
    actsEntered: ProgramContextSelector.getActsEntered(state),
    currentEncounter: context.findEncounterIndex() + 1,
    encounterId: encounter.sysId,
    encounterPercentCompleted: getEncounterPercentComplete(state, act.sysId),
    hasFunFact: funFactData.playbacksRemaining > 0,
    selectedActNumber
  };

  return LevelToActTransitionScreenplayFactory.createFor(screenplayBuilderDeps)
    .screenplay;
}

function getEncounterPercentComplete(state: unknown, actId: string) {
  const actProgress = ProgressSelector.getProgress(state).find(
    ap => ap.sysId === actId
  );
  const encounterPosition = actProgress?.encounterPositions.find(
    ep => !ep.isComplete
  );

  if (!encounterPosition) {
    throw new LexiaError(
      `No encounter position found for act ${actId}`,
      levelToActTransitionEpic.displayName,
      LevelToActTransitionEpicError.NoEncounterPosition
    ).withContext({ actId, actProgress });
  }

  return encounterPosition.percentComplete;
}

export enum LevelToActTransitionEpicError {
  NoEncounterPosition = 'NoEncounterPosition'
}
