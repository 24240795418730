import { LexiaError } from '@lexialearning/utils';
import { v4 as uuid } from 'uuid';
import { ReactAnimationActionPlayer } from 'screenplay';
import { ILogger } from '@lexialearning/main-model';

/**
 * Use this class to access dependencies outside of middleware
 * (and other locations where dependency injection is unavailable).
 *
 * DO NOT use this class from within packages/modules that are meant to be
 * shared outside of the lobo project.
 * DO NOT add services here unless they are really needed. We want to keep this
 * class as small as feasible.
 *
 * @see ServiceDependencies
 */
export class Services {
  public static readonly displayName = 'Services';

  public static logger: ILogger | undefined;

  /**
   * Defined during bootstrapping, and first used well after that,
   * so typing assumes this is defined
   */
  public static reactAnimationScreenplayer: ReactAnimationActionPlayer;
  public static sessionId = uuid();

  /**
   * Log error without throwing it
   * - Intended for minor errors where we do not want to blow up the UI (toss modal and restart app)
   * - But where we *do want some logging of the issue
   */
  public static logError(error: LexiaError): void {
    void Services.logger!.logError(error);
  }
}
