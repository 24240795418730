import { ActionType, createAction } from 'typesafe-actions';
import { LevelIntroSceneActionType } from './level-intro-scene-redux.model';

export const LevelIntroSceneAction = {
  prepare: createAction(
    LevelIntroSceneActionType.Prepare
  )<ILevelIntroSceneActionPreparePayload>()
};

export interface ILevelIntroSceneActionPreparePayload {
  isLevelToLevel: boolean;
}

export type LevelIntroScenePrepareAction = ActionType<
  typeof LevelIntroSceneAction.prepare
>;

export type LevelIntroSceneActions = ActionType<typeof LevelIntroSceneAction>;
