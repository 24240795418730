import { Column, Text } from 'common-ui';
import { ProductInfo } from '../product-info';
import { CopyrightStyles, ICopyrightStyleOverride } from './Copyright.styles';

export interface ICopyrightProps {
  styleOverride?: ICopyrightStyleOverride;
}

export function Copyright(props: ICopyrightProps) {
  const styles = CopyrightStyles.build(props.styleOverride);

  return (
    <Column style={styles.container}>
      <Text style={styles.text}>{ProductInfo.CopyRight}</Text>
    </Column>
  );
}
Copyright.displayName = 'Copyright';
