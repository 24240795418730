import { IMicrophone } from '@lexialearning/lobo-common/main-model/sre';
import * as React from 'react';
import { connect } from 'react-redux';
import { Color } from 'common-styles';
import { Row, TextButton } from 'common-ui';
import { ChangeMicModal } from 'feature-areas/calibration/mic-change';
import { AppShellAction } from 'feature-areas/shell';
import { RouterService, RoutePath } from 'router-service';
import { ScreenplaySelector } from 'screenplay';
import { AppState } from 'services';
import {
  IVUMeterStyleOverrides,
  MicEnergyMeter,
  VUMeter
} from 'shared-components';
import { SreSelector } from 'sre';
import { SystemInfo } from 'utils/SystemInfo';
import { StudentInfoSection } from '../student-info-section/StudentInfoSection';
import { ChangeIconSvg } from './ChangeIcon.lx-svg';
import { MicInfoStyles } from './MicInfo.styles';

export interface IMicInfoProps {
  autoFocus?: boolean;
  selectedMic?: IMicrophone;
  isScreenplayActive: boolean;
  hideModal(): void;
  openChangeMicModal(): void;
}
export class MicInfoComponent extends React.PureComponent<IMicInfoProps> {
  public static readonly displayName = 'MicInfo';

  constructor(props: IMicInfoProps) {
    super(props);

    this.handleChangePress = this.handleChangePress.bind(this);
  }

  private handleChangePress() {
    this.props.hideModal();
    // TODO: Remove setTimeout here once Numfum resolves: LOBO-18444
    setTimeout(() => {
      this.props.openChangeMicModal();
    }, 0);
  }

  private renderMicMeter(styles: IVUMeterStyleOverrides) {
    // When on Calibration page, MicInfo should
    // show a non-animating (all gray) VUMeter
    if (this.isCalibrationRoute()) {
      return <VUMeter styleOverride={styles} micEnergy={0} />;
    }

    return (
      <MicEnergyMeter
        activeBarColor={Color.Clementine}
        styleOverride={styles}
      />
    );
  }

  private isCalibrationRoute(): boolean {
    return RouterService.isOnRoute(RoutePath.Calibration);
  }

  public render() {
    const { selectedMic, isScreenplayActive, autoFocus } = this.props;
    const styles = MicInfoStyles.get();

    return (
      <StudentInfoSection title={selectedMic?.name}>
        <Row style={styles.VUMeterRow}>
          {this.renderMicMeter(styles.VUMeterBar)}
          {!SystemInfo.isNative && (
            <TextButton
              autoFocus={autoFocus}
              text="Change"
              styleOverride={styles.changeButton}
              Icon={ChangeIconSvg}
              onPress={this.handleChangePress}
              disabled={this.isCalibrationRoute() || isScreenplayActive}
            />
          )}
        </Row>
      </StudentInfoSection>
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: AppState) => ({
  isScreenplayActive: !!ScreenplaySelector.getActiveScreenplayId(state),
  selectedMic: SreSelector.getSelectedMic(state)
});

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  hideModal: () => AppShellAction.hideModal(),
  openChangeMicModal: () =>
    AppShellAction.showModal({ id: ChangeMicModal.ModalId })
};

export const MicInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(MicInfoComponent);
