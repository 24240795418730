import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ActivityPositionBuilder,
  CurriculumDependencies,
  ProgramContextSelector
} from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { RoundAction } from '../builders/rounds';
import { TransitionActionEducatorToEncounter } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { EducatorToEncounterTransitionBuilder } from './EducatorToEncounterTransitionBuilder';

export interface IEducatorToEncounterDeps {
  curriculumDependencies: CurriculumDependencies;
  preparedScenes: PreparedScenes;
}

export function educatorToEncounterTransitionEpic(
  action$: Observable<TransitionActionEducatorToEncounter>,
  state$: StateObservable<unknown>,
  deps: IEducatorToEncounterDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.EducatorToEncounter),
    map(action => {
      const {
        curriculumDependencies: { programContextService },
        preparedScenes
      } = deps;
      const { unitId, roundId } = action.payload;

      const position = ActivityPositionBuilder.create(
        ProgramContextSelector.getActivityPosition(state$.value)
      )
        .removeSubunitPositions()
        .resetAllUnitPositions()
        .withUnitPosition({ roundId, unitId }).activityPosition;

      const { screenplay } = EducatorToEncounterTransitionBuilder.create()
        .setActivityPosition(position)
        .awaitLevelLoaded(programContextService, state$)
        .prepareEncounterScenes(preparedScenes)
        .loadUnit()
        .awaitContentLoaded(programContextService, state$)
        .closeActivitySelectionModal()
        .navToRound(unitId, roundId);

      return ScreenplayAction.play({
        nextAction: RoundAction.intro(),
        screenplay
      });
    })
  );
}
educatorToEncounterTransitionEpic.displayName =
  'educatorToEncounterTransitionEpic';
