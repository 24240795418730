import { Position } from '@lexialearning/common-ui';
import { Types } from 'common-ui';

export interface IInstructionButtonStyle {
  container: Types.ViewStyle;
  questionMark: Types.ViewStyle;
}

export class InstructionButtonStyles {
  private static readonly styles = {
    container: {
      bottom: 45,
      position: Position.Absolute,
      left: 14
    }
  };

  public static get() {
    return this.styles;
  }
}
