import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function ShapeXSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G
      data-test-id={ShapeXSvg.displayName}
      transform="translate(-36.4 -114.375)"
    >
      {shadowColor && (
        <Path
          d="M111.837 186.646l-15.123-15.13-15.085 15.079a6.4 6.4 0 01-4.743 2.066H76.872a6.553 6.553 0 01-3.839-1.346 7.666 7.666 0 01-3.287-5.788 6.543 6.543 0 012.068-4.662l15.13-15.124-15.078-15.085a6.406 6.406 0 01-2.067-4.744v-.014a6.554 6.554 0 011.346-3.839 7.667 7.667 0 015.788-3.288 6.56 6.56 0 014.663 2.068l15.122 15.13 15.085-15.079a6.4 6.4 0 014.743-2.065h.014a6.553 6.553 0 013.839 1.348 7.666 7.666 0 013.287 5.787 6.551 6.551 0 01-2.068 4.662l-15.128 15.122 15.079 15.086a6.41 6.41 0 012.066 4.743v.009a6.55 6.55 0 01-1.347 3.84 7.666 7.666 0 01-5.787 3.286 6.552 6.552 0 01-4.664-2.062z"
          fill={shadowColor}
        />
      )}
      <Path
        d="M110.837 184.646l-15.123-15.13-15.085 15.079a6.4 6.4 0 01-4.743 2.066H75.872a6.553 6.553 0 01-3.839-1.346 7.666 7.666 0 01-3.287-5.788 6.543 6.543 0 012.068-4.662l15.13-15.124-15.078-15.085a6.406 6.406 0 01-2.067-4.744v-.014a6.554 6.554 0 011.346-3.839 7.667 7.667 0 015.788-3.288 6.56 6.56 0 014.663 2.068l15.122 15.13 15.085-15.079a6.4 6.4 0 014.743-2.065h.014a6.553 6.553 0 013.839 1.348 7.666 7.666 0 013.287 5.787 6.551 6.551 0 01-2.068 4.662l-15.128 15.122 15.079 15.086a6.41 6.41 0 012.066 4.743h0v.009a6.55 6.55 0 01-1.347 3.84 7.666 7.666 0 01-5.787 3.286 6.552 6.552 0 01-4.664-2.062z"
        fill={iconColor}
        stroke={iconColor}
        strokeMiterlimit="10"
      />
    </G>
  );
}
ShapeXSvg.displayName = 'ShapeXSvg';
