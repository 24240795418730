import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Text, TextButton, TextButtonType } from 'common-ui';
import {
  KeyboardType,
  TextInput
} from 'common-ui/components/inputs/text-input/TextInput';
import { LexiaServiceSelector } from 'lexia-service/redux/LexiaService.selector';
import { AppState } from 'services';
import { CustomerAction, CustomerSelector } from 'services/customer/redux';
import { AppShellAction } from 'shell';
import { SystemInfo } from 'utils';
import { isValidEmailFormat } from 'utils/ValidationUtils';
import { MailIconSvg } from '../login-icons';
import { LoginErrorMessage } from '../login.model';
import { LoginFormSharedStyles } from '../LoginFormShared.styles';
import {
  CONFIGURE_AUTH_SERVER_STRING,
  OpenCommandLineModal
} from './open-command-line-modal';
import { ButtonType } from '@lexialearning/reactxp/dist/common/Types';

export interface IDeviceSetupFormState {
  teachersEmail: string;
}

export interface IDeviceSetupFormProps {
  invalidEmail: boolean;
  isMakingRequest: boolean;
  mainInputRef?: any;
  clearErrors(): void;
  openCommandLineModal(): void;
  setupDevice(teacherEmail: string): void;
}

export class DeviceSetupFormComponent extends React.PureComponent<
  IDeviceSetupFormProps,
  IDeviceSetupFormState
> {
  public static readonly displayName = 'DeviceSetupForm';

  constructor(props: IDeviceSetupFormProps) {
    super(props);

    this.state = {
      teachersEmail: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  get isDisabled(): boolean {
    return (
      this.props.isMakingRequest ||
      !isValidEmailFormat(this.state.teachersEmail) ||
      this.props.invalidEmail
    );
  }

  private handleChange(value: string) {
    if (value === CONFIGURE_AUTH_SERVER_STRING) {
      this.props.openCommandLineModal();
      this.setState({ teachersEmail: '' });

      return;
    }

    this.setState({ teachersEmail: value });
    if (this.props.invalidEmail) {
      this.props.clearErrors();
    }
  }

  private handleSave() {
    this.props.setupDevice(this.state.teachersEmail);
  }

  public render() {
    const { invalidEmail, isMakingRequest, mainInputRef } = this.props;
    const styles = LoginFormSharedStyles.get();

    return (
      <Column testId={DeviceSetupFormComponent.displayName}>
        <Text style={styles.headerText}>Please set up this device</Text>
        <TextInput
          // If you autofocus on native, it will bring up the keyboard and slide the login page up.
          autoFocus={!SystemInfo.isNative}
          disallowSpaces
          name="teachersEmail"
          keyboardType={KeyboardType.EmailAddress}
          placeholder="Teacher's Email"
          value={this.state.teachersEmail}
          onChange={this.handleChange}
          onSubmitEditing={this.handleSave}
          errorInfo={{
            error: invalidEmail,
            errorMessage: LoginErrorMessage.DeviceSetup
          }}
          inputRef={mainInputRef}
          Icon={MailIconSvg}
          styleOverrides={styles.textInput}
          testId="teachersEmailInput"
          disabled={isMakingRequest}
        />
        <TextButton
          disabled={this.isDisabled}
          onPress={this.handleSave}
          text="Save"
          buttonType={TextButtonType.Primary}
          styleOverride={styles.buttonOverride}
          type={ButtonType.Submit}
        />
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    invalidEmail: CustomerSelector.hasInvalidEmail(state),
    isMakingRequest: LexiaServiceSelector.getIsMakingRequest(state)
  };
}

const mapDispatchToProps = {
  clearErrors: () => CustomerAction.clearErrors(),
  openCommandLineModal: () =>
    AppShellAction.showModal({ id: OpenCommandLineModal.ModalId }),
  setupDevice: (email: string) =>
    CustomerAction.setupDeviceWithEmail.request({ email })
};

export const DeviceSetupForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSetupFormComponent);

export const DeviceSetupFormPrivates = { mapDispatchToProps, mapStateToProps };
