import { IScreenplay } from '@lexialearning/lobo-common';
import { throttle } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { InteractionState } from 'common-styles';
import { Column, MicSelector, PressableView } from 'common-ui';
import {
  LevelSceneAnimationName,
  LevelSceneElementName
} from 'feature-areas/levels';
import {
  ScreenplayAction,
  ScreenplayBuilder,
  ScreenplaySelector
} from 'screenplay';
import { SceneName } from 'services/storm-lobo';
import { ActUiAction, ActUiSelector } from '../redux';
import { IFunFactData } from '../redux/act-ui-redux.model';
import { FunFactStyles } from './FunFact.styles';
import { FunFactButton } from './fun-fact-button/FunFactButton';

export interface IFunFactProps {
  disabled: boolean;
  funFactData: IFunFactData;
  isPlayingFunFact: boolean;
  cancelScreenplay(): void;
  playScreenplay(funFact: IScreenplay): void;
}

export class FunFactComponent extends React.PureComponent<IFunFactProps> {
  public static readonly displayName = 'FunFact';

  public static readonly ScreenplayId = 'FunFact';

  public static readonly IdleScreenPlayId = 'IdleFunFact';

  constructor(props: IFunFactProps) {
    super(props);

    this.handlePress = throttle(this.handlePress.bind(this), 1000);
  }

  private handlePress() {
    const {
      isPlayingFunFact,
      cancelScreenplay,
      playScreenplay,
      funFactData: { playbacksRemaining }
    } = this.props;

    if (isPlayingFunFact) {
      playbacksRemaining > 0
        ? playScreenplay(this.idleScreenPlay())
        : cancelScreenplay();
    } else {
      playScreenplay(this.createScreenplay());
    }
  }

  private createScreenplay(): IScreenplay {
    const {
      funFactData: { funFact, playbacksRemaining }
    } = this.props;

    const funFactBuilder = ScreenplayBuilder.create(
      FunFactComponent.ScreenplayId
    )
      .addReduxAction(ActUiAction.addPlayedFunFact({ id: funFact!.id }))
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.FunFactsEffects.Outro,
          targetElement: LevelSceneElementName.Effects,
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addScreenplay(funFact);

    return playbacksRemaining > 1
      ? this.addIdleAnimation(funFactBuilder)
      : funFactBuilder.screenplay;
  }

  private addIdleAnimation(builder: ScreenplayBuilder): IScreenplay {
    return builder.addStormAnimation({
      loop: true,
      name: LevelSceneAnimationName.FunFactsEffects.Idle,
      targetElement: LevelSceneElementName.Effects,
      targetScene: SceneName.Level
    }).screenplay;
  }

  private idleScreenPlay(): IScreenplay {
    const funFactBuilder = ScreenplayBuilder.create(
      FunFactComponent.IdleScreenPlayId
    );

    return this.addIdleAnimation(funFactBuilder);
  }

  public render() {
    const { disabled } = this.props;
    const styles = FunFactStyles.get();

    if (disabled) {
      return null;
    }

    return (
      // characterContainer frames the character position to correctly place the funFact clickable views
      <Column style={styles.characterContainer}>
        <PressableView
          testId="BubbleOverlay"
          style={styles.bubbleOverlay}
          onPress={this.handlePress}
        />
        <PressableView
          testId="CharacterOverlay"
          style={styles.characterOverlay}
          onPress={this.handlePress}
        />
        <FunFactButton onPress={this.handlePress} />
      </Column>
    );
  }
}

function mapStateToProps(state: unknown) {
  const activeScreenplayID = ScreenplaySelector.getActiveScreenplayId(state);
  const isPlayingFunFact = activeScreenplayID === FunFactComponent.ScreenplayId;
  const funFactData = ActUiSelector.getAvailableFunFactData(state);
  const allPlaybacksUsed =
    funFactData.playbacksRemaining <= 0 && !isPlayingFunFact;
  const micInteractionState = MicSelector.getInteractionState(state);
  const isMicEnabled = micInteractionState === InteractionState.Default;

  // The main Act page elements should become enabled/disabled at the same time, so FunFacts
  // can use the enabled (default) state of the mic to determine when it
  // should be enabled as well
  const disabled = !isMicEnabled || allPlaybacksUsed;

  return {
    disabled,
    funFactData,
    isPlayingFunFact
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  cancelScreenplay: () => ScreenplayAction.cancel(),
  playScreenplay: (sp: IScreenplay) => ScreenplayAction.play({ screenplay: sp })
};

export const FunFact = connect(
  mapStateToProps,
  mapDispatchToProps
)(FunFactComponent);

export const FunFactPrivates = {
  mapStateToProps
};
