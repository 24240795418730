import { InteractionState } from 'common-styles';
import { G, Path, Rect, Svg } from 'common-ui/components/svg';
import { PillSvgStyles } from './Pill.lx-svg.styles';
import { KeyNavHelper } from 'common-ui';
import { ActiveComponentState } from '@lexialearning/common-ui';

export interface IPillSvgProps {
  activeComponentState: ActiveComponentState;
  interactionState: InteractionState;
  isChecked: boolean;
}

export function PillSvg(props: IPillSvgProps) {
  const { activeComponentState, interactionState, isChecked } = props;
  const accessibilityLabel = isChecked ? 'Selected answer.' : 'Answer slot.';
  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
  const styles = PillSvgStyles.build(
    activeComponentState,
    interactionState,
    isChecked
  );
  const transform = `translate(${isKeyNav ? '-302' : '-300'} -409)`;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={40}
      viewBox="0 0 60 40"
      data-test-id={PillSvg.displayName}
      accessibilityLabel={accessibilityLabel}
    >
      <G id="pill" transform="translate(-172 -132)">
        <Rect
          id="pill-shadow"
          width={60}
          height={40}
          rx="20"
          transform="translate(172 132)"
          style={styles.shadow}
        />
        <Rect
          id="pill-background"
          width={58}
          height={38}
          rx="18.5"
          transform="translate(172 132)"
          style={styles.background}
        />
        <Path
          id="checkmark"
          d="M502.864,571.13a3.039,3.039,0,0,1-2.25.98,3.111,3.111,0,0,1-2.211-.981l-8.886-8.885a3.264,3.264,0,1,1,4.616-4.615l6.5,6.5,13.539-13.539a3.112,3.112,0,0,1,2.212-.981,3.64,3.64,0,0,1,3.385,3.385,3.109,3.109,0,0,1-.981,2.211Z"
          transform={transform}
          style={styles.checkmark}
        />
      </G>
    </Svg>
  );
}
PillSvg.displayName = 'PillSvg';
