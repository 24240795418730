import {
  IEncounter,
  IProgressBase,
  IRoundReference,
  IUnitSnipped
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';

export class ProgressBaseBuilder {
  public static readonly displayName = 'ProgressBaseBuilder';

  public static create(
    sysId: string,
    children: ProgramItem[]
  ): ProgressBaseBuilder {
    return new ProgressBaseBuilder(
      {
        count: children.length,
        isComplete: false,
        percentComplete: -1,
        position: -1,
        positionId: '',
        sysId
      },
      children
    );
  }

  private constructor(
    public readonly progress: IProgressBase,
    private readonly children: ProgramItem[]
  ) {}

  public maybeCompleted(
    isComplete: boolean,
    activeChildId: string
  ): ProgressBaseBuilder {
    if (isComplete || !this.children.length) {
      return this.completed();
    }

    return this.active(activeChildId);
  }

  public withActivePosition(
    activeIndex: number,
    myIndex: number,
    activeChildId: string
  ): ProgressBaseBuilder {
    const status = this.getStatus(activeIndex, myIndex);
    this.progress.isComplete = status === Status.Done || !this.children.length;

    if (this.progress.isComplete) {
      return this.completed();
    }

    if (status === Status.Pending) {
      return this.pending();
    }

    return this.active(activeChildId);
  }

  private completed(): ProgressBaseBuilder {
    this.progress.isComplete = true;

    this.progress.percentComplete = 100;
    this.progress.position = this.progress.count;
    this.progress.positionId = '';

    return this;
  }

  private pending(): ProgressBaseBuilder {
    this.progress.isComplete = false;
    this.progress.percentComplete = 0;
    this.progress.position = -1;
    this.progress.positionId = '';

    return this;
  }

  private active(activeChildId: string): ProgressBaseBuilder {
    this.progress.positionId = activeChildId;
    this.progress.position = this.children.findIndex(
      c => c.sysId === activeChildId
    );
    if (activeChildId && this.progress.position < 0) {
      throw new LexiaError(
        `Child id "${activeChildId}" not found in children array`,
        ProgressBaseBuilder.displayName,
        ProgressBaseBuilderError.MissingChild
      ).withContext({ activeChildId, children: this.children });
    }

    this.progress.percentComplete = Math.round(
      (Math.max(this.progress.position, 0) * 100) / this.progress.count
    );

    return this;
  }

  private getStatus(activeIndex: number, itemIndex: number): Status {
    return activeIndex > itemIndex
      ? Status.Done
      : activeIndex === itemIndex
      ? Status.Active
      : Status.Pending;
  }
}

type ProgramItem = IEncounter | IUnitSnipped | IRoundReference;

enum Status {
  Done,
  Active,
  Pending
}

export enum ProgressBaseBuilderError {
  MissingChild = 'MissingChild'
}
