import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { IScreenplayActionFactory, ScreenplayerType } from 'screenplay';
import { IMusicRequest } from './music.model';

export class MusicScreenplayerActionFactory
  implements IScreenplayActionFactory<IMusicRequest>
{
  public static readonly displayName = 'MusicScreenplayerActionFactory';

  public type = ScreenplayerType.Music;

  public static create(data: IMusicRequest): IScreenplayAction<IMusicRequest> {
    return {
      data,
      type: ScreenplayerType.Music
    };
  }

  public create(data: IMusicRequest): IScreenplayAction<IMusicRequest> {
    return MusicScreenplayerActionFactory.create(data);
  }
}
