import {
  ITask,
  ITaskEvaluation,
  TaskAudioSupport
} from '@lexialearning/lobo-common/main-model';
import { ActionType, createAction } from 'typesafe-actions';
import { IModeling } from 'task-components/core';
import { TaskActionType } from './task-redux.model';

export const TaskAction = {
  clearSelectedChoices: createAction(TaskActionType.ClearSelectedChoices)(),

  entry: createAction(TaskActionType.Entry)(),
  entryComplete: createAction(TaskActionType.EntryComplete)(),

  evaluated: createAction(TaskActionType.Evaluated)<ITaskEvaluation>(),
  exit: createAction(TaskActionType.Exit)(),

  exitComplete: createAction(TaskActionType.ExitComplete)(),

  feedback: createAction(TaskActionType.Feedback)(),
  feedbackComplete: createAction(TaskActionType.FeedbackComplete)(),

  interactive: createAction(TaskActionType.Interactive)(),

  loaded: createAction(TaskActionType.Loaded)<ITaskLoadActionPayload>(),
  playAudioSupport: createAction(
    TaskActionType.PlayAudioSupport
  )<TaskAudioSupport>(),

  preamble: createAction(TaskActionType.Preamble)(),
  preambleComplete: createAction(TaskActionType.PreambleComplete)(),
  setSelectedChoice: createAction(
    TaskActionType.SetSelectedChoice
  )<ITaskActionSelectedChoicePayload>(),

  showSolution: createAction(TaskActionType.ShowSolution)(),

  solution: createAction(TaskActionType.Solution)(),

  solutionComplete: createAction(TaskActionType.SolutionComplete)(),

  toggleSelectedChoice: createAction(
    TaskActionType.ToggleSelectedChoice
  )<ITaskActionSelectedChoicePayload>(),

  updateCanSubmit: createAction(
    TaskActionType.UpdateCanSubmit
  )<ITaskActionUpdateCanSubmitPayload>()
};

export interface ITaskLoadActionPayload {
  modeling: IModeling;
  roundId: string;
  taskContent: ITask;
}

export interface ITaskActionUpdateCanSubmitPayload {
  canSubmit: boolean;
}
export interface ITaskActionSelectedChoicePayload {
  choice: string;
}

export type TaskActions = ActionType<typeof TaskAction>;
export type TaskActionEntry = ActionType<typeof TaskAction.entry>;
export type TaskActionEvaluated = ActionType<typeof TaskAction.evaluated>;
export type TaskActionExit = ActionType<typeof TaskAction.exit>;
export type TaskActionExitComplete = ActionType<typeof TaskAction.exitComplete>;
export type TaskActionFeedback = ActionType<typeof TaskAction.feedback>;
export type TaskActionFeedbackComplete = ActionType<
  typeof TaskAction.feedbackComplete
>;
export type TaskActionInteractive = ActionType<typeof TaskAction.interactive>;
export type TaskActionLoaded = ActionType<typeof TaskAction.loaded>;
export type TaskActionPlayAudioSupport = ActionType<
  typeof TaskAction.playAudioSupport
>;
export type TaskActionShowSolution = ActionType<typeof TaskAction.showSolution>;
export type TaskActionSolution = ActionType<typeof TaskAction.solution>;
