import { useSelector } from 'react-redux';
import { Column } from 'common-ui/components/layout/column';
import { RoundSelector } from 'curriculum-services';
import { UnitSelector } from 'services/curriculum-services/unit';
import { TaskWorkArea } from 'task-components/core';
import { taskRegistry } from '../tasks/lobo-task-registry';
import { UnitPageStyles } from './UnitPage.styles';

export function UnitPage() {
  const roundId = useSelector(RoundSelector.getRoundMaybe)?.sysId ?? '';
  const unit = useSelector(UnitSelector.getUnitMaybe);

  if (!unit || !roundId) {
    return null;
  }

  const styles = UnitPageStyles.get();

  return (
    <Column testId={UnitPage.displayName} style={styles.container}>
      <TaskWorkArea taskRegistry={taskRegistry} />
    </Column>
  );
}
UnitPage.displayName = 'UnitPage';
