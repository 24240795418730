import { useState } from 'react';
import { CleverWebviewModalStyles } from './CleverWebviewModal.styles';
import { WebView } from '@lexialearning/common-ui';
import { ModalRegistry } from 'shared-components/modals';
import { AppState } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerSelector } from 'services/customer';
import { Linking } from '@lexialearning/reactxp';
import { AppShellAction } from 'feature-areas/shell';
import { SplashScreen } from 'app/SplashScreen';
import { RouterService } from 'router-service';

export function CleverWebviewModal() {
  // istanbul ignore next - native specific event that can't be tested with rtl
  const handleNavigationStateChange = navState => {
    setIsWebViewCovered(RouterService.isLexiaEnglishUrl(navState.url));
  };

  const dispatch = useDispatch();
  Linking.deepLinkRequestEvent.subscribe(() =>
    dispatch(AppShellAction.hideModal())
  );

  const ssoUrl = useSelector((state: AppState) =>
    CustomerSelector.getSsoLink(state)
  );

  const [isWebViewCovered, setIsWebViewCovered] = useState(false);

  const styles = CleverWebviewModalStyles.get();

  return (
    <>
      <WebView
        allowsInlineMediaPlayback={true}
        applicationNameForUserAgent="CleverWebView"
        onNavigationStateChange={handleNavigationStateChange}
        style={styles.webView}
        testId={CleverWebviewModal.displayName}
        // ⬇ temp fix until MINT-1095 is addressed. LOBO-20367 ticket to remove this fix
        url={ssoUrl?.href!.replace('plat%3Dweb', 'plat%3Dliwcb')}
      />
      {
        // istanbul ignore next - native specific event that can't be tested with rtl
        isWebViewCovered && <SplashScreen styleOverride={styles.splashScreen} />
      }
    </>
  );
}

CleverWebviewModal.displayName = 'CleverWebviewModal';
// istanbul ignore next - trivial code, not worth testing
CleverWebviewModal.ModalId = ModalRegistry.register({
  Component: CleverWebviewModal,
  title: 'Clever Login'
});
