import { AuthActionType } from './AuthActionType';
import { AuthActions } from './Auth.action';
import { IAuthState } from './auth-redux.model';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import {
  UserGlobalActionLogout,
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';

const authInitialState: IAuthState = {
  apiTimeoutArray: [],
  authError: { incorrectUsernameOrPassword: false },
  authToken: '-1',
  autoLogin: false,
  details: undefined,
  isAuthenticated: false,
  loginId: '-1',
  loginTime: undefined,
  logoutUrl: undefined,
  personId: -1
};

export function authReducer(
  state: IAuthState = authInitialState,
  action: AuthActions | UserGlobalActionLogout | UserGlobalActionLogoutSuccess
): IAuthState {
  switch (action.type) {
    case AuthActionType.AuthenticateSuccess:
    case AuthActionType.AuthenticatedByMdm:
      return {
        ...state,
        details: action.payload
      };

    case AuthActionType.AuthenticatedBySso:
      const { logoutUrl, ...details } = action.payload;

      return {
        ...state,
        details,
        logoutUrl
      };

    case AuthActionType.AuthenticateFailure:
      const { standardCode } = action.payload;

      return {
        ...state,
        authError: {
          incorrectUsernameOrPassword:
            standardCode === LexiaStandardErrorCode.UnknownUsername
        },
        isAuthenticated: false
      };

    case AuthActionType.DisableAutoLogin:
      return { ...state, autoLogin: false };

    case AuthActionType.InvalidToken:
      return {
        ...state,
        isAuthenticated: false
      };

    case AuthActionType.LoginSuccess:
      return {
        ...state,
        apiTimeoutArray: action.payload.apiTimeoutArray,
        authError: authInitialState.authError,
        authToken: action.payload.authToken,
        isAuthenticated: true,
        loginId: action.payload.loginId,
        loginTime: Date.now(),
        personId: action.payload.personId
      };

    case AuthActionType.Unauthenticate:
      return {
        ...state,
        details: undefined
      };

    case AuthActionType.ResetAuthError:
      return {
        ...state,
        authError: {
          incorrectUsernameOrPassword: false
        }
      };

    case UserGlobalActionType.Logout:
      // QUESTION: bc of this, the user goes to the login page before getting rerouted to the Clever Portal
      // I'm guessing that is not what C5 does, are we ok with that?
      // In testing this, I keep thinking its not working, esp bc it can take a moment before it does the reroute to Clever
      return {
        ...authInitialState,
        // need to keep this set to potentially redirect the user during logout
        logoutUrl: state.logoutUrl
      };

    case UserGlobalActionType.LogoutSuccess:
      return authInitialState;

    default:
      return state;
  }
}

export const AuthReducerPrivates = { initialState: authInitialState };
