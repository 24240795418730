// istanbul ignore file - code generated by svgr
import { Color } from 'common-styles';
import { Circle, G, Path, Svg } from 'common-ui';

export function BellIconSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      accessibilityLabel="New level certificate available."
      data-test-id={BellIconSvg.displayName}
    >
      <G transform="translate(-1589.883 -335.883)">
        <Circle
          cx="10"
          cy="10"
          r="10"
          transform="translate(1589.883 335.883)"
          fill={Color.DeepBlue2}
        />
        <G data-name="bell" fill={Color.NearWhite}>
          <Path
            data-name="Path 282"
            d="M1604.241 349.562h-1.783v-5.758a2.88 2.88 0 0 0-2.6-1.631h-.051a2.752 2.752 0 0 0-2.5 1.605v5.783h-1.779v-6.115l.051-.153a4.5 4.5 0 0 1 4.255-2.9h.025a4.655 4.655 0 0 1 4.306 2.879l.076.153Z"
          />
          <Path d="m1595.731 348.364 1.478.764h5.376l1.478-.764 1.3 1.5v.892h-10.982v-.892ZM1599.858 339.141c.561 0 1.172.433 1.019 1.5h-2.064c-.152-1.016.485-1.5 1.045-1.5ZM1599.859 352.618a1.357 1.357 0 0 0 1.376-1.452h-2.752a1.436 1.436 0 0 0 1.376 1.452Z" />
        </G>
      </G>
    </Svg>
  );
}
BellIconSvg.displayName = 'BellIconSvg';
