import { Svg, G } from '@lexialearning/common-ui';
import {
  UtilityButtonIconType,
  UtilityButtonSharedStyles,
  UtilityButtonColorSetName
} from './utility-button-model';
import { UtilityButtonBackground } from './UtilityButtonBackground.lx-svg';
import { UtilityButtonHelper } from './UtilityButtonHelper';
import { UtilityButtonIcons } from './UtilityButtonIcons.lx-svg';

export interface IUtilityButtonSvgProps {
  colorSetName: UtilityButtonColorSetName;
  iconType: UtilityButtonIconType;
  scale?: number;
}

export function getAccessibilityLabel(iconType: UtilityButtonIconType): string {
  switch (iconType) {
    case UtilityButtonIconType.Apple:
      return 'Troubleshoot';
    case UtilityButtonIconType.BackArrow:
    case undefined:
      return 'Go back';
    case UtilityButtonIconType.Close:
      return 'Close';
    case UtilityButtonIconType.Gear:
      return 'Settings';
    case UtilityButtonIconType.Logout:
      return 'Logout';
    case UtilityButtonIconType.Tools:
      return 'Contact Support';
    default:
      return '';
  }
}

export function UtilityButtonSvg(props: IUtilityButtonSvgProps) {
  const { colorSetName, iconType, scale = 1 } = props;
  const {
    backgroundColor,
    borderColor,
    iconColor,
    withShadow = false
  } = UtilityButtonHelper.getColorSet(colorSetName)!;

  const viewBoxSize =
    UtilityButtonSharedStyles.button.height + (withShadow ? 1 : 0);
  const size = scale * viewBoxSize;

  const UtilityButtonIcon = UtilityButtonIcons.IconMap.get(iconType);

  return (
    <Svg
      accessibilityLabel={getAccessibilityLabel(iconType)}
      data-test-id={UtilityButtonSvg.displayName}
      height={size}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={size}
      style={{ marginBottom: -1, marginRight: -1 }}
    >
      <G transform="translate(-2 -2.1)">
        <UtilityButtonBackground
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          withShadow={withShadow}
        />
        {UtilityButtonIcon && (
          <UtilityButtonIcon iconColor={iconColor} withShadow={withShadow} />
        )}
      </G>
    </Svg>
  );
}
UtilityButtonSvg.displayName = 'UtilityButtonSvg';
