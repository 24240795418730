// istanbul ignore file - non-production
import {
  IImage,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import {
  Column,
  GlossyButton,
  Image,
  Row,
  SpotlightArea,
  Text
} from 'common-ui';
import { SpotlightAreaLayout } from 'common-ui/components/spotlight/Spotlight.styles';
import { ITaskProps } from '../core';

export class PlaceholderTask extends React.PureComponent<ITaskProps> {
  constructor(props: ITaskProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private handleSubmit(): void {
    this.props.onEvaluated(TaskEvaluationResult.Correct, {});
  }

  public render() {
    const content = this.props.taskContent || { placeholder: 'No content!' };

    return (
      <>
        {Object.getOwnPropertyNames(content).map((propName, index) => (
          <React.Fragment key={index}>
            <Text>{propName}: </Text>
            {this.renderValue(content[propName])}
          </React.Fragment>
        ))}
        <Column>
          <GlossyButton onPress={this.handleSubmit} />
        </Column>
      </>
    );
  }

  private renderValue(value: any) {
    if (typeof value === 'string') {
      return <Text>{value}</Text>;
    }

    if (Array.isArray(value)) {
      if (value.every(i => this.isCard(i))) {
        return (
          <SpotlightArea
            {...{ spotlights: value }}
            layout={SpotlightAreaLayout.Small}
          />
        );
      }

      if (value.every(i => this.isImage(i))) {
        return (
          <Row>
            {value.map((img: IImage, idx: number) => (
              <Image
                key={idx}
                style={{ height: 200, width: 200 }}
                source={img.imageSource || (img as any).mediaSource}
                accessibilityLabel="Placeholder."
              />
            ))}
          </Row>
        );
      }
    }

    return <Text>{JSON.stringify(value)}</Text>;
  }

  private isCard(thing: any): boolean {
    return typeof thing === 'object' && 'image' in thing;
  }

  private isImage(thing: any): boolean {
    return (
      typeof thing === 'object' &&
      'mediaSource' in thing &&
      thing.type === 'Image'
    );
  }
}
