import {
  IActivityPosition,
  IProgramPosition
} from '@lexialearning/lobo-common';
import { ActionType, createAction } from 'typesafe-actions';
import { ISessionHistoryEvent, ProgramMode } from '../program-context.model';
import { ProgramContextActionType } from './program-context-redux.model';

export const ProgramContextAction = {
  keepSessionAlive: createAction(ProgramContextActionType.KeepSessionAlive)(),
  keepSessionAliveForced: createAction(
    ProgramContextActionType.KeepSessionAliveForced
  )(),

  /**
   * Program context mode and position have been determined at the start of
   * a session (i.e. from the login response).
   */
  loaded: createAction(
    ProgramContextActionType.Loaded
  )<IProgramContextLoadedActionPayload>(),
  loadedForDeepLinking: createAction(
    ProgramContextActionType.LoadedForDeepLinking
  )<IProgramContextLoadedActionPayload>(),

  monitorSessionTimeout: createAction(
    ProgramContextActionType.MonitorSessionTimeout
  )(),

  /**
   * Gets reduced to set mode, activeActivityId and activity position for Placement
   * and kicks off epic to dispatch load unit
   */
  onboardingCompleted: createAction(
    ProgramContextActionType.OnboardingCompleted
  )<IActivityPosition>(),

  /**
   * Program context position has been adjusted after placement has
   * been loaded and program mode is Onboarding
   */
  programPositionAdjustedForPlacement: createAction(
    ProgramContextActionType.ProgramPositionAdjustedForPlacement
  )<IProgramPosition>(),

  /**
   * Change program mode to ActiveStudent to transition from Placement,
   * change position to the level at which the student placed, and load the
   * level content.
   */
  startProgram: createAction(ProgramContextActionType.StartProgram)(),

  updateSessionHistory: createAction(
    ProgramContextActionType.UpdateSessionHistory
  )<IUpdateSessionHistory>()
};

export interface IProgramContextLoadedActionPayload {
  mode: ProgramMode;
  position: IProgramPosition | undefined;
}

export interface IUpdateSessionHistory {
  sessionHistoryEvent: ISessionHistoryEvent;
}

export type ProgramContextActions = ActionType<typeof ProgramContextAction>;
export type ProgramContextActionKeepSessionAlive = ActionType<
  typeof ProgramContextAction.keepSessionAlive
>;
export type ProgramContextActionKeepSessionAliveForced = ActionType<
  typeof ProgramContextAction.keepSessionAliveForced
>;
export type ProgramContextActionMonitorSessionTimeout = ActionType<
  typeof ProgramContextAction.monitorSessionTimeout
>;

export type ProgramContextActionLoaded = ActionType<
  typeof ProgramContextAction.loaded
>;
export type ProgramContextActionLoadedForDeepLinking = ActionType<
  typeof ProgramContextAction.loadedForDeepLinking
>;

export type ProgramContextActionOnboardingCompleted = ActionType<
  typeof ProgramContextAction.onboardingCompleted
>;

export type ProgramContextActionProgramPositionAdjustedForPlacement =
  ActionType<typeof ProgramContextAction.programPositionAdjustedForPlacement>;

export type ProgramContextActionStartProgram = ActionType<
  typeof ProgramContextAction.startProgram
>;

export type ProgramContextActionUpdateSessionHistory = ActionType<
  typeof ProgramContextAction.updateSessionHistory
>;
