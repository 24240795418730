import { useActiveComponentState } from '@lexialearning/common-ui';
import { PressScaleButton } from 'common-ui';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';
import { SquareButtonStyles } from './SquareButton.styles';
import React from 'react';

export interface ISquareButtonProps extends IButtonBaseProps {
  accessibilityLabel: string | undefined;
  children: React.ReactElement;
  onPress: () => void;
  selected: boolean;
}

export function SquareButton(props: ISquareButtonProps) {
  const { accessibilityLabel, children, onPress, selected, ...restProps } =
    props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = SquareButtonStyles.build(activeComponentState, selected);

  return (
    <PressScaleButton
      {...restProps}
      {...interactionHandlers}
      accessibilityLabel={accessibilityLabel}
      hoverScale={1.06}
      onPress={onPress}
      pressScale={0.9}
      style={styles.squareButton}
      testId={SquareButton.displayName}
    >
      {children}
    </PressScaleButton>
  );
}
SquareButton.displayName = 'SquareButton';
