import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { AuthActionLoginUserSuccess, AuthActionType } from 'services/auth';
import { ErrorShortCircuiter } from './ErrorShortCircuiter';

/**
 * Reset fatal errors on successful login
 */
export function resetFatalErrorsEpic(
  action$: Observable<AuthActionLoginUserSuccess>
): Observable<void> {
  return action$.pipe(
    ofType(AuthActionType.LoginSuccess),
    tap(() => {
      ErrorShortCircuiter.reset();
    }),
    ignoreElements()
  );
}
resetFatalErrorsEpic.displayName = 'resetFatalErrorsEpic';
