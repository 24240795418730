import {
  ActiveComponentState,
  Overflow,
  Position
} from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { Color, ShadowStyle, ZIndex } from 'common-styles';
import {
  UtilityButtonColors,
  UtilityButtonSharedStyles
} from 'common-ui/components/button/utility-button/utility-button-model';
import { UtilityButtonHelper } from 'common-ui/components/button/utility-button/UtilityButtonHelper';
import { UserInfoButtonType } from '../../redux/app-shell.model';
import { KeyNavHelper } from 'common-ui';
import { SystemInfo } from '@lexialearning/utils-react';

export class UserInfoButtonStyles {
  public static readonly positionRight = 58;

  private static readonly borderRadius =
    UtilityButtonSharedStyles.button.borderRadius;

  private static readonly borderWidth = 2;

  private static readonly size = UtilityButtonSharedStyles.button.height;

  private static getIconBackgroundColor(
    isKeyNav: boolean,
    userInfoButtonType: UserInfoButtonType
  ) {
    if (isKeyNav) {
      return Color.Transparent;
    }

    return [
      UserInfoButtonType.Placement,
      UserInfoButtonType.PresentationOfKnowledge
    ].includes(userInfoButtonType)
      ? Color.DeepBlue
      : Color.Gold;
  }

  public static readonly build = memoizeOne(
    (
      userInfoButtonType: UserInfoButtonType,
      isHighlighted: boolean,
      activeComponentState: ActiveComponentState
    ) => {
      const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
      const colorSet = UtilityButtonHelper.getColorSet(activeComponentState);

      return {
        buttonContainer: {
          position: Position.Absolute,
          right: this.positionRight,
          top: 10,
          zIndex: isHighlighted ? ZIndex.Modal + 1 : undefined
        },
        container: isHighlighted
          ? UtilityButtonSharedStyles.buttonHighlight
          : {},
        iosShadow: colorSet?.withShadow
          ? {
              position: Position.Absolute,
              top: 1,
              left: 1,
              bottom: -1,
              right: -1,
              borderColor: UtilityButtonColors.shadow,
              borderRadius: this.borderRadius,
              borderWidth: this.borderWidth,
              height: this.size
            }
          : {},
        iconContainer: {
          ...(userInfoButtonType !== UserInfoButtonType.Minimal && {
            // thin black line on right of icon showing on iOS when not minimal. this fixes it
            left: 1,
            paddingRight: 1
          }),
          backgroundColor: this.getIconBackgroundColor(
            isKeyNav,
            userInfoButtonType
          ),
          borderBottomRightRadius: this.borderRadius,
          borderTopRightRadius: this.borderRadius,
          height: this.size,
          overflow: Overflow.Hidden,
          width: this.size
        },
        innerContainer: {
          ...(!SystemInfo.isNative && ShadowStyle.button),
          backgroundColor: colorSet?.backgroundColor,
          borderColor: colorSet?.borderColor,
          borderRadius: this.borderRadius,
          borderWidth: this.borderWidth,
          height: this.size,
          overflow: Overflow.Hidden
        },
        labelContainer: {
          borderBottomLeftRadius: 8,
          borderTopLeftRadius: 8,
          paddingHorizontal: 10,
          paddingVertical: 5,
          ...(isKeyNav && {
            borderRightColor: colorSet?.borderColor,
            borderRightWidth: 2
          })
        },
        usernameLabel: {
          color: colorSet?.iconColor,
          fontSize: 16
        }
      };
    }
  );
}
