import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { Action } from 'redux';
import {
  IScreenplayActionFactory,
  ScreenplayerType
} from '../screenplayer.model';

export class ReduxDispatchActionFactory
  implements IScreenplayActionFactory<Action>
{
  public static create(data: Action): IScreenplayAction<Action> {
    return {
      data,
      type: ScreenplayerType.ReduxDispatcher
    };
  }

  public readonly type = ScreenplayerType.ReduxDispatcher;

  public create(data: Action): IScreenplayAction<Action> {
    return ReduxDispatchActionFactory.create(data);
  }
}
