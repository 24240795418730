import { Display, Overflow } from '@lexialearning/common-ui';
import { Align } from 'common-styles';

export class DndContainerStyles {
  private static readonly styles = {
    container: {
      alignItems: Align.Stretch,
      flex: 1
    },
    dropTargetStyleOverride: {
      display: Display.Flex,
      flex: 1,
      overflow: Overflow.Hidden
    }
  };

  public static get() {
    return this.styles;
  }
}
