import { createSelector } from '@reduxjs/toolkit';
import { IScreenplayState } from './screenplay-redux.model';

/**
 * These selectors should not be used outside the screenplay "package"
 */
export class ScreenplayPrivateSelector {
  public static getState: (state: unknown) => IScreenplayState;

  public static createSelectors(selector: (state: any) => IScreenplayState) {
    ScreenplayPrivateSelector.getState = createSelector(
      selector,
      (state: IScreenplayState) => state
    );
  }
}
