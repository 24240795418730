import * as React from 'react';
import { useTheme } from 'theme';
import { Row } from '../layout';
import { Text } from '../text';
import {
  ITextPromptStyleOverride,
  TextPromptStyles
} from './TextPrompt.styles';

export interface ITextPromptProps {
  children?: string;
  styleOverride?: ITextPromptStyleOverride;
}

export function TextPrompt(
  props: ITextPromptProps
): React.ReactElement<ITextPromptProps> {
  const { themeType } = useTheme();
  const { children, styleOverride } = props;
  const styles = TextPromptStyles.build(themeType, styleOverride);

  return (
    <Row style={styles.container}>
      <Text style={styles.text}>{children}</Text>
    </Row>
  );
}
TextPrompt.displayName = 'TextPrompt';
