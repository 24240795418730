import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthApi, IAuthDetails } from 'lexia-service';
import { StudentProgressApi } from 'curriculum-services';
import {
  AuthAction,
  AuthActionAuthenticatedByMdm,
  AuthActionLoginMdmUser,
  AuthActionType
} from '../redux';
import {
  LoginActionFactory,
  LoginOutputActions
} from './login-action-factory/LoginActionFactory';
import { IUserInfo, UserInfoFactory } from './user-info-factory';
import { AuthenticationMethod } from '@lexialearning/student-api';
import { CustomerSelector } from 'services/customer';

export interface ILoginUserMdmDependencies {
  authApi: AuthApi;
  studentProgressApi: StudentProgressApi;
}

export type LoginUserMdmOutputActions =
  | LoginOutputActions
  | AuthActionAuthenticatedByMdm;

export function loginUserMdmEpic(
  action$: Observable<AuthActionLoginMdmUser>,
  state$: StateObservable<unknown>,
  deps: ILoginUserMdmDependencies
): Observable<LoginUserMdmOutputActions> {
  return action$.pipe(
    ofType(AuthActionType.LoginMdm),
    mergeMap(async action => {
      const { authApi } = deps;
      const { username, password } = action.payload;
      const details = await authApi.authenticate(username, password);

      const authAction = AuthAction.authenticatedByMdm(details);

      const info = await login(deps.studentProgressApi, details);

      return { authAction, info };
    }),
    mergeMap(data => [
      data.authAction,
      ...LoginActionFactory.createLoginDispatches(
        data.info!,
        AuthenticationMethod.Mdm,
        CustomerSelector.getCustomerCode(state$.value),
        deps.studentProgressApi
      )
    ])
  );
}
loginUserMdmEpic.displayName = 'loginUserMdmEpic';

async function login(
  api: StudentProgressApi,
  details: IAuthDetails
): Promise<IUserInfo> {
  const { personId, role, token } = details;

  const loginResponse = await api.login(personId, role, token);

  return UserInfoFactory.create(loginResponse);
}
