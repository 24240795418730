import { IThemeState, ThemeSize } from './theme.model';
import { ThemeActions } from './Theme.action';
import { ThemeActionType } from './ThemeActionType';

const themeInitialState: IThemeState = {
  theme: {
    size: ThemeSize.Large
  }
};

const reducer = (
  state: IThemeState = themeInitialState,
  action: ThemeActions
): IThemeState => {
  switch (action.type) {
    case ThemeActionType.Update:
      return {
        ...state,
        ...(action.payload.theme && { theme: action.payload.theme })
      };

    default:
      return state;
  }
};

export { reducer as themeReducer };

export const ThemeReducerPrivates = { initialState: themeInitialState };
