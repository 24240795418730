import {
  ActiveComponentState,
  ISyntheticEvent
} from '@lexialearning/common-ui';
import { KeyEventHelper, Types as RxTypes } from '@lexialearning/reactxp';
import * as React from 'react';
import { IButtonBaseProps } from './ButtonBase';

export interface IInteractiveStateButtonBaseState {
  activeComponentState: ActiveComponentState;
}

/**
 * @deprecated use useActiveComponentState where possible, this should be removed once all uses of it are migrated to hooks
 */
export class InteractiveStateButtonBase<
  P extends IButtonBaseProps = IButtonBaseProps,
  S = IInteractiveStateButtonBaseState
> extends React.PureComponent<
  P & IButtonBaseProps,
  S & IInteractiveStateButtonBaseState
> {
  public static readonly displayName: string = 'InteractiveStateButtonBase';

  protected readonly interactionHandlers: any;

  protected handlePress?(): void;

  constructor(props: P & IButtonBaseProps) {
    super(props);

    this.state = {
      activeComponentState: ActiveComponentState.Default
    } as S & IInteractiveStateButtonBaseState;

    this.setActiveComponentState = this.setActiveComponentState.bind(this);

    this.interactionHandlers = {
      onBlur: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.Default);
        this.props.onBlur?.(e);
      },
      onHoverEnd: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.Default);
        this.props.onHoverEnd?.(e);
      },
      onHoverStart: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.Hovered);
        this.props.onHoverStart?.(e);
      },
      onKeyDown: (e: RxTypes.KeyboardEvent) => {
        if (KeyEventHelper.isPressKey(e)) {
          this.setActiveComponentState(ActiveComponentState.KeyboardPressed);
          this.props.onKeyDown?.(e);
        }
      },
      onKeyUp: (e: RxTypes.KeyboardEvent) => {
        if (KeyEventHelper.isPressKey(e)) {
          this.setActiveComponentState(ActiveComponentState.KeyboardFocused);
          this.props.onKeyUp?.(e);
        }
      },
      onKeyboardFocus: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.KeyboardFocused);
        this.props.onKeyboardFocus?.(e);
      },
      onPressIn: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.Pressed);
        this.props.onPressIn?.(e);
      },
      onPressOut: (e: ISyntheticEvent) => {
        this.setActiveComponentState(ActiveComponentState.Default);
        this.props.onPressOut?.(e);
      }
    };
  }

  private setActiveComponentState(activeComponentState: ActiveComponentState) {
    this.setState({
      activeComponentState
    } as S & IInteractiveStateButtonBaseState);
  }
}
