import { LexiaError } from '@lexialearning/utils';
import { debounce, isEqual, last, reverse, sum } from 'lodash';
import * as React from 'react';
import { Types } from '../../types';
import { FadeAnimation } from '../fade-animation';
import { Row } from '../layout';
import { View } from '../view';
import { CenterLeftAlignWrapStyles } from './CenterLeftAlignWrap.styles';
import { Logger } from '@lexialearning/utils-react';

export interface ICenterLeftAlignWrapProps {
  tokenArray: any[];
  renderToken(token: any, index?: number, tokenProps?: any): React.ReactNode;
  width?: number;
  tokenProps?: any;
  testId?: string;
}

export interface ICenterLeftAlignWrapState {
  tokenWidths: (number | undefined)[];
  calculatedWidth: number;
  isSingleLine: boolean;
}

export class CenterLeftAlignWrap extends React.PureComponent<
  ICenterLeftAlignWrapProps,
  ICenterLeftAlignWrapState
> {
  public static readonly displayName = 'CenterLeftAlignWrap';

  constructor(props: ICenterLeftAlignWrapProps) {
    super(props);
    this.state = {
      calculatedWidth: 0,
      isSingleLine: false,
      tokenWidths: []
    };
    this.setWidth = debounce(this.setWidth.bind(this), 100);
  }

  public componentDidUpdate(
    _prevProps: ICenterLeftAlignWrapProps,
    prevState: ICenterLeftAlignWrapState
  ) {
    const { tokenWidths } = this.state;
    const { tokenWidths: prevTokenWidths } = prevState;
    const tokenCount = this.props.tokenArray?.length;

    if (
      tokenWidths.filter(t => t !== undefined).length === tokenCount &&
      !isEqual(prevTokenWidths, tokenWidths)
    ) {
      this.setWidth();
    }
  }

  private updateTokenWidth(index: number, width: number) {
    this.setState(prevState => {
      const tokenWidths = [...prevState.tokenWidths];

      if (!tokenWidths[index]) {
        tokenWidths[index] = width;
      }

      return { tokenWidths };
    });
  }

  private setWidth() {
    const lineWidths = this.calculateLineWidthsWithoutOrphan(
      this.props.width || 500
    );

    // adding 5 to the calculated width because token come in at different values during rendering
    // TODO: for 2.0 look into whether there may be a better approach
    // https://jira.lexialearning.com/browse/LOBO-13804
    this.setState({
      calculatedWidth: Math.max(...lineWidths) + 5,
      isSingleLine: lineWidths.length === 1
    });
  }

  private calculateLineWidthsWithoutOrphan(width: number): number[] {
    const { tokenWidths } = this.state;

    switch (tokenWidths.length) {
      case 1:
      case 2:
        return [width];
      case 3:
        return this.calculateLineWidths(width);
      // For more than 3 tokens, loop through calculating with diminishing width
      // until a non-orphan last line is achieved
      default:
        // Amount by which the width will be reduced in the loop, if needed
        const decrement = 5;
        const maxLoops = width / decrement;
        let lineWidths = [width + decrement];

        for (let _i = 0; _i < maxLoops; _i += 1) {
          const newMaxWidth = Math.max(...lineWidths) - decrement;
          lineWidths = this.calculateLineWidths(newMaxWidth);
          if (last(lineWidths) !== last(tokenWidths)) {
            break;
          }
        }

        return lineWidths;
    }
  }

  private calculateLineWidths(width: number): number[] {
    let currentLine = [...this.state.tokenWidths];
    const lineWidths: number[] = [];
    let nextLine: number[] = [];

    const maxLoops = 100;
    let loopsCount = 0;
    while (sum(currentLine) > width && loopsCount < maxLoops) {
      loopsCount += 1;
      nextLine.push(currentLine.pop()!);
      if (sum(currentLine) <= width) {
        lineWidths.push(sum(currentLine));
        currentLine = reverse(nextLine);
        nextLine = [];
      }
    }

    if (loopsCount >= maxLoops) {
      void Logger.logError(
        new LexiaError(
          'WARNING: CenterLeftAlignWrap loop exited after 100 loops',
          CenterLeftAlignWrap.displayName,
          CenterLeftAlignError.TooManyLoops
        ).withContext(this.state)
      );
    }

    lineWidths.push(sum(currentLine));

    return lineWidths;
  }

  public render() {
    const { tokenArray, renderToken, tokenProps, testId } = this.props;
    const { calculatedWidth, isSingleLine } = this.state;

    const styles = CenterLeftAlignWrapStyles.build(
      calculatedWidth,
      isSingleLine
    );

    return (
      <FadeAnimation
        renderWhenHidden={true}
        shouldShow={!!calculatedWidth}
        testId={testId}
      >
        <Row
          style={styles.textContainer}
          testId={`${CenterLeftAlignWrap.displayName}_row`}
        >
          {tokenArray.map((token, idx) => (
            <View
              key={`token${idx}`}
              onLayout={
                // eslint-disable-next-line react/jsx-no-bind
                (e: Types.ViewOnLayoutEvent) => {
                  this.updateTokenWidth(idx, e.width);
                }
              }
            >
              {renderToken(token, idx, tokenProps)}
            </View>
          ))}
        </Row>
      </FadeAnimation>
    );
  }
}

export enum CenterLeftAlignError {
  TooManyLoops = 'TooManyLoops'
}
