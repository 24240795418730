import {
  AnimatedRow,
  AnimatedView,
  RoundedTabPosition,
  RoundedTabSvg,
  Row,
  Types,
  View
} from 'common-ui';
import { FaceStyleOptions } from '../face-style-options/FaceStyleOptions';
import { HairStyleOptions } from '../hair-style-options/HairStyleOptions';
import { AvatarEditorStyles } from './AvatarEditor.styles';
import { useEffect, useRef } from 'react';
import { SubmitAvatarButton } from '../submit-avatar-button/SubmitAvatarButton';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarEditorAction, AvatarEditorSelector } from '../redux';
import { ENCOUNTERS_TO_ENABLE_REEDIT } from '../redux/avatar-editor-redux.model';

export interface IAvatarEditorAnimatedStyles {
  fullViewComponentsAnimated: Types.AnimatedViewStyle;
  editorAnimated: Types.AnimatedViewStyle;
  selectionContainerAnimated: Types.AnimatedViewStyle;
}

export interface IAvatarEditorProps {
  animatedStyles: IAvatarEditorAnimatedStyles;
  /**
   * If true, full AvatarEditor should be shown,
   * otherwise only face and skin selectors are shown
   */
  showFull: boolean;
}

export function AvatarEditor({ animatedStyles, showFull }: IAvatarEditorProps) {
  const dispatch = useDispatch();
  const selectorStyles = useRef(new AvatarEditorStyles());
  const styles = selectorStyles.current.get();

  const hasAvatarChanged = useSelector(
    AvatarEditorSelector.getHasAvatarChanged
  );

  useEffect(() => {
    if (hasAvatarChanged) {
      dispatch(
        AvatarEditorAction.setEncountersUntilEditable(
          ENCOUNTERS_TO_ENABLE_REEDIT
        )
      );
    }
  }, [dispatch, hasAvatarChanged]);

  return (
    <AnimatedRow
      style={styles.editor}
      animatedStyle={animatedStyles.editorAnimated}
      testId={AvatarEditor.displayName}
    >
      <Row style={styles.selectionContainerBorder}>
        <AnimatedRow
          style={styles.selectionContainer}
          animatedStyle={animatedStyles.selectionContainerAnimated}
        >
          <Row style={styles.faceSelectionContainer}>
            <FaceStyleOptions />
          </Row>
          {showFull && (
            <AnimatedRow
              style={styles.hairSelectionContainer}
              animatedStyle={animatedStyles.fullViewComponentsAnimated}
            >
              <View style={styles.divider} />
              <HairStyleOptions />
            </AnimatedRow>
          )}
        </AnimatedRow>
      </Row>
      {showFull && (
        <AnimatedView
          style={styles.submitButtonContainer}
          animatedStyle={animatedStyles.fullViewComponentsAnimated}
        >
          <RoundedTabSvg
            opacity={styles.roundedTab.opacity}
            position={RoundedTabPosition.Right}
          />
          <SubmitAvatarButton hasAvatarChanged={hasAvatarChanged} />
        </AnimatedView>
      )}
    </AnimatedRow>
  );
}
AvatarEditor.displayName = 'AvatarEditor';
