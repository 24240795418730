import {
  TaskEvaluationResult,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnimatedColumn, Row } from 'common-ui';
import {
  WorkArea,
  WorkAreaLayout
} from 'common-ui/components/work-area/WorkArea';
import { ProgramContextSelector } from 'curriculum-services';
import { Services } from 'services/Services';
import { ThemeType, withTheme } from 'theme';
import { TaskAction, TaskSelector } from '../redux';
import { ITaskActionUpdateCanSubmitPayload } from '../redux/Task.action';
import { TaskRegistry } from '../registry';
import { ITaskProps, TaskReactAnimationName } from '../task-component.model';
import { InstructionFrameSvg } from './InstructionFrame.lx-svg';
import { TaskWorkAreaAnimatedStyles } from './TaskWorkArea.animated-styles';

export interface ITaskWorkAreaProps
  extends ITaskWorkAreaPublicProps,
    Partial<ITaskProps> {
  isEntryTask: boolean;
  isExitTask: boolean;
  themeType: ThemeType;
}
export interface ITaskWorkAreaPublicProps {
  taskRegistry: TaskRegistry;
}

export class TaskWorkAreaComponent extends React.PureComponent<ITaskWorkAreaProps> {
  public static readonly displayName = 'TaskWorkArea';

  private readonly animatedStyles: TaskWorkAreaAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: ITaskWorkAreaProps) {
    super(props);

    this.animatedStyles = new TaskWorkAreaAnimatedStyles(props);
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { instructionEntry, instructionExit, standardEntry, standardExit } =
      this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: TaskReactAnimationName.InstructionUnitEntry,
        animation: instructionEntry
      },
      {
        name: TaskReactAnimationName.InstructionUnitExit,
        animation: instructionExit
      },
      {
        name: TaskReactAnimationName.StandardRoundEntry,
        animation: standardEntry
      },
      {
        name: TaskReactAnimationName.StandardRoundExit,
        animation: standardExit
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public componentDidUpdate(prevProps: ITaskWorkAreaProps): void {
    this.maybeResetAnimatedValues(this.props, prevProps);
  }

  private maybeResetAnimatedValues(
    props: ITaskWorkAreaProps,
    prevProps: ITaskWorkAreaProps
  ) {
    const isNewEntryTask = props.isEntryTask && !prevProps!.isEntryTask;
    const changedThemeType = prevProps!.themeType !== props.themeType;

    // Reset values if just updated to new entry task, or changed themeType
    if (isNewEntryTask || changedThemeType) {
      this.animatedStyles.resetAnimatedValues(this.props);
    }
  }

  public render() {
    const {
      evaluationResult,
      themeType,
      isInteractive,
      onEvaluated,
      phase,
      taskContent,
      taskRegistry,
      themeSize,
      updateCanSubmit,
      workAreaLayout
    } = this.props;

    if (!taskContent) {
      return null;
    }

    const { taskType } = taskContent;
    const registration =
      taskType && taskRegistry.has(taskType)
        ? taskRegistry.get(taskType)
        : taskRegistry.get(TaskTypeName.Placeholder);
    const Task = registration.Component;

    const styles = this.animatedStyles.get();

    return (
      <AnimatedColumn
        animatedStyle={styles.animatedContainer.animated}
        style={styles.animatedContainer.static}
        testId={TaskWorkAreaComponent.displayName}
      >
        <WorkArea layout={workAreaLayout}>
          {themeType === ThemeType.Instruction && <InstructionFrameSvg />}
          <Task
            taskContent={taskContent}
            evaluationResult={evaluationResult}
            isInteractive={isInteractive}
            key={taskContent.sysId}
            onEvaluated={onEvaluated}
            phase={phase}
            themeSize={themeSize}
            updateCanSubmit={updateCanSubmit}
            workAreaLayout={workAreaLayout}
          />
          {!isInteractive && <Row style={styles.interactionPreventionScreen} />}
        </WorkArea>
      </AnimatedColumn>
    );
  }
}

function mapStateToProps(state: unknown) {
  const context = ProgramContextSelector.getRoundContextMaybe(state);
  const isEntryTask = !!(context && !context.roundNode.prev);
  const isExitTask = !!context?.atLastRound;
  const taskProps = TaskSelector.getBasicInfoForComponent(state);
  const isSeeSpeak = taskProps?.taskContent.taskType === TaskTypeName.SeeSpeak;

  return {
    ...taskProps,
    evaluationResult: context?.lastAttemptMaybe?.result,
    isEntryTask,
    isExitTask,
    workAreaLayout:
      context?.hasOnscreenCharacter && !isSeeSpeak
        ? WorkAreaLayout.TwoColumn
        : WorkAreaLayout.FullWidth
  };
}

const mapDispatchToProps = {
  onEvaluated: <A extends object = object>(
    result: TaskEvaluationResult,
    answer: A
  ) => TaskAction.evaluated({ answer, result }),
  updateCanSubmit: (cs: ITaskActionUpdateCanSubmitPayload) =>
    TaskAction.updateCanSubmit(cs)
};

export const TaskWorkArea = withTheme(
  connect(mapStateToProps, mapDispatchToProps)(TaskWorkAreaComponent)
);

export const TaskWorkAreaPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
