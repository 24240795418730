import { DndActionType, IDndReduxState } from './dnd-redux.model';
import { DndActions } from './Dnd.action';

const dndInitialState: IDndReduxState = {
  dropTargetPosition: undefined,
  forcedDragLayer: undefined,
  isDraggingOrDropping: false
};

export function dndReducer(
  state = dndInitialState,
  action: DndActions
): IDndReduxState {
  switch (action.type) {
    case DndActionType.DropToPosition:
      return {
        ...state,
        dropTargetPosition: action.payload.position
      };

    case DndActionType.SetIsDraggingOrDropping:
      return {
        ...state,
        isDraggingOrDropping: action.payload
      };

    case DndActionType.SetForcedDragLayer:
      return {
        ...state,
        forcedDragLayer: action.payload.layer,
        isDraggingOrDropping: !!action.payload.layer
      };

    case DndActionType.Reset:
      return dndInitialState;

    default:
      return state;
  }
}
dndReducer.displayName = 'dndReducer';

export const DndReducerPrivates = { initialState: dndInitialState };
