import { Position } from '@lexialearning/common-ui';
import { sumBy } from 'lodash';
import memoizeOne from 'memoize-one';
import { ILineConfiguration } from '../helpers';

export class TextOrderingContentStyles {
  public static readonly build = memoizeOne(
    (lineConfigurations: ILineConfiguration[], maxLineWidth: number) => {
      const segmentsContainerHeight = sumBy(
        lineConfigurations,
        lineConfiguration => lineConfiguration.layout.height
      );

      return {
        cancelZone: {
          bottom: -100,
          left: -62,
          position: Position.Absolute,
          right: -62,
          top: -100
        },
        linesContainer: {
          bottom: 0,
          left: 0,
          position: Position.Absolute,
          right: 0,
          top: 0
        },
        orderingContainer: {
          marginTop: lineConfigurations.length === 1 ? 50 : 0
        },
        segmentsContainer: {
          height: segmentsContainerHeight,
          marginBottom: 20,
          paddingHorizontal: 30,
          width: maxLineWidth
        }
      };
    }
  );
}
