import { IScreenplay } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, random, sample } from 'lodash';
import { Music } from 'audio/music';
import { UnitAction } from 'curriculum-services';
import {
  LevelReactAnimationName,
  LevelSceneAnimationName,
  LevelSceneElementName
} from 'feature-areas/levels';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { LevelIdleVariantsCount } from '../../../levels/level-scene/LevelScene';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { LevelScreenplayId } from './level-screenplay-builders.model';

export interface ILevelOutroScreenplayDeps {
  characterNamePool: IScreenplay[];
  selectedActNumber: number;
}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Level+to+Showcase+Transition
 */
export class LevelOutroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'LevelOutroScreenplayBuilder';

  public static createFor(
    deps: ILevelOutroScreenplayDeps
  ): LevelOutroScreenplayBuilder {
    return new LevelOutroScreenplayBuilder(deps);
  }

  private constructor(deps: ILevelOutroScreenplayDeps) {
    super(LevelScreenplayId.Outro);

    const { selectedActNumber, characterNamePool } = deps;

    this.disableUtilityBar()
      .fadeOutReactElements()
      .playActSelecion(characterNamePool, selectedActNumber)
      .loadUnit()
      .animateCharacterIdle(selectedActNumber);
  }

  private fadeOutReactElements(): LevelOutroScreenplayBuilder {
    this.builder.addReactAnimation(LevelReactAnimationName.FadeOut, {
      concurrent: true
    });

    return this;
  }

  private playActSelecion(
    characterNamePool: IScreenplay[],
    selectedActNumber: number
  ): LevelOutroScreenplayBuilder {
    const characterNameVo = cloneDeep(sample(characterNamePool)?.actions[0]);
    if (!characterNameVo) {
      throw new LexiaError(
        'Missing character name voiceover',
        LevelOutroScreenplayBuilder.displayName,
        LevelOutroScreenplayBuilderError.NoCharacterNameVo
      );
    }

    characterNameVo.data = {
      ...characterNameVo.data,
      speaker: {
        sceneId: SceneName.Level,
        speakerId: LevelSceneElementName.buildCharacter(selectedActNumber)
      }
    };

    this.builder
      .addAction(characterNameVo, { concurrent: true })
      .addStormAnimation(
        {
          blendTimeSeconds: 0.25,
          name: LevelSceneAnimationName.Character.IntroWave,
          targetElement:
            LevelSceneElementName.buildCharacter(selectedActNumber),
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.ActIntro }, { concurrent: true })
      .addStormAnimation({
        name: LevelSceneAnimationName.Root.buildCharacterSelection(
          selectedActNumber
        ),
        targetScene: SceneName.Level
      });

    return this;
  }

  private loadUnit(): LevelOutroScreenplayBuilder {
    this.builder.addReduxAction(UnitAction.load.request());

    return this;
  }

  private animateCharacterIdle(
    selectedActNumber: number
  ): LevelOutroScreenplayBuilder {
    this.builder.addStormAnimation(
      {
        blendTimeSeconds: 0.5,
        loop: true,
        name: LevelSceneAnimationName.Character.buildIdle(
          random(1, LevelIdleVariantsCount)
        ),
        targetElement: LevelSceneElementName.buildCharacter(selectedActNumber),
        targetScene: SceneName.Level
      },
      { concurrent: true }
    );

    return this;
  }
}

export enum LevelOutroScreenplayBuilderError {
  NoCharacterNameVo = 'NoCharacterNameVo'
}
