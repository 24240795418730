/* eslint-disable max-classes-per-file */
import { sortBy } from 'lodash';
import { StateObservable } from 'redux-observable';
import { from, merge, Observable } from 'rxjs';
import { find, map } from 'rxjs/operators';
import { AppConfigAction } from 'app-config';
import {
  LevelAction,
  LevelActionLoad,
  LevelsCompletedAction,
  LevelSelector,
  ProgramContextSelector
} from 'curriculum-services';
import { LevelsStatusFactory } from 'curriculum-services/levels-completed/epics/LevelsStatus.factory';
import { EducatorSelector } from 'feature-areas/educator';
import { ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { DeepLinkAction } from './redux/DeepLink.action';
import { AppState } from 'services';

export interface IDeepLinkDeps {
  state$: StateObservable<AppState>;
  preparedScenes: PreparedScenes;
}

export type DeepLinkOutputAction = ScreenplayActionPlay;

export class DeepLinkObservableFactoryBase<T extends IDeepLinkDeps> {
  public levelId = '';

  public readonly dispatches: unknown[] = [];

  public readonly deferredDispatches$: Observable<unknown>[] = [];

  public get observable(): Observable<any> {
    return merge(from(this.dispatches), ...(this.deferredDispatches$ || []));
  }

  constructor(protected readonly deps: T) {
    this.dispatches.push(
      AppConfigAction.applyOverrides({
        overrides: [
          { key: 'studentApi.disableWrites', value: true },
          { key: 'devTools.enabled', value: true },
          { key: 'logger.logRocket.enabled', value: false }
        ]
      })
    );
    this.setBootstrappingDeferredDispatch().awaitLevelThenLoadLevelsCompleted();
  }

  private setBootstrappingDeferredDispatch() {
    const { state$ } = this.deps;
    const deepLinkLevelLoadComplete$ = state$.pipe(
      find(() => this.isDone()),
      map(() => DeepLinkAction.positionSetupComplete())
    );
    this.deferredDispatches$.push(deepLinkLevelLoadComplete$);

    return this;
  }

  protected isDone(): boolean {
    const { state$ } = this.deps;
    const pos = ProgramContextSelector.getPosition(state$.value);

    return !!pos.activityPositions.length;
  }

  /**
   * Monitor state changes until level is loaded
   * Then load mock completed levels - with all prior levels shown as completed
   */
  private awaitLevelThenLoadLevelsCompleted() {
    const { state$ } = this.deps;
    const loadCompletdLevels$ = state$.pipe(
      map(() => LevelSelector.getLevelNumberMaybe(state$.value)),
      find(levelNumber => levelNumber !== undefined),
      map(levelNumber =>
        ProgramContextSelector.isOnboardingOrPlacement(state$.value)
          ? undefined
          : levelNumber
      ),
      map((levelNumber: number) => {
        const completedLevels = this.createCompletedLevels(levelNumber);

        return LevelsStatusFactory.create(
          levelNumber,
          ProgramContextSelector.getFinalLevel(state$.value),
          completedLevels,
          // set all completed levels as certificate unseen
          completedLevels
        );
      }),
      map(completedLevels =>
        LevelsCompletedAction.load.success(completedLevels)
      )
    );
    this.deferredDispatches$.push(loadCompletdLevels$);

    return this;
  }

  private createCompletedLevels(currentLevelNumber = 1): number[] {
    return Array.from({ length: currentLevelNumber - 1 }).map(
      (_, idx) => idx + 1
    );
  }
}

export type DeepLinkHelperOutputAction = DeepLinkOutputAction | LevelActionLoad;

export class DeepLinkObservableFactoryBaseWithLevelsLoading<
  T extends IDeepLinkDeps = IDeepLinkDeps
> extends DeepLinkObservableFactoryBase<T> {
  /**
   * Monitor state changes until levels are loaded (kicked off by profile load success).
   * Then load either the argument level or load the first level if no argument
   */
  protected awaitLevelsThenLoadLevel(getLevelId?: (state: unknown) => string) {
    const { state$ } = this.deps;
    const loadLevel$ = state$.pipe(
      map(() => EducatorSelector.getLevels(state$.value)),
      find(levels => !!levels?.length),
      map(
        levels =>
          (getLevelId && getLevelId(state$.value)) ||
          sortBy(levels, 'title')[0]?.sysId
      ),
      map(sysId => LevelAction.load.request({ sysId }))
    );
    this.deferredDispatches$.push(loadLevel$);

    return this;
  }
}
