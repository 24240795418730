import {
  IActivityPosition,
  IPlacementFormWithPool,
  IPlacementStepWithPool,
  IPlacementWithPool,
  IProgramPosition,
  IRoundReference,
  IUnitSnipped
} from '@lexialearning/lobo-common';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  IContentBase,
  IContentProvider,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { nth } from 'lodash';
import { ActivityPositionBuilder, ProgramMode } from 'curriculum-services';
import {
  IDeepLinkSubject,
  SubjectType
} from '../position-builder-deep-link.model';
import { PlacementContentLoader } from './PlacementContentLoader';

export class PlacementDeepLinkPositionBuilder {
  public static readonly displayName = 'PlacementDeepLinkPositionBuilder';

  private readonly loader: PlacementContentLoader;

  public constructor(
    /**
     * sysId and contentType of subject's ancestors starting with Placement and
     * all the way to the subject itself.
     */
    private readonly ancestors: IContentBase[],
    contentProvider: IContentProvider,
    configProvider: IConfigProvider
  ) {
    this.loader = new PlacementContentLoader(configProvider, contentProvider);
  }

  public async build(subject: IDeepLinkSubject): Promise<IProgramPosition> {
    const placement = await this.loader.load();

    const onboardingRound: IRoundReference | undefined =
      subject.type === SubjectType.OnboardingRound
        ? { sysId: subject.id }
        : undefined;
    const onboardingPosition = ActivityPositionBuilder.create({
      activityId: placement.sysId,
      encounterId: placement.onboarding.sysId
    }).withActiveUnitPosition(
      placement.onboarding,
      onboardingRound
    ).activityPosition;

    const placementFormPosition = this.createPlacementFormPosition(
      placement,
      subject
    );

    const activeActivityId =
      subject.programMode === ProgramMode.Onboarding
        ? onboardingPosition.activityId
        : placementFormPosition.activityId;

    if (subject.programMode === ProgramMode.Placement) {
      onboardingPosition.isComplete = true;
    }

    return {
      activeActivityId,
      activityPositions: [onboardingPosition, placementFormPosition],
      isComplete: false,
      levelId: placement.sysId
    };
  }

  private createPlacementFormPosition(
    placement: IPlacementWithPool,
    subject: IDeepLinkSubject
  ): IActivityPosition {
    const placementUnitId = this.determineTargetPlacementUnitId(
      subject,
      placement
    );

    const { placementForm, unit } = this.findPlacementFormAndUnit(
      placement,
      placementUnitId
    );

    const roundId =
      subject.type === SubjectType.PlacementRound
        ? { sysId: subject.id }
        : undefined;

    return ActivityPositionBuilder.create({
      activityId: placementForm.sysId,
      encounterId: placementForm.sysId
    }).withActiveUnitPosition(unit, roundId).activityPosition;
  }

  private determineTargetPlacementUnitId(
    subject: IDeepLinkSubject,
    placement: IPlacementWithPool
  ): string {
    switch (subject.type) {
      case SubjectType.PlacementRound:
        return this.getParentUnitId(subject);

      case SubjectType.PlacementUnit:
        return subject.id;

      case SubjectType.OnboardingRound:
      case SubjectType.OnboardingUnit:
        // set to grade 3Plus when deep linking into onboarding
        return placement.gradeFormPoolMap.g3Plus[0].unitPool[0].sysId;

      default:
        throw new LexiaError(
          `Unexpected subject type ${subject.type}`,
          PlacementDeepLinkPositionBuilder.displayName,
          PlacementDeepLinkPositionBuilderError.SubjectTypeUnexpected
        );
    }
  }

  private getParentUnitId(subject: IDeepLinkSubject): string {
    const parenUnit = nth(this.ancestors, -2);
    if (!parenUnit || parenUnit.contentType !== LoboContentType.Unit) {
      throw new LexiaError(
        'Unexpected placement ancestry!',
        PlacementDeepLinkPositionBuilder.displayName,
        PlacementDeepLinkPositionBuilderError.PlacementAncestryUnexpected
      ).withContext({ ancestors: this.ancestors, subject });
    }

    return parenUnit.sysId;
  }

  private findPlacementFormAndUnit(
    placement: IPlacementWithPool,
    unitId: string
  ): IPlacementUnitInfo {
    const poolMap = placement.gradeFormPoolMap;

    const unitInfo = poolMap.g3Plus
      .concat(poolMap.k2)
      .reduce(
        (result: IPlacementUnitInfo | undefined, test) =>
          result ?? this.findPlacementUnit(test, unitId),
        undefined
      );

    if (!unitInfo) {
      throw new LexiaError(
        `Unit ID "${unitId} not found on any placement form unit pool`,
        PlacementDeepLinkPositionBuilder.displayName,
        PlacementDeepLinkPositionBuilderError.PlacementUnitIdNotFound
      );
    }

    return unitInfo;
  }

  /**
   * Search through all units of all placement steps of the placement form
   * (aka test). This searches recursively by navigating the linked list of
   * placement steps (following each one's nextPlacementStep rule)
   */
  private findPlacementUnit(
    placementForm: IPlacementFormWithPool,
    unitId: string,
    placementStep?: IPlacementStepWithPool
  ): IPlacementUnitInfo | undefined {
    const step = placementStep ?? placementForm;
    const unit = step.unitPool.find(u => u.sysId === unitId);
    if (unit) {
      return {
        placementForm,
        unit
      };
    }

    const nextPlacementStepPool = step.rules.find(r => r.nextPlacementStepPool)
      ?.nextPlacementStepPool;

    if (!nextPlacementStepPool) {
      return undefined;
    }

    return nextPlacementStepPool.reduce(
      (result: IPlacementUnitInfo | undefined, s) =>
        result ?? this.findPlacementUnit(placementForm, unitId, s),
      undefined
    );
  }
}

interface IPlacementUnitInfo {
  placementForm: IPlacementFormWithPool;
  unit: IUnitSnipped;
}

export enum PlacementDeepLinkPositionBuilderError {
  PlacementAncestryUnexpected = 'PlacementAncestryUnexpected',
  PlacementUnitIdNotFound = 'PlacementUnitIdNotFound',
  SubjectTypeUnexpected = 'SubjectTypeUnexpected'
}
