// istanbul ignore file - static SVG, no need for tests
import { Path, Svg } from 'common-ui/components/svg';

export function NotConnectedSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      height={200}
      width={480}
      viewBox="0 0 187.5 176.7"
    >
      <Path
        fill="#fab45f"
        d="M51.7 78.3a91.2 91.2 0 01-1.3-12 67.1 67.1 0 014.9-28.5 50.7 50.7 0 0111.4-17.3A35.6 35.6 0 0184 10.7 47.6 47.6 0 0191 10a3.1 3.1 0 002-.8 38.6 38.6 0 018.8-6 30 30 0 0112-3 46.1 46.1 0 0112.7 1.2 2.6 2.6 0 01.4.2c-2 .8-4 1.6-6 2.6a25 25 0 00-8.8 6.8l-2.8 3.9a2.7 2.7 0 00.3.3 46.7 46.7 0 0112.3 11 56.5 56.5 0 017 11.4 65.2 65.2 0 014.7 13.4 59.2 59.2 0 011.5 10.4l.2 3.3c0 2.1.2 4.2 0 6.3 0 2.3-.3 4.7-.5 7a25 25 0 00-3.3 1.1A20.6 20.6 0 00122 89H55.4c-.3 0-.5.2-.7 0a7 7 0 01-.5-1l-1-3.8-.7-2.3zM70.4 47a2.3 2.3 0 00.3.5 5.5 5.5 0 003.7 1.3 11.8 11.8 0 007.6-2.6c1.9-1.3 3.4-3 3.7-5.4.3-1.8.2-2.7-1.2-3.4-.2-.8-.8-1-1.5-.7a29 29 0 00-8.1 4.3 21.2 21.2 0 00-4.7 4.5c-.5.7-.5 1 .2 1.5zm39.6-25l-.2-.1a24.9 24.9 0 00-2.6-2.4 13 13 0 00-7.7-2 9.8 9.8 0 00-7.1 3c-.7.8-.7 1.4.2 2a6.8 6.8 0 001.6 1.3c1.8.7 3.6 1.2 5.5 1.7l11.3 3.3c1.1.4 1.8-.3 1.7-1.5a8.9 8.9 0 00-2.5-5.1zm2.6 32.2a24 24 0 00-.9-7.4 19 19 0 00-5.6-9.9 8 8 0 00-9.8-.8 14.3 14.3 0 00-5.5 6.8 29.4 29.4 0 00-1 20.1A17.4 17.4 0 0095 72a8.2 8.2 0 0010.7.3 14 14 0 003.7-4.4 25.4 25.4 0 003-13.5zm-50.3-.2a27 27 0 001 7.9 19 19 0 005 9 7.9 7.9 0 009.8 1.3 11.6 11.6 0 003.6-3.4 26.4 26.4 0 004-16.9 5.8 5.8 0 000-.8c-7.8 1.7-15.6 2-23.4 3zM101.2 78l-5.8.7A37 37 0 0082 84.4c-.4.2-.9.5-.5 1s.8.3 1.2 0c2.2-1.1 4.4-2.5 6.7-3.5a27 27 0 0117-1.9c.4.1.9.1 1-.4s-.2-.8-.7-.9l-5.6-.8z"
      />
      <Path
        fill="#e7e0da"
        d="M52.3 89h3.1c.3.2-.3 0 0 0H122a15.7 15.7 0 00-.5 2.4 1.6 1.6 0 00.6 1.2c.5.4 1.2 0 1.5-.8l1.4-2.9h2.9l-.5 7.5-.5 7.7-.6 10.5-.5 7.2-.7 10.5-.6 9.8-.6 10.5c0 .6-.3.8-.9.7l-10.5-.1-13.6-.2-15-.1-15.5-.2-23.2-.2h-1.4v-.6l.4-4.4.6-7.1.3-4.2.7-7.4.3-4.5.6-7 .4-4.4.6-7.3.3-4.6.7-6.8.1-2.6.2-1.9c0-.6.2-.8.8-.7l1.3 3a11.2 11.2 0 001.4 2 1 1 0 001.4.1 1 1 0 00.2-1.3l-.3-.6-1.4-3.2m31.3 7.2A24.4 24.4 0 0072 99.5 27.1 27.1 0 0057.2 121a22 22 0 003.5 15.3c5.9 8.8 14.4 12 24.6 10.8a27.6 27.6 0 0018.8-10.8 25.4 25.4 0 005.7-17.8 22.7 22.7 0 00-6-14.3c-5-5.5-11.3-8-20.2-8z"
      />
      <Path
        fill="#9b9288"
        d="M136.6 146.2a2.7 2.7 0 01-.7.2H126.1l.3-4.6.7-10.7.4-7.2.7-10.3.3-6.8.3-1.8a57.2 57.2 0 005.4-9.7l1.5-3.6.2 4.5.2 12.2.1 12.2.2 11 .2 10.5v4.1z"
      />
      <Path
        fill="#a66c23"
        d="M142.7 87.6a20.1 20.1 0 0012.1-2.4 14.7 14.7 0 007.5-10 43 43 0 00.8-10.5v-3.4c0-.7.2-1.1 1-1l3.1.7a30.3 30.3 0 0013.9-.7 17.1 17.1 0 013.6-.4 1.9 1.9 0 011.8 1.1c.6 1 .4 1.9-.7 2.4a16.7 16.7 0 01-3.8 1.2c-1.8.3-3.6.3-5.4.4a1.6 1.6 0 00-.8.2 12.4 12.4 0 001.4.2c1.8 0 3.7 0 5.6.2a12.4 12.4 0 013.4.7 2 2 0 011.4 2.2c-.2 1-1 1.3-2 1.6a19 19 0 01-13.3-2c-.8-.3-1.7-.8-2.5-1.3l-.2.2.7.5a23 23 0 006.8 3.7 8.6 8.6 0 011.7.8 1.9 1.9 0 01.9 2.6 2 2 0 01-2.5.8 21.3 21.3 0 01-11.8-9.1c0-.2-.2-.3-.4-.6v1.5a30.2 30.2 0 01-2 12.3 19.8 19.8 0 01-7.9 9.9 16.9 16.9 0 01-13 2h-.5l-2.7-1-1.3-.3-1.3-.6-1.6-1.5c-.6-.5-.4-1-.1-1.6a1 1 0 011.3-.6l1.4.4 2.5.8zM49.7 89c-.5-.1-.7.2-.7.7l-.2 1.8c-6.6 2.6-12.6 1.2-18-3a29.3 29.3 0 01-9-11.7c0-.3-.2-.6-.4-1.1l-.6 3.2a21.2 21.2 0 01-6 10.9c-1.2 1.1-2.5 1-3.2-.3a1.8 1.8 0 01-.1-1.4 16.8 16.8 0 011.6-2.2l4-5.5a3.7 3.7 0 00.4-1 1.4 1.4 0 01-.2 0 7.5 7.5 0 00-.5.5 22 22 0 01-8.6 7.7A14.7 14.7 0 014 88.9a1.7 1.7 0 01-1.8-2.4A4.5 4.5 0 014.3 84l4.5-2.3 1.6-1a1.4 1.4 0 01.2 0l1.7-1.2v-.2l-1.9 1.1-.2.1-6 2.1a9.5 9.5 0 01-2.4.2c-.7 0-1.3 0-1.6-.8a2 2 0 01.3-2.2 4.3 4.3 0 011.6-1l5.3-1.5A28 28 0 0019 71a2.8 2.8 0 011-.7 1.1 1.1 0 011 .5l1.4 3a45.7 45.7 0 005 8.7c4.1 5.4 9.6 7.2 16.1 6a44.5 44.5 0 005.5-1.8l.6 2.3z"
      />
      <Path
        fill="#fe8847"
        d="M134.8 78l.6-7v-6.3l-.2-3.3a59.2 59.2 0 00-1.6-10.5 65.2 65.2 0 00-4.8-13.4 56.5 56.5 0 00-7-11.4 46.7 46.7 0 00-12.3-11 2.7 2.7 0 01-.3-.3l2.8-4a25 25 0 018.8-6.8c2-1 4-1.7 6-2.6l3.9.8v.2a29.8 29.8 0 00-17.4 13.4l3 2.1a49.6 49.6 0 0112.8 13.7 65 65 0 019.5 25.3 74 74 0 01.3 20.8l-.1.8a8.5 8.5 0 00-4-.6z"
      />
      <Path
        fill="#b7aca1"
        d="M126 146l.4-4.2.7-10.6.4-7.3.7-10.3.3-6.7c0-.7.2-1.3.3-2a3.5 3.5 0 01-.1-.7l.5-7.8V96c0-1.3 0-2.7.3-4 .2-1.4-.7-2.3-1.6-3.1l-.5 7.5-.5 7.7-.7 10.5-.4 7.2-.7 10.5-.6 9.8-.6 10.4c0 .7-.3.8-.9.8l-10.5-.1-13.6-.2-15-.2-15.6-.1-23.1-.2h-1.4c.5.8 1 1.5 2 1.5h18.6a4 4 0 01.8.2h1l15.3.2 19.2.1h4.2l6.6.2h5.5l7.8.1c.4 0 .8 0 .8-.6l.3-6.1z"
      />
      <Path
        fill="#a66b23"
        d="M61.4 154.1h3a4 4 0 01.7.2l-6.5 17.4c-.3 1-.6 1-1.5.6-1.6-.7-3.1-1.6-4.7-2.2l-19.6-8.3a16.1 16.1 0 01-1.4-.6c-.7-.3-.7-.7-.2-1.2 1.3-1.7 3.3-1.8 5.1-2a21.9 21.9 0 0113.5 3.8 28 28 0 017.5 6.6 2.1 2.1 0 00.3.3l3.8-14.6z"
      />
      <Path
        fill="#a66b22"
        d="M101.3 154.6h3.6l-1.6 6.2-2 8.7v.2h.3a18.5 18.5 0 0112-2 17.4 17.4 0 0110 4.6 6.2 6.2 0 012 3c.2 1-.2 1.6-1.2 1.4l-12.7-2.3-11.6-2.6c-.5 0-.7-.3-.7-.9l.8-5.8 1-10.5z"
      />
      <Path
        fill="#b6aba1"
        d="M136.3 89.5l1.2.5v1.4a5 5 0 00.3.8v11.3l.2 15 .1 10.7.2 12.2.1 2.3c0 1.4-.5 2-1.9 2.5v-4l-.1-10.6-.2-11-.2-12.2-.1-12.1-.2-4.6.6-2.2z"
      />
      <Path
        fill="#5a534c"
        d="M134.8 78a8.5 8.5 0 014 .5c3.5 2 4.4 5.3 4 9l-3-.5a9 9 0 000-1.3c0-1.4-.3-2.8-1.5-3.8-1.2-1.2-2.6-1.2-4.1-.8a6.6 6.6 0 00-1 .3 17.1 17.1 0 00-8.2 7.5l-1.3 2.9c-.4.7-1 1.2-1.6.8a1.6 1.6 0 01-.6-1.2 15.7 15.7 0 01.6-2.5 20.6 20.6 0 019.4-9.8 25 25 0 013.3-1.2z"
      />
      <Path
        fill="#5a544e"
        d="M49.7 89l-.6-2.2.5-4.1a9.9 9.9 0 012-4.4l1 3.5-1 3.4.6 3.8 1.5 3.1c0 .3.2.4.3.7a1 1 0 01-.2 1.3 1 1 0 01-1.4-.1 11.2 11.2 0 01-1.4-2l-1.3-3zM137.8 96.5v-4.3l1-1.7 2.7.8a17 17 0 01-3.7 5.2z"
      />
      <Path
        fill="#fefdfd"
        d="M112.6 54.3a25.4 25.4 0 01-3.1 13.5 14 14 0 01-3.7 4.4 8.2 8.2 0 01-10.7-.2 17.4 17.4 0 01-5.3-8.9 29.4 29.4 0 011-20.1 14.3 14.3 0 015.5-6.8 8 8 0 019.8.8c3 2.7 4.7 6.1 5.7 10a24 24 0 01.8 7.3zm-13 5.4h.2a6.8 6.8 0 00.8 4c.4.6.8 1.2 1.7 1.2s1.2-.7 1.5-1.3a9 9 0 00.4-5.3 6 6 0 00-.8-2.2c-.9-1.3-2.2-1.3-2.9.1a21.8 21.8 0 00-1 3.5z"
      />
      <Path
        fill="#fefdfc"
        d="M62.3 54.2c7.8-1 15.6-1.3 23.3-2.9a5.8 5.8 0 01.1.8c.3 6-.6 11.8-4 16.9a11.6 11.6 0 01-3.6 3.4 7.9 7.9 0 01-9.8-1.3 19 19 0 01-5-9 27 27 0 01-1-8zm12 8.2a33.4 33.4 0 00.9 3.6c.3.9.8 1.8 1.9 1.7s1.4-1 1.7-1.8A9.4 9.4 0 0079 61a6.7 6.7 0 00-1-2.3c-.7-1.2-2-1.2-2.7.1a20.3 20.3 0 00-1 3.4z"
      />
      <Path
        fill="#c98635"
        d="M92.6 22.7c-.9-.7-.9-1.3-.2-2a9.8 9.8 0 017.1-3 13 13 0 017.7 2 24.9 24.9 0 012.6 2.3l.2.2.2.1.5 1.3a13.6 13.6 0 01.7 2.6c.2 1.5-.5 2-1.9 1.5l-7.4-2.2-9.5-2.9z"
      />
      <Path
        fill="#c98735"
        d="M70.4 47.1c-.6-.5-.7-.8-.2-1.5A21.2 21.2 0 0175 41a29 29 0 018.1-4.3c.7-.3 1.3-.1 1.5.6a6.2 6.2 0 01-.5 4.2 11.3 11.3 0 01-5.9 5.2 9.2 9.2 0 01-5.3.8l-2.4-.5z"
      />
      <Path
        fill="#99632a"
        d="M101.2 78l5.6.7c.5.1.9.4.7 1s-.5.4-1 .3a27 27 0 00-17 1.9c-2.3 1-4.5 2.4-6.7 3.6-.4.2-.8.6-1.2 0s0-.9.5-1.1a37 37 0 0113.3-5.8c1.8-.3 3.7-.4 5.8-.6z"
      />
      <Path
        fill="#a86c22"
        d="M70.4 47.1l2.4.5a9.2 9.2 0 005.3-.8 11.3 11.3 0 006-5.2 6.2 6.2 0 00.4-4.2c1.4.8 1.5 1.7 1.2 3.5-.3 2.4-1.8 4-3.7 5.4a11.8 11.8 0 01-7.6 2.6 5.5 5.5 0 01-3.7-1.3 2.3 2.3 0 01-.3-.5zM92.6 22.7l9.5 2.8 7.4 2.2c1.4.4 2 0 2-1.5a13.6 13.6 0 00-.8-2.6l-.5-1.3a8.9 8.9 0 012.5 5c.1 1.3-.6 2-1.7 1.6-3.8-1-7.6-2.2-11.3-3.3-1.9-.5-3.7-1-5.5-1.7a6.8 6.8 0 01-1.7-1.2z"
      />
      <Path
        fill="#a66c23"
        d="M52.3 89l-.7-3.8 1.5-1 1.2 3.7c-.6.4-1 1.2-2 1.1z"
      />
      <Path fill="#a86c22" d="M110 22.2l-.2-.2z" />
      <Path
        fill="#24247b"
        d="M83.6 96.2c9 0 15.3 2.5 20.3 8a22.7 22.7 0 016 14.3 25.4 25.4 0 01-5.8 17.8 27.6 27.6 0 01-18.8 10.8 24.2 24.2 0 01-24.6-10.7A22 22 0 0157 121a27.1 27.1 0 0114.8-21.5 24.4 24.4 0 0111.7-3.4zm17.6 9.9l-5.3 4.3a.7.7 0 00-.2.5l.5 8.7c0 .6.2.7.8.7h9c.6 0 .8-.2.7-.8-.1-1.3-.2-2.6-.5-3.8a19.7 19.7 0 00-5-9.6zm-41 14.1a1 1 0 00.3.1H70c.2 0 .5-.3.5-.6l1.8-8.5c0-.5-.1-.7-.4-1l-3.8-3.7-.4-.4a24 24 0 00-7.5 14.1zM99.5 137a24 24 0 007-13.5l-.3-.1h-9.7c-.5 0-.5.3-.6.6l-1.3 7.2a1 1 0 00.2.7 20.5 20.5 0 014.7 5zm-34.6-.2a1.1 1.1 0 00.2-.2 23.8 23.8 0 015.1-4.8 1 1 0 00.3-.7l-.2-3.2v-4.5H60a20.5 20.5 0 004.8 13.4zM84 131l-1 12.8h.3a20.3 20.3 0 002-1.3c2.6-2.5 4-5.8 5.3-9.1 0-.2-.2-.6-.5-.7a16.6 16.6 0 00-6.1-1.7zm-3.1 0a17.8 17.8 0 00-6.6 1.8.9.9 0 00-.2.8c.2 1.2.5 2.4 1 3.6a13.5 13.5 0 003.2 5.5 16.7 16.7 0 001.7 1.3zm3.8-10.8h7.8c.2 0 .4-.4.5-.6l-.1-4.6c0-1-.2-1.9-.3-2.6l-7 1.5c-.1 0-.4.2-.4.4l-.5 6zm-11.4 0H80c1.8 0 1.8 0 2-1.8 0-1.5.2-3 .3-4.4l-3.6-.7-3.6-1.2zm18.7-11.2c-.7-4.3-3.4-8.7-5.8-9.5l-.8 11.3a17.8 17.8 0 006.6-1.8zm-16 0a15.6 15.6 0 006.3 1.7l.8-11.4c-3.9 2.3-5.5 6-7.1 9.7zm15.7 20.7l1.1-6.3-.2-.1H85a.6.6 0 00-.4.3l-.3 4.1zm-10.3-6.3l-.2-.1h-7.5c-.1 0-.4.3-.4.5a24.7 24.7 0 000 2.6l.2 3.5a23.8 23.8 0 017.2-2c.2 0 .4-.2.4-.3l.3-4.2zm12.2 11.4l-4.4 8.3A25.5 25.5 0 0097 139zM67 139a21.3 21.3 0 007.6 4.3 26 26 0 01-3.5-8.6zm32-35a21.1 21.1 0 00-6.9-3.6l1.7 3.4 1.4 3.7zm-25.7 3.6l4-7a23.9 23.9 0 00-7.1 3.7z"
      />
      <Path
        fill="#f8b35f"
        d="M125 88.9a17.1 17.1 0 018-7.5 6.6 6.6 0 011.1-.3 2.6 2.6 0 010 .5 68 68 0 01-3.3 11.2l-1.5 3.2c0-1.3 0-2.7.2-4 .2-1.4-.6-2.3-1.6-3.1z"
      />
      <Path
        fill="#fc8746"
        d="M129.3 96l1.5-3.2a68 68 0 003.3-11.2 2.6 2.6 0 000-.5c1.5-.4 3-.5 4 .8l-.8 4.2-1.5-.3a1 1 0 00-1.2.6c-.3.5-.5 1.1 0 1.6l1.7 1.5-.6 2.2-1.5 3.6a57.2 57.2 0 01-5.3 9.7 3.5 3.5 0 01-.2-.9l.5-7.7a1.6 1.6 0 00.1-.1z"
      />
      <Path
        fill="#f8b35f"
        d="M129.3 96.3a1.6 1.6 0 01-.1.1 1.6 1.6 0 000-.1z"
      />
      <Path
        fill="#9c662c"
        d="M99.6 59.7a21.8 21.8 0 011-3.4c.6-1.5 2-1.5 2.8-.1a6 6 0 01.8 2.1 9 9 0 01-.4 5.3c-.3.6-.7 1.3-1.5 1.3s-1.3-.6-1.7-1.2a6.8 6.8 0 01-.8-4zM74.3 62.3a20.3 20.3 0 011-3.4c.6-1.4 2-1.4 2.7-.1a6.7 6.7 0 011 2.3 9.4 9.4 0 01-.3 4.7c-.2.9-.7 1.8-1.6 1.9s-1.6-.9-2-1.7a33.4 33.4 0 01-.8-3.7z"
      />
      <Path
        fill="#e4ddd9"
        d="M101.2 106a19.7 19.7 0 015 9.7c.3 1.3.3 2.5.5 3.8 0 .6-.1.8-.8.8h-9c-.5 0-.7-.1-.7-.7l-.5-8.7a.7.7 0 01.2-.5l5.3-4.3z"
      />
      <Path
        fill="#e4ded9"
        d="M60.2 120.2a24 24 0 017.5-14.1l.4.4 3.8 3.7c.3.3.4.5.3 1l-1.7 8.5c0 .2-.3.6-.5.6h-9.5a1 1 0 01-.3 0zM99.5 137a20.5 20.5 0 00-4.7-5.1 1 1 0 01-.2-.8l1.3-7.1c0-.3 0-.7.6-.7h9.7l.3.1a24 24 0 01-7 13.6zM64.8 136.8a20.5 20.5 0 01-4.8-13.4h10.2v4.5l.3 3.2a1 1 0 01-.3.7 23.8 23.8 0 00-5.1 4.8 1.1 1.1 0 01-.3.2z"
      />
      <Path
        fill="#e2dbd8"
        d="M84 131.1a16.6 16.6 0 016.2 1.7c.2.1.5.5.4.7a21.7 21.7 0 01-5.3 9 20.3 20.3 0 01-2 1.5l-.2-.2zM81 131l-1 13a16.7 16.7 0 01-1.7-1.2 13.5 13.5 0 01-3.2-5.5l-1-3.6a.9.9 0 01.3-.8 17.8 17.8 0 016.5-1.8z"
      />
      <Path
        fill="#e3dcd8"
        d="M84.8 120.3l.5-6a603.3 603.3 0 017.4-1.9l.3 2.7v4.6c0 .2-.2.6-.4.6h-7.8zM73.5 120.3l1.5-8c1.3.3 2.4.8 3.5 1l3.7.8-.4 4.4c-.1 1.8-.1 1.8-2 1.8z"
      />
      <Path
        fill="#e2dbd8"
        d="M92 109a17.8 17.8 0 01-6.5 1.8l.8-11.2c2.5.8 5.1 5.2 5.8 9.5zM76 109.1c1.7-3.8 3.3-7.4 7.2-9.7l-.8 11.4a15.6 15.6 0 01-6.3-1.7z"
      />
      <Path
        fill="#e3dcd8"
        d="M91.8 129.8l-7.4-2 .3-4.1a.6.6 0 01.4-.4h7.6l.2.2c-.4 2.1-.8 4.3-1 6.3z"
      />
      <Path
        fill="#e2dbd8"
        d="M81.5 123.5l-.3 4.2-.5.4a23.8 23.8 0 00-7.2 1.9l-.2-3.5a24.7 24.7 0 010-2.6c0-.2.3-.5.5-.6h7.5l.2.2z"
      />
      <Path
        fill="#e1dad7"
        d="M93.7 135l3.4 4a25.5 25.5 0 01-7.9 4.2l4.5-8.3zM67 138.9l4-4.3a26 26 0 003.5 8.6 21.3 21.3 0 01-7.6-4.3z"
      />
      <Path
        fill="#e0d9d7"
        d="M99 104l-3.9 3.4-1.3-3.7c-.4-1.2-1.1-2.2-1.7-3.4a21.1 21.1 0 016.8 3.6zM73.3 107.5L70 104a23.9 23.9 0 017.2-3.7z"
      />
    </Svg>
  );
}
NotConnectedSvg.displayName = 'NotConnectedSvg';
