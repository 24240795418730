import {
  IScreenplay,
  ITextWithVoiceover
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { ScreenplayAction } from 'screenplay/redux';
import { AppState } from 'services';
import { MicSelection } from '../mic-selection/MicSelection';
import {
  CalibrationStep,
  CalibrationUiAction,
  CalibrationUiSelector
} from '../redux';

export interface ICalibrationMicFixProps {
  feedback: ITextWithVoiceover;
  hasPlayedFeedback: boolean;
  step: CalibrationStep;
  cancelScreenplay(): void;
  playFeedbackVO(screenplay: IScreenplay): void;
  setStep(step: CalibrationStep): void;
}

export class CalibrationMicFixComponent extends React.PureComponent<ICalibrationMicFixProps> {
  public static displayName = 'CalibrationMicFix';

  public constructor(props: ICalibrationMicFixProps) {
    super(props);

    this.handleTipsPress = this.handleTipsPress.bind(this);
    this.handleOkPress = this.handleOkPress.bind(this);
  }

  public componentDidMount() {
    // necessary to avoid replaying when coming back from mic setup tips
    if (!this.props.hasPlayedFeedback) {
      this.props.playFeedbackVO(this.props.feedback.voiceover);
    }
  }

  private handleTipsPress() {
    this.props.setStep(CalibrationStep.MicSetupTips);
  }

  private handleOkPress() {
    this.props.cancelScreenplay();
    this.props.setStep(CalibrationStep.Interactive);
  }

  public render() {
    const hideTips = this.props.step === CalibrationStep.MicChanged;

    return (
      <MicSelection
        title={this.props.feedback.text}
        onOkPress={this.handleOkPress}
        onTipsPress={hideTips ? undefined : this.handleTipsPress}
      />
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: AppState) => ({
  feedback: CalibrationUiSelector.getFeedbackContent(state)!,
  hasPlayedFeedback: CalibrationUiSelector.getHasPlayedFeedback(state),
  step: CalibrationUiSelector.getStep(state)
});

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  cancelScreenplay: () => ScreenplayAction.cancel(),
  playFeedbackVO: (vo: IScreenplay) =>
    ScreenplayAction.play({
      nextAction: CalibrationUiAction.setHasPlayedFeedback(true),
      screenplay: vo
    }),
  setStep: (step: CalibrationStep) => CalibrationUiAction.setStep({ step })
};

export const CalibrationMicFix = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalibrationMicFixComponent);
