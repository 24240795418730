import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { IStateManager } from '@lexialearning/lobo-common/lib';
import { LexiaError } from '@lexialearning/utils';
import {
  LexiaStandardErrorCode,
  LogoutReason
} from '@lexialearning/main-model';
import { Dispatch } from 'redux';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { first, startWith, takeWhile } from 'rxjs/operators';
import { LexiaService } from 'lexia-service/LexiaService';
import { LexiaServiceSelector } from 'lexia-service/redux/LexiaService.selector';
import { AuthAction } from 'services/auth';
import {
  IProgressResponse,
  ISendStudentUpdateRequest,
  SendUnitLexia,
  StudentProgressApiEndpoint,
  StudentProgressPayloadType
} from './student-progress-api-private.model';
import { IStudentApiConfigLobo, StudentApiHelper } from 'student-api';
import { ILoginResponse, UserRole } from 'lexia-service';
import { LoginHelper } from './Login.helper';
import {
  ILogoutUserRequest,
  ILogoutUserResponse
} from 'lexia-service/auth-api/auth-api-private.model';
import { TimingUtil } from 'utils';

export class StudentProgressApi {
  public static readonly displayName = 'StudentProgressApi';

  private static readonly LogoutDelayMs = 300;

  private timer?: NodeJS.Timeout;

  private get endpoint(): string {
    return StudentApiHelper.getEndpoint(this.config);
  }

  public get updateEndpoint(): string {
    return `${this.endpoint}${StudentProgressApiEndpoint.Update}`;
  }

  private get idleSessionTimeoutMinutes(): number {
    return this.config.idleSession.timeoutMinutes;
  }

  private get idleSessionWarningMinutes(): number {
    return this.config.idleSession.warningMinutes;
  }

  private get keepAliveSeconds(): number {
    return this.config.keepAliveSeconds;
  }

  private get timestampElapsedSeconds(): number {
    return (Date.now() - this.timestampValue!.getTime()) / 1000;
  }

  public get config(): IStudentApiConfigLobo {
    return StudentApiHelper.getConfig(this.configProvider);
  }

  /**
   * Emits when the session has (been expected to have) timed out
   * @see timestamp
   */
  public get sessionTimeout$(): Observable<void> {
    return this.timeoutSubject.asObservable();
  }

  private readonly timeoutSubject = new Subject<void>();

  /**
   * Emits {warningsSeconds} before the session is due to time out with the
   * number of seconds remaining (i.e. warningSeconds)
   */
  public get sessionTimeoutWarning$(): Observable<void> {
    return this.warningSubject.asObservable();
  }

  private readonly warningSubject = new Subject<void>();

  private queueItemId = 0;

  private requestNum = 1;

  private timestampValue?: Date;

  private dispatch: Dispatch | undefined;

  private readonly queue: IQueueItem[] = [];

  private readonly submissionSubject = new Subject<
    ISubmissionItem | IAskSubmissionItem
  >();

  public constructor(
    private readonly configProvider: IConfigProvider,
    private readonly lexiaApiService: LexiaService,
    private readonly stateManager: IStateManager,
    private readonly version: string,
    private readonly reportError: (err: LexiaError) => void
  ) {}

  public async login(
    personId: number,
    role: UserRole,
    token: string
  ): Promise<ILoginResponse> {
    return LoginHelper.login(
      this.lexiaApiService,
      this.stateManager.getState(),
      this.endpoint,
      this.version,
      personId,
      role,
      token
    );
  }

  public async logout(reason: LogoutReason): Promise<void> {
    const logoutUserRequestBase = LexiaServiceSelector.getLexiaServiceRequests(
      this.stateManager.getState()
    ).logout;
    const request: ILogoutUserRequest = {
      ...logoutUserRequestBase,
      reason,
      version: this.version
    };

    if (!request.authToken) {
      return;
    }

    const url = this.endpoint + StudentProgressApiEndpoint.Logout;

    void this.lexiaApiService.postRequest<ILogoutUserResponse>(request, url);

    /* TODO: Temp solution to allow time for the request to have gone out w/o
     awaiting the response. Ideally we would wait to get ACK that the request
     went out, but not wait for the response. */
    await TimingUtil.delay(StudentProgressApi.LogoutDelayMs);
  }

  public createRequestInfo(): ISendStudentUpdateRequest {
    return {
      ...LexiaServiceSelector.getLexiaServiceRequests(
        this.stateManager.getState()
      ).studentApi,
      attemptNum: 1,
      requestNum: 0,
      version: this.version
    };
  }

  public init(dispatch: Dispatch): void {
    this.dispatch = dispatch;
  }

  /**
   * Send a throttled keep-alive request to the API. Suppress the request if we
   * have recently communicated
   */
  public keepAlive(): void {
    if (!this.timestampValue) {
      throw new LexiaError(
        'Must set timestamp before requesting keepAlive',
        StudentProgressApi.displayName,
        StudentProgressApiError.TimestampMissing
      );
    }

    if (this.timestampElapsedSeconds <= this.keepAliveSeconds) {
      return;
    }

    this.keepAliveForced();
  }

  /**
   * Send a keep-alive regardless of how recently we have connected with the
   * API server. This should only be used in special situations such as when
   * navigating to the Home page where we want to ensure the API server has a
   * better record of usage.
   * See {@link https://jira.lexialearning.com/wiki/display/ELKMK/Session+Timeout+and+Keep+Alives|Session Timeout and Keep Alives}
   */
  public keepAliveForced(): void {
    void this.send([]);
  }

  /**
   * Clear session timeout timers and requestNum counter.
   * Should be called when logging the student out.
   */
  public async reset(): Promise<void> {
    await lastValueFrom(
      this.submissionSubject.pipe(
        startWith(void 0),
        takeWhile(() => this.queue.length > 0)
      ),
      { defaultValue: undefined }
    );

    this.requestNum = 1;
    // istanbul ignore else - trivial
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timestampValue = undefined;
  }

  public async send(sendArray: SendUnitLexia[]): Promise<void> {
    this.queueItemId += 1;
    const id = this.queueItemId;
    const processPromise = lastValueFrom(
      this.submissionSubject.pipe(first(s => s.item.id === id && s.successful))
    ).then(() => void 0);

    this.queue.push({ id, sendArray });

    /**
     * Process this item if it is the only one in the queue.
     * Otherwise, processQueue will deal with it once it finishes its current
     * workload
     */
    if (this.queue.length === 1) {
      void this.processQueue();
    }

    return processPromise;
  }

  /**
   * Process items in sequence, waiting for each one to finish before processing
   * the next one. When done with the initial batch, process any items that
   * may have been added to the queue.
   * Errors are reported via reportError rather than "thrown", but still fatal,
   * in the sense no further items in the queue should be processed and the
   * queue should be reset.
   */
  private async processQueue(): Promise<void> {
    const q = [...this.queue];

    // istanbul ignore next - trivial
    if (!this.dispatch) {
      throw new Error('Dispatch not defined');
    }

    try {
      for (const item of q) {
        const response = await this.processNext(item.sendArray);

        this.submissionSubject.next({ item, response, successful: true });
        this.queue.shift();
      }

      if (this.queue.length) {
        // we got more stuff to do added while we were busy
        await this.processQueue();
      }
    } catch (err) {
      this.abort(err);
    }
  }

  /**
   * Aborts processing of any further items in the queue by emptying it and
   * emitting on submissionSubject (so relevant observers resolve their promise)
   *
   * The error is "reported" (which is assumed to be fatal) and reset is called
   * to reset things.
   *
   * In the special case of an invalid token error, also dispatch
   * AuthAction.invalidToken.
   * (TODO: Why do we do that? Seems that is best handled elsewhere)
   */
  private abort(err: LexiaError): void {
    if (
      err.code === StudentProgressApiError.ApiError &&
      err.standardCode === LexiaStandardErrorCode.InvalidAuthToken
    ) {
      this.dispatch?.(AuthAction.invalidToken());
    }

    [...this.queue].forEach(item => {
      this.submissionSubject.next({ item, successful: false });
      this.queue.shift();
    });
    this.reportError(err);
    void this.reset();
  }

  private async processNext(
    sendArray: SendUnitLexia[]
  ): Promise<IProgressResponse> {
    const request: ISendStudentUpdateRequest = {
      ...this.createRequestInfo(),
      requestNum: this.getRequestNum(sendArray),
      sendArray
    };

    this.maybeClearSendArrayAndNums(request);

    const response = await this.lexiaApiService.postRequest<IProgressResponse>(
      request,
      this.updateEndpoint
    );
    this.checkResponse(response, request.requestNum);
    this.timestamp();
    this.requestNum = response.nextExpectedRequestNum ?? this.requestNum;

    return response;
  }

  /**
   * Request Num should be 0 for Updates that are NOT reporting progress (e.g.
   * keep-alive and ask array) since the request num is used to ensure a
   * monotonically increasing curriculum position sequence number.
   */
  private getRequestNum(sendArray: SendUnitLexia[] | undefined): number {
    return sendArray?.some(u =>
      [
        StudentProgressPayloadType.UnitAdvance,
        StudentProgressPayloadType.UnitProgress
      ].includes(u.__type__)
    )
      ? this.requestNum
      : 0;
  }

  private maybeClearSendArrayAndNums(request: ISendStudentUpdateRequest): void {
    if (!request.sendArray) {
      return;
    }

    if (this.config.disableWrites && request.sendArray.length) {
      // eslint-disable-next-line no-console
      console.log(
        'Student API writes disabled. Clearing send array:',
        request.sendArray.map(a => a.__type__).join(', ')
      );
      request.sendArray = [];
      request.attemptNum = 0;
      request.requestNum = 0;
    }
  }

  private checkResponse(response: IProgressResponse, requestNum: number): void {
    if (response.error?.code) {
      throw new LexiaError(
        `Error submitting progress to student API: ${response.error?.message}`,
        StudentProgressApi.displayName,
        StudentProgressApiError.ApiError
      ).withStandardCode(response.error?.code);
    }

    if (requestNum && !response.nextExpectedRequestNum) {
      throw new LexiaError(
        'Student API failed to return a "nextExpectedRequestNum"',
        StudentProgressApi.displayName,
        StudentProgressApiError.NoExpectedNextRequestNum
      );
    }
  }

  /**
   * Set the timestamp when we last successfully communicated with the student
   * API server. This should typically be used only internally, with the only
   * exception being to establish the login timestamp.
   * This also initiates the countdown timer that emits on sessionTimeoutWarning$
   * and in turn starts the timer that emits on sessionTimeout$.
   *
   * @see sessionTimeoutWarning$
   * @see sessionTimeout$
   *
   * @param time - optionally specify the time. Otherwise set to current time.
   */
  public timestamp(time: Date = new Date()): void {
    this.timestampValue = time;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.startWarningTimer();
  }

  private startWarningTimer(): void {
    this.timer = setTimeout(() => {
      // Only emit if authorization api has not already timed out
      // (no need to show modal if timeout has already run as student will be
      //  immediately logged out anyway, so modal serves no purpose, and is only
      //  confusing to see shown briefly)
      if (!this.hasTimedOut()) {
        this.warningSubject.next();
      }
      this.startTimeoutTimer();
    }, this.idleSessionWarningMinutes * 60000);
  }

  private startTimeoutTimer(): void {
    this.timer = setTimeout(() => {
      this.timeoutSubject.next();
    }, this.getTimeoutTimerMs());
  }

  private hasTimedOut(): boolean {
    return this.getTimeoutTimerMs() === 0;
  }

  /**
   * Returns time in ms until timeout timer will have fully run and authorization api will have timed out
   *
   * Timeout timer needs to be determined using getTimestampElapsedSeconds, rather than idleSessionWarningMinutes.
   * These two should be essentially the same (converted to seconds) *if* the app runs normally through the idleSessionWarningMinutes until the
   * warning is shown and timeout timer is started. However, if the device has gone to sleep before showing the warning timer (or, for iPad,
   * if it has just gone into the background), then more than idleSessionWarningMinutes may pass before the warning is shown and
   * timeout timer is started, yet the logout timer on the API side will have been continuously running in the background.
   * (eg, device sleeps at minute 14, so warning timer (15 min) has not completed, warning has not yet been shown and timeout timer has not yet been started.
   *  Student wakes device 5 minutes later, so the api will time out in 1 minute from the warning being shown, rather than 5
   *  minutes that the user would otherwise have to respond - this is actually most obvious when the full api timeout of 20 minutes has run, so the
   *  'Are you still there' modal comes up even though the api logout has already occurred)
   */
  private getTimeoutTimerMs() {
    const idleSessionTimeoutSeconds = this.idleSessionTimeoutMinutes * 60;
    const timeoutTimerMS =
      (idleSessionTimeoutSeconds - this.timestampElapsedSeconds) * 1000;

    return Math.max(timeoutTimerMS, 0);
  }
}

export interface IQueueItem {
  id: number;
  sendArray: SendUnitLexia[];
}

interface ISubmissionItem {
  item: IQueueItem;
  successful: boolean;
}

interface IAskSubmissionItem extends ISubmissionItem {
  response: IProgressResponse;
}

export enum StudentProgressApiError {
  ApiError = 'ApiError',
  NoConfig = 'NoConfig',
  NoExpectedNextRequestNum = 'NoExpectedNextRequestNum',
  TimestampMissing = 'TimestampMissing'
}
