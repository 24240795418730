export class AmbienceBarStyles {
  private static readonly styles = {
    container: {
      height: 70,
      marginBottom: 28
    },
    headphones: { marginLeft: 10, marginRight: 10 }
  };

  public static get() {
    return this.styles;
  }
}
