import memoizeOne from 'memoize-one';
import { Color, Font, loboTextStyle, TextAlign } from 'common-styles';
import { ThemeSize } from 'theme';

export class TextStyles {
  public static readonly build = memoizeOne(
    (themeSize: ThemeSize, style?: any) =>
      loboTextStyle({
        color: Color.Text,
        fontFamily: Font.Family.regular,
        fontSize: Font.Size[themeSize],
        textAlign: TextAlign.Center,
        ...style
      })
  );
}
