import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { CustomerApi } from 'lexia-service/customer-api';
import { LocalStorageService } from 'services/local-storage';

export enum DeviceSetupLocalStorageKeys {
  CustomerCode = 'CUSTOMER_CODE'
}

export interface IDeviceSetupDependencies {
  customerApi: CustomerApi;
  localStorageService: LocalStorageService;
  configProvider: IConfigProvider;
}
