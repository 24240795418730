import { useCallback, useEffect, useRef } from 'react';
import { AnimatedView } from '../../view';
import { ButtonBase, IButtonBaseProps } from '../ButtonBase';
import { PressScaleButtonAnimatedStyles } from './PressScaleButton.animated-styles';
import { KeyEventHelper } from '@lexialearning/reactxp';
import { ActiveComponentState } from '@lexialearning/common-ui';
import { usePreviousPersistent } from '../../../hooks';

export interface IPressScaleButtonProps extends IButtonBaseProps {
  hoverScale?: number;
  pressScale?: number;
  triggerState?: ActiveComponentState;
}

export function PressScaleButton(props: IPressScaleButtonProps) {
  const {
    hoverScale,
    pressScale,
    testId,
    triggerState,
    children,
    onHoverStart,
    onHoverEnd,
    onKeyDown,
    onKeyUp,
    onPressIn,
    onPressOut,
    onBlur,
    style,
    ...restProps
  } = props;

  const animatedStyles = useRef(
    new PressScaleButtonAnimatedStyles(hoverScale, pressScale)
  );

  const prevTriggerState = usePreviousPersistent(triggerState);

  const handleHoverStart = useCallback(
    (e?: any) => {
      animatedStyles.current.getAnimations().hover.start();
      onHoverStart?.(e);
    },
    [onHoverStart]
  );

  const handleHoverEnd = useCallback(
    (e?: any) => {
      animatedStyles.current.getAnimations().toDefault.start();
      onHoverEnd?.(e);
    },
    [onHoverEnd]
  );

  const handleKeyDown = useCallback(
    (e?: any) => {
      if (KeyEventHelper.isPressKey(e)) {
        animatedStyles.current.getAnimations().pressIn.start();
        onKeyDown?.(e);
      }
    },
    [onKeyDown]
  );

  const handleKeyUp = useCallback(
    (e?: any) => {
      if (KeyEventHelper.isPressKey(e)) {
        animatedStyles.current.getAnimations().toDefault.start();
        onKeyUp?.(e);
      }
    },
    [onKeyUp]
  );

  const handlePressIn = useCallback(
    (e?: any) => {
      animatedStyles.current.getAnimations().pressIn.start();
      onPressIn?.(e);
    },
    [onPressIn]
  );

  const handlePressOut = useCallback(
    (e?: any) => {
      animatedStyles.current.getAnimations().toDefault.start();
      onPressOut?.(e);
    },
    [onPressOut]
  );

  const handleBlur = useCallback(
    (e?: any) => {
      animatedStyles.current.getAnimations().toDefault.start();
      onBlur?.(e);
    },
    [onBlur]
  );

  useEffect(() => {
    switch (triggerState) {
      case ActiveComponentState.Hovered:
        handleHoverStart();
        break;
      case ActiveComponentState.Pressed:
        handlePressIn();
        break;
      case ActiveComponentState.Default:
        if (prevTriggerState === ActiveComponentState.Hovered) {
          handleHoverEnd();
        } else if (prevTriggerState === ActiveComponentState.Pressed) {
          handlePressOut();
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerState]);

  const styles = animatedStyles.current.get();

  return (
    <AnimatedView animatedStyle={styles.containerAnimated}>
      <ButtonBase
        {...restProps}
        onBlur={handleBlur}
        onHoverEnd={handleHoverEnd}
        onHoverStart={handleHoverStart}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        style={style}
        testId={testId || PressScaleButton.displayName}
      >
        {children}
      </ButtonBase>
    </AnimatedView>
  );
}
PressScaleButton.displayName = 'PressScaleButton';
