import {
  ImportantForAccessibility,
  Modal as CuiModal,
  ModalSelector
} from '@lexialearning/common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { DndContainer } from 'dnd';
import {
  PressableView,
  StatusBar,
  View,
  useDidMount,
  useSizing
} from 'common-ui';
import { BootstrappingSelector } from 'services/bootstrapping';
import { ModalUtils, SmartModal } from 'shared-components/modals';
import { AppShellAction, AppShellSelector } from 'shell';
import { AppShell } from 'shell/AppShell';
import { StormContainer } from 'storm';
import { AppRoutes } from './app-routes/AppRoutes';
import { AppStyles } from './App.styles';
import { AppBackground } from './AppBackground';
import { PostAppMountHook } from './post-app-mount-hook';
import { SplashScreen } from './SplashScreen';
import { AppRouter } from './app-router/AppRouter';
import { useCallback } from 'react';

export function App() {
  const { appSize, scale, containerSize, offset } = useSizing();

  // selectors
  const activeLoboModal = useSelector(AppShellSelector.getActiveModal);
  const activeCuiModal = useSelector(ModalSelector.getModalDefinitionMaybe);
  const isInitialized = useSelector(BootstrappingSelector.isBootstrapped);

  const isModalActive = !!(activeLoboModal || activeCuiModal);

  // Will return false for CUI modal, as it is not in our registry,
  // this is as-desired for now as we only use Cui modals for errors, which
  // aren't dismissable, but either way, the Cui modal dismisses by a different
  // action than the Lobo modal, and it checks itself for preventDismiss before doing so
  const isModalDismissable = ModalUtils.isDismissible(activeLoboModal?.id);

  const dispatch = useDispatch();
  const hideModal = useCallback(
    () => dispatch(AppShellAction.hideModal()),
    [dispatch]
  );

  useDidMount(() => {
    StatusBar.setHidden(true, 'fade');
    PostAppMountHook.execute();
  });

  const maybeHideModal = useCallback(() => {
    if (isModalDismissable) {
      hideModal();
    }
  }, [hideModal, isModalDismissable]);

  const styles = AppStyles.build(appSize, containerSize, scale, offset);
  // Aria hide app and contents if behind a modal
  const appImportantForAccessibility = isModalActive
    ? ImportantForAccessibility.NoHideDescendants
    : undefined;

  return (
    <AppRouter>
      <PressableView onPress={maybeHideModal} style={styles.container}>
        <AppBackground />
        <View testId="app" style={styles.app} stopPressPropagation>
          <StormContainer />
          {isInitialized && (
            <DndContainer
              importantForAccessibility={appImportantForAccessibility}
            >
              <AppShell>
                <AppRoutes />
              </AppShell>
            </DndContainer>
          )}
          <SplashScreen isVisible={!isInitialized} />
          <SmartModal />
          <CuiModal />
        </View>
      </PressableView>
    </AppRouter>
  );
}
App.displayName = 'App';
