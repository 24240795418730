import { AppShellAction } from 'feature-areas/shell';
import { AvatarEditorAction } from '../redux';
import { AvatarEditorEvent } from '@lexialearning/lobo-common/main-model/logging';
import { AvatarInstructionModal } from '../instruction-modal/AvatarInstructionModal';
import { FadeAnimation, PressScaleButton, View } from 'common-ui';
import { InstructionButtonStyles } from './InstructionButton.styles';
import { QuestionMarkSvg } from './svgs/QuestionMark.lx-svg';
import { useActiveComponentState } from '@lexialearning/common-ui';
import { useAvatarEditorDisabled } from 'feature-areas/avatar-editor/shared';
import { useDispatch } from 'react-redux';
import { Color } from 'common-styles';

export function InstructionButton() {
  const dispatch = useDispatch();

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState();

  const disabled = useAvatarEditorDisabled();

  const onPress = () => {
    dispatch(AppShellAction.showModal({ id: AvatarInstructionModal.ModalId }));
    dispatch(
      AvatarEditorAction.addUserEvent({
        data: { initiator: InstructionButton.displayName },
        eventName: AvatarEditorEvent.InstructionalGifOpened
      })
    );
  };

  const styles = InstructionButtonStyles.get();

  return (
    <View style={styles.container}>
      <FadeAnimation shouldShow={true}>
        <PressScaleButton
          {...interactionHandlers}
          disabled={disabled}
          hoverScale={1.06}
          onPress={onPress}
          pressScale={0.95}
          disabledOpacity={0.3}
        >
          <QuestionMarkSvg
            activeComponentState={activeComponentState}
            accessibilityLabel="Open 'How to create student avatar example'."
            borderColor={Color.NearWhite}
          />
        </PressScaleButton>
      </FadeAnimation>
    </View>
  );
}
InstructionButton.displayName = 'InstructionButton';
