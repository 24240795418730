import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  AncestryLoader,
  ContentProviderFactory
} from '@lexialearning/lobo-common/cms';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  ProgramContextAction,
  ProgramContextActionLoadedForDeepLinking
} from 'curriculum-services';
import { DeepLinkActionType } from '../../redux/deep-link-redux.model';
import { DeepLinkActionBuildPosition } from '../../redux/DeepLink.action';
import { DeepLinkPositionBuilder } from './DeepLinkPositionBuilder';

export interface IBuildDeepLinkPositionEpicDeps {
  ancestryLoader: AncestryLoader;
  configProvider: IConfigProvider;
  contentProviderFactory: ContentProviderFactory;
}

export function buildDeepLinkPositionEpic(
  action$: Observable<DeepLinkActionBuildPosition>,
  _: StateObservable<unknown>,
  deps: IBuildDeepLinkPositionEpicDeps
): Observable<ProgramContextActionLoadedForDeepLinking> {
  return action$.pipe(
    ofType(DeepLinkActionType.BuildPosition),
    mergeMap(async action => {
      const { ancestryLoader, configProvider, contentProviderFactory } = deps;
      const builder = new DeepLinkPositionBuilder(
        ancestryLoader,
        configProvider,
        contentProviderFactory
      );
      const payload = await builder.build(action.payload.sysId);

      return ProgramContextAction.loadedForDeepLinking(payload);
    })
  );
}
buildDeepLinkPositionEpic.displayName = 'buildDeepLinkPositionEpic';
