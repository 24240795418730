import { ConnectDragPreview } from 'react-dnd';
import { DndGetEmptyImage } from './DndGetEmptyImage';

/**
 * Our function to wrap the react-dnd connectDragPreview function.
 * Use empty image as a drag preview so browsers don't draw it
 * and we can draw whatever we want on the custom drag layer instead.
 */
export function DndConnectDragPreview(connectDragPreview: ConnectDragPreview) {
  connectDragPreview(DndGetEmptyImage());
}
