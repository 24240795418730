import { LexiaError } from '@lexialearning/utils';
import { isEqual, shuffle } from 'lodash';

interface IArrayUtilsOptions {
  ensureReorder: boolean;
}
export class ArrayUtils {
  /**
   * Function returns a shuffled array
   * Non-destructive, the function does not alter the source array.
   *
   * @source array to shuffle
   * @ensureReorder whether returned array must be uniquely reorded compared to original
   */
  public static shuffleArray(
    source: any[] = [],
    options: IArrayUtilsOptions = { ensureReorder: false }
  ) {
    if (source.length < 2) {
      return [...source];
    }

    let shuffled = shuffle([...source]);
    if (!options.ensureReorder) {
      return shuffled;
    }
    let i = 0;
    while (isEqual(source, shuffled)) {
      shuffled = shuffle(shuffled);
      i += 1;
      if (i > 100) {
        throw new LexiaError(
          'ensureReorder failure: No unique array returned in 100 tries',
          'ArrayUtils.shuffleArray',
          '123'
        );
      }
    }

    return shuffled;
  }
}
