import { TopicCode } from '@lexialearning/lobo-common';
import {
  GlossyButton,
  IGlossyButtonProps
} from 'common-ui/components/button/glossy-button/GlossyButton';
import { TopicButtonStyles } from './TopicButton.styles';
import { TopicColorSets, TopicIconMap } from './TopicButton.model';
import { ActiveComponentState } from '@lexialearning/common-ui';

export interface ITopicButtonProps extends IGlossyButtonProps {
  topic: TopicCode;
  triggerState?: ActiveComponentState;
}

export function TopicButton(props: ITopicButtonProps) {
  const { topic, triggerState, ...buttonProps } = props;

  const styles = TopicButtonStyles.get();

  const icon = TopicIconMap.get(topic);
  const colorSet = TopicColorSets[topic];

  return (
    <GlossyButton
      {...buttonProps}
      icon={icon}
      colorSet={colorSet}
      styleOverride={styles.topicButton}
      triggerState={triggerState}
    />
  );
}
TopicButton.displayName = 'TopicButton';
