import { ActiveComponentState } from '@lexialearning/common-ui';
import {
  IUtilityButtonColorSet,
  UtilityButtonColorSetName,
  UtilityButtonColorSetMap,
  UtilityButtonType
} from './utility-button-model';

export class UtilityButtonHelper {
  public static getSharedProps() {
    return {
      disabledOpacity: 0.5,
      hoverScale: 41 / 38,
      pressScale: 36 / 38
    };
  }

  public static getColorSetName(
    activeComponentState: ActiveComponentState,
    buttonType?: UtilityButtonType
  ) {
    if (buttonType === UtilityButtonType.Basic) {
      switch (activeComponentState) {
        case ActiveComponentState.KeyboardFocused:
        case ActiveComponentState.KeyboardPressed:
          return UtilityButtonColorSetName.WhiteBlack;
        default:
          return UtilityButtonColorSetName.Black;
      }
    }

    switch (activeComponentState) {
      case ActiveComponentState.Hovered:
        return UtilityButtonColorSetName.Light;
      case ActiveComponentState.KeyboardFocused:
        return UtilityButtonColorSetName.BlackWhite;
      case ActiveComponentState.KeyboardPressed:
        return UtilityButtonColorSetName.BlackGray;
      case ActiveComponentState.Pressed:
        return UtilityButtonColorSetName.Dark;
      default:
        return UtilityButtonColorSetName.Default;
    }
  }

  public static getColorSet(
    arg: UtilityButtonColorSetName | ActiveComponentState,
    buttonType?: UtilityButtonType
  ): IUtilityButtonColorSet | undefined {
    const colorSetName = Object.values(UtilityButtonColorSetName).includes(
      arg as UtilityButtonColorSetName
    )
      ? (arg as UtilityButtonColorSetName)
      : this.getColorSetName(arg as ActiveComponentState, buttonType);

    return UtilityButtonColorSetMap.get(colorSetName);
  }
}
