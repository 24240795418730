import { DropdownItem, IDropdownItem } from './DropdownItem';
import { IDropdownItemStyleOverride } from './DropdownItem.styles';
import { View } from '../view';
import {
  DropdownPopupStyles,
  IDropdownPopupStyleOverride
} from './DropdownPopup.styles';
import { useCallback, useEffect, useState } from 'react';
import { AccessibilityRole } from '@lexialearning/common-ui';
import { DropdownHelper } from './Dropdown.helper';
import { useSizing } from '../../hooks';

export interface IDropdownPopupProps {
  id: string;
  items: IDropdownItem[];
  selectedItemId: string;
  highlightedItemId: string;
  styleOverride?: IDropdownPopupStyleOverride & IDropdownItemStyleOverride;
  onSelect(id: string): void;
}

export function DropdownPopup(props: IDropdownPopupProps) {
  const {
    items,
    highlightedItemId: initialHighlightedItemId,
    onSelect,
    selectedItemId,
    styleOverride
  } = props;
  const { scale } = useSizing();

  const [highlightedItemId, setHighlightedItemId] = useState<
    string | undefined
  >(initialHighlightedItemId);

  // Only highlight the initial selection (current dropdown selection) until keynav or hover take place
  const handleHoverStart = useCallback(() => {
    setHighlightedItemId(undefined);
  }, [setHighlightedItemId]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent): void => {
      const newHighlightedItemId = DropdownHelper.getHighlightedItemId(
        e,
        items,
        highlightedItemId,
        true
      );

      if (
        !!newHighlightedItemId &&
        highlightedItemId !== newHighlightedItemId
      ) {
        // Has to be updated here, rather than just receiving the updated value bc this is not inside the app, but appended to the page
        setHighlightedItemId(newHighlightedItemId);
      }
    },
    [highlightedItemId, items]
  );

  useEffect(() => {
    window?.addEventListener?.('keyup', handleKeyUp);

    return () => {
      window?.removeEventListener?.('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  const styles = DropdownPopupStyles.build(scale, styleOverride);

  return (
    <View
      testId={DropdownPopup.displayName}
      style={styles.list}
      accessibilityRole={AccessibilityRole.ListBox}
    >
      {items.map(item => (
        <DropdownItem
          id={item.id}
          key={item.id}
          label={item.label}
          onPress={() => onSelect(item.id)}
          onHoverStart={handleHoverStart}
          isHighlighted={item.id === highlightedItemId}
          selected={item.id === selectedItemId}
          styleOverride={styleOverride}
          accessibilityLabel={item.accessibilityLabel}
        />
      ))}
    </View>
  );
}
DropdownPopup.displayName = 'DropdownPopup';
