import { Color } from 'common-styles';
import { ThemeType } from 'theme';

export enum MeterMarkerType {
  Pip = 'pip',
  Bar = 'bar'
}

export enum ColorSet {
  Default = 'default',
  Recycle = 'recycle'
}

// Needs to be a numeric enum to be used for interpolation
export enum MeterMarkerProgressStatus {
  Upcoming, // 0
  Active, // 1
  ActivePop, // 2 -- Pip only
  CompletedPop1, // 3 -- Pip only
  CompletedPop2, // 4
  Completed, // 5
  RecycleFlash, // 6 -- Pip only
  RecycleSkip // 7
}

// Only includes meter animations which need to be called from a screenplay
// and thus need a name by which to be registered
// (ie, doesn't include things like completion animations which are called
//  directly from the component)
export enum MeterMarkerAnimationName {
  BarIntro = 'barIntro',
  PipIntro = 'pipIntro',
  RecycleIntro = 'recycleIntro'
}

// Enum aliases intended for use here only  in order to shorten
// references to these enums and allow for clearer reading
// (TS doesn't allow direct enum aliasing, eg, enum NewAlias = OtherEnum,
// so it has to be aliased as both the type and const that enums actually are)
type MMStatus = MeterMarkerProgressStatus;
const MMStatus = MeterMarkerProgressStatus;
type MMType = MeterMarkerType;
const MMType = MeterMarkerType;

// Helper functions for creating sections of the color mapping,
// Allows for DRYer code and cleaner reading of the MARKERS_COLOR_MAP
type StatusMap = Map<MMStatus, string | TypeMap>;
type TypeMap = Map<MMType, string>;
function statusMap(
  entries: Iterable<readonly [MMStatus, string | TypeMap]>
): StatusMap {
  return new Map<MMStatus, string | TypeMap>(entries);
}
function typeMap(
  entries: Iterable<readonly [MeterMarkerType, string]>
): TypeMap {
  return new Map<MeterMarkerType, string>(entries);
}

export const MARKERS_COLOR_MAP = new Map<ColorSet | ThemeType, StatusMap>([
  [
    ColorSet.Default,
    statusMap([
      [
        MMStatus.Upcoming,
        typeMap([
          [MMType.Pip, Color.Black],
          [MMType.Bar, Color.NearWhiteTransparent30]
        ])
      ],
      [MMStatus.Active, Color.Gray30],
      [MMStatus.ActivePop, Color.Gray10], // Pip only
      [MMStatus.Completed, Color.LimeGreen],
      [MMStatus.CompletedPop1, Color.LimeGreenPop1], // Pip only
      [MMStatus.CompletedPop2, Color.LimeGreenPop2],
      [MMStatus.RecycleFlash, Color.Transparent],
      [MMStatus.RecycleSkip, Color.Transparent]
    ])
  ],

  // ================================
  // The below ThemeType sets only need to define colors to the extent that they
  // differ from those in the Default set
  // ================================

  [ThemeType.Instruction, statusMap([[MMStatus.Completed, Color.Yellow]])],
  // [ThemeType.Placement, ...] ===> See MARKERS_COLOR_MAP.set(...) immediately below
  [
    ThemeType.PoK,
    statusMap([
      [MMStatus.Completed, Color.DeepBlue],
      [MMStatus.CompletedPop1, Color.DeepBluePop1], // Pip only
      [MMStatus.CompletedPop2, Color.DeepBluePop2]
    ])
  ],

  // ================================
  // The below Recycle set only needs to define colors to the extent that they
  // differ from those in PoK
  // ================================

  [
    ColorSet.Recycle,
    statusMap([
      [MMStatus.Upcoming, Color.Gray30],
      [MMStatus.RecycleFlash, Color.Yellow],
      [MMStatus.RecycleSkip, Color.DeepBlue]
    ])
  ]
]);
// Placement uses the same colors as PoK for now, so adding them like this
// in order to not repeat the same mapping
MARKERS_COLOR_MAP.set(
  ThemeType.Placement,
  MARKERS_COLOR_MAP.get(ThemeType.PoK)!
);
