import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  ActivityPositionMap,
  IAct,
  IActivityPosition,
  IEncounter,
  ILevel,
  IMainUnitNode,
  InstructionalStep,
  IPlacementForm,
  IPlacementProgress,
  IPositionChange,
  IProgramNode,
  IRound,
  IRoundNode,
  ISubunitPosition,
  ITask,
  ITaskAttempt,
  IUnit,
  IUnitNode,
  IUnitSavePoint,
  PositionChangeType,
  SubunitType,
  SubunitTypeExtended,
  UnitSavePointStatus
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { last } from 'lodash';
import { ITaskRegistration, TaskRegistry } from 'task-components';
import { NumberUtils } from 'utils';
import { ActivityPositionBuilder } from './epics';
import { ProgramMode } from './program-context.model';

/**
 * The curriculum program context from the perspective of the currently active
 * round. The backing data structure is a tree. Each node has links to
 * siblings, parent and children. Each node also has the element content.
 * There are getters for each node and its content.
 *
 * @see RoundPedigreeFactory
 * @see https://jira.lexialearning.com/wiki/display/ELKMK/Curriculum+Services+and+Program+Context
 */
export class RoundContext {
  public static readonly displayName = 'RoundContext';

  public get level(): ILevel {
    return this.levelNode.content;
  }

  public get levelNode(): IProgramNode<ILevel> {
    return this.actNode.parent as any;
  }

  public get levelNumber(): number {
    return NumberUtils.parse(this.level.title);
  }

  public get act(): IAct {
    return this.actNode.content;
  }

  public get actNode(): IProgramNode<IAct, ILevel, IEncounter> {
    return this.encounterNode.parent as any;
  }

  public get activityPosition(): IActivityPosition {
    const value = this.activityPositionMap.get(this.act.sysId);

    if (!value) {
      throw new LexiaError(
        `Activity position not found for activity id "${this.act.sysId}"`,
        RoundContext.displayName,
        RoundContextError.ActivityPositionMissing
      ).withContext({
        activityId: this.act.sysId,
        activityPositions: [...this.activityPositionMap.values()]
      });
    }

    return value;
  }

  /**
   * The position to which the student is moving.
   * This is only applicable while transitioning from one position to another.
   * It will throw an error if not.
   */
  public get imminentPosition(): IPositionChange {
    if (!this.imminentPositionMaybe) {
      throw new LexiaError(
        'Imminent parent unit position is missing',
        RoundContext.displayName,
        RoundContextError.ImminentPositionMissing
      ).withContext({ activityPosition: this.activityPosition });
    }

    return this.imminentPositionMaybe;
  }

  /**
   * The position to which the student is moving.
   * This is only applicable while transitioning from one position to another.
   * It will return undefined if not.
   */
  public get imminentPositionMaybe(): IPositionChange | undefined {
    return this.activityPosition.imminentPosition;
  }

  public get encounter(): IEncounter {
    return this.encounterNode.content;
  }

  public get atLastEncounter(): boolean {
    return !this.encounterNode.next;
  }

  public get encounterNode(): IProgramNode<IEncounter, IAct, IUnit> {
    return this.mainUnitNode.parent as any;
  }

  /**
   * The main unit node, which is always a child of the encounter and may be a
   * parent or a less direct ancestor of the active round.
   */
  public get mainUnit(): IUnit {
    return this.mainUnitNode.content;
  }

  /**
   * At the last main unit of the encounter
   */
  public get atLastMainUnit(): boolean {
    return !this.mainUnitNode.next;
  }

  /**
   * The main unit node, which is always a child of the encounter and may be a
   * parent or a less direct ancestor of the active round.
   */
  public readonly mainUnitNode: IMainUnitNode;

  /**
   * The active round, which could be in the main unit or a subunit.
   * (Note that round.task is the prepared task, not the original task
   * @see ITaskRegistration.prepareContent)
   */
  public get round(): IRound {
    return this.roundNode.content;
  }

  /**
   * At the last round of the active (sub)unit
   */
  public get atLastRound(): boolean {
    return !this.roundNode.next;
  }

  /**
   * The active round, which could be in the main unit or a subunit
   */
  public get roundNode(): IRoundNode {
    return this.roundPedigree;
  }

  /**
   * The task attempts on the current exposure to the active round.
   * Excludes attempts made in prior exposures (e.g. prior to branching or
   * recycling).
   *
   * @see parentUnitPosition
   */
  public get attempts(): ITaskAttempt[] {
    return this.parentUnitPosition.attempts;
  }

  public get lastAttemptMaybe(): ITaskAttempt | undefined {
    return last(this.attempts);
  }

  /**
   * The recycle pass of the main unit.
   * (Currently only relevant for Presentation of Knowledge units where there
   * is a second pass through missed rounds (and their supports)).
   */
  public get parentUnitRecyclePass(): number {
    return this.parentUnitPosition.recycling?.pass ?? 0;
  }

  /**
   * The unit containing the active round. The parent unit
   * may be a subunit, whose parent is not an encounter.
   */
  public get parentUnit(): IUnit {
    return this.parentUnitNode.content;
  }

  public get atLastParentUnit(): boolean {
    return !this.parentUnitNode.next;
  }

  /**
   * The unit containing the active round. The parent unit
   * may be a subunit, whose parent is not an encounter.
   */
  public get parentUnitNode(): IUnitNode {
    return this.roundNode.parent! as IUnitNode;
  }

  /**
   * The unit/subunit position of the unit containing the active round.
   * This may be the main unit, or a subunit.
   * For main units, the parentUnitId and parentRoundId are set to ''
   */
  public get parentUnitPosition(): ISubunitPosition {
    const { activityPosition } = this;

    if (this.isSubunit && !activityPosition.subunitPositions.length) {
      throw new LexiaError(
        `Unable to find subunit position for id ${this.parentUnit.sysId}`,
        RoundContext.displayName,
        RoundContextError.ParentUnitPositionMissing
      ).withContext({ activityPosition });
    }

    return ActivityPositionBuilder.create(activityPosition)
      .activeUnitPositionAsSubunitPosition;
  }

  /**
   * Placement form (aka test) associated with the active placement round.
   * (Only applicable in Placement program mode. Throws an error when absent)
   */
  public get placementForm(): IPlacementForm {
    if (!this._placementForm) {
      throw new LexiaError(
        'Placement form is missing!',
        RoundContext.displayName,
        RoundContextError.PlacementFormMissing
      ).withContext({ activityPosition: this.activityPosition });
    }

    return this._placementForm;
  }

  /**
   * Placement progress from the active placement unit position.
   * Only applicable for placement units. Throws an error otherwise.
   */
  public get placementUnitProgress(): IPlacementProgress {
    const progress = this.parentUnitPosition.placementProgress;
    if (!progress) {
      throw new LexiaError(
        'Placement unit progress is absent!',
        RoundContext.displayName,
        RoundContextError.PlacementUnitProgressMissing
      ).withContext({ activityPosition: this.activityPosition });
    }

    return progress;
  }

  /**
   * The next unit/subunit position as defined in the active activityPosition
   * imminentPosition property.
   * This is equivalent to the parentUnitPosition of the imminentPosition
   */
  public get imminentParentUnitPosition(): ISubunitPosition {
    return ActivityPositionBuilder.create(
      this.imminentPosition.activityPosition
    ).activeUnitPositionAsSubunitPosition;
  }

  public get grandparentUnit(): IUnit | undefined {
    const node = this.grandparentUnitNode;

    return node && node.content;
  }

  public get grandparentUnitNode(): IUnitNode | undefined {
    return this.parentUnitNode.parentUnit;
  }

  /**
   * True when the round's parent unit is not a "main" unit and is instead
   * a child of an instruction or forked round.
   */
  public get isSubunit(): boolean {
    return !!this.mainUnitNode.childUnit;
  }

  /**
   * The subunit, if the active round is a subunit round
   */
  public get subunit(): IUnit | undefined {
    const node = this.subunitNode;

    return node && node.content;
  }

  /**
   * The subunit node, if the active round is a subunit round
   */
  public get subunitNode(): IUnitNode | undefined {
    return this.isSubunit ? (this.roundNode.parent as IUnitNode) : undefined;
  }

  /**
   * Type of subunit (active round's parent).
   */
  public get subunitType(): SubunitTypeExtended {
    return this.subunitNode
      ? this.subunitNode.subunitType
      : SubunitType.NotApplicable;
  }

  /**
   * Standard instructional step unit.
   * Always the grandparent of an instruction unit.
   * May or may not be the main unit (e.g. could be a fork subunit).
   */
  public get standardUnit(): IUnit {
    return this.standardUnitNode.content;
  }

  /**
   * Standard instructional step unit.
   * Always the grandparent of an instruction unit.
   * May or may not be the main unit (e.g. could be a fork subunit).
   */
  public get standardUnitNode(): IUnitNode {
    return this.standardRoundNode.parent! as IUnitNode;
  }

  /**
   * The same as round for a standard active round.
   * For an instruction round, however, this is the standard round
   * that branched to instruction.
   */
  public get standardRound(): IRound {
    return this.standardRoundNode.content;
  }

  /**
   * The same as round for a standard active round.
   * For an instruction round, however, this is the standard round
   * that branched to instruction.
   */
  public get standardRoundNode(): IRoundNode {
    return this.isInstructionRound
      ? (this.subunitNode!.parent! as IRoundNode)
      : this.roundNode;
  }

  /**
   * Returns true when the active round is part of an instruction subunit
   */
  public get isInstructionRound(): boolean {
    return this.parentUnit.instructionalStep === InstructionalStep.Instruction;
  }

  /**
   * Returns true when active round is finished
   */
  public get isRoundComplete(): boolean {
    const positionChangeType =
      this.activityPosition.imminentPosition?.changeType ||
      PositionChangeType.None;

    return [
      PositionChangeType.ActivityCompletion,
      PositionChangeType.EncounterCompletion,
      PositionChangeType.LevelCompletion,
      PositionChangeType.OnboardingCompletion,
      PositionChangeType.PlacementCompletion,
      PositionChangeType.RoundCompletion,
      PositionChangeType.UnitCompletion,
      PositionChangeType.UnitRecycling
    ].includes(positionChangeType);
  }

  /**
   * Returns true when:
   * - the parent unit prop hasOnscreenCharacter === true,
   * - and
   * - the task prop allowOnscreenCharacter === true
   */
  public get hasOnscreenCharacter(): boolean {
    const { task } = this.round;

    return (
      this.parentUnit.hasOnscreenCharacter && !!task?.allowOnscreenCharacter
    );
  }

  /**
   * The task registration for the active task.
   */
  public get taskRegistration(): ITaskRegistration {
    return this.taskRegistry.get(this.getTask().taskType)!;
  }

  /**
   * The active unit save point where we accumulate information that will be
   * saved to the student API.
   */
  public get savePoint(): IUnitSavePoint {
    return this.getSavePoint();
  }

  constructor(
    public readonly programMode: ProgramMode,
    public readonly activityPositionMap: ActivityPositionMap,
    private readonly roundPedigree: IRoundNode,
    private readonly taskRegistry: TaskRegistry,
    private readonly savePoints: IUnitSavePoint[],
    private readonly _placementForm: IPlacementForm | undefined
  ) {
    if (!this.roundPedigree.parent) {
      throw new LexiaError(
        'Root round lacks a parent!',
        RoundContext.displayName,
        RoundContextError.OrphanRootRound
      ).withContext({ round: this.round });
    }

    this.mainUnitNode = this.determineMainUnit(
      this.roundPedigree.parent as IUnitNode
    );
  }

  private determineMainUnit(candidate: IUnitNode): IMainUnitNode {
    const mainUnitNode = this.findMainUnit(candidate);

    if (
      !mainUnitNode.parent ||
      mainUnitNode.parent.contentType !== LoboContentType.Encounter
    ) {
      throw new LexiaError(
        'Unable to find main unit',
        RoundContext.displayName,
        RoundContextError.MainRoundMissing
      ).withContext({
        deepestAncestor: mainUnitNode.content,
        round: this.round
      });
    }

    return mainUnitNode;
  }

  /**
   * Recurse up the tree until we hit a node with a parent encounter. That's our
   * main unit.
   */
  private findMainUnit(candidate: IUnitNode): IMainUnitNode {
    return candidate.parentUnit
      ? this.findMainUnit(candidate.parentUnit)
      : (candidate as IMainUnitNode);
  }

  public findEncounterIndex(): number {
    return this.act.encounters.findIndex(e => e.sysId === this.encounter.sysId);
  }

  /**
   * Return the save point for the active unit with the given save point status
   * (defaults to Deferred)
   */
  public getSavePoint(status = UnitSavePointStatus.Deferred): IUnitSavePoint {
    const unitId = this.parentUnit.sysId;
    const savePoint = [...this.savePoints]
      .reverse()
      .find(sp => sp.unitId === unitId && sp.status === status);

    if (!savePoint) {
      throw new LexiaError(
        `No save point found for unit id "${unitId}" with status ${status}`,
        RoundContext.displayName,
        RoundContextError.SavePointMissing
      ).withContext({ savePoints: this.savePoints, status, unitId });
    }

    return savePoint;
  }

  public getTask<T extends ITask = ITask>(): T {
    const { task } = this.round;

    if (!task) {
      throw new LexiaError(
        'Active round lacks a task',
        RoundContext.displayName,
        RoundContextError.TaskMissing
      );
    }

    return task as T;
  }
}

export enum RoundContextError {
  ActivityPositionMissing = 'ActivityPositionMissing',
  ImminentPositionMissing = 'ImminentPositionMissing',
  MainRoundMissing = 'MainRoundMissing',
  OrphanRootRound = 'OrphanRootRound',
  ParentUnitPositionMissing = 'ParentUnitPositionMissing',
  PlacementFormMissing = 'PlacementFormMissing',
  PlacementUnitProgressMissing = 'PlacementUnitProgressMissing',
  SavePointMissing = 'SavePointMissing',
  TaskMissing = 'TaskMissing'
}
