import { AnimatedView, Types } from 'common-ui';
import { SpeechBubbleTailSvg } from './SpeechBubbleTail.lx-svg';
import { CharacterSpeechBubbleTailStyles } from './CharacterSpeechBubbleTail.styles';
import { Color } from 'common-styles';

export interface ICharacterSpeechBubbleTailProps {
  animatedStyle?: Types.AnimatedViewStyle;
  borderFill?: string;
  backgroundFill?: string;
}

export function CharacterSpeechBubbleTail(
  props: ICharacterSpeechBubbleTailProps
) {
  const { animatedStyle, backgroundFill, borderFill } = props;

  const styles = CharacterSpeechBubbleTailStyles.get();

  return (
    <AnimatedView
      animatedStyle={animatedStyle}
      style={styles}
      testId={CharacterSpeechBubbleTail.displayName}
    >
      <SpeechBubbleTailSvg
        backgroundFill={backgroundFill || Color.NearWhite}
        borderFill={borderFill || Color.Black}
      />
    </AnimatedView>
  );
}
CharacterSpeechBubbleTail.displayName = 'CharacterSpeechBubbleTail';
