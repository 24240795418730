import { IScreenplay } from '@lexialearning/lobo-common';
import { sample } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CharacterSceneCharacterAnimationLayer,
  CharacterSceneElementName
} from 'feature-areas/character-scene/character-scene.model';
import { ProgramContextSelector, RoundContext } from 'curriculum-services';
import {
  IAnimationRequest,
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import {
  CharacterAnimationCategory,
  PreparedScenes
} from 'services/storm-lobo';
import {
  LanguageFrameSession,
  SreSelector,
  SreSessionAction,
  SreSessionActionFinish,
  SreSessionType
} from 'sre';
import { TaskSelector } from 'task-components/core';
import { TaskRegistry } from 'task-components/core/registry';
import { SeeSpeakActionType } from '../redux/see-speak-redux.model';
import { SeeSpeakActionToggleListen } from '../redux/SeeSpeak.action';
import { SeeSpeakSelector } from '../redux/SeeSpeak.selector';
import { ChoralScreenplayInfo } from '../see-speak.model';

export interface IToggleListenEpicDeps {
  taskRegistry: TaskRegistry;
  preparedScenes: PreparedScenes;
  context: RoundContext;
}

export function toggleListenEpic(
  action$: Observable<SeeSpeakActionToggleListen>,
  state$: StateObservable<unknown>,
  deps: IToggleListenEpicDeps
): Observable<ScreenplayActionPlay | SreSessionActionFinish> {
  return action$.pipe(
    ofType(SeeSpeakActionType.ToggleListen),
    map(() => {
      const state = state$.value;

      if (SreSelector.getIsListeningTo(SreSessionType.LanguageFrame, state)) {
        return SreSessionAction.finish.request();
      }

      const context = ProgramContextSelector.getRoundContext(state);

      const isChoral = SeeSpeakSelector.isChoral(state);
      const sreSessionOptions = SeeSpeakSelector.getSreSessionOptions(state);
      const listenScreenplay = isChoral
        ? buildChoralScreenplay(context, sreSessionOptions)
        : buildListenScreenplay(
            deps.preparedScenes,
            context.hasOnscreenCharacter,
            sreSessionOptions
          );

      const phase = TaskSelector.getPhase(state);

      const screenplay = deps.taskRegistry.screenplayEditor.edit(
        listenScreenplay,
        context,
        phase
      );

      return ScreenplayAction.play({ screenplay });
    })
  );
}
toggleListenEpic.displayName = 'toggleListenEpic';

export const SEE_SPEAK_LISTEN_SCREENPLAY_ID = 'seeSpeakListenScreenplay';
function buildListenScreenplay(
  preparedScenes: PreparedScenes,
  hasOnscreenCharacter: boolean,
  sreSessionOptions: LanguageFrameSession.IConfig
): IScreenplay {
  const builder = ScreenplayBuilder.create(
    SEE_SPEAK_LISTEN_SCREENPLAY_ID
  ).addReduxAction(SreSessionAction.listen(sreSessionOptions));

  if (hasOnscreenCharacter) {
    const animation = getListeningAnimation(preparedScenes);
    builder.addStormAnimation(animation);
  }

  return builder.screenplay;
}

function getListeningAnimation(
  preparedScenes: PreparedScenes
): IAnimationRequest {
  const preparedScene = preparedScenes.encounterOrPlacement!;
  const animation = preparedScene.character.pickOneAnimation(
    CharacterAnimationCategory.Listening
  );

  return {
    animationLayer: CharacterSceneCharacterAnimationLayer.Gesture,
    blendTimeSeconds: 0.25,
    name: animation.name,
    targetElement: CharacterSceneElementName.Character,
    targetScene: preparedScene.scene.id
  };
}

function buildChoralScreenplay(
  { act, round }: RoundContext,
  sreSessionOptions: LanguageFrameSession.IConfig
) {
  const { countdown } = act.character;
  const { presentation } = round;

  return ScreenplayBuilder.create(ChoralScreenplayInfo.ScreenplayId)
    .addScreenplay(sample(countdown!))
    .addReduxAction(SreSessionAction.listen(sreSessionOptions))
    .addDelay(600)
    .addScreenplay(presentation).screenplay;
}
