import { Justify } from 'common-styles';
import { LoginFormSharedStyles } from '../../LoginFormShared.styles';

export class EducatorLinksStyles {
  public static readonly styles = {
    linkRow: {
      height: 20,
      justifyContent: Justify.SpaceBetween,
      marginBottom: 15,
      width: LoginFormSharedStyles.FormWidth
    }
  };

  public static get() {
    return this.styles;
  }
}
