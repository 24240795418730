import {
  ActivityPositionMap,
  IActivityPosition,
  ILevel,
  IPlacement,
  IProgramPosition,
  ITask,
  IUnit,
  IUnitSavePoint,
  TaskEvaluationResult
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { last, uniq } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { ProfileSelector } from 'services/profile';
import { LevelSelector } from '../../level';
import { PlacementSelector } from '../../placement';
import { UnitSelector } from '../../unit';
import { RoundContextFactory } from '../context-factories/RoundContextFactory';
import { EncounterContext } from '../EncounterContext';
import { ActivityPositionBuilder } from '../epics';
import {
  ISessionHistoryEvent,
  ProgramMode,
  SessionHistoryPageType
} from '../program-context.model';
import { RoundContext } from '../RoundContext';
import { ExcellingLevelDeterminer } from '../service-helpers';
import { IProgramContextState } from './program-context-redux.model';
import { RoundSelector } from './Round.selector';

export class ProgramContextSelector {
  public static readonly displayName = 'ProgramContextSelector';

  public static getEncounterContext: (state: unknown) => EncounterContext;

  public static getEncounterContextMaybe: (
    state: unknown
  ) => EncounterContext | undefined;

  public static getActivityPositions: (state: unknown) => IActivityPosition[];

  public static getActivityPositionMap: (
    state: unknown
  ) => Map<string, IActivityPosition>;

  /**
   * Returns the Act for the last worked-in Encounter, IF that Encounter was not
   * completed and the work was done within the last two weeks
   */
  public static getLastEncounterActPositionMaybe: (
    state: unknown
  ) => IActivityPosition | undefined;

  /**
   * Return the evaluation result from the last task attempt on the active
   * activity position (or undefined).
   */
  public static getLastEvaluationResultMaybe: (
    state: unknown
  ) => TaskEvaluationResult | undefined;

  /**
   * Return the final level of the program, which is typically 19 except for
   * non-excelling students in lower grades.
   * @see ExcellingLevelDeterminer
   */
  public static getFinalLevel: (state: unknown) => number;

  public static getMode: (state: unknown) => ProgramMode;

  /**
   * Returns true when program mode is either Onboarding or Placement
   */
  public static isOnboardingOrPlacement: (state: unknown) => boolean;

  public static getPosition: (state: unknown) => IProgramPosition;

  public static getRoundContext: (state: unknown) => RoundContext;

  public static getRoundContextMaybe: (
    state: unknown
  ) => RoundContext | undefined;

  public static getSavePoints: (state: unknown) => IUnitSavePoint[];

  /**
   * Return list of unique sysId's of acts entered during this session.
   * Avoid using this as it is only used by one screenplay and we may want to
   * deprecate how we track this. See LOBO-12367
   */
  public static getActsEntered: (state: unknown) => string[];

  /**
   * Avoid using this. @see getActsEntered
   */
  public static getSessionHistory: (state: unknown) => ISessionHistoryEvent[];

  /**
   * Returns true when there is an act that has been started
   */
  public static hasProgressInLevel: (state: unknown) => boolean | undefined;

  public static createSelectors(
    selector: (state: any) => IProgramContextState,
    factory: RoundContextFactory
  ) {
    this.getActivityPositions = createSelector(
      selector,
      (state: IProgramContextState) => state.position.activityPositions
    );
    this.getActivityPositionMap = createSelector(
      this.getActivityPositions,
      (activityPositions: IActivityPosition[]) =>
        this.buildActivityPositionMap(activityPositions)
    );

    this.getEncounterContextMaybe = createSelector(
      state => LevelSelector.getLevelMaybe(state),
      state => this.getActivityPositionMaybe(state),
      state => this.getActivityPositionMap(state),
      (
        level: ILevel | undefined,
        activityPosition: IActivityPosition | undefined,
        map: ActivityPositionMap
      ) =>
        level &&
        activityPosition &&
        factory.createPartial(level, activityPosition, map)
    );

    this.getEncounterContext = createSelector(
      state => this.getEncounterContextMaybe(state),
      (context: EncounterContext | undefined) => {
        if (!context) {
          throw new LexiaError(
            'Unable to create encounter context',
            ProgramContextSelector.displayName,
            ProgramContextSelectorError.EncounterContextMissing
          );
        }

        return context;
      }
    );

    this.getFinalLevel = state =>
      ExcellingLevelDeterminer.determineFinalLevel(
        ProfileSelector.getGrade(state),
        LevelSelector.getLevelNumber(state)
      );

    this.getLastEvaluationResultMaybe = createSelector(
      state => this.getActivityPositionMaybe(state),
      (a: IActivityPosition | undefined) =>
        last(ActivityPositionBuilder.create(a).activeUnitPosition.attempts)
          ?.result
    );

    this.getMode = createSelector(selector, state => state.mode);
    this.isOnboardingOrPlacement = createSelector(this.getMode, mode =>
      [ProgramMode.Onboarding, ProgramMode.Placement].includes(mode)
    );

    this.getPosition = createSelector(
      selector,
      (state: IProgramContextState) => state.position
    );

    this.getRoundContext = createSelector(
      state => this.getMode(state),
      state => LevelSelector.getLevel(state),
      state => this.getActivityPosition(state),
      state => this.getActivityPositionMap(state),
      state => UnitSelector.getAncestorsAndUnit(state),
      state => factory.preparedTaskSelector.getTask(state),
      state => this.getSavePoints(state),
      state => PlacementSelector.getPlacementMaybe(state),
      (
        mode: ProgramMode,
        level: ILevel,
        activityPosition: IActivityPosition,
        map: ActivityPositionMap,
        units: IUnit[],
        preparedTask: ITask,
        savePoints: IUnitSavePoint[],
        placement: IPlacement | undefined
      ) =>
        factory.create(
          mode,
          level,
          activityPosition,
          map,
          units,
          preparedTask,
          savePoints,
          placement
        )
    );

    this.getRoundContextMaybe = createSelector(
      state => this.getMode(state),
      state => LevelSelector.getLevelMaybe(state),
      state => this.getPosition(state).activeActivityId,
      state => this.getActivityPositionMap(state),
      state => UnitSelector.getAncestorsAndUnit(state),
      state => factory.preparedTaskSelector.getTaskMaybe(state),
      state => this.getSavePoints(state),
      state => PlacementSelector.getPlacementMaybe(state),
      (
        mode: ProgramMode,
        level: ILevel | undefined,
        activeActivityId: string | undefined,
        map: ActivityPositionMap,
        units: IUnit[],
        preparedTask: ITask | undefined,
        savePoints: IUnitSavePoint[],
        placement: IPlacement | undefined
      ) => {
        const activityPosition = activeActivityId
          ? map.get(activeActivityId)
          : undefined;

        return (
          level &&
          activityPosition &&
          preparedTask &&
          factory.maybeCreate(
            mode,
            level,
            activityPosition,
            map,
            units,
            preparedTask!,
            savePoints,
            placement
          )
        );
      }
    );

    this.getSessionHistory = createSelector(
      selector,
      (state: IProgramContextState) => state.sessionHistory
    );

    this.getActsEntered = createSelector(
      this.getSessionHistory,
      (sessionHistory: ISessionHistoryEvent[]) =>
        uniq(
          sessionHistory
            .filter(e => e.pageType === SessionHistoryPageType.Act)
            .map(e => e.actId)
        )
    );

    this.getSavePoints = createSelector(selector, state => state.savePoints);

    this.getLastEncounterActPositionMaybe = createSelector(
      state => ProgramContextSelector.isOnboardingOrPlacement(state),
      state => LevelSelector.getLevel(state),
      state => this.getActivityPositions(state),
      state => this.getActivityPositionMap(state),
      (
        isOnboardingOrPlacement: boolean,
        level: ILevel | undefined,
        activityPositions: IActivityPosition[],
        map: ActivityPositionMap
      ) => {
        if (isOnboardingOrPlacement || !level || !activityPositions.length) {
          return;
        }

        const today = new Date();
        const twoWeeksAgo = new Date(today.setDate(today.getDate() - 14));

        return activityPositions
          .filter(ap => !!ap.lastProgressDate)
          .reduce(
            (prevPos, pos) => {
              const context = factory.createPartial(level, pos, map);
              const currDate = this.getValidDateMaybe(
                pos,
                context,
                twoWeeksAgo
              );
              const isMostRecent =
                !!currDate &&
                (!prevPos || currDate > new Date(prevPos.lastProgressDate));

              return isMostRecent ? pos : prevPos;
            },
            undefined as IActivityPosition | undefined
          );
      }
    );

    this.hasProgressInLevel = createSelector(
      state => LevelSelector.getLevelMaybe(state),
      state => this.getActivityPositions(state),
      state => this.getActivityPositionMap(state),
      (
        level: ILevel | undefined,
        activityPositions: IActivityPosition[],
        map: ActivityPositionMap
      ) => {
        if (!level || !activityPositions.length) {
          return;
        }

        const hasProgressInSomeAct = activityPositions
          .map(ap => {
            const context = factory.createPartial(level, ap, map);
            const isStartOfAct = context.isStartOfAct;

            return !!ap.lastProgressDate || !isStartOfAct;
          })
          .some(hasProgress => hasProgress);

        return hasProgressInSomeAct;
      }
    );

    RoundSelector.createSelectors(this.getRoundContextMaybe);
  }

  /**
   * An act's lastProgressDate is valid in this context if it is for recent unfinished Encounter progress
   * This means that:
   * 1) it exists,
   * 2) Act is not complete,
   * 3) position is not the start of a new Encounter
   * 4) it is within the last 2 weeks
   * @param activityPosition
   * @param encounterContext
   * @returns
   */
  private static getValidDateMaybe(
    { isComplete, lastProgressDate }: IActivityPosition,
    { isStartOfEncounter }: EncounterContext,
    twoWeeksAgo: Date
  ): Date | undefined {
    if (!!lastProgressDate && !isComplete && !isStartOfEncounter) {
      const date = new Date(lastProgressDate);

      if (date > twoWeeksAgo) return date;
    }
  }

  public static buildActivityPositionMap(
    activityPositions: IActivityPosition[]
  ): Map<string, IActivityPosition> {
    return activityPositions.reduce(
      (positionMap, position) => positionMap.set(position.activityId, position),
      new Map<string, IActivityPosition>()
    );
  }

  public static getActivityPositionMaybe(
    state: unknown
  ): IActivityPosition | undefined {
    const { activeActivityId } = ProgramContextSelector.getPosition(state);

    return activeActivityId
      ? ProgramContextSelector.getActivityPosition(state, activeActivityId)
      : undefined;
  }

  public static getActivityPosition(
    state: unknown,
    actId?: string
  ): IActivityPosition {
    const map = ProgramContextSelector.getActivityPositionMap(state);
    const activityId =
      actId ?? ProgramContextSelector.getPosition(state).activeActivityId!;
    const pos = map.get(activityId);

    if (!pos) {
      throw new LexiaError(
        'No activity position found',
        this.displayName,
        ProgramContextSelectorError.ActivityPositionMissing
      ).withContext({
        activityId,
        activityPositions: Array.from(map.values())
      });
    }

    return pos;
  }
}

export enum ProgramContextSelectorError {
  ActivityPositionMissing = 'ActivityPositionMissing',
  EncounterContextMissing = 'EncounterContextMissing'
}
