import { Row, Text } from 'common-ui';
import { ProductInfo } from '../product-info';
import { LoboVersionStyles } from './LoboVersion.styles';

export function LoboVersion() {
  const styles = LoboVersionStyles.get();

  return (
    <Row>
      <Text style={styles.text}>{ProductInfo.Version}</Text>
    </Row>
  );
}
