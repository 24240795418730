/* eslint-disable max-classes-per-file */
import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { StandardSceneAnimationName } from 'services/storm-lobo';

export enum CalibrationAnimationCategory {
  Root = 'Calibration.Root',
  Gabber = 'Calibration.Gabber'
}

export class CalibrationSceneElementName {
  public static readonly Gabber = 'gabber_1';
}

export class CalibrationSceneAnimationName {
  public static readonly Root = {
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro
  };

  public static readonly Gabber = {
    Idle: StandardSceneAnimationName.Idle,
    Negative: 'negative',
    Positive: 'positive',
    TooLoud: 'too_loud',
    TooQuiet: 'too_quiet'
  };
}

export interface ICalibrationStormAssets {
  GabberAnimations: IAnimationDefinition[];
}
