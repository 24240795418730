import {
  AccessibilityRole,
  useActiveComponentState
} from '@lexialearning/common-ui';
import { ButtonBase } from '../button';
import {
  UtilityButtonColorSetName,
  UtilityButtonSvg,
  UtilityButtonIconType
} from '../button/utility-button';
import { Text } from '../text';
import { View } from '../view';
import { CheckboxStyles, ICheckboxStyleOverride } from './Checkbox.styles';
import { IButtonBaseProps } from '../button/ButtonBase';

export interface ICheckboxProps extends IButtonBaseProps {
  isChecked: boolean;
  label: string;
  labelId: string;
  onToggle(): void;
  styleOverride?: ICheckboxStyleOverride;
  testId?: string;
}

export function Checkbox(props: ICheckboxProps) {
  const {
    isChecked,
    label,
    labelId,
    onToggle,
    styleOverride,
    testId,
    ...restProps
  } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = CheckboxStyles.build(activeComponentState, styleOverride);

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      onPress={onToggle}
      style={styles.container}
      testId={testId || 'CheckboxButton'}
    >
      <View
        style={styles.box}
        accessibilityRole={AccessibilityRole.CheckBox}
        accessibilityState={{ checked: isChecked }}
        ariaLabelledBy={labelId}
      >
        {isChecked && (
          <UtilityButtonSvg
            iconType={UtilityButtonIconType.Close}
            colorSetName={UtilityButtonColorSetName.Black}
            scale={0.4}
          />
        )}
      </View>
      <Text style={styles.label} id={labelId}>
        {label}
      </Text>
    </ButtonBase>
  );
}
Checkbox.displayName = 'Checkbox';
