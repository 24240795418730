import { Position } from '@lexialearning/common-ui';
import { Types } from '@lexialearning/reactxp';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { ENCOURAGEMENT_TOTAL_ANIMATION_DURATION } from '../level-certificate-encouragement';

const INITIAL_DELAY = ENCOURAGEMENT_TOTAL_ANIMATION_DURATION;
const OPACITY_DURATION = 200;
const ROTATION_DURATION = 200;

export class LevelCertificateBadgeAnimatedStyles {
  private readonly styles: {
    container: Types.ViewStyle;
    badgeAnimated: Types.AnimatedViewStyle;
  };

  private readonly animations: {
    callout: Types.Animated.CompositeAnimation;
  };

  constructor() {
    const opacityValue = loboAnimated.createValue(0);
    const rotateValue = loboAnimated.createValue(0);

    const opacityInOutIn = [
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
        delay: INITIAL_DELAY,
        duration: OPACITY_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
        duration: OPACITY_DURATION,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
        duration: OPACITY_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      })
    ];
    const rotateBackForth = [
      loboAnimated.timing(AnimatableCSSProperty.Rotate, rotateValue, {
        delay: 200,
        duration: ROTATION_DURATION / 2,
        easing: loboAnimated.Easing.In(),
        toValue: -1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Rotate, rotateValue, {
        duration: ROTATION_DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.Rotate, rotateValue, {
        duration: ROTATION_DURATION / 2,
        easing: loboAnimated.Easing.In(),
        toValue: 0
      })
    ];
    this.animations = {
      callout: loboAnimated.sequence([...opacityInOutIn, ...rotateBackForth])
    };

    this.styles = {
      badgeAnimated: {
        opacity: opacityValue,
        transform: [
          {
            rotate: loboAnimated.interpolate(
              rotateValue,
              [-1, 0, 1],
              ['-45deg', '0deg', '45deg']
            )
          }
        ]
      },
      container: {
        position: Position.Absolute,
        right: -6,
        top: -6
      }
    };
  }

  // istanbul ignore next - trivial
  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
