import memoizeOne from 'memoize-one';
import { Color, InteractionState, Justify, TextAlign } from 'common-styles';
import {
  ActiveComponentState,
  Border,
  Direction
} from '@lexialearning/common-ui';
import { KeyNavHelper } from 'common-ui';

export interface ITextChoiceStyleProps {
  interactionState: InteractionState;
}
export class TextChoiceStyles {
  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      interactionState: InteractionState
    ) => {
      const isHighlighted = interactionState === InteractionState.Highlighted;
      const isHovered = activeComponentState === ActiveComponentState.Hovered;

      return {
        button: {
          borderColor: Color.Transparent,
          borderRadius: 25,
          borderStyle: Border.Solid,
          borderWidth: 5,
          flexDirection: Direction.Row,
          justifyContent: Justify.Start,
          marginVertical: -1,
          padding: 0,
          paddingLeft: 5,
          ...(KeyNavHelper.isKeyNav(activeComponentState) && {
            backgroundColor: Color.getSetFor(activeComponentState).background,
            borderColor: Color.getSetFor(activeComponentState).border
          })
        },
        text: {
          color: Color.getSetFor(
            isHighlighted
              ? InteractionState.Default
              : isHovered
              ? InteractionState.Selected
              : interactionState
          ).text,
          textAlign: TextAlign.Left
        },
        textContainer: {
          height: 45,
          marginLeft: 10,
          paddingHorizontal: 15,
          paddingVertical: 7,
          ...(isHighlighted
            ? {
                backgroundColor: Color.getSetFor(InteractionState.Highlighted)
                  .background,
                borderRadius: 23
              }
            : {})
        }
      };
    }
  );
}
