import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, mergeMap, switchMap } from 'rxjs/operators';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { IntroductionScene } from '../IntroductionScene';
import { IntroductionSceneDefinitionBuilder } from '../IntroductionSceneDefinitionBuilder';
import { IntroductionSceneActionType } from '../redux/introduction-scene-redux.model';
import { IntroductionScenePrepareAction } from '../redux/IntroductionScene.action';

export interface IPrepareIntroductionSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareIntroductionSceneEpic(
  action$: Observable<IntroductionScenePrepareAction>,
  _state$: StateObservable<unknown>,
  deps: IPrepareIntroductionSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(IntroductionSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes } = deps;

      preparedScenes.introduction = undefined;

      const { definition } = IntroductionSceneDefinitionBuilder.create();

      await deps.stormService.initialization;

      return definition;
    }),
    mergeMap(async definition => {
      const { preparedScenes, stormService } = deps;

      const scene = await stormService.prepareScene(definition);
      const introduction = new IntroductionScene(scene);
      preparedScenes.introduction = introduction;
    }),
    ignoreElements()
  );
}
prepareIntroductionSceneEpic.displayName = 'prepareIntroductionSceneEpic';
