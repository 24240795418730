import memoizeOne from 'memoize-one';
import { AnimatableCSSProperty, Color, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { Overflow } from '@lexialearning/common-ui';

export enum UserChoiceCardType {
  LongText = 'longText',
  ShortText = 'shortText',
  Image = 'image'
}
export interface IUserChoiceCardAnimations {
  animateEntry?: Types.Animated.CompositeAnimation;
  animateExit?: Types.Animated.CompositeAnimation;
}
export class UserChoiceCardAnimatedStyles {
  private static readonly EntryAnimationTiming = 750;

  private static readonly ExitAnimationTiming = 2000;

  private static readonly EntryEasing = loboAnimated.Easing.CubicBezier(
    0.215,
    0.61,
    0.355,
    1
  );

  private static readonly OffscreenTopValue = -2000;

  private static readonly animationDelays = [0, 400, 200];

  private static readonly cardColors = [
    Color.Aqua,
    Color.BrightOrange,
    Color.Pink
  ];

  private static readonly bottomPlacement = [17, 0, 23];

  private readonly animatedValues: {
    top: Types.AnimatedValue;
  };

  private readonly animations: {
    entry: Types.Animated.CompositeAnimation;
    exit: Types.Animated.CompositeAnimation;
  };

  constructor(delayIndex: number) {
    this.animatedValues = {
      top: loboAnimated.createValue(
        UserChoiceCardAnimatedStyles.OffscreenTopValue
      )
    };

    this.animations = {
      entry: this.createEntryAnimation(delayIndex),
      exit: this.createExitAnimation()
    };
  }

  public createEntryAnimation(
    delayIndex: number
  ): Types.Animated.CompositeAnimation {
    return loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        delay: UserChoiceCardAnimatedStyles.animationDelays[delayIndex],
        duration: UserChoiceCardAnimatedStyles.EntryAnimationTiming * 0.5,
        easing: UserChoiceCardAnimatedStyles.EntryEasing,
        toValue: 50
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration: UserChoiceCardAnimatedStyles.EntryAnimationTiming * 0.2,
        easing: UserChoiceCardAnimatedStyles.EntryEasing,
        toValue: -25
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration: UserChoiceCardAnimatedStyles.EntryAnimationTiming * 0.2,
        easing: UserChoiceCardAnimatedStyles.EntryEasing,
        toValue: 10
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration: UserChoiceCardAnimatedStyles.EntryAnimationTiming * 0.1,
        easing: UserChoiceCardAnimatedStyles.EntryEasing,
        toValue: 0
      })
    ]);
  }

  public createExitAnimation(): Types.Animated.CompositeAnimation {
    return loboAnimated.timing(
      AnimatableCSSProperty.Top,
      this.animatedValues.top,
      {
        duration: UserChoiceCardAnimatedStyles.ExitAnimationTiming,
        easing: loboAnimated.Easing.Out(),
        toValue: UserChoiceCardAnimatedStyles.OffscreenTopValue
      }
    );
  }

  public getAnimations() {
    return this.animations;
  }

  private static readonly styles = {
    image: {
      height: 364,
      width: 196
    },
    innerCardContainer: {
      borderColor: Color.NearWhite,
      borderRadius: 25,
      borderWidth: 2,
      overflow: Overflow.Hidden
    }
  };

  public readonly build = memoizeOne(
    (cardType: UserChoiceCardType, index: number) => ({
      ...UserChoiceCardAnimatedStyles.styles,
      animatedContainer: {
        top: this.animatedValues.top
      },
      cardContainer: {
        borderColor: UserChoiceCardAnimatedStyles.cardColors[index],
        borderRadius: 25,
        borderWidth: 2
      },
      choiceContainerStyleOverride: {
        button: {
          borderRadius: 34,
          marginBottom: UserChoiceCardAnimatedStyles.bottomPlacement[index],
          marginHorizontal: 5,
          marginTop: 5,
          padding: 0
        },
        interactiveContainerStyleOverride: {
          borderRadius: 29,
          borderWidth: 4
        }
      },
      tag: {
        stringColor: UserChoiceCardAnimatedStyles.cardColors[index]
      },
      textContainer: {
        backgroundColor: Color.NearWhite,
        minHeight: cardType === UserChoiceCardType.LongText ? 136 : 86,
        paddingBottom: 16,
        paddingLeft: 13,
        paddingRight: 13,
        paddingTop: 12,
        width: 196
      }
    })
  );
}
