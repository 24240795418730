import {
  DeepLinkObservableFactoryBaseWithLevelsLoading,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';

export class DefaultDeepLinkObservableFactory extends DeepLinkObservableFactoryBaseWithLevelsLoading {
  public static readonly displayName = 'DefaultDeepLinkObservableFactorys';

  public static createFor(
    deps: IDeepLinkDeps
  ): DefaultDeepLinkObservableFactory {
    return new DefaultDeepLinkObservableFactory(
      deps
    ).awaitLevelsThenLoadLevel();
  }

  constructor(public readonly deps: IDeepLinkDeps) {
    super(deps);
  }
}
