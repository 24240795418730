export class CheckMarkTabStyles {
  public static get() {
    return {
      checkMarkContainer: {
        height: 40,
        marginLeft: 10,
        marginRight: 10,
        width: 60
      }
    };
  }
}
