import { IPlacementWithPool } from '@lexialearning/lobo-common';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  ContentfulApi,
  IContentProvider,
  ILinkChaser,
  IParentContext,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { ContentfulEntry } from '@lexialearning/lobo-common/cms/contentful';
import { LexiaError } from '@lexialearning/utils';

/**
 * The placement content is way too big to load directly from the CMS in a
 * single call. This class is mainly to support preview mode by supplying
 * a "linkChaser".
 */
export class PlacementContentLoader implements ILinkChaser {
  public static readonly displayName = 'PlacementContentLoader';

  // The following values were empirically determined to best compromise
  // chatty/chunky ratio (given the sample content)
  private static readonly InitialDepth = 6;

  private static readonly ChaseDepth = 5;

  private static cachedPlacement?: IPlacementWithPool;

  private readonly contentfulApi: ContentfulApi;

  private readonly links = new Map<string, ILink>();

  public constructor(
    configProvider: IConfigProvider,
    private readonly contentProvider: IContentProvider
  ) {
    this.contentfulApi = new ContentfulApi(configProvider);
  }

  public async load(): Promise<IPlacementWithPool> {
    if (PlacementContentLoader.cachedPlacement) {
      return PlacementContentLoader.cachedPlacement;
    }

    const placements =
      await this.contentProvider.loadByContentType<IPlacementWithPool>(
        LoboContentType.Placement,

        {
          linkChaser: this,
          nestingDepth: PlacementContentLoader.InitialDepth
        }
      );

    if (!placements.length) {
      throw new LexiaError(
        'No placement content found!',
        PlacementContentLoader.displayName,
        PlacementContentLoaderError.NotFound
      );
    }

    PlacementContentLoader.cachedPlacement = placements[0];

    return PlacementContentLoader.cachedPlacement;
  }

  /**
   * This is called whenever the initial content load reached a "dangling link"
   * meaning the nesting level was too shallow. So we make decisions as to
   * whether it is worth pursuing that branch of content with a subsequent
   * load, or return undefined otherwise.
   */
  public async loadLink(
    sysId: string,
    _linkType: unknown,
    parentContext: IParentContext
  ): Promise<ContentfulEntry<unknown> | undefined> {
    const branch = this.buildBranch(parentContext);
    const disposition = this.determineDisposition(sysId, branch);

    const entry = disposition.nestingDepth
      ? await this.contentfulApi.contentfulService.getEntry(sysId, {
          include: disposition.nestingDepth
        })
      : disposition.entry;
    disposition.entry = entry;

    return entry;
  }

  /**
   * Recursively build a "path" to the dangling link entry ID by traversing
   * up the parentContext tree.
   */
  private buildBranch(
    parentContext: IParentContext,
    branch?: IBranch
  ): IBranch {
    const contentType = ContentfulApi.getContentType(parentContext.parent);
    const path = `/${contentType}.${parentContext.fieldName}${
      branch?.path ?? ''
    }`;

    const revisedBranch: IBranch = {
      path
    };

    return parentContext.parentContext
      ? this.buildBranch(parentContext.parentContext, revisedBranch)
      : revisedBranch;
  }

  /**
   * Decide whether we need to load the sysId based on the branch it's in,
   * and what nesting level to use.
   */
  private determineDisposition(sysId: string, branch: IBranch): IDisposition {
    const isDup = this.links.has(sysId);
    if (!isDup) {
      this.links.set(sysId, { count: 0, sysId });
    }
    const link = this.links.get(sysId)!;
    link.count += 1;

    const nestingDepth = this.determineNestingDepth(branch, link);

    return {
      entry: link.entry,
      isDup,
      link,
      nestingDepth
    };
  }

  /**
   * Decide the nesting level with which to load. A level of 0 means "don't
   * load".
   * Right now its either 0 or "ChaseDepth" and we never load content within
   * a level.
   */
  private determineNestingDepth(branch: IBranch, link: ILink): number {
    if (link.count > 1) {
      return 0;
    }

    const { path } = branch;
    const isPlacementLevel = path.includes(
      'placementRule.levelPlacement/level.'
    );

    // No need to follow rounds as we already have the round IDs at this point
    const isUnitRounds = path.includes('unit.rounds');

    if (!isPlacementLevel && !isUnitRounds) {
      return PlacementContentLoader.ChaseDepth;
    }

    // Trying to follow links within the level structure is too expensive
    // so not supported for now

    return 0;
  }
}

interface IBranch {
  path: string;
}

interface IDisposition {
  entry?: ContentfulEntry<unknown>;
  isDup: boolean;
  link: ILink;
  nestingDepth: number;
}

interface ILink {
  count: number;
  entry?: ContentfulEntry<unknown>;
  sysId: string;
}

enum PlacementContentLoaderError {
  NotFound = 'NotFound'
}
