import { IScreenplay } from '@lexialearning/lobo-common';
import { Music } from 'audio/music';
import {
  PlacementSceneAnimationName,
  PlacementSceneElementName
} from 'feature-areas/placement/placement-scene/placement-scene.model';
import { RouteBuilder, RouterService } from 'router-service';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { PlacementScreenplayBuilderBase } from '../placement/PlacementScreenplayBuilderBase';
import { RoundIntroType } from '../rounds';
import { OnboardingScreenplayId } from './onboarding-screenplay-builders.model';

/**
 * Creates a screenplay for the animated intro to the onboarding page
 * - running storm and react animations, and playing voiceovers
 */
export class OnboardingIntroScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'OnboardingIntroScreenplayBuilder';

  public static create(): OnboardingIntroScreenplayBuilder {
    return new OnboardingIntroScreenplayBuilder();
  }

  private constructor() {
    super(OnboardingScreenplayId.Intro);
  }

  public playOnboardingIntro(
    introductionVo: IScreenplay
  ): OnboardingIntroScreenplayBuilder {
    this.builder
      .addMusic({ path: Music.PlacementIntro }, { concurrent: true })
      .addStormAnimation(
        {
          name: PlacementSceneAnimationName.Root.Intro,
          targetScene: SceneName.Placement
        },
        { concurrent: true }
      )
      .addStormAnimation(
        {
          blendTimeSeconds: 0.25,
          name: PlacementSceneAnimationName.Character.IntroWave,
          targetElement: PlacementSceneElementName.Character,
          targetScene: SceneName.Placement
        },
        { concurrent: true }
      )
      .addActionList(this.addSpeaker(introductionVo));

    return this;
  }

  public navigateToOnboardingUnit(
    targetUnitId: string,
    targetRoundId: string
  ): OnboardingIntroScreenplayBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(
        RouteBuilder.onboardingRounds(targetUnitId, targetRoundId)
      );
    });

    return this;
  }

  public dispatchOnboardingRoundIntro(): OnboardingIntroScreenplayBuilder {
    super.dispatchRoundIntro(RoundIntroType.OnboardingIntro);

    return this;
  }
}
