import * as React from 'react';
import { PressScaleButton } from '../../button';
import {
  ArrowButtonSvg,
  ArrowButtonType,
  IArrowButtonSvgStyleOverride
} from './ArrowButton.lx-svg';

export interface IArrowButtonProps {
  type: ArrowButtonType;
  onPress(type: ArrowButtonType): void;
  disabled?: boolean;
  styleOverride?: IArrowButtonSvgStyleOverride;
}

export interface IArrowButtonState {
  isKeyboardFocused: boolean;
}

export class ArrowButton extends React.PureComponent<
  IArrowButtonProps,
  IArrowButtonState
> {
  public static readonly displayName = 'ArrowButton';

  constructor(props: IArrowButtonProps) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.handleKeyboardFocus = this.handleKeyboardFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = {
      isKeyboardFocused: false
    };
  }

  private handlePress() {
    this.props.onPress(this.props.type);
  }

  private handleKeyboardFocus() {
    this.setState({ isKeyboardFocused: true });
  }

  private handleBlur() {
    this.setState({ isKeyboardFocused: false });
  }

  public render() {
    const { type, disabled, styleOverride } = this.props;
    const { isKeyboardFocused } = this.state;

    return (
      <PressScaleButton
        testId={`${type}ArrowButton`}
        disabled={disabled}
        disabledOpacity={0.4}
        onPress={this.handlePress}
        onKeyboardFocus={this.handleKeyboardFocus}
        onBlur={this.handleBlur}
      >
        <ArrowButtonSvg
          type={type}
          styleOverride={styleOverride || {}}
          withShadow={!disabled}
          isKeyboardFocused={isKeyboardFocused}
        />
      </PressScaleButton>
    );
  }
}
