import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import {
  IActionOptions,
  IScreenplayActionFactory,
  ScreenplayerType
} from '../screenplayer.model';
import { IReactAnimationRequest } from './react-animation-player.model';

export class ReactAnimationScreenplayActionFactory
  implements IScreenplayActionFactory<IReactAnimationRequest>
{
  public static create(
    name: string,
    options?: Partial<Exclude<IReactAnimationRequest, 'name'>>,
    actionOptions?: Partial<IActionOptions>
  ): IScreenplayAction<IReactAnimationRequest> {
    return {
      type: ScreenplayerType.ReactAnimation,
      ...actionOptions,
      data: { name, ...options }
    };
  }

  public readonly type = ScreenplayerType.ReactAnimation;

  public create(
    request: IReactAnimationRequest,
    actionOptions?: Partial<IActionOptions>
  ): IScreenplayAction<IReactAnimationRequest> {
    return ReactAnimationScreenplayActionFactory.create(
      request.name,
      request,
      actionOptions
    );
  }
}
