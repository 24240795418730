import { SceneName } from 'services/storm-lobo/StormAssets';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm';

export class IntroductionSceneDefinitionBuilder {
  public static readonly ScenePath = '/scenes/introduction/introduction.sg';

  public static create(): IntroductionSceneDefinitionBuilder {
    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Introduction,
      this.ScenePath
    );

    return new IntroductionSceneDefinitionBuilder(definition);
  }

  private constructor(public readonly definition: ISceneDefinition) {}
}
