import { AvatarType } from './avatar.model';
import { Color } from 'common-styles';
import { Position, Overflow, Border } from '@lexialearning/common-ui';
import { Types } from '@lexialearning/reactxp';
import { merge } from 'lodash';

export interface IAvatarStyles {
  container: Types.ViewStyle;
  image: Types.ImageStyle & { width: number; height: number };
  thumbnailBorder: Types.ViewStyle;
  topImage: Types.ImageStyle;
}

export class AvatarStyles {
  private static getStyles(avatarType) {
    switch (avatarType) {
      case AvatarType.Full:
        return {
          height: 480,
          width: 175
        };
      case AvatarType.Icon:
        return {
          height: 34,
          width: 34
        };
      case AvatarType.Thumbnail:
      default:
        const size = 105;

        return {
          backgroundColor: Color.Gold,
          borderRadius: size / 2,
          height: size,
          overflow: Overflow.Hidden,
          width: size
        };
    }
  }

  public static build(
    avatarType: AvatarType,
    styleOverride?: Types.ViewStyle
  ): IAvatarStyles {
    const typeStyles = merge(
      {},
      AvatarStyles.getStyles(avatarType),
      styleOverride
    );

    const styles = {
      container: {
        ...typeStyles
      },
      thumbnailBorder: {
        ...typeStyles,
        backgroundColor: Color.Transparent,
        borderColor: Color.Black,
        borderStyle: Border.Solid,
        borderWidth: 2,
        position: Position.Absolute,
        top: 0
      },
      image: {
        height: typeStyles.height,
        position: Position.Absolute,
        width: typeStyles.width
      },
      topImage: {
        height: typeStyles.height,
        position: Position.Relative,
        width: typeStyles.width
      }
    };

    return styles;
  }
}
