import { ChoicesLayout } from '@lexialearning/lobo-common/main-model';
import memoizeOne from 'memoize-one';
import { Color, Justify } from 'common-styles';
import { ThemeType } from 'theme';

export class SingleSelectStyles {
  public static readonly build = memoizeOne(
    (
      choicesLayout: ChoicesLayout,
      hasTextPrompt: boolean,
      themeType: ThemeType,
      hasSpotlightArea: boolean
    ) => {
      const isInstruction = themeType === ThemeType.Instruction;
      const isTextChoiceLayout = choicesLayout === ChoicesLayout.Text;

      return {
        container: {
          flex: 1,
          justifyContent:
            isInstruction || !hasSpotlightArea ? Justify.Center : Justify.Start,
          marginBottom: !hasSpotlightArea ? 50 : 0
        },
        innerContainer: {
          ...(isTextChoiceLayout
            ? {
                width: 800
              }
            : {
                backgroundColor: Color.NearWhiteTransparent40,
                borderRadius: 20,
                marginTop:
                  hasSpotlightArea && isInstruction
                    ? 25
                    : hasSpotlightArea && !hasTextPrompt
                    ? 40
                    : 0,
                maxWidth: 870,
                padding: 18,
                paddingBottom: hasTextPrompt ? 20 : 15,
                paddingTop: 15,
                width: hasTextPrompt ? (isInstruction ? 695 : 870) : undefined
              })
        },
        textPrompt: {
          text: {
            color:
              isInstruction && isTextChoiceLayout
                ? Color.TextOpposite
                : Color.Text
          }
        }
      };
    }
  );
}
