import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  ICustomer,
  ISettings
} from 'lexia-service/customer-api/customer-api.model';
import { CustomerActionType } from './CustomerActionType';

export const CustomerAction = {
  /**
   * dispatches action to setup device by either site id, local storage or mdm email
   */
  bootstrapSetup: createAction(
    CustomerActionType.BootstrapSetup
  )<ISetCustomerCodePayload>(),

  changeAccount: createAction(CustomerActionType.ChangeAccount)(),

  clearErrors: createAction(CustomerActionType.ClearErrors)(),

  settingsLoaded: createAction(CustomerActionType.SettingsLoaded)<ISettings>(),

  setupDeviceWithCustomerCode: createAsyncAction(
    CustomerActionType.SetupDeviceWithCustomerCode,
    CustomerActionType.SetupDeviceWithCustomerCodeSuccess,
    CustomerActionType.SetupDeviceWithCustomerCodeFailure
  )<undefined, ICustomerSetupPayload, Error>(),

  setupDeviceWithCustomerCodeAttempted: createAction(
    CustomerActionType.SetupDeviceWithCustomerCodeAttempted
  )<ISetupDeviceWithCustomerCodeActionPayload>(),

  setupDeviceWithEmail: createAsyncAction(
    CustomerActionType.SetupDeviceWithEmail,
    CustomerActionType.SetupDeviceWithEmailSuccess,
    CustomerActionType.SetupDeviceWithEmailFailure
  )<ISetupDeviceWithEmailActionPayload, ICustomerSetupPayload, Error>()
};

export type ICustomerSetupPayload = ICustomer;
export type ISetCustomerCodePayload = string;

export interface ISetupDeviceWithCustomerCodeActionPayload {
  completeDeviceSetupOptimistically: boolean;
}

export interface ISetupDeviceWithEmailActionPayload {
  email: string;
}

export type CustomerActionChangeAccount = ActionType<
  typeof CustomerAction.changeAccount
>;

export type CustomerActionSettingsLoaded = ActionType<
  typeof CustomerAction.settingsLoaded
>;

export type CustomerActionSetupDeviceWithCustomerCodeAttempted = ActionType<
  typeof CustomerAction.setupDeviceWithCustomerCodeAttempted
>;
export type CustomerActionSetupDeviceWithEmailRequest = ActionType<
  typeof CustomerAction.setupDeviceWithEmail.request
>;
export type CustomerActionSetupDeviceWithCustomerCode = ActionType<
  typeof CustomerAction.setupDeviceWithCustomerCode.request
>;
export type CustomerActionSetupDeviceWithCustomerCodeSuccess = ActionType<
  typeof CustomerAction.setupDeviceWithCustomerCode.success
>;
export type CustomerActionSetupDeviceWithCustomerCodeFailure = ActionType<
  typeof CustomerAction.setupDeviceWithCustomerCode.failure
>;
export type CustomerActionSetupDeviceWithEmailSuccess = ActionType<
  typeof CustomerAction.setupDeviceWithEmail.success
>;
export type CustomerActionSetupDeviceWithEmailFailure = ActionType<
  typeof CustomerAction.setupDeviceWithEmail.failure
>;

export type CustomerActionBootstrapSetup = ActionType<
  typeof CustomerAction.bootstrapSetup
>;

export type CustomerActions = ActionType<typeof CustomerAction>;
