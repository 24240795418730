import {
  IModeling,
  IScreenplay,
  TaskTypeName
} from '@lexialearning/lobo-common';
import { CommonUiSelector } from 'common-ui';
import {
  CurriculumDependencies,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { TaskDemoType } from 'services/storm-lobo/StormAssets';
import { TaskRegistry } from 'task-components';
import { TaskAction, TaskSelector } from 'task-components/core/redux';
import { RoundActionType } from '../redux';
import { RoundActionIntro, RoundIntroType } from '../redux/Round.action';
import { RoundIntroScreenplayBuilder } from '../RoundIntroScreenplayBuilder';

export interface IRoundIntroEpicDependencies {
  curriculumDependencies: CurriculumDependencies;
  preparedScenes: PreparedScenes;
  taskRegistry: TaskRegistry;
}

export function roundIntroEpic(
  action$: Observable<RoundActionIntro>,
  state$: StateObservable<unknown>,
  deps: IRoundIntroEpicDependencies
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(RoundActionType.Intro),
    mergeMap(async a => {
      const { curriculumDependencies, preparedScenes, taskRegistry } = deps;

      await curriculumDependencies.programContextService.contentLoaded(state$);

      const state = state$.value;
      const demosShown = CommonUiSelector.getTaskDemosShown(state);
      const phase = TaskSelector.getPhase(state);
      const modeling = TaskSelector.getModeling(state)!;
      const { taskType } = TaskSelector.getTaskContent(state);
      const roundContext = ProgramContextSelector.getRoundContext(state);

      const { type: roundIntroType = RoundIntroType.EncounterIntro } =
        a.payload || {};

      // The screenplay builder requires the encounterOrPlacement scene to be defined
      // so awaiting that before calling the builder
      await preparedScenes.encounterOrPlacementReady;

      const builder = RoundIntroScreenplayBuilder.createFor({ roundContext });

      addActionSet1(builder, preparedScenes, roundIntroType);

      // Only play task demo if entering a certain type of task for the first time
      const shouldPlayTaskDemo =
        !demosShown.includes(taskType) && TaskDemoType.includes(taskType);

      const screenplays = shouldPlayTaskDemo
        ? getScreenplaysWithTaskDemo(
            builder,
            roundContext,
            preparedScenes,
            taskType,
            modeling
          )
        : [getScreenplayWithoutTaskDemo(builder, modeling)];

      const editedScreenplays = screenplays.map(screenplay =>
        taskRegistry.screenplayEditor.edit(screenplay, roundContext, phase)
      );

      return editedScreenplays;
    }),
    map(screenplays => {
      const finalAction = ScreenplayAction.play({
        nextAction: TaskAction.interactive(),
        screenplay: screenplays.pop()!
      });

      // If screenplay had 2 parts (ie, for task demos), the first part should
      // play, then kick off the second part
      if (screenplays.length) {
        return ScreenplayAction.play({
          nextAction: finalAction,
          screenplay: screenplays.pop()!
        });
      }

      return finalAction;
    })
  );
}
roundIntroEpic.displayName = 'roundIntroEpic';

function getScreenplayWithoutTaskDemo(
  builder: RoundIntroScreenplayBuilder,
  modeling: IModeling
): IScreenplay {
  addActionSet2(builder, modeling);

  return builder.screenplay;
}

/**
 * Returns an array of 2 screenplays. This is because, to allow the task demo
 * to be skipped, the rest of the screenplay must be played separately in
 * order to allow it to not be skipped with the first screenplay
 * @param builder
 * @param roundContext
 * @param preparedScenes
 * @param taskType
 * @param modeling
 * @returns IScreenplay[]
 */
function getScreenplaysWithTaskDemo(
  builder: RoundIntroScreenplayBuilder,
  roundContext: RoundContext,
  preparedScenes: PreparedScenes,
  taskType: TaskTypeName,
  modeling: IModeling
): IScreenplay[] {
  const builder2 = RoundIntroScreenplayBuilder.createFor({ roundContext });
  addTaskDemoActions(builder, builder2, preparedScenes, taskType);
  addActionSet2(builder2, modeling);

  return [builder.screenplay, builder2.screenplay];
}

function addActionSet1(
  builder: RoundIntroScreenplayBuilder,
  preparedScenes: PreparedScenes,
  roundIntroType: RoundIntroType
): void {
  builder
    .dispatchTaskEntry()
    .attachCharacterMaybe(preparedScenes)
    .playUnitIntroMaybe(preparedScenes, roundIntroType);
}

function addTaskDemoActions(
  builder1: RoundIntroScreenplayBuilder,
  builder2: RoundIntroScreenplayBuilder,
  preparedScenes: PreparedScenes,
  taskType: TaskTypeName
): void {
  builder1.playTaskDemo(preparedScenes, taskType);
  builder2.setTaskDemoShown(taskType).hideSkipButton();
}

function addActionSet2(
  builder: RoundIntroScreenplayBuilder,
  modeling: IModeling
): void {
  builder
    .fadeInTaskElements()
    .playPostEntryScreenplayMaybe()
    .dispatchTaskEntryComplete()
    .dispatchTaskPreamble()
    .addPreambleScreenplay(modeling)
    .playPreambleCompleteScreenplayMaybe()
    .dispatchTaskPreambleComplete();
}
