export enum CustomerActionType {
  ChangeAccount = 'CUSTOMER.CHANGE_ACCOUNT',
  ClearErrors = 'CUSTOMER.CLEAR_ERRORS',
  SettingsLoaded = 'CUSTOMER.SETTINGS_LOADED',
  BootstrapSetup = 'CUSTOMER.BOOTSTRAP_SETUP',
  SetupDeviceWithCustomerCode = 'CUSTOMER.SETUP_DEVICE_WITH_CUSTOMER_CODE',
  SetupDeviceWithCustomerCodeAttempted = 'CUSTOMER.SETUP_DEVICE_WITH_CUSTOMER_CODE_ATTEMPTED',
  SetupDeviceWithCustomerCodeFailure = 'CUSTOMER.SETUP_DEVICE_WITH_CUSTOMER_CODE_FAILURE',
  SetupDeviceWithCustomerCodeSuccess = 'CUSTOMER.SETUP_DEVICE_WITH_CUSTOMER_CODE_SUCCESS',
  SetupDeviceWithEmail = 'CUSTOMER.SETUP_DEVICE_WITH_EMAIL',
  SetupDeviceWithEmailFailure = 'CUSTOMER.SETUP_DEVICE_WITH_EMAIL_FAILURE',
  SetupDeviceWithEmailSuccess = 'CUSTOMER.SETUP_DEVICE_WITH_EMAIL_SUCCESS'
}
