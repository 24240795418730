import { LexiaProduct } from '../lexia-service-private.model';

export enum CustomerApiEndpoint {
  GetCustomer = '/getCustomer'
}

/**
 * Request info for the getCustomer lexia api call
 */
export interface IRegisterRequestWithEmail extends IRegisterRequestBase {
  email: string;
}

export interface IRegisterRequestWithCustomerCode extends IRegisterRequestBase {
  customerCode: string;
}

/**
 * ssoLink object returned in getCustomer response
 * TODO this interface may belong in customer-api.model
 */
export interface ISsoLink {
  style: string;
  href?: string;
  provider?: SsoProvider;
}

export enum SsoProvider {
  Google = 'Google',
  Clever = 'clever',
  Saml = 'saml'
}

/**
 * Response from the myLexia getCustomer api call. Information on the school district or equivalent
 */
export interface IGetCustomerResponse {
  __type__: CustomerApiPayloadType.GetCustomerResponse;
  allowShowProperty: boolean;
  activeCustomer: string;
  customerName: string;
  /**
   * used to determine if customer uses an SSO system like SAML (Clever)
   */
  hasSSO: boolean;
  /**
   * object containg customer's sso link information
   */
  ssoLink?: ISsoLink;
  /**
   * indicates a redirect url is included to send user to authenticate
   */
  hasRedirect: boolean;
  /**
   * boolean to determine if we connect to LDAP server for authentication
   */
  isCsaLdap: boolean;
  customerCode: string;
  /**
   * indicates whether to show password or not
   */
  locale: string;
  settings: { [key: string]: number };
  valid: boolean;
}

export interface IRegisterRequestBase {
  platform: string;
  product: LexiaProduct;
}

export enum CustomerApiPayloadType {
  GetCustomerResponse = 'getCustomerResponse'
}
