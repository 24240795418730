import { ActionType, createAction } from 'typesafe-actions';
import { SpinnerActionType } from './SpinnerActionType';

export const SpinnerAction = {
  hide: createAction(SpinnerActionType.Hide)(),
  show: createAction(SpinnerActionType.Show)()
};

export type SpinnerActions = ActionType<typeof SpinnerAction>;

export type SpinnerActionHide = ActionType<typeof SpinnerAction.hide>;
export type SpinnerActionShow = ActionType<typeof SpinnerAction.show>;
