import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';
import { DevToolsActionLogSreSessionInfo } from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';
import { ISreDependencies } from 'sre/epics/sre-epics.model';

export function logSreSessionInfoEpic(
  action$: Observable<DevToolsActionLogSreSessionInfo>,
  _state$: StateObservable<unknown>,
  deps: ISreDependencies
): Observable<void> {
  return action$.pipe(
    ofType(DevToolsActionType.LogSreSessionInfo),
    map(() => {
      const { sre } = deps;
      // eslint-disable-next-line deprecation/deprecation
      sre.logSreSessionInfo();
    }),
    ignoreElements()
  );
}
logSreSessionInfoEpic.displayName = 'logSreSessionInfoEpic';
