import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TransitionActionType } from 'feature-areas';
import {
  CurriculumDependencies,
  LevelSelector,
  ProgramContextSelector
} from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { PreparedScenes } from 'services/storm-lobo';
import { TransitionActionPlacementCompleteToLevel } from '../Transition.action';
import { PlacementCompleteToLevelTransitionFactory } from './PlacementCompleteToLevelTransitionFactory';

export interface IPlacementCompleteToLevelTransitionDeps {
  curriculumDependencies: CurriculumDependencies;
  preparedScenes: PreparedScenes;
}

export function placementCompleteToLevelTransitionEpic(
  action$: Observable<TransitionActionPlacementCompleteToLevel>,
  state$: StateObservable<unknown>,
  deps: IPlacementCompleteToLevelTransitionDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.PlacementCompleteToLevel),
    switchMap(async () => {
      const { curriculumDependencies, preparedScenes } = deps;
      await curriculumDependencies.programContextService.contentLoaded(state$, {
        desiredReadiness: { level: true }
      });

      const state = state$.value;
      const welcomeMessageVo =
        LevelSelector.getWelcomeMessage(state)!.voiceover;
      const { levelId } = ProgramContextSelector.getPosition(state$.value);
      const entryPromptVo =
        BootstrapContentSelector.getLevelScreenContent(state)!.entryPrompt;

      return PlacementCompleteToLevelTransitionFactory.createFor({
        entryPromptVo,
        levelId,
        preparedScenes,
        welcomeMessageVo
      });
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
placementCompleteToLevelTransitionEpic.displayName =
  'placementCompleteToLevelTransitionEpic';
