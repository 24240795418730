import { screenplayEpics } from '../epics/screenplay.epic';
import { screenplayReducer } from './screenplay.reducer';
import { ScreenplaySelector } from './Screenplay.selector';

export class ScreenplayReduxProvider {
  public static readonly reducer = screenplayReducer;

  public static readonly selector = ScreenplaySelector;

  public static readonly epics = screenplayEpics;
}
