import { ActionType, createAction } from 'typesafe-actions';
import { IActRouteParams, ILevelRouteParams, RoutePath } from 'router-service';
import { DeepLinkActionType } from './deep-link-redux.model';

export const DeepLinkAction = {
  buildPosition: createAction(
    DeepLinkActionType.BuildPosition
  )<IDeepLinkBuildPositionActionPayload>(),
  positionSetupComplete: createAction(
    DeepLinkActionType.PositionSetupComplete
  )()
};

export interface IDeepLinkBuildPositionActionPayload {
  sysId: string;
}

export interface IDeepLinkRouteActionPayload {
  route: RoutePath;
  params?: ILevelRouteParams | IActRouteParams;
}

export type DeepLinkActions = ActionType<typeof DeepLinkAction>;
export type DeepLinkActionBuildPosition = ActionType<
  typeof DeepLinkAction.buildPosition
>;
export type DeepLinkActionPositionSetupComplete = ActionType<
  typeof DeepLinkAction.positionSetupComplete
>;
