import { StateObservable } from 'redux-observable';
import { first, map, switchMap } from 'rxjs/operators';
import {
  CurriculumDependencies,
  ProgramContextSelector,
  UnitSelector
} from 'curriculum-services';
import { RoundAction } from 'feature-areas/transitions/builders/rounds';
import { StormSelector } from 'storm';
import { TaskSelector } from 'task-components';
import {
  DeepLinkObservableFactoryBase,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';
import { DeepLinkAction } from './redux/DeepLink.action';
import { AppState } from 'services';

export interface IUnitDeepLinkDeps extends IDeepLinkDeps {
  curriculumDependencies: CurriculumDependencies;
  unitId: string;
  roundId: string;
  state$: StateObservable<AppState>;
}

export class UnitDeepLinkObservableFactory extends DeepLinkObservableFactoryBase<IUnitDeepLinkDeps> {
  public static readonly displayName: string = 'UnitDeepLinkObservableFactory';

  // TODO: What can/should be reused from educator to encounter builder?
  // Can this use programContextService.contentLoaded instead of its current isReady checks?
  public static createFor(
    deps: IUnitDeepLinkDeps
  ): UnitDeepLinkObservableFactory {
    return new UnitDeepLinkObservableFactory(deps)
      .loadProgramPosition()
      .thenDispatchRoundIntro();
  }

  constructor(protected readonly deps: IUnitDeepLinkDeps) {
    super(deps);
  }

  protected isDone(): boolean {
    return !!TaskSelector.getTaskContentMaybe(this.deps.state$.value);
  }

  protected loadProgramPosition() {
    const { roundId, unitId } = this.deps;
    this.dispatches.push(
      DeepLinkAction.buildPosition({ sysId: roundId || unitId })
    );

    return this;
  }

  /**
   * Monitor state changes until proper unit and roundContext are loaded
   * Then dispatch RoundAction.intro
   */
  private thenDispatchRoundIntro(): UnitDeepLinkObservableFactory {
    const { preparedScenes, state$ } = this.deps;
    const roundActionIntro$ = state$.pipe(
      first(() => this.isReady()),
      switchMap(async () => {
        await preparedScenes.encounterOrPlacementReady;
      }),
      map(() => RoundAction.intro())
    );
    this.deferredDispatches$.push(roundActionIntro$);

    return this;
  }

  protected isReady(): boolean {
    const { state$, unitId } = this.deps;
    const loadedUnit = UnitSelector.getUnitMaybe(state$.value);
    const roundContextMaybe = ProgramContextSelector.getRoundContextMaybe(
      state$.value
    );

    if (loadedUnit && loadedUnit.sysId !== unitId) {
      // eslint-disable-next-line no-console
      console.warn(
        'Unit ID in content',
        loadedUnit.sysId,
        'does not match target unit ID',
        unitId
      );
    }

    return (
      loadedUnit?.sysId === unitId &&
      !!roundContextMaybe &&
      StormSelector.isInitialized(state$.value)
    );
  }
}
