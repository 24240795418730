import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramContextSelector } from 'curriculum-services';
import { EducatorSelector } from 'feature-areas/educator';
import {
  AppShellActionNavigateBackToEducator,
  AppShellActionType
} from 'feature-areas/shell';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { EncounterToEducatorTransitionBuilder } from './EncounterToEducatorTransitionBuilder';

export interface IEncounterToEducatorTransitionDeps {
  preparedScenes: PreparedScenes;
}

export function encounterToEducatorTransitionEpic(
  action$: Observable<AppShellActionNavigateBackToEducator>,
  state$: StateObservable<unknown>
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(AppShellActionType.NavigateBackToEducator),
    map(() => {
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const builder = EncounterToEducatorTransitionBuilder.create()
        .navToEducator()
        .resetImminentPositionMaybe(context);

      const educatorContext = EducatorSelector.getContext(state$.value);
      if (educatorContext.actIndexMaybe !== undefined) {
        builder.showActivityCarouselModal();
      }

      return ScreenplayAction.play({ screenplay: builder.screenplay });
    })
  );
}
encounterToEducatorTransitionEpic.displayName =
  'encounterToEducatorTransitionEpic';
