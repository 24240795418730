import { IScreenplay, TaskPhase } from '@lexialearning/lobo-common/main-model';
import { RoundContext } from 'curriculum-services';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { ITaskScreenplayEditor } from 'task-components';
import { CharacterSceneBase } from './CharacterSceneBase';
import { CharacterSceneScreenplayEditorBuilder } from './CharacterSceneScreenplayEditorBuilder';

export class CharacterSceneScreenplayEditor implements ITaskScreenplayEditor {
  public static readonly displayName = 'CharacterSceneScreenplayEditor';

  constructor(
    private readonly scene: CharacterSceneBase,
    private readonly sceneName: SceneName
  ) {}

  public edit(
    screenplay: IScreenplay,
    context: RoundContext,
    phase: TaskPhase
  ): IScreenplay {
    return new CharacterSceneScreenplayEditorBuilder(
      this.scene,
      this.sceneName,
      screenplay,
      context
    )
      .editBasedOnPhase(phase)
      .addSpeakerMaybe().screenplay;
  }
}
