import {
  FeedbackStrategy,
  ICharacterFeedback,
  IScreenplay,
  ITaskEvaluation,
  TaskEvaluationResult,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import {
  ISeeSpeak,
  LanguageFrameTokenDecoration,
  SeeSpeakMode
} from '@lexialearning/lobo-common/tasks/see-speak';
import { cloneDeep, last, sample } from 'lodash';
import { Sfx } from 'audio';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder, ScreenplayerType } from 'screenplay';
import {
  FeedbackScreenplayBuilder,
  ITaskRegistration,
  ScreenplayType,
  TaskAction,
  TaskFeedbackDelay,
  TaskRegistrationBuilder
} from '../core';
import { LanguageFrameManager } from './language-frame/LanguageFrameManager';
import { SeeSpeakAction } from './redux/SeeSpeak.action';
import { ISreSessionAnswer } from './see-speak.model';
import { SeeSpeakHelper } from './SeeSpeak.helper';
import { CalibrationResult } from '@lexialearning/sre';
import {
  SeeSpeakType,
  SpeechBubbleReactAnimationName
} from './speech-bubbles/speech-bubbles.model';
import { SeeSpeak } from './SeeSpeak';

export const SCAFFOLD_TEARDOWN_DELAY_MS = 500;

export class SeeSpeakTaskRegistrationFactory {
  public static readonly displayName = 'SeeSpeakTaskRegistrationFactory';

  public static create(): ITaskRegistration<
    TaskTypeName.SeeSpeak,
    ISeeSpeak,
    ISreSessionAnswer
  > {
    const base = TaskRegistrationBuilder.create<
      TaskTypeName.SeeSpeak,
      ISeeSpeak
    >(TaskTypeName.SeeSpeak, SeeSpeak)
      .withPrepareContent(SeeSpeakTaskRegistrationFactory.prepareContent)
      .withScreenplayBuilder(
        ScreenplayType.PostEntry,
        SeeSpeakTaskRegistrationFactory.buildPostEntryScreenplay
      )
      .withScreenplayBuilder(
        ScreenplayType.PreambleComplete,
        SeeSpeakTaskRegistrationFactory.buildPreambleCompleteScreenplay
      )
      .withScreenplayBuilder(
        ScreenplayType.Feedback,
        SeeSpeakTaskRegistrationFactory.createFeedbackScreenplay
      )
      .withScreenplayBuilder(
        ScreenplayType.Solution,
        SeeSpeakTaskRegistrationFactory.createSolutionScreenplay
      ).registration;

    return {
      ...base,
      serializeAnswer: SeeSpeakTaskRegistrationFactory.serializeAnswer
    };
  }

  private static serializeAnswer(answer: ISreSessionAnswer) {
    if (!answer.result) {
      return 'no answer provided';
    }
    const { rubricScore, sreRawScore, wordFinalResult = [] } = answer.result;
    const wordScores = wordFinalResult
      .map((w: any) => `${w.text}^${w.pronunciationScore}`)
      .join(' ');

    return `${rubricScore}|${sreRawScore}|${wordScores}`;
  }

  private static prepareContent(content: ISeeSpeak): ISeeSpeak {
    const contentCopy = cloneDeep(content);

    return contentCopy.mode === SeeSpeakMode.OpenScaffold
      ? SeeSpeakTaskRegistrationFactory.decorateScaffoldedTokens(contentCopy)
      : contentCopy;
  }

  /**
   * Adds the Scaffolded decoration to all ChallengeWord tokens so that they
   * are removed from the "hint" area of SeeSpeak (OpenScaffold mode)
   */
  private static decorateScaffoldedTokens(content: ISeeSpeak) {
    const languageFrame = LanguageFrameManager.manage(content.languageFrame)
      .setScaffolded()
      .toUnManaged();

    return {
      ...content,
      languageFrame
    };
  }

  private static buildPostEntryScreenplay(
    roundContext: RoundContext
  ): IScreenplay {
    const builder = ScreenplayBuilder.create(SeeSpeakScreenplayId.PostEntry);
    if (roundContext.isInstructionRound) {
      const seeSpeak = roundContext.getTask<ISeeSpeak>();
      if (seeSpeak.mode === SeeSpeakMode.OpenScaffold) {
        // handle scaffolding in grammar instruction rounds.
        builder.addScreenplay(
          SeeSpeakTaskRegistrationFactory.createUnScaffoldingScreenplay(
            seeSpeak
          )
        );
      }
    } else {
      builder
        .addDelay(200)
        .addReactAnimation(SpeechBubbleReactAnimationName.BubblesFadeIn);
    }

    return builder.screenplay;
  }
  private static buildPreambleCompleteScreenplay(
    roundContext: RoundContext
  ): IScreenplay {
    const { isInstructionRound } = roundContext;
    const builder = ScreenplayBuilder.create(
      SeeSpeakScreenplayId.PreambleComplete
    );

    if (!isInstructionRound) {
      const seeSpeak = roundContext.getTask<ISeeSpeak>();
      const type = SeeSpeakHelper.getSeeSpeakType(
        seeSpeak.mode,
        seeSpeak.textPromptArray,
        !!roundContext.round.introduction
      );

      if (type !== SeeSpeakType.SayItAgain) {
        // ⬆ throwing console error without this
        builder.addReactAnimation(SpeechBubbleReactAnimationName.ResponseEntry);
      }

      if (seeSpeak.mode === SeeSpeakMode.OpenScaffold) {
        builder.addScreenplay(
          SeeSpeakTaskRegistrationFactory.createUnScaffoldingScreenplay(
            seeSpeak
          )
        );
      }

      builder.addReactAnimation(SpeechBubbleReactAnimationName.MicFadeIn);
    }

    return builder.screenplay;
  }

  private static createUnScaffoldingScreenplay(
    seeSpeak: ISeeSpeak
  ): IScreenplay | undefined {
    const manager = LanguageFrameManager.manage(seeSpeak.languageFrame);

    return ScreenplayBuilder.create(
      SeeSpeakScreenplayId.PostEntry
    ).addActionList(
      manager.tokens
        .map((t, tokenIdx) => ({
          isChallengeWord: t.decorations.has(
            LanguageFrameTokenDecoration.ChallengeWord
          ),
          tokenIdx
        }))
        .filter(x => x.isChallengeWord)
        .map(x => ({
          data: { delayMs: SCAFFOLD_TEARDOWN_DELAY_MS },
          reduxActions: {
            after: SeeSpeakAction.decoratedTokens({
              languageFrameTokens: manager
                .unsetScaffolded(x.tokenIdx)
                .toUnManaged()
            })
          },
          type: ScreenplayerType.Delay
        }))
    ).screenplay;
  }

  private static createSolutionScreenplay(context: RoundContext): IScreenplay {
    const manager = LanguageFrameManager.manage(
      context.getTask<ISeeSpeak>().languageFrame
    );

    return ScreenplayBuilder.create(SeeSpeakScreenplayId.Solution)
      .addReduxAction(TaskAction.showSolution())
      .addReduxAction(
        SeeSpeakAction.decoratedTokens({
          languageFrameTokens: manager.setScaffolded().toUnManaged()
        })
      )
      .addScreenplay(context.round.solution)
      .addDelay(1000).screenplay;
  }

  private static createFeedbackScreenplay(context: RoundContext): IScreenplay {
    const {
      attempts,
      hasOnscreenCharacter,
      lastAttemptMaybe,
      parentUnit,
      mainUnit: unit,
      act
    } = context;
    const evaluationResult = lastAttemptMaybe?.result;
    const incorrectFirstAttempt =
      attempts.length === 1 &&
      evaluationResult === TaskEvaluationResult.Incorrect;
    const { feedbackStrategy } = parentUnit;
    const character =
      (!hasOnscreenCharacter && unit.character) || act.character;
    const calibrationResult = (
      last(attempts) as ITaskEvaluation<ISreSessionAnswer>
    )?.answer.calibrationResult;

    if (
      feedbackStrategy === FeedbackStrategy.Neutral &&
      incorrectFirstAttempt
    ) {
      return ScreenplayBuilder.create(
        SeeSpeakScreenplayId.FeedbackIncorrectNeutral
      ).addSfx(Sfx.Unheard).screenplay;
    }

    if (SeeSpeakHelper.needsRecalibration(context)) {
      return ScreenplayBuilder.create(
        SeeSpeakScreenplayId.FeedbackRecalibrationNeeded
      ).addSfx(Sfx.Unheard).screenplay;
    }

    if (evaluationResult === TaskEvaluationResult.Inconclusive) {
      return ScreenplayBuilder.create(SeeSpeakScreenplayId.FeedbackInconclusive)
        .addSfx(Sfx.Unheard)
        .addScreenplay(
          SeeSpeakTaskRegistrationFactory.getMicErrorScreenplay(
            calibrationResult!,
            character.feedback
          )
        )
        .addDelay(TaskFeedbackDelay.Normal).screenplay;
    }

    return FeedbackScreenplayBuilder.createFor(context).screenplay;
  }

  private static getMicErrorScreenplay(
    calibrationResult: CalibrationResult,
    feedback: ICharacterFeedback
  ) {
    const pool =
      calibrationResult === CalibrationResult.TooLoud
        ? feedback.tooLoud
        : calibrationResult === CalibrationResult.TooSoft
        ? feedback.tooSoft
        : feedback.noMicSignal;

    return sample(pool)?.voiceover;
  }
}

export enum SeeSpeakScreenplayId {
  FeedbackInconclusive = 'Feedback.SeeSpeak.Inconclusive',
  FeedbackIncorrectNeutral = 'Feedback.SeeSpeak.Incorrect.Neutral',
  FeedbackRecalibrationNeeded = 'Feedback.SeeSpeak.RecalibrationNeeded',
  PostEntry = 'PostEntry.SeeSpeak',
  PreambleComplete = 'PreambleComplete.SeeSpeak',
  Solution = 'Solution.SeeSpeak'
}
