import { UAParser } from 'ua-parser-js';
import chromePng from './logos/chrome.png';
import edgePng from './logos/edge.png';
import firefoxPng from './logos/firefox.png';
import safariPng from './logos/safari.png';
import settingsPng from './logos/settings.png';
import { SystemInfo } from './SystemInfo';

export interface ISupportedBrowser {
  name: string;
  minVersion?: string;
}

export interface IUserAgentInfo {
  img: string;
  name: string;
  downloadUrl?: string;
  tipsUrl: string;
}

export enum Browser {
  Chrome = 'Chrome',
  Edge = 'Edge',
  Firefox = 'Firefox',
  Safari = 'Safari'
}

export const IOS_INFO: IUserAgentInfo = {
  img: settingsPng,
  name: 'Settings',
  tipsUrl: 'https://support.apple.com/en-us/HT210896'
};

export const BROWSER_INFOS: {
  [k in Browser]: IUserAgentInfo;
} = {
  [Browser.Chrome]: {
    downloadUrl: 'https://www.google.com/chrome',
    img: chromePng,
    name: Browser.Chrome,
    tipsUrl: 'https://support.google.com/chrome/answer/2693767'
  },
  [Browser.Firefox]: {
    downloadUrl: 'https://www.mozilla.org/en-US/firefox/new',
    img: firefoxPng,
    name: Browser.Firefox,
    tipsUrl:
      'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
  },
  [Browser.Safari]: {
    downloadUrl: 'https://support.apple.com/downloads/safari',
    img: safariPng,
    name: Browser.Safari,
    tipsUrl: 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac'
  },
  [Browser.Edge]: {
    downloadUrl: 'https://www.microsoft.com/en-us/edge',
    img: edgePng,
    name: Browser.Edge,
    tipsUrl: 'https://www.microsoft.com/en-us/itpro/microsoft-edge/support'
  }
};

export class UserAgentUtils {
  public static readonly displayName = 'UserAgentUtils';

  public static supportedBrowsers: ISupportedBrowser[] = [];

  private static readonly uaParser = new UAParser();

  public static getParsedData() {
    return this.uaParser.getResult();
  }

  public static getBrowser() {
    return this.uaParser.getBrowser();
  }

  public static getInfo(): IUserAgentInfo | undefined {
    if (SystemInfo.isNative) {
      return IOS_INFO;
    }

    const browserName = this.uaParser.getBrowser().name;

    return browserName && BROWSER_INFOS[browserName];
  }

  /**
   * Should be used only on web, for native app always returns false
   */
  public static isFirefox(): boolean {
    if (SystemInfo.isNative) {
      return false;
    }

    const browserName = this.uaParser.getBrowser().name;

    return browserName === Browser.Firefox;
  }

  /**
   * Should be used only on web, for native app always returns false
   */
  public static isSafari(): boolean {
    if (SystemInfo.isNative) {
      return false;
    }

    const browserName = this.uaParser.getBrowser().name;

    return browserName === Browser.Safari;
  }

  /**
   * Should be used only on web, for native app always returns false
   */
  public static isEdge(): boolean {
    if (SystemInfo.isNative) {
      return false;
    }

    const browserName = this.uaParser.getBrowser().name;

    return browserName === Browser.Edge;
  }

  /**
   * Should be used only on web, for native app always returns false
   */
  public static isChrome(): boolean {
    if (SystemInfo.isNative) {
      return false;
    }

    const browserName = this.uaParser.getBrowser().name;

    return browserName === Browser.Chrome;
  }

  /**
   * Should be used only on web, for native app always returns true
   */
  public static isIpad(): boolean {
    if (SystemInfo.isNative) {
      return true;
    }

    const { os, device } = this.getParsedData();

    /**
     * On some versions, Safari has "request desktop site" enabled by default
     * which masks UA string to look like Mac OS
     *
     * @see https://developer.apple.com/forums/thread/119186
     */
    const isMobileMacOS =
      os.name === 'Mac OS' && UserAgentUtils.isTouchDevice();

    return (device.type === 'tablet' && os.name === 'iOS') || isMobileMacOS;
  }

  /**
   * For native app always returns true
   */
  public static isTouchDevice(): boolean {
    if (SystemInfo.isNative) {
      return true;
    }

    // ref: https://patrickhlauke.github.io/touch/touchscreen-detection/
    return (
      !!(window.PointerEvent && navigator.maxTouchPoints) ||
      !!window.matchMedia?.('(any-pointer:coarse)').matches ||
      (window.TouchEvent && 'ontouchstart' in window)
    );
  }

  /**
   * Always true for Native, or if device.type is undefined
   */
  public static isSupportedPlatform(): boolean {
    if (SystemInfo.isNative) {
      return true;
    }

    const deviceType = this.getParsedData().device.type;

    // no device type means it's a basic desktop/laptop
    return !deviceType;
  }
}
