import {
  IScreenplay,
  ITask,
  ModelSection,
  TaskComponentType,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep } from 'lodash';
import {
  EncounterContext,
  PositionDeterminerFactory,
  RoundContext
} from 'curriculum-services';
import { SkipPositionDeterminer } from 'curriculum-services/program-context/epics/progress/position-determiners';
import { ModelingHelper, TaskStringKeys } from '../modeling/Modeling.helper';
import { FeedbackScreenplayBuilder } from './FeedbackScreenplayBuilder';
import { SolutionScreenplayBuilder } from './SolutionScreenplayBuilder';
import {
  IPositionDeterminerFactoryOptions,
  ITaskRegistration
} from './task-registry.model';
import { TaskExitScreenplayBuilder } from './TaskExitScreenplayBuilder';

export enum ScreenplayType {
  Entry,
  PostEntry,
  PreambleComplete,
  Exit,
  Feedback,
  Solution
}

export class TaskRegistrationBuilder<
  N extends TaskTypeName,
  T extends ITask,
  TAnswer extends object = object
> {
  public static readonly displayName = 'TaskRegistrationBuilder';

  public static create<N extends TaskTypeName, T extends ITask>(
    taskType: N,
    Component: TaskComponentType
  ): TaskRegistrationBuilder<N, T> {
    return new TaskRegistrationBuilder(taskType, Component);
  }

  private readonly modeledFields = ModelingHelper.createModelSectionFields<T>();

  private readonly _registration: ITaskRegistration<N, T, TAnswer>;

  public get registration(): ITaskRegistration<N, T, TAnswer> {
    this._registration.buildModeling =
      ModelingHelper.createBuildModelingFunction(this.modeledFields);

    return this._registration;
  }

  constructor(taskType: N, Component: TaskComponentType) {
    this.setModeledFields(
      ModelSection.Direction,
      [] as unknown as TaskStringKeys<T>
    );
    this.setModeledFields(ModelSection.Presentation, [
      'textPrompt',
      'choices'
    ] as unknown as TaskStringKeys<T>);

    this._registration = {
      Component,
      buildExitScreenplay: (context: RoundContext) =>
        TaskExitScreenplayBuilder.createFor(context).screenplay,
      buildFeedbackScreenplay: (context: RoundContext) =>
        FeedbackScreenplayBuilder.createFor(context).screenplay,
      buildModeling: ModelingHelper.createBuildModelingFunction(
        this.modeledFields
      ),
      buildSolutionScreenplay: (context: RoundContext) =>
        SolutionScreenplayBuilder.createFor(context).screenplay,
      createPositionDeterminer: (
        context: RoundContext,
        options?: IPositionDeterminerFactoryOptions
      ) =>
        options?.forSkipping
          ? new SkipPositionDeterminer(context)
          : PositionDeterminerFactory.create(context),
      getForkSubunits: (_task: T) => [],
      prepareContent: cloneDeep,
      serializeAnswer: (_answer: TAnswer): string => '',
      taskType
    };
  }

  public withScreenplayBuilder(
    screenplayType: ScreenplayType,
    builder: (deps: RoundContext) => IScreenplay
  ): TaskRegistrationBuilder<N, T> {
    switch (screenplayType) {
      case ScreenplayType.PostEntry:
        this._registration.buildPostEntryScreenplay = builder;
        break;

      case ScreenplayType.PreambleComplete:
        this._registration.buildPreambleCompleteScreenplay = builder;
        break;

      case ScreenplayType.Feedback:
        this._registration.buildFeedbackScreenplay = builder;
        break;

      case ScreenplayType.Solution:
        this._registration.buildSolutionScreenplay = builder;
        break;

      case ScreenplayType.Exit:
        this._registration.buildExitScreenplay = builder;
        break;

      default:
        throw new LexiaError(
          'Invalid ScreenplayType',
          'TaskRegistrationBuilder.withScreenplayBuilder',
          '404'
        );
    }

    return this;
  }

  public withPrepareContent(
    prepareContent: (content: T, partialContext: EncounterContext) => T
  ): TaskRegistrationBuilder<N, T> {
    this._registration.prepareContent = prepareContent;

    return this;
  }

  public setModeledFields(
    modelSection: ModelSection,
    fieldNames: TaskStringKeys<T>
  ): TaskRegistrationBuilder<N, T> {
    this.modeledFields.set(modelSection, fieldNames);

    return this;
  }
}
