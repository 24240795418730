import { ActiveComponentState } from '@lexialearning/common-ui';
import { Font, Color } from 'common-styles';
import { KeyNavHelper } from 'common-ui/helpers';
import { merge } from 'lodash';

export class PlainTextButtonStyles {
  public static readonly styles = {
    text: {
      fontSize: Font.Size.xSmall
    }
  };

  public static readonly keyNavStyles = {
    button: {
      backgroundColor: Color.Black,
      marginHorizontal: -5,
      paddingHorizontal: 5
    },
    text: {
      color: Color.NearWhite
    }
  };

  public static build(
    activeComponentState: ActiveComponentState,
    styleOverride
  ) {
    return merge(
      {},
      this.styles,
      styleOverride,
      KeyNavHelper.isKeyNav(activeComponentState) && this.keyNavStyles
    );
  }
}
