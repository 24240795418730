import { createSelector } from '@reduxjs/toolkit';
import { TaskDemoType } from 'services/storm-lobo/StormAssets';
import { ICommonUiState } from './common-ui-redux.model';

export class CommonUiSelector {
  public static readonly displayName = 'CommonUiSelector';

  public static getState: (state: unknown) => ICommonUiState;

  public static isKeydownHandlingDisabled: (state: unknown) => boolean;

  public static isGlobalDisabled: (state: unknown) => boolean;

  public static hasViewedOnboardingVideo: (state: unknown) => boolean;

  public static getTaskDemosShown: (state: unknown) => TaskDemoType[];

  /** Returns array of indices of all currently available unit rewards */
  public static getAvailableUnitRewards: (state: unknown) => number[];

  public static createSelectors(
    selector: (state: any) => ICommonUiState
  ): void {
    CommonUiSelector.getState = createSelector(
      selector,
      (state: ICommonUiState) => state
    );

    CommonUiSelector.isKeydownHandlingDisabled = createSelector(
      CommonUiSelector.getState,
      (state: ICommonUiState) => state.isKeydownHandlingDisabled
    );

    CommonUiSelector.isGlobalDisabled = createSelector(
      CommonUiSelector.getState,
      (state: ICommonUiState) => state.isGlobalDisabled
    );

    CommonUiSelector.hasViewedOnboardingVideo = createSelector(
      CommonUiSelector.getState,
      (state: ICommonUiState) => state.hasViewedOnboardingVideo
    );

    CommonUiSelector.getTaskDemosShown = createSelector(
      selector,
      (state: ICommonUiState) => state.taskDemosShown
    );

    CommonUiSelector.getAvailableUnitRewards = createSelector(
      selector,
      (state: ICommonUiState) => state.unitRewardsAvailable
    );
  }
}
