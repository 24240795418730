import {
  AvatarEditorAction,
  AvatarEditorActionSaveAndExit,
  AvatarEditorActionReset
} from '../AvatarEditor.action';
import { LoboLogItemCategory } from '@lexialearning/lobo-common/main-model/logging';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, from, mergeMap } from 'rxjs';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { AppState } from 'services';
import { ProfileSelector } from 'services/profile';
import {
  StudentPropertyAction,
  StudentPropertyActionUpdate
} from '@lexialearning/student-api';
import { StudentProperty } from 'student-api';
import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  AvatarEditorActionType,
  ENCOUNTERS_TO_ENABLE_REEDIT
} from '../avatar-editor-redux.model';
import {
  TransitionAction,
  TransitionActionAvatarToNext
} from 'feature-areas/transitions';
import { IAvatar } from 'services/profile/avatar.model';
import {
  AppShellActionRequestLogout,
  AppShellActionShowModal,
  AppShellActionType
} from 'feature-areas/shell';
import { SessionTimeoutConfirmationModal } from 'feature-areas/session-timeout';

export interface IHandleSaveAvatarEpicDeps {
  logger: ILogger;
}

type OutputActions =
  | AvatarEditorActionReset
  | TransitionActionAvatarToNext
  | StudentPropertyActionUpdate;

export function saveAvatarEpic(
  action$: Observable<
    | AppShellActionRequestLogout
    | AppShellActionShowModal
    | AvatarEditorActionSaveAndExit
    | UserGlobalActionLogout
  >,
  state$: StateObservable<AppState>,
  deps: IHandleSaveAvatarEpicDeps
): Observable<OutputActions> {
  return action$.pipe(
    ofType(
      AppShellActionType.RequestLogout,
      AppShellActionType.ShowModal,
      AvatarEditorActionType.SaveAndExit,
      UserGlobalActionType.Logout
    ),
    filter(a => {
      const isAvatarEditorOpen = AvatarEditorSelector.getShouldShowEditor(
        state$.value
      );

      if (!isAvatarEditorOpen) {
        return false;
      }

      const isTimeoutWarning =
        a.type === AppShellActionType.ShowModal &&
        a.payload.id === SessionTimeoutConfirmationModal.ModalId;

      return isTimeoutWarning || a.type !== AppShellActionType.ShowModal;
    }),
    mergeMap(a => {
      const avatar = getAvatar(state$.value);

      logExitEvent(state$.value, deps.logger, avatar);

      const outputActions = getOutputActions(
        state$.value,
        a.type,
        avatar
      ).filter(a => !!a) as OutputActions[];

      return from(outputActions);
    })
  );
}
saveAvatarEpic.displayName = 'saveAvatarEpic';

function getAvatar(state: AppState) {
  const hasCreatedAvatar = ProfileSelector.hasCreatedAvatar(state);
  const avatar = hasCreatedAvatar
    ? ProfileSelector.getAvatarMaybe(state)
    : undefined;

  return avatar;
}

/**
 * Log the exit event to Cargo with payload of userEvents and avatar selection
 * @param state
 * @param logger
 * @param avatar
 */
function logExitEvent(
  state: AppState,
  logger: ILogger,
  avatar: IAvatar | undefined
) {
  const userEvents = AvatarEditorSelector.getUserEvents(state);

  void logger.log({
    category: LoboLogItemCategory.AvatarEditorExit,
    loggingLevel: LoggingLevel.Verbose,
    payload: { avatar, userEvents },
    summary: 'Student exited the Avatar Editor'
  });
}

function getOutputActions(
  state: AppState,
  actionType:
    | AppShellActionType.RequestLogout
    | AppShellActionType.ShowModal
    | AvatarEditorActionType.SaveAndExit
    | UserGlobalActionType.Logout,
  avatar: IAvatar | undefined
): Array<OutputActions | undefined> {
  const saveToStudentPropertiesActionMaybe =
    createSaveToStudentPropertiesActionMaybe(state, avatar);

  switch (actionType) {
    case AppShellActionType.RequestLogout:
    case AppShellActionType.ShowModal:
      return [saveToStudentPropertiesActionMaybe];
    case UserGlobalActionType.Logout:
      return [AvatarEditorAction.reset()];
    case AvatarEditorActionType.SaveAndExit:
    default:
      return [
        AvatarEditorAction.reset(),
        saveToStudentPropertiesActionMaybe,
        TransitionAction.avatarToNext()
      ];
  }
}

function createSaveToStudentPropertiesActionMaybe(
  state: AppState,
  avatar: IAvatar | undefined
) {
  const shouldAvatarUpdate = AvatarEditorSelector.getHasAvatarChanged(state);

  if (avatar && shouldAvatarUpdate) {
    return StudentPropertyAction.update.request({
      studentProperties: [
        {
          key: StudentProperty.Avatar,
          value: JSON.stringify({
            encountersUntilEditable: ENCOUNTERS_TO_ENABLE_REEDIT,
            selection: avatar
          })
        }
      ]
    });
  }
}
