import { Color, Font } from 'common-styles';

export class ModalContentStyles {
  private static readonly styles = {
    contentContainer: {
      maxWidth: 500,
      paddingHorizontal: 10,
      paddingVertical: 35
    },
    headline: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.large,
      marginBottom: 30,
      marginHorizontal: 25
    },
    img: {
      marginTop: 30,
      width: 480
    },
    text: {
      color: Color.Gray70,
      fontSize: Font.Size.small,
      marginBottom: 30,
      marginHorizontal: 25
    }
  };

  public static get() {
    return this.styles;
  }
}
