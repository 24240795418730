import { IStudentProperty } from '@lexialearning/student-api';
import { DeepLinkStudentProfile } from 'services/profile';
import { StudentProperty } from 'student-api';

export interface IAuth {
  apiTimeoutArray: number[];
  authToken: string;
  loginId: string;
  personId: number;
}

export interface IAuthError {
  incorrectUsernameOrPassword: boolean;
}

export const FakeAuth: IAuth = {
  apiTimeoutArray: [1, 2, 3],
  authToken: 'FAKE',
  loginId: 'FAKE',
  personId: -6666
};

export const FakeStudentProperties: IStudentProperty[] = [
  {
    key: StudentProperty.HasViewedOnboardingVideo,
    value: 'false'
  },

  {
    key: StudentProperty.Avatar,
    value: JSON.stringify({
      encountersUntilEditable: 0,
      selection: DeepLinkStudentProfile.avatar
    })
  }
];
