import { IEncounter } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { ProgramContextSelector } from './ProgramContext.selector';

export class EncounterSelector {
  public static readonly displayName = 'EncounterSelector';

  public static getEncounter(state: unknown): IEncounter {
    const encounter = this.getEncounterMaybe(state);

    if (!encounter) {
      throw new LexiaError(
        'No active encounter',
        EncounterSelector.displayName,
        EncounterSelectorError.EncounterMissing
      );
    }

    return encounter;
  }

  public static getEncounterMaybe(state: unknown): IEncounter | undefined {
    return ProgramContextSelector.getEncounterContextMaybe(state)?.encounter;
  }
}

export enum EncounterSelectorError {
  EncounterMissing = 'EncounterMissing'
}
