import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LevelReactAnimationName,
  LevelSceneAnimationName
} from 'feature-areas/levels';
import { AppShellAction } from 'feature-areas/shell';
import {
  ILevelIntroScreenplayDeps,
  LevelIntroScreenplayBuilder
} from 'feature-areas/transitions/builders/levels';
import {
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { SceneName } from 'services/storm-lobo';
import { DevToolsActionPlayLevelCompleteLevelIntro } from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

export function playLevelCompleteLevelIntroEpic(
  action$: Observable<DevToolsActionPlayLevelCompleteLevelIntro>,
  state$: StateObservable<unknown>,
  deps: ILevelIntroScreenplayDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(DevToolsActionType.PlayLevelCompleteLevelIntro),
    map(() => {
      const { preparedScenes } = deps;
      const entryPromptVo = BootstrapContentSelector.getLevelScreenContent(
        state$.value
      )?.entryPrompt;

      const { screenplay } = ScreenplayBuilder.create(
        'playLevelCompleteLevelIntro'
      )
        // First reset page
        .addReduxAction(AppShellAction.disableUtilityBar())
        .addReactAnimation(LevelReactAnimationName.FadeOut)
        .addStormAnimation({
          name: LevelSceneAnimationName.Root.Outro,
          targetScene: SceneName.Level
        })
        .addDelay(3000)
        // Then play level intro
        .addScreenplay(
          LevelIntroScreenplayBuilder.createFor({
            entryPromptVo,
            preparedScenes,
            shouldCalloutLevelCertificates: true
          }).screenplay
        );

      return { screenplay };
    }),
    map(ScreenplayAction.play)
  );
}
playLevelCompleteLevelIntroEpic.displayName = 'playLevelCompleteLevelIntroEpic';
