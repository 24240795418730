import { isEqual } from 'lodash';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { PositionTransition } from 'common-ui/components/position-transition';
import { TextSegmentAnimatedStyles } from 'task-components/shared/text-segment/TextSegment.animated-styles';

export interface ISegmentPositionTransitionProps {
  children?: React.ReactNode;
  segmentPosition?: XYCoord;
  showCorrect?: boolean;
}

export interface ISegmentPositionTransitionState {
  position?: XYCoord;
  toPosition?: XYCoord;
}

export class SegmentPositionTransition extends React.PureComponent<
  ISegmentPositionTransitionProps,
  ISegmentPositionTransitionState
> {
  public static readonly displayName = 'PositionTransition';

  constructor(props: ISegmentPositionTransitionProps) {
    super(props);

    this.state = { position: props.segmentPosition };
    this.handleAfterTransition = this.handleAfterTransition.bind(this);
  }

  public componentDidUpdate(prevProps: ISegmentPositionTransitionProps) {
    const { segmentPosition } = this.props;
    const { segmentPosition: prevSegmentPosition } = prevProps;

    if (!prevSegmentPosition && !segmentPosition) {
      return;
    }

    if (!prevSegmentPosition && segmentPosition) {
      this.setState({ position: segmentPosition });
    } else if (!isEqual(prevSegmentPosition, segmentPosition)) {
      this.setState({ toPosition: segmentPosition });
    }
  }

  private handleAfterTransition() {
    this.setState({ position: this.props.segmentPosition });
  }

  public render() {
    const { children, showCorrect } = this.props;
    const transitionDuration = showCorrect
      ? TextSegmentAnimatedStyles.AnimationDuration
      : undefined;

    return (
      <PositionTransition
        {...this.state}
        onAfterTransition={this.handleAfterTransition}
        transitionDuration={transitionDuration}
      >
        {children}
      </PositionTransition>
    );
  }
}
