import {
  ColorSelector,
  IColorSelectorWrapperProps,
  useHandleAvatarEditorButtonPress
} from '../../shared';
import { HairColor } from 'services/profile/avatar.model';
import { HairColorHexMap } from '../hair.model';

export function HairColorOptions(props: IColorSelectorWrapperProps) {
  const { selectedColor } = props;
  const getHexValue = (color: HairColor) => HairColorHexMap.get(color)?.hair;
  const handlePress = useHandleAvatarEditorButtonPress();
  const handleSelect = (hairColor: HairColor) => handlePress({ hairColor });

  return (
    <ColorSelector
      accessibilityLabelSuffix="hair"
      colors={HairColor}
      getHexValue={getHexValue}
      onSelect={handleSelect}
      selectedColor={selectedColor}
    />
  );
}
HairColorOptions.displayName = 'HairColorOptions';
