import { capitalize } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { MicPermission } from '@lexialearning/sre';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { concatMap, distinctUntilChanged } from 'rxjs/operators';

export class MicPermissionsHandler {
  public static readonly displayName = 'MicPermissionsHandler';

  private static _isListeningForUpdates = false;

  public static get isListeningForUpdates(): boolean {
    return this._isListeningForUpdates;
  }

  private static readonly permissionSubject = new BehaviorSubject<
    MicPermission | undefined
  >(undefined);

  public static get isBlocked$(): Observable<boolean> {
    return this.permissionSubject.asObservable().pipe(
      concatMap(async v => this.isBlocked(v)),
      distinctUntilChanged()
    );
  }

  public static async isBlocked(v?: MicPermission): Promise<boolean> {
    let state = v || this.permissionSubject.value;

    if (!state) {
      state = await this.readPermission();
    }

    return state === MicPermission.Denied;
  }

  public static async readPermission(): Promise<MicPermission | undefined> {
    let state: MicPermission | undefined;

    try {
      const permissionStatus = await navigator.permissions.query({
        // TODO after updating to typescript 4.7.4, got the following error:
        // '"microphone"' is not assignable to type 'PermissionName'.
        // PermissionName is defined in typescript/lib/lib.dom.d.ts and currently has the value:
        // type PermissionName = "geolocation" | "notifications" | "persistent-storage" | "push" | "screen-wake-lock" | "xr-spatial-tracking";
        // This seems very incomplete and more likely to be a bug in typescript rather than an actual issue
        // Casting to PermissionName type for now to resolve
        name: (UserAgentUtils.isSafari()
          ? 'microphone' // ⬅ safari still using 'microphone', all other browsers using 'audio_capture'
          : 'audio_capture') as PermissionName
      });

      permissionStatus.onchange = () => {
        this.permissionSubject.next(
          capitalize(permissionStatus.state) as MicPermission
        );
      };

      // ⬇ permissionStatus.onchange not supported by safari https://udn.realityripple.com/docs/Web/API/PermissionStatus/onchange
      if (!UserAgentUtils.isSafari()) {
        this._isListeningForUpdates = true;
      }

      state = capitalize(permissionStatus.state) as MicPermission;
    } catch (e) {
      state = await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          // cleanup
          stream.getTracks().forEach(t => {
            t.stop();
          });

          return MicPermission.Granted;
        })
        .catch((err: any) => {
          if (err.name === 'NotAllowedError') {
            return MicPermission.Denied;
          }

          return undefined;
        });
    }

    this.permissionSubject.next(state);

    return state;
  }
}
