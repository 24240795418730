import { Color, Font } from 'common-styles';
import { Position } from '@lexialearning/common-ui';
import { Types } from '@lexialearning/reactxp';
import { merge } from 'lodash';

export interface IAvatarUserNameStyleOverride {
  name?: Types.TextStyle;
}

export class AvatarUserNameStyles {
  private static readonly styles = {
    name: {
      backgroundColor: Color.NearWhiteTransparent40,
      borderRadius: 18,
      bottom: 0,
      fontFamily: Font.Family.semiBold,
      fontSize: 20,
      height: 36,
      lineHeight: 36,
      paddingLeft: 24,
      paddingRight: 24,
      position: Position.Absolute
    }
  };

  public static build(styleOverride?: IAvatarUserNameStyleOverride) {
    return merge({}, this.styles, styleOverride);
  }
}
