import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { ILanguageFrameToken, SeeSpeakMode } from '@lexialearning/lobo-common';
import { InteractionState } from 'common-styles';
import { Row, Text, View } from 'common-ui/components';
import { LanguageFrameTokenStyles } from './LanguageFrameToken.styles';
import { ThemeType } from 'theme';

export interface ILanguageFrameTokenProps {
  feedbackInteractionState: InteractionState;
  index: number;
  mode: SeeSpeakMode;
  themeType: ThemeType;
  token: ILanguageFrameToken;
}

export function LanguageFrameToken(props: ILanguageFrameTokenProps) {
  const { feedbackInteractionState, mode, themeType, token } = props;

  const styles = LanguageFrameTokenStyles.build(
    mode,
    themeType,
    token.decorations,
    feedbackInteractionState
  );

  return (
    <Row style={styles.container} testId={LanguageFrameToken.displayName}>
      <View style={styles.punctuationContainer}>
        <Text style={styles.punctuation}>{token.prePunctuation}</Text>
      </View>
      {styles.textHidden ? (
        <View
          style={styles.textContainer}
          importantForAccessibility={ImportantForAccessibility.Yes}
          accessibilityLabel="blank line"
        >
          <Text
            style={styles.textHidden}
            importantForAccessibility={
              ImportantForAccessibility.NoHideDescendants
            }
          >
            {token.cleanText}
          </Text>
        </View>
      ) : (
        <View style={styles.textContainer}>
          <Text style={styles.text} accessibilityLanguage={token.language}>
            {token.cleanText}
          </Text>
        </View>
      )}
      <View style={styles.punctuationContainer}>
        <Text style={styles.punctuation}>{token.postPunctuation}</Text>
      </View>
    </Row>
  );
}
LanguageFrameToken.displayName = 'LanguageFrameToken';
