import {
  IAvatarStudentProperties,
  IStudentApiConfigLobo,
  STUDENT_API_CONFIG_KEY,
  StudentProperty
} from './student-api.model';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { IStudentProperty } from '@lexialearning/student-api';
import { LexiaError } from '@lexialearning/utils';

export class StudentApiHelper {
  public static readonly displayName = 'StudentApiHelper';

  public static getEndpoint(src: IStudentApiConfigLobo | IConfigProvider) {
    const config = (
      'apiDomain' in src ? src : this.getConfig(src)
    ) as IStudentApiConfigLobo;

    const studentApiDomain = config.apiDomain;

    if (!studentApiDomain) {
      throw new LexiaError(
        'Config value for studentApi.apiDomain not found',
        StudentApiHelper.displayName,
        StudentApiHelperError.StudentApiEndpointUndefined
      );
    }

    return `${studentApiDomain}/api/ell`;
  }

  public static getConfig(configProvider: IConfigProvider) {
    const config = configProvider.getConfig<IStudentApiConfigLobo>(
      STUDENT_API_CONFIG_KEY
    );

    if (!config) {
      throw new LexiaError(
        `Configuration for student api unavailable at key ${STUDENT_API_CONFIG_KEY}`,
        StudentApiHelper.displayName,
        StudentApiHelperError.NoConfig
      );
    }

    return config;
  }

  public static getHasViewedOnboardingVideo(
    studentProperties: IStudentProperty[] | null
  ): boolean {
    const hasViewedOnboardingVideo = studentProperties?.find(
      p => p.key === StudentProperty.HasViewedOnboardingVideo
    )?.value;

    return hasViewedOnboardingVideo === 'true';
  }

  /**
   * Returns array of Level numbers for which the Level Complete Certificate
   * has been viewed
   * @param studentProperties
   */
  public static getLevelCertificatesViewed(
    studentProperties: IStudentProperty[]
  ): number[] {
    const levelCertificatesViewedStringValue =
      (studentProperties.find(
        p => p.key === StudentProperty.LevelCertificatesViewed
      )?.value as string) || '';

    const levelCertificatesViewed = levelCertificatesViewedStringValue
      .split(',')
      .map(v => parseInt(v, 10))
      .filter(v => Number.isInteger(v));

    return levelCertificatesViewed;
  }

  /**
   * Returns array of Level numbers for which the Level Complete Certificate
   * has been viewed
   * @param studentProperties
   */
  public static addViewedLevelCertificate(
    studentProperties: IStudentProperty[],
    newViewedCertificateLevel: number
  ): string {
    const levelCertificatesViewedStringValue =
      (studentProperties.find(
        p => p.key === StudentProperty.LevelCertificatesViewed
      )?.value as string) || '';

    const levelCertificatesViewed = levelCertificatesViewedStringValue
      .split(',')
      .map(v => parseInt(v, 10))
      .filter(v => Number.isInteger(v));

    if (levelCertificatesViewed.includes(newViewedCertificateLevel)) {
      // Log error
      // Services.logError(new LexiaError(
      //   'Trying to log viewed certificate that '
      // ))

      return levelCertificatesViewedStringValue;
    }

    const newLevelCertificatesViewedStringValue = [
      ...levelCertificatesViewed,
      newViewedCertificateLevel
    ].join(',');

    return newLevelCertificatesViewedStringValue;
  }

  /**
   * Returns and IAvatar object from an IStudentProperty array
   * @param studentProperties
   */
  public static parseAvatarStudentProperties(
    studentProperties: IStudentProperty[] | undefined
  ): IAvatarStudentProperties | undefined {
    const avatarStudentProperty = studentProperties?.find(
      ({ key }) => key === StudentProperty.Avatar
    );

    if (avatarStudentProperty) {
      try {
        return JSON.parse(
          avatarStudentProperty.value as string
        ) as IAvatarStudentProperties;
      } catch (error) {
        throw new LexiaError(
          `Failed to parse studentProperties avatar value: ${error}`,
          StudentApiHelper.displayName,
          StudentApiHelperError.AvatarUnparsable
        );
      }
    }
  }
}

export enum StudentApiHelperError {
  AvatarUnparsable = 'AvatarUnparsable',
  NoConfig = 'NoConfig',
  StudentApiEndpointUndefined = 'StudentApiEndpointUndefined'
}
