import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import {
  AnimatedViewStyle as ICuiAnimatedViewStyle,
  IViewStyle as ICuiViewStyle,
  Position
} from '@lexialearning/common-ui';

export interface ISparkleAnimationProps {
  fromX: number;
  fromY: number;
  key: number;
  toX: number;
  toY: number;
}

interface ISparkleAnimations {
  sparkle: Types.Animated.CompositeAnimation;
}

interface ISparkleStyles {
  opacityAnimated: ICuiAnimatedViewStyle;
  positionAnimated: ICuiAnimatedViewStyle;
  positionStatic: ICuiViewStyle;
}

const ANIMATION_DURATION = 533;
const DELAY_INCREMENT_DURATION = 133;

export class SparkleAnimatedStyles {
  private readonly animations: ISparkleAnimations;
  private readonly styles: ISparkleStyles;

  constructor(props: ISparkleAnimationProps) {
    const animatedValues = {
      opacity: loboAnimated.createValue(0),
      translateX: loboAnimated.createValue(props.fromX),
      translateY: loboAnimated.createValue(props.fromY)
    };

    const speed = 1;
    const delayDuration = DELAY_INCREMENT_DURATION * props.key * speed;

    const reset = loboAnimated.parallel([
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.opacity,
        {
          delay: delayDuration * speed,
          duration: 0,
          toValue: 0
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        animatedValues.translateY,
        {
          duration: 0,
          toValue: props.fromY
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateX,
        animatedValues.translateX,
        {
          duration: 0,
          toValue: props.fromX
        }
      )
    ]);

    const show = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      animatedValues.opacity,
      {
        delay: delayDuration * speed,
        duration: 0,
        toValue: 1
      }
    );

    const translate = loboAnimated.sequence([
      loboAnimated.parallel([
        loboAnimated.timing(
          AnimatableCSSProperty.TranslateY,
          animatedValues.translateY,
          {
            duration: ANIMATION_DURATION * speed,
            toValue: props.toY
          }
        ),
        loboAnimated.timing(
          AnimatableCSSProperty.TranslateX,
          animatedValues.translateX,
          {
            duration: ANIMATION_DURATION * speed,
            toValue: props.toX
          }
        )
      ])
    ]);

    const fadeout = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      animatedValues.opacity,
      {
        duration: ANIMATION_DURATION * speed,
        toValue: 0
      }
    );

    this.animations = {
      sparkle: loboAnimated.sequence([
        // start with a reset in case the prior animation hadn't completed
        reset,
        show,
        loboAnimated.parallel([fadeout, translate])
      ])
    };

    this.styles = {
      opacityAnimated: {
        opacity: animatedValues.opacity
      },
      positionAnimated: {
        transform: [
          { translateX: animatedValues.translateX },
          { translateY: animatedValues.translateY }
        ]
      },
      positionStatic: {
        left: -3.5,
        position: Position.Absolute,
        top: 0
      }
    };
  }

  public getAnimations(): ISparkleAnimations {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
