import { Path, Rect } from '@lexialearning/common-ui';
import { UtilityButtonColors } from './utility-button-model';

export function UtilityButtonBackground(props: {
  borderColor?: string;
  backgroundColor?: string;
  withShadow: boolean;
}) {
  return (
    <>
      {/* Border shadow */}
      {props.withShadow && (
        <Path
          transform="translate(1 1)"
          fill={UtilityButtonColors.shadow}
          d="M31.48,4.1A6.56,6.56,0,0,1,38,10.66V31.55a6.56,6.56,0,0,1-6.55,6.55H10.59A6.56,6.56,0,0,1,4,31.55V10.66A6.56,6.56,0,0,1,10.59,4.1H31.48m0-2H10.59A8.58,8.58,0,0,0,2,10.66V31.55a8.58,8.58,0,0,0,8.56,8.55H31.48A8.58,8.58,0,0,0,40,31.55V10.66A8.58,8.58,0,0,0,31.48,2.1Z"
        />
      )}
      {/* Background */}
      {props.backgroundColor && (
        <Rect
          className="a"
          width="36.74"
          height="36.74"
          rx="8.35"
          transform="translate(2.66 2.78)"
          fill={props.backgroundColor}
        />
      )}
      {/* Border */}
      {props.borderColor && (
        <Path
          fill={props.borderColor}
          d="M31.48,4.1A6.56,6.56,0,0,1,38,10.66V31.55a6.56,6.56,0,0,1-6.55,6.55H10.59A6.56,6.56,0,0,1,4,31.55V10.66A6.56,6.56,0,0,1,10.59,4.1H31.48m0-2H10.59A8.58,8.58,0,0,0,2,10.66V31.55a8.58,8.58,0,0,0,8.56,8.55H31.48A8.58,8.58,0,0,0,40,31.55V10.66A8.58,8.58,0,0,0,31.48,2.1Z"
        />
      )}
    </>
  );
}
UtilityButtonBackground.displayName = 'UtilityButtonBackground';
