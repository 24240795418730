import { Types } from '@lexialearning/reactxp';
import { AnimatableCSSProperty, Color, loboAnimated } from 'common-styles';

const INITIAL_DELAY = 2000;
const SLIDE_IN_DURATION = 400;
const PAUSE_DURATION = 1000;
const FADE_OUT_DURATION = 400;

export const TOTAL_ANIMATION_DURATION =
  INITIAL_DELAY + SLIDE_IN_DURATION + PAUSE_DURATION + FADE_OUT_DURATION;

export class LevelCertificateEncouragementAnimatedStyles {
  private readonly styles: {
    text: Types.TextStyle;
    textAnimated: Types.AnimatedTextStyle;
  };

  private readonly animations: {
    encouragement: Types.Animated.CompositeAnimation;
  };

  constructor() {
    const initialLeftValue = 140;
    const opacityValue = loboAnimated.createValue(0);
    const positionLeftValue = loboAnimated.createValue(initialLeftValue);

    this.animations = {
      encouragement: loboAnimated.sequence([
        loboAnimated.parallel([
          loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
            delay: INITIAL_DELAY,
            duration: SLIDE_IN_DURATION,
            easing: loboAnimated.Easing.Out(),
            toValue: 1
          }),
          loboAnimated.timing(AnimatableCSSProperty.Left, positionLeftValue, {
            delay: INITIAL_DELAY,
            duration: SLIDE_IN_DURATION,
            easing: loboAnimated.Easing.Out(),
            toValue: 0
          })
        ]),
        loboAnimated.timing(AnimatableCSSProperty.Opacity, opacityValue, {
          delay: PAUSE_DURATION,
          duration: FADE_OUT_DURATION,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }),
        loboAnimated.timing(AnimatableCSSProperty.Left, positionLeftValue, {
          duration: 0,
          toValue: initialLeftValue
        })
      ])
    };

    this.styles = {
      text: {
        backgroundColor: Color.DeepBlue2,
        borderRadius: 8,
        color: Color.NearWhite,
        fontSize: 16,
        paddingVertical: 7,
        width: 130
      },
      textAnimated: {
        left: positionLeftValue,
        opacity: opacityValue
      }
    };
  }

  // istanbul ignore next - trivial
  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
