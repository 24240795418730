import {
  ISyntheticEvent,
  IViewProps as ICuiViewProps,
  IViewStyle as ICuiViewStyle,
  View as CuiView
} from '@lexialearning/common-ui';
import { ViewStyles } from './View.styles';
import { CommonEventProps } from '@lexialearning/reactxp/dist/common/Types';

export interface IViewProps
  extends Omit<
    ICuiViewProps,
    | keyof CommonEventProps
    | 'style'
    // TODO: these should be added to the UNI CommonEventProps and removed here
    | 'onHoverEnd'
    | 'onHoverStart'
    | 'onKeyboardFocus'
  > {
  stopPressPropagation?: boolean;
  style?: ICuiViewStyle;
}
export function View(props: IViewProps) {
  const { style, forwardedRef, stopPressPropagation, ...restProps } = props;

  const onPress = stopPressPropagation
    ? (e: ISyntheticEvent): void => {
        e.stopPropagation();
      }
    : undefined;

  return (
    <CuiView
      {...restProps}
      onPress={onPress}
      forwardedRef={forwardedRef}
      style={ViewStyles.build(style)}
    />
  );
}
View.displayName = 'View';
