import {
  ICompletion,
  ICompletionSentenceFragment,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { sum } from 'lodash';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DndAction, DndSelector } from 'dnd/redux';
import {
  Column,
  RoundedTabPosition,
  RoundedTabSvg,
  Row,
  SpotlightArea,
  withPositionHandler
} from 'common-ui';
import { InteractionState } from 'common-styles';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { ITaskProps, TaskSelector } from '../core';
import { SubmitButton } from '../shared';
import { CompletionAnswerSlot } from './answer-slot/CompletionAnswerSlot';
import { CompletionAnswerSlotStyles } from './answer-slot/CompletionAnswerSlot.styles';
import { CompletionChoice } from './choice/CompletionChoice';
import { CompletionChoiceStyles } from './choice/CompletionChoice.styles';
import { ICompletionChoice } from './completion-component.model';
import { CompletionStyles } from './Completion.styles';
import { CompletionSentenceFragment } from './sentence-fragment/CompletionSentenceFragment';
import { DividingLineSvg } from './svg/DividingLine.lx-svg';

export interface ICompletionProps
  extends ITaskProps<ICompletion<ICompletionChoice>>,
    ISfxProps {
  dndIsDropping: boolean;
  showSolution: boolean;
  dropToPosition(position: XYCoord | undefined): void;
}

export interface ICompletionState {
  choices: ICompletionChoice[];
  activeSrcIdx: number;
  activeTargetIdx: number;
}

const CompletionChoiceWithPosition = withPositionHandler(CompletionChoice);

export class CompletionComponent extends React.PureComponent<
  ICompletionProps,
  ICompletionState
> {
  public static readonly displayName = 'Completion';

  private readonly choicePositions: XYCoord[];

  private readonly answerSlotPositions: XYCoord[];

  constructor(props: ICompletionProps) {
    super(props);

    this.state = {
      activeSrcIdx: -1,
      activeTargetIdx: -1,
      choices: this.props.taskContent.choices
    };

    this.choicePositions = [];
    this.answerSlotPositions = [];
    this.handleChoicePosition = this.handleChoicePosition.bind(this);
    this.handleAnswerSlotPosition = this.handleAnswerSlotPosition.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBeginDrag = this.handleBeginDrag.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleEndDrag = this.handleEndDrag.bind(this);
  }

  public componentDidUpdate(prevProps: ICompletionProps) {
    const { dndIsDropping: prevDndIsDropping, phase: prevPhase } = prevProps;
    const { dndIsDropping, phase } = this.props;

    if (prevDndIsDropping && !dndIsDropping) {
      const { activeSrcIdx, activeTargetIdx } = this.state;
      // Repeatedly trying to drag items in rapid succession (seen on iPad) can lead to this being
      // hit with activeSrcIdx -1, so we need to defense against that
      // Fixes: LOBO-15864
      if (activeSrcIdx !== -1) {
        this.updateChoiceLocation(activeSrcIdx, activeTargetIdx);
      }
      this.setState({
        activeSrcIdx: -1,
        activeTargetIdx: -1
      });
    }

    if (prevPhase === TaskPhase.Feedback && phase === TaskPhase.Interactive) {
      this.returnIncorrectChoicesToBank();
    }
    if (!prevProps.showSolution && this.props.showSolution) {
      this.renderSolution();
    }
  }

  private returnIncorrectChoicesToBank() {
    const { sentences } = this.props.taskContent;
    const choices = this.state.choices.map(c => {
      if (c.answerSlotIdx > -1) {
        const [sentIdx, fragIdx] = this.getIndices(c.answerSlotIdx);
        const sf = sentences[sentIdx][fragIdx];
        if (c.text !== sf.text) {
          c.answerSlotIdx = -1;
        }
      }

      return c;
    });
    this.updateState(choices);
  }

  private updateState(choices: ICompletionChoice[]) {
    this.props.updateCanSubmit({
      canSubmit: !this.hasOpenSlots(choices)
    });
    this.setState({
      choices
    });
  }

  private renderSolution() {
    const choices = this.state.choices.map(c => ({
      ...c,
      answerSlotIdx: this.getSolutionAnswerSlotIdx(c.text)
    }));

    this.updateState(choices);
  }

  private getSolutionAnswerSlotIdx(text: string) {
    let fragmentIdx = -1;
    const { sentences } = this.props.taskContent;
    const sentIdx = sentences.findIndex(sent => {
      fragmentIdx = sent.findIndex(sf => sf.isAnswerSlot && sf.text === text);

      return fragmentIdx > -1;
    });

    return sentIdx > -1 ? sentIdx * 100 + fragmentIdx : -1;
  }

  private getIndices(pos: number) {
    return [Math.floor(pos / 100), pos % 100];
  }

  private getAnswerSlotIdx(sentIdx: number, fragmentIdx: number) {
    return sentIdx * 100 + fragmentIdx;
  }

  private handleBeginDrag(srcIdx: number) {
    this.props.updateCanSubmit({ canSubmit: false });
    this.setState({ activeSrcIdx: srcIdx });
  }

  private handleDrag(offset: XYCoord) {
    const activeTargetIdx = this.findHoveredAnswerSlotIdx(offset);

    if (activeTargetIdx !== this.state.activeTargetIdx) {
      this.setState({
        activeTargetIdx
      });
      if (activeTargetIdx > -1) {
        this.props.playSfx(Sfx.DropZone);
      }
    }
  }

  private findHoveredAnswerSlotIdx(offset: XYCoord): number {
    const pillW = CompletionStyles.getPillWidth(this.props.themeSize);
    const pillH = CompletionChoiceStyles.Height;

    // Note: need to use Array.from in order to replace empty entries with 0
    const overlaps = Array.from(
      this.answerSlotPositions.map(p =>
        this.calculateAnswerSlotOverlap(p, offset, pillW, pillH)
      ),
      overlap => overlap || 0
    );
    const maxOverlap = Math.max(...overlaps);
    if (maxOverlap === 0) {
      return -1;
    }

    return overlaps.indexOf(maxOverlap);
  }

  private calculateAnswerSlotOverlap(
    answerSlotPosition: XYCoord,
    dragPillOffset: XYCoord,
    dragPillW: number,
    dragPillH: number
  ): number {
    const overlapX = Math.min(
      dragPillOffset.x + dragPillW - answerSlotPosition.x,
      answerSlotPosition.x + dragPillW - dragPillOffset.x
    );
    const overlapY = Math.min(
      dragPillOffset.y + dragPillH - answerSlotPosition.y,
      answerSlotPosition.y +
        CompletionAnswerSlotStyles.answerSlotHeight -
        dragPillOffset.y
    );
    if (overlapX <= 0 || overlapY <= 0) {
      return 0;
    }

    return overlapX * overlapY;
  }

  private handleEndDrag(srcIdx: number) {
    const { activeTargetIdx } = this.state;
    const position =
      activeTargetIdx > -1
        ? // Drop to active target answer slot if there is one
          this.answerSlotPositions[activeTargetIdx]
        : // Otherwise retun to choice bank
          this.choicePositions[srcIdx];
    this.props.dropToPosition(position);
  }

  private checkAnswers() {
    const { sentences } = this.props.taskContent;
    const { choices } = this.state;
    const placedChoices = choices.filter(c => c.answerSlotIdx !== -1);
    const hasIncorrectAnswer = placedChoices.some(c => {
      const [sentIdx, fragIdx] = this.getIndices(c.answerSlotIdx);

      return sentences[sentIdx][fragIdx].text !== c.text;
    });

    // return the choices in the order they placed in the sentences
    const sortedChoices = placedChoices.sort(
      (c, c1) => c.answerSlotIdx - c1.answerSlotIdx
    );

    this.props.onEvaluated(
      hasIncorrectAnswer
        ? TaskEvaluationResult.Incorrect
        : TaskEvaluationResult.Correct,
      { choices: sortedChoices, sentences }
    );
  }

  private hasOpenSlots(choices: ICompletionChoice[]) {
    const { sentences } = this.props.taskContent;
    const answerSlotCount = sum(
      sentences.map(s => s.filter(sf => sf.isAnswerSlot).length)
    );
    const placedChoicesCount = choices.filter(c => c.answerSlotIdx > -1).length;

    return answerSlotCount !== placedChoicesCount;
  }

  /**
   * Move choice item to given target location
   * @param sourceItem - item to be moved
   * @param targetIdx - index of target locaiton, if no targetIdx supplied, item will be returned to choice bank
   */
  private updateChoiceLocation(sourceIdx: number, targetIdx = -1) {
    const choices = [...this.state.choices];
    const srcChoice = choices[sourceIdx];
    const srcAnswerSlotIdx = srcChoice.answerSlotIdx;
    const toAnswerSlot = targetIdx > -1; // moving to an answer slot

    let currentPlacedChoiceSrcIdx = targetIdx;
    if (toAnswerSlot) {
      // Check if item currently in target location and if so, set its position
      // to that of source item
      currentPlacedChoiceSrcIdx = choices.findIndex(
        c => c.answerSlotIdx === targetIdx
      );
      if (currentPlacedChoiceSrcIdx > -1) {
        choices[currentPlacedChoiceSrcIdx].answerSlotIdx = srcAnswerSlotIdx;
      }
      // Set value of target answer slot location to sourceItem
      choices[sourceIdx].answerSlotIdx = targetIdx;
    } else {
      choices[sourceIdx].answerSlotIdx = -1;
    }

    this.updateState(choices);
  }

  private handleSubmit(): void {
    this.props.updateCanSubmit({ canSubmit: false });
    this.checkAnswers();
  }

  private getAnswerState(
    sentenceFragment: ICompletionSentenceFragment,
    placedChoiceSrcIdx: number
  ) {
    const { phase, evaluationResult, showSolution } = this.props;
    const isCorrect =
      this.state.choices[placedChoiceSrcIdx]?.text === sentenceFragment.text;

    if (showSolution) {
      return InteractionState.Highlighted;
    }

    if (phase === TaskPhase.Feedback) {
      if (evaluationResult === TaskEvaluationResult.Incorrect && !isCorrect) {
        return InteractionState.Incorrect;
      }

      if (evaluationResult === TaskEvaluationResult.Correct) {
        return InteractionState.Correct;
      }
    }

    return InteractionState.Default;
  }

  private async handleChoicePosition(position: XYCoord, idx: number) {
    this.choicePositions[idx] = position;
  }

  private async handleAnswerSlotPosition(position: XYCoord, idx: number) {
    this.answerSlotPositions[idx] = position;
  }

  public render() {
    const { activeSrcIdx, activeTargetIdx, choices } = this.state;
    const {
      showSolution,
      taskContent: { instructionalImage, spotlights, sentences },
      themeSize
    } = this.props;
    const hasSpotlightArea = !!spotlights.length || !!instructionalImage;
    const styles = CompletionStyles.build(themeSize, hasSpotlightArea);

    return (
      <Column style={styles.task}>
        <Row style={styles.spotlightAreaContainer}>
          <SpotlightArea
            instructionalImage={instructionalImage}
            spotlights={spotlights}
          />
        </Row>
        <Row>
          <Row style={styles.completionBox}>
            <Column style={styles.choicesColumn}>
              {choices.map((c: ICompletionChoice, i: number) => {
                const showChoice = c.answerSlotIdx === -1;

                return (
                  <CompletionChoiceWithPosition
                    key={`CompletionChoice${i}`}
                    index={i}
                    styleOverride={styles.choiceStyleOverride}
                    dragStyleOverride={styles.choiceDragStyleOverride}
                    pillWidth={CompletionStyles.getPillWidth(themeSize)}
                    voiceover={c.voiceover}
                    shouldBeHidden={!showChoice}
                    activeTargetIdx={activeTargetIdx}
                    onPosition={this.handleChoicePosition}
                    onBeginDrag={this.handleBeginDrag}
                    onDrag={this.handleDrag}
                    onEndDrag={this.handleEndDrag}
                  >
                    {c.text}
                  </CompletionChoiceWithPosition>
                );
              })}
            </Column>
            <DividingLineSvg />
            <Column
              style={styles.sentencesContainer}
              blockPointerEvents={showSolution}
            >
              {sentences.map((sent, sentIdx) => (
                <Row style={styles.row} key={`sentence${sentIdx}`}>
                  {sent.map((sf, i: number) => {
                    const answerSlotIdx = this.getAnswerSlotIdx(sentIdx, i);
                    if (sf.isAnswerSlot) {
                      const placedChoiceSrcIdx = choices.findIndex(
                        c => c.answerSlotIdx === answerSlotIdx
                      );
                      const answerInteractionState = this.getAnswerState(
                        sf,
                        placedChoiceSrcIdx
                      );
                      const placedChoice =
                        placedChoiceSrcIdx > -1
                          ? {
                              ...choices[placedChoiceSrcIdx],
                              srcIdx: placedChoiceSrcIdx
                            }
                          : undefined;

                      return (
                        <CompletionAnswerSlot
                          key={`CompletionAnswerSlot${answerSlotIdx}`}
                          index={answerSlotIdx}
                          dragStyleOverride={styles.choiceDragStyleOverride}
                          pillWidth={CompletionStyles.getPillWidth(themeSize)}
                          activeSrcIdx={activeSrcIdx}
                          activeTargetIdx={activeTargetIdx}
                          interactionState={answerInteractionState}
                          placedChoice={placedChoice}
                          prePunctuation={sf.prePunctuation}
                          postPunctuation={sf.postPunctuation}
                          onPosition={this.handleAnswerSlotPosition}
                          onBeginDrag={this.handleBeginDrag}
                          onDrag={this.handleDrag}
                          onEndDrag={this.handleEndDrag}
                        />
                      );
                    }

                    return (
                      <CompletionSentenceFragment
                        text={sf.text}
                        idx={answerSlotIdx}
                        key={`CompletionSentenceFragment${answerSlotIdx}`}
                      />
                    );
                  })}
                </Row>
              ))}
            </Column>
          </Row>
          <Column style={styles.submitButtonContainer}>
            <RoundedTabSvg position={RoundedTabPosition.Right} />
            <SubmitButton onPress={this.handleSubmit} />
          </Column>
        </Row>
      </Column>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    dndIsDropping: !!DndSelector.getDropTargetPosition(state),
    showSolution: TaskSelector.getShowSolution(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  dropToPosition: (position: XYCoord | undefined) =>
    DndAction.dropToPosition({ position })
};

export const Completion = compose(
  withSfx,
  connect(mapStateToProps, mapDispatchToProps)
)(CompletionComponent);
