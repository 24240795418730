export class AppCrasher {
  public static readonly displayName = 'AppCrasher';

  public static location = window.location;

  public static reload(): void {
    this.location.replace(this.location.origin);
  }

  public static crash(): void {
    this.reload();
  }
}
