import {
  IListener,
  SreSessionOptions,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';
import { Sre } from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { CalibrationListener } from './CalibrationListener';
import { LanguageFrameListener } from './LanguageFrameListener';

export class ListenerFactory {
  public static readonly displayName = 'ListenerFactory';

  public static create(options: SreSessionOptions, sre: Sre): IListener {
    switch (options.sessionType) {
      case SreSessionType.LanguageFrame:
        return new LanguageFrameListener(sre);

      case SreSessionType.Calibration:
        return new CalibrationListener(sre);

      default:
        throw new LexiaError(
          `Unsupported session type: ${options.sessionType}`,
          ListenerFactory.displayName,
          ListenerFactoryErrorCode.UnsupportedSessionType
        ).withContext({ options });
    }
  }
}

export enum ListenerFactoryErrorCode {
  UnsupportedSessionType = 'UnsupportedSessionType'
}
