import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { Observable } from 'rxjs';
import { Playback } from './Playback';
import {
  IScreenplayActionPlayer,
  IScreenplayEvent,
  ScreenplayerType
} from './screenplayer.model';
import { ILogger } from '@lexialearning/main-model';

/**
 * Orchestrates screenplay playback.
 *
 * See ADR 26 for some noteworthy nuances
 */
export class Screenplayer {
  public static readonly displayName = 'Screenplayer';

  public readonly actionPlayerMap: Map<string, IScreenplayActionPlayer>;

  private playback?: Playback;

  constructor(
    actionPlayers: IScreenplayActionPlayer[],
    private readonly logger: ILogger
  ) {
    this.actionPlayerMap = actionPlayers.reduce(
      (map, actionPlayer) => map.set(actionPlayer.type, actionPlayer),
      new Map<string, IScreenplayActionPlayer>()
    );
  }

  public addPlayer(actionPlayer: IScreenplayActionPlayer): void {
    this.actionPlayerMap.set(actionPlayer.type, actionPlayer);
  }

  public isPlaying(screenplayId?: string): boolean {
    return screenplayId !== undefined
      ? screenplayId === this.playback?.screenplay.id
      : !!this.playback;
  }

  public isPlayingId(screenplayId: string): boolean {
    return screenplayId === this.playback?.screenplay.id;
  }

  /**
   * Cancel any active screenplay
   */
  public cancel(): void {
    this.playback?.cancel();
    this.playback = undefined;
  }

  /**
   * cancel music that is currently playing
   * this will allow for canceling a looping music play,
   * which would not be handled by simply calling the screenplay.cancel method
   */
  public cancelMusic(): void {
    this.actionPlayerMap.get(ScreenplayerType.Music)?.cancel();
  }

  /**
   * Skip any active screenplay. Same as canceling except the emitted event
   * is "skipped" instead of "canceled" allowing consumer to behave differently.
   */
  public skip(): void {
    this.playback?.skip();
    this.playback = undefined;
  }

  /**
   * Play the screenplay, emitting an IScreenplayEvent before, during, and after
   * each screenplay action is played and culminating with a completed event.
   *
   * An empty screenplay yields a single completed event.
   * Any active screenplay is first canceled before playing the requested one.
   *
   * Canceling or skipping a screenplay emits a canceled/skipped event before
   * completing the observable.
   *
   * The active playback is undefined immediately before emitting the Completed
   * or Canceled event.
   */
  public play(screenplay: IScreenplay): Observable<IScreenplayEvent> {
    if (!screenplay) {
      throw new LexiaError(
        'No screenplay to play!',
        Screenplayer.displayName,
        ScreenplayerErrorCode.ScreenplayRequired
      );
    }
    // console.error(
    //   'Screenplay:',
    //   screenplay.id,
    //   'starting.',
    //   screenplay.actions.length,
    //   'actions'
    // );

    this.cancel();

    this.playback = new Playback(
      screenplay,
      this.actionPlayerMap,
      this.logger,
      () => {
        this.playback =
          this.playback?.screenplay.id === screenplay.id
            ? undefined
            : this.playback;
      }
    );

    return this.playback.start();
  }

  /**
   * Pause any active screenplay
   */
  public pause(): void {
    this.playback?.pause();
  }

  /**
   * Resume any paused screenplay
   */
  public resume(): void {
    this.playback?.resume();
  }
}

export enum ScreenplayerErrorCode {
  ScreenplayRequired = 'ScreenplayRequired'
}
