import { G, Path, Svg } from 'common-ui';

export function Pick3Svg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={75.834}
      height={36.305}
      accessibilityLabel="Pick three."
    >
      <G fill="#455b5b">
        <Path d="M7.393 1.699a7.233 7.233 0 015.259 1.951 6.13 6.13 0 012.121 4.835 6.584 6.584 0 01-2.036 5 7.247 7.247 0 01-5.174 1.951H4.255v4.835a2.049 2.049 0 01-2.121 2.128 2.054 2.054 0 01-2.118-2.295V3.899a2.073 2.073 0 012.288-2.2h5.09zm-.17 9.925a2.921 2.921 0 002.969-3.054 2.854 2.854 0 00-2.884-3.054H4.255v6.107z" />
        <Path d="M16.555 4.496a2.136 2.136 0 01-.763-1.7 2.375 2.375 0 112.375 2.375 2.669 2.669 0 01-1.612-.679zm3.737 15.692a2.129 2.129 0 01-2.036 2.29 2.078 2.078 0 01-2.036-2.29v-11.2a2.184 2.184 0 012.036-2.29 2.078 2.078 0 012.036 2.29z" />
        <Path d="M32.417 11.028l-.763-.254a6.8 6.8 0 00-1.781-.424c-2.121 0-3.478 1.781-3.478 4.241a5.142 5.142 0 00.933 2.969 3.133 3.133 0 002.545 1.272 6.48 6.48 0 001.781-.424 9.084 9.084 0 011.357-.424 1.851 1.851 0 011.781 1.781c0 .848-.509 1.527-1.612 2.036a8.4 8.4 0 01-3.478.848 7.057 7.057 0 01-5.344-2.29 7.815 7.815 0 01-2.121-5.6 8.371 8.371 0 012.121-5.768 6.848 6.848 0 015.345-2.374 7.871 7.871 0 013.563.763c1.018.509 1.527 1.1 1.527 1.866a1.814 1.814 0 01-1.612 1.951c-.255-.08-.509-.08-.764-.169z" />
        <Path d="M40.646 12.045L45.061 7.8a2.82 2.82 0 012.036-1.1 1.866 1.866 0 011.781 1.866c0 .509-.085 1.018-1.018 1.781l-3.478 3.139 4.835 5.683a2.29 2.29 0 01.594 1.442 1.919 1.919 0 01-1.95 1.953 2.877 2.877 0 01-2.036-1.272l-5-6.192h-.094v5.09a2.129 2.129 0 01-2.036 2.29 2.078 2.078 0 01-2.036-2.29V2.29A2.129 2.129 0 0138.695 0a2.078 2.078 0 012.036 2.29v9.755z" />
        <Path d="M75.834 16.718a6.424 6.424 0 01-2.205 4.75 7.851 7.851 0 01-5.429 2.036 7.621 7.621 0 01-5.174-1.866c-1.443-1.273-2.206-2.545-2.206-3.818a2.339 2.339 0 012.375-2.205q1.145 0 2.036 1.781a3.178 3.178 0 002.969 1.782 2.809 2.809 0 002.884-2.884c0-1.612-.848-2.46-2.63-2.545s-2.634-.849-2.634-2.123c0-1.188.763-1.866 2.205-2.121s2.205-1.018 2.205-2.29a2.009 2.009 0 00-2.2-2.034 2.926 2.926 0 00-2.63 1.527c-.593 1.018-1.187 1.527-1.95 1.527a2.066 2.066 0 01-1.951-2.29 4.262 4.262 0 012.121-3.393 7.81 7.81 0 014.58-1.526 6.472 6.472 0 014.665 1.7 4.932 4.932 0 011.866 3.9 5.147 5.147 0 01-2.545 4.665 5.948 5.948 0 013.648 5.429z" />
        <Path d="M19.934 33.507a2.8 2.8 0 112.8 2.8 2.77 2.77 0 01-2.8-2.8z" />
        <Path d="M35.881 33.507a2.8 2.8 0 112.8 2.8 2.8 2.8 0 01-2.8-2.8z" />
        <Path d="M51.829 33.507a2.8 2.8 0 112.8 2.8 2.8 2.8 0 01-2.8-2.8z" />
      </G>
    </Svg>
  );
}
Pick3Svg.displayName = 'Pick3Svg';
