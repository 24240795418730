import { AnimatedView, View } from 'common-ui';
import { Services } from 'services';
import { AuthSelector } from 'services/auth';
import { CustomerSelector } from 'services/customer/redux';
import { DeviceSetupForm } from './device-setup-form';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { LexiaServiceSelector } from 'lexia-service/redux/LexiaService.selector';
import { LoginAnimatedStyles } from './Login.animated-styles';
import { LoginForm } from './login-form';
import { LoginReactAnimationName } from './login.model';
import { LoginShell } from './login-shell';
import { SplashScreenAnimatedStyles } from 'app/SplashScreen.animated-styles';
import { StormSelector } from 'storm';
import { SystemInfo } from 'utils/SystemInfo';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  LoginTabName,
  RoutePath,
  RouteBuilder,
  RouterService
} from 'router-service';
import { LoboLogItemCategory } from 'logging';
import { LoggingLevel } from '@lexialearning/main-model';

export function LoginPageComponent(props: ISfxProps) {
  const hasValidationError = useSelector(AuthSelector.hasValidationError);
  const hasInvalidEmail = useSelector(CustomerSelector.hasInvalidEmail);
  const hasError = hasValidationError || hasInvalidEmail;
  const isAuthenticated = useSelector(AuthSelector.isAuthenticated);
  const isMakingRequest = useSelector(LexiaServiceSelector.getIsMakingRequest);
  const isStormInitialized = useSelector(StormSelector.isInitialized);
  const isDeviceSetupCompleted = useSelector(
    CustomerSelector.isDeviceSetupCompleted
  );

  const { playSfx } = props;
  const { selectedTab: selectedTabParam } = useParams();
  const mainInputRef = useRef<any>();
  const animatedStyles = useRef(new LoginAnimatedStyles());
  const styles = animatedStyles.current.get();
  const { fadeIn, fadeOut } = animatedStyles.current.getAnimations();

  // Reroute if needed
  const isDeviceSetupRoute = RouterService.isOnRoute(RoutePath.DeviceSetup);
  useEffect(() => {
    const rerouteUrl = getRerouteUrlMaybe(
      isDeviceSetupCompleted,
      isDeviceSetupRoute,
      selectedTabParam
    );
    if (!!rerouteUrl) {
      RouterService.history.replace(rerouteUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeviceSetupCompleted, isDeviceSetupRoute, selectedTabParam]);

  function getRerouteUrlMaybe(
    isDeviceSetupCompleted: boolean,
    isDeviceSetupRoute: boolean,
    selectedTabParam: string | undefined
  ) {
    if (!isDeviceSetupCompleted) {
      if (!isDeviceSetupRoute) {
        return RouteBuilder.deviceSetup();
      }
    } else {
      const isLoginTabRoute = Object.values(LoginTabName).includes(
        selectedTabParam as LoginTabName
      );
      if (!RouterService.isOnNonSsoLoginRoute()) {
        Services.logger?.log({
          category: LoboLogItemCategory.PageComponentMisMatch,
          loggingLevel: LoggingLevel.Info,
          payload: {
            hasInvalidEmail,
            hasValidationError,
            isAuthenticated,
            isDeviceSetupCompleted,
            isLoginTabRoute,
            isMakingRequest,
            isStormInitialized,
            route: RouterService.activeRoute
          },
          summary: `LoginPage (still) loaded while on ${RouterService.activeRoute} route`
        });
      } else if (!isLoginTabRoute) {
        // Maintain query string on login page in order to persist SSO values
        const urlQueryString = RouterService.history.location.search;

        return RouteBuilder.login(LoginTabName.Student) + urlQueryString;
      }
    }
  }

  // Register fadeout
  useEffect(() => {
    const unregisterAnimation =
      Services.reactAnimationScreenplayer.registerAnimations([
        /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
        { name: LoginReactAnimationName.FadeOut, animation: fadeOut }
        /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
      ]);

    return () => {
      unregisterAnimation();
    };
  }, [fadeOut]);

  // Play fadeIn after delay
  useEffect(() => {
    const fadeInTimeout = setTimeout(() => {
      fadeIn.start();
    }, SplashScreenAnimatedStyles.Duration);

    return () => clearTimeout(fadeInTimeout);
  }, [fadeIn]);

  useEffect(() => {
    // Play sfx on error
    if (hasError) {
      playSfx(Sfx.Error);
    }
  }, [playSfx, hasError]);

  useEffect(() => {
    // Reset autofocus on main input after storm initializes,
    // as storm steals focus while initializing
    // Except on native - If you autofocus an input on native, it
    // will bring up the keyboard and slide the login page up.
    if (isStormInitialized && !SystemInfo.isNative) {
      mainInputRef.current?.requestFocus();
    }
  }, [isStormInitialized]);

  function renderForm(selectedTab: LoginTabName) {
    return isDeviceSetupCompleted || RouterService.isSsoUrl() ? (
      <LoginForm selectedTab={selectedTab} mainInputRef={mainInputRef} />
    ) : (
      <DeviceSetupForm mainInputRef={mainInputRef} />
    );
  }

  return (
    <View
      testId={LoginPageComponent.displayName}
      style={styles.container}
      blockPointerEvents={isMakingRequest || isAuthenticated}
    >
      <AnimatedView animatedStyle={styles.containerAnimated}>
        <LoginShell>
          {renderForm(
            (selectedTabParam as LoginTabName) || LoginTabName.Student
          )}
        </LoginShell>
      </AnimatedView>
    </View>
  );
}
LoginPageComponent.displayName = 'Login';

export const LoginPage = withSfx(LoginPageComponent);
