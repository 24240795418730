import { AnimatableCSSProperty, Color, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';

const defaultDuration = 500;
enum HeightValues {
  Large = 60,
  Medium = 35,
  Small = 12
}

export interface IAmbienceAnimations {
  sizeAnimation: Types.Animated.CompositeAnimation;
  returnToStart: Types.Animated.CompositeAnimation;
}

export class AmbienceAnimationStyles {
  private readonly animations: IAmbienceAnimations;

  private readonly styles: {
    container: Types.ViewStyle;
    containerAnimated: Types.AnimatedViewStyle;
  };

  constructor() {
    const height = loboAnimated.createValue(HeightValues.Small);

    const sizeAnimation = loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Height, height, {
        duration: defaultDuration,
        easing: loboAnimated.Easing.In(),
        toValue: HeightValues.Large
      }),
      loboAnimated.timing(AnimatableCSSProperty.Height, height, {
        duration: defaultDuration,
        easing: loboAnimated.Easing.Out(),
        toValue: HeightValues.Small
      }),
      loboAnimated.timing(AnimatableCSSProperty.Height, height, {
        duration: defaultDuration,
        easing: loboAnimated.Easing.In(),
        toValue: HeightValues.Medium
      }),
      loboAnimated.timing(AnimatableCSSProperty.Height, height, {
        duration: defaultDuration,
        easing: loboAnimated.Easing.Out(),
        toValue: HeightValues.Small
      })
    ]);

    const returnToStart = loboAnimated.timing(
      AnimatableCSSProperty.Height,
      height,
      {
        duration: defaultDuration / 2,
        easing: loboAnimated.Easing.Out(),
        toValue: HeightValues.Small
      }
    );

    this.animations = {
      returnToStart,
      sizeAnimation
    };

    this.styles = {
      container: {
        backgroundColor: Color.Aqua,
        borderRadius: 4,
        marginLeft: 6.5,
        marginRight: 6.5,
        width: 7
      },
      containerAnimated: {
        height
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
