import { AuthSelector } from 'services/auth';
import { SystemInfo } from '@lexialearning/utils-react';
import { ContactSupportButton } from 'feature-areas/contact-support';
import { HideTroubleshootButton } from 'feature-areas/shell/utility-bar/troubleshoot-button/HideTroubleshootButton';
import { ModalRegistry } from 'shared-components/modals';
import { RoundSelector } from 'curriculum-services';
import { Row, View, WebView, Text, Column } from '@lexialearning/common-ui';
import { SettingsButton } from 'feature-areas/shell/settings-button';
import { TroubleshootModalStyles } from './TroubleshootModal.styles';
import { useSelector } from 'react-redux';
import { REPO_HTML_DIR } from 'services/app-config/appConfig';

export function TroubleshootModal() {
  const loginId = useSelector(AuthSelector.getLoginId);
  const round = useSelector(RoundSelector.getRoundMaybe);

  const checklistUrl = `${REPO_HTML_DIR}/troubleshooting-checklist/${
    // istanbul ignore next - trivial code, difficult to test
    SystemInfo.isNative ? 'ios' : 'web'
  }.html`;

  const styles = TroubleshootModalStyles.get();

  return (
    <>
      <WebView bounces={false} style={styles.webView} url={checklistUrl} />
      <Row style={styles.footerRow}>
        <Column style={styles.footerInfo}>
          {!!round && (
            <Text selectable style={styles.footerText}>
              {round.title}
            </Text>
          )}
          <Text selectable style={styles.footerText}>
            Support ID: {loginId}
          </Text>
        </Column>

        <Row style={styles.footerButtons}>
          <View style={styles.settingsContainer}>
            <SettingsButton />
          </View>
          <ContactSupportButton />
        </Row>
      </Row>
    </>
  );
}
TroubleshootModal.displayName = 'TroubleshootModal';

// istanbul ignore next - trivial code, not worth testing
TroubleshootModal.ModalId = ModalRegistry.register({
  AnchorComponent: HideTroubleshootButton,
  Component: TroubleshootModal,
  ariaLabel: 'Microphone Troubleshooting Checklist',
  title: 'Quick Tips for Microphone Setup & Use'
});
