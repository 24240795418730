import {
  IBootstrapScreens,
  ICalibrationScreen,
  IHomeScreen,
  ILevelScreen,
  IScreenplay,
  ITextWithVoiceover
} from '@lexialearning/lobo-common/main-model';
import { sample } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { SystemInfo } from 'utils';
import { IBootstrapContentReduxState } from './bootstrap-content-redux.model';

export class BootstrapContentSelector {
  public static readonly displayName = 'BootstrapContentSelector';

  public static getState: (state: unknown) => IBootstrapContentReduxState;

  public static getProgramCompleteVO: (
    state: unknown
  ) => IScreenplay | undefined;

  public static getScreens: (state: unknown) => IBootstrapScreens | undefined;

  public static getCalibrationScreenContent: (
    state: unknown
  ) => ICalibrationScreen | undefined;

  public static getCalibrationSentence: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static getHomeScreenContent: (
    state: unknown
  ) => IHomeScreen | undefined;

  public static getLevelScreenContent: (
    state: unknown
  ) => ILevelScreen | undefined;

  // Calibration screen getters
  public static getMicBlockedContent: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static getMicChangedContent: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static getSelectMicContent: (
    state: unknown
  ) => ITextWithVoiceover | undefined;

  public static createSelectors(
    selector: (state: any) => IBootstrapContentReduxState
  ): void {
    BootstrapContentSelector.getState = createSelector(
      selector,
      (state: IBootstrapContentReduxState) => state
    );
    BootstrapContentSelector.getProgramCompleteVO = createSelector(
      BootstrapContentSelector.getState,
      (state: IBootstrapContentReduxState) =>
        sample(state.programCompleteVoiceover || [])
    );
    BootstrapContentSelector.getScreens = createSelector(
      BootstrapContentSelector.getState,
      (state: IBootstrapContentReduxState) => state.screens
    );
    BootstrapContentSelector.getCalibrationScreenContent = createSelector(
      BootstrapContentSelector.getScreens,
      (screens?: IBootstrapScreens) => screens?.calibration
    );
    BootstrapContentSelector.getCalibrationSentence = createSelector(
      BootstrapContentSelector.getCalibrationScreenContent,
      (screens?: ICalibrationScreen) => sample(screens?.calibrationSentences)
    );

    BootstrapContentSelector.getHomeScreenContent = createSelector(
      BootstrapContentSelector.getScreens,
      (screens?: IBootstrapScreens) => screens?.home
    );
    BootstrapContentSelector.getLevelScreenContent = createSelector(
      BootstrapContentSelector.getScreens,
      (screens?: IBootstrapScreens) => screens?.level
    );

    BootstrapContentSelector.getMicBlockedContent = createSelector(
      BootstrapContentSelector.getCalibrationScreenContent,
      (content?: ICalibrationScreen) =>
        content?.feedback.micBlocked[SystemInfo.isNative ? 'ios' : 'web']
    );
    BootstrapContentSelector.getMicChangedContent = createSelector(
      BootstrapContentSelector.getCalibrationScreenContent,
      (content?: ICalibrationScreen) => content?.feedback.micChanged
    );
    BootstrapContentSelector.getSelectMicContent = createSelector(
      BootstrapContentSelector.getCalibrationScreenContent,
      (content?: ICalibrationScreen) => content?.micSelect
    );
  }
}
