import {
  IViewProps as ICuiViewProps,
  IViewStyle as ICuiViewStyle,
  View as CuiView,
  ISyntheticEvent
} from '@lexialearning/common-ui';
import { withGlobalDisabled } from '../withGlobalDisabled.hoc';
import { PressableViewStyles } from './PressableView.styles';
import { UserInterface } from '@lexialearning/reactxp';

export interface IPressableViewProps
  extends Omit<
    ICuiViewProps,
    'style' | 'onMouseEnter' | 'onMouseLeave' | 'onMouseOver'
  > {
  disabled?: boolean;
  style?: ICuiViewStyle;
  onHoverEnd?(e?: ISyntheticEvent): void;
  onHoverStart?(e?: ISyntheticEvent): void;
}
export function PressableViewComponent(props: IPressableViewProps) {
  const {
    style,
    disabled,
    forwardedRef,
    onKeyDown,
    onKeyUp,
    onHoverEnd,
    onHoverStart,
    onPress,
    onPressIn,
    onPressOut,
    ...restProps
  } = props;

  const hoverHandlers = UserInterface.isNavigatingWithKeyboard()
    ? undefined
    : {
        onHoverEnd,
        onHoverStart
      };

  const interactionProps = disabled
    ? undefined
    : {
        ...hoverHandlers,
        onKeyDown,
        onKeyUp,
        onPress,
        onPressIn,
        onPressOut
      };

  return (
    <CuiView
      {...restProps}
      {...interactionProps}
      onMouseEnter={interactionProps?.onHoverStart}
      onMouseLeave={interactionProps?.onHoverEnd}
      forwardedRef={forwardedRef}
      style={PressableViewStyles.build(style, disabled)}
    />
  );
}
PressableViewComponent.displayName = 'PressableView';

export const PressableView = withGlobalDisabled(PressableViewComponent);
