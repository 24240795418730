import * as React from 'react';
import { useTheme } from 'theme';
import { Column, Row } from '../layout';
import { WorkAreaStyles } from './WorkArea.styles';

export interface IWorkAreaProps {
  layout?: WorkAreaLayout;
  children?: React.ReactNode;
}

export enum WorkAreaLayout {
  FullWidth,
  TwoColumn
}

export function WorkArea(props: IWorkAreaProps) {
  const { themeType } = useTheme();
  const { layout = WorkAreaLayout.FullWidth, children } = props;
  const styles = WorkAreaStyles.build(themeType);

  return (
    <Row style={styles.container}>
      {layout === WorkAreaLayout.TwoColumn && (
        <Column style={styles.leftColumn} />
      )}
      <Column style={styles.workArea}>{children}</Column>
    </Row>
  );
}
WorkArea.displayName = 'WorkArea';
