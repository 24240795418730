import * as React from 'react';
import { connect } from 'react-redux';
import { Carousel, Image, ModalCloseButton } from 'common-ui';
import { AppShellAction } from 'feature-areas/shell';
import { ModalRegistry } from 'shared-components';
import {
  ILevelStatus,
  LevelsCompletedAction,
  LevelsCompletedSelector,
  LevelStatus
} from 'curriculum-services';
import * as CertificateImages from './assets';
import { LevelCertificateCarouselModalStyles } from './LevelCertificateCarouselModal.styles';
import { AppState } from 'services';

export interface ILevelCertificateCarouselModalProps {
  levelNumber: number;
  completedLevelsStatuses: ILevelStatus[];
  hideModal(): void;
  setCertificateViewed(level: number): void;
}

interface ILevelCertificateCarouselModalState {
  currentSlideIdx: number;
}

export class LevelCertificateCarouselModalComponent extends React.PureComponent<
  ILevelCertificateCarouselModalProps,
  ILevelCertificateCarouselModalState
> {
  public static displayName = 'LevelCertificateCarouselModal';

  constructor(props: ILevelCertificateCarouselModalProps) {
    super(props);
    // Completed levels don't necessarily start at level 1, nor include all
    // levels before the current one. If a student was initially placed in a
    // Level greater than 1, or manually set ahead to a higher level at some
    // point in the program, they would have some levels with status
    // 'Skipped' so we can't assume the level number and slide index are
    // correlated
    const currentSlideIdx = props.completedLevelsStatuses.findIndex(
      ls => ls.levelNumber === props.levelNumber
    );
    this.state = { currentSlideIdx };
    this.handlePressPrevious = this.handlePressPrevious.bind(this);
    this.handlePressNext = this.handlePressNext.bind(this);
    this.updateSlide = this.updateSlide.bind(this);
  }

  public componentDidMount() {
    this.props.setCertificateViewed(this.props.levelNumber);
  }

  private updateSlide(slideIdx: number) {
    this.setState({ currentSlideIdx: slideIdx });
    const slideLevelStatus = this.props.completedLevelsStatuses[slideIdx];
    if (!slideLevelStatus?.certificateViewed) {
      this.props.setCertificateViewed(slideLevelStatus.levelNumber);
    }
  }

  private handlePressPrevious() {
    const { currentSlideIdx } = this.state;
    this.updateSlide(currentSlideIdx - 1);
  }

  private handlePressNext() {
    const { currentSlideIdx } = this.state;
    this.updateSlide(currentSlideIdx + 1);
  }

  private renderSlides(): React.ReactElement[] {
    const { completedLevelsStatuses } = this.props;
    const styles = LevelCertificateCarouselModalStyles.get();

    return completedLevelsStatuses.map((levelStatus, idx) => {
      const levelString = `0${levelStatus.levelNumber}`.slice(-2);
      // eslint-disable-next-line import/namespace
      const imgSrc = CertificateImages[`l${levelString}Png`];

      return (
        <Image
          key={`levelCertificateImg${idx}`}
          style={styles.image}
          source={imgSrc}
          accessibilityLabel={`Certificate of Achievement for completing Level ${levelString}.`}
        />
      );
    });
  }

  public render() {
    const { hideModal } = this.props;
    const { currentSlideIdx } = this.state;
    const styles = LevelCertificateCarouselModalStyles.get();

    return (
      <>
        <Carousel
          accessibilityLabel="Level Certificates Earned Carousel."
          currentSlideIdx={currentSlideIdx}
          onPressNext={this.handlePressNext}
          onPressPrevious={this.handlePressPrevious}
          slideWidth={LevelCertificateCarouselModalStyles.SlideWidth}
          slides={this.renderSlides()}
          styleOverrides={styles.carouselOverrides}
          updateCurrentSlide={this.updateSlide}
        />
        <ModalCloseButton
          onClose={hideModal}
          scale={0.85}
          styleOverride={styles.closeButton}
        />
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: AppState) {
  const completedLevelsStatuses = LevelsCompletedSelector.getLevelsStatus(
    state
  ).filter(ls => ls.status === LevelStatus.Completed);

  return {
    completedLevelsStatuses
  };
}

const mapDispatchToProps = {
  hideModal: () => AppShellAction.hideModal(),
  setCertificateViewed: (level: number) =>
    LevelsCompletedAction.setCertificateViewed.request(level)
};

export const LevelCertificateCarouselModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelCertificateCarouselModalComponent);

LevelCertificateCarouselModal.ModalId = ModalRegistry.register({
  Component: LevelCertificateCarouselModal,
  ariaLabel: 'Level Certificate Carousel Modal',
  rawLayout: true
});

export const LevelCertificateCarouselModalPrivates = { mapDispatchToProps };
