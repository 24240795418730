import { Align, Color, Justify, TextAlign } from 'common-styles';

export class ActOverviewStyles {
  private static readonly styles = {
    headShot: {
      height: 65,
      width: 65
    },
    infoColumn: {
      alignItems: Align.Start,
      height: 42,
      justifyContent: Justify.SpaceBetween,
      width: 310
    },
    row: {
      backgroundColor: Color.BlackTransparent04,
      borderRadius: 10,
      height: 65,
      justifyContent: Justify.SpaceBetween,
      marginBottom: 10,
      paddingHorizontal: 10,
      width: 400
    },
    text: {
      fontSize: 12,
      textAlign: TextAlign.Left
    }
  };

  public static get() {
    return this.styles;
  }
}
