import {
  AncestryLoader,
  ConfigOverridesLoader,
  ContentfulApi,
  ContentProviderFactory
} from '@lexialearning/lobo-common/cms';
import { StateManager } from '@lexialearning/lobo-common/lib';
import { ErrorHandler } from '@lexialearning/utils-react';
import { ConfigProvider } from 'app-config';
import { AppConfigDependencies } from 'app-config/epics/AppConfigDependencies';
import { StudentProgressApi } from 'curriculum-services/program-context/epics/student-progress-api';
import { createCmsService } from 'feature-areas/tasks/createCmsService';
import { taskRegistry } from 'feature-areas/tasks/lobo-task-registry';
import { LexiaService } from 'lexia-service';
import { AuthApi } from 'lexia-service/auth-api';
import { CustomerApi } from 'lexia-service/customer-api';
import { ConfigOverridesFactory, Version } from 'services/app-config';
import { CurriculumDependencies } from 'services/curriculum-services';
import { Services } from 'services/Services';
import { SreService } from 'sre';
import { LxStormAudio, StormService } from 'storm';
import { LocalStorageService } from '../local-storage';
import { AppLoggerDependencies } from '../logging';
import { ScreenplayerFactory } from '../service-factories';
import { PreparedScenes } from '../storm-lobo';

export class ServiceDependenciesFactory {
  public static readonly displayName = 'ServiceDependenciesFactory';

  /**
   * Create the dependencies object passed to epics.
   * This should only be used by AppBootstrapper (outside of tests). The object
   * should be created only once in the life-cycle of the app.
   */
  public static create() {
    // FIXME: Update lobo common constructor
    const configProvider = new ConfigProvider();
    const contentApi = new ContentfulApi(configProvider);
    const cmsService = createCmsService(configProvider, contentApi);
    const contentProviderFactory = new ContentProviderFactory(
      configProvider,
      cmsService
    );
    // FIXME: Update lobo common constructor
    const stateManager = new StateManager();
    const lexiaService = new LexiaService(configProvider, stateManager);
    const authApi = new AuthApi(lexiaService, stateManager);
    const customerApi = new CustomerApi(lexiaService, stateManager);

    const studentProgressApi = new StudentProgressApi(
      configProvider,
      lexiaService,
      stateManager,
      Version.Main,
      async err => ErrorHandler.handleUnknownError(err)
    );

    const stormService = new StormService(
      configProvider,
      contentProviderFactory,
      cmsService
    );

    const ancestryLoader = new AncestryLoader(contentApi);
    const preparedScenes = new PreparedScenes();
    const appConfigDependencies = new AppConfigDependencies(
      ConfigOverridesFactory.AppName,
      new ConfigOverridesLoader(cmsService)
    );
    const localStorageService = new LocalStorageService();

    const sre = new SreService(configProvider, Services.sessionId);

    const appLoggerDependencies = new AppLoggerDependencies(
      configProvider,
      localStorageService,
      taskRegistry
    );
    const { logger } = appLoggerDependencies;

    const curriculumDependencies = new CurriculumDependencies(
      cmsService,
      configProvider,
      contentProviderFactory,
      stormService,
      studentProgressApi,
      taskRegistry
    );

    const stormAudioPlayer = new LxStormAudio(stormService);
    const screenplayer = ScreenplayerFactory.create(
      stormService,
      stormAudioPlayer,
      logger
    );

    return {
      ancestryLoader,
      appConfigDependencies,
      appLoggerDependencies,
      authApi,
      cmsService,
      configProvider,
      contentProviderFactory,
      curriculumDependencies,
      customerApi,
      lexiaService,
      localStorageService,
      logger,
      preparedScenes,
      screenplayer,
      sre,
      stateManager,
      stormAudioPlayer,
      stormService,
      studentProgressApi,
      taskRegistry
    };
  }
}

export type IServiceDependencies = ReturnType<
  typeof ServiceDependenciesFactory.create
>;
