import { AnimatedView, Types, Avatar, AvatarType } from 'common-ui';
import { AvatarAndSpeechBubbleTailStyles } from './AvatarAndSpeechBubbleTail.styles';
import { Color } from 'common-styles';
import { SpeechBubbleTailSvg } from './SpeechBubbleTail.lx-svg';

export interface IAvatarSpeechBubbleTailProps {
  animatedStyle?: Types.AnimatedViewStyle;
  borderFill?: string;
  backgroundFill?: string;
}

export function AvatarAndSpeechBubbleTail(props: IAvatarSpeechBubbleTailProps) {
  const { animatedStyle, borderFill, backgroundFill } = props;

  const styles = AvatarAndSpeechBubbleTailStyles.get();

  return (
    <AnimatedView
      animatedStyle={animatedStyle}
      style={styles.avatarSpeechTail}
      testId={AvatarAndSpeechBubbleTail.displayName}
    >
      <Avatar
        type={AvatarType.Thumbnail}
        styleOverride={styles.avatarOverride}
      />
      <SpeechBubbleTailSvg
        style={styles.speechTailSvg}
        backgroundFill={backgroundFill || Color.NearWhite}
        borderFill={borderFill || Color.Black}
      />
    </AnimatedView>
  );
}
AvatarAndSpeechBubbleTail.displayName = 'AvatarSpeechBubbleTail';
