import { connect } from 'react-redux';
import { CommonUiSelector } from '../redux/CommonUi.selector';

export interface IGlobalDisabledProps {
  // If true, a component does not disable when globalDisabled is true
  ignoreGlobalDisabled?: boolean;
}

export interface IGlobalDisabledReduxProps {
  globalDisabled: boolean;
  disabled: boolean;
}

// istanbul ignore next - trivial
function mapStateToProps(
  state: unknown,
  ownProps: { disabled?: boolean; ignoreGlobalDisabled?: boolean }
) {
  if (ownProps.ignoreGlobalDisabled) {
    return {};
  }

  const globalDisabled = CommonUiSelector.isGlobalDisabled(state);

  return {
    // Unifying ownProps.disabled and globalDisabled allows most components to remain unchanged
    // in their handling of disabled - setting styles and functionality as they already do to reflect a disabled state
    disabled: ownProps.disabled || globalDisabled,

    // Adding the non-unified globalDisabled prop allows it to be handled distinctly on its own
    // in the minor cases where that is needed
    globalDisabled
  };
}

/**
 * Disable when spinner is showing
 */
export function withGlobalDisabled(component: any) {
  return connect(mapStateToProps)(component) as typeof component;
}
withGlobalDisabled.displayName = 'withGlobalDisabled';

export const withGlobalDisabledPrivates = { mapStateToProps };
