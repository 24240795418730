import { UserGlobalAction } from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { LogoutIconSvg } from 'common-ui/graphics/LogoutIcon.lx-svg';
import {
  ModalActions,
  ModalRegistry,
  ModalType
} from 'shared-components/modals';
import { AppShellAction } from 'shell/redux/AppShell.action';
import about_to_logoutPng from './about_to_logout.png';
import { BackIconSvg } from './BackIcon.lx-svg';

export interface ILogoutConfirmationModalProps {
  close(): void;
  logout(): void;
}

export class LogoutConfirmationModalComponent extends React.PureComponent<ILogoutConfirmationModalProps> {
  public static readonly displayName = 'LogoutConfirmationModal';

  public constructor(props: ILogoutConfirmationModalProps) {
    super(props);

    this.handleLogOut = this.handleLogOut.bind(this);
  }

  private handleLogOut(): void {
    this.props.logout();
    this.props.close();
  }

  public render() {
    return (
      <ModalActions
        primaryAction={{
          Icon: LogoutIconSvg,
          ignoreGlobalDisabled: true,
          onPress: this.handleLogOut,
          text: 'Log Out'
        }}
        secondaryAction={{
          Icon: BackIconSvg,
          onPress: this.props.close,
          text: 'Go Back'
        }}
      />
    );
  }
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  close: () => AppShellAction.hideModal(),
  logout: () => UserGlobalAction.logout.request(LogoutReason.UserLogout)
};

export const LogoutConfirmationModal: any = connect(
  undefined,
  mapDispatchToProps
)(LogoutConfirmationModalComponent);

LogoutConfirmationModal.ModalId = ModalRegistry.register({
  Component: LogoutConfirmationModal,
  ariaLabel: 'Logout Confirmation',
  headline: 'You are about to log out.',
  image: about_to_logoutPng,
  type: ModalType.Confirmation
});
