import memoizeOne from 'memoize-one';
import { Justify } from 'common-styles';

export class VerticalOrderingStyles {
  public static readonly build = memoizeOne((hasSpotlight: boolean) => ({
    orderingContainer: {
      height: 387,
      marginTop: hasSpotlight ? -30 : 0
    },
    taskContainer: {
      flex: 1,
      justifyContent: hasSpotlight ? Justify.Start : Justify.Center
    }
  }));
}
