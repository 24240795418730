import {
  IEvaluation,
  IRoundAttempt,
  IRoundRecycling,
  IScoreableRound,
  TaskEvaluationResult
} from '@lexialearning/lobo-common';
import { findIndex, uniqBy } from 'lodash';

/**
 * Determines list of recycle round ids for Presentation of Knowledge units
 */
export class PokRoundRecycling {
  public static readonly displayName = 'PokRoundRecycling';

  public static buildRecycleRoundIds(
    attempt: IRoundAttempt,
    prevRound: IScoreableRound | undefined
  ): string[] {
    if (!this.failedActiveRound(attempt)) {
      return [];
    }

    const supportingRoundId = this.maybeGetSupportingRoundId(prevRound);
    const recycleIdList = supportingRoundId ? [supportingRoundId] : [];

    recycleIdList.push(attempt.roundId);

    return recycleIdList;
  }

  private static failedActiveRound(attempt: IEvaluation | undefined): boolean {
    return attempt?.result === TaskEvaluationResult.Incorrect;
  }

  private static maybeGetSupportingRoundId(
    prevRound: IScoreableRound | undefined
  ): string | undefined {
    return prevRound && !prevRound.isScored ? prevRound.roundId : undefined;
  }

  /**
   * Determine recycle rounds from attempt history
   * Case 1 (student is in the first pass) [R1 R2 R3 R4]
   * Case 2 (student is in a supporting round of a recycle pass) [R1 SR2 R3 R4 R5 SR2]
   * Case 3 (student) is in a round of a recycle pass) [R1 R2 R3 R4 R5 R2 R3]
   */
  public static buildRoundRecycling(
    currentRoundId: string,
    attempts: IRoundAttempt[]
  ): IRoundRecycling | undefined {
    const uniqAttempts = uniqBy(attempts, 'roundId');
    const roundIdsToRecycle = uniqAttempts.reduce((acc, attempt, index) => {
      const toAdd = this.buildRecycleRoundIds(attempt, attempts[index - 1]);

      return [...acc, ...toAdd];
    }, [] as string[]);

    return roundIdsToRecycle.length
      ? {
          pass:
            uniqAttempts.length !== attempts.length ||
            findIndex(attempts, { roundId: currentRoundId }) >= 0
              ? 1
              : 0,
          roundIdsToRecycle
        }
      : undefined;
  }
}

export enum PokRecycleRoundBuilderError {
  RoundInvalid = 'RoundInvalid'
}
