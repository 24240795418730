import memoizeOne from 'memoize-one';
import { Align, Color, Font, Justify, TextAlign } from 'common-styles';

export class SkillExampleBoxStyles {
  private static readonly styles = {
    content: {
      fontSize: Font.Size.xSmall,
      textAlign: TextAlign.Left
    },
    headline: {
      fontFamily: Font.Family.semiBold,
      fontSize: 16,
      marginBottom: 5,
      textAlign: TextAlign.Left
    }
  };

  public static readonly build = memoizeOne((boxCount: number) => ({
    ...SkillExampleBoxStyles.styles,
    container: {
      alignItems: Align.Start,
      backgroundColor: Color.GoldTransparent30,
      borderRadius: 10,
      height: 120,
      justifyContent: Justify.Start,
      marginVertical: 25,
      paddingHorizontal: 8,
      paddingVertical: 12,
      width: boxCount === 2 ? 175 : 115
    }
  }));
}
