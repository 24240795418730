import { CalibrationMicButtonStyles } from './CalibrationMicButton.styles';
import { InteractionState } from 'common-styles';
import { MicButton } from 'common-ui';
import { SreCalibrationAction } from 'sre';
import { useDispatch } from 'react-redux';

export interface ICalibrationMicButtonProps {
  disabled: boolean;
  interactionState: InteractionState;
}

export function CalibrationMicButton(
  props: ICalibrationMicButtonProps
): React.ReactElement {
  const { disabled, interactionState } = props;
  const styles = CalibrationMicButtonStyles.get();

  const dispatch = useDispatch();

  const startCalibrating = () => {
    dispatch(SreCalibrationAction.calibrate());
  };

  const handleMicPress = () => {
    startCalibrating();
  };

  return (
    <MicButton
      immediateCallout
      interactionState={interactionState}
      disabled={disabled}
      onPress={handleMicPress}
      styleOverride={styles.micButton}
    />
  );
}
CalibrationMicButton.displayName = 'CalibrationMicButton';
