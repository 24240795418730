import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  Direction,
  FontFamily,
  Justify,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import { UserControlButtonStyles } from 'common-ui/components/button/user-control-button/UserControlButton.styles';
import { Position } from '@lexialearning/common-ui';

const DURATION = 250;
const DX = 70;
const DY = 20;

interface ILookbackAnimationStyleProps {
  hasBadge: boolean;
}
export class LookbackButtonAnimatedStyles {
  private readonly animations: {
    show: Types.Animated.CompositeAnimation;
    hide: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
    container: Types.ViewStyle;
    buttonOverride: Types.ViewStyle;
    badgeAnimated: Types.AnimatedViewStyle;
  };

  constructor(props: ILookbackAnimationStyleProps) {
    const opacity = loboAnimated.createValue(0);
    const badgeOpacity = loboAnimated.createValue(0);
    const width = loboAnimated.createValue(55);
    const translateX = loboAnimated.createValue(-DX);
    const translateY = loboAnimated.createValue(-DY);

    const slideOut = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.TranslateX, translateX, {
        duration: DURATION,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: DURATION,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      })
    ]);

    let show = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
        duration: DURATION,
        easing: loboAnimated.Easing.Out(),
        toValue: 1
      }),
      slideOut
    ]);

    let hide = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
        duration: DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: 0
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateX, translateX, {
        duration: DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: -DX
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration: DURATION,
        easing: loboAnimated.Easing.In(),
        toValue: -DY
      })
    ]);

    if (props.hasBadge) {
      const showBadge = loboAnimated.timing(
        AnimatableCSSProperty.Width,
        width,
        {
          duration: DURATION,
          easing: loboAnimated.Easing.Out(),
          toValue: 92
        }
      );

      const hideBadge = loboAnimated.timing(
        AnimatableCSSProperty.Width,
        width,
        {
          duration: DURATION,
          easing: loboAnimated.Easing.Out(),
          toValue: 55
        }
      );

      const badgeTextShow = loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        badgeOpacity,
        {
          easing: loboAnimated.Easing.In(),
          toValue: 1
        }
      );

      const badgeTextHide = loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        badgeOpacity,
        {
          easing: loboAnimated.Easing.In(),
          toValue: 0
        }
      );

      show = loboAnimated.sequence([show, showBadge, badgeTextShow]);
      hide = loboAnimated.sequence([badgeTextHide, hideBadge, hide]);
    }

    this.animations = {
      hide,
      show
    };

    this.styles = {
      badgeAnimated: {
        opacity: badgeOpacity
      },
      buttonOverride: {
        height: 38,
        width: 38
      },
      container: {
        ...UserControlButtonStyles.containerSmall,
        alignItems: Align.Center,
        bottom: 25,
        flexDirection: Direction.Row,
        justifyContent: Justify.Center,
        left: 99,
        paddingHorizontal: 6,
        position: Position.Absolute,
        width: undefined
      },
      containerAnimated: {
        opacity,
        transform: [{ translateX }, { translateY }],
        width
      }
    };
  }

  public getAnimations() {
    return this.animations;
  }

  public readonly build = memoizeOne((disabled: boolean) => ({
    ...this.styles,
    badgeText: {
      color: disabled ? Color.NearWhiteTransparent30 : Color.NearWhite,
      fontFamily: FontFamily.FigmentSansSemiBold,
      fontSize: 22,
      marginHorizontal: 8
    }
  }));
}
