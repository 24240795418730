import { AudioService } from '@lexialearning/common-ui';
import {
  ICalibrationResult,
  IListener,
  SreSessionOptions
} from '@lexialearning/lobo-common/main-model/sre';
import {
  Sre,
  ICalibrationEndResult,
  CalibrationResult
} from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { LoboSfx } from 'audio';
import { SoundLogFormatHelper } from 'sre/helpers/SoundLogFormat.helper';

export class CalibrationListener implements IListener<ICalibrationResult> {
  public static readonly displayName = 'CalibrationListener';

  public constructor(private readonly sre: Sre) {}

  public async listen(options: SreSessionOptions): Promise<ICalibrationResult> {
    try {
      const result = await this.calibrate(options);
      const code = result.calibrationResult;

      return {
        passed: code === CalibrationResult.Ok,
        result: code,
        soundLogId: result.soundLogId
      };
    } catch (sreErr) {
      throw new LexiaError(
        `Error calibrating SRE: ${sreErr}`,
        CalibrationListener.displayName,
        CalibrationListenerError.CalibrationError
      ).withContext({ sreErr });
    }
  }

  private async calibrate(
    options: SreSessionOptions
  ): Promise<ICalibrationEndResult> {
    const soundLogFormat = SoundLogFormatHelper.getSoundLogFormat(
      options.soundLogCollectionMode
    );

    AudioService.playSfx(LoboSfx.MicListening);

    return this.sre.calibrate({
      durationMS: 4000, // default duration from rspeech.js
      soundLogFormat
    });
  }
}

export enum CalibrationListenerError {
  CalibrationError = 'CalibrationError'
}
