import { Column, Text } from 'common-ui';
import { SkillExampleBoxStyles } from './SkillExampleBox.styles';

export interface ISkillExampleBoxProps {
  boxCount: number;
  headline: string;
  content: string;
}

export function SkillExampleBox(props: ISkillExampleBoxProps) {
  const { boxCount, content, headline } = props;
  const styles = SkillExampleBoxStyles.build(boxCount);

  return (
    <Column style={styles.container}>
      <Text style={styles.headline}>{headline}</Text>
      <Text style={styles.content}>{content}</Text>
    </Column>
  );
}
SkillExampleBox.displayName = 'SkillExampleBox';
