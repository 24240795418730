import { ILevel, ILevelUpPosition } from '@lexialearning/lobo-common';
import {
  ContentProviderFactory,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { ProgramPositionFactory } from './ProgramPositionFactory';

export class LevelUpFactory {
  public static readonly displayName = 'LevelUpFactory';

  public static async levelUp(
    currentLevelId: string,
    contentProviderFactory: ContentProviderFactory
  ): Promise<ILevelUpPosition> {
    const levels = await this.getLevels(contentProviderFactory);
    const nextLevel = this.getNextLevel(currentLevelId, levels);

    return nextLevel
      ? ProgramPositionFactory.createLevelUpPosition(nextLevel)
      : ProgramPositionFactory.createProgramCompletePosition();
  }

  private static async getLevels(
    contentProviderFactory: ContentProviderFactory
  ): Promise<ILevel[]> {
    const provider = contentProviderFactory.create();
    const levelsUnsorted = await provider.loadByContentType<ILevel>(
      LoboContentType.Level,
      { nestingDepth: 1 }
    );

    return levelsUnsorted.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  private static getNextLevel(
    currentLevelId: string,
    levels: ILevel[]
  ): ILevel | undefined {
    const currentLevelIndex = levels.findIndex(l => l.sysId === currentLevelId);
    if (currentLevelIndex < 0) {
      throw new LexiaError(
        `Level ${currentLevelId} not found in levels array`,
        LevelUpFactory.displayName,
        LevelUpFactoryError.LevelNotFound
      ).withContext({
        currentLevelId,
        levels: levels.map(l => ({ levelId: l.sysId, title: l.title }))
      });
    }

    const isLastLevel = currentLevelIndex + 1 === levels.length;
    if (isLastLevel) {
      return undefined;
    }

    return levels[currentLevelIndex + 1];
  }
}

export enum LevelUpFactoryError {
  LevelNotFound = 'LevelNotFound'
}
