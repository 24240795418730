import {
  UserGlobalAction,
  UserGlobalActionLogout,
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import { ofType } from 'redux-observable';
import { from, merge, Observable, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { PredicateUtils } from 'utils/PredicateUtils';
import { AuthActionReLoginUser, AuthActionType, SsoActions } from '../redux';

export type ReLoginActionTypes =
  | AuthActionReLoginUser
  | UserGlobalActionLogoutSuccess;

export function reLoginUserEpic(
  action$: Observable<ReLoginActionTypes>
): Observable<UserGlobalActionLogout | SsoActions> {
  return action$.pipe(
    ofType(AuthActionType.ReLogin),
    mergeMap(action => {
      const { ssoActions } = (action as AuthActionReLoginUser).payload;

      return merge(
        of(UserGlobalAction.logout.request(LogoutReason.UserLogout)),
        action$.pipe(
          ofType(UserGlobalActionType.LogoutSuccess),
          mergeMap(() => from(ssoActions)),
          filter(PredicateUtils.isDefined),
          take(2)
        )
      );
    })
  );
}
reLoginUserEpic.displayName = 'reLoginUserEpic';
