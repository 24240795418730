import {
  ActiveComponentState,
  Border,
  Position
} from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { Align, Color, Font, Justify, ShadowStyle } from 'common-styles';
import { KeyNavHelper, Types } from 'common-ui';
import { LevelStatus } from 'curriculum-services';

export interface ILevelCertificateButtonStyle {
  buttonText: Types.TextStyle;
  button: Types.ViewStyle;
}

export class LevelCertificateButtonStyles {
  private static getBackgroundColor(
    activeComponentState: ActiveComponentState,
    levelStatus: LevelStatus
  ): string {
    if (levelStatus === LevelStatus.Incomplete) {
      return Color.Gray20;
    }

    return Color.getSetFor(activeComponentState).background;
  }

  private static getTextColor(levelStatus?: LevelStatus): string {
    switch (levelStatus) {
      case LevelStatus.Skipped:
        return Color.Gray20;
      case LevelStatus.Incomplete:
        return Color.Gray30;
      default:
        return Color.Gray70;
    }
  }

  public static readonly build = memoizeOne(
    (levelStatus: LevelStatus, activeComponentState: ActiveComponentState) => ({
      button: {
        alignItems: Align.Center,
        backgroundColor: LevelCertificateButtonStyles.getBackgroundColor(
          activeComponentState,
          levelStatus
        ),
        borderColor: Color.Transparent,
        borderRadius: 8,
        borderStyle: Border.Solid,
        borderWidth: 5,
        height: 39,
        justifyContent: Justify.Center,
        margin: 0,
        marginBottom: 7,
        minWidth: 40,
        padding: 0,
        width: 34,
        ...(levelStatus === LevelStatus.Completed && ShadowStyle.button),
        ...(KeyNavHelper.isKeyNav(activeComponentState) && {
          borderColor: Color.Black
        })
      },
      buttonText: {
        color: LevelCertificateButtonStyles.getTextColor(levelStatus),
        fontSize: Font.Size.small
      },
      levelCertNotification: {
        backgroundColor: Color.DeepBlue2,
        borderRadius: 5,
        height: 10,
        position: Position.Absolute,
        right: -5,
        top: -5,
        width: 10
      }
    })
  );
}
