import { Sfx as CuiSfx } from '@lexialearning/common-ui';

export { Sfx as CuiSfx } from '@lexialearning/common-ui';
export { ISfxConfig as ISfx } from 'storm/config';

export enum LoboSfx {
  AvatarExpansion = 'avatar_expansion',
  AvatarTimesUp = 'avatar_timesup',
  CallOut = 'callout',
  CantDo = 'cant_do',
  Carousel20 = 'carousel_20',
  Carousel40 = 'carousel_40',
  Carousel60 = 'carousel_60',
  Carousel80 = 'carousel_80',
  DropZone = 'drop_zone',
  Flare = 'flare',
  InstructionOff = 'instruction_off',
  InstructionOn = 'instruction_on',
  MicAvailable = 'mic_available',
  MicListening = 'mic_listening',
  Neutral = 'neutral',
  PokIntro = 'pok_intro',
  TabSelect = 'tab_select',
  Unheard = 'unheard',
  UserChoice2Cards = 'users_choice_cards_2',
  UserChoice3Cards = 'users_choice_cards_3'
}

// create a merged Sfx containing both Lobo and CUI Sfx
export const Sfx = { ...LoboSfx, ...CuiSfx };
export type Sfx = LoboSfx | CuiSfx;

export enum SfxActionType {
  Play = 'SFX.PLAY',
  PlayComplete = 'SFX.PLAY_COMPLETE'
}
