import {
  DropdownItemStyles,
  IDropdownItemStyleOverride
} from './DropdownItem.styles';
import {
  IPressableViewProps,
  PressableViewComponent
} from '../view/PressableView';
import {
  AccessibilityRole,
  ActiveComponentState,
  useActiveComponentState
} from '@lexialearning/common-ui';
import { Types } from 'common-ui/types';
import { PlainText } from '../text/PlainText';

export interface IDropdownItem<T = any> {
  accessibilityLabel?: string;
  label: T;
  id: string;
}

export interface IDropdownItemProps
  extends IDropdownItem,
    Omit<IPressableViewProps, 'id'> {
  isHighlighted?: boolean;
  selected: boolean;
  styleOverride?: IDropdownItemStyleOverride;
  onPress(e: Types.SyntheticEvent): void;
}

export function DropdownItem(props: IDropdownItemProps) {
  const {
    accessibilityLabel,
    id,
    isHighlighted = false,
    label,
    onPress,
    selected,
    styleOverride,
    ...restProps
  } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const isHovered = activeComponentState === ActiveComponentState.Hovered;

  const styles = DropdownItemStyles.build(
    isHighlighted,
    isHovered,
    styleOverride
  );

  return (
    <PressableViewComponent
      {...restProps}
      {...interactionHandlers}
      onPress={onPress}
      style={styles.row}
      testId={DropdownItem.displayName}
      tabIndex={-1}
      accessibilityLabel={accessibilityLabel}
      accessibilityState={{ selected }}
      accessibilityRole={AccessibilityRole.Option}
    >
      <PlainText
        style={styles.item}
        numberOfLines={1}
        testId={`dropdown-item-${id}`}
      >
        {label}
      </PlainText>
    </PressableViewComponent>
  );
}

DropdownItem.displayName = 'DropdownItem';
