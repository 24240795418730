import {
  IActivityPosition,
  PositionChangeType,
  TaskEvaluationResult,
  TaskTypeName
} from '@lexialearning/lobo-common';
import { PokRoundRecycling } from './PokRoundRecycling';
import { PositionDeterminer } from './PositionDeterminer';

/**
 * Determines next position for Assessment/Presentation of Knowledge units
 */
export class PokPositionDeterminer extends PositionDeterminer {
  public static readonly displayName = 'PokPositionDeterminer';

  private get isFirstPass(): boolean {
    return !this.context.parentUnitRecyclePass;
  }

  protected get maxAttempts(): number {
    return this.isFirstPass &&
      this.context.round.task?.taskType === TaskTypeName.SeeSpeak
      ? 2
      : 1;
  }

  protected determineChangeType(): PositionChangeType {
    const change = super.determineChangeType();
    const isUnitComplete = [
      PositionChangeType.EncounterCompletion,
      PositionChangeType.ActivityCompletion,
      PositionChangeType.LevelCompletion
    ].includes(change);

    const hasRoundsToRecycle =
      this.activityPositionBuilder.activeUnitPosition.recycling
        ?.roundIdsToRecycle.length || this.failedActiveRound();

    return this.isFirstPass && isUnitComplete && hasRoundsToRecycle
      ? PositionChangeType.UnitRecycling
      : change;
  }

  protected buildActivityPosition(
    changeType: PositionChangeType
  ): IActivityPosition {
    if (changeType !== PositionChangeType.UnitRecycling) {
      super.buildActivityPosition(changeType);
    }

    this.maybeRecycleRound(changeType);

    if (changeType === PositionChangeType.UnitRecycling) {
      this.activityPositionBuilder.recycle();
    }

    return this.activityPositionBuilder.activityPosition;
  }

  private maybeRecycleRound(changeType: PositionChangeType): void {
    if (
      !this.isFirstPass ||
      ![
        PositionChangeType.RoundCompletion,
        PositionChangeType.UnitRecycling
      ].includes(changeType) ||
      !this.context.lastAttemptMaybe
    ) {
      return;
    }

    const attempt = {
      isScored: this.context.round.task?.isScored!,
      result: this.context.lastAttemptMaybe.result,
      roundId: this.context.round.sysId
    };
    const prevRoundFull = this.context.roundNode.prev?.content;
    const prevRound = prevRoundFull
      ? {
          isScored: !!prevRoundFull.task?.isScored,
          roundId: prevRoundFull.sysId
        }
      : undefined;

    this.activityPositionBuilder.appendRoundIdsToRecycle(
      PokRoundRecycling.buildRecycleRoundIds(attempt, prevRound)
    );
  }

  private failedActiveRound(): boolean {
    return (
      this.context.lastAttemptMaybe?.result === TaskEvaluationResult.Incorrect
    );
  }
}
