import { IProfileStudent } from 'lexia-service/auth-api/auth-api-private.model';
import {
  ILexiaError,
  IStudentApiRequestBase,
  LexiaProduct
} from 'lexia-service/lexia-service-private.model';
import { IAttemptInfo } from './student-progress-api.model';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum LexiaPlacementId {
  Level = '42',
  Activity = '42',
  Encounter = '42'
}

export enum StudentProgressApiEndpoint {
  Login = '/v1/Login.php',
  Logout = '/v1/Logout.php',
  Update = '/v1/Update.php'
}

export enum StudentProgressPayloadType {
  BaseResponse = 'BaseResponse',
  Round = 'Round',
  Step = 'Step',
  UnitAdvance = 'UnitAdvance',
  UnitProgress = 'UnitProgress',
  UpdateResponse = 'UpdateResponse'
}

export enum StudentProgressAskArrayType {
  Ask = 'Ask'
}

export enum StudentProgressAskArrayFor {
  CompletedLevels = 'completedLevels'
}

/**
 * Shape of update payload to progress api
 */
export interface ISendStudentUpdateRequest extends IStudentApiRequestBase {
  /**
   * The attempt number, when retrying a request that was timed out.
   * @see {@link https://docs.google.com/document/d/1Ys_PdeolNJy7VeiRfQ210Q--5b2rBnzJ-qGWjaNfEiU/edit?ts=5d94ddcc|Network Resilience}
   */
  attemptNum: number;
  /**
   * The request number that ensures the server and client are synchronized in
   *  request and response sequence.
   * @see {@link https://docs.google.com/document/d/1Ys_PdeolNJy7VeiRfQ210Q--5b2rBnzJ-qGWjaNfEiU/edit?ts=5d94ddcc|Network Resilience}
   */
  requestNum: number;
  /**
   * array of combination of IUnitProgress or IUnitAdvance to send to myLexia
   */
  sendArray?: SendUnitLexia[];
  /**
   * array to ask for information from the API
   */
  askArray?: IAskLexia[];
}

export interface IProgressResponse
  extends IApiResponseBase<StudentProgressPayloadType.UpdateResponse> {
  pollArray: null;
  profile: IProfileStudent | null;
  sendErrorArray: ILexiaError[] | null;
  studentId: number;
  studentPropertyArray: null;

  // TODO: Update with real shape
  completedLevelsArray: ICompletedLevel[] | null;
}

/**
 * Types of updates allowed to send to myLexia: IUnitProgressLexia or IUnitAdvanceLexia.
 */
export type SendUnitLexia = IUnitProgressLexia | IUnitAdvanceLexia;

/**
 * Types of ask arrays allowed to send to myLexia
 */
export interface IAskLexia {
  __type__: StudentProgressAskArrayType;
  for: StudentProgressAskArrayFor;
}

export interface ICompletedLevel {
  title: string;
  uuid: string;
}

export interface IUnitAdvanceLexia {
  __type__: StudentProgressPayloadType.UnitAdvance;
  /**
   * The position you are moving the student to
   */
  position: IPositionUpdate;
  /**
   * Unit Advance Reason
   */
  reason: UnitAdvanceReason;
  strandKey: LexiaProduct;
}

/**
 * unitUUID is the only required IPosition field for update
 */
export interface IPositionUpdate extends Partial<IPosition> {
  /**
   * Act sysId
   */
  activityId: string;
  activityIndex: number;
  /**
   * level sysId
   */
  conceptId: string;
  conceptIndex: number;
  /**
   * encounter sysId
   */
  unitGroupId: string;
  unitGroupIndex: number;
  /**
   * unit sysId
   */
  unitId: string;
  unitIndex: number;
}

/**
 * Progress object to send to myLexia
 */
export interface IUnitProgressLexia {
  __type__: StudentProgressPayloadType.UnitProgress;
  /**
   * The activity has been completed (as part of completing this unit)
   */
  activityComplete: boolean;
  /**
   * Used when stepping down/up to/from instruction subunits
   */
  branchStep?: IStepLexia;
  /**
   * Whether the unit is completed
   */
  completed: boolean;
  /**
   * Seconds student spent since last update starting at unit load
   */
  duration: number;
  /**
   * only used when forking or joining
   */
  fork?: IStepLexia;
  /**
   * Whether all levels have been completed
   */
  programComplete: boolean;
  roundArray: IRoundLexia[];
  /**
   * sysId of the unit
   */
  unitId: string;
  /**
   * sysId of the unit
   */
  unitKey: string;
}

export interface IStepLexia {
  __type__: StudentProgressPayloadType.Step;
  modality: Modality;
  unitId: string;
  unitKey: string;
  unitName: string;
}

export enum Modality {
  /**
   * Forking unit
   */
  Standard = 1,
  /**
   * Not used in Lobo
   */
  Practice = 2,
  /**
   * Branching unit
   */
  Instruction = 3
}

export interface IRoundLexia {
  __type__: StudentProgressPayloadType.Round;
  attemptArray: IAttemptInfo[];
  /**
   * sysId of the Round
   */
  contentId: string;
  status: RoundLexiaResult;
}

export enum RoundLexiaResult {
  /**
   * If got right on second try
   */
  Assisted = 'assisted',
  Correct = 'correct',
  Excluded = 'excluded',
  Imminent = 'imminent',
  Wrong = 'wrong'
}

export enum UnitAdvanceReason {
  /**
   * Preliminary test that will decide where to place the student. N/A for Lobo ATM
   */
  Auto = 0,
  /**
   * myLexia override
   */
  Manual = 1,
  /**
   * What Lobo will use to inform myLexia of advancement
   */
  Progress = 2,
  /**
   * What lobo can use if desired to move student back
   */
  Regression = 3
}

export interface IStrandStatus {
  error: ILexiaError;
  strandKey: LexiaProduct;
  positionArray: IPosition[];
  weekMinutesPrescription: number | null;
  weekUnitsCompleted: number;
  completed: boolean | null;
  enabled: boolean;
}

export interface IPosition {
  /**
   * act sysId
   */
  activityId: string;
  /**
   * Act number
   */
  activityIndex: number;
  branchUnitKey: string | null;
  branchUnitName: string | null;
  /**
   * level sysId
   */
  conceptId: string;
  /**
   * level number
   */
  conceptIndex: number;
  error: ILexiaError;
  errorKinds: string | null;
  /**
   * Standard or Instruction
   */
  modality: Modality;
  struggling: boolean;
  /**
   * encounter sysId
   */
  unitGroupId: string;
  /**
   * encounter number
   */
  unitGroupIndex: number;
  /**
   * unit sysId
   */
  unitId: string;
  /**
   * i.e. standard, practice or instruction
   */
  /**
   * unit number
   */
  unitIndex: number;
  unitProgress: IUnitProgressLexia | null;
  activityStatus: ActStatus;
  lastProgressDate: string | null;
}

export enum ActStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

export interface IApiResponseBase<T extends string = 'BaseResponse'> {
  __type__: T;
  attemptNum: number | null;
  authToken: string | null;
  error: ILexiaError | null;
  nextExpectedRequestNum: number | null;
  requestNum: number | null;
  studentId: number | null;
}
