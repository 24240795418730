import { IMicsAvailableUpdate } from '@lexialearning/lobo-common/main-model/sre';
import isEqual from 'lodash/isEqual';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, switchMap } from 'rxjs/operators';
import {
  SreConfigAction,
  SreConfigActionType,
  SreConfigureSuccessAction,
  SreSetMicsAvailableAction
} from '../redux';
import { ISreDependencies } from './sre-epics.model';

export function availableMicsUpdateEpic(
  action$: Observable<SreConfigureSuccessAction>,
  _state$: StateObservable<unknown>,
  { sre }: ISreDependencies
): Observable<SreSetMicsAvailableAction> {
  return action$.pipe(
    ofType(SreConfigActionType.ConfigureSuccess),
    switchMap(() => sre.microphones.micsAvailableUpdate$),
    distinctUntilKeyChanged('micsAvailable', isEqual),
    map((micsAvailableUpdate: IMicsAvailableUpdate) => {
      // make serializable for redux serializableCheck
      const micsAvailable = micsAvailableUpdate.micsAvailable.map(m => ({
        ...m
      }));

      return SreConfigAction.setMicsAvailable({ micsAvailable });
    })
  );
}
availableMicsUpdateEpic.displayName = 'availableMicsUpdateEpic';
