export class StringUtils {
  /**
   * Convert 'camelCaseString' to sentence format: 'Camel case string'
   * @param camelString
   * @returns
   */
  public static camelToSentenceFormat(camelString: string): string {
    return (
      camelString
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        // uppercase the first character
        .replace(/^./, str => str.toUpperCase())
    );
  }
}
