import { IScreenplay } from '@lexialearning/lobo-common';
import { CalibrationIntroScreenplayBuilder } from 'feature-areas/transitions/builders/calibration';
import { ScreenplayAction } from 'screenplay';
import { StormService } from 'storm';
import {
  DeepLinkHelperOutputAction,
  DeepLinkObservableFactoryBaseWithLevelsLoading,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';

export interface ICalibrationDeepLinkDeps extends IDeepLinkDeps {
  stormService: StormService;
}
export type CalibrationDeepLinkOutputAction = DeepLinkHelperOutputAction;

export class CalibrationDeepLinkObservableFactory extends DeepLinkObservableFactoryBaseWithLevelsLoading<ICalibrationDeepLinkDeps> {
  public static readonly displayName = 'CalibrationDeepLinkObservableFactory';

  public static createFor(
    deps: ICalibrationDeepLinkDeps
  ): CalibrationDeepLinkObservableFactory {
    return new CalibrationDeepLinkObservableFactory(deps)
      .awaitLevelsThenLoadLevel()
      .playCalibrationIntroScreenplay();
  }

  constructor(public readonly deps: ICalibrationDeepLinkDeps) {
    super(deps);
  }

  private playCalibrationIntroScreenplay(): CalibrationDeepLinkObservableFactory {
    const screenplay = this.buildCalibrationIntroScreenplay();
    this.dispatches.push(ScreenplayAction.play({ screenplay }));

    return this;
  }

  private buildCalibrationIntroScreenplay(): IScreenplay {
    const { preparedScenes, stormService } = this.deps;

    return CalibrationIntroScreenplayBuilder.create()
      .showCalibrationScene(preparedScenes, stormService)
      .fadeInPageElements()
      .setInteractive()
      .animateScene().screenplay;
  }
}
