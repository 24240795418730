import { Border, Overflow, Position } from '@lexialearning/common-ui';
import { MIRROR_SVG_HEIGHT, MIRROR_SVG_WIDTH } from './Mirror.lx-svg';
import { Align, Color, Justify } from 'common-styles';

export class AvatarAndMirrorStyles {
  public static get() {
    return {
      name: {
        alignItems: Align.Center,
        position: Position.Absolute
      },
      avatar: {
        alignItems: Align.Center,
        justifyContent: Justify.Center,
        paddingBottom: 54,
        height: MIRROR_SVG_HEIGHT + 54,
        width: MIRROR_SVG_WIDTH
      },
      thumbnail: {
        backgroundColor: Color.Gold,
        borderColor: Color.Black,
        borderRadius: 79,
        borderStyle: Border.Solid,
        borderWidth: 2,
        height: 157,
        overflow: Overflow.Hidden,
        position: Position.Absolute,
        width: 157
      }
    };
  }
}
