import * as React from 'react';
import { Column } from 'common-ui';
import { DragItemType } from '../DragItemType';
import { DndDropTarget, DropTargetHelper } from '../drop-target';
import { RenderDragLayerItem } from '../render-drag-layer-item';
import { DndContainerStyles } from './DndContainer.styles';
import { withDragDropContext } from './withDragDropContext';
import { DndDragLayer } from './DndDragLayer';
import { ImportantForAccessibility } from '@lexialearning/common-ui';

export interface IDndContainerProps {
  children?: React.ReactNode;
  importantForAccessibility?: ImportantForAccessibility;
  renderItem?(
    itemType: DragItemType,
    itemProps: any
  ): React.ReactElement | null;
}

// Using a target in the background avoids the drop lag that occurs when a
// user drops an item not on a target - it does, however, mean that
// onEndDrag methods cannot check the didDrop value to see if an item was
// dropped on a task target, as didDrop will always be true
export const BackgroundTarget = DndDropTarget(
  Object.values(DragItemType),
  DropTargetHelper.createSpec({ useSfx: false })
)(Column);

export class DndContainerComponent extends React.PureComponent<IDndContainerProps> {
  public render() {
    const {
      importantForAccessibility,
      renderItem = RenderDragLayerItem,
      children
    } = this.props;
    const styles = DndContainerStyles.get();

    return (
      <BackgroundTarget
        dropTargetStyleOverride={styles.dropTargetStyleOverride}
        importantForAccessibility={importantForAccessibility}
        style={styles.container}
      >
        {children}
        <DndDragLayer renderItem={renderItem} itemType={undefined} />
      </BackgroundTarget>
    );
  }
}
export const DndContainer = withDragDropContext(DndContainerComponent);
