import memoizeOne from 'memoize-one';
import { Color, Justify } from 'common-styles';
import { ThemeSize } from 'theme';
import { Position } from '@lexialearning/common-ui';

export type DotRowPosition = 'left' | 'right';

export class GripDotsStyles {
  public static readonly build = memoizeOne(
    (dotRowPosition: DotRowPosition, themeSize) => {
      const isSmall = themeSize === ThemeSize.Small;

      return {
        dotColumn: {
          height: isSmall ? 27 : 30,
          justifyContent: Justify.SpaceBetween,
          width: isSmall ? 5 : 6
        },
        dotRow: {
          [dotRowPosition]: 20,
          justifyContent: Justify.SpaceBetween,
          position: Position.Absolute,
          width: isSmall ? 16 : 19
        },
        singleDot: {
          backgroundColor: Color.Gray10,
          borderRadius: 3,
          height: isSmall ? 5 : 6,
          width: isSmall ? 5 : 6
        }
      };
    }
  );
}
