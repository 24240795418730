import memoizeOne from 'memoize-one';
import { Color } from 'common-styles';
import { ThemeSize } from 'theme';
import { SpeechBubbleStyles } from '../shared/speech-bubble/SpeechBubble.styles';
import { STANDARD_SPEECH_BUBBLE_TEXT_SHARED } from '../speech-bubbles.model';

export class QuestionSpeechBubbleStyles {
  public static build = memoizeOne((themeSize?: ThemeSize) => {
    const width = SpeechBubbleStyles.getSpeechBubbleWidth(false, themeSize);

    return {
      container: {
        ...SpeechBubbleStyles.bubbleCommonStyles,
        backgroundColor: Color.NearWhite,
        marginTop: -15,
        paddingVertical: 0,
        width
      },
      text: STANDARD_SPEECH_BUBBLE_TEXT_SHARED
    };
  });
}
