import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { DeepLinkSubjectFactory } from '../DeepLinkSubjectFactory';
import {
  AncestorIndex,
  SubjectType
} from '../position-builder-deep-link.model';

export class DefaultDeepLinkSubjectFactory extends DeepLinkSubjectFactory {
  public static readonly displayName = 'DefaultDeepLinkSubjectFactory';

  /**
   * Figure out the deep link subject based on the number of ancestors.
   * If there are only 3, must be a main unit (Level, Activity, Encounter), etc.
   * Deep links to an instruction round and fork subunit have the same depth,
   * so we disambiguate by parent content type.
   */
  protected determineSubjectType(): SubjectType {
    switch (this.ancestors.length) {
      case AncestorIndex.MainUnit:
        return SubjectType.MainUnit;
      case AncestorIndex.MainRound:
        return SubjectType.MainRound;
      case AncestorIndex.InstructionSubunit:
        return SubjectType.InstructionSubunit;
      case AncestorIndex.InstructionRound:
      case AncestorIndex.ForkSubunit:
        return this.ancestors[AncestorIndex.InstructionRound - 1]
          .contentType === LoboContentType.Unit
          ? SubjectType.InstructionRound
          : SubjectType.ForkSubunit;
      case AncestorIndex.ForkRound:
        return SubjectType.ForkRound;
      case AncestorIndex.ForkedInstructionSubunit:
        return SubjectType.ForkedInstructionSubunit;
      case AncestorIndex.ForkedInstructionRound:
        return SubjectType.ForkedInstructionRound;
      default:
        return SubjectType.Unknown;
    }
  }

  protected determineContentType(subjectType: SubjectType): LoboContentType {
    return [
      SubjectType.MainUnit,
      SubjectType.ForkSubunit,
      SubjectType.ForkedInstructionSubunit,
      SubjectType.InstructionSubunit
    ].includes(subjectType)
      ? LoboContentType.Unit
      : [
          SubjectType.ForkRound,
          SubjectType.ForkedInstructionRound,
          SubjectType.InstructionRound,
          SubjectType.MainRound
        ].includes(subjectType)
      ? LoboContentType.Round
      : LoboContentType.Unknown;
  }

  protected buildExpectedAncestry(subjectType: SubjectType): LoboContentType[] {
    const expectedAncestry = [
      LoboContentType.Level,
      LoboContentType.Act,
      LoboContentType.Encounter,
      LoboContentType.Unit
    ];

    const appendWhen = (
      index: number,
      contentType: LoboContentType | LoboContentType[]
    ): void => {
      if (this.ancestors.length > index) {
        if (Array.isArray(contentType)) {
          expectedAncestry.push(...contentType);
        } else {
          expectedAncestry.push(contentType);
        }
      }
    };

    appendWhen(AncestorIndex.MainRound, LoboContentType.Round);

    if (
      [SubjectType.InstructionSubunit, SubjectType.InstructionRound].includes(
        subjectType
      )
    ) {
      appendWhen(AncestorIndex.InstructionSubunit, LoboContentType.Unit);
      appendWhen(AncestorIndex.InstructionRound, LoboContentType.Round);
    } else {
      appendWhen(AncestorIndex.ForkSubunit, [
        LoboContentType.UserChoice,
        LoboContentType.Unit
      ]);

      appendWhen(AncestorIndex.ForkRound, LoboContentType.Round);

      appendWhen(AncestorIndex.ForkedInstructionSubunit, LoboContentType.Unit);
      appendWhen(AncestorIndex.ForkedInstructionRound, LoboContentType.Round);
    }

    return expectedAncestry;
  }
}
