import {
  IActivityPosition,
  IEncounterNode,
  ITask
} from '@lexialearning/lobo-common';
import {
  IProgramNode,
  IRoundNode,
  IUnit
} from '@lexialearning/lobo-common/main-model/curriculum';
import { LexiaError } from '@lexialearning/utils';
import { ActivityPositionBuilder } from '../epics';
import { ProgramNodeHelper } from './ProgramNode.helper';
import { IUnitSubtree, UnitSubtreeFactory } from './UnitSubtreeFactory';

export class RoundPedigreeFactory {
  public static readonly displayName = 'RoundPedigreeFactory';

  /** *
   * Creates a tree of IProgramNodes where the root node is the currently active
   * round and its ancestors include all the way up to the Level.
   * If the currently active round is an instruction round, the grandparent
   * should be a standard unit.
   *
   * The active round's task is replaced with the given preparedTask
   *
   * Each node has links to siblings, children and a parent.
   *
   * @param activityPosition - active activity position
   * @param units - Array of (sub)units (order is irrelevant)
   * @param encounterNode - encounter node with ancestors up to the level node
   * @param preparedTask - Task content prepared for current round
   */
  public static create(
    activityPosition: IActivityPosition,
    encounterNode: IEncounterNode,
    units: IUnit[],
    preparedTask: ITask
  ): IRoundNode {
    const unitSubtree = UnitSubtreeFactory.create(activityPosition, units);
    const builder = new RoundPedigreeFactory(unitSubtree);

    const roundNode = builder
      .appendUnitSubtreeToEncounterNode(encounterNode)
      .findActiveRoundNode(activityPosition);

    return builder.replaceTask(roundNode, preparedTask);
  }

  private constructor(private readonly unitSubtree: IUnitSubtree) {}

  private appendUnitSubtreeToEncounterNode(
    encounterNode: IEncounterNode
  ): RoundPedigreeFactory {
    const mainUnitId = this.unitSubtree.mainUnitNode.content.sysId;
    const mainUnitIndex = encounterNode.children.findIndex(
      u => u.content.sysId === mainUnitId
    );

    if (mainUnitIndex < 0) {
      throw new LexiaError(
        `Unit id "${mainUnitId}" not found in active level`,
        RoundPedigreeFactory.displayName,
        RoundPedigreeFactoryError.TreeLacksActiveMainUnit
      ).withContext({ encounter: encounterNode.content, mainUnitId });
    }

    encounterNode.children[mainUnitIndex] = this.unitSubtree.mainUnitNode;
    this.wireUp(encounterNode);

    return this;
  }

  private wireUp(parent: IProgramNode): IProgramNode {
    ProgramNodeHelper.wireUp(parent);

    return parent;
  }

  private findActiveRoundNode(activityPosition: IActivityPosition): IRoundNode {
    const activityPositionBuilder =
      ActivityPositionBuilder.create(activityPosition);
    const activeRoundId = activityPositionBuilder.activeUnitPosition.roundId;
    const roundNode = this.unitSubtree.activeUnitNode.children.find(
      r => r.content.sysId === activeRoundId
    ) as IRoundNode;

    if (!roundNode) {
      throw new LexiaError(
        `Round id "${activeRoundId}" not found in active level`,
        RoundPedigreeFactory.displayName,
        RoundPedigreeFactoryError.TreeLacksActiveRound
      ).withContext({
        activityPosition,
        parentUnit: this.unitSubtree.activeUnitNode.content
      });
    }

    return roundNode;
  }

  private replaceTask(roundNode: IRoundNode, task: ITask): IRoundNode {
    roundNode.content = { ...roundNode.content, task };

    return roundNode;
  }
}

export enum RoundPedigreeFactoryError {
  TreeLacksActiveRound = 'TreeLacksActiveRound',
  TreeLacksActiveMainUnit = 'TreeLacksActiveMainUnit'
}
