import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { LexiaError } from '@lexialearning/utils';
import { LocalStorageService } from 'services/local-storage';
import { IProdDebugFlag, ProdDebugLocalStorageKey } from './prod-debug.model';
import { LoboLogItemCategory } from 'logging';

/**
 * Service intended for use in Production environment to allow for checking
 * localStorage setting of 'debug' flag (set via debug.html) as early as possible
 * in the app pipeline (ie, does not require waiting for customer/user properties
 * to load to check a value there) based on which debug telemetry can be enabled
 */
export class ProdDebugFlagService {
  public static readonly displayName = 'ProdDebugFlagService';

  public constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly logger: ILogger
  ) {}

  /**
   * Loads 'debug' value from localStorage, if it exists,
   * and verifies that it is not expired, logging findings and returning the
   * the debug.debug value only if not expired, otherwise returns false
   */
  public async shouldEnableDebug(): Promise<boolean> {
    const debugRaw = await this.loadFromStorage();
    if (!debugRaw) {
      return false;
    }

    try {
      const debugFlag: IProdDebugFlag = JSON.parse(debugRaw);

      if (debugFlag.expires < new Date().toISOString()) {
        await this.localStorageService.removeItem(
          ProdDebugLocalStorageKey.DebugKey
        );
        this.logger.log({
          category: LoboLogItemCategory.DebugFlagRemoved,
          loggingLevel: LoggingLevel.Info,
          payload: { debugFlag },
          summary: 'Expired debug flag removed'
        });

        return false;
      }

      this.logger.log({
        category: LoboLogItemCategory.DebugFlagActive,
        loggingLevel: LoggingLevel.Info,
        payload: { debugFlag },
        summary: 'Active debug flag encountered'
      });

      return debugFlag.debug;
    } catch (err) {
      this.logger.logError(
        new LexiaError(
          'Error determining whether to enable prod debug telemetry',
          ProdDebugFlagService.displayName,
          ProdDebugFlagServiceError.Parse
        )
          .withCause(err)
          .withContext({ debugRaw })
      );

      return false;
    }
  }

  private async loadFromStorage(): Promise<string | undefined> {
    try {
      return await this.localStorageService.getItem(
        ProdDebugLocalStorageKey.DebugKey
      );
    } catch (err) {
      this.logger.logError(
        new LexiaError(
          'Error getting debug value from local storage',
          ProdDebugFlagService.displayName,
          ProdDebugFlagServiceError.LocalStorage
        ).withCause(err)
      );
    }
  }
}

export enum ProdDebugFlagServiceError {
  LocalStorage = 'LocalStorage',
  Parse = 'Parse'
}
