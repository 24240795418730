import { taskAudioSupportEpic } from './audio-support/taskAudioSupport.epic';
import { loadTaskEpic } from './loadTask.epic';
import { feedbackEpic } from './phase-screenplays/feedback.epic';
import { solutionEpic } from './phase-screenplays/solution.epic';
import { showTaskDemoEpic } from './showTaskDemo.epic';
import { taskPhaseProgressionEpic } from './taskPhaseProgression.epic';

export const taskEpics = [
  feedbackEpic,
  loadTaskEpic,
  showTaskDemoEpic,
  solutionEpic,
  taskAudioSupportEpic,
  taskPhaseProgressionEpic
];
