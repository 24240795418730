// istanbul ignore file - code generated
import { Circle, ITextInputIconProps, Path, Svg } from 'common-ui';

export function UserIconSvg(props: ITextInputIconProps) {
  const { color } = props;

  return (
    <Svg
      data-test-id={UserIconSvg.displayName}
      height="27"
      viewBox="0 0 60 81"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle cx="30" cy="19.5" r="19.5" fill={color} />
      <Path
        d="M30.56 44.29c9.34 0 12.06-6.06 15.23-4.2C54.54 44.66 60 51.45 60 63v18H0V63c0-10.72 5.62-17.13 14.88-22.91 2.67-1.36 5.42 4.2 15.68 4.2z"
        fill={color}
      />
    </Svg>
  );
}
UserIconSvg.displayName = 'UserIconSvg';
