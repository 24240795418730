import {
  AccessibilityOrientation,
  AccessibilityRole
} from '@lexialearning/common-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Text } from 'common-ui';
import { TabButton } from 'common-ui/components/button/tab-button/TabButton';
import { AppState } from 'services';
import { ProfileSelector } from 'services/profile';
import { AccountSettingsTabListStyles } from './AccountSettingsTabList.styles';

export interface IAccountSettingsTabListProps {
  selectedTabId: AccountSettingsTabId;
  isStudent: boolean;
  tabPanelId: string;
  updateSelectedTab(tab: AccountSettingsTabId): void;
}

export enum AccountSettingsTabId {
  Account = 'account',
  StudentInfo = 'student-information',
  Privacy = 'student-privacy',
  License = 'license-agreement',
  Notices = 'notices'
}

export const TabIdToNameMap = {
  [AccountSettingsTabId.Account]: 'Account',
  [AccountSettingsTabId.StudentInfo]: 'Student Information',
  [AccountSettingsTabId.Notices]: 'Notices',
  [AccountSettingsTabId.Privacy]: 'Student Privacy',
  [AccountSettingsTabId.License]: 'License Agreement'
};

export class AccountSettingsTabListComponent extends React.PureComponent<IAccountSettingsTabListProps> {
  public static readonly displayName = 'AccountSettingsTabList';

  constructor(props: IAccountSettingsTabListProps) {
    super(props);

    this.renderTab = this.renderTab.bind(this);
  }

  private renderTab(tabId: AccountSettingsTabId) {
    const { selectedTabId, tabPanelId, updateSelectedTab } = this.props;
    const styles = AccountSettingsTabListStyles.get();

    return (
      <TabButton
        testId={tabId}
        selected={selectedTabId === tabId}
        key={tabId}
        onTabPress={updateSelectedTab}
        styleOverride={styles.tabButton}
        tabId={tabId}
        tabPanelId={tabPanelId}
      >
        <Text style={styles.buttonText}>{TabIdToNameMap[tabId]}</Text>
      </TabButton>
    );
  }

  public render() {
    const { isStudent } = this.props;
    const tabNames = Object.values(AccountSettingsTabId);
    const tabs = isStudent
      ? tabNames
      : tabNames.filter(tab => tab !== AccountSettingsTabId.StudentInfo);
    const styles = AccountSettingsTabListStyles.get();

    return (
      <Column
        accessibilityLabel="Settings Tabs."
        accessibilityRole={AccessibilityRole.TabList}
        ariaOrientation={AccessibilityOrientation.Vertical}
        style={styles.tabList}
      >
        {tabs.map(this.renderTab)}
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    isStudent: ProfileSelector.isStudent(state)
  };
}

export const AccountSettingsTabList = connect(mapStateToProps)(
  AccountSettingsTabListComponent
);

export const AccountSettingsTabListPrivates = { mapStateToProps };
