import { Value } from '@lexialearning/reactxp/dist/web/Animated';
import {
  AnimatableCSSProperty,
  Color,
  Justify,
  TransformOrigin,
  loboAnimated
} from 'common-styles';
import { CONSTANTS, Types } from 'common-ui';
import { Position } from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { SystemInfo } from 'utils';

export interface ITimerAnimatedStyles {
  container: Types.ViewStyle;
  timer: Types.ViewStyle;
  timerAnimated: Types.AnimatedViewStyle;
}

export interface ITimerAnimatedAnimations {
  timer: Types.Animated.CompositeAnimation;
}

export interface ITimerAnimatedValues {
  timerWidth: Value;
}

export class TimerAnimatedStyles {
  private styles: ITimerAnimatedStyles;
  private animatedValues: ITimerAnimatedValues;
  private animations: ITimerAnimatedAnimations;

  constructor(appWidth: number, duration: number) {
    this.animatedValues = {
      timerWidth: loboAnimated.createValue(CONSTANTS.BaseDimensions.Width)
    };

    this.animations = {
      timer: this.createTimerAnimation(duration)
    };

    this.styles = {
      container: {
        backgroundColor: Color.White,
        justifyContent: Justify.Start,
        position: Position.Absolute,
        /**
         * We've identified a positioning anomaly on native. The container does not
         * easily center on all screen sizes. We found that setting a left value equal
         * to ((appSize.w - CONSTANTS.BaseDimensions.Width) / 2) effectively centers
         * the container on all iPad models.
         */
        left: SystemInfo.isNative
          ? (appWidth - CONSTANTS.BaseDimensions.Width) / 2
          : 0,
        top: 0,
        transform: [{ scaleX: 1 }],
        width: CONSTANTS.BaseDimensions.Width,
        ...(!SystemInfo.isNative && {
          transformOrigin: TransformOrigin.Left
        })
      },
      timer: {
        backgroundColor: Color.LexiaPurple,
        height: 7
      },
      timerAnimated: {
        width: this.animatedValues.timerWidth
      }
    };
  }

  private createTimerAnimation(duration: number) {
    return loboAnimated.timing(
      AnimatableCSSProperty.Width,
      this.animatedValues.timerWidth,
      {
        duration,
        easing: loboAnimated.Easing.Linear(),
        toValue: 0
      }
    );
  }

  public build = memoizeOne((appWidth: number) => {
    const scaleX = appWidth / CONSTANTS.BaseDimensions.Width;
    this.styles.container.transform = [{ scaleX }];

    return this.styles;
  });

  public resetTimer() {
    this.animatedValues.timerWidth.setValue(CONSTANTS.BaseDimensions.Width);
  }

  public getAnimations() {
    return this.animations;
  }

  public buildTimerAnimation(duration: number) {
    this.animations = {
      timer: this.createTimerAnimation(duration)
    };

    return this.animations.timer;
  }
}
