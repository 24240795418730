import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IRound,
  IScreenplay,
  IScreenplayAction,
  TaskAudioSupport
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { DelayScreenplayActionFactory, ScreenplayBuilder } from 'screenplay';
import {
  IModeling,
  IModelingSection,
  ModelingHelper,
  ModelSection
} from './modeling';

export class TaskAudioSupportFactory {
  public static readonly displayName = 'TaskAudioSupportFactory';

  public static create(
    round: IRound,
    modeling: IModeling,
    mode: TaskAudioSupport
  ): IScreenplay {
    TaskAudioSupportFactory.validateArguments(round, modeling);

    const builder = ScreenplayBuilder.from(
      [
        TaskAudioSupportFactory.maybeIntroduction(round, mode),
        TaskAudioSupportFactory.maybeDirection(round, modeling, mode),
        TaskAudioSupportFactory.maybePresentation(round, modeling, mode),
        TaskAudioSupportFactory.maybeLookBack(round, mode)
      ],
      mode
    );

    return TaskAudioSupportFactory.insertDelayBetweenActions(builder)
      .screenplay;
  }

  private static validateArguments(round: IRound, modeling: IModeling): void {
    if (!round) {
      throw new LexiaError(
        'Cannot play preamble without an active round',
        TaskAudioSupportFactory.displayName,
        TasFactoryError.NoRound
      );
    }

    if (!modeling) {
      throw new LexiaError(
        'Invalid modeling for this round.',
        TaskAudioSupportFactory.displayName,
        TasFactoryError.NoModeling
      ).withContext({ round });
    }
  }

  private static maybeIntroduction(
    round: IRound,
    mode: TaskAudioSupport
  ): IScreenplay | undefined {
    return mode === TaskAudioSupport.Preamble ? round.introduction : undefined;
  }

  private static maybeDirection(
    round: IRound,
    modeling: IModeling,
    mode: TaskAudioSupport
  ): IScreenplay | undefined {
    return TaskAudioSupportFactory.maybeSection(
      ModelSection.Direction,
      modeling[ModelSection.Direction],
      round.directionAutoplay || mode === TaskAudioSupport.Direction,
      round.direction,
      mode
    );
  }

  private static maybePresentation(
    round: IRound,
    modeling: IModeling,
    mode: TaskAudioSupport
  ): IScreenplay | undefined {
    return TaskAudioSupportFactory.maybeSection(
      ModelSection.Presentation,
      modeling[ModelSection.Presentation],
      round.presentationAutoplay || mode === TaskAudioSupport.Presentation,
      round.presentation,
      mode
    );
  }

  private static maybeLookBack(
    round: IRound,
    mode: TaskAudioSupport
  ): IScreenplay | undefined {
    return mode === TaskAudioSupport.LookBack ? round.lookBack : undefined;
  }

  private static maybeSection(
    modelSection: ModelSection,
    modelingSection: IModelingSection,
    shouldPlayMain: boolean,
    screenplay: IScreenplay | undefined,
    mode: TaskAudioSupport
  ): IScreenplay | undefined {
    if (![TaskAudioSupport.Preamble, modelSection].includes(mode)) {
      return;
    }

    const main = shouldPlayMain ? screenplay : undefined;
    const modeling =
      modelingSection.autoplay || mode !== TaskAudioSupport.Preamble
        ? ModelingHelper.buildScreenplay(modelingSection)
        : undefined;

    return ScreenplayBuilder.from([main, modeling]).screenplay;
  }

  private static insertDelayBetweenActions(
    builder: ScreenplayBuilder,
    delayMs = 400
  ): ScreenplayBuilder {
    if (builder.screenplay.actions.length <= 1) {
      return builder;
    }

    // insert delays between sequential voiceover actions
    const delayAction = DelayScreenplayActionFactory.create(delayMs);
    const actionsWithDelays = builder.screenplay.actions.reduce(
      (acc: IScreenplayAction[], a: IScreenplayAction, idx: number) => {
        // don't add delay at beginning or if the last action in the accumulator or the current action are not of type voiceover
        if (
          idx === 0 ||
          acc[acc.length - 1].type !== LoboContentType.Voiceover ||
          a.type !== LoboContentType.Voiceover
        ) {
          return [...acc, a];
        }

        return [...acc, delayAction, a];
      },
      []
    );

    return ScreenplayBuilder.create(builder.screenplay.id).addActionList(
      actionsWithDelays as IScreenplayAction[]
    );
  }
}

export enum TasFactoryError {
  NoRound = 'NoRound',
  NoModeling = 'NoModeling'
}
