import { VideoRole } from './video.model';

enum ObjectFit {
  Cover = 'cover',
  Contain = 'contain'
}

enum VideoPosition {
  Fixed = 'fixed'
}

export class VideoStyles {
  private static readonly sharedStyles = {
    height: '100%',
    padding: 0,
    /* ⬇ fixed position needed to get progress bar to display in safari */
    position: VideoPosition.Fixed,
    width: '100%'
  };

  private static readonly styles = {
    shellCover: {
      ...this.sharedStyles,
      objectFit: ObjectFit.Cover
    },
    shellContain: {
      ...this.sharedStyles,
      maxWidth: '100%',
      objectFit: ObjectFit.Contain
    }
  };

  public static build(videoRole: VideoRole) {
    return videoRole === VideoRole.ShellContain
      ? this.styles.shellContain
      : this.styles.shellCover;
  }
}
