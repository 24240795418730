import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function LookBackIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={LookBackIconSvg.displayName} transform="translate(30 20)">
      {shadowColor && (
        <G id="lookBackShadow" transform="translate(0.25 0.25)" opacity="0.28">
          <Path
            id="line"
            d="M6.5,37.467a3.364,3.364,0,0,1-3.28-3.443V17.086A3.362,3.362,0,0,1,6.5,13.745a3.362,3.362,0,0,1,3.281,3.34V34.024A3.367,3.367,0,0,1,6.5,37.467Z"
            transform="translate(-3.22 -13.483)"
            fill={shadowColor}
          />
          <Path
            id="arrow"
            d="M31.65,27.286a2.645,2.645,0,0,1,0-4.621l8.283-4.706,8.313-4.7a2.76,2.76,0,0,1,2.717,0,2.66,2.66,0,0,1,1.359,2.311V34.387a2.661,2.661,0,0,1-1.362,2.306,2.76,2.76,0,0,1-2.714,0l-8.283-4.706Z"
            transform="translate(-21.892 -12.901)"
            fill={shadowColor}
          />
        </G>
      )}
      <G id="lookBackSvg">
        <Path
          id="line"
          d="M6.5,37.467a3.364,3.364,0,0,1-3.28-3.443V17.086A3.362,3.362,0,0,1,6.5,13.745a3.362,3.362,0,0,1,3.281,3.34V34.024A3.367,3.367,0,0,1,6.5,37.467Z"
          transform="translate(-3.22 -13.483)"
          fill={iconColor}
        />
        <Path
          id="arrow"
          d="M31.65,27.286a2.645,2.645,0,0,1,0-4.621l8.283-4.706,8.313-4.7a2.76,2.76,0,0,1,2.717,0,2.66,2.66,0,0,1,1.359,2.311V34.387a2.661,2.661,0,0,1-1.362,2.306,2.76,2.76,0,0,1-2.714,0l-8.283-4.706Z"
          transform="translate(-21.892 -12.901)"
          fill={iconColor}
        />
      </G>
    </G>
  );
}
LookBackIconSvg.displayName = 'LookBackIconSvg';
