import * as React from 'react';
import {
  IResponseSpeechBubbleAnimatedStyles,
  ResponseSpeechBubble
} from '../shared/speech-bubble/ResponseSpeechBubble';
import { ISpeechBubbleCommonProps } from '../speech-bubbles.model';
import {
  IPromptSpeechBubbleAnimatedStyles,
  PromptSpeechBubble
} from './PromptSpeechBubble';

export interface ISayItAfterSpeechBubblesAnimatedStyles {
  prompt: IPromptSpeechBubbleAnimatedStyles;
  response: IResponseSpeechBubbleAnimatedStyles;
}

export interface ISayItAfterSpeechBubblesProps
  extends ISpeechBubbleCommonProps {
  hasOnscreenCharacter: boolean;
  animatedStyles: ISayItAfterSpeechBubblesAnimatedStyles;
}

export function SayItAfterSpeechBubbles(
  props: ISayItAfterSpeechBubblesProps
): React.ReactElement {
  const { animatedStyles, hasOnscreenCharacter, ...restProps } = props;

  return (
    <>
      <PromptSpeechBubble
        animatedStyles={animatedStyles.prompt}
        hasTailLeft={hasOnscreenCharacter}
      />
      <ResponseSpeechBubble
        {...restProps}
        animatedStyles={animatedStyles.response}
        testId={SayItAfterSpeechBubbles.displayName}
      />
    </>
  );
}
SayItAfterSpeechBubbles.displayName = 'SayItAfterSpeechBubbles';
