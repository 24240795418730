import memoizeOne from 'memoize-one';
import { Justify } from 'common-styles';

export class TextOrderingStyles {
  public static readonly build = memoizeOne((hasSpotlight: boolean) => ({
    taskContainer: {
      flex: 1,
      justifyContent: hasSpotlight ? Justify.Start : Justify.Center
    }
  }));
}
