/* eslint-disable dot-notation, @typescript-eslint/dot-notation */
import {
  ButtonStyle as CuiButtonStyle,
  ImageStyle as CuiImageStyle,
  IViewStyle as ICuiViewStyle,
  Visibility
} from '@lexialearning/common-ui';
import {
  Animated as RxAnimated,
  Styles as RxStyles,
  Types as RxTypes
} from '@lexialearning/reactxp';
import { SystemInfo } from 'utils';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { AnimatableCSSProperty } from '../enums';

const HARDWARE_ACCELERATION_STYLES = {
  Default: {
    backfaceVisibility: Visibility.Hidden
  },
  Safari: {
    WebkitBackfaceVisibility: Visibility.Hidden,
    WebkitTransformStyle: 'preserve-3d'
  }
};

/*
 * Force browser to use hardware acceleration to fix issues with disappearing elements on large screens
 * (fixes LOBO-12168 and LOBO-12180)
 */
function fixDisappearingElements(styles: any) {
  return SystemInfo.isNative
    ? styles
    : {
        ...styles,
        ...(UserAgentUtils.isSafari()
          ? HARDWARE_ACCELERATION_STYLES.Safari
          : HARDWARE_ACCELERATION_STYLES.Default)
      };
}

function loboButtonStyle(style: RxTypes.ButtonStyle = {}): CuiButtonStyle {
  return fixDisappearingElements(style) as CuiButtonStyle;
}

function loboTextStyle(
  style: RxTypes.TextStyle = {}
): RxTypes.TextStyleRuleSet {
  const _style = fixDisappearingElements(style);

  return RxStyles.createTextStyle(_style as any);
}

function loboTextInputStyle(
  style: RxTypes.TextInputStyle = {}
): RxTypes.TextInputStyleRuleSet {
  return RxStyles.createTextInputStyle(fixDisappearingElements(style));
}

function loboImageStyle(style: CuiImageStyle = {}): CuiImageStyle {
  return fixDisappearingElements(style);
}

function loboViewStyle(style: ICuiViewStyle = {}): ICuiViewStyle {
  return fixDisappearingElements(style) as ICuiViewStyle;
}

function loboAnimatedTextStyle(
  style: RxTypes.AnimatedTextStyle = {}
): RxTypes.AnimatedTextStyleRuleSet {
  return RxStyles.createAnimatedTextStyle(style);
}

const LoboRxAnimated = {
  timing: (
    _type: AnimatableCSSProperty,
    value: RxTypes.AnimatedValue | RxTypes.InterpolatedValue,
    config: Partial<RxTypes.Animated.TimingAnimationConfig> &
      Omit<RxTypes.Animated.TimingAnimationConfig, 'useNativeDriver'>
  ): RxTypes.Animated.CompositeAnimation =>
    RxAnimated.timing(value, {
      useNativeDriver: false, // TEMP fix
      // Object.values(NativeAnimationStyle).includes(
      //   type as NativeAnimationStyle
      // ) ||
      // Object.values(NativeAnimationTransform).includes(
      //   type as NativeAnimationTransform
      // ),
      ...config
    })
};

const loboAnimated = { ...RxAnimated, ...LoboRxAnimated };
export class LoboAnimatedValue extends RxAnimated.Value {}

export interface ILoboAnimations {
  [key: string]: RxTypes.Animated.CompositeAnimation;
}

export interface ILoboAnimatedValues {
  [key: string]: RxTypes.AnimatedValue;
}

export {
  loboAnimatedTextStyle,
  loboButtonStyle,
  loboImageStyle,
  loboTextStyle,
  loboTextInputStyle,
  loboViewStyle,
  loboAnimated,
  fixDisappearingElements
};
