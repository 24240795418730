import { IStormAssetFolders, IStormAssetPath, IStormConfig } from 'storm';

export type ILoboStormConfig = IStormConfig<ILoboStormAssetFolders>;

export interface ILoboStormAssetFolders extends IStormAssetFolders {
  Backgrounds: IStormAssetPath;
  BackgroundOverlays: IStormAssetPath;
  Calibration: IStormAssetPath;
  CharacterAnimations: IStormAssetPath;
  Characters: IStormAssetPath;
  Encounter: IStormAssetPath;
  Home: IStormAssetPath;
  Introduction: IStormAssetPath;
  Level: IStormAssetPath;
  Placement: IStormAssetPath;
  TaskDemo: IStormAssetPath;
  UnitComplete: IStormAssetPath;
}

/**
 * These must match the values in the CMS
 */
export enum CharacterAnimationCategory {
  Introduction = 'Character.Introduction',
  Correct = 'Character.Correct',
  GestureTowards = 'Character.GestureTowards',
  Incorrect = 'Character.Incorrect',
  NeutralFeedback = 'Character.Neutral',
  Celebration = 'Character.Celebration',
  Clap = 'Character.Clap',
  Countdown = 'Character.Countdown',
  Idle = 'Character.Idle',
  Listening = 'Character.Listening',
  NoSignal = 'Character.NoSignal',
  TalkingGestures = 'Character.TalkingGestures',
  TooLoud = 'Character.TooLoud',
  TooSoft = 'Character.TooSoft'
}

export enum StandardSceneAnimationName {
  Intro = 'intro',
  Outro = 'outro',
  Idle = 'idle'
}

export enum SceneZIndex {
  Introduction = 25,
  Calibration = 50,
  Placement = 60,
  Home = 75,
  LevelIntro = 100,
  Level = 150,
  LevelComplete = 200,
  Encounter = 250,
  UnitComplete = 275,
  EncounterComplete = 300,
  TaskDemo = 325,
  ProgramComplete = 700
}
