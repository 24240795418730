import { UserGlobalAction } from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import { connect } from 'react-redux';

export interface IErrorLogoutProps {
  logout(): void;
}

// istanbul ignore next - trivial
export const withErrorLogout = connect(undefined, {
  logout: () => UserGlobalAction.logout.request(LogoutReason.Error)
});
