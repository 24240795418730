import {
  FeedbackStrategy,
  IRoundNode,
  IScreenplay,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { last, sample } from 'lodash';
import { Sfx } from 'audio/sfx';
import { ProgramMode, RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';

export class FeedbackScreenplayBuilder {
  public static readonly displayName = 'FeedbackScreenplayBuilder';

  public static createFor(context: RoundContext): FeedbackScreenplayBuilder {
    return new FeedbackScreenplayBuilder(context);
  }

  public readonly screenplay: IScreenplay;

  private constructor(private readonly context: RoundContext) {
    const feedbackStrategy = this.shouldUseNeutralFeedback()
      ? FeedbackStrategy.Neutral
      : context.parentUnit.feedbackStrategy;

    this.screenplay =
      feedbackStrategy === FeedbackStrategy.Normal
        ? this.createNormalScreenplay()
        : feedbackStrategy === FeedbackStrategy.Neutral
        ? this.createNeutralScreenplay()
        : this.createMinimalScreenplay();
  }

  /**
   * Neutral feedback if
   * - is not scored
   * - is incorrect last attempt during Onboarding
   */
  private shouldUseNeutralFeedback(): boolean {
    const { isRoundComplete, lastAttemptMaybe, programMode } = this.context;
    const { isScored } = this.context.getTask();
    const isOnboardingRoundCompleteIncorrect =
      isRoundComplete &&
      lastAttemptMaybe?.result === TaskEvaluationResult.Incorrect &&
      programMode === ProgramMode.Onboarding;

    return !isScored || isOnboardingRoundCompleteIncorrect;
  }

  private createNormalScreenplay(): IScreenplay {
    return ScreenplayBuilder.create(TaskFeedbackScreenplayId.Feedback)
      .addSfx(this.getSfx(this.context.lastAttemptMaybe?.result!))
      .addScreenplayList([this.getVoiceoverScreenplay()])
      .addDelay(TaskFeedbackDelay.Normal).screenplay;
  }

  private createNeutralScreenplay(): IScreenplay {
    return ScreenplayBuilder.create(TaskFeedbackScreenplayId.FeedbackNeutral)
      .addSfx(Sfx.Neutral)
      .addDelay(TaskFeedbackDelay.Neutral).screenplay;
  }

  private createMinimalScreenplay(): IScreenplay {
    return ScreenplayBuilder.create(TaskFeedbackScreenplayId.Feedback)
      .addSfx(this.getSfx(this.context.lastAttemptMaybe?.result!))
      .addDelay(TaskFeedbackDelay.Normal).screenplay;
  }

  private getSfx(result: TaskEvaluationResult): Sfx {
    return result === TaskEvaluationResult.Correct
      ? Sfx.Correct
      : result === TaskEvaluationResult.Incorrect
      ? Sfx.Incorrect
      : Sfx.Unheard;
  }

  /**
   * Should play correct VO:
   * - 25% of the time for standard
   * - only on last round for instruction units and placement rounds
   * - always for onboarding
   */
  private shouldPlayCorrectVoiceover(
    isInstructionRound: boolean,
    roundNode: IRoundNode
  ): boolean {
    const isLastRound = roundNode.next === undefined;
    const { programMode } = this.context;
    const isOnboarding = programMode === ProgramMode.Onboarding;
    const isPlacement = programMode === ProgramMode.Placement;
    if (roundNode.content.correctFeedbackSuppress) {
      return false;
    }

    return (
      isOnboarding ||
      ((isInstructionRound || isPlacement) && isLastRound) ||
      (!isInstructionRound && Math.random() < 0.25)
    );
  }

  private getVoiceoverScreenplay(): IScreenplay | undefined {
    const {
      act,
      round,
      roundNode,
      mainUnit: unit,
      isInstructionRound
    } = this.context;
    const feedback = unit.character?.feedback || act.character.feedback;

    switch (this.context.lastAttemptMaybe?.result) {
      case TaskEvaluationResult.Correct:
        if (round.feedback.correct) {
          return round.feedback.correct;
        }

        if (this.shouldPlayCorrectVoiceover(isInstructionRound, roundNode)) {
          return sample(feedback.correct);
        }

        return;

      case TaskEvaluationResult.Incorrect:
        return round.feedback.incorrect || sample(feedback.incorrect);

      default:
        throw new LexiaError(
          `Invalid evaluationResult ${this.context.lastAttemptMaybe?.result}`,
          FeedbackScreenplayBuilder.displayName,
          TaskFeedbackScreenplayBuilderError.InvalidEvaluationResult
        ).withContext({
          attempt: last(this.context.attempts),
          taskContent: this.context.getTask()
        });
    }
  }
}

export enum TaskFeedbackScreenplayId {
  Feedback = 'Feedback',
  FeedbackNeutral = 'Feedback.Neutral'
}

export enum TaskFeedbackDelay {
  Normal = 2000,
  Neutral = 500
}

export enum TaskFeedbackScreenplayBuilderError {
  InvalidEvaluationResult = 'InvalidEvaluationResult'
}
