import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { Services } from 'services/Services';
import { ILoggingDeps } from '../logging-epic.model';
import {
  ConfigActionInitSuccess as UniConfigActionInitSuccess,
  ConfigAction as UniConfigAction
} from '@lexialearning/utils-react';

export function initializeLoggerEpic(
  action$: Observable<UniConfigActionInitSuccess>,
  _state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    filter(UniConfigAction.init.success.match),
    tap(() => {
      const {
        appLoggerDependencies: { logger }
      } = deps;
      Services.logger = logger;
    }),
    ignoreElements()
  );
}
initializeLoggerEpic.displayName = 'initializeLoggerEpic';
