import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { ScreenplayActionPause, ScreenplayActionType } from '../redux';
import { IScreenplayDeps } from './screenplay-epic.model';

/**
 * Pause any active screenplay playback.
 * No action is dispatched post-process. The playEpic is expected
 * to dispatch Paused instead.
 */
export function pauseScreenplayEpic(
  action$: Observable<ScreenplayActionPause>,
  _: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<any> {
  return action$.pipe(
    ofType(ScreenplayActionType.Pause),
    tap(() => {
      deps.screenplayer.pause();
    }),
    ignoreElements()
  );
}
pauseScreenplayEpic.displayName = 'pauseScreenplayEpic';
