import {
  LanguageFrameTokenDecoration,
  SeeSpeakMode
} from '@lexialearning/lobo-common';
import memoizeOne from 'memoize-one';
import { Color, Font, InteractionState } from 'common-styles';
import { ThemeType } from 'theme';
import { FlexWrap } from '@lexialearning/common-ui';

export class LanguageFrameTokenStyles {
  public static readonly build = memoizeOne(
    (
      mode: SeeSpeakMode,
      themeType: ThemeType,
      decorations: LanguageFrameTokenDecoration[],
      feedbackInteractionState: InteractionState
    ) => {
      const isChallengeWord = decorations.includes(
        LanguageFrameTokenDecoration.ChallengeWord
      );
      const isUnderlined =
        isChallengeWord &&
        [SeeSpeakMode.Open, SeeSpeakMode.OpenScaffold].includes(mode);
      const isHidden =
        isUnderlined &&
        feedbackInteractionState !== InteractionState.Correct &&
        ![
          LanguageFrameTokenDecoration.Scaffolded,
          LanguageFrameTokenDecoration.Spoken
        ].some(d => decorations.includes(d));

      const isInstructionClosed =
        themeType === ThemeType.Instruction && mode === SeeSpeakMode.Closed;

      const instructionClosedFont = {
        fontFamily: Font.Family.semiBold,
        fontSize: Font.Size.xLarge
      };

      const textContainer = {
        borderBottomWidth: 2,
        borderColor: Color.Transparent
      };

      return {
        container: {
          flexWrap: FlexWrap.NoWrap,
          paddingHorizontal: isInstructionClosed ? 6 : 2.5
        },
        punctuation: {
          ...(isInstructionClosed && instructionClosedFont)
        },
        punctuationContainer: {
          ...textContainer
        },
        text: {
          color: Color.Text,
          ...(isInstructionClosed && instructionClosedFont)
        },
        textContainer: {
          ...textContainer,
          borderBottomColor: isUnderlined ? Color.Text : Color.Transparent
        },
        textHidden: isHidden && { color: Color.Transparent }
      };
    }
  );
}
