import memoizeOne from 'memoize-one';
import { Color, InteractionState } from 'common-styles';
import { ActiveComponentState, Overflow } from '@lexialearning/common-ui';

export interface IInteractiveContainerStylesOverride {
  height?: number;
  width?: number;
  padding?: number;
  borderColor?: string;
  borderWidth?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
  borderLeftWidth?: number;
  borderRightWidth?: number;
  borderRadius?: number;
  borderTopRadius?: number;
  borderBottomRadius?: number;
  borderLeftRadius?: number;
  borderRightRadius?: number;
  backgroundColor?: string;
  overflow?: Overflow;
}

export class InteractiveContainerStyles {
  public static readonly build = memoizeOne(
    (
      activeComponentState?: ActiveComponentState,
      interactionState?: InteractionState,
      styleOverride?: IInteractiveContainerStylesOverride
    ) => {
      const isHovered = activeComponentState === ActiveComponentState.Hovered;

      return {
        container: {
          backgroundColor: Color.NearWhite,
          borderColor: Color.getSetFor(
            isHovered ? InteractionState.Selected : interactionState
          ).border,
          borderRadius: 20,
          borderWidth: 5,
          opacity: interactionState === InteractionState.Disabled ? 0.4 : 1,
          ...styleOverride
        }
      };
    }
  );
}
