import { Storage } from '@lexialearning/reactxp';
import { filter, fromPairs, map, zip } from 'lodash';

export interface IItemsMap {
  [key: string]: string;
}

export class LocalStorageService {
  public async clear(): Promise<void> {
    return Storage.clear();
  }

  public async setItem(key: string, value: string): Promise<void> {
    return Storage.setItem(key, value);
  }

  public async setItems(items: IItemsMap): Promise<void> {
    await Promise.all(
      map(items, async (value, key) => this.setItem(key, value))
    );
  }

  public async getItem(key: string): Promise<string | undefined> {
    return Storage.getItem(key);
  }

  public async getItems(keys: string[]): Promise<IItemsMap> {
    const values = await Promise.all(map(keys, async key => this.getItem(key)));
    const pairs = zip(keys, values);
    const pairsWithDefinedValues = filter(pairs, ([_, value]) => !!value);

    return fromPairs(pairsWithDefinedValues);
  }

  public async removeItem(key: string): Promise<void> {
    return Storage.removeItem(key);
  }

  public async removeItems(keys: string[]): Promise<void> {
    await Promise.all(map(keys, async key => this.removeItem(key)));
  }
}
