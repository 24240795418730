import { Position } from '@lexialearning/common-ui';
import { Color, Font } from 'common-styles';

export class SmartModalStyles {
  private static readonly styles = {
    loginId: {
      bottom: 8,
      color: Color.Gray60,
      fontSize: Font.Size.xSmall,
      left: 10,
      position: Position.Absolute
    }
  };

  public static get() {
    return this.styles;
  }
}
