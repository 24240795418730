import { Position } from '@lexialearning/common-ui';
import { merge } from 'lodash';
import memoizeOne from 'memoize-one';

export class ModalCloseButtonStyles {
  public static readonly build = memoizeOne((styleOverride?: any) =>
    merge(
      {},
      {
        position: Position.Absolute,
        right: 10
      },
      styleOverride
    )
  );
}
