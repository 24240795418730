import { IViewProps, View } from '../../view/View';
import { RowStyles } from './Row.styles';

export function Row(props: IViewProps) {
  const { children, style, testId, ...viewProps } = props;

  return (
    <View
      testId={testId || Row.displayName}
      {...viewProps}
      style={RowStyles.build(style)}
    >
      {children}
    </View>
  );
}
Row.displayName = 'Row';
