import { Border } from '@lexialearning/common-ui';
import { Align, Color, Font, TextAlign } from 'common-styles';

export class CheatSheetModalStyles {
  private static readonly borderStyles = {
    borderColor: Color.Gray70,
    borderStyle: Border.Solid,
    borderWidth: 1
  };

  private static readonly textStyles = {
    fontSize: Font.Size.xSmall
  };

  private static readonly styles = {
    bottomBorder: {
      borderBottomWidth: CheatSheetModalStyles.borderStyles.borderWidth
    },
    cell1: {
      ...CheatSheetModalStyles.borderStyles,
      ...CheatSheetModalStyles.textStyles,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      padding: 10,
      textAlign: TextAlign.Left,
      width: 175
    },
    cell2: {
      ...CheatSheetModalStyles.borderStyles,
      ...CheatSheetModalStyles.textStyles,
      borderBottomWidth: 0,
      flex: 1,
      padding: 10,
      textAlign: TextAlign.Left
    },
    instructions: {
      ...CheatSheetModalStyles.textStyles,
      paddingHorizontal: 20,
      paddingVertical: 10,
      width: 700
    },
    table: {
      alignItems: Align.Stretch,
      marginRight: 1,
      padding: 20
    }
  };

  public static get() {
    return this.styles;
  }
}
