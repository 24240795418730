export enum LevelStatus {
  Completed = 'completed',
  Skipped = 'skipped',
  Incomplete = 'incomplete'
}

export enum LoadStatus {
  NotLoaded = 'NotLoaded',
  Loaded = 'Loaded',
  Loading = 'Loading'
}

export interface ILevelStatus {
  certificateViewed: boolean;
  levelNumber: number;
  status: LevelStatus;
}
