import {
  AppShellActionHideModal,
  AppShellActionType
} from 'feature-areas/shell';
import { AppState } from 'services';
import {
  AvatarEditorAction,
  AvatarEditorActionShowEditor,
  AvatarEditorActionTimerStart
} from '../AvatarEditor.action';
import {
  AvatarEditorActionType,
  AvatarTimerStatus
} from '../avatar-editor-redux.model';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import { Observable, filter, map } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { ProfileSelector } from 'services/profile';

export function timerStartEpic(
  action$: Observable<AppShellActionHideModal | AvatarEditorActionShowEditor>,
  state$: StateObservable<AppState>
): Observable<AvatarEditorActionTimerStart> {
  return action$.pipe(
    ofType(AppShellActionType.HideModal, AvatarEditorActionType.ShowEditor),
    filter(() => AvatarEditorSelector.getShouldShowEditor(state$.value)),
    filter(
      a =>
        a.type === AppShellActionType.HideModal ||
        ProfileSelector.hasCreatedAvatar(state$.value)
    ),
    filter(() =>
      [AvatarTimerStatus.Ready, AvatarTimerStatus.Paused].includes(
        AvatarEditorSelector.getTimerStatus(state$.value)
      )
    ),
    map(() => AvatarEditorAction.timerStart())
  );
}
timerStartEpic.displayName = 'timerStartEpic';
