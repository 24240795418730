import { IScreenplay } from '@lexialearning/lobo-common';
import { UserGlobalAction } from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import { StateObservable } from 'redux-observable';
import { Music } from 'audio/music';
import { LevelSelector, ProgramContextSelector } from 'curriculum-services';
import { LevelCompleteSceneAnimationName } from 'feature-areas/levels';
import { LevelIntroSceneAction } from 'feature-areas/level-intro/level-intro-scene/redux';
import {
  ProgramCompleteSceneAction,
  ProgramCompleteSceneAnimationName
} from 'feature-areas/program-complete';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { SceneName } from 'services/storm-lobo';
import { NumberUtils } from 'utils';
import {
  LevelIntroSceneScreenplayBuilder,
  LevelIntroScreenplayBuilder
} from '../../builders/levels';
import { TransitionScreenplayId } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

export class LevelCompleteToNextTransitionFactory {
  public static readonly displayName = 'LevelCompleteToNextTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): IScreenplay {
    return new LevelCompleteToNextTransitionFactory(state$, deps).screenplay;
  }

  private readonly screenplay: IScreenplay;

  private readonly builder: ScreenplayBuilder;

  private constructor(
    private readonly state$: StateObservable<unknown>,
    private readonly deps: IRoundToNextTransitionDeps
  ) {
    const position = ProgramContextSelector.getPosition(state$.value);
    const activeLevelNumber = LevelSelector.getLevelNumberMaybe(state$.value);
    const isExcelling =
      !!position.excellingLevelNumber &&
      activeLevelNumber === position.excellingLevelNumber;
    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.LevelCompleteToNext
    );

    if (isExcelling || position.isComplete) {
      this.buildProgramComplete();
    } else {
      this.buildLevelIntro();
    }

    this.screenplay = this.builder.screenplay;
  }

  private buildLevelIntro(): LevelCompleteToNextTransitionFactory {
    const welcomeMessageVo = LevelSelector.getWelcomeMessage(this.state$.value)
      ?.voiceover;
    const entryPromptVo = BootstrapContentSelector.getLevelScreenContent(
      this.state$.value
    )?.entryPrompt;

    this.builder
      .addStormAnimation(
        {
          name: LevelCompleteSceneAnimationName.Root.Outro,
          targetScene: SceneName.LevelComplete
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.LevelCompleteOutro })
      .addCallback(async () => {
        await this.deps.preparedScenes.levelIntroReady;
        this.deps.preparedScenes.levelComplete!.hide();
      })
      .addScreenplay(
        LevelIntroSceneScreenplayBuilder.createFor({
          isLevelToLevel: true,
          preparedScenes: this.deps.preparedScenes,
          welcomeMessageVo
        }).screenplay
      )
      .addCallback(async () => {
        await this.deps.preparedScenes.levelReady;
        const levelId = LevelSelector.getLevelId(this.state$.value);
        RouterService.history.replace(RouteBuilder.levels(levelId));
      })
      // Re-prepare the level intro scene after it has played, so it is ready to be used for
      // home to level transition if the user should hit the back button
      .addReduxAction(LevelIntroSceneAction.prepare({ isLevelToLevel: false }))
      .addDelay(1000)
      .addScreenplay(
        LevelIntroScreenplayBuilder.createFor({
          entryPromptVo,
          preparedScenes: this.deps.preparedScenes,
          shouldCalloutLevelCertificates: true
        }).screenplay
      );

    return this;
  }

  private buildProgramComplete(): LevelCompleteToNextTransitionFactory {
    const activeLevelNum = LevelSelector.getLevelNumber(this.state$.value);
    const levelNum = NumberUtils.toTwoDigitString(activeLevelNum - 1);
    this.builder
      .addReduxAction(ProgramCompleteSceneAction.prepare({ levelNum }))
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.programComplete());
      })
      .addCallback(async () => {
        const programCompleteScene =
          await this.deps.preparedScenes.programCompleteReady;
        programCompleteScene.show();
      })
      .addStormAnimation(
        {
          name: ProgramCompleteSceneAnimationName.Root.Intro,
          targetScene: SceneName.ProgramComplete
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.ProgramComplete }, { concurrent: true })
      // Delays are here to properly time the VO (at the time "Congratulations"
      // shows up on screen) and logout/refresh (when animation and SFX finishes)
      .addDelay(11500)
      .addScreenplay(
        BootstrapContentSelector.getProgramCompleteVO(this.state$.value)
      )
      .addDelay(10500)
      .addReduxAction(UserGlobalAction.logout.request(LogoutReason.UserLogout));

    return this;
  }
}
