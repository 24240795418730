import * as React from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { Row, View } from 'common-ui';
import {
  DndDragSource,
  DndDropTarget,
  DragItemType,
  DragSourceHelper,
  DropTargetHelper,
  IDropTargetProps
} from 'dnd';
import { SplitTargetHoverItemStyles } from './SplitTargetHoverItem.styles';

export enum HoverType {
  Before = 'before',
  After = 'after',
  None = 'none'
}

export interface ISplitTargetHoverItemProps {
  index: number;
  activeTargetIdx: number;
  onDrop?(
    targetIdx: number,
    sourceIdx: number,
    sourceItem: any,
    hoverType: HoverType
  ): void;
  onHover?(targetIdx: number, sourceIdx: number, hoverType: HoverType): void;
  hoverType?: HoverType;
}

export function splitTargetHoverItem(dragItemType: DragItemType) {
  return (Component: any) => {
    const DragSource = DndDragSource(
      dragItemType,
      DragSourceHelper.createSpec({ useSfx: false })
    )(Component);
    const DropTarget = DndDropTarget(
      dragItemType,
      DropTargetHelper.createSpec({
        canDrop(dropTargetProps: IDropTargetProps, monitor: DropTargetMonitor) {
          const { index } = dropTargetProps;

          return index !== monitor.getItem<any>().index;
        },
        useSfx: false
      })
    )(View);

    class SplitTargetHoverItem extends React.Component<ISplitTargetHoverItemProps> {
      constructor(props: ISplitTargetHoverItemProps) {
        super(props);

        this.handleHoverBefore = this.handleHoverBefore.bind(this);
        this.handleHoverAfter = this.handleHoverAfter.bind(this);
        this.handleDropBefore = this.handleDropBefore.bind(this);
        this.handleDropAfter = this.handleDropAfter.bind(this);

        this.state = {
          hoverType: HoverType.None
        };
      }

      public handleHoverBefore(targetIdx: number, sourceIdx: number) {
        const { onHover } = this.props;

        if (onHover) {
          onHover(targetIdx, sourceIdx, HoverType.Before);
        }
      }

      public handleHoverAfter(targetIdx: number, sourceIdx: number) {
        const { onHover } = this.props;

        if (onHover) {
          onHover(targetIdx, sourceIdx, HoverType.After);
        }
      }

      public handleDropBefore(
        targetIdx: number,
        sourceIdx: number,
        sourceItem: any
      ) {
        const { onDrop } = this.props;

        if (onDrop) {
          onDrop(targetIdx, sourceIdx, sourceItem, HoverType.Before);
        }
      }

      public handleDropAfter(
        targetIdx: number,
        sourceIdx: number,
        sourceItem: any
      ) {
        const { onDrop } = this.props;

        if (onDrop) {
          onDrop(targetIdx, sourceIdx, sourceItem, HoverType.After);
        }
      }

      public render() {
        const styles = SplitTargetHoverItemStyles.get();
        const { activeTargetIdx, onHover, onDrop, index, ...restProps } =
          this.props;

        return (
          <View>
            <Row style={styles.targetsContainer}>
              <DropTarget
                dropTargetStyleOverride={styles.target}
                defaultSfx={false}
                onHover={this.handleHoverBefore}
                onDrop={this.handleDropBefore}
                index={index}
                activeTargetIdx={activeTargetIdx}
              />
              <DropTarget
                dropTargetStyleOverride={styles.target}
                defaultSfx={false}
                onHover={this.handleHoverAfter}
                onDrop={this.handleDropAfter}
                index={index}
                activeTargetIdx={activeTargetIdx}
              />
            </Row>
            <DragSource {...restProps} index={index} />
          </View>
        );
      }
    }

    return SplitTargetHoverItem as typeof Component;
  };
}
