import { IScreenplay } from '@lexialearning/lobo-common';
import {
  LevelReactAnimationName,
  LevelSceneAnimationName
} from 'feature-areas/levels';
import { AppShellAction } from 'feature-areas/shell';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { LevelScreenplayId } from './level-screenplay-builders.model';

export interface ILevelIntroScreenplayDeps {
  preparedScenes: PreparedScenes;
  entryPromptVo: IScreenplay | undefined;
  shouldCalloutLevelCertificates?: boolean;
}

/**
 * Creates a screenplay for the animated intro to the level page
 * - running storm and react animations, and playing voiceovers
 *
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Home+to+Level+Transition
 */
export class LevelIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'LevelIntroScreenplayBuilder';

  public static createFor(
    deps: ILevelIntroScreenplayDeps
  ): LevelIntroScreenplayBuilder {
    return new LevelIntroScreenplayBuilder(deps);
  }

  private constructor(deps: ILevelIntroScreenplayDeps) {
    super(LevelScreenplayId.Intro);

    const { preparedScenes, entryPromptVo, shouldCalloutLevelCertificates } =
      deps;
    this.showLevelScene(preparedScenes)
      .fadeInReactElements()
      .playLevelIntroAnimation()
      .enableUtilityBar();

    if (shouldCalloutLevelCertificates) {
      this.calloutLevelCertificates();
    }

    this.playEntryPromptVo(entryPromptVo);
  }

  private showLevelScene(
    preparedScenes: PreparedScenes
  ): LevelIntroScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const levelScene = await preparedScenes.levelReady;
        levelScene.show();
        preparedScenes.placement?.hide();
        preparedScenes.home?.hide();
        preparedScenes.levelIntro?.hide();
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  private fadeInReactElements(): LevelIntroScreenplayBuilder {
    this.builder.addReactAnimation(LevelReactAnimationName.FadeIn, {
      concurrent: true
    });

    return this;
  }

  private playLevelIntroAnimation(): LevelIntroScreenplayBuilder {
    this.builder
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.Root.Intro,
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addDelay(200);

    return this;
  }

  private calloutLevelCertificates(): LevelIntroScreenplayBuilder {
    this.builder.addReduxAction(
      AppShellAction.setShouldCalloutLevelCertificates(true)
    );

    return this;
  }

  private playEntryPromptVo(
    entryPromptVo: IScreenplay | undefined
  ): LevelIntroScreenplayBuilder {
    this.builder.addScreenplay(entryPromptVo);

    return this;
  }
}
