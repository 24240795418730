import { Position } from '@lexialearning/common-ui';
import { Justify } from 'common-styles';

const cancelZoneStyle = {
  bottom: 0,
  position: Position.Absolute,
  top: 0,
  width: 200
};

export class VerticalOrderingContentStyles {
  private static readonly styles = {
    leftCancelZone: {
      ...cancelZoneStyle,
      left: -200
    },
    rightCancelZone: {
      ...cancelZoneStyle,
      right: -200
    },
    segmentsContainer: {
      justifyContent: Justify.Center,
      marginBottom: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
