import {
  UtilityButton,
  UtilityButtonIconType,
  UtilityButtonType
} from '../button';
import { Column } from '../layout';
import { ModalCloseButtonStyles } from './ModalCloseButton.styles';

export interface IModalCloseButtonProps {
  onClose?(): void;
  scale?: number;
  styleOverride?: any;
}

export function ModalCloseButton({
  onClose,
  scale,
  styleOverride
}: IModalCloseButtonProps) {
  const styles = ModalCloseButtonStyles.build(styleOverride);

  return (
    <Column style={styles}>
      <UtilityButton
        autoFocus={true}
        iconType={UtilityButtonIconType.Close}
        buttonType={UtilityButtonType.Basic}
        onPress={onClose}
        scale={scale}
        testId={ModalCloseButton.displayName}
      />
    </Column>
  );
}
ModalCloseButton.displayName = 'ModalCloseButton';
