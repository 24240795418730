import { Overflow } from '@lexialearning/common-ui';
import { Color, Font } from 'common-styles';

export class EncounterTabStyles {
  private static readonly styles = {
    badge: {
      borderColor: Color.Gray55,
      borderRadius: 35,
      borderWidth: 1,
      height: 70,
      marginLeft: 20,
      marginRight: 25,
      overflow: Overflow.Hidden,
      width: 70
    },
    badgeImage: {
      height: 70,
      width: 70
    },
    encounterLabel: {
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small,
      marginHorizontal: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
