import {
  ChoicesLayout,
  ITextWithVoiceover
} from '@lexialearning/lobo-common/main-model';
import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, Direction, Justify } from 'common-styles';
import { ThemeSize } from 'theme';

export class MultiSelectStyles {
  private static readonly styles = {
    choiceBox: {
      alignItems: Align.Stretch,
      backgroundColor: Color.NearWhiteTransparent70,
      borderRadius: 15,
      height: 310,
      minWidth: 600
    },
    choiceContainer: { alignItems: Align.Stretch },
    submitContainer: {
      height: 74,
      width: 286
    },
    text: {
      marginBottom: 20,
      maxHeight: 68,
      minHeight: 50
    }
  };

  public static readonly build = memoizeOne(
    (
      choicesLayout: ChoicesLayout,
      textPrompt: ITextWithVoiceover | undefined,
      themeSize: ThemeSize
    ) =>
      merge({}, MultiSelectStyles.styles, {
        choiceBox:
          choicesLayout === ChoicesLayout.Image
            ? { height: 316 }
            : {
                borderColor: Color.NearWhiteTransparent80,
                borderWidth: 2,
                marginTop: -1
              },
        container: {
          flex: 1,
          justifyContent: Justify.Start,
          marginTop: textPrompt ? -20 : 0
        },
        innerChoiceContainer: {
          alignItems:
            choicesLayout === ChoicesLayout.Image
              ? Align.Center
              : Align.Stretch,
          flexDirection:
            choicesLayout === ChoicesLayout.Image
              ? Direction.Row
              : Direction.Column
        },
        textPrompt: {
          container: {
            height: textPrompt ? 68 : 30,
            marginBottom: 20,
            maxWidth: themeSize === ThemeSize.Large ? 850 : 700
          }
        }
      })
  );
}
