import { merge } from 'lodash';
import {
  ButtonMeterAnimatedStylesDefaults,
  ButtonMeterAnimationHelper,
  IButtonMeterAnimations,
  IButtonMeterStyles
} from 'shared-components';

export class CalibrationButtonMeterAnimatedStyles {
  private readonly barWidth = 20;
  private readonly borderContainerSize = 99;

  private readonly initialValues = {
    ...ButtonMeterAnimatedStylesDefaults.initialValues,
    borderContainerWidth: this.borderContainerSize
  };

  private readonly meterOpenValues = {
    ...ButtonMeterAnimatedStylesDefaults.meterOpenValues,
    borderContainerWidth: 299
  };

  private readonly styles: IButtonMeterStyles = merge(
    {},
    ButtonMeterAnimatedStylesDefaults.styles,
    {
      outerContainer: {
        marginTop: 70,
        marginBottom: 117.5
      },
      borderContainer: {
        borderRadius: 52,
        height: this.borderContainerSize
      },
      vuMeterContainer: {
        flexShrink: 1,
        width: 230,
        marginBottom: 90,
        marginTop: 90,
        marginHorizontal: this.barWidth
      },
      vuMeterOverrides: {
        bar: {
          marginHorizontal: 0,
          marginVertical: 0,
          width: this.barWidth
        },
        caret: {
          right: -12
        }
      }
    }
  );

  private readonly animationHelper: ButtonMeterAnimationHelper;
  private readonly animations: IButtonMeterAnimations;

  constructor() {
    this.animationHelper = this.createHelper();

    const speed = 1;
    this.animations = this.createAnimations(speed);
  }

  private createHelper(): ButtonMeterAnimationHelper {
    return new ButtonMeterAnimationHelper(
      this.initialValues,
      this.meterOpenValues,
      this.styles
    );
  }

  private createAnimations(speed: number): IButtonMeterAnimations {
    return {
      showVuMeter: this.createShowVuMeterAnimation(speed),
      reset: this.createResetAnimation(speed)
    };
  }

  private createShowVuMeterAnimation(speed: number) {
    const innerElementsHalfFadeInValues = {
      delay: 275,
      duration: 200
    };

    return this.animationHelper.createShowVuMeterAnimation(
      innerElementsHalfFadeInValues,
      speed
    );
  }

  private createResetAnimation(speed: number) {
    return this.animationHelper.createResetAnimation(speed);
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
