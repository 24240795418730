import { FlexWrap } from '@lexialearning/common-ui';
import { Align, Direction, Justify } from 'common-styles';

export class LevelCertificateButtonsStyles {
  private static readonly styles = {
    buttonContainer: {
      alignSelf: Align.Stretch,
      flexDirection: Direction.Row,
      flexWrap: FlexWrap.Wrap,
      justifyContent: Justify.SpaceBetween,
      padding: 8,
      paddingTop: 14
    }
  };

  public static get() {
    return this.styles;
  }
}
