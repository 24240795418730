import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { EncounterSelector } from 'curriculum-services';
import {
  TransitionActionActToEncounter,
  TransitionActionType
} from 'feature-areas/transitions';
import { IEncounterEntryEventPayload, LoboLogItemCategory } from 'logging';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logEncounterEntryEpic(
  action$: Observable<TransitionActionActToEncounter>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(TransitionActionType.ActToEncounter),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.EncounterEntry),
    ignoreElements()
  );
}
logEncounterEntryEpic.displayName = 'logEncounterEntryEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<object, LoboLogItemCategory> {
  const encounterId = EncounterSelector.getEncounter(state).sysId;
  const context = helper.getContext(state);
  const payload: IEncounterEntryEventPayload = { encounterId };

  return {
    category: LoboLogItemCategory.EncounterEntry,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: `Encounter entry: ${context}`
  };
}
