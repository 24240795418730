import { Column } from '../layout';
import { IDropdownItem } from './DropdownItem';
import { DropdownStyles, IDropdownStyleOverride } from './Dropdown.styles';
import { IDropdownItemStyleOverride } from './DropdownItem.styles';
import { Popup } from '@lexialearning/reactxp';
import { Text } from '../text';
import { Types } from '../../types';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DropdownPopup } from './DropdownPopup';
import { DropdownHelper } from './Dropdown.helper';
import { PressableView, View } from '../view';
import {
  AccessibilityRole,
  useActiveComponentState
} from '@lexialearning/common-ui';
import { IButtonBaseProps } from '../button/ButtonBase';
import { KeyboardCode } from '@lexialearning/reactxp/dist/common/Types';
import { KeyboardEventName } from '@lexialearning/utils-react';

export interface IDropdownProps extends IButtonBaseProps {
  accessibilityLabel: string;
  id: string;
  /**
   * Accepts IDropdownItem[] | string[] | Enum
   * (type of { [key: string]: string } is to allow for an enum as the items value)
   */
  items: IDropdownItem[] | string[] | { [key: string]: string };
  placeholder?: string;
  selectedItemId?: string;
  styleOverride?: IDropdownStyleOverride & IDropdownItemStyleOverride;
  triggerClose?: boolean;
  onChange(id: string): void;
}

export function Dropdown(props: IDropdownProps) {
  const {
    accessibilityLabel,
    id,
    onChange,
    placeholder,
    selectedItemId,
    styleOverride,
    triggerClose,
    ...restProps
  } = props;

  const dropdownRef = useRef<any>();

  const [popupHighlightedItemId, setPopupSelectedItemId] =
    useState(selectedItemId);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const items = useMemo(
    () => DropdownHelper.formatItems(props.items),
    [props.items]
  );

  const styles = DropdownStyles.build(activeComponentState, styleOverride);

  const closePopup = useCallback(() => {
    Popup.dismiss(id);
  }, [id]);

  /* istanbul ignore next - Popup rendering not playing nice in specs */
  const onSelect = useCallback(
    (id: string) => {
      onChange(id);
      closePopup();
    },
    [closePopup, onChange]
  );

  /* istanbul ignore next - Popup rendering not playing nice in specs */
  const renderPopup = useCallback(
    () => (
      <DropdownPopup
        {...props}
        id={id}
        items={items}
        // Item currently selected in the dropdown
        selectedItemId={selectedItemId!}
        // Item to be initially highlighted in the popup (can be different than selected item,
        // eg, if user has keynav';d into the popup)
        highlightedItemId={popupHighlightedItemId!}
        onSelect={onSelect}
      />
    ),
    [id, items, popupHighlightedItemId, selectedItemId, onSelect, props]
  );

  /* istanbul ignore next - Popup rendering not playing nice in specs */
  const getPopupOptions = useCallback(
    (): Types.PopupOptions => ({
      dismissIfShown: true,
      getAnchor: () => dropdownRef.current,
      onDismiss: () => {
        setIsPopupOpen(false);
      },
      positionPriorities: ['bottom', 'top', 'right', 'left'],
      renderPopup
    }),
    [renderPopup]
  );

  const openPopup = useCallback(() => {
    const options = getPopupOptions();
    Popup.show(options, id);
    setIsPopupOpen(true);
  }, [id, getPopupOptions]);

  const handleKeyDown = (e: Types.KeyboardEvent) => {
    // These keys should open the popup and set highlighted,
    // not scroll the page, as is their default
    if (
      [
        KeyboardCode.Home,
        KeyboardCode.End,
        KeyboardCode.ArrowDown,
        KeyboardCode.ArrowUp
      ].includes(e.code as KeyboardCode)
    ) {
      e.preventDefault();
    }

    if (e.code === KeyboardCode.Tab && isPopupOpen) {
      if (popupHighlightedItemId) {
        onSelect(popupHighlightedItemId);
      } else {
        closePopup();
      }
    }
  };

  const handleKeyUp = useCallback(
    (e: Types.KeyboardEvent) => {
      interactionHandlers.onKeyUp(e);

      const newPopupHighlightedItemId = DropdownHelper.getHighlightedItemId(
        e,
        items,
        popupHighlightedItemId,
        isPopupOpen
      );

      if (!!newPopupHighlightedItemId) {
        if (newPopupHighlightedItemId !== popupHighlightedItemId) {
          setPopupSelectedItemId(newPopupHighlightedItemId);
        }
        if (!isPopupOpen) {
          openPopup();
        }
      }
    },
    [interactionHandlers, isPopupOpen, items, popupHighlightedItemId, openPopup]
  );

  const maybeSaveSelection = useCallback(
    (e: SyntheticEvent) => {
      // In browser tests this event was being received as 'keyup', but seems like
      // it should be 'keypress', so seems worth checking for either.
      const isKeyPress = [
        KeyboardEventName.Keyup,
        KeyboardEventName.Keypress
      ].includes(e.type as KeyboardEventName);

      if (
        isKeyPress &&
        !!popupHighlightedItemId &&
        popupHighlightedItemId !== selectedItemId
      ) {
        onChange(popupHighlightedItemId);
      }
    },
    [onChange, popupHighlightedItemId, selectedItemId]
  );

  const handlePress = useCallback(
    (e: SyntheticEvent) => {
      if (isPopupOpen) {
        maybeSaveSelection(e);
        closePopup();
      } else {
        openPopup();
      }
    },
    [closePopup, isPopupOpen, maybeSaveSelection, openPopup]
  );

  useEffect(() => {
    if (triggerClose) {
      closePopup();
    }
  }, [closePopup, triggerClose]);

  return (
    <Column style={styles.container}>
      <PressableView
        {...restProps}
        {...interactionHandlers}
        forwardedRef={dropdownRef}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onPress={handlePress}
        style={styles.input}
        testId={Dropdown.displayName}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole={AccessibilityRole.ComboBox}
        accessibilityState={{ expanded: isPopupOpen }}
        ariaControls={id}
        tabIndex={0}
      >
        <Text numberOfLines={1} style={styles.value}>
          {items.find(item => item.id === selectedItemId)?.label ?? placeholder}
        </Text>
        <View
          style={isPopupOpen ? styles.arrowUp : styles.arrowDown}
          testId="arrow"
        />
      </PressableView>
    </Column>
  );
}
Dropdown.displayName = 'Dropdown';
