import {
  TaskAudioSupport,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  GlossyButtonIconType,
  Types,
  UserControlButton,
  UserControlButtonSize
} from 'common-ui';
import { AppState, Services } from 'services';
import { RoundSelector } from 'services/curriculum-services';
import { TaskAction, TaskSelector } from 'task-components/core';
import {
  ControlPanelButtonAnimation,
  ControlPanelLayout,
  IUserControlInteractionState
} from '../redux/app-shell.model';
import { AppShellSelector } from '../redux/AppShell.selector';
import { AudioSupportStyles } from './AudioSupport.styles';
import { LookbackButton } from './LookbackButton';

export interface IAudioSupportProps {
  disabled: boolean;
  phase?: TaskPhase;
  hasLookBack: boolean;
  controlPanelLayout: ControlPanelLayout;
  userControlInteractionState: IUserControlInteractionState;
  onPlayAudioSupport(support: TaskAudioSupport): void;
}

export class AudioSupportComponent extends React.PureComponent<IAudioSupportProps> {
  public static readonly displayName = 'AudioSupport';

  private get isInteractive(): boolean {
    return this.props.phase === TaskPhase.Interactive;
  }

  constructor(props: IAudioSupportProps) {
    super(props);

    this.handleHelpPress = this.handleHelpPress.bind(this);
    this.handleRehearPress = this.handleRehearPress.bind(this);
    this.handleLookBackPress = this.handleLookBackPress.bind(this);
    this.registerDirectionsButtonAnimation =
      this.registerDirectionsButtonAnimation.bind(this);
    this.registerPresentationButtonAnimation =
      this.registerPresentationButtonAnimation.bind(this);
  }

  private handleHelpPress(): void {
    if (this.isInteractive) {
      this.props.onPlayAudioSupport(TaskAudioSupport.Direction);
    }
  }

  private handleRehearPress(): void {
    if (this.isInteractive) {
      this.props.onPlayAudioSupport(TaskAudioSupport.Presentation);
    }
  }

  private handleLookBackPress(): void {
    this.props.onPlayAudioSupport(TaskAudioSupport.LookBack);
  }

  private registerDirectionsButtonAnimation(
    animation: Types.Animated.CompositeAnimation
  ) {
    return this.registerAnimation(
      ControlPanelButtonAnimation.DirectionsCallout,
      animation
    );
  }

  private registerPresentationButtonAnimation(
    animation: Types.Animated.CompositeAnimation
  ) {
    return this.registerAnimation(
      ControlPanelButtonAnimation.PresentationCallout,
      animation
    );
  }

  private registerAnimation(
    animationName: string,
    animation: Types.Animated.CompositeAnimation
  ): () => void {
    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      { name: animationName, animation }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const {
      controlPanelLayout,
      disabled,
      hasLookBack,
      userControlInteractionState
    } = this.props;

    const styles = AudioSupportStyles.get();
    const isMinimizedLayout =
      controlPanelLayout === ControlPanelLayout.Minimized;

    const helpStyleOverride = isMinimizedLayout
      ? styles.userControlButtons.helpMinimized
      : styles.userControlButtons.help;
    const presentationStyleOverride = isMinimizedLayout
      ? styles.userControlButtons.replayMinimized
      : styles.userControlButtons.replay;
    const presentationButtonSize = isMinimizedLayout
      ? UserControlButtonSize.Small
      : undefined;
    const isLookbackVisible = hasLookBack && this.isInteractive;

    return (
      <>
        {/* Directions button */}
        <UserControlButton
          icon={GlossyButtonIconType.QuestionMark}
          size={UserControlButtonSize.Small}
          interactionState={userControlInteractionState.directions}
          styleOverride={helpStyleOverride}
          onPress={this.handleHelpPress}
          registerAnimation={this.registerDirectionsButtonAnimation}
          disabled={disabled}
          testId="directionsButton"
        />

        {/* Presentation button */}
        <UserControlButton
          icon={GlossyButtonIconType.Rehear}
          size={presentationButtonSize}
          interactionState={userControlInteractionState.presentation}
          styleOverride={presentationStyleOverride}
          onPress={this.handleRehearPress}
          registerAnimation={this.registerPresentationButtonAnimation}
          disabled={disabled}
          testId="presentationButton"
        />

        {/* LookBack button */}
        {hasLookBack && (
          <LookbackButton
            shouldShow={isLookbackVisible}
            interactionState={userControlInteractionState.lookback}
            onPress={this.handleLookBackPress}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    controlPanelLayout: AppShellSelector.getControlPanelLayout(state),
    disabled: AppShellSelector.isAudioSupportDisabled(state),
    hasLookBack: !!RoundSelector.getRoundMaybe(state)?.lookBack,
    phase: TaskSelector.getPhase(state),
    userControlInteractionState:
      AppShellSelector.getUserControlInteractionState(state)
  };
}

const mapDispatchToProps = {
  onPlayAudioSupport: (support: TaskAudioSupport) =>
    TaskAction.playAudioSupport(support)
};

export const AudioSupport = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioSupportComponent);

export const AudioSupportPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
