import * as React from 'react';
import { AnimatedView, Types } from 'common-ui';
import { Services } from 'services';
import { ThemeType, withTheme } from 'theme';
import { MeterMarkerHelper } from '../../MeterMarker.helper';
import { BarAnimatedStyles } from './Bar.animated-styles';
import {
  MeterMarkerAnimationName,
  MeterMarkerProgressStatus
} from '../../meterMarker.model';

export interface IBarProps {
  themeType: ThemeType;
  progress: MeterMarkerProgressStatus;
  index: number;
}

export class BarComponent extends React.PureComponent<IBarProps> {
  public static readonly displayName = 'Bar';

  private animatedStyles: BarAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: IBarProps) {
    super(props);

    const { themeType, progress, index } = props;
    this.animatedStyles = new BarAnimatedStyles(
      themeType,
      progress,
      index,
      true
    );
    this.unregisterAnimations = this.registerAnimations(index);
  }

  public componentDidUpdate(prevProps: IBarProps) {
    const { progress: prevProgress, themeType: prevThemeType } = prevProps;
    const { progress, index, themeType } = this.props;

    if (prevThemeType !== themeType) {
      this.animatedStyles = new BarAnimatedStyles(
        themeType,
        progress,
        index,
        false
      );
      this.registerAnimations(index);
      this.forceUpdate();
    }

    MeterMarkerHelper.maybeUpdateMarker(
      prevProgress,
      progress,
      this.animatedStyles.getAnimations()
    );
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(index: number): () => void {
    const { animateIntro } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: `${MeterMarkerAnimationName.BarIntro}-${index}`,
        animation: animateIntro
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const styles = this.animatedStyles.get();

    return (
      <AnimatedView
        // needed to add 'as Types.ViewStyle' here because skewX is not valid for ReactXp transforms
        // and therefore it is preferable not to extend our typing to generally allow it,
        // however, we have been using it for a while in this one case and it appears to be working ok here,
        // so it seems best for this to be left as-is, unless/until we find an issue with it
        style={styles.bar as Types.ViewStyle}
        animatedStyle={styles.barAnimated}
      />
    );
  }
}

export const Bar = withTheme(BarComponent);
