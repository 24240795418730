import { DynamicNotificationsActionFetch } from '@lexialearning/dynamic-notifications';
import {
  UserGlobalAction,
  UserGlobalActionLogout,
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  ILogger,
  LoggingLevel,
  LogoutReason,
  UserAction
} from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { AuthApi } from 'lexia-service/auth-api';
import { StudentProgressApi } from 'curriculum-services';
import { AppState, BootstrappingActionBootstrap } from 'services/bootstrapping';
import {
  CustomerAction,
  CustomerActionBootstrapSetup
} from 'services/customer';
import { ProfileSelector } from 'services/profile';
import { AuthAction, AuthActionReloadApp } from '../redux/Auth.action';
import {
  ILoggingConfig,
  LOGGER_CONFIG_KEY,
  LoboLogItemCategory
} from 'logging';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { AuthSelector } from '../redux';
import { SystemInfo } from 'utils';
import { Linking } from 'common-ui';
import { LexiaError } from '@lexialearning/utils';

export interface ILogoutDeps {
  authApi: AuthApi;
  configProvider: IConfigProvider;
  logger: ILogger;
  studentProgressApi: StudentProgressApi;
}

export type LogoutOutputActions =
  | UserGlobalActionLogoutSuccess
  | BootstrappingActionBootstrap
  | DynamicNotificationsActionFetch
  | CustomerActionBootstrapSetup
  | AuthActionReloadApp;

/**
 * We nav to the login page and log the user out (with the API).
 * In case of errors, we have to recreate all services so we trigger bootstrapping.
 *
 * The logoutSuccess action should trigger clearing of any user-specific state.
 * Note: Each feature and service area should be responsible for clearing its state
 * and/or re-initializing itself in response to logout success rather than
 * increasing coupling and complexity within this epic.
 */
export function logoutUserEpic(
  action$: Observable<UserGlobalActionLogout>,
  state$: StateObservable<AppState>,
  deps: ILogoutDeps
): Observable<LogoutOutputActions | void> {
  return action$.pipe(
    ofType(UserGlobalActionType.Logout),
    switchMap(async action => {
      const { logger, studentProgressApi } = deps;
      const logoutReason = action.payload;
      const isStudentLoggedIn = ProfileSelector.isStudentLoggedIn(state$.value);
      const sessionMinutes = getSessionMinutes(state$.value);

      logger.log({
        category: LoboLogItemCategory.LogoutRequested,
        loggingLevel: LoggingLevel.Info,
        payload: { isStudentLoggedIn, logoutReason, sessionMinutes },
        summary: 'Logout request received'
      });

      if (isStudentLoggedIn) {
        await studentProgressApi.reset();
        await studentProgressApi.logout(logoutReason);
      }

      return logoutReason;
    }),
    filter(() => {
      // The logoutUrl value may be defined for SSO users who originated their session
      // from the external SSO portal, and if so, the user should be returned there on logout.
      // The remaining actions here (in the mergeMap below), for web, do not need to be
      // dispatched as the user will have left the app.
      const href = AuthSelector.getLogoutUrlMaybe(state$.value);
      if (href) {
        if (SystemInfo.isNative) {
          Linking.openUrl(href).catch(
            // istanbul ignore next - TODO: figure out how to test
            error => {
              throw new LexiaError(
                'Error opening SSO link on native',
                logoutUserEpic.displayName,
                logoutUserEpicError.NativeSsoLinkError
              ).withContext({ error, href });
            }
          );
        } else {
          window.location.href = href;

          // ⬇ redirected away from app no need to continue to logout success.
          return false;
        }
      }

      return true;
    }),
    mergeMap((logoutReason: LogoutReason) => {
      const outputActions: LogoutOutputActions[] = [];

      const isLogRocketEnabled =
        deps.configProvider.getConfig<ILoggingConfig>(LOGGER_CONFIG_KEY)
          .logRocket.enabled;

      if (logoutReason === LogoutReason.Error || isLogRocketEnabled) {
        // Reload the app if logging out due to an error or if LogRocket is enabled
        // (LogRocket does not provide a way to stop running
        //  once it has started. You can start a new session,
        //  but not cancel all sessions. So we must restart
        //  the app in order to stop LogRocket from running.)
        outputActions.push(AuthAction.reloadApp());
      } else {
        // if no site id passed, will use the one found in storage
        outputActions.push(CustomerAction.bootstrapSetup(''));
        outputActions.push(UserGlobalAction.logout.success(logoutReason));
        // Uni action listened to in Uni epic in order to clear logging user data.
        // Dispatched within the Uni error flow if logoutReason is error
        // so only needed here.
        outputActions.push(UserAction.logOut.success());
      }

      return from(outputActions);
    })
  );
}
logoutUserEpic.displayName = 'logoutUserEpic';

/** Returns the length of the current session in minutes with up to 2 decimal places */
function getSessionMinutes(state: AppState) {
  const loginTime = AuthSelector.getLoginTime(state);
  const diffMs = loginTime && Date.now() - loginTime;
  const sessionMinutes = diffMs && Math.round((diffMs / 60000) * 100) / 100;

  return sessionMinutes;
}

export enum logoutUserEpicError {
  NativeSsoLinkError = 'NativeSsoLinkError'
}
