import { Column } from 'common-ui';
import { CurlyAboveTheEarsHairSvg } from './hair-style-svgs/CurlyAboveTheEarsHair.lx-svg';
import { HairColor, HairStyle } from 'services/profile/avatar.model';
import { HairColorOptions } from './hair-color-options/HairColorOptions';
import {
  HairStyleLabelMap,
  HijabColor,
  mapToHairHijabColor
} from './hair.model';
import { HairStyleOptionsStyles } from './HairStyleOptions.styles';
import { HijabColorOptions } from './hijab-color-options/HijabColorOptions';
import { HijabSvg } from './hair-style-svgs/Hijab.lx-svg';
import { LongCurlyHairSvg } from './hair-style-svgs/LongCurlyHair.lx-svg';
import { LongSmallBraidsSvg } from './hair-style-svgs/LongSmallBraids.lx-svg';
import { LongStraightHairSvg } from './hair-style-svgs/LongStraightHair.lx-svg';
import { LongWavyHairSvg } from './hair-style-svgs/LongWavyHair.lx-svg';
import { ProfileSelector } from 'services/profile';
import { ShortCurlyHairSvg } from './hair-style-svgs/ShortCurlyHair.lx-svg';
import { ShortStraightHairSvg } from './hair-style-svgs/ShortStraightHair.lx-svg';
import { ShortWavyHairSvg } from './hair-style-svgs/ShortWavyHair.lx-svg';
import { SquareButton, useHandleAvatarEditorButtonPress } from '../shared';
import { VeryShortHairSvg } from './hair-style-svgs/VeryShortHair.lx-svg';
import { useSelector } from 'react-redux';

export const HairStyleSvgs = [
  LongStraightHairSvg,
  LongWavyHairSvg,
  LongCurlyHairSvg,
  LongSmallBraidsSvg,
  ShortStraightHairSvg,
  ShortWavyHairSvg,
  ShortCurlyHairSvg,
  CurlyAboveTheEarsHairSvg,
  VeryShortHairSvg,
  HijabSvg
];

export function HairStyleOptions() {
  const { isHijabSelected, selectedHairColor, selectedHairStyle } = useSelector(
    state => {
      const avatar = ProfileSelector.getAvatarMaybe(state);

      return {
        isHijabSelected: avatar?.hairStyle === HairStyle.Hijab,
        selectedHairColor: avatar?.hairColor,
        selectedHairStyle: avatar?.hairStyle
      };
    }
  );

  const handlePress = useHandleAvatarEditorButtonPress();
  const handleSelect = (hairStyle: HairStyle) => handlePress({ hairStyle });

  const styles = HairStyleOptionsStyles.get();

  return (
    <>
      <Column style={styles.container} testId={HairStyleOptions.displayName}>
        {Object.values(HairStyle).map((hairStyle: HairStyle, idx: number) => {
          const isHijab = hairStyle === HairStyle.Hijab;
          const colorName = isHijab
            ? (mapToHairHijabColor<HijabColor>(
                selectedHairColor as HairColor
              ) as HijabColor)
            : (selectedHairColor as HairColor);
          const selected = hairStyle === selectedHairStyle;
          const HairSvg = HairStyleSvgs[idx];

          return (
            <SquareButton
              accessibilityLabel={HairStyleLabelMap.get(hairStyle)}
              key={hairStyle}
              onPress={() => handleSelect(hairStyle)}
              selected={selected}
            >
              <HairSvg colorName={colorName} />
            </SquareButton>
          );
        })}
      </Column>
      {isHijabSelected ? (
        <HijabColorOptions selectedColor={selectedHairColor} />
      ) : (
        <HairColorOptions selectedColor={selectedHairColor} />
      )}
    </>
  );
}
HairStyleOptions.displayName = 'HairStyleOptions';
