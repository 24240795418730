import { PredicateUtils } from '@lexialearning/utils';
import {
  ErrorHandlerAction,
  ErrorHandlerActionErrorEvent
} from '@lexialearning/utils-react';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import { SpinnerHandler } from 'spinner-handler';
import { ErrorShortCircuiter } from './ErrorShortCircuiter';
import { loboErrorModalMap } from './loboErrorModalMap';
import { SuppressorErrorModal } from './SuppressorError.modal';

export function handleUniErrorEventEpic(
  action$: Observable<ErrorHandlerActionErrorEvent>
): Observable<AppShellActionShowModal> {
  const suppressedUniErrorModal = new SuppressorErrorModal();

  return action$.pipe(
    filter(ErrorHandlerAction.errorEvent.match),
    tap(({ payload: err }) => {
      SpinnerHandler.reset();
      void ErrorShortCircuiter.record(err);
    }),
    filter(({ payload: err }) => suppressedUniErrorModal.match(err)),
    map(({ payload: err }) => loboErrorModalMap.get(err.standardCode)),
    filter(PredicateUtils.isDefined),
    map(id => AppShellAction.showModal({ id }))
  );
}
handleUniErrorEventEpic.displayName = 'handleUniErrorEventEpic';
