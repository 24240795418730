import { connect } from 'react-redux';
import { AppState } from 'services';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { ProfileSelector } from 'services/profile';
import { ModalRegistry } from 'shared-components';
import { SreSelector } from 'sre';
import { MicSelectionModal } from './MicSelectionModal';

// istanbul ignore next - trivial code, not worth testing
function mapStateToProps(state: AppState) {
  return {
    isStudent: ProfileSelector.isStudent(state),
    openingVO: BootstrapContentSelector.getSelectMicContent(state)!.voiceover,
    selectedMic: SreSelector.getSelectedMic(state)
  };
}

/**
 * This Modal is launched from the Student Info modal to allow
 * the user to manually change their microphone
 */
export const ChangeMicModal: any = connect(
  mapStateToProps,
  MicSelectionModal.mapDispatchToProps
)(MicSelectionModal);

ChangeMicModal.displayName = 'ChangeMicModal';

ChangeMicModal.ModalId = ModalRegistry.register({
  Component: ChangeMicModal,
  preventDismiss: true,
  title: 'Select your Microphone'
});
