import { ActionType, createAction } from 'typesafe-actions';
import { RoundActionType } from './round-redux.model';

export const RoundAction = {
  intro: createAction(RoundActionType.Intro)<IRoundActionIntroPayload | void>()
};

export interface IRoundActionIntroPayload {
  type: RoundIntroType;
}

export enum RoundIntroType {
  EncounterIntro = 'encounterIntro',
  OnboardingIntro = 'onboardingIntro',
  PlacementIntro = 'placementIntro',
  RoundIntro = 'roundIntro',
  /**
   * Coming from a subunit
   * (i.e. completed Instruction step or User Choice flow)
   */
  SubUnitOutro = 'subUnitOutro',
  UnitIntro = 'unitIntro'
}

export type RoundActions = ActionType<typeof RoundAction>;
export type RoundActionIntro = ActionType<typeof RoundAction.intro>;
