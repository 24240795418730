import { IScreenplay } from '@lexialearning/lobo-common';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { CalibrationOutroScreenplayBuilder } from '../builders/calibration';
import { OnboardingOrientationScreenplayBuilder } from '../builders/onboarding';
import { PlacementIntroScreenplayBuilder } from '../builders/placement';
import { TransitionScreenplayId } from '../transition.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICalibrationToPlacementTransitionScreenplayDeps {
  conclusionVo: IScreenplay;
  orientationVo: IScreenplay;
  preparedScenes: PreparedScenes;
  stormService: StormService;
  targetRoundId: string;
  targetUnitId: string;
  welcomeVo: IScreenplay;
}

export class CalibrationToPlacementTransitionScreenplayFactory {
  public static readonly displayName =
    'CalibrationToPlacementTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: ICalibrationToPlacementTransitionScreenplayDeps
  ): CalibrationToPlacementTransitionScreenplayFactory {
    return new CalibrationToPlacementTransitionScreenplayFactory(deps);
  }

  private constructor(deps: ICalibrationToPlacementTransitionScreenplayDeps) {
    const {
      conclusionVo,
      orientationVo,
      preparedScenes,
      stormService,
      targetRoundId,
      targetUnitId,
      welcomeVo
    } = deps;

    this.screenplay = ScreenplayBuilder.create(
      TransitionScreenplayId.CalibrationToPlacement
    )
      .addScreenplay(
        CalibrationOutroScreenplayBuilder.create()
          .disableUtilityBar()
          .fadeOutReactElements()
          .playSceneOutro().screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.onboardingOrientation());
      })
      .addScreenplay(
        OnboardingOrientationScreenplayBuilder.create()
          .showPlacementScene(preparedScenes, stormService)
          .centerCharacter()
          .attachCharacter(preparedScenes)
          .playOrientationIntroVo(welcomeVo)
          .showAndCalloutButtons()
          .animateCharacterButtonGesture()
          .playMicAvailableSfx()
          .playOrientationVo(orientationVo)
          .playConclusionVo(conclusionVo).screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(
          RouteBuilder.placementRounds(targetUnitId, targetRoundId)
        );
      })
      .addScreenplay(
        PlacementIntroScreenplayBuilder.create()
          .setStandardControlPanelLayout()
          .dispatchPlacementRoundIntro().screenplay
      ).screenplay;
  }
}
