import { TaskActionType } from './task-redux.model';
import { TaskActions } from './Task.action';

export function selectedChoicesReducer(
  state: string[] | undefined,
  action: TaskActions
): string[] | undefined {
  switch (action.type) {
    case TaskActionType.ClearSelectedChoices:
      return [];

    case TaskActionType.SetSelectedChoice:
      return [action.payload.choice];

    case TaskActionType.ToggleSelectedChoice:
      const selectedChoices = state
        ? state.includes(action.payload.choice)
          ? state.filter(c => c !== action.payload.choice)
          : [...state, action.payload.choice]
        : [action.payload.choice];

      return selectedChoices;

    default:
      return state;
  }
}
