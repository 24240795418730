import * as React from 'react';
import { compose } from 'redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { AnimatedColumn } from '../layout';
import { withGlobalDisabled } from '../withGlobalDisabled.hoc';
import {
  IModalAnimations,
  IModalStyleOverride,
  ModalAnimatedStyles
} from './Modal.animated-styles';
import { ConnectedComponent } from 'react-redux';
import { PressableView } from '../view';
import { AccessibilityRole } from '@lexialearning/common-ui';

export interface IModalProps extends ISfxProps {
  AnchorComponent?: React.ComponentType | ConnectedComponent<any, any>;
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  isError: boolean;
  isVisible: boolean;
  styleOverride?: IModalStyleOverride;
  /**
   * Used to suppress the sound effect when modal is displayed or another modal
   * replaces the existing one. For example when you need to play a custom audio
   */
  suppressOpeningSfx?: boolean;
  /**
   * Used to suppress the sound effect when modal is closed. For example when
   * you need to play a custom audio
   */
  suppressClosingSfx?: boolean;
  onRequestClose?(): void;
}

export class ModalComponent extends React.PureComponent<IModalProps> {
  public static displayName = 'Modal';

  private readonly modalAnimatedStyles: ModalAnimatedStyles;

  private readonly animations: IModalAnimations;

  constructor(props: IModalProps) {
    super(props);

    this.modalAnimatedStyles = new ModalAnimatedStyles();
    this.animations = this.modalAnimatedStyles.getAnimations();

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public componentDidMount() {
    const { isVisible } = this.props;

    if (isVisible) {
      this.show();
    }
  }

  public componentDidUpdate(prevProps: IModalProps) {
    const { id, isError, isVisible, playSfx, suppressOpeningSfx } = this.props;
    const shouldShow = !prevProps.isVisible && isVisible;
    const shouldHide = prevProps.isVisible && !isVisible;
    const isNewModal = prevProps.id !== id;

    if (shouldShow) {
      this.show();
    } else if (shouldHide) {
      this.hide();
    } else if (isNewModal && !suppressOpeningSfx) {
      playSfx(isError ? Sfx.Error : Sfx.PopupOn);
    }
  }

  private handleKeyDown(e: KeyboardEvent) {
    const { disabled, onRequestClose } = this.props;
    if (!disabled && e.code === 'Escape' && onRequestClose) {
      onRequestClose();
    }
  }

  private show() {
    const { isError, playSfx, suppressOpeningSfx } = this.props;

    this.animations.showModal.start();
    if (!suppressOpeningSfx) {
      playSfx(isError ? Sfx.Error : Sfx.PopupOn);
    }
    if (window?.addEventListener && this.props.onRequestClose) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  private hide() {
    const { playSfx, suppressClosingSfx } = this.props;

    this.animations.hideModal.start();
    if (!suppressClosingSfx) {
      playSfx(Sfx.PopupOff);
    }

    if (window?.removeEventListener && this.props.onRequestClose) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  public render() {
    const {
      AnchorComponent,
      children,
      isVisible,
      onRequestClose,
      styleOverride
    } = this.props;
    if (!isVisible) {
      return null;
    }

    const styles = this.modalAnimatedStyles.build(styleOverride);

    return (
      <PressableView
        restrictFocusWithin
        style={styles.container}
        onPress={onRequestClose}
      >
        <AnimatedColumn
          style={styles.overlay}
          animatedStyle={styles.overlayAnimated}
        />
        {AnchorComponent && <AnchorComponent />}
        <AnimatedColumn
          style={styles.modal}
          animatedStyle={styles.modalAnimated}
          accessibilityRole={AccessibilityRole.Dialog}
          stopPressPropagation
        >
          {children}
        </AnimatedColumn>
      </PressableView>
    );
  }
}

export const Modal = compose(withSfx, withGlobalDisabled)(ModalComponent);
