import memoizeOne from 'memoize-one';

export class ImageChoicesContainerStyles {
  public static readonly build = memoizeOne(
    (choicesCount: number, isInstruction: boolean) => ({
      choiceContainer: {
        height: isInstruction ? 178 : 213,
        marginHorizontal: choicesCount === 2 ? 13 : choicesCount === 3 ? 7 : 0,
        width: isInstruction ? 178 : 213
      }
    })
  );
}
