/* eslint-disable max-classes-per-file */
import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { StandardSceneAnimationName } from 'services/storm-lobo';

export enum HomeAnimationCategory {
  Root = 'Home.Root',
  LevelMeter = 'Home.LevelMeter'
}

export class HomeSceneAnimationName {
  public static readonly Root = {
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro
  };

  public static readonly LevelMeter = {
    buildIdle(levelNumber: number) {
      return `level_${levelNumber}_idle`;
    },
    buildIntro(levelNumber: number) {
      return `level_${levelNumber}_intro`;
    },
    buildStart(levelNumber: number) {
      return `level_${levelNumber}_start`;
    }
  };

  public static readonly LevelMeterSparkles = {
    Intro: StandardSceneAnimationName.Intro
  };
}

export class HomeSceneElementName {
  public static buildCharacter(charIndex: number) {
    return `character_${charIndex}`;
  }

  public static readonly LevelMeter = 'level_meter';

  public static readonly LevelMeterSparkles = 'level_meter_sparkles';
}

export interface IHomeStormAssets {
  CharacterAnimations: IAnimationDefinition[];
}

export const homeCharacterPositionsMap = new Map<string, string>([
  [HomeSceneElementName.buildCharacter(1), 'sarika'],
  [HomeSceneElementName.buildCharacter(2), 'annie'],
  [HomeSceneElementName.buildCharacter(3), 'farah'],
  [HomeSceneElementName.buildCharacter(4), 'juan'],
  [HomeSceneElementName.buildCharacter(5), 'mei'],
  [HomeSceneElementName.buildCharacter(6), 'abshir'],
  [HomeSceneElementName.buildCharacter(7), 'klaus'],
  [HomeSceneElementName.buildCharacter(8), 'javier'],
  [HomeSceneElementName.buildCharacter(9), 'yi'],
  [HomeSceneElementName.buildCharacter(10), 'isko'],
  [HomeSceneElementName.buildCharacter(11), 'ximena'],
  [HomeSceneElementName.buildCharacter(12), 'gabriela'],
  [HomeSceneElementName.buildCharacter(13), 'lela'],
  [HomeSceneElementName.buildCharacter(14), 'nadia'],
  [HomeSceneElementName.buildCharacter(15), 'esther'],
  [HomeSceneElementName.buildCharacter(16), 'rami'],
  [HomeSceneElementName.buildCharacter(17), 'sasha']
]);
