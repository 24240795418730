import memoizeOne from 'memoize-one';
import { Color, Font, loboTextInputStyle } from 'common-styles';
import { Types } from '../../../types';
import { Position } from '@lexialearning/common-ui';

export interface ITextInputStylesOverride {
  input?: Types.TextInputStyle;
  errorBox?: Types.ViewStyle;
  iconView?: Types.ViewStyle;
}

const ICON_SIZE = 60;
export class TextInputStyle {
  private static readonly inputStyle = {
    base: {
      backgroundColor: Color.NearWhiteTransparent80,
      borderRadius: 8,
      color: Color.Text,
      fontFamily: Font.Family.regular,
      fontSize: Font.Size.small,
      height: ICON_SIZE,
      marginBottom: 10,
      marginTop: 10,
      paddingHorizontal: 10,
      width: 440
    },
    errorText: {
      color: Color.DarkRed
    },
    hasIcon: {
      paddingLeft: ICON_SIZE
    },
    isFocused: {
      backgroundColor: Color.NearWhite
    },
    isSecure: {
      paddingRight: ICON_SIZE
    }
  };

  private static readonly styles = {
    errorArrow: {
      borderColor: Color.Transparent,
      borderLeftColor: Color.NearWhite,
      borderWidth: 10,
      position: Position.Absolute,
      right: -19
    },
    errorBox: {
      backgroundColor: Color.NearWhite,
      borderRadius: 8,
      left: -191,
      minHeight: ICON_SIZE,
      paddingHorizontal: 6,
      paddingVertical: 4,
      position: Position.Absolute,
      width: 171
    },
    errorText: {
      color: Color.DarkRed,
      fontSize: Font.Size.xSmall
    },
    errorTitle: {
      color: Color.DarkRed,
      fontSize: Font.Size.small
    },
    iconView: {
      height: ICON_SIZE,
      left: 0,
      position: Position.Absolute,
      width: ICON_SIZE
    },
    showButtonOverride: {
      button: {
        position: Position.Absolute,
        right: 15
      }
    }
  };

  public static readonly build = memoizeOne(
    (
      hasIcon: boolean,
      isFocused: boolean,
      isSecure: boolean,
      emphasizeText: boolean,
      styleOverrides?: ITextInputStylesOverride
    ) => {
      const input = loboTextInputStyle({
        ...TextInputStyle.inputStyle.base,
        ...(hasIcon ? TextInputStyle.inputStyle.hasIcon : {}),
        ...(isFocused ? TextInputStyle.inputStyle.isFocused : {}),
        ...(emphasizeText ? TextInputStyle.inputStyle.errorText : {}),
        ...(isSecure ? TextInputStyle.inputStyle.isSecure : {}),
        ...styleOverrides?.input
      });

      const iconColor = isFocused ? Color.Gray60 : Color.Gray40;

      return {
        ...TextInputStyle.styles,
        errorBox: {
          ...TextInputStyle.styles.errorBox,
          ...styleOverrides?.errorBox
        },
        iconColor: {
          color: iconColor
        },
        iconView: {
          ...TextInputStyle.styles.iconView,
          ...styleOverrides?.iconView
        },
        input
      };
    }
  );
}
