export enum TransitionScreenplayId {
  ActToEncounter = 'actToEncounterTransition',
  AvatarToNext = 'AvatarToNextTransition',
  CalibrationToHome = 'calibrationToHome',
  CalibrationToOnboarding = 'calibrationToOnboarding',
  CalibrationToPlacement = 'calibrationToPlacement',
  EducatorToEncounter = 'educatorToEncounter',
  EncounterToEducator = 'encounterToEducator',
  GettingStartedToCalibration = 'gettingStartedToCalibration',
  HomeToEncounter = 'homeToEncounterTransition',
  HomeToLevel = 'homeToLevelTransition',
  HomeToNext = 'homeToNextTransition',
  IntroductionToAvatarEditor = 'introductionToAvatarEditor',
  IntroductionToCalibration = 'introductionToCalibration',
  LevelCompleteToNext = 'levelCompleteToNext',
  LevelIntroToLevel = 'levelIntroToLevelTransition',
  LevelToAct = 'levelToActTransition',
  LoginToIntroduction = 'LoginToIntroduction',
  LoginToSsoInteraction = 'loginToSsoInteraction',
  OnboardingToPlacement = 'onboardingToPlacement',
  PlacementToLevel = 'placementToLevel',
  RoundToActComplete = 'roundToActComplete',
  RoundToEncounterComplete = 'roundToEncounterComplete',
  RoundToLevelComplete = 'roundToLevelComplete',
  RoundToPlacementComplete = 'roundToPlacementComplete',
  RoundToRound = 'roundToRoundTransition',
  RoundToUnitComplete = 'roundToUnitComplete'
}

export enum TransitionActionType {
  ActToEncounter = 'TRANSITION.ACT_TO_ENCOUNTER',
  AvatarToNext = 'TRANSITION.AVATAR_TO_NEXT',
  CalibrationToNext = 'TRANSITION.CALIBRATION_TO_NEXT',
  EducatorToEncounter = 'TRANSITION.EDUCATOR_TO_ENCOUNTER',
  GettingStartedToCalibration = 'TRANSITION.GETTING_STARTED_TO_CALIBRATION',
  HomeToEncounter = 'TRANSITION.HOME_TO_ENCOUNTER',
  HomeToLevel = 'TRANSITION.HOME_TO_LEVEL',
  HomeToNext = 'TRANSITION.HOME_TO_NEXT',
  IntroductionToNext = 'TRANSITION.INTRODUCTION_TO_NEXT',
  LevelCompleteToNext = 'TRANSITION.LEVEL_COMPLETE_TO_NEXT',
  LevelIntroToLevel = 'TRANSITION.LEVEL_INTRO_TO_LEVEL',
  LoginToIntroduction = 'TRANSITION.LOGIN_TO_INTRODUCTION',
  OnboardingToPlacement = 'TRANSITION.ONBOARDING_TO_PLACEMENT',
  PlacementCompleteToLevel = 'TRANSITION.PLACEMENT_COMPLETE_TO_LEVEL'
}
