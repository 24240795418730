import { Position } from '@lexialearning/common-ui';

export class SvgStyles {
  public static get() {
    return {
      pick: { height: 47.4, marginTop: 14, width: 99 },
      tab: { left: 0, position: Position.Absolute, top: 0 }
    };
  }
}
