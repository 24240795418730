import * as React from 'react';
import { RouterService } from 'router-service';
import { Router } from 'react-router-dom';
import { useState } from 'react';

export function AppRouter(
  props: React.PropsWithChildren<{}>
): React.ReactElement {
  const { children } = props;
  const { history } = RouterService;

  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      location={state.location}
      navigator={history}
      navigationType={state.action}
    >
      {children}
    </Router>
  );
}
AppRouter.displayName = 'AppRouter';
