export enum LoginErrorMessage {
  Title = 'Try again!',
  UsernameOrPassword = 'Username or password is not correct.',
  DeviceSetup = "Teacher email is not valid. Please re-enter your teacher's email"
}

export const ResetPasswordUrl =
  'https://auth.mylexia.com/password/requestReset?https%3A%2F%2Fauth%2Emylexia%2Ecom%2Fpassword%2FrequestReset=undefined&rse=true';

export const StudentDataUrl = 'https://auth.mylexia.com/mylexiaLogin';

export enum LoginReactAnimationName {
  FadeIn = 'LoginPageFadeIn',
  FadeOut = 'LoginPageFadeOut'
}
