import { ICard } from '@lexialearning/lobo-common/main-model';
import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { AnimatedColumn, Column, Image, Text } from 'common-ui';
import { Services } from 'services/Services';
import { ChoiceContainer } from '../../shared';
import { TagSvg } from './Tag.lx-svg';
import {
  UserChoiceCardAnimatedStyles,
  UserChoiceCardType
} from './UserChoiceCard.animated-styles';
import { useEffect, useRef } from 'react';

export interface IUserChoiceCardProps {
  sysId: string;
  choice: ICard;
  cardType: UserChoiceCardType;
  index: number;
  delayIndex: number;
  onSelectionChange?(sysId: string): void;
}

export function UserChoiceCard(props: IUserChoiceCardProps) {
  const { sysId, cardType, choice, index } = props;
  const shouldShowLabel =
    cardType !== UserChoiceCardType.Image && !!choice.label;

  const animatedStyles = useRef(
    new UserChoiceCardAnimatedStyles(props.delayIndex)
  );
  const styles = animatedStyles.current.build(cardType, index);
  const { entry, exit } = animatedStyles.current.getAnimations();

  useEffect(() => {
    const unregisterAnimation =
      Services.reactAnimationScreenplayer.registerAnimations([
        /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
        { name: `Card ${index} Entry`, animation: entry },
        { name: `Card ${index} Exit`, animation: exit }
        /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
      ]);

    return () => {
      unregisterAnimation();
    };
  }, [entry, exit, index]);

  return (
    <AnimatedColumn
      testId={UserChoiceCard.displayName}
      animatedStyle={styles.animatedContainer}
    >
      <TagSvg stringColor={styles.tag.stringColor} />
      <ChoiceContainer
        sysId={sysId}
        styleOverride={styles.choiceContainerStyleOverride}
        hoverScale={1.01}
        pressScale={0.995}
        allowMultiSelect
      >
        <Column style={styles.cardContainer}>
          <Column style={styles.innerCardContainer}>
            <Image
              style={styles.image}
              source={choice.image?.imageSource}
              accessibilityLabel={choice.image?.altText}
              importantForAccessibility={
                shouldShowLabel
                  ? ImportantForAccessibility.NoHideDescendants
                  : ImportantForAccessibility.Yes
              }
            />
            {shouldShowLabel && (
              <Column style={styles.textContainer}>
                <Text>{choice.label}</Text>
              </Column>
            )}
          </Column>
        </Column>
      </ChoiceContainer>
    </AnimatedColumn>
  );
}
UserChoiceCard.displayName = 'UserChoiceCard';
