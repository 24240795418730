import {
  IActEducator,
  IEncounterEducator,
  ILevelEducator
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { IActivityPosition } from 'curriculum-services';

export class EducatorActivityDetail {
  public static readonly displayName = 'EducatorActivityDetail';

  public get act(): IActEducator {
    const act = this.actMaybe;
    if (!act) {
      throw new LexiaError(
        'No selected activity',
        EducatorActivityDetail.displayName,
        EducatorActivityDetailError.ActMissing
      ).withContext({ activityPosition: this.activityPosition });
    }

    return act;
  }

  public get encounter(): IEncounterEducator {
    const encounter = this.encounterMaybe;
    if (!encounter) {
      throw new LexiaError(
        'No selected encounter',
        EducatorActivityDetail.displayName,
        EducatorActivityDetailError.EncounterMissing
      ).withContext({ activityPosition: this.activityPosition });
    }

    return encounter;
  }

  public readonly encounterMaybe: IEncounterEducator | undefined;

  public readonly actMaybe: IActEducator | undefined;

  public constructor(
    public readonly level: ILevelEducator,
    public readonly activityPosition: IActivityPosition,
    public readonly activityIndex: number
  ) {
    this.actMaybe = activityIndex >= 0 ? level.acts[activityIndex] : undefined;
    this.encounterMaybe = this.actMaybe?.encounters.find(
      e => e.sysId === activityPosition?.encounterId
    );
  }
}

export enum EducatorActivityDetailError {
  ActMissing = 'ActMissing',
  EncounterMissing = 'EncounterMissing'
}
