import { IScreenplay } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { IPressScaleButtonProps, TextButton } from 'common-ui';
import { ScreenplayAction, ScreenplaySelector } from 'screenplay';
import { FunFactButtonStyles } from './FunFactButton.styles';

export interface IFunFactButtonProps extends IPressScaleButtonProps {
  activeScreenplayId: string | undefined;
  index: number;
  funFact: IScreenplay;
  playScreenplay(funFact: IScreenplay): void;
}

export class FunFactButtonComponent extends React.PureComponent<IFunFactButtonProps> {
  public static readonly displayName = 'FunFactButton';

  private readonly numberTextArray = ['one', 'two'];

  private get funFactNumberText() {
    return this.numberTextArray[this.props.index];
  }

  constructor(props: IFunFactButtonProps) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
  }

  private handlePress() {
    const { funFact, playScreenplay } = this.props;
    playScreenplay(funFact);
  }

  public render() {
    const { activeScreenplayId, disabled, funFact, index, ...restProps } =
      this.props;

    const styles = FunFactButtonStyles.build(
      !!disabled,
      activeScreenplayId === funFact.id
    );

    return (
      <TextButton
        {...restProps}
        disabled={disabled}
        text={(index + 1).toString()}
        styleOverride={styles}
        onPress={this.handlePress}
        accessibilityLabel={`Fun Fact ${this.funFactNumberText}.`}
      />
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    activeScreenplayId: ScreenplaySelector.getScreenplayMaybe(state)?.id
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  playScreenplay: (sp: IScreenplay) => ScreenplayAction.play({ screenplay: sp })
};

export const FunFactButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(FunFactButtonComponent);
