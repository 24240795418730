import * as React from 'react';
import { AnimatedRow } from 'common-ui';
import { Services } from 'services';
import { CalibrationReactAnimationName } from './calibration-page.model';
import { CalibrationContent } from './CalibrationContent';
import { CalibrationPageAnimatedStyles } from './CalibrationPage.animated-styles';

export class CalibrationPage extends React.PureComponent {
  public static displayName = 'CalibrationPage';

  private readonly animatedStyles: CalibrationPageAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: any) {
    super(props);
    this.animatedStyles = new CalibrationPageAnimatedStyles();
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { fadeIn, fadeOut } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      { name: CalibrationReactAnimationName.FadeIn, animation: fadeIn },
      { name: CalibrationReactAnimationName.FadeOut, animation: fadeOut }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const styles = this.animatedStyles.get();

    return (
      <AnimatedRow animatedStyle={styles.container}>
        <CalibrationContent />
      </AnimatedRow>
    );
  }
}
