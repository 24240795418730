import {
  AccessibilityRole,
  useActiveComponentState
} from '@lexialearning/common-ui';
import {
  ButtonBase,
  IButtonBaseProps
} from 'common-ui/components/button/ButtonBase';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { LoginTabButtonStyles } from './LoginTabButton.styles';
import { TabType } from 'common-styles';
import { Text } from 'common-ui/components/text';
import { capitalize } from 'lodash';
import { useCallback } from 'react';

export interface ILoginTabButtonProps extends ISfxProps, IButtonBaseProps {
  selected: boolean;
  tabButtonId: string;
  tabPanelId: string;
  tabType: TabType;
  text: string;
  onTabPress(tabName: string): void;
}

export function LoginTabButtonComponent(props: ILoginTabButtonProps) {
  const {
    disabled,
    onTabPress,
    playSfx,
    selected,
    tabButtonId,
    tabPanelId,
    tabType,
    text,
    ...restProps
  } = props;

  const handlePress = useCallback(() => {
    playSfx(Sfx.TabSelect);
    onTabPress(text);
  }, [onTabPress, playSfx, text]);

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = LoginTabButtonStyles.build(
    activeComponentState,
    !!disabled,
    selected,
    tabType
  );

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      accessibilityRole={AccessibilityRole.Tab}
      accessibilityState={{ selected }}
      ariaControls={tabPanelId}
      disabled={selected || disabled}
      id={tabButtonId}
      style={styles.button}
      testId={`${LoginTabButtonComponent.displayName}_${text}`}
      onPress={handlePress}
    >
      <Text style={styles.text}>{capitalize(text)}</Text>
    </ButtonBase>
  );
}
LoginTabButtonComponent.displayName = 'LoginTabButtonComponent';

export const LoginTabButton = withSfx(LoginTabButtonComponent);
