import * as React from 'react';
import { ImportantForAccessibility } from '@lexialearning/common-ui';
import {
  IPositionTransitionProps,
  PositionTransition
} from 'common-ui/components/position-transition';
import { withPositionHandler } from 'common-ui';
import { useState } from 'react';
import { CheckMarkSvg } from '../svg';

const PositionTransitionWithPosition = withPositionHandler(PositionTransition);

export function AnimatedPill(
  props: IPositionTransitionProps
): React.ReactElement | null {
  const [offset, setOffset] = useState();
  // istanbul ignore next - can't figure out how to get this to fire
  const onPosition = pos => {
    setOffset(pos);
  };

  return (
    <PositionTransitionWithPosition
      blockPointerEvents
      importantForAccessibility={ImportantForAccessibility.NoHideDescendants}
      offset={offset}
      onPosition={onPosition}
      testId={AnimatedPill.displayName}
      {...props}
    >
      {props.position && <CheckMarkSvg />}
    </PositionTransitionWithPosition>
  );
}
AnimatedPill.displayName = 'AnimatedPill';
