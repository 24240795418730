import {
  AppShellActionShowModal,
  AppShellActionType,
  AppShellSelector
} from 'feature-areas/shell';
import { AuthSelector } from 'services/auth';
import { ILoggingDeps } from '../logging-epic.model';
import { LexiaError } from '@lexialearning/utils';
import { Observable } from 'rxjs';
import { TimingSet } from './TimingSet';
import { TroubleshootModal } from 'feature-areas/troubleshoot/TroubleshootModal';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';

export function logTroubleshootModalTimingsEpic(
  action$: Observable<AppShellActionShowModal>,
  state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal),
    filter(
      () =>
        AuthSelector.isAuthenticated(state$.value) &&
        AppShellSelector.getActiveModal(state$.value)?.id ===
          TroubleshootModal.ModalId
    ),
    tap(() => {
      const { appLoggerDependencies: helper } = deps;

      try {
        TimingSet.create('Troubleshoot Modal', helper.logger)
          .addTiming(
            'Opened to closed',
            {
              payload: { id: TroubleshootModal.ModalId },
              type: AppShellActionType.ShowModal
            },
            { type: AppShellActionType.HideModal }
          )
          .startMonitoring(action$ as any);
      } catch (err) {
        void helper.logger.logError(
          new LexiaError(
            `Error attempting to log the troubleshoot modal timing event: ${err.message}`,
            logTroubleshootModalTimingsEpic.displayName,
            LogTroubleshootTimingsEpicError.ErrorLogging
          ).withCause(err)
        );
      }
    }),
    ignoreElements()
  );
}

logTroubleshootModalTimingsEpic.displayName = 'logTroubleshootModalTimingsEpic';

export enum LogTroubleshootTimingsEpicError {
  ErrorLogging = 'ErrorLogging'
}
