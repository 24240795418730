import { ILoggingDeps } from '../logging-epic.model';
import { LexiaError } from '@lexialearning/utils';
import { Observable } from 'rxjs';
import {
  SreCalibrationActionCalibrate,
  SreCalibrationActionType,
  SreSessionActionType
} from 'sre';
import { ignoreElements, tap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { TimingSet } from 'services/logging/epics/timings/TimingSet';

export function logCalibrationListenDelayEpic(
  action$: Observable<SreCalibrationActionCalibrate>,
  _state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SreCalibrationActionType.Calibrate),
    tap(() => {
      const { appLoggerDependencies: helper } = deps;
      try {
        TimingSet.create('Calibration listen delay', helper.logger)
          .addTiming(
            'Calibrate to Listen',
            { type: SreCalibrationActionType.Calibrate },
            { type: SreSessionActionType.Listen }
          )
          .addTiming(
            'Listen to Listening',
            { type: SreSessionActionType.Listen },
            { type: SreSessionActionType.Listening }
          )
          .startMonitoring(action$ as any);
      } catch (err) {
        void helper.logger.logError(
          new LexiaError(
            `Error attempting to log the calibration listen delay timing event: ${err.message}`,
            logCalibrationListenDelayEpic.displayName,
            LogCalibrationListenDelayEpicError.ErrorLogging
          ).withCause(err)
        );
      }
    }),
    ignoreElements()
  );
}
logCalibrationListenDelayEpic.displayName = 'logCalibrationListenDelayEpic';

export enum LogCalibrationListenDelayEpicError {
  ErrorLogging = 'ErrorLogging'
}
