import { ConfigOverridesLoader } from '@lexialearning/lobo-common/cms';
import { LoggingLevel } from '@lexialearning/main-model';
import { cloneDeep, set } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ILoboAppConfig } from 'services/app-config';
import {
  AppConfigActionApplyOverrides,
  AppConfigActionType,
  IAppConfigOverridesPayload
} from '../redux';
import { AppConfigDependencies } from './AppConfigDependencies';
import { QueryStringConfigOverrides } from './QueryStringConfigOverrides';
import {
  ConfigSelector as UniConfigSelector,
  ConfigAction as UniConfigAction,
  ConfigActionUpdate as UniConfigActionUpdate
} from '@lexialearning/utils-react';
import { IConfigOverride } from '@lexialearning/utils';
import { AppState } from 'services';

export interface IApplyOverridesDependencies {
  appConfigDependencies: AppConfigDependencies;
}

export function applyOverridesEpic(
  action$: Observable<AppConfigActionApplyOverrides>,
  state$: StateObservable<AppState>,
  deps: IApplyOverridesDependencies
): Observable<UniConfigActionUpdate> {
  return action$.pipe(
    ofType(AppConfigActionType.ApplyOverrides),
    mergeMap(async action => {
      const { appConfigDependencies } = deps;
      const state = state$.value;
      const configOriginal = UniConfigSelector.getConfig<ILoboAppConfig>(state);
      const config = cloneDeep(configOriginal);
      const { overrides: payloadOverrides } = action.payload;

      const qstrOverrides = QueryStringConfigOverrides.create();

      const overrideSetName = getOverrideSetName(action.payload, qstrOverrides);

      const loadedOverrides = await getLoadedOverrides(
        appConfigDependencies.appName,
        overrideSetName,
        appConfigDependencies.overridesLoader,
        config.logger.loggingLevel
      );

      const overrides = loadedOverrides.concat(
        payloadOverrides || [],
        qstrOverrides
      );

      overrides
        .concat(payloadOverrides || [], qstrOverrides)
        .forEach(override => {
          set(config, override.key, override.value);
        });

      return UniConfigAction.update(config);
    })
  );
}
applyOverridesEpic.displayName = 'applyOverridesEpic';

function getOverrideSetName(
  payload: IAppConfigOverridesPayload,
  queryStringOverrides: IConfigOverride[]
): string | undefined {
  const qstr = queryStringOverrides.find(o => o.key === 'overrideSetName');

  return qstr ? (qstr.value as string) : payload.overrideSetName;
}

async function getLoadedOverrides(
  appName: string,
  overrideSetName: string | undefined,
  overridesLoader: ConfigOverridesLoader,
  currentLoggingLevel: LoggingLevel
): Promise<IConfigOverride[]> {
  const overrideSet = overrideSetName
    ? await overridesLoader.load(appName, overrideSetName)
    : [];

  // Disallow overriding of Analytics loggingLevel by loadedOverrides
  // (Customer overrides come in as payloadOverrides and *should* be
  //  allowed to override loggingLevel)
  const loadedOverrides =
    currentLoggingLevel === LoggingLevel.Analytics
      ? overrideSet.filter(o => o.key !== 'logger.loggingLevel')
      : overrideSet;

  return loadedOverrides;
}
