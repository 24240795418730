import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'services';
import { IProgressMetersState } from '../progress-meters.model';
import { RoundMeterSelector } from '../round-meter/redux/RoundMeter.selector';
import { UnitMeterSelector } from '../unit-meter/redux/UnitMeter.selector';

type ProgressMetersSelectorType = (state: AppState) => IProgressMetersState;
export class ProgressMetersSelector {
  public static readonly displayName = 'ProgressMetersSelector';

  public static getState: ProgressMetersSelectorType;

  public static createSelectors(selector: ProgressMetersSelectorType): void {
    ProgressMetersSelector.getState = createSelector(
      selector,
      (state: IProgressMetersState) => state
    );
    RoundMeterSelector.createSelectors(state => selector(state).roundMeter);
    UnitMeterSelector.createSelectors(state => selector(state).unitMeter);
  }
}
