import { ILoggingDeps } from '../logging-epic.model';
import { LexiaError } from '@lexialearning/utils';
import { Observable } from 'rxjs';
import { SreSelector, SreSessionActionType, SreSessionType } from 'sre';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { SeeSpeakActionToggleListen } from 'task-components/see-speak/redux/SeeSpeak.action';
import { SeeSpeakActionType } from 'task-components/see-speak/redux/see-speak-redux.model';
import { TimingSet } from 'services/logging/epics/timings/TimingSet';
import { SeeSpeakSelector } from 'task-components/see-speak';

export function logSeeSpeakListenDelayEpic(
  action$: Observable<SeeSpeakActionToggleListen>,
  state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SeeSpeakActionType.ToggleListen),
    // Only listen for ToggleListen that *initiates* a session
    filter(
      () =>
        !SreSelector.getIsListeningTo(
          SreSessionType.LanguageFrame,
          state$.value
        ) &&
        // Don't log for Choral rounds
        !SeeSpeakSelector.isChoral(state$.value)
    ),
    tap(() => {
      const { appLoggerDependencies: helper } = deps;
      try {
        TimingSet.create('SeeSpeak listen delay', helper.logger)
          .addTiming(
            'ToggleListen to Listen',
            { type: SeeSpeakActionType.ToggleListen },
            { type: SreSessionActionType.Listen }
          )
          .addTiming(
            'Listen to Listening',
            { type: SreSessionActionType.Listen },
            { type: SreSessionActionType.Listening }
          )
          .startMonitoring(action$ as any);
      } catch (err) {
        void helper.logger.logError(
          new LexiaError(
            `Error attempting to log the see speak listen delay timing event: ${err.message}`,
            logSeeSpeakListenDelayEpic.displayName,
            LogSeeSpeakListenDelayEpicError.ErrorLogging
          ).withCause(err)
        );
      }
    }),
    ignoreElements()
  );
}
logSeeSpeakListenDelayEpic.displayName = 'logSeeSpeakListenDelayEpic';

export enum LogSeeSpeakListenDelayEpicError {
  ErrorLogging = 'ErrorLogging'
}
