import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { from, lastValueFrom, Observable, race, Subject, timer } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { Types } from 'common-ui/types';
import {
  IScreenplayActionPlayer,
  ScreenplayerType
} from '../screenplayer.model';
import { IReactAnimationRequest } from './react-animation-player.model';
import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { LoboLogItemCategory } from 'logging';

type Animation = Types.Animated.CompositeAnimation;

export interface IAnimationEntry {
  name: string;
  animation: Types.Animated.CompositeAnimation;
}

export class ReactAnimationActionPlayer
  implements IScreenplayActionPlayer<IReactAnimationRequest>
{
  public static readonly displayName = 'ReactAnimationActionPlayer';

  public readonly type = ScreenplayerType.ReactAnimation;

  private readonly animationMap = new Map<string, Animation>();

  private activeAnimation: Animation | undefined;

  private readonly animationRegisteredSubject = new Subject<string>();

  constructor(private readonly logger: ILogger) {}

  public get animationRegistered$(): Observable<string> {
    return this.animationRegisteredSubject.asObservable();
  }

  public cancel(request?: IScreenplayAction<IReactAnimationRequest>): void {
    if (!request) {
      return this.activeAnimation?.stop();
    }

    const { name } = request.data;
    const animation = this.animationMap.get(name);

    if (!animation) {
      return;
    }

    animation.stop();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public pause(): void {}

  public async play({
    data
  }: IScreenplayAction<IReactAnimationRequest>): Promise<void> {
    const { name, awaitRegistrationTimeoutMs } = data;

    const startTime = new Date();
    if (awaitRegistrationTimeoutMs) {
      await lastValueFrom(
        race([this.hasRegistered$(name), timer(awaitRegistrationTimeoutMs)])
      );
    }

    this.activeAnimation = this.animationMap.get(name);

    if (!this.activeAnimation) {
      throw new LexiaError(
        `Play request error: Animation name ${name} is not registered`,
        ReactAnimationActionPlayer.displayName,
        ReactAnimationActionPlayerError.AnimationNotFound
      ).withContext(data);
    }

    const foundRegisteredAnimationTime = new Date();
    const duration =
      foundRegisteredAnimationTime.getTime() - startTime.getTime();
    if (duration > 500) {
      this.logger.log({
        category: LoboLogItemCategory.AnimationRegistrationDelay,
        loggingLevel: LoggingLevel.Info,
        payload: {
          animationName: name,
          durationMs: duration
        },
        summary: `Animation '${name}' had a registration delay of ${duration}`
      });
    }

    await this.playActiveAnimation();
  }

  /**
   *
   * @param entries
   * @returns method to unregister the same entries
   */
  public registerAnimations(entries: IAnimationEntry[]): () => void {
    entries.forEach(e => this.registerAnimation(e));

    return () => this.unregisterAnimations(entries);
  }

  private registerAnimation({ name, animation }: IAnimationEntry): void {
    this.animationMap.set(name, animation);
    this.animationRegisteredSubject.next(name);
  }

  private unregisterAnimations(entries: IAnimationEntry[]): void {
    entries.forEach(e => this.unregisterAnimation(e.name));
  }

  private unregisterAnimation(name: string): void {
    this.animationMap.delete(name);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public resume(): void {}

  private hasRegistered$(name: string): Observable<boolean> {
    return from(
      this.animationRegistered$.pipe(
        startWith(...this.animationMap.keys()),
        filter(n => n === name),
        map(() => true),
        take(1)
      )
    );
  }

  private async playActiveAnimation(): Promise<void> {
    return new Promise<void>(resolve => {
      this.activeAnimation!.start(() => {
        this.activeAnimation = undefined;
        resolve();
      });
    });
  }
}

export enum ReactAnimationActionPlayerError {
  AnimationNotFound = 'AnimationNotFound'
}
