export class CalibrationModalStyles {
  private static readonly styles = {
    modal: {
      minHeight: 470,
      minWidth: 500
    }
  };

  public static get() {
    return this.styles;
  }
}
