import {
  FeedbackStrategy,
  InstructionalStep,
  IRound,
  IUnit,
  UnitType
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { createSelector } from '@reduxjs/toolkit';
import { IUnitState } from './unit-redux.model';

export class UnitSelector {
  public static readonly displayName = 'UnitSelector';

  public static getFeedbackStrategy: (
    state: unknown
  ) => FeedbackStrategy | undefined;

  public static getInstructionalStep: (
    state: unknown
  ) => InstructionalStep | undefined;

  public static getRounds: (state: unknown) => IRound[];

  public static getType: (state: unknown) => UnitType;

  public static getUnitMaybe: (state: unknown) => IUnit | undefined;

  public static getUnit: (state: unknown) => IUnit;

  /**
   * Return an array containing the active (sub)unit along with any
   * parent (sub)units. The active (sub)unit is always the last array element.
   * The main unit is always the first array element.
   */
  public static getAncestorsAndUnit: (state: unknown) => IUnit[];

  public static isLoading: (state: unknown) => boolean;

  public static createSelectors(selector: (state: any) => IUnitState): void {
    this.getUnitMaybe = createSelector(
      selector,
      (state: IUnitState) => state.unitContent
    );
    this.getUnit = createSelector(
      this.getUnitMaybe,
      (unit: IUnit | undefined) => {
        if (!unit) {
          throw new LexiaError(
            'No active unit',
            UnitSelector.displayName,
            UnitSelectorError.NoUnit
          );
        }

        return unit;
      }
    );

    this.getFeedbackStrategy = createSelector(
      this.getUnitMaybe,
      (unitContent: IUnit | undefined) =>
        unitContent && unitContent.feedbackStrategy
    );
    this.getInstructionalStep = createSelector(
      this.getUnitMaybe,
      (unitContent: IUnit | undefined) =>
        unitContent && unitContent.instructionalStep
    );
    this.getRounds = createSelector(
      this.getUnitMaybe,
      (unitContent: IUnit | undefined) =>
        (unitContent && unitContent.rounds) || []
    );
    this.getType = createSelector(
      this.getUnitMaybe,
      (unit: IUnit | undefined) => (unit ? unit.type : UnitType.Unknown)
    );
    this.getAncestorsAndUnit = createSelector(selector, (state: IUnitState) =>
      state.unitContent ? [...state.parentUnits, state.unitContent] : []
    );
    this.isLoading = createSelector(
      selector,
      (state: IUnitState) => state.isLoading
    );
  }
}

export enum UnitSelectorError {
  NoUnit = 'NoUnit',
  NoLoadTime = 'NoLoadTime'
}
