import { TaskPhase } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import { find, map, mergeMap, startWith } from 'rxjs/operators';
import { ScreenplaySelector } from 'screenplay';
import { TaskSelector } from 'task-components';

/**
 * Emits only when app is interactive. If app is interactive immediately, it
 * emits right away, otherwise it waits until redux state is showing that app is
 * interactive.
 * App is considered interactive if one of:
 *   1. user is in a task and task phase is "interactive"
 *   2. user is not in a task and there's no screenplay playing
 *
 * @param state$ - StateObservable
 */
export function awaitAppInteractive(state$: StateObservable<unknown>) {
  return mergeMap(a =>
    state$.pipe(
      startWith(state$.value),
      find(state => {
        const taskPhase = TaskSelector.getPhase(state);
        const isScreenplayActive =
          !!ScreenplaySelector.getActiveScreenplayId(state);

        // if in task, wait until it's in interactive phase
        if (taskPhase !== TaskPhase.None) {
          return taskPhase === TaskPhase.Interactive;
        }

        // otherwise wait until active screenplay is finished
        return !isScreenplayActive;
      }),
      map(() => a)
    )
  );
}
