import { Types } from 'common-ui';
import { IVUMeterStyleOverrides } from '../VUMeter';
import { Color, LoboAnimatedValue } from 'common-styles';
import {
  Align,
  Border,
  Direction,
  Justify,
  Position
} from '@lexialearning/common-ui';

export interface IButtonMeterStyles {
  borderContainer: Types.ViewStyle;
  borderContainerAnimated: Types.AnimatedViewStyle;
  micContainer: Types.ViewStyle;
  micContainerOpacityAnimated: Types.AnimatedViewStyle;
  outerContainer: Types.ViewStyle;
  vuMeterContainer: Types.ViewStyle;
  vuMeterOpacityAnimated: Types.AnimatedViewStyle;
  vuMeterOverrides: IVUMeterStyleOverrides;
}

export interface IButtonMeterAnimations {
  reset: Types.Animated.CompositeAnimation;
  showVuMeter: Types.Animated.CompositeAnimation;
}

export interface IButtonMeterValues<T = number> {
  borderContainerOpacity: T;
  borderContainerWidth: T;
  innerOpacity: T;
  micOpacity: T;
}

export interface IButtonMeterAnimatedValues
  extends IButtonMeterValues<LoboAnimatedValue> {}

interface IButtonMeterDefaultValues {
  initialValues: IButtonMeterValues;
  meterOpenValues: IButtonMeterValues;
  styles: IButtonMeterStyles;
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
export const ButtonMeterAnimatedStylesDefaults: IButtonMeterDefaultValues = {
  initialValues: {
    borderContainerOpacity: 1,
    borderContainerWidth: 0, // To be set by consuming styles class
    innerOpacity: 0,
    micOpacity: 1
  },

  meterOpenValues: {
    borderContainerOpacity: 1,
    borderContainerWidth: 0, // To be set by consuming styles class
    innerOpacity: 1,
    micOpacity: 0
  },

  styles: {
    outerContainer: {},
    borderContainer: {
      alignItems: Align.Center,
      borderColor: Color.Gray10,
      borderStyle: Border.Solid,
      borderWidth: 1,
      flexDirection: Direction.Row,
      justifyContent: Justify.Center ///
    },
    borderContainerAnimated: {
      width: undefined,
      opacity: undefined
    },
    vuMeterOpacityAnimated: {
      opacity: undefined
    },
    vuMeterContainer: {
      flexGrow: 0
    },
    vuMeterOverrides: {
      container: {
        justifyContent: Justify.SpaceBetween
      }
    },
    micContainer: {
      position: Position.Absolute
    },
    micContainerOpacityAnimated: {
      opacity: undefined
    }
  }
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
/* eslint-enable sort-keys */
