import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import {
  PlacementSelector,
  ProgramContextAction,
  ProgramContextService,
  RoundContext
} from 'curriculum-services';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import {
  OnboardingOrientationScreenplayBuilder,
  OnboardingOutroScreenplayBuilder
} from '../../builders/onboarding';
import { PlacementIntroScreenplayBuilder } from '../../builders/placement';
import { TransitionScreenplayId } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

export class RoundToOnboardingCompleteTransitionFactory {
  public static readonly displayName =
    'RoundToOnboardingCompleteTransitionBuilder';

  public static createFor(
    state$: StateObservable<unknown>,
    roundToNextDeps: IRoundToNextTransitionDeps,
    context: RoundContext
  ): IScreenplay {
    const { curriculumDependencies, preparedScenes } = roundToNextDeps;
    const { programContextService } = curriculumDependencies;

    return new RoundToOnboardingCompleteTransitionFactory(
      state$,
      preparedScenes,
      context,
      programContextService
    ).screenplay;
  }

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  protected readonly builder: ScreenplayBuilder;

  private constructor(
    state$: StateObservable<unknown>,
    preparedScenes: PreparedScenes,
    context: RoundContext,
    programContextService: ProgramContextService
  ) {
    const placementContent = PlacementSelector.getPlacement(state$.value);
    const congratulationsVo = placementContent.onboardingCongratulations;
    const orientationVo = placementContent.onboardingOrientation;
    const conclusionVo = placementContent.onboardingConclusion;
    const nextActivityPosition = context.imminentPosition.activityPosition;
    const { unitId: nextUnitId, roundId: nextRoundId } =
      nextActivityPosition.unitPosition;

    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.OnboardingToPlacement
    )
      .addScreenplay(
        OnboardingOutroScreenplayBuilder.create()
          .disableUtilityBar()
          .fadeOutControlPanel().screenplay
      )
      .addCallback(async () => {
        RouterService.history.replace(RouteBuilder.onboardingOrientation());
      })
      .addScreenplay(
        OnboardingOrientationScreenplayBuilder.create()
          .centerCharacter()
          .attachCharacter(preparedScenes)
          .playOrientationIntroVo(congratulationsVo)
          .showAndCalloutButtons()
          .animateCharacterButtonGesture()
          .playMicAvailableSfx()
          .playOrientationVo(orientationVo)
          .playConclusionVo(conclusionVo).screenplay
      )
      .addReduxAction(
        ProgramContextAction.onboardingCompleted(nextActivityPosition)
      )
      .addCallback(async () => {
        await programContextService.contentLoaded(state$);
      })
      .addCallback(async () => {
        RouterService.history.replace(
          RouteBuilder.placementRounds(nextUnitId, nextRoundId)
        );
      })
      .addScreenplay(
        PlacementIntroScreenplayBuilder.create().dispatchPlacementRoundIntro()
          .screenplay
      );
  }
}
