import { LexiaError } from '@lexialearning/utils';
import { Color } from 'common-styles';
import { Path, Svg } from '../../components/svg';
import { RoundedTabSvgStyles } from './RoundedTab.lx-svg.styles';
import { useMemo } from 'react';

/**
 * length: Length of the flat edge of the tab.
 *        Pertains to width for horizontal tabs (top / bottom)
 *        and height for vertical tabs (left / right)
 * depth: Depth is the dimension perpendicular to length (or is there another name that
 *        is better and still relevant regardless of orientation?)
 *        Pertains to height for horizontal tabs (top / bottom)
 *        and width for vertical tabs (left / right)
 * rectLength: The length of the center piece between tabEnds
 *        This can be 0, so the two curves meet without any rectangular section between them,
 *        or can be given a positive value to increase/decrease the amount of space between tab ends
 */
export interface IRoundedTabSvgProps {
  position: RoundedTabPosition;
  length?: number;
  depth?: number;
  rectLength?: number;
  fill?: string;
  opacity?: number;
}

export enum RoundedTabPosition {
  Top,
  Bottom,
  Left,
  Right
}

/**
 * These pulled from svg code - used to set scale values
 * Should not be changed
 */
const svgDefaultDimensions = {
  depth: 54,
  length: 198,
  rectLength: 90,
  tabEndLength: 54
};

/**
 * These can be any values desired for component defaults,
 * but if changed, these values will need to be input where this
 * component is used without overriding defaults
 *
 * I chose these values for default as they are the ones from 2 of the 4
 * locations where this tab is currently used
 */
const defaultDimensions = {
  depth: 74,
  fill: Color.NearWhite,
  length: 286,
  opacity: 0.146,
  rectLength: 0
};

export function RoundedTabSvg(props: IRoundedTabSvgProps) {
  const {
    position,
    length = defaultDimensions.length,
    depth = defaultDimensions.depth,
    rectLength = defaultDimensions.rectLength,
    fill = defaultDimensions.fill,
    opacity = defaultDimensions.opacity
  } = props;

  const {
    width,
    height,
    svgRotate,
    pathScaleX,
    pathScaleY,
    pathRotate,
    rectLengthComputed
  } = useMemo(
    () => getRoundedTabSettings(position, length, depth, rectLength),
    [position, length, depth, rectLength]
  );

  const styles = RoundedTabSvgStyles.build(svgRotate);

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={styles.svg}
    >
      <Path
        d={`M25.3,0H${
          rectLengthComputed + 108
        }v0.3c-10.3,3.9-19.4,14.6-25.4,30c-5.1,13-13.1,21.4-22,23.2c-1.9,0.3-3.8,0.5-5.6,0.5
          c-96.2,0.2-${rectLengthComputed},0-${
            rectLengthComputed + 1.5
          },0c-2.5,0-5.9-0.3-6.6-0.5c-8.9-1.8-16.9-10.2-22-23.2C19.4,14.9,10.3,4.2,0,0.3V0H25.3z`}
        fill={fill}
        opacity={opacity}
        transform={`scale(${pathScaleX} ${pathScaleY}) rotate(${pathRotate})`}
      />
    </Svg>
  );
}
RoundedTabSvg.displayName = 'RoundedTabSvg';

export function getRoundedTabSettings(
  position: RoundedTabPosition,
  length: number,
  depth: number,
  rectLength: number
) {
  // If rect (center piece) is greater than overall length, this is an invalid shape
  // If it is equal to overall length, there are no tab ends, and this is an unnecessary shape
  if (rectLength >= length) {
    throw new LexiaError(
      'Invalid inputs: rectLength cannot be greater than or equal to length.',
      'RoundedTabSvg getRoundedTabSettings()',
      '123'
    );
  }

  // Set svg width/height from length/depth based on whether orientation isHorizontal
  const isHorizontal =
    position === RoundedTabPosition.Top ||
    position === RoundedTabPosition.Bottom;
  const width = isHorizontal ? length : depth;
  const height = isHorizontal ? depth : length;

  // Set svg rotation in order to 'flip' svg for position Top and Left
  const shouldRotateSvg =
    position === RoundedTabPosition.Top || position === RoundedTabPosition.Left;
  const svgRotate = shouldRotateSvg ? 180 : 0;

  // Get path scaling and related computed rectLength
  const tabEndLength = (length - rectLength) / 2;
  const pathScaleL = tabEndLength / svgDefaultDimensions.tabEndLength;
  const pathScaleD = depth / svgDefaultDimensions.depth;

  const pathScaleX = isHorizontal ? pathScaleL : -pathScaleD;
  const pathScaleY = isHorizontal ? pathScaleD : pathScaleL;
  let rectLengthComputed = rectLength / pathScaleL;
  rectLengthComputed = rectLengthComputed < 0 ? 0 : rectLengthComputed;

  // Set path rotation to 90 for position Left and Right
  const shouldRotatePath =
    position === RoundedTabPosition.Left ||
    position === RoundedTabPosition.Right;
  const pathRotate = shouldRotatePath ? 90 : 0;

  return {
    height,
    pathRotate,
    pathScaleX,
    pathScaleY,
    rectLengthComputed,
    svgRotate,
    width
  };
}
