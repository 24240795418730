import { IChoice, TaskPhase } from '@lexialearning/lobo-common/main-model';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'common-ui';
import { TaskAction, TaskSelector } from 'task-components';
import { NoChoice } from './NoChoice';
import { YesChoice } from './YesChoice';
import { YesNoChoicesContainerStyles } from './YesNoChoicesContainer.styles';

export interface IYesNoChoicesContainerProps {
  choices: IChoice[];
}

export function YesNoChoicesContainer(props: IYesNoChoicesContainerProps) {
  const { choices } = props;

  const showSolution = useSelector(TaskSelector.getShowSolution);
  const isInteractivePhase = useSelector(
    state => TaskSelector.getPhase(state) === TaskPhase.Interactive
  );

  const dispatch = useDispatch();

  const setSelectedChoice = (sysId: string) =>
    dispatch(TaskAction.setSelectedChoice({ choice: sysId }));

  const disabled = !isInteractivePhase;

  const styles = YesNoChoicesContainerStyles.get();
  const yesChoice: IChoice = choices[0];
  const noChoice: IChoice = choices[1];

  return (
    <Row style={styles.choiceContainer}>
      <YesChoice
        disabled={disabled}
        sysId={yesChoice.card.sysId}
        isCorrectChoice={yesChoice.isCorrectChoice}
        onSelectionChange={setSelectedChoice}
        showSolution={showSolution}
      />
      <NoChoice
        disabled={disabled}
        sysId={noChoice.card.sysId}
        isCorrectChoice={noChoice.isCorrectChoice}
        onSelectionChange={setSelectedChoice}
        showSolution={showSolution}
      />
    </Row>
  );
}
YesNoChoicesContainer.displayName = 'YesNoChoicesContainer';
