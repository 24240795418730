import { PositionDeterminer } from './PositionDeterminer';

export class SkipPositionDeterminer extends PositionDeterminer {
  public static readonly displayName = 'SkipPositionDeterminer';

  protected shouldRetry() {
    return false;
  }

  protected isCorrect() {
    return true;
  }
}
