import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventFactory, ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';
import { LoboLogItemCategory } from 'logging';

/**
 * Use rxjs tap operator to call helper.logSafely
 *
 * @param state$ - StateObservable
 * @param deps - epic middleware dependencies
 * @param eventFactory - (action, state, helper) => ILogEvent
 * @param category - LoboLogItemCategory
 */
export function logSafely<TAction extends Action, TPayload extends object>(
  state$: StateObservable<AppState>,
  deps: ILoggingDeps,
  eventFactory: EventFactory<TAction, TPayload>,
  category: LoboLogItemCategory
): MonoTypeOperatorFunction<TAction> {
  return tap<TAction>(a => {
    void deps.appLoggerDependencies.logSafely(
      a,
      state$.value,
      eventFactory,
      category
    );
  });
}
