import {
  IRoundSaveInfo,
  IUnitSavePoint,
  PositionChangeType,
  TaskEvaluationResult,
  UnitSavePointStatus
} from '@lexialearning/lobo-common';
import { ProgramMode } from 'curriculum-services';
import { TaskRegistry } from 'task-components';
import { RoundContext } from '../../RoundContext';
import {
  IRoundLexia,
  IStepLexia,
  IUnitProgressLexia,
  Modality,
  RoundLexiaResult,
  SendUnitLexia,
  StudentProgressPayloadType
} from '../student-progress-api/student-progress-api-private.model';
import { IAttemptInfo } from '../student-progress-api/student-progress-api.model';
import { UnitAdvanceFactory } from './UnitAdvanceFactory';

/**
 * Create the sendArray portion of the student API Update payload.
 * @see {@link https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=LADS&title=Lobo+Unit+Progress+Use+Cases |Unit Progress Use Cases}
 */
export class ProgressPayloadFactory {
  public static readonly displayName = 'ProgressPayloadFactory';

  public static create(
    roundContext: RoundContext,
    taskRegistry: TaskRegistry,
    programMode: ProgramMode,
    isProgramComplete = false
  ): SendUnitLexia[] {
    const factory = new ProgressPayloadFactory(
      roundContext,
      taskRegistry,
      programMode
    );

    return factory
      .createProgress(isProgramComplete)
      .setActivityCompleted()
      .setUnitCompleted()
      .mapRounds()
      .addBranchMaybe()
      .addForkMaybe()
      .appendCompletedForkedMainUnitMaybe()
      .appendAdvanceMaybe().elements;
  }

  private progressElement!: IUnitProgressLexia;

  private readonly elements: SendUnitLexia[] = [];

  private get changeType(): PositionChangeType {
    return this.context.imminentPosition.changeType;
  }

  private get savePoint(): IUnitSavePoint {
    // When constructing payload we are in the midst of saving the save point
    return this.context.getSavePoint(UnitSavePointStatus.Saving);
  }

  private constructor(
    private readonly context: RoundContext,
    private readonly taskRegistry: TaskRegistry,
    private readonly programMode: ProgramMode
  ) {}

  /**
   * Simple progress element, default to incomplete
   */
  private createProgress(isProgramComplete: boolean): ProgressPayloadFactory {
    const { unitId } = this.context.parentUnitPosition;
    const { workStartTime } = this.savePoint;

    this.progressElement = {
      __type__:
        StudentProgressPayloadType.UnitProgress as StudentProgressPayloadType.UnitProgress,
      activityComplete: false,
      completed: false,
      duration: Math.round((Date.now() - workStartTime) / 1000),
      programComplete: isProgramComplete,
      roundArray: [],
      unitId,
      unitKey: unitId
    };

    this.elements.push(this.progressElement);

    return this;
  }

  private setActivityCompleted(): ProgressPayloadFactory {
    this.progressElement.activityComplete = [
      PositionChangeType.ActivityCompletion,
      PositionChangeType.LevelCompletion,
      PositionChangeType.PlacementCompletion
    ].includes(this.changeType);

    return this;
  }

  private setUnitCompleted(): ProgressPayloadFactory {
    this.progressElement.completed = [
      PositionChangeType.ActivityCompletion,
      PositionChangeType.EncounterCompletion,
      PositionChangeType.Join,
      PositionChangeType.LevelCompletion,
      PositionChangeType.OnboardingCompletion,
      PositionChangeType.PlacementCompletion,
      PositionChangeType.StepUp,
      PositionChangeType.UnitCompletion
    ].includes(this.changeType);

    return this;
  }

  private mapRounds(): ProgressPayloadFactory {
    this.progressElement.roundArray = this.savePoint.rounds.map(r =>
      this.transformRoundSaveInfo(r)
    );

    this.addImminentRoundMaybe();

    return this;
  }

  private transformRoundSaveInfo(info: IRoundSaveInfo): IRoundLexia {
    const attemptArray = info.attempts
      .filter(a => a.result !== TaskEvaluationResult.Inconclusive)
      .map(a => ({
        analyticsCorrelationId: a.correlationId,
        response: this.taskRegistry
          .get(info.taskType)
          .serializeAnswer(a.answer)
          .substring(0, 1000),
        success: a.result === TaskEvaluationResult.Correct
      }));

    return {
      __type__: StudentProgressPayloadType.Round,
      attemptArray,
      contentId: info.roundId,
      status: this.determineRoundStatus(info.isScored, attemptArray)
    };
  }

  private determineRoundStatus(
    isScored: boolean,
    attempts: IAttemptInfo[]
  ): RoundLexiaResult {
    if (!isScored) {
      return RoundLexiaResult.Excluded;
    }

    return attempts.some(attempt => attempt.success)
      ? attempts.length > 1
        ? RoundLexiaResult.Assisted
        : RoundLexiaResult.Correct
      : RoundLexiaResult.Wrong;
  }

  /**
   * Add the branchStep prop, if applicable
   * (i.e. when stepping down/up to/from instruction)
   */
  private addBranchMaybe(): ProgressPayloadFactory {
    const branch = this.maybeCreateStep(
      [PositionChangeType.StepDown, PositionChangeType.StepUp],
      this.changeType === PositionChangeType.StepDown
        ? Modality.Instruction
        : Modality.Standard
    );
    if (branch) {
      this.progressElement.branchStep = branch;
    }

    return this;
  }

  /**
   * Add the fork prop, if applicable
   * (i.e. when forking/joining to/from a forked path)
   */
  private addForkMaybe(): ProgressPayloadFactory {
    const fork = this.maybeCreateStep([
      PositionChangeType.Fork,
      PositionChangeType.Join
    ]);

    if (fork) {
      this.progressElement.fork = fork;
    }

    return this;
  }

  /**
   * Add imminent round, if applicable
   * (i.e. when current position's unit and next position's unit are the same)
   * Imminent rounds in the middle of branch/instruction units. The only time these
   * imminent rounds are not appended is when moving to a new unit / branching down/up.
   * These are already captured in a UnitAdvance/UnitProgress.
   */
  private addImminentRoundMaybe() {
    const { imminentPosition, mainUnit: unit } = this.context;

    if (unit.sysId !== imminentPosition?.activityPosition.unitPosition.unitId) {
      return;
    }

    this.progressElement.roundArray.push({
      __type__: StudentProgressPayloadType.Round,
      attemptArray: [],
      contentId: imminentPosition.activityPosition.unitPosition.roundId,
      status: RoundLexiaResult.Imminent
    });
  }

  private maybeCreateStep(
    changeTypes: PositionChangeType[],
    modality: Modality = Modality.Standard
  ): IStepLexia | undefined {
    if (!changeTypes.includes(this.changeType)) {
      return;
    }

    const { unitId } = this.context.imminentParentUnitPosition;

    return {
      __type__: StudentProgressPayloadType.Step,
      modality,
      unitId,
      unitKey: unitId,
      unitName: unitId
    };
  }

  /**
   * When the last round of a main unit forks, the student joins on
   * the first round of the next unit. In that case, this method
   * adds a completion element for the main unit (with 0 additional
   * rounds completed in 0 seconds), and modifies the completion of the fork
   * subunit
   */
  private appendCompletedForkedMainUnitMaybe(): ProgressPayloadFactory {
    const mainUnitId = this.context.mainUnit.sysId;
    const isAdvancingToNextMainUnit = [
      PositionChangeType.UnitCompletion,
      PositionChangeType.EncounterCompletion
    ].includes(this.changeType);
    if (!this.context.isSubunit || !isAdvancingToNextMainUnit) {
      return this;
    }

    // Adjust fork to indicate join to parent unit rather than destination
    this.progressElement.fork = {
      __type__: StudentProgressPayloadType.Step,
      modality: Modality.Standard,
      unitId: mainUnitId,
      unitKey: mainUnitId,
      unitName: mainUnitId
    };

    this.elements.push({
      __type__: StudentProgressPayloadType.UnitProgress,
      activityComplete: false,
      completed: true,
      duration: 0,
      programComplete: false,
      roundArray: [],
      unitId: mainUnitId,
      unitKey: mainUnitId
    });

    return this;
  }

  /**
   * Append an advance element to indicate the new position in the program when
   * completing a main unit and advancing to the next within the activity.
   * Stepping up/down, forking/joining do not advance, except in the case of
   * joining "after" the last round.
   * Completing an activity or level do not advance, as there no further main
   * unit to advance to.
   */
  private appendAdvanceMaybe(): ProgressPayloadFactory {
    if (
      [
        PositionChangeType.EncounterCompletion,
        PositionChangeType.UnitCompletion,
        PositionChangeType.OnboardingCompletion
      ].includes(this.changeType)
    ) {
      const { imminentPosition, level } = this.context;
      this.elements.push(
        UnitAdvanceFactory.create(
          imminentPosition.activityPosition,
          level.sysId,
          this.programMode
        )
      );
    }

    return this;
  }
}
