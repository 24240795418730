import { Align, Font, Justify, TextAlign } from 'common-styles';

export class StudentInfoStyles {
  private static readonly styles = {
    content: {
      alignItems: Align.Start,
      fontSize: Font.Size.small,
      textAlign: TextAlign.Left,
      width: 415
    },
    label: {
      fontSize: Font.Size.small,
      textAlign: TextAlign.Left
    },
    row: {
      alignItems: Align.Start,
      justifyContent: Justify.SpaceBetween,
      paddingVertical: 8,
      width: 540
    }
  };

  public static get() {
    return this.styles;
  }
}
