import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { PositionActionActivityDeselected } from 'curriculum-services';
import { PositionActionType } from 'curriculum-services/program-context';
import {
  LevelActionLoad,
  LevelActionType,
  LevelActionUnload
} from '../../level';
import { IUnitState } from './unit-redux.model';
import { UnitActions } from './Unit.action';
import { UnitActionType } from './UnitActionType';

type InputActions =
  | UnitActions
  | PositionActionActivityDeselected
  | LevelActionLoad
  | LevelActionUnload
  | UserGlobalActionLogoutSuccess;

const unitInitialState: IUnitState = {
  isLoading: false,
  parentUnits: [],
  unitContent: undefined
};

export function unitReducer(
  state = unitInitialState,
  action: InputActions
): IUnitState {
  switch (action.type) {
    case UnitActionType.Load:
      return {
        ...state,
        isLoading: true
      };

    case UnitActionType.LoadSuccess:
      return {
        ...action.payload,
        isLoading: false
      };

    case PositionActionType.ActivityDeselected:
    case LevelActionType.Load:
    case LevelActionType.Unload:
    case UserGlobalActionType.LogoutSuccess:
      return unitInitialState;

    default:
      return state;
  }
}
unitReducer.displayName = 'unitReducer';

export const UnitReducerPrivates = { initialState: unitInitialState };
