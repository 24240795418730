import { connect } from 'react-redux';
import { AppState } from 'services';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { ProfileSelector } from 'services/profile';
import { ModalRegistry, ModalType } from 'shared-components/modals';
import { SreSelector } from 'sre/redux';
import { MicSelectionModal } from './MicSelectionModal';

// istanbul ignore next - trivial code, not worth testing
function mapStateToProps(state: AppState) {
  return {
    isStudent: ProfileSelector.isStudent(state),
    openingVO: BootstrapContentSelector.getMicChangedContent(state)!.voiceover,
    selectedMic: SreSelector.getSelectedMic(state)
  };
}

/**
 * This component is triggered whenever the app notices a change
 * in the microphone
 */
export const MicChangedModal: any = connect(
  mapStateToProps,
  MicSelectionModal.mapDispatchToProps
)(MicSelectionModal);

MicChangedModal.ModalId = ModalRegistry.register({
  Component: MicChangedModal,
  preventDismiss: true,
  title: 'Microphone has changed',
  type: ModalType.Error
});
