import {
  IActivityPosition,
  IEncounterNode,
  ILevel,
  IProgramNode
} from '@lexialearning/lobo-common';
import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { ProgramNodeHelper } from './ProgramNode.helper';

export class EncounterPedigreeFactory {
  public static readonly displayName = 'EncounterPedigreeFactory';

  public static create(
    level: ILevel,
    activityPosition: IActivityPosition
  ): IEncounterNode {
    const builder = new EncounterPedigreeFactory(level, activityPosition);

    return builder.createProgramTree().wireUp().findActiveEncounterNode();
  }

  private programTree!: IProgramNode;

  private constructor(
    private readonly level: ILevel,
    private readonly activityPosition: IActivityPosition
  ) {}

  private createProgramTree(): EncounterPedigreeFactory {
    this.programTree = {
      ...ProgramNodeHelper.createUnwired(this.level, LoboContentType.Level),
      children: this.level.acts.map(a => ({
        ...ProgramNodeHelper.createUnwired(a, LoboContentType.Act),
        children: a.encounters.map(e => ({
          ...ProgramNodeHelper.createUnwired(e, LoboContentType.Encounter),
          children: e.units.map(u => ({
            ...ProgramNodeHelper.createUnwired(u, LoboContentType.Unit),
            children: u.rounds.map(r => ({
              ...ProgramNodeHelper.createUnwired(r, LoboContentType.Round)
            }))
          }))
        }))
      }))
    };

    return this;
  }

  private wireUp(
    parent: IProgramNode = this.programTree
  ): EncounterPedigreeFactory {
    ProgramNodeHelper.wireUp(parent);

    return this;
  }

  private findActiveEncounterNode(): IEncounterNode {
    const activityNode = this.programTree.children.find(
      a => a.content.sysId === this.activityPosition.activityId
    );
    if (!activityNode) {
      throw this.createError(
        'Unable to find active activity in level',
        EncounterPedigreeFactoryError.ActivityMismatched
      );
    }

    const encounterNode = activityNode.children.find(
      e => e.content.sysId === this.activityPosition.encounterId
    );
    if (!encounterNode) {
      throw this.createError(
        'Unable find active encounter in level activities',
        EncounterPedigreeFactoryError.EncounterMismatched
      );
    }

    return encounterNode as IEncounterNode;
  }

  private createError(
    message: string,
    code: EncounterPedigreeFactoryError
  ): LexiaError {
    throw new LexiaError(
      message,
      EncounterPedigreeFactory.displayName,
      code
    ).withContext({
      activityPosition: this.activityPosition,
      level: this.level
    });
  }
}

export enum EncounterPedigreeFactoryError {
  ActivityMismatched = 'ActivityMismatched',
  EncounterMismatched = 'EncounterMismatched'
}
