import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { IMicChangeEventPayload, LoboLogItemCategory } from 'logging';
import { SreConfigActionType, SreSelectMicAction, SreSelector } from 'sre';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logMicChangeEpic(
  action$: Observable<SreSelectMicAction>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SreConfigActionType.SelectMic),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.MicChange),
    ignoreElements()
  );
}
logMicChangeEpic.displayName = 'logMicChangeEpic';

function createEvent(
  _: unknown,
  state: AppState
): ILogItemMinimal<IMicChangeEventPayload, LoboLogItemCategory> {
  const { mic, micsAvailable } = SreSelector.getConfig(state);
  const payload: IMicChangeEventPayload = {
    mic,
    micsAvailable
  };

  return {
    category: LoboLogItemCategory.MicChange,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: `Mic changed to "${mic?.name}"`
  };
}
