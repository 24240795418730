import { Align, Color, Font } from 'common-styles';
import { IModalStyleOverride } from 'common-ui/components/modal/Modal.animated-styles';

export class OpenCommandLineModalStyles {
  private static readonly styles = {
    checkboxOverrides: {
      container: {
        alignSelf: Align.Start,
        marginBottom: 40
      },
      label: {
        fontSize: Font.Size.small
      }
    },
    container: {
      padding: 20
    },
    titleText: {
      alignSelf: Align.Start,
      fontSize: Font.Size.small
    },
    urlInput: {
      input: {
        borderColor: Color.Gold,
        borderRadius: 8,
        borderWidth: 2,
        flex: 0,
        height: 60,
        marginBottom: 25,
        marginTop: 5,
        padding: 5
      }
    }
  };

  public static get() {
    return this.styles;
  }

  // istanbul ignore next - trivial code, not worth testing
  public static getModalOverride(): IModalStyleOverride {
    return {
      modal: {
        top: 60
      }
    };
  }
}
