export enum Justify {
  Center = 'center',
  Start = 'flex-start',
  End = 'flex-end',
  SpaceAround = 'space-around',
  SpaceBetween = 'space-between'
}

/**
 * Values to be used for align styles (alignSelf, alignContent, alignItems)
 *
 * NOTE: Align.Auto is not used in the project so far and is therefore not included. This allows the same enum to be
 * used for all align styles  without causing a typing error. If we do end up wanting/needing to add an 'auto' option
 * at some point, we will need a separate enum for alignItems as that does not allow an 'auto' value
 */
export enum Align {
  Center = 'center',
  Start = 'flex-start',
  End = 'flex-end',
  Stretch = 'stretch'
}

export enum TextAlign {
  Auto = 'auto',
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify'
}

/**
 * Not compatible with native, careful in using
 */
export enum TransformOrigin {
  Top = 'top',
  Left = 'left',
  Right = 'right',
  Center = 'center'
}
