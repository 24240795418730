import { G, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function ShapeCheckSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G
      data-test-id={ShapeCheckSvg.displayName}
      id="shape_check"
      transform="translate(-36.4 -114.375)"
    >
      {shadowColor && (
        <Path
          dataname="shadow"
          d="M96.574 186.262a6.8 6.8 0 01-5.033 2.193 6.96 6.96 0 01-4.947-2.194L66.72 166.395a7.3 7.3 0 1110.038-10.6c.1.092.2.189.288.288l14.54 14.54 30.285-30.284a6.963 6.963 0 014.949-2.2 8.144 8.144 0 017.571 7.571 6.954 6.954 0 01-2.195 4.948z"
          fill={shadowColor}
        />
      )}
      <Path
        d="M95.574 184.262a6.8 6.8 0 01-5.033 2.193 6.96 6.96 0 01-4.947-2.194L65.72 164.395a7.3 7.3 0 1110.038-10.6c.1.092.2.189.288.288l14.54 14.54 30.285-30.284a6.963 6.963 0 014.949-2.2 8.144 8.144 0 017.571 7.571 6.954 6.954 0 01-2.195 4.948z"
        fill={iconColor}
      />
    </G>
  );
}
ShapeCheckSvg.displayName = 'ShapeCheckSvg';
