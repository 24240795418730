import * as React from 'react';
import { BackgroundImage, InputImageSource } from 'common-ui/components/image';
import {
  AnimatedView,
  IAnimatedViewProps
} from 'common-ui/components/view/AnimatedView';
import { BouncingGabberAnimatedStyles } from './BouncingGabber.animated-styles';

export interface IBouncingGabberProps extends IAnimatedViewProps {
  source: InputImageSource;
  delay: number;
}

export class BouncingGabber extends React.PureComponent<IBouncingGabberProps> {
  private readonly animatedStyles: BouncingGabberAnimatedStyles;

  constructor(props: IBouncingGabberProps) {
    super(props);

    const { delay } = props;

    this.animatedStyles = new BouncingGabberAnimatedStyles(delay);
    this.startAnimation = this.startAnimation.bind(this);
  }

  public componentDidMount() {
    this.startAnimation();
  }

  public startAnimation() {
    const { pop } = this.animatedStyles.getAnimations();

    pop.start(this.startAnimation);
  }

  public render() {
    const { source } = this.props;
    const styles = this.animatedStyles.get();

    return (
      <AnimatedView animatedStyle={styles.containerAnimated}>
        <BackgroundImage source={source} style={styles.gabberImage} />
      </AnimatedView>
    );
  }
}
