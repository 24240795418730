export const enum DeployEnvironmentUrls {
  Develop = 'https://lobo-develop.lexia-dev.com',
  DevelopDebug = 'https://lobo-develop-debug.lexia-dev.com',
  DevelopLatest = 'https://lobo-develop-latest.lexia-dev.com',
  FeatureAsh = 'https://lobo-ash.lexia-dev.com',
  FeatureAshDebug = 'https://lobo-ash-debug.lexia-dev.com',
  FeatureElm = 'https://lobo-elm.lexia-dev.com',
  FeatureElmDebug = 'https://lobo-elm-debug.lexia-dev.com',
  FeatureOak = 'https://lobo-oak.lexia-dev.com',
  FeatureOakDebug = 'https://lobo-oak-debug.lexia-dev.com',
  Main = 'https://lobo-main.lexia-dev.com',
  MainDebug = 'https://lobo-main-debug.lexia-dev.com',
  MainLatest = 'https://lobo-main-latest.lexia-dev.com',
  Production = 'https://english.lexialearning.com',
  Staging = 'https://english-staging.lexialearning.com',
  StagingDebug = 'https://english-staging-debug.lexialearning.com'
}
