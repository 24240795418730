import { Color } from 'common-styles';
import { CalibrationSharedStyles } from '../CalibrationShared.styles';

export class CalibrationMicSetupTipsStyles {
  private static readonly styles = {
    setupTips: {
      color: Color.Gray70,
      fontSize: 24,
      lineHeight: 48
    },
    setupTipsContainer: {
      marginVertical: 50
    },
    setupTipsTitle: {
      ...CalibrationSharedStyles.get().title
    }
  };

  public static get() {
    return this.styles;
  }
}
