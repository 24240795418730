import { actToEncounterTransitionEpic } from './act-to-encounter/actToEncounterTransition.epic';
import { calibrationToNextTransitionEpic } from './calibration-to-next/calibrationToNextTransition.epic';
import { educatorToEncounterTransitionEpic } from './educator-to-encounter/educatorToEncounterTransition.epic';
import { encounterToEducatorTransitionEpic } from './encounter-to-educator/encounterToEducatorTransition.epic';
import { gettingStartedToCalibrationTransitionEpic } from './getting-started-to-calibration/gettingStartedToCalibrationTransition.epic';
import { homeToActTransitionEpic } from './home-to-act/homeToActTransitionEpic.epic';
import { homeToLevelIntroTransitionEpic } from './home-to-level-intro/homeToLevelIntroTransition.epic';
import { homeToNextTransitionEpic } from './home-to-next/homeToNextTransition.epic';
import { introductionToNextTransitionEpic } from './introduction-to-next/introductionToNextTransition.epic';
import { levelCompleteToNextTransitionEpic } from './round-to-next/level-complete/levelCompleteToNextTransition.epic';
import { levelIntroToLevelTransitionEpic } from './level-intro-to-level/levelIntroToLevelTransition.epic';
import { levelToActTransitionEpic } from './level-to-act/levelToActTransition.epic';
import { placementCompleteToLevelTransitionEpic } from './placement-complete-to-level/placementCompleteToLevelTransition.epic';
import { roundToNextTransitionEpic } from './round-to-next/roundToNextTransition.epic';
import { loginToIntroductionTransitionEpic } from './login-to-introduction/loginToIntroductionTransition.epic';
import { avatarToNextTransitionEpic } from './avatar-to-next/avatarToNextTransition.epic';

export const transitionEpics = [
  actToEncounterTransitionEpic,
  avatarToNextTransitionEpic,
  calibrationToNextTransitionEpic,
  educatorToEncounterTransitionEpic,
  encounterToEducatorTransitionEpic,
  gettingStartedToCalibrationTransitionEpic,
  homeToActTransitionEpic,
  homeToLevelIntroTransitionEpic,
  homeToNextTransitionEpic,
  introductionToNextTransitionEpic,
  levelCompleteToNextTransitionEpic,
  levelIntroToLevelTransitionEpic,
  levelToActTransitionEpic,
  loginToIntroductionTransitionEpic,
  placementCompleteToLevelTransitionEpic,
  roundToNextTransitionEpic
];
