import {
  AvatarEditorActionToStatusMap,
  AvatarEditorActionType,
  AvatarTimerDurationMs,
  AvatarTimerStatus,
  ENCOUNTERS_TO_ENABLE_REEDIT,
  IAvatarEditorState
} from './avatar-editor-redux.model';
import { AvatarEditorActions } from './AvatarEditor.action';
import { LexiaError } from '@lexialearning/utils';
import { AvatarEditorEvent } from '@lexialearning/lobo-common/main-model/logging';
import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ProfileActionType, ProfileActionUpdateAvatar } from 'services/profile';

export const avatarEditorInitialState: IAvatarEditorState = {
  encountersUntilEditable: ENCOUNTERS_TO_ENABLE_REEDIT,
  shouldShowEditor: false,
  timer: {
    duration: AvatarTimerDurationMs.Editing,
    extensionsRemaining: 10,
    status: AvatarTimerStatus.Ready
  },
  userEvents: []
};

export function avatarEditorReducer(
  state = avatarEditorInitialState,
  action:
    | AvatarEditorActions
    | ProfileActionUpdateAvatar
    | UserGlobalActionLogout
): IAvatarEditorState {
  switch (action.type) {
    case AvatarEditorActionType.AddUserEvent:
      return {
        ...state,
        userEvents: [
          ...state.userEvents,
          {
            ...action.payload,
            timestamp: Date.now()
          }
        ]
      };

    case AvatarEditorActionType.Reset:
      return {
        ...avatarEditorInitialState,
        encountersUntilEditable: state.encountersUntilEditable
      };

    case AvatarEditorActionType.SetEncountersUntilEditable:
      return { ...state, encountersUntilEditable: action.payload };

    case AvatarEditorActionType.ShowEditor:
      return {
        ...state,
        shouldShowEditor: true,
        userEvents: [
          ...state.userEvents,
          {
            data: { avatar: action.payload },
            eventName: AvatarEditorEvent.AvatarEntry,
            timestamp: Date.now()
          }
        ]
      };

    case AvatarEditorActionType.TimerPause:
    case AvatarEditorActionType.TimerRestart:
    case AvatarEditorActionType.TimerStart:
      return {
        ...state,
        timer: {
          ...state.timer,
          status: getTimerStatus(action.type)
        }
      };

    case AvatarEditorActionType.TimerSetDuration:
      return {
        ...state,
        timer: {
          ...state.timer,
          duration: action.payload
        }
      };

    case AvatarEditorActionType.TimerTimeout:
      return {
        ...state,
        timer: {
          duration: AvatarTimerDurationMs.Extension,
          extensionsRemaining: Math.max(0, state.timer.extensionsRemaining - 1),
          status: AvatarTimerStatus.Finished
        }
      };

    case ProfileActionType.UpdateAvatar:
      const [[property, value]] = Object.entries(action.payload);

      return {
        ...state,
        userEvents: [
          ...state.userEvents,
          {
            data: {
              property,
              value
            },
            eventName: AvatarEditorEvent.AvatarSelectionUpdated,
            timestamp: Date.now()
          }
        ]
      };

    case UserGlobalActionType.Logout:
      return {
        ...state,
        userEvents: [
          ...state.userEvents,
          {
            data: { logoutReason: action.payload },
            eventName: AvatarEditorEvent.LogoutConfirmed,
            timestamp: Date.now()
          }
        ]
      };

    default:
      return state;
  }
}
avatarEditorReducer.displayName = 'avatarEditorReducer';

function getTimerStatus(actionType: AvatarEditorActionType): AvatarTimerStatus {
  const status = AvatarEditorActionToStatusMap.get(actionType);

  // istanbul ignore next - unreachable, but could be reached if the map or action types are modified
  if (!status) {
    throw new LexiaError(
      `Action type ${actionType} does not exist in status map`,
      avatarEditorReducer.displayName,
      AvatarEditorReducerError.InvalidActionType
    );
  }

  return status;
}

export enum AvatarEditorReducerError {
  InvalidActionType = 'InvalidActionType'
}
