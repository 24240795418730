import {
  ITextOrderingSegment,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { DndSelector } from 'dnd/redux';
import {
  ITaskActionUpdateCanSubmitPayload,
  TaskAction,
  TaskSelector
} from 'task-components/core';
import { SubmitButton } from '../../submit-button';
import { TextSegmentAnimatedStyles } from '../../text-segment/TextSegment.animated-styles';
import { IOrderingAnswer } from '../ordering.model';
import { ISegment, OrderingHelper } from './helpers';

export { ISegment };

export interface IOrderingContainerProps {
  orderedSegments: ITextOrderingSegment[];
  renderContent(
    segments: ISegment[],
    moveSegment: (targetIndex: number, segmentIndex: number) => void
  ): React.ReactElement;
  onEvaluated(result: TaskEvaluationResult, answer: IOrderingAnswer): void;
  updateCanSubmit(canSubmit: ITaskActionUpdateCanSubmitPayload): void;
  extraData?: any;
  phase: TaskPhase;
  isAnimating: boolean;
}

export interface IOrderingContainerState {
  segments: ISegment[];
  submitPending: boolean;
}

export class OrderingContainerComponent extends React.PureComponent<
  IOrderingContainerProps,
  IOrderingContainerState
> {
  public static readonly displayName = 'OrderingContainer';

  constructor(props: IOrderingContainerProps) {
    super(props);

    const { orderedSegments } = props;

    const initialSegments =
      OrderingHelper.buildInitialSegments(orderedSegments);
    const shuffledSegments = OrderingHelper.shuffle(initialSegments);

    this.state = {
      segments: shuffledSegments,
      submitPending: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.moveSegment = this.moveSegment.bind(this);
    this.orderSegmentsCorrectly = this.orderSegmentsCorrectly.bind(this);
  }

  public componentDidUpdate(prevProps: IOrderingContainerProps) {
    const { phase, isAnimating } = this.props;

    if (prevProps.isAnimating && !isAnimating && this.state.submitPending) {
      this.handleSubmit();
    }

    if (
      prevProps.phase !== TaskPhase.Solution &&
      phase === TaskPhase.Solution
    ) {
      this.orderSegmentsCorrectly();
    }
  }

  public handleSubmit(): void {
    const { updateCanSubmit, isAnimating } = this.props;
    const { submitPending } = this.state;

    if (isAnimating) {
      this.setState({ submitPending: true });

      return;
    }

    updateCanSubmit({ canSubmit: false });

    if (submitPending) {
      setTimeout(() => {
        this.onEvaluated();
      }, TextSegmentAnimatedStyles.AnimationDuration);

      this.setState({ submitPending: false });

      return;
    }

    this.onEvaluated();
  }

  private onEvaluated(): void {
    const { orderedSegments, onEvaluated } = this.props;
    const { segments } = this.state;

    const segmentContents = segments.map(segment => segment.content);
    const isCorrect = OrderingHelper.evaluate(segments, orderedSegments);

    onEvaluated(
      isCorrect ? TaskEvaluationResult.Correct : TaskEvaluationResult.Incorrect,
      { segments: segmentContents }
    );
  }

  public moveSegment(targetIndex: number, sourceIndex: number) {
    if (targetIndex === sourceIndex) {
      return;
    }

    const { segments } = this.state;

    const reorderedSegments = OrderingHelper.reorderItems(
      sourceIndex,
      targetIndex,
      segments
    );

    this.setState({ segments: reorderedSegments });
    const { updateCanSubmit } = this.props;
    updateCanSubmit({ canSubmit: true });
  }

  private orderSegmentsCorrectly() {
    const { orderedSegments } = this.props;

    this.setState({
      segments: OrderingHelper.buildInitialSegments(orderedSegments)
    });
  }

  public render() {
    const { renderContent } = this.props;
    const { segments } = this.state;

    return (
      <>
        {renderContent(segments, this.moveSegment)}
        <SubmitButton onPress={this.handleSubmit} />
      </>
    );
  }
}

const mapStateToProps = (state: unknown) => ({
  isAnimating: DndSelector.getIsDraggingOrDropping(state),
  phase: TaskSelector.getPhase(state)
});

const mapDispatchToProps = {
  onEvaluated: (result: TaskEvaluationResult, answer: IOrderingAnswer) =>
    TaskAction.evaluated({ answer, result }),
  updateCanSubmit: (cs: ITaskActionUpdateCanSubmitPayload) =>
    TaskAction.updateCanSubmit(cs)
};

export const OrderingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderingContainerComponent);
