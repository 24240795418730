import { IRound, ITask } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ActivityPositionActionChanged,
  ActivityPositionActionType
} from 'curriculum-services/program-context/redux';
import {
  CurriculumDependencies,
  ProgramContextSelector,
  ProgramContextService,
  UnitActionLoadSuccess,
  UnitActionType
} from 'curriculum-services';
import { ITaskRegistration, TaskRegistry } from '..';
import { ModelingHelper } from '../modeling';
import { TaskAction, TaskActionLoaded } from '../redux';

export interface ILoadTaskDeps {
  curriculumDependencies: CurriculumDependencies;
  taskRegistry: TaskRegistry;
}

/**
 * Reactively "load" a task whenever unit(s) have been loaded or the activity
 * position has changed. Note that activity position changes can lead to unit
 * loads (e.g. stepping up/down), in which case we should not yet load the
 * task. (But some activity changes do not cause unit loads.)
 * Loading the task means copying (and potentially modifying, such as shuffling
 * choices) the task content from the active round and mounting it onto the
 * task redux state.
 *
 * TODO Move modeling onto diff epic
 */
export function loadTaskEpic(
  action$: Observable<UnitActionLoadSuccess | ActivityPositionActionChanged>,
  state$: StateObservable<unknown>,
  deps: ILoadTaskDeps
): Observable<TaskActionLoaded> {
  return action$.pipe(
    ofType(UnitActionType.LoadSuccess, ActivityPositionActionType.Changed),
    filter(() =>
      isUnitLoaded(
        deps.curriculumDependencies.programContextService,
        state$.value
      )
    ),
    map(() => {
      const {
        curriculumDependencies: { programContextService },
        taskRegistry
      } = deps;
      const state = state$.value;
      const roundUnprepared = programContextService.findRoundUnprepared(state);
      const taskUnprepared = getTask(roundUnprepared);
      const registration = taskRegistry.get(taskUnprepared.taskType);

      const taskContent = prepareContent(taskUnprepared, registration, state);
      const modeling = ModelingHelper.addReduxActions(
        registration.buildModeling(taskContent)
      );

      return TaskAction.loaded({
        modeling,
        roundId: roundUnprepared.sysId,
        taskContent
      });
    })
  );
}
loadTaskEpic.displayName = 'loadTaskEpic';

/**
 * If the unit content does not match the active position, we assume the
 * position just changed and the matching unit has not yet loaded, so we ignore
 * this action and will instead wait for the unit loaded action.
 */
function isUnitLoaded(
  programContextService: ProgramContextService,
  state: unknown
): boolean {
  return programContextService.determineContentReadiness(state).unit;
}

function getTask(round: IRound): ITask {
  const { task } = round;

  if (!task) {
    throw new LexiaError(
      'Round lacks a task',
      loadTaskEpic.displayName,
      LoadTaskEpicError.TaskMissing
    );
  }

  return task;
}

function prepareContent(
  task: ITask,
  registration: ITaskRegistration,
  state: unknown
): ITask {
  const partialContext = ProgramContextSelector.getEncounterContext(state);

  return registration.prepareContent(task, partialContext);
}

export enum LoadTaskEpicError {
  TaskMissing = 'TaskMissing'
}
