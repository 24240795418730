import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, TextAlign } from 'common-styles';
import { ThemeType } from 'theme';
import { FlexWrap } from '@lexialearning/common-ui';

export interface ITextPromptStyleOverride {
  container?: {
    alignItems?: Align;
    height?: number;
    marginBottom?: number;
    maxWidth?: number;
    width?: number;
  };
  text?: {
    textAlign?: TextAlign;
    color?: string;
  };
}

export class TextPromptStyles {
  public static readonly build = memoizeOne(
    (themeType: ThemeType, styleOverride?: ITextPromptStyleOverride) =>
      merge(
        {},
        {
          container: {
            flexWrap: FlexWrap.Wrap,
            marginBottom: 30,
            maxWidth: 695
          },
          text: {
            color:
              themeType === ThemeType.Instruction
                ? Color.TextOpposite
                : Color.Text,
            textAlign: TextAlign.Left
          }
        },
        styleOverride
      )
  );
}
