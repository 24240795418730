import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppShellActionType } from 'feature-areas/shell';
import { MicAction, MicActionSetForceDisabled } from 'common-ui';
import { TimingUtil } from 'utils';
import {
  AppShellActionHideModal,
  AppShellActionShowModal
} from '../AppShell.action';

export function updateMicForceDisabledEpic(
  action$: Observable<AppShellActionShowModal | AppShellActionHideModal>
): Observable<MicActionSetForceDisabled> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal, AppShellActionType.HideModal),
    mergeMap(async a => {
      if (a.type === AppShellActionType.ShowModal) {
        return true;
      }

      // Delay dispatching when setting forceDisabled to false
      // in order to avoid modal close sfx and other sfx (eg, mic enabled)
      // from playing concurrently
      await TimingUtil.delay(400);

      return false;
    }),
    map(MicAction.setForceDisabled)
  );
}
updateMicForceDisabledEpic.displayName = 'updateMicInteractionStateEpic';
