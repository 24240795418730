/* eslint-disable import/order, import/no-unassigned-import */
/* istanbul ignore file */
import './polyfills/crypto-polyfill';
import { Provider } from 'react-redux';
import { App as RxApp } from '@lexialearning/reactxp';
import { UserInterface } from 'common-ui/helpers';
import { appConfig } from 'services/app-config/appConfig';
import { App } from 'app/App';
import { AppBootstrapping } from 'services/bootstrapping/App.bootstrapping';

RxApp.initialize(appConfig.isDebug, false);

UserInterface.setMainView(
  <Provider store={AppBootstrapping.bootstrap().store}>
    <App />
  </Provider>
);
