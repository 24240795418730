import { uniqueId } from 'lodash';
import * as React from 'react';
import { Color, InteractionState } from 'common-styles';
import {
  GlossyButtonColorSet,
  GlossyButtonIconType,
  IKeyNavGlossyButtonColorSet
} from './glossy-button-svg.model';
import {
  EditIconSvg,
  CheckIconSvg,
  LookBackIconSvg,
  MicIconSvg,
  PlayIconSvg,
  QuestionMarkIconSvg,
  RehearIconSvg,
  ReplayIconSvg,
  ShapeCheckSvg,
  ShapeXSvg,
  SkipIconSvg
} from './icons';
import {
  GlossyButtonIconSvg,
  IGlossyButtonIconSvgProps
} from './icons/glossy-button-icon-svg.model';
import { KeyNavHelper } from '../../../../helpers';
import { ActiveComponentState } from '@lexialearning/common-ui';

export interface IGlossyButtonBaseSvgProps {
  activeComponentState: ActiveComponentState;
  interactionState: InteractionState;
  icon?: GlossyButtonIconType | React.JSX.Element;
  accessibilityLabel?: string;
}

export abstract class GlossyButtonBaseSvg<
  P extends IGlossyButtonBaseSvgProps
> extends React.PureComponent<P> {
  public static displayName = 'GlossyButtonBase.lx-svg';

  protected readonly id: string;

  constructor(props: P) {
    super(props);
    this.id = uniqueId('glossy_button');
  }

  protected getButtonColorSetName(): GlossyButtonColorSet {
    const { activeComponentState, interactionState } = this.props;

    switch (interactionState) {
      case InteractionState.Correct:
        return GlossyButtonColorSet.Green;
      case InteractionState.Incorrect:
        return GlossyButtonColorSet.Red;
      case InteractionState.Highlighted:
        return GlossyButtonColorSet.Yellow;
      case InteractionState.Inconclusive:
        return GlossyButtonColorSet.BlackGray;
      case InteractionState.Default:
      default:
        switch (activeComponentState) {
          case ActiveComponentState.Callout:
          case ActiveComponentState.Hovered:
            return GlossyButtonColorSet.BlueHovered;
          case ActiveComponentState.Pressed:
            return GlossyButtonColorSet.BluePressed;
          case ActiveComponentState.Default:
          default:
            return GlossyButtonColorSet.Blue;
        }
    }
  }

  protected getKeyNavColorSet(): IKeyNavGlossyButtonColorSet {
    const { activeComponentState, interactionState } = this.props;
    const colorSet = Color.getSetFor(ActiveComponentState.KeyboardFocused);
    const defaultColors = {
      fill: colorSet.background,
      icon: colorSet.text,
      outerStroke: colorSet.background,
      stroke: colorSet.border
    };

    switch (interactionState) {
      case InteractionState.Correct:
        return {
          ...defaultColors,
          icon: Color.MidGreen,
          outerStroke: Color.Black,
          stroke: Color.Green
        };
      case InteractionState.Incorrect:
        return {
          ...defaultColors,
          icon: Color.DarkRed,
          outerStroke: Color.Black,
          stroke: Color.Red
        };
      case InteractionState.Highlighted:
        return {
          ...defaultColors,
          fill: Color.Gold,
          outerStroke: Color.Gold
        };
      case InteractionState.Disabled:
        return {
          ...defaultColors,
          fill: Color.Gray50,
          icon: Color.Gray30,
          outerStroke: Color.Gray30
        };
      default:
        return activeComponentState === ActiveComponentState.KeyboardPressed
          ? {
              ...defaultColors,
              fill: Color.getSetFor(ActiveComponentState.KeyboardPressed)
                .background
            }
          : defaultColors;
    }
  }

  protected getAccessibilityLabel(): string {
    if (this.props.accessibilityLabel) {
      return this.props.accessibilityLabel;
    }

    switch (this.props.icon) {
      case GlossyButtonIconType.Check:
      case undefined:
        return 'Submit';
      case GlossyButtonIconType.Edit:
        return 'Edit your avatar.';
      case GlossyButtonIconType.Mic:
        return 'Microphone';
      case GlossyButtonIconType.Play:
        return 'Continue';
      case GlossyButtonIconType.Rehear:
        return 'Rehear presentation';
      case GlossyButtonIconType.Replay:
        return 'Replay Video';
      case GlossyButtonIconType.QuestionMark:
        return 'Rehear directions';
      case GlossyButtonIconType.LookBack:
        return 'Rehear passage';
      case GlossyButtonIconType.ShapeCheck:
        return 'Yes';
      case GlossyButtonIconType.ShapeX:
        return 'No';
      case GlossyButtonIconType.Skip:
        return 'Skip Intro';
      default:
        return '';
    }
  }

  private getIconComponent(): GlossyButtonIconSvg | undefined {
    switch (this.props.icon) {
      case GlossyButtonIconType.Check:
      case undefined:
        return CheckIconSvg;
      case GlossyButtonIconType.Edit:
        return EditIconSvg;
      case GlossyButtonIconType.Mic:
        return MicIconSvg;
      case GlossyButtonIconType.Play:
        return PlayIconSvg;
      case GlossyButtonIconType.Rehear:
        return RehearIconSvg;
      case GlossyButtonIconType.Replay:
        return ReplayIconSvg;
      case GlossyButtonIconType.QuestionMark:
        return QuestionMarkIconSvg;
      case GlossyButtonIconType.LookBack:
        return LookBackIconSvg;
      case GlossyButtonIconType.ShapeCheck:
        return ShapeCheckSvg;
      case GlossyButtonIconType.ShapeX:
        return ShapeXSvg;
      case GlossyButtonIconType.Skip:
        return SkipIconSvg;
      default:
        return undefined;
    }
  }

  protected renderIcon(
    iconProps: IGlossyButtonIconSvgProps
  ): React.ReactElement {
    const Icon = (this.getIconComponent() ||
      this.props.icon) as GlossyButtonIconSvg;

    return <Icon {...iconProps} />;
  }

  // Methods to be defined in the classes which extends this
  protected abstract renderGlossy(): any;
  protected abstract renderFlat(): any;

  public render() {
    return KeyNavHelper.isKeyNav(this.props.activeComponentState)
      ? this.renderFlat()
      : this.renderGlossy();
  }
}
