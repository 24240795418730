import memoizeOne from 'memoize-one';
import { XYCoord } from 'react-dnd';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { Position } from '@lexialearning/common-ui';

export class PositionTransitionAnimatedStyles {
  private readonly animatedValues: {
    translateX: Types.AnimatedValue;
    translateY: Types.AnimatedValue;
  };

  constructor() {
    const translateX = loboAnimated.createValue(0);
    const translateY = loboAnimated.createValue(0);

    this.animatedValues = {
      translateX,
      translateY
    };
  }

  public buildAnimations(
    startPosition: XYCoord,
    endPosition: XYCoord,
    transitionDuration?: number
  ) {
    const { translateX, translateY } = this.animatedValues;
    const duration = transitionDuration ?? 250;

    const transitionAnimation = loboAnimated.parallel([
      loboAnimated.timing(AnimatableCSSProperty.TranslateX, translateX, {
        duration,
        easing: loboAnimated.Easing.InOut(),
        toValue: endPosition.x - startPosition.x
      }),
      loboAnimated.timing(AnimatableCSSProperty.TranslateY, translateY, {
        duration,
        easing: loboAnimated.Easing.InOut(),
        toValue: endPosition.y - startPosition.y
      })
    ]);

    return {
      transitionAnimation
    };
  }

  public updateAnimatedValues(
    translateXValue: number,
    translateYValue: number
  ) {
    const { translateX, translateY } = this.animatedValues;
    translateX.setValue(translateXValue);
    translateY.setValue(translateYValue);
  }

  private readonly buildMemoized = memoizeOne(
    (
      currentPosition: XYCoord | undefined,
      offset: XYCoord | undefined,
      translateX: Types.AnimatedValue = this.animatedValues.translateX,
      translateY: Types.AnimatedValue = this.animatedValues.translateY
    ) => ({
      absolutePositionedWrapper: {
        position: Position.Absolute,
        ...(currentPosition
          ? {
              left: currentPosition.x - (offset?.x || 0),
              opacity: 1,
              top: currentPosition.y - (offset?.y || 0)
            }
          : {
              left: -(offset?.x || 0),
              opacity: 0,
              top: -(offset?.y || 0)
            })
      },
      absolutePositionedWrapperAnimated: {
        transform: [{ translateX }, { translateY }]
      }
    })
  );

  public readonly build = (
    currentPosition: XYCoord | undefined,
    offset: XYCoord | undefined
  ) => this.buildMemoized(currentPosition, offset);
}
