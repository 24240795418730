import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  ILanguageFrameToken,
  IWordWeights
} from '@lexialearning/lobo-common/tasks/see-speak';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ILoboAppConfig } from 'services/app-config';
import {
  IListenResultActionPayload,
  LanguageFrameSession as LF,
  LanguageFrameOptionsFactory,
  SreSessionActionListenResult,
  SreSessionActionType,
  SreSessionType
} from 'sre';
import { LanguageFrameManager } from '../language-frame/LanguageFrameManager';
import { SeeSpeakActionType } from '../redux/see-speak-redux.model';
import {
  SeeSpeakAction,
  SeeSpeakActionSetSreOptions,
  SeeSpeakActionSetSreOptionsSuccess
} from '../redux/SeeSpeak.action';
import { SeeSpeakSelector } from '../redux/SeeSpeak.selector';
import { IToken } from '@lexialearning/sre';
import { SoundLogCollectionModeDeterminer } from 'sre/epics/SoundLogCollectionModeDeterminer';
import { ConfigSelector } from '@lexialearning/utils-react';
import { AppState } from 'services';
import { PayloadAction } from 'typesafe-actions';

export interface ISetSreOptionsDeps {
  configProvider: IConfigProvider;
}

/**
 * Set the SRE Options for the Language Frame session used for
 * See Speak tasks.
 * - Determine the sound log collection mode based on configuration
 *   and loaded policies.
 * - Apply configured weights and scoring strategy
 * - Create tokens via the LanguageFrameManager
 * OR
 * - Just update soundLogCollectionMode if invoked by SreSessionActionType.ListenResult

 * @see SoundLogCollectionModeDeterminer
 * @see LanguageFrameManager
 * @see LanguageFrameOptionsFactory
 */
export function setSreOptionsEpic(
  action$: Observable<
    SeeSpeakActionSetSreOptions | SreSessionActionListenResult
  >,
  state$: StateObservable<AppState>
): Observable<SeeSpeakActionSetSreOptionsSuccess | void> {
  return action$.pipe(
    ofType(SeeSpeakActionType.SetSreOptions, SreSessionActionType.ListenResult),
    map(action => {
      if (action.type === SreSessionActionType.ListenResult) {
        return maybeUpdateSoundLogCollectionMode(action, state$.value);
      }

      const options = createSreOptions(
        action.payload.languageFrameTokens,
        state$.value
      );

      return SeeSpeakAction.setSreOptions.success(options);
    }),
    filter(a => !!a)
  );
}
setSreOptionsEpic.displayName = 'setSreOptionsEpic';

/**
 * Get the SRE Options for the Language Frame session used for
 * See Speak tasks.
 * - Determine the sound log collection mode based on configuration
 * and loaded policies.
 * - Apply configured weights and scoring strategy
 * - Create tokens via the LanguageFrameManager
 *
 * @see LanguageFrameManager
 * @see LanguageFrameOptionsFactory
 */
function createSreOptions(
  tokens: ILanguageFrameToken[],
  state: AppState
): LF.IConfig {
  const {
    seeSpeak: { scoring, wordWeights }
  } = ConfigSelector.getConfig<ILoboAppConfig>(state);
  const soundLogCollectionMode = determineSoundLogCollectionMode(state);

  return LanguageFrameOptionsFactory.create(
    createSreTokens(tokens, wordWeights),
    soundLogCollectionMode,
    scoring
  );
}

function createSreTokens(
  tokens: ILanguageFrameToken[],
  weights: IWordWeights
): IToken[] {
  const lf = LanguageFrameManager.manage(tokens);

  return lf.tokens.map(token => ({
    text: token.text,
    weight: lf.getScoringWeight(token, weights),
    wordType: lf.getWordType(token)
  }));
}

/**
 * Returns
 * - undefined --if not a LF/PrimedLF session type--
 * OR
 * - SeeSpeakAction.setSreOptions.success with the current options, but with
 *   an updated soundLogCollectionMode
 */
function maybeUpdateSoundLogCollectionMode(
  action: PayloadAction<
    SreSessionActionType.ListenResult,
    IListenResultActionPayload
  >,
  state: AppState
): SeeSpeakActionSetSreOptionsSuccess | void {
  if (
    ![
      SreSessionType.LanguageFrame,
      SreSessionType.PrimedLanguageFrame
    ].includes(action.payload.sessionOptions.sessionType)
  ) {
    // Completed session was not LF/PrimedLF, so do nothing
    return;
  }

  // Completed session was LF/PrimedLF, so update soundLogCollectionMode
  // determination for next session
  const options = {
    ...(action.payload.sessionOptions as LF.IConfig),
    soundLogCollectionMode: determineSoundLogCollectionMode(state)
  };

  return SeeSpeakAction.setSreOptions.success(options);
}

function determineSoundLogCollectionMode(state: AppState) {
  const { sre: sreConfig } = ConfigSelector.getConfig<ILoboAppConfig>(state);
  const shouldReturnDefault = SeeSpeakSelector.isChoral(state);

  return SoundLogCollectionModeDeterminer.determine(
    sreConfig,
    SreSessionType.LanguageFrame,
    shouldReturnDefault
  );
}
