import memoizeOne from 'memoize-one';
import { Align, Color, ShadowStyle } from 'common-styles';
import { Types } from 'common-ui';

export interface IFunFactButtonStyle {
  buttonText: Types.TextStyle;
  button: Types.ViewStyle;
}

export class FunFactButtonStyles {
  private static computeBackgroundColor(
    disabled: boolean,
    isPlaying: boolean
  ): string {
    return disabled
      ? Color.BlackTransparent04
      : isPlaying
      ? Color.Yellow
      : Color.Gold;
  }

  public static readonly build = memoizeOne(
    (disabled: boolean, isPlaying: boolean) => ({
      button: {
        alignItems: Align.Center,
        backgroundColor: FunFactButtonStyles.computeBackgroundColor(
          disabled,
          isPlaying
        ),
        borderRadius: 19,
        height: 30,
        marginHorizontal: 2,
        marginVertical: 4,
        minWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingVertical: 0,
        width: 30,
        ...ShadowStyle.button
      },
      buttonText: {
        color: Color.Black,
        flex: 1,
        fontSize: 16
      }
    })
  );
}
