import * as React from 'react';
import { AnimatedRow, Row, Text } from 'common-ui';
import {
  HintBoxAnimatedStyles,
  IHintBoxStyleOverride
} from './HintBox.animated-styles';
import {
  ILanguageFrameToken,
  LanguageFrameTokenDecoration,
  SeeSpeakMode
} from '@lexialearning/lobo-common/tasks/see-speak';
import { ImportantForAccessibility } from '@lexialearning/common-ui';
import { ProgramContextSelector } from 'curriculum-services';
import { SeeSpeakSelector } from '../redux/SeeSpeak.selector';
import { SreSelector, SreSessionType } from 'sre';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

export interface IHintBoxProps {
  hintTokens: ILanguageFrameToken[];
  hasInteracted: boolean;
  isInstruction?: boolean;
  styleOverride?: IHintBoxStyleOverride;
}

export class HintBoxComponent extends React.PureComponent<IHintBoxProps> {
  public static readonly displayName = 'HintBox';

  private readonly animatedStyles: HintBoxAnimatedStyles;

  constructor(props: IHintBoxProps) {
    super(props);

    this.animatedStyles = new HintBoxAnimatedStyles();
  }

  public componentDidUpdate(prevProps: IHintBoxProps) {
    if (
      !this.props.isInstruction &&
      this.props.hasInteracted &&
      !prevProps.hasInteracted
    ) {
      this.animatedStyles.getAnimations().fadeOut.start();
    }
    if (
      !isEqual(
        prevProps.hintTokens.map(t => t.text),
        this.props.hintTokens.map(t => t.text)
      )
    ) {
      const { fadeOut, reset } = this.animatedStyles.getAnimations();
      fadeOut.stop();
      reset.start();
    }
  }

  private isTokenHidden(decorations: LanguageFrameTokenDecoration[]) {
    return decorations.includes(LanguageFrameTokenDecoration.Scaffolded);
  }

  public render() {
    const { hintTokens, isInstruction, styleOverride } = this.props;

    if (!hintTokens.length) {
      return null;
    }

    const styles = this.animatedStyles.build(isInstruction, styleOverride);

    return (
      <AnimatedRow
        style={styles.borderContainer.static}
        animatedStyle={styles.borderContainer.animated}
        testId={HintBoxComponent.displayName}
      >
        <Row style={styles.backgroundContainer}>
          {hintTokens.map((token, key) => {
            const isHidden = this.isTokenHidden(token.decorations);
            const importantForAccessibility =
              isHidden || this.props.hasInteracted
                ? ImportantForAccessibility.NoHideDescendants
                : undefined;

            return (
              <Text
                key={key}
                style={this.animatedStyles.buildTokenStyle(
                  isHidden,
                  isInstruction
                )}
                importantForAccessibility={importantForAccessibility}
              >
                {token.text}
              </Text>
            );
          })}
        </Row>
      </AnimatedRow>
    );
  }
}

function mapStateToProps(state: unknown) {
  const languageFrameTokens = SeeSpeakSelector.getLanguageFrameTokens(state);
  const mode = SeeSpeakSelector.getMode(state);
  const hintTokens =
    mode === SeeSpeakMode.OpenScaffold
      ? languageFrameTokens.filter(t =>
          t.decorations.includes(LanguageFrameTokenDecoration.ChallengeWord)
        )
      : [];

  return {
    hasInteracted:
      SreSelector.getIsListeningTo(SreSessionType.LanguageFrame, state) ||
      !!ProgramContextSelector.getRoundContextMaybe(state)?.lastAttemptMaybe,
    hintTokens
  };
}

export const HintBox = connect(mapStateToProps)(HintBoxComponent);

export const HintBoxPrivates = {
  mapStateToProps
};
