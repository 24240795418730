import { IAct, ILevel, IPlacement } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { LexiaPlacementId } from 'curriculum-services/program-context/epics/student-progress-api/student-progress-api-private.model';
import { FauxActivityFactory } from './FauxActivityFactory';

export class FauxLevelFactory {
  public static readonly displayName = 'FauxLevelFactory';

  public static readonly LevelTitle = LexiaPlacementId.Level;

  public static create(placement: IPlacement): ILevel {
    const factory = new FauxLevelFactory(placement);
    const onboardingAct = factory.createOnboardingActivity();
    const placementAct = factory.createPlacementActivity();

    return {
      acts: [onboardingAct, placementAct],
      levelRecap: [],
      sceneBackground: '',
      sysId: placement.sysId,
      title: this.LevelTitle,
      welcomeMessage: []
    };
  }

  private constructor(private readonly placement: IPlacement) {}

  private createOnboardingActivity(): IAct {
    return FauxActivityFactory.create(this.placement);
  }

  private createPlacementActivity(): IAct {
    if (!this.placement.form) {
      throw new LexiaError(
        'Missing placement activity id',
        FauxLevelFactory.displayName,
        FauxLevelFactoryError.PlacementFormMissing
      ).withContext({
        placement: this.placement
      });
    }

    return FauxActivityFactory.create(this.placement, this.placement.form);
  }
}

export enum FauxLevelFactoryError {
  PlacementFormMissing = 'PlacementFormMissing'
}
