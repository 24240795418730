import * as React from 'react';
import { Card } from '../card';
import { Column } from '../layout';
import { TextPill } from '../text-pill';
import {
  ISpotlightStyleOverride,
  SpotlightAreaLayout,
  SpotlightStyles
} from './Spotlight.styles';

export interface ISpotlightProps {
  spotlightsCount: number;
  accessibilityLabel: string | undefined;
  imageSource?: string;
  text?: string;
  isHighlighted?: boolean;
  layout?: SpotlightAreaLayout;
  styleOverride?: ISpotlightStyleOverride;
}

export function Spotlight(
  props: ISpotlightProps
): React.ReactElement<ISpotlightProps> {
  const {
    spotlightsCount,
    accessibilityLabel,
    imageSource,
    text,
    isHighlighted = false,
    layout = SpotlightAreaLayout.Default,
    styleOverride
  } = props;
  const styles = SpotlightStyles.build(
    spotlightsCount,
    isHighlighted,
    layout,
    styleOverride
  );

  if (imageSource) {
    return (
      <Column style={styles.container} testId={Spotlight.displayName}>
        <Card
          imageSource={imageSource}
          accessibilityLabel={accessibilityLabel}
          text={text}
          styleOverride={styles.card}
        />
      </Column>
    );
  }

  return <TextPill>{text}</TextPill>;
}
Spotlight.displayName = 'Spotlight';
