import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { StateObservable } from 'redux-observable';
import { EMPTY, from, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { Configurator } from 'common-ui/components/Configurator';
import { DeepLinkActionType } from 'feature-areas/dev-tools/deep-link/redux/deep-link-redux.model';
import { DeepLinkActionPositionSetupComplete } from 'feature-areas/dev-tools/deep-link/redux/DeepLink.action';
import { QueryStringParam, RouterService } from 'router-service';
import { ILoboAppConfig } from 'services/app-config';
import {
  SreCheckMicPermissionAction,
  SreConfigAction,
  SreConfigureAction
} from 'sre';
import { IMountPoint, StormEnvironment } from 'storm';
import { SystemInfo } from 'utils';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import {
  AuthAction,
  AuthActionAutoLogin,
  AuthActionDisableAutoLogin,
  AuthActionLoginUserSuccess,
  AuthActionType
} from '../../auth';
import {
  CustomerAction,
  CustomerActionBootstrapSetup,
  CustomerActionSetupDeviceWithCustomerCodeAttempted,
  CustomerActionType
} from '../../customer';
import {
  BootstrapContentAction,
  BootstrapContentActionLoad,
  BootstrapContentActionLoadSuccess,
  BootstrapContentActionType
} from '../bootstrap-content';
import { LoboImageSourceTransformer } from '../lobo-image-source-transformer';
import { AppState, BootstrappingSelector } from '../redux';
import {
  BootstrappingAction,
  BootstrappingActionAutoLoginComplete,
  BootstrappingActionConfigurationComplete,
  BootstrappingActionDeepLinkPositionSetupComplete,
  BootstrappingActionDeviceSetupComplete
} from '../redux/Bootstrapping.action';
import {
  ConfigAction as UniConfigAction,
  ConfigActionUpdate as UniConfigActionUpdate,
  ConfigSelector as UniConfigSelector
} from '@lexialearning/utils-react';
import { LocalStorageService } from 'services/local-storage';

export type BootstrapOrchestrationInputTypes =
  | UniConfigActionUpdate
  | AuthActionDisableAutoLogin
  | AuthActionLoginUserSuccess
  | BootstrapContentActionLoadSuccess
  | CustomerActionSetupDeviceWithCustomerCodeAttempted
  | DeepLinkActionPositionSetupComplete;

export type BootstrapOrchestrationOutputTypes =
  | AuthActionAutoLogin
  | BootstrappingActionAutoLoginComplete
  | BootstrappingActionConfigurationComplete
  | BootstrappingActionDeepLinkPositionSetupComplete
  | BootstrappingActionDeviceSetupComplete
  | BootstrapContentActionLoad
  | SreCheckMicPermissionAction
  | CustomerActionBootstrapSetup
  | SreConfigureAction;

export interface IBootstrapOrchestrationDeps {
  configProvider: IConfigProvider;
  localStorageService: LocalStorageService;
}

export function bootstrapOrchestrationEpic(
  action$: Observable<BootstrapOrchestrationInputTypes>,
  state$: StateObservable<AppState>,
  deps: IBootstrapOrchestrationDeps
): Observable<BootstrapOrchestrationOutputTypes> {
  return action$.pipe(
    filter(
      action =>
        UniConfigAction.update.match(action) ||
        [
          AuthActionType.DisableAutoLogin,
          AuthActionType.LoginSuccess,
          BootstrapContentActionType.LoadSuccess,
          CustomerActionType.SetupDeviceWithCustomerCodeAttempted,
          DeepLinkActionType.PositionSetupComplete
        ].includes(action.type)
    ),
    filter(() => !BootstrappingSelector.isBootstrapped(state$.value)),
    mergeMap(action => {
      const { configProvider } = deps;

      switch (action.type) {
        case UniConfigAction.update.type:
          if (BootstrappingSelector.isConfigurationComplete(state$.value)) {
            return EMPTY;
          }

          if (!SystemInfo.isNative) {
            // if we're not Native, but on an iPad, we could be in the Clever WebView
            if (UserAgentUtils.isIpad()) {
              checkUniversalLink();
            }
            checkWebGLSupport();
          }

          advertiseConfigHighlights(state$.value);
          // FIXME: Move to a more appropriate place
          configureUiDependencies(configProvider);
          maybeDisableRightClick(configProvider);

          return from([
            BootstrappingAction.configurationComplete(),
            CustomerAction.bootstrapSetup(getSiteIdFromUrl()),
            SreConfigAction.configure.request(),
            BootstrapContentAction.load.request()
          ]);

        case BootstrapContentActionType.LoadSuccess:
          return of(SreConfigAction.checkMicPermission());

        case CustomerActionType.SetupDeviceWithCustomerCodeAttempted:
          return from([
            BootstrappingAction.deviceSetupComplete(),
            AuthAction.autoLogin()
          ]);

        case AuthActionType.DisableAutoLogin:
          return from([
            BootstrappingAction.autoLoginComplete(),
            BootstrappingAction.deepLinkPositionSetupComplete()
          ]);

        case AuthActionType.LoginSuccess:
          return of(BootstrappingAction.autoLoginComplete());

        case DeepLinkActionType.PositionSetupComplete:
          return of(BootstrappingAction.deepLinkPositionSetupComplete());

        // istanbul ignore next - theoretically unreachable
        default:
          return EMPTY;
      }
    })
  );
}
bootstrapOrchestrationEpic.displayName = 'bootstrapOrchestrationEpic';

function configureUiDependencies(configProvider: IConfigProvider): void {
  const imagesMountPoint =
    configProvider.getConfig<IMountPoint>('imagesMountPoint');

  Configurator.configure({
    imageSourceTransformer:
      LoboImageSourceTransformer.getTransformMethod(imagesMountPoint)
  });
}

function getSiteIdFromUrl(): string {
  return SystemInfo.isNative
    ? ''
    : RouterService.getQueryParam(QueryStringParam.SiteId) || '';
}

function advertiseConfigHighlights(state: AppState): void {
  const config = UniConfigSelector.getConfig<ILoboAppConfig>(state);
  // eslint-disable-next-line no-console
  console.log('Configuration', {
    contentStrategy: config.content.strategy,
    contentful: config.contentful.environment,
    isDebug: config.isDebug,
    lexiaApi: config.lexiaService.customerUrl
  });
}

function checkWebGLSupport(): void {
  if (!StormEnvironment.getInstance().isWebGLAvailable()) {
    throw new LexiaError(
      'WebGL disabled',
      bootstrapOrchestrationEpic.displayName,
      LexiaStandardErrorCode.WebGLDisabled.toString()
    ).withStandardCode(LexiaStandardErrorCode.WebGLDisabled);
  }
}

function maybeDisableRightClick(configProvider: IConfigProvider): void {
  const isDebug = configProvider.getConfig<boolean>('isDebug');
  if (!isDebug && !SystemInfo.isNative) {
    BootstrapOrchestrationPrivates.document!.oncontextmenu = () => false;
  }
}

function checkUniversalLink(): void {
  const { search } = RouterService.history.location;

  if (RouterService.isSsoUrl(search)) {
    BootstrapOrchestrationPrivates.location!.href = `lexiaenglish://oauth${search}`;
  }
}

// istanbul ignore next - trivial
export const BootstrapOrchestrationPrivates = {
  document: SystemInfo.isNative ? undefined : document,
  location: window?.location
};
