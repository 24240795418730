import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export class EncounterCompleteSceneAnimationName {
  public static readonly Root = {
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro
  };
}

export enum EncounterCompleteSceneMaterialName {
  PaperBack = 'paper_back'
}
