import { Avatar, AvatarType, AvatarUserName } from 'common-ui';
import { AvatarShowcaseStyles } from './AvatarShowcase.styles';
import { BackgroundGradientSvg } from 'feature-areas/shell';
import { Color } from 'common-styles';
import { Column, Row } from '@lexialearning/common-ui';

export function AvatarShowcase() {
  const styles = AvatarShowcaseStyles.get();

  return (
    <Column style={styles.outerContainer}>
      <BackgroundGradientSvg
        colorBottom={Color.Clementine}
        colorTop={Color.Gold}
        height={styles.outerContainer.height}
        id={AvatarShowcase.displayName}
        width={styles.outerContainer.width}
      />
      <Row style={styles.innerContainer}>
        <Avatar type={AvatarType.Full} />
      </Row>
      <Row style={styles.displayNameContainer}>
        <AvatarUserName styleOverride={styles.displayNameOverrides} />
      </Row>
    </Column>
  );
}
AvatarShowcase.displayName = 'AvatarShowcase';
