export class HeaderTitlePillStyles {
  private static readonly styles = {
    titlePillOverrides: {
      container: {
        marginTop: 45
      },
      pill: {
        minWidth: 550
      },
      pillOutline: {
        minWidth: 560
      }
    }
  };

  public static get() {
    return this.styles;
  }
}
