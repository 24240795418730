import memoizeOne from 'memoize-one';
import { Color, TextAlign } from 'common-styles';
import { Types } from '../../types';

export interface IFillInTextPromptStyleOverride {
  text: Types.TextStyle;
}

export class FillInTextPromptStyles {
  public static readonly build = memoizeOne(
    (styleOverride?: IFillInTextPromptStyleOverride) => ({
      text: {
        color: Color.Text,
        textAlign: TextAlign.Left,
        ...styleOverride?.text
      }
    })
  );

  public static get() {
    return {
      outerContainer: {
        marginBottom: 20,
        marginTop: 10,
        width: 695
      }
    };
  }
}
