import {
  IScreenplay,
  ITextWithVoiceover
} from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { Text, TextButton, TextButtonType, View } from 'common-ui';
import { ScreenplayAction } from 'screenplay/redux';
import { AppState } from 'services';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { CalibrationStep, CalibrationUiAction } from '../redux';
import { CalibrationMicSetupTipsStyles } from './CalibrationMicSetupTips.styles';

export interface ICalibrationMicSetupTipsProps {
  micFixTips: ITextWithVoiceover;
  cancelScreenplay(): void;
  playScreenplay(screenplay: IScreenplay): void;
  setStep(step: CalibrationStep): void;
}

export class CalibrationMicSetupTipsComponent extends React.PureComponent<ICalibrationMicSetupTipsProps> {
  public static displayName = 'CalibrationMicSetupTips';
  public static buttonDisplayName = 'CalibrationMicSetupTipsButton';

  public constructor(props: ICalibrationMicSetupTipsProps) {
    super(props);

    this.handlePressOk = this.handlePressOk.bind(this);
  }

  public componentDidMount() {
    this.props.playScreenplay(this.props.micFixTips.voiceover);
  }

  public componentWillUnmount() {
    // make sure mic tips VO is stopped when exiting this screen
    this.props.cancelScreenplay();
  }

  private handlePressOk() {
    this.props.setStep(CalibrationStep.MicFix);
  }

  private renderTips() {
    const styles = CalibrationMicSetupTipsStyles.get();
    const tips = this.props.micFixTips.text.split('\\n');

    return tips.map((t, i) => (
      <Text style={styles.setupTips} key={i}>
        {t}
      </Text>
    ));
  }

  public render() {
    const styles = CalibrationMicSetupTipsStyles.get();

    return (
      <>
        <Text style={styles.setupTipsTitle}>Microphone Setup Tips</Text>
        <View
          style={styles.setupTipsContainer}
          testId={CalibrationMicSetupTipsComponent.displayName}
        >
          {this.renderTips()}
        </View>
        <TextButton
          testId={CalibrationMicSetupTipsComponent.buttonDisplayName}
          buttonType={TextButtonType.Primary}
          text="OK"
          onPress={this.handlePressOk}
        />
      </>
    );
  }
}

// istanbul ignore next - trivial code, not worth testing
const mapStateToProps = (state: AppState) => ({
  micFixTips:
    BootstrapContentSelector.getCalibrationScreenContent(state)!.micFixTips
});

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  cancelScreenplay: () => ScreenplayAction.cancel(),
  playScreenplay: (vo: IScreenplay) =>
    ScreenplayAction.play({ screenplay: vo }),
  setStep: (step: CalibrationStep) => CalibrationUiAction.setStep({ step })
};

export const CalibrationMicSetupTips = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalibrationMicSetupTipsComponent);
