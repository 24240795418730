import * as React from 'react';
import { AnimatedView, Column } from 'common-ui';
import { Services } from 'services/Services';
import { ThemeType, withTheme } from 'theme';
import { MeterMarkerHelper } from '../../MeterMarker.helper';
import {
  MeterMarkerAnimationName,
  MeterMarkerProgressStatus
} from '../../meterMarker.model';
import { PipAnimatedStyles } from './Pip.animated-styles';
import { PipCompletedRingSvg } from './PipCompletedRing.lx-svg';
import { IAnimationEntry } from 'screenplay/screenplayers/react-animation/ReactAnimationActionPlayer';

export interface IPipProps {
  delayStart: number;
  isRecyclePass?: boolean;
  progress: MeterMarkerProgressStatus;
  themeType: ThemeType;
  sysId: string;
}

export class PipComponent extends React.PureComponent<IPipProps> {
  public static readonly displayName = 'Pip';

  private animatedStyles: PipAnimatedStyles;

  private readonly unregisterAnimations: () => void;

  constructor(props: IPipProps) {
    super(props);

    const { delayStart, themeType, progress, isRecyclePass } = props;
    this.animatedStyles = new PipAnimatedStyles(
      themeType,
      progress,
      delayStart,
      !!isRecyclePass
    );
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentDidUpdate(prevProps: IPipProps) {
    const {
      progress: prevProgress,
      themeType: prevThemeType,
      delayStart: prevDelayStart,
      isRecyclePass: prevIsRecyclePass
    } = prevProps;
    const { delayStart, progress, themeType, isRecyclePass } = this.props;

    if (
      prevThemeType !== themeType ||
      prevIsRecyclePass !== isRecyclePass ||
      prevDelayStart !== delayStart ||
      (prevProgress === MeterMarkerProgressStatus.Completed &&
        progress !== MeterMarkerProgressStatus.Completed)
    ) {
      this.animatedStyles = new PipAnimatedStyles(
        themeType,
        progress,
        delayStart,
        !!isRecyclePass
      );
      this.registerAnimations();
      this.forceUpdate();
    }

    MeterMarkerHelper.maybeUpdateMarker(
      prevProgress,
      progress,
      this.animatedStyles.getAnimations()
    );
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { sysId, isRecyclePass, progress } = this.props;
    const { animateIntro, recycleFlash } = this.animatedStyles.getAnimations();

    const animationsToRegister: IAnimationEntry[] = [];

    /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
    // only 1 pip (the active one) has an Intro animation
    if (animateIntro) {
      animationsToRegister.push({
        name: MeterMarkerAnimationName.PipIntro,
        animation: animateIntro
      });
    }

    if (!!isRecyclePass && progress !== MeterMarkerProgressStatus.RecycleSkip) {
      animationsToRegister.push({
        name: `${MeterMarkerAnimationName.RecycleIntro}-${sysId}`,
        animation: recycleFlash
      });
    }
    /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */

    return Services.reactAnimationScreenplayer.registerAnimations(
      animationsToRegister
    );
  }

  public render() {
    const { isRecyclePass, progress } = this.props;
    const styles = this.animatedStyles.build(progress, !!isRecyclePass);

    return (
      <Column style={styles.container}>
        {/*
        In order to do smooth animations of two styles at once (scale and opacity here),
        we found it was best to have each style animated on a separate component
        */}
        <AnimatedView
          style={styles.ringScale}
          animatedStyle={styles.ringScaleAnimated}
        >
          <AnimatedView
            style={styles.ringOpacity}
            animatedStyle={styles.ringOpacityAnimated}
          >
            <PipCompletedRingSvg />
          </AnimatedView>
        </AnimatedView>
        {/*
        In order to do smooth animations of two styles at once (scale and color here),
        we found it was best to have each style animated on a separate component
        */}
        <AnimatedView
          style={styles.pipScale}
          animatedStyle={styles.pipScaleAnimated}
        >
          <AnimatedView
            style={styles.pipColor}
            animatedStyle={styles.pipColorAnimated}
          />
        </AnimatedView>
      </Column>
    );
  }
}

export const Pip = withTheme(PipComponent);
