import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { ProfileSelector } from 'services/profile';
import { ICurriculumDependencies } from '../../../CurriculumDependencies';
import {
  ProgramContextActionKeepSessionAlive,
  ProgramContextActionKeepSessionAliveForced,
  ProgramContextActionType
} from '../../redux';

type InputAction =
  | ProgramContextActionKeepSessionAlive
  | ProgramContextActionKeepSessionAliveForced;

export function keepSessionAliveEpic(
  action$: Observable<InputAction>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<void> {
  return action$.pipe(
    ofType(
      ProgramContextActionType.KeepSessionAlive,
      ProgramContextActionType.KeepSessionAliveForced
    ),
    filter(() => ProfileSelector.isStudentLoggedIn(state$.value)),
    tap<InputAction>(action => {
      const { curriculumDependencies } = deps;

      if (action.type === ProgramContextActionType.KeepSessionAliveForced) {
        curriculumDependencies.studentProgressApi.keepAliveForced();
      } else {
        curriculumDependencies.studentProgressApi.keepAlive();
      }
    }),
    ignoreElements()
  );
}
keepSessionAliveEpic.displayName = 'keepSessionAliveEpic';
