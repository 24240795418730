import { TaskDemoType } from 'services/storm-lobo/StormAssets';

export interface ICommonUiState {
  hasViewedOnboardingVideo: boolean;
  isKeydownHandlingDisabled: boolean;
  isGlobalDisabled: boolean;
  taskDemosShown: TaskDemoType[];
  /** Array of indices of all unit rewards which the user may see (determined by level) */
  unitRewards: number[];
  /**
   * Array of indices of unit rewards currently available to the student
   * (subset of all unitRewards for the given user, excluding those which have
   *  already been viewed - resets once all rewards have been viewed, or student
   *  logs back in)
   */
  unitRewardsAvailable: number[];
}

export enum CommonUiActionType {
  SetHasViewedOnboardingVideo = 'COMMON_UI.SET_HAS_VIEWED_ONBOARDING_VIDEO',
  SetKeydownHandlingDisabled = 'COMMON_UI.SET_KEYDOWN_HANDLING_DISABLED',
  SetGlobalDisabled = 'COMMON_UI.SET_GLOBAL_DISABLED',
  SetTaskDemoShown = 'COMMON_UI.SET_TASK_DEMO_SHOWN',
  SetUnitRewards = 'COMMON_UI.SET_UNIT_REWARDS',
  UnitRewardShown = 'COMMON_UI.UNIT_REWARD_SHOWN'
}
