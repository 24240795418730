import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import { IRoundToNextTransitionDeps } from '../round-transition.model';
import { RoundToActCompleteTransitionBuilder } from './RoundToActCompleteTransitionBuilder';

export class RoundToActCompleteTransitionFactory {
  public static readonly displayName = 'RoundToActCompleteTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): IScreenplay {
    return new RoundToActCompleteTransitionFactory(state$, deps).screenplay;
  }

  private readonly screenplay: IScreenplay;

  private constructor(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ) {
    this.screenplay = RoundToActCompleteTransitionBuilder.createFor(
      state$,
      deps
    )
      .disableUtilityBar()
      .changeActivityPositionToComplete()
      .prepareLevelShowcaseScene()
      .playEncounterCompleteActions()
      .playCharacterCelebration()
      .animateInBadge()
      .navToLevelPage()
      .deselectActivity()
      .animateInActSelection()
      .animateInTitleBar()
      .enableUtilityBar().screenplay;
  }
}
