import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { LevelSelector } from 'services/curriculum-services';
import { PreparedScenes } from 'services/storm-lobo';
import {
  TransitionAction,
  TransitionActionHomeToLevel
} from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { HomeToLevelIntroTransitionScreenplayFactory } from './HomeToLevelIntroTransitionScreenplayFactory';

export interface IHomeToLevelTransitionEpicDeps {
  preparedScenes: PreparedScenes;
}

export function homeToLevelIntroTransitionEpic(
  action$: Observable<TransitionActionHomeToLevel>,
  state$: StateObservable<unknown>,
  deps: IHomeToLevelTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.HomeToLevel),
    map(() => {
      const { preparedScenes } = deps;
      const welcomeMessageVo = LevelSelector.getWelcomeMessage(state$.value)
        ?.voiceover;

      return HomeToLevelIntroTransitionScreenplayFactory.createFor({
        preparedScenes,
        welcomeMessageVo
      }).screenplay;
    }),
    map(screenplay =>
      ScreenplayAction.play({
        nextAction: TransitionAction.levelIntroToLevel(),
        screenplay
      })
    )
  );
}
homeToLevelIntroTransitionEpic.displayName = 'homeToLevelIntroTransitionEpic';
