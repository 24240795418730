import memoizeOne from 'memoize-one';
import { loboAnimatedTextStyle } from 'common-styles';
import { ThemeSize } from 'theme/theme.model';
import { TextStyles } from './Text.styles';

export class AnimatedTextStyles {
  public static readonly build = memoizeOne(
    (themeSize: ThemeSize, style: any, animatedStyle: any) => [
      TextStyles.build(themeSize, style),
      // spreading prop to pass as arg as the Rx style creators apply changes to the object passed in,
      // not just to their output, and while this doesn't seem to have caused issues for us
      // it does seem wrong to have the passed in prop get changed by the creator method
      loboAnimatedTextStyle({ ...animatedStyle })
    ]
  );
}
