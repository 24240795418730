import { AppState } from 'services';
import { ILogger } from '@lexialearning/main-model';
import { LoginToInstructionTransitionScreenplayBuilder } from './LoginToIntroductionTransitionScreenplayBuilder';
import { Observable } from 'rxjs';
import { PreparedScenes } from 'services/storm-lobo';
import { RouterService } from 'router-service';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { SystemInfo } from 'utils';
import {
  TransitionAction,
  TransitionActionLoginToIntroduction
} from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { filter, map } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';

export interface ILoginToIntroductionTransitionEpicDeps {
  logger: ILogger;
  preparedScenes: PreparedScenes;
}

export function loginToIntroductionTransitionEpic(
  action$: Observable<TransitionActionLoginToIntroduction>,
  _state$: StateObservable<AppState>,
  deps: ILoginToIntroductionTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.LoginToIntroduction),
    filter(() => RouterService.isOnLoginRoute()),
    map(action => {
      const { preparedScenes } = deps;
      const { isSso } = action.payload;
      // Web SSO requires user interaction before moving on, SSO on native does not
      const needsSsoInteraction = isSso && !SystemInfo.isNative;
      const builder = LoginToInstructionTransitionScreenplayBuilder.create();

      if (needsSsoInteraction) {
        builder.navToSsoInteractionPage().awaitInteraction(action$);
      }

      builder
        .awaitAndShowIntroductionScene(preparedScenes)
        .fadeOutLoginPageMaybe(needsSsoInteraction)
        .navToAndPlayIntroduction();

      return ScreenplayAction.play({
        nextAction: TransitionAction.introductionToNext(),
        screenplay: builder.screenplay
      });
    })
  );
}
loginToIntroductionTransitionEpic.displayName =
  'loginToIntroductionTransitionEpic';
