import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { LoboLogItemCategory } from 'logging';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import {
  AppShellActionSkipButtonPressed,
  AppShellActionType
} from 'feature-areas/shell';
import { AppState } from 'services/bootstrapping';

export function logSkipButtonPressedEpic(
  action$: Observable<AppShellActionSkipButtonPressed>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(AppShellActionType.SkipButtonPressed),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.SkipButtonPressed),
    ignoreElements()
  );
}
logSkipButtonPressedEpic.displayName = 'logSkipButtonPressedEpic';

function createEvent(): ILogItemMinimal<{}, LoboLogItemCategory> {
  return {
    category: LoboLogItemCategory.SkipButtonPressed,
    loggingLevel: LoggingLevel.Verbose,
    payload: {},
    summary: 'Skip Button Pressed'
  };
}
