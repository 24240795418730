import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements } from 'rxjs/operators';
import {
  ISessionInitialLevelPositionEventPayload,
  LoboLogItemCategory
} from 'logging';
import {
  LevelActionLoadSuccess,
  LevelActionType,
  ProgramContextActionProgramPositionAdjustedForPlacement,
  ProgramContextActionType,
  ProgramContextSelector,
  ProgramMode
} from 'curriculum-services';
import { AppLoggerDependencies } from 'services/logging/AppLoggerDependencies';
import { ProfileSelector } from 'services/profile';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { SessionPositionEventFactory } from './SessionPositionEventFactory';
import { AppState } from 'services';

/**
 * Log the student's session's initial program position, once we've loaded the
 * level. (This will also fire whe the student levels up)
 */
export function logSessionInitialLevelPositionEpic(
  action$: Observable<
    | LevelActionLoadSuccess
    | ProgramContextActionProgramPositionAdjustedForPlacement
  >,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(
      LevelActionType.LoadSuccess,
      ProgramContextActionType.ProgramPositionAdjustedForPlacement
    ),
    filter(action => shouldLogPosition(state$, action)),
    logSafely(
      state$,
      deps,
      createEvent,
      LoboLogItemCategory.SessionInitialLevelPosition
    ),
    ignoreElements()
  );
}
logSessionInitialLevelPositionEpic.displayName =
  'logSessionInitialLevelPositionEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<
  ISessionInitialLevelPositionEventPayload,
  LoboLogItemCategory
> {
  const factory = new SessionPositionEventFactory(state, helper.taskRegistry);

  helper.setLevelInfo(factory.levelTitle);

  return {
    category: LoboLogItemCategory.SessionInitialLevelPosition,
    loggingLevel: LoggingLevel.Info,
    payload: factory.createData(),
    summary: factory.createSummary()
  };
}

function shouldLogPosition(
  state: StateObservable<unknown>,
  action:
    | LevelActionLoadSuccess
    | ProgramContextActionProgramPositionAdjustedForPlacement
): boolean {
  return (
    ProfileSelector.isStudentLoggedIn(state.value) &&
    (ProgramContextSelector.getMode(state.value) !== ProgramMode.Placement ||
      action.type ===
        ProgramContextActionType.ProgramPositionAdjustedForPlacement)
  );
}
