export class NumberUtils {
  public static parse(n: string): number {
    // trim all characters that are not digits
    const numberPart = n.replace(/^0+/, '');

    return parseInt(numberPart, 10);
  }

  public static toTwoDigitString(n: number): string {
    return String(n).padStart(2, '0');
  }
}
