import { createSelector } from '@reduxjs/toolkit';
import { InteractionState } from 'common-styles';
import { IMicState } from './mic.model';

type MicSelectorType = (state: any) => IMicState;

export class MicSelector {
  public static readonly displayName = 'MicSelector';

  public static getInteractionState: (state: unknown) => InteractionState;
  /**
   * Does not reflect whether forceDisabled has been set, but instead only returns
   * the value of interactionState stored in the mic state. This is NOT be used
   * for any UI components or anything that would care about App-wide disabling.
   * Those instances should use getInteractionState instead.
   * This is added specifically for use with the updateMicInteractionStateEpic which
   * needs to verify whether its computed value is different than that stored in
   * state to know whether it should be updated.
   */
  public static getStoredInteractionState: (state: unknown) => InteractionState;

  public static createSelectors(selector: MicSelectorType): void {
    MicSelector.getInteractionState = createSelector(
      selector,
      (state: IMicState) =>
        state.forceDisabled ? InteractionState.Disabled : state.interactionState
    );
    MicSelector.getStoredInteractionState = createSelector(
      selector,
      (state: IMicState) => state.interactionState
    );
  }
}
