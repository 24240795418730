import { flatten, times } from 'lodash';
import {
  AnimatableCSSProperty,
  loboAnimated,
  LoboAnimatedValue
} from 'common-styles';

const item1Duration = 1000;
const item1Iterations = 4;

const item2Duration = 2000;
const item2Iterations = 1;

export class ScreenplayTestHelper {
  public static createItem1Animation(
    _scaleAnimatedValue: LoboAnimatedValue,
    translateYAnimatedValue: LoboAnimatedValue
  ) {
    // const scaleAnimation = this.createReactAnimation(
    //   'scale',
    //   scaleAnimatedValue,
    //   item1Duration,
    //   3,
    //   1
    // );
    const translateYAnimation = this.createReactAnimation(
      AnimatableCSSProperty.TranslateY,
      translateYAnimatedValue,
      item1Duration + 10,
      100,
      0
    );
    const singleIteration = loboAnimated.parallel([
      translateYAnimation
      // scaleAnimation
    ]);

    return loboAnimated.sequence(
      flatten(times(item1Iterations, () => [singleIteration]))
    );
  }

  public static createItem2Animation(scaleYAnimatedValue: LoboAnimatedValue) {
    const scaleYAnimation = this.createReactAnimation(
      AnimatableCSSProperty.ScaleY,
      scaleYAnimatedValue,
      item2Duration,
      3,
      1
    );

    return loboAnimated.sequence(
      flatten(times(item2Iterations, () => [scaleYAnimation]))
    );
  }

  private static createReactAnimation(
    type: AnimatableCSSProperty,
    animatedValue: LoboAnimatedValue,
    duration: number,
    toValueOut: number,
    toValueIn: number
  ) {
    return loboAnimated.sequence([
      loboAnimated.timing(type, animatedValue, {
        duration,
        easing: loboAnimated.Easing.Default(),
        toValue: toValueOut
      }),
      loboAnimated.timing(type, animatedValue, {
        duration,
        easing: loboAnimated.Easing.Default(),
        toValue: toValueIn
      })
    ]);
  }
}
