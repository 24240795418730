import { loadFauxLevelEpic } from './loadFauxLevel.epic';
import { loadPlacementContentEpic } from './loadPlacementContent.epic';
import { preparePlacedLevelPositionEpic } from './preparePlacedLevelPosition.epic';
import { requestPlacementContentLoadEpic } from './requestPlacementContentLoad.epic';
import { unloadPlacementContentEpic } from './unloadPlacementContent.epic';

export const placementEpics = [
  loadFauxLevelEpic,
  loadPlacementContentEpic,
  preparePlacedLevelPositionEpic,
  requestPlacementContentLoadEpic,
  unloadPlacementContentEpic
];
