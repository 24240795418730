import {
  AnimatedViewStyle as CuiAnimatedViewStyle,
  IViewStyle as ICuiViewStyle,
  Overflow
} from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { loboViewStyle } from 'common-styles';

export class AnimatedViewStyles {
  public static readonly build = memoizeOne(
    (style: any, animatedStyle?: CuiAnimatedViewStyle) => ({
      animatedStyle: animatedStyle || {},
      staticStyle: loboViewStyle({
        overflow: Overflow.Visible,
        ...style
      }) as ICuiViewStyle
    })
  );
}
