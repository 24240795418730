import * as React from 'react';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { PopFadeAnimation, View } from 'common-ui';
import { IndicatorVerticalSvg } from './svgs/IndicatorVertical.lx-svg';
import {
  VerticalIndicatorPosition,
  VerticalIndicatorStyles
} from './VerticalIndicator.styles';

export interface IVerticalIndicatorProps extends ISfxProps {
  indicatorWidth?: number;
  indicatorHeight?: number;
  isVisible: boolean;
  position: VerticalIndicatorPosition;
}

export class VerticalIndicatorComponent extends React.PureComponent<IVerticalIndicatorProps> {
  public static readonly displayName = 'VerticalIndicator';

  public componentDidUpdate(prevProps: IVerticalIndicatorProps) {
    const { isVisible, playSfx } = this.props;

    if (!prevProps.isVisible && isVisible) {
      playSfx(Sfx.DropZone);
    }
  }

  public render() {
    const { isVisible, position } = this.props;
    const styles = VerticalIndicatorStyles.build(position);

    return (
      <View style={styles.container}>
        <PopFadeAnimation trigger={isVisible} scaleY={1.3}>
          <IndicatorVerticalSvg />
        </PopFadeAnimation>
      </View>
    );
  }
}

export const VerticalIndicator = withSfx(VerticalIndicatorComponent);
