import {
  BootstrapContentActionType,
  IBootstrapContentReduxState
} from './bootstrap-content-redux.model';
import { BootstrapContentActions } from './BootstrapContent.action';

export const bootstrapContentInitialState: IBootstrapContentReduxState = {
  screens: undefined
};

export function bootstrapContentReducer(
  state = bootstrapContentInitialState,
  action: BootstrapContentActions
): IBootstrapContentReduxState {
  switch (action.type) {
    case BootstrapContentActionType.LoadSuccess:
      return {
        ...state,
        ...action.payload.content
      };

    default:
      return state;
  }
}
