import { IUnit } from '@lexialearning/lobo-common/main-model';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { UnitActionType } from './UnitActionType';

export const UnitAction = {
  /**
   * Loads the unit and subunits specified by the active activity position
   */
  load: createAsyncAction(
    UnitActionType.Load,
    UnitActionType.LoadSuccess,
    UnitActionType.LoadFailure
  )<void, IUnitLoadSuccessActionPayload, Error>()
};

export interface IUnitLoadActionPayload {
  sysId: string;
  asParent?: boolean;
}

export interface IUnitLoadSuccessActionPayload {
  parentUnits: IUnit[];
  unitContent: IUnit;
}

export type UnitActionLoad = ActionType<typeof UnitAction.load.request>;
export type UnitActionLoadSuccess = ActionType<typeof UnitAction.load.success>;

export type UnitActions = ActionType<typeof UnitAction>;
