import { XYCoord } from 'react-dnd';
import { DragItemType } from '../DragItemType';

export interface IDndReduxState {
  dropTargetPosition: XYCoord | undefined;
  /** true if any item is actively being dragged or is dropping */
  isDraggingOrDropping: boolean;
  forcedDragLayer: IDndForcedDragLayer | undefined;
}

export interface IDndForcedDragLayer {
  itemType: DragItemType;
  itemProps: any;
  initialOffset: XYCoord;
}

export enum DndActionType {
  DropToPosition = 'DND.DROP_TO_POSITION',
  SetIsDraggingOrDropping = 'DND.SET_IS_DRAGGING_OR_DROPPING',
  SetForcedDragLayer = 'DND.SET_FORCED_DRAG_LAYER',
  Reset = 'DND.RESET'
}
