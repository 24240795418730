import { LoboContentType } from '@lexialearning/lobo-common/cms';
import { LexiaError } from '@lexialearning/utils';
import { LxStormAudio } from 'storm';
import {
  VoiceoverScreenplayActionType,
  VoiceoverScreenplayerType
} from './voiceover-screenplayer.model';
import { ILogger } from '@lexialearning/main-model';

export class VoiceoverScreenplayer implements VoiceoverScreenplayerType {
  public static readonly displayName = 'VoiceoverScreenplayer';

  public readonly type = LoboContentType.Voiceover;

  constructor(
    private readonly stormAudioPlayer: LxStormAudio,
    private readonly logger: ILogger
  ) {}

  public cancel(action: VoiceoverScreenplayActionType): void {
    const filePath = this.getFilePath(action);
    const { speaker } = action.data;
    this.stormAudioPlayer.cancelVoiceover(filePath, speaker);
  }

  public pause(): void {
    this.stormAudioPlayer.pauseVoiceover();
  }

  public async play(action: VoiceoverScreenplayActionType): Promise<void> {
    const filePath = this.getFilePath(action);

    return this.playVoiceoverWithErrorHandling(filePath, action);
  }

  public resume(): void {
    this.stormAudioPlayer.resumeVoiceover();
  }

  private cleanTranscript(transcript: string): string {
    return transcript.replace(/_/g, '');
  }

  private async handleError(
    err: LexiaError,
    filePath: string,
    action: VoiceoverScreenplayActionType
  ) {
    void this.logger.logError(
      new LexiaError(
        `Error playing voiceover file ${filePath}: ${err.message}`,
        VoiceoverScreenplayer.displayName,
        VoiceoverScreenplayerError.StormAudioError
      )
        .withCause(err)
        .withContext({ action, filePath })
    );
  }

  private async playVoiceoverWithErrorHandling(
    filePath: string,
    action: VoiceoverScreenplayActionType
  ) {
    try {
      const { transcript, speaker } = action.data;
      const cleanTranscript = this.cleanTranscript(transcript);
      await this.stormAudioPlayer.playVoiceover(
        filePath,
        cleanTranscript,
        speaker
      );
    } catch (err) {
      await this.handleError(err, filePath, action);
    }
  }

  private getFilePath(action: VoiceoverScreenplayActionType): string {
    return (action.data.url || '').match(/[^\/]+\.opus$/)
      ? action.data.url!
      : '';
  }
}

export enum VoiceoverScreenplayerError {
  StormAudioError = 'StormAudioError'
}
