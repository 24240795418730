import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ActUiActionType, IActUiState } from './act-ui-redux.model';
import { ActUiActions } from './ActUi.action';

export const actUiInitialState: IActUiState = {
  playedFunFactIds: []
};

export function actUiReducer(
  state = actUiInitialState,
  action: ActUiActions | UserGlobalActionLogoutSuccess
): IActUiState {
  switch (action.type) {
    case ActUiActionType.AddPlayedFunFact:
      const funFact = action.payload.id;

      return {
        ...state,
        playedFunFactIds: [...state.playedFunFactIds, funFact]
      };

    case UserGlobalActionType.LogoutSuccess:
      return actUiInitialState;

    default:
      return state;
  }
}
