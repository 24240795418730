import { Types } from 'common-ui';
import { useCallback, useRef, useState } from 'react';

export enum AnimationEndState {
  ShowMeter = 'showMeter',
  ShowMic = 'showMic'
}

export function useButtonMeterTransitions() {
  // STATE
  const [showMeter, setShowMeter] = useState<boolean>(false);
  const [showMic, setShowMic] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  /** Allows for awaiting the completion of the meter show animation */
  const vuMeterShowingPromise = useRef(new Promise(resolve => resolve(false)));

  const animateTransition = useCallback(
    async (args: {
      animation: Types.Animated.CompositeAnimation;
      endState: AnimationEndState;
    }) => {
      let resolveMeterShowing;
      // istanbul ignore else - LOBO-19009 to resolve in separate PR
      if (args.endState === AnimationEndState.ShowMic) {
        const vuMeterShowing = await vuMeterShowingPromise.current;

        // istanbul ignore next - LOBO-19009 to resolve in separate PR
        if (!vuMeterShowing) {
          return;
        }
        // istanbul ignore next - LOBO-19009 to resolve in separate PR
        vuMeterShowingPromise.current = new Promise(resolve => resolve(false));
      } else {
        vuMeterShowingPromise.current = new Promise(
          resolve => (resolveMeterShowing = resolve)
        );
      }

      // istanbul ignore next - LOBO-19009 to resolve in separate PR
      setShowMeter(true);
      // istanbul ignore next - LOBO-19009 to resolve in separate PR
      setShowMic(true);
      // istanbul ignore next - LOBO-19009 to resolve in separate PR
      setIsAnimating(true);

      // istanbul ignore next - LOBO-19009 to resolve in separate PR
      args.animation.start(
        // istanbul ignore next - LOBO-19009 to resolve in separate PR
        () => {
          setShowMeter(args.endState === AnimationEndState.ShowMeter);
          setShowMic(args.endState === AnimationEndState.ShowMic);
          setIsAnimating(false);
          if (args.endState === AnimationEndState.ShowMeter) {
            resolveMeterShowing(true);
          }
        }
      );
    },
    [vuMeterShowingPromise]
  );

  return {
    animateTransition,
    isAnimating,
    showMeter,
    showMic
  };
}
