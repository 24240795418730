import { Position } from '@lexialearning/common-ui';

export class ProgressMetersStyles {
  private static readonly styles = {
    container: {
      bottom: 0,
      left: 0,
      position: Position.Absolute,
      right: 0
    }
  };

  public static get() {
    return this.styles;
  }
}
