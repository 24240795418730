import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LevelSelector } from '../../level';
import {
  LevelsCompletedAction,
  LevelsCompletedActionAdd,
  LevelsCompletedActionAddSuccess,
  LevelsCompletedSelector
} from '../redux';
import { ILevelsCompletedStateWrapper } from '../redux/levels-completed-redux.model';
import { LevelsStatusFactory } from './LevelsStatus.factory';

/**
 * Add level student just completed to the array of level status.
 * Called from the level completion screenplay prior to leveling up.
 */
export function addCompletedLevelEpic(
  action$: Observable<LevelsCompletedActionAdd>,
  state$: StateObservable<ILevelsCompletedStateWrapper>
): Observable<LevelsCompletedActionAddSuccess> {
  return action$.pipe(
    filter(LevelsCompletedAction.add.request.match),
    map(() =>
      LevelsStatusFactory.addCompletedLevel(
        LevelSelector.getLevelNumber(state$.value),
        LevelsCompletedSelector.getLevelsStatus(state$.value)
      )
    ),
    map(revisedLevelsStatus =>
      LevelsCompletedAction.add.success(revisedLevelsStatus)
    )
  );
}
addCompletedLevelEpic.displayName = 'addCompletedLevelEpic';
