import { IImage, ITopic } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Text, TitlePill, Types, View } from 'common-ui';
import { ProgramContextSelector } from 'curriculum-services';
import { TransitionAction } from 'feature-areas/transitions/Transition.action';
import { FauxMicButton } from 'shared-components/faux-mic-button';
import { TitlePillBadge } from 'shared-components/title-pill-badge';
import { withSpinner } from 'spinner-handler';
import { ActFooterStyles } from './ActFooter.styles';
import { Services } from 'services';

export interface IActFooterProps {
  characterName: string;
  topic: ITopic;
  completedEncounterBadges: IImage[];
  isActComplete: boolean;
  transitionToEncounter(): void;
}

export class ActFooterComponent extends React.PureComponent<IActFooterProps> {
  public static readonly displayName = 'ActFooter';
  public static readonly FauxMicCalloutReactAnimation =
    'ActFooter_FauxMicCalloutReactAnimation';

  private registerMicCalloutAnimation(
    animation: Types.Animated.CompositeAnimation
  ): () => void {
    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: ActFooterComponent.FauxMicCalloutReactAnimation,
        animation
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const {
      topic,
      completedEncounterBadges,
      isActComplete,
      transitionToEncounter
    } = this.props;
    const styles = ActFooterStyles.get();

    return (
      <Row style={styles.container}>
        <TitlePill styleOverride={styles.titlePillOverrides}>
          <TitlePillBadge
            imageSrc={topic?.badge?.imageSource}
            accessibilityLabel={topic?.badge?.altText}
          />
          {completedEncounterBadges.map((badge, i) => (
            <TitlePillBadge
              key={i}
              id={`encounter_${i}`}
              imageSrc={badge.imageSource}
              accessibilityLabel={`Encounter ${i + 1} completed.`}
            />
          ))}
          <Text style={styles.characterName}>{this.props.characterName}</Text>
        </TitlePill>
        {!isActComplete && (
          <FauxMicButton
            onPress={transitionToEncounter}
            registerAnimation={this.registerMicCalloutAnimation}
          />
        )}
        <View style={styles.lineRight} />
      </Row>
    );
  }
}

function mapStateToProps(state: unknown) {
  const context = ProgramContextSelector.getEncounterContextMaybe(state);
  if (!context) {
    return {
      isPageReady: false
    };
  }
  const { act, activityPosition } = context;
  const isActComplete = activityPosition.isComplete;
  const completedEncounters = isActComplete
    ? act.encounters
    : act.encounters.slice(0, context.findEncounterIndex());
  const completedEncounterBadges = completedEncounters.map(e => e.badge);

  return {
    characterName: act.character.displayName,
    completedEncounterBadges,
    isActComplete,
    isPageReady: true,
    topic: act.topic
  };
}

// istanbul ignore next - trivial code, not worth testing
const mapDispatchToProps = {
  transitionToEncounter: () => TransitionAction.actToEncounter()
};

export const ActFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSpinner<Partial<IActFooterProps>>(ActFooterComponent));

export const ActFooterPrivates = { mapStateToProps };
