import * as React from 'react';
import { connect } from 'react-redux';
import { Text } from 'common-ui';
import { CustomerSelector } from 'services/customer';
import { ModalActions, ModalRegistry, ModalType } from 'shared-components';
import { AppShellAction } from 'shell';
import { LoginFormSharedStyles } from '../LoginFormShared.styles';

export interface IDeviceSetupConfirmationModalProps {
  customerName: string;
  close(): void;
}

export class DeviceSetupConfirmationModalComponent extends React.PureComponent<IDeviceSetupConfirmationModalProps> {
  public static readonly displayName = 'DeviceSetupConfirmationModal';

  public render() {
    return (
      <>
        <Text style={LoginFormSharedStyles.get().confirmationText}>
          {this.props.customerName}
        </Text>
        <ModalActions
          primaryAction={{
            ignoreGlobalDisabled: true,
            onPress: this.props.close,
            text: 'Go to Login'
          }}
        />
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    customerName: CustomerSelector.getCustomerName(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  close: () => AppShellAction.hideModal()
};

export const DeviceSetupConfirmationModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSetupConfirmationModalComponent);

DeviceSetupConfirmationModal.ModalId = ModalRegistry.register({
  Component: DeviceSetupConfirmationModal,
  ariaLabel: 'Device Setup Confirmation',
  headline: 'Great! This device is now set up for',
  type: ModalType.Confirmation
});
