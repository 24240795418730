import {
  IMicrophone,
  IMicsAvailableUpdate
} from '@lexialearning/lobo-common/main-model/sre';
import { IMicrophoneService, Sre } from '@lexialearning/sre';
import { LexiaError } from '@lexialearning/utils';
import { Observable, Subject } from 'rxjs';
import { MicPermissionsHandler } from '../helpers/MicPermissionsHandler';

export interface IMicInfo {
  micsAvailable: IMicrophone[];
  mic: IMicrophone | undefined;
  micBlocked: boolean;
}

export enum MicrophoneManagerError {
  Uninitialized = 'Uninitialized'
}

export class MicrophoneManager {
  public static readonly displayName = 'MicrophoneManager';

  public get micsAvailableUpdate$(): Observable<IMicsAvailableUpdate> {
    return this.micsUpdateSubject.asObservable();
  }

  private readonly micsUpdateSubject = new Subject<IMicsAvailableUpdate>();

  private micService?: IMicrophoneService;

  public async init(sre: Sre): Promise<IMicInfo> {
    this.micService = sre.getMicrophoneService();

    this.micService.onAvailableMicrophonesChanged(m => {
      this.micsUpdateSubject.next({ micsAvailable: m });
    });

    const [micsAvailable, micBlocked] = await Promise.all([
      this.micService.refreshMicrophones(),
      MicPermissionsHandler.isBlocked()
    ]);
    const [mic] = micsAvailable;
    if (mic) {
      this.selectMic(mic);
    }

    return { mic, micBlocked, micsAvailable };
  }

  public selectMic(mic: IMicrophone): void {
    if (!this.micService) {
      throw new LexiaError(
        'Cannot select a microphone before initializing',
        MicrophoneManager.displayName,
        MicrophoneManagerError.Uninitialized
      );
    }

    this.micService.selectMicrophone(mic);
  }
}
