import {
  IPositionChange,
  PositionChangeType
} from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UnitAction, UnitActionLoad } from 'curriculum-services/unit';
import {
  ActivityPositionAction,
  ActivityPositionActionChange,
  ActivityPositionActionChanged,
  ActivityPositionActionType,
  IActivityPositionChangeActionPayload,
  ProgramContextSelector
} from 'curriculum-services/program-context/redux';
import { LexiaError } from '@lexialearning/utils';

export type ChangePositionOutputTypes =
  | ActivityPositionActionChanged
  | UnitActionLoad;

export function changePositionEpic(
  action$: Observable<ActivityPositionActionChange>,
  state$: StateObservable<unknown>
): Observable<ChangePositionOutputTypes> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Change),
    mergeMap(action => {
      const { activityPosition: next, changeType } = getImminentPosition(
        state$.value
      );

      const dispatches: ChangePositionOutputTypes[] = [
        ActivityPositionAction.changed(next)
      ];

      const suppressUnitLoad = (
        action.payload as IActivityPositionChangeActionPayload
      )?.suppressUnitLoad;
      if (
        !suppressUnitLoad &&
        [
          PositionChangeType.EncounterCompletion,
          PositionChangeType.Fork,
          PositionChangeType.Join,
          PositionChangeType.StepDown,
          PositionChangeType.StepUp,
          PositionChangeType.UnitCompletion,
          PositionChangeType.UnitRecycling
        ].includes(changeType)
      ) {
        dispatches.push(UnitAction.load.request());
      }

      return from(dispatches);
    })
  );
}
changePositionEpic.displayName = 'changePositionEpic';

function getImminentPosition(state: unknown): IPositionChange {
  const priorPosition = ProgramContextSelector.getActivityPosition(state);

  if (!priorPosition.imminentPosition) {
    throw new LexiaError(
      'Invalid attempt to change activity position lacking an imminent position',
      changePositionEpic.displayName,
      ChangePositionEpicError.ImminentPositionMissing
    ).withContext({ priorPosition });
  }

  return priorPosition.imminentPosition!;
}

export enum ChangePositionEpicError {
  ImminentPositionMissing = 'ImminentPositionMissing'
}
