import {
  CalibrationStatus,
  ICalibration
} from '@lexialearning/lobo-common/main-model/sre';
import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { CalibrationResult } from '@lexialearning/sre';
import {
  SreCalibrationActionType,
  SreSessionActionType
} from '../sre-redux.model';
import { SreCalibrationActions } from './SreCalibration.action';
import { SreSessionActionCancel } from '../session/SreSession.action';

export const calibrationInitialState: ICalibration = {
  history: [],
  result: CalibrationResult.None,
  status: CalibrationStatus.NotCalibrated
};

export function calibrationReducer(
  state = calibrationInitialState,
  action:
    | SreCalibrationActions
    | SreSessionActionCancel
    | UserGlobalActionLogoutSuccess
): ICalibration {
  switch (action.type) {
    case SreCalibrationActionType.SetCalibrationResult:
    case SreCalibrationActionType.Complete:
      const { result } = action.payload;

      return {
        ...state,
        history: [...state.history, result],
        result,
        status:
          result === CalibrationResult.Ok
            ? CalibrationStatus.Calibrated
            : CalibrationStatus.Failure
      };

    case SreCalibrationActionType.Calibrating:
      return {
        ...state,
        result: CalibrationResult.None,
        status: CalibrationStatus.Calibrating
      };

    case SreSessionActionType.Cancel:
    case UserGlobalActionType.LogoutSuccess:
      return calibrationInitialState;

    default:
      return state;
  }
}
