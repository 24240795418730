import {
  FeedbackStrategy,
  IProgressStatus,
  ProgressStatus,
  TaskEvaluationResult
} from '@lexialearning/lobo-common/main-model';
import memoizeOne from 'memoize-one';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ActivityPositionActions,
  ActivityPositionActionType,
  ICurriculumDependencies,
  ProgramContextSelector,
  ProgramContextService,
  RoundContext
} from 'curriculum-services';
import {
  TaskActionLoaded,
  TaskActionShowSolution,
  TaskActionType,
  TaskSelector
} from 'task-components';
import { IMeterMarker } from '../../progress-meters.model';
import { RoundMeterAction, RoundMeterUpdatedAction } from './RoundMeter.action';
import { MeterMarkerProgressStatus } from '../../meterMarker.model';

export function updateRoundMeterEpic(
  action$: Observable<
    TaskActionLoaded | TaskActionShowSolution | ActivityPositionActions
  >,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<RoundMeterUpdatedAction | void> {
  return action$.pipe(
    ofType(
      TaskActionType.Loaded,
      ActivityPositionActionType.Prepared,
      ActivityPositionActionType.SkipPrepared,
      TaskActionType.ShowSolution
    ),
    map(action => {
      const context = ProgramContextSelector.getRoundContextMaybe(state$.value);

      if (!context) {
        return;
      }

      const { programContextService } = deps.curriculumDependencies;
      const isShowingSolution = TaskSelector.getShowSolution(state$.value);
      const isSkipped = action.type === ActivityPositionActionType.SkipPrepared;

      const markers = createRoundMarkers(
        context,
        programContextService,
        isShowingSolution,
        isSkipped
      );

      return RoundMeterAction.updated({ markers });
    })
  );
}
updateRoundMeterEpic.displayName = 'updateRoundMeterEpic';

function createRoundMarkers(
  context: RoundContext,
  programContextService: ProgramContextService,
  isShowingSolution: boolean,
  isSkipped: boolean
): IMeterMarker[] {
  const progress = programContextService.determineUnitProgress(context);

  const markers = progress.map(p => ({
    status: getStatus(p, context, isShowingSolution, isSkipped),
    sysId: p.sysId
  }));

  return markers;
}

/**
 * If 1) user answered incorrectly, 2) we aren't showing them the solution at
 * the moment, and 3) we could show it to them at some point, continue to return
 * active round meter status as active and not completed.
 * (This is required because the active round pip should turn green only when
 * solution is displayed which is few seconds after the answer is selected
 * so we need to adjust the progress here)
 * Otherwise, return mapped MeterMarkerProgressStatus for the given ProgressStatus
 */
function getStatus(
  progressStatus: IProgressStatus,
  context: RoundContext,
  isShowingSolution: boolean,
  isSkipped: boolean
): MeterMarkerProgressStatus {
  const activeRound = isActiveRound(progressStatus, context);

  // Skipping in educator mode should mark the round completed right away
  // So that issues like this one are mitigated: https://jira.lexialearning.com/browse/LOBO-13699
  if (isSkipped && activeRound) {
    return MeterMarkerProgressStatus.Completed;
  }

  return isPreSolutionIncorrect(context, isShowingSolution) &&
    isActiveRound(progressStatus, context)
    ? MeterMarkerProgressStatus.Active
    : ProgressStatusMap.get(progressStatus.status)!;
}

const isPreSolutionIncorrect = memoizeOne(
  (context: RoundContext, isShowingSolution: boolean): boolean => {
    const incorrectlyAnswered =
      context.lastAttemptMaybe?.result !== TaskEvaluationResult.Correct;
    const hasSolutionPhase =
      context.parentUnit.feedbackStrategy !== FeedbackStrategy.Neutral;

    return !isShowingSolution && hasSolutionPhase && incorrectlyAnswered;
  }
);

function isActiveRound(progressStatus: IProgressStatus, context: RoundContext) {
  return context.standardRound.sysId === progressStatus.sysId;
}

const ProgressStatusMap = new Map<ProgressStatus, MeterMarkerProgressStatus>([
  [ProgressStatus.Active, MeterMarkerProgressStatus.Active],
  [ProgressStatus.Done, MeterMarkerProgressStatus.Completed],
  [ProgressStatus.Pending, MeterMarkerProgressStatus.Upcoming],
  [ProgressStatus.Skip, MeterMarkerProgressStatus.RecycleSkip]
]);
