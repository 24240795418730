import { AnimatedView, View } from 'common-ui';
import { CaretSvg } from './Caret.lx-svg';
import { IBarStyleOverride, BarAnimatedStyles } from './Bar.animated-styles';
import { InteractionState } from 'common-styles';
import { Sparkles } from './sparkles/Sparkles';
import { useEffect, useRef, useState } from 'react';

export interface IBarProps {
  activeBarColor?: string;
  interactionState?: InteractionState;
  isActive: boolean;
  styleOverride?: IBarStyleOverride;
  withCaret?: boolean;
  withSparkles?: boolean;
}

export function Bar(props: IBarProps) {
  const {
    activeBarColor,
    interactionState,
    isActive,
    styleOverride,
    withCaret,
    withSparkles
  } = props;

  const BarAnimatedStylesRef = useRef(new BarAnimatedStyles());
  const { brighten } = BarAnimatedStylesRef.current.getAnimations();
  const styles = BarAnimatedStylesRef.current.build(
    isActive,
    interactionState,
    activeBarColor,
    styleOverride
  );

  const [triggerSparkles, setTriggerSparkles] = useState(0);

  useEffect(() => {
    if (isActive) {
      brighten.start();
      setTriggerSparkles(val => val + 1);
    }
  }, [brighten, isActive]);

  return (
    <View style={styles.bar}>
      {withSparkles && (
        <>
          <AnimatedView
            animatedStyle={styles.barBrightenAnimated}
            style={styles.barBrighten}
          />
          <Sparkles triggerAnimation={triggerSparkles} />
        </>
      )}
      {withCaret && <CaretSvg style={styles.caret} />}
    </View>
  );
}
Bar.displayName = 'Bar';
