import { useSelector } from 'react-redux';
import { DeveloperAccessMenu } from 'feature-areas/dev-tools';
import { View, useSizing } from 'common-ui';
import { ProfileSelector } from 'services/profile';
import { AppShellMode } from '../redux/app-shell.model';
import { AppShellSelector } from '../redux/AppShell.selector';
import { SkipToNextPositionButton } from './skip-to-next-position-button';
import { ShowUserInfoButton } from './user-info-button';
import { UtilityBarStyles } from './UtilityBar.styles';
import { ShowTroubleshootButton } from './troubleshoot-button';
import { NavigationButton } from './navigation-button/NavigationButton';

export function UtilityBar() {
  const isStudent = useSelector(ProfileSelector.isStudent);
  const appShellMode = useSelector(AppShellSelector.getMode);
  const isSkipButtonVisible = appShellMode === AppShellMode.Full && !isStudent;

  const isDisabled = useSelector(AppShellSelector.isUtilityBarDisabled);

  const isZIndexDisabled = useSelector(
    AppShellSelector.isUtilityBarZIndexDisabled
  );

  const { appSize } = useSizing();

  const styles = UtilityBarStyles.build(appSize.w, isZIndexDisabled);

  return (
    <View style={styles.container} id={UtilityBar.displayName}>
      <NavigationButton disabled={isDisabled} />

      {isSkipButtonVisible && <SkipToNextPositionButton />}

      <ShowUserInfoButton disabled={isDisabled} />
      <ShowTroubleshootButton disabled={isDisabled} />

      <DeveloperAccessMenu />
    </View>
  );
}
UtilityBar.displayName = 'UtilityBar';
