import { ISeeSpeak, TaskTypeName } from '@lexialearning/lobo-common';
import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { LanguageFrameManager } from '../language-frame/LanguageFrameManager';
import { ISeeSpeakState, SeeSpeakActionType } from './see-speak-redux.model';
import { SeeSpeakActions } from './SeeSpeak.action';
import {
  TaskActionExitComplete,
  TaskActionLoaded,
  TaskActionType
} from '../../core/redux';

const seeSpeakInitialState: ISeeSpeakState = {
  languageFrameTokens: [],
  sreOptions: undefined
};

export function seeSpeakReducer(
  state = seeSpeakInitialState,
  action:
    | SeeSpeakActions
    // | SreSessionActionListenResult
    | TaskActionLoaded
    | TaskActionExitComplete
    | UserGlobalActionLogoutSuccess
): ISeeSpeakState {
  switch (action.type) {
    case SeeSpeakActionType.DecoratedTokens:
      return {
        ...state,
        languageFrameTokens: action.payload.languageFrameTokens
      };

    case TaskActionType.Loaded:
      const taskContent = action.payload.taskContent;
      if (action.payload.taskContent.taskType !== TaskTypeName.SeeSpeak) {
        return state;
      }

      return {
        ...state,
        languageFrameTokens: (taskContent as ISeeSpeak).languageFrame
      };

    case SeeSpeakActionType.ClearSreOptions:
      return {
        ...state,
        sreOptions: undefined
      };

    case SeeSpeakActionType.SetSreOptionsSuccess:
      return {
        ...state,
        sreOptions: action.payload
      };

    case SeeSpeakActionType.RemoveTokenDecoration:
      return {
        ...state,
        languageFrameTokens: LanguageFrameManager.manage(
          state.languageFrameTokens
        )
          .undecorateAll(action.payload.decoration)
          .toUnManaged()
      };

    case TaskActionType.ExitComplete:
    case UserGlobalActionType.LogoutSuccess:
      return seeSpeakInitialState;

    default:
      return state;
  }
}

export const SeeSpeakReducerPrivates = { initialState: seeSpeakInitialState };
