import { Border } from '@lexialearning/common-ui';
import { Color, Font } from 'common-styles';

export class EditConfigValueStyles {
  private static readonly styles = {
    applyButton: {
      button: {
        minWidth: 100
      },
      buttonText: {
        fontSize: Font.Size.small
      }
    },
    textInput: {
      input: {
        borderColor: Color.Gray30,
        borderStyle: Border.Solid,
        borderWidth: 1,
        fontSize: Font.Size.small,
        marginRight: 30,
        paddingLeft: 5,
        paddingRight: 5,
        paddingVertical: 5,
        width: 300
      }
    },
    textInputHeader: { fontSize: Font.Size.small }
  };

  public static get() {
    return this.styles;
  }
}
