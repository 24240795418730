import { LexiaError } from '@lexialearning/utils';
import { createSelector } from '@reduxjs/toolkit';
import { IAuthDetailsSso } from 'lexia-service';
import { IAuth, IAuthError } from '../auth.model';
import { IAuthState } from './auth-redux.model';

export class AuthSelector {
  public static readonly displayName = 'AuthSelector';

  public static getAuth: (state: unknown) => IAuth;

  public static getAuthDetailsMaybe: (
    state: unknown
  ) => IAuthDetailsSso | undefined;

  public static getAuthDetails: (state: unknown) => IAuthDetailsSso;

  public static getAuthError: (state: unknown) => IAuthError;

  public static getAuthNameMaybe: (state: unknown) => string | undefined;

  public static getLogoutUrlMaybe: (state: unknown) => string | undefined;

  public static getLoginId: (state: unknown) => string | undefined;

  public static getLoginTime: (state: unknown) => number | undefined;

  public static hasValidationError: (state: unknown) => boolean;

  public static isAuthenticated: (state: unknown) => boolean;

  public static createSelectors(selector: (state: any) => IAuthState): void {
    this.getAuth = createSelector(
      selector,
      ({ apiTimeoutArray, authToken, loginId, personId }: IAuthState) => ({
        apiTimeoutArray,
        authToken,
        loginId,
        personId
      })
    );

    this.getAuthDetailsMaybe = createSelector(
      selector,
      (state: IAuthState) => state.details
    );

    this.getAuthDetails = createSelector(
      this.getAuthDetailsMaybe,
      (details: IAuthDetailsSso | undefined) => {
        if (!details) {
          throw new LexiaError(
            'Missing authentication details',
            AuthSelector.displayName,
            AuthSelectorError.NoDetails
          );
        }

        return details;
      }
    );

    this.getAuthError = createSelector(
      selector,
      (auth: IAuthState) => auth.authError
    );

    this.getAuthNameMaybe = createSelector(
      this.getAuthDetails,
      (details: IAuthDetailsSso) => details.name
    );

    this.getLogoutUrlMaybe = createSelector(
      selector,
      (auth: IAuthState) => auth.logoutUrl
    );

    this.getLoginId = createSelector(
      selector,
      (auth: IAuthState) => auth.loginId
    );

    this.getLoginTime = createSelector(
      selector,
      (auth: IAuthState) => auth.loginTime
    );

    this.hasValidationError = createSelector(
      this.getAuthError,
      ({ incorrectUsernameOrPassword }: IAuthError) =>
        incorrectUsernameOrPassword
    );

    this.isAuthenticated = createSelector(
      selector,
      (auth: IAuthState) => auth.isAuthenticated
    );
  }
}

export enum AuthSelectorError {
  NoDetails = 'NoDetails'
}
