import { ICard } from '@lexialearning/lobo-common/main-model';
import { ThemeType, useTheme } from 'theme';
import { InstructionalImage } from '../instructional-image/InstructionalImage';
import { Row } from '../layout';
import { Spotlight } from '../spotlight/Spotlight';
import {
  ISpotlightStyleOverride,
  SpotlightAreaLayout
} from '../spotlight/Spotlight.styles';

export interface ISpotlightAreaProps {
  instructionalImage?: ICard;
  spotlights?: ICard[];
  layout?: SpotlightAreaLayout;
  styleOverride?: {
    spotlightStyleOverride: ISpotlightStyleOverride;
  };
}

export function SpotlightArea(props: ISpotlightAreaProps) {
  const { themeType } = useTheme();
  const { instructionalImage, spotlights, styleOverride } = props;
  // Override layout size if is instructional step, to set it to small version
  const layout =
    themeType === ThemeType.Instruction
      ? SpotlightAreaLayout.Small
      : props.layout;

  if (!(instructionalImage || (spotlights && spotlights.length > 0))) {
    return null;
  }

  return (
    <Row
      style={styleOverride?.spotlightStyleOverride.container}
      testId={SpotlightArea.displayName}
    >
      {!!instructionalImage?.image && (
        <InstructionalImage
          sysId={instructionalImage.sysId}
          source={instructionalImage.image.imageSource}
          layout={layout}
          accessibilityLabel={instructionalImage.image.altText}
        />
      )}
      {!instructionalImage && !!spotlights?.length && (
        <Row>
          {spotlights.map((spotlight, idx) => (
            <Spotlight
              key={idx}
              spotlightsCount={spotlights.length}
              imageSource={spotlight.image?.imageSource}
              accessibilityLabel={spotlight.image?.altText}
              text={spotlight.label}
              layout={layout}
            />
          ))}
        </Row>
      )}
    </Row>
  );
}
SpotlightArea.displayName = 'SpotlightArea';
