import * as React from 'react';
import { connect } from 'react-redux';
import { AnimatedView } from 'common-ui';
import { LevelSelector } from 'curriculum-services';
import { Services } from 'services';
import { BackgroundGradientSvg } from '../background/BackgroundGradient.lx-svg';
import { CurtainAnimatedStyles } from './Curtain.animated-styles';
import { CurtainReactAnimationName } from './curtain.model';

export interface ICurtainReduxProps {
  levelNumber: number | undefined;
}

/**
 * Overlay which visually covers the full app
 * and can be animated in and out of view
 * - has no affect on app interactability
 * - currently used for nav back transitions
 */
export class CurtainComponent extends React.PureComponent<ICurtainReduxProps> {
  public static readonly displayName = 'Curtain';

  private readonly animatedStyles: CurtainAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: ICurtainReduxProps) {
    super(props);

    this.animatedStyles = new CurtainAnimatedStyles();
    this.unregisterAnimations = this.registerAnimations();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const {
      backToHome: { fadeIn: homeFadeIn, fadeOut: homeFadeOut },
      backToLevel: { fadeIn: levelFadeIn, fadeOut: levelFadeOut }
    } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: CurtainReactAnimationName.BackToHomeFadeIn,
        animation: homeFadeIn
      },
      {
        name: CurtainReactAnimationName.BackToHomeFadeOut,
        animation: homeFadeOut
      },
      {
        name: CurtainReactAnimationName.BackToLevelFadeIn,
        animation: levelFadeIn
      },
      {
        name: CurtainReactAnimationName.BackToLevelFadeOut,
        animation: levelFadeOut
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  public render() {
    const { levelNumber } = this.props;
    const styles = this.animatedStyles.get();
    const levelColors = this.animatedStyles.getLevelColors(levelNumber);

    return (
      <>
        <AnimatedView
          testId={CurtainComponent.displayName}
          style={styles.container}
          animatedStyle={styles.backToHome}
          blockPointerEvents
        >
          <BackgroundGradientSvg id="backToHome" />
        </AnimatedView>
        <AnimatedView
          style={styles.container}
          animatedStyle={styles.backToLevel}
          blockPointerEvents
        >
          <BackgroundGradientSvg id="backToLevel" {...levelColors} />
        </AnimatedView>
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    levelNumber: LevelSelector.getLevelNumberMaybe(state)
  };
}

/**
 * Overlay which visually blocks the full app
 * and can be animated in and out of view
 * - has no affect on app interactability
 * - currently used for nav back transitions
 */
export const Curtain = connect(mapStateToProps)(CurtainComponent);
