import { View, AnimatedView } from 'common-ui';
import { IButtonMeterStyles } from './button-meter.model';

export interface IButtonMeterProps {
  showMeter: boolean;
  showMic: boolean;
  styles: IButtonMeterStyles;
  renderMeter: () => React.ReactNode;
  renderMic: () => React.ReactNode;
  renderBeforeMeter?: () => React.ReactNode;
  renderAfterMeter?: () => React.ReactNode;
}

export function ButtonMeter(props: IButtonMeterProps) {
  const {
    renderMeter,
    renderMic,
    renderBeforeMeter,
    renderAfterMeter,
    showMeter,
    showMic,
    styles
  } = props;

  return (
    <View style={styles.outerContainer} testId={ButtonMeter.displayName}>
      <AnimatedView
        style={styles.borderContainer}
        animatedStyle={styles.borderContainerAnimated}
      >
        {showMeter && (
          <>
            {renderBeforeMeter?.()}
            <AnimatedView
              style={styles.vuMeterContainer}
              animatedStyle={styles.vuMeterOpacityAnimated}
            >
              {
                // istanbul ignore next - LOBO-19009 to resolve in separate PR
                showMeter && renderMeter()
              }
            </AnimatedView>
            {renderAfterMeter?.()}
          </>
        )}
      </AnimatedView>
      <AnimatedView
        style={styles.micContainer}
        animatedStyle={styles.micContainerOpacityAnimated}
      >
        {showMic && renderMic()}
      </AnimatedView>
    </View>
  );
}
ButtonMeter.displayName = 'ButtonMeter';
