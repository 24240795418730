import { SizingUtils } from 'common-ui';
import {
  IBundledImageSourceSet,
  IImageSourceSet,
  Transform
} from 'common-ui/components/image';
import { REPO_IMG_DIR } from 'services/app-config/appConfig';
import { IMountPoint } from 'storm';

/**
 * for content images whose URL is obtained through content - source is a string
 * for static images that are bundled using webpack - source is an object conforming to IBundledImageSourceSet interface
 */
export type IWebImageSource = string | IBundledImageSourceSet | undefined;

/**
 * Image source transformer used only on web, for native version see LoboImageSourceTransformer.native.ts
 */
export class LoboImageSourceTransformer {
  public static getTransformMethod(
    imagesMountPoint: IMountPoint
  ): Transform<IWebImageSource> {
    return this.webTransform(imagesMountPoint);
  }

  private static createImageSourceSetFromUrl(source: string): IImageSourceSet {
    const [link, format] = source.split(/\.(jpe?g|tiff|png|webp|bmp)$/i);

    return {
      large: `${link}@3x.${format || 'jpg'}`,
      medium: `${link}@2x.${format || 'jpg'}`,
      small: source
    };
  }

  public static transformBundledSourceSet(
    bundledSourceSet: IBundledImageSourceSet
  ) {
    const {
      data: { uri: small, 'uri@2x': medium, 'uri@3x': large }
    } = bundledSourceSet;

    return {
      large,
      medium,
      small
    };
  }

  private static webTransform(
    imagesMountPoint: IMountPoint
  ): Transform<IWebImageSource> {
    return (source: IWebImageSource) => {
      if (source === undefined) {
        return {};
      }

      // source is a string for content images
      if (typeof source === 'string') {
        const resolvedSourceUrl = source.startsWith(REPO_IMG_DIR)
          ? source
          : source.match(/^\/\//)
          ? `https:${source}`
          : `${imagesMountPoint.webOrigin}${imagesMountPoint.dir}${source}`;

        if (resolvedSourceUrl.endsWith('.gif')) {
          return {
            sourceUrl: resolvedSourceUrl
          };
        }

        const contentSourceSet =
          this.createImageSourceSetFromUrl(resolvedSourceUrl);

        return {
          sourceSet: this.maybeTransformForScaling(contentSourceSet)
        };
      }

      // otherwise source implements IBundledImageSourceSet corresponding to bundled static image
      const bundledSourceSet = this.transformBundledSourceSet(
        source as IBundledImageSourceSet
      );

      return {
        sourceSet: this.maybeTransformForScaling(bundledSourceSet)
      } as any;
    };
  }

  /**
   * Browser chooses from srcset based on pixel density only, so, due to app scaling,
   * a student on a much larger screen that has lower pixel density, will receive
   * a 1x image, when they might be at a screen size that warrants a 2x or 3x image.
   * Therefore, this updates the srcset to assign larger images to lower pixel density
   * sizes in the srcset (small, medium) when the scaling is above a certain threshold
   */
  private static maybeTransformForScaling(
    srcSet: IImageSourceSet
  ): Partial<IImageSourceSet> {
    const { scale } = SizingUtils.getAppSizing();

    // App is scaled less than 1.5x, return original srcset
    if (scale < 1.5) {
      return srcSet;
    }

    // App is scaled less than 2.5x, but greater than or equal to 1.5x,
    // use medium srcset image (@2) for small and large (@2) for medium
    // (large uses to medium value if undefined)
    if (scale < 2.5) {
      return {
        medium: srcSet.large || srcSet.medium,
        small: srcSet.medium || srcSet.small
      };
    }

    // App is scaled 2.5x or greater, use largest srcset image (@3)
    // for all source sizes
    // (medium and large use small value if undefined)
    return {
      small: srcSet.large || srcSet.medium || srcSet.small
    };
  }
}
