import { IAnimationDefinition } from '@lexialearning/lobo-common';
import { LxStormNode } from '../service/LxStormNode';
import { SceneBase } from './SceneBase';
import { SceneElement } from './SceneElement';
import { IMaterialDefinition } from './storm-scenes.model';

export class Scene extends SceneBase<SceneElement> {
  public static readonly displayName = 'Scene';

  public readonly id: string;

  constructor(
    id: string, // tk - Do not make this a shorthand member initializer as Istanbul injection will fail below. id must be initialized after super() is called.
    node: LxStormNode,
    elements: SceneElement[],
    animationDefinitions: IAnimationDefinition[],
    materialDefinitions: IMaterialDefinition[]
  ) {
    super('', node, elements, animationDefinitions, materialDefinitions);
    this.id = id;
  }

  public get active(): boolean {
    return this.node.stormNode.active;
  }

  public set active(value: boolean) {
    this.node.stormNode.active = value;
  }

  public get zIndex(): number {
    return this.node.stormNode.zIndex;
  }

  public set zIndex(value: number) {
    this.node.stormNode.zIndex = value;
  }
}
