import { createSelector } from '@reduxjs/toolkit';
import { ProgressMetersSelector } from '../progress-meters/redux/ProgressMeters.selector';
import {
  AppShellMode,
  ControlPanelLayout,
  IActiveModal,
  IAppShellBaseState,
  IAppShellState,
  IUserControlInteractionState,
  NavigationButtonType,
  UserInfoButtonType
} from './app-shell.model';

type AppShellSelectorType = (state: any) => IAppShellState;
export class AppShellSelector {
  public static readonly displayName = 'AppShellSelector';

  public static getBaseState: (state: unknown) => IAppShellBaseState;

  public static getActiveModal: (state: unknown) => IActiveModal | undefined;

  public static isModalOpen: (state: unknown) => boolean;

  /**
   * Return the duration (in seconds) when the loader/spinner has been in a
   * shown state.
   */
  public static getSpinnerDisplaySeconds: (state: unknown) => number;

  public static getMode: (state: unknown) => AppShellMode;

  public static getShowSpinner: (state: unknown) => boolean;

  public static getUserInfoButtonType: (state: unknown) => UserInfoButtonType;

  public static getNavigationButtonType: (
    state: unknown
  ) => NavigationButtonType;

  public static getShouldCalloutLevelCertificates: (state: unknown) => boolean;

  public static getControlPanelLayout: (state: unknown) => ControlPanelLayout;

  public static getShouldShowSkipButton: (state: unknown) => boolean;

  public static getUserControlInteractionState: (
    state: unknown
  ) => IUserControlInteractionState;

  public static isUtilityBarDisabled: (state: unknown) => boolean;

  public static isUtilityBarZIndexDisabled: (state: unknown) => boolean;

  public static isAudioSupportDisabled: (state: unknown) => boolean;

  public static createSelectors(selector: AppShellSelectorType): void {
    AppShellSelector.getBaseState = createSelector(
      selector,
      (state: IAppShellState) => state.base
    );

    AppShellSelector.getActiveModal = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.activeModal
    );

    AppShellSelector.isModalOpen = createSelector(
      AppShellSelector.getActiveModal,
      (modal: IActiveModal | undefined) => !!modal
    );

    AppShellSelector.getSpinnerDisplaySeconds = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) =>
        (Date.now() - baseState.spinnerShowTimestamp) / 1000
    );

    AppShellSelector.getMode = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.mode
    );

    AppShellSelector.getShowSpinner = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.showSpinner
    );

    AppShellSelector.getUserInfoButtonType = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.userInfoButtonType
    );

    AppShellSelector.getNavigationButtonType = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.navigationButtonType
    );

    AppShellSelector.getShouldCalloutLevelCertificates = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) =>
        baseState.shouldCalloutLevelCertificates
    );

    AppShellSelector.getShouldShowSkipButton = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.shouldShowSkipButton
    );

    AppShellSelector.getControlPanelLayout = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.controlPanelLayout
    );

    AppShellSelector.getUserControlInteractionState = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.userControlInteractionState
    );

    AppShellSelector.isUtilityBarDisabled = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.utilityBarDisabled
    );

    AppShellSelector.isUtilityBarZIndexDisabled = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.utilityBarZIndexDisabled
    );

    AppShellSelector.isAudioSupportDisabled = createSelector(
      AppShellSelector.getBaseState,
      (baseState: IAppShellBaseState) => baseState.audioSupportDisabled
    );

    ProgressMetersSelector.createSelectors(
      state => selector(state).progressMeters
    );
  }
}
