import {
  TextInput as RxTextInput,
  Types as RxTypes
} from '@lexialearning/reactxp';
import capitalize from 'lodash/capitalize';
import * as React from 'react';
import { compose } from 'redux';
import { Color } from 'common-styles';
import { ISfxProps, Sfx, withSfx } from 'audio/sfx';
import { SystemInfo } from 'utils';
import { PlainTextButton } from '../../button';
import { Text } from '../../text';
import { View } from '../../view';
import {
  IGlobalDisabledProps,
  withGlobalDisabled
} from '../../withGlobalDisabled.hoc';
import { ITextInputStylesOverride, TextInputStyle } from './TextInput.styles';

export enum KeyboardType {
  Default = 'default',
  Numeric = 'numeric',
  EmailAddress = 'email-address',
  NumberPad = 'number-pad'
}

export interface IErrorInfo {
  error?: boolean;
  errorMessage?: string;
  errorTitle?: string;
  emphasizeError?: boolean;
}

export interface ITextInputIconProps {
  color: string;
}

export interface ITextInputProps
  extends RxTypes.TextInputProps,
    ISfxProps,
    IGlobalDisabledProps {
  disabled?: boolean;
  keyboardType?: KeyboardType;
  name: string;
  errorInfo?: IErrorInfo;
  inputRef?: any;
  styleOverrides?: ITextInputStylesOverride;
  showSecureTextOption?: boolean;
  disallowSpaces?: boolean;
  Icon?: React.ElementType<ITextInputIconProps>;
  onChange(newValue: string, fieldName: string): void;
}

export interface ITextInputState {
  isFocused: boolean;
  isSecure: boolean;
}

export class TextInputComponent extends React.PureComponent<
  ITextInputProps,
  ITextInputState
> {
  public static displayName = 'TextInput';

  constructor(props: ITextInputProps) {
    super(props);

    this.state = {
      isFocused: false,
      isSecure: props.secureTextEntry || false
    };

    this.toggleSecure = this.toggleSecure.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidUpdate(_: ITextInputProps, prevState: ITextInputState) {
    const { showSecureTextOption, secureTextEntry } = this.props;
    if (!showSecureTextOption && secureTextEntry && !prevState.isSecure) {
      this.setState({ isSecure: true });
    }
  }

  private toggleSecure() {
    this.props.playSfx(Sfx.Neutral);
    this.setState(prevState => ({ isSecure: !prevState.isSecure }));
  }

  private handleChange(v: string) {
    if (this.props.disallowSpaces && v.includes(' ')) {
      return;
    }

    this.props.onChange(v, this.props.name);
  }

  private handleFocus(e: RxTypes.FocusEvent) {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  private handleBlur(e: RxTypes.FocusEvent) {
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  public render() {
    const {
      disabled,
      errorInfo,
      name,
      styleOverrides,
      inputRef,
      Icon,
      keyboardType,
      ...inputProps
    } = this.props;

    const styles = TextInputStyle.build(
      !!Icon,
      this.state.isFocused,
      this.state.isSecure,
      !!errorInfo?.error && (errorInfo?.emphasizeError ?? true), // emphasize text if error is present by default
      styleOverrides
    );

    const { isNative } = SystemInfo;

    return (
      <View>
        {errorInfo?.error && (
          <View style={styles.errorBox}>
            {errorInfo.errorTitle && (
              <Text style={styles.errorTitle}>{errorInfo.errorTitle}</Text>
            )}
            <Text style={styles.errorText}>
              {errorInfo.errorMessage || `${capitalize(name)} is not correct.`}
            </Text>
            <View style={styles.errorArrow} />
          </View>
        )}
        <RxTextInput
          editable={!disabled}
          allowFontScaling={false}
          autoCapitalize="none"
          {...inputProps}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChangeText={this.handleChange}
          secureTextEntry={this.state.isSecure}
          placeholderTextColor={Color.Gray60}
          style={styles.input}
          ref={inputRef}
          keyboardType={isNative ? keyboardType : undefined}
        />
        {Icon && (
          <View style={styles.iconView}>
            <Icon color={styles.iconColor.color} />
          </View>
        )}

        {inputProps.showSecureTextOption && (
          <PlainTextButton
            onPress={this.toggleSecure}
            styleOverride={styles.showButtonOverride}
            testId="showSecureTextButton"
            text={this.state.isSecure ? 'Show' : 'Hide'}
          />
        )}
      </View>
    );
  }
}

export const TextInput = compose(
  withSfx,
  withGlobalDisabled
)(TextInputComponent);
