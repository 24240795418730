import { LexiaError } from '@lexialearning/utils';
import { AppCrasher } from '../app-crasher/AppCrasher';
import { Logger } from '@lexialearning/utils-react';
import { LexiaErrorSeverity } from '@lexialearning/main-model';

export enum ErrorShortCircuiterError {
  FatalErrorLoop = 'FatalErrorLoop'
}

export interface IFatalErrorLoopContext {
  recentErrors: {
    rootCode: string;
    timestamp: string; // ISO date
  }[];
  totalSessionErrors: number;
}

interface IErrorRecord {
  rootCode: string;
  timestamp: number;
}

export class ErrorShortCircuiter {
  public static readonly displayName = 'ErrorShortCircuiter';

  /**
   * Max number of error records to keep in the array
   */
  public static readonly BufferSize = 100;

  /**
   * Max number of seconds to retain error records. Expired records are removed
   * when new ones are submitted (not as soon as they expire).
   */
  public static readonly MaxRecordSeconds = 3;

  /**
   * Buffer of error records limited by total size and record age
   * @see trim
   */
  private static errors: IErrorRecord[] = [];

  /**
   * True when actively logging a short-circuit event (aka fatal error loop)
   * to prevent subsequent logs/crashing triggers.
   * Set to true when initiating a crash event log and trigger and set to false
   * only on reset (which should theoretically never happen after a crash)
   */
  private static crashing = false;

  /**
   * Total number of error records recorded since start or last reset
   */
  private static counter = 0;

  /**
   * Public mainly to simplify unit testing
   */
  public static getErrorRecords(): IErrorRecord[] {
    return this.errors;
  }

  /**
   * Public only to simplify unit testing
   */
  public static determineMinRecordAge(): number {
    return performance.now() - this.MaxRecordSeconds * 1_000;
  }

  /**
   * Track errors. If the same error (based on root code) occurs more than
   * twice, log a special error and crash the app (i.e. short-circuit).
   */
  public static async record(error: LexiaError): Promise<void> {
    this.trim();

    const rootCode = error.getRootCode();
    this.errors.push({ rootCode, timestamp: performance.now() });
    this.counter += 1;

    // TODO: Same error is getting caught twice when first thrown, need to look into this,
    // but for now considering it a fatal error loop when 3 instances of the same fatal error have occurred
    // since last successful login (or app start), rather than 2
    // https://jira.lexialearning.com/browse/LOBO-15474
    // The same fatal error has recurred too often
    const shouldShortCircuit =
      !this.crashing &&
      this.errors.filter(e => e.rootCode === rootCode).length >= 3;

    if (shouldShortCircuit) {
      this.crashing = true; // prevent a concurrent crash log/trigger
      await Logger.logError(
        new LexiaError(
          'Forcing crash to resolve error loop',
          this.displayName,
          ErrorShortCircuiterError.FatalErrorLoop
        )
          .withCause(error)
          .withContext(this.createCrashErrorContext())
          .withSeverity(LexiaErrorSeverity.Fatal)
      );

      AppCrasher.crash();
    }
  }

  public static reset(): void {
    this.counter = 0;
    this.errors = [];
    this.crashing = false;
  }

  private static createCrashErrorContext(): IFatalErrorLoopContext {
    return {
      recentErrors: this.errors.map(e => ({
        rootCode: e.rootCode,
        timestamp: new Date(performance.timeOrigin + e.timestamp).toISOString()
      })),
      totalSessionErrors: this.counter
    };
  }

  /**
   * Keep the error record array trimmed to a buffer limited by record age and
   * total size.
   */
  private static trim(): void {
    const min = this.determineMinRecordAge();
    this.errors = this.errors
      .filter(e => e.timestamp > min)
      // trim to buffer size + one more slot for error coming in
      .slice(-(this.BufferSize - 1));
  }
}
