import {
  IActivityPosition,
  IRoundRecycling,
  IUnit,
  IUnitPosition
} from '@lexialearning/lobo-common';
import {
  ContentProviderFactory,
  IContentProvider,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { ActivityPositionBuilder } from '../../program-context/epics/progress/position-determiners';
import { IUnitLoadSuccessActionPayload } from '../redux';
import { PoKUnitRecycler } from './PoKUnitRecycler';

export class UnitLoader {
  public static readonly displayName = 'UnitLoader';

  private readonly provider: IContentProvider;

  public constructor(contentProviderFactory: ContentProviderFactory) {
    this.provider = contentProviderFactory.create();
  }

  public async load(
    activityPosition: IActivityPosition
  ): Promise<IUnitLoadSuccessActionPayload> {
    const {
      activeUnitPositionAsSubunitPosition: activeUnitPosition,
      unitPositions
    } = ActivityPositionBuilder.create(activityPosition);

    const unit = await this.loadUnit(activeUnitPosition.unitId);
    const unitContent = this.shouldRecycle(activeUnitPosition.recycling)
      ? PoKUnitRecycler.recycle(unit, activeUnitPosition)
      : unit;
    const parentUnits = await this.loadParents(unitPositions.slice(0, -1));

    return {
      parentUnits,
      unitContent
    };
  }

  private shouldRecycle(recycling: IRoundRecycling | undefined): boolean {
    const isRecyclePass = !!recycling?.pass;
    const roundsToRecycleCount = recycling?.roundIdsToRecycle.length ?? 0;

    return isRecyclePass && roundsToRecycleCount > 0;
  }

  private async loadParents(unitPositions: IUnitPosition[]): Promise<IUnit[]> {
    const loadingUnits = unitPositions.map(async u => this.loadUnit(u.unitId));

    return Promise.all(loadingUnits);
  }

  private async loadUnit(id: string): Promise<IUnit> {
    return this.provider.loadById<IUnit>(LoboContentType.Unit, id);
  }
}
