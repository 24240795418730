import { LexiaError } from '@lexialearning/utils';
import { merge } from 'lodash';
import { TextPill } from 'common-ui';
import { Services } from 'services';
import { TextSegment } from 'task-components/shared/text-segment';
import { DragItemType } from '../DragItemType';
import { RenderDragLayerItemStyle } from './RenderDragLayerItem.styles';

export function RenderDragLayerItem(itemType: DragItemType, itemProps?: any) {
  const styles = RenderDragLayerItemStyle.get();

  switch (itemType) {
    case DragItemType.TextPill:
      return (
        <TextPill
          {...itemProps}
          hasShadow
          styleOverride={merge(
            {},
            styles.textPill,
            itemProps?.dragStyleOverride
          )}
        />
      );
    case DragItemType.TextSegment:
      return <TextSegment {...itemProps} isPreviewing />;

    default:
      Services.logError(
        new LexiaError(
          `invalid DragItemType: ${itemType}`,
          'RenderDragLayerItem',
          RenderDragLayerItemErrorCode.invalidDragItemType
        ).withContext({ itemProps })
      );

      return null;
  }
}

enum RenderDragLayerItemErrorCode {
  invalidDragItemType = 'invalidDragItemType'
}
