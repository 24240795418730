import { MicPermissionsHandler } from '../helpers/MicPermissionsHandler';
import { Observable } from 'rxjs';
import {
  SreCheckMicPermissionAction,
  SreConfigAction,
  SreConfigActionType,
  SreSetMicBlockedAction
} from '../redux';
import { map, switchMap, take } from 'rxjs/operators';
import { ofType } from 'redux-observable';

export function checkMicPermissionEpic(
  action$: Observable<SreCheckMicPermissionAction>
): Observable<SreSetMicBlockedAction> {
  return action$.pipe(
    ofType(SreConfigActionType.CheckMicPermission),
    take(1),
    switchMap(() => MicPermissionsHandler.isBlocked$),
    map(isMicBlocked => SreConfigAction.setMicBlocked(!!isMicBlocked))
  );
}
checkMicPermissionEpic.displayName = 'checkMicPermissionEpic';
