import { Svg, Path } from '@lexialearning/common-ui';
import { Color } from 'common-styles';

export interface IArrowButtonSvgStyleOverride {
  height?: number;
  width?: number;
  color?: string;
}

export enum ArrowButtonType {
  Prev = 'prev',
  Next = 'next'
}

export interface IArrowButtonSvgProps {
  type: ArrowButtonType;
  withShadow: boolean;
  isKeyboardFocused: boolean;
  styleOverride: IArrowButtonSvgStyleOverride;
}

export function ArrowButtonSvg(props: IArrowButtonSvgProps) {
  const {
    type,
    withShadow,
    isKeyboardFocused,
    styleOverride: { height = 54, width = 60, color = Color.NearWhite }
  } = props;
  const transform =
    type === ArrowButtonType.Next ? 'rotate(-180 30 27)' : undefined;
  const shadowTransform =
    type === ArrowButtonType.Next
      ? `${transform} translate(-2 -2)`
      : 'translate(2 2)';

  const accessibilityLabel =
    type === ArrowButtonType.Prev ? 'Previous Slide' : 'Next Slide';

  const sharedPathProps = {
    d: 'M 6 27 l 48 0 M 6 27 l 26 20 M 6 27 l 26 -20',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  };

  return (
    <Svg
      width={width}
      height={height}
      viewBox="-4 -6 66 64"
      accessibilityLabel={accessibilityLabel}
    >
      {isKeyboardFocused && (
        <Path
          {...sharedPathProps}
          stroke={Color.Black}
          strokeWidth="22"
          transform={transform}
        />
      )}
      {withShadow && !isKeyboardFocused && (
        <Path
          {...sharedPathProps}
          stroke={Color.BlackTransparent20}
          strokeWidth="11"
          transform={shadowTransform}
        />
      )}
      <Path
        {...sharedPathProps}
        stroke={color}
        strokeWidth="11"
        transform={transform}
      />
    </Svg>
  );
}
ArrowButtonSvg.displayName = 'ArrowButtonSvg';
