import { IUseMicEnergyOptions, SreSessionType, useMicEnergy } from 'sre';
import { IVUMeterProps, VUMeter } from './VUMeter';

export interface IMicEnergyMeterProps
  extends Omit<IVUMeterProps, 'micEnergy'> {}

export function MicEnergyMeter(
  props: IMicEnergyMeterProps & IUseMicEnergyOptions
) {
  const { subscribeToSessionType = SreSessionType.None, testId } = props;
  const { micEnergy } = useMicEnergy({ subscribeToSessionType });

  return (
    <VUMeter
      {...props}
      micEnergy={micEnergy}
      testId={testId || MicEnergyMeter.displayName}
    />
  );
}
MicEnergyMeter.displayName = 'MicEnergyMeter';
