import * as React from 'react';
import { Linking } from 'common-ui';
import {
  ModalActions,
  ModalRegistry,
  ModalType
} from 'shared-components/modals';
import webglPng from './webgl.png';

export class WebGLDisabledModal extends React.PureComponent {
  public static readonly displayName = 'WebGLDisabledModal';

  public static readonly ModalId = ModalRegistry.register({
    Component: WebGLDisabledModal,
    getTeacherVO: true,
    headline: 'WebGL is required.',
    image: webglPng,
    text: 'Please enable WebGL in your browser and reload the page.',
    title: 'Oops!',
    type: ModalType.Error
  });

  public render() {
    return (
      <ModalActions
        primaryAction={{
          onPress: async () =>
            Linking.openUrl(
              'https://help.lexialearning.com/s/article/Turn-on-Web-GL-in-Your-Browser'
            ),
          text: 'Learn More'
        }}
      />
    );
  }
}
