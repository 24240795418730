import { IScreenplay } from '@lexialearning/lobo-common';
import { sample } from 'lodash';
import { StateObservable } from 'redux-observable';
import { Music } from 'audio/music';
import {
  LevelsCompletedAction,
  LevelSelector,
  PositionAction
} from 'curriculum-services';
import {
  LevelCompleteSceneAction,
  LevelCompleteSceneAnimationName
} from 'feature-areas/levels';
import { LevelReactAnimationName } from 'feature-areas/levels/level-page/level-page.model';
import { RouteBuilder, RouterService } from 'router-service';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../../builders/TransitionScreenplayBuilderBase';
import { TransitionAction } from '../../Transition.action';
import { TransitionScreenplayId } from '../../transition.model';
import { RoundToActCompleteTransitionBuilder } from '../act-complete/RoundToActCompleteTransitionBuilder';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

export class RoundToLevelCompleteTransitionFactory extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundToLevelCompleteTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): IScreenplay {
    return new RoundToLevelCompleteTransitionFactory(state$, deps).screenplay;
  }

  private constructor(
    private readonly state$: StateObservable<unknown>,
    private readonly deps: IRoundToNextTransitionDeps
  ) {
    super(TransitionScreenplayId.RoundToLevelComplete);

    this.prepareLevelCompleteScene()
      .disableUtilityBar()
      .playActivityCompletion()
      .fadeOutLevelComponents()
      .navToLevelCompletePage()
      .addCompletedLevel()
      .levelUp()
      .showLevelCompleteCertificate()
      .dispatchLevelCompleteToNext();
  }

  public prepareLevelCompleteScene(): this {
    this.builder.addReduxAction(LevelCompleteSceneAction.prepare());

    return this;
  }

  private playActivityCompletion(): this {
    this.builder.addScreenplay(
      RoundToActCompleteTransitionBuilder.createFor(this.state$, this.deps)
        .changeActivityPositionToComplete()
        .prepareLevelShowcaseScene()
        .playEncounterCompleteActions()
        .playCharacterCelebration()
        .animateInBadge()
        .navToLevelPage()
        .deselectActivity()
        .animateInTitleBar().screenplay
    );

    return this;
  }

  private fadeOutLevelComponents(): this {
    this.builder
      .addCallback(async () => {
        const levelCompleteScene =
          await this.deps.preparedScenes.levelCompleteReady;
        levelCompleteScene.show();
      })
      .addStormAnimation({
        name: LevelCompleteSceneAnimationName.Root.PreIntro,
        targetScene: SceneName.LevelComplete
      })
      .addReactAnimation(LevelReactAnimationName.FadeOut);

    return this;
  }

  private navToLevelCompletePage(): this {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.levelComplete());
      this.deps.preparedScenes.level!.hide();
      this.deps.preparedScenes.level = undefined;
    });

    return this;
  }

  private addCompletedLevel(): this {
    this.builder.addReduxAction(LevelsCompletedAction.add.request());

    return this;
  }

  private levelUp(): this {
    this.builder.addReduxAction(PositionAction.levelUp());

    return this;
  }

  private showLevelCompleteCertificate(): this {
    this.addLevelCompleteIntro();
    this.addLevelRecapVoiceovers();

    return this;
  }

  private addLevelCompleteIntro(): this {
    this.builder
      .addStormAnimation(
        {
          name: LevelCompleteSceneAnimationName.Root.Intro,
          targetScene: SceneName.LevelComplete
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.LevelComplete });

    return this;
  }

  private addLevelRecapVoiceovers(): this {
    const level = LevelSelector.getLevel(this.state$.value);
    this.builder.addScreenplay(sample(level.levelRecap)?.voiceover);
    level.acts.forEach((a, i) => {
      this.builder
        .addStormAnimation(
          {
            name: `${LevelCompleteSceneAnimationName.Root.Highlight}_${i + 1}`,
            targetScene: SceneName.LevelComplete
          },
          { concurrent: true }
        )
        .addScreenplay(sample(a.levelRecap)?.voiceover);
    });
    this.builder.addStormAnimation(
      {
        name: LevelCompleteSceneAnimationName.Root.Idle,
        targetScene: SceneName.LevelComplete
      },
      { concurrent: true }
    );

    return this;
  }

  private dispatchLevelCompleteToNext(): this {
    this.builder.addReduxAction(TransitionAction.levelCompleteToNext());

    return this;
  }
}
