import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ProgramMode } from 'curriculum-services/program-context/program-context.model';
import { ICurriculumDependencies } from '../../../CurriculumDependencies';
import {
  ProgramContextSelector,
  SavePointAction,
  SavePointActionSavePosition,
  SavePointActionSavePositionSuccess,
  SavePointActionType
} from '../../redux';
import { SendUnitLexia } from '../student-progress-api/student-progress-api-private.model';
import { ProgressPayloadFactory } from './ProgressPayloadFactory';
import { UnitAdvanceFactory } from './UnitAdvanceFactory';

/**
 * Save unit progress and latest position to student API.
 *
 * LevelUp if we have a level-up position. When leveling up, also
 * send one unit advance item for each activity, setting it to the starting
 * position of the level.
 */
export function savePositionEpic(
  action$: Observable<SavePointActionSavePosition>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<SavePointActionSavePositionSuccess> {
  return action$.pipe(
    ofType(SavePointActionType.SavePosition),
    mergeMap(async () => {
      const state = state$.value;
      const { studentProgressApi: api, taskRegistry } =
        deps.curriculumDependencies;
      const context = ProgramContextSelector.getRoundContext(state);
      const isProgramComplete =
        ProgramContextSelector.getPosition(state).levelUpPosition
          ?.isProgramComplete ?? false;
      const programMode = ProgramContextSelector.getMode(state);

      const currentLevelProgress = ProgressPayloadFactory.create(
        context,
        taskRegistry,
        programMode,
        isProgramComplete
      );
      const levelAdvance = maybeLevelAdvance(state);

      await api.send([...currentLevelProgress, ...levelAdvance]);

      return SavePointAction.savePosition.success({
        unitId: context.parentUnit.sysId
      });
    })
  );
}
savePositionEpic.displayName = 'savePositionEpic';

function maybeLevelAdvance(state: unknown): SendUnitLexia[] {
  const { levelUpPosition } = ProgramContextSelector.getPosition(state);

  return levelUpPosition
    ? levelUpPosition.activityPositions.map(a =>
        UnitAdvanceFactory.create(
          a,
          levelUpPosition.levelId,
          ProgramMode.ActiveStudent
        )
      )
    : [];
}
