import {
  AuthActionAuthenticatedBySso,
  AuthActionDisableAutoLogin,
  AuthActionLoginMdmUser,
  AuthActionLoginSsoUser,
  AuthActionLoginUser,
  AuthActionLoginUserSuccess,
  SsoActions
} from '../../redux';
import {
  ProgramContextAction,
  ProgramContextActionLoaded,
  ProgramContextActionMonitorSessionTimeout,
  ProgramMode,
  StudentProgressApi
} from 'curriculum-services';
import { AuthAction } from 'services/auth';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { IMdmConfigParameters } from '@lexialearning/mdm-config';
import { IUserInfo } from '../user-info-factory';
import { Linking } from 'common-ui/helpers';
import { PredicateUtils } from 'utils/PredicateUtils';
import { ProfileAction, ProfileActionLoadSuccess } from 'services/profile';
import { QueryStringParam, RouterService } from 'router-service';
import { SreConfigureAction } from 'sre';
import { SystemInfo } from 'utils';
import { UserRole } from 'lexia-service';
import {
  AuthenticationMethod,
  SessionAction,
  SessionActionLoggedIn,
  StudentPropertyAction,
  StudentPropertyActionLoad,
  StudentPropertyActionUpdate
} from '@lexialearning/student-api';
import {
  TransitionAction,
  TransitionActionLoginToIntroduction
} from 'feature-areas/transitions';
import { StudentApiConfigValues, StudentApiHelper } from 'student-api';
import { AppConfigAction, AppConfigActionUpdateEntries } from 'app-config';
import { CommonUiActions } from 'common-ui';
import {
  AvatarEditorAction,
  AvatarEditorActionSetEncountersUntilEditable
} from 'feature-areas/avatar-editor/redux';
import { UserRoleTransformer } from 'services/profile/UserRoleTransformer';
import {
  ILexiaServiceConfigs,
  LEXIA_SERVICE_CONFIG_KEY
} from 'lexia-service/lexia-config.model';

export type LoginOutputActions =
  | AppConfigActionUpdateEntries
  | AuthActionLoginUserSuccess
  | AvatarEditorActionSetEncountersUntilEditable
  | CommonUiActions
  | ProfileActionLoadSuccess
  | ProgramContextActionLoaded
  | ProgramContextActionMonitorSessionTimeout
  | SessionActionLoggedIn
  | SreConfigureAction
  | StudentPropertyActionLoad
  | StudentPropertyActionUpdate
  | TransitionActionLoginToIntroduction;

export type LoginReturnActions =
  | AuthActionAuthenticatedBySso
  | AuthActionDisableAutoLogin
  | AuthActionLoginMdmUser
  | AuthActionLoginSsoUser
  | AuthActionLoginUser;

export class LoginActionFactory {
  public static displayName = 'LoginActionFactory';

  public static async create(
    configProvider: IConfigProvider
  ): Promise<LoginReturnActions[]> {
    const url = await Linking.getInitialUrl();

    return this.createAction(configProvider, url);
  }

  private static createAction(
    configProvider: IConfigProvider,
    url?: string
  ): LoginReturnActions[] {
    const mdmInfo = configProvider.getConfig<IMdmConfigParameters | undefined>(
      'mdm'
    );

    if (mdmInfo?.username && mdmInfo?.password) {
      const mdmAction = AuthAction.loginMdmUser.request(mdmInfo);

      return [mdmAction, AuthAction.disableAutoLogin()];
    }

    // Deeplinking (autologin) is disabled for native
    // And
    // SSO actions for Native are created/dispatched via nativeDeepLink.epic.ts rather than here
    // So only AuthAction.disableAutoLogin need be dispatched here for native
    if (SystemInfo.isNative) {
      return [AuthAction.disableAutoLogin()];
    }

    if (RouterService.isSsoUrl(url)) {
      // Temp fix for LOBO-20384 - remove once that ticket is resolved by MyLexia
      const lexiaService = configProvider.getConfig<ILexiaServiceConfigs>(
        LEXIA_SERVICE_CONFIG_KEY
      );

      const returnActions = this.createSsoActionsFromUrl(
        url,
        lexiaService.customerUrl
      );

      return [...returnActions, AuthAction.disableAutoLogin()];
    }

    const allowDeepLinking = configProvider.getConfig<boolean>('isDebug');

    const autoLogin =
      !SystemInfo.isNative &&
      !RouterService.isOnLoginRoute() &&
      (this.hasAutoLoginParam() || allowDeepLinking);

    const nextAction = autoLogin
      ? AuthAction.loginUser.request({
          autoLogin
        })
      : AuthAction.disableAutoLogin();

    return [nextAction];
  }
  public static createSsoActionsFromUrl(
    urlWithSsoParams: string | undefined,
    authApiUrl: string
  ): SsoActions[] {
    const opts = { url: urlWithSsoParams };
    const role = RouterService.getQueryParam(QueryStringParam.Role, opts)!;
    const logoutUrl = RouterService.getQueryParam(
      QueryStringParam.LogoutUrl,
      opts
    );
    const authTokenAndPersonId = RouterService.getQueryParam(
      QueryStringParam.LexiaAuthToken,
      opts
    )?.split(',')!;
    const lexiaAuthToken = authTokenAndPersonId[0];
    const personId = authTokenAndPersonId[1];

    return this.createSsoActions({
      authApiUrl,
      lexiaAuthToken,
      logoutUrl,
      personId,
      role
    });
  }

  public static createSsoActions(args: {
    authApiUrl: string;
    lexiaAuthToken: string;
    logoutUrl?: string | undefined;
    personId: string;
    role: string;
  }): SsoActions[] {
    const { personId, role, lexiaAuthToken, logoutUrl, authApiUrl } = args;

    // Temp fix for LOBO-20384 - remove once that ticket is resolved by MyLexia
    // issue is logoutUrl should not point to customerUrl (MyLexia), so ignore if it does
    const fixedLogoutUrl = logoutUrl?.startsWith(authApiUrl)
      ? undefined
      : logoutUrl;

    return [
      AuthAction.authenticatedBySso({
        logoutUrl: fixedLogoutUrl,
        personId: parseInt(personId, 10),
        role: parseInt(role!, 10),
        token: lexiaAuthToken
      }),
      AuthAction.loginSsoUser.request({
        lexiaAuthToken,
        personId: parseInt(personId, 10),
        userRole: parseInt(role, 10)
      })
    ];
  }

  private static hasAutoLoginParam(): boolean {
    if (SystemInfo.isNative) {
      return false;
    }
    const autoLogin = RouterService.getQueryParam(QueryStringParam.AutoLogin);

    return PredicateUtils.isDefined(autoLogin);
  }

  public static createLoginDispatches(
    userInfo: IUserInfo,
    authenticationMethod: AuthenticationMethod,
    customerCode: string,
    studentProgressApi: StudentProgressApi
  ): LoginOutputActions[] {
    const { auth, profile, position, programMode } = userInfo;
    const { minutesSinceLastLogin } = profile;

    const output: LoginOutputActions[] = [
      AuthAction.loginUser.success(auth),
      ProfileAction.loadSuccess(profile),
      SessionAction.loggedIn({
        authToken: auth.authToken,
        authenticationMethod,
        customerCode,
        grade: profile.grade,
        level: position?.levelId ?? '0',
        personId: auth.personId.toString(),
        sessionId: auth.loginId,
        userRole: UserRoleTransformer.toName(profile.role)
      }),
      AppConfigAction.updateEntries([
        {
          key: StudentApiConfigValues.FetchRetryThrottleSeconds,
          value: auth.apiTimeoutArray
        }
      ]),
      TransitionAction.loginToIntroduction({
        isSso: this.isSso(authenticationMethod),
        minutesSinceLastLogin
      })
    ];

    if ([UserRole.Student, UserRole.DeepLinkStudent].includes(profile.role)) {
      output.push(
        ProgramContextAction.loaded({
          mode: programMode,
          position
        })
      );

      const studentProperties = userInfo.studentProperties || [];
      output.push(StudentPropertyAction.load.success({ studentProperties }));

      const avatarStudentProperties =
        StudentApiHelper.parseAvatarStudentProperties(studentProperties);
      if (avatarStudentProperties) {
        const { encountersUntilEditable } = avatarStudentProperties;
        output.push(
          AvatarEditorAction.setEncountersUntilEditable(encountersUntilEditable)
        );
      }

      if (profile.role === UserRole.Student) {
        studentProgressApi.timestamp();
        output.push(ProgramContextAction.monitorSessionTimeout());
      }
    } else {
      output.push(
        ProgramContextAction.loaded({
          mode: ProgramMode.Educator,
          position: undefined
        })
      );
    }

    return output;
  }

  private static isSso(authenticationMethod: AuthenticationMethod): boolean {
    return [
      AuthenticationMethod.SsoClever,
      AuthenticationMethod.SsoGoogle,
      AuthenticationMethod.SsoClassLink,
      AuthenticationMethod.Sso
    ].includes(authenticationMethod);
  }
}
