import { SreSessionType } from '@lexialearning/lobo-common/main-model/sre';
import { IMicrophoneService } from '@lexialearning/sre';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';

/**
 * Service which allows for subscribing to micEnergy updates from any of the
 * SRE session types (Calibration, Mic Energy, and Language Frame sessions
 * all include micEnergy in their updates)
 */
export class MicEnergyService {
  public static readonly displayName = 'MicEnergyService';

  // TODO: get from SRE?
  private static isEnergyUpdateEnabled = false;

  private static observers = 0;

  private static currentSessionType: SreSessionType = SreSessionType.None;

  private static micService?: IMicrophoneService;

  public static init(micService: IMicrophoneService) {
    this.micService = micService;
    micService.onMicLevelUpdate(this.onEnergyUpdate);
  }

  private static readonly energyUpdateSubject = new BehaviorSubject<number>(0);

  private static get energyUpdate$(): Observable<number> {
    return this.energyUpdateSubject.asObservable();
  }

  public static setSessionType(sessionType: SreSessionType) {
    this.currentSessionType = sessionType;
  }

  private static onEnergyUpdate(micEnergy: number) {
    MicEnergyService.energyUpdateSubject.next(micEnergy);
  }

  public static subscribeTo(
    subscribeToType: SreSessionType | undefined,
    next: (update: number) => void
  ): Subscription {
    this.maybeEnableUpdates();
    this.observers = this.observers + 1;
    const subscription = MicEnergyService.energyUpdate$
      .pipe(
        filter(
          () =>
            subscribeToType === MicEnergyService.currentSessionType ||
            (subscribeToType === SreSessionType.None &&
              MicEnergyService.currentSessionType ===
                SreSessionType.PrimedLanguageFrame)
        )
      )
      .subscribe({ next });

    subscription.add(() => {
      this.observers = Math.max(0, this.observers - 1);
      this.maybeDisableUpdates();
    });

    return subscription;
  }

  private static maybeEnableUpdates() {
    if (!this.isEnergyUpdateEnabled) {
      this.micService?.enableMicLevelUpdates(true);
      this.isEnergyUpdateEnabled = true;
    }
  }

  private static maybeDisableUpdates() {
    if (this.isEnergyUpdateEnabled && !this.observers) {
      this.micService?.enableMicLevelUpdates(false);
      this.isEnergyUpdateEnabled = false;
    }
  }
}
