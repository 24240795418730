import { Column } from 'common-ui';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';
import { ColorChoice } from './color-choice/ColorChoice';
import { ColorSelectorStyles } from './ColorSelector.styles';
import { AccessibilityRole } from '@lexialearning/common-ui';
import { StringUtils } from 'utils/StringUtils';

export interface IColorSelectorWrapperProps {
  disabled?: boolean;
  selectedColor: string | undefined;
}

export interface IColorSelectorProps extends IButtonBaseProps {
  accessibilityLabelSuffix: string;
  /** value should be an enum */
  colors: { [key: string]: string };
  getHexValue: (string) => string | undefined;
  onSelect: (color: string) => void;
  selectedColor: string | undefined;
}

export function ColorSelector(props: IColorSelectorProps) {
  const {
    accessibilityLabelSuffix,
    colors,
    disabled,
    getHexValue,
    onSelect,
    selectedColor
  } = props;

  const styles = ColorSelectorStyles.get();

  return (
    <Column
      style={styles.container}
      testId={ColorSelector.displayName}
      accessibilityRole={AccessibilityRole.RadioGroup}
    >
      {Object.values(colors).map((colorName: string) => {
        const selected = colorName === selectedColor;
        const hex = getHexValue(colorName);

        if (!hex) {
          return null;
        }

        return (
          <ColorChoice
            accessibilityLabel={`${StringUtils.camelToSentenceFormat(
              colorName
            )} ${accessibilityLabelSuffix}`}
            colorName={colorName}
            colorHex={hex}
            disabled={disabled}
            selected={selected}
            key={colorName}
            onSelect={onSelect}
          />
        );
      })}
    </Column>
  );
}
ColorSelector.displayName = 'ColorSelector';
