import { last } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppConfigAction, AppConfigActionApplyOverrides } from 'app-config';
import {
  ActivityPositionBuilder,
  IActivityPosition,
  LevelSelector
} from 'curriculum-services';
import {
  ActivityPositionAction,
  ActivityPositionActionAdjusted,
  ProgramContextSelector
} from 'curriculum-services/program-context/redux';
import {
  LevelSceneAction,
  LevelSceneLayout,
  LevelScenePrepareAction
} from 'feature-areas/levels';
import {
  DevToolsActionAdjustToCompletedActivity,
  IDevToolsAdjustCompletedActivityPayload
} from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

type OutputActions =
  | AppConfigActionApplyOverrides
  | ActivityPositionActionAdjusted
  | LevelScenePrepareAction;

export function adjustToCompletedActivityEpic(
  action$: Observable<DevToolsActionAdjustToCompletedActivity>,
  state$: StateObservable<unknown>
): Observable<OutputActions> {
  return action$.pipe(
    ofType(DevToolsActionType.AdjustCompletedActivity),
    map(action => buildActivityPosition(action.payload, state$.value)),
    mergeMap(position => {
      const disableApiWrites = AppConfigAction.applyOverrides({
        overrides: [{ key: 'studentApi.disableWrites', value: true }]
      });
      const prepareLevelScene = LevelSceneAction.prepare({
        layout: LevelSceneLayout.ActSelection
      });

      return from([
        disableApiWrites,
        prepareLevelScene,
        ActivityPositionAction.adjusted([position])
      ]);
    })
  );
}

function buildActivityPosition(
  { activityId, toggleComplete }: IDevToolsAdjustCompletedActivityPayload,
  state: unknown
): IActivityPosition {
  const activityPosition =
    ProgramContextSelector.getActivityPositionMap(state).get(activityId)!;
  const isComplete = toggleComplete
    ? !activityPosition.isComplete
    : activityPosition.isComplete;
  const level = LevelSelector.getLevel(state);
  const activity = level.acts.find(a => a.sysId === activityId)!;
  const encounter = last(activity.encounters)!;
  const unit = last(encounter.units)!;

  return ActivityPositionBuilder.create({
    ...activityPosition,
    encounterId: encounter.sysId,
    isComplete
  })
    .removeSubunitPositions()
    .resetAllUnitPositions()
    .withActiveUnitPosition(unit, last(unit.rounds)).activityPosition;
}
adjustToCompletedActivityEpic.displayName = 'adjustToCompletedActivityEpic';
