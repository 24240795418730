import { ActionType, createAction } from 'typesafe-actions';
import {
  CalibrationStep,
  CalibrationUiActionType
} from './calibration-ui-redux.model';

export const CalibrationUiAction = {
  setHasPlayedFeedback: createAction(
    CalibrationUiActionType.SetHasPlayedFeedback
  )<boolean>(),
  setStep: createAction(CalibrationUiActionType.SetStep)<{
    step: CalibrationStep;
  }>()
};

export type CalibrationUiActions = ActionType<typeof CalibrationUiAction>;
export type CalibrationUiActionSetStep = ActionType<
  typeof CalibrationUiAction.setStep
>;
