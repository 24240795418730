import { Position } from '@lexialearning/common-ui';
import { SystemInfo } from 'utils';

export class AppBackgroundStyles {
  private static readonly styles = {
    image: {
      bottom: 0,
      height: undefined,
      left: 0,
      position: Position.Absolute,
      right: 0,
      top: 0,
      width: undefined,
      ...(!SystemInfo.isNative && {
        backgroundPosition: 'bottom',
        backgroundSize: 'auto 100%'
      })
    }
  };

  public static get() {
    return this.styles;
  }
}
