import { AppState } from 'services';
import { CalibrationResult } from '@lexialearning/sre';
import {
  FeedbackStrategy,
  ITaskEvaluation,
  ITextPrompt,
  SeeSpeakMode,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { ISreSessionAnswer } from './see-speak.model';
import { InteractionState } from 'common-styles';
import { SeeSpeakSelector } from './redux/SeeSpeak.selector';
import { SreSelector } from 'sre';
import { SreSessionType } from '@lexialearning/lobo-common/main-model/sre';
import { TaskSelector } from 'task-components/core';
import {
  ProgramContextSelector,
  RoundContext,
  UnitSelector
} from 'curriculum-services';
import { SeeSpeakType } from './speech-bubbles/speech-bubbles.model';

export interface IGetInteractionStateArgs {
  feedbackInteractionState: InteractionState;
  isFeedbackPhase: boolean;
  isNeutralFeedback: boolean;
  /** Is listening to a LanguageFrame session */
  isListeningToLf: boolean;
}

export class SeeSpeakHelper {
  public static readonly displayName = 'SeeSpeakHelper';

  public static getInteractionState(
    state: AppState,
    callback: (args: IGetInteractionStateArgs) => InteractionState
  ): InteractionState {
    const isListeningToLf = SreSelector.getIsListeningTo(
      SreSessionType.LanguageFrame,
      state
    );
    const phase = TaskSelector.getPhase(state);
    const isFeedbackPhase = phase === TaskPhase.Feedback;
    const feedbackInteractionState = TaskSelector.getFeedbackInteractionState(
      ProgramContextSelector.getLastEvaluationResultMaybe(state)
    );
    const feedbackStrategy = UnitSelector.getFeedbackStrategy(state);
    const isChoral = SeeSpeakSelector.isChoral(state);
    const isNeutralFeedback =
      feedbackStrategy === FeedbackStrategy.Neutral || isChoral;

    return callback({
      feedbackInteractionState,
      isFeedbackPhase,
      isListeningToLf,
      isNeutralFeedback
    });
  }

  public static getSeeSpeakType(
    mode: SeeSpeakMode,
    textPromptArray: ITextPrompt[],
    hasIntroduction: boolean
  ): SeeSpeakType {
    if (mode === SeeSpeakMode.Choral) {
      return SeeSpeakType.Choral;
    }
    if (textPromptArray.length) {
      return SeeSpeakType.QuestionAnswer;
    }
    if (hasIntroduction) {
      return SeeSpeakType.SayItAfter;
    }

    return SeeSpeakType.SayItAgain;
  }

  public static needsRecalibration(context: RoundContext): boolean {
    const reversedAttempts = [
      ...context.attempts
    ].reverse() as ITaskEvaluation<ISreSessionAnswer>[];
    const lastAttempt =
      context.lastAttemptMaybe as ITaskEvaluation<ISreSessionAnswer>;

    if (lastAttempt.result !== TaskEvaluationResult.Inconclusive) {
      return false;
    }

    if (lastAttempt.answer.calibrationResult === CalibrationResult.NoSignal) {
      return true;
    }

    // find out what was the last time user re-calibrated or had a successful SRE result
    const firstNonErrorIndex = reversedAttempts.findIndex(a =>
      [CalibrationResult.NoSignal, CalibrationResult.Ok].includes(
        a.answer.calibrationResult
      )
    );
    const numOfSequentialMicFails =
      firstNonErrorIndex === -1 ? reversedAttempts.length : firstNonErrorIndex;

    // triggering only on even numbers because if user re-calibrates we want
    // them to fail 2 more times before having to calibrate again
    return !!numOfSequentialMicFails && numOfSequentialMicFails % 2 === 0;
  }
}
