import {
  IAct,
  IActivityPosition,
  IEncounter,
  ILevel,
  IRound,
  ISubunitPosition,
  IUnitNode,
  IUnitSnipped,
  SubunitType
} from '@lexialearning/lobo-common';
import {
  LevelSelector,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import { TaskRegistry } from 'task-components';
import { NumberUtils } from 'utils';

/** *
 * Used to build "position paths" (e.g. L01.A2.E3.U09.R03) as reported in
 * various log events
 */
export class PositionPathBuilder {
  public static readonly displayName = 'PositionPathBuilder';

  public static readonly Placeholder = '(none)';

  /**
   * Create a builder that can assemble a "position path" from redux state.
   * The path that can be built depends on the activity position and associated
   * program content available in state. For example, if there's no level
   * content, we can't build any path. If we can build a full RoundContext,
   * we can provide a path all the way down to a forked instruction round.
   * And everything in between.
   * The activityPosition can be optionally specified to provide a path to
   * that activity's round position, in which case we avoid using RoundContext
   * or EncounterContext.
   */
  public static create(
    state: unknown,
    taskRegistry: TaskRegistry,
    activityPosition?: IActivityPosition
  ): PositionPathBuilder {
    const level = LevelSelector.getLevelMaybe(state);
    const params: IPathBuilderParams = { level };

    this.addFromActivityPositionMaybe(params, activityPosition);
    this.addFromRoundContextMaybe(params, state);
    this.addFromEncounterContextMaybe(params, state);

    return new PositionPathBuilder(params, taskRegistry);
  }

  /**
   * Set activity and activityPosition from the provided activityPosition,
   * if available. This is used when constructing a path for inactive
   * activities.
   */
  private static addFromActivityPositionMaybe(
    params: IPathBuilderParams,
    activityPosition: IActivityPosition | undefined
  ): void {
    if (!params.level || !activityPosition) {
      return;
    }

    params.activity = params.level.acts.find(
      a => a.sysId === activityPosition.activityId
    );
    params.activityPosition = activityPosition;
  }

  /**
   * Set activity, activityPosition, and context from RoundContext, if
   * a) we can build it from state
   * b) the activity position hasn't already been specified, which means
   * we don't care about the active activity per se.
   */
  private static addFromRoundContextMaybe(
    params: IPathBuilderParams,
    state: unknown
  ): void {
    if (!params.level || params.activityPosition) {
      return;
    }

    const roundContext = ProgramContextSelector.getRoundContextMaybe(state);
    if (!roundContext) {
      return;
    }

    params.activity = roundContext.act;
    params.activityPosition = roundContext.activityPosition;
    params.context = roundContext;
  }

  /**
   * Set the activity and activityPosition from EncounterContext, if
   * a) we can built it from state
   * a) the activityPosition hasn't already been specified, which means
   * we don't care about the active activity per se or we have the full
   * RoundContext available.
   */
  private static addFromEncounterContextMaybe(
    params: IPathBuilderParams,
    state: unknown
  ): void {
    if (!params.level || params.activityPosition || params.context) {
      return;
    }

    const encounterContext =
      ProgramContextSelector.getEncounterContextMaybe(state);
    if (!encounterContext) {
      return;
    }

    params.activity = encounterContext.act;
    params.activityPosition = encounterContext.activityPosition;
  }

  public static createFromContext(
    context: RoundContext,
    taskRegistry: TaskRegistry
  ): PositionPathBuilder {
    const { activityPosition, act: activity, level } = context;

    return new PositionPathBuilder(
      { activity, activityPosition, context, level },
      taskRegistry
    );
  }

  public get path(): string {
    return this.segments.join('.');
  }

  private readonly activityPosition?: IActivityPosition;

  private readonly context?: RoundContext;

  private readonly info: IPositionInfo;

  private readonly segments: string[] = [];

  private constructor(
    params: IPathBuilderParams,
    private readonly taskRegistry: TaskRegistry
  ) {
    this.activityPosition = params.activityPosition;
    this.context = params.context;

    const activityIndex =
      params.activityIndex ??
      params.level?.acts.findIndex(
        a => a.sysId === params.activityPosition?.activityId
      ) ??
      -1;
    const activity =
      params.activity ?? activityIndex >= 0
        ? params.level?.acts[activityIndex]
        : undefined;

    const encounterId = params.activityPosition?.encounterId;
    const encounterIndex =
      params.activity?.encounters.findIndex(e => e.sysId === encounterId) ?? -1;
    const encounter =
      encounterIndex >= 0
        ? params.activity?.encounters[encounterIndex]
        : undefined;

    const mainUnitId = params.activityPosition?.unitPosition.unitId;
    const mainUnitIndex =
      encounter?.units.findIndex(u => u.sysId === mainUnitId) ?? -1;
    const mainUnit =
      mainUnitIndex >= 0 ? encounter?.units[mainUnitIndex] : undefined;

    this.info = {
      activity,
      activityIndex,
      encounter,
      encounterIndex,
      level: params.level,
      mainUnit,
      mainUnitIndex
    };
  }

  public setFullPath(): PositionPathBuilder {
    return this.addLevel()
      .addActivity()
      .addEncounter()
      .addMainUnit()
      .addMainRound()
      .addSubunits();
  }

  public addLevel(
    placeholder = PositionPathBuilder.Placeholder
  ): PositionPathBuilder {
    const { level } = this.info;
    if (!level) {
      this.segments.push(placeholder);

      return this;
    }

    this.segments.push(`L${level.title}`);

    return this;
  }

  public addActivity(placeholder = ''): PositionPathBuilder {
    const position = this.info.activityIndex + 1 || placeholder;
    if (position) {
      this.segments.push(`A${position}`);
    }

    return this;
  }

  public addEncounter(placeholder = ''): PositionPathBuilder {
    const position = this.info.encounterIndex + 1 || placeholder;
    if (position) {
      this.segments.push(`E${position}`);
    }

    return this;
  }

  public addMainUnit(placeholder = ''): PositionPathBuilder {
    const index = this.info.mainUnitIndex;
    const position =
      index >= 0 ? NumberUtils.toTwoDigitString(index + 1) : placeholder;
    if (position) {
      this.segments.push(`U${position}`);
    }

    return this;
  }

  public addMainRound(placeholder = ''): PositionPathBuilder {
    const roundId = this.activityPosition?.unitPosition.roundId;
    const { mainUnit } = this.info;
    const index =
      mainUnit && roundId === ''
        ? 0 // assume first round
        : mainUnit?.rounds.findIndex(r => r.sysId === roundId) ?? -1;
    const position =
      index >= 0 ? NumberUtils.toTwoDigitString(index + 1) : placeholder;
    if (position) {
      this.segments.push(`R${position}`);
    }

    return this;
  }

  public addSubunits(): PositionPathBuilder {
    if (!this.context) {
      return this;
    }

    const { mainUnitNode } = this.context;
    const subunitNodes = this.getSubunitNodes(mainUnitNode);

    this.segments.push(
      ...this.context.activityPosition.subunitPositions.map((sp, index) =>
        this.createSubunitPath(subunitNodes[index], sp)
      )
    );

    return this;
  }

  private getSubunitNodes(
    parentUnitNode: IUnitNode,
    subunits: IUnitNode[] = []
  ): IUnitNode[] {
    return parentUnitNode.childUnit
      ? this.getSubunitNodes(parentUnitNode.childUnit, [
          ...subunits,
          parentUnitNode.childUnit
        ])
      : subunits;
  }

  private createSubunitPath(
    subunitNode: IUnitNode,
    subunitPosition: ISubunitPosition
  ): string {
    const roundNumber = NumberUtils.toTwoDigitString(
      subunitNode.content.rounds.findIndex(
        r => r.sysId === subunitPosition.roundId
      ) + 1
    );
    const subunitSlug = this.createForkSubunitSlug(
      subunitNode,
      subunitPosition
    );
    const prefix =
      subunitPosition.subunitType === SubunitType.Instruction ? 'I' : 'R';

    return `${subunitSlug}${prefix}${roundNumber}`;
  }

  private createForkSubunitSlug(
    subunitNode: IUnitNode,
    subunitPosition: ISubunitPosition
  ): string {
    if (subunitPosition.subunitType === SubunitType.Instruction) {
      return '';
    }
    const parentRound = subunitNode.parent!.content as IRound;
    const task = parentRound.task!;
    const registration = this.taskRegistry!.get(task.taskType);
    const forkPaths = registration.getForkSubunits(task);
    const forkPathNumber =
      forkPaths.findIndex(p => p.sysId === subunitPosition.unitId) + 1;

    return `F${forkPathNumber}.`;
  }
}

interface IPathBuilderParams {
  level?: ILevel;
  activity?: IAct;
  activityIndex?: number;
  activityPosition?: IActivityPosition;
  context?: RoundContext;
}

interface IPositionInfo {
  level?: ILevel;
  activity?: IAct;
  activityIndex: number;
  encounter?: IEncounter;
  encounterIndex: number;
  mainUnit?: IUnitSnipped;
  mainUnitIndex: number;
}
