export enum GlossyButtonType {
  Round = 'round',
  RoundWithDropShadow = 'roundWithDropShadow',
  Square = 'square'
}

export enum GlossyButtonIconType {
  Check,
  Edit,
  LookBack,
  Mic,
  Play,
  QuestionMark,
  Rehear,
  Replay,
  ShapeCheck,
  ShapeX,
  Skip,
  Square
}

export interface IRoundGlossyButtonColorSet {
  stop1: string;
  stop2: string;
}

export interface ISquareGlossyButtonColorSet {
  stop1: string;
  stop2: string;
  stop3: string;
  stop4: string;
  stop5: string;
}

export interface IKeyNavGlossyButtonColorSet {
  fill: string;
  icon: string;
  outerStroke: string;
  stroke: string;
}

export enum GlossyButtonColorSet {
  BlackGray = 'BlackGray',
  BlackWhite = 'BlackWhite',
  Blue = 'blue',
  BlueHovered = 'blueHovered',
  BluePressed = 'bluePressed',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow'
}
