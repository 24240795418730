import { ModalActions, ModalRegistry } from 'shared-components';
import { AvatarEditorTimeoutModalStyles } from './AvatarEditorTimeoutModal.styles';
import { useDispatch } from 'react-redux';
import { AppShellAction } from 'feature-areas/shell';
import {
  CheckMarkIconSvg,
  Column,
  ITextButtonIconProps,
  Image,
  Row,
  Svg,
  Text,
  View
} from 'common-ui';
import { AvatarEditorAction } from 'feature-areas/avatar-editor/redux';
import { EditIconSvg } from 'common-ui/components/button/glossy-button/svgs/icons';
import { REPO_IMG_DIR } from 'services/app-config/appConfig';

export interface IAvatarEditorTimeoutModalProps {
  showRetryButton: boolean;
}

const TextButtonEditIcon = (props: ITextButtonIconProps) => (
  <Svg height="100%" width="100%" viewBox="29 -69 200 200">
    <EditIconSvg iconColor={props.color} />
  </Svg>
);

export function AvatarEditorTimeoutModal(
  props: IAvatarEditorTimeoutModalProps
) {
  const { showRetryButton } = props;
  const timesUpArtwork = `${REPO_IMG_DIR}/avatar/times-up/times-up.png`;

  const dispatch = useDispatch();

  const handlePressRetry = () => {
    dispatch(AppShellAction.hideModal());
    dispatch(AvatarEditorAction.timerRestart());
  };
  const handlePressYes = () => {
    dispatch(AppShellAction.hideModal());
    dispatch(AvatarEditorAction.timeoutUserConfirmation());
  };

  const styles = AvatarEditorTimeoutModalStyles.get();

  return (
    <Column
      style={styles.container}
      testId={AvatarEditorTimeoutModal.displayName}
    >
      <Text style={styles.title}>Are you done?</Text>
      <Row style={styles.buttonContainer}>
        <ModalActions
          primaryAction={{
            Icon: CheckMarkIconSvg,
            ignoreGlobalDisabled: true,
            onPress: handlePressYes,
            text: 'Yes'
          }}
          secondaryAction={
            showRetryButton
              ? {
                  Icon: TextButtonEditIcon,
                  onPress: handlePressRetry,
                  text: 'No'
                }
              : undefined
          }
        />
      </Row>
      <View style={styles.imageContainer}>
        <Image
          accessibilityLabel="Time’s up"
          source={timesUpArtwork}
          style={styles.image}
        />
      </View>
    </Column>
  );
}
AvatarEditorTimeoutModal.displayName = 'AvatarEditorTimeoutModal';

AvatarEditorTimeoutModal.ModalId = ModalRegistry.register({
  Component: AvatarEditorTimeoutModal,
  preventDismiss: true,
  // Avatar timeout has its own SFX that plays at the same time the modal is opened
  suppressOpeningSfx: true
});
