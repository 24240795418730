import { IAct } from '@lexialearning/lobo-common';
import { IActProgress } from '@lexialearning/lobo-common/main-model/student';
import * as React from 'react';
import { connect } from 'react-redux';
import { Column, Row, Text } from 'common-ui';
import {
  LevelSelector,
  ProgramContextSelector,
  ProgressSelector
} from 'curriculum-services';
import { GradeName } from 'lexia-service';
import { AppState } from 'services';
import { ProfileSelector } from 'services/profile';
import { ActOverview } from './act-overview/ActOverview';
import { StudentInfoStyles } from './StudentInfo.styles';

export interface IStudentInfoProps {
  studentName: string;
  levelNumber: number;
  acts: IAct[];
  grade: GradeName | undefined;
  progress: IActProgress[];
  isOnboardingOrPlacement: boolean;
}

export class StudentInfoComponent extends React.PureComponent<IStudentInfoProps> {
  public static readonly displayName = 'StudentInfo';

  public render() {
    const {
      studentName,
      levelNumber,
      acts,
      grade,
      progress,
      isOnboardingOrPlacement
    } = this.props;
    const styles = StudentInfoStyles.get();

    return (
      <Column>
        <Row style={styles.row}>
          <Text style={styles.label}>Name</Text>
          <Text style={styles.content}>{studentName}</Text>
        </Row>
        <Row style={styles.row}>
          <Text style={styles.label}>Grade</Text>
          <Text style={styles.content}>{grade}</Text>
        </Row>
        {!isOnboardingOrPlacement && (
          <>
            <Row style={styles.row}>
              <Text style={styles.label}>Level</Text>
              <Text style={styles.content}>{levelNumber}</Text>
            </Row>
            <Row style={styles.row}>
              <Text style={styles.label}>Encounter</Text>
              <Column style={styles.content}>
                {acts.map((a, i) => (
                  <ActOverview key={a.sysId} act={a} progress={progress[i]} />
                ))}
              </Column>
            </Row>
          </>
        )}
      </Column>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    acts: LevelSelector.getActs(state),
    grade: ProfileSelector.getGradeMaybe(state),
    isOnboardingOrPlacement:
      ProgramContextSelector.isOnboardingOrPlacement(state),
    levelNumber: LevelSelector.getLevelNumber(state),
    progress: ProgressSelector.getProgress(state),
    studentName: `${ProfileSelector.getFirstName(
      state
    )} ${ProfileSelector.getLastName(state)}`
  };
}

export const StudentInfo = connect(mapStateToProps)(StudentInfoComponent);

export const StudentInfoPrivates = { mapStateToProps };
