import { DevToolsActionTypes } from './DevTools.action';
import { DevToolsActionType } from './DevToolsActionType';
import { DevToolsPosition, IDevToolsState } from './IDevToolsState';

const devToolsInitialState: IDevToolsState = {
  isVisible: false,
  position: DevToolsPosition.Top,
  shouldShowIosShortcuts: false,
  shouldShowMicErrorShortcuts: false
};

const reducer = (
  state: IDevToolsState = devToolsInitialState,
  action: DevToolsActionTypes
): IDevToolsState => {
  switch (action.type) {
    case DevToolsActionType.Show:
      return {
        ...state,
        isVisible: true
      };

    case DevToolsActionType.Hide:
      return {
        ...state,
        isVisible: false
      };

    case DevToolsActionType.ChangePosition:
      return {
        ...state,
        position: action.payload.position
      };

    case DevToolsActionType.SetShouldShowMicErrorShortcuts:
      return {
        ...state,
        shouldShowMicErrorShortcuts: action.payload
      };

    case DevToolsActionType.SetShouldShowIosShortcuts:
      return {
        ...state,
        shouldShowIosShortcuts: action.payload
      };

    default:
      return state;
  }
};

export { reducer as devToolsReducer };
