import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { INavigateBackEventPayload, LoboLogItemCategory } from 'logging';
import { AppShellActionNavigateBack, AppShellActionType } from 'shell';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logNavigateBackEpic(
  action$: Observable<AppShellActionNavigateBack>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(AppShellActionType.NavigateBack),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.NavigateBack),
    ignoreElements()
  );
}
logNavigateBackEpic.displayName = 'logNavigateBackEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<object, LoboLogItemCategory> {
  const payload: INavigateBackEventPayload = {
    context: helper.getContext(state)
  };

  return {
    category: LoboLogItemCategory.NavigateBack,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: 'Nav back'
  };
}
