import memoizeOne from 'memoize-one';
import { Justify } from 'common-styles';
import { SpeechBubbleStyles } from './speech-bubbles/shared/speech-bubble/SpeechBubble.styles';
import { SpotlightStyles } from 'common-ui/components/spotlight/Spotlight.styles';
import { ThemeSize, ThemeType } from 'theme';

export class SeeSpeakStyles {
  private static readonly SpotlightMarginHorizontal = 7;

  private static readonly SpotlightImageSpacing =
    (SeeSpeakStyles.SpotlightMarginHorizontal +
      SpotlightStyles.styles.container.default.borderWidth) *
    2;

  private static calculateMarginTop(
    hasInstructionalImage: boolean,
    spotlightsCount: number,
    hasOnscreenCharacter: boolean,
    hasTextPrompt: boolean,
    isInstruction: boolean
  ): number {
    const hasImages = hasInstructionalImage || spotlightsCount > 0;

    if (isInstruction) {
      return hasTextPrompt ? 66 : 126;
    }

    return hasImages ? 27 : hasOnscreenCharacter ? 221 : 189;
  }

  public static readonly build = memoizeOne(
    (
      themeSize: ThemeSize,
      themeType: ThemeType,
      hasInstructionalImage: boolean,
      spotlightsCount: number,
      hasOnscreenCharacter: boolean,
      hasTextPrompt: boolean
    ) => {
      const isInstruction = themeType === ThemeType.Instruction;
      const instrSpeechBubbleWidth = SpeechBubbleStyles.getSpeechBubbleWidth(
        true,
        themeSize
      );

      return {
        seeSpeakContainer: {
          flex: 1,
          justifyContent: Justify.Start,
          marginBottom: 0,
          left: hasOnscreenCharacter ? 90 : 0,
          marginTop: SeeSpeakStyles.calculateMarginTop(
            hasInstructionalImage,
            spotlightsCount,
            hasOnscreenCharacter,
            hasTextPrompt,
            isInstruction
          )
        },
        instruction: {
          hintBoxOverride: {
            borderContainer: {
              width: instrSpeechBubbleWidth - 36
            }
          },
          languageFrame: {
            width: instrSpeechBubbleWidth - 46
          },
          textPromptOverride: {
            container: {
              width: instrSpeechBubbleWidth - 36
            }
          }
        },
        spotlightAreaStyleOverride: {
          spotlightStyleOverride: {
            container: {
              marginHorizontal: SeeSpeakStyles.SpotlightMarginHorizontal
            }
          }
        }
      };
    }
  );
}
