import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import program_completePng from './program_complete.png';

export class ProgramCompleteModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    headline: 'You have already finished!',
    id: 'ProgramCompleteModal',
    image: program_completePng,
    text: 'Great work!  Please talk to your teacher about what to do next.',
    title: 'Congratulations!',
    type: ModalType.Confirmation
  });
}
