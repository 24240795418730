import { AnimatedView, Types, View } from 'common-ui';
import { CharacterSpeechBubbleTail } from '../shared/speech-bubble/tail/CharacterSpeechBubbleTail';
import { PromptSpeechBubbleStyles } from './PromptSpeechBubble.styles';
import { STANDARD_SPEECH_BUBBLE_CONTENTS_WIDTH } from '../speech-bubbles.model';
import { SeeSpeakIntroduction } from './introduction/SeeSpeakIntroduction';
import { useTheme } from 'theme/useTheme';

export interface IPromptSpeechBubbleAnimatedStyles {
  container: Types.AnimatedViewStyle & { borderColor?: Types.AnimatedValue };
}

export interface IPromptSpeechBubbleProps {
  animatedStyles?: IPromptSpeechBubbleAnimatedStyles;
  hasTailLeft?: boolean;
}

export function PromptSpeechBubble(props: IPromptSpeechBubbleProps) {
  const { animatedStyles, hasTailLeft } = props;
  const { themeSize } = useTheme();
  const styles = PromptSpeechBubbleStyles.build(themeSize);

  return (
    <AnimatedView
      testId={PromptSpeechBubble.displayName}
      animatedStyle={animatedStyles?.container}
      style={styles.container}
    >
      {hasTailLeft && <CharacterSpeechBubbleTail />}

      <View style={styles.text}>
        <SeeSpeakIntroduction width={STANDARD_SPEECH_BUBBLE_CONTENTS_WIDTH} />
      </View>
    </AnimatedView>
  );
}
PromptSpeechBubble.displayName = 'PromptSpeechBubble';
