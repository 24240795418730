import { TextAlign } from 'common-styles';

export class CalibrationIntroStyles {
  private static readonly styles = {
    introText: {
      fontSize: 40,
      lineHeight: 48,
      textAlign: TextAlign.Center
    }
  };

  public static get() {
    return this.styles;
  }
}
