import { InstructionalStep } from '@lexialearning/lobo-common/main-model';
import { ActionType, createAction } from 'typesafe-actions';
import { ITransitionActionEducatorToEncounterPayload } from 'feature-areas/transitions';
import { DevToolsActionType } from './DevToolsActionType';
import { DevToolsPosition } from './IDevToolsState';

export const DevToolsAction = {
  adjustCompletedActivity: createAction(
    DevToolsActionType.AdjustCompletedActivity
  )<IDevToolsAdjustCompletedActivityPayload>(),
  changePosition: createAction(
    DevToolsActionType.ChangePosition
  )<IChangePositionPayload>(),
  hide: createAction(DevToolsActionType.Hide)(),
  logSreSessionInfo: createAction(DevToolsActionType.LogSreSessionInfo)(),
  openContentfulEntry: createAction(
    DevToolsActionType.OpenContentfulEntry
  )<IOpenContentfulEntryPayload>(),
  playLevelCompleteLevelIntro: createAction(
    DevToolsActionType.PlayLevelCompleteLevelIntro
  )(),
  setShouldShowIosShortcuts: createAction(
    DevToolsActionType.SetShouldShowIosShortcuts
  )<boolean>(),
  setShouldShowMicErrorShortcuts: createAction(
    DevToolsActionType.SetShouldShowMicErrorShortcuts
  )<boolean>(),
  shortcutToRound: createAction(
    DevToolsActionType.ShortcutToRound
  )<ITransitionActionEducatorToEncounterPayload>(),
  show: createAction(DevToolsActionType.Show)(),
  skipToLevelPage: createAction(DevToolsActionType.SkipToLevelPage)(),
  updateHasOnscreenCharacter: createAction(
    DevToolsActionType.UpdateHasOnscreenCharacter
  )<IUpdateHasOnscreenCharacterPayload>()
};

export interface IDevToolsAdjustCompletedActivityPayload {
  activityId: string;
  toggleComplete: boolean;
}

export interface IChangePositionPayload {
  position: DevToolsPosition;
}

export interface IOpenContentfulEntryPayload {
  entryId: string;
}

export interface IUpdateHasOnscreenCharacterPayload {
  hasOnscreenCharacter: boolean;
}

export interface IUpdateInstructionalStepPayload {
  instructionalStep: InstructionalStep;
}

export type DevToolsActionAdjustToCompletedActivity = ActionType<
  typeof DevToolsAction.adjustCompletedActivity
>;
export type DevToolsActionShow = ActionType<typeof DevToolsAction.show>;
export type DevToolsActionHide = ActionType<typeof DevToolsAction.hide>;
export type DevToolsActionChangePosition = ActionType<
  typeof DevToolsAction.changePosition
>;
export type DevToolsActionLogSreSessionInfo = ActionType<
  typeof DevToolsAction.logSreSessionInfo
>;
export type DevToolsActionOpenContentfulEntry = ActionType<
  typeof DevToolsAction.openContentfulEntry
>;
export type DevToolsActionPlayLevelCompleteLevelIntro = ActionType<
  typeof DevToolsAction.playLevelCompleteLevelIntro
>;
export type DevToolsActionShortcutToRound = ActionType<
  typeof DevToolsAction.shortcutToRound
>;
export type DevToolsActionSkipToLevelPage = ActionType<
  typeof DevToolsAction.skipToLevelPage
>;
export type DevToolsActionUpdateHasOnscreenCharacter = ActionType<
  typeof DevToolsAction.updateHasOnscreenCharacter
>;
export type DevToolsActionTypes = ActionType<typeof DevToolsAction>;
