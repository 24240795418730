import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { LoboLogItemCategory } from 'logging';
import {
  PositionActionActivitySelected,
  PositionActionType,
  ProgramContextSelector
} from 'curriculum-services';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logActivitySelectionEpic(
  action$: Observable<PositionActionActivitySelected>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(PositionActionType.ActivitySelected),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.ActivitySelected),
    ignoreElements()
  );
}
logActivitySelectionEpic.displayName = 'logActivitySelectionEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<object, LoboLogItemCategory> {
  const activityPosition = ProgramContextSelector.getActivityPosition(state);
  const context = helper.getContext(state);

  return {
    category: LoboLogItemCategory.ActivitySelected,
    loggingLevel: LoggingLevel.Verbose,
    payload: { activityPosition },
    summary: `Activity selected: ${context}`
  };
}
