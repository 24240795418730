import { Position } from '@lexialearning/common-ui';
import { SystemInfo } from '@lexialearning/utils-react';

export class AvatarAndSpeechBubbleTailStyles {
  private static styles = {
    avatarSpeechTail: {
      position: Position.Absolute,
      top: 0,
      left: 569
    },
    avatarOverride: {
      borderBottomWidth: 4
    },
    speechTailSvg: {
      position: Position.Absolute,
      top: 66,
      left: SystemInfo.isNative
        ? // istanbul ignore next - not worth covering
          -14.5
        : -14,
      transform: [{ scaleX: -0.8 }]
    }
  };

  public static get() {
    return this.styles;
  }
}
