import { AppShellAction } from 'feature-areas/shell';
import {
  DeepLinkHelperOutputAction,
  DeepLinkObservableFactoryBaseWithLevelsLoading,
  IDeepLinkDeps
} from './DeepLinkObservableHelper';
import { AvatarInstructionModal } from 'feature-areas/avatar-editor/instruction-modal/AvatarInstructionModal';
import { AvatarEditorAction } from 'feature-areas/avatar-editor/redux';

export interface IAvatarEditorDeepLinkDeps extends IDeepLinkDeps {}
export type AvatarEditorDeepLinkOutputAction = DeepLinkHelperOutputAction;

export class AvatarEditorDeepLinkObservableFactory extends DeepLinkObservableFactoryBaseWithLevelsLoading<IAvatarEditorDeepLinkDeps> {
  public static readonly displayName = 'AvatarEditorDeepLinkObservableFactory';

  public static createFor(
    deps: IAvatarEditorDeepLinkDeps
  ): AvatarEditorDeepLinkObservableFactory {
    return new AvatarEditorDeepLinkObservableFactory(deps)
      .awaitLevelsThenLoadLevel()
      .showEditor()
      .showInstructionalModal();
  }

  constructor(public readonly deps: IAvatarEditorDeepLinkDeps) {
    super(deps);
  }

  private showEditor(): AvatarEditorDeepLinkObservableFactory {
    this.dispatches.push(AvatarEditorAction.showEditor());

    return this;
  }

  public showInstructionalModal(): AvatarEditorDeepLinkObservableFactory {
    this.dispatches.push(
      AppShellAction.showModal({ id: AvatarInstructionModal.ModalId })
    );

    return this;
  }
}
