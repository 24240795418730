import { Cursor } from '@lexialearning/common-ui';
import { ViewStyles } from './View.styles';

export class PressableViewStyles {
  public static readonly build = (style?: any, disabled?: boolean) =>
    ViewStyles.build({
      ...style,
      cursor: disabled ? Cursor.Default : style?.cursor
    });
}
