import { SceneControllerBase } from 'services/storm-lobo';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { IApplyTextureOptions, Scene, StormService } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  LevelIntroSceneAnimationName,
  LevelIntroSceneElementName,
  LevelIntroSceneMaterialName
} from './level-intro-scene.model';

export class LevelIntroScene extends SceneControllerBase {
  public static readonly displayName = 'LevelIntroScene';

  private readonly AnimationName = LevelIntroSceneAnimationName;

  private readonly ElementName = LevelIntroSceneElementName;

  public constructor(
    scene: Scene,
    private readonly stormService: StormService,
    private readonly level: string,
    isLevelToLevel = false
  ) {
    super(scene, SceneZIndex.LevelIntro);
    this.init();
    if (isLevelToLevel) {
      this.animatePriorLevelBackground();
    }
    this.animateBackgroundOverlay();
    this.prepareTexture();
  }

  private init(): void {
    const request: ISceneAnimationRequest = {
      name: LevelIntroSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  private animatePriorLevelBackground(): void {
    const request: ISceneAnimationRequest = {
      name: this.AnimationName.Background.Idle,
      targetElement: this.ElementName.buildBackground(1)
    };
    this.scene.startAnimation(request);
  }

  private animateBackgroundOverlay(): void {
    const request: ISceneAnimationRequest = {
      loop: true,
      name: this.AnimationName.BackgroundOverlay.Idle,
      targetElement: this.ElementName.BackgroundOverlay
    };
    this.scene.startAnimation(request);
  }

  private prepareTexture(): void {
    const options: Partial<IApplyTextureOptions> = {
      materialName: LevelIntroSceneMaterialName.PaperBack
    };
    const path = `/scenes/images/level-intro/l${this.level}_intro.tx`;
    const texture = this.stormService.loadTexture(path);
    this.scene.applyTexture(texture, options);
  }
}
