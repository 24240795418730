import { IScreenplay } from '@lexialearning/lobo-common';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import {
  ILevelIntroScreenplayDeps,
  LevelIntroScreenplayBuilder
} from '../builders/levels';
import { TransitionScreenplayId } from '../transition.model';

export interface ILevelIntroToLevelTransitionScreenplayDeps
  extends ILevelIntroScreenplayDeps {
  levelId: string;
}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Home+to+Level+Transition
 */
export class LevelIntroToLevelTransitionScreenplayFactory {
  public static readonly displayName = 'LevelIntroToLevelScreenplayBuilder';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: ILevelIntroToLevelTransitionScreenplayDeps
  ): LevelIntroToLevelTransitionScreenplayFactory {
    return new LevelIntroToLevelTransitionScreenplayFactory(deps);
  }

  private constructor(deps: ILevelIntroToLevelTransitionScreenplayDeps) {
    const { entryPromptVo, levelId, preparedScenes } = deps;

    this.screenplay = ScreenplayBuilder.create(
      TransitionScreenplayId.LevelIntroToLevel
    )
      .addCallback(async () => {
        RouterService.history.replace(RouteBuilder.levels(levelId));
      })
      .addScreenplay(
        LevelIntroScreenplayBuilder.createFor({
          entryPromptVo,
          preparedScenes
        }).screenplay
      ).screenplay;
  }
}
