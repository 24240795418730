import {
  ILanguageFrameToken,
  ISeeSpeak,
  SeeSpeakMode
} from '@lexialearning/lobo-common/tasks/see-speak';
import { createSelector } from '@reduxjs/toolkit';
import { TaskSelector } from 'task-components/core';
import { ISeeSpeakState } from './see-speak-redux.model';
import { LanguageFrameSession as LF } from '@lexialearning/lobo-common/main-model/sre';
import { LexiaError } from '@lexialearning/utils';

export class SeeSpeakSelector {
  public static readonly displayName = 'SeeSpeakSelector';

  public static getState: (state: unknown) => ISeeSpeakState;

  public static getLanguageFrameTokens: (
    state: unknown
  ) => ILanguageFrameToken[];

  public static getMode: (state: unknown) => SeeSpeakMode | undefined;

  public static getSreSessionOptions: (state: unknown) => LF.IConfig;

  public static getSreSessionOptionsMaybe: (
    state: unknown
  ) => LF.IConfig | undefined;

  public static isChoral: (state: unknown) => boolean;

  public static createSelectors(selector: (state: any) => ISeeSpeakState) {
    SeeSpeakSelector.getState = createSelector(
      selector,
      (state: ISeeSpeakState) => state
    );
    SeeSpeakSelector.getLanguageFrameTokens = createSelector(
      SeeSpeakSelector.getState,
      (state: ISeeSpeakState) => state.languageFrameTokens
    );
    SeeSpeakSelector.getMode = createSelector(
      TaskSelector.getTaskContentMaybe,
      (taskContent: ISeeSpeak | undefined) => taskContent?.mode
    );
    SeeSpeakSelector.isChoral = createSelector(
      SeeSpeakSelector.getMode,
      (mode: SeeSpeakMode | undefined) => mode === SeeSpeakMode.Choral
    );
    SeeSpeakSelector.getSreSessionOptionsMaybe = createSelector(
      SeeSpeakSelector.getState,
      (state: ISeeSpeakState) => state.sreOptions
    );
    SeeSpeakSelector.getSreSessionOptions = createSelector(
      SeeSpeakSelector.getSreSessionOptionsMaybe,
      (sreOptions: LF.IConfig | undefined) => {
        if (!sreOptions) {
          throw new LexiaError(
            'Sre options undefined',
            SeeSpeakSelector.displayName,
            SeeSpeakError.OptionsUndefined
          );
        }

        return sreOptions;
      }
    );
  }
}

export enum SeeSpeakError {
  OptionsUndefined = 'OptionsUndefined'
}
