import { IntroductionToCalibrationTransitionScreenplayFactory } from '../introduction-to-next/IntroductionToCalibrationTransitionScreenplayFactory';
import { Observable } from 'rxjs';
import { PreparedScenes } from 'services/storm-lobo';
import { ScreenplayAction, ScreenplayActions } from 'screenplay';
import { StormService } from 'storm';
import { TransitionActionGettingStartedToCalibration } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { map } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import { AppState } from 'services';

export interface IGettingStartedToCalibrationNextTransitionEpicDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function gettingStartedToCalibrationTransitionEpic(
  action$: Observable<TransitionActionGettingStartedToCalibration>,
  state$: StateObservable<AppState>,
  deps: IGettingStartedToCalibrationNextTransitionEpicDeps
): Observable<ScreenplayActions> {
  return action$.pipe(
    ofType(TransitionActionType.GettingStartedToCalibration),
    map(() => {
      const { preparedScenes, stormService } = deps;

      return IntroductionToCalibrationTransitionScreenplayFactory.createFor({
        preparedScenes,
        state$,
        stormService
      }).screenplay;
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
gettingStartedToCalibrationTransitionEpic.displayName =
  'gettingStartedToCalibrationTransitionEpic';
