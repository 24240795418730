import { IAct } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { StateObservable } from 'redux-observable';
import { ProgressSelector } from 'curriculum-services';
import { SceneControllerBase } from 'services/storm-lobo';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { IApplyTextureOptions, Scene, SceneElement, StormService } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import { ArrayUtils } from 'utils';
import {
  LevelSceneAnimationName,
  LevelSceneElementName,
  LevelSceneLayout,
  LevelSceneMaterialName,
  swatchTopicColorMap
} from './level-scene.model';

export const LevelIdleVariantsCount = 8;

export class LevelScene extends SceneControllerBase {
  public static readonly displayName = 'LevelScene';

  private readonly AnimationName = LevelSceneAnimationName;

  private readonly ElementName = LevelSceneElementName;

  public static buildTexturePath(path: string): string {
    return `/scenes/images/level/${path}`;
  }

  public static buildShowcaseTexturePath(path: string): string {
    return this.buildTexturePath(`showcase/${path}`);
  }

  public get showcase(): SceneElement {
    return this.scene.elementMap.get(this.ElementName.Showcase)!;
  }

  /**
   * The currently selected Act when in showcase mode
   */
  public showcasedAct?: IAct;

  public constructor(
    private readonly state$: StateObservable<unknown>,
    private readonly stormService: StormService,
    scene: Scene,
    public readonly actsIncomplete: IAct[],
    private readonly actComplete: IAct | undefined,
    private readonly levelSceneLayout: LevelSceneLayout
  ) {
    super(scene, SceneZIndex.Level);
    this.init();
    this.animateBackground();
    this.animateBackgroundOverlay();
    this.animateCharacterIdles();
    this.initializeCharacterFunFacts();
    this.prepareTopicSwatches();
    this.setActProgressMeters();
  }

  private init(): void {
    const animationName =
      this.levelSceneLayout === LevelSceneLayout.ActComplete
        ? LevelSceneAnimationName.Root.CharacterCompleteIdle
        : LevelSceneAnimationName.Root.Intro;
    const request: ISceneAnimationRequest = {
      name: animationName,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  /**
   * Fill the meter for each Act based on the student's progress
   */
  public setActProgressMeters() {
    ProgressSelector.getProgress(this.state$.value)
      .filter(p => !p.isComplete)
      .map(
        (progress, index): ISceneAnimationRequest => ({
          name: LevelSceneAnimationName.ActMeter.Fill,
          speed: 0,
          targetElement: LevelSceneElementName.buildActMeter(index + 1),
          timeAsPercent: progress.percentComplete
        })
      )
      .forEach(request => {
        this.scene.startAnimation(request);
      });
  }

  private animateBackground() {
    const request: ISceneAnimationRequest = {
      name: this.AnimationName.Background.Idle,
      targetElement: LevelSceneElementName.buildBackground(1)
    };
    this.scene.startAnimation(request);
  }

  private animateBackgroundOverlay() {
    const request: ISceneAnimationRequest = {
      loop: true,
      name: this.AnimationName.BackgroundOverlay.Idle,
      targetElement: this.ElementName.BackgroundOverlay
    };
    this.scene.startAnimation(request);
  }

  private animateCharacterIdles() {
    const idleVariants = Array(LevelIdleVariantsCount)
      .fill(0)
      .map((_, i) => i + 1);
    const shuffledIdleVariants = ArrayUtils.shuffleArray(idleVariants);

    this.actsIncomplete.forEach((_, i) => {
      const request: ISceneAnimationRequest = {
        loop: true,
        name: LevelSceneAnimationName.Character.buildIdle(
          shuffledIdleVariants[i]
        ),
        targetElement: LevelSceneElementName.buildCharacter(i + 1)
      };
      this.scene.startAnimation(request);
    });
  }

  private initializeCharacterFunFacts() {
    const request: ISceneAnimationRequest = {
      name: LevelSceneAnimationName.FunFactsEffects.Init,
      targetElement: LevelSceneElementName.Effects
    };
    this.scene.startAnimation(request);
  }

  private prepareTopicSwatches() {
    [...this.actsIncomplete, this.actComplete]
      .filter(a => !!a)
      .forEach((a, i) => {
        const options: Partial<IApplyTextureOptions> = {
          materialName: LevelSceneMaterialName.buildSwatchMat(i + 1)
        };
        const topic = a!.topic.code;
        const color = swatchTopicColorMap.get(topic);
        const texturePath = LevelScene.buildTexturePath(`swatch_${color}.tx`);
        const texture = this.stormService.loadTexture(texturePath);
        this.scene.applyTexture(texture, options);
      });
  }

  public getSelectedActNumber(): number {
    if (!this.showcasedAct) {
      throw new LexiaError(
        'Cannot create act selection screenplay since there is no showcased act',
        LevelScene.displayName,
        LevelSceneError.NoSelectedAct
      );
    }

    const selectedActNumber =
      this.actsIncomplete.findIndex(a => a.sysId === this.showcasedAct!.sysId) +
      1;
    if (!selectedActNumber) {
      throw new LexiaError(
        'Selected act is not incomplete',
        LevelScene.displayName,
        LevelSceneError.SelectedActComplete
      ).withContext({ actsIncomplete: this.actsIncomplete });
    }

    return selectedActNumber;
  }

  public prepareShowcase(act: IAct): void {
    act.encounters.forEach((encounter, i) => {
      const options: Partial<IApplyTextureOptions> = {
        materialName: LevelSceneMaterialName.buildEncounterMat(i + 1)
      };
      const texturePath = LevelScene.buildShowcaseTexturePath(
        encounter.showcaseTexturePath
      );
      const texture = this.stormService.loadTexture(texturePath);
      this.showcase.applyTexture(texture, options);
    });

    this.showcasedAct = act;
  }
}

export enum LevelSceneError {
  NoSelectedAct = 'NoSelectedAct',
  SelectedActComplete = 'SelectedActComplete'
}
