import { connect } from 'react-redux';
import { SfxAction } from './Sfx.action';
import { Sfx } from './sfx.model';

/**
 * @deprecated use useSfx where possible, this should be removed once all uses of it are migrated to hooks
 */
export interface ISfxProps {
  playSfx(path: Sfx): void;
}

const mapDispatchToProps = {
  playSfx: (path: Sfx) => SfxAction.play({ path })
};

export function withSfx(component: any) {
  return connect(undefined, mapDispatchToProps)(component) as typeof component;
}

export const withSfxPrivates = { mapDispatchToProps };
