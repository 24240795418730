import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileSelector } from 'services/profile';
import {
  ActivityPositionActionPrepared,
  ActivityPositionActionType,
  ProgramContextSelector,
  SavePointAction,
  SavePointActionRoundAdded
} from '../../redux';
import { SavePointBuilder } from './SavePointBuilder';

/**
 * Add information about the round we are about to exit to the active unit
 * save point.
 */
export function addSavePointRoundEpic(
  action$: Observable<ActivityPositionActionPrepared>,
  state$: StateObservable<unknown>
): Observable<SavePointActionRoundAdded> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Prepared),
    filter(() => ProfileSelector.isStudentLoggedIn(state$.value)),
    map(() => {
      const state = state$.value;
      const { parentUnitPosition, round, savePoint } =
        ProgramContextSelector.getRoundContext(state);

      const savePointRevised = SavePointBuilder.from(savePoint).addRound(
        parentUnitPosition,
        round.task!
      ).savePoint;

      return SavePointAction.roundAdded(savePointRevised);
    })
  );
}
addSavePointRoundEpic.displayName = 'addSavePointRoundEpic';
