import {
  IActivityPosition,
  IEncounter,
  ILevel,
  IUnitSnipped
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';

// TODO: Rework this and LevelSetupBuilder with better eye to responsibilities.
// See comment: https://stash.lexialearning.com/projects/LOBO/repos/lobo/pull-requests/3466/diff#src/services/curriculum-services/program-context/epics/level-management/LevelSetup.helper.ts
// Probably factor out program position logic - investigate:
// - should that just be reactive - existing elsewhere and just responding to events dispatched here?
// - or should it stay tightly coupled and be factored out, instead of this helper, into a service class?

export class LevelSetupHelper {
  public static readonly displayName = 'LevelSetupHelper';

  public static getFirstRoundIdForActivityPositionUnit(
    activityPosition: IActivityPosition,
    level: ILevel
  ): string {
    const unit = this.getUnitForActivityPosition(activityPosition, level);
    const roundId = unit!.rounds[0].sysId;

    return roundId!;
  }

  public static getUnitForActivityPosition(
    activityPosition: IActivityPosition,
    level: ILevel
  ): IUnitSnipped | undefined {
    const { unitId } = activityPosition.unitPosition;
    const encounter = this.getEncounterForActivityPosition(
      activityPosition,
      level
    );
    const unit = encounter!.units.find(u => u.sysId === unitId);

    if (!unit) {
      this.throwImproperPositionError(activityPosition, level);
    }

    return unit;
  }

  public static getEncounterForActivityPosition(
    activityPosition: IActivityPosition,
    level: ILevel
  ): IEncounter | undefined {
    const { activityId, encounterId } = activityPosition;
    const act = level.acts.find(a => a.sysId === activityId);
    const encounter = act?.encounters.find(e => e.sysId === encounterId);

    if (!encounter) {
      this.throwImproperPositionError(activityPosition, level);
    }

    return encounter;
  }

  private static throwImproperPositionError(
    activityPosition: IActivityPosition,
    level: ILevel
  ): void {
    throw new LexiaError(
      'Activity position does not match level',
      LevelSetupHelper.displayName,
      LevelSetupHelperError.PositionInvalid
    ).withContext({ activityPosition, level });
  }
}

export enum LevelSetupHelperError {
  PositionInvalid = 'PositionInvalid'
}
