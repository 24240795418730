import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenplayActionPlay } from 'screenplay';
import { TaskActionExit, TaskActionType } from 'task-components';
import { IRoundToNextTransitionDeps } from './round-transition.model';
import { RoundToNextTransitionFactory } from './RoundToNextTransitionFactory';

export function roundToNextTransitionEpic(
  action$: Observable<TaskActionExit>,
  state$: StateObservable<unknown>,
  deps: IRoundToNextTransitionDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TaskActionType.Exit),
    map(() => RoundToNextTransitionFactory.create(state$, deps))
  );
}
roundToNextTransitionEpic.displayName = 'roundToNextTransitionEpic';
