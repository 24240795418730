import {
  FadeAnimation,
  GlossyButton,
  GlossyButtonIconType,
  View
} from 'common-ui';
import { ScreenplayAction } from 'screenplay';
import { SkipButtonStyles } from './SkipButton.styles';
import { useDispatch } from 'react-redux';
import { AppShellAction } from '../redux';

export function SkipButton() {
  const styles = SkipButtonStyles.get();
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(ScreenplayAction.skip());
    dispatch(AppShellAction.skipButtonPressed());
  };

  const buttonProps = {
    icon: GlossyButtonIconType.Skip,
    onPress,
    testId: SkipButton.displayName
  };

  return (
    <View style={styles.bottomRight}>
      <FadeAnimation shouldShow={true}>
        <GlossyButton {...buttonProps} />
      </FadeAnimation>
    </View>
  );
}
SkipButton.displayName = 'SkipButton';
