import * as React from 'react';
import { Row } from 'common-ui';
import { HeadphoneSize, HeadphoneSvg } from '../svg/Headphone.lx-svg';
import { AmbienceAnimation } from './AmbienceAnimation';
import { AmbienceBarStyles } from './AmbienceBar.styles';

export interface IAmbienceBarProps {
  hasImage: boolean;
  playAnimation: boolean;
}

export class AmbienceBar extends React.PureComponent<IAmbienceBarProps> {
  public static readonly displayName = 'AmbienceBar';

  private renderAnimations(set: number, count: number) {
    return Array.from({ length: count }).map((_r, i) => (
      <AmbienceAnimation
        key={`Ambience${set * i}`}
        index={i}
        playAnimation={this.props.playAnimation}
      />
    ));
  }

  public render() {
    const { hasImage } = this.props;
    const styles = AmbienceBarStyles.get();

    return (
      <Row style={styles.container}>
        {this.renderAnimations(1, 4)}
        {hasImage ? (
          <HeadphoneSvg size={HeadphoneSize.Small} style={styles.headphones} />
        ) : (
          this.renderAnimations(2, 2)
        )}
        {this.renderAnimations(3, 4)}
      </Row>
    );
  }
}
