import memoizeOne from 'memoize-one';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { Position } from '@lexialearning/common-ui';

const ANIMATION_DURATION = 750;

export class LineBackgroundAnimatedStyles {
  private readonly animatedValues: {
    textPillOpacity: Types.AnimatedValue;
  };

  private readonly animations: {
    showTextPill: Types.Animated.CompositeAnimation;
  };

  constructor() {
    const textPillOpacity = loboAnimated.createValue(0);

    this.animatedValues = {
      textPillOpacity
    };

    const showTextPill = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      textPillOpacity,
      {
        delay: ANIMATION_DURATION * 0.5,
        duration: ANIMATION_DURATION * 0.5,
        toValue: 1
      }
    );

    this.animations = {
      showTextPill
    };
  }

  public readonly build = memoizeOne((lineLayout: Types.LayoutInfo) => ({
    dashedLineContainer: {
      height: lineLayout.height,
      left: 0,
      position: Position.Absolute,
      top: lineLayout.y
    },
    textPillContainer: {
      left: lineLayout.x,
      position: Position.Absolute,
      top: lineLayout.y
    },
    textPillContainerAnimated: {
      opacity: this.animatedValues.textPillOpacity
    }
  }));

  public getAnimations() {
    return this.animations;
  }
}
