import { ActiveComponentState, LinearGradient } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { Circle, Defs, Ellipse, G, KeyNavHelper, Stop, Svg } from 'common-ui';

export interface IColorChoiceIndicatorSvgStyle {
  backgroundColor: string | undefined;
  borderColor: string;
}

export interface IColorChoiceIndicatorSvgProps {
  activeComponentState: ActiveComponentState;
  style: IColorChoiceIndicatorSvgStyle;
}

export function ColorChoiceIndicatorSvg(props: IColorChoiceIndicatorSvgProps) {
  const { activeComponentState, style } = props;
  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);

  return (
    <Svg
      data-test-id={ColorChoiceIndicatorSvg.displayName}
      height="29"
      viewBox="0 0 29 29"
      width="29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Defs>
        <LinearGradient
          id="color-choice-btn-gradient"
          x1="0.226"
          y1="0.573"
          x2="0.962"
          y2="0.464"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor={Color.NearWhite} stopOpacity="0.722" />
          <Stop offset="1" stopColor={Color.NearWhite} stopOpacity="0" />
        </LinearGradient>
      </Defs>
      <G id="Button_full" transform="translate(-6.027 -15.684)">
        <G
          id="Button"
          transform="translate(9.027 18.684)"
          fill={style.backgroundColor}
          stroke={style.borderColor}
          strokeWidth="3"
        >
          <Circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
          <Circle cx="11.5" cy="11.5" r="13" fill="none" />
        </G>
        {!isKeyNav && (
          <Ellipse
            id="highlight"
            cx="4.595"
            cy="1.606"
            rx="4.595"
            ry="1.606"
            transform="translate(11.315 24.035) rotate(-32.09)"
            fill="url(#color-choice-btn-gradient)"
          />
        )}
      </G>
    </Svg>
  );
}
ColorChoiceIndicatorSvg.displayName = 'ColorChoiceIndicatorSvg';
