import memoizeOne from 'memoize-one';
import * as React from 'react';
import { Row, Text } from 'common-ui/components';
import { CenterLeftAlignWrap } from 'common-ui/components/center-left-align-wrap/CenterLeftAlignWrap';
import { SeeSpeakTextPromptStyles } from './SeeSpeakTextPrompt.styles';
import { ITextPrompt } from '@lexialearning/lobo-common/main-model/tasks/see-speak.model';
import { ITextToken } from '@lexialearning/lobo-common';

export interface ISeeSpeakTextPromptProps {
  isInstruction?: boolean;
  textPromptArray: ITextPrompt[];
  width: number;
}

export class SeeSpeakTextPrompt extends React.PureComponent<ISeeSpeakTextPromptProps> {
  public static readonly displayName = 'SeeSpeakTextPrompt';

  constructor(props: ISeeSpeakTextPromptProps) {
    super(props);

    this.renderHighlightedLanguageFrameText =
      this.renderHighlightedLanguageFrameText.bind(this);
    this.renderPlainLanguageFrameText =
      this.renderPlainLanguageFrameText.bind(this);
    this.renderToken = this.renderToken.bind(this);
    this.renderPlainToken = this.renderPlainToken.bind(this);
    this.renderText = this.renderText.bind(this);
  }

  private readonly getHighlightingValuesMemoized = memoizeOne(
    (textPromptArray: ITextPrompt[]) => {
      // split array up on spaces for wrapping
      const array1 = textPromptArray[0].textTokens.length
        ? textPromptArray[0].textTokens
        : [];
      const focusStartIdx = array1.length - 1;
      const array2 = array1.concat(textPromptArray[1].textTokens);
      const focusEndIdx = array2.length;
      const tokenArray = array2.concat(textPromptArray[2].textTokens);

      return { focusEndIdx, focusStartIdx, tokenArray };
    }
  );

  private get highlightingValues() {
    return this.getHighlightingValuesMemoized(this.props.textPromptArray);
  }

  private renderText(text: string, language?: string, isFocused = false) {
    const { isInstruction, width } = this.props;
    const styles = SeeSpeakTextPromptStyles.build(
      !!isInstruction,
      width,
      isFocused
    );

    return (
      <Text style={styles.text} accessibilityLanguage={language}>
        {text}
      </Text>
    );
  }

  private renderToken(token: ITextToken, index: number) {
    const { tokenArray, focusStartIdx, focusEndIdx } = this.highlightingValues;
    const textString =
      index === tokenArray.length - 1 ? token.text : `${token.text} `;
    const isFocused = focusStartIdx < index && index < focusEndIdx;

    return this.renderText(textString, token.language, isFocused);
  }

  private renderPlainToken(token: ITextToken) {
    return this.renderText(`${token.text} `, token.language);
  }

  private renderHighlightedLanguageFrameText() {
    const { width } = this.props;
    const { tokenArray } = this.highlightingValues;

    return (
      <CenterLeftAlignWrap
        width={width}
        tokenArray={tokenArray}
        renderToken={this.renderToken}
      />
    );
  }

  private renderPlainLanguageFrameText() {
    const { width, textPromptArray } = this.props;
    const tokenArray = textPromptArray.flatMap(prompt =>
      prompt.textTokens.map(token => token)
    );

    return (
      <CenterLeftAlignWrap
        width={width}
        tokenArray={tokenArray}
        renderToken={this.renderPlainToken}
      />
    );
  }

  public render() {
    const { isInstruction, textPromptArray, width } = this.props;
    const styles = SeeSpeakTextPromptStyles.build(
      !!isInstruction,
      width,
      false
    );

    if (textPromptArray.length === 0) {
      return null;
    }

    const textPromptText =
      isInstruction && textPromptArray.length === 3
        ? this.renderHighlightedLanguageFrameText()
        : this.renderPlainLanguageFrameText();

    return (
      <Row testId={SeeSpeakTextPrompt.displayName} style={styles.container}>
        {textPromptText}
      </Row>
    );
  }
}
