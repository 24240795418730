import {
  AccessibilityRole,
  useActiveComponentState
} from '@lexialearning/common-ui';
import * as React from 'react';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { BackgroundImage } from '../../image';
import { View } from '../../view';
import { ButtonBase, IButtonBaseProps } from '../ButtonBase';
import tab_shadowPng from './images/tab_shadow.png';
import { ITabButtonStyleOverride, TabButtonStyles } from './TabButton.styles';
import { useCallback } from 'react';

export interface ITabButtonProps extends ISfxProps, IButtonBaseProps {
  selected: boolean;
  onTabPress(tabId: string): void;
  styleOverride?: ITabButtonStyleOverride;
  tabId: string;
  tabPanelId?: string;
}

export function TabButtonComponent(
  props: React.PropsWithChildren<ITabButtonProps>
) {
  const {
    children,
    disabled,
    autoFocus,
    onTabPress,
    playSfx,
    selected,
    styleOverride,
    tabId,
    tabPanelId,
    ...restProps
  } = props;
  const handlePress = useCallback(() => {
    playSfx(Sfx.TabSelect);
    onTabPress(tabId);
  }, [onTabPress, playSfx, tabId]);

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = TabButtonStyles.build(
    selected,
    activeComponentState,
    styleOverride
  );

  return (
    <View style={styles.tabContainer}>
      <BackgroundImage style={styles.shadow} source={tab_shadowPng} />
      <ButtonBase
        {...restProps}
        {...interactionHandlers}
        accessibilityRole={AccessibilityRole.Tab}
        accessibilityState={{ selected }}
        ariaControls={tabPanelId}
        autoFocus={autoFocus}
        id={tabId}
        style={styles.tab}
        disabled={disabled || selected}
        onPress={handlePress}
        testId={`${TabButtonComponent.displayName}_Button`}
      >
        {children}
      </ButtonBase>
    </View>
  );
}
TabButtonComponent.displayName = 'TabButton';

export const TabButton = withSfx(TabButtonComponent);
