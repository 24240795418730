import { InteractionState } from 'common-styles';
import {
  GlossyButton,
  GlossyButtonIconType,
  GlossyButtonType
} from 'common-ui';
import { BackgroundImage } from 'common-ui/components/image';
import RedPng from './images/Red.png';
import { YellowStrokeSvg } from './YellowStroke.lx-svg';
import { IYesNoChoiceProps } from './YesNoChoice.model';
import { YesNoChoicesContainerStyles } from './YesNoChoicesContainer.styles';
import { useChoiceState } from 'task-components/shared/useChoiceState';

export function YesChoice(props: IYesNoChoiceProps) {
  const { disabled, onSelectionChange, showSolution, sysId } = props;
  const styles = YesNoChoicesContainerStyles.get();

  const { interactionState } = useChoiceState(props);

  function handlePress() {
    onSelectionChange!(sysId);
  }

  const modelHighlight =
    interactionState === InteractionState.Highlighted && !showSolution;
  const buttonInteractionState = modelHighlight
    ? InteractionState.Default
    : interactionState;

  return (
    <BackgroundImage
      source={RedPng}
      style={styles.yesGabber}
      testId={YesChoice.displayName}
    >
      <GlossyButton
        disabled={disabled}
        icon={GlossyButtonIconType.ShapeCheck}
        buttonType={GlossyButtonType.Square}
        interactionState={buttonInteractionState}
        style={styles.yesButton}
        onPress={handlePress}
      />
      {modelHighlight && <YellowStrokeSvg style={styles.yesHighlight} />}
    </BackgroundImage>
  );
}
YesChoice.displayName = 'YesChoice';
