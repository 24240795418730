import { IBootstrapContent } from '@lexialearning/lobo-common';
import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  ContentProviderFactory,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IBootstrapping } from 'services/app-config';
import {
  BootstrapContentAction,
  BootstrapContentActionLoad,
  BootstrapContentActionLoadSuccess,
  BootstrapContentActionType
} from '../bootstrap-content';

export interface ILoadBootstrapContentDeps {
  configProvider: IConfigProvider;
  contentProviderFactory: ContentProviderFactory;
}

export function loadBootstrapContentEpic(
  action$: Observable<BootstrapContentActionLoad>,
  _state$: StateObservable<unknown>,
  deps: ILoadBootstrapContentDeps
): Observable<BootstrapContentActionLoadSuccess> {
  return action$.pipe(
    ofType(BootstrapContentActionType.Load),
    switchMap(async () =>
      getBootstrapContent(deps.configProvider, deps.contentProviderFactory)
    ),
    map((bootstrapContent: IBootstrapContent) =>
      BootstrapContentAction.load.success({ content: bootstrapContent })
    )
  );
}

loadBootstrapContentEpic.displayName = 'loadBootstrapContentEpic';

async function getBootstrapContent(
  configProvider: IConfigProvider,
  contentProviderFactory: ContentProviderFactory
): Promise<IBootstrapContent> {
  const contentProvider = contentProviderFactory.create();
  const bootstrappingConfig =
    configProvider.getConfig<IBootstrapping>('bootstrapping');
  const bootstrapContentPromise = contentProvider.loadById<IBootstrapContent>(
    LoboContentType.BootstrapContent,
    bootstrappingConfig.bootstrapContentSysId
  );
  const bootstrapContent = await bootstrapContentPromise;

  return bootstrapContent;
}
