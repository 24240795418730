import { soundLogsEpics } from 'sre/sound-logs/soundLogs.epic';
import { availableMicsUpdateEpic } from './availableMicsUpdate.epic';
import { calibrationCalibratingEpic } from './calibrationCalibrating.epic';
import { calibrationCompletedEpic } from './calibrationCompleted.epic';
import { checkMicPermissionEpic } from './checkMicPermission.epic';
import { configureSreEpic } from './configureSre.epic';
import { configureSreValidatedEpic } from './configureSreValidated.epic';
import { finishSreSessionEpic } from './finishSreSession.epic';
import { listenEpic } from './listen.epic';
import { selectMicEpic } from './selectMic.epic';
import { cancelSreSessionEpic } from './cancelSreSession.epic';
import { setMicEnergySessionTypeEpic } from './setMicEnergySessionType.epic';
import { calibrationCalibrateEpic } from './calibrationCalibrate.epic';
import { primeSreSessionEpic } from './primeSreSession.epic';
import { openMicEpic } from './openMic.epic';

export const sreEpics = [
  ...soundLogsEpics,
  availableMicsUpdateEpic,
  calibrationCalibrateEpic,
  calibrationCalibratingEpic,
  calibrationCompletedEpic,
  cancelSreSessionEpic,
  checkMicPermissionEpic,
  configureSreEpic,
  configureSreValidatedEpic,
  finishSreSessionEpic,
  listenEpic,
  openMicEpic,
  primeSreSessionEpic,
  selectMicEpic,
  setMicEnergySessionTypeEpic
];
