import {
  ActivityPositionMap,
  IActivityPosition,
  IEncounterNode,
  ILevel,
  IPlacement,
  IPlacementForm,
  ITask,
  IUnit,
  IUnitSavePoint,
  IUnitSnipped
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { TaskRegistry } from 'task-components/core/registry';
import { EncounterContext } from '../EncounterContext';
import { ProgramMode } from '../program-context.model';
import { RoundContext } from '../RoundContext';
import { EncounterPedigreeFactory } from './EncounterPedigreeFactory';
import { RoundPedigreeFactory } from './RoundPedigreeFactory';

export class RoundContextFactory {
  public static readonly displayName = 'RoundContextFactory';

  public constructor(
    public readonly preparedTaskSelector: IPreparedTaskSelector,
    private readonly taskRegistry: TaskRegistry
  ) {}

  /**
   * Create a RoundContext instance
   *
   * @param programMode - program mode (e.g. ActiveStudent, Educator)
   * @param level - active level content
   * @param activityPosition - active activity position
   * @param activityPositionMap - map of all activity positions for active level
   * @param units - content for active unit and its parents
   * @param preparedTask - prepared task (potentially modified from original task content)
   * @param savePoints - unit save points
   * @param placement - placement content, when applicable
   */
  public create(
    programMode: ProgramMode,
    level: ILevel,
    activityPosition: IActivityPosition,
    activityPositionMap: ActivityPositionMap,
    units: IUnit[],
    preparedTask: ITask,
    savePoints: IUnitSavePoint[],
    placement: IPlacement | undefined
  ): RoundContext {
    return new RoundContext(
      programMode,
      activityPositionMap,
      RoundPedigreeFactory.create(
        activityPosition,
        EncounterPedigreeFactory.create(level, activityPosition),
        units,
        preparedTask
      ),
      this.taskRegistry,
      savePoints,
      this.findPlacementFormMaybe(programMode, activityPosition, placement)
    );
  }

  /**
   * In placement mode, find the placement form content specified by the
   * ID in the activity position.
   */
  private findPlacementFormMaybe(
    programMode: ProgramMode,
    activityPosition: IActivityPosition,
    placement: IPlacement | undefined
  ): IPlacementForm | undefined {
    if (programMode !== ProgramMode.Placement) {
      return undefined;
    }

    if (!placement) {
      throw new LexiaError(
        'Missing placement content despite being in Placement mode',
        RoundContextFactory.displayName,
        RoundContextFactoryError.PlacementMissing
      ).withContext({ activityPosition });
    }

    return placement.form;
  }

  /**
   * Create an instance if possible, but return undefined if not.
   * For example, outside of an active activity or during transitions it is
   * expected that program redux content may not match program position.
   *
   * @param programMode - program mode (e.g. ActiveStudent, Educator)
   * @param level - active level content
   * @param activityPosition - active activity position
   * @param activityPositionMap - map of all activity positions for active level
   * @param units - content for active unit and its parents
   * @param preparedTask - prepared task (potentially modified from original task content)
   * @param savePoints - unit save points
   * @param placement - placement content, when applicable
   */
  public maybeCreate(
    programMode: ProgramMode,
    level: ILevel,
    activityPosition: IActivityPosition,
    activityPositionMap: ActivityPositionMap,
    units: IUnit[],
    preparedTask: ITask,
    savePoints: IUnitSavePoint[],
    placement: IPlacement | undefined
  ): RoundContext | undefined {
    try {
      return this.create(
        programMode,
        level,
        activityPosition,
        activityPositionMap,
        units,
        preparedTask,
        savePoints,
        placement
      );
    } catch {
      return undefined;
    }
  }

  public createPartial(
    level: ILevel,
    activityPosition: IActivityPosition,
    activityPositionMap: ActivityPositionMap
  ): EncounterContext {
    const encounterNode = EncounterPedigreeFactory.create(
      level,
      activityPosition
    );
    const unit = this.findUnit(activityPosition, encounterNode);

    return new EncounterContext(
      encounterNode,
      activityPositionMap,
      activityPosition,
      unit
    );
  }

  private findUnit(
    activityPosition: IActivityPosition,
    encounterNode: IEncounterNode
  ): IUnitSnipped {
    const { unitId } = activityPosition.unitPosition;
    const unit = encounterNode.content.units.find(u => u.sysId === unitId);

    if (!unit) {
      throw new LexiaError(
        `Unable to find unit ${unitId} in active encounter`,
        RoundContextFactory.displayName,
        RoundContextFactoryError.UnitMissing
      );
    }

    return unit;
  }
}

export enum RoundContextFactoryError {
  PlacementFormMissing = 'PlacementFormMissing',
  PlacementMissing = 'PlacementMissing',
  UnitMissing = 'UnitMissing'
}

/**
 * Redux Selectors for getting the task as it has been prepared for the round
 * being presented (which may not be the original task content)
 */
export interface IPreparedTaskSelector {
  getTask(state: unknown): ITask;
  getTaskMaybe(state: unknown): ITask | undefined;
}
