import { Position, ScrollSnapType } from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import { Align, Color, ZIndex } from 'common-styles';
import { SystemInfo } from 'utils';
import { Types } from '../../types';
import { IArrowButtonSvgStyleOverride } from './arrow-button/ArrowButton.lx-svg';

export interface ICarouselStyleOverride {
  container?: Types.ViewStyle;
  scrollContainer?: Types.ViewStyle;
  controlContainerLeft?: Types.ViewStyle;
  controlContainerLeftDisabled?: Types.ViewStyle;
  controlContainerRight?: Types.ViewStyle;
  controlContainerRightDisabled?: Types.ViewStyle;
  controlContainerBottom?: Types.ViewStyle;
  arrowButtonSvg?: IArrowButtonSvgStyleOverride;
}

export class CarouselStyles {
  public static readonly build = memoizeOne(
    (
      slideWidth: number,
      styleOverrides: ICarouselStyleOverride,
      isArrowNavigation: boolean,
      disabledLeftCtrl: boolean,
      disabledRightCtrl: boolean
    ) => ({
      arrowButtonSvg: {
        color: Color.NearWhite,
        height: 54,
        width: 60,
        ...styleOverrides.arrowButtonSvg
      },
      container: {
        ...styleOverrides.container
      },
      controlContainerBottom: {
        bottom: 0,
        position: Position.Absolute,
        ...styleOverrides.controlContainerBottom
      },
      controlContainerLeft: {
        left: 0,
        position: Position.Absolute,
        zIndex: ZIndex.Shared,
        ...styleOverrides.controlContainerLeft,
        ...(disabledLeftCtrl && styleOverrides.controlContainerLeftDisabled)
      },
      controlContainerRight: {
        position: Position.Absolute,
        right: 0,
        ...styleOverrides.controlContainerRight,
        ...(disabledRightCtrl && styleOverrides.controlContainerRightDisabled)
      },
      scrollContainer: {
        alignItems: Align.Start,
        width: slideWidth,
        ...styleOverrides.scrollContainer
      },
      scrollView: {
        width: slideWidth,
        ...(!SystemInfo.isNative &&
          !isArrowNavigation && { scrollSnapType: ScrollSnapType.XMandatory })
      }
    })
  );
}
