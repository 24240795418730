import { ActionType, createAction } from 'typesafe-actions';
import {
  IAuthRequestBase,
  ILoginUserRequestBase,
  ILogoutUserRequestBase
} from '../auth-api/auth-api-private.model';
import { IRegisterRequestBase } from '../customer-api/customer-api-private.model';
import { IStudentApiRequestBase } from '../lexia-service-private.model';
import { ILexiaServiceOptions } from './lexia-service-redux.model';
import { LexiaServiceActionType } from './LexiaServiceActionType';

export const LexiaServiceAction = {
  updateRequestBase: createAction(
    LexiaServiceActionType.UpdateRequestBase
  )<IUpdateRequestBaseActionPayload>(),
  updateServiceOptions: createAction(
    LexiaServiceActionType.UpdateServiceOptions
  )<IUpdateServiceOptionsActionPayload>()
};

export interface IUpdateRequestBaseActionPayload {
  register?: Partial<IRegisterRequestBase>;
  authenticate?: Partial<IAuthRequestBase>;
  login?: Partial<ILoginUserRequestBase>;
  logout?: Partial<ILogoutUserRequestBase>;
  studentApi?: Partial<IStudentApiRequestBase>;
}

export interface IUpdateServiceOptionsActionPayload {
  options?: Partial<ILexiaServiceOptions>;
}

export type LexiaServiceActionUpdateRequest = ActionType<
  typeof LexiaServiceAction.updateRequestBase
>;
export type LexiaServiceActionUpdateServiceOptions = ActionType<
  typeof LexiaServiceAction.updateServiceOptions
>;
export type LexiaServiceActions = ActionType<typeof LexiaServiceAction>;
