import { createSelector } from '@reduxjs/toolkit';
import { IMeterMarker, IMeterState } from '../../progress-meters.model';

type RoundMeterSelectorType = (state: any) => IMeterState;

export class RoundMeterSelector {
  public static getState: RoundMeterSelectorType;

  public static getMarkers: (state: unknown) => IMeterMarker[];

  public static createSelectors(selector: RoundMeterSelectorType) {
    RoundMeterSelector.getState = createSelector(
      selector,
      (state: IMeterState) => state
    );
    RoundMeterSelector.getMarkers = createSelector(
      RoundMeterSelector.getState,
      (state: IMeterState) => state.markers
    );
  }
}
