export enum TextDecorationLine {
  LineThrough = 'line-through',
  None = 'none',
  Underline = 'underline'
}

export enum TextDecorationStyle {
  Dashed = 'dashed',
  Dotted = 'dotted',
  Double = 'double',
  Solid = 'solid'
}
