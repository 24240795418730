import { IScreenplay } from '@lexialearning/lobo-common';
import {
  CalibrationReactAnimationName,
  CalibrationSceneAnimationName,
  CalibrationSceneElementName,
  CalibrationStep,
  CalibrationUiAction
} from 'feature-areas/calibration';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { StormService } from 'storm';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { CalibrationScreenplayId } from './calibration-screenplay-builders.model';
import { UsageMetricsAction } from '@lexialearning/student-api';

/**
 * Creates a screenplay for the animated intro to the calibration page
 * - running storm and react animations, and playing voiceovers
 */
export class CalibrationIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'CalibrationIntroScreenplayBuilder';

  public static create(): CalibrationIntroScreenplayBuilder {
    return new CalibrationIntroScreenplayBuilder();
  }

  private constructor() {
    super(CalibrationScreenplayId.Intro);
  }

  public showCalibrationScene(
    preparedScenes: PreparedScenes,
    stormService: StormService
  ): CalibrationIntroScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const calibrationScene = await preparedScenes.calibrationReady;
        calibrationScene.show();

        // TODO: https://jira.lexialearning.com/browse/LOBO-15621
        if (preparedScenes.introduction) {
          stormService.unloadScene(preparedScenes.introduction.scene);
          preparedScenes.introduction = undefined;
        }
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  public fadeInPageElements(): CalibrationIntroScreenplayBuilder {
    this.builder.addReactAnimation(
      CalibrationReactAnimationName.FadeIn,
      {
        concurrent: true
      },
      { awaitRegistrationTimeoutMs: 1500 }
    );

    return this;
  }

  public animateScene(): CalibrationIntroScreenplayBuilder {
    this.builder
      .addStormAnimation(
        {
          loop: true,
          name: CalibrationSceneAnimationName.Gabber.Idle,
          targetElement: CalibrationSceneElementName.Gabber,
          targetScene: SceneName.Calibration
        },
        { concurrent: true }
      )
      .addStormAnimation({
        name: CalibrationSceneAnimationName.Root.Intro,
        targetScene: SceneName.Calibration
      });

    return this;
  }

  public loadUsageMetrics() {
    this.builder.addReduxAction(UsageMetricsAction.loadUsageMetrics.request());

    return this;
  }

  public playIntroVoiceovers(
    calibrationIntroductionVo: IScreenplay | undefined,
    calibrationInteractiveVo: IScreenplay | undefined,
    calibrationSentenceVo: IScreenplay | undefined
  ): CalibrationIntroScreenplayBuilder {
    this.builder
      .addScreenplay(calibrationIntroductionVo)
      .addDelay(500)
      .addReduxAction(
        CalibrationUiAction.setStep({ step: CalibrationStep.InteractiveIntro })
      )
      .addScreenplay(calibrationInteractiveVo)
      .addScreenplay(calibrationSentenceVo);

    return this;
  }

  public setInteractive(): CalibrationIntroScreenplayBuilder {
    this.builder.addReduxAction(
      CalibrationUiAction.setStep({ step: CalibrationStep.Interactive })
    );

    return this;
  }
}
