import * as React from 'react';
import { Row } from 'common-ui/components/layout/row/Row';
import { Linking } from 'common-ui/helpers';
import { ResetPasswordUrl, StudentDataUrl } from '../../login.model';
import { EducatorLinksStyles } from './EducatorLinks.styles';
import { PlainTextButton } from 'common-ui';

export interface IEducatorLinksProps {
  isVisible: boolean;
}

export class EducatorLinks extends React.PureComponent<IEducatorLinksProps> {
  public static readonly displayName = 'EducatorLinks';

  private async handleResetPassword(): Promise<void> {
    return Linking.openUrl(ResetPasswordUrl);
  }

  private async handleLookingForData(): Promise<void> {
    return Linking.openUrl(StudentDataUrl);
  }

  public render() {
    const { isVisible } = this.props;
    const styles = EducatorLinksStyles.get();

    // We need to render the outer Row regardless of isVisible to maintain the
    // login button position between Student and Educator tabs
    // (this EducatorLinks component is only visible for Educator tab, but
    //  determines the position of the login button below it for both)
    return (
      <Row style={styles.linkRow}>
        {isVisible && (
          <>
            <PlainTextButton
              onPress={this.handleResetPassword}
              testId="resetPassword"
              text="I forgot my password"
            />
            <PlainTextButton
              onPress={this.handleLookingForData}
              testId="studentData"
              text="I'm looking for student data"
            />
          </>
        )}
      </Row>
    );
  }
}
