// istanbul ignore file - static SVG, nothing to test
import { ITextButtonIconProps, Path, Svg } from 'common-ui';

export function BackIconSvg({ color }: ITextButtonIconProps) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 26 26"
    >
      <Path
        fill={color}
        d="M3.87 11.41L17.07.9a2.57 2.57 0 01.44-.29 2.71 2.71 0 013.64 4l-.18.13a3.11 3.11 0 01-.28.23L12 11.9a1.41 1.41 0 000 2.3l8.5 6.77a2.8 2.8 0 01.57.46 2.69 2.69 0 01.71 1.82 2.72 2.72 0 01-2.72 2.72 2.68 2.68 0 01-1.66-.58 1.69 1.69 0 01-.41-.24L3.87 14.67a2 2 0 010-3.26z"
      />
    </Svg>
  );
}
BackIconSvg.displayName = 'BackIconSvg';
