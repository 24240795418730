import { CharacterSceneBase } from 'feature-areas/character-scene';
import { CharacterSceneCharacterAnimationLayer } from 'feature-areas/character-scene/character-scene.model';
import {
  CharacterAnimationCategory,
  SceneZIndex
} from 'services/storm-lobo/lobo-storm-config.model';
import { Scene } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  PlacementSceneAnimationName,
  PlacementSceneElementName
} from './placement-scene.model';

export class PlacementScene extends CharacterSceneBase {
  public static readonly displayName = 'PlacementScene';

  constructor(scene: Scene) {
    super(scene, SceneZIndex.Placement);
    this.init();
    this.animateCharacterIdle();
    this.animateBackground();
  }

  private init() {
    const request: ISceneAnimationRequest = {
      name: PlacementSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  private animateCharacterIdle() {
    const idleAnimation = this.character.pickOneAnimation(
      CharacterAnimationCategory.Idle
    );

    const request: ISceneAnimationRequest = {
      animationLayer: CharacterSceneCharacterAnimationLayer.Base,
      loop: true,
      name: idleAnimation.name,
      targetElement: PlacementSceneElementName.Character
    };
    this.scene.startAnimation(request);
  }

  private animateBackground() {
    const request: ISceneAnimationRequest = {
      loop: true,
      name: PlacementSceneAnimationName.BackgroundOverlay.Idle,
      targetElement: PlacementSceneElementName.BackgroundOverlay
    };
    this.scene.startAnimation(request);
  }
}
