import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import {
  CalibrationStep,
  CalibrationUiActionType,
  ICalibrationUiState
} from './calibration-ui-redux.model';
import { CalibrationUiActions } from './CalibrationUi.action';

export const calibrationUiInitialState: ICalibrationUiState = {
  hasPlayedFeedback: false,
  isRetry: false,
  step: CalibrationStep.Intro
};

export function calibrationUiReducer(
  state = calibrationUiInitialState,
  action: CalibrationUiActions | UserGlobalActionLogoutSuccess
): ICalibrationUiState {
  switch (action.type) {
    case CalibrationUiActionType.SetStep:
      const newStep = action.payload.step;
      const shouldResetRetry = [
        CalibrationStep.MicSetupTips,
        CalibrationStep.MicFix,
        CalibrationStep.MicChanged,
        CalibrationStep.Intro
      ].includes(newStep);

      return {
        ...state,
        hasPlayedFeedback:
          newStep === CalibrationStep.Interactive
            ? false
            : state.hasPlayedFeedback,
        isRetry:
          newStep === CalibrationStep.TryAgain
            ? true
            : shouldResetRetry
            ? false
            : state.isRetry,
        step: newStep
      };

    case CalibrationUiActionType.SetHasPlayedFeedback:
      return {
        ...state,
        hasPlayedFeedback: action.payload
      };

    case UserGlobalActionType.LogoutSuccess:
      return calibrationUiInitialState;

    default:
      return state;
  }
}
