import { cancelListeningOnShowModalEpic } from './cancelListeningOnShowModal.epic';
import { handleRequestLogoutEpic } from './handleRequestLogout.epic';
import { handleNavBackEpic } from './handleNavBack.epic';
import { micBlockedModalEpic } from './microphones/micBlockedModal.epic';
import { micChangedModalEpic } from './microphones/micChangedModal.epic';
import { noMicModalEpic } from './microphones/noMicModal.epic';
import { toggleGlobalDisabledEpic } from './toggleGlobalDisabled.epic';
import { toggleKeydownHandlingDisabledEpic } from './toggleKeydownHandlingDisabled.epic';
import { updateAppShellModeEpic } from './updateAppShellMode.epic';
import { updatePageTitleEpic } from './updatePageTitle.epic';
import { updateControlPanelLayoutEpic } from './updateControlPanelLayout.epic';
import { updateMicForceDisabledEpic } from './updateMicForceDisabled.epic';
import { updateMicInteractionStateEpic } from './updateMicInteractionState.epic';
import { updateNavigationButtonTypeEpic } from './updateNavigationButtonType.epic';
import { updateSkipButtonStateEpic } from './updateSkipButtonState.epic';
import { updateUserControlInteractionStateEpic } from './updateUserControlInteractionState.epic';
import { updateUserInfoButtonTypeEpic } from './updateUserInfoButtonType.epic';

export const appShellEpics = [
  cancelListeningOnShowModalEpic,
  handleRequestLogoutEpic,
  handleNavBackEpic,
  micBlockedModalEpic,
  micChangedModalEpic,
  noMicModalEpic,
  toggleGlobalDisabledEpic,
  toggleKeydownHandlingDisabledEpic,
  updateAppShellModeEpic,
  updatePageTitleEpic,
  updateControlPanelLayoutEpic,
  updateMicForceDisabledEpic,
  updateMicInteractionStateEpic,
  updateNavigationButtonTypeEpic,
  updateSkipButtonStateEpic,
  updateUserControlInteractionStateEpic,
  updateUserInfoButtonTypeEpic
];
