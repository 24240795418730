import { StateObservable } from 'redux-observable';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import {
  ActivityPositionAction,
  PositionAction,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import {
  LevelSceneAction,
  LevelSceneAnimationName,
  LevelSceneElementName,
  LevelSceneLayout
} from 'feature-areas/levels';
import { LevelReactAnimationName } from 'feature-areas/levels/level-page/level-page.model';
import { RouteBuilder, RouterService } from 'router-service';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TitlePillBadgeReactAnimationName } from 'shared-components/title-pill-badge/title-pill-badge.model';
import { TransitionScreenplayBuilderBase } from '../../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../../transition.model';
import { RoundToEncounterCompleteTransitionBuilder } from '../encounter-complete/RoundToEncounterCompleteTransitionBuilder';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

export class RoundToActCompleteTransitionBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundToActCompleteTransitionBuilder';

  public static createFor(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps
  ): RoundToActCompleteTransitionBuilder {
    return new RoundToActCompleteTransitionBuilder(state$, deps);
  }

  private readonly context: RoundContext;

  private constructor(
    private readonly state$: StateObservable<unknown>,
    private readonly deps: IRoundToNextTransitionDeps
  ) {
    super(TransitionScreenplayId.RoundToActComplete);
    this.context = ProgramContextSelector.getRoundContext(this.state$.value);
  }

  public changeActivityPositionToComplete(): RoundToActCompleteTransitionBuilder {
    this.builder.addReduxAction(ActivityPositionAction.change());

    return this;
  }

  public prepareLevelShowcaseScene(): RoundToActCompleteTransitionBuilder {
    this.builder.addReduxAction(
      LevelSceneAction.prepare({ layout: LevelSceneLayout.ActComplete })
    );

    return this;
  }

  public playEncounterCompleteActions(): RoundToActCompleteTransitionBuilder {
    this.builder.addScreenplay(
      this.createEncounterScreenplayBuilder()
        .fadeOutControlPanel()
        .navToEncounterComplete()
        .showEncounterReward()
        .navToActPage()
        .showLevelScene({ withShowcase: false })
        .hidePriorScenes()
        .animateInFooter(true).screenplay
    );

    return this;
  }

  public playCharacterCelebration(): RoundToActCompleteTransitionBuilder {
    this.builder
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.Root.CharacterCompleteIdle,
          targetScene: SceneName.Level
          // Note: given the animation that is used here, unlike other idle animations
          // this should never be set to loop
        },
        { concurrent: true }
      )
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.CharacterComplete.getRandomCelebration()!,
          targetElement: LevelSceneElementName.CharacterComplete,
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.ActComplete }, { concurrent: true });

    return this;
  }

  public animateInBadge(): RoundToActCompleteTransitionBuilder {
    this.builder
      // Delay required in order to start badge animation slightly *before the end of the celebration animation/music
      .addDelay(4000)
      .addScreenplay(
        this.createEncounterScreenplayBuilder().animateInBadge().screenplay
      )
      // Final delay to allow the celebration animation to complete before moving on
      .addDelay(1500);

    return this;
  }

  public navToLevelPage(): RoundToActCompleteTransitionBuilder {
    this.builder
      .addStormAnimation(
        {
          name: LevelSceneAnimationName.Root.Outro,
          targetScene: SceneName.Level
        },
        { concurrent: true }
      )
      .addCallback(() => {
        RouterService.history.replace(
          RouteBuilder.levels(this.context.level.sysId)
        );
      });

    return this;
  }

  public deselectActivity(): RoundToActCompleteTransitionBuilder {
    this.builder.addReduxAction(PositionAction.activityDeselected());

    return this;
  }

  public animateInActSelection(): RoundToActCompleteTransitionBuilder {
    this.builder.addStormAnimation(
      {
        name: LevelSceneAnimationName.Root.Intro,
        targetScene: SceneName.Level
      },
      { concurrent: true }
    );

    return this;
  }

  public animateInTitleBar(): RoundToActCompleteTransitionBuilder {
    const { act, level } = this.context;
    const actIdx = level.acts.findIndex(a => a.sysId === act.sysId);

    this.builder
      .addReactAnimation(
        TitlePillBadgeReactAnimationName.buildImageHide(`act_${actIdx}`)
      )
      .addReactAnimation(LevelReactAnimationName.FadeIn)
      .addSfx(Sfx.Flare, { concurrent: true })
      .addReactAnimation(
        TitlePillBadgeReactAnimationName.buildFlare(`act_${actIdx}`)
      );

    return this;
  }

  private createEncounterScreenplayBuilder(): RoundToEncounterCompleteTransitionBuilder {
    return RoundToEncounterCompleteTransitionBuilder.createFor(
      this.state$,
      this.deps
    );
  }
}
