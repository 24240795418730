import { Column } from 'common-ui';
import { FaceStyle } from 'services/profile/avatar.model';
import { FaceStyleFourSvg } from './face-style-svgs/FaceStyleFour.lx-svg';
import { FaceStyleOneSvg } from './face-style-svgs/FaceStyleOne.lx-svg';
import { FaceStyleThreeSvg } from './face-style-svgs/FaceStyleThree.lx-svg';
import { FaceStyleTwoSvg } from './face-style-svgs/FaceStyleTwo.lx-svg';
import { ProfileSelector } from 'services/profile';
import { SkinColorOptions } from './skin-color-options/SkinColorOptions';
import { useSelector } from 'react-redux';
import {
  SquareButton,
  useAvatarEditorDisabled,
  useHandleAvatarEditorButtonPress
} from '../shared';

const FaceStyleSvgs = [
  FaceStyleOneSvg,
  FaceStyleTwoSvg,
  FaceStyleThreeSvg,
  FaceStyleFourSvg
];

export function FaceStyleOptions() {
  const { selectedFaceStyle, skinColor } = useSelector(state => {
    const avatar = ProfileSelector.getAvatarMaybe(state);

    return {
      selectedFaceStyle: avatar?.faceStyle,
      skinColor: avatar?.skinColor
    };
  });

  const disabled = useAvatarEditorDisabled();

  const handlePress = useHandleAvatarEditorButtonPress();
  const handleSelect = (faceStyle: FaceStyle) => handlePress({ faceStyle });

  return (
    <>
      <Column testId={FaceStyleOptions.displayName}>
        {Object.values(FaceStyle).map((faceStyle: FaceStyle, idx: number) => {
          const FaceSvg = FaceStyleSvgs[idx];
          const selected = faceStyle === selectedFaceStyle;

          return (
            <SquareButton
              accessibilityLabel={`Face option ${idx + 1}`}
              key={faceStyle}
              onPress={() => {
                handleSelect(faceStyle);
              }}
              disabled={disabled}
              selected={selected}
            >
              <FaceSvg />
            </SquareButton>
          );
        })}
      </Column>
      <SkinColorOptions disabled={disabled} selectedColor={skinColor} />
    </>
  );
}
FaceStyleOptions.displayName = 'FaceStyleOptions';
