import memoizeOne from 'memoize-one';
import { Color, InteractionState } from 'common-styles';
import { SpotlightAreaLayout } from '../spotlight/Spotlight.styles';

export class InstructionalImageStyles {
  public static readonly styles = {
    container: {
      default: {
        borderColor: Color.NearWhite,
        borderWidth: 8
      },
      highlighted: {
        borderColor: Color.getSetFor(InteractionState.Highlighted).border
      }
    },
    image: {
      default: {
        borderColor: Color.Gray50,
        borderWidth: 1,
        height: 220,
        width: 695
      },
      small: {
        height: 176,
        width: 555
      }
    }
  };

  public static readonly build = memoizeOne(
    (isHighlighted: boolean, layout: SpotlightAreaLayout) => ({
      container: {
        ...InstructionalImageStyles.styles.container.default,
        ...(isHighlighted &&
          InstructionalImageStyles.styles.container.highlighted)
      },
      image: {
        ...InstructionalImageStyles.styles.image.default,
        ...(layout === SpotlightAreaLayout.Small &&
          InstructionalImageStyles.styles.image.small)
      }
    })
  );
}
