import {
  CalibrationStep,
  CalibrationUiActionType
} from 'feature-areas/calibration';
import { ILoggingDeps } from '../logging-epic.model';
import { LexiaError } from '@lexialearning/utils';
import { LoggingLevel } from '@lexialearning/main-model';
import { Observable } from 'rxjs';
import { ProfileSelector } from 'services/profile';
import { SreSessionActionType } from 'sre';
import { TimingSet } from './TimingSet';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import {
  ProgramContextActionLoaded,
  ProgramContextActionType,
  ProgramContextSelector,
  ProgramMode
} from 'curriculum-services';

// TEMPORARY LOGGING: LOBO-18305
// Request from Wes for analytics about Onboarding to assess student
// difficulties and remedies
export function logOnboardingCalibrationTimingsEpic(
  action$: Observable<ProgramContextActionLoaded>,
  state$: StateObservable<unknown>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(ProgramContextActionType.Loaded),
    filter(
      () =>
        ProfileSelector.isStudentLoggedIn(state$.value) &&
        ProgramContextSelector.getMode(state$.value) === ProgramMode.Onboarding
    ),
    tap(() => {
      const { appLoggerDependencies: helper } = deps;

      try {
        TimingSet.create(
          'Onboarding calibration',
          helper.logger,
          LoggingLevel.Analytics
        )
          .addTiming(
            'Calibration interactive to started',
            {
              payload: { step: CalibrationStep.Interactive },
              type: CalibrationUiActionType.SetStep
            },
            { type: SreSessionActionType.Listen }
          )
          .startMonitoring(action$ as any);
      } catch (err) {
        void helper.logger.logError(
          new LexiaError(
            `Error attempting to log the Onboarding calibration timing event: ${err.message}`,
            logOnboardingCalibrationTimingsEpic.displayName,
            LogOnboardingTimingsEpicError.ErrorLogging
          ).withCause(err)
        );
      }
    }),
    ignoreElements()
  );
}
logOnboardingCalibrationTimingsEpic.displayName =
  'logOnboardingCalibrationTimingsEpic';

export enum LogOnboardingTimingsEpicError {
  ErrorLogging = 'ErrorLogging'
}
