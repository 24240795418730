import { AppConfigActionType, AppConfigActionUpdateEntries } from '../redux';
import { AppState } from 'services';
import { ILoboAppConfig } from 'services/app-config';
import { Observable } from 'rxjs';
import { cloneDeep, set } from 'lodash';
import { mergeMap } from 'rxjs/operators';
import { ofType, StateObservable } from 'redux-observable';
import {
  ConfigSelector as UniConfigSelector,
  ConfigAction as UniConfigAction,
  ConfigActionUpdate as UniConfigActionUpdate
} from '@lexialearning/utils-react';

export function updateEntriesEpic(
  action$: Observable<AppConfigActionUpdateEntries>,
  state$: StateObservable<AppState>
): Observable<UniConfigActionUpdate> {
  return action$.pipe(
    ofType(AppConfigActionType.UpdateEntries),
    mergeMap(async action => {
      const configOriginal = UniConfigSelector.getConfig<ILoboAppConfig>(
        state$.value
      );
      const config = cloneDeep(configOriginal);

      action.payload.forEach(entry => {
        set(config, entry.key, entry.value);
      });

      return UniConfigAction.update(config);
    })
  );
}

updateEntriesEpic.displayName = 'updateEntriesEpic';
