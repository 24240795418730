import {
  IActivityPosition,
  ITaskAttempt
} from '@lexialearning/lobo-common/main-model';
import { ITaskEvaluation } from '@lexialearning/lobo-common/main-model/tasks';
import { v4 as uuid } from 'uuid';
import {
  TaskActionEvaluated,
  TaskActionInteractive,
  TaskActionType
} from 'task-components/core/redux';
import { ActivityPositionBuilder } from '../../../epics';
import {
  IProgramContextState,
  ProgramContextActionType
} from '../../program-context-redux.model';
import { ProgramContextActionOnboardingCompleted } from '../../ProgramContext.action';
import { ActivityPositionActionType } from './activity-position-redux.model';
import { ActivityPositionActions } from './ActivityPosition.action';

type InputActions =
  | ActivityPositionActions
  | TaskActionEvaluated
  | TaskActionInteractive
  | ProgramContextActionOnboardingCompleted;

/**
 * NOTE: This reducer is called by programContextReducer not the root reducer.
 * So all actions here must first be recognized by programContextReducer.
 */
export function activityPositionsReducer(
  state: IProgramContextState,
  action: InputActions
): IActivityPosition[] {
  switch (action.type) {
    case ActivityPositionActionType.Adjusted:
      return mergePositions(state, action.payload);

    case ActivityPositionActionType.Changed:
    case ActivityPositionActionType.Selected:
      return updateActivityPosition(state, () => action.payload);

    case ProgramContextActionType.OnboardingCompleted:
      return updateActivityPosition(state, ap => ({
        ...ap,
        isComplete: true
      }));

    case ActivityPositionActionType.EncounterSelected:
      return updateActivityPosition(state, ap => ({
        ...ap,
        encounterId: action.payload.encounterId
      }));

    case ActivityPositionActionType.Prepared:
    case ActivityPositionActionType.SkipPrepared:
      return updateActivityPosition(state, ap => ({
        ...ap,
        imminentPosition: action.payload
      }));

    case TaskActionType.Evaluated:
      return updateActivityPosition(state, ap => addAttempt(ap, action));

    case TaskActionType.Interactive:
      return updateActivityPosition(state, setAttemptStartDate);

    default:
      return state.position.activityPositions;
  }
}
activityPositionsReducer.displayName = 'activityPositionsReducer';

function mergePositions(
  state: IProgramContextState,
  overrides: IActivityPosition[]
): IActivityPosition[] {
  const overridesMap = overrides.reduce(
    (m, ap) => m.set(ap.activityId, ap),
    new Map<string, IActivityPosition>()
  );

  return state.position.activityPositions.map(ap =>
    overridesMap.has(ap.activityId) ? overridesMap.get(ap.activityId)! : ap
  );
}

/**
 * Return all activity positions updating the active one with the results of
 * the updater callback
 */
function updateActivityPosition(
  state: IProgramContextState,
  updater: (ap: IActivityPosition) => IActivityPosition
): IActivityPosition[] {
  const activeId = state.position.activeActivityId;

  return state.position.activityPositions.map(ap =>
    ap.activityId === activeId ? updater(ap) : ap
  );
}

function addAttempt(
  ap: IActivityPosition,
  action: TaskActionEvaluated
): IActivityPosition {
  const builder = ActivityPositionBuilder.create(ap);
  const attempt = buildAttempt(
    action.payload,
    builder.activeUnitPosition.attemptStartDate!
  );

  return builder.addAttempt(attempt).activityPosition;
}

function buildAttempt(
  evaluation: ITaskEvaluation,
  attemptStartDate: number
): ITaskAttempt {
  return {
    ...evaluation,
    correlationId: uuid(),
    durationSeconds: Math.round((Date.now() - attemptStartDate) / 1000)
  };
}

function setAttemptStartDate(ap: IActivityPosition): IActivityPosition {
  return ActivityPositionBuilder.create(ap).updateActiveUnitPosition({
    attemptStartDate: Date.now()
  }).activityPosition;
}
