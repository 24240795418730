import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Align, Color, TransformOrigin } from 'common-styles';
import { SystemInfo } from 'utils/SystemInfo';
import { Types } from '../../types';
import { Cursor, Overflow } from '@lexialearning/common-ui';

export interface IDropdownPopupStyleOverride {
  list?: Types.ViewStyle;
}

const DROPDOWN_WIDTH = 380;

export class DropdownPopupStyles {
  private static styles = {
    list: {
      alignItems: Align.Stretch,
      backgroundColor: Color.NearWhite,
      borderRadius: 8,
      cursor: Cursor.Pointer,
      marginTop: 8,
      overflow: Overflow.Hidden,
      shadowColor: Color.BlackTransparent30,
      shadowOffset: { height: 3, width: 0 },
      shadowOpacity: 1,
      shadowRadius: 10,
      // transform: [{ scale }],
      width: DROPDOWN_WIDTH,
      ...(!SystemInfo.isNative && {
        transformOrigin: TransformOrigin.Top
      })
    }
  };
  public static readonly build = memoizeOne(
    (scale: number, styleOverride?: IDropdownPopupStyleOverride) =>
      merge(
        {
          list: {
            alignItems: Align.Stretch,
            backgroundColor: Color.NearWhite,
            borderRadius: 8,
            cursor: Cursor.Pointer,
            marginTop: 8,
            overflow: Overflow.Hidden,
            shadowColor: Color.BlackTransparent30,
            shadowOffset: { height: 3, width: 0 },
            shadowOpacity: 1,
            shadowRadius: 10,
            transform: [{ scale }],
            width: DROPDOWN_WIDTH,
            ...(!SystemInfo.isNative && {
              transformOrigin: TransformOrigin.Top
            })
          }
        },
        styleOverride
      )
  );
}
