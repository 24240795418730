import { ActionType, createAction } from 'typesafe-actions';
import { LevelSceneLayout } from '../level-scene.model';
import { LevelSceneActionType } from './level-scene-redux.model';

export const LevelSceneAction = {
  prepare: createAction(
    LevelSceneActionType.Prepare
  )<ILevelSceneActionPreparePayload>()
};

export interface ILevelSceneActionPreparePayload {
  layout: LevelSceneLayout;
}

export type LevelScenePrepareAction = ActionType<
  typeof LevelSceneAction.prepare
>;
export type LevelSceneActions = ActionType<typeof LevelSceneAction>;
