import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ICurriculumDependencies } from 'curriculum-services/CurriculumDependencies';
import { SessionTimeoutConfirmationModal } from 'feature-areas/session-timeout';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import {
  ProgramContextActionMonitorSessionTimeout,
  ProgramContextActionType
} from '../../redux';

export function handleSessionTimeoutWarningsEpic(
  action$: Observable<ProgramContextActionMonitorSessionTimeout>,
  _: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<AppShellActionShowModal> {
  return action$.pipe(
    ofType(ProgramContextActionType.MonitorSessionTimeout),
    switchMap(
      () =>
        deps.curriculumDependencies.studentProgressApi.sessionTimeoutWarning$
    ),
    map(() =>
      AppShellAction.showModal({ id: SessionTimeoutConfirmationModal.ModalId })
    )
  );
}
handleSessionTimeoutWarningsEpic.displayName =
  'handleSessionTimeoutWarningsEpic';
