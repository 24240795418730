import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import { Border, Position } from '@lexialearning/common-ui';

export interface IHintBoxStyleOverride {
  borderContainer: {
    width?: number;
  };
}

export class HintBoxAnimatedStyles {
  private readonly animations: {
    fadeOut: Types.Animated.CompositeAnimation;
    reset: Types.Animated.CompositeAnimation;
  };

  private readonly animatedValues: {
    opacity: Types.AnimatedValue;
  };

  constructor() {
    this.animatedValues = { opacity: loboAnimated.createValue(1) };

    this.animations = {
      fadeOut: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.opacity,
        {
          duration: 250,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      ),
      reset: loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        this.animatedValues.opacity,
        {
          duration: 0,
          toValue: 1
        }
      )
    };
  }

  public readonly build = memoizeOne(
    (isInstruction?: boolean, styleOverride?: IHintBoxStyleOverride) => ({
      backgroundContainer: {
        backgroundColor: isInstruction ? Color.Gray40 : Color.Gray10,
        borderRadius: isInstruction ? 100 : 22,
        flex: isInstruction ? 1 : 0,
        height: isInstruction ? 64 : 44,
        paddingLeft: 15,
        paddingRight: 15
      },
      borderContainer: {
        animated: {
          opacity: this.animatedValues.opacity
        },
        static: {
          alignItems: Align.Center,
          borderColor: isInstruction ? Color.Gray40 : Color.Gray10,
          borderRadius: isInstruction ? 100 : 32,
          borderStyle: Border.Dashed,
          borderWidth: 4,
          marginBottom: 15,
          marginTop: isInstruction ? 0 : -15,
          padding: 6,
          position: Position.Relative,
          top: 0,
          ...styleOverride?.borderContainer
        }
      }
    })
  );

  public getAnimations() {
    return this.animations;
  }

  public readonly buildTokenStyle = memoizeOne(
    (isHidden: boolean, isInstruction?: boolean) => ({
      color: isHidden ? Color.Transparent : Color.Black,
      paddingLeft: 15,
      paddingRight: 15,
      ...(isInstruction && { fontSize: 40 })
    })
  );
}
