import { ICalibrationResult } from '@lexialearning/lobo-common/main-model/sre';
import { ActionType, createAction } from 'typesafe-actions';
import { SreCalibrationActionType } from '../sre-redux.model';

export const SreCalibrationAction = {
  calibrate: createAction(SreCalibrationActionType.Calibrate)(),
  calibrating: createAction(SreCalibrationActionType.Calibrating)(),
  calibrationComplete: createAction(
    SreCalibrationActionType.Complete
  )<ICalibrationResult>(),
  /**
   * Coming from a session outside Calibration (eg, See Speak language frame session),
   * when recalibration is needed, set the calibration result from that session's result
   * in order to play and display the correct feedback in the CalibrationMicFix screen
   */
  setCalibrationResult: createAction(
    SreCalibrationActionType.SetCalibrationResult
  )<ICalibrationResult>()
};

export type SreCalibrationActions = ActionType<typeof SreCalibrationAction>;
export type SreCalibrationActionCalibrate = ActionType<
  typeof SreCalibrationAction.calibrate
>;
export type SreCalibrationActionCalibrating = ActionType<
  typeof SreCalibrationAction.calibrating
>;
export type SreCalibrationActionComplete = ActionType<
  typeof SreCalibrationAction.calibrationComplete
>;
export type SreCalibrationActionSetCalibrationResult = ActionType<
  typeof SreCalibrationAction.setCalibrationResult
>;
