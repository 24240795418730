import { Position } from '@lexialearning/common-ui';
import { Defs, G, LinearGradient, Rect, Stop, Svg, useSizing } from 'common-ui';

export interface IBackgroundGradientSvgProps {
  /**
   * Should be given unique id in case
   * there are multiple of this component
   * on the page at once
   */
  id: string;
  /** Default = 1 */
  opacity?: number;
  /** Default = app background gradient top color */
  colorTop?: string;
  /** Default = app background gradient bottom color */
  colorBottom?: string;
  height?: number;
  width?: number;
}

export function BackgroundGradientSvg(props: IBackgroundGradientSvgProps) {
  const { appSize } = useSizing();
  const {
    colorTop = '#FDB928',
    colorBottom = '#EBA103',
    id,
    opacity = 1,
    height = appSize.h,
    width = appSize.w
  } = props;

  return (
    <Svg
      data-test-id={`backgroundGradient_${id}`}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ opacity, position: Position.Absolute }}
    >
      <Defs>
        <LinearGradient id={`backgroundGradient_${id}`} x2="0" y2="1">
          <Stop offset="0" stopColor={colorTop} />
          <Stop offset="1" stopColor={colorBottom} />
        </LinearGradient>
      </Defs>
      <G>
        <Rect
          fill={`url(#backgroundGradient_${id})`}
          x="0"
          y="0"
          width={width}
          height={height}
        />
      </G>
    </Svg>
  );
}
BackgroundGradientSvg.displayName = 'BackgroundGradientSvg';
