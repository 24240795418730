import { LoggingLevel } from '@lexialearning/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { Column } from 'common-ui/components/layout/column/Column';
import { Text } from 'common-ui/components/text';
import { LoboLogItemCategory } from 'logging';
import { AuthSelector } from 'services/auth/redux';
import { DeviceInfoHelper } from 'services/logging';
import { Services } from 'services/Services';
import {
  IModalAction,
  ModalActions,
  ModalRegistry
} from 'shared-components/modals';
import { AppShellAction } from 'shell/redux';
import { ContactSupportModalStyles } from './ContactSupportModal.styles';
import { DeviceInfoReporter } from '@lexialearning/utils-react';

export enum ContactSupportModalStatus {
  Open,
  Sending,
  Sent
}

export interface IContactSupportModalProps extends ISfxProps {
  loginId: string;
  close(): void;
}

export interface IContactSupportModalState {
  status: ContactSupportModalStatus;
}

export class ContactSupportModalComponent extends React.PureComponent<
  IContactSupportModalProps,
  IContactSupportModalState
> {
  public static readonly displayName = 'ContactSupportModal';

  public static ModalId: string;

  public constructor(props: IContactSupportModalProps) {
    super(props);

    this.state = {
      status: ContactSupportModalStatus.Open
    };

    this.handleSend = this.handleSend.bind(this);
  }

  private get primaryButton(): IModalAction {
    const { status } = this.state;
    const isSent = status === ContactSupportModalStatus.Sent;
    const isSending = status === ContactSupportModalStatus.Sending;
    const text = isSent ? 'Done' : isSending ? 'Sending...' : 'Send';

    return {
      disabled: isSending,
      ignoreGlobalDisabled: true,
      onPress: isSent ? this.props.close : this.handleSend,
      text
    };
  }

  private get secondaryButton(): IModalAction | undefined {
    if (this.state.status === ContactSupportModalStatus.Sent) {
      return undefined;
    }

    return {
      onPress: this.props.close,
      text: 'Cancel'
    };
  }

  private async handleSend() {
    this.props.playSfx(Sfx.Neutral);
    this.setState({ status: ContactSupportModalStatus.Sending });
    const deviceInfo = await DeviceInfoReporter.create();
    await Services.logger!.log({
      category: LoboLogItemCategory.CustomerSupport,
      loggingLevel: LoggingLevel.Error,
      payload: { deviceInfo },
      summary: `Customer filed support log on ${DeviceInfoHelper.getSummary()}`
    });
    this.setState({ status: ContactSupportModalStatus.Sent });
  }

  public render() {
    const styles = ContactSupportModalStyles.get();
    const isSent = this.state.status === ContactSupportModalStatus.Sent;

    return (
      <Column style={styles.container}>
        {isSent ? (
          <Column style={styles.sentTextContainer}>
            <Text style={styles.text}>Your support request has been sent.</Text>
            <Text style={styles.text}>Contact support for next steps with</Text>
            <Text style={styles.text}>
              Support ID:
              <Text style={styles.bold} selectable>
                {` ${this.props.loginId}`}
              </Text>
            </Text>
          </Column>
        ) : (
          <Text style={styles.text}>
            To help debug problems, this will send your
            <Text style={styles.bold}> technical information </Text>
            to Lexia&reg; You must contact support for next steps, along with
            capturing the Support ID.
          </Text>
        )}
        <ModalActions
          primaryAction={this.primaryButton}
          secondaryAction={this.secondaryButton}
        />
      </Column>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    loginId: AuthSelector.getLoginId(state)!
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  close: () => AppShellAction.hideModal()
};

export const ContactSupportModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSfx(ContactSupportModalComponent));

ContactSupportModal.ModalId = ModalRegistry.register({
  Component: ContactSupportModal,
  title: 'Support'
});
