import { SoundLogCollection } from '@lexialearning/lobo-common/main-model/sre';
import { SoundLogFormat } from '@lexialearning/sre';

export class SoundLogFormatHelper {
  public static readonly displayName = 'SoundLogFormatHelper';

  public static getSoundLogFormat(
    mode: SoundLogCollection | undefined
  ): SoundLogFormat {
    return mode === SoundLogCollection.FullLossless
      ? SoundLogFormat.Flac
      : SoundLogFormat.Speex;
  }
}
