import { CancellationSignal } from './CancellationSignal';

/**
 * Helper for canceling async tasks. The async task should be given the signal
 * generated by this controller. The calling process can cancel the task by
 * calling cancellationController.cancel(), which throws the signal (i.e.
 * resolves the signal's promise and flags it as being canceled).
 *
 * This is similar to {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController|AbortController}
 * or the C# CancellationToken.
 *
 * @see CancellationSignal
 *
 * @example
 *
 *    // Task that can be cancelled
 *    const controller = new CancellationController();
 *    const signal = controller.signal;
 *    await Promise.race([task(), signal.promise]);
 *    if (signal.isCanceled) {
 *      // task was canceled
 *    }
 *
 *    // Cancelling the task (in some other execution "thread")
 *    controller.cancel();
 */
export class CancellationController {
  public static readonly displayName = 'CancellationController';

  public readonly signal = new CancellationSignal();

  public cancel(): void {
    this.signal.cancel();
  }
}
