import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramContextSelector } from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import {
  TaskAction,
  TaskActionFeedback,
  TaskActionType,
  TaskSelector
} from '../../redux';
import { ITaskEpicsDependencies } from '../task-epics.model';

export function feedbackEpic(
  action$: Observable<TaskActionFeedback>,
  state$: StateObservable<unknown>,
  deps: ITaskEpicsDependencies
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TaskActionType.Feedback),
    map(() => {
      const phase = TaskSelector.getPhase(state$.value);
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const registration = context.taskRegistration;

      const screenplay = deps.taskRegistry.screenplayEditor.edit(
        registration.buildFeedbackScreenplay(context),
        context,
        phase
      );

      return ScreenplayAction.play({
        nextAction: TaskAction.feedbackComplete(),
        screenplay
      });
    })
  );
}
feedbackEpic.displayName = 'feedbackEpic';
