import {
  TaskEvaluationResult,
  TaskPhase,
  TaskTypeName
} from '@lexialearning/lobo-common';
import { useDispatch, useSelector } from 'react-redux';
import { PressableView, Row, Text, useSizing } from 'common-ui';
import { ILoboAppConfig } from 'services/app-config';
import { RoundSelector } from 'services/curriculum-services';
import { IDevShortcutsProps, withDevShortcuts } from './dev-shortcuts';
import { DeveloperAccessMenuStyles } from './DeveloperAccessMenu.styles';
import { DevTools } from './DevTools';
import { DevToolsAction } from './redux/DevTools.action';
import { DevToolsSelector } from './redux/DevTools.selector';
import { CalibrationResult } from '@lexialearning/sre';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { TaskSelector } from 'task-components';
import { useCallback } from 'react';

export function DeveloperAccessMenuComponent(props: IDevShortcutsProps) {
  const { appSize } = useSizing();

  // selectors
  const hasTask = !!useSelector(RoundSelector.getRoundMaybe)?.task;
  const isSeeSpeak =
    useSelector(TaskSelector.getTaskContentMaybe)?.taskType ===
    TaskTypeName.SeeSpeak;
  const taskPhase = useSelector(TaskSelector.getPhase);
  const shouldShowMicErrorShortcuts =
    useSelector(DevToolsSelector.shouldShowMicErrorShortcuts) && isSeeSpeak;
  const isEnabled = useSelector(
    UniConfigSelector.getConfig<ILoboAppConfig>
  ).isDebug;
  const isInteractive = taskPhase === TaskPhase.Interactive;
  const isPreamble = taskPhase === TaskPhase.Preamble;
  const shouldShowIosShortcuts =
    useSelector(DevToolsSelector.shouldShowIosShortcuts) && hasTask;

  // dispatches
  const dispatch = useDispatch();
  const showDevTools = useCallback(
    () => dispatch(DevToolsAction.show()),
    [dispatch]
  );

  const { simulateAnswer, simulateMicError, skip } = props.devShortcuts;

  const handleSkipPreambleShortcutPress = useCallback(() => skip(), [skip]);

  const handleCorrectShortcutPress = useCallback(
    () => simulateAnswer(TaskEvaluationResult.Correct),
    [simulateAnswer]
  );

  const handleIncorrectShortcutPress = useCallback(
    () => simulateAnswer(TaskEvaluationResult.Incorrect),
    [simulateAnswer]
  );

  const handleTooLoudShortcutPress = useCallback(
    () => simulateMicError(CalibrationResult.TooLoud),
    [simulateMicError]
  );

  const handleTooSoftShortcutPress = useCallback(
    () => simulateMicError(CalibrationResult.TooSoft),
    [simulateMicError]
  );

  const handleNoSignalShortcutPress = useCallback(
    () => simulateMicError(CalibrationResult.NoSignal),
    [simulateMicError]
  );

  const renderMicErrorsShortcutButtons = useCallback(
    (styles: any) => (
      <>
        <PressableView
          style={styles.simulateTooLoud}
          onPress={handleTooLoudShortcutPress}
          blockPointerEvents={!isInteractive}
        >
          <Text style={styles.micErrorText}>Sim Too Loud</Text>
        </PressableView>
        <PressableView
          style={styles.simulateTooSoft}
          onPress={handleTooSoftShortcutPress}
          blockPointerEvents={!isInteractive}
        >
          <Text style={styles.micErrorText}>Sim Too Soft</Text>
        </PressableView>
        <PressableView
          style={styles.simulateNoSignal}
          onPress={handleNoSignalShortcutPress}
          blockPointerEvents={!isInteractive}
        >
          <Text style={styles.micErrorText}>Sim No Signal</Text>
        </PressableView>
      </>
    ),
    [
      handleNoSignalShortcutPress,
      handleTooLoudShortcutPress,
      handleTooSoftShortcutPress,
      isInteractive
    ]
  );

  const renderIosShortcutButtons = useCallback(
    (styles: any) => (
      <>
        <PressableView
          style={styles.skipPreamble}
          onPress={handleSkipPreambleShortcutPress}
          blockPointerEvents={!isPreamble}
        >
          <Text style={styles.text}>Skip Preamble</Text>
        </PressableView>
        <PressableView
          style={styles.simulateCorrect}
          onPress={handleCorrectShortcutPress}
          blockPointerEvents={!isInteractive}
        >
          <Text style={styles.text}>Sim Correct</Text>
        </PressableView>
        <PressableView
          style={styles.simulateIncorrect}
          onPress={handleIncorrectShortcutPress}
          blockPointerEvents={!isInteractive}
        >
          <Text style={styles.text}>Sim Incorrect</Text>
        </PressableView>
      </>
    ),
    [
      handleSkipPreambleShortcutPress,
      handleCorrectShortcutPress,
      handleIncorrectShortcutPress,
      isPreamble,
      isInteractive
    ]
  );

  if (!isEnabled) {
    return null;
  }

  const styles = DeveloperAccessMenuStyles.build(isPreamble, isInteractive);

  return (
    <>
      <PressableView style={styles.devToolsButton} onPress={showDevTools}>
        <Text style={styles.text}>Dev Tools</Text>
      </PressableView>

      {(shouldShowIosShortcuts || shouldShowMicErrorShortcuts) && (
        <Row style={styles.shortcutRow}>
          {shouldShowIosShortcuts && renderIosShortcutButtons(styles)}

          {shouldShowMicErrorShortcuts &&
            renderMicErrorsShortcutButtons(styles)}
        </Row>
      )}
      <DevTools appHeight={appSize.h} />
    </>
  );
}
DeveloperAccessMenuComponent.displayName = 'DeveloperAccessMenu';

export const DeveloperAccessMenu = withDevShortcuts(
  DeveloperAccessMenuComponent
) as any;
