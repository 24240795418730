import { Path, Svg } from 'common-ui';
import { Color } from 'common-styles';

export function CaretSvg(props: { style?: any }) {
  return (
    <Svg
      data-test-id={CaretSvg.displayName}
      width="14"
      height="14"
      style={props.style}
    >
      <Path
        d="M 7,1.4 12.6,12.6 1.4,12.6 z"
        fill={Color.Gray20}
        stroke={Color.Gray20}
        strokeWidth="2.8"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </Svg>
  );
}

CaretSvg.displayName = 'CaretSvg';
