import { ActiveComponentState, Circle, G, Svg } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { KeyNavHelper } from 'common-ui';
import { QuestionMarkIconSvg } from 'common-ui/components/button/glossy-button/svgs/icons';

export interface IQuestionMarkSvgProps {
  accessibilityLabel: string;
  activeComponentState: ActiveComponentState;
  borderColor?: string;
  size?: number;
}

export function QuestionMarkSvg(props: IQuestionMarkSvgProps) {
  const {
    activeComponentState,
    borderColor,
    accessibilityLabel,
    size = 42
  } = props;

  const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
  const isHovered = activeComponentState === ActiveComponentState.Hovered;
  const isPressed = activeComponentState === ActiveComponentState.Pressed;
  const colors = Color.getSetFor(activeComponentState);
  const backgroundColor = isKeyNav
    ? colors.background
    : isHovered
    ? Color.DodgerBlue
    : isPressed
    ? Color.Blue
    : Color.DeepBlue;
  const iconColor = isKeyNav ? colors.border : Color.NearWhite;

  return (
    <Svg
      accessibilityLabel={accessibilityLabel}
      data-test-id={QuestionMarkSvg.displayName}
      height={size}
      viewBox="0 0 42 42"
      width={size}
    >
      <Circle
        cx="20"
        cy="20"
        r="20"
        fill={backgroundColor}
        stroke={isKeyNav ? colors.border : borderColor || backgroundColor}
        strokeWidth={2}
        transform="translate(1 1)"
      />
      <G transform="scale(.678) translate(-15 0)">
        <QuestionMarkIconSvg iconColor={iconColor} />
      </G>
    </Svg>
  );
}
QuestionMarkSvg.displayName = 'QuestionMarkSvg';
