import { SceneControllerBase } from 'services/storm-lobo';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { Scene, SceneElement } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  HomeSceneAnimationName,
  HomeSceneElementName
} from './home-scene.model';

export class HomeScene extends SceneControllerBase {
  public static readonly displayName = 'HomeScene';

  public get characters(): SceneElement[] {
    const characters = [] as SceneElement[];
    this.scene.elementMap.forEach((value, key) => {
      if (key.includes('character_')) {
        characters.push(value);
      }
    });

    return characters;
  }

  constructor(scene: Scene) {
    super(scene, SceneZIndex.Home);
    this.init();
    this.animateCharacterIdles();
  }

  private init() {
    const request: ISceneAnimationRequest = {
      name: HomeSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  private animateCharacterIdles() {
    this.characters.forEach((char, i) => {
      const request: ISceneAnimationRequest = {
        loop: true,
        name: `idle_stance_${char.name}`,
        targetElement: HomeSceneElementName.buildCharacter(i + 1)
      };
      this.scene.startAnimation(request);
    });
  }
}
