import {
  HijabColor,
  HijabColorHexMap,
  mapToHairHijabColor
} from '../hair.model';
import {
  ColorSelector,
  IColorSelectorWrapperProps,
  useHandleAvatarEditorButtonPress
} from '../../shared';
import { HairColor } from 'services/profile/avatar.model';

/**
 * Only hair color choice is saved, and applies to both hair and hijab
 * ie, selecting blond hair color also selects pink hijab color
 * and/or selecting green hijab color also selects darkBrown hair color, etc
 */
export function HijabColorOptions(props: IColorSelectorWrapperProps) {
  const { selectedColor } = props;
  const hijabColor = mapToHairHijabColor<HijabColor>(
    selectedColor as HairColor
  );
  const getHexValue = (color: HijabColor) => HijabColorHexMap.get(color)?.hair;
  const handlePress = useHandleAvatarEditorButtonPress();
  const handleSelect = (hijabColor: HijabColor) =>
    handlePress({ hairColor: mapToHairHijabColor<HairColor>(hijabColor) });

  return (
    <ColorSelector
      accessibilityLabelSuffix="hijab"
      colors={HijabColor}
      getHexValue={getHexValue}
      onSelect={handleSelect}
      selectedColor={hijabColor}
    />
  );
}
HijabColorOptions.displayName = 'HijabColorOptions';
