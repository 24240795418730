import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { first, ignoreElements, mergeMap } from 'rxjs/operators';
import { ISpinnerEventPayload, LoboLogItemCategory } from 'logging';
import { AppShellSelector } from 'feature-areas/shell';
import { ScreenplaySelector } from 'screenplay';
import {
  SpinnerActionHide,
  SpinnerActionShow,
  SpinnerActionType
} from 'spinner-handler';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

/**
 * Log the duration of a spinner display, which indicates a particularly slow
 * process in the app (currently longer than 3s).
 * Note that this epic triggers on the spinner show action, but then waits for
 * a matching hide action so that we can capture the spinner display duration.
 * This means that it will NOT log a spinner that is displayed and then never
 * hidden.
 */
export function logSpinnerDisplayEpic(
  action$: Observable<SpinnerActionShow | SpinnerActionHide>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SpinnerActionType.Show),
    mergeMap(() => action$.pipe(ofType(SpinnerActionType.Hide), first())),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.Spinner),
    ignoreElements()
  );
}
logSpinnerDisplayEpic.displayName = 'logSpinnerDisplayEpic';

function createEvent(
  _: unknown,
  state: AppState
): ILogItemMinimal<ISpinnerEventPayload, LoboLogItemCategory> {
  const screenplay = ScreenplaySelector.getScreenplayMaybe(state);
  const { index: playbackActionIndex, type: playbackActionType } =
    ScreenplaySelector.getCurrentActionInfo(state);
  const durationSeconds = AppShellSelector.getSpinnerDisplaySeconds(state);

  const payload: ISpinnerEventPayload = {
    durationSeconds,
    playbackActionIndex,
    playbackActionType,
    screenplayActionCount: screenplay?.actions.length ?? 0,
    screenplayId: screenplay?.id ?? ''
  };

  return {
    category: LoboLogItemCategory.Spinner,
    loggingLevel: LoggingLevel.Info,
    payload,
    summary: buildSummary(payload)
  };
}

function buildSummary({
  durationSeconds,
  playbackActionIndex,
  playbackActionType,
  screenplayId
}: ISpinnerEventPayload): string {
  const durationMessage = `${Math.round(durationSeconds)}s spinner`;

  return screenplayId
    ? `${durationMessage} while playing "${playbackActionType}" action (index ${playbackActionIndex}) of screenplay "${screenplayId}"`
    : `${durationMessage} (no active screenplay)`;
}

export const LogSpinnerDisplayPrivates = { buildSummary };
