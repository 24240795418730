import { Border } from '@lexialearning/common-ui';
import { CalibrationSharedStyles } from '../CalibrationShared.styles';
import { Color, Justify } from 'common-styles';

export class MicSelectionStyles {
  private static readonly styles = {
    buttonsContainer: {
      justifyContent: Justify.SpaceBetween,
      width: 380
    },
    title: {
      ...CalibrationSharedStyles.get().title,
      marginBottom: 40
    },
    vuMeterContainer: {
      borderColor: Color.Gray10,
      borderRadius: 52,
      borderStyle: Border.Solid,
      borderWidth: 1,
      height: 99,
      marginBottom: 55,
      marginTop: 50,
      width: 299
    }
  };

  public static get() {
    return this.styles;
  }
}
