import { IOrderingAnswer } from '../ordering.model';

export function serializeOrderingAnswer(answer: IOrderingAnswer) {
  if (!answer.segments) {
    return 'no answer provided';
  }

  return answer.segments
    ?.map(segment => `${segment.voiceover.id}:${segment.text}`)
    .join('|');
}
