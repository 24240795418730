import { createSelector } from '@reduxjs/toolkit';
import { ISsoLink } from 'lexia-service/customer-api/customer-api-private.model';
import { ICustomerState } from './customer-redux.model';

export class CustomerSelector {
  public static readonly displayName = 'CustomerSelector';

  public static getCustomerName: (state: unknown) => string;

  public static getCustomerCode: (state: unknown) => string;

  public static getHasSso: (state: unknown) => boolean;

  public static getSsoLink: (state: unknown) => ISsoLink | undefined;

  public static hasInvalidEmail: (state: unknown) => boolean;

  public static isSiteIdParsed: (state: unknown) => boolean;

  public static isDeviceSetupCompleted: (state: unknown) => boolean;

  public static isDeviceSetupWithCustomerCodeAttempted: (
    state: unknown
  ) => boolean;

  public static createSelectors(selector: (state: any) => ICustomerState) {
    this.getCustomerName = createSelector(
      selector,
      (state: ICustomerState) => state.customer.customerName
    );

    this.getCustomerCode = createSelector(
      selector,
      (state: ICustomerState) => state.customer.customerCode
    );

    this.getHasSso = createSelector(
      selector,
      (state: ICustomerState) => !!state.customer.ssoLink?.href
    );

    this.getSsoLink = createSelector(
      selector,
      (state: ICustomerState) => state.customer.ssoLink
    );

    this.hasInvalidEmail = createSelector(
      selector,
      (state: ICustomerState) => state.deviceSetup.invalidEmail
    );
    this.isDeviceSetupCompleted = createSelector(
      selector,
      (state: ICustomerState) => state.deviceSetup.deviceSetupCompleted
    );

    this.isSiteIdParsed = createSelector(
      selector,
      (state: ICustomerState) => state.deviceSetup.siteIdParsed
    );

    this.isDeviceSetupWithCustomerCodeAttempted = createSelector(
      selector,
      (state: ICustomerState) =>
        state.deviceSetup.attemptedToSetupDeviceWithCustomerCode
    );
  }
}
