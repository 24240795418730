import { IRound, IScreenplay, TaskTypeName } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import {
  ActivityPositionAction,
  PositionChangeType,
  ProgramContextService,
  ProgramMode,
  RoundContext
} from 'curriculum-services';
import {
  EncounterSceneAnimationName,
  EncounterSceneElementName
} from 'feature-areas/encounters';
import {
  ControlPanelComponent,
  FadeAnimationType
} from 'feature-areas/shell/control-panel/ControlPanel';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { RoundAction, RoundIntroType } from '../../builders/rounds';
import { TransitionScreenplayBuilderBase } from '../../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

interface IRoundToRoundTransitionDeps {
  roundContext: RoundContext;
  preparedScenes: PreparedScenes;
  state$: StateObservable<unknown>;
  programContextService: ProgramContextService;
  roundIntroType: RoundIntroType;
  changeType?: PositionChangeType;
}

export class RoundToRoundTransitionFactory extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundToRoundTransitionBuilder';

  public static create(
    state$: StateObservable<unknown>,
    roundToNextDeps: IRoundToNextTransitionDeps,
    roundContext: RoundContext,
    roundIntroType: RoundIntroType,
    changeType?: PositionChangeType
  ): IScreenplay {
    const { curriculumDependencies, preparedScenes } = roundToNextDeps;
    const { programContextService } = curriculumDependencies;

    return new RoundToRoundTransitionFactory({
      changeType,
      preparedScenes,
      programContextService,
      roundContext,
      roundIntroType,
      state$
    }).screenplay;
  }

  private constructor(private readonly deps: IRoundToRoundTransitionDeps) {
    super(TransitionScreenplayId.RoundToRound);
    const { programContextService, state$ } = this.deps;

    this.detachCharacterMaybe()
      .hideControlPanelMaybe()
      .brightenAnimationMaybe()
      .changeActivityPosition()
      .awaitContentLoaded(programContextService, state$)
      .navToNextRound()
      .dispatchRoundIntro();
  }

  /**
   * This needs to occur with the task exit react animations so the task content and the character disappear together
   * The react animations are currently coming from the task exit screenplay, though there is a TODO (LOBO-13442)
   * to rework the task exit screenplay to better combine directly with these RoundToNext screenplays
   *
   * Note: Due to the current limitation of the nextRound getter, this will always detach character for all changeTypes
   * other than RoundCompletion, if coming from a round with hasOnscreenCharacter === true
   * Only for RoundCompletion, currently, is it able to check the allowOnscreenCharacter value of the upcoming
   * round to potentially detach the character based on that value
   * (This works fine with how the content is currently set up for the app, but may need to be updated in the future
   *  eg, if an Instruction round could have a character, or if a Forked/Joined unit might not have a character - but
   *  be forked/joined to a unit that does)
   */
  private detachCharacterMaybe(): RoundToRoundTransitionFactory {
    const { preparedScenes } = this.deps;

    if (this.shouldDetachCharacter()) {
      this.builder.addCallback(() => {
        preparedScenes.encounterOrPlacement?.character?.detach();
      });
    }

    return this;
  }

  /*
   * Will return false if:
   * - current round hasOnscreenCharacter === false
   * - is a Fork changeType
   * - is NOT a StepDown changeType and next round allowOnscreenCharacter === false
   *
   * Limitations:
   * - if Instruction rounds ever should have a character (unlikely) this would not handle that
   * - if the nextRound is a Forked unit round with allowOnscreenCharacter false, and current round has hasOnscreenCharacter true,
   *   this would not handle that (not currently done, but more feasible than the above) - in order to fix this, we would need a
   *   way to get subunit info before navigating to it
   */
  private shouldDetachCharacter(): boolean {
    const { roundContext } = this.deps;
    const {
      hasOnscreenCharacter,
      imminentPosition: { changeType }
    } = roundContext;

    if (changeType === PositionChangeType.StepDown) {
      return true;
    }

    if (
      !hasOnscreenCharacter ||
      this.nextRound?.task?.allowOnscreenCharacter ||
      // there is currently no way to access nextRound info for a Forked round, so we just assume it should
      // have the same hasOnscreenCharacter value as the current round
      changeType === PositionChangeType.Fork
    ) {
      return false;
    }

    return true;
  }

  /**
   * Currently only returns nextRound for RoundCompletion, Join, StepUp and UnitRecycling change types
   * For Fork and StepDown we do not yet have a way to access the nextRound info here
   */
  private get nextRound(): IRound | undefined {
    const { roundContext } = this.deps;
    const {
      imminentPosition: { changeType },
      imminentParentUnitPosition: nextPosition,
      mainUnit,
      parentUnit
    } = roundContext;
    if (
      [PositionChangeType.StepDown, PositionChangeType.Fork].includes(
        changeType
      )
    ) {
      return;
    }

    const { unitId: nextUnitId, roundId: nextRoundId } = nextPosition;
    const unit = [mainUnit, parentUnit].find(u => u.sysId === nextUnitId);
    const nextRound = unit?.rounds.find(r => r.sysId === nextRoundId)!;

    return nextRound;
  }

  public hideControlPanelMaybe(): RoundToRoundTransitionFactory {
    if (this.isFirstNonSeeSpeakOnboardingRound()) {
      this.builder.addReactAnimation(
        ControlPanelComponent.getAnimationName(FadeAnimationType.FadeOut)
      );
    }

    return this;
  }

  private isFirstNonSeeSpeakOnboardingRound() {
    const isOnboarding =
      this.deps.roundContext.programMode === ProgramMode.Onboarding;

    if (!isOnboarding) {
      return false;
    }

    const isSeeSpeak =
      this.deps.roundContext.round.task!.taskType === TaskTypeName.SeeSpeak;
    const willBeSeeSpeak =
      this.deps.roundContext.roundNode.next!.content.task?.taskType ===
      TaskTypeName.SeeSpeak;

    return isSeeSpeak && !willBeSeeSpeak;
  }

  private brightenAnimationMaybe(): RoundToRoundTransitionFactory {
    const { changeType } = this.deps;
    const isStepUp = changeType === PositionChangeType.StepUp;

    if (!isStepUp) {
      return this;
    }

    this.builder.addStormAnimation({
      name: EncounterSceneAnimationName.Background.Brighten,
      targetElement: EncounterSceneElementName.Background,
      targetScene: SceneName.Encounter
    });

    return this;
  }

  private changeActivityPosition(): RoundToRoundTransitionFactory {
    this.builder.addReduxAction(ActivityPositionAction.change());

    return this;
  }

  private navToNextRound(): RoundToRoundTransitionFactory {
    const { roundContext } = this.deps;
    const { programMode, imminentParentUnitPosition: nextPosition } =
      roundContext;
    const { roundId, unitId } = nextPosition;
    const uri = RouteBuilder.modeSpecificRound(programMode, unitId, roundId);

    this.builder.addCallback(() => {
      RouterService.history.replace(uri);
    });

    return this;
  }

  // Adding this as last action, rather than nextAction, as it would be a weird state for
  // the screenplay to be skipped, with the given actions that set up the next round
  // and yet still play this action at that point
  // eg, https://jira.lexialearning.com/browse/LOBO-13946
  private dispatchRoundIntro(): RoundToRoundTransitionFactory {
    const { roundIntroType } = this.deps;

    this.builder.addReduxAction(RoundAction.intro({ type: roundIntroType }));

    return this;
  }
}
