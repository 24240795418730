import * as React from 'react';
import { ModalRegistry, ModalType } from 'shared-components/modals';
import { NotConnectedSvg } from './NotConnected.lx-svg';

export class NotConnectedModal extends React.PureComponent {
  public static readonly displayName = 'NotConnectedModal';

  public static readonly ModalId = ModalRegistry.register({
    Component: NotConnectedModal,
    getTeacherVO: true,
    headline: 'Your device is not connected to the Internet.',
    text: 'Ask your teacher for help.',
    title: 'Oops!',
    type: ModalType.Error
  });

  // istanbul ignore next - trivial
  // using SVG instead of PNG to keep it in the bundle, otherwise it would fail
  // to fetch it when there's no network
  public render() {
    return <NotConnectedSvg />;
  }
}
