import { SceneControllerBase, SceneZIndex } from 'services/storm-lobo';
import { Scene } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import { IntroductionSceneAnimationName } from './introduction-scene.model';

export class IntroductionScene extends SceneControllerBase {
  public static readonly displayName = 'IntroductionScene';

  public constructor(scene: Scene) {
    super(scene, SceneZIndex.Introduction);
    this.init();
  }

  private init() {
    const request: ISceneAnimationRequest = {
      name: IntroductionSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 100
    };
    this.scene.startAnimation(request);
  }
}
