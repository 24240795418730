import { useDispatch } from 'react-redux';
import {
  IPressScaleButtonProps,
  PressScaleButton,
  Text,
  View
} from 'common-ui';
import { ILevelStatus, LevelStatus } from 'curriculum-services';
import { AppShellAction } from 'feature-areas/shell';
import { LevelCertificateCarouselModal } from '../level-certificate-carousel-modal/LevelCertificateCarouselModal';
import { LevelCertificateButtonStyles } from './LevelCertificateButton.styles';
import { useCallback } from 'react';
import { useActiveComponentState } from '@lexialearning/common-ui';

export interface ILevelCertificateButtonProps
  extends Omit<IPressScaleButtonProps, 'onPress'> {
  levelStatus: ILevelStatus;
}

export function LevelCertificateButton(props: ILevelCertificateButtonProps) {
  const {
    levelStatus: { status, levelNumber, certificateViewed },
    ...restProps
  } = props;

  const dispatch = useDispatch();

  const handlePress = useCallback(() => {
    dispatch(
      AppShellAction.showModal({
        id: LevelCertificateCarouselModal.ModalId,
        props: {
          levelNumber
        }
      })
    );
  }, [dispatch, levelNumber]);

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = LevelCertificateButtonStyles.build(
    status,
    activeComponentState
  );
  const buttonTestId = `Level${levelNumber}CertificateButton`;
  const isCompleted = status === LevelStatus.Completed;
  const showNotification = isCompleted && !certificateViewed;

  return (
    <PressScaleButton
      {...restProps}
      {...interactionHandlers}
      disabled={!isCompleted}
      style={styles.button}
      testId={buttonTestId}
      onPress={handlePress}
    >
      <Text style={styles.buttonText}>{levelNumber}</Text>
      {showNotification && (
        <View
          style={styles.levelCertNotification}
          testId="LevelCertNotification"
        />
      )}
    </PressScaleButton>
  );
}
LevelCertificateButton.displayName = 'LevelCertificateButton';
