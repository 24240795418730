import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { ContentProviderFactory } from '@lexialearning/lobo-common/cms';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  PlacementAction,
  PlacementActionLoadForDeepLink,
  PlacementActionLoadSuccess,
  PlacementActionType,
  ProgramContextSelector
} from 'curriculum-services';
import { PlacementTransformer } from 'curriculum-services/placement/epics/PlacementTransformer';
import { GradeName } from 'lexia-service';
import { PlacementContentLoader } from './PlacementContentLoader';

interface ILoadPlacementContentForDeepLinkDeps {
  configProvider: IConfigProvider;
  contentProviderFactory: ContentProviderFactory;
}

/**
 * Loads placement content for a deep link. This is essential when using the CMS
 * content strategy as the full content is too big to load in one go straight
 * from the CMS.
 */
export function loadPlacementContentForDeepLinkEpic(
  action$: Observable<PlacementActionLoadForDeepLink>,
  state$: StateObservable<unknown>,
  deps: ILoadPlacementContentForDeepLinkDeps
): Observable<PlacementActionLoadSuccess> {
  return action$.pipe(
    ofType(PlacementActionType.LoadForDeepLink),
    mergeMap(async () => {
      const { configProvider, contentProviderFactory } = deps;
      const contentProvider = contentProviderFactory.create();
      const loader = new PlacementContentLoader(
        configProvider,
        contentProvider
      );
      const placementWithPool = await loader.load();
      const { activeActivityId } = ProgramContextSelector.getPosition(
        state$.value
      );
      const gradeName = placementWithPool.gradeFormPoolMap.k2.find(
        form => form.sysId === activeActivityId
      )
        ? GradeName.K
        : GradeName.G3;

      const placement = PlacementTransformer.transform(
        gradeName,
        placementWithPool
      )!;

      return PlacementAction.load.success(placement);
    })
  );
}
loadPlacementContentForDeepLinkEpic.displayName =
  'loadPlacementContentForDeepLinkEpic';
