import { Color } from 'common-styles';
import { Line, Svg } from 'common-ui';

export function DividingLineSvg() {
  return (
    <Svg width={4} height={290} viewBox="0 0 4 290" style={{ marginRight: 15 }}>
      <Line
        id="Dividing_line"
        y2="290"
        transform="translate(2)"
        fill="none"
        stroke={Color.NearWhite}
        strokeWidth="4"
        strokeDasharray="10"
      />
    </Svg>
  );
}
DividingLineSvg.displayName = 'DividingLineSvg';
