import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ILevelState, LevelActionType } from './level-redux.model';
import { LevelActions } from './Level.action';
import {
  levelVoiceoversInitialState,
  levelVoiceoversStateReducer
} from './levelVoiceoversState.reducer';

const levelInitialState: ILevelState = {
  isLoading: false,
  isReady: false,
  levelContent: undefined,
  voiceovers: levelVoiceoversInitialState
};

export function levelReducer(
  state = levelInitialState,
  action: LevelActions | UserGlobalActionLogoutSuccess
): ILevelState {
  switch (action.type) {
    case LevelActionType.Load:
      return { ...state, isLoading: true, isReady: false };

    case LevelActionType.LoadSuccess:
      return { ...state, isLoading: false, isReady: false, ...action.payload };

    case LevelActionType.Ready:
      return { ...state, isReady: true };

    case LevelActionType.UnloadSuccess:
    case UserGlobalActionType.LogoutSuccess:
      return levelInitialState;

    default:
      const voiceovers = levelVoiceoversStateReducer(state.voiceovers, action);

      return voiceovers === state.voiceovers ? state : { ...state, voiceovers };
  }
}
levelReducer.displayName = 'levelReducer';

export const LevelReducerPrivates = { initialState: levelInitialState };
