import { SceneControllerBase, SceneZIndex } from 'services/storm-lobo';
import { IApplyTextureOptions, Scene, StormService } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import {
  EncounterCompleteSceneAnimationName,
  EncounterCompleteSceneMaterialName
} from './encounter-complete-scene.model';

export class EncounterCompleteScene extends SceneControllerBase {
  public static readonly displayName = 'EncounterCompleteScene';

  public constructor(
    scene: Scene,
    private readonly stormService: StormService,
    private readonly position: string
  ) {
    super(scene, SceneZIndex.EncounterComplete);
    this.init();
    this.prepareTexture();
  }

  private init(): void {
    const request: ISceneAnimationRequest = {
      name: EncounterCompleteSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }

  private prepareTexture(): void {
    const options: Partial<IApplyTextureOptions> = {
      materialName: EncounterCompleteSceneMaterialName.PaperBack
    };
    const path = `/scenes/images/encounter-complete/${this.position}_complete.tx`;
    const texture = this.stormService.loadTexture(path);
    this.scene.applyTexture(texture, options);
  }
}
