import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { AppCrasher } from 'services/error-handler/app-crasher/AppCrasher';
import { StormService } from 'storm';
import { AuthActionReloadApp, AuthActionType } from '../redux';

export interface IReloadAppDeps {
  stormService: StormService;
}
export function reloadApplicationEpic(
  action$: Observable<AuthActionReloadApp>,
  _state$: StateObservable<unknown>,
  _deps: IReloadAppDeps
): Observable<void> {
  return action$.pipe(
    ofType(AuthActionType.ReloadApp),
    tap(() => {
      // Reloads the app: refreshes the web page or restarts the React Native JS Bundle
      AppCrasher.reload();
    }),
    ignoreElements()
  );
}
reloadApplicationEpic.displayName = 'reloadApplicationEpic';
