import { SsoButtonStyles } from './SsoButton.styles';
import { CleverButtonSvg } from './CleverButton.lx-svg';
import { PressScaleButton } from 'common-ui';
import { SsoButtonSvg } from './SsoButton.lx-svg';
import { GoogleSsoButtonSvg } from './GoogleSsoButton.lx-svg';
import {
  useActiveComponentState,
  ActiveComponentState
} from '@lexialearning/common-ui';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';
import { SsoProvider } from 'lexia-service/customer-api/customer-api-private.model';
import { Services } from 'services';
import { LoboLogItemCategory } from '@lexialearning/lobo-common/main-model/logging';
import { LoggingLevel } from '@lexialearning/main-model';

export interface ISsoButtonProps extends IButtonBaseProps {
  provider?: SsoProvider;
  href: string;
}

export interface ISvgButtonProps extends ISsoButtonProps {
  activeComponentState: ActiveComponentState;
}

const ButtonSvg = (props: ISvgButtonProps) => {
  const { provider, href, activeComponentState } = props;

  switch (provider) {
    case SsoProvider.Clever:
      return <CleverButtonSvg activeComponentState={activeComponentState} />;
    case SsoProvider.Google:
      return <GoogleSsoButtonSvg activeComponentState={activeComponentState} />;
    case SsoProvider.Saml:
      return <SsoButtonSvg activeComponentState={activeComponentState} />;
    default:
      logDefaultCase(href, provider);

      return <SsoButtonSvg activeComponentState={activeComponentState} />;
  }
};

const logDefaultCase = (href, provider): void => {
  void Services.logger?.log({
    category: LoboLogItemCategory.SsoButtonEmptyOrInvalidProvider,
    loggingLevel: LoggingLevel.Verbose,
    payload: {
      href,
      provider
    },
    summary: "In 'SsoButton', href is present but valid provider is not."
  });
};

export function SsoButton(props: ISsoButtonProps) {
  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const { disabled, onPress, href, provider, ...restProps } = props;
  const styles = SsoButtonStyles.get();

  return (
    <PressScaleButton
      {...restProps}
      {...interactionHandlers}
      hoverScale={1.02}
      pressScale={0.98}
      style={styles.button}
      disabled={disabled}
      testId="ssoLoginButton"
      onPress={onPress}
    >
      <ButtonSvg {...{ activeComponentState, href, provider }} />
    </PressScaleButton>
  );
}
SsoButton.displayName = 'SsoButton';
