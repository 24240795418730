import { BrowserName } from '@lexialearning/main-model';
import {
  IPlayerArgsTypes,
  IVideoPlayerModifiedProps,
  TimeoutDurations
} from './video.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorTimeoutInfo: Record<PropertyKey, any> = {
  errorTimeout: 0,
  errorTimeoutActive: false
};

export const playerTimeInfo: Record<PropertyKey, number | undefined> = {
  timeStamp: 0
};

interface ISetupPlayerProps
  extends Omit<
    IVideoPlayerModifiedProps,
    'source' | 'videoConfig' | 'triggerVideoControls' | 'videoRole'
  > {
  source?: string;
}

export class VideoHelper {
  /**
   *  called when video is paused by the user
   */
  public static handlePause = (
    event: IPlayerArgsTypes['event'],
    player: IPlayerArgsTypes['player'],
    triggerVideoControls: IPlayerArgsTypes['triggerVideoControls'],
    poster?: string
  ): void => {
    // fires when app pushed into the background
    triggerVideoControls?.(event?.type);

    playerTimeInfo.timeStamp = poster ? player.current?.currentTime() : 1;
    player.current?.reset();
  };

  /**
   * clears any active timeouts set to show errors after our designated timeout time
   */
  public static clearErrorTimeout = (): void => {
    // if it isn't active, don't do anything
    if (!errorTimeoutInfo.errorTimeoutActive) {
      return;
    }

    clearTimeout(errorTimeoutInfo.errorTimeout);
    errorTimeoutInfo.errorTimeoutActive = false;
  };

  /**
   * clears any running timeouts and resets the timeout for playback error
   */
  public static resetErrorTimeout = (): void => {
    // if we already have a timer going, don't interfere
    if (!errorTimeoutInfo.errorTimeoutActive) {
      this.clearErrorTimeout();

      errorTimeoutInfo.errorTimeout = setTimeout(
        // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
        () => {},
        TimeoutDurations.ErrorMs
      );

      errorTimeoutInfo.errorTimeoutActive = true;
    }
  };

  /**
   *  This sets all config for the player instantiated by video.js
   */
  // This an internal callback called by video.js, ignoring in testing here
  /* istanbul ignore next */
  public static setupPlayer = (
    playerProps: ISetupPlayerProps,
    player: IPlayerArgsTypes['player']
  ): void => {
    const {
      checkBrowser,
      checkForWifi,
      deferConfig,
      loadingSpinner,
      onCanPlayThrough,
      onClick,
      onEnded,
      onError,
      onTimeUpdate,
      resetPauseFlag,
      seekable,
      setHlsCanPlay
    } = playerProps;

    if (!player.current) {
      return;
    }

    const browser = checkBrowser ? checkBrowser(window) : undefined;

    // make sure on setup, pauseFlag is false
    resetPauseFlag?.();

    // video.js only supports a single poster at the beginning that has to be set
    // at initialization.  media task has start and intro (end poster was removed in 3.5).  video.js styles also hide
    // the poster after the video starts playing, so there is a style override using our

    let supposedCurrentTime: number | undefined = 0;

    // map events
    player.current.on('ready', () => {
      player.current.off('dblclick');
    });

    player.current.on('loadstart', () => {
      setHlsCanPlay?.(player.current);
    });

    player.current.on('canplaythrough', () => {
      onCanPlayThrough?.();
    });

    player.current.on('timeupdate', () => {
      if (!player.current.seeking()) {
        supposedCurrentTime = player.current.currentTime();
      }
      onTimeUpdate?.();
    });

    player.current.on('pause', () => {
      resetPauseFlag?.();
    });

    // prevent Macbook Pro touch bar scrolling
    player.current.on('seeking', () => {
      if (seekable) {
        return;
      }
      const delta =
        player.current && supposedCurrentTime
          ? player.current.currentTime() - supposedCurrentTime
          : 0;
      if (Math.abs(delta) > 0.01 && supposedCurrentTime) {
        player.current.currentTime(supposedCurrentTime);
      }
    });

    player.current.on('click', () => {
      onClick?.();
    });

    player.current.on('ended', () => {
      onEnded?.();
    });

    player.current.on('error', () => {
      checkForWifi?.();
      onError?.();
    });

    let tsRetries = 0;

    // if ts files fail to load, video.js does not mount listeners
    // use this listener to listen to retries and show error if retries
    // amount to 7, which equates to about 20s – our error timeout
    // eslint-disable-next-line no-underscore-dangle
    player.current.tech_.on('retryplaylist', () => {
      // eslint-disable-next-line no-plusplus
      tsRetries++;
      if (tsRetries === 7) {
        // couldn't see a way to get track/ts file information here, so just logging time to help pinpoint failure
        // eslint-disable-next-line no-console
        console.error(
          'cannot load current ts file, current video time is:',
          player.current.currentTime()
        );
        onError?.();
      }
    });

    // the XHR object is not accessible native Safari and Edge
    if (browser !== BrowserName.Edge && browser !== BrowserName.Safari) {
      // the video.js hls library does not expose the xhr object as the documentation suggests
      // shim a timeout here to handle cases where the initial playlist is stuck on infinite load state
      this.resetErrorTimeout();

      // successfully captures the hls tech so that we can listen to the XHR requests
      // if the videojs-contrib-hls plugin exposes events in a future update, this should be cleaned
      player.current.on('loadedmetadata', () => {
        // part of the infinite loading state timeout shim
        this.clearErrorTimeout();

        const vhs = player.current.tech({
          IWillNotUseThisInPlugins: true
        }).vhs;

        vhs.xhr.onRequest = (options: Record<string, unknown>) => {
          // eslint-disable-next-line no-param-reassign
          options.beforeSend = (xhrObject: Record<string, unknown>) => {
            // if we have a successful load, clear the timeout as our
            // network conditions have improved
            // eslint-disable-next-line no-param-reassign
            xhrObject.onload = () => {
              this.clearErrorTimeout();
              tsRetries = 0;
            };

            // a segment failed to load, so begin a timeout
            // the network might be down temporarily but if it lasts too long,
            // we want to throw an error
            // eslint-disable-next-line no-param-reassign
            xhrObject.onerror = () => {
              this.resetErrorTimeout();
            };
          };
        };
      });
    }

    // if the source m3u8 cannot be loaded for any reason, we want
    // to handle the error.  we provide the source here instead of
    // in the videoConfig object so that the failure occurs AFTER
    // we've attached our error listener
    if (deferConfig) {
      player.current.src(deferConfig.sources);
    }

    // eslint-disable-next-line no-param-reassign
    player.current.resizeManager = false;

    // eslint-disable-next-line no-param-reassign
    player.current.loadingSpinner = loadingSpinner;
  };
}
