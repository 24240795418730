import {
  IAct,
  IActivityPosition,
  IEncounter
} from '@lexialearning/lobo-common';
import { cloneDeep } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { LevelSceneElementName } from 'feature-areas/levels';
import { SceneName } from 'services/storm-lobo';
import {
  AllottedFunFactPlaybacks,
  IActUiState,
  IFunFactData
} from './act-ui-redux.model';
import {
  ProgramContextSelector,
  ActSelector,
  EncounterSelector
} from 'curriculum-services';

export class ActUiSelector {
  public static readonly displayName = 'ActUiSelector';

  private static readonly funFactsUnavailable = {
    funFact: undefined,
    playbacksRemaining: 0
  };

  private static getState: (state: unknown) => IActUiState;

  public static getPlayedFunFactIds: (state: unknown) => string[];

  public static getAvailableFunFactData: (state: unknown) => IFunFactData;

  public static createSelectors(selector: (state: any) => IActUiState): void {
    ActUiSelector.getState = createSelector(
      selector,
      (state: IActUiState) => state
    );

    ActUiSelector.getPlayedFunFactIds = createSelector(
      ActUiSelector.getState,
      (state: IActUiState) => state.playedFunFactIds
    );

    ActUiSelector.getAvailableFunFactData = createSelector(
      ActUiSelector.getPlayedFunFactIds,
      ProgramContextSelector.getActivityPositions,
      ActSelector.getActMaybe,
      EncounterSelector.getEncounterMaybe,
      ActUiSelector.checkForAvailableFunFact
    );
  }

  private static checkForAvailableFunFact(
    playedFunFactIds: string[],
    actPositions: IActivityPosition[],
    act: IAct | undefined,
    encounter: IEncounter | undefined
  ): IFunFactData {
    if (!act || !encounter) {
      return ActUiSelector.funFactsUnavailable;
    }

    const isActComplete = actPositions.find(p => p.activityId === act.sysId)
      ?.isComplete;
    const currentEncounterIndex = act.encounters.findIndex(
      e => e.sysId === encounter.sysId
    );

    if (isActComplete || currentEncounterIndex === 0) {
      return ActUiSelector.funFactsUnavailable;
    }

    const funFact = cloneDeep(act.characterFunFacts[currentEncounterIndex - 1]);
    if (!funFact) {
      return ActUiSelector.funFactsUnavailable;
    }

    const playbacksRemaining =
      AllottedFunFactPlaybacks -
      playedFunFactIds.filter(id => id === funFact.id).length;

    if (playbacksRemaining === 0) {
      return ActUiSelector.funFactsUnavailable;
    }

    // Add speaker to fun fact voiceovers
    const selectedActNumber =
      actPositions
        .filter(ap => !ap.isComplete)
        .findIndex(ap => ap.activityId === act.sysId) + 1;
    funFact.actions.forEach(a => {
      a.data = {
        ...a.data,
        speaker: {
          sceneId: SceneName.Level,
          speakerId: LevelSceneElementName.buildCharacter(selectedActNumber)
        }
      };
    });

    return {
      funFact,
      playbacksRemaining
    };
  }
}
