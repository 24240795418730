import { bootstrapEpic } from './bootstrap.epic';
import { bootstrapOrchestrationEpic } from './bootstrapOrchestration.epic';
import { checkNetworkStateEpic } from './checkNetworkState.epic';
import { loadBootstrapContentEpic } from './loadBootstrapContent.epic';

export const bootstrappingEpics = [
  bootstrapEpic,
  bootstrapOrchestrationEpic,
  checkNetworkStateEpic,
  loadBootstrapContentEpic
];
