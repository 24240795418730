export class CalibrationSharedStyles {
  public static styles = {
    title: {
      fontSize: 30,
      lineHeight: 41
    }
  };

  public static get() {
    return this.styles;
  }
}
