import { IPlacementState, PlacementActionType } from './placement-redux.model';
import { PlacementActions } from './Placement.action';

export function placementReducer(
  state = buildPlacementInitialState(),
  action: PlacementActions
): IPlacementState {
  switch (action.type) {
    case PlacementActionType.Load:
      return { ...state, isLoading: true };

    case PlacementActionType.LoadSuccess:
      return {
        hasPlayedLookbackIntro: false,
        isLoading: false,
        placementContent: action.payload
      };

    case PlacementActionType.UpdateHasPlayedLookbackIntro:
      return { ...state, hasPlayedLookbackIntro: true };

    case PlacementActionType.Unloaded:
      return buildPlacementInitialState();

    default:
      return state;
  }
}
placementReducer.displayName = 'placementReducer';

function buildPlacementInitialState(): IPlacementState {
  return {
    hasPlayedLookbackIntro: false,
    isLoading: false,
    placementContent: undefined
  };
}

export const PlacementReducerPrivates = { buildPlacementInitialState };
