import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { micReducer as mic } from 'common-ui/components/button/mic-button/redux/mic.reducer';
import { commonUiReducer as commonUi } from 'common-ui/redux/CommonUi.reducer';
import { levelsCompletedReducer as levelsCompleted } from 'curriculum-services/levels-completed/redux/levelsCompleted.reducer';
import { placementReducer as placement } from 'curriculum-services/placement/redux/placement.reducer';
import { dndReducer as dnd } from 'dnd/redux/dnd.reducer';
import { actUiReducer as actUi } from 'feature-areas/acts/redux/actUi.reducer';
import { calibrationUiReducer as calibrationUi } from 'feature-areas/calibration/redux/calibrationUi.reducer';
import { devToolsReducer as devTools } from 'feature-areas/dev-tools/redux/devTools.reducer';
import { educatorReducer as educator } from 'feature-areas/educator/redux/educator.reducer';
import { avatarEditorReducer as avatarEditor } from 'feature-areas/avatar-editor/redux';
import { lexiaServiceReducer as lexiaService } from 'lexia-service/redux/lexiaService.reducer';
import { ScreenplayReduxProvider } from 'screenplay/redux/ScreenplayReduxProvider';
import {
  levelReducer as level,
  programContextReducer as programContext,
  unitReducer as unit
} from 'services/curriculum-services/curriculum-reducers';
import { appShellReducer as shell } from 'shell/redux/appShell.reducer';
import { sreReducer as sre } from 'sre/redux/sre.reducer';
import { stormReducer as storm } from 'storm/redux/storm.reducer';
import { taskReducer as task } from 'task-components/core/redux/task.reducer';
import { seeSpeakReducer as seeSpeak } from 'task-components/see-speak/redux/seeSpeak.reducer';
import { themeReducer as theme } from 'theme';
import { authReducer as auth } from '../../auth/redux/auth.reducer';
import { customerReducer as customer } from '../../customer/redux/customer.reducer';
import { profileReducer as profile } from '../../profile/redux/profile.reducer';
import { bootstrapContentReducer as bootstrapContent } from '../bootstrap-content/bootstrapContent.reducer';
import { bootstrappingReducer as bootstrapping } from './bootstrapping.reducer';
import { IUtilsReactState } from '@lexialearning/utils-react/lib/bootstrapping/utils-react-redux.model';

const screenplay = ScreenplayReduxProvider.reducer;

export const loboReducers = {
  actUi,
  auth,
  avatarEditor,
  bootstrapContent,
  bootstrapping,
  calibrationUi,
  commonUi,
  customer,
  devTools,
  dnd,
  educator,
  level,
  levelsCompleted,
  lexiaService,
  mic,
  placement,
  profile,
  programContext,
  screenplay,
  seeSpeak,
  shell,
  sre,
  storm,
  task,
  theme,
  unit
};

export const rootReducer = combineReducers(loboReducers);

export type AppState = StateType<typeof rootReducer> & IUtilsReactState;
