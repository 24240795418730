import { ILevelEducator } from '@lexialearning/lobo-common';
import { sortBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { IProgramPosition, ProgramContextSelector } from 'curriculum-services';
import { IEducatorState } from './educator-redux.model';
import { EducatorContext } from './EducatorContext';

export class EducatorSelector {
  public static readonly displayName = 'EducatorSelector';

  public static getContext: (state: unknown) => EducatorContext;

  public static getLevels: (state: unknown) => ILevelEducator[];

  public static createSelectors(selector: (state: any) => IEducatorState) {
    this.getContext = createSelector(
      state => this.getLevels(state),
      state => ProgramContextSelector.getPosition(state),
      (levels: ILevelEducator[], position: IProgramPosition) =>
        new EducatorContext(levels, position)
    );

    this.getLevels = createSelector(selector, (state: IEducatorState) =>
      sortBy(state.levels, 'title')
    );
  }
}
