import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from '../../types';

const defaultShowDuration = 300;
const defaultHideDuration = 150;

interface IPopFadeAnimationStyleProps {
  duration?: number;
  showDuration?: number;
  hideDuration?: number;
  scale?: number;
  scaleX?: number;
  scaleY?: number;
}

function resolveMaxScale(props: IPopFadeAnimationStyleProps) {
  const { scale, scaleX, scaleY } = props;

  const maxScaleX = scaleX || scale || 1;
  const maxScaleY = scaleY || scale || 1;

  return {
    maxScaleX,
    maxScaleY
  };
}

function resolveDurations(props: IPopFadeAnimationStyleProps) {
  const { duration, showDuration, hideDuration } = props;
  const resolvedShowDuration = showDuration || duration || defaultShowDuration;
  const resolvedHideDuration = hideDuration || duration || defaultHideDuration;

  return {
    hideDuration: resolvedHideDuration,
    showDuration: resolvedShowDuration
  };
}

export interface IPopFadeAnimations {
  show: Types.Animated.CompositeAnimation;
  hide: Types.Animated.CompositeAnimation;
}

export class PopFadeAnimationAnimatedStyles {
  private readonly animations: IPopFadeAnimations;

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
  };

  constructor(props: IPopFadeAnimationStyleProps) {
    const opacity = loboAnimated.createValue(0);
    const scaleX = loboAnimated.createValue(1);
    const scaleY = loboAnimated.createValue(1);

    const { maxScaleX, maxScaleY } = resolveMaxScale(props);
    const { showDuration, hideDuration } = resolveDurations(props);

    const show = loboAnimated.sequence([
      loboAnimated.parallel([
        loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
          duration: showDuration * 0.67,
          easing: loboAnimated.Easing.In(),
          toValue: 1
        }),
        loboAnimated.timing(AnimatableCSSProperty.ScaleX, scaleX, {
          duration: showDuration * 0.67,
          easing: loboAnimated.Easing.In(),
          toValue: maxScaleX
        }),
        loboAnimated.timing(AnimatableCSSProperty.ScaleY, scaleY, {
          duration: showDuration * 0.67,
          easing: loboAnimated.Easing.In(),
          toValue: maxScaleY
        })
      ]),
      loboAnimated.timing(AnimatableCSSProperty.ScaleX, scaleX, {
        duration: showDuration * 0.33,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      }),
      loboAnimated.timing(AnimatableCSSProperty.ScaleY, scaleY, {
        duration: showDuration * 0.33,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      })
    ]);

    const hide = loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
      duration: hideDuration,
      easing: loboAnimated.Easing.Out(),
      toValue: 0
    });

    this.animations = {
      hide,
      show
    };

    this.styles = {
      containerAnimated: {
        opacity,
        transform: [{ scaleX }, { scaleY }]
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
