import { FadeAnimation, Row, useUpdateEffect } from 'common-ui';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProfileSelector } from 'services/profile';
import { AvatarEditorScreenAnimatedStyles } from './AvatarEditorScreen.animated-styles';
import { AvatarAndMirror } from './AvatarAndMirror';
import { Services } from 'services';
import { AvatarAnimationName } from './avatar-editor.model';
import { AvatarEditor } from './avatar-editor/AvatarEditor';
import { BackgroundGradientSvg } from 'feature-areas/shell';
import { InstructionButton } from './instruction-button/InstructionButton';
import { Timer } from './timer/Timer';
import { AvatarEditorUtilityBar } from './utility-bar/AvatarEditorUtilityBar';

export function AvatarEditorScreen() {
  const showFullEditor = useSelector(ProfileSelector.hasCreatedAvatar);

  const animatedStyles = useRef(
    new AvatarEditorScreenAnimatedStyles(showFullEditor)
  );
  const animations = animatedStyles.current.getAnimations();
  const styles = animatedStyles.current.get();

  useEffect(() => {
    const { slideOutFaceSelector } = animations;

    const unregisterAnimations =
      Services.reactAnimationScreenplayer.registerAnimations([
        /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
        {
          name: AvatarAnimationName.ShowFaceSelector,
          animation: slideOutFaceSelector
        }
        /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
      ]);

    return () => {
      unregisterAnimations();
    };
  }, [animations]);

  useUpdateEffect(() => {
    // istanbul ignore else - nothing to actually test for in the else case
    // there shouldn't be a way to hit an else case here anyway
    // (which would mean user had had a face selected and then subsequently they don't),
    // but still seems proper to wrap this in the if
    if (showFullEditor) {
      animations.expandToFullEditor.start();
    }
  }, [animations.expandToFullEditor, showFullEditor]);

  return (
    <FadeAnimation
      restrictFocusWithin={true}
      shouldShow={true}
      testId={AvatarEditorScreen.displayName}
      styleOverride={styles.fadeOverride}
    >
      <BackgroundGradientSvg id={AvatarEditorScreen.displayName} />
      <Timer />
      <AvatarEditorUtilityBar />
      <Row style={styles.container}>
        <AvatarEditor
          showFull={showFullEditor}
          animatedStyles={styles.avatarEditorAnimated}
        />
        <AvatarAndMirror animatedStyles={styles.avatarAnimated} />
      </Row>
      <InstructionButton />
    </FadeAnimation>
  );
}
AvatarEditorScreen.displayName = 'AvatarEditorScreen';
