import { TextPillWidth } from '@lexialearning/lobo-common';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { Column, View } from 'common-ui';
import { DndDropTarget, DragItemType, DropTargetHelper } from 'dnd';
import { DndAction, DndSelector } from 'dnd/redux';
import { ISegment } from 'task-components/shared';
import { VerticalOrderingSegment } from '../vertical-ordering-segment';
import { VerticalOrderingContentStyles } from './VerticalOrderingContent.styles';

export interface IVerticalOrderingContentProps extends ISfxProps {
  textPillWidth: TextPillWidth;
  segments: ISegment[];
  shouldShowCorrectFeedback: boolean;
  dndIsDropping: boolean;
  moveSegment(targetIndex: number, sourceIndex: number): void;
  dropToPosition(position: XYCoord | undefined): void;
}

export interface IVerticalOrderingContentState {
  activeSrcIdx: number;
  activeTargetIdx: number;
  droppedItemSrcIdx: number;
  droppedItemTargetIdx: number;
}

const CancelDropTarget = DndDropTarget(
  DragItemType.TextSegment,
  DropTargetHelper.createSpec({ canDrop: () => false })
)(View);

export class VerticalOrderingContentComponent extends React.PureComponent<
  IVerticalOrderingContentProps,
  IVerticalOrderingContentState
> {
  public static readonly displayName = 'VerticalOrderingContent';

  private readonly segmentPositions: XYCoord[] = [];

  constructor(props: IVerticalOrderingContentProps) {
    super(props);

    this.state = {
      activeSrcIdx: -1,
      activeTargetIdx: -1,
      droppedItemSrcIdx: -1,
      droppedItemTargetIdx: -1
    };

    this.handleBeginDrag = this.handleBeginDrag.bind(this);
    this.handleTargetHover = this.handleTargetHover.bind(this);
    this.handleEndDrag = this.handleEndDrag.bind(this);
    this.cancelDrag = this.cancelDrag.bind(this);
    this.handleSegmentPosition = this.handleSegmentPosition.bind(this);
  }

  public componentDidUpdate(prevProps: IVerticalOrderingContentProps) {
    if (prevProps.dndIsDropping && !this.props.dndIsDropping) {
      const { moveSegment } = this.props;
      const { droppedItemTargetIdx, droppedItemSrcIdx } = this.state;
      moveSegment(droppedItemTargetIdx, droppedItemSrcIdx);
      this.setState({
        droppedItemSrcIdx: -1,
        droppedItemTargetIdx: -1
      });
    }
  }

  private handleBeginDrag(srcIdx: number) {
    this.setState({
      activeSrcIdx: srcIdx,
      activeTargetIdx: srcIdx
    });
  }

  private handleTargetHover(targetIdx: number) {
    this.setState({
      activeTargetIdx: targetIdx
    });
  }

  private handleEndDrag() {
    const { playSfx } = this.props;
    const { activeSrcIdx, activeTargetIdx } = this.state;
    playSfx(Sfx.DragEnd);

    const position = this.segmentPositions[activeTargetIdx];
    this.props.dropToPosition(position);
    this.setState({
      activeSrcIdx: -1,
      activeTargetIdx: -1,
      droppedItemSrcIdx: activeSrcIdx,
      droppedItemTargetIdx: activeTargetIdx
    });
  }

  private cancelDrag() {
    this.setState(({ activeSrcIdx }) => ({
      activeTargetIdx: activeSrcIdx
    }));
  }

  private async handleSegmentPosition(position: XYCoord, idx: number) {
    this.segmentPositions[idx] = position;
  }

  public render() {
    const {
      dndIsDropping,
      textPillWidth,
      shouldShowCorrectFeedback,
      segments
    } = this.props;
    const { activeSrcIdx, activeTargetIdx } = this.state;

    const styles = VerticalOrderingContentStyles.get();

    return (
      <>
        <CancelDropTarget
          style={styles.leftCancelZone}
          onHover={this.cancelDrag}
        />
        <CancelDropTarget
          style={styles.rightCancelZone}
          onHover={this.cancelDrag}
        />

        <Column style={styles.segmentsContainer}>
          {segments.map((segment: ISegment, segmentIndex: number) => (
            <VerticalOrderingSegment
              key={segment.key}
              index={segmentIndex}
              content={segment.content}
              activeSrcIdx={activeSrcIdx}
              activeTargetIdx={activeTargetIdx}
              dndIsDropping={dndIsDropping}
              onPosition={this.handleSegmentPosition}
              onBeginDrag={this.handleBeginDrag}
              onEndDrag={this.handleEndDrag}
              onHover={this.handleTargetHover}
              textPillWidth={textPillWidth}
              showCorrect={shouldShowCorrectFeedback}
            />
          ))}
        </Column>
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    dndIsDropping: !!DndSelector.getDropTargetPosition(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  dropToPosition: (position: XYCoord | undefined) =>
    DndAction.dropToPosition({ position })
};

export const VerticalOrderingContent = compose(
  withSfx,
  connect(mapStateToProps, mapDispatchToProps)
)(VerticalOrderingContentComponent);
