import { Position } from '@lexialearning/common-ui';
import { ZIndex } from 'common-styles';
import { UtilityButtonSharedStyles } from 'common-ui/components/button/utility-button/utility-button-model';
import memoizeOne from 'memoize-one';

const build = memoizeOne((isHighlighted?: boolean) => ({
  container: {
    position: Position.Absolute,
    right: 10,
    top: 10,
    zIndex: isHighlighted ? ZIndex.Modal + 1 : undefined
  },
  buttonOverride: isHighlighted ? UtilityButtonSharedStyles.buttonHighlight : {}
}));

export const TroubleshootButtonStyles = { build };
