import { AppShellAction, AppShellSelector } from 'feature-areas/shell';
import {
  AvatarEditorAction,
  AvatarEditorSelector
} from 'feature-areas/avatar-editor/redux';
import { InteractionState } from 'common-styles';
import { ProfileSelector } from 'services/profile';
import { ToolTipButton } from './tool-tip/tool-tip-button/ToolTipButton';
import { ToolTipDialog } from './tool-tip/tool-tip-dialog/ToolTipDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import {
  Avatar,
  AvatarType,
  Column,
  GlossyButton,
  GlossyButtonIconType,
  PressableView,
  Text,
  View
} from 'common-ui';
import { DashboardAvatarStyles } from './DashboardAvatar.styles';

export function DashboardAvatar() {
  const dispatch = useDispatch();
  const userDisplayName = useSelector(ProfileSelector.getDisplayName);
  const avatar = useSelector(state => ProfileSelector.getAvatarMaybe(state));
  const canEditAvatar = useSelector(AvatarEditorSelector.getCanEditAvatar);
  const isUtilityBarDisabled = useSelector(
    AppShellSelector.isUtilityBarDisabled
  );

  const isDisabled = !canEditAvatar || isUtilityBarDisabled;
  const [isToolTipDialogShowing, setIsToolTipDialogShowing] =
    useState<boolean>(false);

  const handlePress = useCallback(() => {
    dispatch(
      isToolTipDialogShowing
        ? AppShellAction.enableUtilityBarZIndex()
        : AppShellAction.disableUtilityBarZIndex()
    );
    setIsToolTipDialogShowing(!isToolTipDialogShowing);
  }, [dispatch, isToolTipDialogShowing]);

  const styles = DashboardAvatarStyles.build(isToolTipDialogShowing);

  return (
    <Column style={styles.container} testId={DashboardAvatar.displayName}>
      <View style={styles.dashboardBumpOutContainer}>
        <View style={styles.dashboardBumpOut} />
      </View>
      <View style={styles.toolTipZIndex}>
        {isToolTipDialogShowing && (
          <PressableView
            testId="toolTipBackgroundOverlay"
            onPress={handlePress}
            style={styles.toolTipBackgroundOverlay}
          />
        )}
        <Avatar
          styleOverride={styles.avatarOverride}
          type={AvatarType.Thumbnail}
        />
        <GlossyButton
          disabled={isDisabled}
          icon={GlossyButtonIconType.Edit}
          interactionState={
            isDisabled ? InteractionState.Disabled : InteractionState.Default
          }
          onPress={() => dispatch(AvatarEditorAction.showEditor(avatar))}
          styleOverride={styles.editButtonOverrides}
        />
        <Text style={styles.userDisplayName}>{userDisplayName}</Text>
        {!canEditAvatar && (
          <ToolTipButton
            isToolTipDialogShowing={isToolTipDialogShowing}
            onPress={handlePress}
          />
        )}
        {isToolTipDialogShowing && <ToolTipDialog />}
      </View>
    </Column>
  );
}

DashboardAvatar.displayName = 'DashboardAvatar';
