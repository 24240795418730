import { first, map } from 'rxjs/operators';
import { PlacementIntroScreenplayBuilder } from 'feature-areas/transitions/builders/placement';
import { ScreenplayAction } from 'screenplay';
import { StormService } from 'storm';
import {
  IUnitDeepLinkDeps,
  UnitDeepLinkObservableFactory
} from './UnitDeepLinkObservableFactory';

export interface IPlacementDeepLinkDeps extends IUnitDeepLinkDeps {
  stormService: StormService;
}

export class PlacementDeepLinkObservableFactory extends UnitDeepLinkObservableFactory {
  public static readonly displayName = 'PlacementDeepLinkObservableFactory';

  public static createFor(
    deps: IPlacementDeepLinkDeps
  ): PlacementDeepLinkObservableFactory {
    return new PlacementDeepLinkObservableFactory(deps)
      .loadProgramPosition()
      .thenPlayOnboardingIntroScreenplay();
  }

  constructor(protected readonly deps: IPlacementDeepLinkDeps) {
    super(deps);
  }

  private thenPlayOnboardingIntroScreenplay(): PlacementDeepLinkObservableFactory {
    const { state$, preparedScenes, stormService } = this.deps;

    const playOnboardingIntroScreenplay$ = state$.pipe(
      first(() => this.isReady()),
      map(
        () =>
          PlacementIntroScreenplayBuilder.create()
            .showPlacementScene(preparedScenes, stormService)
            .dispatchPlacementRoundIntro().screenplay
      ),
      map(screenplay => ScreenplayAction.play({ screenplay }))
    );
    this.deferredDispatches$.push(playOnboardingIntroScreenplay$);

    return this;
  }
}
