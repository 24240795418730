import memoizeOne from 'memoize-one';
import { Align, Color, Font, Justify } from 'common-styles';

export class AccountInfoStyles {
  public static readonly styles = {
    mainColumn: {
      alignItems: Align.Stretch
    },
    mainRow: {
      justifyContent: Justify.SpaceBetween,
      marginVertical: 10
    },
    text: {
      fontSize: Font.Size.small
    }
  };

  public static get() {
    return this.styles;
  }

  public static readonly buildButton = memoizeOne((disabled: boolean) => ({
    button: {
      backgroundColor: disabled ? Color.Gray5 : Color.LexiaPurpleTransparent10,
      borderRadius: 8,
      height: 40,
      paddingVertical: 0,
      shadowColor: Color.Transparent,
      width: 160
    },
    buttonText: {
      color: disabled ? Color.Gray55 : Color.Blue,
      fontFamily: Font.Family.semiBold,
      fontSize: Font.Size.small
    }
  }));
}
