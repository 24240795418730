import { Position } from '@lexialearning/common-ui';
import { Align, Color, Font } from 'common-styles';

export class AppShellStyles {
  private static readonly styles = {
    authUrlBar: {
      backgroundColor: Color.NearWhiteTransparent40,
      fontSize: Font.Size.xSmall,
      maxWidth: 500,
      opacity: 0.5,
      position: Position.Absolute,
      top: 0
    },
    content: {
      alignItems: Align.Stretch,
      flex: 1,
      position: Position.Relative
    }
  };

  public static get() {
    return this.styles;
  }
}
