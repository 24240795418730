import { AuthActionType } from 'services/auth';
import { IntroductionSceneAnimationName } from 'feature-areas/introduction';
import { LoginReactAnimationName } from 'feature-areas/login/login.model';
import { Music } from 'audio/music';
import { PredicateUtils } from 'utils';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { RouteBuilder, RouterService } from 'router-service';
import { TransitionScreenplayBuilderBase } from '../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../transition.model';
import { first } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { ofType } from 'redux-observable';

export class LoginToInstructionTransitionScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName =
    'LoginToInstructionTransitionScreenplayBuilder';

  public static create(): LoginToInstructionTransitionScreenplayBuilder {
    return new LoginToInstructionTransitionScreenplayBuilder();
  }

  private constructor() {
    super(TransitionScreenplayId.LoginToIntroduction);
  }

  public navToSsoInteractionPage(): LoginToInstructionTransitionScreenplayBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.loginSsoInteraction());
    });

    return this;
  }

  public awaitInteraction(
    action$: Observable<any>
  ): LoginToInstructionTransitionScreenplayBuilder {
    const interactionPromise = firstValueFrom(
      action$.pipe(
        ofType(AuthActionType.Interacted),
        first(PredicateUtils.isDefined)
      )
    );

    this.builder.addCallback(async () => interactionPromise);

    return this;
  }

  public awaitAndShowIntroductionScene(
    preparedScenes: PreparedScenes
  ): LoginToInstructionTransitionScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const introductionScene = await preparedScenes.introductionReady;
        introductionScene.show();
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  public fadeOutLoginPageMaybe(
    needsSsoInteraction: boolean
  ): LoginToInstructionTransitionScreenplayBuilder {
    if (!needsSsoInteraction) {
      this.builder.addReactAnimation(LoginReactAnimationName.FadeOut);
    }

    return this;
  }

  public navToAndPlayIntroduction(): LoginToInstructionTransitionScreenplayBuilder {
    this.builder
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.introduction());
      })
      .addMusic({ path: Music.Branding }, { concurrent: true })
      .addStormAnimation({
        name: IntroductionSceneAnimationName.Root.Intro,
        targetScene: SceneName.Introduction
      });

    return this;
  }
}
