import memoizeOne from 'memoize-one';
import { XYCoord } from 'react-dnd';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { Position } from '@lexialearning/common-ui';

export interface IDndDragLayerStyles {
  container: Types.ViewStyle;
  containerAnimated: Types.AnimatedViewStyle;
}

export class DndDragLayerAnimatedStyles {
  public static readonly DropAnimationDuration = 250;

  private readonly translateXValue: Types.AnimatedValue;

  private readonly translateYValue: Types.AnimatedValue;

  private readonly styles: IDndDragLayerStyles = {
    container: {
      left: 0,
      opacity: 0.9,
      position: Position.Absolute,
      top: 0
    },
    containerAnimated: {
      transform: []
    }
  };

  constructor(currentOffset?: XYCoord) {
    const { x, y } = currentOffset || { x: 0, y: 0 };
    this.translateXValue = loboAnimated.createValue(x);
    this.translateYValue = loboAnimated.createValue(y);

    this.styles.containerAnimated.transform = [
      { translateX: this.translateXValue },
      { translateY: this.translateYValue }
    ];
  }

  public readonly setTranslations = memoizeOne((currentOffset: XYCoord) => {
    const { x, y } = currentOffset;
    this.translateXValue.setValue(x);
    this.translateYValue.setValue(y);
  });

  public buildDropAnimation(targetOffset: XYCoord) {
    return loboAnimated.parallel([
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateX,
        this.translateXValue,
        {
          duration: DndDragLayerAnimatedStyles.DropAnimationDuration,
          toValue: targetOffset.x
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        this.translateYValue,
        {
          duration: DndDragLayerAnimatedStyles.DropAnimationDuration,
          toValue: targetOffset.y
        }
      )
    ]);
  }

  public get() {
    return this.styles;
  }
}
