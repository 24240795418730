import { AnimatedView, IAnimatedViewProps } from '../../view/AnimatedView';
import { ColumnStyles } from './Column.styles';

export function AnimatedColumn(props: IAnimatedViewProps) {
  const { children, style, ...viewProps } = props;

  return (
    <AnimatedView {...viewProps} style={ColumnStyles.build(style)}>
      {children}
    </AnimatedView>
  );
}
AnimatedColumn.displayName = 'AnimatedColumn';
