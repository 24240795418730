import * as React from 'react';
import { HeaderTitlePillStyles } from './HeaderTitlePill.styles';
import { TitlePill } from './TitlePill';

export function HeaderTitlePill(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const styles = HeaderTitlePillStyles.get();

  return (
    <TitlePill
      styleOverride={styles.titlePillOverrides}
      testId={HeaderTitlePill.displayName}
    >
      {children}
    </TitlePill>
  );
}
HeaderTitlePill.displayName = 'HeaderTitlePill';
