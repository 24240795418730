import { EffectCallback, useEffect, useRef } from 'react';

/**
 * Run effect only on update, not initial mount
 */
export const useUpdateEffect = <T>(
  effect: EffectCallback,
  dependencies: T[],
  cleanUp?: () => void
): void => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      effect();
    } else {
      hasMounted.current = true;
    }

    return cleanUp?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
