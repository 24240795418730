import * as React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'common-ui';
import {
  EducatorContext,
  EducatorSelector
} from 'feature-areas/educator/redux';
import { TransitionScreenplayId } from 'feature-areas/transitions';
import { ScreenplayAction, ScreenplaySelector } from 'screenplay';
import { PositionAction } from 'services/curriculum-services';
import { ModalRegistry } from 'shared-components/modals';
import { ActDetail } from './act-detail/ActDetail';
import { ActivityCarouselModalStyles } from './ActivityCarouselModal.styles';

export interface IActivityCarouselModalProps {
  educatorContext: EducatorContext;
  isTransitioningToEncounter: boolean;
  cancelScreenplay(): void;
  unloadLevelPosition(): void;
  selectActivity(activityId: string): void;
}

export class ActivityCarouselModalComponent extends React.PureComponent<IActivityCarouselModalProps> {
  public static readonly displayName = 'ActivityCarouselModal';

  constructor(props: IActivityCarouselModalProps) {
    super(props);
    this.handlePressPrevious = this.handlePressPrevious.bind(this);
    this.handlePressNext = this.handlePressNext.bind(this);
    this.updateSlide = this.updateSlide.bind(this);
  }

  public componentWillUnmount() {
    const {
      unloadLevelPosition,
      isTransitioningToEncounter,
      cancelScreenplay
    } = this.props;

    if (!isTransitioningToEncounter) {
      // Assume we are unmounting due to modal dismissal w/o unit selection
      unloadLevelPosition();
      cancelScreenplay();
    }
  }

  private handlePressPrevious() {
    this.updateSlide(this.props.educatorContext.actIndex - 1);
  }

  private handlePressNext() {
    this.updateSlide(this.props.educatorContext.actIndex + 1);
  }

  private updateSlide(slideIdx: number) {
    const {
      educatorContext: { level },
      selectActivity,
      cancelScreenplay
    } = this.props;
    const actId = level.acts[slideIdx].sysId;

    cancelScreenplay();
    selectActivity(actId);
  }

  private renderSlides(): React.ReactElement[] {
    const { educatorContext: context } = this.props;

    return context.activityPositionDetail.map((actDetail, idx) => (
      <ActDetail
        key={`slide${idx}`}
        activityDetail={actDetail}
        carouselIndex={idx}
        isCurrentSlide={context.actIndex === idx}
        levelNumber={context.levelNumber}
      />
    ));
  }

  public render(): React.ReactElement {
    const { educatorContext: context } = this.props;
    const styles = ActivityCarouselModalStyles.get();

    return (
      <Carousel
        accessibilityLabel="Level Activities Carousel."
        currentSlideIdx={context.actIndex}
        onPressNext={this.handlePressNext}
        onPressPrevious={this.handlePressPrevious}
        slideWidth={ActivityCarouselModalStyles.SlideWidth}
        slides={this.renderSlides()}
        styleOverrides={styles.carouselOverrides}
        updateCurrentSlide={this.updateSlide}
      />
    );
  }
}

function mapStateToProps(state: unknown) {
  const isTransitioningToEncounter =
    ScreenplaySelector.getActiveScreenplayId(state) ===
    TransitionScreenplayId.EducatorToEncounter;

  return {
    educatorContext: EducatorSelector.getContext(state),
    isTransitioningToEncounter
  };
}

const mapDispatchToProps = {
  cancelScreenplay: () => ScreenplayAction.cancel(),
  selectActivity: (activityId: string) =>
    PositionAction.activitySelected({ activityId }),
  unloadLevelPosition: () => PositionAction.levelPositionUnloaded()
};

export const ActivityCarouselModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityCarouselModalComponent);

ActivityCarouselModal.ModalId = ModalRegistry.register({
  Component: ActivityCarouselModal,
  ariaLabel: 'Activity Carousel',
  rawLayout: true
});

export const ActivityCarouselModalPrivates = {
  mapDispatchToProps,
  mapStateToProps
};
