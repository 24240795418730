import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import {
  Align,
  Color,
  Direction,
  Font,
  Justify,
  ShadowStyle,
  TextAlign
} from 'common-styles';
import { Types } from '../../types';
import {
  ActiveComponentState,
  Border,
  Cursor,
  Position
} from '@lexialearning/common-ui';
import { KeyNavHelper } from 'common-ui/helpers';

export interface IDropdownStyleOverride {
  container?: Types.ViewStyle;
  input?: Types.ViewStyle;
  value?: Types.TextStyle;
  arrowDown?: {
    borderTopColor: string;
  };
  arrowUp?: {
    borderBottomColor: string;
  };
}

const DROPDOWN_WIDTH = 380;
export const ROW_HEIGHT = 50;

export class DropdownStyles {
  private static readonly styles = {
    arrowDown: {
      borderBottomWidth: 0,
      borderColor: Color.Transparent,
      borderBottomColor: Color.Transparent,
      borderLeftWidth: 6,
      borderRightWidth: 6,
      borderStyle: Border.Solid,
      borderTopColor: Color.Clementine,
      borderTopWidth: 11
    },
    arrowUp: {
      borderColor: Color.Transparent,
      borderBottomColor: Color.Clementine,
      borderBottomWidth: 11,
      borderLeftWidth: 6,
      borderRightWidth: 6,
      borderStyle: Border.Solid,
      borderTopColor: Color.Transparent,
      borderTopWidth: 0
    },
    container: {
      alignItems: Align.Stretch,
      position: Position.Relative,
      width: DROPDOWN_WIDTH
    },
    input: {
      alignItems: Align.Center,
      backgroundColor: Color.NearWhite,
      borderRadius: 8,
      borderStyle: Border.Solid,
      borderColor: Color.Transparent,
      borderWidth: 5,
      cursor: Cursor.Pointer,
      flexDirection: Direction.Row,
      height: ROW_HEIGHT,
      justifyContent: Justify.SpaceBetween,
      paddingRight: 8,
      ...ShadowStyle.button
    },
    value: {
      color: Color.Black,
      flex: 1,
      fontSize: Font.Size.small,
      letterSpacing: 0,
      lineHeight: 22,
      marginRight: 6,
      textAlign: TextAlign.Center
    }
  };

  public static readonly build = memoizeOne(
    (
      activeComponentState: ActiveComponentState,
      styleOverride?: IDropdownStyleOverride
    ) => {
      const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);
      const keyNavColorSet = Color.getSetFor(activeComponentState);

      return merge(
        {},
        DropdownStyles.styles,
        {
          ...(isKeyNav && {
            input: {
              backgroundColor: keyNavColorSet.background,
              borderColor: keyNavColorSet.border
            }
          })
        },
        styleOverride
      );
    }
  );
}
