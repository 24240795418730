import { Color } from 'common-styles';

export class LoboVersionStyles {
  private static readonly styles = {
    text: {
      color: Color.Gray60,
      fontSize: 14
    }
  };

  public static get() {
    return this.styles;
  }
}
