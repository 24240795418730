import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  IntroductionSceneAction,
  IntroductionScenePrepareAction
} from 'feature-areas/introduction/introduction-scene/redux';
import { AppConfigAction, AppConfigActionApplyOverrides } from 'app-config';
import { ConfigOverridesFactory } from '../../app-config';
import { DeployConfig } from '../../app-config/DeployConfig';
import {
  BootstrappingActionBootstrap,
  BootstrappingActionType
} from '../redux';

export type BootstrapOutputActions =
  | AppConfigActionApplyOverrides
  | IntroductionScenePrepareAction;

export function bootstrapEpic(
  action$: Observable<BootstrappingActionBootstrap>
): Observable<BootstrapOutputActions> {
  return action$.pipe(
    ofType(BootstrappingActionType.Bootstrap),
    mergeMap(() =>
      from([
        IntroductionSceneAction.prepare(),
        AppConfigAction.applyOverrides({
          overrideSetName: ConfigOverridesFactory.getSetName(
            DeployConfig.deployEnvironment
          )
        })
      ])
    )
  );
}
bootstrapEpic.displayName = 'bootstrapEpic';
