import { IScreenplay } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LevelSelector,
  PlacementSelector,
  ProgramContextSelector,
  ProgramMode,
  RoundSelector,
  UnitSelector
} from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { ProfileSelector } from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { AppShellActionHideModal } from 'shell';
import { StormService } from 'storm';
import { TransitionActionCalibrationToNext } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { CalibrationToHomeTransitionScreenplayFactory } from './CalibrationToHomeTransitionScreenplayFactory';
import { CalibrationToOnboardingTransitionScreenplayFactory } from './CalibrationToOnboardingTransitionScreenplayFactory';
import { CalibrationToPlacementTransitionScreenplayFactory } from './CalibrationToPlacementTransitionScreenplayFactory';

export interface ICalibrationToNextTransitionDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function calibrationToNextTransitionEpic(
  action$: Observable<TransitionActionCalibrationToNext>,
  state$: StateObservable<unknown>,
  deps: ICalibrationToNextTransitionDeps
): Observable<ScreenplayActionPlay | AppShellActionHideModal> {
  return action$.pipe(
    ofType(TransitionActionType.CalibrationToNext),
    map(() => {
      const mode = ProgramContextSelector.getMode(state$.value);

      return createTransitionScreenplay(
        state$.value,
        deps.preparedScenes,
        deps.stormService,
        mode
      );
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
calibrationToNextTransitionEpic.displayName = 'calibrationToNextTransitionEpic';

function createTransitionScreenplay(
  state: unknown,
  preparedScenes: PreparedScenes,
  stormService: StormService,
  mode: ProgramMode
): IScreenplay {
  switch (mode) {
    case ProgramMode.Onboarding:
      return createCalibrationToOnboardingScreenplay(
        state,
        preparedScenes,
        stormService
      );
    case ProgramMode.Placement:
      return createCalibrationToPlacementScreenplay(
        state,
        preparedScenes,
        stormService
      );
    default:
      return createCalibrationToHomeScreenplay(
        state,
        preparedScenes,
        stormService
      );
  }
}

function createCalibrationToOnboardingScreenplay(
  state: unknown,
  preparedScenes: PreparedScenes,
  stormService: StormService
): IScreenplay {
  const placement = PlacementSelector.getPlacement(state);
  const introductionVo = placement.onboardingIntroduction;

  return CalibrationToOnboardingTransitionScreenplayFactory.createFor({
    introductionVo,
    preparedScenes,
    stormService,
    targetRoundId: placement.onboarding.rounds[0].sysId,
    targetUnitId: placement.onboarding.sysId
  }).screenplay;
}

function createCalibrationToPlacementScreenplay(
  state: unknown,
  preparedScenes: PreparedScenes,
  stormService: StormService
): IScreenplay {
  const placement = PlacementSelector.getPlacement(state);
  const unit = UnitSelector.getUnit(state);
  const round = RoundSelector.getRound(state);

  return CalibrationToPlacementTransitionScreenplayFactory.createFor({
    conclusionVo: placement.reEntryConclusion,
    orientationVo: placement.reEntryOrientation,
    preparedScenes,
    stormService,
    targetRoundId: round.sysId,
    targetUnitId: unit.sysId,
    welcomeVo: placement.reEntryWelcome
  }).screenplay;
}

function createCalibrationToHomeScreenplay(
  state: unknown,
  preparedScenes: PreparedScenes,
  stormService: StormService
): IScreenplay {
  const homeContent = BootstrapContentSelector.getHomeScreenContent(state);
  const micPromptVo = homeContent?.microphonePrompt.voiceover;
  const levelNum = LevelSelector.getLevelNumber(state);
  const grade = ProfileSelector.getGrade(state);

  return CalibrationToHomeTransitionScreenplayFactory.createFor({
    grade,
    levelNum,
    micPromptVo,
    preparedScenes,
    stormService
  }).screenplay;
}
