import { XYCoord } from 'react-dnd';
import { InteractionState } from 'common-styles';
import {
  RoundedTabPosition,
  RoundedTabSvg,
  Row,
  View,
  withPositionHandler
} from 'common-ui';
import { IPickCount } from '..';
import { CheckMarkSvg } from '../svg/CheckMark.lx-svg';
import { CheckMarkTabStyles } from './checkMarkTab.styles';

export interface ICheckMarkTabProps extends IPickCount {
  /** Indices of pills to be hidden */
  hiddenPills: number[];
  interactionState?: InteractionState;
  onCheckMarkPosition(position: XYCoord, idx?: number): void;
}

const tabWidth = new Map([
  [2, 376],
  [3, 466],
  [4, 556]
]);

const CheckMarkWithPosition = withPositionHandler(CheckMarkSvg);

export function CheckMarkTab(props: ICheckMarkTabProps) {
  const { pickCount, hiddenPills, onCheckMarkPosition } = props;
  const styles = CheckMarkTabStyles.get();
  const width = tabWidth.get(pickCount);
  const height = 54;

  return (
    <Row style={{ height, width }}>
      <RoundedTabSvg
        position={RoundedTabPosition.Top}
        length={width}
        rectLength={width! - 143 * 2}
        depth={height}
        opacity={0.7}
      />
      {Array(pickCount)
        .fill('')
        .map((_t, idx) => {
          const isHidden = hiddenPills.includes(idx);

          return (
            <View
              style={styles.checkMarkContainer}
              key={`checkMarkContainer${idx}`}
            >
              {!isHidden && (
                <CheckMarkWithPosition
                  index={idx}
                  onPosition={onCheckMarkPosition}
                  accessibilityLabel="Available answer checkmark."
                />
              )}
            </View>
          );
        })}
    </Row>
  );
}
CheckMarkTab.displayName = 'CheckMarkTab';
