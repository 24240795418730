import { IScreenplay } from '@lexialearning/lobo-common';
import { Sfx } from 'audio';
import { InteractionState } from 'common-styles';
import {
  PlacementSceneAnimationName,
  PlacementSceneElementName
} from 'feature-areas/placement/placement-scene/placement-scene.model';
import {
  AppShellAction,
  AppShellMode,
  ControlPanelButtonAnimation,
  ControlPanelLayout
} from 'feature-areas/shell';
import {
  ControlPanelComponent,
  FadeAnimationType
} from 'feature-areas/shell/control-panel/ControlPanel';
import { SceneName } from 'services/storm-lobo';
import { PlacementScreenplayBuilderBase } from '../placement/PlacementScreenplayBuilderBase';
import { OnboardingScreenplayId } from './onboarding-screenplay-builders.model';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/pages/viewpage.action?spaceKey=ELKMK&title=Student+Placement
 */
export class OnboardingOrientationScreenplayBuilder extends PlacementScreenplayBuilderBase {
  public static readonly displayName = 'OnboardingOrientationScreenplayBuilder';

  public static create(): OnboardingOrientationScreenplayBuilder {
    return new OnboardingOrientationScreenplayBuilder();
  }

  private constructor() {
    super(OnboardingScreenplayId.Orientation);
  }

  public playOrientationIntroVo(
    orientationIntroVo: IScreenplay
  ): OnboardingOrientationScreenplayBuilder {
    this.builder.addActionList(this.addSpeaker(orientationIntroVo));

    return this;
  }

  public showAndCalloutButtons(): OnboardingOrientationScreenplayBuilder {
    this.builder
      .addReduxAction(
        AppShellAction.setUserControlInteractionState({
          directions: InteractionState.Default,
          lookback: InteractionState.Default,
          presentation: InteractionState.Default
        })
      )
      .addReduxAction(AppShellAction.setMode(AppShellMode.Full))
      .addReduxAction(
        AppShellAction.setControlPanelLayout(
          ControlPanelLayout.AudioSupportOnly
        )
      )
      .addReactAnimation(
        ControlPanelComponent.getAnimationName(FadeAnimationType.FadeIn)
      )
      .addReactAnimation(ControlPanelButtonAnimation.DirectionsCallout, {
        concurrent: true
      })
      .addReactAnimation(ControlPanelButtonAnimation.PresentationCallout, {
        concurrent: true
      });

    return this;
  }

  public playMicAvailableSfx(): OnboardingOrientationScreenplayBuilder {
    this.builder.addSfx(Sfx.MicAvailable, { concurrent: true });

    return this;
  }

  public animateCharacterButtonGesture(): OnboardingOrientationScreenplayBuilder {
    this.builder.addStormAnimation(
      {
        name: PlacementSceneAnimationName.Character.GestureBottomLeft,
        targetElement: PlacementSceneElementName.Character,
        targetScene: SceneName.Placement
      },
      { concurrent: true }
    );

    return this;
  }

  public playOrientationVo(
    orientationVo: IScreenplay
  ): OnboardingOrientationScreenplayBuilder {
    this.builder.addActionList(this.addSpeaker(orientationVo));

    return this;
  }

  public playConclusionVo(
    conclusionVo: IScreenplay
  ): OnboardingOrientationScreenplayBuilder {
    this.builder.addActionList(this.addSpeaker(conclusionVo));

    return this;
  }
}
