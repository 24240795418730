import { CalibrationSharedStyles } from '../CalibrationShared.styles';

export class CalibrationInteractiveStyles {
  private static readonly styles = {
    sentenceText: {
      ...CalibrationSharedStyles.get().title
    }
  };

  public static get() {
    return this.styles;
  }
}
