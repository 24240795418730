import {
  IAuthRequestBase,
  ILoginUserRequestBase,
  ILogoutUserRequestBase
} from '../auth-api/auth-api-private.model';
import { IRegisterRequestBase } from '../customer-api/customer-api-private.model';
import { IStudentApiRequestBase } from '../lexia-service-private.model';

export interface ILexiaServiceRequestsBases {
  register: IRegisterRequestBase;
  authenticate: IAuthRequestBase;
  login: ILoginUserRequestBase;
  logout: ILogoutUserRequestBase;
  studentApi: IStudentApiRequestBase;
}

export interface ILexiaServiceOptions {
  apiTimeoutArray: number[];
  isMakingRequest: boolean;
  maxRetries: number;
}

export interface ILexiaServiceState {
  options: ILexiaServiceOptions;
  requestsBases: ILexiaServiceRequestsBases;
}

export const AUTH_SERVER_CONFIG_STRING = 'lexiaService.customerUrl';
export const SHOW_AUTH_URL_CONFIG_STRING = 'showAuthUrl';
