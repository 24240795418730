import { CenterLeftAlignWrap } from 'common-ui/components/center-left-align-wrap/CenterLeftAlignWrap';
import { ILanguageFrameToken } from '@lexialearning/lobo-common';
import { SeeSpeakSelector } from '../../../redux/SeeSpeak.selector';
import { Text } from 'common-ui';
import { useSelector } from 'react-redux';

export interface ILanguageFrameProps {
  width?: number;
}

export function SeeSpeakIntroduction(props: ILanguageFrameProps) {
  const { width } = props;

  const languageFrameTokens = useSelector(
    SeeSpeakSelector.getLanguageFrameTokens
  );

  const renderToken = (token: ILanguageFrameToken) => {
    const textToShow = `${token.prePunctuation}${token.cleanText}${token.postPunctuation} `;

    return <Text accessibilityLanguage={token.language}>{textToShow}</Text>;
  };

  return (
    <CenterLeftAlignWrap
      width={width}
      tokenArray={languageFrameTokens}
      renderToken={renderToken}
    />
  );
}
SeeSpeakIntroduction.displayName = 'SeeSpeakIntroduction';
