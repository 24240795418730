// istanbul ignore file - code generated
import { ITextInputIconProps, Path, Svg } from 'common-ui';

export function MailIconSvg(props: ITextInputIconProps) {
  const { color } = props;

  return (
    <Svg
      data-test-id={MailIconSvg.displayName}
      height="18"
      viewBox="0 0 127.472 88.25"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M122.991,0H4.481L63.736,59.255Z" fill={color} />
      <Path
        d="M127.472,84.783V2.451L63.736,66.188,0,2.451V84.783l37.7-37.7,3.467,3.467-37.7,37.7H124.006l-37.7-37.7,3.466-3.467Z"
        fill={color}
      />
    </Svg>
  );
}
MailIconSvg.displayName = 'MailIconSvg';
