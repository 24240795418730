import { Position } from '@lexialearning/common-ui';
import { Justify } from 'common-styles';

export class FunFactsBubbleStyles {
  private static readonly styles = {
    container: {
      justifyContent: Justify.Start,
      position: Position.Absolute,
      right: 65,
      top: 20
    },
    contentContainer: {
      paddingHorizontal: 13,
      paddingVertical: 7,
      position: Position.Absolute
    },
    funFactsButtonsContainer: {
      flex: 1,
      justifyContent: Justify.SpaceBetween
    },
    title: {
      flex: 1,
      fontSize: 16
    }
  };

  public static get() {
    return this.styles;
  }
}
