export interface IStormVersion {
  'storm-react'?: string;
  storm_core: string;
  storm_lib: IStormBuild;
}

export interface IStormBuild {
  build?: string;
  revision?: string | number;
  runtime?: string;
  ios?: IStormBuild;
  android?: {};
}

export enum StormStatus {
  Initializing = 'initializing',
  Initialized = 'initialized',
  Uninitialized = 'uninitialized'
}
