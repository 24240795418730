import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import {
  IScreenplayActionFactory,
  ScreenplayerType
} from '../screenplayer.model';
import { IScreenplayDelay } from './delay-player.model';

export class DelayScreenplayActionFactory
  implements IScreenplayActionFactory<IScreenplayDelay>
{
  public static readonly DefaultDelayMs = 500;

  public static create(delayMs?: number): IScreenplayAction<IScreenplayDelay> {
    return {
      data: { delayMs: delayMs || DelayScreenplayActionFactory.DefaultDelayMs },
      type: ScreenplayerType.Delay
    };
  }

  public readonly type = ScreenplayerType.Delay;

  public create(data?: IScreenplayDelay): IScreenplayAction<IScreenplayDelay> {
    return DelayScreenplayActionFactory.create(data && data.delayMs);
  }
}
