import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  Font,
  ILoboAnimatedValues,
  ILoboAnimations,
  Justify,
  loboAnimated,
  ShadowStyle,
  TextAlign
} from 'common-styles';
import { ThemeSize } from 'theme';
import { OrderingDimensions } from '../ordering/OrderingDimensions';
import { Position } from '@lexialearning/common-ui';

const ColorValues = {
  Black: 1,
  Blue: 3,
  Green: 2,
  Transparent: 0
};

export const TextSegmentDimension = {
  HorizontalPadding: 10
};

interface ITextSegmentStyleProps {
  themeSize?: ThemeSize;
  width?: number;
  verticalSpacing?: number;
  horizontalSpacing?: number;
  draggingOpacity: number;
  isDragging?: boolean;
  isDraggingOrDropping?: boolean;
}

export class TextSegmentAnimatedStyles {
  public static readonly AnimationDuration = 1000;

  private readonly animatedValues: ILoboAnimatedValues;

  private readonly animations: ILoboAnimations;

  constructor() {
    const textColorValue = loboAnimated.createValue(1);
    const textColor = loboAnimated.interpolate(
      textColorValue,
      [
        ColorValues.Transparent,
        ColorValues.Black,
        ColorValues.Green,
        ColorValues.Blue
      ],
      [Color.Transparent, Color.Black, Color.Green, Color.Blue]
    );

    const backgroundOpacity = loboAnimated.createValue(1);

    this.animatedValues = {
      backgroundOpacity,
      textColor
    };

    const setTextTransparent = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      textColorValue,
      {
        duration: 0,
        toValue: ColorValues.Transparent
      }
    );

    const setTextBlack = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      textColorValue,
      {
        duration: 0,
        toValue: ColorValues.Black
      }
    );

    const setTextBlue = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      textColorValue,
      {
        duration: 0,
        toValue: ColorValues.Blue
      }
    );

    const setTextGreen = loboAnimated.timing(
      AnimatableCSSProperty.Color,
      textColorValue,
      {
        duration: TextSegmentAnimatedStyles.AnimationDuration * 0.25,
        toValue: ColorValues.Green
      }
    );

    const hidePill = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      backgroundOpacity,
      {
        delay: TextSegmentAnimatedStyles.AnimationDuration * 0.5,
        duration: TextSegmentAnimatedStyles.AnimationDuration * 0.25,
        toValue: 0
      }
    );

    const setTextGreenAndHidePill = loboAnimated.sequence([
      setTextGreen,
      hidePill
    ]);

    this.animations = {
      setTextBlack,
      setTextBlue,
      setTextGreen,
      setTextGreenAndHidePill,
      setTextTransparent
    };
  }

  private readonly buildMemoized = memoizeOne(
    (
      isDragging: boolean | undefined,
      isDropping: boolean | undefined,
      draggingOpacity: number,
      themeSize?: ThemeSize,
      verticalSpacing?: number,
      horizontalSpacing?: number,
      width?: number
    ) => ({
      background: {
        ...(!(isDragging || isDropping) && ShadowStyle.activity),
        backgroundColor: Color.NearWhite,
        borderRadius: 30,
        bottom: 0,
        left: 0,
        position: Position.Absolute,
        right: 0,
        top: 0
      },
      backgroundAnimated: {
        opacity: this.animatedValues.backgroundOpacity
      },
      container: {
        alignItems: Align.Center,
        height: OrderingDimensions.textPillHeight(themeSize!),
        justifyContent: Justify.Center,
        marginHorizontal: horizontalSpacing || 0,
        marginVertical: verticalSpacing || 20,
        opacity: isDragging ? draggingOpacity : isDropping ? 0 : 1,
        paddingHorizontal: TextSegmentDimension.HorizontalPadding,
        width
      },
      text: {
        fontFamily: Font.Family.semiBold,
        textAlign: TextAlign.Center
      },
      textAnimated: {
        color: this.animatedValues.textColor
      }
    })
  );

  public build(props: ITextSegmentStyleProps) {
    const {
      isDragging,
      isDraggingOrDropping,
      draggingOpacity,
      themeSize,
      verticalSpacing,
      horizontalSpacing,
      width
    } = props;

    // Leaving this buildMemoized as otherwise it would require destructuring
    // a lot of props on the component side, so it seems cleaner to leave it here
    return this.buildMemoized(
      isDragging,
      !isDragging && isDraggingOrDropping,
      draggingOpacity,
      themeSize,
      verticalSpacing,
      horizontalSpacing,
      width
    );
  }

  public getAnimations() {
    return this.animations;
  }
}
