import { UnitSavePointStatus } from '@lexialearning/lobo-common/main-model/curriculum';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  RoundActionIntro,
  RoundActionType
} from 'feature-areas/transitions/builders/rounds';
import { ProfileSelector } from 'services/profile';
import {
  ProgramContextSelector,
  SavePointAction,
  SavePointActionCreated
} from '../../redux';
import { SavePointBuilder } from './SavePointBuilder';

/**
 * Creates and appends a save point (IUnitSavePoint) unless one already exists
 * for the same unit id (for which saving has not been requested).
 * Importantly, this timestamps when work (presumably) started on the unit
 * since it was last saved.
 */
export function createSavePointEpic(
  action$: Observable<RoundActionIntro>,
  state$: StateObservable<unknown>
): Observable<SavePointActionCreated> {
  return action$.pipe(
    ofType(RoundActionType.Intro),
    map(() => ProgramContextSelector.getRoundContext(state$.value)),
    filter(context =>
      shouldCreateSavePoint(context.parentUnit.sysId, state$.value)
    ),
    map(context => {
      const { savePoint } = SavePointBuilder.create(
        context.parentUnit.sysId,
        context.subunitType
      );

      return SavePointAction.created(savePoint);
    })
  );
}
createSavePointEpic.displayName = 'createSavePointEpic';

/**
 * If a save point already exists for the unit, no need to create a new one
 * unless the pre-existing save point is being (or was) saved.
 */
function shouldCreateSavePoint(activeUnitId: string, state: unknown): boolean {
  if (!ProfileSelector.isStudentLoggedIn(state)) {
    return false;
  }

  const savePoints = ProgramContextSelector.getSavePoints(state);
  const savePointAlreadyExists = savePoints.some(
    sp =>
      sp.unitId === activeUnitId && sp.status === UnitSavePointStatus.Deferred
  );

  return !savePointAlreadyExists;
}
