import { ILevelEducator } from '@lexialearning/lobo-common/main-model';
import { ActionType, createAction } from 'typesafe-actions';
import { EducatorActionType } from './educator-redux.model';

export const EducatorAction = {
  loadLevelsSuccess: createAction(EducatorActionType.LoadLevelsSuccess)<
    ILevelEducator[]
  >(),
  selectLevelActivity: createAction(
    EducatorActionType.SelectLevelActivity
  )<ISelectLevelActivityActionPayload>()
};

export interface ISelectLevelActivityActionPayload {
  activityId: string;
  levelId: string;
}

export type EducatorActionLoadLevelsSuccess = ActionType<
  typeof EducatorAction.loadLevelsSuccess
>;
export type EducatorActionSelectLevelActivity = ActionType<
  typeof EducatorAction.selectLevelActivity
>;
export type EducatorActions = ActionType<typeof EducatorAction>;
