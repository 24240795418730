import { DeployEnvironment } from '@lexialearning/main-model';
import { DeployConfig } from 'services/app-config/DeployConfig';
import { deepLinkEpics } from '../deep-link/epics/deepLink.epic';
import { playTestScreenplayEpic } from '../screenplay-tester/playTestScreenplay.epic';
import { adjustToCompletedActivityEpic } from './adjustToCompletedActivity.epic';
import { openContentfulEntryEpic } from './openContentfulEntry.epic';
import { playLevelCompleteLevelIntroEpic } from './playLevelCompleteLevelIntro.epic';
import { shortcutToRoundEpic } from './shortcutToRound.epic';
import { showUpdatedLevelSceneEpic } from './showUpdatedLevelScene.epic';
import { skipToLevelPageEpic } from './skipToLevelPage.epic';
import { updateHasOnscreenCharacterEpic } from './updateHasOnscreenCharacter.epic';
import { logSreSessionInfoEpic } from './logSreSessionInfo.epic';
import { appConfig } from 'services/app-config/appConfig';

const nonDeepLinkEpics = [
  adjustToCompletedActivityEpic,
  logSreSessionInfoEpic,
  openContentfulEntryEpic,
  playLevelCompleteLevelIntroEpic,
  playTestScreenplayEpic,
  shortcutToRoundEpic,
  showUpdatedLevelSceneEpic,
  skipToLevelPageEpic,
  updateHasOnscreenCharacterEpic
];

/** Limited to debug environments */
const shouldAllowNonDeepLinkEpics = appConfig.isDebug;

/**
 * Limited to all lower environments plus StagingDebug, however,
 * it is only allowed by default in debug environments, as gated by autoLogin
 *  @see LoginActionFactory - autoLogin
 * - all other environments require the 'trainingCode' queryString to be present for deep linking
 * - (and for StagingDebug, the proper 'config.contentful.accessToken' must also be provided)
 */
const shouldAllowDeepLinkEpics = [
  DeployEnvironment.Local,
  DeployEnvironment.Develop,
  DeployEnvironment.DevelopDebug,
  DeployEnvironment.DevelopLatest,
  DeployEnvironment.Main,
  DeployEnvironment.MainDebug,
  DeployEnvironment.MainLatest,
  DeployEnvironment.StagingDebug
].includes(DeployConfig.deployEnvironment);

export const devToolsEpic = [
  ...(shouldAllowDeepLinkEpics ? deepLinkEpics : []),
  ...(shouldAllowNonDeepLinkEpics ? nonDeepLinkEpics : [])
];
