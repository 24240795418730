import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { ActionType, createAction, EmptyAction } from 'typesafe-actions';
import { ScreenplayActionType } from './screenplay-redux.model';

const action = {
  cancel: createAction(ScreenplayActionType.Cancel)(),
  cancelMusic: createAction(ScreenplayActionType.CancelMusic)(),
  canceled: createAction(
    ScreenplayActionType.Canceled
  )<ICanceledActionPayload>(),
  pause: createAction(ScreenplayActionType.Pause)(),
  play: createAction(ScreenplayActionType.Play)<IPlayActionPayload>(),
  playComplete: createAction(
    ScreenplayActionType.PlayComplete
  )<IPlayActionPayload>(),
  playing: createAction(ScreenplayActionType.Playing)<IPlayingActionPayload>(),
  resume: createAction(ScreenplayActionType.Resume)(),
  skip: createAction(ScreenplayActionType.Skip)()
};

export interface ICanceledActionPayload {
  screenplay: IScreenplay;
}

export interface IPlayActionPayload {
  screenplay: IScreenplay;
  nextAction?: EmptyAction<string>;
}

export interface IPlayingActionPayload {
  actionIndex: number;
  actionType: string;
}

export { action as ScreenplayAction };

export type ScreenplayActionCancel = ActionType<typeof action.cancel>;
export type ScreenplayActionCancelMusic = ActionType<typeof action.cancelMusic>;
export type ScreenplayActionComplete = ActionType<typeof action.playComplete>;
export type ScreenplayActionPause = ActionType<typeof action.pause>;
export type ScreenplayActionPlay = ActionType<typeof action.play>;
export type ScreenplayActionPlaying = ActionType<typeof action.playing>;
export type ScreenplayActionResume = ActionType<typeof action.resume>;
export type ScreenplayActionSkip = ActionType<typeof action.skip>;
export type ScreenplayActions = ActionType<typeof action>;
