import { Overflow } from '@lexialearning/common-ui';
import { Color } from 'common-styles';

export class ActivityCarouselModalStyles {
  public static readonly SlideWidth = 740;

  private static readonly SlideHeight = 640;

  private static readonly styles = {
    carouselOverrides: {
      arrowButtonSvg: {
        height: 42,
        width: 50
      },
      container: {
        height: ActivityCarouselModalStyles.SlideHeight,
        width: ActivityCarouselModalStyles.SlideWidth
      },
      controlContainerLeft: {
        left: -60
      },
      controlContainerRight: {
        right: -60
      },
      scrollContainer: {
        backgroundColor: Color.NearWhite,
        borderRadius: 10,
        height: ActivityCarouselModalStyles.SlideHeight,
        overflow: Overflow.Hidden,
        width: ActivityCarouselModalStyles.SlideWidth
      }
    }
  };

  public static get() {
    return this.styles;
  }
}
