import { AccessibilityRole } from '@lexialearning/common-ui';
import * as React from 'react';
import { Column, Row } from 'common-ui';
import { Copyright, ProductInfo } from 'shared-components';
import { ModalRegistry } from 'shared-components/modals';
import { AccountInfo } from './account-info/AccountInfo';
import {
  AccountSettingsTabId,
  AccountSettingsTabList
} from './account-settings-tab-list/AccountSettingsTabList';
import { AccountSettingsModalStyles } from './AccountSettingsModal.styles';
import { LegalInfoWebView } from './legal-info-webview/LegalInfoWebView';
import { Notices } from './notices/Notices';
import { StudentInfo } from './student-info/StudentInfo';

interface IAccountSettingsModalState {
  selectedTabId: AccountSettingsTabId;
}

export class AccountSettingsModal extends React.PureComponent<
  {},
  IAccountSettingsModalState
> {
  public static readonly displayName = 'AccountSettingsModal';

  public static readonly ModalId = ModalRegistry.register({
    Component: AccountSettingsModal,
    title: `${ProductInfo.FullName} ${ProductInfo.Version.toUpperCase()}`
  });

  private static readonly TabIdToComponentMap = {
    [AccountSettingsTabId.Account]: AccountInfo,
    [AccountSettingsTabId.StudentInfo]: StudentInfo,
    [AccountSettingsTabId.Notices]: Notices,
    [AccountSettingsTabId.Privacy]: LegalInfoWebView,
    [AccountSettingsTabId.License]: LegalInfoWebView
  };

  constructor(props: {}) {
    super(props);
    this.state = {
      selectedTabId: AccountSettingsTabId.Account
    };
    this.updateSelectedTab = this.updateSelectedTab.bind(this);
  }

  private updateSelectedTab(tabId: AccountSettingsTabId) {
    this.setState({ selectedTabId: tabId });
  }

  public render() {
    const { selectedTabId } = this.state;
    const styles = AccountSettingsModalStyles.get();
    const Content = AccountSettingsModal.TabIdToComponentMap[selectedTabId];
    const tabPanelId = 'settings-panel';

    return (
      <Column style={styles.mainColumn}>
        <Row style={styles.mainRow}>
          <AccountSettingsTabList
            selectedTabId={selectedTabId}
            tabPanelId={tabPanelId}
            updateSelectedTab={this.updateSelectedTab}
          />
          <Column
            accessibilityRole={AccessibilityRole.TabPanel}
            ariaLabelledBy={selectedTabId}
            id={tabPanelId}
            style={styles.contentArea}
          >
            <Content tabId={selectedTabId} />
          </Column>
        </Row>
        <Row style={styles.footerInfo}>
          <Copyright />
        </Row>
      </Column>
    );
  }
}
