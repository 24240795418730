import * as React from 'react';
import { AnimatedView, Image, View } from 'common-ui';
import { Services } from 'services';
import { FlareSvg } from './flare/Flare.lx-svg';
import { TitlePillBadgeReactAnimationName } from './title-pill-badge.model';
import { TitlePillBadgeAnimatedStyles } from './TitlePillBadge.animated-styles';

export interface ITitlePillBadgeProps {
  id?: string;
  imageSrc: string | undefined;
  accessibilityLabel: string | undefined;
}

export class TitlePillBadge extends React.PureComponent<ITitlePillBadgeProps> {
  public static readonly displayName = 'TitlePillBadge';

  private readonly animatedStyles: TitlePillBadgeAnimatedStyles;
  private readonly unregisterAnimations?: () => void;

  constructor(props: ITitlePillBadgeProps) {
    super(props);

    this.animatedStyles = new TitlePillBadgeAnimatedStyles();
    if (props.id) {
      this.registerAnimations(props.id);
      this.unregisterAnimations = this.registerAnimations(props.id);
    }
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations?.();
  }

  private registerAnimations(id: string): () => void {
    const { flare, imageHide } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      {
        name: TitlePillBadgeReactAnimationName.buildFlare(id),
        animation: flare
      },
      {
        name: TitlePillBadgeReactAnimationName.buildImageHide(id),
        animation: imageHide
      }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  private renderImage() {
    const { accessibilityLabel, imageSrc } = this.props;
    const styles = this.animatedStyles.get();

    if (!imageSrc) {
      return null;
    }

    return (
      <Image
        source={imageSrc}
        style={styles.image}
        accessibilityLabel={accessibilityLabel}
      />
    );
  }

  public render() {
    const { id } = this.props;
    const styles = this.animatedStyles.get();

    return (
      <View testId={TitlePillBadge.displayName} style={styles.container}>
        {id ? (
          <>
            <AnimatedView animatedStyle={styles.flare}>
              <FlareSvg />
            </AnimatedView>
            <AnimatedView animatedStyle={styles.imageContainer}>
              {this.renderImage()}
            </AnimatedView>
          </>
        ) : (
          this.renderImage()
        )}
      </View>
    );
  }
}
