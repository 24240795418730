import { Position } from '@lexialearning/common-ui';
import { Types } from 'common-ui';

export class SubmitAvatarButtonStyles {
  private readonly styles: {
    submitButton: Types.ViewStyle;
  };

  constructor() {
    this.styles = {
      submitButton: {
        position: Position.Absolute
      }
    };
  }

  public get() {
    return this.styles;
  }
}
