import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterService, RoutePath, ILocationState } from 'router-service';
import { NavigationButtonType } from '../app-shell.model';
import {
  AppShellAction,
  AppShellActionNotifyNavigationHistoryUpdate,
  AppShellActionSetNavigationButtonType
} from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

export function updateNavigationButtonTypeEpic(
  action$: Observable<AppShellActionNotifyNavigationHistoryUpdate>
): Observable<AppShellActionSetNavigationButtonType> {
  return action$.pipe(
    ofType(AppShellActionType.NotifyNavigationHistoryUpdate),
    map(() => {
      const locationState: ILocationState =
        RouterService.history.location.state || {};
      const { prevPath } = locationState;

      const shouldShowLogoutButton =
        // When routing to Avatar Editor from a page other than login
        // the prevPath should be set in state (second arg of history.replace())
        // in order to know where to nav back to upon completion of editing.
        // So if that is not present, the expectation is the user came from login
        // and thus the navigation button should be a logout button
        (RouterService.isOnRoute(RoutePath.AvatarEditor) && !prevPath) ||
        RouterService.isOnRoute([
          RoutePath.Calibration,
          RoutePath.Home,
          RoutePath.Educator,
          RoutePath.EducatorTab,
          RoutePath.Onboarding,
          RoutePath.OnboardingOrientation,
          RoutePath.OnboardingRounds,
          RoutePath.PlacementComplete,
          RoutePath.PlacementRounds
        ]);

      const type = shouldShowLogoutButton
        ? NavigationButtonType.Logout
        : NavigationButtonType.Back;

      return AppShellAction.setNavigationButtonType(type);
    })
  );
}
updateNavigationButtonTypeEpic.displayName = 'updateNavigationButtonTypeEpic';
