import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { LexiaError } from '@lexialearning/utils';
import { LexiaStandardErrorCode } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthApi } from 'lexia-service/auth-api';
import { SpinnerHandler } from 'spinner-handler';
import {
  AuthAction,
  AuthActionAuthenticate,
  AuthActionAuthenticateFailure,
  AuthActionAuthenticateSuccess,
  AuthActionType
} from '../redux';

export interface IAuthenticateDeps {
  authApi: AuthApi;
  configProvider: IConfigProvider;
}

export function authenticateEpic(
  action$: Observable<AuthActionAuthenticate>,
  _: StateObservable<unknown>,
  deps: IAuthenticateDeps
): Observable<AuthActionAuthenticateSuccess | AuthActionAuthenticateFailure> {
  return action$.pipe(
    ofType(AuthActionType.Authenticate),
    mergeMap(async action => {
      SpinnerHandler.requestSpinner();

      try {
        const { authApi } = deps;
        const { username, password } = action.payload;
        const details = await authApi.authenticate(username, password);

        return AuthAction.authenticate.success(details);
      } catch (error) {
        return maybeHandleError(error);
      } finally {
        SpinnerHandler.dismissSpinner();
      }
    })
  );
}
authenticateEpic.displayName = 'authenticateEpic';

function maybeHandleError(error: Error): AuthActionAuthenticateFailure {
  if (
    error instanceof LexiaError &&
    [
      LexiaStandardErrorCode.UnknownUsername,
      LexiaStandardErrorCode.WrongPassword
    ].includes(error.standardCode!)
  ) {
    return AuthAction.authenticate.failure(error);
  }

  throw error;
}
