import { DeployEnvironment } from '@lexialearning/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  AppConfigAction,
  IAppConfigUpdateEntryPayload
} from 'app-config/redux';
import {
  Column,
  Row,
  Text,
  TextButton,
  TextButtonType,
  TextInput,
  Checkbox
} from 'common-ui';
import {
  AUTH_SERVER_CONFIG_STRING,
  SHOW_AUTH_URL_CONFIG_STRING
} from 'lexia-service/redux/lexia-service-redux.model';
import { ILoboAppConfig } from 'services/app-config';
import {
  determineAuthServerUrl,
  determineStudentApiUrl
} from 'services/app-config/appConfig';
import { ModalRegistry } from 'shared-components/modals';
import { AppShellAction } from 'shell';
import { SystemInfo } from 'utils';
import { OpenCommandLineModalStyles } from './OpenCommandLineModal.styles';
import { STUDENT_API_URL_CONFIG_STRING } from 'student-api';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppState } from 'services';

export interface IOpenCommandLineModalState {
  authUrl: string;
  studentApiUrl: string;
  shouldShowAuthUrl: boolean;
  useStagingUrls: boolean;
}

export interface IOpenCommandLineModalProps {
  authUrl: string;
  showAuthUrl: boolean;
  studentApiUrl: string;
  updateEntries(payload: IAppConfigUpdateEntryPayload[]): void;
  closeModal(): void;
}

export class OpenCommandLineModalComponent extends React.PureComponent<
  IOpenCommandLineModalProps,
  IOpenCommandLineModalState
> {
  public static readonly displayName = 'OpenCommandLineModal';

  constructor(props: IOpenCommandLineModalProps) {
    super(props);
    this.state = {
      authUrl: props.authUrl,
      shouldShowAuthUrl: props.showAuthUrl,
      studentApiUrl: props.studentApiUrl,
      useStagingUrls: false
    };

    this.handleChangeAuthUrl = this.handleChangeAuthUrl.bind(this);
    this.handleChangeStudentApiUrl = this.handleChangeStudentApiUrl.bind(this);
    this.handleToggleShowAuthUrl = this.handleToggleShowAuthUrl.bind(this);
    this.handleSetToStaging = this.handleSetToStaging.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  private handleChangeAuthUrl(value: string) {
    this.setState({ authUrl: value });
  }

  private handleChangeStudentApiUrl(value: string) {
    this.setState({ studentApiUrl: value });
  }

  private handleToggleShowAuthUrl() {
    const { shouldShowAuthUrl } = this.state;
    this.setState({ shouldShowAuthUrl: !shouldShowAuthUrl });
  }

  private handleSetToStaging() {
    const staginEnv = DeployEnvironment.Staging;
    this.setState({
      authUrl: determineAuthServerUrl(staginEnv),
      studentApiUrl: determineStudentApiUrl(staginEnv)
    });
  }

  private getUrlOrigin(url: string): string {
    return url.split('/', 3).join('/');
  }

  private handleSave() {
    const { authUrl, shouldShowAuthUrl, studentApiUrl } = this.state;
    const { updateEntries } = this.props;
    // Remove any path in the entered url before saving
    // Just doing this for studentApi value as previously it needed to be entered
    // with the pathname of '/api/ell', so correcting for those who are still using that
    const cleanedStudentApiUrlValue = this.getUrlOrigin(studentApiUrl);

    const authServer: IAppConfigUpdateEntryPayload = {
      key: AUTH_SERVER_CONFIG_STRING,
      value: authUrl
    };

    const studentApi: IAppConfigUpdateEntryPayload = {
      key: STUDENT_API_URL_CONFIG_STRING,
      value: cleanedStudentApiUrlValue
    };

    const showAuthUrl: IAppConfigUpdateEntryPayload = {
      key: SHOW_AUTH_URL_CONFIG_STRING,
      value: shouldShowAuthUrl
    };

    updateEntries([authServer, showAuthUrl, studentApi]);

    this.props.closeModal();
  }

  public render() {
    const { authUrl, shouldShowAuthUrl, studentApiUrl } = this.state;
    const styles = OpenCommandLineModalStyles.get();

    return (
      <Column style={styles.container}>
        <Text style={styles.titleText}>Auth Server</Text>
        <TextInput
          autoCapitalize="none"
          autoFocus
          name="authUrl"
          onChange={this.handleChangeAuthUrl}
          styleOverrides={styles.urlInput}
          testId="authUrl"
          value={authUrl}
        />
        <Text style={styles.titleText}>Student API</Text>
        <TextInput
          autoCapitalize="none"
          name="studentApiUrl"
          onChange={this.handleChangeStudentApiUrl}
          styleOverrides={styles.urlInput}
          testId="studentApiUrl"
          value={studentApiUrl}
        />
        <Checkbox
          isChecked={shouldShowAuthUrl}
          onToggle={this.handleToggleShowAuthUrl}
          styleOverride={styles.checkboxOverrides}
          label="Debug"
          labelId="show-auth-url"
        />
        <Row>
          <TextButton
            onPress={this.handleSetToStaging}
            text="Set to Staging Urls"
            buttonType={TextButtonType.Secondary}
          />
          <TextButton
            onPress={this.handleSave}
            text="Save"
            buttonType={TextButtonType.Primary}
          />
        </Row>
      </Column>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: AppState) {
  const appConfig = UniConfigSelector.getConfig<ILoboAppConfig>(state);

  return {
    authUrl: appConfig.lexiaService.customerUrl,
    showAuthUrl: appConfig.showAuthUrl,
    studentApiUrl: appConfig.studentApi.apiDomain
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  closeModal: () => AppShellAction.hideModal(),
  updateEntries: (payload: IAppConfigUpdateEntryPayload[]) =>
    AppConfigAction.updateEntries(payload)
};

export const OpenCommandLineModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenCommandLineModalComponent);

// istanbul ignore next - trivial code, not worth testing
OpenCommandLineModal.ModalId = ModalRegistry.register({
  Component: OpenCommandLineModal,
  styleOverride: SystemInfo.isNative
    ? OpenCommandLineModalStyles.getModalOverride()
    : {},
  title: 'QA Settings'
});
