import * as React from 'react';
import { ConnectedComponent } from 'react-redux';
import { ITextButtonIconProps, Types } from 'common-ui';
import { IModalStyleOverride } from 'common-ui/components/modal/Modal.animated-styles';
import { IModalContainerStyleOverride } from './ModalContainer.styles';

export type ModalId = string;

export enum ModalType {
  Confirmation = 'confirmation',
  Default = 'default',
  Error = 'error'
}

export interface IModalAction {
  disabled?: boolean;
  ignoreGlobalDisabled?: boolean;
  Icon?: React.ElementType<ITextButtonIconProps>;
  onPress(e: Types.SyntheticEvent): void;
  text: string;
}

export interface IModalConfig {
  Component: React.ComponentType | ConnectedComponent<any, any>;
  AnchorComponent?: React.ComponentType | ConnectedComponent<any, any>;
  getTeacherVO?: boolean;
  headline?: string;
  /**
   * In general Component's displayName should be used as an id, explicitly
   * define an id only when you have multiple modals using the same Component
   */
  id?: string;
  image?: string;
  preventDismiss?: boolean;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  /**
   * This means it will just render the Component inside a Modal, without any
   * additional styling.
   * Used for complete customization since now the consumer completely controls style
   */
  rawLayout?: boolean;
  suppressOpeningSfx?: boolean;
  suppressClosingSfx?: boolean;
  text?: string;
  title?: string;
  type?: ModalType;
  styleOverride?: IModalStyleOverride & IModalContainerStyleOverride;
}
