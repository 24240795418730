import {
  UserGlobalActionLogout,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICurriculumDependencies } from '../../CurriculumDependencies';
import {
  LevelAction,
  LevelActionType,
  LevelActionUnload,
  LevelActionUnloadSuccess,
  LevelSelector
} from '../redux';

export function unloadLevelEpic(
  action$: Observable<LevelActionUnload | UserGlobalActionLogout>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<LevelActionUnloadSuccess> {
  return action$.pipe(
    ofType(LevelActionType.Unload, UserGlobalActionType.Logout),
    map(() => {
      const mount = LevelSelector.getMount(state$.value);
      if (mount) {
        deps.curriculumDependencies.stormService.unmount(mount);
      }

      return LevelAction.unload.success();
    })
  );
}
unloadLevelEpic.displayName = 'unloadLevelEpic';
