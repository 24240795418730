import { LexiaError } from '@lexialearning/utils';
import { Action } from 'redux';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  IPlayActionPayload,
  ScreenplayAction,
  ScreenplayActionCancel,
  ScreenplayActionComplete,
  ScreenplayActionPlay,
  ScreenplayActionPlaying,
  ScreenplayActionType
} from '../redux';
import { IScreenplayEvent, ScreenplayEvent } from '../screenplayers';
import { IScreenplayDeps } from './screenplay-epic.model';

const EPIC_NAME = 'playEpic';

export type PlayScreenplayEpicOutputActions =
  | ScreenplayActionPlaying
  | ScreenplayActionComplete
  | ScreenplayActionCancel;

export function playScreenplayEpic(
  action$: Observable<ScreenplayActionPlay>,
  _$: StateObservable<unknown>,
  deps: IScreenplayDeps
): Observable<PlayScreenplayEpicOutputActions> {
  return action$.pipe(
    ofType(ScreenplayActionType.Play),
    mergeMap(action => {
      const { screenplayer } = deps;
      const { screenplay } = action.payload;

      if (screenplayer.isPlaying(screenplay.id)) {
        // A request to re-play the currently playing screenplay is treated as a toggle
        return from([ScreenplayAction.cancel()]);
      }

      return screenplayer
        .play(screenplay)
        .pipe(
          mergeMap(e => from(mapPlaybackEventToReduxActions(e, action.payload)))
        );
    })
  );
}
playScreenplayEpic.displayName = 'playScreenplayEpic';

function mapPlaybackEventToReduxActions(
  event: IScreenplayEvent,
  payload: IPlayActionPayload
): Action[] {
  const outputActions = [] as (Action | undefined)[];

  switch (event.type) {
    case ScreenplayEvent.AfterAction:
      outputActions.push(event.action?.reduxActions?.after);
      break;

    case ScreenplayEvent.BeforeAction:
      outputActions.push(
        event.action?.reduxActions?.before,
        ScreenplayAction.playing({
          actionIndex: event.actionIndex!,
          actionType: event.action?.type!
        })
      );
      break;

    case ScreenplayEvent.Canceled:
      outputActions.push(
        ScreenplayAction.canceled({ screenplay: payload.screenplay })
      );
      break;

    case ScreenplayEvent.Completed:
    case ScreenplayEvent.Skipped:
      outputActions.push(ScreenplayAction.playComplete(payload));
      break;

    default:
      throw new LexiaError(
        `Unrecognized screenplay event type ${event.type}`,
        EPIC_NAME,
        PlayScreenplayEpicErrorCode.UnrecognizedScreenplayEventType
      ).withContext({ event, payload });
  }

  return outputActions.filter(a => a) as Action[];
}

export enum PlayScreenplayEpicErrorCode {
  UnrecognizedScreenplayEventType = 'UnrecognizedScreenplayEventType'
}
