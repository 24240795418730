import {
  ICalibration,
  IListenResult,
  IMicrophone,
  ISreConfig,
  ISreSession,
  SreConfigStatus,
  SreSessionType,
  SreStatus
} from '@lexialearning/lobo-common/main-model/sre';
import { CalibrationResult } from '@lexialearning/sre';
import { createSelector } from '@reduxjs/toolkit';
import { SreState } from './sre.reducer';

export class SreSelector {
  public static readonly displayName = 'SreSelector';

  public static getState: (state: unknown) => SreState;

  public static getAvailableMics: (state: unknown) => IMicrophone[];

  public static getSelectedMic: (state: unknown) => IMicrophone | undefined;

  public static getMicBlocked: (state: unknown) => boolean;

  public static getCalibration: (state: unknown) => ICalibration;

  public static getCalibrationResult: (state: unknown) => CalibrationResult;

  public static getConfig: (state: unknown) => ISreConfig;

  public static getIsInitialized: (state: unknown) => boolean;

  public static getIsCanceling: (state: unknown) => boolean;

  public static getIsListening: (state: unknown) => boolean;

  public static getIsListeningTo: (
    sessionType: SreSessionType,
    state: unknown
  ) => boolean;

  public static getSession: (state: unknown) => ISreSession;

  public static getResult: (state: unknown) => IListenResult | undefined;

  public static getSessionType: (state: unknown) => SreSessionType;

  public static getStatus: (state: unknown) => SreStatus;

  public static createSelectors(selector: (state: any) => SreState): void {
    SreSelector.getState = createSelector(selector, (state: SreState) => state);

    SreSelector.getCalibration = createSelector(
      selector,
      (state: SreState) => state.calibration
    );
    SreSelector.getCalibrationResult = createSelector(
      SreSelector.getCalibration,
      (calibration: ICalibration) => calibration.result
    );

    SreSelector.getConfig = createSelector(
      selector,
      (state: SreState) => state.config
    );

    SreSelector.getIsInitialized = createSelector(
      selector,
      (state: SreState) => state.config.status === SreConfigStatus.Initialized
    );

    SreSelector.getAvailableMics = createSelector(
      SreSelector.getConfig,
      (config: ISreConfig) => config.micsAvailable
    );

    SreSelector.getSelectedMic = createSelector(
      SreSelector.getConfig,
      (config: ISreConfig) => config.mic
    );

    SreSelector.getMicBlocked = createSelector(
      SreSelector.getConfig,
      (config: ISreConfig) => config.micBlocked
    );

    SreSelector.getSession = createSelector(
      selector,
      (state: SreState) => state.session
    );

    SreSelector.getResult = createSelector(
      SreSelector.getSession,
      (session: ISreSession) => session.result
    );

    SreSelector.getSessionType = createSelector(
      SreSelector.getSession,
      (session: ISreSession) => session.sessionType
    );

    SreSelector.getStatus = createSelector(
      selector,
      SreSelector.determineSreStatus
    );

    SreSelector.getIsCanceling = createSelector(
      SreSelector.getSession,
      (session: ISreSession) => session.isCanceling
    );

    SreSelector.getIsListening = createSelector(
      SreSelector.getStatus,
      (status: SreStatus) => status === SreStatus.Listening
    );

    SreSelector.getIsListeningTo = (
      sessionType: SreSessionType,
      state: unknown
    ) => {
      const isListening = SreSelector.getIsListening(state);
      const currentSessionType = SreSelector.getSessionType(state);

      return isListening && currentSessionType === sessionType;
    };
  }

  private static determineSreStatus(state: SreState): SreStatus {
    if (state.session.isListening) {
      return SreStatus.Listening;
    }

    switch (state.config.status) {
      case SreConfigStatus.Uninitialized:
        return SreStatus.Uninitialized;
      case SreConfigStatus.Initializing:
        return SreStatus.Initializing;
      case SreConfigStatus.Initialized:
        return SreStatus.Ready;
      default:
        return SreStatus.Disabled;
    }
  }
}
