import {
  ColorSelector,
  IColorSelectorWrapperProps,
  useHandleAvatarEditorButtonPress
} from '../../shared';
import { SkinColor } from 'services/profile/avatar.model';
import { SkinColorToSkinAttributesMap } from '../face-styles.model';
export function SkinColorOptions(props: IColorSelectorWrapperProps) {
  const { disabled, selectedColor } = props;
  const handlePress = useHandleAvatarEditorButtonPress();
  const handleSelect = (skinColor: SkinColor) => handlePress({ skinColor });

  const getHexValue = (color: SkinColor) =>
    SkinColorToSkinAttributesMap.get(color)?.skin;

  return (
    <ColorSelector
      accessibilityLabelSuffix="skin"
      colors={SkinColor}
      disabled={disabled}
      getHexValue={getHexValue}
      onSelect={handleSelect}
      selectedColor={selectedColor}
    />
  );
}
SkinColorOptions.displayName = 'SkinColorOptions';
