import * as React from 'react';
import { connect } from 'react-redux';
import { AnimatedView, View } from 'common-ui';
import { AppState } from 'services';
import { AppShellSelector } from 'feature-areas/shell';
import { AppShellAction } from 'feature-areas/shell/redux';
import { BellIconSvg } from './BellIcon.lx-svg';
import { LevelCertificateBadgeAnimatedStyles } from './LevelCertificateBadge.animated-styles';

export interface ILevelCertificateBadgeProps {
  shouldCalloutLevelCertificates: boolean;
  resetShouldCalloutLevelCertificates(): void;
}

export class LevelCertificateBadgeComponent extends React.PureComponent<ILevelCertificateBadgeProps> {
  public static readonly displayName = 'LevelCertificateBadge';

  private readonly animatedStyles: LevelCertificateBadgeAnimatedStyles;

  constructor(props: ILevelCertificateBadgeProps) {
    super(props);

    this.animatedStyles = new LevelCertificateBadgeAnimatedStyles();
  }

  public componentDidUpdate(prevProps: ILevelCertificateBadgeProps) {
    const { shouldCalloutLevelCertificates } = this.props;
    const {
      shouldCalloutLevelCertificates: prevShouldCalloutLevelCertificates
    } = prevProps;

    if (shouldCalloutLevelCertificates && !prevShouldCalloutLevelCertificates) {
      this.animatedStyles.getAnimations().callout.start(() => {
        this.props.resetShouldCalloutLevelCertificates();
      });
    }
  }

  public render() {
    const styles = this.animatedStyles.get();

    return (
      <View style={styles.container}>
        <AnimatedView animatedStyle={styles.badgeAnimated}>
          <BellIconSvg />
        </AnimatedView>
      </View>
    );
  }
}

// istanbul ignore next - trivial
const mapStateToProps = (state: AppState) => ({
  shouldCalloutLevelCertificates:
    AppShellSelector.getShouldCalloutLevelCertificates(state)
});

// istanbul ignore next - trivial
const mapDispatchToProps = {
  resetShouldCalloutLevelCertificates: () =>
    AppShellAction.setShouldCalloutLevelCertificates(false)
};

export const LevelCertificateBadge = connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelCertificateBadgeComponent);
