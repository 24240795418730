import { LevelSceneAnimationName } from 'feature-areas/levels';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { ActIntroScreenplayBuilder } from '../builders/acts';
import { HomeOutroScreenplayBuilder } from '../builders/home';
import { TransitionScreenplayBuilderBase } from '../builders/TransitionScreenplayBuilderBase';
import { ILevelToActTransitionScreenplayDeps } from '../level-to-act/LevelToActTransitionScreenplayFactory';
import { TransitionScreenplayId } from '../transition.model';

export class HomeToActTransitionBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'HomeToEncounterScreenplayBuilder';

  public static create(): HomeToActTransitionBuilder {
    return new HomeToActTransitionBuilder();
  }

  private constructor() {
    super(TransitionScreenplayId.HomeToEncounter);
  }

  public playHomeOutro(): HomeToActTransitionBuilder {
    this.builder.addScreenplay(HomeOutroScreenplayBuilder.create().screenplay);

    return this;
  }

  public navToActShowcase(activityId: string): HomeToActTransitionBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(RouteBuilder.acts(activityId));
    });

    return this;
  }

  public playActIntro(
    preparedScenes: PreparedScenes,
    screenplayBuilderDeps: ILevelToActTransitionScreenplayDeps
  ): HomeToActTransitionBuilder {
    this.builder
      .addCallback(
        async () => {
          const levelScene = await preparedScenes.levelReady;
          levelScene.show();
          preparedScenes.home?.hide();
        },
        { shouldUseLoadingSpinner: true }
      )
      .addStormAnimation({
        name: LevelSceneAnimationName.Root.buildCharacterSelection(
          screenplayBuilderDeps.selectedActNumber
        ),
        speed: 0,
        targetScene: SceneName.Level,
        timeAsPercent: 100
      })
      .addScreenplay(
        ActIntroScreenplayBuilder.createFor(screenplayBuilderDeps).screenplay
      );

    return this;
  }
}
