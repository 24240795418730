import { Column, Image } from 'common-ui';
import { ModalRegistry } from 'shared-components';
import { AvatarInstructionalModalStyles } from './AvatarInstructionModal.styles';
import { SubmitButton } from 'task-components/shared';
import { useDispatch } from 'react-redux';
import { AppShellAction } from 'feature-areas/shell';
import { InteractionState } from 'common-styles';
import { REPO_IMG_DIR } from 'services/app-config/appConfig';
import { AvatarEditorAction } from '../redux';
import { AvatarEditorEvent } from '@lexialearning/lobo-common/main-model/logging';

export function AvatarInstructionModal() {
  const dispatch = useDispatch();

  const styles = AvatarInstructionalModalStyles.get();
  const instructionalGif = `${REPO_IMG_DIR}/avatar/avatar-editor-how-to.gif`;

  const handlePressButton = () => {
    dispatch(AppShellAction.hideModal());
    dispatch(
      AvatarEditorAction.addUserEvent({
        eventName: AvatarEditorEvent.InstructionalGifClosed
      })
    );
  };

  return (
    <Column
      style={styles.container}
      testId={AvatarInstructionModal.displayName}
    >
      <Image
        style={styles.gif}
        source={instructionalGif}
        accessibilityLabel="How to create student avatar example."
      />
      <SubmitButton
        interactionState={InteractionState.Default}
        onPress={handlePressButton}
        styleOverride={styles.submitButton}
      />
    </Column>
  );
}
AvatarInstructionModal.displayName = 'AvatarInstructionModal';

AvatarInstructionModal.ModalId = ModalRegistry.register({
  Component: AvatarInstructionModal,
  preventDismiss: true
});
