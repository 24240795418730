export { CheckIconSvg } from './CheckIcon.lx-svg';
export { EditIconSvg } from './EditIcon.lx-svg';
export { LookBackIconSvg } from './LookBackIcon.lx-svg';
export { MicIconSvg } from './MicIcon.lx-svg';
export { PlayIconSvg } from './PlayIcon.lx-svg';
export { QuestionMarkIconSvg } from './QuestionMarkIcon.lx-svg';
export { RehearIconSvg } from './RehearIcon.lx-svg';
export { ReplayIconSvg } from './ReplayIcon.lx-svg';
export { ShapeCheckSvg } from './ShapeCheck.lx-svg';
export { ShapeXSvg } from './ShapeX.lx-svg';
export { SkipIconSvg } from './SkipIcon.lx-svg';
