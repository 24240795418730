import {
  AppShellActionHideModal,
  AppShellActionShowModal,
  AppShellActionType
} from 'feature-areas/shell';
import { AppState } from 'services';
import {
  AvatarEditorActionHideEditor,
  AvatarEditorActionShowEditor,
  AvatarEditorActionTimerRestart,
  AvatarEditorActionType,
  AvatarEditorSelector
} from 'feature-areas/avatar-editor/redux';
import { AvatarInstructionModal } from 'feature-areas/avatar-editor/instruction-modal/AvatarInstructionModal';
import { ISfxDeps } from 'audio/sfx/playSfx.epic';
import { ModalId } from 'shared-components/modals/modal.model';
import { Music } from 'audio/music';
import { Observable, filter, ignoreElements, tap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { LexiaError } from '@lexialearning/utils';
import { AvatarTimerStatus } from '../avatar-editor-redux.model';

export function toggleAvatarMusicEpic(
  action$: Observable<
    | AppShellActionHideModal
    | AppShellActionShowModal
    | AvatarEditorActionHideEditor
    | AvatarEditorActionShowEditor
    | AvatarEditorActionTimerRestart
  >,
  state$: StateObservable<AppState>,
  deps: ISfxDeps
): Observable<void> {
  const avatarMusic = { loop: true, path: Music.AvatarEditor };
  // Store modal id to check in hideModal case, as it is already gone when hideModal is called
  let openedModalId: ModalId | undefined;

  return action$.pipe(
    ofType(
      AppShellActionType.HideModal,
      AppShellActionType.ShowModal,
      AvatarEditorActionType.HideEditor,
      AvatarEditorActionType.ShowEditor,
      AvatarEditorActionType.TimerRestart
    ),
    filter(
      a =>
        a.type === AvatarEditorActionType.HideEditor ||
        AvatarEditorSelector.getShouldShowEditor(state$.value)
    ),
    tap(a => {
      const { type, payload } = a as {
        type: AvatarEditorActionType | AppShellActionType;
        payload?: { id: ModalId };
      };
      switch (type) {
        case AppShellActionType.HideModal:
          if (
            openedModalId !== AvatarInstructionModal.ModalId &&
            AvatarEditorSelector.getTimerStatus(state$.value) !==
              AvatarTimerStatus.Finished
          ) {
            deps.stormAudioPlayer.playMusic(avatarMusic);
          }
          openedModalId = undefined;
          break;
        case AppShellActionType.ShowModal:
          openedModalId = payload?.id;
          // Keep the music going for AvatarInstructionModal.
          // This modal is part of the editor experience.
          if (openedModalId !== AvatarInstructionModal.ModalId) {
            deps.stormAudioPlayer.cancelMusic();
          }
          break;
        case AvatarEditorActionType.HideEditor:
          deps.stormAudioPlayer.cancelMusic();
          break;
        case AvatarEditorActionType.ShowEditor:
          // First cancel audio that may already be playing
          deps.stormAudioPlayer.cancelVoiceover();
          deps.stormAudioPlayer.cancelMusic();
          // Then play the avatar music
          deps.stormAudioPlayer.playMusic(avatarMusic);
          break;
        case AvatarEditorActionType.TimerRestart:
          deps.stormAudioPlayer.playMusic(avatarMusic);
          break;
        // istanbul ignore next - unreachable
        default:
          throw new LexiaError(
            `Unhandled type ${type}`,
            toggleAvatarMusicEpic.displayName,
            ToggleAvatarMusicEpicError.TypeUnhandled
          ).withContext({ type });
      }
    }),
    ignoreElements()
  );
}
toggleAvatarMusicEpic.displayName = 'toggleAvatarMusicEpic';

export enum ToggleAvatarMusicEpicError {
  TypeUnhandled = 'TypeUnhandled'
}
