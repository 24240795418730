import { ModalActions } from 'shared-components/modals';
import { ReloadSvg } from './Reload.lx-svg';

export function MicBlockedAction() {
  return (
    <ModalActions
      data-test-id={MicBlockedAction.displayName}
      primaryAction={{
        Icon: ReloadSvg,
        // eslint-disable-next-line no-void
        onPress: () => window.location.reload(),

        text: 'Reload'
      }}
    />
  );
}
MicBlockedAction.displayName = 'MicBlockedAction';
