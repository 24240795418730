import { Justify } from '@lexialearning/common-ui';
import { Align } from 'common-styles';

export class AvatarShowcaseStyles {
  public static readonly styles = {
    outerContainer: {
      alignItems: Align.Center,
      height: 610,
      justifyContent: Justify.Center,
      width: 250
    },
    innerContainer: {
      height: 480,
      marginVertical: 26,
      width: 175
    },
    displayNameContainer: {
      height: 36,
      justifyContent: Justify.Center,
      width: 250
    },
    displayNameOverrides: {
      name: { fontSize: 14 }
    }
  };

  public static get() {
    return this.styles;
  }
}
