import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { Path, Svg } from 'common-ui';

export function PodcastStringSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={841.027}
      height={593.745}
      style={{ left: 46, position: Position.Absolute, top: -16 }}
    >
      <Path
        d="M425.487 593.744c-7.36 0-13.48-2.33-18.22-6.92-9.83-9.54-10.12-25.38-10.13-26.05l-2.55-516.22c0-.15.07-12.52-8.33-25.85-8.23-13.05-31.85-13.65-32.09-13.65H28.027c-.09 0-9.63-.63-13.5 3.26-3.27 3.25-3.63 15.14-3.5 19.44v17.86h-3v-17.81c0-1.67-.38-16.81 4.35-21.61s14.84-4.2 15.79-4.14h326c1.06 0 25.51.63 34.6 15.05 8.89 14.12 8.8 26.93 8.79 27.47l2.55 516.17c0 .15.3 15.28 9.23 23.94 4.28 4.14 9.93 6.17 16.72 6s12.4-2.37 16.26-6.66c7.81-8.68 6.65-23 6.64-23.19v-333.5c0-.39 3.24-39.41 45.3-39.41h.85c42.84.53 46.2 39 46.22 39.4v.11l.07 233.71 4.57.15h295.06v3h-295.07l-4.52-.14v41.4a47 47 0 01-4 16.8c-6 12.41-16.69 18.63-32.71 19h-.12c-10.91-.62-36.36-6.06-37.12-43.21v-.13c1.77-27.36 22.88-34.77 35.32-36.75 3.66-.95 21.39-.66 35.59-.25l-.07-233.57c-.17-2.1-3.61-36.08-43.26-36.57h-.83c-38.86 0-42.13 34.48-42.29 36.58v333.24c.1 1.29 1.08 15.94-7.4 25.38-4.44 4.94-10.64 7.52-18.44 7.66zm45-95.54c.7 33.28 21.71 39.48 34.22 40.21 14.75-.4 24.57-6.06 30-17.32a43.739 43.739 0 003.71-15.6v-41.42c-16.48-.46-32.59-.58-34.63.06l-.18.11h-.28c-14.09 2.28-31.22 9.98-32.8 33.96zm33.35-34.11z"
        fill={Color.BlackTransparent20}
      />
      <Path
        d="M6 29.678h11a3 3 0 013 3v21H3v-21a3 3 0 013-3z"
        fill={Color.BlackTransparent30}
      />
      <Path
        d="M424.487 591.744c-7.36 0-13.48-2.33-18.22-6.92-9.83-9.54-10.12-25.38-10.13-26.05l-2.55-516.22c0-.15.07-12.52-8.33-25.85-8.23-13.05-31.85-13.65-32.09-13.65H27.027c-.09 0-9.63-.63-13.5 3.26-3.27 3.25-3.63 15.14-3.5 19.44v17.86h-3v-17.81c0-1.67-.38-16.81 4.35-21.61s14.84-4.2 15.79-4.14h326c1.06 0 25.51.63 34.6 15.05 8.89 14.12 8.8 26.93 8.79 27.47l2.55 516.17c0 .15.3 15.28 9.23 23.94 4.28 4.14 9.93 6.17 16.72 6s12.4-2.37 16.26-6.66c7.81-8.68 6.65-23 6.64-23.19v-333.5c0-.39 3.24-39.41 45.3-39.41h.85c42.84.53 46.2 39 46.22 39.4v.11l.07 233.71 4.57.15h295.06v3h-295.07l-4.52-.14v41.4a47 47 0 01-4 16.8c-6 12.41-16.69 18.63-32.71 19h-.12c-10.91-.62-36.36-6.06-37.12-43.21v-.13c1.77-27.36 22.88-34.77 35.32-36.75 3.66-.95 21.39-.66 35.59-.25l-.07-233.57c-.17-2.1-3.61-36.08-43.26-36.57h-.83c-38.86 0-42.13 34.48-42.29 36.58v333.24c.1 1.29 1.08 15.94-7.4 25.38-4.44 4.94-10.64 7.52-18.44 7.66zm45-95.54c.7 33.28 21.71 39.48 34.22 40.21 14.75-.4 24.57-6.06 30-17.32a43.739 43.739 0 003.71-15.6v-41.42c-16.48-.46-32.59-.58-34.63.06l-.18.11h-.28c-14.09 2.28-31.22 9.98-32.8 33.96zm33.35-34.11z"
        fill={Color.Aqua}
      />
      <Path
        d="M3 26.678h11a3 3 0 013 3v24H0v-24a3 3 0 013-3z"
        fill={Color.Aqua}
      />
    </Svg>
  );
}
PodcastStringSvg.displayName = 'PodcastStringSvg';
