import { authenticateEpic } from './authenticate.epic';
import { autoLoginEpic } from './autoLogin.epic';
import { loginUserEpic } from './loginUser.epic';
import { loginUserMdmEpic } from './loginUserMdm.epic';
import { logoutUserEpic } from './logoutUser.epic';
import { mapUniLogoutEpic } from './mapUniLogout.epic';
import { nativeDeepLinkEpic } from './nativeDeepLink.epic';
import { reLoginUserEpic } from './reLoginUser.epic';
import { reloadApplicationEpic } from './reloadApplication.epic';
import { showLoginConfirmationModalEpic } from './showLoginConfirmationModal.epic';

export const authEpics = [
  authenticateEpic,
  autoLoginEpic,
  loginUserEpic,
  loginUserMdmEpic,
  logoutUserEpic,
  mapUniLogoutEpic,
  nativeDeepLinkEpic,
  reLoginUserEpic,
  reloadApplicationEpic,
  showLoginConfirmationModalEpic
];
