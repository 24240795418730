import {
  UserGlobalAction,
  UserGlobalActionLogout
} from '@lexialearning/lobo-common/main-model/user';
import { UserAction, UserActionLogOut } from '@lexialearning/main-model';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * Epic is a mapping from the Uni user logout action to the Lobo logout request action.
 * Required for the Uni Error Modals implementation for return to login.
 * @param action$ Uni UserActionLogOut action
 * @returns UserGlobalAction.logout.request with a LogoutReason as the payload
 */
export function mapUniLogoutEpic(
  action$: Observable<UserActionLogOut>
): Observable<UserGlobalActionLogout> {
  return action$.pipe(
    filter(UserAction.logOut.request.match),
    map(({ payload: logoutReason }) =>
      UserGlobalAction.logout.request(logoutReason)
    )
  );
}
mapUniLogoutEpic.displayName = 'mapUniLogoutEpic';
