import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { ActSelector, LevelSelector } from 'curriculum-services';
import {
  ActUiActionAddPlayedFunFact,
  ActUiActionType
} from 'feature-areas/acts';
import { LoboLogItemCategory } from 'logging';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

/**
 * Log the Fun Fact that was played
 */
export function logFunFactPlayedEventEpic(
  action$: Observable<ActUiActionAddPlayedFunFact>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(ActUiActionType.AddPlayedFunFact),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.FunFactPlayed),
    ignoreElements()
  );
}
logFunFactPlayedEventEpic.displayName = 'logFunFactPlayedEventEpic';

function createEvent(
  action: ActUiActionAddPlayedFunFact,
  state: AppState
): ILogItemMinimal<object, LoboLogItemCategory> {
  const level = LevelSelector.getLevel(state);
  const act = ActSelector.getAct(state);
  const funFact = act.characterFunFacts.find(ff => ff.id === action.payload.id);

  return {
    category: LoboLogItemCategory.FunFactPlayed,
    loggingLevel: LoggingLevel.Info,
    payload: {
      actSysId: act.sysId,
      actTitle: act.title,
      funFactData: funFact?.actions[0].data,
      funFactId: funFact?.id,
      levelSysId: level.sysId,
      levelTitle: level.title
    },
    summary: `${act.character.displayName} Fun Fact played for level ${level.title}`
  };
}
