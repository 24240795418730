import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export class ObservableHelper<T> {
  private readonly subject: BehaviorSubject<T>;

  public constructor(initialValue: T) {
    this.subject = new BehaviorSubject<T>(initialValue);
  }

  public get value(): T {
    return this.subject.value;
  }

  public set value(value: T) {
    this.subject.next(value);
  }

  /**
   * Returns promise that resolves when (truthy) value is set
   */
  public get valuePromise(): Promise<T | undefined> {
    return lastValueFrom(
      this.subject.asObservable().pipe(
        filter(s => !!s),
        take(1)
      )
    );
  }
}
