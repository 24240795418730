import { Align, Justify } from 'common-styles';

export class UserChoiceStyles {
  public static get() {
    return {
      choiceRow: {
        alignItems: Align.End,
        justifyContent: Justify.SpaceBetween,
        minWidth: 500
      },
      taskRow: {
        alignSelf: Align.Center,
        flex: 1
      }
    };
  }
}
