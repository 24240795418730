import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { sample } from 'lodash';
import { Sfx } from 'audio';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';
import { TaskAction } from '../redux';

const DEFAULT_DELAY_MS = 2000;

export class SolutionScreenplayBuilder {
  public static createFor(context: RoundContext): SolutionScreenplayBuilder {
    return new SolutionScreenplayBuilder(context);
  }

  private delay = DEFAULT_DELAY_MS;

  private screenplays: IScreenplay[] = [];

  public get screenplay(): IScreenplay {
    const { act, parentUnit: unit, round } = this.context;
    const preSolutionPool = unit.character
      ? unit.character.preSolution
      : act.character.preSolution;

    return ScreenplayBuilder.create('Solution')
      .addDelay(500)
      .addScreenplay(sample(preSolutionPool))
      .addDelay(500)
      .addSfx(Sfx.CallOut, { concurrent: true })
      .addReduxAction(TaskAction.showSolution())
      .addScreenplayList(this.screenplays)
      .addScreenplay(round.solution)
      .addDelay(this.delay).screenplay;
  }

  private constructor(private readonly context: RoundContext) {}

  public addScreenplayList(screenplays: IScreenplay[]) {
    this.screenplays = screenplays;

    return this;
  }

  public addDelay(delayMs: number) {
    this.delay = delayMs;

    return this;
  }
}
