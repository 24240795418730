import { AnimatedView, Types } from 'common-ui';
import { InteractionState } from 'common-styles';
import { SpeechBubbleStyles } from './SpeechBubble.styles';
import { useTheme } from 'theme/useTheme';
import { CharacterSpeechBubbleTail } from './tail/CharacterSpeechBubbleTail';
import { AvatarAndSpeechBubbleTail } from './tail/AvatarAndSpeechBubbleTail';

export interface ISpeechBubbleAnimatedStyles {
  avatarSpeechTail?: Types.AnimatedViewStyle;
  container: Types.AnimatedViewStyle & { borderColor?: Types.AnimatedValue };
}

export interface ISpeechBubbleProps {
  animatedStyles?: ISpeechBubbleAnimatedStyles;
  children?: React.ReactNode;
  hasAvatar?: boolean;
  hasTailLeft?: boolean;
  interactionState?: InteractionState;
  testId?: string;
}

export function SpeechBubble(props: ISpeechBubbleProps) {
  const {
    animatedStyles,
    children,
    hasAvatar,
    hasTailLeft,
    interactionState,
    testId
  } = props;

  const { themeSize, themeType } = useTheme();

  const styles = SpeechBubbleStyles.build(
    interactionState,
    themeSize,
    themeType
  );

  const borderFill = styles.container.borderColor;
  const backgroundFill = styles.container.backgroundColor;

  return (
    <AnimatedView
      testId={testId || SpeechBubble.displayName}
      animatedStyle={animatedStyles?.container}
      style={styles.container}
    >
      {hasTailLeft && (
        <CharacterSpeechBubbleTail
          backgroundFill={backgroundFill}
          borderFill={borderFill}
        />
      )}
      {hasAvatar && (
        <AvatarAndSpeechBubbleTail
          animatedStyle={animatedStyles?.avatarSpeechTail}
          backgroundFill={backgroundFill}
          borderFill={borderFill}
        />
      )}
      {children}
    </AnimatedView>
  );
}
SpeechBubble.displayName = 'SpeechBubble';
