import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, switchMap } from 'rxjs/operators';
import { LevelSelector } from 'curriculum-services/level';
import { ProfileSelector } from 'services/profile';
import { PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { HomeScene } from '../HomeScene';
import { HomeSceneDefinitionBuilder } from '../HomeSceneDefinitionBuilder';
import { HomeSceneActionType, HomeScenePrepareAction } from '../redux';

export interface IPrepareHomeSceneDep {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareHomeSceneEpic(
  action$: Observable<HomeScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareHomeSceneDep
): Observable<void> {
  return action$.pipe(
    ofType(HomeSceneActionType.Prepare),
    switchMap(async () => {
      const { preparedScenes, stormService } = deps;
      preparedScenes.home = undefined;

      const grade = ProfileSelector.getGrade(state$.value);
      const levelNum = LevelSelector.getLevelNumber(state$.value);

      const { definition } = HomeSceneDefinitionBuilder.create()
        .withLevelMeter(grade, levelNum)
        .withCharacters();

      await stormService.initialization;
      const scene = await stormService.prepareScene(definition);
      const homeScene = new HomeScene(scene);
      preparedScenes.home = homeScene;
    }),
    ignoreElements()
  );
}
prepareHomeSceneEpic.displayName = 'prepareHomeSceneEpic';
