import { StateObservable, ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppState } from 'services';
import {
  ProfileAction,
  ProfileActionUpdateAvatar,
  ProfileSelector
} from 'services/profile';
import {
  AvatarEditorAction,
  AvatarEditorActionAddUserEvent,
  AvatarEditorActionSaveAndExit,
  AvatarEditorActionTimeoutUserConfirmation
} from '../AvatarEditor.action';
import { EnumUtils } from 'utils/EnumUtil';
import { FaceStyle } from 'services/profile/avatar.model';
import { AvatarEditorEvent } from 'logging';
import { AvatarEditorActionType } from '../avatar-editor-redux.model';

type OutputActions =
  | AvatarEditorActionSaveAndExit
  | AvatarEditorActionAddUserEvent
  | ProfileActionUpdateAvatar;

export function handleTimeoutUserConfirmationEpic(
  action$: Observable<AvatarEditorActionTimeoutUserConfirmation>,
  state$: StateObservable<AppState>
): Observable<OutputActions> {
  return action$.pipe(
    ofType(AvatarEditorActionType.TimeoutUserConfirmation),
    mergeMap(() => {
      const outputActions: OutputActions[] = [AvatarEditorAction.saveAndExit()];

      const hasCreatedAvatar = ProfileSelector.hasCreatedAvatar(state$.value);
      if (hasCreatedAvatar) {
        return from(outputActions);
      }

      // If user has not created their avatar when the timeout modal confirmation button
      // is pressed, create it for them randomly (all values but faceStyle will
      // have already been randomly set, so only faceStyle need to be set at this point)
      outputActions.unshift(
        AvatarEditorAction.addUserEvent({
          eventName: AvatarEditorEvent.AvatarRandomSelection
        })
      );
      outputActions.unshift(
        ProfileAction.updateAvatar({
          faceStyle: EnumUtils.getRandomValue(FaceStyle)
        })
      );

      return from(outputActions);
    })
  );
}
handleTimeoutUserConfirmationEpic.displayName =
  'handleTimeoutUserConfirmationEpic';
