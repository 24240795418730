import {
  Svg,
  Defs,
  Path,
  Stop,
  G,
  Position,
  LinearGradient
} from '@lexialearning/common-ui';

export const MIRROR_SVG_HEIGHT = 597;
export const MIRROR_SVG_WIDTH = 241;

export function MirrorSvg(): React.ReactElement {
  return (
    <Svg
      data-test-id={MirrorSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      width={MIRROR_SVG_WIDTH}
      height={MIRROR_SVG_HEIGHT}
      viewBox={`0 0 ${MIRROR_SVG_WIDTH} ${MIRROR_SVG_HEIGHT}`}
      style={{ position: Position.Absolute }}
    >
      <Defs>
        <LinearGradient
          id="mirror-svg-linear-gradient"
          x1="0.5"
          y1="1"
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor="#eca204" />
          <Stop offset="1" stopColor="#fdb927" />
        </LinearGradient>
      </Defs>
      <G>
        <Path
          d="M120.5,0h0C187,0,241,54.92,241,122.563v449.78A24.461,24.461,0,0,1,216.758,597H24.242A24.461,24.461,0,0,1,0,572.343V122.563C0,54.92,54,0,120.5,0Z"
          fill="#7f3f00"
        />
        <Path
          d="M120.28,10.96h0c59.6,0,108,50.052,108,111.694V561.473c0,13.517-10.607,24.487-23.677,24.487H35.957c-13.07,0-23.677-10.97-23.677-24.487V122.654C12.28,61,60.676,10.96,120.28,10.96Z"
          transform="translate(0 0)"
          fill="url(#mirror-svg-linear-gradient)"
        />
      </G>
      <G transform="translate(12.28 10.96)">
        <Path
          d="M120.5,10.96h0c59.725,0,108.22,50.112,108.22,111.829V562.139c0,13.533-10.629,24.517-23.726,24.517H36.006c-13.1,0-23.726-10.984-23.726-24.517V122.789C12.28,61.064,60.775,10.96,120.5,10.96Z"
          transform="translate(-12.28 -10.96)"
          fill="#eefaff"
          opacity="0.2"
        />
        <Path
          d="M186.441,49.2l129.888,504H284.705L181.329,69.373Z"
          transform="translate(-179.52 21.874)"
          fill="#eefaff"
          opacity="0.15"
        />
        <Path
          d="M158.06,12.746l126.3,399.392v95.608l-135-492.956Z"
          transform="translate(-69.521 -10.961)"
          fill="#eefaff"
          opacity="0.15"
        />
      </G>
    </Svg>
  );
}
MirrorSvg.displayName = 'MirrorSvg';
