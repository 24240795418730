import { Row, useSizing } from 'common-ui';
import { AvatarEditorNavigationButton } from './AvatarEditorNavigationButton';
import { UtilityBarStyles } from 'feature-areas/shell/utility-bar/UtilityBar.styles';
import { ShowTroubleshootButton } from 'feature-areas/shell/utility-bar/troubleshoot-button';
import { useDispatch, useSelector } from 'react-redux';
import { AppShellSelector } from 'feature-areas/shell';
import { DeveloperAccessMenu } from 'feature-areas/dev-tools';
import { AvatarEditorAction } from '../redux';
import { AvatarEditorEvent } from 'logging';

/**
 * The utility bar here is very specific to this section of the app.
 * Rather than add props and rules to UtilityBar.tsx to support this, it makes
 * sense to create it as a separate component here, reusing as much as possible
 * from UtilityBar.tsx methods.
 */
export function AvatarEditorUtilityBar() {
  const { appSize } = useSizing();
  const isDisabled = useSelector(AppShellSelector.isUtilityBarDisabled);

  const dispatch = useDispatch();
  const handlePressTroubleshoot = () => {
    dispatch(
      AvatarEditorAction.addUserEvent({
        eventName: AvatarEditorEvent.TroubleshootButtonPressed
      })
    );
  };

  return (
    <Row style={UtilityBarStyles.build(appSize.w).container}>
      <AvatarEditorNavigationButton disabled={isDisabled} />
      <ShowTroubleshootButton
        disabled={isDisabled}
        onPress={handlePressTroubleshoot}
      />

      <DeveloperAccessMenu />
    </Row>
  );
}
AvatarEditorUtilityBar.displayName = 'AvatarEditorUtilityBar';
