import { StormStatus } from '../service';
import { IStormState, StormActionType } from './storm-redux.model';
import { StormActions } from './Storm.action';

export const stormInitialState: IStormState = {
  status: StormStatus.Uninitialized
};

export function stormReducer(
  state: IStormState = stormInitialState,
  action: StormActions
): IStormState {
  switch (action.type) {
    case StormActionType.Initialize:
      return { ...state, status: StormStatus.Initializing };

    case StormActionType.InitializeSuccess:
      return {
        ...state,
        status: StormStatus.Initialized
      };
    default:
      return state;
  }
}
