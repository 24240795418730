import { Animated as RxAnimated } from '@lexialearning/reactxp';
import { useTheme } from 'theme';
import { IAnimatedTextProps } from './AnimatedText.model';
import { AnimatedTextStyles } from './AnimatedText.styles';

export function AnimatedText(props: IAnimatedTextProps) {
  const { themeSize } = useTheme();
  const { animatedStyle, style, ...textProps } = props;

  return (
    <RxAnimated.Text
      {...textProps}
      style={AnimatedTextStyles.build(themeSize, style, animatedStyle)}
    />
  );
}
AnimatedText.displayName = 'AnimatedText';
