import * as React from 'react';
import { BackgroundImage } from 'common-ui/components/image';
import { Column } from 'common-ui/components/layout';
import { Text } from 'common-ui/components/text';
import { Linking } from 'common-ui/helpers';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { BrowserCardStyles } from './BrowserCard.styles';
import { PressableView } from 'common-ui';

export class BrowserCard extends React.PureComponent {
  public static readonly displayName = 'BrowserCard';

  private async handleClick() {
    const data = UserAgentUtils.getInfo()!;

    return Linking.openUrl(data.tipsUrl);
  }

  public render() {
    const styles = BrowserCardStyles.get();
    const data = UserAgentUtils.getInfo();

    if (!data) {
      // unsupported browser
      return null;
    }

    return (
      <PressableView
        style={styles.browserCard}
        testId={BrowserCard.displayName}
        onPress={this.handleClick}
      >
        <Column style={styles.logoBox}>
          <BackgroundImage source={data.img} style={styles.logo} />
        </Column>
        <Column style={styles.browserNameWrapper}>
          <Text style={styles.browserName}>{data.name}</Text>
        </Column>
      </PressableView>
    );
  }
}
