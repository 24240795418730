import { Services } from 'services';
import { ChoralSpeechBubble } from './choral/ChoralSpeechBubble';
import { ITextPrompt, ThemeSize } from '@lexialearning/lobo-common';
import { ProgramContextSelector } from 'curriculum-services';
import {
  IQuestionAndAnswerBubblesAnimatedStyles,
  QuestionAndAnswerSpeechBubbles
} from './question-and-answer/QuestionAndAnswerSpeechBubbles';
import {
  ISayItAfterSpeechBubblesAnimatedStyles,
  SayItAfterSpeechBubbles
} from './say-it-after/SayItAfterSpeechBubbles';
import { SayItAgainSpeechBubble } from './say-it-again/SayItAgainSpeechBubble';
import { useSelector } from 'react-redux';
import { AnimatedView } from 'common-ui';
import { StandardSpeechBubblesAnimatedStyles } from './StandardSpeechBubbles.animated-styles';
import { useEffect, useRef } from 'react';
import {
  ISpeechBubbleCommonProps,
  SeeSpeakType,
  SpeechBubbleReactAnimationName
} from './speech-bubbles.model';
import { SeeSpeakHelper } from '../SeeSpeak.helper';

export interface IStandardSpeechBubbleProps extends ISpeechBubbleCommonProps {
  hasOnscreenCharacter: boolean;
  textPromptArray: ITextPrompt[];
  themeSize: ThemeSize;
}

export function StandardSpeechBubbles(
  props: Omit<IStandardSpeechBubbleProps, 'animatedStyles'>
) {
  const { mode, textPromptArray } = props;
  const context = useSelector(ProgramContextSelector.getRoundContext);
  const hasIntroduction = !!context.round.introduction;
  const type = SeeSpeakHelper.getSeeSpeakType(
    mode,
    textPromptArray,
    hasIntroduction
  );

  const animatedStylesRef = useRef(
    new StandardSpeechBubblesAnimatedStyles(type, mode)
  );
  const { bubblesFadeIn, micFadeIn, responseEntry } =
    animatedStylesRef.current.getAnimations();
  const styles = animatedStylesRef.current.get();
  const bubblesAnimatedStyles = {
    prompt: styles.prompt,
    question: styles.question,
    response: styles.response
  };

  useEffect(() => {
    const unregisterAnimation =
      Services.reactAnimationScreenplayer.registerAnimations([
        {
          animation: bubblesFadeIn,
          name: SpeechBubbleReactAnimationName.BubblesFadeIn
        },
        {
          animation: micFadeIn,
          name: SpeechBubbleReactAnimationName.MicFadeIn
        },
        {
          animation: responseEntry,
          name: SpeechBubbleReactAnimationName.ResponseEntry
        }
      ]);

    return () => {
      unregisterAnimation();
    };
  }, [bubblesFadeIn, micFadeIn, responseEntry]);

  return (
    <AnimatedView
      style={styles.bubblesContainer}
      animatedStyle={styles.bubblesContainerAnimated}
      testId={StandardSpeechBubbles.displayName}
    >
      {getBubbles({ ...props, animatedStyles: bubblesAnimatedStyles }, type)}
    </AnimatedView>
  );
}

function getBubbles(props: IStandardSpeechBubbleProps, type: SeeSpeakType) {
  const { hasOnscreenCharacter, themeSize, textPromptArray, ...commonProps } =
    props;
  switch (type) {
    case SeeSpeakType.Choral:
      return <ChoralSpeechBubble {...commonProps} />;

    case SeeSpeakType.QuestionAnswer:
      return (
        <QuestionAndAnswerSpeechBubbles
          {...commonProps}
          hasOnscreenCharacter={hasOnscreenCharacter}
          textPromptArray={textPromptArray}
          themeSize={themeSize}
          animatedStyles={
            commonProps.animatedStyles as IQuestionAndAnswerBubblesAnimatedStyles
          }
        />
      );

    case SeeSpeakType.SayItAfter:
      return (
        <SayItAfterSpeechBubbles
          {...commonProps}
          hasOnscreenCharacter={hasOnscreenCharacter}
          animatedStyles={
            commonProps.animatedStyles as ISayItAfterSpeechBubblesAnimatedStyles
          }
        />
      );

    default:
      return <SayItAgainSpeechBubble {...commonProps} />;
  }
}

StandardSpeechBubbles.displayName = 'StandardSpeechBubbles';
