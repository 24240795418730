import { Color } from 'common-styles';
import { G, Path } from '@lexialearning/common-ui';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function ReplayIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G data-test-id={ReplayIconSvg.displayName}>
      {/* Replay shadow */}
      {shadowColor && (
        <G transform="translate(29.8 12)" opacity="0.28">
          <G
            fill="none"
            stroke={shadowColor}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="6"
          >
            <Path
              d="M4.244 19.802a13.017 13.017 0 0 0 18.232 11.52"
              data-name="Bottom Left"
            />
            <Path
              d="M30.243 18.592a13.007 13.007 0 0 0-17.806-11.25"
              data-name="Upper Right"
            />
          </G>
          <Path
            fill={shadowColor}
            d="M29.892 27.128a1 1 0 0 1 .615 1.317l-3.314 8.368a1 1 0 0 1-1.835.054L20.129 25.67a1 1 0 0 1 1.221-1.372Z"
          />
          <Path
            fill={shadowColor}
            d="M4.595 11.843a1 1 0 0 1-.615-1.317l3.314-8.368a1 1 0 0 1 1.835-.054l5.229 11.198a1 1 0 0 1-1.221 1.372Z"
            data-name="Arrowhead"
          />
        </G>
      )}
      {/* Replay */}
      <G transform="translate(29.8 12)">
        <G
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="6"
        >
          <Path
            d="M4.244 19.802a13.017 13.017 0 0 0 18.232 11.52"
            data-name="Bottom Left"
          />
          <Path
            d="M30.243 18.592a13.007 13.007 0 0 0-17.806-11.25"
            data-name="Upper Right"
          />
        </G>
        <Path
          fill={iconColor}
          d="M29.892 27.128a1 1 0 0 1 .615 1.317l-3.314 8.368a1 1 0 0 1-1.835.054L20.129 25.67a1 1 0 0 1 1.221-1.372Z"
        />
        <Path
          fill={iconColor}
          d="M4.595 11.843a1 1 0 0 1-.615-1.317l3.314-8.368a1 1 0 0 1 1.835-.054l5.229 11.198a1 1 0 0 1-1.221 1.372Z"
          data-name="Arrowhead"
        />
      </G>
    </G>
  );
}
ReplayIconSvg.displayName = 'ReplayIconSvg';
