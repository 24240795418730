import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  SreSessionAction,
  SreSessionActionFinish,
  SreSessionActionFinishSuccess,
  SreSessionActionType
} from '../redux';
import { ISreDependencies } from './sre-epics.model';

export function finishSreSessionEpic(
  action$: Observable<SreSessionActionFinish>,
  _state$: StateObservable<unknown>,
  deps: ISreDependencies
): Observable<SreSessionActionFinishSuccess> {
  return action$.pipe(
    ofType(SreSessionActionType.Finish),
    switchMap(async () => {
      await deps.sre.finish();

      return SreSessionAction.finish.success();
    })
  );
}
finishSreSessionEpic.displayName = 'finishSreSessionEpic';
