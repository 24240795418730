import {
  IScreenplay,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { ICarousel } from '@lexialearning/lobo-common/tasks/carousel';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';
import {
  ITaskRegistration,
  ScreenplayType,
  TaskRegistrationBuilder
} from '../core';
import { CarouselTask } from './CarouselTask';

export function createCarouselTaskRegistration(): ITaskRegistration<
  TaskTypeName.Carousel,
  ICarousel
> {
  return TaskRegistrationBuilder.create<TaskTypeName.Carousel, ICarousel>(
    TaskTypeName.Carousel,
    CarouselTask
  ).withScreenplayBuilder(ScreenplayType.PostEntry, addMusicMaybe).registration;
}

function addMusicMaybe(roundContext: RoundContext): IScreenplay {
  const carouselTask = roundContext.getTask<ICarousel>();
  const builder = ScreenplayBuilder.create('Carousel Music');

  if (carouselTask.music) {
    builder.addMusic(
      {
        loop: true,
        path: `task/carousel_${carouselTask.music}`
      },
      { concurrent: true }
    );
  }

  return builder.screenplay;
}
