import { UserInterface as RxUserInterface } from '@lexialearning/reactxp';
import memoizeOne from 'memoize-one';
import { XYCoord } from 'react-dnd';
import { SystemInfo } from 'utils';
import { UserAgentUtils } from 'utils/UserAgentUtils';
import { CONSTANTS } from './Constants';

const { Height } = CONSTANTS.BaseDimensions;

export interface ISize {
  w: number;
  h: number;
}
export interface IOffset {
  h: number;
  v: number;
}
export interface ISizing {
  appSize: ISize;
  containerSize: ISize;
  scale: number;
  offset: IOffset;
}

export class SizingUtils {
  public static readonly displayName = 'SizingUtils';

  // An approximate Chrome toolbar height with a bookmark bar
  public static ChromeBarHeight = 100;

  private static readonly getRatio = memoizeOne(
    (width: number, height: number) => width / height
  );

  /**
   * The difference between the app dimensions and the outer window dimensions ratios
   * The ratios should be the same, or close, unless the debugger is opened (horizontal/vertical)
   */
  private static readonly getRatioDifference = memoizeOne(
    (
      width: number,
      height: number,
      outerWidth: number,
      outerHeight: number
    ): number => {
      const appRatio = SizingUtils.getRatio(width, height);
      const outerWindowRatio = SizingUtils.getRatio(outerWidth, outerHeight);

      return appRatio - outerWindowRatio;
    }
  );

  public static isDebuggerOpen = memoizeOne(
    (ratioDifference: number): boolean => {
      const ratioDiffAbs = Math.abs(ratioDifference);

      // Edge calculations seem to be different and showing scrollbars when not expected
      if (UserAgentUtils.isEdge()) {
        return ratioDiffAbs > 0.125;
      }

      return ratioDiffAbs > 0.1;
    }
  );

  /**
   * Zoom detection works on Chrome (Mac, Windows, Chromebook), Safari and Edge
   */
  private static readonly getZoomedValue = memoizeOne(
    (
      width: number,
      height: number,
      outerWidth: number,
      outerHeight: number,
      isMaybeFullscreen
    ): number => {
      const ratioDifference = SizingUtils.getRatioDifference(
        width,
        height,
        outerWidth,
        outerHeight
      );
      const isDebuggerOpen = SizingUtils.isDebuggerOpen(ratioDifference);

      if (!isDebuggerOpen) {
        return outerWidth / width;
      }

      /**
       * We need to modify the outer width/height to eliminate the debugger width/height
       * So that our zoomed value is correct
       * We assume that the person is using Chrome to debug
       */
      const viewportRatio = SizingUtils.getRatio(width, height);
      const scaledOuterWidth =
        (outerHeight - (isMaybeFullscreen ? 0 : SizingUtils.ChromeBarHeight)) *
        viewportRatio;
      const scaledOuterHeight = outerWidth / viewportRatio;

      // If width/height bigger, then debugger opened at bottom
      if (ratioDifference > 0) {
        return scaledOuterHeight / height;
      }

      return scaledOuterWidth / width;
    }
  );

  /**
   * Starts as 1024x768, then scale to fit full height.
   * If scaled width is wider than screen
   *     ==> update scaling so 1024x728 fits
   * If screen is wider than scaled width
   *     ==> stretch app width to fit window but not more than 16:9 ratio
   */
  private static readonly getSizingValues = memoizeOne(
    (width: number, height: number, zoomed: number): ISizing => {
      const appSize = {
        h: Height,
        w: (4 / 3) * Height
      };

      let scale = height / appSize.h;

      if (appSize.w * scale > width) {
        scale = width / appSize.w;
      } else if (appSize.w * scale < width) {
        appSize.w = Math.min(width / scale, (16 / 9) * Height);
      }

      scale *= zoomed;

      const containerSize = {
        h: Math.max(height, appSize.h * scale),
        w: Math.max(width, appSize.w * scale)
      };

      const offset = {
        h: (containerSize.w - scale * appSize.w) / 2,
        v: (containerSize.h - scale * appSize.h) / 2
      };

      return {
        appSize,
        containerSize,
        offset,
        scale
      };
    }
  );

  public static getAppSizing(): ISizing {
    const { width, height } = RxUserInterface.measureWindow();

    const isSupportedBrowser =
      !UserAgentUtils.isFirefox() && !SystemInfo.isNative;
    const isMaybeFullscreen = !window.screenTop && !window.screenY;
    const outerWidth = window.outerWidth - (UserAgentUtils.isEdge() ? 8 : 0);
    const outerHeight = window.outerHeight - (UserAgentUtils.isEdge() ? 8 : 0);

    const zoomed = isSupportedBrowser
      ? SizingUtils.getZoomedValue(
          width,
          height,
          outerWidth,
          outerHeight,
          isMaybeFullscreen
        )
      : 1;

    return this.getSizingValues(width, height, zoomed);
  }

  /**
   * Converts screen offset (position relative to window/screen)
   * to unscaled offset relative to App container
   * @param screenOffset - offset relative to window/screen
   */
  public static getOffsetRelativeToApp(screenOffset: XYCoord): XYCoord {
    const { offset, scale } = SizingUtils.getAppSizing();

    return {
      x: (screenOffset.x - offset.h) / scale,
      y: (screenOffset.y - offset.v) / scale
    };
  }
}
