import {
  IActivityPosition,
  IEncounter,
  IPositionChange,
  IRoundNode,
  PositionChangeType,
  TaskEvaluationResult
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { RoundContext } from '../../../RoundContext';
import { ActivityPositionBuilder } from './ActivityPositionBuilder';

/**
 * Determine the next position given the current program context with the
 * latest attempt evaluation.
 * Forking and recycling are not directly supported and should be implemented
 * by extending this class.
 */
export class PositionDeterminer {
  public static readonly displayName: string = 'PositionDeterminer';

  protected get maxAttempts(): number {
    return 2;
  }

  protected readonly activityPositionBuilder: ActivityPositionBuilder;

  /**
   * We can ignore this error in certain situations such as logging or
   * ed mode skipping
   */
  public suppressImminentPositionUnexpectedError = false;

  public constructor(protected readonly context: RoundContext) {
    this.activityPositionBuilder = ActivityPositionBuilder.create(
      context.activityPosition
    );
  }

  public determine(): IPositionChange {
    this.checkImminentPosition();

    const changeType = this.determineChangeType();
    const activityPosition = this.buildActivityPosition(changeType);

    return {
      activityPosition,
      changeType
    };
  }

  private checkImminentPosition(): void {
    if (
      this.context.activityPosition.imminentPosition &&
      !this.suppressImminentPositionUnexpectedError
    ) {
      throw new LexiaError(
        'Invalid operation attempting to determine the next position with a pre-existing imminent position',
        PositionDeterminer.displayName,
        PositionDeterminerError.ImminentPositionUnexpected
      ).withContext({ activityPosition: this.context.activityPosition });
    }
  }

  protected determineChangeType(): PositionChangeType {
    const {
      atLastEncounter,
      atLastMainUnit: atLastUnit,
      atLastRound,
      isInstructionRound,
      isSubunit
    } = this.context;
    const isUnitCompleted = atLastRound && !isSubunit;
    const isEncounterCompleted = isUnitCompleted && atLastUnit;
    const isActivityCompleted = isEncounterCompleted && atLastEncounter;
    const isLevelCompleted =
      isActivityCompleted && this.allOtherActivitiesCompleted();

    return this.shouldRetry()
      ? PositionChangeType.None
      : this.shouldStepDown()
      ? PositionChangeType.StepDown
      : !atLastRound
      ? PositionChangeType.RoundCompletion
      : // all checks below mean we completed a subunit or above
      isInstructionRound
      ? PositionChangeType.StepUp
      : isSubunit && this.findParentRoundNode().next
      ? PositionChangeType.Join
      : isLevelCompleted
      ? PositionChangeType.LevelCompletion
      : isActivityCompleted
      ? PositionChangeType.ActivityCompletion
      : isEncounterCompleted
      ? PositionChangeType.EncounterCompletion
      : PositionChangeType.UnitCompletion;
  }

  protected shouldRetry(): boolean {
    const incorrectAttempts = this.context.attempts.filter(
      a => a.result === TaskEvaluationResult.Incorrect
    );

    return !this.isCorrect() && incorrectAttempts.length < this.maxAttempts;
  }

  protected isCorrect(): boolean {
    return (
      this.context.lastAttemptMaybe?.result === TaskEvaluationResult.Correct
    );
  }

  /**
   * Step down after too many failed attempts, if we have a branch down we've
   * not stepped down to twice already (i.e. round exposure count >= 3)
   */
  protected shouldStepDown(): boolean {
    const { parentUnitPosition, round } = this.context;
    const hasFailed = !this.isCorrect() && !this.shouldRetry();
    const isOverexposed = parentUnitPosition.roundExposureCount >= 3;
    const hasBranching = !!round.branch;

    return hasFailed && !isOverexposed && hasBranching;
  }

  private allOtherActivitiesCompleted(): boolean {
    return [...this.context.activityPositionMap.values()].every(
      p => p.isComplete || p.activityId === this.context.act.sysId
    );
  }

  protected buildActivityPosition(
    changeType: PositionChangeType
  ): IActivityPosition {
    switch (changeType) {
      case PositionChangeType.ActivityCompletion:
        return this.toCompletedActivity();
      case PositionChangeType.EncounterCompletion:
        return this.toNextEncounter();
      case PositionChangeType.Join:
        return this.fromSubunit();
      case PositionChangeType.LevelCompletion:
        return this.toCompletedActivity();
      case PositionChangeType.None:
        return this.context.activityPosition;
      case PositionChangeType.RoundCompletion:
        return this.toNextRound();
      case PositionChangeType.StepDown:
        return this.toInstructionSubunit();
      case PositionChangeType.StepUp:
        return this.fromInstruction();
      case PositionChangeType.UnitCompletion:
        return this.toNextUnit();

      // istanbul ignore next - unreachable
      default:
        throw new LexiaError(
          `Unrecognized change type ${changeType}`,
          PositionDeterminer.displayName,
          PositionDeterminerError.ChangeTypeUnrecognized
        ).withContext({ changeType });
    }
  }

  protected toCompletedActivity(): IActivityPosition {
    return this.activityPositionBuilder
      .removeSubunitPositions()
      .resetAllUnitPositions()
      .update({
        isComplete: true
      }).activityPosition;
  }

  private toNextEncounter(): IActivityPosition {
    const nextEncounter = this.findNextEncounter();

    const { activityPosition } = this.activityPositionBuilder
      .update({ encounterId: nextEncounter.sysId })
      .withActiveUnitPosition(nextEncounter.units?.[0]);

    return activityPosition;
  }

  private findNextEncounter(): IEncounter {
    const nextEncounter = this.context.encounterNode.next?.content;
    if (!nextEncounter) {
      throw new LexiaError(
        'No destination encounter',
        PositionDeterminer.displayName,
        PositionDeterminerError.NoNextEncounter
      ).withContext({
        activity: this.context.act,
        activityPosition: this.context.activityPosition
      });
    }

    return nextEncounter;
  }

  private fromInstruction(): IActivityPosition {
    return this.activityPositionBuilder.popSubunit().activityPosition;
  }

  private fromSubunit(): IActivityPosition {
    const nextRoundId = this.determinePostJoinRoundId();

    return this.activityPositionBuilder
      .popSubunit()
      .withUnitPosition({ roundId: nextRoundId }).activityPosition;
  }

  private determinePostJoinRoundId(): string {
    const parentRound = this.findParentRoundNode();
    const nextRoundId = parentRound.next?.content.sysId;

    if (!nextRoundId) {
      throw new LexiaError(
        'Cannot join after the last round of the grandparent unit',
        PositionDeterminer.displayName,
        PositionDeterminerError.JoinFailure
      ).withContext({
        parentRound: parentRound.content.sysId
      });
    }

    return nextRoundId;
  }

  private findParentRoundNode(): IRoundNode {
    const { context } = this;
    const { parentRoundId } = context.parentUnitPosition;
    const parentRound = context.grandparentUnitNode?.children.find(
      r => r.content.sysId === parentRoundId
    );

    if (!parentRound) {
      throw new LexiaError(
        'Unable to find the parent round for the subunit',
        PositionDeterminer.displayName,
        PositionDeterminerError.ParentRoundMissing
      ).withContext({
        parentUnitPosition: context.parentUnitPosition,
        potentialParents: context.grandparentUnit?.rounds
      });
    }

    return parentRound;
  }

  private toInstructionSubunit(): IActivityPosition {
    const { round, roundNode } = this.context;

    return this.activityPositionBuilder.addSubunitPositionFrom(
      round.branch!,
      roundNode
    ).activityPosition;
  }

  private toNextRound(): IActivityPosition {
    const { roundNode } = this.context;

    return this.activityPositionBuilder.updateActiveUnitPosition({
      attemptStartDate: undefined,
      attempts: [],
      roundExposureCount: 1,
      roundId: roundNode.next?.content.sysId ?? ''
    }).activityPosition;
  }

  private toNextUnit(): IActivityPosition {
    const nextUnit = this.context.mainUnitNode.next?.content;

    if (!nextUnit) {
      const { activityPosition, mainUnit: unit } = this.context;
      throw new LexiaError(
        'Next unit is missing',
        PositionDeterminer.displayName,
        PositionDeterminerError.NoNextUnit
      ).withContext({ activityPosition, unit });
    }

    return this.activityPositionBuilder.withActiveUnitPosition(nextUnit)
      .activityPosition;
  }
}

export enum PositionDeterminerError {
  ChangeTypeUnrecognized = 'ChangeTypeUnrecognized',
  ImminentPositionUnexpected = 'ImminentPositionUnexpected',
  JoinFailure = 'JoinFailure',
  NoNextEncounter = 'NoNextEncounter',
  NoNextUnit = 'NoNextUnit',
  ParentRoundMissing = 'ParentRoundMissing',
  UnitPositionInvalid = 'UnitPositionInvalid'
}
