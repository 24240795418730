import { Column as CuiColumn, Row as CuiRow } from '@lexialearning/common-ui';
import * as React from 'react';
import { AnimatedView, Text } from 'common-ui';
import { BouncingGabber } from './bouncing-gabber/BouncingGabber';
import GabberBangPng from './images/GabberBang.png';
import GabberBubblePng from './images/GabberBubble.png';
import GabberCloudPng from './images/GabberCloud.png';
import { SpinnerToastAnimatedStyles } from './SpinnerToast.animated-styles';
import { TailSvg } from './Tail.lx-svg';

export interface ISpinnerToastProps {
  withBottomOffset: boolean;
  shouldShow: boolean;
}

export interface ISpinnerToastState {
  isHidden: boolean;
}

export class SpinnerToast extends React.PureComponent<
  ISpinnerToastProps,
  ISpinnerToastState
> {
  private readonly animatedStyles: SpinnerToastAnimatedStyles;

  public static displayName = 'spinnerToast';

  constructor(props: ISpinnerToastProps) {
    super(props);

    this.state = { isHidden: true };
    this.animatedStyles = new SpinnerToastAnimatedStyles();
  }

  public componentDidMount() {
    const { shouldShow } = this.props;

    if (shouldShow) {
      this.show();
    }
  }

  public componentDidUpdate(prevProps: ISpinnerToastProps) {
    const { shouldShow } = this.props;

    if (shouldShow && !prevProps.shouldShow) {
      this.show();
    } else if (!shouldShow && prevProps.shouldShow) {
      this.hide();
    }
  }

  private show() {
    const { show } = this.animatedStyles.getAnimations();
    this.setState({ isHidden: false }, () => {
      show.start();
    });
  }

  private hide() {
    const { hide } = this.animatedStyles.getAnimations();
    hide.start(() => {
      this.setState({ isHidden: true });
    });
  }

  public render() {
    if (this.state.isHidden) {
      return null;
    }

    const { withBottomOffset } = this.props;
    const styles = this.animatedStyles.build(withBottomOffset);

    return (
      <AnimatedView
        animatedStyle={styles.containerAnimated}
        style={styles.container}
        testId={SpinnerToast.displayName}
      >
        <CuiColumn style={styles.outerContainer}>
          <CuiRow style={styles.innerContainer}>
            <Text style={styles.label}>Loading</Text>
            <BouncingGabber source={GabberCloudPng} delay={0} />
            <BouncingGabber source={GabberBangPng} delay={150} />
            <BouncingGabber source={GabberBubblePng} delay={300} />
          </CuiRow>
          <TailSvg />
        </CuiColumn>
      </AnimatedView>
    );
  }
}
