import {
  InstructionalStep,
  UnitType
} from '@lexialearning/lobo-common/main-model';
import { createSelector } from '@reduxjs/toolkit';
import { IThemeState, ThemeSize, ThemeType } from './theme.model';

type ThemeSelectorType = (state: any) => IThemeState;

export class ThemeSelector {
  public static readonly displayName = 'ThemeSelector';

  public static getThemeState: ThemeSelectorType;

  public static getThemeSize: (state: any) => ThemeSize;

  public static getThemeType: (state: any) => ThemeType;

  public static createSelectors(
    selector: ThemeSelectorType,
    getIsOnboardingOrPlacement: (state: any) => boolean,
    getInstructionalStep: (state: any) => InstructionalStep | undefined,
    getUnitType: (state: any) => UnitType
  ) {
    ThemeSelector.getThemeState = createSelector(
      selector,
      (state: IThemeState) => state
    );

    ThemeSelector.getThemeSize = createSelector(
      ThemeSelector.getThemeState,
      (state: IThemeState) => state.theme?.size || ThemeSize.Large
    );

    ThemeSelector.getThemeType = createSelector(
      getIsOnboardingOrPlacement,
      getInstructionalStep,
      getUnitType,
      (
        isOnboardingOrPlacement: boolean,
        instructionalStep: InstructionalStep | undefined,
        unitType: UnitType
      ) =>
        isOnboardingOrPlacement
          ? ThemeType.Placement
          : instructionalStep === InstructionalStep.Instruction
          ? ThemeType.Instruction
          : unitType === UnitType.PresentationOfKnowledge
          ? ThemeType.PoK
          : ThemeType.Standard
    );
  }
}
