import { IModalConfig, ModalId, ModalType } from './modal.model';
import { ModalRegistry } from './ModalRegistry';

export class ModalUtils {
  public static getConfig(id: ModalId | undefined): IModalConfig | undefined {
    return id ? ModalRegistry.get(id) : undefined;
  }

  public static isDismissible(id: ModalId | undefined): boolean {
    const config = this.getConfig(id);

    if (!config) {
      return false;
    }

    const { preventDismiss, type = ModalType.Default } = config;

    // all errors and confirmations are non-dismissible
    return type === ModalType.Default && !preventDismiss;
  }

  public static isError(id: ModalId | undefined): boolean {
    const config = this.getConfig(id);

    return config?.type === ModalType.Error;
  }
}
