import * as React from 'react';
import { Column, Row, Text } from 'common-ui';
import { ModalRegistry } from 'shared-components/modals';
import { CheatSheetModalStyles } from './CheatSheetModal.styles';

export class CheatSheetModal extends React.PureComponent {
  public static readonly displayName = 'CheatSheetModal';

  public static ModalId = 'CheatSheetModal';

  public render() {
    const styles = CheatSheetModalStyles.get();

    return (
      <Column>
        <Text style={styles.instructions}>
          Note: You can also substitute Opt/Alt (Mac/Windows) instead of Ctrl
          for any of the below
        </Text>
        <Column style={styles.table}>
          <Row>
            <Text style={styles.cell1}>Ctrl + Shift</Text>
            <Text style={styles.cell2}>Toggle Dev Tools shelf</Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + Right arrow</Text>
            <Text style={styles.cell2}>
              Simulate correct answer (when in round)
            </Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + Down arrow</Text>
            <Text style={styles.cell2}>
              Simulate incorrect answer (when in round)
            </Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + Escape</Text>
            <Text style={styles.cell2}>Skip</Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + [Any number 1-8]</Text>
            <Text style={styles.cell2}>
              Play sfx: 1- correct, 2- incorrect, 3- drag begin, 4- drag end, 5-
              drag over dropzone, 6- cant do, 7- neutral, 8- call out
            </Text>
          </Row>
          <Row>
            <Text style={{ ...styles.cell1, ...styles.bottomBorder }}>
              Ctrl + [Any letter A-H]
            </Text>
            <Text style={{ ...styles.cell2, ...styles.bottomBorder }}>
              Play sfx: A- mic listening, B- mic available, C- error, D- popup
              off, E- popup on, F- unheard, G- instruction off, H- instruction
              on
            </Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + I</Text>
            <Text style={styles.cell2}>Log SRE session info to console</Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + K</Text>
            <Text style={styles.cell2}>Go to Solution Phase</Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + L</Text>
            <Text style={styles.cell2}>Play Level Complete animation</Text>
          </Row>
          <Row>
            <Text style={styles.cell1}>Ctrl + S</Text>
            <Text style={styles.cell2}>Toggle spinner</Text>
          </Row>
        </Column>
      </Column>
    );
  }
}

CheatSheetModal.ModalId = ModalRegistry.register({
  Component: CheatSheetModal,
  title: 'Shortcuts Cheat Sheet'
});
