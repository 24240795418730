// istanbul ignore file - not used in production
export class MeasureService {
  public static startMeasure(_label: string, startMark: string) {
    performance.mark(startMark);
  }

  public static endMeasure(label: string, startMark: string, endMark: string) {
    performance.mark(endMark);
    performance.measure(label, startMark, endMark);
  }
}
