import { Position } from '@lexialearning/common-ui';
import {
  Align,
  AnimatableCSSProperty,
  Color,
  Justify,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';

export class SplashScreenAnimatedStyles {
  public static readonly Duration = 250;

  private readonly animations: {
    hide: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
    container: Types.ViewStyle;
    image: Types.ImageStyle;
  };

  constructor() {
    const opacity = loboAnimated.createValue(1);

    this.animations = {
      hide: loboAnimated.timing(AnimatableCSSProperty.Opacity, opacity, {
        duration: SplashScreenAnimatedStyles.Duration,
        easing: loboAnimated.Easing.Out(),
        toValue: 0
      })
    };

    this.styles = {
      container: {
        alignItems: Align.Center,
        backgroundColor: Color.DarkOrange,
        bottom: 0,
        justifyContent: Justify.Center,
        left: 0,
        position: Position.Absolute,
        right: 0,
        top: 0
      },
      containerAnimated: {
        opacity
      },
      image: {
        height: 310,
        width: 272
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
