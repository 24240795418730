import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CalibrationResult } from '@lexialearning/sre';
import { ProgramContextSelector } from 'curriculum-services';
import {
  CalibrationModal,
  CalibrationStep,
  CalibrationUiAction,
  CalibrationUiActionSetStep
} from 'feature-areas/calibration';
import { AppShellAction, AppShellActionShowModal } from 'shell/redux';
import {
  ICalibrationResult,
  ILanguageFrameResult,
  SreCalibrationAction,
  SreCalibrationActionSetCalibrationResult,
  SreSelector
} from 'sre';
import { TaskActionFeedbackComplete, TaskActionType } from '../../core';
import { SeeSpeakHelper } from '../SeeSpeak.helper';

/**
 * Show Recalibration modal when the history of the current See Speak task
 * attempts suggest there may be mic audio problems.
 */
export function maybeRecalibrateEpic(
  action$: Observable<TaskActionFeedbackComplete>,
  state$: StateObservable<unknown>
): Observable<
  | SreCalibrationActionSetCalibrationResult
  | AppShellActionShowModal
  | CalibrationUiActionSetStep
> {
  return action$.pipe(
    ofType(TaskActionType.FeedbackComplete),
    filter(() =>
      SeeSpeakHelper.needsRecalibration(
        ProgramContextSelector.getRoundContext(state$.value)
      )
    ),
    map(() =>
      createCalibrationResult(
        SreSelector.getResult(state$.value) as ILanguageFrameResult | undefined
      )
    ),
    mergeMap((calibrationResult: ICalibrationResult) =>
      from([
        SreCalibrationAction.setCalibrationResult(calibrationResult),
        CalibrationUiAction.setStep({ step: CalibrationStep.MicFix }),
        AppShellAction.showModal({ id: CalibrationModal.ModalId })
      ])
    )
  );
}
maybeRecalibrateEpic.displayName = 'maybeRecalibrateEpic';

function createCalibrationResult(
  result: ILanguageFrameResult | undefined
): ICalibrationResult {
  // Handles edge case where naving away from the page while the result
  // is coming in creates a race condition whereby the result here may
  // be undefined when this is hit
  if (!result) {
    return {
      passed: false,
      result: CalibrationResult.None,
      soundLogId: ''
    };
  }

  const { audioQuality, ...listenResult } = result;
  const calibrationResult = {
    ...listenResult,
    result: audioQuality.calibrationResult,
    soundLogId: ''
  };

  return calibrationResult;
}
