import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramContextSelector } from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import {
  TaskActionPlayAudioSupport,
  TaskActionType,
  TaskSelector
} from '../../redux';
import { TaskAudioSupportFactory } from '../../TaskAudioSupportFactory';
import { ITaskEpicsDependencies } from '../task-epics.model';

export function taskAudioSupportEpic(
  action$: Observable<TaskActionPlayAudioSupport>,
  state$: StateObservable<unknown>,
  deps: ITaskEpicsDependencies
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TaskActionType.PlayAudioSupport),
    map(action => {
      const state = state$.value;
      const phase = TaskSelector.getPhase(state);
      const modeling = TaskSelector.getModeling(state)!;
      const roundContext = ProgramContextSelector.getRoundContext(state);

      const audioSupportScreenplay = TaskAudioSupportFactory.create(
        roundContext.round,
        modeling,
        action.payload
      );

      return deps.taskRegistry.screenplayEditor.edit(
        audioSupportScreenplay,
        roundContext,
        phase
      );
    }),
    map(screenplay => ScreenplayAction.play({ screenplay }))
  );
}
taskAudioSupportEpic.displayName = 'taskAudioSupportEpic';
