import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export class ProgramCompleteSceneAnimationName {
  public static readonly Root = {
    Intro: StandardSceneAnimationName.Intro
  };

  public static readonly Background = {
    Idle: StandardSceneAnimationName.Idle
  };

  public static readonly BackgroundOverlay = {
    Idle: StandardSceneAnimationName.Idle
  };
}

export enum ProgramCompleteSceneMaterialName {
  Certificate = 'certificate'
}

export enum ProgramCompleteSceneElementName {
  Background = 'background',
  BackgroundOverlay = 'background_overlay'
}
