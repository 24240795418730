import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { NoMicModal } from 'feature-areas/errors';
import { ProfileSelector } from 'services/profile';
import { SreStatus } from 'sre';
import {
  SreConfigAction,
  SreConfigActionType,
  SreConfigureSuccessAction,
  SreSelectMicAction,
  SreSelector,
  SreSetMicsAvailableAction
} from 'sre/redux';
import { SystemInfo } from 'utils';
import {
  AppShellAction,
  AppShellActionHideModal,
  AppShellActionShowModal
} from '../../AppShell.action';
import { AppShellSelector } from '../../AppShell.selector';
import { awaitAppInteractive } from '../awaitAppInteractive';

export type NoMicrophoneOutputActions =
  | AppShellActionShowModal
  | AppShellActionHideModal
  | SreSelectMicAction;

export function noMicModalEpic(
  action$: Observable<SreConfigureSuccessAction | SreSetMicsAvailableAction>,
  state$: StateObservable<unknown>
): Observable<NoMicrophoneOutputActions> {
  return action$.pipe(
    ofType(
      SreConfigActionType.ConfigureSuccess,
      SreConfigActionType.SetMicsAvailable
    ),
    // native platforms always have at least one (built-in) microphone
    filter(() => !SystemInfo.isNative),
    awaitAppInteractive(state$),
    // filter out case where state was reset (ie, on logout) while awaiting the above
    // as availableMics will have been reset to []
    filter(
      () => SreSelector.getStatus(state$.value) !== SreStatus.Uninitialized
    ),
    mergeMap(() => {
      const output: NoMicrophoneOutputActions[] = [];

      const availableMics = SreSelector.getAvailableMics(state$.value);
      const modalAlreadyActive =
        AppShellSelector.getActiveModal(state$.value)?.id ===
        NoMicModal.ModalId;

      if (!availableMics.length && !modalAlreadyActive) {
        output.push(AppShellAction.showModal({ id: NoMicModal.ModalId }));
      } else if (availableMics.length && modalAlreadyActive) {
        output.push(AppShellAction.hideModal());

        // for educators, automatically select newly connected mic
        // (students will go through mic changed as part of calibration flow)
        const isStudent = ProfileSelector.isStudent(state$.value);
        if (!isStudent) {
          output.push(
            SreConfigAction.selectMic.request({ mic: availableMics[0] })
          );
        }
      }

      return output;
    })
  );
}
noMicModalEpic.displayName = 'noMicModalEpic';
