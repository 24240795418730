import { connect } from 'react-redux';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { ILoboAppConfig } from 'services/app-config';
import { AppState } from 'services';

export interface IAppStoreUrlProps {
  appStoreUrl: string;
}

// istanbul ignore next - trivial
function mapStateToProps(state: AppState) {
  return {
    appStoreUrl:
      UniConfigSelector.getConfig<ILoboAppConfig>(state).mobileApp.appStoreUrl
  };
}

export const withAppStoreUrl = connect(mapStateToProps);
