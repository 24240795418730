import {
  MaterialChannel,
  StormNode,
  StormTexture
} from '@lexialearning/storm-react';
import { LexiaError } from '@lexialearning/utils';
import {
  IApplyTextureOptions,
  MaterialChannelName
} from './storm-materials.model';

export class TextureApplier {
  public static readonly displayName = 'TextureApplier';

  private static get MaterialChannelMap(): Map<
    MaterialChannelName,
    MaterialChannel
  > {
    if (!this.channelMap) {
      this.channelMap = Object.values(MaterialChannelName).reduce(
        (cMap, c) => cMap.set(c, MaterialChannel[c]),
        new Map<MaterialChannelName, MaterialChannel>()
      );
    }

    return this.channelMap;
  }

  private static channelMap: Map<MaterialChannelName, MaterialChannel>;

  public static applyTexture(
    texture: StormTexture,
    options: IApplyTextureOptions,
    stormNode: StormNode
  ): void {
    const applier = new TextureApplier(stormNode, texture, options);
    applier.apply();
  }

  public constructor(
    private readonly stormNode: StormNode,
    private readonly texture: StormTexture,
    private readonly options: IApplyTextureOptions
  ) {}

  private apply(): void {
    if (!this.texture) {
      throw this.createError(
        'Invalid attempt to apply an invalid texture to',
        TextureApplierError.InvalidTexture
      );
    }

    const material = this.stormNode.findMaterial(this.options.materialName);
    if (!material) {
      throw this.createError(
        'Unable to find',
        TextureApplierError.MaterialNotFound
      );
    }

    const bitmap = material.getBitmap(
      TextureApplier.MaterialChannelMap.get(this.options.channel)!,
      this.options.index
    );
    if (!bitmap) {
      throw this.createError(
        'Unable to get bitmap from',
        TextureApplierError.BitmapNotFound
      );
    }

    bitmap.set(this.texture);
    this.texture.release();
  }

  private createError(
    messagePrefix: string,
    code: TextureApplierError
  ): LexiaError {
    const stormNode = this.stormNode.name;
    this.texture?.release();

    return new LexiaError(
      `${messagePrefix} material ${this.options.materialName} in ${stormNode} node`,
      TextureApplier.displayName,
      code
    ).withContext({ options: this.options, stormNode });
  }
}

export enum TextureApplierError {
  BitmapNotFound = 'BitmapNotFound',
  InvalidTexture = 'InvalidTexture',
  MaterialNotFound = 'MaterialNotFound'
}
