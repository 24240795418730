import { IScreenplayAction } from '@lexialearning/lobo-common';
import { IScreenplayActionPlayer, ScreenplayerType } from 'screenplay';
import { LxStormAudio } from 'storm/audio';
import { IMusicRequest } from './music.model';

export class MusicScreenplayer implements IScreenplayActionPlayer {
  public static readonly displayName = 'MusicScreenplayer';

  public readonly type = ScreenplayerType.Music;

  public constructor(private readonly stormAudioPlayer: LxStormAudio) {}

  public cancel(): void {
    this.stormAudioPlayer.cancelMusic();
  }

  public pause(): void {
    this.stormAudioPlayer.pauseMusic();
  }

  public resume(): void {
    this.stormAudioPlayer.resumeMusic();
  }

  public async play({ data }: IScreenplayAction<IMusicRequest>): Promise<void> {
    await this.stormAudioPlayer.playMusic(data);
  }
}
