import { ActionType } from '@lexialearning/main-model';
import { createAction } from '@reduxjs/toolkit';
import { BootstrappingActionType } from './bootstrapping-redux.model';

export const BootstrappingAction = {
  autoLoginComplete: createAction(BootstrappingActionType.AutoLoginComplete),
  bootstrap: createAction(BootstrappingActionType.Bootstrap),

  configurationComplete: createAction(
    BootstrappingActionType.ConfigurationComplete
  ),

  deepLinkPositionSetupComplete: createAction(
    BootstrappingActionType.DeepLinkPositionSetupComplete
  ),

  deviceSetupComplete: createAction(
    BootstrappingActionType.DeviceSetupComplete
  ),
  /**
   * Bootstrapping halted due too network offline
   * @see checkNetworkEpic
   */
  halted: createAction('bootstrapping/halted')
};

export type BootstrappingActionTypes = ActionType<typeof BootstrappingAction>;
export type BootstrappingActionAutoLoginComplete = ActionType<
  typeof BootstrappingAction.autoLoginComplete
>;
export type BootstrappingActionBootstrap = ActionType<
  typeof BootstrappingAction.bootstrap
>;
export type BootstrappingActionConfigurationComplete = ActionType<
  typeof BootstrappingAction.configurationComplete
>;
export type BootstrappingActionDeepLinkPositionSetupComplete = ActionType<
  typeof BootstrappingAction.deepLinkPositionSetupComplete
>;
export type BootstrappingActionDeviceSetupComplete = ActionType<
  typeof BootstrappingAction.deviceSetupComplete
>;
export type BootstrappingActionHalted = ActionType<
  typeof BootstrappingAction.halted
>;
