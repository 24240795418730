import { AnimatableCSSProperty, Color, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import {
  ISpeechBubblesAnimatedStyles,
  SPEECH_BUBBLE_PADDING,
  SeeSpeakType,
  SpeechBubbleAnimationTimes
} from './speech-bubbles.model';
import { SeeSpeakMode } from '@lexialearning/lobo-common';
import { AnimatedValue } from '@lexialearning/reactxp/dist/common/Types';
import { Value } from '@lexialearning/reactxp/dist/web/Animated';
import { IQuestionAndAnswerBubblesAnimatedStyles } from './question-and-answer/QuestionAndAnswerSpeechBubbles';
import { ISayItAfterSpeechBubblesAnimatedStyles } from './say-it-after/SayItAfterSpeechBubbles';

enum BorderColorInterpolation {
  Black = 0,
  Transparent = 1
}

export type IStandardSpeechBubbleAnimatedStyles = ISpeechBubblesAnimatedStyles &
  IQuestionAndAnswerBubblesAnimatedStyles &
  ISayItAfterSpeechBubblesAnimatedStyles & {
    bubblesContainerAnimated?: {
      opacity: Types.AnimatedValue;
    };
  };

export interface IStandardSpeechBubbleStyles {
  bubblesContainer: Types.ViewStyle;
}

export interface IStandardSpeechBubbleAnimatedValues {
  bubblesOpacity: Types.AnimatedValue;
  prompt: {
    opacity?: AnimatedValue;
    top?: Types.AnimatedValue;
    left?: Types.AnimatedValue;
  };
  question: {
    borderColor?: Value;
    characterTailOpacity?: AnimatedValue;
    opacity?: AnimatedValue;
    textTop?: Types.AnimatedValue;
    textTransformScale?: Types.AnimatedValue;
    top?: Types.AnimatedValue;
    width?: Types.AnimatedValue;
    verticalPadding?: Types.AnimatedValue;
  };
  response: {
    avatarTailOpacity?: Types.AnimatedValue;
    hintBoxHeight?: Types.AnimatedValue;
    hintBoxOpacity?: Types.AnimatedValue;
    micHeight?: Types.AnimatedValue;
    micOpacity: Types.AnimatedValue;
    opacity?: Types.AnimatedValue;
    right?: Types.AnimatedValue;
    top?: Types.AnimatedValue;
    transformScale?: Types.AnimatedValue;
  };
}

export class StandardSpeechBubblesAnimatedStyles {
  private animations: {
    bubblesFadeIn: Types.Animated.CompositeAnimation;
    responseEntry: Types.Animated.CompositeAnimation;
    micFadeIn: Types.Animated.CompositeAnimation;
  };

  private styles: IStandardSpeechBubbleStyles &
    IStandardSpeechBubbleAnimatedStyles;

  constructor(type: SeeSpeakType, mode: SeeSpeakMode) {
    const animatedValues = this.createAnimatedValues(type, mode);

    const bubblesFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      animatedValues.bubblesOpacity,
      {
        duration: SpeechBubbleAnimationTimes.FadeIn,
        toValue: 1
      }
    );

    const responseEntry = this.createResponseEntryAnimation(
      animatedValues,
      type
    );

    const micFadeIn = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      animatedValues.response.micOpacity,
      {
        delay: 300,
        duration: SpeechBubbleAnimationTimes.Standard,
        toValue: 1
      }
    );

    this.animations = {
      bubblesFadeIn,
      micFadeIn,
      responseEntry
    };

    this.styles = {
      // STATIC
      bubblesContainer: {
        marginTop: 55
      },
      // ANIMATED
      bubblesContainerAnimated: {
        opacity: animatedValues.bubblesOpacity
      },
      prompt: {
        container: {
          top: animatedValues.prompt.top,
          left: animatedValues.prompt.left,
          opacity: animatedValues.prompt.opacity
        }
      },
      question: {
        characterSpeechTail: {
          opacity: animatedValues.question.characterTailOpacity
        },
        container: {
          borderColor:
            animatedValues.question.borderColor &&
            this.getBorderColorInterpolation(
              animatedValues.question.borderColor
            ),
          opacity: animatedValues.question.opacity,
          top: animatedValues.question.top,
          width: animatedValues.question.top
        },
        containerVerticalPadding: {
          height: animatedValues.question.verticalPadding
        },
        text: {
          top: animatedValues.question.textTop,
          transform: animatedValues.question.textTransformScale && [
            { scale: animatedValues.question.textTransformScale }
          ]
        }
      },
      response: {
        avatarSpeechTail: {
          opacity: animatedValues.response.avatarTailOpacity
        },
        container: {
          opacity: animatedValues.response.opacity,
          top: animatedValues.response.top,
          right: animatedValues.response.right,
          transform: animatedValues.response.transformScale && [
            { scale: animatedValues.response.transformScale }
          ]
        },
        hintBox: {
          height: animatedValues.response.hintBoxHeight,
          opacity: animatedValues.response.hintBoxOpacity
        },
        languageFrame: {},
        sreButtonMeter: {
          height: animatedValues.response.micHeight,
          opacity: animatedValues.response.micOpacity
        }
      }
    };
  }

  private getBorderColorInterpolation(
    questionBorderColor: Value
  ): Types.AnimatedValue {
    return loboAnimated.interpolate(
      questionBorderColor,
      [BorderColorInterpolation.Black, BorderColorInterpolation.Transparent],
      [Color.Black, Color.Transparent]
    );
  }

  private createAnimatedValues(
    type: SeeSpeakType,
    mode: SeeSpeakMode
  ): IStandardSpeechBubbleAnimatedValues {
    const values: IStandardSpeechBubbleAnimatedValues = {
      bubblesOpacity: loboAnimated.createValue(0),
      prompt: {},
      question: {},
      response: {
        micOpacity: loboAnimated.createValue(0)
      }
    };

    if ([SeeSpeakType.Choral, SeeSpeakType.QuestionAnswer].includes(type)) {
      values.response.avatarTailOpacity = loboAnimated.createValue(0);
      values.response.micHeight = loboAnimated.createValue(0);
    }

    if ([SeeSpeakType.QuestionAnswer].includes(type)) {
      values.question.borderColor = loboAnimated.createValue(
        BorderColorInterpolation.Black
      );
      values.question.characterTailOpacity = loboAnimated.createValue(1);
      values.question.opacity = loboAnimated.createValue(1);
      values.question.textTop = loboAnimated.createValue(0);
      values.question.textTransformScale = loboAnimated.createValue(1);
      values.question.top = loboAnimated.createValue(0);
      values.question.verticalPadding = loboAnimated.createValue(
        SPEECH_BUBBLE_PADDING
      );

      values.response.opacity = loboAnimated.createValue(0.5);
      values.response.right = loboAnimated.createValue(-72);
      values.response.top = loboAnimated.createValue(20);
      values.response.transformScale = loboAnimated.createValue(0.92);

      if (mode === SeeSpeakMode.OpenScaffold) {
        values.response.hintBoxHeight = loboAnimated.createValue(0);
        values.response.hintBoxOpacity = loboAnimated.createValue(0);
      }
    }

    if ([SeeSpeakType.SayItAfter].includes(type)) {
      values.prompt.left = loboAnimated.createValue(0);
      values.prompt.opacity = loboAnimated.createValue(1);
      values.prompt.top = loboAnimated.createValue(0);
      values.response.opacity = loboAnimated.createValue(0);
    }

    return values;
  }

  private createResponseEntryAnimation(
    animatedValues: IStandardSpeechBubbleAnimatedValues,
    type: SeeSpeakType
  ): Types.Animated.CompositeAnimation {
    const promptFadeOut =
      animatedValues.prompt.opacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.prompt.opacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0
        }
      );
    const promptPartialFadeOut =
      animatedValues.prompt.opacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.prompt.opacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0.5
        }
      );
    const promptShiftLeft =
      animatedValues.prompt.left &&
      loboAnimated.timing(
        AnimatableCSSProperty.Left,
        animatedValues.prompt.left,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: -18
        }
      );
    const promptShiftUp =
      animatedValues.prompt.top &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.prompt.top,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: -30
        }
      );

    const questionBorderColorFadeOut =
      animatedValues.question.borderColor &&
      loboAnimated.timing(
        AnimatableCSSProperty.BorderColor,
        animatedValues.question.borderColor,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: BorderColorInterpolation.Transparent
        }
      );
    const questionCharacterSpeechTailFadeOut =
      animatedValues.question.characterTailOpacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.question.characterTailOpacity,
        {
          duration: SpeechBubbleAnimationTimes.Short,
          toValue: 0
        }
      );
    const questionPartialFadeOut =
      animatedValues.question.opacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.question.opacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0.5
        }
      );
    const questionShiftUp =
      animatedValues.question.top &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.question.top,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: -20
        }
      );
    const questionTextScaleDown =
      animatedValues.question.textTransformScale &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.question.textTransformScale,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0.92
        }
      );
    const questionTextShiftDown =
      animatedValues.question.textTop &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.question.textTop,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 3
        }
      );
    const questionVerticalPaddingShrink =
      animatedValues.question.verticalPadding &&
      loboAnimated.timing(
        AnimatableCSSProperty.Height,
        animatedValues.question.verticalPadding,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 5
        }
      );

    const responseAvatarTailFadeIn =
      animatedValues.response.avatarTailOpacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.response.avatarTailOpacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 1
        }
      );
    const responseHintBoxExpandHeight =
      animatedValues.response.hintBoxHeight &&
      loboAnimated.timing(
        AnimatableCSSProperty.Height,
        animatedValues.response.hintBoxHeight,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 85
        }
      );
    const responseHintBoxFadeIn =
      animatedValues.response.hintBoxOpacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.response.hintBoxOpacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 1
        }
      );
    const responseMicExpandHeight =
      animatedValues.response.micHeight &&
      loboAnimated.timing(
        AnimatableCSSProperty.Height,
        animatedValues.response.micHeight,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 85
        }
      );
    const responseShiftUp =
      animatedValues.response.top &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.response.top,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0
        }
      );
    const responseScaleUp =
      animatedValues.response.transformScale &&
      loboAnimated.timing(
        AnimatableCSSProperty.Top,
        animatedValues.response.transformScale,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 1
        }
      );
    const responseShiftLeft =
      animatedValues.response.right &&
      loboAnimated.timing(
        AnimatableCSSProperty.Right,
        animatedValues.response.right,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 0
        }
      );
    const responseFadeIn =
      animatedValues.response.opacity &&
      loboAnimated.timing(
        AnimatableCSSProperty.Opacity,
        animatedValues.response.opacity,
        {
          duration: SpeechBubbleAnimationTimes.Standard,
          toValue: 1
        }
      );

    const responseEntry = loboAnimated.parallel(
      [
        promptPartialFadeOut,
        promptShiftLeft,
        promptShiftUp,
        questionBorderColorFadeOut,
        questionCharacterSpeechTailFadeOut,
        questionPartialFadeOut,
        questionShiftUp,
        questionTextScaleDown,
        questionTextShiftDown,
        questionVerticalPaddingShrink,
        responseAvatarTailFadeIn,
        responseHintBoxExpandHeight,
        responseHintBoxFadeIn,
        responseMicExpandHeight,
        responseScaleUp,
        responseShiftLeft,
        responseShiftUp,
        ![SeeSpeakType.SayItAfter].includes(type) && responseFadeIn
      ].filter(a => !!a) as Types.Animated.CompositeAnimation[]
    );

    if ([SeeSpeakType.SayItAfter].includes(type)) {
      return loboAnimated.sequence([
        responseEntry,
        responseFadeIn!,
        promptFadeOut!
      ]);
    }

    return responseEntry;
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
