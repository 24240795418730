import { Svg, Path, G, Rect } from '@lexialearning/common-ui';
import { useSelector } from 'react-redux';
import { ProfileSelector } from 'services/profile';
import { FaceStyle } from 'services/profile/avatar.model';
import { getFaceColors } from '../face-styles.model';

export function FaceStyleThreeSvg(): React.ReactElement {
  const { hairColor, skinColor } = useSelector(ProfileSelector.getAvatarMaybe)!;
  const {
    eyeLines,
    eyebrowLines,
    eyebrows,
    eyelashes,
    eyes,
    lips,
    skin,
    skinLines
  } = getFaceColors(FaceStyle.Three, hairColor, skinColor);

  return (
    <Svg
      data-test-id={FaceStyleThreeSvg.displayName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
    >
      <G>
        <Rect fill={skin} strokeWidth="0" width="1000" height="1000" />
      </G>
      <G>
        <G>
          <Path
            fill={lips}
            strokeWidth="0"
            d="M707.51,881.17c4.45,5.01-3.48,12.89-10.3,13.75-12.71,1.61-25.77,3.21-38.41,2.01-32.32-3.06-64.46-7.98-96.74-11.56-26.53-2.94-53.03-7.51-79.86-7.52-19.23,0-38.38,2.68-57.34,5.5-30.1,4.48-59.92,10.95-90.06,15.09-11.69,1.61-23.95.58-35.91-1.63-2.99-.55-6.32-2.56-8.57-4.83-1.72-1.74-3.34-5.54-2.54-7.32,1.13-2.51,4.31-5.06,7.02-5.64,3.01-.64,6.72.36,9.73,1.6,12.36,5.11,22.47,1.95,31.8-7.03,9.31-8.97,18.63-18.08,28.89-25.87,16.3-12.36,32.9-24.92,54.42-26.51,8.23-.61,16.59.73,24.87.51,9.48-.25,19.34.24,28.32-2.21,23.77-6.5,47.84-8.43,69.97,2.33,21.1,10.27,63.26,31.07,83.3,43.28,21.32,13,29.48,26.94,66.66,11.83,3.55-1.44,12.19,1.35,14.74,4.21Z"
          />
          <G>
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M351.24,611.26c.14-23.04,12.5-63.46,24.65-103.91.48-1.59,1.19-3.13,2.02-4.57.35-.62,1.74-1.39,1.85-1.28.77.76,1.9,1.77,1.84,2.63-.21,2.89-.83,5.75-1.37,8.6-6.29,33.55-13.78,66.93-16.16,101.09-2.3,33.04,1.33,64.89,18.1,94.55,10.39,18.37,26.65,29.59,43.58,40.47,2.81,1.81,6.26,2.92,8.49,5.24,1.96,2.04,5.25,5.43,3.83,8.08-1.45,2.73-6.54,2.29-9.06,1.37-15.58-5.69-28.52-15.75-40.14-27.23-29.32-28.95-38.85-65.25-37.62-125.04Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M764.02,273.19c-.11.08-1.55,1.83-3.34,2.34-7.19,2.05-14.51,3.59-21.73,5.53-41.66,11.25-73.71,36.08-98.77,70.55-9.08,12.48-17.59,25.37-26.55,37.95-1.3,1.82-3.31,5.28-5.45,4.35-2.38-1.04-1.49-5.66-.53-7.69,21.38-45.6,50.48-84.69,97.13-106.83,15.16-7.19,32.38-10.2,48.86-14.31,4.06-1.01,11.08-2.69,10.38,8.11Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M212.54,323.45c-6.51,0-12.37.16-18.2-.12-1.4-.07-3.85-.85-4.08-2.44-.27-1.88,2.38-3.92,3.79-4.1,11.92-1.51,24.22-4.94,35.81-3.38,34.98,4.73,67.52,16.74,93.66,41.54,9.6,9.11,17.3,20.26,25.43,30.82,1.28,1.66,2.4,6.26.24,7.76-2.19,1.52-5.67-1.22-7.36-2.95-11.09-11.33-20.62-24.58-32.98-34.21-28.23-21.98-61.29-31.69-96.3-32.95Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M574.09,669.74c3.93,15.53-1.1,28.55-10.22,40.39-6.53,8.48-21.14,15.41-32.76,14.73-2.49-.15-5.01,0-7.52.12-10.91.49-21.64,1.29-29.79,10.23-1.34,1.47-4.56,3.2-6.59,2.05-2.33-1.31-2.53-5.48-1.9-7.67,2.59-9.02,10.19-13.06,18.37-15.33,6.35-1.76,13.1-2.49,19.72-2.85,31.78-1.73,46.59-22.09,38.22-53.31-1.5-5.58-4.6-10.71-6.27-16.26-.67-2.24-1.49-6.49.83-7.57,2.31-1.08,5.47,2.26,6.26,4.19,4.23,10.28,8.65,19.48,11.63,31.28Z"
            />
            <Path
              fill={skinLines}
              strokeWidth="0"
              d="M400.49,366.63c-.82,9.81-1.19,17.74-2.32,25.56-.33,2.27-1.73,6.44-4.3,6.32s-3.15-4.44-3.81-6.91c-.62-2.31-.04-4.96.08-7.45,1.1-22.77-1.52-44.79-12.4-65.34-1.12-2.12-2.69-6.46-.45-7.91,2.24-1.45,5.64,1.73,7.05,3.64,11.84,16,16.36,34.29,16.15,52.08Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M896.52,465.89c-14.06,54.94-91.19,91.16-150.7,84.33-46.64-5.36-102.35-43.3-102.35-65.13,0-49.88,67.76-146.83,136.93-145.45,78.59,1.56,128.49,77.93,116.12,126.25Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M125.28,485.35c14.06,54.94,61.19,79.59,121.07,80.66,52,.93,91.81-30.11,91.81-51.94,0-49.88-43.03-133.04-112.2-131.66-78.59,1.56-113.05,54.62-100.68,102.94Z"
            />
            <G>
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M327.57,479.05c0,3.08-1.68,11.61-2.8,14.54-6.15,16.78-14.54,30.48-28.95,41.39-50.48,38.04-114.11,13-132.99-40.55-10.07-28.25-2.94-72.72,22.37-89.64,16.64-11.19,54.68-18.74,74.25-14.54,38.18,8.11,68.1,49.78,68.1,88.8Z"
              />
              <Path
                fill={eyes}
                strokeWidth="0"
                d="M873.36,474.71c-7.55,17.2-19.72,30.06-34.82,40.13-41.25,27.27-90.61,18.74-120.26-13.28-31.18-33.84-36.64-79.71-17.34-115.09,19.58-35.94,67.26-43.91,92.57-42.23,25.31,1.68,46.71-2.66,78.31,46.71,13,20.42,11.47,61.25,1.54,83.76Z"
              />
            </G>
            <G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M247.78,424.67c28.61.7,50.65,33.48,37.3,59.65-11.65,25.75-50.64,30.71-69.93,10.01-25.82-24.46-4.96-70.69,29.95-69.71l2.69.06Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M185.16,404.81c-25.2,16.92-32.34,61.4-22.38,89.67,18.88,53.57,82.51,78.5,133.05,40.45,14.38-10.83,22.72-24.54,28.96-41.36,1.08-2.9,2.84-11.49,2.84-14.59,0-38.99-30.03-80.56-68.11-88.67-19.65-4.19-57.68,3.3-74.36,14.5ZM282.66,531.65c-10,5.44-29.46,8.29-40.91,9.35-37.95,3.51-67.1-30.62-68.36-72.88-1.47-49.45,45.42-80.1,92.05-60.7,26.91,11.2,40.14,24.35,48.49,53.49,7.08,24.73-4.47,56.15-31.27,70.73Z"
                />
              </G>
              <G>
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M700.92,386.5c-19.24,35.4-13.8,81.24,17.41,115.05,29.62,32.09,78.9,40.56,120.17,13.24,15.18-10.06,27.33-22.87,34.88-40.03,9.9-22.52,11.48-63.46-1.54-83.8-31.59-49.35-52.97-45.08-78.31-46.72-25.4-1.65-73.04,6.25-92.61,42.25ZM788.15,518.91c-54.65-3.18-84.07-46.46-80.71-93.03,2.94-40.78,34.31-67.78,79.43-68.4,59.42-.82,80.23,46.56,79.08,82.89-1.51,48.06-38.84,80.8-77.8,78.54Z"
                />
                <Path
                  fill={eyeLines}
                  strokeWidth="0"
                  d="M736.21,430.2c6.61-66.14,101.83-47.82,95.15,14.59-8.71,62.94-96.84,47.7-95.27-11.85l.12-2.75Z"
                />
              </G>
            </G>
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M752.94,462.35c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
            <Path
              fill="#FFFFF3"
              strokeWidth="0"
              d="M220.49,492.16c1.21,3.62-7.53,6.83-16.83,6.83s-15.66-1.53-16.83-6.83c-1.67-7.54,7.53-9.92,16.83-9.92s15.24,5.17,16.83,9.92Z"
            />
          </G>
          <G>
            <G>
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M230.56,571.98c-11.72.65-77.2-14.54-68.82-30.49,4.01-1.67,10.64,1.35,15.16,3.44,25.53,13.23,52.93,18.2,80.81,15.9,6.92-.06,17.05-2.3,22.87.08,2.23,5.98-37.38,11.13-47.27,11.09l-2.75-.02Z"
              />
              <Path
                fill={eyelashes}
                strokeWidth="0"
                d="M331.58,488.69c.8,3.67,1.48,13.04,2.73,16.58.48,1.36,2.26,8.81,3.85,8.81s2.21-7.43,2.56-8.75c1.08-4.02,1.91-8.16,2.33-12.3,1.79-17.55-2.13-34.03-9.29-49.98-17.55-39.08-44.7-66.09-88.95-72.43-22.66-3.25-43.97,1.44-65,9.22-26.32,9.74-50.11,23.33-67.97,45.47-11.47,14.21-16.53,30.17-7.57,47.75,4.93,9.66,10.33,19.12,16.19,28.24,2.63,4.11,6.66,7.45,10.51,10.59,1.74,1.42,4.58,1.5,6.92,2.19-.07-2.31.41-4.81-.32-6.89-1.51-4.32-3.69-8.4-5.55-12.6-4.63-10.48-8.81-21.3-4.29-32.67,8.2-20.66,24.62-32.37,42.75-43.79,25.65-16.15,58.1-32.77,98.26-16.6,11.44,4.61,25.07,11.82,36.62,27.11,8.98,11.9,18.98,26.82,26.22,60.05Z"
              />
            </G>
            <Path
              fill={eyelashes}
              strokeWidth="0"
              d="M768.1,556.66c-16.89.1-37.61-3.76-53.42-11-22.77-11.7-6.79-12.58,6.26-7.63,36.54,12.33,74.21,8.39,108.99-9.59,4.81-2.23,11.39-5.52,15.88-5,6.29.44.6,9.85-10.19,16.12-18.67,10.81-44.17,15.98-64.84,17.04l-2.67.05Z"
            />
            <Path
              fill={eyelashes}
              strokeWidth="0"
              d="M789.26,349.84c48.86-1.48,74.85,33.64,91.79,53.87,10.56,12.61,17.5,25.54,14.81,42.01-1.61,9.86-4.78,19.46-6.92,29.24-.47,2.13.49,4.58.79,6.88,2.17-.88,5.03-1.17,6.42-2.74,13.92-15.86,26.22-32.68,28.62-54.58,1.29-11.72-1.23-22.59-9.08-31.61-6.54-7.51-13.46-14.7-20.49-21.76-29.01-29.13-64.62-41.79-105.09-42.06-21.47-.88-41.6,3.96-61.01,12.49-47.33,20.79-76.21,57.4-89.68,106.46-3.15,11.46-2.91,23.9-3.7,35.92-.14,2.13,2.35,4.43,3.63,6.66,1.42-1.83,3.48-3.45,4.13-5.52,2.64-8.35,4.46-16.96,7.3-25.24,9.85-28.77,23.45-54.86,47.02-75.53,19.25-16.88,50.04-33.23,91.47-34.49Z"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M911.42,245.38c-10.85-4.57-21.1-8.88-31.45-12.52-31.54-11.29-65.78-16.78-104.66-16.78-4.54,0-9.09.08-13.66.22-41.86,1.45-84.99,7.69-131.88,19.1-7.19,1.76-13.99,2.72-20.22,2.84h-.11c-5.16,0-8.1-.82-9-2.24-.87-1.39-.36-4.3,1.42-7.99,5.25-11.24,12.62-22.96,22.55-35.87,7.39-9.68,15.46-19.75,25.73-27.28,13.23-9.61,23.8-13.72,35.29-13.72,1.15,0,2.31.04,3.5.12,67.37,5.24,120.34,20.21,166.72,47.09,24.59,14.22,45.92,31.76,59.28,48.52-1.19-.51-2.36-1-3.52-1.47Z"
            />
            <Path
              fill={eyebrows}
              strokeWidth="0"
              d="M72.97,289.27c2.51-6.16,11.2-18.22,22.86-31.52,25.52-29.05,52.81-47.97,83.47-57.85l5.59-1.82c15.96-5.19,31.04-10.11,47.49-10.11l2.78.05c15.46.41,28.82,5.64,38.67,15.12,17.41,16.82,34.08,35.22,48.34,51.13,2.54,2.79,4.6,7.09,3.54,8.85-.71,1.15-2.8,1.82-5.74,1.82-2.24,0-4.32-.38-5.35-.61-9.67-2.23-19.23-4.9-28.79-7.57-10.14-2.83-20.26-5.65-30.48-7.96-18.66-4.32-36.99-6.5-54.45-6.5-40.39,0-78.17,11.72-112.31,34.85-1.91,1.37-3.96,3.09-6.1,4.89-3.15,2.64-6.42,5.38-9.41,7.18-.03.01-.06.03-.1.04Z"
            />
          </G>
          <G>
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M924.86,254.74c-17.08-6.95-31.39-13.35-46.11-18.63-37.79-13.56-77.01-17.54-117.01-16.25-44.45,1.43-88.01,8.46-131.1,18.94-6.85,1.67-13.98,2.74-21.02,2.94-11.94.34-16.09-4.43-10.95-15.26,6.12-12.89,14.22-25.08,22.96-36.46,7.77-10.11,15.95-20.25,26.46-27.97,13.23-9.72,25.83-15.4,41.14-14.23,59.27,4.55,116.28,17.49,168.19,47.55,29.44,17.04,54.89,39.18,67.44,59.37ZM605.94,228.93c.12,3.6,8.19,4.27,16.83,2.3,11.09-2.54,35.99-6.36,60.84-11.02,50.47-9.46,101.14-11.52,151.96-3.63,17.9,2.78,38.81,11.97,60.13,18.11-7.9-8.14-20.68-18.82-28.54-23.53-45.14-27.07-93.71-44.32-146.33-50.9-30.08-3.76-53.88-.33-79.46,22.14-11.21,9.84-35.69,38.72-35.44,46.53Z"
            />
            <Path
              fill={eyebrowLines}
              strokeWidth="0"
              d="M68.69,292.26c-.08-7.57,16.62-27.77,24.5-36.74,23.28-26.51,50.4-47.69,85.05-58.92,18.73-6.07,36.89-12.73,57.05-12.1,15.83.5,29.99,5.59,40.97,16.15,16.96,16.31,32.8,33.79,48.53,51.29,2.76,3.07,6.25,9.17,3.85,13.07-2.66,4.31-10.33,3.73-14.81,2.69-19.9-4.62-39.39-10.94-59.3-15.52-58.53-13.46-113.62-6.17-163.94,27.95-4.55,3.09-10.44,8.89-15.67,12.13-1.23.77-6.24,1.45-6.25,0ZM316.77,259.07c1.65-6.29-28.17-37.45-45.98-51.62-17.16-13.65-36.12-15.24-55.47-10.9-39.03,8.76-75.58,23.41-104.55,52.21-3.13,3.11-5.56,6.9-8.32,10.37,35.51-13.92,70.56-21.72,107.91-23.16,37.45-1.44,104.11,31.84,106.4,23.1Z"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
FaceStyleThreeSvg.displayName = 'FaceStyleThreeSvg';
