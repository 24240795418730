import { Position } from '@lexialearning/common-ui';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from 'common-ui';
import { OrderingDimensions } from 'task-components/shared';
import { ThemeSize } from 'theme';

const animationDurations = {
  movePill: 250
};

const pillVerticalMargin = 3;

interface IVerticalOrderingSegmentStyleProps {
  themeSize?: ThemeSize;
}

export class VerticalOrderingSegmentAnimatedStyles {
  private readonly animations: {
    moveUp: Types.Animated.CompositeAnimation;
    moveDown: Types.Animated.CompositeAnimation;
    moveToDefault: Types.Animated.CompositeAnimation;
    reset: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    indicatorContainer: Types.ViewStyle;
    textPillContainerAnimated: Types.AnimatedViewStyle;
  };

  constructor(props: IVerticalOrderingSegmentStyleProps) {
    const { themeSize } = props;
    const segmentTranslateY = loboAnimated.createValue(0);

    const pillHeight =
      OrderingDimensions.textPillHeight(themeSize!) + pillVerticalMargin + 5;

    this.animations = {
      moveDown: loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        segmentTranslateY,
        {
          duration: animationDurations.movePill,
          easing: loboAnimated.Easing.In(),
          toValue: pillHeight
        }
      ),
      moveToDefault: loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        segmentTranslateY,
        {
          duration: animationDurations.movePill,
          easing: loboAnimated.Easing.Out(),
          toValue: 0
        }
      ),
      moveUp: loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        segmentTranslateY,
        {
          duration: animationDurations.movePill,
          easing: loboAnimated.Easing.In(),
          toValue: -pillHeight
        }
      ),
      reset: loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        segmentTranslateY,
        {
          duration: 0,
          toValue: 0
        }
      )
    };

    this.styles = {
      indicatorContainer: {
        bottom: 0,
        left: 0,
        position: Position.Absolute,
        right: 0,
        top: 0
      },
      textPillContainerAnimated: {
        transform: [{ translateY: segmentTranslateY }]
      }
    };
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return this.styles;
  }
}
