import memoizeOne from 'memoize-one';
import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { GlossyButtonType } from './svgs/glossy-button-svg.model';
import { Types } from '../../../types';

export interface IGlossyButtonStyleOverride {
  width?: number;
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
}

export interface IGlossyButtonAnimations {
  buttonCallout: Types.Animated.CompositeAnimation;
  buttonShake: Types.Animated.CompositeAnimation;
}

const TRANSITION_DURATION = 100;

export class GlossyButtonAnimatedStyles {
  private readonly animatedValues: {
    scale: Types.AnimatedValue;
    translateX: Types.AnimatedValue;
  };

  private readonly animations: IGlossyButtonAnimations;

  constructor() {
    this.animatedValues = {
      scale: loboAnimated.createValue(1),
      translateX: loboAnimated.createValue(0)
    };

    this.animations = {
      buttonCallout: loboAnimated.sequence([
        loboAnimated.timing(
          AnimatableCSSProperty.Scale,
          this.animatedValues.scale,
          {
            duration: TRANSITION_DURATION,
            easing: loboAnimated.Easing.In(),
            toValue: 1.4
          }
        ),
        loboAnimated.timing(
          AnimatableCSSProperty.Scale,
          this.animatedValues.scale,
          {
            duration: TRANSITION_DURATION,
            easing: loboAnimated.Easing.Out(),
            toValue: 1.25
          }
        ),
        loboAnimated.timing(
          AnimatableCSSProperty.Scale,
          this.animatedValues.scale,
          {
            duration: TRANSITION_DURATION,
            easing: loboAnimated.Easing.In(),
            toValue: 1.4
          }
        ),
        loboAnimated.timing(
          AnimatableCSSProperty.Scale,
          this.animatedValues.scale,
          {
            duration: TRANSITION_DURATION,
            easing: loboAnimated.Easing.Out(),
            toValue: 1
          }
        )
      ]),
      buttonShake: loboAnimated.sequence([
        ...this.animateTranslateLeftRight(),
        ...this.animateTranslateLeftRight(),
        ...this.animateTranslateLeftRight(),
        this.animateTranslateX(0)
      ])
    };
  }

  private animateTranslateLeftRight() {
    return [this.animateTranslateX(-1), this.animateTranslateX(1)];
  }

  private animateTranslateX(value: number) {
    return loboAnimated.timing(
      AnimatableCSSProperty.TranslateX,
      this.animatedValues.translateX,
      {
        duration: TRANSITION_DURATION / 2,
        easing: loboAnimated.Easing.In(),
        toValue: value
      }
    );
  }

  public getAnimations() {
    return this.animations;
  }

  public readonly build = memoizeOne(
    (type: GlossyButtonType, styleOverride?: IGlossyButtonStyleOverride) => {
      const isSquare = type === GlossyButtonType.Square;
      const withDropShadow = type === GlossyButtonType.RoundWithDropShadow;

      return {
        animatedColumn: {
          transform: [
            { scale: this.animatedValues.scale },
            { translateX: this.animatedValues.translateX }
          ]
        },
        button: {
          height: isSquare ? 95 : withDropShadow ? 75 : 62,
          width: isSquare ? 121 : withDropShadow ? 75 : 62,
          ...styleOverride
        }
      };
    }
  );
}
