import * as React from 'react';
import { Row } from '../layout/row/Row';
import { View } from '../view/View';
import { ITitlePillStyleOverrides, TitlePillStyles } from './TitlePill.styles';

export interface ITitlePillProps {
  children?: React.ReactNode;
  styleOverride?: ITitlePillStyleOverrides;
  testId?: string;
}
export function TitlePill(props: ITitlePillProps) {
  const { children, styleOverride, testId } = props;
  const styles = TitlePillStyles.build(styleOverride);

  return (
    <Row style={styles.container} testId={testId || TitlePill.displayName}>
      <View style={styles.lineLeft} />
      <Row style={styles.pillOutline}>
        <Row style={styles.pill}>{children}</Row>
      </Row>
      <View style={styles.lineRight} />
    </Row>
  );
}
TitlePill.displayName = 'TitlePill';
