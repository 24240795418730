import {
  ConfigSelector as UniConfigSelector,
  IBootstrapper,
  IErrorModal,
  IModalDefinition
} from '@lexialearning/utils-react';
import { ReducersMapObject, Store } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { ReduxDispatchPlayer } from 'screenplay';
import { SuppressorErrorModal } from 'services/error-handler/epics/SuppressorError.modal';
import { AppState, loboReducers } from './redux';
import { allEpics } from './redux/all.epic';
import { SelectorsFactory } from './SelectorsFactory';
import {
  IServiceDependencies,
  ServiceDependenciesFactory
} from './ServiceDependencies.factory';
import { ILoboAppConfig } from 'services/app-config';

export class AppBootstrapper
  implements IBootstrapper<IServiceDependencies, AppState>
{
  public static readonly displayName = 'AppBootstrapper';

  public readonly displayName = AppBootstrapper.displayName;

  private readonly deps = ServiceDependenciesFactory.create();

  public getDependencies(): IServiceDependencies {
    return this.deps;
  }

  public getErrorModals(): IErrorModal[] {
    return [new SuppressorErrorModal()];
  }

  // TODO: Ensure all epics have a displayName!
  public getEpics(): Epic[] {
    return allEpics;
  }

  public getReducer(): ReducersMapObject<AppState> {
    return { ...loboReducers } as any;
  }

  public getModals(): IModalDefinition[] {
    return [];
  }

  public async onReduxStoreCreated(store: Store) {
    this.deps.configProvider.init(
      () => UniConfigSelector.getConfig<ILoboAppConfig>(store.getState()) as any
    );
    this.deps.stateManager.init(store.getState, store.dispatch);
    this.deps.studentProgressApi.init(store.dispatch);
    this.deps.screenplayer.addPlayer(new ReduxDispatchPlayer(store.dispatch));
  }

  public setUp(): void {
    SelectorsFactory.create();
  }
}
