// istanbul ignore file - trivial
/* eslint-disable max-classes-per-file */
import { TopicCode } from '@lexialearning/lobo-common';
import { sample } from 'lodash';
import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export enum LevelAnimationCategory {
  ActMeter = 'Level.ActMeter',
  Root = 'Level.Root',
  Showcase = 'Level.Showcase'
}

export class LevelSceneElementName {
  public static readonly BackgroundOverlay = 'background_overlay';

  public static readonly CharacterComplete = 'character_complete';

  public static readonly Lighting = 'lighting';

  public static readonly Showcase = 'showcase';

  public static readonly Effects = 'effects';

  public static buildActMeter(idx: number): string {
    return `act_meter_${idx}`;
  }

  public static buildBackground(idx: number): string {
    return `background_${idx}`;
  }

  public static buildCharacter(idx: number): string {
    return `character_${idx}`;
  }
}

export class LevelSceneMaterialName {
  public static buildEncounterMat(idx: number) {
    return `enc_${idx}_mat`;
  }

  public static buildAllEncounterMats(): string[] {
    return Array.from({ length: 3 }, (_, idx) =>
      this.buildEncounterMat(idx + 1)
    );
  }

  public static buildSwatchMat(idx: number) {
    return `swatch_${idx}_mat`;
  }

  public static buildAllSwatchMats(): string[] {
    return Array.from({ length: 4 }, (_, idx) => this.buildSwatchMat(idx + 1));
  }
}

export class LevelSceneAnimationName {
  public static readonly Root = {
    CharacterCompleteIdle: 'char_complete_idle',
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro,
    buildCharacterIdle(selectedActIdx: number) {
      return `char_${selectedActIdx}_idle`;
    },
    buildCharacterSelection(selectedActIdx: number) {
      return `char_${selectedActIdx}`;
    }
  };

  public static readonly ActMeter = {
    Fill: 'fill'
  };

  public static FunFactsEffects = {
    Idle: StandardSceneAnimationName.Idle,
    Init: 'init',
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro
  };

  public static readonly Background = {
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro
  };

  public static readonly BackgroundOverlay = {
    Idle: StandardSceneAnimationName.Idle
  };

  public static Character = {
    IntroWave: 'intro_wave_1',
    buildIdle(idx: number) {
      return `idle_extended_pose_${idx}`;
    }
  };

  public static readonly Showcase = {
    buildIntro(currentEncounter: number) {
      return `enc_${currentEncounter}_intro`;
    },
    buildMeterFill(currentEncounter: number) {
      return `enc_${currentEncounter}_fill`;
    }
  };

  public static CharacterComplete = {
    Celebrations: [
      'celebration_bat_dance',
      'celebration_bhangra_1',
      'celebration_bhangra_2',
      'celebration_big_fish',
      'celebration_biphop',
      'celebration_cabbage_patch',
      'celebration_dabble',
      'celebration_floss',
      'celebration_jumpjig',
      'celebration_robot',
      'celebration_running_man',
      'celebration_tucker',
      'celebration_twist',
      'celebration_window'
    ],
    getRandomCelebration() {
      return sample(LevelSceneAnimationName.CharacterComplete.Celebrations);
    }
  };
}

export const swatchTopicColorMap = new Map<TopicCode, string>([
  [TopicCode.Biographies, '1'],
  [TopicCode.Math, '2'],
  [TopicCode.Science, '3'],
  [TopicCode.SocialStudies, '4']
]);

export enum LevelSceneLayout {
  ActComplete = 'ActComplete',
  ActSelection = 'ActSelection',
  Showcase = 'Showcase'
}
