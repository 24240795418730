import { last, maxBy } from 'lodash';
import { XYCoord } from 'react-dnd';
import { Types } from 'common-ui';

export interface IDimensions {
  width: number;
  height: number;
}

export type IItemPositions = Map<string, XYCoord>;

/**
 * Represents one line of text.
 */
export interface ILineConfiguration {
  /**
   * Keys of segments belonging to a line.
   */
  items: string[];
  /**
   * Layout of a line used to draw line background.
   */
  layout: Types.LayoutInfo;
}

/**
 * Result of position calculation.
 */
export interface IPositionCalculations {
  /**
   * Absolute positions of text segments.
   */
  itemPositions: IItemPositions;
  /**
   * Represents arrangements of text segments in lines.
   * Used to determine which item is first in line/last in line, etc.
   * which is useful for adding additional drop targets and drop indicators
   * at the beginning and end of lines.
   */
  lineConfigurations: ILineConfiguration[];
}

enum ItemsAlignment {
  Left = 'left',
  Center = 'center'
}

const EMPTY_LAYOUT: Types.LayoutInfo = { height: 0, width: 0, x: 0, y: 0 };

/**
 * Calculates absolute positions of text segments based on their width, line height,
 * and maximum width of the container.
 * Basically, its like a flex-wrap with a few special features: we have to know
 * absolute positions of items because we animate position changes. Also, when positive
 * feedback is displayed all text segments that were in the same line fuze together
 * in one text segment per line. This is referred to as squeezed positions.
 */
export class PositioningHelper {
  private lineConfigurations: ILineConfiguration[] = [];

  private readonly itemPositions: IItemPositions = new Map<string, XYCoord>();

  constructor(
    private readonly lineHeight: number,
    private readonly containerWidth: number,
    private readonly itemDimensions: Map<string, IDimensions>
  ) {}

  /**
   * Calculates absolute positions of items, their arrangements in lines, and line layouts
   * for standard use-case: segments are centered, and spacing between them is zero.
   *
   * @param itemOrdering list of string keys that corresponds to ordering of the segments
   * that should be rendered
   */
  public calculatePositions(itemOrdering: string[]): IPositionCalculations {
    this.arrangeItemsInLines(itemOrdering);
    this.calculateLineLayouts(ItemsAlignment.Center);
    this.calculateItemPositions();

    return {
      itemPositions: this.itemPositions,
      lineConfigurations: this.lineConfigurations
    };
  }

  /**
   * Calculates absolute positions of items, their arrangements in lines, and line layouts
   * for squeezed use-case that is used when giving positive feedback. Spacing between items is
   * negative - all items are squeezed by the same amount in order to make them appear as single
   * text segment per line. Content of each line is left aligned.
   *
   * @param itemOrdering list of string keys that corresponds to ordering of the segments
   * that should be rendered
   *
   * @param squeezeBy negative spacing between text segment - amount by which they need to get
   * squeezed in order to appear as single text segment per line
   */
  public calculateSqueezedPositions(
    itemOrdering: string[],
    squeezeBy: number
  ): IPositionCalculations {
    this.arrangeItemsInLines(itemOrdering);
    this.calculateLineLayouts(ItemsAlignment.Left, squeezeBy);
    this.calculateItemPositions(squeezeBy);

    return {
      itemPositions: this.itemPositions,
      lineConfigurations: this.lineConfigurations
    };
  }

  private arrangeItemsInLines(itemOrdering: string[]) {
    this.lineConfigurations = [];

    itemOrdering.forEach((itemKey: string) => {
      const { width } = this.itemDimensions.get(itemKey)!;
      let currentLineConfiguration = last(this.lineConfigurations);

      if (
        !currentLineConfiguration ||
        currentLineConfiguration.layout.width + width > this.containerWidth
      ) {
        currentLineConfiguration = {
          items: [],
          layout: { ...EMPTY_LAYOUT }
        };
        this.lineConfigurations.push(currentLineConfiguration);
      }

      currentLineConfiguration.items.push(itemKey);
      currentLineConfiguration.layout.width += width;
    });
  }

  private calculateLineLayouts(alignment: ItemsAlignment, squeezeBy = 0) {
    const maxLine = maxBy(
      this.lineConfigurations,
      lineConfiguration => lineConfiguration.layout.width
    );

    const maxLineWidth =
      maxLine!.layout.width - (maxLine!.items.length - 1) * squeezeBy;

    this.lineConfigurations = this.lineConfigurations.map(
      (lineConfiguration, index) => {
        const width =
          lineConfiguration.layout.width -
          (lineConfiguration.items.length - 1) * squeezeBy;

        const layout = {
          height: this.lineHeight,
          width,
          x: this.calculateLineStartOffset(width, maxLineWidth, alignment),
          y: index * this.lineHeight
        };

        return {
          ...lineConfiguration,
          layout
        };
      }
    );
  }

  private calculateItemPositions(squeezeBy = 0) {
    this.lineConfigurations.forEach(
      (lineConfiguration: ILineConfiguration, lineIndex: number) => {
        const y = lineIndex * this.lineHeight;
        let lineWidthAccumulator = lineConfiguration.layout.x;

        lineConfiguration.items.forEach((itemKey: string) => {
          const { width } = this.itemDimensions.get(itemKey)!;

          const x = lineWidthAccumulator;
          lineWidthAccumulator += width - squeezeBy;

          this.itemPositions.set(itemKey, { x, y });
        });
      }
    );
  }

  private calculateLineStartOffset(
    lineWidth: number,
    maxLineWidth: number,
    alignment: ItemsAlignment
  ) {
    return alignment === ItemsAlignment.Center
      ? (this.containerWidth - lineWidth) / 2
      : (this.containerWidth - maxLineWidth) / 2;
  }
}
