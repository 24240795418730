import { ofType, StateObservable } from 'redux-observable';
import { ignoreElements, map, Observable } from 'rxjs';
import {
  SreConfigActionType,
  SreSelectMicSuccessAction,
  SreConfigureSuccessAction,
  SreSetMicBlockedAction,
  SreSelector
} from '../redux';
import { ISreDependencies } from './sre-epics.model';

export function openMicEpic(
  action$: Observable<
    | SreConfigureSuccessAction
    | SreSelectMicSuccessAction
    | SreSetMicBlockedAction
  >,
  state$: StateObservable<unknown>,
  deps: ISreDependencies
): Observable<void> {
  return action$.pipe(
    ofType(
      SreConfigActionType.ConfigureSuccess,
      SreConfigActionType.SelectMicSuccess,
      SreConfigActionType.SetMicBlocked
    ),
    map(a => {
      const micBlocked = SreSelector.getMicBlocked(state$.value);
      if (micBlocked) {
        return;
      }

      // When a new mic selection is made, the mic needs to be closed and
      // reopened in order for the new mic to be the source of input.
      const shouldReopenMic = a.type === SreConfigActionType.SelectMicSuccess;

      if (shouldReopenMic) {
        deps.sre.closeMicrophone();
      }

      void deps.sre.openMicrophone({
        autoGainControl: true,
        echoCancellation: false,
        noiseSuppression: false,
        postRecordCaptureMillis: 200,
        preRecordCaptureMillis: 200
      });
    }),
    ignoreElements()
  );
}
openMicEpic.displayName = 'openMicEpic';
