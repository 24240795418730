import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { Action, Dispatch } from 'redux';
import {
  IScreenplayActionPlayer,
  ScreenplayerType
} from '../screenplayer.model';

export class ReduxDispatchPlayer implements IScreenplayActionPlayer<Action> {
  public readonly type = ScreenplayerType.ReduxDispatcher;

  constructor(private readonly dispatch: Dispatch) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public cancel(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public pause(): void {}

  public async play(action: IScreenplayAction<Action>): Promise<void> {
    this.dispatch(action.data);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  public resume(): void {}
}
