import { useSelector } from 'react-redux';
import { Row } from 'common-ui';
import { AppState } from 'services';
import { Bar } from './bar/Bar';
import { UnitMeterSelector } from './redux/UnitMeter.selector';
import { UnitMeterStyles } from './UnitMeter.styles';

export function UnitMeter() {
  const markers = useSelector((state: AppState) =>
    UnitMeterSelector.getMarkers(state)
  );

  const styles = UnitMeterStyles.get();

  return (
    <Row style={styles.container} testId={UnitMeter.displayName}>
      {markers.map((marker, idx) => (
        <Bar
          key={marker.sysId}
          index={idx}
          markersCount={markers.length}
          progress={marker.status}
        />
      ))}
    </Row>
  );
}
UnitMeter.displayName = 'UnitMeter';
