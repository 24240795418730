import {
  InstructionalStep,
  IScreenplay,
  UnitType
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { StateObservable } from 'redux-observable';
import { Music } from 'audio/music';
import { CommonUiAction } from 'common-ui';
import {
  ActivityPositionAction,
  IUnitPosition,
  ProgramMode,
  RoundContext
} from 'curriculum-services';
import { PlacementFormHelper } from 'curriculum-services/placement/position-determiners/PlacementForm.helper';
import {
  EncounterScene,
  EncounterSceneAnimationName,
  EncounterSceneElementName
} from 'feature-areas/encounters';
import { PlacementUnitToUnitScreenplayBuilder } from 'feature-areas/transitions/builders/placement/PlacementUnitToUnitScreenplayBuilder';
import { UnitCompleteSceneAnimationName } from 'feature-areas/units/unit-complete-scene';
import { UnitCompleteSceneAction } from 'feature-areas/units/unit-complete-scene/redux';
import { UnitCompleteScene } from 'feature-areas/units/unit-complete-scene/UnitCompleteScene';
import { RouteBuilder, RouterService } from 'router-service';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { RoundAction, RoundIntroType } from '../../builders/rounds';
import { TransitionScreenplayBuilderBase } from '../../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../../transition.model';
import { IRoundToNextTransitionDeps } from '../round-transition.model';

export class RoundToUnitCompleteTransitionFactory extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'RoundToUnitCompleteTransitionFactory';

  public static create(
    state$: StateObservable<unknown>,
    deps: IRoundToNextTransitionDeps,
    context: RoundContext
  ): IScreenplay {
    return new RoundToUnitCompleteTransitionFactory(state$, deps, context)
      .screenplay;
  }

  private constructor(
    state$: StateObservable<unknown>,
    { curriculumDependencies, preparedScenes }: IRoundToNextTransitionDeps,
    context: RoundContext
  ) {
    super(TransitionScreenplayId.RoundToUnitComplete);

    const { imminentParentUnitPosition, parentUnit, programMode } = context;
    const { instructionalStep, type: unitType } = parentUnit;
    const { programContextService } = curriculumDependencies;

    this.brightenAnimationMaybe(unitType, instructionalStep)
      .playEncouragementMaybe(programMode, context, preparedScenes)
      .showRewardMaybe(unitType, preparedScenes, programMode)
      .changeActivityPosition()
      .awaitContentLoaded(programContextService, state$)
      .navToNextUnit(programMode, imminentParentUnitPosition)
      .prepareNextUnitCompleteScene()
      .dispatchRoundIntro();
  }

  private brightenAnimationMaybe(
    unitType: UnitType,
    instructionalStep: InstructionalStep
  ): RoundToUnitCompleteTransitionFactory {
    const isMediaUnit = unitType === UnitType.Media;
    const isInstruction = instructionalStep === InstructionalStep.Instruction;

    if (!isMediaUnit && !isInstruction) {
      return this;
    }

    this.builder.addStormAnimation({
      name: EncounterSceneAnimationName.Background.Brighten,
      targetElement: EncounterSceneElementName.Background,
      targetScene: SceneName.Encounter
    });

    return this;
  }

  private playEncouragementMaybe(
    programMode: ProgramMode,
    context: RoundContext,
    preparedScenes: PreparedScenes
  ): RoundToUnitCompleteTransitionFactory {
    if (programMode !== ProgramMode.Placement) {
      return this;
    }

    const {
      placementForm,
      parentUnit: { sysId: parentUnitSysId }
    } = context;
    const unitCompleteVo = PlacementFormHelper.createFor(
      placementForm,
      parentUnitSysId
    ).step.unitCompleteTransition;

    this.builder.addScreenplay(
      PlacementUnitToUnitScreenplayBuilder.create()
        .preEncouragementDelay()
        .centerCharacter()
        .attachCharacter(preparedScenes)
        .playEncouragementMusic()
        .playEncouragementVo(unitCompleteVo).screenplay
    );

    return this;
  }

  private showRewardMaybe(
    unitType: UnitType,
    preparedScenes: PreparedScenes,
    programMode: ProgramMode
  ): RoundToUnitCompleteTransitionFactory {
    const shouldAddReward = !(
      [UnitType.PresentationOfKnowledge, UnitType.Media].includes(unitType) ||
      programMode === ProgramMode.Placement
    );
    if (!shouldAddReward) {
      return this;
    }

    const { encounter: encounterScene, unitComplete: unitCompleteScene } =
      preparedScenes;
    if (!encounterScene) {
      throw new LexiaError(
        'Invalid operation: encounter scene is undefined',
        RoundToUnitCompleteTransitionFactory.displayName,
        RoundToUnitCompleteTransitionFactoryError.EncounterSceneMissing
      ).withContext({ preparedScenes });
    }
    if (!unitCompleteScene) {
      throw new LexiaError(
        'Invalid operation: unit complete scene is undefined',
        RoundToUnitCompleteTransitionFactory.displayName,
        RoundToUnitCompleteTransitionFactoryError.UnitCompleteSceneMissing
      ).withContext({ preparedScenes });
    }

    return this.navToUnitReward()
      .disableUtilityBar()
      .addUnitCompleteReward(encounterScene, unitCompleteScene)
      .enableUtilityBar();
  }

  private navToUnitReward(): RoundToUnitCompleteTransitionFactory {
    const uri = RouteBuilder.unitComplete();

    this.builder.addCallback(() => {
      RouterService.history.replace(uri);
    });

    return this;
  }

  private addUnitCompleteReward(
    encounterScene: EncounterScene,
    unitCompleteScene: UnitCompleteScene
  ): RoundToUnitCompleteTransitionFactory {
    const unitCompleteRewardAnimationRequest = {
      name: UnitCompleteSceneAnimationName.Root.Intro,
      targetScene: SceneName.UnitComplete
    };
    const unitCompleteRewardMusic = Music.buildUnitComplete(
      unitCompleteScene.RewardName
    );
    const unitRewardIdx = unitCompleteScene.RewardIdx;

    this.builder
      .addCallback(() => {
        encounterScene.character.detach();
        unitCompleteScene.show();
      })
      .addReduxAction(CommonUiAction.unitRewardShown({ idx: unitRewardIdx }))
      .addStormAnimation(unitCompleteRewardAnimationRequest, {
        concurrent: true
      })
      .addMusic({ path: unitCompleteRewardMusic })
      .addCallback(() => {
        unitCompleteScene.hide();
      });

    return this;
  }

  private changeActivityPosition(): RoundToUnitCompleteTransitionFactory {
    this.builder.addReduxAction(ActivityPositionAction.change());

    return this;
  }

  private navToNextUnit(
    programMode: ProgramMode,
    nextPosition: IUnitPosition
  ): RoundToUnitCompleteTransitionFactory {
    const { roundId, unitId } = nextPosition;
    const uri = RouteBuilder.modeSpecificRound(programMode, unitId, roundId);

    this.builder.addCallback(() => {
      RouterService.history.replace(uri);
    });

    return this;
  }

  private prepareNextUnitCompleteScene(): RoundToUnitCompleteTransitionFactory {
    this.builder.addReduxAction(UnitCompleteSceneAction.prepare());

    return this;
  }

  // Adding this as last action, rather than nextAction, as it would be a weird state for
  // the screenplay to be skipped, with the given actions that set up the next round
  // and yet still play this action at that point
  // eg, https://jira.lexialearning.com/browse/LOBO-13946
  private dispatchRoundIntro(): RoundToUnitCompleteTransitionFactory {
    this.builder.addReduxAction(
      RoundAction.intro({ type: RoundIntroType.UnitIntro })
    );

    return this;
  }
}

export enum RoundToUnitCompleteTransitionFactoryError {
  EncounterSceneMissing = 'EncounterSceneMissing',
  UnitCompleteSceneMissing = 'UnitCompleteSceneMissing'
}
