import { TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { IPodcast } from '@lexialearning/lobo-common/tasks/podcast';
import { createNeutralCorrectFeedbackScreenplay } from 'task-components/shared';
import {
  ITaskRegistration,
  ModelSection,
  ScreenplayType,
  TaskRegistrationBuilder
} from '../core';
import { Podcast } from './Podcast';

export function createPodcastRegistration(): ITaskRegistration<TaskTypeName.Podcast> {
  return TaskRegistrationBuilder.create<TaskTypeName.Podcast, IPodcast>(
    TaskTypeName.Podcast,
    Podcast
  )
    .withScreenplayBuilder(
      ScreenplayType.Feedback,
      createNeutralCorrectFeedbackScreenplay
    )
    .setModeledFields(ModelSection.Presentation, ['voiceover']).registration;
}
