import { Version } from 'services/app-config';
import { ILogRocketConfig, LoboLogItemCategory } from 'logging';
import { ILogger, LoggingLevel } from '@lexialearning/main-model';
import { addMiddleware } from 'redux-dynamic-middlewares';
import { CargoService } from '@lexialearning/utils-react';

export class LogRocketInitializer {
  public static readonly displayName = 'LogRocketInitializer';

  /**
   * Initialize LogRocket when it is enabled. This is currently done a part
   * of initializing loggers in AppLoggerDependencies.
   * It will change once Uni implements LogRocket integration
   */
  public static async init(
    config: ILogRocketConfig,
    logger: CargoService,
    { enabledForDebug } = { enabledForDebug: false }
  ): Promise<void> {
    const shouldEnableLogRocket = config.enabled || enabledForDebug;
    if (!shouldEnableLogRocket) return;

    const { LogRocket, LogRocketSanitizer } =
      await this.dynamicImportLogRocket();
    addMiddleware(
      LogRocket.reduxMiddleware({
        actionSanitizer: LogRocketSanitizer.maybeSanitizeAction,
        stateSanitizer: LogRocketSanitizer.sanitizeState
      })
    );

    const logRocketAppId = config.appId;
    // istanbul ignore next -  hard to test requestSanitizer function and straight forward
    LogRocket.init(logRocketAppId, {
      network: {
        requestSanitizer: request => LogRocketSanitizer.sanitizeRequest(request)
      },
      release: Version.All
    });

    this.identifyToLogRocket(logger);

    const logRocketSessionUrlPromise = new Promise<string>(resolve =>
      LogRocket.getSessionURL(resolve)
    );
    const logRocketSessionUrl = await logRocketSessionUrlPromise;

    logger.errorItemFactory.logRocketSessionUrl = logRocketSessionUrl;

    void logger.log({
      category: LoboLogItemCategory.LogRocketSessionUrl,
      // loggingLevel set to 'Error' to ensure this gets logged
      loggingLevel: LoggingLevel.Error,

      payload: { logRocketSessionUrl },
      summary: logRocketSessionUrl
    });
  }

  /**
   * Initializes LogRocket if not already done, and adds identifying info to session
   * This is done form identifyLogsEpic, but it will no longer be necessary
   * once we implement the Uni LogRocket service.
   */
  public static async identify(
    config: ILogRocketConfig,
    logger: CargoService
  ): Promise<void> {
    const { logRocketSessionUrl } = logger.errorItemFactory;

    if (!logRocketSessionUrl) {
      return this.init(config, logger);
    }

    this.identifyToLogRocket(logger, 'LogRocket already initialized. ');
  }

  private static async identifyToLogRocket(
    logger: ILogger,
    logMessagePrefix = ''
  ): Promise<void> {
    const { LogRocket } = await this.dynamicImportLogRocket();
    const payload = {
      appInstanceId: logger.appInfo.instanceId,
      name: logger.userInfo.personId,
      userRole: logger.userInfo.userRole
    };

    void logger.log({
      category: LoboLogItemCategory.LogRocketIdentify,
      // loggingLevel set to 'Error' to ensure this gets logged
      loggingLevel: LoggingLevel.Error,
      payload,
      summary: `${logMessagePrefix}Adding identifying info to session.`
    });

    LogRocket.identify(payload.name, payload);
  }

  // istanbul ignore next - dynamic import doesn't play well with jest
  private static async dynamicImportLogRocket() {
    const { LogRocket } = await import('./LogRocket');
    const { LogRocketSanitizer } = await import('./LogRocketSanitizer');

    return { LogRocket, LogRocketSanitizer };
  }
}
