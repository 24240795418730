import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { IProfileState, ProfileActionType } from './profile-redux.model';
import { ProfileActions } from './Profile.action';

const profileInitialState: IProfileState = {
  profile: undefined
};

export function profileReducer(
  state = profileInitialState,
  action: UserGlobalActionLogoutSuccess | ProfileActions
): IProfileState {
  switch (action.type) {
    case ProfileActionType.LoadSuccess:
      return { ...state, profile: action.payload };

    case ProfileActionType.UpdateAvatar:
      return {
        ...state,
        profile: {
          ...state.profile!,
          avatar: {
            ...state.profile!.avatar!,
            ...action.payload
          }
        }
      };

    case UserGlobalActionType.LogoutSuccess:
      return profileInitialState;

    default:
      return state;
  }
}
profileReducer.displayName = 'profileReducer';

export const ProfileReducerPrivates = {
  initialState: profileInitialState
};
