import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { LogoutReason } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  IntroductionSceneAction,
  IntroductionScenePrepareAction
} from 'feature-areas/introduction/introduction-scene/redux';
import { StormService } from 'storm';
import { PreparedScenes } from '../PreparedScenes';

export interface IStormHandleLogoutDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function stormLoboHandleLogoutEpic(
  action$: Observable<UserGlobalActionLogoutSuccess>,
  _state$: StateObservable<unknown>,
  deps: IStormHandleLogoutDeps
): Observable<IntroductionScenePrepareAction> {
  return action$.pipe(
    ofType(UserGlobalActionType.LogoutSuccess),
    filter(() => deps.stormService.isInitialized),
    tap(() => {
      deps.stormService.unloadAllScenes();
      deps.preparedScenes = new PreparedScenes();
    }),
    filter(a => a.payload !== LogoutReason.Error),
    map(() => IntroductionSceneAction.prepare())
  );
}
stormLoboHandleLogoutEpic.displayName = 'stormLoboHandleLogoutEpic';
