import {
  AppShellAction,
  AppShellActionHideSkipButton,
  AppShellActionNotifyNavigationHistoryUpdate,
  AppShellActionShowSkipButton,
  AppShellActionType,
  AppShellSelector
} from '..';
import { ofType, StateObservable } from 'redux-observable';
import { RouterService, RoutePath } from 'router-service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PredicateUtils } from '@lexialearning/utils';

/**
 * Show/hide skip button on route change
 * @param action$
 * @param state$
 * @returns AppShellAction.showSkipButton() or AppShellAction.hideSkipButton()
 */
export function updateSkipButtonStateEpic(
  action$: Observable<AppShellActionNotifyNavigationHistoryUpdate>,
  state$: StateObservable<unknown>
): Observable<AppShellActionShowSkipButton | AppShellActionHideSkipButton> {
  return action$.pipe(
    ofType(AppShellActionType.NotifyNavigationHistoryUpdate),
    map(() => {
      const isOnIntroductionPage = RouterService.isOnRoute(
        RoutePath.Introduction
      );
      const isOnLevelIntroductionPage = RouterService.isOnRoute(
        RoutePath.LevelIntroduction
      );

      if (isOnIntroductionPage || isOnLevelIntroductionPage) {
        return AppShellAction.showSkipButton();
      }

      if (AppShellSelector.getShouldShowSkipButton(state$.value)) {
        return AppShellAction.hideSkipButton();
      }

      return;
    }),
    filter(PredicateUtils.isDefined)
  );
}
updateSkipButtonStateEpic.displayName = 'updateSkipButtonStateEpic';
