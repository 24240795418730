import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, get } from 'lodash';

export type GetState = <T extends object = object>() => T;

export class ConfigProvider implements IConfigProvider {
  public static readonly displayName = 'ConfigProvider';

  private getState!: GetState;

  public init(getState: GetState): void {
    this.getState = getState;
  }

  public getConfig<T>(key: string): T {
    try {
      const state = this.getStateSafely();

      return cloneDeep(get(state, key));
    } catch (err) {
      throw new LexiaError(
        `Error getting config for key ${key}`,
        ConfigProvider.displayName,
        ConfigProviderErrorCode.GetStateFailure
      )
        .withCause(err)
        .withContext({ key });
    }
  }

  private getStateSafely<T extends object = object>(): T {
    if (!this.getState) {
      throw new LexiaError(
        'Invalid attempt to getConfig before initializing ConfigProvider with a getState function',
        ConfigProvider.displayName,
        ConfigProviderErrorCode.NoGetState
      );
    }

    return this.getState();
  }
}

export enum ConfigProviderErrorCode {
  NoGetState = 'NoGetState',
  GetStateFailure = 'GetStateFailure'
}
