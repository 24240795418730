// istanbul ignore file - challenge to cover SystemInfo and straight forward
import { Position } from '@lexialearning/common-ui';
import { Color, Font } from 'common-styles';

export class LoginFormSharedStyles {
  public static readonly FormWidth = 440;

  public static readonly ButtonBorderWidth = 2;

  private static readonly styles = {
    buttonOverride: {
      button: {
        borderColor: Color.NearWhite,
        borderWidth: LoginFormSharedStyles.ButtonBorderWidth,
        height: 56,
        marginHorizontal: 5,
        minWidth: LoginFormSharedStyles.FormWidth
      }
    },
    confirmationText: {
      color: Color.Gray70,
      marginBottom: 30
    },
    formDisabled: {
      opacity: 0.6
    },
    headerText: {
      fontSize: Font.Size.large,
      paddingBottom: 30
    },
    settingsButtonContainer: {
      bottom: 0,
      padding: 15,
      position: Position.Absolute,
      right: 0
    },
    textInput: {
      input: {
        borderColor: Color.Gray70,
        borderWidth: 1
      }
    }
  };

  public static get() {
    return LoginFormSharedStyles.styles;
  }
}
