import { IActivityPosition } from '@lexialearning/lobo-common';
import { ProgramMode } from 'curriculum-services/program-context/program-context.model';
import { LexiaProduct } from 'lexia-service/lexia-service-private.model';
import {
  IUnitAdvanceLexia,
  LexiaPlacementId,
  StudentProgressPayloadType,
  UnitAdvanceReason
} from '../student-progress-api/student-progress-api-private.model';

export class UnitAdvanceFactory {
  public static create(
    { activityId, encounterId, unitPosition }: IActivityPosition,
    levelId: string,
    programMode: ProgramMode
  ): IUnitAdvanceLexia {
    let lexiaLevelId = levelId;
    let lexiaActivityId = activityId;
    let lexiaEncounterId = encounterId;

    if ([ProgramMode.Placement, ProgramMode.Onboarding].includes(programMode)) {
      lexiaLevelId = LexiaPlacementId.Level;
      lexiaActivityId = LexiaPlacementId.Activity;
      lexiaEncounterId = LexiaPlacementId.Encounter;
    }

    return {
      __type__: StudentProgressPayloadType.UnitAdvance,
      position: {
        activityId: lexiaActivityId,
        activityIndex: 0,
        conceptId: lexiaLevelId,
        conceptIndex: 0,
        unitGroupId: lexiaEncounterId,
        unitGroupIndex: 0,
        unitId: unitPosition.unitId,
        unitIndex: 0
      },
      reason: UnitAdvanceReason.Progress,
      strandKey: LexiaProduct.Lobo
    };
  }
}
