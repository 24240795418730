import { Align, Color } from 'common-styles';
import { DevToolsStyles } from '../DevTools.styles';

export class AdjustLevelProgressModalStyles {
  private static readonly styles = {
    ...DevToolsStyles.styles,
    checkboxContainer: {
      marginBottom: 10
    },
    container: {
      alignItems: Align.Start,
      padding: 20
    },
    roundText: {
      color: Color.Blue,
      ...DevToolsStyles.styles.contentItemValue
    },
    title: {
      marginBottom: 10
    }
  };

  public static get() {
    return this.styles;
  }
}
