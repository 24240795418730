import { ImportantForAccessibility } from '@lexialearning/common-ui/lib/types/enums';
import { Types } from '../../../types';
import { Text } from '../../text';
import { View } from '../../view';
import { TextPromptSegmentStyles } from './TextPromptSegment.styles';

export interface ITextPromptSegmentProps {
  textToken: string;
  style: Types.TextStyle;
  shouldFillInText: boolean;
  correctChoice: string;
}

export const BLANK_LINE = '__';

export function TextPromptSegment({
  correctChoice,
  shouldFillInText,
  style,
  textToken
}: ITextPromptSegmentProps) {
  const styles = TextPromptSegmentStyles.build(shouldFillInText);

  if (textToken === BLANK_LINE) {
    if (shouldFillInText) {
      return (
        <View
          accessibilityLabel="Blank line"
          style={styles.line}
          testId={`${TextPromptSegment.displayName}_filledInBlank`}
        >
          <Text style={styles.lineText}>{correctChoice}</Text>
        </View>
      );
    } else {
      return (
        <View
          importantForAccessibility={ImportantForAccessibility.Yes}
          style={styles.line}
          testId={`${TextPromptSegment.displayName}_emptyBlank`}
        >
          <Text
            importantForAccessibility={
              ImportantForAccessibility.NoHideDescendants
            }
            style={styles.lineText}
          >
            {correctChoice}
          </Text>
        </View>
      );
    }
  }

  return (
    <View
      style={styles.noLine}
      testId={`${TextPromptSegment.displayName}_text`}
    >
      <Text style={style}>{textToken}</Text>
    </View>
  );
}
TextPromptSegment.displayName = 'TextPromptSegment';
