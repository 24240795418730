import { ITextWithVoiceover } from '@lexialearning/lobo-common/main-model';
import { connect } from 'react-redux';
import { Text } from 'common-ui';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { CalibrationStep, CalibrationUiSelector } from '../redux';
import { CalibrationInteractiveStyles } from './CalibrationInteractive.styles';
import { CalibrationButtonMeter } from './calibration-button-meter/CalibrationButtonMeter';

export interface ICalibrationInteractiveReduxProps {
  step: CalibrationStep;
  calibrationSentence?: ITextWithVoiceover;
}

export function CalibrationInteractiveComponent(
  props: ICalibrationInteractiveReduxProps
) {
  const { calibrationSentence, step } = props;
  const styles = CalibrationInteractiveStyles.get();
  const isTryAgain = step === CalibrationStep.TryAgain;

  return (
    <>
      {isTryAgain ? (
        <Text style={styles.sentenceText} testId="TryAgainText">
          Try again!
        </Text>
      ) : (
        <Text
          style={styles.sentenceText}
          testId={CalibrationInteractiveComponent.displayName}
        >
          "{calibrationSentence?.text}"
        </Text>
      )}
      <CalibrationButtonMeter />
    </>
  );
}
CalibrationInteractiveComponent.displayName = 'CalibrationInteractive';

// istanbul ignore next - trivial code, not worth testing
function mapStateToProps(state: unknown) {
  return {
    calibrationSentence: BootstrapContentSelector.getCalibrationSentence(state),
    step: CalibrationUiSelector.getStep(state)
  };
}

export const CalibrationInteractive = connect(mapStateToProps)(
  CalibrationInteractiveComponent
);
