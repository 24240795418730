import { RouteService } from '@lexialearning/utils-react';
import { matchRoutes } from 'react-router-dom';
import { QueryStringParam, RoutePath } from './routing.model';
import { DeployEnvironmentUrls } from 'services/app-config/deploy.model';
import { IGetQueryParamOptions } from '@lexialearning/utils-react/lib/routing/RouteService';

export class RouterService {
  public static readonly displayName = 'RouterService';

  public static history = RouteService.history;

  public static get activeRoute(): string {
    return this.history.location.pathname;
  }

  /**
   * Get the value of the named (case-insensitive) query string parameter from
   * the active (or provided) URL (or route). Return undefined if the parameter
   * is absent; empty string if the param is present with no value.
   * @param paramName — query string parameter name
   * @param options — options
   */
  public static getQueryParam(
    paramName: string,
    options?: IGetQueryParamOptions
  ): string | undefined {
    const completeUrl = this.getCompleteUrl(options?.url);

    const paramValue = RouteService.getQueryParam(paramName, {
      ...options,
      url: completeUrl
    });

    return paramValue;
  }

  /**
   * Return the query string from the active or given location as a Map of keys to values.
   * @deprecated use sparingly, prefer using getQueryParam which is case-insensitive
   * @param url - optional url to use instead of active location
   * @returns
   */
  public static getQueryParamMap(url?: string): Map<string, string> {
    const completeUrl = this.getCompleteUrl(url);

    return RouteService.getQueryParamMap(completeUrl);
  }

  public static isSsoUrl(url?: string): boolean {
    return [
      QueryStringParam.LexiaAuthToken,
      QueryStringParam.Role,
      QueryStringParam.ApiUrl
    ].every(param => !!this.getQueryParam(param, { url }));
  }

  public static isOnRoute(props: string | string[]): boolean {
    const paths = typeof props === 'string' ? [props] : props;

    return !!matchRoutes(
      paths.map(path => ({ path })),
      this.activeRoute
    );
  }

  /** Returns true for root route, device setup, generic login routes and SSO login interaction route */
  public static isOnLoginRoute() {
    return this.isOnRoute([
      RoutePath.DeviceSetup,
      RoutePath.Login,
      RoutePath.LoginSsoInteraction,
      RoutePath.LoginTab,
      RoutePath.Root
    ]);
  }

  /**
   * Returns true for root route, device setup and generic login routes
   * Returns false for LoginSsoInteraction route and all other routes
   */
  public static isOnNonSsoLoginRoute() {
    return this.isOnRoute([
      RoutePath.DeviceSetup,
      RoutePath.Login,
      RoutePath.LoginTab,
      RoutePath.Root
    ]);
  }

  /** Returns true for regular round routes, as well as Onboarding and Placement round routes */
  public static isOnRoundRoute(): boolean {
    return this.isOnRoute([
      RoutePath.OnboardingRounds,
      RoutePath.PlacementRounds,
      RoutePath.Rounds
    ]);
  }

  /**
   * This is used in the Clever iOS flow to check if a given url is a Lexia English URL
   * It only checks against Develop, Main, Staging and Production.
   * It does NOT check for debug, latest, or feature branches (because those aren't expected to work with Clever).
   * If this function is used in other ways, we may need to expand what it's checking against.
   * @param url
   * @returns
   */
  public static isLexiaEnglishUrl = url =>
    url
      ? [
          DeployEnvironmentUrls.Develop,
          DeployEnvironmentUrls.Main,
          DeployEnvironmentUrls.Production,
          DeployEnvironmentUrls.Staging
        ].some(envUrl => url.startsWith(envUrl))
      : false;

  /** Returns a URL prepended with https:// and a lexialearning.com if no domain is provided */
  private static getCompleteUrl(url?: string): string {
    const providedUrl = url ?? this.history.location.search;
    if (providedUrl.startsWith('?')) {
      return `https://lexialearning.com/${providedUrl}`;
    } else {
      return providedUrl.startsWith('http')
        ? providedUrl
        : `https://${providedUrl}`;
    }
  }
}
