import { connect } from 'react-redux';
import { ThemeSelector } from './Theme.selector';

function mapStateToProps(state: unknown) {
  return {
    themeSize: ThemeSelector.getThemeSize(state),
    themeType: ThemeSelector.getThemeType(state)
  };
}

/**
 * @deprecated use useTheme, this should be removed once all uses of it are migrated to hooks
 */
export function withTheme(component: any) {
  return connect(mapStateToProps)(component) as typeof component;
}

export const WithThemePrivates = {
  mapStateToProps
};
