import { TaskEvaluationResult } from '@lexialearning/lobo-common/main-model';
import * as React from 'react';
import { compose } from 'redux';
import { Sfx } from 'audio/sfx';
import { withGlobalDisabled } from 'common-ui';
import { DevShortcuts, withDevShortcuts } from './dev-shortcuts';

export function withKeyDownEventHoc(WrappedComponent: any) {
  return class extends React.PureComponent<Readonly<any>> {
    public static readonly displayName = 'KeydownEventHOC';

    constructor(props: any) {
      super(props);

      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    private get shortcuts(): DevShortcuts {
      return this.props.devShortcuts;
    }

    public componentDidMount() {
      if (window?.addEventListener) {
        window.addEventListener('keydown', this.handleKeyDown);
      }
    }

    public componentWillUnmount() {
      if (window?.removeEventListener) {
        window.removeEventListener('keydown', this.handleKeyDown);
      }
    }

    private handleKeyDown(evt: KeyboardEvent) {
      // Block keyboard events when the required keys are not pressed
      if (!evt.ctrlKey && !evt.altKey) {
        return;
      }

      // Only these events are allowed when global disabled is true
      switch (evt.code) {
        case 'Shift':
        case 'ShiftLeft':
        case 'ShiftRight':
          this.shortcuts.toggleDevTools(evt);
          break;
        case 'KeyS':
          this.shortcuts.toggleSpinner();
          break;
        case 'KeyK':
          this.shortcuts.goToSolution();
          break;
        default:
      }

      if (!this.props.disabled) {
        switch (evt.code) {
          case 'ArrowRight':
            this.shortcuts.simulateAnswer(TaskEvaluationResult.Correct);
            break;

          case 'ArrowDown':
            this.shortcuts.simulateAnswer(TaskEvaluationResult.Incorrect);
            break;

          case 'Backspace':
            this.shortcuts.navigateBack();
            break;

          case 'Digit1':
            this.shortcuts.playSfx(Sfx.Correct);
            break;

          case 'Digit2':
            this.shortcuts.playSfx(Sfx.Incorrect);
            break;

          case 'Digit3':
            this.shortcuts.playSfx(Sfx.DragBegin);
            break;

          case 'Digit4':
            this.shortcuts.playSfx(Sfx.DragEnd);
            break;

          case 'Digit5':
            this.shortcuts.playSfx(Sfx.DropZone);
            break;

          case 'Digit6':
            this.shortcuts.playSfx(Sfx.CantDo);
            break;

          case 'Digit7':
            this.shortcuts.playSfx(Sfx.Neutral);
            break;

          case 'Digit8':
            this.shortcuts.playSfx(Sfx.CallOut);
            break;

          case 'KeyA':
            this.shortcuts.playSfx(Sfx.MicListening);
            break;

          case 'KeyB':
            this.shortcuts.playSfx(Sfx.MicAvailable);
            break;

          case 'KeyC':
            this.shortcuts.playSfx(Sfx.Error);
            break;

          case 'KeyD':
            this.shortcuts.playSfx(Sfx.PopupOff);
            break;

          case 'KeyE':
            this.shortcuts.playSfx(Sfx.PopupOn);
            break;

          case 'KeyF':
            this.shortcuts.playSfx(Sfx.Unheard);
            break;

          case 'KeyG':
            this.shortcuts.playSfx(Sfx.InstructionOff);
            break;

          case 'KeyH':
            this.shortcuts.playSfx(Sfx.InstructionOn);
            break;

          case 'KeyI':
            this.shortcuts.logSreSessionInfo();
            break;

          case 'KeyL':
            this.shortcuts.playLevelCertificatesBadgeAnimation();
            break;

          case 'Escape':
            this.shortcuts.skip(evt.ctrlKey);
            break;

          default:
            break;
        }
      }
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export function withKeyDownEvent(component: any) {
  return compose(
    withGlobalDisabled,
    withDevShortcuts,
    withKeyDownEventHoc
  )(component) as typeof component;
}
