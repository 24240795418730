import { FlexWrap } from '@lexialearning/common-ui';
import { Align, Color, Justify, TextAlign } from 'common-styles';
import { IModalStyleOverride } from 'common-ui/components/modal/Modal.animated-styles';
import { UserInfoButtonStyles } from 'feature-areas/shell/utility-bar/user-info-button/UserInfoButton.styles';

export class StudentInfoModalStyles {
  public static readonly styles = {
    content: {
      height: 610,
      justifyContent: Justify.SpaceAround,
      paddingBottom: 10,
      paddingHorizontal: 15,
      paddingTop: 20,
      width: 350
    },
    footerInfo: {
      alignItems: Align.Start,
      flex: 1,
      justifyContent: Justify.Center,
      minHeight: 38,
      paddingLeft: 5
    },
    footerRow: {
      alignItems: Align.Start,
      alignSelf: Align.Stretch,
      justifyContent: Justify.SpaceBetween,
      marginTop: 15
    },
    footerText: {
      color: Color.Gray50,
      flexWrap: FlexWrap.Wrap,
      fontSize: 11,
      marginRight: 1,
      maxWidth: 210,
      textAlign: TextAlign.Left
    },
    logoutButtonOverrides: {
      button: {
        marginVertical: 10
      }
    },
    settingsContainer: {
      marginRight: 5
    }
  };

  public static get() {
    return this.styles;
  }

  public static getModalOverride(): IModalStyleOverride {
    return {
      modal: {
        right: UserInfoButtonStyles.positionRight,
        top: 58
      }
    };
  }
}
