export interface IProdDebugFlag {
  debug: boolean;
  /**
   * ISO date after which the flag should be ignored/cleared
   */
  expires: string;
}

export enum ProdDebugLocalStorageKey {
  // NOTE: If this ever changes, it must also be updated in debug.html
  DebugKey = 'debug'
}
