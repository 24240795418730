import { HairColor, HairStyle } from 'services/profile/avatar.model';
import { EnumUtils } from 'utils/EnumUtil';

export interface IHairSvgProps {
  colorName: HairColor | HijabColor;
}

export interface IHairColorHex {
  hair: string;
  hairLines: string;
}

export const HairColorHexMap: Map<HairColor, IHairColorHex> = new Map<
  HairColor,
  IHairColorHex
>([
  [HairColor.Blond, { hair: '#E4AA73', hairLines: '#AC682B' }],
  [HairColor.LightBrown, { hair: '#A55E32', hairLines: '#7E3400' }],
  [HairColor.DarkBrown, { hair: '#451D11', hairLines: '#2B0A05' }],
  [HairColor.Black, { hair: '#1D0B06', hairLines: '#050201' }]
]);

export const defaultHairColor: IHairColorHex = HairColorHexMap.get(
  HairColor.Blond
)!;

// ORDER SHOULD NOT CHANGE, THIS AFFECTS DISPLAY ORDER AND IMAGE FILE NAMES
export enum HijabColor {
  Pink = 'pink',
  Yellow = 'yellow',
  Green = 'green',
  Purple = 'purple'
}

export const HijabColorHexMap: Map<HijabColor, IHairColorHex> = new Map<
  HijabColor,
  IHairColorHex
>([
  [HijabColor.Pink, { hair: '#EF8076', hairLines: '#97333F' }],
  [HijabColor.Yellow, { hair: '#F7A43C', hairLines: '#AB5409' }],
  [HijabColor.Green, { hair: '#559984', hairLines: '#094C38' }],
  [HijabColor.Purple, { hair: '#463158', hairLines: '#30223D' }]
]);

export const defaultHijabColor: IHairColorHex = HijabColorHexMap.get(
  HijabColor.Pink
)!;

/** Maps hair color to hijab color, and vice versa, based on index of the value in the given enum */
export function mapToHairHijabColor<T>(color: HairColor | HijabColor): T {
  const hairColorIndex = EnumUtils.getIndexValue(HairColor, color as HairColor);
  if (hairColorIndex > -1) {
    return EnumUtils.getValueAtIndex<T>(HijabColor, hairColorIndex);
  }

  const hijabColorIndex = EnumUtils.getIndexValue(
    HijabColor,
    color as HijabColor
  );

  return EnumUtils.getValueAtIndex<T>(HairColor, hijabColorIndex);
}

export const HairStyleLabelMap: Map<HairStyle, string> = new Map<
  HairStyle,
  string
>([
  [HairStyle.LongStraight, 'long straight hair'],
  [HairStyle.LongWavy, 'long wavy hair'],
  [HairStyle.LongCurly, 'long curly hair'],
  [HairStyle.LongSmallBraids, 'long small braids hair'],
  [HairStyle.ShortStraight, 'short straight hair'],
  [HairStyle.ShortWavy, 'short wavy hair'],
  [HairStyle.ShortCurly, 'short curly hair'],
  [HairStyle.CurlyAboveEars, 'curly above the ears hair'],
  [HairStyle.Buzz, 'very short hair'],
  [HairStyle.Hijab, 'hijab']
]);
