import * as React from 'react';
import { connect } from 'react-redux';
import { AppConfigAction, IAppConfigOverridesPayload } from 'app-config';
import { ButtonBase, Checkbox, Column, Row, Text, TextButton } from 'common-ui';
import {
  UtilityButtonColorSetName,
  UtilityButtonSvg,
  UtilityButtonIconType
} from 'common-ui/components/button/utility-button';
import { ILoboAppConfig } from 'services/app-config';
import { ModalId } from 'shared-components/modals/modal.model';
import { AppShellAction } from 'shell/redux';
import { ThemeAction, ThemeSelector, ThemeSize } from 'theme';
import { ReloadAppButton } from './reload-app/ReloadAppButton';
import { AdjustLevelProgressModal } from './adjust-level-progress';
import { ConfigAdminModal } from './config-admin/ConfigAdminModal';
import { DevToolsStyles } from './DevTools.styles';
import { DevToolsDropdown } from './DevToolsDropdown';
import { DevToolsAction } from './redux/DevTools.action';
import { DevToolsSelector } from './redux/DevTools.selector';
import { DevToolsPosition } from './redux/IDevToolsState';
import { RoundDevTools, UnitDevTools } from './sections';
import { ShortcutsDevTools } from './sections/ShortcutsDevTools';
import { withKeyDownEvent } from './withKeyDownEvent.hoc';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { AppState } from 'services';
import { LexiaError } from '@lexialearning/utils';
import { StudentPropertyAction } from '@lexialearning/student-api';
import { StudentProperty } from 'student-api';
import { ProfileAction, ProfileSelector } from 'services/profile';
import { AvatarEditorAction } from 'feature-areas/avatar-editor/redux';
import { IAvatar } from 'services/profile/avatar.model';

export interface IDevToolsProps {
  appConfig: ILoboAppConfig;
  avatar: IAvatar;
  isVisible?: boolean;
  position?: DevToolsPosition;
  themeSize: ThemeSize;
  appHeight: number;
  applyConfigOverrides(overrides: IAppConfigOverridesPayload): void;
  changePosition(position: DevToolsPosition): void;
  hide(): void;
  show(): void;
  resetAvatar(): void;
  resetAvatarStudentProperties(): void;
  setAvatarEditable(): void;
  setAvatarEditableStudentProperties(avatar: IAvatar): void;
  updateTheme(sz: ThemeSize): void;
  showModal(modalId: ModalId): void;
}

interface IDevToolsState {
  shouldSendProgressToApi: boolean;
}

export class DevToolsComponent extends React.PureComponent<
  IDevToolsProps,
  IDevToolsState
> {
  public static readonly displayName = 'DevTools';

  constructor(props: IDevToolsProps) {
    super(props);

    this.close = this.close.bind(this);
    this.handleAdjustLevelProgressPress =
      this.handleAdjustLevelProgressPress.bind(this);
    this.handleConfigAdminPress = this.handleConfigAdminPress.bind(this);
    this.handleResetAvatarPress = this.handleResetAvatarPress.bind(this);
    this.handleSetAvatarEditablePress =
      this.handleSetAvatarEditablePress.bind(this);
    this.handleToggleDisableWrites = this.handleToggleDisableWrites.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.updateThemeSize = this.updateThemeSize.bind(this);
  }

  private close() {
    this.props.hide();
  }

  private throwLexiaError() {
    throw new LexiaError('Lexia Error', '', '');
  }

  private setPosition(position: DevToolsPosition) {
    this.props.changePosition(position);
  }

  private updateThemeSize(value: any) {
    this.props.updateTheme(value);
  }

  private handleAdjustLevelProgressPress() {
    this.props.showModal(AdjustLevelProgressModal.ModalId);
  }

  private handleConfigAdminPress() {
    this.props.showModal(ConfigAdminModal.ModalId);
  }

  private handleResetAvatarPress() {
    this.props.resetAvatar();
    this.props.resetAvatarStudentProperties();
  }

  private handleSetAvatarEditablePress() {
    this.props.setAvatarEditable();
    this.props.setAvatarEditableStudentProperties(this.props.avatar);
  }

  private handleToggleDisableWrites() {
    const { disableWrites } = this.props.appConfig.studentApi;
    this.props.applyConfigOverrides({
      overrides: [{ key: 'studentApi.disableWrites', value: !disableWrites }]
    });
  }

  public render() {
    const { position, appHeight } = this.props;
    const styles = DevToolsStyles.build(position, appHeight);

    return (
      this.props.isVisible && (
        <Column style={{ ...styles.container }}>
          {/* HEADER ROW */}
          <Row style={styles.header}>
            <Text style={styles.headerText}>Dev Tools</Text>
            <Row>
              {Object.keys(DevToolsPosition).map((p: string, idx: number) => (
                <ButtonBase
                  key={idx}
                  style={styles.positionButton}
                  ignoreGlobalDisabled
                  // eslint-disable-next-line react/jsx-no-bind,no-void
                  onPress={() => this.setPosition(DevToolsPosition[p])}
                >
                  <Column style={styles.positionIcons[p]} />
                </ButtonBase>
              ))}
            </Row>
            <ButtonBase
              style={styles.closeIcon}
              ignoreGlobalDisabled
              onPress={this.close}
            >
              <CloseIconSvg />
            </ButtonBase>
          </Row>

          {/* OUTPUT AREA */}
          <Row style={styles.content}>
            {/* ADJUST SETTINGS COLUMN */}
            <Column style={{ ...styles.contentBlock, ...styles.settingsBlock }}>
              <Text
                style={{
                  ...styles.contentBlockLabel,
                  ...styles.settingsBlockLabel
                }}
              >
                Adjust Settings:
              </Text>

              <DevToolsDropdown
                accessibilityLabel="Theme Size"
                id="themeSizes"
                label="Theme Size"
                items={ThemeSize}
                selectedItemId={this.props.themeSize}
                onChange={this.updateThemeSize}
              />

              {/* SEND PROGRESS CHECKBOX */}
              <Row style={styles.checkboxRow}>
                <Checkbox
                  isChecked={!this.props.appConfig.studentApi.disableWrites}
                  label="Send updates to Student API"
                  labelId="send-student-api-updates"
                  onToggle={this.handleToggleDisableWrites}
                  styleOverride={styles.checkboxOverrides}
                />
              </Row>

              <TextButton
                text="Adjust Level Progress"
                onPress={this.handleAdjustLevelProgressPress}
                styleOverride={styles.buttonOverrides}
                ignoreGlobalDisabled
              />

              <TextButton
                text="Config"
                onPress={this.handleConfigAdminPress}
                styleOverride={styles.buttonOverrides}
                ignoreGlobalDisabled
              />

              <TextButton
                text="Reset saved Avatar"
                onPress={this.handleResetAvatarPress}
                styleOverride={styles.buttonOverrides}
                ignoreGlobalDisabled
              />

              <TextButton
                text="Set Avatar Editable"
                onPress={this.handleSetAvatarEditablePress}
                styleOverride={styles.buttonOverrides}
                ignoreGlobalDisabled
              />
            </Column>

            <Column style={styles.infoBoxes}>
              <Row style={styles.infoBoxes}>
                {/* UNIT INFO COLUMN */}
                <UnitDevTools />

                {/* ROUND INFO COLUMN */}
                <RoundDevTools />
              </Row>

              <ShortcutsDevTools />
            </Column>
          </Row>

          {/* FOOTER BUTTONS */}
          <Row style={{ ...styles.content, ...styles.footer }}>
            <ReloadAppButton style={styles.buttonOverrides} />
            <TextButton
              text="Lexia Error"
              onPress={this.throwLexiaError}
              styleOverride={styles.buttonOverrides}
              ignoreGlobalDisabled
            />
            <Column />
            <TextButton
              onPress={this.close}
              text="Close"
              styleOverride={styles.buttonOverrides}
              Icon={CloseIconSvg}
              ignoreGlobalDisabled
            />
          </Row>
        </Column>
      )
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    appConfig: UniConfigSelector.getConfig<ILoboAppConfig>(state),
    avatar: ProfileSelector.getAvatarMaybe(state),
    isVisible: DevToolsSelector.getIsVisible(state),
    position: DevToolsSelector.getPosition(state),
    themeSize: ThemeSelector.getThemeSize(state)
  };
}

const mapDispatchToProps = {
  applyConfigOverrides: (overrides: IAppConfigOverridesPayload) =>
    AppConfigAction.applyOverrides(overrides),
  changePosition: (position: DevToolsPosition) =>
    DevToolsAction.changePosition({ position }),
  hide: () => DevToolsAction.hide(),
  resetAvatar: () =>
    ProfileAction.updateAvatar({
      faceStyle: undefined
    }),
  resetAvatarStudentProperties: () =>
    StudentPropertyAction.update.request({
      studentProperties: [
        {
          key: StudentProperty.Avatar,
          value: JSON.stringify({
            encountersUntilEditable: 0,
            selection: undefined
          })
        }
      ]
    }),
  setAvatarEditable: () => AvatarEditorAction.setEncountersUntilEditable(0),
  setAvatarEditableStudentProperties: avatar =>
    StudentPropertyAction.update.request({
      studentProperties: [
        {
          key: StudentProperty.Avatar,
          value: JSON.stringify({
            encountersUntilEditable: 0,
            selection: avatar
          })
        }
      ]
    }),
  show: () => DevToolsAction.show(),
  showModal: (id: ModalId) => AppShellAction.showModal({ id }),
  updateTheme: (sz: ThemeSize) => ThemeAction.update({ theme: { size: sz } })
};

export const DevTools = withKeyDownEvent(
  connect(mapStateToProps, mapDispatchToProps)(DevToolsComponent)
);

function CloseIconSvg() {
  return (
    <UtilityButtonSvg
      iconType={UtilityButtonIconType.Close}
      colorSetName={UtilityButtonColorSetName.Black}
      scale={0.4}
      aria-hidden="true"
    />
  );
}
CloseIconSvg.displayName = 'CloseIconSvg';
