import * as React from 'react';
import { AnimatedView } from '../view/AnimatedView';
import { PopFadeAnimationAnimatedStyles } from './PopFadeAnimation.animated-styles';

export interface IPopFadeAnimationProps {
  children?: React.ReactNode;
  duration?: number;
  hideDuration?: number;
  scale?: number;
  scaleX?: number;
  scaleY?: number;
  showDuration?: number;
  trigger: boolean;
}

export class PopFadeAnimation extends React.PureComponent<IPopFadeAnimationProps> {
  public static readonly displayName = 'PopFadeAnimation';

  private readonly animatedStyles: PopFadeAnimationAnimatedStyles;

  constructor(props: IPopFadeAnimationProps) {
    super(props);

    this.animatedStyles = new PopFadeAnimationAnimatedStyles(props);
  }

  public componentDidUpdate(prevProps: IPopFadeAnimationProps) {
    const { trigger } = this.props;
    const animations = this.animatedStyles.getAnimations();
    const { show, hide } = animations;

    if (!prevProps.trigger && trigger) {
      show.start();
    } else if (prevProps.trigger && !trigger) {
      hide.start();
    }
  }

  public render() {
    const { children } = this.props;
    const styles = this.animatedStyles.get();

    return (
      <AnimatedView animatedStyle={styles.containerAnimated}>
        {children}
      </AnimatedView>
    );
  }
}
