import { IScreenplay } from '@lexialearning/lobo-common/main-model';
import { Sfx } from 'audio';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';
import { TaskReactAnimationName } from '../task-component.model';
import { TaskAction } from '../redux';

export class TaskExitScreenplayBuilder {
  public static readonly ScreenplayId = 'TaskExit';

  public static createFor(context: RoundContext): TaskExitScreenplayBuilder {
    return new TaskExitScreenplayBuilder(context);
  }

  private readonly builder: ScreenplayBuilder;

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  private constructor(private readonly roundContext: RoundContext) {
    this.builder = ScreenplayBuilder.create(
      TaskExitScreenplayBuilder.ScreenplayId
    );
    const isUnitExit = this.roundContext.atLastRound;

    if (isUnitExit) {
      this.instructionUnitExitSfxMaybe();
    }

    this.taskExitReactAnimation().dispatchTaskExitComplete();
  }

  private instructionUnitExitSfxMaybe(): TaskExitScreenplayBuilder {
    if (this.roundContext.isInstructionRound) {
      this.builder.addSfx(Sfx.InstructionOff, { concurrent: true });
    }

    return this;
  }

  private taskExitReactAnimation(): TaskExitScreenplayBuilder {
    const isUnitExit = this.roundContext.atLastRound;

    if (this.roundContext.isInstructionRound) {
      if (isUnitExit) {
        this.builder.addReactAnimation(
          TaskReactAnimationName.InstructionUnitExit
        );
      }
    } else {
      this.builder.addReactAnimation(TaskReactAnimationName.StandardRoundExit);
    }

    return this;
  }

  private dispatchTaskExitComplete(): TaskExitScreenplayBuilder {
    this.builder.addReduxAction(TaskAction.exitComplete());

    return this;
  }
}
