import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export class LevelCompleteSceneAnimationName {
  public static readonly Root = {
    Highlight: 'highlight',
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro,
    PreIntro: 'preintro'
  };

  public static readonly Background = {
    Idle: StandardSceneAnimationName.Idle
  };

  public static readonly BackgroundOverlay = {
    Idle: StandardSceneAnimationName.Idle
  };
}

export enum LevelCompleteSceneMaterialName {
  Certificate = 'certificate'
}

export enum LevelCompleteSceneElementName {
  Background = 'background',
  BackgroundOverlay = 'background_overlay'
}
