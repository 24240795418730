import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { CustomerActions, CustomerActionType } from '../redux';
import {
  DeviceSetupLocalStorageKeys,
  IDeviceSetupDependencies
} from './device-setup-epic.model';

export function changeAccountEpic(
  action$: Observable<CustomerActions>,
  _state$: StateObservable<unknown>,
  deps: IDeviceSetupDependencies
): Observable<void> {
  return action$.pipe(
    ofType(CustomerActionType.ChangeAccount),
    tap(() => {
      void deps.localStorageService.removeItem(
        DeviceSetupLocalStorageKeys.CustomerCode
      );
    }),
    ignoreElements()
  );
}

changeAccountEpic.displayName = 'changeAccountEpic';
