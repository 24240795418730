import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import { Font } from 'common-styles';
import { ITextButtonStyleOverride } from 'common-ui';

export class LogoutWithConfirmationButtonStyles {
  private static readonly styles = {
    buttonText: {
      fontSize: Font.Size.small
    }
  };

  public static readonly build = memoizeOne(
    (styleOverride?: ITextButtonStyleOverride) =>
      merge({}, LogoutWithConfirmationButtonStyles.styles, styleOverride)
  );
}
