import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export enum EncounterAnimationCategory {
  EncounterComplete = 'Encounter complete rewards'
}

export enum EncounterSceneElementName {
  Background = 'background',
  Character = 'character1'
}

export class EncounterSceneAnimationName {
  public static readonly Background = {
    Brighten: 'brighten',
    Dim: 'dim',
    Intro: StandardSceneAnimationName.Intro,
    PokIdle: 'pok_idle',
    PokIntro: 'pok_intro'
  };
}
