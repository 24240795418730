import { AccountSettingsModalStyles } from '../AccountSettingsModal.styles';

export class LegalInfoWebViewStyles {
  private static readonly styles = {
    borderWidth: 0,
    height: AccountSettingsModalStyles.webViewHeight
  };

  public static get() {
    return this.styles;
  }
}
