import { Types } from '@lexialearning/reactxp';
import * as React from 'react';
import { InteractionState } from 'common-styles/enums/InteractionState';
import { GripDots } from '../grip-dots/GripDots';
import { Column } from '../layout/column/Column';
import { Text } from '../text/Text';
import { ITextPillStyleOverride, TextPillStyle } from './TextPill.styles';
import { ImportantForAccessibility } from '@lexialearning/common-ui';

export interface ITextPillProps {
  children?: React.ReactNode;
  isHighlighted?: boolean;
  hasGripDots?: boolean;
  hasShadow?: boolean;
  importantForAccessibility?: ImportantForAccessibility;
  interactionState?: InteractionState;
  styleOverride?: ITextPillStyleOverride;
  blockPointerEvents?: boolean;
  onLayout?(e: Types.ViewOnLayoutEvent): void;
}

export function TextPill(props: ITextPillProps) {
  const {
    children,
    hasGripDots,
    importantForAccessibility,
    interactionState = InteractionState.Default,
    hasShadow,
    styleOverride,
    blockPointerEvents,
    onLayout
  } = props;
  const styles = TextPillStyle.build(
    interactionState,
    !!hasShadow,
    styleOverride
  );

  return (
    <Column
      style={styles.container}
      onLayout={onLayout}
      blockPointerEvents={blockPointerEvents}
      importantForAccessibility={importantForAccessibility}
    >
      {hasGripDots && <GripDots dotRowPosition="left" />}
      <Text style={styles.text}>{children}</Text>
      {hasGripDots && <GripDots dotRowPosition="right" />}
    </Column>
  );
}
TextPill.displayName = 'TextPill';
