import * as React from 'react';
import { HintBox } from 'task-components/see-speak/hint-box/HintBox';
import { IHintBoxStyleOverride } from 'task-components/see-speak/hint-box/HintBox.animated-styles';
import { ISpeechBubbleCommonProps } from '../speech-bubbles.model';
import { ITextPrompt } from '@lexialearning/lobo-common';
import { IViewStyle } from '@lexialearning/common-ui';
import { ResponseSpeechBubble } from '../shared/speech-bubble/ResponseSpeechBubble';
import { SeeSpeakTextPrompt } from 'task-components/see-speak/text-prompt/SeeSpeakTextPrompt';

interface IInstructionSpeechBubbleProps
  extends Omit<ISpeechBubbleCommonProps, 'animatedStyles'> {
  styles: {
    hintBoxOverride: IHintBoxStyleOverride;
    languageFrame: IViewStyle;

    textPromptOverride: {
      container: {
        width: number;
      };
    };
  };
  textPromptArray: ITextPrompt[];
}

export function InstructionSpeechBubble(
  props: IInstructionSpeechBubbleProps
): React.ReactElement {
  const { mode, styles, textPromptArray } = props;

  return (
    <>
      <SeeSpeakTextPrompt
        textPromptArray={textPromptArray}
        isInstruction={true}
        width={styles.textPromptOverride.container.width}
      />
      <HintBox styleOverride={styles.hintBoxOverride} isInstruction={true} />
      <ResponseSpeechBubble
        hasAvatar={false}
        isInstruction={true}
        languageFrameWidth={styles.languageFrame.width}
        mode={mode}
      />
    </>
  );
}
InstructionSpeechBubble.displayName = 'InstructionSpeechBubble';
