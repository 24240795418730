import { useSelector } from 'react-redux';
import { Column, Link, Row, ScrollView, Text } from 'common-ui';
import { ILoboAppConfig } from 'services/app-config';
import { NoticesStyles } from './Notices.styles';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { ExternalLinkIconSvg } from 'common-ui/graphics/ExternalLinkIcon.lx-svg';
import { Color } from 'common-styles';

export function Notices() {
  const { licensesUrl } = useSelector(
    UniConfigSelector.getConfig<ILoboAppConfig>
  ).accountSettings;
  const styles = NoticesStyles.get();

  return (
    <ScrollView
      // ID added in order to use it to allow :focus outline style on anchors
      // in this content, as focus outlines are turned off (set to 0) for the
      // app in general (in template.html)
      id="notices-tab-content"
      testId={Notices.displayName}
      style={styles.scrollContainer}
    >
      <Column style={styles.container}>
        <Text style={styles.headline}>
          Disclaimer & Attribution for Instruction and Assessment Content
        </Text>
        <Text style={styles.paragraph}>
          Information and examples included in instruction and assessment
          content are provided for language-learning instruction and assessment
          purposes only, and are not intended to be used for any other purpose.
          Lexia&reg; makes no representations or warranties, express or implied,
          that such information is sufficient, accurate, complete, current, or
          fit for a particular purpose. Any references to particular
          organizations, institutions, individuals, or topics included in
          instructional and assessment content are for purposes of example and
          context for language-instruction and assessment only, and do not
          necessarily constitute the opinion, endorsement, or approval by or of
          Lexia.
        </Text>
        <Text style={styles.paragraph}>
          Certain passages and images within the instruction and assessment
          content may contain works prepared by third-party contributors or
          published by the sources listed below. Such works may be in the public
          domain, or may be protected by copyright, trademark, and/or other
          rights and used by Lexia with permission.
        </Text>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              Lionel Pincus and Princess Firyal Map Division, The New York
              Public Library. (1902 - 1916). C1. Smyrna. Retrieved from
            </Text>
            <Text style={styles.listText}>
              http://digitalcollections.nypl.org/items/95d04112-04fa-4164-e040-e00a18067503
            </Text>
          </Column>
        </Row>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              The Miriam and Ira D. Wallach Division of Art, Prints and
              Photographs: Photography Collection, The New York Public Library.
              (1860 - 1929). Inundation du Nil et palmiers. Retrieved from
            </Text>
            <Text style={styles.listText}>
              http://digitalcollections.nypl.org/items/510d47d9-616d-a3d9-e040-e00a18064a99
            </Text>
          </Column>
        </Row>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              The Miriam and Ira D. Wallach Division of Art, Prints and
              Photographs: Photography Collection, The New York Public Library.
              (1841). Frontispiece Retrieved from
            </Text>
            <Text style={styles.listText}>
              http://digitalcollections.nypl.org/items/510d47d9-5d74-a3d9-e040-e00a18064a99
            </Text>
          </Column>
        </Row>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              The Boy Who Harnessed the Wind by William Kamkwamba “Interior
              Illustrations,” by Elizabeth Zumon, copyright © 2012 by Elizabeth
              Zumon; and Excerpt(s) from The Boy Who Harnessed The Wind: Picture
              Book Edition by William Kamkwamba and Bryan Mealer, text copyright
              © 2012 by William Kamkwamba and Bryan Mealer. Used by permission
              of Dial Books for Young Readers, an imprint of Penguin Young
              Readers Group, a division of Penguin Random House LLC. All rights
              reserved.
            </Text>
          </Column>
        </Row>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              Illustrations of 3 Thomas Dambo sculptures By permission of Thomas
              Dambo.
            </Text>
            <Text style={styles.listText}>www.thomasdambo.com</Text>
          </Column>
        </Row>
        <Row style={styles.listRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>
              Illustrations of Hedy Lamarr Hedy Lamarr™ is a trademark of the
              Estate of Hedy Lamarr.
            </Text>
          </Column>
        </Row>
        <Row style={styles.lastRow}>
          <Text>{'\u2022'}</Text>
          <Column style={styles.listItem}>
            <Text style={styles.listText}>R.A. McLean Photography</Text>
          </Column>
        </Row>
        <Text style={styles.headline}>
          Attribution for Third-Party Libraries and Components
        </Text>
        <Link style={styles.link} url={licensesUrl}>
          <ExternalLinkIconSvg color={Color.Gray55} style={styles.linkIcon} />
          <Text style={styles.linkText}>
            Third-party libraries and components used
          </Text>
        </Link>
      </Column>
    </ScrollView>
  );
}
Notices.displayName = 'Notices';
