import { AvatarEditorEvent } from 'logging';

export enum AvatarEditorActionType {
  AddUserEvent = 'AVATAR_EDITOR.ADD_USER_EVENT',
  HideEditor = 'AVATAR_EDITOR.HIDE_EDITOR',
  Reset = 'AVATAR_EDITOR.RESET',
  SaveAndExit = 'AVATAR_EDITOR.SAVE_AND_TRANSITION',
  SetEncountersUntilEditable = 'AVATAR_EDITOR.SET_ENCOUNTERS_UNTIL_EDITABLE',
  ShowEditor = 'AVATAR_EDITOR.SHOW_EDITOR',
  TimeoutUserConfirmation = 'AVATAR_EDITOR.TIMEOUT_USER_CONFIRMATION',
  TimerSetDuration = 'AVATAR_EDITOR.TIMER_SET_DURATION',
  TimerStart = 'AVATAR_EDITOR.TIMER_START',
  TimerPause = 'AVATAR_EDITOR.TIMER_PAUSE',
  TimerRestart = 'AVATAR_EDITOR.TIMER_RESTART',
  TimerTimeout = 'AVATAR_EDITOR.TIMER_TIMEOUT'
}

export enum AvatarTimerStatus {
  Finished = 'finished',
  Paused = 'paused',
  Ready = 'ready',
  Restart = 'restart',
  Running = 'running'
}

/** AvatarTimerStatus to be set when the given action is dispatched */
export const AvatarEditorActionToStatusMap = new Map<
  AvatarEditorActionType,
  AvatarTimerStatus
>([
  [AvatarEditorActionType.TimerStart, AvatarTimerStatus.Running],
  [AvatarEditorActionType.TimerPause, AvatarTimerStatus.Paused],
  [AvatarEditorActionType.TimerRestart, AvatarTimerStatus.Restart]
]);

export enum AvatarTimerDurationMs {
  /** Time to create an avatar for the first time (in milliseconds). */
  Creating = 120000,
  /** Time to edit an existing avatar (in milliseconds). */
  Editing = 60000,
  /** Time added after the initial time elapses (in milliseconds). */
  Extension = 15000
}

export interface IAvatarTimer {
  duration: number;
  extensionsRemaining: number;
  status: AvatarTimerStatus;
}

export interface IAvatarEditorState {
  encountersUntilEditable: number;
  shouldShowEditor: boolean;
  userEvents: IAvatarEditorUserEvent[];
  timer: IAvatarTimer;
}

export interface IAvatarEditorUserEvent extends IAvatarEditorUserEventPayload {
  timestamp: number;
}

export interface IAvatarEditorUserEventPayload {
  eventName: AvatarEditorEvent;
  data?: Object;
}

export const ENCOUNTERS_TO_ENABLE_REEDIT = 1;
