import { TaskTypeName } from '@lexialearning/lobo-common/main-model';
import { IImageTask } from '@lexialearning/lobo-common/tasks/image-task';
import { createNeutralCorrectFeedbackScreenplay } from 'task-components/shared';
import {
  ITaskRegistration,
  ModelSection,
  ScreenplayType,
  TaskRegistrationBuilder
} from '../core';
import { ImageTask } from './ImageTask';

export function createImageTaskRegistration(): ITaskRegistration<TaskTypeName.ImageTask> {
  return TaskRegistrationBuilder.create<TaskTypeName.ImageTask, IImageTask>(
    TaskTypeName.ImageTask,
    ImageTask
  )
    .withScreenplayBuilder(
      ScreenplayType.Feedback,
      createNeutralCorrectFeedbackScreenplay
    )
    .setModeledFields(ModelSection.Presentation, ['voiceover']).registration;
}
