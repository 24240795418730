import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { EducatorActionType, IEducatorState } from './educator-redux.model';
import { EducatorActions } from './Educator.action';

const educatorInitialState: IEducatorState = {
  levels: []
};

export function educatorReducer(
  state = educatorInitialState,
  action: EducatorActions | UserGlobalActionLogoutSuccess
): IEducatorState {
  switch (action.type) {
    case EducatorActionType.LoadLevelsSuccess:
      return { ...state, levels: action.payload };

    case UserGlobalActionType.LogoutSuccess:
      return educatorInitialState;

    default:
      return state;
  }
}

export const EducatorReducerPrivates = { initialState: educatorInitialState };
