import { Color, InteractionState } from 'common-styles';

export class RenderDragLayerItemStyle {
  public static get() {
    return {
      textPill: {
        text: {
          color: Color.getSetFor(InteractionState.Selected).text
        }
      }
    };
  }
}
