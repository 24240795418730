import { AvatarType } from '../avatar.model';
import { IAvatarLayeredImagesProps } from '../layered-images/AvatarLayeredImages';
import { SilhouetteFaceSvg } from './SilhouetteFace.lx-svg';
import { SilhouetteFullBodySvg } from './SilhouetteFullBody.lx-svg';

export function AvatarSilhouetteSvg(
  props: IAvatarLayeredImagesProps
): React.ReactElement {
  const {
    type,
    styles: { image: sizes }
  } = props;

  return type === AvatarType.Full ? (
    <SilhouetteFullBodySvg {...sizes} />
  ) : (
    <SilhouetteFaceSvg {...sizes} />
  );
}
