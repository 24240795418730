import { ModalRegistry, ModalType } from 'shared-components/modals';
import { ReturnToLoginModal } from '../ReturnToLoginModal';
import invalid_siteIDPng from './invalid_siteID.png';

export class InvalidCustomerCodeModal {
  public static readonly ModalId = ModalRegistry.register({
    Component: ReturnToLoginModal,
    headline: 'Outdated Link.',
    id: 'InvalidCustomerCodeModal',
    image: invalid_siteIDPng,
    text: 'Please talk with your tech person about getting a new URL with a new SiteID.',
    title: 'Oops!',
    type: ModalType.Error
  });
}
