import { ofType, StateObservable } from 'redux-observable';
import { lastValueFrom, Observable } from 'rxjs';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import { PredicateUtils } from 'utils';
import { ICurriculumDependencies } from '../../CurriculumDependencies';
import { ProgramContextSelector } from '../../program-context';
import {
  UnitAction,
  UnitActionLoad,
  UnitActionLoadSuccess,
  UnitActionType
} from '../redux';
import { UnitLoader } from './UnitLoader';

/**
 * Load active (sub)unit (based on activity position) and any required parent
 * units.
 * (e.g. just the main unit, an instruction subunits and its parent, etc.)
 */
export function loadUnitsEpic(
  action$: Observable<UnitActionLoad>,
  state$: StateObservable<unknown>,
  deps: ICurriculumDependencies
): Observable<UnitActionLoadSuccess> {
  return action$.pipe(
    ofType(UnitActionType.Load),
    switchMap(async () => {
      const activityPosition = await lastValueFrom(
        state$.pipe(
          startWith(state$.value),
          map(ProgramContextSelector.getActivityPositionMaybe),
          first(PredicateUtils.isDefined)
        )
      );

      const loader = new UnitLoader(
        deps.curriculumDependencies.contentProviderFactory
      );
      const payload = await loader.load(activityPosition);

      return UnitAction.load.success(payload);
    })
  );
}

loadUnitsEpic.displayName = 'loadUnitsEpic';
