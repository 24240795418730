import { ActionType, createAction } from 'typesafe-actions';
import {
  AppShellMode,
  ControlPanelLayout,
  IActiveModal,
  IUserControlInteractionState,
  NavigationButtonType,
  UserInfoButtonType
} from './app-shell.model';
import { AppShellActionType } from './AppShellActionType';

export const AppShellAction = {
  disableAudioSupport: createAction(AppShellActionType.DisableAudioSupport)(),
  disableUtilityBar: createAction(AppShellActionType.DisableUtilityBar)(),
  disableUtilityBarZIndex: createAction(
    AppShellActionType.DisableUtilityBarZIndex
  )(),
  enableAudioSupport: createAction(AppShellActionType.EnableAudioSupport)(),
  enableUtilityBar: createAction(AppShellActionType.EnableUtilityBar)(),
  enableUtilityBarZIndex: createAction(
    AppShellActionType.EnableUtilityBarZIndex
  )(),
  hideModal: createAction(AppShellActionType.HideModal)(),
  hideSkipButton: createAction(AppShellActionType.HideSkipButton)(),
  navigateBack: createAction(AppShellActionType.NavigateBack)(),
  navigateBackToEducator: createAction(
    AppShellActionType.NavigateBackToEducator
  )(),
  notifyNavigationHistoryUpdate: createAction(
    AppShellActionType.NotifyNavigationHistoryUpdate
  )(),
  requestLogout: createAction(AppShellActionType.RequestLogout)(),
  setControlPanelLayout: createAction(
    AppShellActionType.SetControlPanelLayout
  )<ControlPanelLayout>(),
  setMode: createAction(AppShellActionType.SetMode)<AppShellMode>(),
  setNavigationButtonType: createAction(
    AppShellActionType.SetNavigationButtonType
  )<NavigationButtonType>(),
  setShouldCalloutLevelCertificates: createAction(
    AppShellActionType.SetShouldCalloutLevelCertificates
  )<boolean>(),
  setUserControlInteractionState: createAction(
    AppShellActionType.SetUserControlInteractionState
  )<ISetUserControlInteractionStateActionPayload>(),
  setUserInfoButtonType: createAction(
    AppShellActionType.SetUserInfoButtonType
  )<UserInfoButtonType>(),
  showModal: createAction(AppShellActionType.ShowModal)<IActiveModal>(),
  showSkipButton: createAction(AppShellActionType.ShowSkipButton)(),
  skipButtonPressed: createAction(AppShellActionType.SkipButtonPressed)()
};

export type ISetUserControlInteractionStateActionPayload =
  Partial<IUserControlInteractionState>;
export type AppShellActions = ActionType<typeof AppShellAction>;
export type AppShellActionRequestLogout = ActionType<
  typeof AppShellAction.requestLogout
>;
export type AppShellActionHideModal = ActionType<
  typeof AppShellAction.hideModal
>;
export type AppShellActionHideSkipButton = ActionType<
  typeof AppShellAction.hideSkipButton
>;
export type AppShellActionNotifyNavigationHistoryUpdate = ActionType<
  typeof AppShellAction.notifyNavigationHistoryUpdate
>;
export type AppShellActionNavigateBack = ActionType<
  typeof AppShellAction.navigateBack
>;
export type AppShellActionNavigateBackToEducator = ActionType<
  typeof AppShellAction.navigateBackToEducator
>;
export type AppShellActionSetControlPanelLayout = ActionType<
  typeof AppShellAction.setControlPanelLayout
>;
export type AppShellSetMode = ActionType<typeof AppShellAction.setMode>;
export type AppShellActionSetNavigationButtonType = ActionType<
  typeof AppShellAction.setNavigationButtonType
>;
export type AppShellActionSetUserControlInteractionState = ActionType<
  typeof AppShellAction.setUserControlInteractionState
>;
export type AppShellActionSetUserInfoButtonType = ActionType<
  typeof AppShellAction.setUserInfoButtonType
>;
export type AppShellActionShowModal = ActionType<
  typeof AppShellAction.showModal
>;
export type AppShellActionShowSkipButton = ActionType<
  typeof AppShellAction.showSkipButton
>;
export type AppShellActionSkipButtonPressed = ActionType<
  typeof AppShellAction.skipButtonPressed
>;
