import { Defs, Ellipse, G, LinearGradient, Path, Stop, Svg } from 'common-ui';
import { uniqueId } from 'lodash';
import { Color } from 'common-styles';

export function FlareSvg() {
  const gradientId = uniqueId('glossy_button');

  return (
    <Svg
      data-test-id={FlareSvg.displayName}
      width={40}
      height={40}
      viewBox="0 0 180 180"
    >
      <Defs>
        <LinearGradient
          id={gradientId}
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor={Color.White} />
          <Stop offset="1" stopColor={Color.White} stopOpacity="0" />
        </LinearGradient>
      </Defs>
      <G transform="translate(-640 -65)">
        <Path
          d="M6.5,0,13,92H0Z"
          transform="translate(727.88 155.378)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(729.527 161.071) rotate(-49)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(727.227 158.036) rotate(-29)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="matrix(0.574, -0.819, 0.819, 0.574, 729.315, 161.617)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(737.122 162.394) rotate(-115)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(730.729 148.535) rotate(43)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="matrix(-0.035, 0.999, -0.999, -0.035, 735.182, 147.313)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="matrix(0.309, 0.951, -0.951, 0.309, 734.339, 147.307)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(736.358 147.978) rotate(98)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Path
          d="M6.68,0l6.68,92.779H0Z"
          transform="translate(740.647 151.447) rotate(158)"
          opacity="0.57"
          fill={`url(#${gradientId})`}
        />
        <Ellipse
          cx="42.5"
          cy="43"
          rx="42.5"
          ry="43"
          transform="translate(688.88 110.378)"
          fill={Color.White}
          opacity="0.19"
        />
        <Ellipse
          cx="63.5"
          cy="65"
          rx="63.5"
          ry="65"
          transform="translate(667.88 88.378)"
          fill={Color.White}
          opacity="0.07"
        />
        <Ellipse
          cx="24.5"
          cy="24"
          rx="24.5"
          ry="24"
          transform="translate(706.88 129.378)"
          fill={Color.White}
          opacity="0.32"
        />
      </G>
    </Svg>
  );
}

FlareSvg.displayName = 'FlareSvg';
