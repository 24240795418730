import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IScreenplay,
  ITaskEvaluation,
  TaskEvaluationResult,
  TaskPhase
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { cloneDeep, last } from 'lodash';
import { ISpeakerInfo, VoiceoverScreenplayActionType } from 'audio';
import { RoundContext } from 'curriculum-services';
import { ScreenplayBuilder } from 'screenplay';
import { CharacterAnimationCategory } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TaskFeedbackScreenplayId } from 'task-components/core/registry/FeedbackScreenplayBuilder';
import {
  ChoralScreenplayInfo,
  ISreSessionAnswer
} from 'task-components/see-speak';
import {
  CharacterSceneCharacterAnimationLayer,
  CharacterSceneElementName
} from './character-scene.model';
import { CharacterSceneBase } from './CharacterSceneBase';
import { CalibrationResult } from '@lexialearning/sre';

export class CharacterSceneScreenplayEditorBuilder {
  public static readonly displayName = 'CharacterScreenplayEditorBuilder';

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  private builder: ScreenplayBuilder;

  constructor(
    private readonly scene: CharacterSceneBase,
    private readonly sceneName: SceneName,
    private readonly originalScreenplay: IScreenplay,
    private readonly context: RoundContext
  ) {
    this.builder = ScreenplayBuilder.create(originalScreenplay.id);
  }

  public editBasedOnPhase(
    phase: TaskPhase
  ): CharacterSceneScreenplayEditorBuilder {
    switch (phase) {
      case TaskPhase.Feedback:
        return this.editAndAddFeedbackScreenplay();

      default:
        return this.addOriginalScreenplay();
    }
  }

  private addOriginalScreenplay(): CharacterSceneScreenplayEditorBuilder {
    this.builder.addScreenplay(this.originalScreenplay);

    return this;
  }

  private editAndAddFeedbackScreenplay(): CharacterSceneScreenplayEditorBuilder {
    if (!this.shouldAnimateCharacterForFeedback()) {
      return this.addOriginalScreenplay();
    }

    const sfxAction = this.originalScreenplay.actions[0];
    const otherActions = this.originalScreenplay.actions.slice(1);
    const category = this.getAnimationCategory();
    const animation = this.scene.character.pickOneAnimation(category);

    this.builder
      .addAction(sfxAction)
      .addStormAnimation(
        {
          animationLayer: CharacterSceneCharacterAnimationLayer.Gesture,
          blendTimeSeconds: 0.25,
          name: animation.name,
          targetElement: CharacterSceneElementName.Character,
          targetScene: this.sceneName
        },
        { concurrent: true }
      )
      .addActionList(otherActions);

    return this;
  }

  private shouldAnimateCharacterForFeedback() {
    const isNeutralFeedbackScreenplay =
      this.originalScreenplay.id === TaskFeedbackScreenplayId.FeedbackNeutral;
    const { hasOnscreenCharacter, lastAttemptMaybe } = this.context;
    const hasMicError =
      lastAttemptMaybe?.result === TaskEvaluationResult.Inconclusive;

    return (
      hasOnscreenCharacter && (hasMicError || !isNeutralFeedbackScreenplay)
    );
  }

  private getAnimationCategory(): CharacterAnimationCategory {
    switch (this.context.lastAttemptMaybe?.result) {
      case TaskEvaluationResult.Correct:
        return this.context.roundNode.next === undefined
          ? CharacterAnimationCategory.Clap
          : CharacterAnimationCategory.Correct;
      case TaskEvaluationResult.Incorrect:
        return CharacterAnimationCategory.Incorrect;
      case TaskEvaluationResult.Inconclusive:
        return this.getMicAnimationCategory(last(this.context.attempts));
      default:
        throw new LexiaError(
          `Invalid evaluationResult ${this.context.lastAttemptMaybe?.result}`,
          CharacterSceneScreenplayEditorBuilder.displayName,
          CharacterSceneScreenplayEditorBuilderError.InvalidTaskEvaluationResult
        );
    }
  }

  private getMicAnimationCategory(
    lastAttempt: ITaskEvaluation | undefined
  ): CharacterAnimationCategory {
    const attempt = lastAttempt as
      | ITaskEvaluation<ISreSessionAnswer>
      | undefined;
    const calibrationResult = attempt?.answer.calibrationResult;
    switch (calibrationResult) {
      case CalibrationResult.TooLoud:
        return CharacterAnimationCategory.TooLoud;
      case CalibrationResult.TooSoft:
        return CharacterAnimationCategory.TooSoft;
      case CalibrationResult.NoSignal:
        return CharacterAnimationCategory.NoSignal;
      default:
        throw new LexiaError(
          `Invalid calibrationResult ${calibrationResult}`,
          CharacterSceneScreenplayEditorBuilder.displayName,
          CharacterSceneScreenplayEditorBuilderError.InvalidCalibrationResult
        );
    }
  }

  public addSpeakerMaybe(): CharacterSceneScreenplayEditorBuilder {
    if (!this.context.hasOnscreenCharacter) {
      return this;
    }

    const hasVoiceovers = this.screenplay.actions.some(
      a => a.type === LoboContentType.Voiceover
    );
    if (!hasVoiceovers) {
      return this;
    }

    const revisedActions = this.screenplay.actions.map((a, idx) =>
      a.type === LoboContentType.Voiceover
        ? this.addSpeaker(a as VoiceoverScreenplayActionType, idx)
        : a
    );

    this.builder = ScreenplayBuilder.create(this.screenplay.id).addActionList(
      revisedActions
    );

    return this;
  }

  private addSpeaker(
    voAction: VoiceoverScreenplayActionType,
    index: number
  ): VoiceoverScreenplayActionType {
    const speaker: ISpeakerInfo = {
      directions: this.maybeAddDirections(index),
      sceneId: this.sceneName,
      speakerId: CharacterSceneElementName.Character
    };
    const action = cloneDeep(voAction);

    return {
      ...action,
      data: { ...action.data, speaker }
    };
  }

  private maybeAddDirections(index: number) {
    return this.screenplay.id === ChoralScreenplayInfo.ScreenplayId &&
      index === ChoralScreenplayInfo.CountdownActionIndex
      ? { category: CharacterAnimationCategory.Countdown }
      : {};
  }
}

export enum CharacterSceneScreenplayEditorBuilderError {
  InvalidCalibrationResult = 'InvalidCalibrationResult',
  InvalidTaskEvaluationResult = 'InvalidTaskEvaluationResult'
}
