import { XYCoord } from 'react-dnd';
import { ActionType, createAction } from 'typesafe-actions';
import { DndActionType, IDndForcedDragLayer } from './dnd-redux.model';

export const DndAction = {
  dropToPosition: createAction(
    DndActionType.DropToPosition
  )<IDndActionDropToPositionPayload>(),

  reset: createAction(DndActionType.Reset)(),

  /**
   * Used to force drag layer to be visible when no drag has occurred
   * (for when user moves draggable elements without dragging
   *  eg, via tap on target in Multi Select)
   */
  setForcedDragLayer: createAction(
    DndActionType.SetForcedDragLayer
  )<IDndActionSetForceDragLayerPayload>(),
  setIsDraggingOrDropping: createAction(
    DndActionType.SetIsDraggingOrDropping
  )<boolean>()
};

export interface IDndActionDropToPositionPayload {
  position: XYCoord | undefined;
}
export interface IDndActionSetForceDragLayerPayload {
  layer: IDndForcedDragLayer | undefined;
}

export type DndActions = ActionType<typeof DndAction>;
