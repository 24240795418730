import { StateObservable } from 'redux-observable';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { filter, first, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { AppState, BootstrappingSelector } from 'services';
import { PredicateUtils } from 'utils/PredicateUtils';
import {
  AuthAction,
  AuthActionReLoginUser,
  AuthSelector,
  SsoActions
} from '../redux';
import { LoginActionFactory } from './login-action-factory';
import {
  ConfigSelector as UniConfigSelector,
  RouteService,
  SystemInfo
} from '@lexialearning/utils-react';
import { ILoboAppConfig } from 'services/app-config';
import { AuthApi } from 'lexia-service';
import { RouterService } from 'router-service';
import { ILoggingDeps } from 'services/logging/epics/logging-epic.model';

type DeepLinkReturnActions = AuthActionReLoginUser | SsoActions;

export interface INativeDeepLinkDeps extends ILoggingDeps {
  authApi: AuthApi;
}

/**
 * In native apps, listening to deeplinks and wait for bootstrap to complete before dispatching output actions
 */
export function nativeDeepLinkEpic(
  _action$: Observable<any>,
  state$: StateObservable<AppState>,
  deps: INativeDeepLinkDeps
): Observable<DeepLinkReturnActions> {
  return RouteService.deepLinkRequest$.pipe(
    filter(() => SystemInfo.isNative),
    switchMap(async url => {
      await firstValueFrom(
        state$.pipe(
          startWith(state$.value),
          first(state => BootstrappingSelector.isBootstrapped(state))
        )
      );

      return url;
    }),
    switchMap(async url => {
      // Temp fix for LOBO-20384 - remove once that ticket is resolved by MyLexia
      const config = UniConfigSelector.getConfig<ILoboAppConfig>(state$.value);

      // Fix for LOBO-20423 - in certain Clever flows, the SSO redirect url does
      // not contain the auth data, so it must be fetched
      if (!RouterService.isSsoUrl(url)) {
        try {
          const result = await deps.authApi.fetchAuthenticationData(url);
          const { apiUrl: authApiUrl, ...restResults } = result;

          return LoginActionFactory.createSsoActions({
            authApiUrl,
            ...restResults
          });
        } catch (error) {
          const { appLoggerDependencies: helper } = deps;
          void helper.logger!.logError(error);

          return;
        }
      }

      return LoginActionFactory.createSsoActionsFromUrl(
        url,
        config.lexiaService.customerUrl
      );
    }),
    filter(PredicateUtils.isDefined),
    mergeMap((ssoActions: SsoActions[]) => {
      const isLoggedIn = AuthSelector.isAuthenticated(state$.value);

      return isLoggedIn
        ? of(AuthAction.reLogin({ ssoActions }))
        : from(ssoActions);
    }),
    filter(PredicateUtils.isDefined)
  );
}
nativeDeepLinkEpic.displayName = 'nativeDeepLinkEpic';
