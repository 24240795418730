import { IAct } from '@lexialearning/lobo-common';
import { IActProgress } from '@lexialearning/lobo-common/main-model/student';
import * as React from 'react';
import { Column, Image, Row, Text } from 'common-ui';
import { ActOverviewStyles } from './ActOverview.styles';
import { EncounterProgressBar } from './progress-bar/EncounterProgressBar';
import { ActHelper } from 'curriculum-services/program-context/service-helpers';

export interface IActOverviewProps {
  act: IAct;
  progress: IActProgress;
}

export class ActOverview extends React.PureComponent<IActOverviewProps> {
  public static readonly displayName = 'ActOverview';

  public render() {
    const { act, progress } = this.props;
    const styles = ActOverviewStyles.get();

    return (
      <Row testId={ActOverview.displayName} style={styles.row}>
        <Image
          style={styles.headShot}
          source={act.character.headShot?.imageSource}
          accessibilityLabel={act.character.headShot?.altText}
        />
        <Column style={styles.infoColumn}>
          <Text style={styles.text}>{ActHelper.getDescriptiveTitle(act)}</Text>
          <EncounterProgressBar
            encounterPositions={progress.encounterPositions}
          />
        </Column>
      </Row>
    );
  }
}
