import { Border, Direction, Position } from '@lexialearning/common-ui';
import { Align, Color, Justify } from 'common-styles';
import { Types } from 'common-ui';

export class AvatarEditorStyles {
  private readonly styles: {
    divider: Types.ViewStyle;
    faceSelectionContainer: Types.ViewStyle;
    hairSelectionContainer: Types.ViewStyle;
    roundedTab: {
      opacity: number;
    };
    editor: Types.ViewStyle;
    selectionContainer: Types.ViewStyle;
    selectionContainerBorder: Types.ViewStyle;
    submitButton: Types.ViewStyle;
    submitButtonContainer: Types.ViewStyle;
  };

  constructor() {
    const selectionContainerStyles = {
      alignItems: Align.Center,
      height: 447,
      justifyContent: Justify.Start
    };

    this.styles = {
      divider: {
        backgroundColor: Color.NearWhite,
        borderRadius: 10,
        height: 421,
        marginLeft: -5,
        width: 3
      },
      faceSelectionContainer: {
        ...selectionContainerStyles,
        justifyContent: Justify.Center,
        width: 203
      },
      hairSelectionContainer: {
        alignItems: Align.Center,
        justifyContent: Justify.Start,
        height: 447
      },
      roundedTab: {
        opacity: 0.4
      },
      editor: {
        alignItems: Align.Center
      },
      selectionContainer: {
        ...selectionContainerStyles,
        backgroundColor: Color.NearWhiteTransparent40,
        borderRadius: 10,
        borderColor: Color.NearWhite
      },
      selectionContainerBorder: {
        borderColor: Color.NearWhite,
        borderStyle: Border.Solid,
        borderWidth: 3,
        borderRadius: 15,
        padding: 3,
        justifyContent: Justify.Start
      },
      submitButton: {
        position: Position.Absolute
      },
      submitButtonContainer: {
        alignItems: Align.Center,
        flexDirection: Direction.Row,
        height: 280,
        justifyContent: Justify.Center,
        width: 74
      }
    };
  }

  public get() {
    return this.styles;
  }
}
