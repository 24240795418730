import { IActivityPosition, IRound, IUnit } from '@lexialearning/lobo-common';
import * as React from 'react';
import { connect } from 'react-redux';
import { DevToolsAction } from '../redux/DevTools.action';
import { AdjustLevelProgressModalStyles } from './AdjustLevelProgressModal.styles';
import { Checkbox, PressScaleButton, Row, Text, View } from 'common-ui';
import { RouterService, RoutePath } from 'router-service';
import { AppState } from 'services';
import {
  LevelSelector,
  ProgramContextSelector,
  UnitSelector
} from 'services/curriculum-services';
import { ModalRegistry } from 'shared-components/modals';

interface IActPositionWithTitle extends IActivityPosition {
  title: string;
}

export interface IAdjustLevelProgressProps {
  unit: IUnit | undefined;
  activityPositionsTitled: IActPositionWithTitle[];
  adjustToCompletedActivity(activityId: string): void;
  shortcutToRound(unitId: string, roundId: string): void;
}

export class AdjustLevelProgressModalComponent extends React.PureComponent<IAdjustLevelProgressProps> {
  public static readonly displayName = 'AdjustLevelProgressModal';

  public static ModalId = 'AdjustLevelProgressModal';

  private shouldShowToggleActsIsComplete() {
    return RouterService.isOnRoute([
      RoutePath.Calibration,
      RoutePath.Home,
      RoutePath.Levels
    ]);
  }

  private handleToggleActComplete(activityId: string) {
    this.props.adjustToCompletedActivity(activityId);
  }

  private shouldShowGoToRoundList() {
    const { unit } = this.props;

    return (
      !!unit && RouterService.isOnRoute([RoutePath.Acts, RoutePath.Rounds])
    );
  }

  private handleRoundPress(roundId: string): void {
    const { unit } = this.props;
    this.props.shortcutToRound(unit!.sysId, roundId);
  }

  private getRoundDisplayTitle(roundTitle: string): string {
    return roundTitle.split('.').pop() || roundTitle;
  }

  private getTaskType(round: IRound): string {
    const { task }: { task: any } = round;

    return round.task ? task.taskType || task.contentType || task.type : '?';
  }

  public render() {
    const { activityPositionsTitled = [], unit } = this.props;
    const styles = AdjustLevelProgressModalStyles.get();

    return (
      <View style={styles.container}>
        {this.shouldShowToggleActsIsComplete() && (
          <>
            <Text style={styles.title}>Select acts to set as completed:</Text>
            {activityPositionsTitled.map((a, idx) => (
              <View key={idx} style={styles.checkboxContainer}>
                <Checkbox
                  isChecked={a.isComplete}
                  label={a.title}
                  labelId={`set-completed-act-0${idx + 1}`}
                  onToggle={this.handleToggleActComplete.bind(
                    this,
                    a.activityId
                  )}
                  styleOverride={styles.checkboxOverrides}
                />
              </View>
            ))}
          </>
        )}
        {this.shouldShowGoToRoundList() && (
          <>
            <Text style={styles.title}>Go directly to round:</Text>
            {(unit?.rounds || []).map((r, idx) => (
              <Row key={idx} style={styles.contentItem}>
                <Row style={styles.contentItemBullet} />
                <PressScaleButton
                  hoverScale={1.02}
                  onPress={this.handleRoundPress.bind(this, r.sysId)}
                >
                  <Text style={styles.roundText}>
                    {`${this.getRoundDisplayTitle(
                      r.title
                    )} - ${this.getTaskType(r)}`}
                  </Text>
                </PressScaleButton>
              </Row>
            ))}
          </>
        )}
      </View>
    );
  }
}

function mapStateToProps(state: AppState) {
  const level = LevelSelector.getLevel(state);
  const activityPositions = ProgramContextSelector.getActivityPositions(state);
  const activityPositionsTitled = activityPositions.map(ap => ({
    ...ap,
    title: level.acts.find(a => a.sysId === ap.activityId)?.title || ''
  }));
  const unit = UnitSelector.getUnitMaybe(state);

  return {
    activityPositionsTitled,
    unit
  };
}

const mapDispatchToProps = {
  adjustToCompletedActivity: (activityId: string) =>
    DevToolsAction.adjustCompletedActivity({
      activityId,
      toggleComplete: true
    }),
  shortcutToRound: (unitId: string, roundId: string) =>
    DevToolsAction.shortcutToRound({ roundId, unitId })
};

export const AdjustLevelProgressModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjustLevelProgressModalComponent);

AdjustLevelProgressModal.ModalId = ModalRegistry.register({
  Component: AdjustLevelProgressModal,
  title: 'Adjust Level Progress'
});
