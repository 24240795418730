import * as React from 'react';
import { ResponseSpeechBubble } from '../shared/speech-bubble/ResponseSpeechBubble';
import { ISpeechBubbleCommonProps } from '../speech-bubbles.model';

export function SayItAgainSpeechBubble(
  props: ISpeechBubbleCommonProps
): React.ReactElement {
  const { animatedStyles, ...restProps } = props;

  return (
    <ResponseSpeechBubble
      {...restProps}
      testId={SayItAgainSpeechBubble.displayName}
      animatedStyles={animatedStyles.response}
    />
  );
}
SayItAgainSpeechBubble.displayName = 'SayItAgainSpeechBubble';
