import { ImageSourceTransformer, Transform } from './image';

export interface IUiOptions {
  imageSourceTransformer: Transform<any>;
}

export class Configurator {
  public static configure(uiOptions: IUiOptions) {
    const { imageSourceTransformer } = uiOptions;

    ImageSourceTransformer.setTransformer(imageSourceTransformer);
  }
}
