import { Position } from '@lexialearning/common-ui';
import { ZIndex } from 'common-styles';

const ucButtons = {
  bottom: 25,
  position: Position.Absolute
};

const styles = {
  userControlButtons: {
    help: {
      ...ucButtons,
      bottom: 111,
      left: 6
    },
    helpMinimized: {
      ...ucButtons,
      borderWidth: 0,
      height: 47,
      left: 26,
      width: 47
    },
    replay: {
      ...ucButtons,
      left: 10,
      // zIndex should be used only when necessary, see documentation:
      // https://jira.lexialearning.com/wiki/pages/viewpage.action?pageId=169084866
      // We need the Replay button to have a higher zIndex than the Lookback button as
      // the latter must animate out from behind the former, and
      // reordering them in the dom to achieve this was throwing off the ordering for
      // keyboard navigation. So using zIndex here makes sense.
      zIndex: ZIndex.Shared
    },
    replayMinimized: {
      ...ucButtons,
      borderWidth: 0,
      height: 47,
      left: 89,
      width: 47
    }
  }
};

export class AudioSupportStyles {
  public static get() {
    return styles;
  }
}
