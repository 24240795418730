import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IProgramNode,
  NodeContentTypes
} from '@lexialearning/lobo-common/main-model';

export class ProgramNodeHelper {
  public static createUnwired<T extends IProgramNode>(
    content: NodeContentTypes,
    contentType: LoboContentType
  ): T {
    return {
      children: [],
      content,
      contentType,
      next: undefined,
      parent: undefined,
      prev: undefined
    } as unknown as T;
  }

  public static wireUp(parent: IProgramNode): void {
    const nodes = parent.children;

    nodes.forEach((n, index) => {
      n.next = nodes[index + 1];
      n.prev = nodes[index - 1];
      n.parent = parent;
      this.wireUp(n);
    });
  }
}
