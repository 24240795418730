import {
  Svg,
  Defs,
  RadialGradient,
  Stop,
  LinearGradient,
  Rect
} from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { SquareColorSets } from './ColorSets';
import { ISquareGlossyButtonColorSet } from './glossy-button-svg.model';
import {
  GlossyButtonBaseSvg,
  IGlossyButtonBaseSvgProps
} from './GlossyButtonBase.lx-svg';

export class SquareGlossyButtonSvg extends GlossyButtonBaseSvg<IGlossyButtonBaseSvgProps> {
  public static readonly displayName = 'SquareGlossyButton.lx-svg';

  private readonly width = 120;

  private readonly height = 95;

  private readonly innerWidth = 112;

  private readonly innerHeight = 87;

  private get glossyColorSet(): ISquareGlossyButtonColorSet {
    return SquareColorSets[this.getButtonColorSetName()];
  }

  private renderBox(fill: string, stroke: string, useThickStroke = false) {
    const strokeProps = useThickStroke
      ? {
          height: this.height,
          rx: '12',
          ry: '12',
          width: this.width,
          x: '0',
          y: '0'
        }
      : {
          height: this.innerHeight + 6,
          rx: '11',
          ry: '11',
          width: this.innerWidth + 6,
          x: '0.8',
          y: '0.5'
        };

    return (
      <>
        {/*
          Using a second Rect as a mock stroke as it was more straight-forward
          than the stroke itself
        */}
        <Rect {...strokeProps} fill={stroke} />
        <Rect
          x={(this.width - this.innerWidth) / 2}
          y={(this.height - this.innerHeight) / 2}
          rx="8"
          ry="8"
          width={this.innerWidth}
          height={this.innerHeight}
          fill={fill}
        />
      </>
    );
  }

  protected renderFlat() {
    const colorSet = this.getKeyNavColorSet();

    return (
      <Svg
        width={this.width}
        height={this.height}
        accessibilityLabel={this.getAccessibilityLabel()}
      >
        {this.renderBox(colorSet.fill, colorSet.stroke, true)}
        {this.renderIcon({ iconColor: colorSet.icon })}
      </Svg>
    );
  }

  protected renderGlossy() {
    return (
      <Svg
        width={this.width}
        height={this.height}
        accessibilityLabel={this.getAccessibilityLabel()}
      >
        <Defs>
          <RadialGradient
            id={`${this.id}_a`}
            cx=".5"
            cy=".5"
            r=".5"
            gradientUnits="objectBoundingBox"
          >
            <Stop offset="0" stopColor={this.glossyColorSet.stop1} />
            <Stop offset="1" stopColor={this.glossyColorSet.stop2} />
          </RadialGradient>
          <LinearGradient
            id={`${this.id}_b`}
            x1=".5"
            x2=".5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <Stop offset="0" stopColor={this.glossyColorSet.stop3} />
            <Stop offset="0.678" stopColor={this.glossyColorSet.stop3} />
            <Stop offset="1" stopColor={this.glossyColorSet.stop4} />
          </LinearGradient>
          <LinearGradient
            id={`${this.id}_c`}
            y1=".5"
            x2="1"
            y2=".5"
            gradientUnits="objectBoundingBox"
          >
            <Stop offset="0" stopColor={Color.NearWhite} stopOpacity=".7" />
            <Stop offset="1" stopColor={Color.NearWhite} stopOpacity="0" />
          </LinearGradient>
        </Defs>
        <Rect width={this.width} height={this.height} rx="12" opacity="0.16" />
        {this.renderBox(`url(#${this.id}_a)`, Color.NearWhite)}
        {/* Glossy shine */}
        <Rect
          x={(this.width - this.innerWidth) / 2}
          y={(this.height - this.innerHeight) / 2 + 33}
          rx="8"
          ry="8"
          width={this.innerWidth}
          height={this.innerHeight - 33}
          fill={`url(#${this.id}_b)`}
        />
        <Rect
          width={61}
          height={7}
          rx="3.5"
          transform="translate(8.6 6.3)"
          opacity=".5"
          fill={`url(#${this.id}_c)`}
        />
        {this.renderIcon({ shadowColor: this.glossyColorSet.stop5 })}
      </Svg>
    );
  }
}
