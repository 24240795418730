import { IUnit } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UnitAction,
  UnitActionLoadSuccess,
  UnitSelector
} from 'services/curriculum-services/unit';
import { PreparedScenes } from 'services/storm-lobo';
import { DevToolsActionUpdateHasOnscreenCharacter } from '../redux/DevTools.action';
import { DevToolsActionType } from '../redux/DevToolsActionType';

export interface IUpdateHasOnscreenCharacterDeps {
  preparedScenes: PreparedScenes;
}

export function updateHasOnscreenCharacterEpic(
  action$: Observable<DevToolsActionUpdateHasOnscreenCharacter>,
  state$: StateObservable<unknown>,
  deps: IUpdateHasOnscreenCharacterDeps
): Observable<UnitActionLoadSuccess> {
  return action$.pipe(
    ofType(DevToolsActionType.UpdateHasOnscreenCharacter),
    map(action => {
      const state = state$.value;
      const { preparedScenes } = deps;
      const { hasOnscreenCharacter } = action.payload;
      const parentUnits = UnitSelector.getAncestorsAndUnit(state);
      const activeUnit: IUnit = {
        ...parentUnits.pop()!,
        hasOnscreenCharacter
      };
      if (hasOnscreenCharacter) {
        preparedScenes.encounterOrPlacement?.attachCharacter();
      } else {
        preparedScenes.encounterOrPlacement?.detachCharacter();
      }

      return UnitAction.load.success({
        parentUnits,
        unitContent: activeUnit
      });
    })
  );
}
updateHasOnscreenCharacterEpic.displayName = 'updateHasOnscreenCharacterEpics';
