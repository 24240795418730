import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RouterService, RoutePath } from 'router-service';
import { LevelAction, LevelActionUnload } from '../../../level';
import {
  PositionActionLevelPositionUnloaded,
  PositionActionType
} from '../../redux';

/**
 * Unloads level in response to Level position unload action
 * We avoid unloading level position when completing a level as we will
 * soon replace with the level-up position
 * Beyond that, this is mainly (only?) relevant for educator mode and logout
 */
export function removeLevelPositionEpic(
  action$: Observable<PositionActionLevelPositionUnloaded>
): Observable<LevelActionUnload> {
  return action$.pipe(
    ofType(PositionActionType.LevelPositionUnloaded),
    filter(() => !RouterService.isOnRoute([RoutePath.LevelComplete])),
    map(() => LevelAction.unload.request())
  );
}
removeLevelPositionEpic.displayName = 'removeLevelPositionEpic';
