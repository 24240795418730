import {
  AvatarEditorAction,
  AvatarEditorActionTimerPause
} from '../AvatarEditor.action';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';

import {
  AppShellActionShowModal,
  AppShellActionType
} from 'feature-areas/shell';
import { AvatarTimerStatus } from '../avatar-editor-redux.model';
import { AvatarEditorSelector } from '../AvatarEditor.selector';
import { AppState } from 'services';

export function timerPauseEpic(
  action$: Observable<AppShellActionShowModal>,
  state$: StateObservable<AppState>
): Observable<AvatarEditorActionTimerPause> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal),
    filter(() => AvatarEditorSelector.getShouldShowEditor(state$.value)),
    filter(() =>
      [AvatarTimerStatus.Running].includes(
        AvatarEditorSelector.getTimerStatus(state$.value)
      )
    ),
    map(() => AvatarEditorAction.timerPause())
  );
}
timerPauseEpic.displayName = 'timerPauseEpic';
