// istanbul ignore file - static SVG, nothing to test
import { ITextButtonIconProps, Path, Svg } from 'common-ui';

export function ReloadSvg({ color }: ITextButtonIconProps) {
  return (
    <Svg
      data-test-id={ReloadSvg.displayName}
      height="22"
      viewBox="0 0 30 30"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M22.579 22.198a8.95 8.95 0 002.972-9.32 9.4 9.4 0 00-9.983-6.797"
      />
      <Path
        fill={color}
        d="M19.070743 7.459708l.063187 3.297583a.749.749 0 01-.751809.748166.725.725 0 01-.531946-.211384l-4.95592-4.616607a.72.72 0 01-.017175-1.038126L17.62614.832299a.758.758 0 011.29787.50214l.057857 3.210424"
      />
      <Path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M9.401 8.341a8.95 8.95 0 00-2.972 9.32 9.4 9.4 0 009.983 6.797"
      />
      <Path
        fill={color}
        d="M12.359648 23.019314l-.063186-3.297583a.749.749 0 01.751809-.748166.725.725 0 01.531946.211384l4.95592 4.616607a.72.72 0 01.017175 1.038126l-4.749061 4.807041a.758.758 0 01-1.29787-.50214l-.057856-3.210424"
      />
    </Svg>
  );
}
ReloadSvg.displayName = 'ReloadSvg';
