import { Position } from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';

export class RoundedTabSvgStyles {
  public static readonly build = memoizeOne((rotate: number) => ({
    svg: {
      left: 0,
      position: Position.Absolute,
      top: 0,
      transform: [{ rotate: `${rotate}deg` }]
    }
  }));
}
