import { findIndex, first, last, some } from 'lodash';
import * as React from 'react';
import { XYCoord } from 'react-dnd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ISfxProps, Sfx, withSfx } from 'audio';
import { CONSTANTS, Row, View, withPositionHandler } from 'common-ui';
import { DndDropTarget, DragItemType, DropTargetHelper } from 'dnd';
import { DndAction } from 'dnd/redux';
import { TaskSelector } from 'task-components';
import {
  ISegment,
  OrderingDimensions,
  TextSegment,
  TextSegmentDimension,
  withTapAndHear
} from 'task-components/shared';
import { TextSegmentAnimatedStyles } from 'task-components/shared/text-segment/TextSegment.animated-styles';
import { ThemeSize, withTheme } from 'theme';
import { IDimensions, ILineConfiguration, PositioningHelper } from '../helpers';
import { LineBackground } from '../line-background';
import { SegmentPositionTransition } from '../segment-position-transition';
import { HoverType, splitTargetHoverItem } from '../split-target-hover-item';
import {
  VerticalIndicator,
  VerticalIndicatorDimension,
  VerticalIndicatorPosition
} from '../vertical-indicator';
import { TextOrderingContentStyles } from './TextOrderingContent.styles';
import { Position } from '@lexialearning/common-ui';

export interface ITextOrderingContentProps extends ISfxProps {
  shouldShowCorrectFeedback: boolean;
  showSolution: boolean;
  segments: ISegment[];
  themeSize: ThemeSize;
  moveSegment(targetIndex: number, segmentIndex: number): void;
  dropToPosition(position: XYCoord | undefined): void;
}

export interface ITextOrderingContentState {
  lineConfigurations: ILineConfiguration[];
  showMergedSegments: boolean;
  segmentPositions: Map<string, XYCoord>;
  activeTargetIdx: number;
  activeSrcIdx: number;
  hoverType: HoverType;
  dropTargetIndex: number;
}

const MAX_LINE_WIDTH = 900;

export const TextOrderingDimension: { [key: string]: number } = {
  LineHorizontalPadding: (CONSTANTS.BaseDimensions.Width - MAX_LINE_WIDTH) / 2,
  LineVerticalPadding: 21,
  MaxLineWidth: MAX_LINE_WIDTH,
  PillPadding: TextSegmentDimension.HorizontalPadding,
  SegmentPadding: VerticalIndicatorDimension.Width / 2,
  SpaceWidth: 7
};

const SegmentsContainer = withPositionHandler(View);

const TextOrderingSegment = withTapAndHear(
  splitTargetHoverItem(DragItemType.TextSegment)(TextSegment)
);

const AdditionalTarget = DndDropTarget(
  DragItemType.TextSegment,
  DropTargetHelper.createSpec({ useSfx: false })
)(View);

export class TextOrderingContentComponent extends React.PureComponent<
  ITextOrderingContentProps,
  ITextOrderingContentState
> {
  public static readonly displayName = 'TextOrderingContent';

  private readonly positioningHelper: PositioningHelper;

  private readonly segmentDimensions = new Map<string, IDimensions>();

  private readonly pillWidths = new Map<string, number>();

  private showMergedTimeout?: NodeJS.Timeout;

  private segmentsContainerOffset: XYCoord = { x: 0, y: 0 };

  constructor(props: ITextOrderingContentProps) {
    super(props);

    this.calculateInteractivePhaseSegmentPositions =
      this.calculateInteractivePhaseSegmentPositions.bind(this);
    this.calculateFeedbackPhaseSegmentPositions =
      this.calculateFeedbackPhaseSegmentPositions.bind(this);
    this.getEndOfLineHoverHandler = this.getEndOfLineHoverHandler.bind(this);
    this.calculateDimensions = this.calculateDimensions.bind(this);
    this.handleBeginDrag = this.handleBeginDrag.bind(this);
    this.handleEndDrag = this.handleEndDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.resetDragState = this.resetDragState.bind(this);
    this.resetTargetState = this.resetTargetState.bind(this);
    this.handleSegmentsContainerPosition =
      this.handleSegmentsContainerPosition.bind(this);

    this.state = {
      activeSrcIdx: -1,
      activeTargetIdx: -1,
      dropTargetIndex: -1,

      hoverType: HoverType.None,
      lineConfigurations: [],
      segmentPositions: new Map<string, XYCoord>(),
      showMergedSegments: false
    };

    const { themeSize } = this.props;

    const segmentDimensions = this.calculateDimensions();
    const lineHeight =
      OrderingDimensions.textPillHeight(themeSize!) +
      TextOrderingDimension.LineVerticalPadding * 2;

    this.positioningHelper = new PositioningHelper(
      lineHeight,
      TextOrderingDimension.MaxLineWidth,
      segmentDimensions
    );
  }

  private calculateDimensions(): Map<string, IDimensions> {
    const { segments, themeSize } = this.props;
    const segmentHeight = OrderingDimensions.textPillHeight(themeSize!);

    segments.forEach((segment: ISegment) => {
      const textWidth = segment.content.widthByTheme[themeSize];
      const pillWidth = textWidth + TextOrderingDimension.PillPadding * 2;
      const segmentWidth = pillWidth + TextOrderingDimension.SegmentPadding * 2;

      this.segmentDimensions.set(segment.key, {
        height: segmentHeight,
        width: segmentWidth
      });

      this.pillWidths.set(segment.key, pillWidth);
    });

    return this.segmentDimensions;
  }

  private calculateInteractivePhaseSegmentPositions() {
    const { segments } = this.props;
    const segmentOrdering = segments.map(segment => segment.key);

    const { itemPositions: segmentPositions, lineConfigurations } =
      this.positioningHelper.calculatePositions(segmentOrdering);

    this.setState({ lineConfigurations, segmentPositions });
  }

  private calculateFeedbackPhaseSegmentPositions() {
    const { segments } = this.props;

    const segmentOrdering = segments.map(segment => segment.key);

    const totalPaddingBetweenText =
      TextOrderingDimension.SegmentPadding * 2 +
      TextOrderingDimension.PillPadding * 2;

    const squeezePillsBy =
      totalPaddingBetweenText - TextOrderingDimension.SpaceWidth;

    const { itemPositions: segmentPositions, lineConfigurations } =
      this.positioningHelper.calculateSqueezedPositions(
        segmentOrdering,
        squeezePillsBy
      );

    this.setState({
      lineConfigurations,
      segmentPositions,
      showMergedSegments: true
    });
  }

  public componentDidMount() {
    this.calculateInteractivePhaseSegmentPositions();
  }

  public componentDidUpdate(prevProps: ITextOrderingContentProps) {
    const { shouldShowCorrectFeedback, segments, showSolution } = this.props;

    if (prevProps.segments !== segments) {
      this.calculateInteractivePhaseSegmentPositions();
      this.dropToPosition();
    }

    if (
      (!prevProps.shouldShowCorrectFeedback && shouldShowCorrectFeedback) ||
      (!prevProps.showSolution && showSolution)
    ) {
      this.showMergedTimeout = setTimeout(
        this.calculateFeedbackPhaseSegmentPositions,
        TextSegmentAnimatedStyles.AnimationDuration * 0.25
      );
    }
  }

  public componentWillUnmount() {
    if (this.showMergedTimeout) {
      clearTimeout(this.showMergedTimeout);
    }
  }

  private handleHover(
    targetIndex: number,
    sourceIndex: number,
    hoverType: HoverType
  ) {
    this.setState({
      activeSrcIdx: sourceIndex,
      activeTargetIdx: targetIndex,
      hoverType
    });
  }

  private handleDrop() {
    const { moveSegment } = this.props;

    const {
      activeTargetIdx: targetIndex,
      activeSrcIdx: sourceIndex,
      hoverType
    } = this.state;

    const targetAdjust =
      (sourceIndex < targetIndex ? -1 : 0) +
      (hoverType === HoverType.Before ? 0 : 1);
    const resolvedTarget = targetIndex + targetAdjust;

    this.resetDragState(() => {
      this.setState({ dropTargetIndex: resolvedTarget });
      moveSegment(resolvedTarget, sourceIndex);
    });
  }

  private resetDragState(callback?: () => void) {
    this.setState(
      {
        activeSrcIdx: -1,
        activeTargetIdx: -1,
        hoverType: HoverType.None
      },
      callback
    );
  }

  private resetTargetState() {
    this.setState({
      activeTargetIdx: this.state.activeSrcIdx,
      hoverType: HoverType.None
    });
  }

  private handleBeginDrag(srcIdx: number) {
    const { playSfx } = this.props;

    playSfx(Sfx.DragBegin);
    this.setState({
      activeSrcIdx: srcIdx,
      activeTargetIdx: srcIdx
    });
  }

  private handleEndDrag() {
    const { activeTargetIdx, activeSrcIdx, hoverType } = this.state;
    const { playSfx } = this.props;
    const isSamePositionBefore =
      activeTargetIdx - 1 === activeSrcIdx && hoverType === HoverType.Before;
    const isSamePositionAfter =
      activeTargetIdx + 1 === activeSrcIdx && hoverType === HoverType.After;
    if (
      isSamePositionBefore ||
      isSamePositionAfter ||
      activeTargetIdx === activeSrcIdx ||
      activeTargetIdx === -1 ||
      activeSrcIdx === -1 ||
      hoverType === HoverType.None
    ) {
      playSfx(Sfx.CantDo);
      this.resetDragState(() => {
        this.dropToPosition(activeSrcIdx);
      });
    } else {
      this.handleDrop();
      playSfx(Sfx.DragEnd);
    }
  }

  private dropToPosition(dropTargetIndex = this.state.dropTargetIndex) {
    if (dropTargetIndex === -1) {
      return;
    }
    const { segments, dropToPosition } = this.props;
    const { segmentPositions } = this.state;
    const targetKey = segments[dropTargetIndex].key;
    const segmentPosition = segmentPositions.get(targetKey)!;
    const position = {
      x: segmentPosition.x + this.segmentsContainerOffset.x,
      y: segmentPosition.y + this.segmentsContainerOffset.y
    };
    dropToPosition(position);
    this.setState({ dropTargetIndex: -1 });
  }

  private isLastItemInLine(segmentKey: string) {
    const { lineConfigurations } = this.state;

    return some(lineConfigurations, line => last(line.items) === segmentKey);
  }

  private isFirstItemInLine(segmentKey: string) {
    const { lineConfigurations } = this.state;

    return some(lineConfigurations, line => first(line.items) === segmentKey);
  }

  private isIndicatorBeforeVisible(segmentIndex: number) {
    const { segments } = this.props;
    const { activeTargetIdx, hoverType } = this.state;

    const segmentKey = segments[segmentIndex].key;

    return (
      (activeTargetIdx === segmentIndex && hoverType === HoverType.Before) ||
      (activeTargetIdx === segmentIndex - 1 &&
        hoverType === HoverType.After &&
        !this.isFirstItemInLine(segmentKey))
    );
  }

  private isIndicatorAfterVisible(segmentIndex: number) {
    const { activeTargetIdx, hoverType } = this.state;

    return activeTargetIdx === segmentIndex && hoverType === HoverType.After;
  }

  private getFirstItemIndex(lineIndex: number) {
    const { lineConfigurations } = this.state;

    const firstItemKey = first(lineConfigurations[lineIndex].items);

    return findIndex(
      this.props.segments,
      segment => segment.key === firstItemKey
    );
  }

  private getLastItemIndex(lineIndex: number) {
    const { lineConfigurations } = this.state;

    const lastItemKey = last(lineConfigurations[lineIndex].items);

    return findIndex(
      this.props.segments,
      segment => segment.key === lastItemKey
    );
  }

  private getEndOfLineHoverHandler(hoverType: HoverType) {
    return (targetIndex: number, sourceIndex: number): void => {
      this.handleHover(targetIndex, sourceIndex, hoverType);
    };
  }

  private async handleSegmentsContainerPosition(position: XYCoord) {
    this.segmentsContainerOffset = position;
  }

  /**
   * Additional drop targets are added at the beginning and end of every line to account for item being
   * dragged above those positions - above the empty space that corresponds to padding between the edge
   * of the screen and the first/last item. If item is dragged at the beginning of line, it is treated
   * as it wants to drop before the first item in the line. If it is dragged at the end of line it is
   * treated as it wants to drop after the last item in the line.
   */
  private renderEndOfLineTargets() {
    const { lineConfigurations } = this.state;

    return lineConfigurations.map((lineConfiguration, lineIndex) => {
      const { layout: lineLayout } = lineConfiguration;

      /**
       * Width of additional targets is equal to the total amount of padding between the edge of the
       * screen and the beginning of the line, i.e. distance from the edge of the screen to lines
       * container (TextOrderingDimension.LineHorizontalPadding) + the distance between the edge of
       * lines container and the beginning of line (lineLayout.x).
       * Target at the beginning of each line and at the end of each line have the same width, bacause
       * lines are center aligned.
       */
      // Styles for target element
      const targetWidth =
        TextOrderingDimension.LineHorizontalPadding + lineLayout.x;
      const targetStyles = {
        height: lineLayout.height,
        width: targetWidth
      };
      // Override styles to pass to drop target hoc wrapper
      const targetWrapperStyle = {
        position: Position.Absolute,
        top: lineLayout.y
      };
      const targetWrapperHorizontalPosition =
        -TextOrderingDimension.LineHorizontalPadding;

      return (
        <React.Fragment key={lineIndex}>
          <AdditionalTarget
            index={this.getFirstItemIndex(lineIndex)}
            onHover={this.getEndOfLineHoverHandler(HoverType.Before)}
            style={targetStyles}
            dropTargetStyleOverride={{
              ...targetWrapperStyle,
              left: targetWrapperHorizontalPosition
            }}
          />
          <AdditionalTarget
            index={this.getLastItemIndex(lineIndex)}
            onHover={this.getEndOfLineHoverHandler(HoverType.After)}
            style={targetStyles}
            dropTargetStyleOverride={{
              ...targetWrapperStyle,
              right: targetWrapperHorizontalPosition
            }}
          />
        </React.Fragment>
      );
    });
  }

  private renderLineBackgrounds() {
    const { lineConfigurations, showMergedSegments } = this.state;

    return lineConfigurations.map((lineConfiguration, lineIndex) => (
      <LineBackground
        key={lineIndex}
        lineLayout={lineConfiguration.layout}
        showTextPill={showMergedSegments}
      />
    ));
  }

  private renderSegments() {
    const { shouldShowCorrectFeedback, segments, showSolution } = this.props;
    const { activeTargetIdx, segmentPositions } = this.state;

    return segments.map((segment, segmentIndex) => (
      <SegmentPositionTransition
        key={segment.key}
        segmentPosition={segmentPositions.get(segment.key)}
        showCorrect={shouldShowCorrectFeedback || showSolution}
      >
        <Row>
          <VerticalIndicator
            position={VerticalIndicatorPosition.Left}
            isVisible={this.isIndicatorBeforeVisible(segmentIndex)}
          />
          <TextOrderingSegment
            width={this.pillWidths.get(segment.key)}
            horizontalSpacing={TextOrderingDimension.SegmentPadding}
            index={segmentIndex}
            activeTargetIdx={activeTargetIdx}
            onHover={this.handleHover}
            onBeginDrag={this.handleBeginDrag}
            onEndDrag={this.handleEndDrag}
            showCorrect={shouldShowCorrectFeedback || showSolution}
            showPillOnCorrect={false}
            voiceover={segment.content.voiceover}
          >
            {segment.content.text}
          </TextOrderingSegment>
          {this.isLastItemInLine(segment.key) && (
            <VerticalIndicator
              position={VerticalIndicatorPosition.Right}
              isVisible={this.isIndicatorAfterVisible(segmentIndex)}
            />
          )}
        </Row>
      </SegmentPositionTransition>
    ));
  }

  public render() {
    const { lineConfigurations } = this.state;

    const styles = TextOrderingContentStyles.build(
      lineConfigurations,
      TextOrderingDimension.MaxLineWidth
    );

    return (
      <View style={styles.orderingContainer}>
        {/*
         * Additional target is added to act as cancellation zone that is placed in the background.
         * As soon as text segment that's being dragged is moved outside of the text area, this
         * target detects it and updates the state to remove visible vertical indicators.
         */}
        <AdditionalTarget
          style={styles.cancelZone}
          onHover={this.resetTargetState}
        />
        <View style={styles.linesContainer}>
          {this.renderLineBackgrounds()}
        </View>
        <SegmentsContainer
          style={styles.segmentsContainer}
          onPosition={this.handleSegmentsContainerPosition}
        >
          {this.renderSegments()}
        </SegmentsContainer>
        {this.renderEndOfLineTargets()}
      </View>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    showSolution: TaskSelector.getShowSolution(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  dropToPosition: (position: XYCoord | undefined) =>
    DndAction.dropToPosition({ position })
};

export const TextOrderingContent = compose(
  withSfx,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(TextOrderingContentComponent);
