import { Position } from '@lexialearning/common-ui';
import { Align, AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { CONSTANTS, Types } from 'common-ui';
import { ThemeType } from 'theme';

interface ITaskWorkAreaStyleProps {
  isEntryTask: boolean;
  themeType: ThemeType;
}

export interface ITaskWorkAreaAnimations {
  standardEntry: Types.Animated.CompositeAnimation;
  standardExit: Types.Animated.CompositeAnimation;
  instructionEntry: Types.Animated.CompositeAnimation;
  instructionExit: Types.Animated.CompositeAnimation;
}

export class TaskWorkAreaAnimatedStyles {
  public static readonly StandardEntryAnimationTiming = 250;
  private static readonly StandardExitAnimationTiming = 250;
  private static readonly InstructionEntryAnimationTiming = 750;
  private static readonly InstructionExitAnimationTiming = 2000;
  private static readonly InstructionEntryEasing =
    loboAnimated.Easing.CubicBezier(0.215, 0.61, 0.355, 1);
  private static readonly OffscreenTopValue = -2000;

  private readonly animatedValues: {
    opacity: Types.AnimatedValue;
    top: Types.AnimatedValue;
  };

  private readonly styles = {
    interactionPreventionScreen: {
      bottom: 0,
      left: 0,
      position: Position.Absolute,
      right: 0,
      top: 0
    }
  };

  private animations: ITaskWorkAreaAnimations;

  constructor(props: ITaskWorkAreaStyleProps) {
    this.animatedValues = this.createAnimatedValues(
      props.themeType,
      props.isEntryTask
    );

    this.animations = {
      instructionEntry: this.createInstructionEntryAnimation(),
      instructionExit: this.createInstructionExitAnimation(),
      standardEntry: this.createStandardRoundEntryAnimation(),
      standardExit: this.createStandardRoundExitAnimation()
    };
  }

  public resetAnimatedValues(props: ITaskWorkAreaStyleProps) {
    const initialValues = this.getInitialAnimatedValues(
      props.themeType,
      props.isEntryTask
    );
    this.animatedValues.opacity.setValue(initialValues.opacity);
    this.animatedValues.top.setValue(initialValues.top);
  }

  private getInitialAnimatedValues(
    themeType?: ThemeType,
    isEntryTask?: boolean
  ) {
    const opacity = themeType === ThemeType.Instruction ? 1 : 0;
    const top =
      themeType === ThemeType.Instruction && isEntryTask
        ? TaskWorkAreaAnimatedStyles.OffscreenTopValue
        : 0;

    return {
      opacity,
      top
    };
  }

  private createAnimatedValues(themeType?: ThemeType, isEntryTask?: boolean) {
    const initialValues = this.getInitialAnimatedValues(themeType, isEntryTask);

    return {
      opacity: loboAnimated.createValue(initialValues.opacity),
      top: loboAnimated.createValue(initialValues.top)
    };
  }

  private createInstructionEntryAnimation(): Types.Animated.CompositeAnimation {
    return loboAnimated.sequence([
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration:
          TaskWorkAreaAnimatedStyles.InstructionEntryAnimationTiming * 0.5,
        easing: TaskWorkAreaAnimatedStyles.InstructionEntryEasing,
        toValue: 50
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration:
          TaskWorkAreaAnimatedStyles.InstructionEntryAnimationTiming * 0.2,
        easing: TaskWorkAreaAnimatedStyles.InstructionEntryEasing,
        toValue: -25
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration:
          TaskWorkAreaAnimatedStyles.InstructionEntryAnimationTiming * 0.2,
        easing: TaskWorkAreaAnimatedStyles.InstructionEntryEasing,
        toValue: 10
      }),
      loboAnimated.timing(AnimatableCSSProperty.Top, this.animatedValues.top, {
        duration:
          TaskWorkAreaAnimatedStyles.InstructionEntryAnimationTiming * 0.1,
        easing: TaskWorkAreaAnimatedStyles.InstructionEntryEasing,
        toValue: 0
      })
    ]);
  }

  private createInstructionExitAnimation(): Types.Animated.CompositeAnimation {
    return loboAnimated.timing(
      AnimatableCSSProperty.Top,
      this.animatedValues.top,
      {
        duration: TaskWorkAreaAnimatedStyles.InstructionExitAnimationTiming,
        easing: loboAnimated.Easing.Out(),
        toValue: TaskWorkAreaAnimatedStyles.OffscreenTopValue
      }
    );
  }

  private createStandardRoundEntryAnimation(): Types.Animated.CompositeAnimation {
    return loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.opacity,
      {
        duration: TaskWorkAreaAnimatedStyles.StandardEntryAnimationTiming,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      }
    );
  }

  private createStandardRoundExitAnimation(): Types.Animated.CompositeAnimation {
    return loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.opacity,
      {
        duration: TaskWorkAreaAnimatedStyles.StandardExitAnimationTiming,
        easing: loboAnimated.Easing.In(),
        toValue: 0
      }
    );
  }

  public getAnimations() {
    return this.animations;
  }

  public get() {
    return {
      animatedContainer: {
        animated: {
          opacity: this.animatedValues.opacity,
          top: this.animatedValues.top
        },
        static: {
          alignSelf: Align.Center,
          flex: 1,
          position: Position.Relative,
          width: CONSTANTS.BaseDimensions.Width
        }
      },
      ...this.styles
    };
  }
}
