import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IScreenplay,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import {
  IUserChoice,
  UserChoiceSubunitLinker
} from '@lexialearning/lobo-common/tasks/user-choice';
import { Sfx } from 'audio';
import { ScreenplayBuilder } from 'screenplay';
import { RoundContext } from 'services/curriculum-services';
import {
  IPositionDeterminerFactoryOptions,
  ITaskRegistration,
  ScreenplayType,
  TaskRegistrationBuilder
} from '../core';
import { IUserChoiceAnswer } from './user-choice-component.model';
import { UserChoice } from './UserChoice';
import { UserChoicePositionDeterminer } from './UserChoicePositionDeterminer';
import { UserChoiceSkipPositionDeterminer } from './UserChoiceSkipPositionDeterminer';

export class UserChoiceRegistrationFactory {
  public static readonly displayName = 'UserChoiceRegistrationFactory';

  public static readonly SubunitType = LoboContentType.UserChoice;

  public static create(): ITaskRegistration<
    TaskTypeName.UserChoice,
    IUserChoice,
    IUserChoiceAnswer
  > {
    const base = TaskRegistrationBuilder.create<
      TaskTypeName.UserChoice,
      IUserChoice
    >(TaskTypeName.UserChoice, UserChoice)
      .withScreenplayBuilder(
        ScreenplayType.PostEntry,
        UserChoiceRegistrationFactory.buildPostEntryScreenplay
      )
      .withScreenplayBuilder(
        ScreenplayType.Exit,
        UserChoiceRegistrationFactory.buildExitScreenplay
      ).registration;
    const subunitLinker = new UserChoiceSubunitLinker();

    return {
      ...base,
      createPositionDeterminer: (
        context: RoundContext,
        options: IPositionDeterminerFactoryOptions
      ) =>
        options?.forSkipping
          ? new UserChoiceSkipPositionDeterminer(context)
          : new UserChoicePositionDeterminer(context),
      getForkSubunits: task => subunitLinker.getForkSubunits(task),
      serializeAnswer: UserChoiceRegistrationFactory.serializeAnswer
    };
  }

  private static serializeAnswer(answer: IUserChoiceAnswer): string {
    if (!answer.selectedChoices) {
      return 'no answer provided';
    }

    return answer.selectedChoices
      .map(
        c =>
          `${c.sysId}:${c.label}:${c.image ? c.image.imageSource : 'no image'}`
      )
      .join('|');
  }

  private static buildPostEntryScreenplay(
    roundContext: RoundContext
  ): IScreenplay {
    const userChoiceTask = roundContext.getTask<IUserChoice>();
    const builder = ScreenplayBuilder.create('UserChoiceCard Entries');
    const lastChoiceIndex = userChoiceTask.choices.length - 1;
    builder.addSfx(
      lastChoiceIndex === 2 ? Sfx.UserChoice3Cards : Sfx.UserChoice2Cards,
      { concurrent: true }
    );

    // by setting the last animation not concurrent, the card animations will happen
    // concurrently together, but synchronously with the other screenplays
    userChoiceTask.choices.forEach((_, idx) => {
      builder.addReactAnimation(`Card ${idx} Entry`, {
        concurrent: idx !== lastChoiceIndex
      });
    });

    return builder.screenplay;
  }

  private static buildExitScreenplay(roundContext: RoundContext): IScreenplay {
    const userChoiceTask = roundContext.getTask<IUserChoice>();
    const builder = ScreenplayBuilder.create('UserChoiceCard Exits');
    const lastChoiceIndex = userChoiceTask.choices.length - 1;
    // by setting the last animation not concurrent, the card animations will happen
    // concurrently together, but synchronously with the other screenplays
    userChoiceTask.choices.forEach((_, idx) => {
      builder.addReactAnimation(`Card ${idx} Exit`, {
        concurrent: idx !== lastChoiceIndex
      });
    });

    return ScreenplayBuilder.create('Exit.UserChoice').addScreenplay(
      builder.screenplay
    ).screenplay;
  }
}
