import * as React from 'react';
import { InteractionState } from 'common-styles';
import {
  IInteractiveContainerStylesOverride,
  InteractiveContainerStyles
} from './InteractiveContainer.styles';
import { Types } from 'common-ui/types';
import { PressableView } from '../view';
import { ActiveComponentState } from '@lexialearning/common-ui';

export interface IInteractiveContainerProps {
  children?: React.ReactNode;
  activeComponentState?: ActiveComponentState;
  interactionState?: InteractionState;
  onPress?(e?: Types.SyntheticEvent): void;
  styleOverride?: IInteractiveContainerStylesOverride;
}

export class InteractiveContainer extends React.PureComponent<IInteractiveContainerProps> {
  public static readonly displayName = 'InteractiveContainer';

  public render() {
    const {
      activeComponentState,
      children,
      interactionState,
      onPress,
      styleOverride
    } = this.props;
    const styles = InteractiveContainerStyles.build(
      activeComponentState || ActiveComponentState.Default,
      interactionState || InteractionState.Default,
      styleOverride
    );

    return (
      <PressableView
        onPress={onPress}
        style={styles.container}
        testId={InteractiveContainer.displayName}
      >
        {children}
      </PressableView>
    );
  }
}
