import { Color } from 'common-styles';
import {
  GlossyButtonColorSet,
  IRoundGlossyButtonColorSet,
  ISquareGlossyButtonColorSet
} from './glossy-button-svg.model';

export const SquareColorSets: { [id: string]: ISquareGlossyButtonColorSet } = {
  [GlossyButtonColorSet.Blue]: {
    stop1: '#6EC0FF',
    stop2: '#2D6CE0',
    stop3: '#2D6CE0',
    stop4: '#3B90FF',
    stop5: '#0F4CBE'
  },
  [GlossyButtonColorSet.BlueHovered]: {
    stop1: '#CCF0FE',
    stop2: '#4495FF',
    stop3: '#4495FF',
    stop4: '#84C0FE',
    stop5: '#2D6CE0'
  },
  [GlossyButtonColorSet.BluePressed]: {
    stop1: '#549EEB',
    stop2: '#0F4CBE',
    stop3: '#0F4CBE',
    stop4: '#3379E7',
    stop5: '#053CA5'
  },
  [GlossyButtonColorSet.Green]: {
    stop1: '#4ADE2B',
    stop2: '#1AC324',
    stop3: '#00A226',
    stop4: '#1AC324',
    stop5: '#227908'
  },
  [GlossyButtonColorSet.Red]: {
    stop1: '#FF7463',
    stop2: '#FF2730',
    stop3: '#CE1218',
    stop4: '#FF2730',
    stop5: '#B1131A'
  },
  [GlossyButtonColorSet.Yellow]: {
    stop1: Color.Yellow,
    stop2: '#FFBC00',
    stop3: '#FF9900',
    stop4: '#FBC720',
    stop5: '#E67F31'
  }
};

export const RoundColorSets: { [id: string]: IRoundGlossyButtonColorSet } = {
  [GlossyButtonColorSet.BlackGray]: {
    stop1: '#A2A2A2',
    stop2: '#556770'
  },
  [GlossyButtonColorSet.Blue]: {
    stop1: '#6EC0FF',
    stop2: '#2D6CE0'
  },
  [GlossyButtonColorSet.BlueHovered]: {
    stop1: '#CCF0FE',
    stop2: '#4495FF'
  },
  [GlossyButtonColorSet.BluePressed]: {
    stop1: '#6DBDFC',
    stop2: '#0F4CBE'
  },
  [GlossyButtonColorSet.Green]: {
    stop1: '#13CC1F',
    stop2: '#279A2F'
  },
  [GlossyButtonColorSet.Red]: {
    stop1: '#FFA47B',
    stop2: '#9F0005'
  },
  [GlossyButtonColorSet.Yellow]: {
    stop1: Color.Yellow,
    stop2: '#FF9900'
  }
};
