import { StateObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SreSessionOptions, SreSessionType } from 'sre';
import {
  SreCalibrationActionCalibrate,
  SreCalibrationActionType,
  SreSessionAction,
  SreSessionActionListen
} from '../redux';
import { ConfigSelector as UniConfigSelector } from '@lexialearning/utils-react';
import { SoundLogCollectionModeDeterminer } from './SoundLogCollectionModeDeterminer';
import { ILoboAppConfig } from 'services/app-config';
import { AppState } from 'services';

export function calibrationCalibrateEpic(
  action$: Observable<SreCalibrationActionCalibrate>,
  state$: StateObservable<AppState>
): Observable<SreSessionActionListen> {
  return action$.pipe(
    ofType(SreCalibrationActionType.Calibrate),
    map(() => {
      const sessionType = SreSessionType.Calibration;
      const sreAppConfig = UniConfigSelector.getConfig<ILoboAppConfig>(
        state$.value
      ).sre;
      const soundLogCollectionMode = SoundLogCollectionModeDeterminer.determine(
        sreAppConfig,
        sessionType
      );
      const sessionOptions: SreSessionOptions = {
        sessionType,
        soundLogCollectionMode
      };

      return SreSessionAction.listen(sessionOptions);
    })
  );
}
calibrationCalibrateEpic.displayName = 'calibrationCalibrateEpic';
