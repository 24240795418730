import { AnimatedRow, Column, Text, View, useDidMount } from 'common-ui';
import { AuthSelector } from 'services/auth';
import { DashboardAvatar } from './dashboard-avatar/DashboardAvatar';
import { FauxMicButton } from 'shared-components/faux-mic-button';
import { HomePageAnimatedStyles } from './HomePage.animated-styles';
import { HomeReactAnimationName } from './home-page.model';
import { Services } from 'services';
import { TransitionAction } from 'feature-areas/transitions/Transition.action';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useSpinner } from 'spinner-handler';
import { ProgramContextAction } from 'services/curriculum-services/program-context';
import { AvatarEditorSelector } from 'feature-areas/avatar-editor/redux';
import { useGetCumulativeWeeklyMinutes } from './useGetCumulativeWeeklyMinutes';

export function HomePage() {
  const animatedStyles = useRef(new HomePageAnimatedStyles());
  const animations = animatedStyles.current.getAnimations();
  const styles = animatedStyles.current.get();
  const dispatch = useDispatch();
  const transitionToNext = () => dispatch(TransitionAction.homeToNext());
  const fauxMicDisabled = useSelector(AvatarEditorSelector.getShouldShowEditor);
  const loginTime = useSelector(AuthSelector.getLoginTime);
  const { cumulativeWeeklyMinutes } = useGetCumulativeWeeklyMinutes({
    loginTime
  });
  const isPageReady = !!loginTime;

  useDidMount(() => {
    dispatch(ProgramContextAction.keepSessionAliveForced());
  });

  useEffect(() => {
    const { fadeIn, fadeOut, show } = animatedStyles.current.getAnimations();

    const unregisterAnimations =
      Services.reactAnimationScreenplayer.registerAnimations([
        { animation: fadeIn, name: HomeReactAnimationName.FadeIn },
        { animation: fadeOut, name: HomeReactAnimationName.FadeOut },
        { animation: show, name: HomeReactAnimationName.Show }
      ]);

    return () => {
      unregisterAnimations();
    };
  }, [animations]);

  const { shouldBlockRender } = useSpinner({
    componentDisplayName: HomePage.displayName,
    isPageReady
  });

  if (shouldBlockRender) {
    return null;
  }

  return (
    <AnimatedRow
      animatedStyle={styles.dashboardAnimated}
      style={styles.dashboard}
      testId="dashboard"
    >
      <Column style={styles.section}>
        <View style={styles.progressNumberContainer}>
          <Text style={styles.progressNumber} testId="minutesNumber">
            {cumulativeWeeklyMinutes}
          </Text>
        </View>
        <Text style={styles.progressText} testId="minutesText">
          Weekly minutes
        </Text>
      </Column>
      <View style={styles.separator} />
      <DashboardAvatar />
      <View style={styles.separator} />
      <Column style={styles.section}>
        <FauxMicButton
          disabled={fauxMicDisabled}
          onPress={transitionToNext}
          styleOverride={styles.mic}
        />
        <Text style={styles.sectionText}>Say "Let's go!"</Text>
      </Column>
    </AnimatedRow>
  );
}

HomePage.displayName = 'HomePage';
