import { camelCase } from 'lodash';
import * as React from 'react';
import { Text } from '../../text';
import { View } from '../../view';
import { withGlobalDisabled } from '../../withGlobalDisabled.hoc';
import {
  IPressScaleButtonProps,
  PressScaleButton
} from '../press-scale-button';
import {
  ITextButtonStyleOverride,
  TextButtonStyles,
  TextButtonType
} from './TextButton.styles';
import { useActiveComponentState } from '@lexialearning/common-ui';

export interface ITextButtonIconProps {
  color: string;
}

export interface ITextButtonProps extends IPressScaleButtonProps {
  Icon?: React.ElementType<ITextButtonIconProps>;
  buttonType?: TextButtonType;
  styleOverride?: ITextButtonStyleOverride;
  iconColor?: string;
  text: string;
}

export function TextButtonComponent(props: ITextButtonProps) {
  const {
    disabled,
    text,
    buttonType = TextButtonType.Secondary,
    Icon,
    iconColor,
    styleOverride,
    testId,
    ...restProps
  } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const styles = TextButtonStyles.build(
    activeComponentState,
    buttonType,
    !!disabled,
    !!Icon,
    styleOverride
  );

  const buttonTestId = testId || `${camelCase(text)}Button`;

  return (
    <PressScaleButton
      {...restProps}
      {...interactionHandlers}
      hoverScale={1.02}
      pressScale={0.98}
      disabled={disabled}
      style={styles.button}
      testId={buttonTestId}
    >
      {Icon && (
        <View style={styles.iconContainer}>
          <Icon color={iconColor || styles.buttonText.color!} />
        </View>
      )}
      <Text style={styles.buttonText}>{text}</Text>
    </PressScaleButton>
  );
}
TextButtonComponent.displayName = 'TextButtonComponent';

export const TextButton = withGlobalDisabled(
  TextButtonComponent
) as typeof TextButtonComponent;
