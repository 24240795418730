import { Action } from 'redux';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { PredicateUtils } from 'utils/PredicateUtils';
import { ScreenplayActionComplete, ScreenplayActionType } from '../redux';

export function playCompleteEpic(
  action$: Observable<ScreenplayActionComplete>
): Observable<Action> {
  return action$.pipe(
    ofType(ScreenplayActionType.PlayComplete),
    pluck('payload', 'nextAction'),
    filter(PredicateUtils.isDefined)
  ) as any;
}
playCompleteEpic.displayName = 'playCompleteEpic';
