import { LoboContentType } from '@lexialearning/lobo-common/cms';
import {
  IModeling,
  IModelingSection,
  IModelItem,
  IScreenplay,
  ITask,
  ModelSection,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { flatten, get } from 'lodash';
import { ScreenplayBuilder } from 'screenplay';
import { IModelingActionPayload, ModelingAction } from './Modeling.action';

export type TaskStringKeys<T> = (keyof T & string)[];
export type ModelSectionFields<T> = Map<ModelSection, TaskStringKeys<T>>;

export class ModelingHelper {
  public static readonly NoId = 'NO SYS ID';

  public static readonly autoplayChoiceTaskTypes = [
    TaskTypeName.SingleSelect,
    TaskTypeName.YesNo,
    TaskTypeName.MultiSelect,
    TaskTypeName.UserChoice
  ];

  public static createModelSectionFields<T>(): ModelSectionFields<T> {
    return new Map<ModelSection, TaskStringKeys<T>>();
  }

  public static createBuildModelingFunction<T extends ITask>(
    fields: ModelSectionFields<T>
  ): (content: T) => IModeling {
    return (content: T) => ({
      direction: {
        autoplay: false,
        items: ModelingHelper.buildModelItems(
          content,
          fields.get(ModelSection.Direction)!
        )
      },
      presentation: {
        autoplay:
          this.autoplayChoiceTaskTypes.includes(content.taskType) ||
          !!get(content, 'autoplayVoiceover'),
        items: ModelingHelper.buildModelItems(
          content,
          fields.get(ModelSection.Presentation)!
        )
      }
    });
  }

  private static buildModelItems(
    content: ITask,
    fields: string[]
  ): IModelItem[] {
    return flatten(
      fields.map(key => content[key]).map(f => this.buildModelItem(f))
    ).filter(m => !!m);
  }

  private static buildModelItem(item: any): IModelItem[] {
    if (Array.isArray(item)) {
      return flatten(item.map((m: any) => this.buildModelItem(m))).filter(
        (m: any) => !!m
      );
    }

    if (typeof item !== 'object') {
      return [];
    }

    if (item.voiceover) {
      return [
        {
          data: {},
          highlight: false,
          id: item.sysId || ModelingHelper.NoId,
          voiceover: item.voiceover
        }
      ];
    }

    if (this.isVoiceover(item)) {
      return [
        {
          data: {},
          highlight: false,
          id: item.id,
          voiceover: item
        }
      ];
    }

    if (item.card) {
      return this.buildModelItem(item.card);
    }

    return [];
  }

  private static isVoiceover(item: any): boolean {
    return item.id && item.actions[0].type === LoboContentType.Voiceover;
  }

  public static addReduxActions(modeling: IModeling): IModeling {
    return {
      ...modeling,
      direction: ModelingHelper.addActionsToSection(
        modeling.direction,
        ModelSection.Direction
      ),
      presentation: ModelingHelper.addActionsToSection(
        modeling.presentation,
        ModelSection.Presentation
      )
    };
  }

  private static addActionsToSection(
    section: IModelingSection,
    modelSection: ModelSection
  ): IModelingSection {
    return {
      ...section,
      items: section.items.map(item => {
        const payload: IModelingActionPayload = {
          modelItemId: item.id,
          modelSection
        };

        return {
          ...item,
          voiceover: {
            ...item.voiceover,
            actions: item.voiceover.actions.map(a => ({
              ...a,
              reduxActions: {
                after: ModelingAction.unhighlight(payload),
                before: ModelingAction.highlight(payload)
              }
            }))
          }
        };
      })
    };
  }

  public static buildScreenplay(
    modelingSection: IModelingSection
  ): IScreenplay {
    return ScreenplayBuilder.from(modelingSection.items.map(i => i.voiceover))
      .screenplay;
  }
}
