import { SceneControllerBase } from 'services/storm-lobo';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { Scene } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import { UnitCompleteSceneAnimationName } from './unit-complete-scene.model';

export class UnitCompleteScene extends SceneControllerBase {
  public static readonly displayName = 'UnitCompleteScene';

  public readonly RewardIdx: number;

  public readonly RewardName: string;

  constructor(scene: Scene) {
    super(scene, SceneZIndex.UnitComplete);
    this.init();

    const scenePath = scene.node.path;
    const pathSections = scenePath.split('/');

    // Rewards are numbered 01 - 20, and the first two chars in the path are the number
    this.RewardIdx = +pathSections[pathSections.length - 2] - 1;

    this.RewardName = pathSections[pathSections.length - 1].replace('.sg', '');
  }

  private init(): void {
    const request: ISceneAnimationRequest = {
      name: UnitCompleteSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }
}
