import { Identifier, TargetType } from 'dnd-core';
import * as React from 'react';
import { DndOptions, DropTarget } from 'react-dnd';
import { withSfx } from 'audio/sfx';
import {
  IDropTargetCollectedProps,
  IDropTargetCombinedProps,
  IDropTargetProps,
  IDropTargetPropsWithSfx
} from './drop-target.model';
import { DropTargetHelper } from './DropTarget.helper';

/**
 * Decorates a component to make it a drop target. Use the spec, collect, and
 * options parameters to override standard behavior.
 *
 * @see DropTargetHelper
 *
 * @param type - The type of component to associate with relevant drag sources
 * @param spec - Spec object with callbacks for drop, hover, and canDrop
 * @param collect - Props collector function
 * @param options - Dnd options
 */
export function DndDropTarget(
  type:
    | string
    | symbol
    | Identifier[]
    | ((props: IDropTargetProps) => TargetType),
  // istanbul ignore next - DND to be removed anyway
  spec = DropTargetHelper.createSpec(),
  collect = DropTargetHelper.collect,
  options?: DndOptions<IDropTargetProps>
) {
  return (WrappedComponent: any) => {
    const component = class extends React.Component<IDropTargetCombinedProps> {
      public static readonly displayName = `DndDropTargetComponent_${WrappedComponent.displayName}`;

      public componentDidUpdate(prevProps: IDropTargetCombinedProps) {
        if (spec.hoverBegin && !prevProps.isOver && this.props.isOver) {
          spec.hoverBegin!(this.props);
        }
      }

      public render() {
        return (
          <div
            ref={this.props.connectDropTarget}
            style={this.props.dropTargetStyleOverride}
            className="drop-target"
          >
            <WrappedComponent {...this.props} />
          </div>
        );
      }
    };

    const { hoverBegin, useSfx, ...dndSpec } = spec;

    return withSfx(
      DropTarget<IDropTargetPropsWithSfx, IDropTargetCollectedProps>(
        type,
        dndSpec,
        collect,
        options
      )(component)
    ) as typeof WrappedComponent;
  };
}
