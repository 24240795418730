import { CalibrationResult } from '@lexialearning/sre';
import { LanguageFrameSession, SreSessionOptions } from 'sre';

export enum ChoralScreenplayInfo {
  ScreenplayId = 'choralScreenplay',
  CountdownActionIndex = 0
}
export interface ISreSessionAnswer {
  result: LanguageFrameSession.IResult;
  options: SreSessionOptions;
  calibrationResult: CalibrationResult;
}
