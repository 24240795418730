export enum SreSessionActionType {
  Cancel = 'SRE_SESSION.CANCEL',
  CancelFailure = 'SRE_SESSION.CANCEL_FAILURE',
  CancelSuccess = 'SRE_SESSION.CANCEL_SUCCESS',
  Finish = 'SRE_SESSION.FINISH',
  FinishFailure = 'SRE_SESSION.FINISH_FAILURE',
  FinishSuccess = 'SRE_SESSION.FINISH_SUCCESS',
  Listen = 'SRE_SESSION.LISTEN',
  Listening = 'SRE_SESSION.LISTENING',
  ListenResult = 'SRE_SESSION.LISTEN_RESULT',
  Prime = 'SRE_SESSION.PRIME',
  PrimeFailure = 'SRE_SESSION.PRIME_FAILURE',
  PrimeSuccess = 'SRE_SESSION.PRIME_SUCCESS'
}

export enum SreCalibrationActionType {
  Calibrate = 'SRE_CALIBRATION.CALIBRATE',
  Calibrating = 'SRE_CALIBRATION.CALIBRATING',
  Complete = 'SRE_CALIBRATION.COMPLETE',
  SetCalibrationResult = 'SRE_CALIBRATION.SET_CALIBRATION_RESULT'
}

export enum SreConfigActionType {
  CheckMicPermission = 'SRE_CONFIG.CHECK_MIC_PERMISSION',
  Configure = 'SRE_CONFIG.CONFIGURE',
  ConfigureSuccess = 'SRE_CONFIG.CONFIGURE_SUCCESS',
  ConfigureFailure = 'SRE_CONFIG.CONFIGURE_FAILURE',
  ConfigureValidated = 'SRE_CONFIG.CONFIGURE_VALIDATED',
  OpenMicrophone = 'SRE_SESSION.OPEN_MICROPHONE',
  SelectMic = 'SRE_CONFIG.SELECT_MIC',
  SelectMicSuccess = 'SRE_CONFIG.SELECT_MIC_SUCCESS',
  SelectMicFailure = 'SRE_CONFIG.SELECT_MIC_FAILURE',
  SetMicsAvailable = 'SRE_CONFIG.SET_MICS_AVAILABLE',
  SetMicBlocked = 'SRE_CONFIG.SET_MIC_BLOCKED'
}
