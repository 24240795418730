import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { Path, Svg } from 'common-ui';

interface ITagSvgProps {
  stringColor: string;
}

const styles = { left: 68, position: Position.Absolute, top: -228 };

export function TagSvg({ stringColor }: ITagSvgProps) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="238"
      viewBox="0 0 94 238"
      style={styles}
    >
      <Path
        d="M49 204V0"
        fill="none"
        stroke={Color.Black}
        strokeWidth="3"
        opacity="0.11"
      />
      <Path
        d="M0 238.9v-1c10.7-3.12 15.23-10.25 19.62-17.15 2.76-4.35 5.37-8.45 9.4-11.57a22.61 22.61 0 017.24-3.75A34.24 34.24 0 0146.61 204h.78a34.23 34.23 0 0110.36 1.42 22.61 22.61 0 017.24 3.75c4 3.12 6.63 7.23 9.4 11.57 4.38 6.9 8.91 14 19.61 17.15v1zM47.13 210a5.97 5.97 0 10.06 0z"
        fill={Color.NearWhite}
      />
      <Path
        d="M51 203l-3 8zm-5.55.05l1.1 7.92zm0 0l1.1 7.92zM47.2 0v203.99z"
        fill={stringColor}
        stroke={stringColor}
        strokeWidth="3"
      />
    </Svg>
  );
}

TagSvg.displayName = 'TagSvg';
