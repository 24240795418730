import {
  ISreAppConfig,
  SoundLogCollection,
  SreSessionType
} from '@lexialearning/lobo-common/main-model/sre';

export interface ISoundLogTaskContext {
  isChoral: boolean;
}

export class SoundLogCollectionModeDeterminer {
  public static readonly displayName = 'SoundLogCollectionModeDeterminer';

  /**
   * Return the sound log collection mode based on the first applicable sound
   * log collection policy returned from Sound Log Collection service. Otherwise,
   * return the given default mode.
   *
   * Used to collect lossless audio (SoundLogCollection.FullLossless) some
   * percentage of the time to support SRE rubric training and evaluation
   * data science efforts.
   *
   * Intended to support multiple policies, but initial release will only use
   * one.
   *
   * @see loadSoundLogCollectionPoliciesEpic
   * @see LanguageFrameListener
   */
  public static determine(
    sreAppConfig: ISreAppConfig,
    sreSessionType: SreSessionType,
    shouldReturnDefault?: boolean
  ): SoundLogCollection {
    const { soundLogCollectionMode: defaultMode, soundLogCollectionPolicies } =
      sreAppConfig;

    if (shouldReturnDefault) {
      return defaultMode;
    }

    const randomProbability = Math.random() * 100;

    return (
      soundLogCollectionPolicies.find(
        p =>
          p.sreSessionType === sreSessionType && p.percent >= randomProbability
      )?.mode ?? defaultMode
    );
  }
}
