import memoizeOne from 'memoize-one';
import { Color, Font } from 'common-styles';

interface IModalHeaderStyleProps {
  hasCloseButton: boolean;
}

export class ModalHeaderStyles {
  public static readonly build = memoizeOne(
    ({ hasCloseButton }: IModalHeaderStyleProps) => ({
      modalHeader: {
        backgroundColor: Color.Gold,
        height: 60,
        paddingHorizontal: hasCloseButton ? 60 : 20
      },
      modalTitle: {
        color: Color.Black,
        fontFamily: Font.Family.semiBold,
        fontSize: Font.Size.large
      }
    })
  );
}
