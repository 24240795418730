import { useDispatch } from 'react-redux';
import { NavigationButtonType } from 'feature-areas/shell';
import { RoutePath, RouterService } from 'router-service';
import { NavigationButton } from 'feature-areas/shell/utility-bar/navigation-button/NavigationButton';
import { AvatarEditorEvent } from 'logging';
import { AvatarEditorAction } from '../redux';
import { useCallback } from 'react';

export interface IAvatarEditorNavigationButtonProps {
  disabled: boolean;
}
export function AvatarEditorNavigationButton({
  disabled
}: IAvatarEditorNavigationButtonProps) {
  const navigationButtonType = RouterService.isOnRoute(RoutePath.AvatarEditor)
    ? NavigationButtonType.Logout
    : NavigationButtonType.Back;

  const dispatch = useDispatch();
  const handlePressNavButton = useCallback(() => {
    if (navigationButtonType === NavigationButtonType.Logout) {
      dispatch(
        AvatarEditorAction.addUserEvent({
          eventName: AvatarEditorEvent.LogoutButtonPressed
        })
      );
    } else {
      dispatch(
        AvatarEditorAction.addUserEvent({
          eventName: AvatarEditorEvent.BackButtonPressed
        })
      );
      dispatch(AvatarEditorAction.saveAndExit());
    }
  }, [dispatch, navigationButtonType]);

  return (
    <NavigationButton
      disabled={disabled}
      navigationButtonType={navigationButtonType}
      onPress={handlePressNavButton}
    />
  );
}
AvatarEditorNavigationButton.displayName = 'AvatarEditorNavigationButton';
