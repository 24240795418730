// istanbul ignore file - code generated by svgr
import { Ellipse, G, Path, Svg } from 'common-ui';
import { IUserInfoButtonIconProps } from './user-info-button-icon.model';

export function PlacementIconSvg({ colorSet }: IUserInfoButtonIconProps) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={26.379}
      accessibilityLabel="Placement, user information."
      data-test-id={PlacementIconSvg.displayName}
    >
      <G transform="translate(-847 -63)" fill={colorSet.iconColor}>
        <Ellipse
          cx="8.5"
          cy="2.5"
          rx="8.5"
          ry="2.5"
          transform="translate(848 84.379)"
          opacity=".5"
        />
        <Path d="M856.5 87l-3.248-5.569a9.477 9.477 0 01-4.493-3.423 9.433 9.433 0 01-1.758-5.506 9.511 9.511 0 019.5-9.5 9.51 9.51 0 019.5 9.5 9.437 9.437 0 01-1.758 5.507 9.47 9.47 0 01-4.493 3.423l-3.248 5.569zm0-19a4.505 4.505 0 00-4.5 4.5 4.505 4.505 0 004.5 4.5 4.505 4.505 0 004.5-4.5 4.505 4.505 0 00-4.5-4.5z" />
      </G>
    </Svg>
  );
}
PlacementIconSvg.displayName = 'PlacementIconSvg';
