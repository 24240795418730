import { IScreenplay } from '@lexialearning/lobo-common';
import { RouterService, RouteBuilder } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { HomeOutroScreenplayBuilder } from '../builders/home';
import {
  ILevelIntroSceneScreenplayDeps,
  LevelIntroSceneScreenplayBuilder
} from '../builders/levels';
import { TransitionScreenplayId } from '../transition.model';

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Home+to+Level+Transition
 */
export class HomeToLevelIntroTransitionScreenplayFactory {
  public static readonly displayName = 'HomeToLevelTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: ILevelIntroSceneScreenplayDeps
  ): HomeToLevelIntroTransitionScreenplayFactory {
    return new HomeToLevelIntroTransitionScreenplayFactory(deps);
  }

  private constructor(deps: ILevelIntroSceneScreenplayDeps) {
    const { preparedScenes, welcomeMessageVo } = deps;

    this.screenplay = ScreenplayBuilder.create(
      TransitionScreenplayId.HomeToLevel
    )
      .addScreenplay(HomeOutroScreenplayBuilder.create().screenplay)
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.levelIntro());
      })
      .addScreenplay(
        LevelIntroSceneScreenplayBuilder.createFor({
          preparedScenes,
          welcomeMessageVo
        }).screenplay
      ).screenplay;
  }
}
