import { CommonUIBootstrapper, IProductTheme } from '@lexialearning/common-ui';
import { DynamicNotificationsBootstrapper } from '@lexialearning/dynamic-notifications';
import {
  BootstrappingOrchestrator,
  ConfigSelector as UniConfigSelector
} from '@lexialearning/utils-react';
import { Store } from '@reduxjs/toolkit';
import { LoboTheme } from 'app/Lobo.theme';
import { DeployConfig } from 'services/app-config/DeployConfig';
import { ILoboAppConfig } from 'services/app-config/lobo-config.model';
import { Version } from 'services/app-config/Version';
import { PerformanceUtils } from 'utils';
import { SystemInfo } from 'utils/SystemInfo';
import fatal_errorPng from '../../feature-areas/errors/dynamic-notification/fatal_error.png';
import c_generic_errorPng from '../../feature-areas/errors/generic/c_generic_error.png';
import { AppBootstrapper } from './App.bootstrapper';
import { reduxLogger } from './reduxLogger';
import { YellowBoxFacade } from './YellowBox';
import { StudentApiBootstrapper } from '@lexialearning/student-api';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import { appConfig } from 'services/app-config/appConfig';

YellowBoxFacade.ignoreWarnings([
  // TODO Should no longer be needed after upgrading RN
  " AsyncStorage has been extracted from react-native core and will be removed in a future release. It can now be installed and imported from '@react-native-community/async-storage' instead of 'react-native'. See https://github.com/react-native-community/async-storage"
]);

export class AppBootstrapping {
  public static readonly displayName = 'AppBootstrapping';

  public static buildConditionalMiddlewares(): any[] {
    const middlewares: any[] = [];

    // istanbul ignore next
    if (appConfig.isDebug) {
      // measures time spent in epic middleware and the store
      middlewares.push(PerformanceUtils.createMonitorMiddleware('epic+store'));

      // istanbul ignore next
      if (!SystemInfo.isNative) {
        middlewares.push(reduxLogger);
      }

      // measures time spent in the store, after all middlewares -
      // in reducer and alerting store change listeners
      middlewares.push(PerformanceUtils.createMonitorMiddleware('store'));
    }

    // TODO we should hopefully not need this once using inhouse logrocket
    // it depends whether we can serve the logroget etc all from our own
    // servers
    // For being able to add middleware dynamically post-bootstrapping
    // currently used for Logrocket
    // https://github.com/pofigizm/redux-dynamic-middlewares
    middlewares.push(dynamicMiddlewares);

    return middlewares;
  }

  /**
   * TODO:
   * Strategy to combine commonUI (Uni) and commonUi (Lobo)
   * Update README files as needed.
   * Remove @reduxjs/toolkit from package.json once the RTK bootstrap is gone. It may become a peerDependency of Uni in the future.
   *
   * QUESTIONS:
   * Does the order of the bootstrappers matter, ever? yes it can
   * What about CUI bootstrapper theme, it is currently required prop, should it be? Lobo also has a theme in redux.
   */

  public static bootstrap(): AppBootstrapping {
    /* eslint-disable no-console */
    console.log('Lobo version', Version.All);
    console.log('Deployment configuration', DeployConfig);
    /* eslint-enable no-console */

    // get config from redux to cater to overrides e.g. qstr params
    const getConfig = (): ILoboAppConfig =>
      UniConfigSelector.getConfig<ILoboAppConfig>(
        orchestrator.store.getState()
      );

    /*
    TODO: CUI bootstrapper
    • Theme is a required prop of type ProductTheme, differs from Lobo's theme and should be optional in Uni.
    */
    const commonUIBootstrapper = new CommonUIBootstrapper({
      genericErrorModal: {
        imageUrl: c_generic_errorPng,
        title: 'Whoopsie Daisy!'
      },
      getConfig: () => getConfig().commonUi,
      theme: LoboTheme as IProductTheme
    });

    const notificationsBootstrapper = new DynamicNotificationsBootstrapper({
      errorModalImageUrl: fatal_errorPng,
      getConfig: () => getConfig().dynamicNotifications
    });

    const appBootstrapper = new AppBootstrapper();
    const { logger } = appBootstrapper.getDependencies();

    const studentApiBootstrapper = new StudentApiBootstrapper({
      getConfig: () => getConfig().studentApi
    });

    const orchestrator = BootstrappingOrchestrator.bootstrap({
      appVersion: Version.Main,
      bootstrappers: [
        appBootstrapper,
        commonUIBootstrapper,
        notificationsBootstrapper,
        studentApiBootstrapper
      ],
      defaultConfig: appConfig,
      deployEnvironment: DeployConfig.deployEnvironment,
      errorHandling: {
        handleNativeUnhandledPromiseRejections: true
      },
      getConfig: () => getConfig().bootstrappingOrchestrator,
      logger,

      redux: {
        middleware: {
          append: AppBootstrapping.buildConditionalMiddlewares(),
          immutableCheck: false,
          serializableCheck: false
        },
        store: {
          devTools: appConfig.isDebug
        }
      }
    });

    return new AppBootstrapping(orchestrator.store);
  }

  private constructor(public readonly store: Store) {}
}
