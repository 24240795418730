import { IAct } from '@lexialearning/lobo-common';
import { IActProgress } from '@lexialearning/lobo-common/main-model/student';
import { createSelector } from '@reduxjs/toolkit';
import { LevelSelector } from '../../../level';
import { ProgramContextSelector } from '../ProgramContext.selector';
import { ActProgressFactory } from './ActProgressFactory';

export class ProgressSelector {
  public static readonly displayName = 'ProgressSelector';

  public static getProgress: (state: unknown) => IActProgress[];
  public static getProgressMaybe: (
    state: unknown
  ) => IActProgress[] | undefined;

  public static createSelectors(): void {
    this.getProgress = createSelector(
      state => LevelSelector.getActs(state),
      state => state,
      (acts: IAct[], state) =>
        acts.map(act =>
          ActProgressFactory.create(
            act,
            ProgramContextSelector.getActivityPosition(state, act.sysId)
          )
        )
    );

    this.getProgressMaybe = createSelector(
      state => state,
      state => {
        try {
          return this.getProgress(state);
        } catch (_e) {
          return undefined;
        }
      }
    );
  }
}
