import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements } from 'rxjs/operators';
import {
  AppShellActionShowModal,
  AppShellActionType
} from 'feature-areas/shell';
import { StudentInfoModal } from 'feature-areas/student-info';
import { IStudentInfoModalEventPayload, LoboLogItemCategory } from 'logging';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logStudentInfoModalEpic(
  action$: Observable<AppShellActionShowModal>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal),
    filter(a => a.payload.id === StudentInfoModal.ModalId),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.StudentInfoModal),
    ignoreElements()
  );
}
logStudentInfoModalEpic.displayName = 'logStudentInfoModalEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<IStudentInfoModalEventPayload, LoboLogItemCategory> {
  const context = helper.getContext(state);
  const payload: IStudentInfoModalEventPayload = { context };

  return {
    category: LoboLogItemCategory.StudentInfoModal,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: `Accessed Student Info from ${context}`
  };
}
