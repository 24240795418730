import {
  CharacterSetNumber,
  IAct,
  IAnimationDefinition
} from '@lexialearning/lobo-common';
import { capitalize } from 'lodash';
import { StormAssets } from 'services/storm-lobo';
import { CharacterName, SceneName } from 'services/storm-lobo/StormAssets';
import { AnimationDefinitionFactory } from 'storm/animations';
import { ISceneDefinition, SceneDefinitionBuilder } from 'storm/scenes';
import {
  LevelSceneAnimationName,
  LevelSceneElementName,
  LevelSceneMaterialName
} from './level-scene.model';
import { LevelIdleVariantsCount, LevelScene } from './LevelScene';

export class LevelSceneDefinitionBuilder {
  public static create(
    levelTitle: string,
    incompleteActs: IAct[]
  ): LevelSceneDefinitionBuilder {
    // Better as a private static? In the constructor?
    const materials = LevelSceneMaterialName.buildAllSwatchMats().map(
      (name, idx) => ({
        name,
        texturePaths: [`/scenes/images/level/swatch_${idx + 1}.tx`]
      })
    );

    const numChars = incompleteActs.length;
    const levelPath = `/scenes/level/level_root_${numChars}.sg`;

    const { definition } = SceneDefinitionBuilder.createRoot(
      SceneName.Level,
      levelPath,
      {
        materials
      }
    );

    return new LevelSceneDefinitionBuilder(
      definition,
      levelTitle,
      incompleteActs
    );
  }

  private constructor(
    public readonly definition: ISceneDefinition,
    private readonly levelTitle: string,
    private readonly incompleteActs: IAct[]
  ) {}

  public withBackground(name: any): LevelSceneDefinitionBuilder {
    const background = SceneDefinitionBuilder.create(
      LevelSceneElementName.buildBackground(1),
      `${StormAssets.Backgrounds}/level/${name}.sg`
    ).definition;
    this.definition.elements.push(background);

    return this;
  }

  public withBackgroundOverlay(): LevelSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      LevelSceneElementName.BackgroundOverlay,
      StormAssets.BackgroundOverlays.Stars
    ).definition;
    this.definition.elements.push(def);

    return this;
  }

  public withLighting(): LevelSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      LevelSceneElementName.Lighting,
      `/scenes/level/lights/level_${this.levelTitle}_lights.sg`
    ).definition;
    this.definition.elements.push(def);

    return this;
  }

  public withCharacters(): LevelSceneDefinitionBuilder {
    const characters = this.incompleteActs.map((a, idx) => {
      const charName = capitalize(a.character.code);
      const charPathGroup =
        StormAssets.Character[charName] || StormAssets.Character.Placeholder;
      const scenePath = charPathGroup.get(a.characterSetNumber);
      const elementName = LevelSceneElementName.buildCharacter(idx + 1);
      const animations = [...this.buildCharacterAnimations(elementName)];

      return SceneDefinitionBuilder.create(elementName, scenePath, {
        animations
      }).definition;
    });
    this.definition.elements.push(...characters);

    return this;
  }

  private buildCharacterAnimations(
    placeholder: string
  ): IAnimationDefinition[] {
    // first add all idle animations
    const idleVariants = Array(LevelIdleVariantsCount)
      .fill(0)
      .map((_, i) => i + 1);
    const characterAnimations = idleVariants.map(i => {
      const idleAnimName = LevelSceneAnimationName.Character.buildIdle(i);

      return AnimationDefinitionFactory.create(idleAnimName, {
        autoplay: true,
        loop: true,
        path: `/scenes/character-animations/${idleAnimName}.sga`,
        targetElements: [placeholder]
      });
    });

    characterAnimations.push(
      AnimationDefinitionFactory.create(
        LevelSceneAnimationName.Character.IntroWave,
        {
          path: `/scenes/character-animations/${LevelSceneAnimationName.Character.IntroWave}.sga`,
          targetElements: [placeholder]
        }
      )
    );

    return characterAnimations;
  }

  public withActMeters(): LevelSceneDefinitionBuilder {
    const meters = this.incompleteActs.map(
      (_, idx) =>
        SceneDefinitionBuilder.create(
          LevelSceneElementName.buildActMeter(idx + 1),
          '/scenes/level/act-meter/foot_meter.sg'
        ).definition
    );
    this.definition.elements.push(...meters);

    return this;
  }

  public withCharacterFunFactEffect(): LevelSceneDefinitionBuilder {
    const def = SceneDefinitionBuilder.create(
      LevelSceneElementName.Effects,
      '/scenes/level/effects/funfact_highlight.sg'
    ).definition;
    this.definition.elements.push(def);

    return this;
  }

  public withShowcase(): LevelSceneDefinitionBuilder {
    if (this.incompleteActs.length) {
      const texturePaths = this.incompleteActs
        .reduce(
          (acc, a) => [...acc, ...a.encounters.map(e => e.showcaseTexturePath)],
          [] as string[]
        )
        .map(p => LevelScene.buildShowcaseTexturePath(p));

      const materials = LevelSceneMaterialName.buildAllEncounterMats().map(
        name => ({
          name,
          texturePaths
        })
      );

      const def = SceneDefinitionBuilder.create(
        LevelSceneElementName.Showcase,
        '/scenes/level/showcase/showcase_bubbles.sg',
        {
          materials
        }
      ).definition;
      this.definition.elements.push(def);
    }

    return this;
  }

  public withCompletedCharacter(
    charName: CharacterName,
    characterSetNumber: CharacterSetNumber
  ): LevelSceneDefinitionBuilder {
    const animations = [
      ...StormAssets.Level.CharacterAnimations,
      ...this.buildCelebrationAnimations(
        LevelSceneElementName.CharacterComplete
      )
    ];
    const charPathGroup =
      StormAssets.Character[charName] || StormAssets.Character.Placeholder;
    const scenePath = charPathGroup.get(characterSetNumber);
    const def = SceneDefinitionBuilder.create(
      LevelSceneElementName.CharacterComplete,
      scenePath,
      { animations }
    ).definition;
    this.definition.elements.push(def);

    return this;
  }

  private buildCelebrationAnimations(
    placeholder: string
  ): IAnimationDefinition[] {
    const definitions =
      LevelSceneAnimationName.CharacterComplete.Celebrations.map(name =>
        AnimationDefinitionFactory.create(name, {
          autoplay: true,
          loop: false,
          path: `/scenes/character-animations/${name}.sga`,
          targetElements: [placeholder]
        })
      );

    return definitions;
  }
}
