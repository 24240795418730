import { Border, FontSize, Overflow, Position } from '@lexialearning/common-ui';
import { Color, Font, Justify, ZIndex } from 'common-styles';
import { HomePageAnimatedStyles } from '../HomePage.animated-styles';
import { SystemInfo } from 'utils';

export class DashboardAvatarStyles {
  public static readonly avatarSize = 140;
  public static readonly editButtonBottomOffset = 5;
  public static readonly nameHeight = 30;
  private static readonly editButtonSize = 30;

  private static readonly styles: any = {
    avatarOverride: {
      borderRadius: this.avatarSize / 2,
      height: this.avatarSize,
      width: this.avatarSize
    },
    editButtonOverrides: {
      position: Position.Absolute,
      bottom: -this.editButtonBottomOffset,
      right: -(this.avatarSize / 2),
      width: this.avatarSize,
      height: this.avatarSize + this.editButtonBottomOffset,
      padding: 0,
      paddingLeft: this.avatarSize - this.editButtonSize,
      paddingTop:
        this.avatarSize - this.editButtonSize + this.editButtonBottomOffset
    },
    dashboardBumpOutContainer: {
      borderBottomWidth: 1,
      borderColor: HomePageAnimatedStyles.dashboardBackgroundColor,
      borderStyle: Border.Solid,
      bottom: SystemInfo.isNative
        ? // istanbul ignore next - can't test without moving it down into the build method. not worth it.
          150.5
        : 149.5,
      height: 42,
      justifyContent: Justify.Start,
      overflow: Overflow.Hidden,
      position: Position.Absolute,
      width: 145
    },
    dashboardBumpOut: {
      backgroundColor: HomePageAnimatedStyles.dashboardBackgroundColor,
      borderColor: Color.NearWhite,
      borderRadius: 85,
      borderWidth: 2,
      height: 180,
      width: 170
    },
    hitBox: {
      borderColor: Color.Red,
      borderWidth: 1,
      bottom: 28,
      height: 140,
      position: Position.Absolute,
      width: 138
    },
    toolTipBackgroundOverlay: {
      backgroundColor: Color.BlackTransparent30,
      bottom: -750,
      height: 1500,
      position: Position.Absolute,
      right: -750,
      width: 1600
    },
    userDisplayName: {
      fontFamily: Font.Family.semiBold,
      fontSize: FontSize.Large,
      height: this.nameHeight,
      top: 5
    }
  };

  public static build(isToolTipDialogShowing: boolean) {
    return {
      ...this.styles,
      container: {
        bottom: 20,
        flex: 1,
        justifyContent: Justify.Start,
        zIndex: isToolTipDialogShowing ? ZIndex.Shared : undefined
      },
      toolTipZIndex: {
        zIndex: isToolTipDialogShowing ? ZIndex.Shared : undefined
      }
    };
  }
}
