import { IVideoPlayerProps, Video } from 'common-ui/components/video';
import { useState } from 'react';
import { VideoPlayerHelper } from './VideoPlayer.helper';
import { VideoPlayerStyles } from './VideoPlayer.styles';
import {
  Column,
  FadeAnimation,
  GlossyButton,
  GlossyButtonIconType,
  Row,
  Text,
  UserControlButtonSize,
  View,
  useSizing
} from 'common-ui';

export interface IVideoPlayerComponentProps
  extends Omit<IVideoPlayerProps, 'source'> {
  controlsFadeoutDelay?: number;
  playlist: string[];
  onPlaylistEnd?: () => void;
}

export function VideoPlayer(
  props: IVideoPlayerComponentProps
): React.ReactElement {
  const FadeDurationMS = 500;

  const { playlist, onPlaylistEnd } = props;
  const [currentVideo, setCurrentVideo] = useState<string>(playlist[0]);
  const [hasVideoEnded, setHasVideoEnded] = useState<boolean>(false);
  const [shouldShowVideo, setShouldShowVideo] = useState<boolean>(true);

  function toggleShouldShowVideo(): void {
    setShouldShowVideo(current => !current);
    setTimeout(() => {
      setShouldShowVideo(current => !current);
    }, FadeDurationMS);
  }

  function handlePressReplay(): void {
    setHasVideoEnded(false);
    toggleShouldShowVideo();
  }

  function handlePressContinue(): void {
    const isLastVideo = currentVideo === playlist[playlist.length - 1];
    if (isLastVideo) {
      !!onPlaylistEnd && onPlaylistEnd();
    } else {
      const nextVideo = playlist[playlist.indexOf(currentVideo) + 1];
      setCurrentVideo(nextVideo);
      setHasVideoEnded(false);
      toggleShouldShowVideo();
    }
  }

  const modifiedProps = VideoPlayerHelper.getVideoConfigAndControls({
    ...props,
    bigPlayButton: false,
    captionsBlocked: true,
    onEnded: () => {
      setHasVideoEnded(true);
    },
    source: currentVideo
  });

  const { appSize } = useSizing();

  const styles = VideoPlayerStyles.build(appSize);

  return (
    <Column style={styles.appSize} testId={VideoPlayer.displayName}>
      {shouldShowVideo && (
        <FadeAnimation duration={FadeDurationMS} shouldShow={shouldShowVideo}>
          <View style={styles.appSize}>
            <Video {...modifiedProps} />
          </View>
        </FadeAnimation>
      )}
      {hasVideoEnded && (
        <View style={styles.buttonOverlay}>
          <FadeAnimation duration={FadeDurationMS} shouldShow={hasVideoEnded}>
            <Row style={styles.buttonRow}>
              <Column testId="replay">
                <GlossyButton
                  icon={GlossyButtonIconType.Replay}
                  size={UserControlButtonSize.Large}
                  onPress={handlePressReplay}
                />
                <Text style={styles.buttonText}>Replay</Text>
              </Column>
              <Column testId="continue">
                <GlossyButton
                  icon={GlossyButtonIconType.Check}
                  size={UserControlButtonSize.Large}
                  onPress={handlePressContinue}
                />
                <Text style={styles.buttonText}>Continue</Text>
              </Column>
            </Row>
          </FadeAnimation>
        </View>
      )}
    </Column>
  );
}

VideoPlayer.displayName = 'VideoPlayer';
