import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurriculumDependencies } from 'curriculum-services';
import { ScreenplayAction, ScreenplayActionPlay } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { RoundAction } from '../builders/rounds';
import { TransitionActionActToEncounter } from '../Transition.action';
import { TransitionActionType } from '../transition.model';
import { ActToEncounterTransitionScreenplayFactory } from './ActToEncounterTransitionScreenplayFactory';

export interface IActToEncounterTransitionEpicDeps {
  curriculumDependencies: CurriculumDependencies;
  preparedScenes: PreparedScenes;
}

export function actToEncounterTransitionEpic(
  action$: Observable<TransitionActionActToEncounter>,
  state$: StateObservable<unknown>,
  deps: IActToEncounterTransitionEpicDeps
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(TransitionActionType.ActToEncounter),
    map(() => {
      const {
        curriculumDependencies: { programContextService },
        preparedScenes
      } = deps;

      return ActToEncounterTransitionScreenplayFactory.createFor({
        preparedScenes,
        programContextService,
        state$
      }).screenplay;
    }),
    map(screenplay =>
      ScreenplayAction.play({ nextAction: RoundAction.intro(), screenplay })
    )
  );
}
actToEncounterTransitionEpic.displayName = 'actToEncounterTransitionEpic';
