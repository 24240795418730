import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { StormService } from 'storm';
import { StormAssets } from '../StormAssets';
import {
  ConfigAction as UniConfigAction,
  ConfigActionInitSuccess as UniConfigActionInitSuccess
} from '@lexialearning/utils-react';

export interface IInitializeStormAssetsDeps {
  stormService: StormService;
}

export function initializeStormAssetsEpic(
  action$: Observable<UniConfigActionInitSuccess>,
  _state$: StateObservable<unknown>,
  deps: IInitializeStormAssetsDeps
): Observable<void> {
  return action$.pipe(
    filter(UniConfigAction.init.success.match),
    tap(() => StormAssets.init(deps.stormService)),
    ignoreElements()
  );
}
initializeStormAssetsEpic.displayName = 'initializeStormAssetsEpic';
