import {
  FileFilter,
  InfoMap,
  Mount,
  MountType,
  PreloadGroup,
  StormAudio,
  StormEngineControl,
  StormNode,
  StormTexture
} from '@lexialearning/storm-react';
import { IMountPoint } from '../config';
import { ISceneDefinition, ISceneElementDefinition } from '../scenes';
import { LexiaError } from '@lexialearning/utils';
import { LexiaErrorSeverity } from '@lexialearning/main-model';
import { LxStormNode } from './LxStormNode';
import { SystemInfo } from 'utils';
import { NetworkStateProvider } from 'services/network-state';

export class LxStormEngineControl {
  public static readonly displayName = 'LxStormEngineControl';

  public static readonly DefaultFileTypes = new FileFilter(
    '*.tx',
    '*.sg',
    '*.sga',
    '*.opus'
  );

  private get ctrl(): StormEngineControl {
    if (!this._ctrl) {
      throw new LexiaError(
        'Storm player control unavailable. StormService un-initialized',
        LxStormEngineControl.displayName,
        LxStormEngineControlError.CtrlUnavailable
      );
    }

    return this._ctrl!;
  }

  private _ctrl: StormEngineControl | undefined;

  public get info(): InfoMap | undefined {
    return this._ctrl?.getInfo();
  }

  public initialize(ctrl: StormEngineControl): void {
    this._ctrl = ctrl;
    // if (this.stubbing.visuals) {
    //   this._ctrl.loadScene = this.createStormNodeStub.bind(this);
    // eslint-disable-next-line no-console
    //   console.warn('STORM animation engine is STUBBED');
    // }

    // Menu and render stats for debugging purposes
    // this.showMenu()
    // this.showStats(true)
  }

  public showMenu() {
    this.ctrl.showMenu();
  }

  public showStats(show: boolean) {
    this.ctrl.showStats(show);
  }

  // private createStormNodeStub(path: string): StormNode {
  //   return (new StormNodeStub(path) as unknown) as StormNode;
  // }

  /**
   * This (and the mount method which calls it), are only used for mounting
   * level voiceovers at present. Nonetheless, leaving in the below comment and the check
   * for 'scenes' in the logic here, in case it gets more widely used again.
   *
   *  Web & Android: always use HTTP to fetch storm scenes, global audio, and vos
   *  iOS: use HTTP to fetch storm scenes and voiceovers.
   *       Remaining assets, for now, use locally bundled resources
   */
  private determineMountType(mountName: string): MountType {
    // VO's should use HTTP_WITHOUT_FILELIST type
    if (mountName.includes('vo-')) {
      return MountType.HTTP_WITHOUT_FILELIST;
    }

    // Storm scenes should use HTTP
    // All other assets for NON-iOS devices should use HTTP type
    if (mountName.includes('scenes') || !SystemInfo.isIos) {
      return MountType.HTTP;
    }

    // All other assets for iOS devices should use ASSETS type
    return MountType.ASSETS;
  }

  public async mount(mountPoint: IMountPoint): Promise<Mount> {
    const mountType: MountType = this.determineMountType(mountPoint.name);
    const isHttpMount = [
      MountType.HTTP,
      MountType.HTTP_WITHOUT_FILELIST
    ].includes(mountType);

    const dir = isHttpMount
      ? `${mountPoint.webOrigin}${mountPoint.dir}`
      : mountPoint.dir;

    return this.ctrl.filesystem
      .mount(mountType, mountPoint.name, dir)
      .catch(e => {
        throw new LexiaError(
          `Error mounting ${mountPoint.name}`,
          LxStormEngineControl.displayName,
          LxStormEngineControlError.MountFailure
        )
          .withCause(e)
          .withContext({ mountPoint });
      });
  }

  public unmount(mount: Mount): void {
    if (!this.ctrl.filesystem.unmount(mount)) {
      throw new LexiaError(
        `Error unmounting ${mount.target}`,
        LxStormEngineControl.displayName,
        LxStormEngineControlError.Unmount
      );
    }
  }

  public async preload(
    preloadDir: string,
    fileFilter: FileFilter = LxStormEngineControl.DefaultFileTypes
  ): Promise<void> {
    const preloadGroup: PreloadGroup =
      this.ctrl.filesystem.createPreloadGroup('files');

    preloadGroup.appendPath(preloadDir, { fileFilter });

    return preloadGroup
      .execute()
      .then(() => void 0)
      .catch(async e => {
        const isOnline = await NetworkStateProvider.getOnlineStatus();
        if (isOnline) {
          throw new LexiaError(
            `Error preloading ${preloadDir}`,
            LxStormEngineControl.displayName,
            LxStormEngineControlError.PreloadFailure
          )
            .withCause(e)
            .withContext({ fileFilter, preloadDir })
            .withSeverity(LexiaErrorSeverity.Error);
        }
      });
  }

  public async createNode(
    definition: ISceneDefinition | ISceneElementDefinition
  ): Promise<LxStormNode> {
    if (!definition.path) {
      throw new LexiaError(
        'Invalid attempt to create a storm node without a path',
        LxStormEngineControl.displayName,
        LxStormEngineControlError.MissingPath
      ).withContext({ definition });
    }
    const stormNode = await this.ctrl
      .loadScenePromise(definition.path)
      .catch(e => {
        throw new LexiaError(
          `Error loading a scene file from ${definition.path}`,
          LxStormEngineControl.displayName,
          LxStormEngineControlError.SceneLoadError
        )
          .withCause(e)
          .withContext({ definition });
      });

    return new LxStormNode(stormNode, definition.path);
  }

  public setActiveScene(sceneNode: StormNode): void {
    if (!this.ctrl.setActiveScene(sceneNode)) {
      throw new LexiaError(
        `Error activating scene node ${sceneNode.name}`,
        LxStormEngineControl.displayName,
        LxStormEngineControlError.SceneActivation
      ).withContext({ sceneNode });
    }
  }

  public loadTexture(path: string): StormTexture {
    const texture = this.ctrl.loadTexture(path);

    if (!texture) {
      throw new LexiaError(
        `Unable to load texture ${path}`,
        LxStormEngineControl.displayName,
        LxStormEngineControlError.LoadTexture
      ).withContext({ path });
    }

    return texture;
  }

  public createAudio(hasMarks: boolean, decodeOnLoad: boolean): StormAudio {
    return this.ctrl.createAudioTrack({ decodeOnLoad, hasMarks });
  }

  public unloadScene(scene: StormNode): boolean {
    return this.ctrl.unloadScene(scene);
  }

  public unloadAllScenes(): void {
    this.ctrl.unloadAllScenes();
  }
}

export enum LxStormEngineControlError {
  AnimationLoadFailure = 'AnimationLoadFailure',
  CtrlUnavailable = 'CtrlUnavailable',
  LoadTexture = 'LoadTexture',
  MissingPath = 'MissingPath',
  MountFailure = 'MountFailure',
  PreloadFailure = 'PreloadFailure',
  SceneActivation = 'SceneActivation',
  SceneLoadError = 'SceneLoadError',
  Unmount = 'Unmount'
}
