import { IScreenplay } from '@lexialearning/lobo-common';
import * as React from 'react';
import { Row, Text, View } from 'common-ui';
import { FunFactButton } from './fun-fact-button/FunFactButton';
import { FunFactsBubbleStyles } from './FunFactsBubble.styles';
import { FunFactsBubbleSvg } from './svgs/FunFactsBubble.lx-svg';

export interface IFunFactsBubbleProps {
  funFacts: IScreenplay[];
}
export class FunFactsBubble extends React.PureComponent<IFunFactsBubbleProps> {
  public static readonly displayName = 'FunFactsBubble';

  constructor(props: IFunFactsBubbleProps) {
    super(props);
  }

  public render() {
    const { funFacts } = this.props;
    const styles = FunFactsBubbleStyles.get();

    return (
      <View style={styles.container}>
        <FunFactsBubbleSvg />
        <View style={styles.contentContainer}>
          <Text testId="title" style={styles.title}>
            Fun Facts
          </Text>
          <Row style={styles.funFactsButtonsContainer}>
            {funFacts.map((funFact, index) => (
              <FunFactButton
                key={`funFactButton-${index}`}
                index={index}
                funFact={funFact}
              />
            ))}
          </Row>
        </View>
      </View>
    );
  }
}
