import {
  ISingleSelect,
  TaskTypeName
} from '@lexialearning/lobo-common/main-model';
import { cloneDeep } from 'lodash';
import { ArrayUtils } from 'utils';
import { ITaskRegistration, TaskRegistrationBuilder } from '../../core';
import {
  ChoiceHelper,
  IChoiceAnswer
} from '../../shared/choice-helper/ChoiceHelper';
import { SingleSelect } from './SingleSelect';

export function createSingleSelectTaskRegistration(): ITaskRegistration<
  TaskTypeName.SingleSelect,
  ISingleSelect,
  IChoiceAnswer
> {
  const base = TaskRegistrationBuilder.create<
    TaskTypeName.SingleSelect,
    ISingleSelect
  >(TaskTypeName.SingleSelect, SingleSelect).withPrepareContent(
    maybeShuffleChoices
  ).registration;

  return {
    ...base,
    serializeAnswer: ChoiceHelper.serializeAnswer
  };
}

function maybeShuffleChoices(content: ISingleSelect) {
  const contentCopy = cloneDeep(content);

  if (contentCopy.shuffleChoices) {
    contentCopy.choices = ArrayUtils.shuffleArray(contentCopy.choices);
  }

  return contentCopy;
}
