import {
  AppShellMode,
  ControlPanelLayout,
  IAppShellBaseState,
  NavigationButtonType,
  UserInfoButtonType
} from './app-shell.model';
import {
  UserGlobalActionLogoutSuccess,
  UserGlobalActionType
} from '@lexialearning/lobo-common/main-model/user';
import { AppShellActionType } from './AppShellActionType';
import { AppShellActions } from './AppShell.action';
import { InteractionState } from 'common-styles';
import { ModalUtils } from 'shared-components/modals/ModalUtils';
import { SpinnerActions, SpinnerActionType } from 'spinner-handler';
import { combineReducers } from 'redux';
import { progressMetersCombinedReducer as progressMeters } from '../progress-meters/redux/progressMeters.reducer';

export const shellInitialState: IAppShellBaseState = {
  activeModal: undefined,
  audioSupportDisabled: false,
  controlPanelLayout: ControlPanelLayout.Standard,
  mode: AppShellMode.None,
  navigationButtonType: NavigationButtonType.Logout,
  shouldCalloutLevelCertificates: false,
  shouldShowSkipButton: false,
  showSpinner: false,
  spinnerShowTimestamp: 0,
  userControlInteractionState: {
    directions: InteractionState.Disabled,
    lookback: InteractionState.Disabled,
    presentation: InteractionState.Disabled
  },
  userInfoButtonType: UserInfoButtonType.Standard,
  utilityBarDisabled: false,
  utilityBarZIndexDisabled: false
};

export function appShellBaseReducer(
  state: IAppShellBaseState = shellInitialState,
  action: AppShellActions | SpinnerActions | UserGlobalActionLogoutSuccess
): IAppShellBaseState {
  switch (action.type) {
    case AppShellActionType.HideModal:
      return {
        ...state,
        activeModal: undefined
      };

    case AppShellActionType.SetControlPanelLayout:
      return {
        ...state,
        controlPanelLayout: action.payload
      };

    case AppShellActionType.SetMode:
      return {
        ...state,
        mode: action.payload
      };

    case AppShellActionType.SetShouldCalloutLevelCertificates:
      return {
        ...state,
        shouldCalloutLevelCertificates: action.payload
      };

    case AppShellActionType.DisableUtilityBar:
      return {
        ...state,
        utilityBarDisabled: true
      };

    case AppShellActionType.EnableUtilityBar:
      return {
        ...state,
        utilityBarDisabled: false
      };

    case AppShellActionType.DisableUtilityBarZIndex:
      return {
        ...state,
        utilityBarZIndexDisabled: true
      };

    case AppShellActionType.EnableUtilityBarZIndex:
      return {
        ...state,
        utilityBarZIndexDisabled: false
      };

    case AppShellActionType.DisableAudioSupport:
      return {
        ...state,
        audioSupportDisabled: true
      };

    case AppShellActionType.EnableAudioSupport:
      return {
        ...state,
        audioSupportDisabled: false
      };

    case SpinnerActionType.Show:
      return {
        ...state,
        showSpinner: true,
        spinnerShowTimestamp: Date.now()
      };

    case SpinnerActionType.Hide:
      return {
        ...state,
        showSpinner: false
      };

    case AppShellActionType.SetNavigationButtonType:
      return {
        ...state,
        navigationButtonType: action.payload
      };

    case AppShellActionType.SetUserControlInteractionState:
      return {
        ...state,
        userControlInteractionState: {
          ...state.userControlInteractionState,
          ...action.payload
        }
      };

    case AppShellActionType.SetUserInfoButtonType:
      return {
        ...state,
        userInfoButtonType: action.payload
      };

    case AppShellActionType.ShowModal:
      if (ModalUtils.isError(state.activeModal?.id)) {
        // error modals can't be replaced by another modal
        return state;
      }

      return {
        ...state,
        activeModal: action.payload
      };

    case AppShellActionType.HideSkipButton:
      return {
        ...state,
        shouldShowSkipButton: false
      };

    case AppShellActionType.ShowSkipButton:
      return {
        ...state,
        shouldShowSkipButton: true
      };

    case UserGlobalActionType.LogoutSuccess:
      return shellInitialState;

    default:
      return state;
  }
}

const base = appShellBaseReducer;
export const appShellReducer = combineReducers({
  base,
  progressMeters
});
