import { ILevelSnipped, PositionChangeType } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ActivityPositionActionPrepared,
  ActivityPositionActionType,
  PositionAction,
  PositionActionLevelUpPositionPrepared,
  ProgramContextSelector,
  ProgramPositionFactory,
  RoundContext
} from '../../program-context';
import { PlacementFormHelper } from '../position-determiners/PlacementForm.helper';

/**
 * Upon PlacementCompletion, set up the "levelUp" position based on the
 * placement rule that matches the placement unit accuracy.
 */
export function preparePlacedLevelPositionEpic(
  action$: Observable<ActivityPositionActionPrepared>,
  state$: StateObservable<unknown>
): Observable<PositionActionLevelUpPositionPrepared> {
  return action$.pipe(
    ofType(ActivityPositionActionType.Prepared),
    filter(
      a => a.payload.changeType === PositionChangeType.PlacementCompletion
    ),
    map(() => {
      const context = ProgramContextSelector.getRoundContext(state$.value);
      const level = findTargetLevel(context);

      const position = ProgramPositionFactory.createLevelPosition(level);

      return PositionAction.levelUpPositionPrepared({
        ...position,
        isProgramComplete: false
      });
    })
  );
}
preparePlacedLevelPositionEpic.displayName = 'preparePlacedLevelPositionEpic';

function findTargetLevel(context: RoundContext): ILevelSnipped {
  const { level } = PlacementFormHelper.findMatchingRule(context);

  if (!level) {
    throw new LexiaError(
      'Matched placement rule lacks a level',
      preparePlacedLevelPositionEpic.displayName,
      PreparePlacedLevelPositionError.TargetLevelMissing
    ).withContext({
      activityPosition: context.activityPosition,
      placementForm: context.placementForm,
      unitId: context.mainUnit.sysId
    });
  }

  return level;
}

export enum PreparePlacedLevelPositionError {
  TargetLevelMissing = 'TargetLevelMissing'
}
