import { Position } from '@lexialearning/common-ui';
import { Font, Justify } from 'common-styles';
import { TitlePillStyles } from 'common-ui/components/title-pill/TitlePill.styles';

export class ActFooterStyles {
  public static readonly styles = {
    characterName: {
      fontFamily: Font.Family.semiBold,
      marginLeft: 10,
      marginRight: 25
    },
    container: {
      bottom: 0,
      height: 130,
      justifyContent: Justify.Center,
      left: 0,
      position: Position.Absolute,
      right: 0
    },
    lineRight: {
      ...TitlePillStyles.lineStyles,
      flex: 0,
      width: 10
    },
    titlePillOverrides: {
      container: {
        flex: 1
      },
      lineLeft: {
        flex: 0,
        width: 20
      }
    }
  };

  public static get() {
    return this.styles;
  }
}
