import { AnimatableCSSProperty, loboAnimated } from 'common-styles';
import { Types } from '../../../types';

export const duration = 100;

export class PressScaleButtonAnimatedStyles {
  private readonly animations: {
    hover: Types.Animated.CompositeAnimation;
    pressIn: Types.Animated.CompositeAnimation;
    toDefault: Types.Animated.CompositeAnimation;
  };

  private readonly styles: {
    containerAnimated: Types.AnimatedViewStyle;
  };

  constructor(hoverScale?: number, pressScale?: number) {
    const scale = loboAnimated.createValue(1);

    const hover = loboAnimated.timing(AnimatableCSSProperty.Scale, scale, {
      duration,
      easing: loboAnimated.Easing.Out(),
      toValue: hoverScale || 1.11
    });

    const pressIn = loboAnimated.timing(AnimatableCSSProperty.Scale, scale, {
      duration,
      easing: loboAnimated.Easing.Out(),
      toValue: pressScale || 0.93
    });

    const toDefault = loboAnimated.timing(AnimatableCSSProperty.Scale, scale, {
      duration,
      easing: loboAnimated.Easing.Out(),
      toValue: 1
    });

    this.animations = {
      hover,
      pressIn,
      toDefault
    };

    this.styles = {
      containerAnimated: {
        transform: [{ scale }]
      }
    };
  }

  public get() {
    return this.styles;
  }

  public getAnimations() {
    return this.animations;
  }
}
