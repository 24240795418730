import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';
import { Line, Svg } from 'common-ui';
import { ThemeSize } from 'theme';

export interface IDashedLinesSvgProps {
  themeSize?: ThemeSize;
}

export function DashedLinesSvg(props: IDashedLinesSvgProps) {
  const { themeSize } = props;
  const strokeWidth = 4;
  const viewBoxHeight = themeSize === ThemeSize.Small ? 80 : 90;

  return (
    <Svg
      width={900}
      height={viewBoxHeight}
      viewBox={`0 0 900 ${viewBoxHeight}`}
      style={{
        left: 0,
        position: Position.Absolute,
        top: 0,
        width: 900
      }}
    >
      <Line
        x2="900"
        transform={`translate(0 ${viewBoxHeight - strokeWidth * 0.5})`}
        fill="none"
        stroke={Color.NearWhite}
        strokeWidth={strokeWidth}
        strokeDasharray="10 10"
      />
    </Svg>
  );
}
DashedLinesSvg.displayName = 'DashedLinesSvg';
