import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import {
  IAnimationRequest,
  IScreenplayActionPlayer,
  ScreenplayerType
} from 'screenplay';
import { Scene } from '../scenes';
import { StormService } from '../service';

export class StormAnimationScreenplayer implements IScreenplayActionPlayer {
  public static readonly displayName = 'StormAnimationScreenplayer';

  public readonly type = ScreenplayerType.StormAnimation;

  constructor(private readonly stormService: StormService) {}

  public cancel(action?: IScreenplayAction<IAnimationRequest>): void {
    if (action) {
      const scene = this.getSceneMaybe(action);
      scene?.stopAnimation(action.data);
    }
  }

  public pause(action?: IScreenplayAction<IAnimationRequest>): void {
    if (action) {
      const scene = this.getScene(action);
      scene.pauseAnimation(action.data);
    }
  }

  public async play(
    action: IScreenplayAction<IAnimationRequest>
  ): Promise<void> {
    const scene = this.getScene(action);

    return scene.playAnimation(action.data);
  }

  public resume(action?: IScreenplayAction<IAnimationRequest>): void {
    if (action) {
      const scene = this.getScene(action);
      scene.resumeAnimation(action.data);
    }
  }

  private getScene(action: IScreenplayAction<IAnimationRequest>): Scene {
    const scene = this.getSceneMaybe(action);

    if (!scene || !scene.active) {
      throw new LexiaError(
        'Specified target scene is not active!',
        StormAnimationScreenplayer.displayName,
        StormAnimationScreenplayerError.NoActiveScene
      ).withContext({ action });
    }

    return scene;
  }

  private getSceneMaybe(
    action: IScreenplayAction<IAnimationRequest>
  ): Scene | undefined {
    const { targetScene } = action.data;

    return this.stormService.findSceneById(targetScene);
  }
}

export enum StormAnimationScreenplayerError {
  NoActiveScene = 'NoActiveScene'
}
