import { AppShellSelector } from 'feature-areas/shell';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvatarInstructionModal } from '../instruction-modal/AvatarInstructionModal';
import { useDidMount, usePreviousPersistent } from 'common-ui';
import { ProfileSelector } from 'services/profile';

export function useAvatarEditorDisabled() {
  const [disabled, setDisabled] = useState<boolean>(true);

  const activeModal = useSelector(AppShellSelector.getActiveModal);
  const hasCreatedAvatar = useSelector(ProfileSelector.hasCreatedAvatar);
  const closedModal = usePreviousPersistent(activeModal);

  useDidMount(() => {
    if (hasCreatedAvatar) {
      setDisabled(false);
    }
  });

  useEffect(() => {
    if (closedModal?.id === AvatarInstructionModal.ModalId) {
      setDisabled(false);
    }
  }, [activeModal, closedModal]);

  return disabled;
}
