import * as React from 'react';
import { Row, TextButton, TextButtonType } from 'common-ui';
import { IModalAction } from './modal.model';

export interface IModalActionsProps {
  primaryAction: IModalAction;
  secondaryAction?: IModalAction;
}

/**
 * Defines buttons in a modal as a shared component to reuse styles.
 * Ideally this would be part of modal config & rendered inside ModalContent,
 * but because modal config can't accept props, we can't define the onPress there
 */
export class ModalActions extends React.PureComponent<IModalActionsProps> {
  public static readonly displayName = 'ModalActions';

  public render() {
    const { primaryAction, secondaryAction } = this.props;

    return (
      <Row testId={ModalActions.displayName}>
        {!!secondaryAction && <TextButton {...secondaryAction} />}
        <TextButton
          autoFocus
          buttonType={TextButtonType.Primary}
          {...primaryAction}
          ignoreKeydownHandlingDisabled
        />
      </Row>
    );
  }
}
