import { UserGlobalAction } from '@lexialearning/lobo-common/main-model/user';
import { LoggingLevel, LogoutReason } from '@lexialearning/main-model';
import * as React from 'react';
import { connect } from 'react-redux';
import { CheckMarkIconSvg, LogoutIconSvg } from 'common-ui';
import { ProgramContextAction } from 'curriculum-services';
import { ModalActions, ModalRegistry, ModalType } from 'shared-components';
import { AppShellAction } from 'shell';
import are_you_therePng from './are_you_there.png';
import { Services } from 'services';
import { LoboLogItemCategory } from 'logging';

export interface ISessionTimeoutConfirmationModalProps {
  close(): void;
  keepAlive(): void;
  logout(): void;
}

export class SessionTimeoutConfirmationModalComponent extends React.PureComponent<ISessionTimeoutConfirmationModalProps> {
  public static readonly displayName = 'SessionTimeoutConfirmationModal';

  constructor(props: ISessionTimeoutConfirmationModalProps) {
    super(props);
    this.handlePressLogOut = this.handlePressLogOut.bind(this);
    this.handlePressYes = this.handlePressYes.bind(this);
  }

  private handlePressLogOut(): void {
    void Services.logger?.log({
      category: LoboLogItemCategory.SessionTimeoutModalLogOutPressed,
      loggingLevel: LoggingLevel.Verbose,
      payload: {},
      summary: "'LogOut' button pressed in SessionTimeoutConfirmationModal"
    });

    this.props.logout();
  }

  private handlePressYes(): void {
    void Services.logger?.log({
      category: LoboLogItemCategory.SessionTimeoutModalYesPressed,
      loggingLevel: LoggingLevel.Verbose,
      payload: {},
      summary:
        "'Yes' button (keep alive) pressed in SessionTimeoutConfirmationModal"
    });

    this.props.keepAlive();
    this.props.close();
  }

  public render() {
    return (
      <ModalActions
        primaryAction={{
          Icon: CheckMarkIconSvg,
          ignoreGlobalDisabled: true,
          onPress: this.handlePressYes,
          text: 'Yes'
        }}
        secondaryAction={{
          Icon: LogoutIconSvg,
          onPress: this.handlePressLogOut,
          text: 'Log Out'
        }}
      />
    );
  }
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  close: () => AppShellAction.hideModal(),
  keepAlive: () => ProgramContextAction.keepSessionAlive(),
  logout: () => UserGlobalAction.logout.request(LogoutReason.UserLogout)
};

export const SessionTimeoutConfirmationModal: any = connect(
  undefined,
  mapDispatchToProps
)(SessionTimeoutConfirmationModalComponent);

SessionTimeoutConfirmationModal.ModalId = ModalRegistry.register({
  Component: SessionTimeoutConfirmationModal,
  ariaLabel: 'Session Timeout Confirmation',
  headline: 'Are you still there?',
  image: are_you_therePng,
  type: ModalType.Confirmation
});
