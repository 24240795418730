import { Color } from 'common-styles';
import { Path, Svg } from 'common-ui';

export enum HeadphoneSize {
  Small = 'small',
  Large = 'large'
}

interface IHeadphoneStyle {
  marginLeft: number;
  marginRight: number;
}

export interface IHeadphoneSvgProps {
  size: HeadphoneSize;
  style?: IHeadphoneStyle;
}

export function HeadphoneSvg(props: IHeadphoneSvgProps) {
  const { size, style } = props;
  const width = size === HeadphoneSize.Large ? 141 : 55;
  const height = size === HeadphoneSize.Large ? 128 : 50;

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 141 128"
      style={style}
      accessibilityLabel="Headphones."
    >
      <Path
        d="M12.7 78.91h7.43v40.96H12.7A12.7 12.7 0 010 107.17v-15.6a12.7 12.7 0 0112.7-12.66zM95.47 71.1h11.12a10 10 0 0110 10V118a10 10 0 01-10 10H95.47a3 3 0 01-3-3V74.1a3 3 0 013-3zM69.74 0C37.97.05 12.22 25.54 12.17 57v14.09s3.01.02 4.55 0h8.32v-14.1c0-25.52 20.48-44.2 44.68-44.2s44.68 18.68 44.68 44.2v14.1h8.23c1.53.02 4.54 0 4.54 0v-14.1c-.05-31.4-27.4-57-57.43-57zM128.3 119.87h-7.43V78.9h7.43a12.7 12.7 0 0112.7 12.7v15.6a12.7 12.7 0 01-12.7 12.7z"
        fill={Color.Aqua}
      />
      <Path
        d="M34.45 71.06h11.13a3 3 0 013 3v50.9a3 3 0 01-3 3H34.45a10 10 0 01-10-10v-36.9a10 10 0 0110-10z"
        fill={Color.Aqua}
      />
    </Svg>
  );
}
HeadphoneSvg.displayName = 'HeadphoneSvg';
