import { ActionType, createAction } from 'typesafe-actions';
import { LevelCompleteSceneActionType } from './level-complete-scene-redux.model';

export const LevelCompleteSceneAction = {
  prepare: createAction(LevelCompleteSceneActionType.Prepare)()
};

export type LevelCompleteScenePrepareAction = ActionType<
  typeof LevelCompleteSceneAction.prepare
>;

export type LevelCompleteSceneActions = ActionType<
  typeof LevelCompleteSceneAction
>;
