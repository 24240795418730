// istanbul ignore file - static SVG, nothing to test
import { ITextButtonIconProps, Path, Svg } from '../components';

export function CheckMarkIconSvg({ color }: ITextButtonIconProps) {
  return (
    <Svg
      data-test-id={CheckMarkIconSvg.displayName}
      height="22"
      viewBox="0 0 29.587 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M502.117,570.65a2.839,2.839,0,0,1-2.133.959,2.908,2.908,0,0,1-2.1-.959l-8.422-8.686a3.073,3.073,0,0,1,.073-4.438,2.856,2.856,0,0,1,4.3-.075L500,563.8l12.833-13.237a2.909,2.909,0,0,1,2.1-.959,2.973,2.973,0,0,1,2.206,1.035,3.154,3.154,0,0,1,1,2.275,3.085,3.085,0,0,1-.93,2.162Z"
        transform="translate(-488.554 -549.609)"
        fill={color}
      />
    </Svg>
  );
}
CheckMarkIconSvg.displayName = 'CheckMarkIconSvg';
