import { IScreenplay } from '@lexialearning/lobo-common';
import { EncounterSceneAction } from 'feature-areas/encounters/encounter-scene';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import {
  ActIntroScreenplayBuilder,
  IActIntroScreenplayDeps
} from '../builders/acts';
import {
  ILevelOutroScreenplayDeps,
  LevelOutroScreenplayBuilder
} from '../builders/levels';
import { TransitionScreenplayId } from '../transition.model';

export interface ILevelToActTransitionScreenplayDeps
  extends Omit<ILevelOutroScreenplayDeps, 'characterNamePool'>,
    IActIntroScreenplayDeps {}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Level+to+Showcase+Transition
 */
export class LevelToActTransitionScreenplayFactory {
  public static readonly displayName = 'LevelToActTransitionScreenplayFactory';

  public readonly screenplay: IScreenplay;

  public static createFor(
    deps: ILevelToActTransitionScreenplayDeps
  ): LevelToActTransitionScreenplayFactory {
    return new LevelToActTransitionScreenplayFactory(deps);
  }

  private constructor(deps: ILevelToActTransitionScreenplayDeps) {
    const {
      act,
      selectedActNumber,
      hasFunFact,
      actsEntered,
      encounterId,
      currentEncounter,
      encounterPercentCompleted
    } = deps;
    const characterNamePool = act.character.nameVoiceover;

    this.screenplay = ScreenplayBuilder.create(
      TransitionScreenplayId.LevelToAct
    )
      .addReduxAction(EncounterSceneAction.prepare())
      .addScreenplay(
        LevelOutroScreenplayBuilder.createFor({
          characterNamePool,
          selectedActNumber
        }).screenplay
      )
      .addCallback(() => {
        RouterService.history.replace(RouteBuilder.acts(act.sysId));
      })
      .addScreenplay(
        ActIntroScreenplayBuilder.createFor({
          act,
          actsEntered,
          currentEncounter,
          encounterId,
          encounterPercentCompleted,
          hasFunFact,
          selectedActNumber
        }).screenplay
      ).screenplay;
  }
}
