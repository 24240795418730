export enum AvatarType {
  Full = 'full',
  Icon = 'icon',
  Thumbnail = 'thumbnail'
}

export interface IAvatarImages {
  hairBack: string | undefined;
  hairFront: string;
  eyebrows: string;
  face: string;
  outfit: string;
}
