import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import {
  AncestryLoader,
  ContentProviderFactory,
  IContentBase,
  IContentProvider,
  LoboContentType
} from '@lexialearning/lobo-common/cms';
import { DefaultDeepLinkPositionBuilder } from './default/DefaultDeepLinkPositionBuilder';
import { DefaultDeepLinkSubjectFactory } from './default/DefaultDeepLinkSubjectFactory';
import { PlacementDeepLinkPositionBuilder } from './placement/PlacementDeepLinkPositionBuilder';
import { PlacementDeepLinkSubjectFactory } from './placement/PlacementDeepLinkSubjectFactory';
import { IDeepLinkPositionInfo } from './position-builder-deep-link.model';

export class DeepLinkPositionBuilder {
  public static readonly displayName = 'DeepLinkPositionBuilder';

  private readonly provider: IContentProvider;

  public constructor(
    private readonly ancestryLoader: AncestryLoader,
    private readonly configProvider: IConfigProvider,
    contentProviderFactory: ContentProviderFactory
  ) {
    this.provider = contentProviderFactory.create();
  }

  /**
   * Find the subject ID's lineage in the CMS up to the Level so as to
   * construct a representative program position.
   *
   * @param subjectId - sysId of round or unit of focus
   */
  public async build(subjectId: string): Promise<IDeepLinkPositionInfo> {
    const ancestors = await this.loadAncestors(subjectId);
    const isOnboardingOrPlacement =
      ancestors[0].contentType === LoboContentType.Placement;

    const subjectFactory = isOnboardingOrPlacement
      ? new PlacementDeepLinkSubjectFactory(subjectId, ancestors)
      : new DefaultDeepLinkSubjectFactory(subjectId, ancestors);
    const subject = subjectFactory.appendToAncestors();

    const builder = isOnboardingOrPlacement
      ? new PlacementDeepLinkPositionBuilder(
          ancestors,
          this.provider,
          this.configProvider
        )
      : new DefaultDeepLinkPositionBuilder(ancestors, this.provider);

    const position = await builder.build(subject);

    return { mode: subject.programMode, position };
  }

  /**
   * Ancestors up to Level or Placement level. The Level entry
   * may have parents such as Program or a PlacementRule, so these are excluded.
   */
  private async loadAncestors(subjectId: string): Promise<IContentBase[]> {
    const originalOrder = await this.ancestryLoader.load(subjectId);
    const revisedRootIndex = originalOrder.findIndex(a =>
      [LoboContentType.Level, LoboContentType.Placement].includes(a.contentType)
    );
    const snipped =
      revisedRootIndex >= 0
        ? originalOrder.slice(0, revisedRootIndex + 1)
        : originalOrder;

    return snipped.reverse();
  }
}
