import { ILogItemMinimal, LoggingLevel } from '@lexialearning/main-model';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { ISavePositionEventPayload, LoboLogItemCategory } from 'logging';
import {
  IUnitSavePoint,
  ProgramContextSelector,
  RoundContext,
  SavePointActionSavePosition,
  SavePointActionType,
  UnitSavePointStatus
} from 'curriculum-services';
import { TaskRegistry } from 'task-components';
import { AppLoggerDependencies } from '../../AppLoggerDependencies';
import { logSafely, PositionPathBuilder } from '../helpers';
import { ILoggingDeps } from '../logging-epic.model';
import { AppState } from 'services';

export function logPositionSaveEpic(
  action$: Observable<SavePointActionSavePosition>,
  state$: StateObservable<AppState>,
  deps: ILoggingDeps
): Observable<void> {
  return action$.pipe(
    ofType(SavePointActionType.SavePosition),
    logSafely(state$, deps, createEvent, LoboLogItemCategory.SavePosition),
    ignoreElements()
  );
}
logPositionSaveEpic.displayName = 'logPositionSaveEpic';

function createEvent(
  _: unknown,
  state: AppState,
  helper: AppLoggerDependencies
): ILogItemMinimal<ISavePositionEventPayload, LoboLogItemCategory> {
  const context = ProgramContextSelector.getRoundContext(state);
  const savePoint = stripAnswer(
    context.getSavePoint(UnitSavePointStatus.Saving)
  );
  const { levelUpPosition } = ProgramContextSelector.getPosition(state);

  const payload: ISavePositionEventPayload = { levelUpPosition, savePoint };

  return {
    category: LoboLogItemCategory.SavePosition,
    loggingLevel: LoggingLevel.Verbose,
    payload,
    summary: buildSummary(context, payload, helper.taskRegistry)
  };
}

function stripAnswer(savePoint: IUnitSavePoint): IUnitSavePoint {
  // need to declare this variable otherwise it causes an iOS error in production builds LOBO-13673
  const { rounds } = savePoint;

  return {
    ...savePoint,
    rounds: rounds.map(r => ({
      ...r,
      attempts: r.attempts.map(a => ({ ...a, answer: {} }))
    }))
  };
}

function buildSummary(
  context: RoundContext,
  { levelUpPosition, savePoint }: ISavePositionEventPayload,
  taskRegistry: TaskRegistry
): string {
  const builder = PositionPathBuilder.createFromContext(context, taskRegistry)
    .addLevel()
    .addActivity()
    .addEncounter()
    .addMainUnit();
  const path = context.isSubunit
    ? builder.addMainRound().addSubunits().path
    : builder.path;
  const rounds = savePoint.rounds.map(r => r.roundId);
  const levelUp = levelUpPosition?.isProgramComplete
    ? ' Completed Program!'
    : levelUpPosition
    ? ' Leveling Up!'
    : '';

  return `Saving ${path} rounds: [${rounds}]${levelUp}`;
}

export const LogPositionSavePrivates = { buildSummary };
