import {
  IAct,
  ILevel,
  ILevelScreen,
  ITopic,
  TopicCode
} from '@lexialearning/lobo-common/main-model';
import { IActProgress } from '@lexialearning/lobo-common/main-model/student';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  PositionAction,
  ProgressSelector,
  ActSelector,
  LevelSelector,
  ProgramContextAction
} from 'curriculum-services';
import { AnimatedView, Row } from 'common-ui';
import { ScreenplaySelector } from 'screenplay';
import { AppState, Services } from 'services';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { withSpinner } from 'spinner-handler';
import { ActColumn } from '../act-column/ActColumn';
import { LevelHeader } from '../header/LevelHeader';
import { LevelReactAnimationName } from './level-page.model';
import { LevelPageAnimatedStyles } from './LevelPage.animated-styles';

export interface ILevelPageProps {
  activeAct: IAct | undefined;
  actsProgress: IActProgress[] | undefined;
  topics: ITopic[];
  content: ILevelScreen | undefined;
  isScreenplayActive: boolean;
  level: ILevel;
  keepSessionAlive(): void;
  selectAct(actId: string): void;
}

export interface ILevelPageState {
  hasMadeSelection: boolean;
}

export class LevelPageComponent extends React.PureComponent<
  ILevelPageProps,
  ILevelPageState
> {
  public static readonly displayName = 'LevelPage';

  private readonly animatedStyles: LevelPageAnimatedStyles;
  private readonly unregisterAnimations: () => void;

  constructor(props: ILevelPageProps) {
    super(props);

    this.animatedStyles = new LevelPageAnimatedStyles();
    this.unregisterAnimations = this.registerAnimations();
    this.handleActColumnPress = this.handleActColumnPress.bind(this);

    this.state = { hasMadeSelection: false };
  }

  public componentDidMount() {
    this.props.keepSessionAlive();
  }

  public componentWillUnmount(): void {
    this.unregisterAnimations();
  }

  private registerAnimations(): () => void {
    const { fadeIn, fadeOut, show } = this.animatedStyles.getAnimations();

    return Services.reactAnimationScreenplayer.registerAnimations([
      /* eslint-disable sort-keys-fix/sort-keys-fix, sort-keys */
      { name: LevelReactAnimationName.FadeIn, animation: fadeIn },
      { name: LevelReactAnimationName.FadeOut, animation: fadeOut },
      { name: LevelReactAnimationName.Show, animation: show }
      /* eslint-enable sort-keys-fix/sort-keys-fix, sort-keys */
    ]);
  }

  private get isInteractive(): boolean {
    const { isScreenplayActive } = this.props;
    const { hasMadeSelection } = this.state;

    return !hasMadeSelection && !isScreenplayActive;
  }

  private handleActColumnPress(actId: string): void {
    if (this.isInteractive) {
      this.setState({ hasMadeSelection: true });
      this.props.selectAct(actId);
    }
  }

  private getActAccessibilityLabel(topicCode: TopicCode) {
    const characterName = this.getActCharacterName(topicCode);

    return `${characterName}, ${topicCode}`;
  }

  private getActCharacterName(topicCode: TopicCode) {
    const character = this.props.level.acts.find(
      a => a.topic.code === topicCode
    )?.character;

    return character?.displayName;
  }

  public render() {
    const { level, topics, actsProgress } = this.props;
    const styles = this.animatedStyles.get();

    // ⬇ actsProgress may be momentarily undefined upon level completion when the app has nav'd to the
    // level complete route, but this component has not yet unmounted (change introduced with the async
    // rendering of React 18's new root api)
    if (!actsProgress) {
      return null;
    }

    return (
      <AnimatedView animatedStyle={styles.container}>
        <LevelHeader
          levelNumber={level.title.replace(/^0+/, '')}
          actsProgress={actsProgress}
          topics={topics}
        />
        <Row style={styles.acts} blockPointerEvents={!this.isInteractive}>
          {actsProgress.map((a, i) => {
            const topicCode = topics[i]?.code;

            return (
              !a.isComplete && (
                <ActColumn
                  key={i}
                  actId={a.sysId}
                  topic={topicCode}
                  onPress={this.handleActColumnPress}
                  accessibilityLabel={this.getActAccessibilityLabel(topicCode)}
                />
              )
            );
          })}
        </Row>
      </AnimatedView>
    );
  }
}

function mapStateToProps(state: AppState) {
  const level = LevelSelector.getLevelDisplayReady(state);

  return {
    activeAct: ActSelector.getActMaybe(state),
    actsProgress: ProgressSelector.getProgressMaybe(state),
    content: BootstrapContentSelector.getLevelScreenContent(state),
    // Needed for withSpinner hoc
    isPageReady: !!level,

    isScreenplayActive: !!ScreenplaySelector.getActiveScreenplayId(state),

    level: level!,

    topics: LevelSelector.getTopics(state)
  };
}

const mapDispatchToProps = {
  keepSessionAlive: () => ProgramContextAction.keepSessionAlive(),
  selectAct: (actId: string) =>
    PositionAction.activitySelected({ activityId: actId })
};

export const LevelPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSpinner<ILevelPageProps>(LevelPageComponent));

export const LevelPagePrivates = { mapDispatchToProps, mapStateToProps };
