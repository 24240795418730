import { IModeling, ModelSection } from '@lexialearning/lobo-common/main-model';
import { createSelector } from '@reduxjs/toolkit';

export class ModelingSelector {
  public static getState: (state: unknown) => IModeling | undefined;

  public static getHighlightedDirectionItemId: (state: unknown) => string;

  public static getHighlightedPresentationItemId: (state: unknown) => string;

  public static getHighlightedItemId: (state: unknown) => string;

  public static createSelectors(
    selector: (state: any) => IModeling | undefined
  ) {
    ModelingSelector.getState = createSelector(
      selector,
      (state: IModeling | undefined) => state
    );
    ModelingSelector.getHighlightedDirectionItemId = createSelector(
      selector,
      (modeling: IModeling | undefined) =>
        ModelingSelector.getSectionHighlightedItemId(
          modeling,
          ModelSection.Direction
        )
    );
    ModelingSelector.getHighlightedPresentationItemId = createSelector(
      selector,
      (modeling: IModeling | undefined) =>
        ModelingSelector.getSectionHighlightedItemId(
          modeling,
          ModelSection.Presentation
        )
    );
    ModelingSelector.getHighlightedItemId = createSelector(
      selector,
      (modeling: IModeling | undefined) =>
        ModelingSelector.getSectionHighlightedItemId(modeling)
    );
  }

  private static getSectionHighlightedItemId(
    modeling: IModeling | undefined,
    section?: ModelSection
  ): string {
    if (!modeling || (section && !modeling[section])) {
      return '';
    }
    let highlightedItem: any;
    if (section) {
      highlightedItem = modeling[section].items.find(i => i.highlight);
    } else {
      highlightedItem = [
        ...modeling.direction.items,
        ...modeling.presentation.items
      ].find(i => i.highlight);
    }

    return highlightedItem ? highlightedItem.id : '';
  }
}
