import {
  AnimatedView as CuiAnimatedView,
  AnimatedViewStyle as CuiAnimatedViewStyle
} from '@lexialearning/common-ui';
import { AnimatedViewStyles } from './AnimatedView.styles';
import { IViewProps } from './View';

export interface IAnimatedViewProps extends IViewProps {
  animatedStyle?: CuiAnimatedViewStyle;
}
export function AnimatedView(props: IAnimatedViewProps) {
  const { animatedStyle, style, ...restProps } = props;

  const animatedViewStyle = AnimatedViewStyles.build(style, animatedStyle);

  return (
    <CuiAnimatedView
      {...restProps}
      staticStyle={animatedViewStyle.staticStyle}
      animatedStyle={animatedViewStyle.animatedStyle}
    />
  );
}
AnimatedView.displayName = 'AnimatedView';
