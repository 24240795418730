import { Color } from 'common-styles';
import { G, Path } from '@lexialearning/common-ui';
import { IGlossyButtonIconSvgProps } from './glossy-button-icon-svg.model';

export function EditIconSvg({
  iconColor = Color.NearWhite,
  shadowColor
}: IGlossyButtonIconSvgProps) {
  return (
    <G transform="scale(2.3) translate(1,-3)">
      {/* pencil shadow */}
      {shadowColor && (
        <G transform="translate(0.25 0.25)" opacity="0.28">
          <Path
            id="Path_13037-2"
            d="m 0.1,0.188 9.947,-0.01 V 5.27 L 0.094,5.28 Z"
            transform="rotate(-45,29.50411,-7.2810025)"
            fill={shadowColor}
          />
          <Path
            d="m 18.567,65.274 c -0.293,1.027 0.293,1.638 1.345,1.345 l 3.35,-0.954 -3.741,-3.765 z"
            transform="translate(-6.29,-42.5)"
            fill={shadowColor}
          />
          <Path
            d="M 63.834,20.659 62.172,19 a 1.491,1.491 0 0 0 -2.078,0 l -1.394,1.39 3.741,3.741 1.394,-1.394 a 1.447,1.447 0 0 0 -10e-4,-2.078 z"
            transform="translate(-37.08,-9.38)"
            fill={shadowColor}
          />
        </G>
      )}
      {/* pencil */}
      <Path
        id="Path_13037-2"
        d="m 0.1,0.188 9.947,-0.01 V 5.27 L 0.094,5.28 Z"
        transform="rotate(-45,29.50411,-7.2810025)"
        fill={iconColor}
      />
      <Path
        d="m 18.567,65.274 c -0.293,1.027 0.293,1.638 1.345,1.345 l 3.35,-0.954 -3.741,-3.765 z"
        transform="translate(-6.29,-42.5)"
        fill={iconColor}
      />
      <Path
        d="M 63.834,20.659 62.172,19 a 1.491,1.491 0 0 0 -2.078,0 l -1.394,1.39 3.741,3.741 1.394,-1.394 a 1.447,1.447 0 0 0 -10e-4,-2.078 z"
        transform="translate(-37.08,-9.38)"
        fill={iconColor}
      />
    </G>
  );
}

EditIconSvg.displayName = 'EditIconSvg';
