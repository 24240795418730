import { SceneControllerBase } from 'services/storm-lobo';
import { SceneZIndex } from 'services/storm-lobo/lobo-storm-config.model';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { Scene } from 'storm';
import { ISceneAnimationRequest } from 'storm/scenes/SceneBase';
import { CalibrationSceneAnimationName } from './calibration-scene.model';

export class CalibrationScene extends SceneControllerBase {
  public static readonly Name = SceneName.Calibration;

  constructor(scene: Scene) {
    super(scene, SceneZIndex.Calibration);
    this.init();
  }

  private init() {
    const request: ISceneAnimationRequest = {
      name: CalibrationSceneAnimationName.Root.Intro,
      speed: 0,
      timeAsPercent: 0
    };
    this.scene.startAnimation(request);
  }
}
