import { merge } from 'lodash';
import memoizeOne from 'memoize-one';
import {
  Color,
  Font,
  FontFamily,
  InteractionState,
  ShadowStyle,
  TextAlign
} from 'common-styles';
import { Display } from '@lexialearning/common-ui';

export interface ITextPillStyleOverride {
  container?: IContainerStyleOverride;
  text?: ITextStyleOverride;
}

interface IContainerStyleOverride {
  flex?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  cursor?: any;
  height?: number;
  marginBottom?: number;
  opacity?: number;
  overflow?: string;
  paddingLeft?: number;
  paddingRight?: number;
  shadowColor?: string;
  shadowOffset?: { height: number; width: number };
  shadowOpacity?: number;
  shadowRadius?: number;
  width?: number;
}
interface ITextStyleOverride {
  textAlign?: TextAlign;
  fontSize?: number;
  fontFamily?: FontFamily;
  color?: string;
  display?: Display;
}

export const pillOverrides = {
  hasShadow: {
    container: {
      ...ShadowStyle.activity
    }
  },
  selected: {
    container: {
      borderColor: Color.getSetFor(InteractionState.Selected).border,
      borderWidth: 2
    }
  }
};

export class TextPillStyle {
  public static readonly build = memoizeOne(
    (
      interactionState: InteractionState,
      hasShadow: boolean,
      styleOverride?: ITextPillStyleOverride
    ) => {
      const isHighlighted = interactionState === InteractionState.Highlighted;

      return merge(
        {},
        {
          container: {
            backgroundColor: isHighlighted
              ? Color.getSetFor(InteractionState.Highlighted).background
              : Color.NearWhite,
            borderColor: Color.NearWhite,
            borderRadius: 30,
            borderWidth: 3,
            paddingLeft: 5,
            paddingRight: 5
          },
          text: {
            color: isHighlighted
              ? Color.Text
              : Color.getSetFor(interactionState).text,
            fontFamily: Font.Family.semiBold,
            textAlign: TextAlign.Center
          }
        },
        pillOverrides[interactionState],
        hasShadow && pillOverrides.hasShadow,
        styleOverride
      );
    }
  );
}
