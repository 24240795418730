import { AppShellAction } from 'feature-areas/shell/redux';
import { useDispatch } from 'react-redux';
import { TroubleshootButton } from './TroubleshootButton';

export function HideTroubleshootButton() {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(AppShellAction.hideModal());
  };

  return (
    <TroubleshootButton
      isHighlighted
      onPress={onPress}
      testId={HideTroubleshootButton.displayName}
    />
  );
}
HideTroubleshootButton.displayName = 'HideTroubleshootButton';
