import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProgramContextActionStartProgram,
  ProgramContextActionType
} from '../../program-context';
import {
  PlacementAction,
  PlacementActionUnloaded
} from '../redux/Placement.action';

export function unloadPlacementContentEpic(
  action$: Observable<ProgramContextActionStartProgram>
): Observable<PlacementActionUnloaded> {
  return action$.pipe(
    ofType(ProgramContextActionType.StartProgram),
    map(() => PlacementAction.unloaded())
  );
}
unloadPlacementContentEpic.displayName = 'unloadPlacementContentEpic';
