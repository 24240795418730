import { IScreenplay } from '@lexialearning/lobo-common';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import {
  LevelIntroSceneAnimationName,
  LevelIntroSceneElementName
} from 'feature-areas/level-intro';
import { AppShellAction } from 'feature-areas/shell';
import { AppShellMode } from 'feature-areas/shell/redux/app-shell.model';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { LevelScreenplayId } from './level-screenplay-builders.model';

export interface ILevelIntroSceneScreenplayDeps {
  preparedScenes: PreparedScenes;
  welcomeMessageVo: IScreenplay | undefined;
  isLevelToLevel?: boolean;
}

/**
 * Creates a screenplay for the level intro scene
 * - running storm animations, and playing voiceovers
 *
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Home+to+Level+Transition
 */
export class LevelIntroSceneScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'LevelIntroSceneScreenplayBuilder';

  public static createFor(
    deps: ILevelIntroSceneScreenplayDeps
  ): LevelIntroSceneScreenplayBuilder {
    return new LevelIntroSceneScreenplayBuilder(deps);
  }

  private constructor(deps: ILevelIntroSceneScreenplayDeps) {
    super(LevelScreenplayId.Intro);

    const { preparedScenes, isLevelToLevel, welcomeMessageVo } = deps;

    this.builder
      .addReduxAction(AppShellAction.setMode(AppShellMode.None))
      .addCallback(
        async () => {
          const levelIntroScene = await preparedScenes.levelIntroReady;
          levelIntroScene.show();
        },
        { shouldUseLoadingSpinner: true }
      )
      // Only add an animation for the prior level's background if going from level to level
      // (That background element should not be part of the scene definition except in this case)
      .addStormAnimation(
        isLevelToLevel
          ? {
              name: LevelIntroSceneAnimationName.Background.Idle,
              targetElement: LevelIntroSceneElementName.buildBackground(1),
              targetScene: SceneName.LevelIntro
            }
          : undefined
      )
      .addStormAnimation(
        {
          name: LevelIntroSceneAnimationName.Background.Intro,
          targetElement: LevelIntroSceneElementName.buildBackground(2),
          targetScene: SceneName.LevelIntro
        },
        { concurrent: true }
      )
      .addStormAnimation(
        {
          name: LevelIntroSceneAnimationName.Root.Intro,
          targetScene: SceneName.LevelIntro
        },
        { concurrent: true }
      )
      .addMusic({ path: Music.HomeLevelIntro })
      .addScreenplay(welcomeMessageVo)
      .addDelay(250)
      .addSfx(Sfx.InstructionOn, { concurrent: true })
      .addStormAnimation(
        {
          name: LevelIntroSceneAnimationName.Root.Outro,
          targetScene: SceneName.LevelIntro
        },
        { concurrent: true }
      )
      .addDelay(1000)
      .addReduxAction(AppShellAction.setMode(AppShellMode.UtilitiesOnly));
  }
}
