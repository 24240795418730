// istanbul ignore file - not used in production
import { LexiaError } from '@lexialearning/utils';
import { FunctionBase } from 'lodash';
import {
  Action,
  Dispatch,
  PreloadedState,
  Reducer,
  Store,
  StoreCreator,
  StoreEnhancer
} from 'redux';
import { Services } from 'services/Services'; // TODO this should not depend on lobo services
import { MeasureService } from './MeasureService';

/**
 *  Provides utilities used for performance measurement using User Timing API.
 */
export class PerformanceUtils {
  public static readonly displayName = 'PerformanceUtils';

  /**
   * Wraps up arbitrary function and records the time spent in the function using User Timing API.
   * @param label label that will be visible in profiler's Timing section
   */
  public static monitorFunction =
    (label: string) =>
    (inner: FunctionBase) =>
    (...args: any[]) => {
      const startMark = `${label} start`;
      const endMark = `${label} end`;
      const markLabel = `${label}`;

      MeasureService.startMeasure(markLabel, startMark);

      const result = inner(...args);

      MeasureService.endMeasure(markLabel, startMark, endMark);

      return result;
    };

  /**
   * Redux middleware that, added to the middleware pipeline, measures time spent in all other
   * middlewares down the pipeline and records the measurement using User Timing API.
   * @param label label that will be visible in profiler's Timing section
   */
  public static createMonitorMiddleware =
    (label: string) =>
    (_store: Store) =>
    (next: Dispatch) =>
    (action: Action) => {
      // function monitor(...) is not used to wrap next on purpose to prevent creation of new function on every dispatch

      if (!action) {
        Services.logError(
          new LexiaError(
            'Falsy action dispatched! Perhaps you need to add a filter to an epic?',
            PerformanceUtils.displayName,
            PerformanceUtilsError.FalsyAction
          )
        );

        return;
      }

      const fullLabel = `${action.type} ${label}`;

      const startMark = `${fullLabel} start`;
      const endMark = `${fullLabel} end`;
      const markLabel = `${fullLabel}`;

      MeasureService.startMeasure(markLabel, startMark);

      const result = next(action);

      MeasureService.endMeasure(markLabel, startMark, endMark);

      return result;
    };

  /**
   * Redux store enhancer that records the time spent in the root reducer using User Timing API.
   */
  public static createReducerMonitorEnhancer =
    <S, A extends Action>(createStore: StoreCreator) =>
    (
      reducer: Reducer<S, A>,
      initialState: PreloadedState<S>,
      enhancer: StoreEnhancer
    ) => {
      const monitoredReducer: Reducer<S, A> = (
        state: S | undefined,
        action: A
      ) => {
        const startMark = `${action.type} reducer start`;
        const endMark = `${action.type} reducer end`;
        const markLabel = `${action.type} reducer`;

        MeasureService.startMeasure(markLabel, startMark);

        const nextState = reducer(state, action);

        MeasureService.endMeasure(markLabel, startMark, endMark);

        return nextState;
      };

      return createStore(monitoredReducer, initialState, enhancer);
    };

  public static getStackSize() {
    const e = new Error('log-stack-trace');
    Error.stackTraceLimit = Infinity;
    Error.captureStackTrace(e);
    const stackSize = (e.stack && e.stack.split('\n').length) || 0;

    return stackSize;
  }
}

export enum PerformanceUtilsError {
  FalsyAction = 'FalsyAction'
}
