import {
  IAct,
  IActivityPosition,
  IEncounter,
  IUnitSnipped
} from '@lexialearning/lobo-common';
import {
  IActProgress,
  IEncounterProgress,
  IUnitProgress
} from '@lexialearning/lobo-common/main-model/student';
import { ProgressBaseBuilder } from './ProgressBaseBuilder';

/**
 * Creates an IActProgress instance based on the current act's position.
 * This makes it easier to determine the percent completion of each program
 * element in the act.
 *
 * The percent completion of an act is based on the number of units completed
 * across all encounters. (An alternative approach would be to base it on the
 * percent completion of each encounter, which would yield different results).
 *
 * We may need to convert this to calculate based on round completion, once we
 * start saving partial units. See LOBO-10958.
 */
export class ActProgressFactory {
  public static readonly displayName = 'ActProgressBuilder';

  /**
   * Creates an IActProgress instance based on the current act's position
   */
  public static create(
    act: IAct,
    activityPosition: IActivityPosition
  ): IActProgress {
    const { progress } = ProgressBaseBuilder.create(
      act.sysId,
      act.encounters
    ).maybeCompleted(activityPosition.isComplete, activityPosition.encounterId);
    const encounterPositions = act.encounters.map((e, index) =>
      this.toEncounterProgress(e, activityPosition, progress.position, index)
    );

    const percentComplete = this.determineActPercentComplete(act, {
      ...progress,
      encounterPositions
    });

    return {
      ...progress,
      encounterPositions,
      percentComplete
    };
  }

  private static toEncounterProgress(
    encounter: IEncounter,
    activityPosition: IActivityPosition,
    activeIndex: number,
    itemIndex: number
  ): IEncounterProgress {
    const { progress } = ProgressBaseBuilder.create(
      encounter.sysId,
      encounter.units
    ).withActivePosition(
      activeIndex,
      itemIndex,
      activityPosition.unitPosition.unitId
    );

    return {
      ...progress,
      unitPositions: encounter.units.map((u, index) =>
        this.toUnitProgress(u, progress.position, index)
      )
    };
  }

  private static toUnitProgress(
    unit: IUnitSnipped,
    activeIndex: number,
    itemIndex: number
  ): IUnitProgress {
    return ProgressBaseBuilder.create(
      unit.sysId,
      unit.rounds
    ).withActivePosition(activeIndex, itemIndex, unit.rounds[0]?.sysId)
      .progress;
  }

  private static determineActPercentComplete(
    act: IAct,
    progress: IActProgress
  ): number {
    const totalUnits = act.encounters.reduce(
      (acc, e) => acc + e.units.length,
      0
    );
    const activeEncounter = progress.encounterPositions[progress.position];
    const completedUnits =
      progress.encounterPositions
        .slice(0, progress.position)
        .reduce((acc, e) => acc + e.count, 0) +
      (activeEncounter?.position ?? 0);

    return totalUnits ? Math.round((completedUnits * 100) / totalUnits) : 100;
  }
}
