import { useTheme } from 'theme';
import { IPlainTextProps, PlainText } from './PlainText';

export function Text(props: IPlainTextProps) {
  const { themeSize } = useTheme();
  const { children, testId, ...textProps } = props;

  return (
    <PlainText
      testId={testId || Text.displayName}
      themeSize={themeSize}
      {...textProps}
    >
      {children}
    </PlainText>
  );
}
Text.displayName = 'Text';
