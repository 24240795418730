import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LevelAction, LevelActionLoad } from '../../../level';
import { ProgramMode } from '../../program-context.model';
import {
  ProgramContextActionLoaded,
  ProgramContextActionType,
  ProgramContextSelector
} from '../../redux';

export function requestLevelLoadEpic(
  action$: Observable<ProgramContextActionLoaded>,
  state$: StateObservable<unknown>
): Observable<LevelActionLoad> {
  return action$.pipe(
    ofType(ProgramContextActionType.Loaded),
    filter(a => a.payload.mode === ProgramMode.ActiveStudent),
    map(() => {
      const targetLevelId = ProgramContextSelector.getPosition(
        state$.value
      ).levelId;

      return LevelAction.load.request({ sysId: targetLevelId });
    })
  );
}

requestLevelLoadEpic.displayName = 'requestLevelLoadEpic';
