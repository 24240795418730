import { Color, Justify, ShadowStyle } from 'common-styles';

export class PodcastStyles {
  public static readonly styles = {
    container: {
      backgroundColor: Color.NearWhite,
      borderRadius: 30,
      height: 590,
      width: 370,
      ...ShadowStyle.activity
    },
    imageContainer: {
      backgroundColor: Color.LightBlue,
      borderRadius: 20,
      height: 330,
      marginBottom: 45,
      marginTop: 10,
      width: 330
    },
    innerContainer: {
      borderColor: Color.Aqua,
      borderRadius: 25,
      borderWidth: 2,
      height: 570,
      justifyContent: Justify.Start,
      width: 350
    },
    taskContainer: {
      flex: 1
    }
  };

  public static get() {
    return this.styles;
  }
}
