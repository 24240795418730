import { IScreenplay } from '@lexialearning/lobo-common';
import {
  IScreenplayState,
  ScreenplayActionType,
  ScreenplayStatus
} from './screenplay-redux.model';
import { ScreenplayActions } from './Screenplay.action';

const screenplayInitialState: IScreenplayState = {
  currentActionInfo: { index: -1, type: '' },
  screenplay: undefined,
  status: ScreenplayStatus.None
};

export function screenplayReducer(
  state = screenplayInitialState,
  action: ScreenplayActions
): IScreenplayState {
  switch (action.type) {
    case ScreenplayActionType.Canceled:
      return maybeResetState(state, action.payload.screenplay);

    case ScreenplayActionType.Play:
      return {
        ...state,
        screenplay: action.payload.screenplay
      };

    case ScreenplayActionType.Pause:
      return {
        ...state,
        status: ScreenplayStatus.Paused
      };

    case ScreenplayActionType.Playing:
      const { actionIndex: index, actionType: type } = action.payload;

      return {
        ...state,
        currentActionInfo: { index, type },
        status: ScreenplayStatus.Playing
      };

    case ScreenplayActionType.PlayComplete:
      return maybeResetState(state, action.payload.screenplay);

    case ScreenplayActionType.Resume:
      return {
        ...state,
        status: ScreenplayStatus.Playing
      };

    default:
      return state;
  }
}

function maybeResetState(
  state: IScreenplayState,
  screenplay: IScreenplay
): IScreenplayState {
  return screenplay.id === state.screenplay?.id
    ? screenplayInitialState
    : state;
}

export const ScreenplayReducerPrivates = {
  initialState: screenplayInitialState
};
