import { Position } from '@lexialearning/common-ui';
import memoizeOne from 'memoize-one';
import {
  Align,
  AnimatableCSSProperty,
  Justify,
  loboAnimated
} from 'common-styles';
import { Types } from 'common-ui';
import { BarAnimatedStyles } from '../unit-meter/bar/Bar.animated-styles';
import { UNIT_METER_HEIGHT } from '../unit-meter/UnitMeter.styles';

export class RoundMeterAnimatedStyles {
  private readonly animations: {
    animateEntry?: Types.Animated.CompositeAnimation;
  };

  public static readonly EntryAnimationTiming = 750;

  private static readonly EntryEasing = loboAnimated.Easing.CubicBezier(
    0.215,
    0.61,
    0.355,
    1
  );

  private static readonly OffscreenBottomValue = -100;

  private readonly animatedValues: {
    bottom?: Types.AnimatedValue;
  };

  constructor(activeUnitIndex: number) {
    if (activeUnitIndex === -1) {
      this.animations = {};
      this.animatedValues = {};
    } else {
      const delayStart = activeUnitIndex * BarAnimatedStyles.AnimationTiming;
      this.animatedValues = {
        bottom: loboAnimated.createValue(
          RoundMeterAnimatedStyles.OffscreenBottomValue
        )
      };
      this.animations = {
        animateEntry: this.createEntryAnimation(delayStart)
      };
    }
  }

  public createEntryAnimation(
    delayStart: number
  ): Types.Animated.CompositeAnimation {
    return loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Bottom,
        this.animatedValues.bottom!,
        {
          delay: delayStart,
          duration: RoundMeterAnimatedStyles.EntryAnimationTiming * 0.5,
          easing: RoundMeterAnimatedStyles.EntryEasing,
          toValue: 0
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Bottom,
        this.animatedValues.bottom!,
        {
          duration: RoundMeterAnimatedStyles.EntryAnimationTiming * 0.2,
          easing: RoundMeterAnimatedStyles.EntryEasing,
          toValue: -UNIT_METER_HEIGHT
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Bottom,
        this.animatedValues.bottom!,
        {
          duration: RoundMeterAnimatedStyles.EntryAnimationTiming * 0.2,
          easing: RoundMeterAnimatedStyles.EntryEasing,
          toValue: 0
        }
      )
    ]);
  }

  public getAnimations() {
    return this.animations;
  }

  public readonly build = memoizeOne((width: number, height: number) => ({
    container: {
      bottom: UNIT_METER_HEIGHT,
      height,
      position: Position.Absolute,
      width
    },
    pipRow: {
      animated: {
        bottom: this.animatedValues.bottom
      },
      static: {
        alignItems: Align.Center,
        height,
        justifyContent: Justify.SpaceBetween,
        paddingLeft: 50,
        paddingRight: 50,
        position: Position.Absolute,
        width
      }
    }
  }));
}
