type EnumType = { [key: string]: string } | { [key: string]: number };

export class EnumUtils {
  public static getRandomValue<T extends EnumType>(inputEnum: T): T[keyof T] {
    const values = Object.values(inputEnum);
    const enumValues = values.some(Number.isInteger)
      ? // Numeric enum values need to be filtered, due to the reverse mapping that TS uses for numeric enums
        values.filter(Number.isInteger)
      : values;

    const randomIndex = Math.floor(Math.random() * enumValues.length);
    const randomEnumValue = enumValues[randomIndex];

    return randomEnumValue;
  }

  /** Returns index of arg value within enum values */
  public static getIndexValue<T extends EnumType>(
    inputEnum: T,
    value: T[keyof T]
  ): number {
    return Object.values(inputEnum).indexOf(value);
  }

  /**
   * Returns ordinal position (1-based index) of arg value within enum values
   * @param inputEnum
   * @param value
   * @returns
   */
  public static getOrdinalValue<T extends EnumType>(
    inputEnum: T,
    value: T[keyof T]
  ): number {
    return Object.values(inputEnum).indexOf(value) + 1;
  }

  /** Returns nth index value from string enum */
  public static getValueAtIndex<T>(inputEnum: EnumType, index: number): T {
    return Object.values(inputEnum)[index];
  }
}
