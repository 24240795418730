import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IUnitSnipped,
  PositionChangeType,
  ProgramContextSelector,
  RoundContext
} from 'curriculum-services';
import { IMeterMarker } from '../../progress-meters.model';
import { RoundMeterUpdatedAction } from '../../round-meter/redux/RoundMeter.action';
import { RoundMeterSelector } from '../../round-meter/redux/RoundMeter.selector';
import { RoundMeterActionType } from '../../round-meter/redux/RoundMeterActionType';
import { UnitMeterAction, UnitMeterUpdatedAction } from './UnitMeter.action';
import { MeterMarkerProgressStatus } from '../../meterMarker.model';

export function updateUnitMeterEpic(
  action$: Observable<RoundMeterUpdatedAction>,
  state$: StateObservable<unknown>
): Observable<UnitMeterUpdatedAction> {
  return action$.pipe(
    ofType(RoundMeterActionType.Updated),
    map(() => ProgramContextSelector.getRoundContext(state$.value)),
    map((context: RoundContext) => {
      const { units } = context.encounter;
      const { imminentPositionMaybe, mainUnit } = context;
      const roundMarkers = RoundMeterSelector.getMarkers(state$.value);

      const unitMarkers = createUnitMarkers(
        units,
        roundMarkers,
        mainUnit.sysId,
        imminentPositionMaybe?.changeType
      );

      return UnitMeterAction.updated({ markers: unitMarkers });
    })
  );
}
updateUnitMeterEpic.displayName = 'updateUnitMeterEpic';

function createUnitMarkers(
  units: IUnitSnipped[],
  roundMarkers: IMeterMarker[],
  activeUnitId: string,
  changeType?: PositionChangeType
) {
  const allRoundsCompleted = roundMarkers.every(m =>
    [
      MeterMarkerProgressStatus.Completed,
      MeterMarkerProgressStatus.RecycleSkip // === completed before recycling
    ].includes(m.status)
  );

  const isActiveUnitCompleted =
    allRoundsCompleted && changeType !== PositionChangeType.UnitRecycling;
  const isPlacementCompletion =
    changeType === PositionChangeType.PlacementCompletion &&
    isActiveUnitCompleted;

  const activeUnitIdx = units.findIndex(u => u.sysId === activeUnitId);

  return units.map((u, i) => ({
    status: getMarkerStatus(
      i,
      isPlacementCompletion,
      activeUnitIdx,
      isActiveUnitCompleted
    ),
    sysId: u.sysId
  }));
}

function getMarkerStatus(
  markerIdx: number,
  isPlacementCompletion: boolean,
  activeUnitIdx: number,
  isActiveUnitCompleted: boolean
) {
  return isPlacementCompletion || markerIdx < activeUnitIdx
    ? MeterMarkerProgressStatus.Completed
    : markerIdx > activeUnitIdx
    ? MeterMarkerProgressStatus.Upcoming
    : isActiveUnitCompleted
    ? MeterMarkerProgressStatus.Completed
    : MeterMarkerProgressStatus.Active;
}
