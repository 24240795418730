import { ILevelEducator } from '@lexialearning/lobo-common';

export enum EducatorActionType {
  LoadLevels = 'EDUCATOR.LOAD_LEVELS',
  LoadLevelsSuccess = 'EDUCATOR.LOAD_LEVELS_SUCCESS',
  LoadLevelsFailure = 'EDUCATOR.LOAD_LEVELS_FAILURE',
  SelectLevelActivity = 'EDUCATOR.SELECT_LEVEL_ACTIVITY'
}

export interface IEducatorState {
  levels: ILevelEducator[];
}
