import { IScreenplay } from '@lexialearning/lobo-common';
import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Sfx } from 'audio/sfx';
import {
  ActivityPositionAction,
  LevelSelector,
  ProgramContextSelector
} from 'curriculum-services';
import {
  LevelSceneAnimationName,
  LevelSceneElementName
} from 'feature-areas/levels';
import { HomeShowScreenplayBuilder } from 'feature-areas/transitions/builders/home';
import { LevelShowScreenplayBuilder } from 'feature-areas/transitions/builders/levels';
import { RouterService, RoutePath } from 'router-service';
import {
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder,
  Screenplayer
} from 'screenplay';
import { ProfileSelector } from 'services/profile';
import { PreparedScenes, SceneName } from 'services/storm-lobo';
import { SreSessionAction, SreSessionActionCancel } from 'sre';
import { StormService } from 'storm';
import { AppShellAction, AppShellActionNavigateBack } from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';
import { AppState } from 'services';

export interface IHandleNavBackEpicDependencies {
  preparedScenes: PreparedScenes;
  screenplayer: Screenplayer;
  stormService: StormService;
}

export enum NavBackScreenplayId {
  Educator = 'navBackToEducator',
  Level = 'navBackToLevel',
  Home = 'navBackToHome'
}

export function handleNavBackEpic(
  action$: Observable<AppShellActionNavigateBack>,
  state$: StateObservable<AppState>,
  deps: IHandleNavBackEpicDependencies
): Observable<SreSessionActionCancel | ScreenplayActionPlay> {
  return action$.pipe(
    ofType(AppShellActionType.NavigateBack),
    map(() => {
      if (deps.preparedScenes.taskDemo) {
        deps.stormService.unloadScene(deps.preparedScenes.taskDemo.scene);
        deps.preparedScenes.taskDemo = undefined;
      }
    }),
    map(() => {
      // NavButton on home page is a logout button, so BackButton would only be
      // pressed from that route when in AvatarEditor.
      // We want to ignore this action when called from AvatarEditor as rerouting
      // is instead handled via the AvatarEditorAction.saveAndExit() action
      if (RouterService.isOnRoute(RoutePath.Home)) {
        return;
      }

      const shouldGoBackToEducator = !ProfileSelector.isStudent(state$.value);

      return shouldGoBackToEducator
        ? NavBackScreenplayId.Educator
        : shouldGoBackToLevel()
        ? NavBackScreenplayId.Level
        : NavBackScreenplayId.Home;
    }),
    filter(
      screenplayId =>
        !!screenplayId && !deps.screenplayer.isPlaying(screenplayId)
    ),
    map(screenplayId =>
      createNavBackScreenplay(screenplayId!, deps.preparedScenes, state$.value)
    ),
    mergeMap(screenplay =>
      from([
        SreSessionAction.cancel.request(),
        ScreenplayAction.play({ screenplay })
      ])
    )
  );
}
handleNavBackEpic.displayName = 'handleNavBackEpic';

function shouldGoBackToLevel() {
  return RouterService.isOnRoute([
    RoutePath.Acts,
    RoutePath.EncounterComplete,
    RoutePath.Rounds,
    RoutePath.UnitComplete
  ]);
}

function createNavBackScreenplay(
  id: string,
  preparedScenes: PreparedScenes,
  state: unknown
): IScreenplay {
  const builder = ScreenplayBuilder.create(id).addSfx(Sfx.Neutral);

  switch (id) {
    case NavBackScreenplayId.Level:
      return createNavBackToLevelScreenplay(builder, preparedScenes, state);
    case NavBackScreenplayId.Home:
      return createNavBackToHomeScreenplay(builder, preparedScenes, state);
    case NavBackScreenplayId.Educator:
    default:
      return createNavBackToEducatorScreenplay(builder);
  }
}

function createNavBackToLevelScreenplay(
  builder: ScreenplayBuilder,
  preparedScenes: PreparedScenes,
  state: unknown
): IScreenplay {
  const levelId = LevelSelector.getLevelId(state);
  const levelShowScreenplay = LevelShowScreenplayBuilder.createFor({
    levelId,
    preparedScenes,
    shouldDeselectActivity: true
  }).screenplay;

  const leavingActPage = RouterService.isOnRoute([
    RoutePath.Acts,
    RoutePath.EncounterComplete
  ]);

  if (shouldChangeActivityPosition(state)) {
    builder.addReduxAction(
      ActivityPositionAction.change({ suppressUnitLoad: true })
    );
  }

  if (leavingActPage) {
    builder.addStormAnimation({
      name: LevelSceneAnimationName.FunFactsEffects.Outro,
      targetElement: LevelSceneElementName.Effects,
      targetScene: SceneName.Level
    });
  }

  return builder.addScreenplay(levelShowScreenplay).screenplay;
}

/**
 * True if the activity has an imminent position (meaning the user is transitioning
 * to a new activity position), so that if the user returns to the activity,
 * they are properly positioned.
 */
function shouldChangeActivityPosition(state: unknown): boolean {
  const hasImminentPosition =
    !!ProgramContextSelector.getRoundContextMaybe(state)?.activityPosition
      .imminentPosition;

  return hasImminentPosition;
}

function createNavBackToHomeScreenplay(
  builder: ScreenplayBuilder,
  preparedScenes: PreparedScenes,
  state: unknown
): IScreenplay {
  const levelNum = LevelSelector.getLevelNumber(state);
  const homeShowScreenplay = HomeShowScreenplayBuilder.createFor({
    levelNum,
    preparedScenes
  }).screenplay;

  return builder.addScreenplay(homeShowScreenplay).screenplay;
}

function createNavBackToEducatorScreenplay(
  builder: ScreenplayBuilder
): IScreenplay {
  return builder.addReduxAction(AppShellAction.navigateBackToEducator())
    .screenplay;
}
