import { ActivityPositionAction, RoundContext } from 'curriculum-services';
import { ActivityCarouselModal } from 'feature-areas/educator';
import { AppShellAction } from 'feature-areas/shell';
import { EducatorTabName, RouteBuilder, RouterService } from 'router-service';
import { TransitionScreenplayBuilderBase } from '../builders/TransitionScreenplayBuilderBase';
import { TransitionScreenplayId } from '../transition.model';

export class EncounterToEducatorTransitionBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'EncounterToEducatorTransitionBuilder';

  public static create(): EncounterToEducatorTransitionBuilder {
    return new EncounterToEducatorTransitionBuilder();
  }

  private constructor() {
    super(TransitionScreenplayId.EncounterToEducator);
  }

  public navToEducator(): EncounterToEducatorTransitionBuilder {
    this.builder.addCallback(() => {
      RouterService.history.replace(
        RouteBuilder.educator(EducatorTabName.Level)
      );
    });

    return this;
  }

  /**
   * Reset imminentPosition to undefined if is currently defined
   */
  public resetImminentPositionMaybe(
    context: RoundContext
  ): EncounterToEducatorTransitionBuilder {
    const { activityPosition } = context;

    if (activityPosition.imminentPosition) {
      this.builder.addReduxAction(
        ActivityPositionAction.changed({
          ...activityPosition,
          imminentPosition: undefined
        })
      );
    }

    return this;
  }

  public showActivityCarouselModal(): EncounterToEducatorTransitionBuilder {
    this.builder.addReduxAction(
      AppShellAction.showModal({ id: ActivityCarouselModal.ModalId })
    );

    return this;
  }
}
