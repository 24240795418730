import { Circle, Defs, G, Path, RadialGradient, Stop, Svg } from 'common-ui';

export function SparkleSvg() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.797"
      height="30.202"
      viewBox="0 0 30.797 30.202"
    >
      <Defs>
        <RadialGradient
          id="radial-gradient-1"
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor="#379fec" />
          <Stop offset="0.74" stopColor="#379fec" stopOpacity="0.196" />
          <Stop offset="1" stopColor="#379fec" stopOpacity="0" />
        </RadialGradient>
        <RadialGradient
          id="radial-gradient-2"
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <Stop offset="0" stopColor="#0289e1" />
          <Stop offset="1" stopColor="#379fec" stopOpacity="0" />
        </RadialGradient>
      </Defs>
      <G id="sparkle" transform="translate(-735 -129)">
        <Circle
          id="Ellipse_323"
          cx="7.739"
          cy="7.739"
          r="7.739"
          transform="translate(742.937 136.54)"
          fill="url(#radial-gradient-1)"
        />
        <Path
          id="Union_14"
          d="M14.406,16.668,5.4,25l8.215-8.89L0,15.081l13.573-1.032L5.4,5.2l9.009,8.334L15.438,0,16.47,13.493,25.439,5.2l-8.175,8.85L30.8,15.081,17.224,16.113,25.439,25,16.47,16.708,15.438,30.2Z"
          transform="translate(735 129)"
          fill="url(#radial-gradient-2)"
        />
      </G>
    </Svg>
  );
}
