export class AvatarEditorTimeoutModalStyles {
  private static readonly styles = {
    buttonContainer: {
      marginTop: 37
    },
    container: {
      height: 417,
      width: 500
    },
    image: {
      height: 195,
      width: 480
    },
    imageContainer: {
      height: 195,
      marginBottom: 10,
      marginTop: 47,
      width: 480
    },
    title: {
      marginTop: 40
    }
  };

  public static get() {
    return this.styles;
  }
}
