import { Position } from '@lexialearning/common-ui';
import { Color } from 'common-styles';

export class GabberPromptStyles {
  public static get() {
    return {
      container: {
        backgroundColor: Color.NearWhiteTransparent70,
        borderRadius: 33,
        height: 52,
        marginBottom: 15,
        width: 147
      },
      gabber: {
        height: 65,
        left: -35,
        position: Position.Absolute,
        width: 62.5
      }
    };
  }
}
