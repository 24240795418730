import {
  ISreAppConfig,
  SoundLogCollection
} from '@lexialearning/lobo-common/main-model/sre';
import { ISoundLogConfig, SoundLogSaveLocation, Sre } from '@lexialearning/sre';

/**
 * Configure sound log collection mode. Sound log audio is considered PII
 * (personally identifiable information), so we allow customers to opt out of
 * sound log collection. It may also have network and performance implications,
 * so we may want to fully disable in some cases.
 */
export class SoundLogConfigurator {
  public static readonly displayName = 'SoundLogConfigurator';

  private static readonly SaveLocationMap: Record<
    SoundLogCollection,
    SoundLogSaveLocation
  > = {
    Full: SoundLogSaveLocation.Remote,
    FullLossless: SoundLogSaveLocation.Remote,
    Metadata: SoundLogSaveLocation.RemoteNoAudio,
    None: SoundLogSaveLocation.Disabled
  };

  public sre?: Sre;

  public constructor(
    private readonly getConfig: () => ISreAppConfig,
    private readonly sessionId: string
  ) {}

  /**
   * This method might be called before the SRE has initialized, before it's
   * been configured, or after it's been configured (depending on whether
   * customer settings are processed first etc). It is a no-op when called
   * before the SRE has been configured, since it is anyway called as part of
   * the SRE configuration process, at which point we'll get the latest sound
   * log collection mode from the app config state (via this.getConfig()).
   */
  public configure(): void {
    if (!this.sre?.isConfigured()) {
      return;
    }

    this.sre.configureSoundLogCollection(this.createConfig());
  }

  public createConfig(): ISoundLogConfig {
    const {
      appId,
      soundLogUrl: savePath,
      soundLogCollectionMode: mode
    } = this.getConfig();

    const saveLocation: SoundLogSaveLocation =
      SoundLogConfigurator.SaveLocationMap[mode];

    return {
      appId,
      maxAudioBytes: Math.round(2.5 * 1024 * 1024),
      saveLocation,
      savePath,
      userId: this.sessionId // or over 3 minutes of flac
    };
  }
}
