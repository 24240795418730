import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommonUiAction,
  CommonUiActionSetKeydownHandlingDisabled
} from 'common-ui/redux/CommonUi.action';
import {
  AppShellActionHideModal,
  AppShellActionShowModal
} from '../AppShell.action';
import { AppShellActionType } from '../AppShellActionType';

export function toggleKeydownHandlingDisabledEpic(
  action$: Observable<AppShellActionShowModal | AppShellActionHideModal>
): Observable<CommonUiActionSetKeydownHandlingDisabled> {
  return action$.pipe(
    ofType(AppShellActionType.ShowModal, AppShellActionType.HideModal),
    map(action =>
      CommonUiAction.setKeydownHandlingDisabled({
        isKeydownHandlingDisabled: action.type === AppShellActionType.ShowModal
      })
    )
  );
}
toggleKeydownHandlingDisabledEpic.displayName =
  'toggleKeydownHandlingDisabledEpic';
