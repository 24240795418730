import { Cursor } from '@lexialearning/common-ui';
import { Justify } from 'common-styles';
import { CONSTANTS } from 'common-ui';

export class ActColumnStyles {
  private static readonly styles = {
    column: {
      cursor: Cursor.Pointer,
      flex: 1,
      justifyContent: Justify.End,
      maxWidth: CONSTANTS.BaseDimensions.Width / 2,
      paddingBottom: 50
    }
  };

  public static get() {
    return this.styles;
  }
}
