import { IScreenplay } from '@lexialearning/lobo-common';
import { StateObservable } from 'redux-observable';
import {
  ProgramContextSelector,
  ProgramContextService
} from 'curriculum-services';
import { RouteBuilder, RouterService } from 'router-service';
import { ScreenplayBuilder } from 'screenplay';
import { PreparedScenes } from 'services/storm-lobo';
import { ActOutroScreenplayBuilder } from '../builders/acts';
import { TransitionScreenplayId } from '../transition.model';

export interface IActToEncounterTransitionScreenplayDeps {
  preparedScenes: PreparedScenes;
  programContextService: ProgramContextService;
  state$: StateObservable<unknown>;
}

/**
 * Functional spec:
 * https://jira.lexialearning.com/wiki/display/ELKMK/Showcase+to+Encounter+transition
 */
export class ActToEncounterTransitionScreenplayFactory {
  public static readonly displayName =
    'ActToEncounterTransitionScreenplayFactory';

  public static createFor(
    deps: IActToEncounterTransitionScreenplayDeps
  ): ActToEncounterTransitionScreenplayFactory {
    return new ActToEncounterTransitionScreenplayFactory(deps);
  }

  private readonly builder: ScreenplayBuilder;

  public get screenplay(): IScreenplay {
    return this.builder.screenplay;
  }

  private constructor(
    private readonly deps: IActToEncounterTransitionScreenplayDeps
  ) {
    this.builder = ScreenplayBuilder.create(
      TransitionScreenplayId.ActToEncounter
    );

    this.playActivityOutro().navToRound();
  }

  private playActivityOutro(): ActToEncounterTransitionScreenplayFactory {
    const { programContextService, state$ } = this.deps;

    this.builder.addScreenplay(
      ActOutroScreenplayBuilder.createFor({
        programContextService,
        state$
      }).screenplay
    );

    return this;
  }

  private navToRound(): ActToEncounterTransitionScreenplayFactory {
    const { state$ } = this.deps;
    this.builder.addCallback(() => {
      const roundContext = ProgramContextSelector.getRoundContext(state$.value);
      RouterService.history.replace(
        RouteBuilder.rounds(
          roundContext.parentUnit.sysId,
          roundContext.round.sysId
        )
      );
    });

    return this;
  }
}
