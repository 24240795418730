import { useActiveComponentState } from '@lexialearning/common-ui';
import { PressScaleButton } from 'common-ui';
import { ToolTipButtonStyles } from './ToolTipButton.styles';
import { QuestionMarkSvg } from 'feature-areas/avatar-editor/instruction-button/svgs/QuestionMark.lx-svg';
import { ToolTipDialog } from '../tool-tip-dialog/ToolTipDialog';

interface IToolTipButtonProps {
  isToolTipDialogShowing: boolean;
  onPress: () => void;
}

export function ToolTipButton(props: IToolTipButtonProps) {
  const { isToolTipDialogShowing, onPress } = props;
  const styles = ToolTipButtonStyles.get();

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState();

  return (
    <PressScaleButton
      {...interactionHandlers}
      accessibilityState={{ expanded: isToolTipDialogShowing }}
      ariaControls={ToolTipDialog.displayName}
      disabledOpacity={0.3}
      hoverScale={1.02}
      onPress={onPress}
      pressScale={0.98}
      style={styles.button}
      testId={ToolTipButton.displayName}
    >
      <QuestionMarkSvg
        accessibilityLabel="Why avatar edit is disabled"
        activeComponentState={activeComponentState}
        size={styles.svg.size}
      />
    </PressScaleButton>
  );
}
ToolTipButton.displayName = 'ToolTipButton';
