import { CalibrationResult } from '@lexialearning/sre';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sfx } from 'audio';
import { TransitionAction } from 'feature-areas/transitions';
import { RouterService, RoutePath } from 'router-service';
import {
  IPlayActionPayload,
  ScreenplayAction,
  ScreenplayActionPlay,
  ScreenplayBuilder
} from 'screenplay';
import { BootstrapContentSelector } from 'services/bootstrapping/bootstrap-content';
import { SceneName } from 'services/storm-lobo';
import { AppShellAction } from 'shell';
import {
  SreCalibrationActionComplete,
  SreCalibrationActionType,
  SreSelector
} from 'sre';
import {
  CalibrationSceneAnimationName,
  CalibrationSceneElementName
} from '../../calibration-scene/calibration-scene.model';
import { CalibrationStep } from '../calibration-ui-redux.model';
import { CalibrationUiAction } from '../CalibrationUi.action';
import { CalibrationUiSelector } from '../CalibrationUi.selector';
import { AppState } from 'services';

export function playCalibrationFeedbackEpic(
  action$: Observable<SreCalibrationActionComplete>,
  state$: StateObservable<AppState>
): Observable<ScreenplayActionPlay> {
  return action$.pipe(
    ofType(SreCalibrationActionType.Complete),
    map(() => {
      const state = state$.value;
      const calibrationResult = SreSelector.getCalibrationResult(state);

      if (calibrationResult !== CalibrationResult.Ok) {
        return { screenplay: buildNegativeFeedbackScreenplay(state) };
      }

      return {
        // Setting this as next action to make sure concurrent actions have
        // completed before dispatching it
        nextAction: getNextAction(),

        screenplay: buildPositiveFeedbackScreenplay(state)
      };
    }),
    map((payload: IPlayActionPayload) => ScreenplayAction.play(payload))
  );
}
playCalibrationFeedbackEpic.displayName = 'playCalibrationFeedbackEpic';

function buildNegativeFeedbackScreenplay(state: unknown) {
  const calibrationResult = SreSelector.getCalibrationResult(state);

  const builder = ScreenplayBuilder.create('calibrationResult_negative')
    .addReduxAction(
      CalibrationUiAction.setStep({ step: CalibrationStep.Feedback })
    )
    .addSfx(Sfx.Unheard, { concurrent: true });

  if (isCalibrationPage()) {
    const animationName =
      calibrationResult === CalibrationResult.TooLoud
        ? CalibrationSceneAnimationName.Gabber.TooLoud
        : calibrationResult === CalibrationResult.TooSoft
        ? CalibrationSceneAnimationName.Gabber.TooQuiet
        : CalibrationSceneAnimationName.Gabber.Negative;

    builder
      .addStormAnimation({
        blendTimeSeconds: 0.25,
        name: animationName,
        targetElement: CalibrationSceneElementName.Gabber,
        targetScene: SceneName.Calibration
      })
      .addStormAnimation(
        {
          blendTimeSeconds: 0.25,
          loop: true,
          name: CalibrationSceneAnimationName.Gabber.Idle,
          targetElement: CalibrationSceneElementName.Gabber,
          targetScene: SceneName.Calibration
        },
        { concurrent: true }
      );
  }

  const isRetry = CalibrationUiSelector.getIsRetry(state);
  const allowRetry =
    calibrationResult !== CalibrationResult.NoSignal && !isRetry;

  builder.addReduxAction(
    CalibrationUiAction.setStep({
      step: allowRetry ? CalibrationStep.TryAgain : CalibrationStep.MicFix
    })
  );

  return builder.screenplay;
}

function buildPositiveFeedbackScreenplay(state: unknown) {
  const resolutionVo =
    BootstrapContentSelector.getCalibrationScreenContent(state)!.feedback
      .resolution;

  const builder = ScreenplayBuilder.create(
    'calibrationResult_ok'
  ).addReduxAction(
    CalibrationUiAction.setStep({ step: CalibrationStep.Feedback })
  );

  if (isCalibrationPage()) {
    builder.addStormAnimation(
      {
        blendTimeSeconds: 0.25,
        name: CalibrationSceneAnimationName.Gabber.Positive,
        targetElement: CalibrationSceneElementName.Gabber,
        targetScene: SceneName.Calibration
      },
      { concurrent: true }
    );
  }

  builder.addSfx(Sfx.Correct).addScreenplay(resolutionVo);

  return builder.screenplay;
}

// Note! This function needs to be constructed like this or else iOS fails in production with
// Attempted to assign to a readonly property (LOBO-14400)
function getNextAction() {
  const action = isCalibrationPage()
    ? TransitionAction.calibrationToNext()
    : AppShellAction.hideModal();

  return action;
}

function isCalibrationPage() {
  return RouterService.isOnRoute(RoutePath.Calibration);
}
