import {
  IScreenplay,
  IScreenplayAction
} from '@lexialearning/lobo-common/main-model';
import { LexiaError } from '@lexialearning/utils';
import { Action } from 'redux';
import { IMusicRequest } from 'audio/music';
import { Sfx } from 'audio/sfx';
import {
  CallbackActionFactory,
  DelayScreenplayActionFactory,
  IActionOptions,
  IAnimationRequest,
  IReactAnimationRequest,
  IScreenplayActionFactory,
  IScreenplayCallback,
  ReactAnimationScreenplayActionFactory,
  ReduxDispatchActionFactory,
  ScreenplayerType
} from './screenplayers';

type ActionFactoryMap = Map<ScreenplayerType, IScreenplayActionFactory>;

/**
 * Builds an IScreenplay
 */
export class ScreenplayBuilder {
  public static readonly displayName = 'ScreenplayBuilder';

  public static readonly DelayMsDefault =
    DelayScreenplayActionFactory.DefaultDelayMs;

  /**
   * This map can be altered by the consuming application to register a set
   * of action factories that can be used by all future instances of the class.
   * This should be done during the application bootstrapping phase.
   */
  public static readonly defaultActionFactories =
    ScreenplayBuilder.createDefaultActionFactories();

  private static createDefaultActionFactories(): ActionFactoryMap {
    const map = new Map();

    map.set(ScreenplayerType.Callback, new CallbackActionFactory());
    map.set(ScreenplayerType.Delay, new DelayScreenplayActionFactory());
    map.set(ScreenplayerType.ReduxDispatcher, new ReduxDispatchActionFactory());

    return map;
  }

  public readonly screenplay: IScreenplay;

  public readonly actionFactories: ActionFactoryMap;

  // Should not be instantiated directly, instead, to create a screenplay
  // the .create or .from method should be used
  private constructor(id: string, screenplay?: IScreenplay) {
    this.screenplay = screenplay || { actions: [], id };
    this.actionFactories = new Map(ScreenplayBuilder.defaultActionFactories);
  }

  public static create(id: string): ScreenplayBuilder {
    return new ScreenplayBuilder(id);
  }

  public static from(
    source?: IScreenplay | (IScreenplay | undefined)[],
    id?: string
  ): ScreenplayBuilder {
    return Array.isArray(source)
      ? new ScreenplayBuilder(id || source[0]?.id || '').addScreenplayList(
          source
        )
      : new ScreenplayBuilder(id || source?.id || '', source as any);
  }

  public addAction(
    action: IScreenplayAction | undefined,
    options?: Partial<IActionOptions>
  ): ScreenplayBuilder {
    (this.screenplay as any).actions = action
      ? [...this.screenplay.actions, { ...action, ...options }]
      : this.screenplay.actions;

    return this;
  }

  public addActionList(actions: IScreenplayAction[]): ScreenplayBuilder {
    (this.screenplay as any).actions = this.screenplay.actions
      .concat(actions)
      .filter(a => !!a);

    return this;
  }

  public addReactAnimation(
    animationName: string,
    actionOptions?: Partial<IActionOptions> | undefined,
    options?: Partial<IReactAnimationRequest> | undefined
  ): ScreenplayBuilder {
    const action = ReactAnimationScreenplayActionFactory.create(
      animationName,
      {
        awaitRegistrationTimeoutMs: 500,
        ...options
      },
      actionOptions
    );

    return this.addAction(action);
  }

  public addCallback(
    fn: () => void,
    options?: Partial<Exclude<IScreenplayCallback, 'fn'>>,
    actionOptions?: Partial<IActionOptions>
  ): ScreenplayBuilder {
    const callbackFactory = this.getFactory(ScreenplayerType.Callback);

    return this.addAction(
      callbackFactory.create({ fn, ...options }, actionOptions)
    );
  }

  public addDelay(delayMs: number): ScreenplayBuilder {
    const delayFactory = this.getFactory(ScreenplayerType.Delay);

    return this.addAction(delayFactory.create({ delayMs }));
  }

  public addMusic(
    data: IMusicRequest,
    options?: Partial<IActionOptions>
  ): ScreenplayBuilder {
    const factory = this.getFactory(ScreenplayerType.Music);

    return this.addAction(factory.create(data), options);
  }

  public addReduxAction(reduxAction: Action): ScreenplayBuilder {
    const dispatchFactory = this.getFactory(ScreenplayerType.ReduxDispatcher);

    return this.addAction(dispatchFactory.create(reduxAction));
  }

  public addScreenplayList(
    screenplays: (IScreenplay | undefined)[]
  ): ScreenplayBuilder {
    screenplays
      .filter(s => s)
      .forEach(s => {
        this.addScreenplay(s!);
      });

    return this;
  }

  public addScreenplay(screenplay: IScreenplay | undefined): ScreenplayBuilder {
    if (!screenplay) {
      return this;
    }

    (this.screenplay as any).actions = this.screenplay.actions.concat(
      screenplay.actions
    );

    return this;
  }

  public addSfx(
    nameOrPath: Sfx | string,
    options?: Partial<IActionOptions>
  ): ScreenplayBuilder {
    if (!nameOrPath) {
      return this;
    }

    const sfxFactory = this.getFactory(ScreenplayerType.Sfx);

    return this.addAction(sfxFactory.create({ path: nameOrPath }), options);
  }

  public addStormAnimation(
    request: IAnimationRequest | undefined,
    actionOptions?: Partial<IActionOptions> | undefined
  ): ScreenplayBuilder {
    const factory = this.getFactory(ScreenplayerType.StormAnimation);

    return !request
      ? this
      : this.addAction(factory.create(request, actionOptions));
  }

  public createAndAddAction<T extends object>(
    type: string,
    data: T,
    options?: Partial<IActionOptions>
  ): ScreenplayBuilder {
    this.addAction({ data, type, ...options });

    return this;
  }

  public usingActionFactories(
    ...factories: IScreenplayActionFactory[]
  ): ScreenplayBuilder {
    factories.forEach(f => this.actionFactories.set(f.type, f));

    return this;
  }

  private getFactory(type: ScreenplayerType): IScreenplayActionFactory {
    const factory = this.actionFactories.get(type);

    if (!factory) {
      throw new LexiaError(
        `Cannot add '${type}' action because action factory is missing.`,
        ScreenplayBuilder.displayName,
        ScreenplayBuilderError.MissingFactory
      ).withContext({ type });
    }

    return factory;
  }
}

export enum ScreenplayBuilderError {
  MissingFactory = 'MissingFactory'
}
