import * as React from 'react';
import { connect } from 'react-redux';
import { Text } from 'common-ui';
import { AuthAction, AuthSelector } from 'services/auth';
import { ModalActions, ModalRegistry, ModalType } from 'shared-components';
import { AppShellAction } from 'shell';
import { LoginFormSharedStyles } from '../LoginFormShared.styles';
import { Logger } from '@lexialearning/utils-react';
import { LoboLogItemCategory } from '@lexialearning/lobo-common/main-model/logging';
import { LoggingLevel } from '@lexialearning/main-model';

export interface ILoginConfirmationModalProps {
  authName: string;
  close(): void;
  confirm(): void;
  deny(): void;
}

export class LoginConfirmationModalComponent extends React.PureComponent<ILoginConfirmationModalProps> {
  public static readonly displayName = 'LoginConfirmationModal';

  private noResponseTimeout?: NodeJS.Timeout;

  constructor(props: ILoginConfirmationModalProps) {
    super(props);

    this.handlePressYes = this.handlePressYes.bind(this);
    this.handlePressNo = this.handlePressNo.bind(this);
  }

  public componentDidMount() {
    /**
     * Close the modal after 90 seconds to be sure the user doesn't get to
     * select Yes with an expired token
     */
    this.noResponseTimeout = setTimeout(() => {
      this.handlePressNo();
    }, 90 * 1000);
  }

  public componentWillUnmount() {
    // istanbul ignore else - trivial
    if (this.noResponseTimeout) {
      clearTimeout(this.noResponseTimeout);
    }
  }

  private handlePressYes() {
    void Logger.log({
      category: LoboLogItemCategory.LoginUserConfirmed,
      loggingLevel: LoggingLevel.Info,
      payload: {},
      summary: "'Yes' button pressed in LoginConfirmationModal"
    });

    const { close, confirm } = this.props;
    confirm();
    close();
  }

  private handlePressNo() {
    void Logger.log({
      category: LoboLogItemCategory.LoginUserDenied,
      loggingLevel: LoggingLevel.Info,
      payload: {},
      summary: "'No' button pressed in LoginConfirmationModal"
    });

    const { close, deny } = this.props;
    close();
    deny();
  }

  public render() {
    const { authName } = this.props;

    return (
      <>
        <Text style={LoginFormSharedStyles.get().confirmationText}>
          {authName}
        </Text>
        <ModalActions
          primaryAction={{
            onPress: this.handlePressYes,
            text: 'Yes'
          }}
          secondaryAction={{
            onPress: this.handlePressNo,
            text: 'No'
          }}
        />
      </>
    );
  }
}

// istanbul ignore next - trivial
function mapStateToProps(state: unknown) {
  return {
    authName: AuthSelector.getAuthNameMaybe(state)
  };
}

// istanbul ignore next - trivial
const mapDispatchToProps = {
  close: () => AppShellAction.hideModal(),
  confirm: () => AuthAction.loginUser.request(),
  deny: () => AuthAction.unauthenticate()
};

export const LoginConfirmationModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginConfirmationModalComponent);

LoginConfirmationModal.ModalId = ModalRegistry.register({
  Component: LoginConfirmationModal,
  ariaLabel: 'Login Confirmation',
  headline: 'Is this your name?',
  type: ModalType.Confirmation
});
