import { IScreenplayAction } from '@lexialearning/lobo-common/main-model';
import {
  IActionOptions,
  IAnimationRequest,
  IScreenplayActionFactory,
  ScreenplayerType
} from 'screenplay';

export class StormAnimationActionFactory implements IScreenplayActionFactory {
  public readonly type = ScreenplayerType.StormAnimation;

  public create(
    request: IAnimationRequest,
    actionOptions?: Partial<IActionOptions>
  ): IScreenplayAction<IAnimationRequest> {
    return {
      type: ScreenplayerType.StormAnimation,
      ...actionOptions,
      data: request
    };
  }
}
