import { IScreenplay } from '@lexialearning/lobo-common';
import { Sfx } from 'audio';
import { Music } from 'audio/music';
import { ExcellingLevelDeterminer } from 'curriculum-services/program-context/service-helpers';
import {
  HomeReactAnimationName,
  HomeSceneAnimationName,
  HomeSceneElementName
} from 'feature-areas/home';
import { GradeName } from 'lexia-service';
import { PreparedScenes } from 'services/storm-lobo';
import { SceneName } from 'services/storm-lobo/StormAssets';
import { StormService } from 'storm';
import { TransitionScreenplayBuilderBase } from '../TransitionScreenplayBuilderBase';
import { HomeScreenplayId } from './home-screenplay-builders.model';

export interface IHomeIntroScreenplayDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
  levelNum: number;
  grade: GradeName;
  micPromptVo?: IScreenplay;
}

/**
 * Creates a screenplay for the animated intro to the home page
 * - running storm and react animations, and playing voiceovers
 */
export class HomeIntroScreenplayBuilder extends TransitionScreenplayBuilderBase {
  public static readonly displayName = 'HomeIntroScreenplayBuilder';

  public static createFor(
    deps: IHomeIntroScreenplayDeps
  ): HomeIntroScreenplayBuilder {
    return new HomeIntroScreenplayBuilder(deps);
  }

  private constructor(deps: IHomeIntroScreenplayDeps) {
    super(HomeScreenplayId.Intro);

    const { grade, levelNum, micPromptVo, preparedScenes, stormService } = deps;
    this.showHomeScene(preparedScenes, stormService)
      .playHomeIntro()
      .fadeInReactElements()
      .playLevelMeterIntro(levelNum, grade)
      .enableUtilityBar()
      .enableMic()
      .playMicPrompt(micPromptVo);
  }

  private showHomeScene(
    preparedScenes: PreparedScenes,
    stormService: StormService
  ): HomeIntroScreenplayBuilder {
    this.builder.addCallback(
      async () => {
        const homeScene = await preparedScenes.homeReady;
        homeScene.show();

        // TODO: https://jira.lexialearning.com/browse/LOBO-15621
        if (preparedScenes.calibration) {
          stormService.unloadScene(preparedScenes.calibration.scene);
          preparedScenes.calibration = undefined;
        }
      },
      { shouldUseLoadingSpinner: true }
    );

    return this;
  }

  private playHomeIntro(): HomeIntroScreenplayBuilder {
    this.builder.addStormAnimation(
      {
        name: HomeSceneAnimationName.Root.Intro,
        targetScene: SceneName.Home
      },
      { concurrent: true }
    );

    return this;
  }

  private fadeInReactElements(): HomeIntroScreenplayBuilder {
    this.builder.addReactAnimation(HomeReactAnimationName.FadeIn, {
      concurrent: true
    });

    return this;
  }

  private playLevelMeterIntro(
    levelNum: number,
    grade: GradeName
  ): HomeIntroScreenplayBuilder {
    this.playLevelMeterSparklesIntroMaybe(levelNum, grade);

    this.builder
      .addMusic({ path: Music.HomeIntro }, { concurrent: true })
      .addStormAnimation(
        {
          name: HomeSceneAnimationName.LevelMeter.buildIntro(levelNum),
          targetElement: HomeSceneElementName.LevelMeter,
          targetScene: SceneName.Home
        },
        { concurrent: true }
      )
      .addDelay(3000)
      .addSfx(Sfx.Flare, { concurrent: true })
      .addStormAnimation({
        name: HomeSceneAnimationName.LevelMeter.buildStart(levelNum),
        targetElement: HomeSceneElementName.LevelMeter,
        targetScene: SceneName.Home
      });

    return this;
  }

  private playLevelMeterSparklesIntroMaybe(
    levelNum: number,
    grade: GradeName
  ): HomeIntroScreenplayBuilder {
    const isExcellingLevel = ExcellingLevelDeterminer.isExcellingLevel(
      grade,
      levelNum
    );

    if (isExcellingLevel) {
      this.builder.addStormAnimation(
        {
          loop: true,
          name: HomeSceneAnimationName.LevelMeterSparkles.Intro,
          targetElement: HomeSceneElementName.LevelMeterSparkles,
          targetScene: SceneName.Home
        },
        { concurrent: true }
      );
    }

    return this;
  }

  private playMicPrompt(
    micPromptVo: IScreenplay | undefined
  ): HomeIntroScreenplayBuilder {
    this.builder.addDelay(500).addScreenplay(micPromptVo);

    return this;
  }
}
