import { IAct } from '@lexialearning/lobo-common';
import { capitalize } from 'lodash';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  ActSelector,
  LevelAction,
  LevelActionReady,
  LevelSelector,
  ProgramContextSelector
} from 'curriculum-services';
import { CharacterName, PreparedScenes } from 'services/storm-lobo';
import { StormService } from 'storm';
import { LevelSceneLayout } from '../level-scene.model';
import { LevelScene } from '../LevelScene';
import { LevelSceneDefinitionBuilder } from '../LevelSceneDefinitionBuilder';
import { LevelSceneActionType, LevelScenePrepareAction } from '../redux';

export interface IPrepareLevelSceneDeps {
  preparedScenes: PreparedScenes;
  stormService: StormService;
}

export function prepareLevelSceneEpic(
  action$: Observable<LevelScenePrepareAction>,
  state$: StateObservable<unknown>,
  deps: IPrepareLevelSceneDeps
): Observable<LevelActionReady> {
  return action$.pipe(
    ofType(LevelSceneActionType.Prepare),
    mergeMap(async action => {
      const state = state$.value;
      const { preparedScenes, stormService } = deps;
      preparedScenes.level = undefined;

      const level = LevelSelector.getLevel(state);
      const incompleteActs = getIncompleteActs(level.acts, state);

      const builder = LevelSceneDefinitionBuilder.create(
        level.title,
        incompleteActs
      )
        .withBackground(level.sceneBackground)
        .withBackgroundOverlay()
        .withLighting()
        .withActMeters()
        .withShowcase()
        .withCharacters()
        .withCharacterFunFactEffect();

      const { layout } = action.payload;
      let actComplete: IAct | undefined;
      if (layout === LevelSceneLayout.ActComplete) {
        actComplete = ActSelector.getAct(state);
        builder.withCompletedCharacter(
          capitalize(actComplete.character.code) as CharacterName,
          actComplete.characterSetNumber
        );
      }

      const { definition } = builder;

      await stormService.initialization;
      const scene = await stormService.prepareScene(definition);
      const levelScene = new LevelScene(
        state$,
        stormService,
        scene,
        incompleteActs!,
        actComplete,
        layout
      );

      if (layout === LevelSceneLayout.Showcase) {
        const act = ActSelector.getAct(state);
        levelScene.prepareShowcase(act);
      }

      preparedScenes.level = levelScene;

      await preparedScenes.levelReady;
    }),
    map(() => LevelAction.ready())
  );
}
prepareLevelSceneEpic.displayName = 'prepareLevelSceneEpic';

function getIncompleteActs(acts: IAct[], state: unknown): IAct[] {
  const activityPositionMap =
    ProgramContextSelector.getActivityPositionMap(state);

  return acts.filter(a => !activityPositionMap.get(a.sysId)?.isComplete);
}
