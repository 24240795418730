import { ILoopedActionRequest } from 'screenplay';

export interface IMusicRequest extends ILoopedActionRequest {
  path: string;
}

// TODO: Move this out of packages/audio to ?
export const Music = {
  ActComplete: 'act/act_complete',
  ActIntro: 'act/act_intro',
  AvatarEditor: 'avatar-editor/avatar_editor',
  BackgroundIntro: 'background-intro/background_intro',
  Branding: 'app-intro/branding',
  EncounterComplete: 'encounter/encounter_complete',
  HomeIntro: 'home/home_intro',
  HomeLevelIntro: 'home/home_level_intro',
  LevelComplete: 'level-complete/level_complete',
  LevelCompleteOutro: 'level-complete/level_complete_outro',
  PlacementComplete: 'placement/placement_complete',
  PlacementIntro: 'placement/placement_intro',
  PlacementUnitComplete: 'placement/placement_unit_complete',
  ProgramComplete: 'program-complete/program_complete',
  TaskDemo: {
    completion: 'task-demo/completion/demo_completion',
    textOrdering: 'task-demo/text-ordering/demo_text_ordering'
  },
  buildUnitComplete(rewardFileName: string) {
    return `unit-complete/${rewardFileName}`;
  }
};
