import { IUserChoice } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { IPositionChange, PositionChangeType } from 'curriculum-services';
import { UserChoicePositionDeterminer } from './UserChoicePositionDeterminer';

export class UserChoiceSkipPositionDeterminer extends UserChoicePositionDeterminer {
  public static readonly displayName = 'UserChoiceSkipPositionDeterminer';

  public determine(): IPositionChange {
    const task = this.context.getTask<IUserChoice>();
    // select first path when skip is pressed on user choice
    const path = task.paths[0];

    if (!path) {
      throw new LexiaError(
        'User choice task lacks paths',
        UserChoiceSkipPositionDeterminer.displayName,
        UserChoiceSkipPositionDeterminerError.TaskLacksPaths
      ).withContext({ task });
    }

    return {
      activityPosition: this.fork(path.pathUnit),
      changeType: PositionChangeType.Fork
    };
  }
}

export enum UserChoiceSkipPositionDeterminerError {
  TaskLacksPaths = 'TaskLacksPaths'
}
