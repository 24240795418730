import { LogoutIconSvg } from 'common-ui/graphics';
import { ModalActions } from 'shared-components/modals';
import { IErrorLogoutProps, withErrorLogout } from './withErrorLogout.hoc';

export function ReturnToLoginModalComponent(props: IErrorLogoutProps) {
  return (
    <ModalActions
      primaryAction={{
        Icon: LogoutIconSvg,
        ignoreGlobalDisabled: true,
        onPress: props.logout,
        text: 'Return to Login'
      }}
    />
  );
}
ReturnToLoginModalComponent.displayName = 'ReturnToLoginModal';

export const ReturnToLoginModal = withErrorLogout(ReturnToLoginModalComponent);
