import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppShellAction, AppShellActionShowModal } from 'feature-areas/shell';
import {
  PositionActionLevelActivitySelected,
  PositionActionType
} from 'curriculum-services';
import { ActivityCarouselModal } from '../level-tab-content/level-acts-carousel/ActivityCarouselModal';

export function showActivityCarouselModalEpic(
  action$: Observable<PositionActionLevelActivitySelected>
): Observable<AppShellActionShowModal> {
  return action$.pipe(
    ofType(PositionActionType.LevelActivitySelected),
    map(() => AppShellAction.showModal({ id: ActivityCarouselModal.ModalId }))
  );
}
showActivityCarouselModalEpic.displayName = 'showActivityCarouselModalEpic';
