import { loboAnimated, AnimatableCSSProperty, Color } from 'common-styles';
import {
  ActiveComponentState,
  Cursor,
  IViewStyle,
  Position
} from '@lexialearning/common-ui';
import { KeyNavHelper, Types } from 'common-ui';
import { duration as pressScaleDuration } from 'common-ui/components/button/press-scale-button/PressScaleButton.animated-styles';
import { Value } from '@lexialearning/reactxp/dist/web/Animated';

interface IFunFactButtonAnimatedValues {
  backgroundColor: Value;
  opacity: Types.AnimatedValue;
  scale: Types.AnimatedValue;
  translateX: Types.AnimatedValue;
  translateY: Types.AnimatedValue;
}
interface IFunFactButtonAnimations {
  entry: Types.Animated.CompositeAnimation;
  toDefault: Types.Animated.CompositeAnimation;
  hovered: Types.Animated.CompositeAnimation;
  keyboardFocused: Types.Animated.CompositeAnimation;
  keyboardPressed: Types.Animated.CompositeAnimation;
  pressed: Types.Animated.CompositeAnimation;
}

interface IFunFactButtonStyles {
  backgroundColorAnimated: {
    backgroundColor: Value;
  };
  opacityAnimated: {
    opacity: Types.AnimatedValue;
  };
  scaleAnimated: {
    transform: [{ scale: Types.AnimatedValue }];
  };
  translate: IViewStyle;
  translateAnimated: {
    transform: [
      { translateX: Types.AnimatedValue },
      { translateY: Types.AnimatedValue }
    ];
  };
}

const AnimationDuration = {
  ScaleOut: 233,
  ScaleIn: 100,
  /** Both a scale and color change */
  PulseOut: 133,
  /** Both a scale and color change */
  PulseIn: 200,
  PressIn: pressScaleDuration,
  SlideOut: 333
};
const ENTRY_ANIMATION_DELAY = 566;
const BACKGROUND_COLOR_ANIMATION_DELAY =
  ENTRY_ANIMATION_DELAY +
  AnimationDuration.ScaleOut +
  AnimationDuration.ScaleIn;
const enum ColorInterpolation {
  Default,
  Pulse,
  Pressed,
  Hovered,
  KeyboardFocused,
  KeyboardPressed
}

export const ButtonColor = {
  Default: Color.DeepBlue,
  Pulse: '#63D5FE',
  KeyboardFocused: Color.getSetFor(ActiveComponentState.KeyboardPressed)
    .background,
  KeyboardPressed: Color.getSetFor(ActiveComponentState.KeyboardPressed)
    .background,
  Pressed: '#1757CD',
  Hovered: '#3F9BEF'
};

export class FunFactButtonAnimatedStyles {
  private readonly animatedValues: IFunFactButtonAnimatedValues;
  private readonly animations: IFunFactButtonAnimations;

  private readonly backgroundColorInterpolation: Value;

  private readonly entryBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly hoverBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly keyboardFocusedBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly keyboardPressedBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly opacityAnimation: Types.Animated.CompositeAnimation;
  private readonly pressInBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly scaleAnimation: Types.Animated.CompositeAnimation;
  private readonly toDefaultBackgroundColorAnimation: Types.Animated.CompositeAnimation;
  private readonly translateAnimation: Types.Animated.CompositeAnimation;

  private readonly styles: IFunFactButtonStyles;

  constructor() {
    this.animatedValues = {
      backgroundColor: loboAnimated.createValue(0),
      opacity: loboAnimated.createValue(0),
      scale: loboAnimated.createValue(0.8),
      translateX: loboAnimated.createValue(0),
      translateY: loboAnimated.createValue(0)
    };

    this.backgroundColorInterpolation = loboAnimated.interpolate(
      this.animatedValues.backgroundColor,
      [
        ColorInterpolation.Default,
        ColorInterpolation.Pulse,
        ColorInterpolation.Pressed,
        ColorInterpolation.Hovered,
        ColorInterpolation.KeyboardFocused,
        ColorInterpolation.KeyboardPressed
      ],
      [
        ButtonColor.Default,
        ButtonColor.Pulse,
        ButtonColor.Pressed,
        ButtonColor.Hovered,
        ButtonColor.KeyboardFocused,
        ButtonColor.KeyboardPressed
      ]
    );

    this.entryBackgroundColorAnimation = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.animatedValues.backgroundColor,
        {
          delay: BACKGROUND_COLOR_ANIMATION_DELAY,
          duration: AnimationDuration.PulseOut,
          easing: loboAnimated.Easing.Out(),
          toValue: ColorInterpolation.Pulse
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.BackgroundColor,
        this.animatedValues.backgroundColor,
        {
          duration: AnimationDuration.PulseIn,
          easing: loboAnimated.Easing.In(),
          toValue: ColorInterpolation.Default
        }
      )
    ]);

    this.toDefaultBackgroundColorAnimation = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.animatedValues.backgroundColor,
      {
        duration: AnimationDuration.PressIn,
        easing: loboAnimated.Easing.In(),
        toValue: ColorInterpolation.Default
      }
    );

    this.hoverBackgroundColorAnimation = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.animatedValues.backgroundColor,
      {
        toValue: ColorInterpolation.Hovered
      }
    );

    this.keyboardFocusedBackgroundColorAnimation = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.animatedValues.backgroundColor,
      {
        duration: AnimationDuration.PressIn,
        easing: loboAnimated.Easing.In(),
        toValue: ColorInterpolation.KeyboardFocused
      }
    );

    this.keyboardPressedBackgroundColorAnimation = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.animatedValues.backgroundColor,
      {
        duration: AnimationDuration.PressIn,
        easing: loboAnimated.Easing.In(),
        toValue: ColorInterpolation.KeyboardPressed
      }
    );

    this.pressInBackgroundColorAnimation = loboAnimated.timing(
      AnimatableCSSProperty.BackgroundColor,
      this.animatedValues.backgroundColor,
      {
        duration: AnimationDuration.PressIn,
        easing: loboAnimated.Easing.In(),
        toValue: ColorInterpolation.Pressed
      }
    );

    this.opacityAnimation = loboAnimated.timing(
      AnimatableCSSProperty.Opacity,
      this.animatedValues.opacity,
      {
        delay: ENTRY_ANIMATION_DELAY,
        duration: AnimationDuration.PulseOut,
        easing: loboAnimated.Easing.In(),
        toValue: 1
      }
    );

    this.scaleAnimation = loboAnimated.sequence([
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        this.animatedValues.scale,
        {
          delay: ENTRY_ANIMATION_DELAY,
          duration: AnimationDuration.ScaleOut,
          easing: loboAnimated.Easing.Out(),
          toValue: 1.1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        this.animatedValues.scale,
        {
          duration: AnimationDuration.ScaleIn,
          easing: loboAnimated.Easing.In(),
          toValue: 1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        this.animatedValues.scale,
        {
          duration: AnimationDuration.PulseOut,
          easing: loboAnimated.Easing.Out(),
          toValue: 1.1
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.Scale,
        this.animatedValues.scale,
        {
          duration: AnimationDuration.PulseIn,
          easing: loboAnimated.Easing.In(),
          toValue: 1
        }
      )
    ]);

    this.translateAnimation = loboAnimated.parallel([
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateX,
        this.animatedValues.translateX,
        {
          delay: ENTRY_ANIMATION_DELAY,
          duration: AnimationDuration.SlideOut,
          easing: loboAnimated.Easing.In(),
          toValue: 30
        }
      ),
      loboAnimated.timing(
        AnimatableCSSProperty.TranslateY,
        this.animatedValues.translateY,
        {
          delay: ENTRY_ANIMATION_DELAY,
          duration: AnimationDuration.SlideOut,
          easing: loboAnimated.Easing.In(),
          toValue: -32
        }
      )
    ]);

    this.animations = {
      entry: loboAnimated.parallel([
        this.opacityAnimation,
        this.translateAnimation,
        this.scaleAnimation,
        this.entryBackgroundColorAnimation
      ]),
      toDefault: this.toDefaultBackgroundColorAnimation,
      hovered: this.hoverBackgroundColorAnimation,
      keyboardFocused: this.keyboardFocusedBackgroundColorAnimation,
      keyboardPressed: this.keyboardPressedBackgroundColorAnimation,
      pressed: this.pressInBackgroundColorAnimation
    };

    this.styles = {
      backgroundColorAnimated: {
        backgroundColor: this.backgroundColorInterpolation
      },
      opacityAnimated: {
        opacity: this.animatedValues.opacity
      },
      scaleAnimated: {
        transform: [{ scale: this.animatedValues.scale }]
      },
      translate: {
        bottom: 570,
        cursor: Cursor.Pointer,
        position: Position.Absolute,
        right: 135
      },
      translateAnimated: {
        transform: [
          { translateX: this.animatedValues.translateX },
          { translateY: this.animatedValues.translateY }
        ]
      }
    };
  }
  public getAnimations(): IFunFactButtonAnimations {
    return this.animations;
  }

  public build(activeComponentState: ActiveComponentState) {
    const isKeyNav = KeyNavHelper.isKeyNav(activeComponentState);

    return {
      ...this.styles,
      circle: {
        borderColor: isKeyNav ? Color.Black : Color.NearWhite,
        borderRadius: 20,
        borderWidth: isKeyNav ? 3 : 2,
        height: 39,
        shadowColor: Color.BlackTransparent30,
        shadowOffset: { height: 3, width: 1 },
        shadowRadius: 6,
        width: 39
      },
      exclamationMark: {
        color: isKeyNav ? Color.Black : Color.White
      }
    };
  }
}
