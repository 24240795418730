import { IConfigProvider } from '@lexialearning/lobo-common/app-config';
import { BehaviorSubject, Observable, of, race, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

export interface ISpinnerHandlerConfig {
  delay: number;
}

export class SpinnerHandler {
  public static readonly displayName = 'SpinnerHandler';

  private static delayMs = 0;

  private static readonly counter = new BehaviorSubject(0);

  public static get visibility$(): Observable<boolean> {
    return this.counter.asObservable().pipe(
      switchMap((value: number) => {
        if (value === 1) {
          return race([
            timer(this.delayMs),
            this.counter.asObservable().pipe(filter(v => v === 0))
          ]).pipe(
            map(
              // istanbul ignore next - trivial
              () => this.counter.value !== 0
            )
          );
        }

        return of(value > 0);
      }),
      distinctUntilChanged()
    );
  }

  public static configure(configProvider: IConfigProvider) {
    this.delayMs =
      configProvider.getConfig<ISpinnerHandlerConfig>('spinnerHandler').delay;
  }

  public static requestSpinner() {
    const val = Math.max(this.counter.value, 0);

    this.counter.next(val + 1);
  }

  public static dismissSpinner() {
    if (this.counter.value !== 0) {
      this.counter.next(this.counter.value - 1);
    }
  }

  public static reset() {
    // use negative values for full spinner reset - coming from errorHandler
    this.counter.next(-1);
  }
}

export enum SpinnerHandlerError {
  DismissedMoreTimesThanRequested = 'DismissedMoreTimesThanRequested'
}
