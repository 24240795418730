import { Position } from '@lexialearning/common-ui';
import { Align, Color, Justify } from 'common-styles';

export class YesNoChoicesContainerStyles {
  private static readonly buttonStyle = {
    bottom: 20,
    height: 95,
    marginBottom: 0,
    position: Position.Absolute,
    width: 121
  };

  private static readonly styles = {
    choiceContainer: {
      backgroundColor: Color.NearWhiteTransparent70,
      borderRadius: 20,
      height: 266,
      paddingRight: 13,
      width: 696
    },
    noButton: {
      ...YesNoChoicesContainerStyles.buttonStyle,
      right: 23
    },
    noGabber: {
      alignItems: Align.End,
      height: 241,
      justifyContent: Justify.End,
      marginHorizontal: 50,
      paddingTop: 5,
      width: 165
    },
    noHighlight: {
      bottom: 17,
      position: Position.Absolute,
      right: 21
    },
    yesButton: {
      ...YesNoChoicesContainerStyles.buttonStyle,
      right: 31
    },
    yesGabber: {
      alignItems: Align.End,
      height: 234,
      justifyContent: Justify.End,
      marginHorizontal: 50,
      width: 196
    },
    yesHighlight: {
      bottom: 17,
      position: Position.Absolute,
      right: 29
    }
  };

  public static get() {
    return this.styles;
  }
}
