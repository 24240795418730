import { ILanguageFrameToken } from '@lexialearning/lobo-common/tasks/see-speak';
import { LanguageFrameSession } from 'sre';

export enum SeeSpeakActionType {
  ClearSreOptions = 'SEE_SPEAK.CLEAR_SRE_OPTIONS',
  DecoratedTokens = 'SEE_SPEAK.DECORATED_TOKENS',
  RemoveTokenDecoration = 'SEE_SPEAK.RESET_TOKEN_DECORATIONS',
  SetSreOptions = 'SEE_SPEAK.SET_SRE_OPTIONS',
  SetSreOptionsSuccess = 'SEE_SPEAK.SET_SRE_OPTIONS_SUCCESS',
  SetSreOptionsFailure = 'SEE_SPEAK.SET_SRE_OPTIONS_FAILURE',
  ToggleListen = 'SEE_SPEAK.TOGGLE_LISTEN'
}

export interface ISeeSpeakState {
  languageFrameTokens: ILanguageFrameToken[];
  sreOptions: LanguageFrameSession.IConfig | undefined;
}
