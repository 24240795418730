import { IScreenplay } from '@lexialearning/lobo-common';
import { createSelector } from '@reduxjs/toolkit';
import {
  IPlaybackActionInfo,
  IScreenplayState,
  ScreenplayStatus
} from './screenplay-redux.model';
import { ScreenplayPrivateSelector } from './ScreenplayPrivate.selector';

export class ScreenplaySelector {
  public static readonly displayName = 'ScreenplaySelector';

  public static getActiveScreenplayId: (state: unknown) => string | undefined;

  public static getCurrentActionInfo: (state: unknown) => IPlaybackActionInfo;

  public static getScreenplayMaybe: (state: unknown) => IScreenplay | undefined;

  public static isPlaying: (state: unknown) => boolean;

  public static createSelectors(selector: (state: any) => IScreenplayState) {
    ScreenplayPrivateSelector.createSelectors(selector);

    this.getActiveScreenplayId = createSelector(
      ScreenplayPrivateSelector.getState,
      (state: IScreenplayState) => state.screenplay?.id
    );
    this.getCurrentActionInfo = createSelector(
      ScreenplayPrivateSelector.getState,
      (state: IScreenplayState) => state.currentActionInfo
    );
    this.getScreenplayMaybe = createSelector(
      ScreenplayPrivateSelector.getState,
      (state: IScreenplayState) => state.screenplay
    );
    this.isPlaying = createSelector(
      ScreenplayPrivateSelector.getState,
      (state: IScreenplayState) => state.status === ScreenplayStatus.Playing
    );
  }
}
