import { TaskPhase } from '@lexialearning/lobo-common';
import { useDispatch, useSelector } from 'react-redux';
import { PressScaleButton, View } from 'common-ui';
import { InteractiveContainer } from 'common-ui/components/interactive/InteractiveContainer';
import { TaskAction } from 'task-components/core/redux/Task.action';
import { TaskSelector } from 'task-components/core/redux/Task.selector';
import { IChoiceProps } from '../choice.model';
import {
  ChoiceContainerStyles,
  IChoiceContainerStylesOverride
} from './ChoiceContainer.styles';
import { useChoiceState } from '../useChoiceState';
import { useActiveComponentState } from '@lexialearning/common-ui';

export interface IChoiceContainerProps
  extends Omit<IChoiceProps, 'isSelected'> {
  hoverScale?: number;
  pressScale?: number;
  styleOverride?: IChoiceContainerStylesOverride;
  allowMultiSelect?: boolean;
}

export function ChoiceContainer(props: IChoiceContainerProps) {
  const {
    allowMultiSelect = false,
    children,
    hoverScale,
    pressScale,
    styleOverride,
    sysId
  } = props;

  const { interactionState, isSelected } = useChoiceState(props);

  const isInteractivePhase = useSelector(
    state => TaskSelector.getPhase(state) === TaskPhase.Interactive
  );

  const disabled = !isInteractivePhase || (isSelected && !allowMultiSelect);

  const dispatch = useDispatch();
  const selectionChangeAction = allowMultiSelect
    ? TaskAction.toggleSelectedChoice
    : TaskAction.setSelectedChoice;

  const onSelectionChange = (id: string) =>
    dispatch(selectionChangeAction({ choice: id }));

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState();

  const styles = ChoiceContainerStyles.build(
    activeComponentState,
    styleOverride
  );

  return (
    <PressScaleButton
      {...interactionHandlers}
      disabled={disabled}
      hoverScale={hoverScale || 1.02}
      onPress={() => onSelectionChange!(sysId)}
      pressScale={pressScale || 0.99}
      style={styles.button}
      testId={ChoiceContainer.displayName}
    >
      <View style={styles.interactiveContainerWrapper}>
        <InteractiveContainer
          styleOverride={styles.interactiveContainerStyleOverride}
          interactionState={interactionState}
        >
          {children}
        </InteractiveContainer>
      </View>
    </PressScaleButton>
  );
}
ChoiceContainer.displayName = 'ChoiceContainer';
