import memoizeOne from 'memoize-one';
import { Align, Color, TextAlign } from 'common-styles';

export class SeeSpeakTextPromptStyles {
  public static readonly build = memoizeOne(
    (isInstruction: boolean, width: number, isFocused: boolean) => ({
      container: {
        alignItems: Align.Center,
        maxWidth: width,
        ...(isInstruction && { height: 100 })
      },
      text: {
        color: isFocused
          ? Color.NearWhite
          : isInstruction
          ? Color.Gray30
          : Color.Black,
        textAlign: TextAlign.Left
      }
    })
  );
}
