import { IBootstrapContent } from '@lexialearning/lobo-common';

export enum BootstrapContentActionType {
  Load = 'BOOTSTRAP_CONTENT.LOAD_CONTENT',
  LoadSuccess = 'BOOTSTRAP_CONTENT.LOAD_CONTENT_SUCCESS',
  LoadFailure = 'BOOTSTRAP_CONTENT.LOAD_CONTENT_FAILURE'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBootstrapContentReduxState
  extends Partial<IBootstrapContent> {}
