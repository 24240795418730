import { Align, Color, Justify } from 'common-styles';

export class TroubleshootModalStyles {
  public static readonly styles = {
    footerInfo: {
      alignItems: Align.Start,
      flex: 1,
      justifyContent: Justify.End,
      minHeight: 38
    },
    footerRow: {
      alignItems: Align.Start,
      alignSelf: Align.Stretch,
      backgroundColor: Color.Gold,
      justifyContent: Justify.SpaceBetween,
      padding: 13,
      paddingRight: 16
    },
    footerButtons: {
      alignItems: Align.End
    },
    footerText: {
      fontSize: 11,
      height: 14
    },
    settingsContainer: {
      marginRight: 5
    },
    webView: {
      backgroundColor: Color.NearWhite,
      height: 490,
      width: 1005
    }
  };

  public static get() {
    return this.styles;
  }
}
