import { ButtonBase, Card, InteractiveContainer, Row } from 'common-ui';
import { IconSlot } from '../icon-slot/IconSlot';
import { IMultiSelectChoiceProps } from '../multi-select-component.model';
import { ImageSlotTransparentBgSvg } from '../svg';
import { ImageChoiceStyles } from './ImageChoice.styles';
import { useSelector } from 'react-redux';
import { TaskSelector } from 'task-components';
import { TaskPhase } from '@lexialearning/lobo-common';
import { useActiveComponentState } from '@lexialearning/common-ui';

export interface IImageChoiceProps extends IMultiSelectChoiceProps {
  choicesCount: number;
}

export function ImageChoice(props: IImageChoiceProps) {
  const taskPhase = useSelector(TaskSelector.getPhase);
  const {
    card,
    choicesCount,
    pillOriginIdx,
    index,
    interactionState,
    onPress,
    onSlotPosition,
    ...restProps
  } = props;

  const handlePress = () => {
    onPress(index);
  };

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const disabled = taskPhase !== TaskPhase.Interactive;
  const styles = ImageChoiceStyles.build(activeComponentState, choicesCount);

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      disabled={disabled}
      onPress={handlePress}
      style={styles.button}
    >
      <Row style={styles.slotTab}>
        <ImageSlotTransparentBgSvg />
        <IconSlot
          index={index}
          pillOriginIdx={pillOriginIdx}
          activeComponentState={activeComponentState}
          interactionState={interactionState}
          onPosition={onSlotPosition}
        />
      </Row>
      <InteractiveContainer
        activeComponentState={activeComponentState}
        interactionState={interactionState}
        styleOverride={styles.cardContainer}
      >
        <Card
          imageSource={card.image?.imageSource}
          styleOverride={styles.card}
          text={card.label}
          accessibilityLabel={card.image?.altText}
        />
      </InteractiveContainer>
    </ButtonBase>
  );
}
ImageChoice.displayName = 'ImageChoice';
