import { ActiveComponentState } from '@lexialearning/common-ui';

export class KeyNavHelper {
  public static readonly displayName = 'KeyNavHelper';

  /**
   * True when user is keyboard navigating on the element
   * (activeComponentState is either KeyboardFocused or KeyboardPressed)
   */
  public static isKeyNav(activeComponentState?: ActiveComponentState): boolean {
    return (
      !!activeComponentState &&
      [
        ActiveComponentState.KeyboardFocused,
        ActiveComponentState.KeyboardPressed
      ].includes(activeComponentState)
    );
  }
}
