import { IProgramPosition, IUnitSavePoint } from '@lexialearning/lobo-common';
import { ISessionHistoryEvent, ProgramMode } from '../program-context.model';

export enum ProgramContextActionType {
  AddCompletedLevel = 'PROGRAM_CONTEXT.ADD_COMPLETED_LEVEL',
  AddCompletedLevelSuccess = 'PROGRAM_CONTEXT.ADD_COMPLETED_LEVEL_SUCCESS',
  AddCompletedLevelError = 'PROGRAM_CONTEXT.ADD_COMPLETED_LEVEL_ERROR',
  KeepSessionAlive = 'PROGRAM_CONTEXT.KEEP_SESSION_ALIVE',
  KeepSessionAliveForced = 'PROGRAM_CONTEXT.KEEP_SESSION_ALIVE_FORCED',
  Loaded = 'PROGRAM_CONTEXT.LOADED',
  LoadedForDeepLinking = 'PROGRAM_CONTEXT.LOADED_FOR_DEEP_LINKING',
  MonitorSessionTimeout = 'PROGRAM_CONTEXT.MONITOR_SESSION_TIMEOUT',
  OnboardingCompleted = 'PROGRAM_CONTEXT.ONBOARDING_COMPLETED',
  ProgramPositionAdjustedForPlacement = 'PROGRAM_CONTEXT.PROGRAM_POSITION_ADJUSTED_FOR_PLACEMENT',
  StartProgram = 'PROGRAM_CONTEXT.START_PROGRAM',
  UpdateSessionHistory = 'PROGRAM_CONTEXT.UPDATE_SESSION_HISTORY',
  LoadCompletedLevels = 'PROGRAM_CONTEXT.COMPLETED_LEVELS_LOAD',
  LoadCompletedLevelsSuccess = 'PROGRAM_CONTEXT.COMPLETED_LEVELS_LOAD_SUCCESS',
  LoadCompletedLevelsError = 'PROGRAM_CONTEXT.COMPLETED_LEVELS_LOAD_ERROR'
}

export interface IProgramContextState {
  /**
   * Mode or flow in which the curriculum program is presented to the user
   */
  mode: ProgramMode;
  position: IProgramPosition;
  /**
   * Note that there may be more than one save point for a given unit id, but
   * there can only be one per unit id that is in "Deferred" status. Others
   * should be getting saved (or already saved)
   */
  savePoints: IUnitSavePoint[];
  /**
   * This is still in use by only one small feature and may get deprecated
   * See LOBO-12367
   */
  sessionHistory: ISessionHistoryEvent[];
}
