/* eslint-disable max-classes-per-file */
import { StandardSceneAnimationName } from 'services/storm-lobo/lobo-storm-config.model';

export enum LevelIntroSceneMaterialName {
  PaperBack = 'paper_back'
}

export class LevelIntroSceneAnimationName {
  public static readonly Root = {
    Intro: StandardSceneAnimationName.Intro,
    Outro: StandardSceneAnimationName.Outro
  };

  public static readonly Background = {
    Idle: StandardSceneAnimationName.Idle,
    Intro: StandardSceneAnimationName.Intro
  };

  public static readonly BackgroundOverlay = {
    Idle: StandardSceneAnimationName.Idle
  };
}

export class LevelIntroSceneElementName {
  public static buildBackground(idx: number): string {
    return `background_${idx}`;
  }

  public static BackgroundOverlay = 'background_overlay';
}
