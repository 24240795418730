import {
  IActivityPosition,
  IPlacementProgress,
  IProgramPosition,
  IRoundAttempt,
  IUnitPosition,
  TaskEvaluationResult
} from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { last } from 'lodash';
import {
  ActivityPositionBuilder,
  IPositionResponse,
  ProgramMode
} from 'curriculum-services';
import { PokRoundRecycling } from 'curriculum-services/program-context/epics/progress/position-determiners/PokRoundRecycling';
import { ExcellingLevelDeterminer } from 'curriculum-services/program-context/service-helpers';
import { ILoginResponse, UserRole } from 'lexia-service';
import { IAuth } from 'services/auth';
import { IProfile } from 'services/profile';
import { IStudentProperty } from '@lexialearning/student-api';
import { StudentApiHelper } from 'student-api';
import { HairColor, HairStyle, SkinColor } from 'services/profile/avatar.model';
import { EnumUtils } from 'utils/EnumUtil';

export interface IUserInfo {
  auth: IAuth;
  position?: IProgramPosition;
  profile: IProfile;
  programMode: ProgramMode;
  studentProperties: IStudentProperty[] | undefined;
}

export class UserInfoFactory {
  public static displayName = 'UserInfoFactory';

  public static create(loginResponse: ILoginResponse): IUserInfo {
    const {
      apiTimeoutArray,
      authToken,
      loginId,
      personId,
      programMode,
      studentProperties
    } = loginResponse;
    const auth: IAuth = { apiTimeoutArray, authToken, loginId, personId };
    const avatarStudentProperties =
      StudentApiHelper.parseAvatarStudentProperties(studentProperties);
    const defaultAvatar = {
      hairColor: EnumUtils.getRandomValue(HairColor),
      hairStyle: EnumUtils.getRandomValue(HairStyle),
      skinColor: EnumUtils.getRandomValue(SkinColor)
    };

    const profile: IProfile = {
      avatar: avatarStudentProperties?.selection ?? defaultAvatar,
      firstName: loginResponse.firstName,
      grade: loginResponse.grade,
      lastName: loginResponse.lastName,
      minutesSinceLastLogin: loginResponse.minutesSinceLastLogin,
      role: loginResponse.role,
      studentId: loginResponse.studentId
    };

    const activityPositions = this.transformActivityPositions(
      programMode,
      loginResponse.positionArray
    );

    const currentUserPosition = loginResponse.positionArray[0];
    if (
      loginResponse.programMode === ProgramMode.ActiveStudent &&
      !currentUserPosition
    ) {
      throw new LexiaError(
        'Login response returned without currentUserPosition',
        UserInfoFactory.displayName,
        UserInfoFactoryError.MissingCurrentUserPosition
      );
    }

    const position: IProgramPosition | undefined =
      profile.role === UserRole.Student
        ? {
            activeActivityId: undefined,
            activityPositions,
            excellingLevelNumber: ExcellingLevelDeterminer.determine(
              loginResponse.grade
            ),
            isComplete: false,
            levelId: currentUserPosition?.levelSysId ?? ''
          }
        : undefined;

    return {
      auth,
      position,
      profile,
      programMode,
      studentProperties
    };
  }

  public static transformActivityPositions(
    programMode: ProgramMode,
    positions: IPositionResponse[]
  ): IActivityPosition[] {
    return positions.map(
      position =>
        ActivityPositionBuilder.create({
          activityId: position.actSysId,
          encounterId: position.encounterSysId,
          isComplete: position.isComplete,
          lastProgressDate: position.lastProgressDate
        }).withUnitPosition(this.getUnitPosition(programMode, position)).raw
      // Note we use the raw (un-validated) position to allow "" round id from
      // API response. That is adjusted later: @see LevelSetupBuilder
    );
  }

  private static getUnitPosition(
    programMode: ProgramMode,
    position: IPositionResponse
  ): Partial<IUnitPosition> {
    const unitPosition: Partial<IUnitPosition> = {
      roundId: position.roundSysId,
      unitId: position.unitSysId
    };

    if (programMode === ProgramMode.Placement) {
      unitPosition.placementProgress =
        this.transformRoundAttemptsForPlacement(position);
    } else {
      unitPosition.recycling = PokRoundRecycling.buildRoundRecycling(
        position.roundSysId,
        this.transformRoundAttemptsForRecycling(position)
      );
    }

    return unitPosition;
  }

  private static transformRoundAttemptsForRecycling(
    position: IPositionResponse
  ): IRoundAttempt[] {
    return position.rounds.map(r => ({
      isScored: r.isScored,
      result: last(r.attempts)?.success
        ? TaskEvaluationResult.Correct
        : TaskEvaluationResult.Incorrect,
      roundId: r.roundId
    }));
  }

  private static transformRoundAttemptsForPlacement(
    position: IPositionResponse
  ): IPlacementProgress | undefined {
    const scoredRoundCount = position.rounds.filter(r => r.isScored).length;
    const incorrectRoundIds = position.rounds
      .filter(r => r.isScored && r.attempts.filter(a => a.success).length === 0)
      .map(r => r.roundId);
    const accuracy =
      ((scoredRoundCount - incorrectRoundIds.length) / scoredRoundCount) * 100;

    return {
      accuracy,
      incorrectRoundIds
    };
  }
}

export enum UserInfoFactoryError {
  MissingCurrentUserPosition = 'MissingCurrentUserPosition'
}
