// istanbul ignore file - TODO: finish Addressing specs - LOBO-19187 -says missing coverage when tests are hitting the area supposedly missed
import { Column, SpotlightArea } from 'common-ui/components';
import { ITaskProps } from 'task-components/core';
import { ProgramContextSelector } from 'curriculum-services';
import { SeeSpeakSelector } from './redux/SeeSpeak.selector';
import { SeeSpeakStyles } from './SeeSpeak.styles';
import { SpotlightAreaLayout } from 'common-ui/components/spotlight/Spotlight.styles';
import { ThemeType } from 'theme';
import { usePrimedSession } from 'sre/hooks/usePrimedSession';
import { useDispatch, useSelector } from 'react-redux';
import { ISeeSpeak } from '@lexialearning/lobo-common/tasks/see-speak';
import { useTheme } from 'theme/useTheme';
import { StandardSpeechBubbles } from './speech-bubbles/StandardSpeechBubbles';
import { InstructionSpeechBubble } from './speech-bubbles/instruction/InstructionSpeechBubble';
import { useDidMount } from 'common-ui';
import { SeeSpeakAction } from './redux/SeeSpeak.action';
import { useEffect, useRef } from 'react';

export interface ISeeSpeakProps extends ITaskProps<ISeeSpeak> {}

export function SeeSpeak(props: ISeeSpeakProps) {
  const { instructionalImage, spotlights, mode, textPromptArray } =
    props.taskContent;

  const { themeSize, themeType } = useTheme();
  const sreOptions = useSelector(SeeSpeakSelector.getSreSessionOptionsMaybe);
  const { initializePrimedState, updatePrimedStateSreOptions } =
    usePrimedSession();
  const context = useSelector(ProgramContextSelector.getRoundContext);
  const hasOnscreenCharacter = context.hasOnscreenCharacter;

  const isInitialized = useRef(false);

  useEffect(() => {
    if (sreOptions && isInitialized.current) {
      updatePrimedStateSreOptions(sreOptions);
    }
  }, [updatePrimedStateSreOptions, sreOptions]);

  useEffect(() => {
    if (sreOptions && !isInitialized.current) {
      initializePrimedState(sreOptions);
      isInitialized.current = true;
    }
  }, [initializePrimedState, sreOptions]);

  const styles = SeeSpeakStyles.build(
    themeSize,
    themeType,
    !!instructionalImage,
    spotlights?.length,
    hasOnscreenCharacter,
    !!textPromptArray.length
  );

  const isInstruction = themeType === ThemeType.Instruction;

  const dispatch = useDispatch();
  useDidMount(() => {
    dispatch(
      SeeSpeakAction.setSreOptions.request({
        languageFrameTokens: props.taskContent.languageFrame
      })
    );

    return () => {
      dispatch(SeeSpeakAction.clearSreOptions());
    };
  });

  return (
    <Column style={styles.seeSpeakContainer} testId={SeeSpeak.displayName}>
      {isInstruction ? (
        <InstructionSpeechBubble
          mode={mode}
          styles={styles.instruction}
          textPromptArray={textPromptArray}
        />
      ) : (
        <>
          <SpotlightArea
            layout={SpotlightAreaLayout.Small}
            styleOverride={styles.spotlightAreaStyleOverride}
            {...{ instructionalImage, spotlights }}
          />
          <StandardSpeechBubbles
            hasOnscreenCharacter={hasOnscreenCharacter}
            mode={mode}
            textPromptArray={textPromptArray}
            themeSize={themeSize}
          />
        </>
      )}
    </Column>
  );
}
SeeSpeak.displayName = 'SeeSpeak';
