import { DeployEnvironment } from '@lexialearning/main-model';
import { IDeployConfig } from './lobo-config.model';

declare const __DEPLOY_ENVIRONMENT__: DeployEnvironment;
// istanbul ignore next - n/a in prod
/**
 * DeployConfig should be defined in template.html for web. For native values will be
 * injected at build time.
 */
export const DeployConfig = ((window as any).DeployConfig as IDeployConfig) || {
  deployEnvironment: __DEPLOY_ENVIRONMENT__
};

/**
 * Replace tokens. For higher envs, this is already done during deployment.
 */

/* eslint-disable no-template-curly-in-string */
DeployConfig.deployEnvironment = DeployConfig.deployEnvironment.replace(
  '${deployEnvironment}',
  DeployEnvironment.Local
) as DeployEnvironment;
