import { TaskTypeName } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import {
  ITaskRegistration,
  ITaskScreenplayEditor
} from './task-registry.model';

export class TaskRegistry<N extends TaskTypeName = TaskTypeName> {
  private readonly map = new Map<N, ITaskRegistration<N>>();

  public placeholder?: ITaskRegistration<N>;

  public screenplayEditor: ITaskScreenplayEditor = { edit: s => s };

  constructor(registrations: ITaskRegistration<N>[]) {
    registrations.forEach(r => this.map.set(r.taskType, r));
  }

  public get(taskType: N): ITaskRegistration<N> {
    if (!this.map.has(taskType)) {
      if (this.placeholder) {
        return this.placeholder;
      }

      throw new LexiaError(
        `Unregistered task type '${taskType}.`,
        'TaskRegistry',
        TaskRegistryErrorCode.UnregisteredTaskType
      ).withContext({ taskType });
    }

    return this.map.get(taskType)!;
  }

  public has(taskType: N): boolean {
    return this.map.has(taskType);
  }
}

export enum TaskRegistryErrorCode {
  UnregisteredTaskType = 'UnregisteredTaskType'
}
