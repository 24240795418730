import { IPlacement, IScreenplay } from '@lexialearning/lobo-common';
import { LexiaError } from '@lexialearning/utils';
import { createSelector } from '@reduxjs/toolkit';
import { IPlacementState } from './placement-redux.model';

export class PlacementSelector {
  public static readonly displayName = 'PlacementSelector';

  public static getPlacement: (state: unknown) => IPlacement;

  public static getPlacementMaybe: (state: unknown) => IPlacement | undefined;

  public static getPlayedLookbackIntro: (state: unknown) => boolean;

  public static getLookbackSingleClickVoiceover: (
    state: unknown
  ) => IScreenplay | undefined;

  public static createSelectors(
    selector: (state: any) => IPlacementState
  ): void {
    this.getPlacementMaybe = createSelector(
      selector,
      state => state.placementContent
    );

    this.getPlacement = createSelector(
      this.getPlacementMaybe,
      placementContent => {
        if (!placementContent) {
          throw new LexiaError(
            'No placement content',
            PlacementSelector.displayName,
            PlacementSelectorError.PlacementContentMissing
          );
        }

        return placementContent;
      }
    );

    this.getLookbackSingleClickVoiceover = createSelector(
      selector,
      (state: IPlacementState) =>
        !state.hasPlayedLookbackIntro && state.placementContent
          ? state.placementContent.lookbackSingleClick
          : undefined
    );
  }
}

export enum PlacementSelectorError {
  PlacementContentMissing = 'PlacementContentMissing'
}
