import { AnimatedView, ButtonBase, View } from 'common-ui';
import {
  AccessibilityRole,
  ActiveComponentState,
  useActiveComponentState
} from '@lexialearning/common-ui';
import { ColorChoiceIndicatorSvg } from './ColorChoiceIndicator.lg-svg';
import { useEffect, useRef } from 'react';
import { IButtonBaseProps } from 'common-ui/components/button/ButtonBase';
import { ColorChoiceAnimatedStyles } from './ColorChoice.animated-styles';

export interface IColorChoiceProps extends IButtonBaseProps {
  accessibilityLabel: string | undefined;
  colorName: string;
  colorHex: string;
  onSelect: (colorName: string) => void;
  selected: boolean;
}

export function ColorChoice(props: IColorChoiceProps) {
  const {
    accessibilityLabel,
    colorHex,
    colorName,
    onSelect,
    selected,
    ...restProps
  } = props;

  const { activeComponentState, interactionHandlers } =
    useActiveComponentState(props);

  const animatedStyles = useRef(new ColorChoiceAnimatedStyles());

  const animations = animatedStyles.current.getAnimations();

  const styles = animatedStyles.current.build(activeComponentState, colorHex);

  const handlePress = () => {
    onSelect(colorName);
  };

  useEffect(() => {
    switch (activeComponentState) {
      case ActiveComponentState.Hovered:
        animations.hovered.start();
        break;
      case ActiveComponentState.KeyboardPressed:
      case ActiveComponentState.Pressed:
        animations.pressed.start();
        break;
      default:
        animations.toDefault.start();
        break;
    }
  }, [activeComponentState, animations]);

  return (
    <ButtonBase
      {...restProps}
      {...interactionHandlers}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={AccessibilityRole.Radio}
      accessibilityState={{ checked: selected }}
      onPress={handlePress}
      style={styles.colorChoice}
      testId={ColorChoice.displayName}
    >
      <AnimatedView animatedStyle={styles.scaleAnimated}>
        {selected ? (
          <ColorChoiceIndicatorSvg
            activeComponentState={activeComponentState}
            style={styles.indicator}
          />
        ) : (
          <View style={styles.pip} testId={'pip'} />
        )}
      </AnimatedView>
    </ButtonBase>
  );
}
ColorChoice.displayName = 'ColorChoice';
