import memoizeOne from 'memoize-one';
import { Color, Justify } from 'common-styles';
import { ThemeSize } from 'theme';
import { CompletionChoiceStyles } from './choice/CompletionChoice.styles';
import { FlexWrap } from '@lexialearning/common-ui';

export class CompletionStyles {
  public static readonly styles = {
    choiceStyleOverride: {
      textPill: {
        container: {
          marginBottom: 10
        }
      }
    },
    choicesColumn: {
      padding: 15
    },
    completionBox: {
      backgroundColor: Color.NearWhiteTransparent40,
      borderRadius: 10,
      height: 330,
      justifyContent: Justify.Start,
      marginBottom: 20,
      width: 810
    },
    row: {
      flexWrap: FlexWrap.Wrap,
      justifyContent: Justify.Start,
      marginBottom: 20,
      marginTop: 20,
      width: 510
    },
    sentenceWord: {
      paddingLeft: 2,
      paddingRight: 3
    },
    sentencesContainer: {
      marginBottom: 5,
      marginTop: 5
    },
    spotlightAreaContainer: { marginRight: 75 },
    submitButtonContainer: {
      height: 290,
      width: 74
    }
  };

  public static readonly build = memoizeOne(
    (themeSize: ThemeSize, hasSpotlightArea: boolean) => ({
      ...CompletionStyles.styles,
      choiceDragStyleOverride: {
        container: {
          height: CompletionChoiceStyles.Height,
          width: CompletionStyles.getPillWidth(themeSize)
        }
      },
      task: {
        flex: 1,
        justifyContent: hasSpotlightArea ? Justify.Start : Justify.Center,
        paddingLeft: 22
      }
    })
  );

  public static getPillWidth(themeSize?: ThemeSize) {
    return themeSize === ThemeSize.Large ? 240 : 200;
  }
}
