import { TopicCode } from '@lexialearning/lobo-common';
import { GlossyButtonIconSvg, IRoundGlossyButtonColorSet } from 'common-ui';
import { BiographiesIconSvg } from './icons/BiographiesIcon.lx-svg';
import { MathIconSvg } from './icons/MathIcon.lx-svg';
import { ScienceIconSvg } from './icons/ScienceIcon.lx-svg';
import { SocialStudiesIconSvg } from './icons/SocialStudiesIcon.lx-svg';

export const TopicColorSets: { [id: string]: IRoundGlossyButtonColorSet } = {
  biographies: {
    stop1: '#F8832E',
    stop2: '#D16E25'
  },
  math: {
    stop1: '#00BCFF',
    stop2: '#0087C2'
  },
  science: {
    stop1: '#92E947',
    stop2: '#499410'
  },
  socialStudies: {
    stop1: '#FFD736',
    stop2: '#C58C07'
  }
};

export const TopicIconMap = new Map<TopicCode, GlossyButtonIconSvg>([
  [TopicCode.Biographies, BiographiesIconSvg],
  [TopicCode.Math, MathIconSvg],
  [TopicCode.Science, ScienceIconSvg],
  [TopicCode.SocialStudies, SocialStudiesIconSvg]
]);
