import { SreStatus } from '@lexialearning/lobo-common/main-model/sre';
import { ofType, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { first, map, mergeMap, startWith } from 'rxjs/operators';
import {
  SreConfigAction,
  SreConfigActionType,
  SreConfigureAction,
  SreConfigureValidatedAction,
  SreSelector
} from '../redux';

export function configureSreEpic(
  action$: Observable<SreConfigureAction>,
  state$: StateObservable<unknown>
): Observable<SreConfigureValidatedAction> {
  return action$.pipe(
    ofType(SreConfigActionType.Configure),
    mergeMap(a => awaitProperSreStatus(a, state$))
  );
}
configureSreEpic.displayName = 'configureSreEpic';

/**
 * Typically we just configure once. But if/when we allow changing the voice
 * type, we will want to allow re-configuring, in which case we want that
 * action to wait around any other active initialization or active session
 */
function awaitProperSreStatus(
  _action: SreConfigureAction,
  state$: StateObservable<unknown>
): Observable<SreConfigureValidatedAction> {
  return state$.pipe(
    startWith(state$.value),
    first(
      state =>
        ![SreStatus.Initializing, SreStatus.Listening].includes(
          SreSelector.getStatus(state)
        )
    ),
    map(() => SreConfigAction.configureValidated())
  );
}
