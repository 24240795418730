import { Align, Color } from 'common-styles';

export const UNIT_METER_HEIGHT = 15;

export class UnitMeterStyles {
  private static readonly styles = {
    container: {
      alignSelf: Align.Stretch,
      backgroundColor: Color.Black,
      height: UNIT_METER_HEIGHT,
      paddingHorizontal: 1
    }
  };

  public static get() {
    return this.styles;
  }
}
